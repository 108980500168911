import { useContext } from 'react';
import { CommandPalleteContext } from '../../context/CommandPalleteProvider';

export const useCommandPallete = () => {
  const {
    actions,
    gptChatResponses,
    isLoading,
    mentionedObject,
    searchBarDisabled,
    secondViewPath,
    selectedIntent,
    setGptChatResponses,
    setIsLoading,
    setMentionedObject,
    setSearchBarDisabled,
    setSecondViewPath,
    setSelectedIntent,
    isOpen,
    setIsOpen,
    threadId,
    setThreadId,
    instruction,
    setInstruction,
    conversationId,
    setConversationId,
  } = useContext(CommandPalleteContext);

  const clearCommandPallete = () => {
    //setGptChatResponses([]);
    setMentionedObject(null);
    setSelectedIntent(null);
    setSecondViewPath('');
  };

  return {
    isOpen,
    setIsOpen,
    actions,
    gptChatResponses,
    isLoading,
    mentionedObject,
    searchBarDisabled,
    secondViewPath,
    selectedIntent,
    threadId,
    setThreadId,
    conversationId,
    setConversationId,
    setGptChatResponses,
    setIsLoading,
    setMentionedObject,
    setSearchBarDisabled,
    setSecondViewPath,
    setSelectedIntent,
    clearCommandPallete,
    instruction,
    setInstruction,
  };
};
