import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import {  TYPE_OPTIONS } from '../../../components/rules/configurator/constants';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type TypeFilterRowType = { value: string; label: string };
export type TypeFilterContextType = FilterContextParams<TypeFilterRowType>;

const defaultFilterState = TYPE_OPTIONS.map((option) => ({
  ...option,
  selected: false,
}));
export const useTypeFilterState = (page: string) => {
  const { state, setState } = useFilterState<TypeFilterRowType>(FILTER_TYPE.TYPE, page, defaultFilterState);
  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);
  return {
    state,
    setState,
    hasSelections,
  };
};
