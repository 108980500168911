import { useAccountingPeriods, useTransactionById } from '../../../../hooks/http';
import { Sidebar, SidebarBody, SidebarTopBar } from '../../../atoms/Sidebar';
import AddTransactionMemo from '../../../Transaction/AddTransactionMemo';
import CreateJournalEntry from '../../../jorunalEntry/CreateJournalEntry';
import { SidebarProps } from '../../SidebarGlobal';
import { useTabState } from '../../../../hooks';
import NFTDetails from './NFTDetails';
import JournalEntriesTable from './JournalEntriesTable';
import Traits from './Traits';
import CostBasis from './CostBasis';
import PriceBreakdown from './PriceBreakdown';
import Details from './Details';
import Memo from './Memo';
import TransactionSidebarHeader from './TransactionSidebarHeader';
import { getDisplayedAccountingPeriods, mergeAccountingPeriods } from '../../../templates/utils';
import { useEffect, useState } from 'react';

export const TransactionSidebar: React.FC<SidebarProps> = ({
  id,
  isDockPanelAvailable,
  onBack,
  isPrimary,
  handlePrimaryPanelClose,
  'data-cy': dataCy = 'transaction',
}) => {
  const [defaultTransaction, setDefaultTransaction] = useState<{
    transactionId: string;
    walletId: string;
    legalEntityId: string;
    sequenceNumber: string;
    chain: string;
    accountingPeriodId: string;
    amount?: string;
  }>({
    transactionId: '',
    walletId: '',
    legalEntityId: '',
    sequenceNumber: '',
    chain: '',
    accountingPeriodId: '',
  });

  const { data, isLoading } = useTransactionById(id);

  const transaction = data?.pages[0].transactions[0];

  const { secondRouteUnStack, updateTabSidebarState } = useTabState();

  const { data: accountingPeriods } = useAccountingPeriods({ status: 'Open' });
  const mergedAccountingPeriods = mergeAccountingPeriods(accountingPeriods);
  const displayedAccountingPeriods = getDisplayedAccountingPeriods(mergedAccountingPeriods);

  useEffect(() => {
    if (data && transaction && displayedAccountingPeriods.length > 0) {
      const transactionDate = new Date(transaction.transactionDate ?? '');
      const accountingPeriodId = displayedAccountingPeriods.find(
        ({ label }) =>
          label === transactionDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' }).toUpperCase(),
      );

      setDefaultTransaction({
        transactionId: transaction._id ?? '',
        walletId: transaction.walletId?._id ?? '',
        legalEntityId: transaction.legalEntityId?._id ?? '',
        sequenceNumber: transaction.sequenceNumber ?? '',
        chain: transaction.chain ?? '',
        accountingPeriodId: accountingPeriodId?.value ?? '',
        amount: transaction?.grossPrice?.$numberDecimal,
      });
    }
  }, [data, transaction, displayedAccountingPeriods]);

  console.log('defaultTransaction', defaultTransaction);
  return (
    <>
      {(!secondRouteUnStack?.showCreateJournal || isPrimary) && (
        <Sidebar data-cy={dataCy}>
          <SidebarTopBar
            isDockPanelAvailable={isDockPanelAvailable}
            onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
            onBack={onBack}
            itemId={id}
            isPrimary={isPrimary}
            data-cy={dataCy}
          />
          <TransactionSidebarHeader
            transaction={transaction}
            transactionId={id}
            isLoading={isLoading}
            isPrimary={isPrimary}
            dataCy={dataCy}
          />
          <SidebarBody>
            <NFTDetails transaction={transaction} isLoading={isLoading} />
            <Details transaction={transaction} isLoading={isLoading} />
            <PriceBreakdown transaction={transaction} transactionId={id} />
            <CostBasis transaction={transaction} isLoading={isLoading} />
            <Traits transaction={transaction} />
            <Memo transaction={transaction} isLoading={isLoading} isPrimary={isPrimary} />
            <JournalEntriesTable transactionId={id} />
          </SidebarBody>
        </Sidebar>
      )}
      {secondRouteUnStack?.showAddTransactionMemo && (
        <AddTransactionMemo
          onClose={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          transactionId={transaction?._id}
          organizationId={transaction?.organizationId}
          savedMemo={secondRouteUnStack?.memo ?? transaction?.memo}
        />
      )}
      {secondRouteUnStack?.showCreateJournal && (
        <CreateJournalEntry
          onCancel={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          selectedJournalEntry={null}
          onClose={() => updateTabSidebarState({ secondRouteUnStack: {} })}
          defaultTransaction={defaultTransaction}
        />
      )}
    </>
  );
};

export default TransactionSidebar;
