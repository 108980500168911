import { useContext, createContext, useEffect, useMemo, Dispatch, ReactNode } from 'react';

import {
  Condition,
  ConditionGroup,
  RULE_CONFIGURATOR_TYPE,
  RuleConfiguratorState,
  CONDITION_GROUP_CHILD_TYPE,
  GATE_TYPE,
} from '../types';
import { ACTION_TYPE } from './action-types';
import { useRuleConfiguratorReducer } from './reducer';
import { getInitialStateForRuleConfigurator } from './utils';

const RuleConfigurator = createContext({
  isDisabled: false,
  state: getInitialStateForRuleConfigurator(RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  dispatch: (action: any) => {},
  getAllConditionGroupIds: () => [] as string[],
  getAllParentConditionGroupIds: () => [] as string[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getConditionGroupChildType: (childId: string) =>
    CONDITION_GROUP_CHILD_TYPE.CONDITION_GROUP as CONDITION_GROUP_CHILD_TYPE,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getConditionGroupChildren: (conditionGroupId: string) => [] as (ConditionGroup | Condition)[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getConditionGroupById: (conditionGroupId: string) => ({}) as ConditionGroup,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getParentByChildId: (childId: string) => ({}) as ConditionGroup,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getParentCountByChildId: (childId: string) => 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getConditionById: (conditionId: string) => ({}) as Condition,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getGate: (childId: string): { value: GATE_TYPE; shouldShow: boolean; parentId: string; index: number } => ({
    value: GATE_TYPE.AND,
    shouldShow: true,
    parentId: '',
    index: 0,
  }),

  getAsTopLevelCondition: () => Array<Array<{ [key: string]: any }>>,
  addConditionGroup: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    conditionGroup: Omit<ConditionGroup, 'id' | 'childIds' | 'gates'> & {
      id?: string;
      childIds?: string[];
      gates?: GATE_TYPE[];
    },
  ) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateConditionGroup: (conditionGroupId: string, update: Partial<Omit<ConditionGroup, 'id'>>) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateGate: (conditionGroupId: string, index: number, update: GATE_TYPE) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  removeConditionGroup: (conditionGroupId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getParentConditionGroup: (childId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  reorderConditionGroups: (conditionGroupId: string, to: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  duplicate: (childId: string, parentId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  addCondition: (condition: Omit<Condition, 'id'>) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  addNestedCondition: (condition: Omit<Condition, 'id'>) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  updateCondition: (conditionId: string, update: Partial<Omit<Condition, 'id'>>) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  removeCondition: (conditionId: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  reorderConditionGroupConditions: (conditionGroupId: string, conditionId: string, at: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  moveConditionToConditionGroup: (conditionId: string, toConditionGroup: string, at: number) => {},
});
export const useRuleConfigurator = () => useContext(RuleConfigurator);

const useSelectors = (reducer, mapStateToSelectors) => {
  const [state] = reducer;
  const selectors = useMemo(() => mapStateToSelectors(state), [state]);
  return selectors;
};

const useActions = (reducer, mapDispatchToActions) => {
  const [, dispatch] = reducer;
  const actions = useMemo(() => mapDispatchToActions(dispatch), [dispatch]);
  return actions;
};

const getParentCount = (state: RuleConfiguratorState, childId: string) => {
  const parent = Object.values(state.conditionGroups).find((conditionGroup) =>
    conditionGroup.childIds.includes(childId),
  );
  if (!parent) return 0;
  if (!parent.parentId) return 1;
  return 1 + getParentCount(state, parent.id);
};

function generateJSON(conditionGroup: ConditionGroup, conditions: Condition[]): any {
  const { childIds, gates } = conditionGroup;

  if (childIds.length === 0) {
    return {}; // Empty JSON if no childIds are provided
  }

  if (childIds.length === 1) {
    const matchingCondition = conditions.find((cond) => cond.id === childIds[0]);
    if (matchingCondition) {
      return matchingCondition;
    }
  }

  const output: any[] = [];

  for (let i = 0; i < gates.length; i++) {
    output.push({
      [gates[i]]: childIds.slice(i).map((childId) => {
        const matchingCondition = conditions.find((cond) => cond.id === childId);
        return matchingCondition ? matchingCondition : childId;
      }),
    });
  }

  return output;
}

const useRuleConfiguratorSelectors = (rulesetStateReducer: [RuleConfiguratorState, Dispatch<any>]) =>
  useSelectors(rulesetStateReducer, (state: RuleConfiguratorState) => ({
    getConditionGroupChildType: (childId: string) =>
      state.conditionGroupIds.includes(childId)
        ? CONDITION_GROUP_CHILD_TYPE.CONDITION_GROUP
        : CONDITION_GROUP_CHILD_TYPE.CONDITION,
    getAllConditionGroupIds: () => state.conditionGroupIds,
    getAllParentConditionGroupIds: () =>
      state.conditionGroupIds.filter((conditionGroupId) => !state.conditionGroups[conditionGroupId].parentId),

    getConditionGroupById: (conditionGroupId: string) => state.conditionGroups[conditionGroupId],
    getParentConditionGroup: (childId: string) =>
      Object.values(state.conditionGroups).find((conditionGroup) => conditionGroup.childIds.includes(childId)),
    getConditionById: (conditionId: string) => state.conditions[conditionId],
    getParentByChildId: (childId: string) =>
      Object.values(state.conditionGroups).find((conditionGroup) =>
        conditionGroup.childIds.includes(childId),
      ) as ConditionGroup,
    getParentCountByChildId: (childId: string) => getParentCount(state, childId),
    getGate: (childId: string) => {
      const parent = Object.values(state.conditionGroups).find((conditionGroup) =>
        conditionGroup.childIds.includes(childId),
      );
      if (!parent) throw new Error('Invalid childId, no parent found for this condition/condition-group');

      // the gate index is same as the child index
      const index = parent.childIds.findIndex((c) => c === childId);
      // console.log('getGate', index, parent.childIds, index < parent.gates.length - 1);
      return {
        value: parent.gates[index],
        shouldShow: index < parent.gates.length - 1, // we dont want to show gate if its for the last child
        parentId: parent.id,
        index,
      };
    },
    getAsTopLevelCondition: () =>
      Object.values(state.conditionGroups).map((group) => generateJSON(group, Object.values(state.conditions))),
  }));

const useRuleConfiguratorActions = (rulesetStateReducer: [RuleConfiguratorState, Dispatch<any>]) =>
  useActions(rulesetStateReducer, (dispatch: Dispatch<any>) => ({
    addConditionGroup: (
      conditionGroup: Omit<ConditionGroup, 'id' | 'childIds'> & { id?: string; childIds?: string[] },
      at?: number,
    ) => dispatch({ type: ACTION_TYPE.ADD_CONDITION_GROUP, payload: { conditionGroup, at } }),
    updateConditionGroup: (conditionGroupId: string, update: Partial<Omit<ConditionGroup, 'id'>>) =>
      dispatch({ type: ACTION_TYPE.UPDATE_CONDITION_GROUP, payload: { id: conditionGroupId, update } }),
    removeConditionGroup: (conditionGroupId: string) =>
      dispatch({ type: ACTION_TYPE.REMOVE_CONDITION_GROUP, payload: { id: conditionGroupId } }),
    reorderConditionGroups: (conditionGroupId: string, to: number) =>
      dispatch({ type: ACTION_TYPE.REORDER_CONDITION_GROUPS, payload: { id: conditionGroupId, to } }),

    addCondition: (condition: Omit<Condition, 'id'> & { id?: string }) =>
      dispatch({ type: ACTION_TYPE.ADD_CONDITION, payload: { condition } }),
    addNestedCondition: (condition: Omit<Condition, 'id'> & { id?: string }) =>
      dispatch({ type: ACTION_TYPE.ADD_NESTED_CONDITION, payload: { condition } }),
    updateCondition: (conditionId: string, update: Partial<Omit<Condition, 'id'>>) => {
      console.log('update in payload', update);
      dispatch({
        type: ACTION_TYPE.UPDATE_CONDITION,
        payload: { id: conditionId, update },
      });
    },
    updateGate: (conditionGroupId: string, index: number, update: GATE_TYPE) =>
      dispatch({
        type: ACTION_TYPE.UPDATE_GATE,
        payload: { conditionGroupId, index, update },
      }),
    removeCondition: (conditionId: string) =>
      dispatch({ type: ACTION_TYPE.REMOVE_CONDITION, payload: { id: conditionId } }),
    reorderConditionGroupConditions: (conditionGroupId: string, conditionId: string, at: number) =>
      dispatch({
        type: ACTION_TYPE.REORDER_CONDITION_GROUP_CONDITIONS,
        payload: { conditionGroupId, id: conditionId, at },
      }),
    moveConditionToConditionGroup: (conditionId: string, toConditionGroup: string, at: number) =>
      dispatch({
        type: ACTION_TYPE.MOVE_CONDITION_TO_CONDITION_GROUP,
        payload: { toConditionGroup, id: conditionId, at },
      }),
    duplicate: (childId: string, parentId: string) => {
      dispatch({
        type: ACTION_TYPE.CREATE_COPY,
        payload: { childId, parentId },
      });
    },
  }));

export const RuleConfiguratorProvider = ({
  configuratorType,
  defaultValue,
  onChange,
  children,
  isDisabled,
}: {
  configuratorType: RULE_CONFIGURATOR_TYPE;
  defaultValue?: RuleConfiguratorState;
  onChange?: (v: RuleConfiguratorState) => void;
  children: ReactNode;
  isDisabled?: boolean;
}) => {
  const reducer = useRuleConfiguratorReducer(configuratorType, defaultValue);
  const selectors = useRuleConfiguratorSelectors(reducer);
  const actions = useRuleConfiguratorActions(reducer);

  const [state, dispatch] = reducer;

  useEffect(() => {
    if (onChange) onChange(state);
  }, [state]);

  useEffect(() => {
    if (defaultValue) dispatch({ type: ACTION_TYPE.SYNC, payload: defaultValue });
  }, [defaultValue]);

  return (
    <RuleConfigurator.Provider
      value={{
        isDisabled,
        state,
        dispatch,
        ...actions,
        ...selectors,
      }}
    >
      {children}
    </RuleConfigurator.Provider>
  );
};
