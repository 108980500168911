import axios from 'axios';
import { CredentialResponse } from '@react-oauth/google';
import { SERVER_URL_AUTH } from '../config';
import { getSubdomain } from './utils';
import { Organization, OrganizationMembers, User } from 'schemas';

export type SessionDetailsFromServer = {
  user: User;
  organizations: Organization[];
  memberships: OrganizationMembers[];
  selectedOrganization?: Organization;
  selectedMembership?: OrganizationMembers;
};
export const googleAuthRequest = (data: CredentialResponse) =>
  axios.post<SessionDetailsFromServer>(`${SERVER_URL_AUTH}/google-auth`, {
    ...data,
    subdomain: getSubdomain(),
  });

export const initPasswordReset = (email: string) =>
  axios.post(`${SERVER_URL_AUTH}/init-password-reset`, { email, subdomain: getSubdomain() });

export const resetPassword = (data: { token: string; newPassword: string }) =>
  axios.post(`${SERVER_URL_AUTH}/reset-password`, { ...data });

export const registerUser = (data: { user: { email: string; password: string; passwordConfirmation: string } }) =>
  axios.post<SessionDetailsFromServer>(`${SERVER_URL_AUTH}/register`, { ...data, subdomain: getSubdomain() });

export type SignUpFormType = {
  email: string;
  password: string;
  passwordConfirmation: string;
  name: {
    first: string;
    last: string;
  };
  profilePhoto?: string;
};

export const registerUserV2 = async (data: SignUpFormType) =>
  axios.post<SessionDetailsFromServer>(`${SERVER_URL_AUTH}/signup`, { user: data, subdomain: getSubdomain() });

export const loginUser = (data: { user: { email: string; password: string } }) =>
  axios.post<SessionDetailsFromServer>(`${SERVER_URL_AUTH}/login`, { ...data, subdomain: getSubdomain() });

export const logout = () => axios.post(`${SERVER_URL_AUTH}/logout`);

export const getCurrentSession = (token: string) =>
  axios.get<SessionDetailsFromServer>(`${SERVER_URL_AUTH}/get-current-session?token=${token}`, {
    excludeAuthorizationHeader: true,
  });

export const verifyEmailWithShortCode = async (shortCode: string, userId: string) =>
  axios.post<{ user: User }>(`${SERVER_URL_AUTH}/verify-email?shortCode=${shortCode}&userId=${userId}`);

export const resentShortCode = async (userId) => {
  return axios.post(`${SERVER_URL_AUTH}/resent-short-code`, {
    userId,
    subdomain: getSubdomain(),
  });
};
