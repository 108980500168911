import { useEffect, useState } from 'react';
import { LedgerAccount } from 'services/http/response.types';
import { useLedgerAccount } from './useLedgerAccounts';

export const useAllLedgerAccounts = () => {
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[]>([]);
  const { data, isLoading } = useLedgerAccount({});

  useEffect(() => {
    if (!isLoading && data) setLedgerAccounts(data.pages.flat());
  }, [data, isLoading]);

  return { ledgerAccounts, isLoading };
};
