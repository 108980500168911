import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, currencyImg } from 'ui';
import { useAssetTypeFilterContext } from '../../../../context';

export const AssetTypesFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useAssetTypeFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Assets' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((asset, i) => (
            <CheckboxListItem
              key={i}
              label={asset.name}
              bottomText={asset.abbreviation}
              checked={asset.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
              enableAvatar
              src={currencyImg(asset.abbreviation.toLowerCase())}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No assets to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
