import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type RowType = { value: string; label: string };
export type OriginatedByFilterContextType = FilterContextParams<RowType>;

const defaultState = [
  { value: 'system', label: 'System', selected: false },
  { value: 'user', label: 'User', selected: false },
];

export const useOriginatedByFilterState = (page: string) => {
  const { state, setState } = useFilterState<RowType>(FILTER_TYPE.ORIGINATED_BY, page, defaultState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);
  return {
    state,
    setState,
    hasSelections,
  };
};
