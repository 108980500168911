import * as React from 'react';

import { StyledCheckbox, StyledIndicator } from '../checkbox';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { AiOutlineMinus } from 'react-icons/ai';
import { Tooltip } from '../tooltip';
import { classNames } from '../utils';
import { IndeterminateCheckboxProps } from './types';

export function IndeterminateCheckbox({
  indeterminate,
  isHeader = false,
  className,
  ...rest
}: IndeterminateCheckboxProps) {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return isHeader ? (
    <Tooltip content={rest.checked ? 'Deselect all' : 'Select all'} removeTooltipContainerClassname>
      <StyledCheckbox
        {...rest}
        checked={indeterminate ? 'indeterminate' : rest.checked}
        onClick={(event) => event.stopPropagation()}
        className={classNames(
          'hover:!border-zinc-700 data-[state=checked]:!bg-indigo-600 data-[state=checked]:!border-indigo-600 data-[state=indeterminate]:!border-indigo-600 data-[state=indeterminate]:!bg-indigo-600',
          className,
        )}
      >
        <StyledIndicator>
          {indeterminate ? <AiOutlineMinus className='stroke-white fill-white' /> : <CheckIcon />}
        </StyledIndicator>
      </StyledCheckbox>
    </Tooltip>
  ) : (
    <StyledCheckbox
      {...rest}
      onClick={(event) => event.stopPropagation()}
      onCheckedChange={rest.onChange as any}
      className={classNames(
        'hover:!border-zinc-700 !min-w-[16px] data-[state=checked]:!bg-indigo-600 data-[state=checked]:!border-indigo-600',
        className,
      )}
    >
      <StyledIndicator>
        <CheckIcon />
      </StyledIndicator>
    </StyledCheckbox>
  );
}
