import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
export type GetDistinctContractNamesParams = {
  organizationId: string;
};
export const getDistinctContractNames = async ({ organizationId }: GetDistinctContractNamesParams) => {
  return await axios.get(
    `${SERVER_URL_CONFIGURATION_SERVICE}/distinct-contract-names?organizationId=${organizationId}`,
  );
};
