export enum PAY_INVOICE_MODAL_STEP {
  SELECT_PAYMENT_MODE,
  SELECT_STRIPE_PAYMENT_METHOD,
  SHOW_STRIPE_PAYMENT_STATUS,
  SUCCESS,
  ERROR,
}

export enum INVOICE_PAYMENT_METHOD {
  CARD,
  USDC,
}
