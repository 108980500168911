import React from 'react';
import ConditionItem from '../common/ConditionItem';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { Virtuoso } from 'react-virtuoso';
import { SUPPORTED_ERC20_TOKEN } from '../../../../../../constants';
import { setSaveData } from '../../../../utils';
import { assets } from '../../../constants';
import { InputWithExtras } from 'ui';
import { MdSearch } from 'react-icons/md';
import SearchFilter from '../common/SearchFilter';
import { ConditionMenusBodyProps } from '../../../types';

export const AssetConditionBody = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  callbackFn,
  isInLineCondition,
  closeDialog,
  filterERC,
  setFilterERC,
  filterAssets,
  assetTypes,
  assetsTypeLoading,
}: ConditionMenusBodyProps) => {
  return (
    <SearchFilter
      data={assets}
      renderItem={(item) => {
        // console.log({ item });
        if (item === 'ERC - 20 Tokens') {
          return (
            <ConditionItem key={item} label='ERC- 20 Tokens' img='eth'>
              <div className='my-2 absolute inset-x-2'>
                <InputWithExtras
                  type='text'
                  placeholder='Search...'
                  onChange={(e) => setFilterERC(filterAssets(SUPPORTED_ERC20_TOKEN, e.target.value))}
                  leading={<MdSearch className='w-5 h-5' />}
                  containerClassName='min-w-[none] w-full'
                />
              </div>
              <div className='mt-15'>
                {filterERC.length ? (
                  <ScrollArea.Root>
                    <ScrollArea.Viewport>
                      <Virtuoso
                        style={{ height: 250 }}
                        data={filterERC}
                        itemContent={(index, token) => (
                          <ConditionItem
                            onClick={() => {
                              if (isInLineCondition) {
                                callbackFn && callbackFn(token);
                                closeDialog && closeDialog();
                              } else {
                                setSaveData('Asset', token, isFirstLevel, ruleSetData, getRuleSetData);
                              }
                            }}
                            img={token.toLowerCase()}
                            label={token.toLowerCase()}
                          />
                        )}
                      />
                    </ScrollArea.Viewport>
                    <ScrollArea.Scrollbar orientation='vertical'>
                      <ScrollArea.Thumb />
                    </ScrollArea.Scrollbar>
                  </ScrollArea.Root>
                ) : (
                  <div className='w-full text-center'>No data found</div>
                )}
              </div>
            </ConditionItem>
          );
        } else if (item === 'SOL Tokens') {
          return (
            <ConditionItem key={item} label='SOL Tokens' img='sol'>
              <SearchFilter
                data={['DUST', 'RAY']}
                renderItem={(item) => (
                  <ConditionItem
                    key={item}
                    onClick={() => {
                      if (isInLineCondition) {
                        callbackFn && callbackFn(item);
                        closeDialog && closeDialog();
                      } else {
                        setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                      }
                    }}
                    img={item.toLowerCase()}
                    label={item}
                  />
                )}
              />
            </ConditionItem>
          );
        } else if (item === 'NEAR Tokens') {
          return (
            <ConditionItem key={item} label='NEAR Tokens' img='near'>
              <SearchFilter
                data={['NEAR', 'SWEAT']}
                renderItem={(item) => (
                  <ConditionItem
                    key={item}
                    onClick={() => {
                      if (isInLineCondition) {
                        callbackFn && callbackFn(item);
                        closeDialog && closeDialog();
                      } else {
                        setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                      }
                    }}
                    img={item.toLowerCase()}
                    label={item}
                  />
                )}
              />
            </ConditionItem>
          );
        } else if (item === 'My assets') {
          return (
            <ConditionItem img='' isLoading={assetsTypeLoading} key={item} label='My assets'>
              <SearchFilter
                data={assetTypes ?? []}
                renderItem={(item) => (
                  <ConditionItem
                    key={item}
                    onClick={() => {
                      if (isInLineCondition) {
                        callbackFn && callbackFn(item);
                        closeDialog && closeDialog();
                      } else {
                        setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                      }
                    }}
                    img={item?.toLowerCase()}
                    label={item}
                  />
                )}
              />
            </ConditionItem>
          );
        } else {
          return (
            <ConditionItem
              key={item}
              onClick={() => {
                if (isInLineCondition) {
                  callbackFn && callbackFn(item);
                  closeDialog && closeDialog();
                } else {
                  setSaveData('Asset', item, isFirstLevel, ruleSetData, getRuleSetData);
                }
              }}
              label={item}
              img={item?.toLowerCase()}
            />
          );
        }
      }}
    />
  );
};
