import { useQuery } from '@tanstack/react-query';
import { getAiCreditUsage } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';
import { useSession } from '../../../useSession';

export const useGetAiCreditUsageForOrgQuery = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.USAGE),
    async () => {
      const response = await getAiCreditUsage();
      return response.data;
    },
    { enabled: !!organizationId },
  );
};
