import { FILTER_TYPE } from '../../components/filters';

export enum SOURCE_CHART_PERIODS {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
  ALLTIME = 'All Time',
}

export const SOURCES_PAGE_FILTERS = [
  FILTER_TYPE.LEGAL_ENTITY,
  FILTER_TYPE.CHAIN,
  FILTER_TYPE.TAG,
  FILTER_TYPE.WALLET_TYPE,
];

export const WALLET_TYPES_FILTER_ROWS = ['Internal', 'External'];
