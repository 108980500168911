import { HiXMark } from 'react-icons/hi2';
import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { JOB_NAME } from 'services/http';
import { useLatestJobConfiguration } from './use-latest-job-configuration';
import { getLoaderBgStateFromJobConfig, getLoaderDescriptionFromBgJobState } from './utils';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { LoaderIcon } from 'ui';
import { isJobComplete } from 'global-utils';

interface TrackJobInTaskManagerProps {
  referenceId: string;
  jobName: JOB_NAME;
  onClose: () => void;
  uniqueId?: string;
}

export const TrackJobInTaskManager = ({ referenceId, jobName, onClose, uniqueId }: TrackJobInTaskManagerProps) => {
  const [showToolTip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto-start',
  });

  const { jobConfiguration } = useLatestJobConfiguration(referenceId, jobName, uniqueId);

  const backgroundJobStatus = getLoaderBgStateFromJobConfig(jobConfiguration);
  const description = getLoaderDescriptionFromBgJobState({
    bgJobState: backgroundJobStatus,
    jobName,
    jobConfig: jobConfiguration,
  });
  const icon = useMemo(() => {
    if (!jobConfiguration || !isJobComplete(jobConfiguration)) return <LoaderIcon />;
    if (jobConfiguration.jobStatus === 'COMPLETED')
      return <MdCheckCircle className='w-4 h-4 text-green-100 bg-green-800 rounded-full' />;
    if (jobConfiguration.jobStatus && ['JOB_FAILED', 'CANCELED'].includes(jobConfiguration.jobStatus))
      return <MdError className='w-4 h-4 text-red-100 bg-red-800 rounded-full' />;
  }, [jobConfiguration]);

  return (
    <div className='flex items-center justify-between p-4 pr-5 text-left'>
      {icon && (
        <span
          className='ml-1 mr-4'
          style={{
            transform: 'scale(2)',
          }}
        >
          {icon}
        </span>
      )}
      <span className='mr-3.5 flex-grow w-full truncate'>
        <span>
          <span
            ref={setReferenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {description}
          </span>

          {description.length > 20 && showToolTip && (
            <span ref={setPopperElement} style={styles.popper} {...attributes.popper} className='z-40 w-full'>
              <span className='text-xs flex py-1 px-2 shadow rounded-lg bg-black opactity-70 text-white m-2 whitespace-normal break-all'>
                {description}
              </span>
            </span>
          )}
        </span>
      </span>

      {onClose && (
        <button onClick={onClose} className='hover:text-gray-500'>
          <HiXMark className='w-5 h-5' />
        </button>
      )}
    </div>
  );
};
