import { useQuery } from '@tanstack/react-query';
import { getTransactionCountByWallet } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useGetTransactionCountByWalletQuery = (walletId: string) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.GET_TRANSACTION_COUNT_BY_WALLET, { walletId }),
    async () => {
      const response = await getTransactionCountByWallet(walletId);
      return response.data;
    },
    { enabled: !!organizationId },
  );
};
