import * as React from 'react';
import { forwardRef } from 'react';
const SvgSunny = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='m27.04 19.36-7.2-7.16-5.64 5.64L21.36 25l5.68-5.64ZM4 42h12v8H4v-8ZM44 2.2h8V14h-8V2.2Zm32.16 9.98 5.632 5.627-7.16 7.16-5.628-5.632 7.156-7.156Zm-7.2 60.46 7.16 7.2 5.64-5.64-7.2-7.16-5.6 5.6ZM80 42h12v8H80v-8ZM48 22c-13.24 0-24 10.76-24 24s10.76 24 24 24 24-10.76 24-24-10.76-24-24-24Zm0 40c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16Zm-4 16h8v11.8h-8V78Zm-29.8-3.84 5.64 5.64L27 72.6l-5.64-5.64-7.16 7.2Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSunny);
export default ForwardRef;
