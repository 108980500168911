import { ColumnDef } from '@tanstack/react-table';
import { IndeterminateCheckbox } from 'ui';
import { GoTriangleDown, GoTriangleRight } from 'react-icons/go';
import { cn } from '@/shadcn/lib/utils';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export const defaultColumns: ColumnDef<any, any>[] = [
  {
    id: 'select',
    accessorKey: 'select_checkbox',
    header: ({ table }) => {
      const route = useRouter();
      const isDragableRowsTable = useMemo(() => route.pathname === '/ledger/sources', []);

      return (
        <div className={cn('flex justify-center items-center h-full')}>
          {isDragableRowsTable && <div className='w-5 flex justify-center'></div>}
          <IndeterminateCheckbox
            isHeader
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <>
          {(row.original.isGroup || row.original?.isSubRow?.length > 0) && (
            <button className='w-5 flex justify-center align-middle'>
              {row.getIsExpanded() ? <GoTriangleDown /> : <GoTriangleRight />}
            </button>
          )}

          <div className={cn('flex justify-center items-center h-full', row.original?.groupId ? 'w-[38px]' : 'w-full')}>
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        </>
      );
    },
    enableResizing: false,
    enableHiding: false,
    size: 48,
  },
];
