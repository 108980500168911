import { useRouter } from 'next/router';
import { useSession } from './useSession';
import { useLogout } from './useLogout';
import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getCurrentSession, getOrganizationById, updateSelectedOrganization } from 'services';
import { ONBOARDING_REDIRECT_MAP } from 'schemas';

export const useOnboardingRedirectCallback = () => {
  const { handleSessionFromServer } = useSession();
  const logout = useLogout(false);

  const callback = useCallback(async (token: string, organizationId: string, step: string) => {
    try {
      const response = await getCurrentSession(token);
      const data = response.data;

      handleSessionFromServer(data);
      // we need these cookies for axios interceptor and auth middleware (frontend)
      console.log('useOnboardingRedirectCallback', organizationId);
      const orgResponse = await getOrganizationById({ organizationId, token: data?.user?.jwt });
      const organization = orgResponse.data.organization;

      return ONBOARDING_REDIRECT_MAP[step] ?? ONBOARDING_REDIRECT_MAP[organization.onboardingStatus];
    } catch (error) {
      console.error('useOnboardingRedirectCallback', error);
      toast.error('Link expired, please login to continue');
      logout();
    }
  }, []);
  return callback;
};

export const useHandleRedirectWithSession = (redirectTo?: string) => {
  const { handleSessionFromServer } = useSession();
  const logout = useLogout();
  const router = useRouter();

  useEffect(() => {
    const handleSession = async () => {
      const params = new URL(location.href).searchParams;
      const token = params.get('token'); // is the string "Jonathan Smith".
      const organizationId = params.get('organizationId');
      if (token && organizationId) {
        console.log('changing organization', { token, organizationId });
        try {
          await updateSelectedOrganization({ token, selectedOrganizationId: organizationId });

          const response = await getCurrentSession(token);
          const data = response.data;

          handleSessionFromServer(data);

          console.log('useHandleRedirectWithSession', organizationId);
          const orgResponse = await getOrganizationById({ organizationId, token: data.user.jwt });
          const organization = orgResponse.data.organization;

          if (organization.completedOnboarding) router.replace('/overview');
          else
            router.replace({
              pathname: redirectTo ?? ONBOARDING_REDIRECT_MAP[organization.onboardingStatus],
              query: router.query,
            });
        } catch (error) {
          console.error('useHandleRedirectWithSession.handleSession', error);
          toast.error('Your session expired');
          logout();
        }
      } else {
        console.log('logging out');
        logout();
      }
    };
    if (window) handleSession();
  }, []);
};
