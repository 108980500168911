import { Action, Priority } from 'farish-kbar';
import { MdAccountBalance, MdAccountBalanceWallet, MdDomain, MdOutlineSms } from 'react-icons/md';

export const KBarSections = {
  navigation: 'Navigation',
  journal: 'Journal Entry',
  transaction: 'Operational Transaction',
  wallets: 'Wallet',
  rulesets: 'Ruleset',
  templates: 'Journal Entry Template',
  legalEntity: 'Legal Entity',
  ledgerAccount: 'Ledger Account',
  commands: 'Commands',
  create: 'Create',
  suggestions: 'Suggested Instructions',
};

export const CreateIntentActions: Action[] = [
  {
    id: 'create-ledger-account-ai',
    name: 'Create a ledger account called...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'create',
    icon: <MdAccountBalance className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'create-wallet-ai',
    name: 'Import my ETH wallet with address...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'create',
    icon: <MdAccountBalanceWallet className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'create-legal-entity-ai',
    name: 'Create a legal entity with currency USD called...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'create',
    icon: <MdDomain className='w-5 h-5 text-zinc-900' />,
  },
];

export const EditIntentActions: Action[] = [
  {
    id: 'edit-ledger-account-ai',
    name: 'Edit ledger account...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'edit',

    icon: <MdAccountBalance className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'edit-wallet-ai',
    name: 'Edit wallet...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'edit',
    icon: <MdAccountBalanceWallet className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'edit-legal-entity-ai',
    name: 'Edit legal entity...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'edit',
    icon: <MdDomain className='w-5 h-5 text-zinc-900' />,
  },
];

export const DeleteIntentActions: Action[] = [
  {
    id: 'delete-ledger-account-ai',
    name: 'Delete ledger account...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'delete',
    icon: <MdAccountBalance className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'delete-wallet-ai',
    name: 'Delete wallet...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'delete',
    icon: <MdAccountBalanceWallet className='w-5 h-5 text-zinc-900' />,
  },
  {
    id: 'delete-legal-entity-ai',
    name: 'Delete legal entity...',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'delete',
    icon: <MdDomain className='w-5 h-5 text-zinc-900' />,
  },
];

export const AnalyzeIntentActions: Action[] = [
  // {
  //   id: 'analyze-income-expense',
  //   name: 'How has my income and expense changed over the last 3 periods?',
  //   section: KBarSections.suggestions,
  //   priority: Priority.HIGH,
  //   disableToggleAfterPerform: true,
  //   keywords: 'analyze',
  //   icon: <MdOutlineSms />,
  // },
  {
    id: 'analyze-transaction-volume',
    name: 'What wallets had the most transaction volume?',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'analyze',
    icon: <MdOutlineSms />,
  },
  {
    id: 'analyze-gas-fees',
    name: 'How much gas fees have I paid by wallet?',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'analyze',
    icon: <MdOutlineSms />,
  },
  {
    id: 'analyze-assets',
    name: 'How much have my assets increased over the last 3 periods?',
    section: KBarSections.suggestions,
    priority: Priority.HIGH,
    disableToggleAfterPerform: true,
    keywords: 'analyze',
    icon: <MdOutlineSms />,
  },
];
