import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '../button';
import { classNames } from '../utils';

export const ModalActions = ({ actions, className }: { actions: (ButtonProps | ReactNode)[]; className?: string }) =>
  actions.length > 0 ? (
    <div className={classNames('flex flex-row-reverse gap-x-2 w-full', className)}>
      {actions.map((action, i) =>
        React.isValidElement(action) ? action : <Button key={i} {...(action as ButtonProps)} />,
      )}
    </div>
  ) : null;
