'use client';

import { useMemo, useState, useCallback } from 'react';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../@/components/ui/card';
import { ChartConfig, ChartContainer } from '../../../@/components/ui/chart';
import { ScrollArea } from '../../../@/components/ui/scroll-area';
import { Button } from '../../../@/components/ui/button';
import { DatePicker } from '../../../@/components/ui/datepicker';
import { WalletAssetQuantitiesChartSkeleton } from './wallet-asset-quantities-chart-skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from '../../../@/components/ui/table';
import { Switch } from '../../../@/components/ui/switch';
import { WalletAssetQuantitiesChartProps } from './types';
import { FilterPopover } from '../../popovers/filter/filter-popover';

export function WalletAssetQuantitiesChartAssetXAxis({
  data,
  isLoading,
  onDownload,
  dateFilter,
  setDateFilter,
  legalEntityFilter,
  setLegalEntityFilter,
  chainFilter,
  setChainFilter,
  walletFilter,
  setWalletFilter,
  legalEntities,
  chains,
  sources,
}: WalletAssetQuantitiesChartProps) {
  const [activeTooltip, setActiveTooltip] = useState<{ payload: any; label: string } | null>(null);
  const [selectedAssetType, setSelectedAssetType] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<'chart' | 'table'>('table');
  const handleDateChange = (date: Date | undefined) => {
    setDateFilter(date);
  };

  const filteredData = useMemo(() => {
    return data.map((source) => ({
      ...source,
      assets:
        selectedAssetType.length > 0
          ? source.assets.filter((asset) => selectedAssetType.includes(asset.assetType))
          : source.assets,
    }));
  }, [data, selectedAssetType]);

  const processedData = useMemo(() => {
    const assetData: { [key: string]: { assetType: string; [key: string]: number | string } } = {};
    const allSources = new Set<string>();
    const sourceNameCounts: { [key: string]: number } = {};

    filteredData.forEach((source) => {
      let sourceName = source.sourceName;
      if (sourceNameCounts[sourceName]) {
        sourceNameCounts[sourceName]++;
        sourceName = `${sourceName} (${sourceNameCounts[sourceName]})`;
      } else {
        sourceNameCounts[sourceName] = 1;
      }

      allSources.add(sourceName);
      source.assets.forEach((asset) => {
        if (!assetData[asset.assetType]) {
          assetData[asset.assetType] = { assetType: asset.assetType };
        }
        const quantity = Number(asset.quantity) || 0;
        assetData[asset.assetType][sourceName] = ((assetData[asset.assetType][sourceName] as number) || 0) + quantity;
      });
    });

    Object.values(assetData).forEach((asset) => {
      allSources.forEach((source) => {
        if (!(source in asset)) {
          asset[source] = 0;
        }
      });
    });

    return { assetData, allSources };
  }, [filteredData]);

  const chartData = useMemo(() => {
    const { assetData } = processedData;

    let filteredAssets = Object.values(assetData);

    return filteredAssets.sort((a, b) => {
      const totalA = Object.entries(a).reduce(
        (sum, [key, val]) => (key !== 'assetType' && typeof val === 'number' ? sum + val : sum),
        0,
      );
      const totalB = Object.entries(b).reduce(
        (sum, [key, val]) => (key !== 'assetType' && typeof val === 'number' ? sum + val : sum),
        0,
      );
      return totalB - totalA;
    });
  }, [processedData, selectedAssetType]);

  const assetTypes = useMemo(() => {
    const types = new Set<string>();
    data.forEach((source) => {
      source.assets.forEach((asset) => {
        types.add(asset.assetType);
      });
    });
    return [...Array.from(types)];
  }, [data]);

  const chartConfig: ChartConfig = useMemo(() => {
    const colors = [
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
      '#bcbd22',
      '#17becf',
      '#aec7e8',
      '#ffbb78',
      '#98df8a',
      '#ff9896',
      '#c5b0d5',
    ];

    console.log(`The chart data is`, chartData);
    if (chartData.length === 0) {
      return {};
    }

    const sources = Object.keys(chartData[0]).filter((key) => key !== 'assetType');
    return Object.fromEntries(
      sources.map((source, index) => [source, { label: source, color: colors[index % colors.length] }]),
    );
  }, [chartData]);

  const formatYAxis = (value: number) => {
    if (value === 0) return '0';
    const absValue = Math.abs(value);
    if (absValue >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
    if (absValue >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
    if (absValue >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
    return value.toFixed(1);
  };

  const handleMouseEnter = useCallback((props: any) => {
    if (props.payload && props.payload.length) {
      setActiveTooltip({ payload: props.payload, label: props.label });
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveTooltip(null);
  }, []);

  const customTooltip = useCallback(
    (props: any) => {
      const { payload, label } = props.active ? props : activeTooltip || {};
      if (payload && payload.length) {
        const filteredPayload = payload.filter((entry: any) => entry.value !== 0);
        const sortedPayload = [...filteredPayload].sort((a, b) => b.value - a.value);
        const total = sortedPayload.reduce((sum, entry) => sum + entry.value, 0);

        const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

        return (
          <div className='bg-white p-4 border border-gray-200 rounded-lg shadow-md max-w-[300px] max-h-[300px]'>
            <h4 className='font-semibold mb-2'>{label}</h4>
            <ScrollArea className='h-[200px]'>
              {sortedPayload.map((entry: any, index: number) => (
                <div key={index} className='flex  items-center py-1'>
                  <span className='font-medium' style={{ color: entry.color }}>
                    {entry.name}:
                  </span>
                  <span className='ml-2'>{formatYAxis(entry.value)}</span>
                </div>
              ))}
            </ScrollArea>
            <div className='mt-2 pt-2 border-t border-gray-200 flex  items-center'>
              <span className='font-semibold'>Total:</span>
              <div className='flex items-center'>
                <span className='font-semibold mr-2'>{formatter.format(total)}</span>
              </div>
            </div>
          </div>
        );
      }
      return null;
    },
    [activeTooltip],
  );

  const handleDownload = useCallback(() => {
    if (onDownload) {
      onDownload(filteredData);
    }
  }, [onDownload, filteredData]);

  const tableData = useMemo(() => {
    const assetTypes = new Set<string>();
    const walletData: { [key: string]: { [key: string]: number } } = {};
    const sourceNameCounts: { [key: string]: number } = {};

    filteredData.forEach((source) => {
      let sourceName = source.sourceName;
      if (sourceNameCounts[sourceName]) {
        sourceNameCounts[sourceName]++;
        sourceName = `${sourceName} (${sourceNameCounts[sourceName]})`;
      } else {
        sourceNameCounts[sourceName] = 1;
      }

      walletData[sourceName] = {};
      source.assets.forEach((asset) => {
        assetTypes.add(asset.assetType);
        walletData[sourceName][asset.assetType] = asset.quantity;
      });
    });

    const assetTypesArray = Array.from(assetTypes);

    return {
      assetTypes: assetTypesArray,
      wallets: Object.entries(walletData).map(([wallet, assets]) => ({
        wallet,
        ...assetTypesArray.reduce(
          (acc, assetType) => ({
            ...acc,
            [assetType]: assets[assetType] || 0,
          }),
          {},
        ),
        total: assetTypesArray.reduce((sum, assetType) => sum + (assets[assetType] || 0), 0),
      })),
    };
  }, [filteredData]);

  const renderSelectedItems = (selectedItems: string[], maxDisplay: number = 1): JSX.Element => {
    if (selectedItems.length <= maxDisplay) {
      return <span>{selectedItems.join(', ')}</span>;
    }
    const displayedItems = selectedItems.slice(0, maxDisplay).join(', ');
    const remainingCount = selectedItems.length - maxDisplay;
    return (
      <span>
        {displayedItems} +{remainingCount}
      </span>
    );
  };

  return (
    <div className='my-8'>
      <Card className='w-full'>
        <CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0'>
          <div className='flex flex-1 items-center justify-between px-6 py-5'>
            <div>
              <CardTitle>Source Balances</CardTitle>
              <CardDescription>Showing asset quantities across sources</CardDescription>
            </div>
            <div className='flex items-center gap-4'>
              <div className='flex items-center gap-2'>
                <span className='text-sm font-medium'>Table</span>
                <Switch
                  checked={viewMode === 'chart'}
                  onCheckedChange={(checked) => setViewMode(checked ? 'chart' : 'table')}
                />
                <span className='text-sm font-medium'>Chart</span>
              </div>
              <Button onClick={handleDownload} disabled={isLoading}>
                Download
              </Button>
            </div>
          </div>
          <div className='flex flex-wrap items-center gap-4 px-6 py-5'>
            <div className='flex flex-wrap items-center gap-4'>
              <DatePicker date={dateFilter || new Date()} setDate={handleDateChange} className='w-[180px]' />

              <FilterPopover
                label='Asset Types'
                title='Select Asset Types'
                options={assetTypes}
                selectedOptions={selectedAssetType}
                setSelectedOptions={setSelectedAssetType}
                renderSelectedItems={renderSelectedItems}
              />

              <FilterPopover
                label='Legal Entities'
                title='Select Legal Entities'
                options={legalEntities.map((e) => e.entityName)}
                selectedOptions={legalEntityFilter.map(
                  (id) => legalEntities.find((e) => e._id === id)?.entityName || id,
                )}
                setSelectedOptions={(selected) =>
                  setLegalEntityFilter(
                    selected.map((name) => legalEntities.find((e) => e.entityName === name)?._id || name),
                  )
                }
                renderSelectedItems={renderSelectedItems}
              />

              <FilterPopover
                label='Chains'
                title='Select Chains'
                options={chains}
                selectedOptions={chainFilter}
                setSelectedOptions={setChainFilter}
                renderSelectedItems={renderSelectedItems}
              />

              <FilterPopover
                label='Wallets'
                title='Select Wallets'
                options={sources.map((s) => s.label)}
                selectedOptions={walletFilter.map((id) => sources.find((s) => s.value === id)?.label || id)}
                setSelectedOptions={(selected) =>
                  setWalletFilter(selected.map((label) => sources.find((s) => s.label === label)?.value || label))
                }
                renderSelectedItems={renderSelectedItems}
              />
            </div>
          </div>
        </CardHeader>
        <CardContent className='p-6 max-h-[500px]'>
          <div className='h-[500px] overflow-auto'>
            {isLoading ? (
              <div className='h-[300px]'>
                <WalletAssetQuantitiesChartSkeleton variant={viewMode} />
              </div>
            ) : viewMode === 'chart' ? (
              <ChartContainer config={chartConfig} className='h-[500px] w-full'>
                <ResponsiveContainer width='100%' height='100%'>
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
                    onMouseMove={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                      dataKey='assetType'
                      angle={-45}
                      textAnchor='end'
                      height={100}
                      interval={0}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis tickFormatter={formatYAxis} />
                    <Tooltip content={customTooltip} wrapperStyle={{ zIndex: 1000 }} isAnimationActive={false} />
                    {Object.keys(chartConfig).map((source) => {
                      console.log(chartConfig[source], source);
                      return <Bar key={source} dataKey={source} stackId='a' fill={chartConfig[source].color} />;
                    })}
                  </BarChart>
                </ResponsiveContainer>
              </ChartContainer>
            ) : (
              <div className='h-80 relative overflow-auto'>
                <Table>
                  <TableHeader className='sticky top-0 bg-white z-20'>
                    <TableRow>
                      <TableHead className='sticky left-0 z-30 bg-white'>Wallet</TableHead>
                      {tableData.assetTypes.map((assetType) => (
                        <TableHead key={assetType} className='text-right'>
                          {assetType}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {tableData.wallets.map((wallet) => (
                      <TableRow key={wallet.wallet}>
                        <TableCell className='sticky left-0 bg-white z-10 font-medium'>{wallet.wallet}</TableCell>
                        {tableData.assetTypes.map((assetType) => (
                          <TableCell key={assetType} className='text-right'>
                            {formatYAxis(wallet[assetType as keyof typeof wallet] as number)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell className='sticky left-0 bg-gray-100 z-10 font-bold'>Total</TableCell>
                      {tableData.assetTypes.map((assetType) => (
                        <TableCell key={assetType} className='text-right font-bold'>
                          {formatYAxis(
                            tableData.wallets.reduce(
                              (sum, wallet) => sum + ((wallet[assetType as keyof typeof wallet] as number) || 0),
                              0,
                            ),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
