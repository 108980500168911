import React from 'react';
import { DotsIcon } from '../../../assets/generated/svg';
import { DropDownMenuProps } from './types';
import { Button, Dropdown, DropdownContent, DropdownBody, DropdownTrigger, classNames } from 'ui';
import { MdArrowDropDown } from 'react-icons/md';

export const DropDownMenu = ({ children, label, className, dropdownContainerClassname = '' }: DropDownMenuProps) => {
  return (
    <Dropdown>
      <DropdownTrigger className={className}>
        {!label ? (
          <Button emphasis='medium' leadingIcon={<DotsIcon className='!text-xl rotate-90' />} />
        ) : (
          <Button label={label} emphasis='medium' trailingIcon={<MdArrowDropDown />} />
        )}
      </DropdownTrigger>
      <DropdownContent
        className={classNames('z-sidepanel w-48', dropdownContainerClassname)}
        align='start'
        avoidCollisions={true}
      >
        <DropdownBody className='p-1'>{children}</DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};

export default DropDownMenu;
