import { useCreateExchangeJobs } from '../components/integrations/exchanges/useExchangeSourceQueries';

export const useCreateExchangeSyncBgJob = (onSuccess?: () => void, onError?: (error: any) => void) => {
  const { mutateAsync } = useCreateExchangeJobs();

  const createExchangeJobs = async (sourceId: string, accountingPeriodId?: string,) => {
    try {
      await mutateAsync({
        accountingPeriodId,
        exchangeId: sourceId,
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return createExchangeJobs;
};
