import { useState } from 'react';
import { JobConfiguration } from 'schemas';
import { incrementalUpdateForJob } from '../lib/utils';
import { useJobUpdatesByJobNameAndReferenceId } from '../pubsub-topics';
import { useInvalidateQuery } from './useInvalidateQuery';

export const useWebsocketUpdatesForJob = (referenceId: string, jobName, uniqueId?: string) => {
  const [jobConfig, setJobConfig] = useState<JobConfiguration>();

  const { invalidateGetTransactionCountByWallet } = useInvalidateQuery();
  const onUpdate = (jobUpdate) => {
    setJobConfig((prev) => incrementalUpdateForJob(jobUpdate.payload.jobConfig, prev));
    invalidateGetTransactionCountByWallet({ walletId: jobUpdate.payload.jobConfig?.referenceId });
  };

  useJobUpdatesByJobNameAndReferenceId({
    jobName,
    referenceId,
    uniqueId,
    callback: onUpdate,
  });
  return jobConfig;
};
