import useDimensions from 'react-cool-dimensions';
import { truncateText } from 'global-utils';

export const Truncate = ({ text, className }: { text: string; className?: string }) => {
  const { observe: containerRef, width: containerWidth } = useDimensions();

  return (
    <div ref={containerRef} className='w-full'>
      <div className={`inline-block ${className}`}>{truncateText(text, containerWidth)}</div>
    </div>
  );
};
