import axios from 'axios';
import { SERVER_URL_CLASSIFICATION_SERVICE, SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../../config';
import { buildMultiParams, prepareSearchParams } from './utils';
import { useAddExternalSourcePropsType } from '../types';
import { BulkCategorizeTransactionsParams } from '../../../../apps/next/src/hooks';
import { Task } from 'schemas';
import { Transaction, WALLET_CHAIN } from '../response.types';

export const getTransactionById = async (data) => {
  const transactionIdsString = buildMultiParams(data.transactionIds, 'transactionIds');
  return axios.get(
    `${SERVER_URL_CORE}/transaction?organizationId=${data.organizationId}${transactionIdsString}&page=${data.page}&userId=${data.userId}&includeSpam=${data.includeSpam}`,
  );
};
export const getTransactionStats = async (params) => {
  const stringifiedParams = prepareSearchParams(params, { defaultSortFilterPropertyName: 'transactionDate' });
  return axios.get(`${SERVER_URL_CORE}/transaction-stats?${stringifiedParams}`);
};

export const patchTransaction = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/transaction?id=${data.transactionId}&organizationId=${data.organizationId}`,
    data.body,
  );

export const postTransactionExternalSources = async (data: useAddExternalSourcePropsType) =>
  axios.post(
    `${SERVER_URL_CORE}/transaction-source?transactionIds=${data.transactionIds}&organizationId=${data.organizationId}&userId=${data.userId}`,
    data.body,
  );

export const bulkRecategorizeTransactions = async (data: BulkCategorizeTransactionsParams) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/recategorize-transactions`, data);
};

export const getLastTransactionsDate = async (data: { organizationId: string }) =>
  axios.get(`${SERVER_URL_CORE}/tx-last-queried?organizationId=${data.organizationId}`);

export const getTransactions = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'transactionDate',
  });
  return axios.get(`${SERVER_URL_CORE}/transaction?${stringifiedParams}`);
};

export const bulkEditTransactionsCostBasis = (data) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-edit-tx-cost-basis`, {
    transactions: data,
  });
};

export const triggerTransactionClassificationJob = (data?) => {
  const params = prepareSearchParams(data);
  return axios.post(`${SERVER_URL_CLASSIFICATION_SERVICE}/produce?${params}`);
};

export const getClassificationTaskStatus = () =>
  axios.get<{ classificationTask?: Task }>(`${SERVER_URL_CLASSIFICATION_SERVICE}/status`);

export const getUnaccountedTransactions = (data: { organizationId: string }) => {
  return axios.get<{ unaccountedTransactions: Transaction[] }>(
    `${SERVER_URL_CORE}/unaccounted-transactions?organizationId=${data.organizationId}`,
  );
};
export type BulkUpdateTransactionMemoParams = {
  transactionIds: string[];
  memo: string; // key of CLASSIFICATION_ROW: INTERNAL_TRANSFER | BRIDGE etc
};
export const bulkUpdateTransactionMemo = (data: BulkUpdateTransactionMemoParams) => {
  return axios.post(`${SERVER_URL_CORE}/bulk-set-transaction-memo`, data);
};

export type GetChainsParams = Partial<{
  legalEntityIds: string[];
  sourceIds: string[];
}>;
export const getChains = (params: GetChainsParams) =>
  axios.get<{ chains: string[] }>(`${SERVER_URL_CORE}/chains?${prepareSearchParams(params, { noExtras: true })}`);
