import React, { memo, useContext, useState } from 'react';
import { TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { mapTableKeyToOriginalKey } from '../../utils';
import { TABLE_TYPE } from '../../../context';
import Table from 'src/components/tables/tanstack-table/Table';
import { journalsColumns } from 'src/components/tables';
import { EditColumns } from 'src/components/tables/table-components';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { DensityDropdown } from 'src/components/tables/table-components';

export const JournalsTable = ({
  displayedJEs,
  searchTerm,
  setSearchTerm,
  isSearching,
  setSortState,
  setShowCreateJournal,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(journalsColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  return (
    <TableWrapper>
      <Table
        onRowClick={(row) => {
          setShowCreateJournal(false);
          openSidebar('journals', { id: row.original._id });
        }}
        columns={columns}
        data={displayedJEs}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        tableType={TABLE_TYPE.ALL_JOURNALS}
        onSortingChange={(state) => {
          if (state.length === 0) return setSortState([]);
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Journals'), desc: state[0].desc }]);
        }}
      >
        <div className='flex items-center justify-between gap-3 px-6 py-5 md:gap-0 w-full'>
          <div className='flex items-center gap-3'>
            <SearchInput
              data-cy='transactions_searchTermInput'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              loading={isSearching}
              placeholder='Search transactions...'
              className='placeholder:text-zinc-500 '
              containerClassName=' min-w-0 '
              resultCount={displayedJEs?.length}
            />
          </div>
          <div className='flex gap-3'>
            <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} />
            <DensityDropdown />
          </div>
        </div>
      </Table>
    </TableWrapper>
  );
};

export default memo(JournalsTable);
