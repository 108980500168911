import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledItem, StyledSubContent, StyledSubTrigger } from './StyledRadixDropdownElements';
import { ArrowRight } from '../../assets/generated/svg';
export const IsImpairedSubMenu = ({ callbackFn }) => {
  return (
    <DropdownMenu.Sub>
      <StyledSubTrigger>
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center py-2'>
            <div>
              <p className='text-[#222222] mb-0'>Is Impaired</p>
            </div>
          </div>
          <ArrowRight width='7' height='11' />
        </div>
      </StyledSubTrigger>
      <StyledSubContent>
        <StyledItem
          onClick={() => {
            callbackFn(1);
          }}
        >
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center py-2'>
              <div className=''>
                <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
              </div>
              <div>
                <p className='text-[#222222] mb-0'>True</p>
              </div>
            </div>
          </div>
        </StyledItem>

        <StyledItem
          onClick={() => {
            callbackFn(0);
          }}
        >
          <div className='flex justify-between items-center w-full'>
            <div className='flex items-center py-2'>
              <div className=''>
                <div className='w-10 h-10 border border-[#F2F2F2] rounded-sm bg-[#F8F8F8] mr-2' />
              </div>
              <div>
                <p className='text-[#222222] mb-0'>False</p>
              </div>
            </div>
          </div>
        </StyledItem>
      </StyledSubContent>
    </DropdownMenu.Sub>
  );
};
