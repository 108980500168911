import { useQuery } from '@tanstack/react-query';
import { getBalanceSheet } from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { AccountingPeriod } from 'services/http/response.types';

export const useBalanceSheet = (
  legalEntityIds?: string[],
  accountingPeriodStartDateUTCs?: string[],
  tagId?: string,
) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.BALANCE_SHEET, { accountingPeriodStartDateUTCs, legalEntityIds, tagId }),
    async () => {
      const response = await getBalanceSheet({
        organizationId,
        legalEntityIds,
        accountingPeriodStartDateUTCs,
        tagId,
      });
      const { balanceSheet } = response.data;
      return balanceSheet;
    },
    {
      enabled: !!organizationId,
    },
  );
};
export const useBalanceSheetForAccountingPeriod = (accountingPeriod?: AccountingPeriod) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.BALANCE_SHEET, { accountingPeriodId: accountingPeriod?._id }),
    async () => {
      const response = await getBalanceSheet({
        organizationId,
        accountingPeriodStartDateUTCs: accountingPeriod ? [accountingPeriod.startDateUTC] : [],
        legalEntityIds: [accountingPeriod?.legalEntityId],
      });
      const { balanceSheet } = response.data;
      return balanceSheet;
    },
    {
      enabled: !!organizationId && !!accountingPeriod?._id,
    },
  );
};
