import { TaskRowProps } from './types';
import { LoaderIcon } from '../loader-icon';
import { HiXMark } from 'react-icons/hi2';
import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { DESCRIPTIVE_LOADER_STATES } from '../descriptive-loader';
import { MdCheckCircle, MdError } from 'react-icons/md';

export const TaskRow = ({ description, isLoading, status, onClose }: TaskRowProps) => {
  const [showToolTip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto-start',
  });

  const icon = useMemo(() => {
    if (status === DESCRIPTIVE_LOADER_STATES.LOADING || isLoading) return <LoaderIcon />;
    if (status === DESCRIPTIVE_LOADER_STATES.SUCCESS)
      return <MdCheckCircle className='w-4 h-4 text-green-100 bg-green-800 rounded-full' />;
    if (status === DESCRIPTIVE_LOADER_STATES.ERROR)
      return <MdError className='w-4 h-4 text-red-100 bg-red-800 rounded-full' />;
  }, [status, isLoading]);

  return (
    <div className='flex items-center justify-between p-4 pr-5 text-left'>
      {icon && (
        <span
          className='ml-1 mr-4'
          style={{
            transform: 'scale(2)',
          }}
        >
          {icon}
        </span>
      )}
      <span className='mr-3.5 flex-grow w-full truncate'>
        <span>
          <span
            ref={setReferenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {description}
          </span>

          {description.length > 20 && showToolTip && (
            <span ref={setPopperElement} style={styles.popper} {...attributes.popper} className='z-40 w-full'>
              <span className='text-xs flex py-1 px-2 shadow rounded-lg bg-black opactity-70 text-white m-2 whitespace-normal break-all'>
                {description}
              </span>
            </span>
          )}
        </span>
      </span>

      {onClose && (
        <button onClick={onClose} className='hover:text-gray-500'>
          <HiXMark className='w-5 h-5' />
        </button>
      )}
    </div>
  );
};
