import { useEffect, useState } from 'react';
import { AppLineChartProps } from './types';
import { LineChart } from '@tremor/react';
import { TremorCard } from '../tremor-card';

export const AppLineChart: React.FC<AppLineChartProps> = ({
  isLoading = false,
  categories,
  data,
  children,
  containerId,
  ...props
}) => {
  const [yAxisWidth, setYAxisWidth] = useState(80);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    const values = data
      .map((item) => Object.values(item).filter((value: any) => typeof value === 'number'))
      .flat() as number[];
    const max = Math.max(...values);
    setMaxValue(max);
    setYAxisWidth(10 + max.toFixed(2).toString().length * 9);
  }, [data]);

  return (
    <div className='px-6 py-6'>
      <TremorCard id={containerId}>
        {children}
        {isLoading ? (
          <div className='h-[300px] animate-pulse bg-gray-50' />
        ) : (
          <LineChart
            {...props}
            categories={categories}
            showLegend={false}
            className='h-[300px]'
            data={data}
            yAxisWidth={yAxisWidth}
            maxValue={maxValue}
          />
        )}
      </TremorCard>
    </div>
  );
};
