import { format } from 'date-fns';
import jwtDecode from 'jwt-decode';

type ObjectType = 'LEGAL_ENTITY' | 'LEDGER_ACCOUNT' | 'WALLET';

declare module 'axios' {
  export interface AxiosRequestConfig {
    excludeAuthorizationHeader?: boolean;
    skipOrganizationId?: boolean;
    forwardOrganizationIdAsArray?: boolean;
    skipEverything?: boolean;
  }
}

export const convertQuery = (queryData: string[], queryName: string) => {
  const result = queryData?.length > 0 ? `&${queryData.map((item) => `${queryName}=${item}`).join('&')}` : '';
  return result;
};

export const unprotectedPaths: string[] = [
  '',
  '/',
  '/login',
  '/sign-up',
  '/onboarding/new/create-account',
  '/onboarding/new/short-code',
  '/forgot-password',
  '/reset-password',
  '/slice-simulator',
  '/landing',
  '/about',
  '/profile/accept-organization-invite-existing-user',
  '/profile/accept-organization-invite',
  '/reset-password',
  '/change-workspace',
  '/onboarding/new/create-account',
  '/onboarding/new/payment/success',
  '/onboarding/new/payment/cancel',
  '/via-email/onboarding',
];

export const isSessionTokenValid = (token: string) => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    if (!decoded) throw new Error('Could not parse jwt');
    if (Date.now() / 1000 >= decoded.exp) throw new Error('jwt expired');
    return true;
  } catch (error) {
    console.error('Session token is invalid:', error);
    return false;
  }
};


export const formatDateForQuery = (date: Date) => format(date, 'yyyy-MM-dd');

export function formatExchangeTypeToName(str?: string) {
  return str
    ? str
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    : '';
}

export const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    const subdomain = hostname.split('.')[0];
    if (subdomain === 'com') {
      return 'entendre';
    }
    return subdomain;
  }
  return;
};

export const formatDate = (date?: Date, showFullDate = false) => {
  if (!date) return 'Invalid date';

  if (!showFullDate && date.getFullYear() === new Date().getFullYear()) {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    });
  } else {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

export const transformGptInstruction = (instruction: string, selectedIntent: string | undefined | null) => {
  const regex = new RegExp(/@\[(.*?)\]\((.*?)\)/g);
  let mentionedObject: ObjectType | undefined;
  instruction?.match(regex)?.forEach((match) => {
    // find object type
    if (match.includes('WALLET_')) {
      mentionedObject = 'WALLET';
      instruction = instruction.replace('WALLET_', '');
    } else if (match.includes('LEDGER_ACCOUNT_')) {
      mentionedObject = 'LEDGER_ACCOUNT';
      instruction = instruction.replace('LEDGER_ACCOUNT_', '');
    } else if (match.includes('LEGAL_ENTITY_')) {
      mentionedObject = 'LEGAL_ENTITY';
      instruction = instruction.replace('LEGAL_ENTITY_', '');
    }
  });

  // if selected intent is of CRUD type, append the intent to the instruction
  if (selectedIntent) {
    const intent = selectedIntent.toLowerCase();
    if (intent.includes('create') || intent.includes('update') || intent.includes('delete')) {
      const rawInstruction = instruction.replace(regex, '$1');
      return {
        instructionWithMarkup: `${intent} ${instruction}`,
        rawInstruction: `${rawInstruction}`,
        mentionedObject,
      };
    }
  }

  const rawInstruction = instruction.replace(regex, '$1');

  return {
    instructionWithMarkup: instruction,
    rawInstruction,
    mentionedObject,
  };
};
