import { useQuery } from '@tanstack/react-query';
import { JOB_NAME, UseJobProgressPayload, getJobProgress } from 'services/http/job-queue';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useJobProgress = (
  payload: UseJobProgressPayload,
  options: { enabled: boolean; forUseRulesetJobProgress?: boolean },
) => {
  const { jobName, referenceId, organizationId } = payload;
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOB_PROGRESS, {
      jobName,
      referenceId,
      forUseRulesetJobProgress: options.forUseRulesetJobProgress,
    }),
    async () => {
      const response = await getJobProgress(payload);
      return response.data?.jobConfiguration ?? null;
    },
    {
      enabled: !!jobName && !!referenceId && !!organizationId && options.enabled,
      refetchInterval: 5000,
    },
  );
};

export const useJobConfigurationForRulesetJob = (rulesetId = '') => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOB_CONFIGURATION_FOR_RULESET_JOB, { rulesetId }),
    async () => {
      const response = await getJobProgress({ referenceId: rulesetId, jobName: JOB_NAME.RULESET_JOB, organizationId });
      return response.data?.jobConfiguration ?? null;
    },
    {
      enabled: !!rulesetId && rulesetId?.length !== 0 && !!organizationId,
      refetchInterval: 10 * 60 * 1000,
    },
  );
};
