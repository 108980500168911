import { InputLabel, SingleSelectMenu } from 'ui';
import { useLedgerAccount } from '../../hooks';
import { getDisplayedLedgerAccounts, mergeLedgerAccounts } from '../templates/utils';

export const PickLedgerAccount = ({
  value,
  onChange,
  dataCy = '',
}: {
  value?: string;
  onChange: (value: string) => void;
  dataCy?: string;
}) => {
  const { data: templatePages, isLoading: isLoadingLedgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });

  const merged = mergeLedgerAccounts(templatePages);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(merged);

  return (
    <div>
      <InputLabel heading='Ledger account' />

      <SingleSelectMenu
        fullWidth
        isOnSidepanel
        isModal={false}
        data-cy={`${dataCy}__ledgerAccount`}
        placeholder='Select ledger account'
        options={displayedLedgerAccounts ?? []}
        onChange={({ value }) => onChange(value)}
        value={displayedLedgerAccounts.find((item) => item.value === value)}
        isLoading={isLoadingLedgerAccounts}
        onClearValue={() => onChange('')}
      />
    </div>
  );
};
