import toast from 'react-hot-toast';
import { useInvalidateQuery, usePatchOrganizationById, useSession } from '../../hooks';
import { deriveError } from '../templates/utils';

export const useUpdateOrganizationTimezone = () => {
  const { organizationId, setters, selectedOrganization } = useSession();
  const { mutateAsync: patchOrganization, isLoading } = usePatchOrganizationById();
  const { invalidateOrganizations } = useInvalidateQuery();

  const updateTimezone = async (timezone?: string) => {
    if (!timezone) return;
    if (isLoading) return;
    if (timezone === selectedOrganization?.timezone) return;
    let toastId;
    try {
      toastId = toast.loading('Saving timezone setting');
      await patchOrganization({
        organizationId,
        update: { timezone },
      });

      await invalidateOrganizations();
      setters.setSelectedOrganization((prev) => (prev ? { ...prev, timezone } : undefined));

      toast.success(`Timezone updated`, { id: toastId });
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  return updateTimezone;
};
