export const SCHEDULE_CADENCES = [
  {
    label: 'Every 15 minutes',
    value: '*/15 * * * *',
  },
  {
    label: 'Every day at 2pm',
    value: '0 14 * * *',
  },
  {
    label: 'Weekly on sunday',
    value: '0 0 * * 0',
  },
  {
    label: '1st of the month',
    value: '0 0 1 * *',
  },
  {
    label: '1st and 15th of the month',
    value: '0 0 1,15 * *',
  },
  {
    label: 'Monthly on the last day of the month at midnight',
    value: '0 0 28-31 * *',
  },
  {
    label: 'At 00:00 on day-of-month 1 in every 3rd month.',
    value: '0 0 1 */3 *',
  },
];
