import toast from 'react-hot-toast';
import { deriveError } from '../../components/templates/utils';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getClosingPositions } from 'services';
import { GetFinanceReportsQueryFilter } from './type';

export const useGetClosingPositions = (params?: GetFinanceReportsQueryFilter) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.CLOSINGPOSITIONS, params),
    async ({ pageParam = 0 }) => {
      const response = await getClosingPositions({
        organizationId,
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'dateReceived', desc: true },
      });
      return response.data.assets;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};
