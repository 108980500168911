import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';

export type RelativePriceTimeSeriesPoint = {
  price: string;
  timestamp: number | string;
};

export type AssetRelativePriceData = {
  data: {
    timeSeriesPrices: RelativePriceTimeSeriesPoint[];
    assetType: string;
    transactionId: string;
    transactionDate: Date;
    fiveDaysPercentageChange: number;
  };
};

export type AssetBreakdownData = {
  data: Record<string, { quantity: string; value: string; assetType: string }>;
};

export type GetAssetHistoricalPriceParams = {
  assetType: string;
  startDate: Date;
  endDate: Date;
};

export type PricePoint = {
  price: string;
  timestamp: Date;
};

export type AssetHistoricalPriceData = {
  timeSeriesPrices: PricePoint[];
  assetType: string;
  startDate: Date;
  endDate: Date;
};

export type AssetHistoricalPriceResponse = {
  data: AssetHistoricalPriceData;
};

export const getHistoricalAssetPrice = async (data: { assetType: string; startDate: Date; endDate: Date }) => {
  const { assetType, startDate, endDate } = data;

  const stringQuery = prepareSearchParams({ assetType, startDate, endDate }, { isPaginated: false });

  return axios.get<AssetHistoricalPriceResponse>(`${SERVER_URL_ANALYTICS}/get-historical-asset-price?${stringQuery}`);
};
export const getTransactionAssetRelativePrices = async (data: { transactionId: string }) => {
  const stringQuery = prepareSearchParams(data);

  return axios.get<AssetRelativePriceData>(
    `${SERVER_URL_ANALYTICS}/get-transaction-asset-relative-price?${stringQuery}`,
  );
};
export type GetAssetBreakdownParams = {
  assetTypes?: string[];
  legalEntityIds?: string[];
  accountingPeriodStartDateUTCs?: string[];
  targetDate?: string;
  startDate?: string;
  endDate?: string;
};
export const getAssetBreakdown = async (data: GetAssetBreakdownParams) => {
  const stringQuery = prepareSearchParams(data);

  return axios.get<AssetBreakdownData>(`${SERVER_URL_ANALYTICS}/get-asset-breakdown?${stringQuery}`);
};
