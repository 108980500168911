import { addDays, isValid, subDays, subMonths } from 'date-fns';
import { DatePickerSelection, DatePickerSelectionInLocalStorage } from './DatePickerComp.types';

export const convertDatePickerSelectionsFromLocalStorage = (
  selections?: DatePickerSelectionInLocalStorage[],
): DatePickerSelection[] =>
  selections?.map((selection) => {
    const startDate = getDateFromString(selection?.startDate);
    const endDate = getDateFromString(selection?.endDate);
    return {
      startDate,
      endDate,
      key: selection.key,
    };
  }) ?? [];

export const getPreviousDateISOString = (dateString?: string) =>
  subDays(dateString ? new Date(dateString) : new Date(), 1).toISOString();
export const getNextDateISOString = (dateString?: string) =>
  addDays(dateString ? new Date(dateString) : new Date(), 1).toISOString();

export const renderDate = (date?: Date, defaultLabel?: string) =>
  isValid(date) ? date?.toDateString().slice(4) : defaultLabel;
export const getDateFromString = (string?: string) => {
  if (!string) return undefined;
  try {
    return new Date(string);
  } catch {
    /* empty */
  }
};

export const getDateSelectionHelpers = (
  setDateSelections: React.Dispatch<React.SetStateAction<DatePickerSelectionInLocalStorage[]>>,
) => ({
  onResetDateSelections: () => setDateSelections([{ key: 'selection' }]),
  onStartDateDayDcr: () =>
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          startDate: getPreviousDateISOString(selection?.startDate),
        },
      ];
    }),
  onStartDateDayIncr: () =>
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          startDate: getNextDateISOString(selection?.startDate),
        },
      ];
    }),
  onEndDateDayDcr: () =>
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          endDate: getPreviousDateISOString(selection?.endDate),
        },
      ];
    }),
  onEndDateDayIncr: () =>
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        Object.assign({ key: 'selection' }, selection, {
          endDate: getNextDateISOString(selection?.endDate),
        }),
      ];
    }),
  onLastXMonths: (numberOfMonths: number) =>
    setDateSelections([
      {
        startDate: subMonths(new Date(), numberOfMonths).toISOString(),
        endDate: new Date().toISOString(),
        key: 'selection',
      },
    ]),
  onDateRangeChange: (item) => setDateSelections([item.selection]),
});
