export const enum BG_JOB_STATE {
  PENDING_CREATE = 'pending-create',
  BEING_CREATED = 'being-created',
  COULD_NOT_BE_CREATED = 'could-not-be-created',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAILURE = 'failure',
  CANCELLED = 'cancelled',
}
