import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledItem, StyledSubContent, StyledSubTrigger } from './StyledRadixDropdownElements';
import { MdChevronRight, MdCancel } from 'react-icons/md';
import { Virtuoso } from 'react-virtuoso';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { InputWithExtras } from 'ui';
import { SUPPORTED_CHAINS } from '../../constants/Chains';
import { CryptoIcon } from '../utils';

export const ChainSubMenu = ({ callbackFn }) => {
  const [filter, setFilter] = useState('');

  return (
    <DropdownMenu.Sub defaultOpen>
      <StyledSubTrigger>
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center py-2'>
            <div>
              <p className='text-[#222222] mb-0'>Chains</p>
            </div>
          </div>
          <MdChevronRight width='7' height='11' />
        </div>
      </StyledSubTrigger>

      <StyledSubContent className='min-h-[250px] min-w-[300px] max-w-[300px] overflow-auto'>
        <div className='m-4'>
          <InputWithExtras
            containerClassName='!w-full'
            value={filter}
            trailing={<MdCancel className='w-5 h-5' onClick={() => setFilter('')} />}
            placeholder='Search Chains'
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
        </div>

        <ScrollArea.Root>
          <ScrollArea.Viewport className='overflow-none'>
            <Virtuoso
              style={{ height: 200 }}
              data={
                filter
                  ? SUPPORTED_CHAINS.filter((chain) => chain.toLowerCase().includes(filter.toLowerCase()))
                  : SUPPORTED_CHAINS
              }
              itemContent={(index, value) => (
                <StyledItem onClick={() => callbackFn(value)} key={value + index}>
                  <div className='flex items-center py-2'>
                    <div className='mr-2 w-8'>
                      <CryptoIcon symbol={value} />
                    </div>
                    <div>
                      <p className='text-[#222222] mb-0'>{value.toUpperCase()}</p>
                    </div>
                  </div>
                </StyledItem>
              )}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation='vertical'>
            <ScrollArea.Thumb />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </StyledSubContent>
    </DropdownMenu.Sub>
  );
};
