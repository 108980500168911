import axios from 'axios';
import { prepareSearchParams } from './utils';
import { SERVER_URL_CORE, SERVER_URL_IMPAIRMENT_ENGINE, SERVER_URL_JOB_QUEUE } from '../../config';
import { Asset } from 'schemas';

export const getAssets = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'dateReceived',
  });
  return axios.get(`${SERVER_URL_CORE}/asset?${stringifiedParams}`);
};

export const getAssetsV2 = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'dateReceived',
  });
  return axios.get(`${SERVER_URL_CORE}/assetV2?${stringifiedParams}`);
};

export const createAsset = async (data) => {
  return axios.post(`${SERVER_URL_CORE}/asset?organizationId=${data.organizationId}`, data);
};

export const manualAssetImpairment = (data) => {
  return axios.post(
    `${SERVER_URL_IMPAIRMENT_ENGINE}/manual-asset-impairment?organizationId=${data.organizationId}`,
    data,
  );
};

export const getAssetMetrics = async (
  params,
): Promise<{
  data: {
    assetsByQuantity: Record<string, string>;
    assetsByCostBasis: Record<string, string>;
    assetCount: string;
    impairedAmount: string;
    assetValue: string;
    gainAmount: string;
    lossAmount: string;
  };
}> => {
  const stringifiedParams = prepareSearchParams(params);

  return axios.get(`${SERVER_URL_CORE}/get-asset-metrics?${stringifiedParams}`);
};

export const getAssetTypes = async (params: { includeSpam?: boolean; spamOnly?: boolean } = {}) => {
  return axios.get<{ assetTypes: string[] }>(
    `${SERVER_URL_CORE}/asset-types?${prepareSearchParams(params, { noExtras: true })}`,
  );
};

export const deleteAsset = async (assetId: string) => axios.delete(`${SERVER_URL_CORE}/asset/${assetId}`);
export const patchAsset = async (assetId: string, update: Partial<Asset>) =>
  axios.patch(`${SERVER_URL_CORE}/asset/${assetId}`, update);

export const produceAssetReconstructionJob = (data: { organizationId: string }) => {
  return axios.post(`${SERVER_URL_JOB_QUEUE}/produce-reconstruct-assets-job?organizationId=${data.organizationId}`, {});
};
