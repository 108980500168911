//import { useCommandPallete } from './useCommandPallete';

import { GPTServiceIntentEnum } from 'services';
import { AssistantsConversationInteractionHistory } from 'schemas';
import { GPTAnalyticsContainer } from './GPTAnalyticsContainer';
import { ResponseContainer } from './ResponseContainer';
import { FunctionCallJournalsTable, tableToFunctionMap } from './FunctionCallTables';
import { getDataFromConversation } from './utils';
import { DetailsCard } from './DetailsCard';
import { AiTransactionsTable } from 'ai-ui';

export const GPTFeedback: React.FC<{ interactionHistory: AssistantsConversationInteractionHistory }> = ({
  interactionHistory,
}) => {
  //const { setGptChatResponses } = useCommandPallete();
  if (!interactionHistory) return <></>;
  const { instruction: gptQuery } = interactionHistory;
  console.log(`The intent is: ${interactionHistory.intent}`);
  switch (interactionHistory.intent) {
    case GPTServiceIntentEnum.LEGAL_ENTITY_RENAME:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_TYPE:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_CURRENCY:
    case GPTServiceIntentEnum.LEGAL_ENTITY_EDIT_ADDRESS: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          {/*   <ResponseContainer>
           <DetailsCard
              number={1}
              title={entityName}
              subtitle={addressString}
              onClick={() => {
                navigate(`${getHost()}/configure/entities/${_id}`);
                clearGPTResponseAndToggleKBar();
              }}
            /> 
          </ResponseContainer> */}
        </>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_RENAME:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_TYPE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_CLEARING:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_PARENT: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          {/*<ResponseContainer>
            <DetailsCard
              number={1}
              title={`${ledgerAccountSequence}: ${ledgerAccountName}`}
              subtitle={ledgerAccountType}
              onClick={() => {
                navigate(`${getHost()}/configure/ledger-accounts/${_id}`);
                clearGPTResponseAndToggleKBar();
              }}
            />
          </ResponseContainer> */}
        </>
      );
    }
    case GPTServiceIntentEnum.WALLET_EDIT_ADDRESS:
    case GPTServiceIntentEnum.WALLET_EDIT_ALIAS:
    case GPTServiceIntentEnum.WALLET_EDIT_STATUS:
    case GPTServiceIntentEnum.WALLET_EDIT_LEGAL_ENTITY: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.WALLET_ADD_TAG: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
        </>
      );
    }

    case GPTServiceIntentEnum.TAG_CREATE:
    case GPTServiceIntentEnum.TAG_EDIT_VALUE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.ASSET_CREATE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.LEGAL_ENTITY_CREATE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.WALLET_CREATE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Success</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_EDIT_SEQUENCE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_CREATE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Success</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.WALLET_DELETE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Wallet successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE_PARENT:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_DELETE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Ledger account successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.TAG_DELETE: {
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Tag successfully deleted.</ResponseContainer>
        </>
      );
    }
    case GPTServiceIntentEnum.SPAM_ASSISTANT:
    case GPTServiceIntentEnum.GL_ASSISTANT:
    case GPTServiceIntentEnum.AIO_ASSISTANT:
    case GPTServiceIntentEnum.RECONCILE_BALANCE: {
      if (!interactionHistory)
        return (
          <>
            <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
            <ResponseContainer>Sorry I could not understand the instruction.</ResponseContainer>
          </>
        );
      const { message, functionName, outputs } = getDataFromConversation(interactionHistory);
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>{message}</ResponseContainer>
          {outputs.map((output: any, index) => {
            const processOutput = (output: any) => {
              // If output is an array, recursively process each element
              if (Array.isArray(output)) {
                return output.map((innerOutput) => processOutput(innerOutput));
              } else {
                if (!output) return <></>;
                // Original logic for processing an object
                const validNameKeys = ['ledgerAccountName', 'name', 'entityName', 'runningBalance'];
                const validSequenceKeys = ['ledgerAccountSequence', 'journalSequenceNumber', 'sequenceNumber'];
                const validTypeKeys = ['ledgerAccountType', 'walletType', 'assetType'];

                const nameKey = validNameKeys.find((key) => output[key]);
                const sequenceKey = validSequenceKeys.find((key) => output[key]);
                const typeKey = validTypeKeys.find((key) => output[key]);

                // Check if output is a tag
                if (output.entry && output.usageCount !== undefined && output.status) {
                  // Process tag specific logic here
                  const tagTitle = `Tag: ${output.entry.key} - ${output.entry.value}`;
                  const tagSubtitle = `Status: ${output.status}, Usage: ${output.usageCount}`;
                  // Return the DetailsCard component for this tag
                  return <DetailsCard key={output._id} title={tagTitle} subtitle={tagSubtitle} />;
                }

                const name = nameKey ? output[nameKey] : undefined;
                const sequence = sequenceKey ? output[sequenceKey] + ' ' : '';
                const type = typeKey ? output[typeKey] : undefined;

                console.log('name:', name, 'sequence:', sequence, 'type:', type);
                // Return the DetailsCard component for this output
                if (!name || name === '') return <> </>;
                return (
                  <ResponseContainer>
                    <DetailsCard title={sequence + name} subtitle={type} number={index + 1} />
                  </ResponseContainer>
                );
              }
            };
            // Call processOutput for the current output
            return processOutput(output);
          })}
          {functionName === 'query_transactions' && <AiTransactionsTable transactions={outputs} />}
          {tableToFunctionMap[functionName] && <FunctionCallJournalsTable outputs={outputs} />}
        </>
      );
    }
    case GPTServiceIntentEnum.JOURNALS_ASSISTANT:
    case GPTServiceIntentEnum.OPERATIONAL_TRANSACTIONS_ASSISTANT: {
      let message = '';
      if (interactionHistory) {
        message = getDataFromConversation(interactionHistory).message;
        if (message?.length === 0) {
          message = 'Here is your chart for the query: ' + gptQuery;
        }
      } else {
        message = 'Here is your chart for the query: ' + gptQuery;
      }
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>{message}</ResponseContainer>
          <GPTAnalyticsContainer interactionHistory={interactionHistory} />
        </>
      );
    }
    case GPTServiceIntentEnum.INVALID_INTENT:
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Sorry I could not understand the instruction.</ResponseContainer>
        </>
      );
    case GPTServiceIntentEnum.JOURNAL_ENTRY_CREATE_BY_TEMPLATE:
    case GPTServiceIntentEnum.LEDGER_ACCOUNT_AGENT:
    default:
      // VIEW FOR UNSUPPORTED INTENT BUT SUCCESSFUL RESPONSE
      return (
        <>
          <ResponseContainer query={gptQuery}>{gptQuery}</ResponseContainer>
          <ResponseContainer>Task was successfully executed.</ResponseContainer>
        </>
      );
  }
};
