import * as React from 'react';
import { forwardRef } from 'react';
const SvgSap = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <rect width={40} height={40} rx={20} fill='url(#SAP_svg__a)' />
    <defs>
      <linearGradient id='SAP_svg__a' x1={20} y1={0} x2={15.357} y2={31.786} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#00A9EB' />
        <stop offset={1} stopColor='#0067B4' />
      </linearGradient>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgSap);
export default ForwardRef;
