import { InputLabel, SingleSelectMenu } from 'ui';
import { useJournalEntryTemplates } from '../../hooks';
import { getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../templates/utils';

export const PickJournalEntryTemplateFE = ({
  value,
  onChange,
}: {
  value?: string;
  onChange: (value: string) => void;
}) => {
  const { data: templatePages, isLoading: isLoadingTemplates } = useJournalEntryTemplates({
    pageSize: 1000,
  });

  const merged = mergeJournalEntryTemplates(templatePages);
  const displayedTemplates = getDisplayedJournalEntryTemplates(merged);

  return (
    <div>
      <InputLabel heading='Journal entry template' />
      <SingleSelectMenu
        fullWidth
        isOnSidepanel
        isLoading={isLoadingTemplates}
        options={displayedTemplates}
        onChange={({ value }) => onChange(value)}
        value={displayedTemplates.find((template) => template.value === value)}
        placeholder='Select Journal Entry Template'
      />
    </div>
  );
};
