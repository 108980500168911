import { useRouter } from 'next/router';
import { useSession } from './useSession';
import { useCallback } from 'react';
import { getOverviewData } from 'services';

export const useRedirectToSubscriptionIfNoTransactions = () => {
  const { organizationId } = useSession();
  const router = useRouter();

  const redirect = useCallback(async () => {
    try {
      const response = await getOverviewData({
        organizationId,
      });
      const { overviewData } = response.data;
      if (overviewData.transactionsCount === 0) {
        router.push({ pathname: `/onboarding/new/subscription`, query: router.query });
      } else {
        router.push({ pathname: `/onboarding/new/transaction-backfill`, query: router.query });
      }
    } catch (error) {
      console.log('Could not redirect based on overview data, redirecting to subscription page', error);
      router.push({ pathname: `/onboarding/new/subscription`, query: router.query });
    }
  }, [organizationId]);

  return redirect;
};
