import { useContext, useEffect } from 'react';
import { MainDirectoryContext } from '../../../context';
import { useJournalQueryParams } from './use-journal-query-params';
import { useJournalEntries } from '../../../hooks';
import { useDisplayedJEs } from './utils';
import JournalsTable from './JournalsTable';

export const JournalsTableWithQuery = ({
  sortState,
  setSortState,
  searchTerm,
  setSearchTerm,
  isSearching,
  setIsSearching,
  setShowCreateJournal,
}) => {
  const { setMainDirectoryIds } = useContext(MainDirectoryContext);

  const journalParams = useJournalQueryParams({ sortState, searchTerm });
  const {
    data: journalEntriesIQData,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
  } = useJournalEntries(journalParams);

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);

  const displayedJEs = useDisplayedJEs(journalEntriesIQData);
  useEffect(() => {
    const journalIds = displayedJEs.map((je: { _id: any }) => je._id);
    if (journalIds) {
      setMainDirectoryIds(journalIds);
    }
  }, [displayedJEs]);
  return (
    <JournalsTable
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      isSearching={isSearching}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setShowCreateJournal={setShowCreateJournal}
      setSortState={setSortState}
      displayedJEs={displayedJEs}
      fetchNextPage={fetchNextPage}
    />
  );
};
