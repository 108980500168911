import { useEffect, useState } from 'react';
import { Tag } from 'services/http/response.types';
import { useTags } from './useTags';

export const useAllTags = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const { data, isLoading } = useTags();

  useEffect(() => {
    if (!isLoading && data) setTags(data.pages.flat());
  }, [data, isLoading]);

  return { tags, isLoading };
};
