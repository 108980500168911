import React, { useState } from 'react';
import { deriveError } from '../templates/utils';
import { useCreateSource } from '../../hooks/http';
import { Button, DescriptiveLoader } from 'ui';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { WALLET_CHAIN, WALLET_TYPE } from 'services/http/response.types';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Sidebar, SidebarBody, SidebarFooter, SidebarRoot, SidebarTopBar } from '../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { useInvalidateQuery } from '../../hooks';
import { useCreateImportTransactionsBgJob } from '../../hooks/useCreateImportTransactionsBgJob';
import { CREATE_SOURCE_PROCESS_STATE, getAddWalletProcessStateDescriptorProps } from '../process-states';
import { CreateSourceFormElements, CreateSourceFormState, isCreateSourceFormStateValid } from '../form-elements';
import { JOB_NAME, importNFTs } from 'services/http';
import { TrackJobInSidebar } from '../job-progress';
import { useTaskManager } from '../../context/task-manager-context';

// apps/next/src/components/Sources/constants.tsx , add new chain to dropdown
// packages/services/http/response.types.ts , add new chain to filters

const defaultFormState = {
  walletType: WALLET_TYPE.INTERNAL,
  name: '',
  address: '',
  chain: WALLET_CHAIN.ETHEREUM,
  addresses: [],
  tags: [],
};

export const useCreateSourceWithTransactionImportJob = () => {
  const { userId, organizationId } = useSession();
  const { mutateAsync: addWallet, isLoading: isAddingWallet, data } = useCreateSource();
  const [step, setStep] = useState<CREATE_SOURCE_PROCESS_STATE>(CREATE_SOURCE_PROCESS_STATE.FORM);

  const { createJob } = useCreateImportTransactionsBgJob();
  const [referenceId, setReferenceId] = useState<string>();
  const { invalidateWallets } = useInvalidateQuery();

  const createSourceWithTransactionImportJob = async (formState: CreateSourceFormState) => {
    if (!userId || !organizationId) {
      // console.log(
      //   'useCreateSourceWithTransactionImportJob: createSourceWithTransactionImportJob: called before userId or organizationId were initialized',
      // );
      return toast.error('Please try again after some time.');
    }

    if (isCreateSourceFormStateValid(formState))
      try {
        setStep(CREATE_SOURCE_PROCESS_STATE.CREATING_SOURCE);
        const createWalletResponse = await addWallet({
          wallet: {
            ...formState,
            legalEntityId: formState.legalEntityId as string,
            organizationId,
            userId,
          },
        });

        if (!createWalletResponse?.data?.wallet?._id) throw new Error('wallet id missing in create_source response');
        try {
          if (formState.walletType === WALLET_TYPE.INTERNAL && formState.importStartDate) {
            try {
              importNFTs({
                walletId: createWalletResponse?.data?.wallet?._id,
                organizationId: createWalletResponse?.data?.wallet?.organizationId,
              });
            } catch (error) {
              console.error(error);
            }
            setStep(CREATE_SOURCE_PROCESS_STATE.CREATING_PULL_TRANSACTIONS_BG_JOB);
            setReferenceId(createWalletResponse.data.wallet._id);
            await createJob(createWalletResponse.data.wallet, formState.importStartDate);
            setStep(CREATE_SOURCE_PROCESS_STATE.PULLING_TRANSACTIONS);
          } else {
            setStep(CREATE_SOURCE_PROCESS_STATE.EXTERNAL_SOURCE_CREATED);
          }
        } catch (error) {
          return setStep(CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_PULL_TRANSACTIONS_BG_JOB);
        }

        await invalidateWallets();
      } catch (error) {
        setStep(CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE);
        console.log('CreateWalletForm error:', error);
        throw error;
      }
  };

  return {
    createSourceWithTransactionImportJob,
    processState: step,
    setProcessState: setStep,
    isCreatingSource: isAddingWallet,
    referenceId,
    data,
  };
};

export function CreateSourceSidebar({ onClose }) {
  const [formState, setFormState] = useState<CreateSourceFormState>(defaultFormState);

  const [error, setError] = useState<string>();
  const { passJobToTaskManager } = useTaskManager();

  const { createSourceWithTransactionImportJob, processState, setProcessState, isCreatingSource, referenceId } =
    useCreateSourceWithTransactionImportJob();

  const onFormSubmit = async () => {
    try {
      await createSourceWithTransactionImportJob(formState);
    } catch (e) {
      console.log('CreateWalletForm error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };

  const onCloseClick = () => {
    if (referenceId) passJobToTaskManager(referenceId, JOB_NAME.IMPORT_TRANSACTION_JOB);
    if (onClose) onClose();
    setFormState(defaultFormState);
  };

  const dataCy = 'createSource';
  return (
    <SidebarRoot>
      <Sidebar data-cy={dataCy} rootId='create-sources-sidebar'>
        <SidebarTopBar onClose={onCloseClick} testId='create-sources-sidebar-header' />
        <SidebarHeader data-cy={dataCy} title={'Create source'} />
        <SidebarBody>
          {processState === CREATE_SOURCE_PROCESS_STATE.FORM ? (
            <>
              <SidebarSectionHeader title='Details' />
              <SidebarSection numberOfColumns={1}>
                <CreateSourceFormElements data-cy={dataCy} formState={formState} setFormState={setFormState} />
              </SidebarSection>
            </>
          ) : processState === CREATE_SOURCE_PROCESS_STATE.PULLING_TRANSACTIONS ? (
            <TrackJobInSidebar jobName={JOB_NAME.IMPORT_TRANSACTION_JOB} referenceId={referenceId} error={error} />
          ) : (
            <div className='h-[300px] w-full flex items-center justify-center'>
              <DescriptiveLoader {...getAddWalletProcessStateDescriptorProps(processState, error)} data-cy={dataCy} />
            </div>
          )}
        </SidebarBody>
        {processState === CREATE_SOURCE_PROCESS_STATE.FORM ? (
          <SidebarFooter
            primaryBtn={
              <Button
                onClick={onFormSubmit}
                isLoading={isCreatingSource}
                label={'Create source'}
                data-cy={`${dataCy}__createSourceButton`}
              />
            }
            secondaryBtn={
              <Button onClick={onCloseClick} emphasis='medium' label='Cancel' data-cy={`${dataCy}__cancelButton`} />
            }
          />
        ) : (
          <SidebarFooter
            primaryBtn={
              <Button
                label='Done'
                emphasis='high'
                disabled={[
                  CREATE_SOURCE_PROCESS_STATE.CREATING_SOURCE,
                  CREATE_SOURCE_PROCESS_STATE.CREATING_PULL_TRANSACTIONS_BG_JOB,
                ].includes(processState)}
                onClick={onCloseClick}
                data-cy={dataCy}
              />
            }
            secondaryBtn={
              <Button
                label={
                  processState === CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE ? 'Back' : 'Add another source'
                }
                emphasis='medium'
                onClick={() => {
                  if (processState !== CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE)
                    setFormState(defaultFormState);
                  setProcessState(CREATE_SOURCE_PROCESS_STATE.FORM);
                }}
              />
            }
          />
        )}
      </Sidebar>
    </SidebarRoot>
  );
}
