import { getTableGroups, saveTableGroups, TableGroups } from 'services';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useGetTableGroups = (tableName: string = '') => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.TABLE_GROUPS, tableName),
    async () => {
      const response = await getTableGroups(tableName);
      return response.data.tableGroups;
    },
    {
      enabled: !!tableName && !!organizationId,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 5 * 60,
    },
  );
};

export type useSaveTableGroupsPayload = {
  tableGroups: Partial<TableGroups>;
};

// export const useSaveTableGroups = () => {
//   return useMutation((data: useSaveTableGroupsPayload) => saveTableGroups(data));
// };

// Optmisitic updates with tanstack query: https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#updating-a-list-of-todos-when-adding-a-new-todo
export const useUpdateTableGroups = (tableName: string = '') => {
  const queryClient = useQueryClient();
  const getKey = useOrgBasedQueryKey();
  const queryKey = getKey(QUERY_KEY.TABLE_GROUPS, tableName);

  return useMutation({
    mutationFn: saveTableGroups,
    // When mutate is called:
    onMutate: async (newData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)

      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousGroups = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (old?: TableGroups) => {
        if (!old) return old;

        const next = { ...old };
        next.groups = next.groups.map((group) =>
          Object.assign(
            {},
            group,
            newData.groups.find((g) => g._id === group._id),
          ),
        );
        return next;
      });

      // Return a context object with the snapshotted value
      return { previousGroups };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newGroups, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
