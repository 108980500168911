import { useEffect, useState } from 'react';
import { useConditionSelectorContext } from '../condition-selector-context';
import { ConditionSelectorRowData, ConvertToRowFn, FilterFn } from '../types';
import { appendIsSelectedOnRowsFn } from './append-is-selected-on-row-fn';
import { defaultConverToRowFn } from './default-convert-to-row-fn';

export const useFilteredConditions = <T = any>(
  allConditions: T[],
  filterfn: FilterFn<T>,
  convertToRowFn?: ConvertToRowFn<T>,
  skipFn?: (values: T[]) => T[],
) => {
  const { state, debouncedSearchTerm } = useConditionSelectorContext();

  const [conditionsData, setConditionsData] = useState<ConditionSelectorRowData[]>([]);

  useEffect(() => {
    const selectedValue = state.selected.value;
    const filter = filterfn(debouncedSearchTerm);
    const appendIsSelected = appendIsSelectedOnRowsFn(selectedValue);

    let filtered = allConditions.filter(filter);
    if (skipFn) filtered = skipFn(filtered);
    const convertFn = convertToRowFn ?? defaultConverToRowFn;
    const converted = filtered.map(convertFn);

    const rows = appendIsSelected(converted);
    // console.log({ allConditions, rows });

    setConditionsData(rows);
  }, [debouncedSearchTerm, allConditions, state.selected.value]);

  // useEffect(() => {
  //   console.log('in useFilteredCondition', conditionsData);
  // }, [conditionsData]);

  return conditionsData;
};
