import React, { useState } from 'react';
import SwitchGroup from '../atoms/SwitchGroup';
import { InputLabel, Button } from 'ui';
import { RunAccountPostingRuleSetsHandlerPayload } from 'services';
import { LegalEntityMultiSelectMenu, AccountingPeriodStartDatesMultiSelectMenu } from '../menu';

interface RunAllRulesetsDialogFormProps {
  onClose: () => void;
  organizationId: string;
  onSubmit: (params: Omit<RunAccountPostingRuleSetsHandlerPayload, 'ruleSetId'>) => void;
}

interface FormState {
  legalEntityIds: string[];
  accountingPeriodStartDate?: Date;
  processTransactionsWithNoAccounting: boolean;
  overWrite: boolean;
}

const defaultFormState: FormState = {
  legalEntityIds: [],
  processTransactionsWithNoAccounting: false,
  overWrite: false,
};

export const RunAllRulesetsDialogForm = ({ onClose, onSubmit }: RunAllRulesetsDialogFormProps) => {
  const [form, setForm] = useState<FormState>(defaultFormState);
  const { legalEntityIds, accountingPeriodStartDate, processTransactionsWithNoAccounting, overWrite } = form;

  const [selectedLegalEntities, setSelectedLegalEntities] = useState<any[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<any[]>([]);

  const runRule = () =>
    onSubmit({
      legalEntityIds,
      accountingPeriodStartDateUTCs: accountingPeriodStartDate
        ? [new Date(accountingPeriodStartDate).toISOString()]
        : [],
      processTransactionsWithNoAccounting,
      overWrite,
    });

  const onCloseClick = () => {
    setForm(defaultFormState);
    onClose();
  };

  return (
    <>
      <div className='w-full flex flex-col flex-nowrap gap-8'>
        <div>
          <InputLabel heading='Entities' />
          <LegalEntityMultiSelectMenu
            selectedLegalEntities={selectedLegalEntities}
            setSelectedLegalEntities={setSelectedLegalEntities}
          />
        </div>
        <div>
          <InputLabel heading='Run Period' />
          <AccountingPeriodStartDatesMultiSelectMenu
            selectedPeriods={selectedPeriods}
            setSelectedPeriods={setSelectedPeriods}
          />
        </div>
        <SwitchGroup
          switchGroupItems={[
            { label: 'Only process transactions with no accounting', labelLeft: false, checked: false },
            { label: 'Overwrite accounting with latest match', labelLeft: false, checked: false },
          ]}
          switchStyle={'place-self-start'}
          clickFn={(switchGroupValue) => {
            setForm({
              ...form,
              processTransactionsWithNoAccounting: !!switchGroupValue[0].checked,
              overWrite: !!switchGroupValue[1].checked,
            });
          }}
          className='place-self-start flex flex-col items-start !gap-6'
        />
      </div>
      <div className='flex justify-end place-self-end mt-12 gap-2'>
        <Button onClick={onCloseClick} emphasis='medium' label='Cancel' />
        <Button onClick={runRule} label='Run rulesets' />
      </div>
    </>
  );
};
