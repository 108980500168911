import React from 'react';
import { Oval } from 'react-loader-spinner';
import { LOADER_ICON_SIZE, LOADER_ICON_PRIMARY_COLOR, LOADER_ICON_SECONDARY_COLOR, LoaderIconProps } from './types';

export const LoaderIcon = ({
  size = LOADER_ICON_SIZE.SMALL,
  primaryColor = LOADER_ICON_PRIMARY_COLOR.BLUE,
  secondaryColor = LOADER_ICON_SECONDARY_COLOR.LIGHTGRAY,
}: LoaderIconProps) => {
  if (size === LOADER_ICON_SIZE.SMALL)
    return (
      <Oval
        color={primaryColor}
        secondaryColor={secondaryColor}
        width='16'
        height='16'
        visible={true}
        strokeWidthSecondary={7}
        strokeWidth={7}
      />
    );
  if (size === LOADER_ICON_SIZE.MEDIUM)
    return (
      <Oval
        color={primaryColor}
        secondaryColor={secondaryColor}
        width='24'
        height='24'
        visible={true}
        strokeWidthSecondary={7}
        strokeWidth={7}
      />
    );
  if (size === LOADER_ICON_SIZE.LARGE)
    return (
      <Oval
        color={primaryColor}
        secondaryColor={secondaryColor}
        width='36'
        height='36'
        visible={true}
        strokeWidthSecondary={7}
        strokeWidth={7}
      />
    );
  return null;
};
