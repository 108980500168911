import React from 'react';
import { Button } from 'ui';
import { useMutation } from '@tanstack/react-query';
import { useTransactionQueryParams } from './use-transaction-query-params';
import { downloadCsv, withCsvToasts } from '../../../lib/utils/download-csv';
import { getAllTransactionsQueryFn, getCsvRowsForTransactions } from '../../../lib/utils';
import { useGetAllFn } from '../../../hooks';
import { Transaction } from 'services/http/response.types';
import { RiDownload2Line } from 'react-icons/ri';

export const TransactionsCSV = ({ hideClosePeriodButton = false }) => {
  const transactionsQueryFilters = useTransactionQueryParams();
  const getAllJournalEntriesFn = useGetAllFn<Transaction>(getAllTransactionsQueryFn, transactionsQueryFilters, 250);

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      const allJournalEntries = await getAllJournalEntriesFn();
      const data = getCsvRowsForTransactions(allJournalEntries);
      downloadCsv(data, `transactions-export-${new Date().toLocaleDateString()}.csv`);
    },
  });

  return (
    <Button
      className='px-2'
      emphasis={hideClosePeriodButton ? 'high' : 'medium'}
      onClick={withCsvToasts(mutateAsync)}
      disabled={isLoading}
      isLoading={isLoading}
      leadingIcon={<RiDownload2Line className='w-5 h-5' />}
      label='Download'
    />
  );
};

export const SpamTransactionsCSV = ({ hideClosePeriodButton = false }) => {
  const transactionsQueryFilters = useTransactionQueryParams();
  const getAllJournalEntriesFn = useGetAllFn<Transaction>(
    getAllTransactionsQueryFn,
    { ...transactionsQueryFilters, spamOnly: true },
    250,
  );

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      const allJournalEntries = await getAllJournalEntriesFn();
      const data = getCsvRowsForTransactions(allJournalEntries);
      downloadCsv(data, `spam-transactions-export-${new Date().toLocaleDateString()}.csv`);
    },
  });

  return (
    <Button
      emphasis={hideClosePeriodButton ? 'high' : 'medium'}
      onClick={withCsvToasts(mutateAsync)}
      disabled={isLoading}
      isLoading={isLoading}
      label='Download'
    />
  );
};
