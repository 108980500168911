import * as React from 'react';
import { forwardRef } from 'react';
const SvgSearch = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='m23.5 25.5-8.867-8.867c-.644.533-1.41.955-2.3 1.267a8.515 8.515 0 0 1-2.833.466c-2.511 0-4.628-.866-6.35-2.6C1.428 14.033.567 11.944.567 9.5c0-2.444.866-4.533 2.6-6.266C4.9 1.499 6.989.633 9.433.633c2.467 0 4.556.866 6.267 2.6 1.711 1.733 2.567 3.822 2.567 6.266 0 .978-.145 1.9-.434 2.767a8.779 8.779 0 0 1-1.3 2.467l8.9 8.833L23.5 25.5ZM9.467 15.7c1.71 0 3.166-.606 4.366-1.817 1.2-1.211 1.8-2.672 1.8-4.384 0-1.71-.6-3.172-1.8-4.383-1.2-1.211-2.655-1.817-4.366-1.817-1.756 0-3.24.606-4.45 1.817C3.806 6.327 3.2 7.788 3.2 9.5c0 1.712.606 3.173 1.817 4.384 1.21 1.21 2.694 1.817 4.45 1.817Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSearch);
export default ForwardRef;
