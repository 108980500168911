import * as React from 'react';
import { cn } from 'shadcn/lib/utils';
import { TableLoader } from 'src/components/tables/table-components';
import { LoadingLineComponent } from 'ui';
import { useDensity } from 'src/context';

export const OuterTableWrapper = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <div className={cn('max-w-fit rounded-lg border min-w-full', className)}>{children}</div>
);

export const NoDataTableRow = ({ message, isLoading, noData }) =>
  !isLoading && noData ? <div className='border-b p-2 text-center'>{message}</div> : null;

export const TableSkeleton = ({ rowCount, isLoading }) => {
  if (!isLoading) return null;
  const rows: React.ReactNode[] = [];
  for (let i = 0; i < rowCount; i++)
    rows.push(
      <div className='h-14 p-4'>
        <LoadingLineComponent pulse />
      </div>,
    );

  return <div className='grid grid-cols-1 rounded-lg p-1 divide-y'>{rows}</div>;
};

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => <table ref={ref} className={cn(className, 'w-full caption-bottom')} {...props} />,
);
Table.displayName = 'Table';

const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => <thead ref={ref} className={cn(className)} {...props} />,
);
TableHeader.displayName = 'TableHeader';

export const StickyTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ children, className, ...props }, ref) => (
  <TableHeader className={cn('sticky top-[-1px] z-10 bg-zinc-100', className)} ref={ref} {...props}>
    {children}
  </TableHeader>
));
StickyTableHeader.displayName = 'StickyTableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    isLoading?: boolean;
    colSpan?: number;
    lastChildHasBorder?: boolean;
  }
>(({ className, isLoading = false, lastChildHasBorder, ...props }, ref) => (
  <tbody ref={ref} className={cn(!lastChildHasBorder && '[&_tr:last-child]:border-0', className)} {...props}>
    {isLoading ? <TableLoader /> : props.children}
  </tbody>
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot ref={ref} className={cn('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)} {...props} />
  ),
);
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn('transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted', className)}
        {...props}
      />
    );
  },
);
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & { withoutBorder?: boolean }
>(({ className, withoutBorder, ...props }, ref) => {
  const { density } = useDensity();

  return (
    <th
      ref={ref}
      className={cn(
        !withoutBorder && 'border-t border-b p-0 box-content',
        'relative text-left align-middle font-medium leading-6 text-zinc-600',
        className,
        {
          compact: 'text-[16px]',
          default: 'text-[16px]',
          comfortable: 'text-[16px]',
        }[density],
      )}
      {...props}
    >
      <span className={"border-l block h-[64px] absolute left-[0] pointer-events-none"} />
      {props.children}
    </th>
  );
});
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & { withoutBorder?: boolean }
>(({ className, withoutBorder, ...props }, ref) => {
  const { density } = useDensity();
  return (
    <td
      ref={ref}
      className={cn(
        !withoutBorder && 'border-b border-l',
        'align-middle',
        {
          compact: 'text-[16px]',
          default: 'text-[16px]',
          comfortable: 'text-[16px]',
        }[density],
        className,
      )}
      {...props}
    />
  );
});
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(
  ({ className, ...props }, ref) => (
    <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
  ),
);
TableCaption.displayName = 'TableCaption';

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
