import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, currencyImg } from 'ui';
import { shortText } from '../../../rules/utils';
import { useWalletFilterContext } from '../../../../context';

export const WalletsFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useWalletFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Wallets' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((wallet, i) => (
            <CheckboxListItem
              key={i}
              label={wallet.name ?? '' + ' ' + shortText(wallet.address) ?? ''}
              bottomText={wallet.walletType}
              checked={wallet.selected}
              enableAvatar
              src={currencyImg(wallet.chain?.toLowerCase())}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No wallets to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
