import React from 'react';
import { SidebarAnim, SidebarRoot } from '../../atoms/Sidebar';
import CreateJournalEntry from '../../jorunalEntry/CreateJournalEntry';

export const JournalsSidebar = ({ setShowCreateJournal }) => {
  return (
    <SidebarAnim>
      <SidebarRoot>
        <CreateJournalEntry
          data-cy='createJournalEntry'
          shouldNestCreateJournalLinePanel={true}
          onCancel={() => {
            setShowCreateJournal(false);
          }}
          onClose={() => {
            setShowCreateJournal(false);
          }}
        />
      </SidebarRoot>
    </SidebarAnim>
  );
};

export default JournalsSidebar;
