import React from 'react';
import { Sidebar, SidebarFooter, SidebarTopBar } from '../atoms/Sidebar';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Button } from 'ui';
import { ViewJournalEntryLineBody } from './ViewJournalEntryLineBody';

export const ViewJournalEntryLinePanel = ({ selectedLine, onCancel }) => {
  const dataCy = 'viewJournalEntryLine';
  return (
    <Sidebar data-cy={dataCy}>
      <SidebarTopBar onClose={onCancel} />
      <SidebarHeader data-cy={dataCy} title={`${selectedLine?.legalEntityId.entityName} Line`} />
      <ViewJournalEntryLineBody data-cy={dataCy} line={selectedLine} />
      <SidebarFooter
        secondaryBtn={
          <Button
            onClick={() => {
              onCancel();
            }}
            label='Cancel'
            emphasis='medium'
          />
        }
      ></SidebarFooter>
    </Sidebar>
  );
};
