import { createGlobalStyle } from 'styled-components';

const Form = createGlobalStyle`

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }

    input[type='file']::file-selector-button {
      border: 1px solid #e8e8e8;
      padding: 4px 12px;
      border-radius: 8px;
      background-color: #ffffff;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }
    
    input[type='file']::file-selector-button:hover {
      border: 1px solid #e8e8e8;
      box-shadow: inset 0px 0px 0px 2px rgba(128,128,128,1);
    }    

    .chrome-picker {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
      border: 1px solid #e8e8e8;
    }
    
    .search-container {
        padding: 16px 0px 0px;

        &.search-container-dark {
            background-color: var(--primary-dark);
        }

    .search-bar {
        box-sizing: border-box;
        display: flex;
        height: 100%;

    

        .input {
            height: 100%;
            width: 100%;
            outline: none;
            background-color: #f8f8f8;

          padding: 12px 24px;
          border: 2px solid rgba(0, 0, 0, 0.2);
          border-radius: 8px;

        }

        .input::placeholder {
            font-family: 'Inter';
            font-weight: 500;
            font-size: 18px;
            color: rgba(17, 17, 17, 0.6);
        }

        .search-result {
            display: grid;
            grid-template-columns: min-content 1fr max-content;
            grid-column-gap: 24px;
        }

        .search-result:not(:first-child) {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        
        

        .search-dropdown {
            background: #FFFFFF;
        }



        &.search-bar-dark {
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: rgba(255, 255, 255, 0.06);
            .input {
                background: transparent;
                color: rgba(255, 255, 255, 0.6);
            }
            .search-dropdown {
                background: var(--primary-dark)

            }
            .search-result {

            }
        }

    }
}

`;

export default Form;
