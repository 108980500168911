import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getSourcesData } from 'services/http/analytics/sources';

type useGetSourcesDataParams = {
  chains?: string[];
  legalEntityIds?: string[];
  tags?: string[];
};

/**
 * Get sources data is an API for analytics on wallets, transaction volume by wallet
 * @param params
 * @returns
 */

export const useGetSourcesData = (params: useGetSourcesDataParams) => {
  const { chains, legalEntityIds, tags } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId, userId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.WALLET_STATS, params),
    async () => {
      const response = await getSourcesData({
        chains,
        legalEntityIds,
        userId,
        tags,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
