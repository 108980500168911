import { useEffect, useRef } from 'react';

type ConditionalIntervalCallback = () => Promise<void> | void;
type ConditionFn = () => boolean;

export const useConditionalInterval = (
  callback: ConditionalIntervalCallback,
  initialDelay: number,
  interval: number,
  condition: ConditionFn,
) => {
  const savedCallback = useRef<ConditionalIntervalCallback>();
  const timeoutId = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current && condition()) {
        savedCallback.current();
        timeoutId.current = setTimeout(tick, interval);
      }
    }

    if (condition()) {
      timeoutId.current = setTimeout(tick, initialDelay);
      return () => clearTimeout(timeoutId.current);
    } else {
      clearTimeout(timeoutId.current);
    }
  }, [initialDelay, interval, condition]);
};
