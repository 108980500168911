import React from 'react';
import getStyles from './getStyles';

import { StatusIndicatorProps } from './types';
import { classNames } from '../utils';

export const StatusIndicator = ({
  emphasis = 'high',
  type = 'neutral',
  label,
  leadingIcon,
  trailingIcon,
  className,
  withoutPaddingLeft = true,
}: StatusIndicatorProps) => {
  const isIconOnly = !!(!label && ((!leadingIcon && trailingIcon) || (leadingIcon && !trailingIcon)));
  const styles = getStyles(type, emphasis);

  if (isIconOnly) styles.style.padding = 4;

  return (
    <div className={`inline-flex px-4 md:px-6 ${withoutPaddingLeft ? '!px-0' : ''}`}>
      <span
        className={classNames(
          'inline-flex items-center h-full leading-4 px-1.5 py-1 rounded-md !border-none',
          styles.classes.container,
          className,
        )}
      >
        {leadingIcon && (
          <span
            className={classNames('inline-flex items-center', styles.classes.icon, isIconOnly ? 'm-0' : 'ml-1 my-1')}
          >
            {leadingIcon}
          </span>
        )}

        {label && emphasis === 'low' && (
          <span
            className={classNames(
              'flex-shrink-0 flex-grow-0 block w-1 h-1 rounded-full bg-black mr-1.5',
              styles.classes.dotBgColor,
            )}
          ></span>
        )}
        {label && (
          <span
            className={classNames(
              'inline-flex items-center capitalize font-semibold text-xs whitespace-nowrap',
              styles.classes.label,
              trailingIcon ? 'ml-2' : '',
              leadingIcon ? 'mr-2' : '',
            )}
          >
            {label}
          </span>
        )}
        {trailingIcon && (
          <span
            className={classNames('inline-flex items-center', styles.classes.icon, isIconOnly ? 'm-0' : 'mr-1 my-1')}
          >
            {trailingIcon}
          </span>
        )}
      </span>
    </div>
  );
};
