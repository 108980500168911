import React from 'react';
import { AppBarList } from './bar-list';
import { processAssetBreakdownData } from './utils';
//import { useGetAssetBreakdown } from '../../../hooks/http/useGetAssetBreakdown';
import {
  useDateFilterContext,
  useFilteredAccountingPeriodStartDateUTCs,
  useFilteredAssetTypes,
  useFilteredLegalEntityIds,
  useTargetDateFilterContext,
} from '../../../context';
import { useGetAssetMetrics } from 'src/hooks/http/useAssets';
import { AssetBreakdownData } from 'services/http/analytics/assets';
import { formatMetricsResponseIntoAssetBreakdownData } from 'src/components/widgets';

export const AssetsChart = ({ searchTerm }) => {
  const assetTypes = useFilteredAssetTypes();
  const legalEntityIds = useFilteredLegalEntityIds();
  const accountingPeriodStartDateUTCs = useFilteredAccountingPeriodStartDateUTCs();
  const targetDateFilter = useTargetDateFilterContext();
  const dateFilter = useDateFilterContext();

  const { data: metricsData, isLoading } = useGetAssetMetrics({
    assetTypes: searchTerm !== '' ? [...assetTypes, searchTerm] : assetTypes,
    legalEntityIds,
    accountingPeriodStartDateUTCs: accountingPeriodStartDateUTCs.map((date) => new Date(date).toISOString()),
    targetDate: targetDateFilter.datesWithTzOffset.start,
    startDate: dateFilter.datesWithTzOffset.start,
    endDate: dateFilter.datesWithTzOffset.end,
  });

  let assetBreakdownData: undefined | AssetBreakdownData = formatMetricsResponseIntoAssetBreakdownData(metricsData);

  const { processedData } = processAssetBreakdownData(assetBreakdownData);

  return (
    <AppBarList
      data={processedData}
      categories={['Values', 'Quantities']}
      title='Asset Breakdown'
      subtitle='Asset type'
      isLoading={isLoading}
      containerId='assets-chart'
    />
  );
};

export default AssetsChart;
