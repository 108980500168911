import { useMemo, useState } from 'react';
import { INVOICE_PAYMENT_METHOD, PAY_INVOICE_MODAL_STEP } from './types';
import toast from 'react-hot-toast';
import { Button, Modal, ModalTrigger } from 'ui';
import { SelectPaymentMode } from './select-payment-mode';
import { SelectPaymentMethod } from './select-payment-method';
import { StripeProvider } from '../../../../context';
import { ShowStripePaymentStatus } from './show-stripe-payment-status';

export const PayInvoiceModal = ({ invoice, initialStep = PAY_INVOICE_MODAL_STEP.SELECT_STRIPE_PAYMENT_METHOD }) => {
  const [step, setStep] = useState(initialStep);
  const [open, setOpen] = useState(false);

  const onPaymentModeSelect = (paymentMode: INVOICE_PAYMENT_METHOD) => {
    switch (paymentMode) {
      case INVOICE_PAYMENT_METHOD.CARD:
        setStep(PAY_INVOICE_MODAL_STEP.SELECT_STRIPE_PAYMENT_METHOD);
        break;
      case INVOICE_PAYMENT_METHOD.USDC:
        toast.success('Should open up USDC payment stuff');
        break;
      default:
        toast.error('Incomplete payment mode integration');
    }
  };

  const onClose = () => {
    setOpen(false);
    setStep(initialStep);
  };

  const clientSecret = useMemo(() => invoice?.payment_intent?.client_secret, [invoice]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Pay invoice' emphasis='medium' variant='xs' onClick={() => setOpen(true)} />
      </ModalTrigger>
      {step === PAY_INVOICE_MODAL_STEP.SELECT_PAYMENT_MODE && (
        <SelectPaymentMode onClose={onClose} onNext={onPaymentModeSelect} />
      )}
      {step === PAY_INVOICE_MODAL_STEP.SELECT_STRIPE_PAYMENT_METHOD && (
        <StripeProvider options={{ clientSecret }}>
          <SelectPaymentMethod
            invoice={invoice}
            onClose={onClose}
            // onBack={() => setStep(PAY_INVOICE_MODAL_STEP.SELECT_PAYMENT_MODE)}
          />
        </StripeProvider>
      )}
      {step === PAY_INVOICE_MODAL_STEP.SHOW_STRIPE_PAYMENT_STATUS && (
        <StripeProvider options={{ clientSecret }}>
          <ShowStripePaymentStatus clientSecret={clientSecret} onClose={onClose} />
        </StripeProvider>
      )}
    </Modal>
  );
};
