import React from 'react';
import getStyles from './getStyles';

import { TagProps } from './types';

export const Tag = ({ emphasis = 'high', label, leadingIcon, trailingIcon }: TagProps) => {
  const styles = getStyles(emphasis);
  return (
    <span
      className={`min-w-[26px] isolate px-1 py-0 font-medium inline-flex item-center justify-center text-base rounded ${styles.container}`}
    >
      {leadingIcon && <span className={`inline-flex items-center ${styles.icon}`}>{leadingIcon}</span>}
      {label && (
        <span className={`inline-flex items-center ${styles.label} ${trailingIcon && 'mr-1'} ${leadingIcon && 'ml-1'}`}>
          {label}
        </span>
      )}
      {trailingIcon && <span className={`inline-flex items-center ${styles.icon}`}>{trailingIcon}</span>}
    </span>
  );
};
