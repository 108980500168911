import { useEffect, useState } from 'react';
import {
  JobProgressUpdates,
  OutgoingWebsocketMessages,
  filterJobUpdatesByJobName,
  filterJobUpdatesByJobNameAndReferenceId,
} from '../pubsub-topics';
import { filter } from 'rxjs';
import { WS_OUTGOING_MESSAGE_TYPE } from '../hooks/types';
import { JOB_STATUS } from 'services';
import { JobConfiguration } from 'schemas';

export const useJobUpdatesByJobName = ({ jobName, callback }) => {
  useEffect(() => {
    const topic = JobProgressUpdates.pipe(filter(filterJobUpdatesByJobName(jobName)));

    const subscription = topic.subscribe(callback);
    return () => subscription.unsubscribe();
  }, [jobName, callback]);
};

export const useJobUpdatesByJobNameAndReferenceId = ({ jobName, referenceId, uniqueId, callback }) => {
  useEffect(() => {
    const topic = JobProgressUpdates.pipe(filter(filterJobUpdatesByJobNameAndReferenceId(jobName, referenceId, uniqueId)));

    const subscription = topic.subscribe(callback);
    return () => subscription.unsubscribe();
  }, [jobName, referenceId, callback]);
};

type MessageFilterFn = (message: any) => boolean;

export const useJobUpdates = (callback, filterFn: MessageFilterFn = () => true) => {
  useEffect(() => {
    const topic = JobProgressUpdates.pipe(filter(filterFn));

    const subscription = topic.subscribe(callback);
    return () => subscription.unsubscribe();
  }, [callback]);
};

export const useIsJobComplete = (jobName, referenceId) => {
  const [jobConfig, setJobConfig] = useState<JobConfiguration>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!jobName || !referenceId) return;

      const jobStatus = jobConfig?.jobStatus || JOB_STATUS.IN_PROGRESS;

      if (['STARTED', 'IN_PROGRESS'].includes(jobStatus))
        OutgoingWebsocketMessages.next({
          payload: { jobName, referenceId },
          type: WS_OUTGOING_MESSAGE_TYPE.REQUEST_FOR_UPDATES_ON_QUEUED_JOBS,
        });
    }, 5000);

    return () => clearTimeout(timeout);
  }, [jobConfig, jobName, referenceId]);
  return { updateMessage: setJobConfig };
};
