import { createGlobalStyle } from 'styled-components';

export const TooltipContentStyle = createGlobalStyle`
.tooltip-custom-style {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    width: fit-content;
    min-height: 28px;
    whitespace: no-wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    &.primary {
        color: white;
        background-color: #222222
    }
}
`;
