import { Skeleton } from '../../../@/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../@/components/ui/table';
interface WalletAssetQuantitiesChartSkeletonProps {
  variant: 'chart' | 'table';
}

export const WalletAssetQuantitiesChartSkeleton: React.FC<WalletAssetQuantitiesChartSkeletonProps> = ({ variant }) => {
  return (
    <div className='relative w-full h-full'>
      {/* Background shimmer */}
      <svg width='100%' height='100%' className='absolute inset-0'>
        <defs>
          <linearGradient id='backgroundShimmer' x1='0' x2='100%' y1='0' y2='0'>
            <stop offset='0%' stopColor='rgba(224, 224, 224, 0.4)'>
              <animate attributeName='offset' values='-2; 1' dur='2s' repeatCount='indefinite' />
            </stop>
            <stop offset='50%' stopColor='rgba(224, 224, 224, 0.7)'>
              <animate attributeName='offset' values='-1; 2' dur='2s' repeatCount='indefinite' />
            </stop>
            <stop offset='100%' stopColor='rgba(224, 224, 224, 0.4)'>
              <animate attributeName='offset' values='0; 3' dur='2s' repeatCount='indefinite' />
            </stop>
          </linearGradient>
        </defs>
        <rect width='100%' height='100%' fill='url(#backgroundShimmer)' />
      </svg>

      {variant === 'chart' ? (
        // Stacked bar chart skeleton
        <svg width='100%' height='100%' viewBox='0 0 120 100' preserveAspectRatio='none' className='absolute inset-0'>
          <g>
            {[0, 1, 2, 3, 4].map((index) => (
              <rect
                key={index}
                x={index * 20 + 15}
                y={20}
                width='15'
                height='60'
                fill='rgba(200, 200, 200, 0.7)'
                rx='2'
                ry='2'
              >
                <animate
                  attributeName='height'
                  values='60;55;58;56;60'
                  dur='2s'
                  repeatCount='indefinite'
                  begin={`${index * 0.2}s`}
                />
                <animate
                  attributeName='y'
                  values='20;25;22;24;20'
                  dur='2s'
                  repeatCount='indefinite'
                  begin={`${index * 0.2}s`}
                />
              </rect>
            ))}
          </g>
          {/* X-axis */}
          <line x1='10' y1='100' x2='110' y2='100' stroke='#e0e0e0' strokeWidth='1' />
        </svg>
      ) : (
        <div className='absolute inset-0 overflow-x-auto'>
          <Table className='w-full'>
            <TableHeader>
              <TableRow>
                <TableHead className='sticky left-0 bg-white z-20 w-1/4'>
                  <Skeleton className='h-6 w-full max-w-[120px]' />
                </TableHead>
                {[...Array(5)].map((_, index) => (
                  <TableHead key={index} className='text-right w-1/6'>
                    <Skeleton className='h-6 w-full max-w-[80px] ml-auto' />
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {[...Array(5)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell className='sticky left-0 bg-white z-10 font-medium w-1/4'>
                    <Skeleton className='h-6 w-full max-w-[120px]' />
                  </TableCell>
                  {[...Array(5)].map((_, cellIndex) => (
                    <TableCell key={cellIndex} className='text-right w-1/6'>
                      <Skeleton className='h-6 w-full max-w-[60px] ml-auto' />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow className='font-bold bg-gray-100'>
                <TableCell className='sticky left-0 bg-gray-100 z-10 w-1/4'>
                  <Skeleton className='h-6 w-full max-w-[80px]' />
                </TableCell>
                {[...Array(5)].map((_, index) => (
                  <TableCell key={index} className='text-right w-1/6'>
                    <Skeleton className='h-6 w-full max-w-[80px] ml-auto' />
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};
