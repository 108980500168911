import { toast } from 'react-hot-toast';
import { CreateImpairmentRuleFormState } from './CreateImpairmentRuleFormElements';

export const isCreateImpairmentRuleFormStateValid = (formState: CreateImpairmentRuleFormState) => {
  if (!formState.name.length) {
    toast.error('We need a name for impairment rule');
    return false;
  }
  if (!formState.journalEntryTemplateId) {
    toast.error('Please select a journal entry template for impairment rule');
    return false;
  }
  return true;
};
