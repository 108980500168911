import * as React from 'react';
import { forwardRef } from 'react';
const SvgYinYang = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M70.64 31.72 48 9.08 25.36 31.72c-12.48 12.48-12.48 32.76 0 45.24C31.6 83.2 39.8 86.32 48 86.32c8.2 0 16.4-3.12 22.64-9.36 12.48-12.48 12.48-32.76 0-45.24ZM48 78.36c-6.4 0-12.44-2.48-16.96-7.04C26.48 66.76 24 60.76 24 54.36s2.48-12.44 7.04-16.96L48 20.4v57.96Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgYinYang);
export default ForwardRef;
