import { JobConfiguration } from 'schemas';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
import axios from 'axios';

export type UpdateJobConfigurationParams = {
  jobConfigurationId: string;
  update: Partial<JobConfiguration>;
};
export const updateJobConfiguration = async (params: UpdateJobConfigurationParams) => {
  const response = await axios.patch(
    `${SERVER_URL_CONFIGURATION_SERVICE}/job-configuration?jobConfigurationId=${params.jobConfigurationId}`,
    params.update,
  );
  return response.data;
};

export const deleteJobConfiguration = async (jobConfigurationId) => {
  const response = await axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/job-configuration?jobConfigurationId=${jobConfigurationId}`,
  );
  return response.data;
};
