import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../../config';
import { prepareSearchParams } from '../core/utils';
export type AddTokenToSpamListPayload = {
  rawContractAddress: string;
  assetType: string;
  chain: string;
};
export const addTokenToSpamList = async ({ rawContractAddress, assetType, chain }: AddTokenToSpamListPayload) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/spam-token`, {
    rawContractAddress,
    assetType,
    chain,
  });
};

export type RemoveTokenFromSpamListPayload = {
  rawContractAddress: string;
  assetType: string;
};

export const removeTokenFromSpamList = async ({ rawContractAddress, assetType }: RemoveTokenFromSpamListPayload) => {
  return await axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/spam-token?rawContractAddress=${rawContractAddress}&assetType=${assetType}`,
  );
};

export type MarkTransactionAsSpamPayload = {
  transactionIds: string[];
};

export const markTransactionsAsSpam = async ({ transactionIds }: MarkTransactionAsSpamPayload) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-spam-transactions`, {
    transactionIds,
  });
};

export type MarkContractsAsSpamPayload = {
  spamTokens: { chain: string; assetType: string; rawContractAddress: string }[];
};
export const markContractsAsSpam = async (payload: MarkContractsAsSpamPayload) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/mark-contracts-as-spam`, payload);
};
export const markContractsAsNotSpam = async (spamTokenIds: string[]) => {
  return await axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/mark-contracts-as-not-spam`, { spamTokenIds });
};
