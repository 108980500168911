import { useQueryClient } from '@tanstack/react-query';
import { useSession } from './useSession';

export enum QUERY_KEY {
  ASSET_BY_ID = 'ASSET_BY_ID',
  OVERVIEW_DATA = 'OVERVIEW_DATA',
  ALGOLIA_KEY = 'ALGOLIA_KEY',
  LEGAL_ENTITIES = 'LEGAL_ENTITIES',
  WALLETS = 'WALLETS',
  WALLETS_BY_ID = 'WALLETS_BY_ID',
  WALLET_TAGS = 'WALLET_TAGS',
  WALLET_STATS = 'WALLET_STATS',
  LEDGER_ACCOUNTS = 'LEDGER_ACCOUNTS',
  IMPAIRMENT_RULES = 'IMPAIRMENT_RULES',
  ORGANIZATION_IMPAIRMENT_CONFIG = 'ORGANIZATION_IMPAIRMENT_CONFIG',
  ACCOUNTING_PERIODS = 'ACCOUNTING_PERIODS',
  BALANCE_SHEET = 'BALANCE_SHEET',
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  TAGS = 'TAGS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  ASSETS = 'ASSETS',
  SCHEDULEOFDISPOSITION = 'SCHEDULEOFDISPOSITION',
  REALIZEDGAINORLOSS = 'REALIZEDGAINORLOSS',
  CLOSINGPOSITIONS = 'CLOSINGPOSITIONS',
  ACCOUNT_TRANSACTION = 'ACCOUNT_TRANSACTION',
  ASSET_TYPES = 'ASSET_TYPES',
  ASSET_METRICS = 'ASSET_METRICS',
  EXCHANGE_SOURCES = 'EXCHANGE_SOURCES',
  EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS = 'EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS',
  HEDGEY_SOURCES = 'HEDGEY_SOURCES',
  MAPLE_SOURCES = 'MAPLE_SOURCES',
  HEDGEY_SOURCE_JOB_HISTORY_RECORDS = 'HEDGEY_SOURCE_JOB_HISTORY_RECORDS',
  MAPLE_SOURCES_JOB_HISTORY_RECORDS = 'MAPLE_SOURCES_JOB_HISTORY_RECORDS',
  EXTERNAL_ACCOUNTS = 'EXTERNAL_ACCOUNTS',
  EXTERNAL_LEGAL_ENTITIES = 'EXTERNAL_LEGAL_ENTITIES',
  INTEGRATION_CONFIGS = 'INTEGRATION_CONFIGS',
  INTEGRATION_AUTHS = 'INTEGRATION_AUTHS',
  EXTERNAL_ACCOUNT_MAPPINGS = 'EXTERNAL_ACCOUNT_MAPPINGS',
  EXTERNAL_LEGAL_ENTITIES_MAPPINGS = 'EXTERNAL_LEGAL_ENTITIES_MAPPINGS',
  JOURNAL_ENTRIES = 'JOURNAL_ENTRIES',
  JOURNAL_ENTRY_FILE_ATTACHMENTS = 'JOURNAL_ENTRY_FILE_ATTACHMENTS',
  ACCOUNT_POSTING_RULESET_CONFIGS = 'ACCOUNT_POSTING_RULESET_CONFIGS',
  ACCOUNT_POSTING_RULE_METRICS = 'ACCOUNT_POSTING_RULE_METRICS',
  ACCOUNT_POSTING_RULESETS = 'ACCOUNT_POSTING_RULESETS',
  JOURNAL_ENTRY_TEMPLATES = 'JOURNAL_ENTRY_TEMPLATES',
  GET_ALL_JOURNAL_ENTRY_TEMPLATES = 'GET_ALL_JOURNAL_ENTRY_TEMPLATES',
  LINE_TEMPLATES = 'LINE_TEMPLATES',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTION_BY_ID = 'TRANSACTION_BY_ID',
  TRANSACTION_STATS = 'TRANSACTION_STATS',
  TRANSACTIONS_LAST_DATE = 'TRANSACTIONS_LAST_DATE',
  USER = 'USER',
  QUICKBOOKS_REDIRECT_URL = 'QUICKBOOKS_REDIRECT_URL',
  XERO_REDIRECT_URL = 'XERO_REDIRECT_URL',
  NETSUITE_REDIRECT_URL = 'NETSUITE_REDIRECT_URL',
  SYNC_HISTORY_RECORDS = 'SYNC_HISTORY_RECORDS',
  JOB_PROGRESS = 'JOB_PROGRESS',
  JOB_CONFIGS = 'JOB_CONFIGS',
  TRIAL_BALANCE = 'TRIAL_BALANCE',
  ORGANIZATION_MEMBERS = 'ORGANIZATION_MEMBERS',
  RAINCARDS_INTEGRATION_CONFIGURATION = 'RAINCARDS_INTEGRATION_CONFIGURATION',
  RAINCARDS_SOURCES = 'RAINCARDS_SOURCES',
  RAINCARDS_JOB_HISTORY = 'RAINCARDS_JOB_HISTORY',
  SCHEDULED_RULESET_JOBS = 'SCHEDULED_RULESET_JOBS',
  SCHEDULED_IMPAIRMENT_JOBS = 'SCHEDULED_IMPAIRMENT_JOBS',
  RAINCARDS_RULE_META = 'RAINCARDS_RULE_META',
  LOOP_RULE_META = 'LOOP_RULE_META',
  SOURCES_TIMESTAMP = 'SOURCES_TIMESTAMP',
  TRANSACTIONS_TIMESTAMP = 'TRANSACTIONS_TIMESTAMP',
  JOURNALS_TIMESTAMP = 'JOURNALS_TIMESTAMP',
  ASSETS_TIMESTAMP = 'ASSETS_TIMESTAMP',
  TEMPLATES_TIMESTAMP = 'TEMPLATES_TIMESTAMP',
  RULESETS_TIMESTAMP = 'RULESETS_TIMESTAMP',
  TAGS_TIMESTAMP = 'TAGS_TIMESTAMP',
  IMPAIRMENTS_TIMESTAMP = 'IMPAIRMENTS_TIMESTAMP',
  LEDGER_ACCOUNTS_TIMESTAMP = 'LEDGER_ACCOUNTS_TIMESTAMP',
  LEGAL_ENTITIES_TIMESTAMP = 'LEGAL_ENTITIES_TIMESTAMP',
  REPORTS_TIMESTAMP = 'REPORTS_TIMESTAMP',
  INTEGRATIONS_TIMESTAMP = 'INTEGRATIONS_TIMESTAMP',
  TABS_SERVICE = 'TABS_SERVICE',
  JOURNAL_ENTRY_VOLUME = 'JOURNAL_ENTRY_VOLUME',
  HISTORICAL_ASSET_PRICE = 'HISTORICAL_ASSET_PRICE',
  TRANSACTION_ASSET_RELATIVE_PRICES = 'TRANSACTION_ASSET_RELATIVE_PRICES',
  ASSET_BREAKDOWN = 'ASSET_BREAKDOWN',
  TRANSACTIONS_VOLUME = 'TRANSACTIONS_VOLUME',
  LOOP_INTEGRATION_CONFIGURATION = 'LOOP_INTEGRATION_CONFIGURATION',
  STRIPE_AI_CREDITS_RECHARGE_OPTIONS = 'STRIPE_AI_CREDITS_RECHARGE_OPTIONS',
  STRIPE_SUBSCRIPTION_PRODUCTS = 'STRIPE_SUBSCRIPTION_PRODUCTS',
  STRIPE_CARDS = 'STRIPE_CARDS',
  STRIPE_INVOICES = 'STRIPE_INVOICES',
  STRIPE_DETAILS_FOR_ORG = 'STRIPE_DETAILS_FOR_ORG',
  USAGE = 'USAGE',
  GET_ALL_GL_INTEGRATION_CONFIGS = 'GET_ALL_GL_INTEGRATION_CONFIGS',
  TABLE_GROUPS = 'TABLE_GROUPS',
  ORGANIZATION_BY_SUBDOMAIN = 'ORGANIZATION_BY_SUBDOMAIN',
  STRIPE_SUBSCRIPTION_PLAN_SUGGESTION = 'STRIPE_SUBSCRIPTION_PLAN_SUGGESTION',
  STRIPE_SUBSCRIPTION_PLAN_FROM_QUOTE_ID = 'STRIPE_SUBSCRIPTION_PLAN_FROM_QUOTE_ID',
  AUTOMATION_SCORE = 'AUTOMATION_SCORE',
  GET_DISTINCT_FUNCTION_NAMES = 'GET_DISTINCT_FUNCTION_NAMES',
  GET_CLASSIFICATION_TASK_STATUS = 'GET_CLASSIFICATION_TASK_STATUS',
  GET_DISTINCT_CONTRACT_NAMES = 'GET_DISTINCT_CONTRACT_NAMES',
  GET_RULESET_JOB_CONFIGS = 'GET_RULESET_JOB_CONFIGS',
  GET_TRANSACTION_COUNT_BY_WALLET = 'GET_TRANSACTION_COUNT_BY_WALLET',
  GET_TRANSACTION_COUNT_BY_SOURCE = 'GET_TRANSACTION_COUNT_BY_SOURCE',
  GET_RUNNING_JOBS = 'GET_RUNNING_JOBS',
  GET_ORGANIZATION_BY_ID = 'GET_ORGANIZATION_BY_ID',
  GET_BEST_PERFORMING_RULES = 'GET_BEST_PERFORMING_RULES',
  GET_TRANSACTIONS_VOLUME_BY_MONTH = 'GET_TRANSACTIONS_VOLUME_BY_MONTH',
  GET_ACCOUNTING_PERIODS_HEALTH = 'GET_ACCOUNTING_PERIODS_HEALTH',
  UNACCOUNTED_TRANSACTIONS = 'UNACCOUNTED_TRANSACTIONS',
  SPAM_TOKENS = 'SPAM_TOKENS',
  SPAM_TRANSACTIONS = 'SPAM_TRANSACTIONS',
  JOB_CONFIGURATION_FOR_RULESET_JOB = 'JOB_CONFIGURATION_FOR_RULESET_JOB',
  LEGAL_ENTITY_WISE_ACCOUNTING_PERIODS = 'LEGAL_ENTITY_WISE_ACCOUNTING_PERIODS',
  ACCOUNTING_PERIOD_CLOSING_SETTINGS = 'ACCOUNTING_PERIOD_CLOSING_SETTINGS',
  ACCOUNTING_PERCENTAGE = 'ACCOUNTING_PERCENTAGE',
  MAPLE_POOL_POSITIONS = 'MAPLE_POOL_POSITIONS',
  ACCOUNTING_PERIOD_START_DATES = 'ACCOUNTING_PERIOD_START_DATES',
  ORGANIZATION = 'ORGANIZATION',
  GET_CONVERSATIONS = 'GET_CONVERSATIONS',
  AUDIT_LOGS = 'AUDIT_LOGS',
  GET_WALLET_ASSET_QUANTITIES = 'GET_WALLET_ASSET_QUANTITIES',
  CHAINS = 'CHAINS',
}

type InvalidateQueryFnKey =
  | 'invalidateOverviewData'
  | 'invalidateAlgoliaKey'
  | 'invalidateLegalEntities'
  | 'invalidateWallets'
  | 'invalidateWalletTags'
  | 'invalidateLedgerAccounts'
  | 'invalidateImpairmentRules'
  | 'invalidateOrganizationImpairmentConfig'
  | 'invalidateAccountingPeriods'
  | 'invalidateBalanceSheets'
  | 'invalidateIncomeStatements'
  | 'invalidateTrialBalances'
  | 'invalidateTags'
  | 'invalidateMemberships'
  | 'invalidateOrganizations'
  | 'invalidateUser'
  | 'invalidateAssets'
  | 'invalidateAssetTypes'
  | 'invalidateAssetMetrics'
  | 'invalidateExchangeSources'
  | 'invalidateExchangeSourceSyncHistoryRecords'
  | 'invalidateHedgeySources'
  | 'invalidateMapleSources'
  | 'invalidateHedgeySourceJobHistoryRecords'
  | 'invalidateMapleSourceJobHistoryRecords'
  | 'invalidateExternalAccounts'
  | 'invalidateExternalAccountMappings'
  | 'invalidateIntegrationConfigs'
  | 'invalidateIntegrationAuths'
  | 'invalidateJournalEntries'
  | 'invalidateJournalEntryFileAttachments'
  | 'invalidateJournalEntryTemplates'
  | 'invalidateAllJournalEntryTemplates'
  | 'invalidateLineTemplates'
  | 'invalidateAccountPostingRulesetConfigs'
  | 'invalidateAccountPostingRuleMetrics'
  | 'invalidateAccountPostingRulesets'
  | 'invalidateTransactions'
  | 'invalidateSourcesTimestamp'
  | 'invalidateTransactionsTimestamp'
  | 'invalidateJournalsTimestamp'
  | 'invalidateAssetsTimestamp'
  | 'invalidateTemplatesTimestamp'
  | 'invalidateRulesetsTimestamp'
  | 'invalidateTagsTimestamp'
  | 'invalidateImpairmentsTimestamp'
  | 'invalidateLedgerAccountsTimestamp'
  | 'invalidateLegalEntitiesTimestamp'
  | 'invalidateReportsTimestamp'
  | 'invalidateIntegrationsTimestamp'
  | 'invalidateTransactionById'
  | 'invalidateTransactionStats'
  | 'invalidateTransactionLastDate'
  | 'invalidateQuickbooksRedirectUrl'
  | 'invalidateSyncHistoryRecords'
  | 'invalidateJobProgress'
  | 'invalidateJobConfigs'
  | 'invalidateScheduledRulesetJobs'
  | 'invalidateScheduledImpairmentJobs'
  | 'invalidateTabsService'
  | 'invalidateRaincardsIntegrationConfiguration'
  | 'invalidateLoopIntegrationConfiguration'
  | 'invalidateOrganizationMembers'
  | 'invalidateStripeAICreditsRechargeOptions'
  | 'invalidateStripeCards'
  | 'invalidateStripeDetailsForOrg'
  | 'invalidateStripeInvoices'
  | 'invalidateStripeSubscriptionProducts'
  | 'invalidateUsage'
  | 'invalidateTableGroups'
  | 'invalidateGLConfigs'
  | 'invalidateOrganizationBySubdomain'
  | 'invalidateStripeSubscriptionPlanSuggestion'
  | 'invalidateAutomationScore'
  | 'invalidateGetClassificationTaskStatus'
  | 'invalidateGetRulesetJobConfigs'
  | 'invalidateGetTransactionCountByWallet'
  | 'invalidateGetTransactionCountBySource'
  | 'invalidateGetRunningJobs'
  | 'invalidateRainCardsSources'
  | 'invalidateGetOrganizationById'
  | 'invalidateGetBestPerformingRules'
  | 'invalidateGetTransactionsVolumeByMonth'
  | 'invalidateAccountingPeriodsHealth'
  | 'invalidateUnaccountedTransactions'
  | 'invalidateSpamTokens'
  | 'invalidateSpamTransactions'
  | 'invalidateJobConfigurationForRulesetJob'
  | 'invalidateLegalEntityWiseAccountingPeriods'
  | 'invalidateAccountingPeriodClosingSettings'
  | 'invalidateAccountingPercentage'
  | 'invalidateMaplePoolPositions'
  | 'invalidateAccountingPeriodStartDates'
  | 'invalidateOrganization'
  | 'invalidateJobConfigurationForRulesetJob'
  | 'invalidateGetConversations'
  | 'invalidateChains';

type InvalidateFn = (slug?: any, exact?: boolean) => Promise<void>;
export type InvalidateQueries = Record<InvalidateQueryFnKey, InvalidateFn>;

export const useOrgBasedQueryKey = () => {
  const { organizationId } = useSession();

  const getKey = (key: QUERY_KEY, slug?: any) => (slug ? [key, organizationId, slug] : [key, organizationId]);

  return getKey;
};

export const useInvalidateQuery = (): InvalidateQueries => {
  const queryClient = useQueryClient();
  const getKey = useOrgBasedQueryKey();

  const createInvalidatorForKey =
    (key: QUERY_KEY): InvalidateFn =>
      (slug?: any, exact = false) =>
        queryClient.invalidateQueries({ queryKey: getKey(key, slug), exact });

  return {
    invalidateOrganizationMembers: createInvalidatorForKey(QUERY_KEY.ORGANIZATION_MEMBERS),
    invalidateOverviewData: createInvalidatorForKey(QUERY_KEY.OVERVIEW_DATA),
    invalidateAlgoliaKey: createInvalidatorForKey(QUERY_KEY.ALGOLIA_KEY),
    invalidateLegalEntities: createInvalidatorForKey(QUERY_KEY.LEGAL_ENTITIES),
    invalidateWallets: createInvalidatorForKey(QUERY_KEY.WALLETS),
    invalidateWalletTags: createInvalidatorForKey(QUERY_KEY.WALLET_TAGS),
    invalidateLedgerAccounts: createInvalidatorForKey(QUERY_KEY.LEDGER_ACCOUNTS),
    invalidateImpairmentRules: createInvalidatorForKey(QUERY_KEY.IMPAIRMENT_RULES),
    invalidateOrganizationImpairmentConfig: createInvalidatorForKey(QUERY_KEY.ORGANIZATION_IMPAIRMENT_CONFIG),
    invalidateAccountingPeriods: createInvalidatorForKey(QUERY_KEY.ACCOUNTING_PERIODS),
    invalidateBalanceSheets: createInvalidatorForKey(QUERY_KEY.BALANCE_SHEET),
    invalidateIncomeStatements: createInvalidatorForKey(QUERY_KEY.INCOME_STATEMENT),
    invalidateTrialBalances: createInvalidatorForKey(QUERY_KEY.TRIAL_BALANCE),
    invalidateTags: createInvalidatorForKey(QUERY_KEY.TAGS),
    invalidateMemberships: createInvalidatorForKey(QUERY_KEY.MEMBERSHIPS),
    invalidateOrganizations: createInvalidatorForKey(QUERY_KEY.ORGANIZATIONS),
    invalidateUser: createInvalidatorForKey(QUERY_KEY.USER),
    invalidateAssets: createInvalidatorForKey(QUERY_KEY.ASSETS),
    invalidateAssetTypes: createInvalidatorForKey(QUERY_KEY.ASSET_TYPES),
    invalidateAssetMetrics: createInvalidatorForKey(QUERY_KEY.ASSET_METRICS),
    invalidateExchangeSources: createInvalidatorForKey(QUERY_KEY.EXCHANGE_SOURCES),
    invalidateExchangeSourceSyncHistoryRecords: createInvalidatorForKey(QUERY_KEY.EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS),
    invalidateHedgeySources: createInvalidatorForKey(QUERY_KEY.HEDGEY_SOURCES),
    invalidateHedgeySourceJobHistoryRecords: createInvalidatorForKey(QUERY_KEY.HEDGEY_SOURCE_JOB_HISTORY_RECORDS),
    invalidateMapleSources: createInvalidatorForKey(QUERY_KEY.MAPLE_SOURCES),
    invalidateMapleSourceJobHistoryRecords: createInvalidatorForKey(QUERY_KEY.MAPLE_SOURCES_JOB_HISTORY_RECORDS),
    invalidateExternalAccounts: createInvalidatorForKey(QUERY_KEY.EXTERNAL_ACCOUNTS),
    invalidateExternalAccountMappings: createInvalidatorForKey(QUERY_KEY.EXTERNAL_ACCOUNT_MAPPINGS),
    invalidateIntegrationConfigs: createInvalidatorForKey(QUERY_KEY.INTEGRATION_CONFIGS),
    invalidateIntegrationAuths: createInvalidatorForKey(QUERY_KEY.INTEGRATION_AUTHS),
    invalidateJournalEntries: createInvalidatorForKey(QUERY_KEY.JOURNAL_ENTRIES),
    invalidateJournalEntryFileAttachments: createInvalidatorForKey(QUERY_KEY.JOURNAL_ENTRY_FILE_ATTACHMENTS),
    invalidateJournalEntryTemplates: createInvalidatorForKey(QUERY_KEY.JOURNAL_ENTRY_TEMPLATES),
    invalidateAllJournalEntryTemplates: createInvalidatorForKey(QUERY_KEY.GET_ALL_JOURNAL_ENTRY_TEMPLATES),
    invalidateLineTemplates: createInvalidatorForKey(QUERY_KEY.LINE_TEMPLATES),
    invalidateAccountPostingRulesetConfigs: createInvalidatorForKey(QUERY_KEY.ACCOUNT_POSTING_RULESET_CONFIGS),
    invalidateAccountPostingRuleMetrics: createInvalidatorForKey(QUERY_KEY.ACCOUNT_POSTING_RULE_METRICS),
    invalidateAccountPostingRulesets: createInvalidatorForKey(QUERY_KEY.ACCOUNT_POSTING_RULESETS),
    invalidateTransactions: createInvalidatorForKey(QUERY_KEY.TRANSACTIONS),
    invalidateTransactionById: createInvalidatorForKey(QUERY_KEY.TRANSACTION_BY_ID),
    invalidateTransactionStats: createInvalidatorForKey(QUERY_KEY.TRANSACTION_STATS),
    invalidateTransactionLastDate: createInvalidatorForKey(QUERY_KEY.TRANSACTIONS_LAST_DATE),
    invalidateQuickbooksRedirectUrl: createInvalidatorForKey(QUERY_KEY.QUICKBOOKS_REDIRECT_URL),
    invalidateSyncHistoryRecords: createInvalidatorForKey(QUERY_KEY.SYNC_HISTORY_RECORDS),
    invalidateJobProgress: createInvalidatorForKey(QUERY_KEY.JOB_PROGRESS),
    invalidateJobConfigs: createInvalidatorForKey(QUERY_KEY.JOB_CONFIGS),
    invalidateScheduledRulesetJobs: createInvalidatorForKey(QUERY_KEY.SCHEDULED_RULESET_JOBS),
    invalidateScheduledImpairmentJobs: createInvalidatorForKey(QUERY_KEY.SCHEDULED_IMPAIRMENT_JOBS),
    invalidateSourcesTimestamp: createInvalidatorForKey(QUERY_KEY.SOURCES_TIMESTAMP),
    invalidateTransactionsTimestamp: createInvalidatorForKey(QUERY_KEY.TRANSACTIONS_TIMESTAMP),
    invalidateJournalsTimestamp: createInvalidatorForKey(QUERY_KEY.JOURNALS_TIMESTAMP),
    invalidateAssetsTimestamp: createInvalidatorForKey(QUERY_KEY.ASSETS_TIMESTAMP),
    invalidateTemplatesTimestamp: createInvalidatorForKey(QUERY_KEY.TEMPLATES_TIMESTAMP),
    invalidateRulesetsTimestamp: createInvalidatorForKey(QUERY_KEY.RULESETS_TIMESTAMP),
    invalidateTagsTimestamp: createInvalidatorForKey(QUERY_KEY.TAGS_TIMESTAMP),
    invalidateImpairmentsTimestamp: createInvalidatorForKey(QUERY_KEY.IMPAIRMENTS_TIMESTAMP),
    invalidateLedgerAccountsTimestamp: createInvalidatorForKey(QUERY_KEY.LEDGER_ACCOUNTS_TIMESTAMP),
    invalidateLegalEntitiesTimestamp: createInvalidatorForKey(QUERY_KEY.LEGAL_ENTITIES_TIMESTAMP),
    invalidateReportsTimestamp: createInvalidatorForKey(QUERY_KEY.REPORTS_TIMESTAMP),
    invalidateIntegrationsTimestamp: createInvalidatorForKey(QUERY_KEY.INTEGRATIONS_TIMESTAMP),
    invalidateTabsService: createInvalidatorForKey(QUERY_KEY.TABS_SERVICE),
    invalidateRaincardsIntegrationConfiguration: createInvalidatorForKey(QUERY_KEY.RAINCARDS_INTEGRATION_CONFIGURATION),
    invalidateLoopIntegrationConfiguration: createInvalidatorForKey(QUERY_KEY.LOOP_INTEGRATION_CONFIGURATION),
    invalidateStripeAICreditsRechargeOptions: createInvalidatorForKey(QUERY_KEY.STRIPE_AI_CREDITS_RECHARGE_OPTIONS),
    invalidateStripeDetailsForOrg: createInvalidatorForKey(QUERY_KEY.STRIPE_DETAILS_FOR_ORG),
    invalidateStripeInvoices: createInvalidatorForKey(QUERY_KEY.STRIPE_INVOICES),
    invalidateStripeCards: createInvalidatorForKey(QUERY_KEY.STRIPE_CARDS),
    invalidateStripeSubscriptionProducts: createInvalidatorForKey(QUERY_KEY.STRIPE_SUBSCRIPTION_PRODUCTS),
    invalidateUsage: createInvalidatorForKey(QUERY_KEY.USAGE),
    invalidateTableGroups: createInvalidatorForKey(QUERY_KEY.TABLE_GROUPS),
    invalidateGLConfigs: createInvalidatorForKey(QUERY_KEY.GET_ALL_GL_INTEGRATION_CONFIGS),
    invalidateOrganizationBySubdomain: createInvalidatorForKey(QUERY_KEY.ORGANIZATION_BY_SUBDOMAIN),
    invalidateStripeSubscriptionPlanSuggestion: createInvalidatorForKey(QUERY_KEY.STRIPE_SUBSCRIPTION_PLAN_SUGGESTION),
    invalidateAutomationScore: createInvalidatorForKey(QUERY_KEY.AUTOMATION_SCORE),
    invalidateGetClassificationTaskStatus: createInvalidatorForKey(QUERY_KEY.GET_CLASSIFICATION_TASK_STATUS),
    invalidateGetRulesetJobConfigs: createInvalidatorForKey(QUERY_KEY.GET_RULESET_JOB_CONFIGS),
    invalidateGetTransactionCountByWallet: createInvalidatorForKey(QUERY_KEY.GET_TRANSACTION_COUNT_BY_WALLET),
    invalidateGetTransactionCountBySource: createInvalidatorForKey(QUERY_KEY.GET_TRANSACTION_COUNT_BY_SOURCE),
    invalidateGetRunningJobs: createInvalidatorForKey(QUERY_KEY.GET_RUNNING_JOBS),
    invalidateRainCardsSources: createInvalidatorForKey(QUERY_KEY.RAINCARDS_SOURCES),
    invalidateGetOrganizationById: createInvalidatorForKey(QUERY_KEY.GET_ORGANIZATION_BY_ID),
    invalidateGetBestPerformingRules: createInvalidatorForKey(QUERY_KEY.GET_BEST_PERFORMING_RULES),
    invalidateGetTransactionsVolumeByMonth: createInvalidatorForKey(QUERY_KEY.GET_TRANSACTIONS_VOLUME_BY_MONTH),
    invalidateAccountingPeriodsHealth: createInvalidatorForKey(QUERY_KEY.GET_ACCOUNTING_PERIODS_HEALTH),
    invalidateUnaccountedTransactions: createInvalidatorForKey(QUERY_KEY.UNACCOUNTED_TRANSACTIONS),
    invalidateSpamTokens: createInvalidatorForKey(QUERY_KEY.SPAM_TOKENS),
    invalidateSpamTransactions: createInvalidatorForKey(QUERY_KEY.SPAM_TRANSACTIONS),
    invalidateJobConfigurationForRulesetJob: createInvalidatorForKey(QUERY_KEY.JOB_CONFIGURATION_FOR_RULESET_JOB),
    invalidateLegalEntityWiseAccountingPeriods: createInvalidatorForKey(QUERY_KEY.LEGAL_ENTITY_WISE_ACCOUNTING_PERIODS),
    invalidateAccountingPeriodClosingSettings: createInvalidatorForKey(QUERY_KEY.ACCOUNTING_PERIOD_CLOSING_SETTINGS),
    invalidateAccountingPercentage: createInvalidatorForKey(QUERY_KEY.ACCOUNTING_PERCENTAGE),
    invalidateMaplePoolPositions: createInvalidatorForKey(QUERY_KEY.MAPLE_POOL_POSITIONS),
    invalidateAccountingPeriodStartDates: createInvalidatorForKey(QUERY_KEY.ACCOUNTING_PERIOD_START_DATES),
    invalidateOrganization: createInvalidatorForKey(QUERY_KEY.ORGANIZATION),
    invalidateGetConversations: createInvalidatorForKey(QUERY_KEY.GET_CONVERSATIONS),
    invalidateChains: createInvalidatorForKey(QUERY_KEY.CHAINS),
  };
};
