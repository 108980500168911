import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getTransactionAssetQuantityChanges, getTransactionsNetFiatAmountCumulative, getTransactionsVolume } from 'services/http/analytics/transactions';

type TransactionsVolumeParams = {
  directions?: string[];
  chains?: (string | undefined)[];
  transactionFrom?: string;
  transactionTo?: string;
  accountingPeriodIds?: string[];
  legalEntityIds?: string[];
  assetTypes?: string[];
  walletTypesFilter?: string[];
  showTransactionsWithoutJournalEntries?: boolean;
  searchTerm?: string;
  addresses?: string[];
  sourceIds?: string[];
  walletIds?: string[];
  grossPrice?: string;
  minGrossPrice?: string;
  maxGrossPrice?: string;
};

export const useTransactionsVolume = (params: TransactionsVolumeParams) => {
  const {
    directions = ['Debit', 'Credit'],
    chains,
    transactionFrom,
    transactionTo,
    accountingPeriodIds,
    legalEntityIds,
    assetTypes,
    walletTypesFilter,
    showTransactionsWithoutJournalEntries,
    searchTerm,
    addresses,
    walletIds,
    sourceIds,
    grossPrice,
    minGrossPrice,
    maxGrossPrice,
  } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.TRANSACTIONS_VOLUME, params),
    async () => {
      const response = await getTransactionsVolume({
        transactionFrom,
        transactionTo,
        chains,
        directions,
        accountingPeriodIds,
        legalEntityIds,
        assetTypes,
        walletTypesFilter,
        showTransactionsWithoutJournalEntries,
        searchTerm,
        addresses,
        walletIds,
        sourceIds,
        organizationId,
        grossPrice,
        minGrossPrice,
        maxGrossPrice,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};


export const useTransactionQuantityChanges = (params: TransactionsVolumeParams) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.TRANSACTIONS_VOLUME, params),
    async () => {
      const response = await getTransactionAssetQuantityChanges(params);
      return response.data.changes
    },
    {
      enabled: !!organizationId,

      refetchOnWindowFocus: true
    },
  );
}

export const useTransactionNetFiatAmountCumulative = (params: TransactionsVolumeParams) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.TRANSACTIONS_VOLUME, params),
    async () => {
      const response = await getTransactionsNetFiatAmountCumulative(params);
      return response.data.result
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: true
    },
  );
}