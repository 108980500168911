import { DropdownGroup, DropdownGroupBody, DropdownGroupTitle, RadioListItem } from 'ui';
import { useAccountingTreatmentFilterContext } from '../../../../context';

export const AccountingTreatmentFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, deselectAll },
  } = useAccountingTreatmentFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Accounting treatment' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((option, i) => (
            <RadioListItem
              key={i}
              label={option.value}
              checked={option.selected}
              onCheckedChange={(selected: boolean) => {
                if (selected) deselectAll();
                setSelectionByRowIndex(i, selected);
              }}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No accounting treatments to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
