import React from 'react';
import { MultiValueRemoveProps } from 'react-select';
import { components } from 'react-select';
import { XIcon } from '../../../../assets/generated/svg';
export const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <XIcon className='!text-sm' />
    </components.MultiValueRemove>
  );
};

export default MultiValueRemove;
