import React from 'react';
import { MdError } from 'react-icons/md';
import { InputErrorProps } from './types';

export const InputError = ({ message, showError }: InputErrorProps) => (
  <>
    {showError && (
      <div className='font-medium text-[13px] text-[#ff0000] flex items-center mt-3'>
        <span className='mr-1'>
          <MdError className='w-[13px] h-[13px]' />
        </span>
        <span>{message || 'Error message not configured'}</span>
      </div>
    )}
  </>
);
