import { ReactNode } from 'react';

export enum BUTTON_EMPHASIS {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum BUTTON_STATUS {
  NEUTRAL = 'neutral',
  DANGER = 'danger',
}
export interface BaseButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'label'> {
  emphasis?: 'high' | 'medium' | 'low';
  status?: 'neutral' | 'danger';
  label?: string | ReactNode;
  trailingIcon?: ReactNode;
  leadingIcon?: ReactNode;
  variant?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isFocused?: boolean;
  disableRingOnFocus?: boolean;
  trailingIconContainerClassname?: string;
  leadingIconContainerClassname?: string;
  labelContainerClassname?: string;
  'data-cy'?: string;
}
