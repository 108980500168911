import { formatISO } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { checkValidity } from './valid-contracts';

export const classNames = twMerge;
export const getTransactionExplorerLink = (chain: string) => {
  if (chain === 'btc') return 'https://www.blockchain.com/explorer/transactions/btc';
  if (chain === 'eth') return 'https://etherscan.io/tx';
  if (chain === 'sol') return 'https://solscan.io/tx';
  if (chain === 'polygon') return 'https://polygonscan.com/tx';
  if (chain === 'bsc') return 'https://bscscan.com/tx';
  if (chain === 'near') return `https://explorer.near.org/transactions`;
  if (chain === 'arb') return 'https://arbiscan.io/tx';
  if (chain === 'op') return 'https://optimistic.etherscan.io/tx';
  if (chain === 'base') return 'https://basescan.org/tx';
  if (chain === 'avalanche') return 'https://snowtrace.io/tx';
  if (chain === 'linea') return 'https://lineascan.build/tx';
  if (chain === 'zora') return 'https://explorer.zora.energy/tx';
  if (chain === 'zksync') return 'https://explorer.zksync.io/tx';
  if (chain === 'scroll') return 'https://blockscout.scroll.io/tx';
  if (chain === 'celo') return 'https://explorer.celo.org/tx';
  if (chain === 'ronin') return 'https://legacy-explorer.roninchain.com/tx';
  if (chain === 'xlm') return 'https://stellar.expert/explorer/public/tx';
  if (chain === 'xrp') return 'https://xrpscan.com/tx';
  if (chain === 'cardano') return 'https://explorer.bitquery.io/cardano/tx';
  if (chain === 'ftm') return 'https://ftmscan.com/tx';
  if (chain === 'celestia') return 'https://www.mintscan.io/celestia/tx';
  if (chain == 'polygon-zkevm') return 'https://zkevm.polygonscan.com/tx'
  if (chain === 'cro') return 'https://cronoscan.com/tx';
};

export const getAddressExplorerLink = (chain: string) => {
  if (chain === 'btc') return 'https://www.blockchain.com/explorer/addresses/btc';
  if (chain === 'eth') return 'https://etherscan.io/address';
  if (chain === 'sol') return 'https://solscan.io/account';
  if (chain === 'polygon') return 'https://polygonscan.com/address';
  if (chain === 'bsc') return 'https://bscscan.com/address';
  if (chain === 'near') return 'https://explorer.near.org/accounts';
  if (chain === 'arb') return 'https://arbiscan.io/address';
  if (chain === 'op') return 'https://optimistic.etherscan.io/address';
  if (chain === 'base') return 'https://basescan.org/address';
  if (chain === 'avalanche') return 'https://snowtrace.io/address';
  if (chain === 'linea') return 'https://lineascan.build/address';
  if (chain === 'zora') return 'https://explorer.zora.energy/address';
  if (chain === 'zksync') return 'https://explorer.zksync.io/address';
  if (chain === 'scroll') return 'https://blockscout.scroll.io/address';
  if (chain === 'celo') return 'https://explorer.celo.org/address';
  if (chain === 'ronin') return 'https://legacy-explorer.roninchain.com/address';
  if (chain === 'xlm') return 'https://stellar.expert/explorer/public/account';
  if (chain === 'xrp') return 'https://xrpscan.com/address';
  if (chain === 'cardano') return 'https://explorer.bitquery.io/cardano/address';
  if (chain === 'ftm') return 'https://ftmscan.com/address';
  if (chain === 'celestia') return 'https://www.mintscan.io/celestia/address';
  if (chain === 'polygon-zkevm') return 'https://zkevm.polygonscan.com/address';
  if (chain === 'cro') return 'https://cronoscan.com/address';
};

export const formatDateAsString = (date: Date) => formatISO(date, { representation: 'date' });

type TGetValueFromDataFn<T> = (d: T) => string;
type TGetOptionDataFnKey<T> = string | TGetValueFromDataFn<T>;
type TGetOptionLabelFromDataFnKey<T> = TGetOptionDataFnKey<T>;
type TGetOptionValueFromDataFnKey<T> = TGetOptionDataFnKey<T>;

export type TGetOptionsFromFn = <T>(
  data: T[],
  keys: [TGetOptionLabelFromDataFnKey<T>, TGetOptionValueFromDataFnKey<T>],
) => { label: string; value: string }[];

const getValueFromData = <T>(d: T, key: TGetOptionDataFnKey<T>) => {
  if (typeof key === 'string') return d[key as keyof T] as string;
  return key(d);
};

export const getMenuOptionsFrom: TGetOptionsFromFn = (data, keys) =>
  data.map((d) => ({ label: getValueFromData(d, keys[0]), value: getValueFromData(d, keys[1]) }));

export const truncateParagraph = (paragraph: string, maxLength?: number) => {
  if (typeof maxLength === 'undefined') maxLength = 75;
  if (typeof paragraph === 'number') return paragraph;
  if (typeof paragraph !== 'string') return '';

  if (paragraph.length <= maxLength) {
    return paragraph;
  }

  const words = paragraph.split(' ');
  let truncatedString = '';
  let currentLength = 0;

  for (const word of words) {
    if (currentLength + word.length + 1 <= maxLength) {
      truncatedString += word + ' ';
      currentLength += word.length + 1;
    } else {
      break;
    }
  }

  truncatedString = truncatedString.trim(); // Remove trailing space
  truncatedString = truncatedString.substring(0, truncatedString.length - 3) + '...'; // Replace last space with '...'

  return truncatedString;
};

export const removeFromArray = <T>(element: T, array: T[]) => array.filter((value) => value !== element);

export const addToArray = <T>(element: T, array: T[]) => [...array, element];

export const toggleInArray = (value: string, array: string[]) => {
  const exists = array.includes(value);
  if (exists) {
    const values = removeFromArray(value, array);
    return values;
  } else {
    const values = addToArray(value, array);
    return values;
  }
};

export const onToggleSelection =
  <T>(setState: React.Dispatch<React.SetStateAction<T[]>>) =>
  (value: T) => {
    setState((prev) => {
      const idAlreadySelected = prev.includes(value);
      if (idAlreadySelected) {
        const values = removeFromArray(value, prev);
        return values;
      } else {
        const values = addToArray(value, prev);
        return values;
      }
    });
  };

export const currencyImg = (imgName: string, contract?: string) => {
  const CRYPTO_ICON_HOSTING_URL = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons';
  try {
    if (contract) {
      const valid = checkValidity(contract, imgName);
      if (!valid) return '';
    }
    let icon = '';
    imgName = imgName.toLowerCase();
    if (imgName === 'usdc.e') imgName = 'usdc';
    else if(imgName === 'coinbase') {
      icon = '/coinbase.png';
    } else if(imgName === 'gemini') {
      icon = '/gemini.png';
    } else if (imgName === 'kraken') {
      icon = '/kraken.png';
    } else if (imgName === 'maple') {
      icon = '/maple.png';
    } else if (imgName === 'polygon') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/matic.png?raw=true`;
    } else if (imgName === 'ausdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/aave.png?raw=true`;
    } else if (imgName === 'usdc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/usdc.png?raw=true`;
    } else if (imgName === 'sol') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png`;
    } else if (imgName === 'dust') {
      icon =
        'https://github.com/solana-labs/token-list/blob/main/assets/mainnet/DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ/logo.jpg?raw=true';
    } else if (imgName === 'bsc') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/bnb.png?raw=true`;
    } else if (imgName === 'near') {
      icon = `https://raw.githubusercontent.com/ErikThiart/cryptocurrency-icons/master/32/near-protocol.png?raw=true`;
    } else if (imgName === 'sweat') {
      icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/21351.png?raw=true';
    } else if (imgName === 'weth' || imgName === 'eth') {
      icon = 'https://github.com/spothq/cryptocurrency-icons/blob/master/32/color/eth.png?raw=true';
    } else if (imgName === 'avalanche') {
      icon = 'https://ef-crypto-icons.s3.us-east-1.amazonaws.com/crypto-currency-icons/avax.png';
    } else if (imgName === 'arb') {
      icon = 'https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=025';
    } else if (imgName === 'op') {
      icon = 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=025';
    } else if (imgName === 'zksync') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/24091.png?raw=true`;
    } else if (imgName === 'celo') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/5567.png?raw=true`;
    } else if (imgName === 'linea') {
      icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/27657.png?raw=true';
    } else if (imgName === 'scroll') {
      icon = 'https://www.datocms-assets.com/86369/1697057840-scroll-colour.png?auto=format&fit=crop&h=200&w=200';
    } else if (imgName === 'zora') {
      icon = `https://s2.coinmarketcap.com/static/img/coins/64x64/7826.png?raw=true`;
    } else if (imgName === 'ronin' || imgName === 'ron') {
      icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/14101.png?raw=true';
    } else if (imgName === 'cardano') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/ada.png?raw=true`;
    } else if (imgName === 'polygon-zkevm') {
      icon = `${CRYPTO_ICON_HOSTING_URL}/matic.png?raw=true`;
    } else {
      icon = `${CRYPTO_ICON_HOSTING_URL}/${imgName}.png?raw=true`;
    }
    return icon;
  } catch (error) {
    return '';
  }
};
