import { LegalEntity } from 'schemas';

export const getMultiSelectMenuValuesForLegalEntities = (multiSelectData: string[], legalEntities: LegalEntity[]) => {
  return multiSelectData.map((id) => ({
    label: legalEntities.find((entity) => entity._id === id)?.entityName,
    value: id,
  }));
};

export type DropdownOption = {
  label: string;
  value: string;
};

export const getCheckedCheckboxListItem = (checkboxListData: DropdownOption[], option: DropdownOption) => {
  return checkboxListData?.includes(option);
};
