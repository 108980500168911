import React from 'react';
import { Sidebar, SidebarTopBar, SidebarRoot } from '../atoms/Sidebar';
// import AddLegalEntityForm from '../onboarding/AddLegalEntityForm';
import AddLegalEntityFormForSidebar from '../onboarding/2.0/AddLegalEntityFormForSidebar';

export function AddEntity({ onClose }) {
  const dataCy = 'addLegalEntity';
  return (
    <SidebarRoot>
      <Sidebar data-cy={dataCy} rootId='add-entity-sidebar'>
        <SidebarTopBar data-cy={dataCy} testId='add-entity-sidebar__header' onClose={onClose} />
        <AddLegalEntityFormForSidebar data-cy={dataCy} onCancel={onClose} />
      </Sidebar>
    </SidebarRoot>
  );
}
