import { LeadingCellProps } from 'ui/src/table-cells/types';
import { useDensity } from 'src/context';
import { LeadingCell } from 'ui';

export const ColumnLeadingCell = (props: LeadingCellProps) => {
  const { density } = useDensity();
  const densityHeight = {
    compact: 'h-[36px]',
    default: 'h-[48px]',
    comfortable: 'h-[64px]',
  }[density];
  return <LeadingCell {...props} densityHeight={densityHeight} />;
};
