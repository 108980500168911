import { useContext, createContext, useState, useEffect } from 'react';

import { produce } from 'immer';
import { useSearch } from '../../../../../hooks';
import { ConditionsSelectorState } from './types';

const getDefaultStateForConditionSelector = (parentId = ''): ConditionsSelectorState => ({
  history: [],
  parentId,
  selected: {
    keys: [],
  },
});

const ConditionSelectorContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  push: (currentMenu: string) => {},
  pop: () => {},
  reset: () => {},
  state: getDefaultStateForConditionSelector(),
  searchTerm: '',
  debouncedSearchTerm: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  setSearchTerm: (v: string) => {},
  closeSelector: () => {},
  addNewAsNested: false,
});

export const useConditionSelectorState = (parentId: string) => {
  const [state, setState] = useState<ConditionsSelectorState>(getDefaultStateForConditionSelector(parentId));
  const { searchTerm, debouncedSearchTerm, setSearchTerm } = useSearch(0);

  // useEffect(() => {
  //   console.log('ConditionsSelectorState', state);
  // }, [state]);

  useEffect(() => {
    setState(getDefaultStateForConditionSelector(parentId));
  }, [parentId]);

  const push = (currentMenu: string) =>
    setState((prev) =>
      produce(prev, (draft) => {
        if (draft.currentMenu) draft.history.push(draft.currentMenu);
        draft.currentMenu = currentMenu;
      }),
    );

  const pop = () =>
    setState((prev) =>
      produce(prev, (draft) => {
        if (draft.history.length) {
          draft.currentMenu = draft.history.pop();
        } else {
          delete draft.currentMenu;
        }
      }),
    );

  const reset = () => setState((prev) => getDefaultStateForConditionSelector(prev.parentId));

  return { push, pop, reset, state, searchTerm, debouncedSearchTerm, setSearchTerm };
};

export const useConditionSelectorContext = () => useContext(ConditionSelectorContext);
export const ConditionSelectorContextProvider = ConditionSelectorContext.Provider;
