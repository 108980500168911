import {
  CheckboxListItem,
  DropdownGroup,
  DropdownGroupBody,
  DropdownGroupTitle,
  capitalizeFirstLetter,
  getLeftIcon,
} from 'ui';
import { useClassificationFilterContext } from '../../../../context';

export const ClassificationFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useClassificationFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Transaction Categories' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((option, i) => (
            <CheckboxListItem
              key={i}
              enableIcon
              icon={getLeftIcon(option.value.toUpperCase())}
              label={capitalizeFirstLetter(option.value.toLowerCase())}
              checked={option.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No transaction categories to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
