import axios from 'axios';
import { SERVER_URL_CORE } from '../../config';
import { TableGroupsGroup } from 'schemas';

export type TableGroups = {
  _id: string;
  table: string;
  organizationId: string;
  groups: TableGroupsGroup[];
};

export type getTableGroupPayload = {
  table?: string;
};

export const getTableGroups = async (tableName: string) => {
  return axios.get<{ tableGroups: TableGroups }>(`${SERVER_URL_CORE}/table-groups?table=${tableName}`);
};

export type SaveTableGroupsFnParams = {
  groups: Partial<TableGroupsGroup>[];
  table: string;
};

export const saveTableGroups = async (data: SaveTableGroupsFnParams) => {
  return axios.post(`${SERVER_URL_CORE}/table-groups?table=${data.table}`, { tableGroups: data });
};
