import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';
import { ProductWithPrice, getStripeProductByPriceId } from 'services';
import { useSession } from '../../../useSession';

export const useGetStripeSubscriptionPlanFromQuoteIdQuery = (quoteId) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.STRIPE_SUBSCRIPTION_PLAN_FROM_QUOTE_ID),
    async () => {
      const response = await getStripeProductByPriceId(quoteId);
      const productWithPrices = response.data.product;

      const productWithPrice: ProductWithPrice = {
        product: productWithPrices.product,
        price: productWithPrices.prices.find((price) => price.id === quoteId)!,
      };
      return productWithPrice;
    },
    { enabled: !!quoteId && quoteId.length > 0 && !!organizationId },
  );
};
