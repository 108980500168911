import {
  useGetAllStripeProductsQuery,
  useGetCardsFromStripeQuery,
  useGetStripeDetailsForOrgQuery,
  useInvalidateQuery,
  useSession,
  useUpdatePaymentMethodForStripeSubscriptionMutation,
  useUpdateSubscriptionCadenceMutation,
} from '../../../../hooks';
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  InputLabel,
  LoaderIcon,
  ModalContent,
  SelectableCardWithRadio,
  SingleSelectMenu,
} from 'ui';
import { PaymentCard } from '../../common';
import toast from 'react-hot-toast';
import { deriveError } from '../../../templates/utils';

const BILLING_CADENCE_OPTIONS = [
  { label: 'Monthly', value: 'month' },
  { label: 'Yearly', value: 'year' },
];

enum EDIT_SUBSCRIPTION_MODAL_STEP {
  FORM,
  LOADING,
  SUCCESS,
  ERROR,
}

export const EditSubscriptionModalContent = ({ subscriptionPlan, onCancel, disabled, onUpgrade, isLoading }) => {
  const [step, setStep] = useState(EDIT_SUBSCRIPTION_MODAL_STEP.FORM);
  const { data: stripeDetails, isLoading: isLoadingStripeDetails } = useGetStripeDetailsForOrgQuery();
  const { data: stripeCards, isLoading: isLoadingStripeCards } = useGetCardsFromStripeQuery();
  const { organizationId } = useSession();
  const defaultPaymentMethodId = stripeDetails?.paymentMethod?.id;
  const defaultCadence = stripeDetails?.subscription?.items.data[0].price.recurring?.interval;

  const [cadence, setCadence] = useState(BILLING_CADENCE_OPTIONS.find((option) => option.value === defaultCadence));

  useEffect(() => {
    setCadence(BILLING_CADENCE_OPTIONS.find((option) => option.value === defaultCadence));
  }, [defaultCadence]);

  useEffect(() => {
    setPaymentMethod(defaultPaymentMethodId);
  }, [defaultPaymentMethodId]);

  const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethodId);
  const { mutateAsync: updatePaymentMethod } = useUpdatePaymentMethodForStripeSubscriptionMutation();
  const { mutateAsync: updateSubscriptionCadence } = useUpdateSubscriptionCadenceMutation();
  const { invalidateStripeDetailsForOrg, invalidateStripeInvoices, invalidateUsage } = useInvalidateQuery();

  const { data: subscriptionProducts, isLoading: isLoadingSubscriptionProducts } = useGetAllStripeProductsQuery({
    productType: 'subscription',
  });

  const updateSubscriptionPlan = async () => {
    try {
      setStep(EDIT_SUBSCRIPTION_MODAL_STEP.LOADING);
      if (cadence && defaultCadence && cadence.value !== defaultCadence && subscriptionProducts)
        await updateSubscriptionCadence({ organizationId, cadence: cadence.value });

      if (paymentMethod && defaultPaymentMethodId !== paymentMethod)
        await updatePaymentMethod({ paymentMethodId: paymentMethod });
      setStep(EDIT_SUBSCRIPTION_MODAL_STEP.SUCCESS);
      setTimeout(async () => {
        await invalidateStripeDetailsForOrg();
        await invalidateStripeInvoices();
        await invalidateUsage();
      }, 3000);
    } catch (error) {
      setStep(EDIT_SUBSCRIPTION_MODAL_STEP.ERROR);
      toast.error(deriveError(error));
    }
  };

  const onClose = () => {
    setCadence(BILLING_CADENCE_OPTIONS.find((option) => option.value === defaultCadence));
    setPaymentMethod(defaultPaymentMethodId);
    setStep(EDIT_SUBSCRIPTION_MODAL_STEP.FORM);
    onCancel();
  };

  const title = step === EDIT_SUBSCRIPTION_MODAL_STEP.FORM ? 'Edit subscription' : undefined;

  return (
    <ModalContent
      title={title}
      onClose={step !== EDIT_SUBSCRIPTION_MODAL_STEP.LOADING ? onClose : undefined}
      actions={
        step === EDIT_SUBSCRIPTION_MODAL_STEP.FORM || step === EDIT_SUBSCRIPTION_MODAL_STEP.ERROR
          ? [
              {
                label: 'Update',
                onClick: updateSubscriptionPlan,
                isLoading: isLoading || isLoadingSubscriptionProducts,
                disabled,
              },
              {
                label: 'Cancel',
                onClick: onClose,
                emphasis: 'medium',
                disabled,
              },
            ]
          : []
      }
    >
      {step === EDIT_SUBSCRIPTION_MODAL_STEP.FORM && (
        <div className='grid grid-cols-1 gap-6'>
          <div>
            <InputLabel heading='Current plan' />
            {subscriptionPlan ? (
              <Card>
                <div className='flex items-center justify-between'>
                  <span className='flex flex-col items-start'>
                    <span className='text-lg font-semibold'>{subscriptionPlan.label} </span>
                    <span>{subscriptionPlan.description} </span>
                  </span>
                  <Button label='Upgrade' onClick={onUpgrade} emphasis='medium' />
                </div>
              </Card>
            ) : (
              <Card>
                <div className='flex items-center justify-between'>
                  <div>No subscription plan active</div>
                  <Button label='Choose' onClick={onUpgrade} emphasis='medium' />
                </div>
              </Card>
            )}
          </div>
          <div>
            <InputLabel heading='Billing cadence' />

            <SingleSelectMenu
              isOnSidepanel
              fullWidth
              options={BILLING_CADENCE_OPTIONS}
              value={cadence}
              onChange={(value) =>
                setCadence(
                  value as {
                    label: string;
                    value: string;
                  },
                )
              }
              isLoading={isLoadingStripeDetails}
              disabled={!subscriptionPlan}
            />
          </div>
          <div>
            <InputLabel heading='Payment method' />
            <div className='flex flex-col gap-y-6'>
              {isLoadingStripeCards && !stripeCards && <LoaderIcon />}
              {stripeCards?.length === 0 && <div className='text-sm'>No payment methods found</div>}
              {stripeCards?.map((data, i) =>
                data.card ? (
                  <SelectableCardWithRadio
                    key={i}
                    selected={paymentMethod === data.id}
                    onClick={() => setPaymentMethod(data.id)}
                    label={
                      <PaymentCard
                        className='p-0 border-0'
                        brand={data.card.brand}
                        last4={data.card.last4}
                        expiresOn={`${data.card.exp_month}/${`${data.card.exp_year}`.slice(-2)}`}
                        paymentMethodId={data.id}
                        hideRemove
                      />
                    }
                  />
                ) : null,
              )}
            </div>
          </div>
        </div>
      )}
      {step === EDIT_SUBSCRIPTION_MODAL_STEP.LOADING && (
        <DescriptiveLoader inModal title='Processing' description='Updating subscription related details' />
      )}
      {step === EDIT_SUBSCRIPTION_MODAL_STEP.SUCCESS && (
        <DescriptiveLoader
          inModal
          title='Success'
          status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
          description='Subscription updated'
        />
      )}
    </ModalContent>
  );
};
