import { useQuery } from '@tanstack/react-query';
import { getAssetTypes } from 'services/http';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useAssetTypes = (params: { includeSpam?: boolean; spamOnly?: boolean } = {}) => {
  const getKey = useOrgBasedQueryKey();

  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.ASSET_TYPES, params),
    async () => {
      const response = await getAssetTypes(params);
      return response.data.assetTypes;
    },
    { enabled: !!organizationId },
  );
};
