import { RequestConfigFactory } from './axios-request-config-factory';
import { ExtendedAxiosRequestConfig } from './types';

/**
 * Enhance the Axios request configuration by applying custom transformations.
 * @param {ExtendedAxiosRequestConfig} config - The original Axios request configuration.
 * @returns {ExtendedAxiosRequestConfig} The transformed Axios request configuration.
 */
export function onRequest(config: ExtendedAxiosRequestConfig): ExtendedAxiosRequestConfig {
  try {
    // Create a new RequestConfigFactory instance to manage the configuration.
    const factory = new RequestConfigFactory(config);

    // Apply transformations and return the updated configuration.
    return factory.transform().getConfig()
  } catch (error) {
    console.error('Error processing request configuration:', error);
    // Optionally, you could rethrow the error or handle it as needed.
    return config; // Return the original config if error occurs
  }
}
