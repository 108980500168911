import { useEffect, useState } from 'react';
import { TimezoneRowData, TimezoneRowProps } from './types';
import { getCityFromTimezoneName, getShortLabelForTimezone, getStateFromTimezoneName } from './utils';
import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';

const defaultRowState: TimezoneRowData = {
  city: '',
  state: '',
  shortTimezoneLabel: '',
  timezoneLabel: '',
  gmtLabel: '',
  currentTime: '',
};

export const TimezoneRow = ({ timezone, onSelect }: TimezoneRowProps) => {
  const [state, setState] = useState(defaultRowState);
  useEffect(() => {
    setState({
      city: getCityFromTimezoneName(timezone),
      state: getStateFromTimezoneName(timezone),
      shortTimezoneLabel: getShortLabelForTimezone(timezone),
      timezoneLabel: formatInTimeZone(new Date(), timezone, 'zzzz', { locale: enGB }),
      gmtLabel: formatInTimeZone(new Date(), timezone, 'OOO'),
      currentTime: formatInTimeZone(new Date(), timezone, 'hh:mmaaa', { locale: enGB }),
    });
  }, [timezone]);

  return (
    <div
      className='w-full p-2 rounded flex items-start justify-between hover:bg-indigo-50 font-normal hover:cursor-pointer'
      onClick={() => onSelect(timezone)}
    >
      <div>
        <div className='font-semibold'>
          {state.city}, {state.state}
        </div>
        <div className='text-xs'>
          <span>
            {state.timezoneLabel} {state.shortTimezoneLabel ? `- ${state.shortTimezoneLabel}` : ''}
          </span>{' '}
          <span className='text-gray-500'>({state.gmtLabel})</span>
        </div>
      </div>
      <div className='text-gray-600 font-medium'>{state.currentTime}</div>
    </div>
  );
};
