import { TAG_TYPES } from 'ui';

export const tagsOptions = [
  {
    label: 'Customer',
    value: '',
    backgroundColor: '#0000FF14',
    edit: true,
  },
  {
    label: 'Supplier',
    value: '',
    backgroundColor: '#FFA5003D',
    edit: true,
  },
  {
    label: 'System',
    value: '',
    backgroundColor: '#00800026',
    edit: true,
  },
  {
    label: 'ID',
    value: '',
    backgroundColor: '#80008026',
    edit: true,
  },
  {
    label: 'Bank Account',
    value: '',
    backgroundColor: '#1111111A',
    edit: true,
  },
];

export const TAG_TYPE_OPTIONS = Object.values(TAG_TYPES).map((tagType) => ({
  label: tagType,
  value: tagType,
}));

export const JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS = [
  {
    label: 'Auto submit',
    value: 'POSTED',
  },
  {
    label: 'Keep in draft',
    value: 'DRAFT',
  },
];
