import short from 'short-uuid';
import { produce } from 'immer';
import { GATE_TYPE, RuleConfiguratorState } from '../../types';

const translator = short();

export const addNestedCondition = (state: RuleConfiguratorState, action) => {
  // console.log('addNestedCondition');
  return produce(state, (draft) => {
    // CONDITION GROUP
    const conditionGroupId = translator.new().toString();
    const parentId = action.payload.condition.parentId;
    // add to conditionGroups
    draft.conditionGroups[conditionGroupId] = {
      id: conditionGroupId,
      childIds: [],
      gates: [],
      parentId,
    };
    // add to conditionGroupIds
    draft.conditionGroupIds.splice(action.payload.at ?? draft.conditionGroupIds.length, 0, conditionGroupId);

    // add to parent
    if (parentId) {
      draft.conditionGroups[parentId].childIds.splice(
        action.payload.at ?? draft.conditionGroups[parentId].childIds.length,
        0,
        conditionGroupId,
      );
    }
    // add gate to parent
    draft.conditionGroups[parentId].gates.push(GATE_TYPE.AND);

    // CONDITION
    const id = translator.new().toString();
    // add to conditions
    draft.conditions[id] = {
      ...action.payload.condition,
      parentId: conditionGroupId,
      id,
    };
    // add to conditionIds
    draft.conditionIds.push(id);
    // add to parent
    draft.conditionGroups[conditionGroupId].childIds.push(id);
    // add gate to parent
    draft.conditionGroups[conditionGroupId].gates.push(GATE_TYPE.AND);
  });
};
