import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { createLoopConfiguration, getLoopConfiguration, getLoopRulesMeta, importLoopTransactions } from 'services';
export const useLoopIntegrationConfig = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.LOOP_INTEGRATION_CONFIGURATION),
    async () => {
      const response = await getLoopConfiguration();
      return response.data.configuration;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
    },
  );
};

export const useCreateLoopConfiguration = () => {
  const { organizationId } = useSession();
  return useMutation(async (body: any) => {
    return await createLoopConfiguration({ ...body, organizationId });
  });
};

export const useImportLoopTransactions = () => {
  const { organizationId, userId } = useSession();
  return useMutation(async () => await importLoopTransactions({ organizationId, userId }));
};

export const useLoopRulesMeta = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  return useQuery(
    getKey(QUERY_KEY.LOOP_RULE_META),
    async () => {
      const response = await getLoopRulesMeta();
      return response.data.values;
    },
    {
      enabled: !!organizationId,
    },
  );
};
