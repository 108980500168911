import styled from 'styled-components';
import { XIcon } from '../assets/generated/svg';

export const StyledTagsInput = styled.input`
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
`;

export const StyledComponentContainer = styled.div`
  /* Text Field */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const StyledTagsInputContainer = styled.div`
  /* Field */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  border: 1px solid #c4c4c4;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledTag = styled.div`
  /* Tag */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px 2px 8px;
  gap: 4px;

  border: 1px solid #c9c9c9;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const StyledTagText = styled.span`
  /* Label */

  width: 112px;
  height: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #111111;

  opacity: 0.8;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledTagsWrapper = styled.div`
  /* Tags */

  /* Auto layout */

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const StyledXIcon = styled(XIcon)`
  /* Icon */
  opacity: 0.5;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`;
