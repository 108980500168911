import { createContext, useState } from 'react';

export const CommandContext = createContext<{
  open: boolean;
  setOpen: (open: boolean) => void;
}>({
  setOpen: (data) => data,
  open: false,
});

export const CommandProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  return <CommandContext.Provider value={{ open, setOpen }}>{children}</CommandContext.Provider>;
};
