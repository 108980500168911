import React from 'react';
import { Button } from 'ui';
import { useGetAllSourcesFn } from '../../../hooks';
import { useSourceQueryParams } from './use-source-query-params';
import { useMutation } from '@tanstack/react-query';
import { downloadCsv, withCsvToasts } from '../../../lib/utils/download-csv';
import { convertSources, removeEmpty } from '../../templates/utils';
import { getWallets } from 'services';

export const SourcesCSV = ({ searchTerm, sortState }) => {
  const sourcesParams = useSourceQueryParams({ searchTerm, sortState });
  const getAllSourcesFn = useGetAllSourcesFn(sourcesParams);

  const { isLoading: isPreparingCsv, mutateAsync } = useMutation({
    mutationFn: async () => {
      const allSources = await getAllSourcesFn();

      let allTxs: any = [];
      const perQuery = 3000;
      const totalQuery = Math.max(1, allSources.length / perQuery);

      for (let i = 0; i < totalQuery; i += 1) {
        const response = await getWallets(
          removeEmpty({
            page: i,
            walletTypes: sourcesParams.walletTypes,
            chains: sourcesParams.chains,
            nameOrAddress: searchTerm !== '' ? searchTerm : undefined,
            pageSize: perQuery,
          }),
        );
        const newTexs = response.data?.wallets;
        allTxs = [...allTxs, ...newTexs];
      }

      const data = convertSources(allTxs);
      downloadCsv(data, `sources-export-${new Date().toLocaleDateString()}.csv`);
    },
  });

  return (
    <Button
      label='Download'
      emphasis='medium'
      onClick={withCsvToasts(mutateAsync)}
      isLoading={isPreparingCsv}
      disabled={isPreparingCsv}
    />
  );
};

export default SourcesCSV;
