import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { AssetCondition } from './AssetCondition';
import { DialogPrimitive } from 'ui';
import { MdArrowDropDown } from 'react-icons/md';

const G = createGlobalStyle`
[data-disabled] {
  background-color: #fff !important;
  pointer-events: none;
  opacity: .5;
}
`;

export const AssetInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <G />
      <DialogPrimitive.Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogPrimitive.DialogTrigger
          disabled={isDisabled}
          className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
        >
          <div className='mr-2 flex h-full justify-center items-center content-center'>{rule.value}</div>
          <MdArrowDropDown className='w-6 h-6 text-black' />
        </DialogPrimitive.DialogTrigger>
        <DialogPrimitive.DialogContent className='max-h-[390px] overflow-auto px-2'>
          <AssetCondition isInLineCondition callbackFn={callbackFn} closeDialog={closeDialog} />
        </DialogPrimitive.DialogContent>
      </DialogPrimitive.Dialog>
    </>
  );
};
