import { DetailsItem } from '../../components/atoms';
import { formatRelative } from 'date-fns';
export const renderRaincardsTransactionDetails = (transaction) => {
  if (transaction?.sourceType !== 'RAINCARD') return <></>;
  const firstname = `${transaction?.meta?.cardholderFirstName}`;
  const lastName = `${transaction?.meta?.cardholderLastName}`;
  let fullname = firstname && lastName && `${firstname} ${lastName}`;
  const nameDetail = <DetailsItem tag='Cardholder name' value={fullname} />;
  const cardNicknameDetail = <DetailsItem tag='Card nickname' value={transaction?.meta?.cardName} />;
  const last4 = <DetailsItem tag='Last 4' value={transaction?.meta?.last4} />;
  const category = <DetailsItem tag='Category' value={transaction?.meta?.category?.replace('_', ' ')} />;
  const cardType = <DetailsItem tag='Card type' value={transaction?.meta?.cardType} />;
  const transactionType = <DetailsItem tag='Transaction type' value={transaction?.meta?.type} />;
  const merchant = <DetailsItem tag='Merchant' value={transaction?.meta?.merchantName} />;
  return (
    <>
      {cardNicknameDetail}
      {transaction?.meta?.cardholderFirstName && nameDetail}
      {last4}
      {category}
      {merchant}
      {transactionType}
      {cardType}
    </>
  );
};

export const convertRaincardJobHistoryData = (history) => {
  return history.map((data) => {
    return {
      jobName: data?.jobConfigurationId?.jobName,
      createdBy: data?.createdBy?.email,
      createdAt: formatRelative(new Date(data?.createdAt), new Date()),
      jobStatus: data.jobStatus,
      toProcessCount: data.toProcessCount,
      completedCount: data.completedCount,
    };
  });
};
