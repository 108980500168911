import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { DialogContent, InputLabel, SimpleMenu } from 'ui';
import { CLASSIFICATION_ROW } from '../../../filters';
import { useBulkRecategorizeTransactions, useInvalidateQuery } from '../../../../hooks';
import { deriveError } from '../../../templates/utils';

export const UpdateTransactionCategoryModalContent = ({ transactionIds, onSuccess = () => {}, onOpenChange }) => {
  const { invalidateTransactions } = useInvalidateQuery();
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();
  const { mutateAsync: bulkCategorizeTransactions, isLoading } = useBulkRecategorizeTransactions();

  return (
    <DialogContent
      actions={[
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              await bulkCategorizeTransactions({
                transactionIds,
                classification: selectedOption?.value || '',
              });
              onOpenChange(false);
              toast.success(`${transactionIds.length === 1 ? 'Transaction' : 'Transactions'} recategorized`);
              onSuccess();
              await invalidateTransactions();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
          disabled: isLoading,
          isLoading: isLoading,
        },
        {
          label: 'Cancel',
          emphasis: 'medium',
          onClick: () => {
            onOpenChange(false);
            setSelectedOption(undefined);
          },
        },
      ]}
    >
      <div>
        <InputLabel heading='Categories' />
        <SimpleMenu
          options={Object.keys(CLASSIFICATION_ROW).map((key) => ({
            label: CLASSIFICATION_ROW[key],
            value: key,
          }))}
          onChange={(option) => setSelectedOption(option)}
          value={selectedOption}
        />
      </div>
    </DialogContent>
  );
};
