export enum ACTION_TYPE {
  ADD_CONDITION_GROUP = 'ADD_CONDITION_GROUP',
  UPDATE_CONDITION_GROUP = 'UPDATE_CONDITION_GROUP',
  REMOVE_CONDITION_GROUP = 'REMOVE_CONDITION_GROUP',
  REORDER_CONDITION_GROUPS = 'REORDER_CONDITION_GROUPS',

  UPDATE_GATE = 'UPDATE_GATE',

  ADD_CONDITION = 'ADD_CONDITION',
  ADD_NESTED_CONDITION = 'ADD_NESTED_CONDITION',
  UPDATE_CONDITION = 'UPDATE_CONDITION',
  REMOVE_CONDITION = 'REMOVE_CONDITION',
  REORDER_CONDITION_GROUP_CONDITIONS = 'REORDER_CONDITION_GROUP_CONDITIONS',
  MOVE_CONDITION_TO_CONDITION_GROUP = 'MOVE_CONDITION_TO_CONDITION_GROUP',

  CREATE_COPY = 'CREATE_COPY',
  PROCESS_COPY = 'PROCESS_COPY',

  COPY_CONDITION = 'COPY_CONDITION',
  COPY_CONDITION_GROUP = 'COPY_CONDITION_GROUP',

  CLEAR_ALL = 'CLEAR_ALL',
  SYNC = 'SYNC',
}
