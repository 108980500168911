import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
export type GetDistinctFunctionNamesParams = {
  organizationId: string;
};
export const getDistinctFunctionNames = async ({ organizationId }: GetDistinctFunctionNamesParams) => {
  return await axios.get(
    `${SERVER_URL_CONFIGURATION_SERVICE}/distinct-function-names?organizationId=${organizationId}`,
  );
};
