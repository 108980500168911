import { useMemo } from 'react';
import { useWalletFilterContext } from '../context';

export const useFilteredWalletIds = () => {
  const { state } = useWalletFilterContext();
  return useMemo(
    () =>
      state
        .filter((option) => option.chain !== 'HEDGEY' && option.chain !== 'Exchange' && option.chain !== 'Raincard')
        .filter((option) => option.selected)
        .map((option) => option._id),
    [state],
  );
};
