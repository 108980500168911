import { useEffect, useState } from 'react';
import { useConditionSelectorContext } from '../condition-selector-context';
import { ConditionGroupFilterConfig, ConditionSelectorRowData } from '../types';
import { appendIsSelectedOnRowsFn } from './append-is-selected-on-row-fn';
import { defaultConverToRowFn } from './default-convert-to-row-fn';

export const useFilteredConditionGroups = (
  conditionGroups: Omit<ConditionGroupFilterConfig, 'rows'>[],
  // from?: string,
) => {
  const { state, debouncedSearchTerm } = useConditionSelectorContext();
  const [conditionsGroupsData, setConditionGroupsData] = useState<{
    headings: string[];
    values: ConditionSelectorRowData[][];
    counts: number[];
    components: React.FC<{ conditionData: any }>[];
  }>({ headings: [], values: [], counts: [], components: [] });

  useEffect(() => {
    const selectedValue = state.selected.value;

    const headings: string[] = [];
    const values: ConditionSelectorRowData[][] = [];
    const counts: number[] = [];
    const components: React.FC<{ conditionData: any }>[] = [];

    conditionGroups.forEach((conditionGroup) => {
      const appendIsSelected = appendIsSelectedOnRowsFn(selectedValue);
      const filterFn = conditionGroup.filterFn(debouncedSearchTerm);
      const convertToRowFn = conditionGroup.convertToRowFn ?? defaultConverToRowFn;

      let filtered = conditionGroup.data.filter(filterFn);
      if (conditionGroup.skipFn) filtered = conditionGroup.skipFn(filtered);
      // console.log({ filtered });
      const converted = filtered.map(convertToRowFn);
      // console.log({ converted });

      const filteredRows = appendIsSelected(converted);
      // console.log({ filteredRows });

      if (filteredRows.length) {
        headings.push(conditionGroup.heading);
        values.push(filteredRows);
        counts.push(filteredRows.length);
        components.push(conditionGroup.Row);
      }
    });
    // console.log('useFilteredConditionGroups', from, {
    //   debouncedSearchTerm,
    //   headings: conditionGroups.map((g) => g.heading),
    //   filteredHeadings: headings,
    // });
    setConditionGroupsData({ headings, values, counts, components });
  }, [debouncedSearchTerm, conditionGroups, state.selected.value]);

  return conditionsGroupsData;
};
