import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import {
  getAssetsTimestamp,
  getImpairmentsTimestamp,
  getIntegrationsTimestamp,
  getJournalsTimestamp,
  getLedgerAccountsTimestamp,
  getLegalEntitiesTimestamp,
  getReportsTimestamp,
  getRulesetsTimestamp,
  getSourcesTimestamp,
  getTagsTimestamp,
  getTemplatesTimestamp,
  getTransactionsTimestamp,
} from 'services/http';

export const useGetSourcesTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getSourcesTimestamp,
    queryKey: getKey(QUERY_KEY.SOURCES_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetTransactionsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getTransactionsTimestamp,
    queryKey: getKey(QUERY_KEY.TRANSACTIONS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetJournalsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getJournalsTimestamp,
    queryKey: getKey(QUERY_KEY.JOURNALS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetAssetsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getAssetsTimestamp,
    queryKey: getKey(QUERY_KEY.ASSETS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetTemplatesTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getTemplatesTimestamp,
    queryKey: getKey(QUERY_KEY.TEMPLATES_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetRulesetsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getRulesetsTimestamp,
    queryKey: getKey(QUERY_KEY.RULESETS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetTagsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({ queryFn: getTagsTimestamp, queryKey: getKey(QUERY_KEY.TAGS_TIMESTAMP), enabled: !!organizationId });
};
export const useGetImpairmentsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getImpairmentsTimestamp,
    queryKey: getKey(QUERY_KEY.IMPAIRMENTS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetLedgerAccountsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getLedgerAccountsTimestamp,
    queryKey: getKey(QUERY_KEY.LEDGER_ACCOUNTS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetLegalEntitiesTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getLegalEntitiesTimestamp,
    queryKey: getKey(QUERY_KEY.LEGAL_ENTITIES_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetReportsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getReportsTimestamp,
    queryKey: getKey(QUERY_KEY.REPORTS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
export const useGetIntegrationsTimestampQuery = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryFn: getIntegrationsTimestamp,
    queryKey: getKey(QUERY_KEY.INTEGRATIONS_TIMESTAMP),
    enabled: !!organizationId,
  });
};
