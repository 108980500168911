import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { useGetDistinctContractNames } from '../../../../../hooks/http';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  const { data, isLoading } = useGetDistinctContractNames();
  const options = data?.map((item) => ({
    label: item,
    value: item,
  }));
  return (
    <>
      <SingleSelectMenu value={{ label: 'Smart Contract', value: 'Smart Contract' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      <SingleSelectMenu
        options={options}
        value={options?.find((item) => item.value.toLowerCase() === condition.value.toLowerCase())}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </>
  );
};

export const TransactionSmartContractRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION_SMART_CONTRACT]}>
    <Row />
  </ConditionRow>
);
