import { useQuery } from '@tanstack/react-query';
import { getIncomeStatement } from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { AccountingPeriod } from 'services/http/response.types';

export const useIncomeStatement = (
  legalEntityIds?: string[],
  accountingPeriodStartDateUTCs?: string[],
  tagId?: string,
) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.INCOME_STATEMENT, { legalEntityIds, accountingPeriodStartDateUTCs, tagId }),
    async () => {
      const response = await getIncomeStatement({
        organizationId,
        legalEntityIds,
        accountingPeriodStartDateUTCs,
        tagId,
      });
      const { incomeStatement } = response.data;
      return incomeStatement;
    },
    {
      enabled: !!organizationId,
    },
  );
};
export const useIncomeStatementForAccountingPeriod = (accountingPeriod?: AccountingPeriod) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.INCOME_STATEMENT, { accountingPeriodId: accountingPeriod?._id }),
    async () => {
      const response = await getIncomeStatement({
        organizationId,
        accountingPeriodStartDateUTCs: accountingPeriod ? [accountingPeriod.startDateUTC] : [],
        legalEntityIds: [accountingPeriod?.legalEntityId],
      });
      const { incomeStatement } = response.data;
      return incomeStatement;
    },
    {
      enabled: !!organizationId && !!accountingPeriod?._id,
    },
  );
};
