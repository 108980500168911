import { TagProps } from './types';

const getStyles = (emphasis: TagProps['emphasis']) => {
  let styles = {
    container: 'bg-[#dddddd] rounded',
    label: 'text-[#111111] opacity-80',
    icon: 'text-[#111111]',
  };

  if (emphasis === 'high')
    styles = {
      ...styles,
      container: `${styles.container} border border-[#c9c9c9]`,
    };
  if (emphasis === 'medium')
    styles = {
      ...styles,
      container: `${styles.container} border border-[#c9c9c9] bg-white`,
    };
  if (emphasis === 'low')
    styles = {
      ...styles,
      container: `${styles.container} bg-white`,
    };

  return styles;
};
export default getStyles;
