import * as React from 'react';
import { forwardRef } from 'react';
const SvgArrowRightButton = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <rect x={1} y={1} width={54} height={54} rx={7} fill='#fff' />
    <g clipPath='url(#arrow-right-button_svg__a)'>
      <path
        d='M17.333 29.334H33.56l-7.453 7.453L28 38.667 38.667 28 28 17.334l-1.88 1.88 7.44 7.453H17.333v2.667Z'
        fill='#000'
      />
    </g>
    <rect x={1} y={1} width={54} height={54} rx={7} stroke='#E8E8E8' strokeWidth={2} />
    <defs>
      <clipPath id='arrow-right-button_svg__a'>
        <path fill='#fff' transform='translate(12 12)' d='M0 0h32v32H0z' />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgArrowRightButton);
export default ForwardRef;
