import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useTabState } from '../../hooks';
import { FILTER_TYPE } from '../../components/filters';
import { FilterRowState } from './types';
import { getLocalStorageKeyForTabState } from './utils';
export const useFilterState = <T = any>(
  filterType: FILTER_TYPE,
  page: string,
  initialState: FilterRowState<T>[] = [],
) => {
  const { activeTab } = useTabState();
  const activeTabId = useMemo(() => activeTab.id, [activeTab]);

  const [state, setState] = useLocalStorage<FilterRowState<T>[]>(
    getLocalStorageKeyForTabState(activeTabId, filterType, page),
    initialState,
  );

  return { state, setState };
};

export const useTabBasedFilterState = <T = any>(filterType: FILTER_TYPE, page: string, initialState: T) => {
  const { activeTab } = useTabState();
  const activeTabId = useMemo(() => activeTab.id, [activeTab]);

  const [state, setState] = useLocalStorage<T>(
    getLocalStorageKeyForTabState(activeTabId, filterType, page),
    initialState,
  );

  return { state, setState };
};
