import React, { useState } from 'react';
import { Button, InputWithExtras, Section, Card, SectionHeader, capitalizeFirstLetter, StatusIndicator } from 'ui';

import { MetricGroup } from '../../atoms';
import toast from 'react-hot-toast';
import { deriveError, formatDate } from '../../templates/utils';
import { AddDiscountModal, SUBSCRIPTION_MODAL_STEP, SubscriptionModal } from '../modals';
import {
  useCreateAiCreditUsageRecordMutation,
  useGetAiCreditUsageForOrgQuery,
  useGetStripeDetailsForOrgQuery,
} from '../../../hooks';
import { format } from 'date-fns';
import Stripe from 'stripe';
import { BillingMetricCard } from '../common';
import { formatStripeAmount, getStatusIndicatorTypeForSubscription, getSubscriptionInterval } from '../utils';
import { PaymentMethodSymbol } from '../common/payment-method-symbol';

export const CreateAICreditUsageRecord = () => {
  const [count, setCount] = useState('10');
  const { mutateAsync: createAICreditUsageRecord } = useCreateAiCreditUsageRecordMutation();

  const onClick = async () => {
    const toastId = toast.loading('Creating a ai credit usage record');
    try {
      await createAICreditUsageRecord({ aiCreditCount: +count, operationType: 'test' });
      toast.success('Usage record created', { id: toastId });
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  return (
    <div>
      <InputWithExtras value={count} onChange={(e) => setCount(e.target.value)} type='number' />

      <Button label='Create AI credit usage record' onClick={onClick} />
    </div>
  );
};

export const SubscriptionSection = () => {
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();

  const { data: usage } = useGetAiCreditUsageForOrgQuery();

  const isLoading = !stripeDetails || !usage;

  const subscriptionPlan = stripeDetails?.subscription?.items.data[0].price.product as unknown as Stripe.Product;
  const subscriptionInterval = getSubscriptionInterval(stripeDetails?.subscription?.items.data[0].price);
  const subscriptionAmount = stripeDetails?.subscription?.items.data[0].price.unit_amount;

  const wallets = `${usage?.wallets.used} / ${usage?.wallets.limit}`;
  const entities = `${usage?.entities.used} / ${usage?.entities.limit}`;

  const nextBillDate = stripeDetails?.subscription?.current_period_end
    ? `${format(new Date(stripeDetails.subscription.current_period_end * 1000), 'MMM d')} ${
        subscriptionInterval && `(${capitalizeFirstLetter(subscriptionInterval)}ly)`
      }`
    : '';
  const defaultSource = stripeDetails?.paymentMethod;
  const paymentMethod = defaultSource?.card ? (
    <span className='flex items-center gap-x-2'>
      <PaymentMethodSymbol paymentMethodType={defaultSource.card.brand} />
      <span>
        {capitalizeFirstLetter(defaultSource.card.brand)}...
        {defaultSource.card.last4}
      </span>
    </span>
  ) : (
    ''
  );

  const lastUpdatedOn = usage?.lastUpdate ? `Last updated on ${formatDate(new Date(usage.lastUpdate))}` : '';
  const subscriptionPlanName = subscriptionPlan?.name ?? '';
  const subscriptionPlanDescription = subscriptionAmount
    ? `${formatStripeAmount(subscriptionAmount as number)} ${subscriptionInterval}`
    : "No active subscription plan is currently detected. If you have chosen a plan that isn't reflecting here, kindly review the invoices section below to ensure all payments are up to date.";

  return (
    <Card>
      <Section
        heading='Subscription'
        description={lastUpdatedOn}
        headerActions={
          <span className='flex items-center gap-x-2'>
            <AddDiscountModal />
            <SubscriptionModal initialStep={SUBSCRIPTION_MODAL_STEP.UPGRADE} />
          </span>
        }
      >
        <div className='grid grid-cols-1 gap-3 mb-6'>
          <Card>
            <div className='flex justify-between items-center'>
              <SectionHeader
                heading={subscriptionPlanName}
                description={subscriptionPlanDescription}
                variant='md'
                loading={isLoading}
                tag={
                  stripeDetails?.subscription?.status && (
                    <StatusIndicator
                      label={capitalizeFirstLetter(stripeDetails.subscription.status)}
                      type={getStatusIndicatorTypeForSubscription(stripeDetails.subscription.status)}
                    />
                  )
                }
              />
              <SubscriptionModal initialStep={SUBSCRIPTION_MODAL_STEP.EDIT} />
            </div>
          </Card>
          {stripeDetails?.subscription && (
            <MetricGroup className='gap-3 p-0 m-0'>
              <BillingMetricCard label='Wallets' metric={wallets} loading={isLoading} />
              <BillingMetricCard label='Entities' metric={entities} loading={isLoading} />
              {stripeDetails?.subscription && (
                <BillingMetricCard label='Next bill date' metric={nextBillDate} loading={isLoading} />
              )}
              {stripeDetails?.paymentMethod && (
                <BillingMetricCard label='Payment method' metric={paymentMethod} loading={isLoading} />
              )}
            </MetricGroup>
          )}
        </div>
      </Section>
    </Card>
  );
};
