import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { useLegalEntityFilterContext } from '../../../../context';

export const LegalEntitiesFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useLegalEntityFilterContext();

  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Legal Entities' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((legalEntity, i) => (
            <CheckboxListItem
              key={legalEntity._id}
              label={legalEntity.entityName}
              checked={legalEntity.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No legal entities to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
