import { formatInTimeZone } from 'date-fns-tz';
import enGB from 'date-fns/locale/en-GB';
import { getCountriesForTimezone } from 'countries-and-timezones';

export const getCityFromTimezoneName = (timezone: string) => {
  const words = timezone.split('/');
  // the last word is the city
  return words[words.length - 1].replaceAll('_', ' ');
};

export const getStateFromTimezoneName = (timezone: string) => {
  const words = timezone.split('/');
  // the last word is the city
  return words[words.length - 2].replaceAll('_', ' ');
};

export const getShortLabelForTimezone = (timezone: string) => {
  const label = formatInTimeZone(new Date(), timezone, 'zzz');
  if (label.includes('GMT')) return;
  return label;
};

export const searchTermMatchesCountry = (searchTerm: string, timezone: string) => {
  const countries = getCountriesForTimezone(timezone);
  return countries.find(
    (country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      country.id.toLowerCase().includes(searchTerm.toLowerCase()),
  );
};

export const searchTermMatchesTimezoneName = (searchTerm: string, timezone: string) =>
  timezone.toLowerCase().replaceAll('_', ' ').includes(searchTerm.toLowerCase());

export const searchTermMatchesOtherDescriptor = (searchTerm: string, timezone: string) => {
  const city = getCityFromTimezoneName(timezone).toLocaleLowerCase();
  const state = getStateFromTimezoneName(timezone).toLocaleLowerCase();
  const shortTimezoneLabel = getShortLabelForTimezone(timezone)?.toLocaleLowerCase();
  const timezoneLabel = formatInTimeZone(new Date(), timezone, 'zzzz', { locale: enGB }).toLocaleLowerCase();
  const gmtLabel = formatInTimeZone(new Date(), timezone, 'OOO').toLocaleLowerCase();
  const currentTime = formatInTimeZone(new Date(), timezone, 'hh:mmaaa', { locale: enGB }).toLocaleLowerCase();

  const st = searchTerm.toLowerCase();

  return (
    city.includes(st) ||
    state.includes(st) ||
    gmtLabel.includes(st) ||
    shortTimezoneLabel?.includes(st) ||
    timezoneLabel.includes(st) ||
    currentTime.includes(st)
  );
};
