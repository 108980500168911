import axios from 'axios';
import { SERVER_URL_ORG_SERVICE } from '../../config';

export const getOrganizationById = ({ organizationId, token }: { organizationId: string; token?: string }) => {
  console.log('getOrganizationById', organizationId);
  if (token)
    return axios.get(`${SERVER_URL_ORG_SERVICE}/get-organization-by-id?organizationId=${organizationId}`, {
      headers: { Authorization: token },
    });
  return axios.get(`${SERVER_URL_ORG_SERVICE}/get-organization-by-id?organizationId=${organizationId}`);
};
