import { ReactNode, useState } from 'react';
import { BannerContext } from './context';
import { BannerProps, HideBannerFn, ShowBannerFn } from './types';
import { MdClose, MdErrorOutline, MdWarningAmber } from 'react-icons/md';
import { classNames } from 'ui';

const defaultBannerProps: BannerProps = {
  bannerType: 'info',
  message: 'Hi there',
  closeButtonVisible: true,
};

const BannerIcon = (props: { bannerType: BannerProps['bannerType'] }) => {
  if (props.bannerType === 'info') return <MdErrorOutline />;
  if (props.bannerType === 'warning') return <MdWarningAmber />;
  return null;
};

const infoBannerContainerColorClassName = 'bg-[#6366F1]';
const warningBannerContainerColorClassName = 'bg-[#F59E0B]';

const getBannerContainerStyles = (bannerType: BannerProps['bannerType']) => {
  const common = 'px-4 py-2 text-white relative flex items-center justify-center gap-2';
  if (bannerType === 'info') return classNames(common, infoBannerContainerColorClassName);
  if (bannerType === 'warning') return classNames(common, warningBannerContainerColorClassName);
  return '';
};

const BannerAction = (props: { bannerType: BannerProps['bannerType']; label: string; onClick: () => void }) => (
  <button onClick={props.onClick} className='bg-white bg-opacity-25 px-2 py-1 rounded ml-2'>
    {props.label}
  </button>
);

const Banner = (props: BannerProps & { onCloseClick: () => void }) => (
  <div className={getBannerContainerStyles(props.bannerType)}>
    <BannerIcon bannerType={props.bannerType} />
    {props.message}
    {props.action && (
      <BannerAction bannerType={props.bannerType} label={props.action.label} onClick={props.action.onClick} />
    )}
    {props.closeButtonVisible && (
      <button className='absolute right-4' onClick={props.onCloseClick}>
        <MdClose />
      </button>
    )}
  </div>
);

export const BannerProvider = ({ children }: { initialState?: any; children: ReactNode }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [bannerProps, setBannerProps] = useState<BannerProps>(defaultBannerProps);

  const showBanner: ShowBannerFn = (props: BannerProps) => {
    setBannerProps({ ...defaultBannerProps, ...props });
    setIsBannerVisible(true);
  };
  const hideBanner: HideBannerFn = () => setIsBannerVisible(false);

  return (
    <BannerContext.Provider
      value={{
        showBanner,
        hideBanner,
        isBannerVisible,
      }}
    >
      {isBannerVisible && <Banner {...bannerProps} onCloseClick={hideBanner} />}
      {children}
    </BannerContext.Provider>
  );
};
