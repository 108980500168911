import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownHeader,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { MdFilterList } from 'react-icons/md';
import {
  LegalEntitiesFilterRowGroup,
  AccountingPeriodsFilterRowGroup,
  AccountingTreatmentFilterRowGroup,
  ChainsFilterRowGroup,
  DirectionsFilterRowGroup,
  AssetTypesFilterRowGroup,
  WalletsFilterRowGroup,
  TagsFilterRowGroup,
  OriginatedByFilterRowGroup,
  StatusFilterRowGroup,
  ImpairedFilterRowGroup,
  WalletTypesFilterRowGroup,
} from './all-filters-dropdown-row-groups';
import { FILTER_TYPE, FilterRowState } from './types';
import { ClassificationFilterRowGroup } from './all-filters-dropdown-row-groups/ClassificationFilterRowGroup';
import {
  useAccountingPeriodFilterContext,
  useAccountingTreatmentFilterContext,
  useAssetTypeFilterContext,
  useChainFilterContext,
  useClassificationFilterContext,
  useDirectionFilterContext,
  useImpairedFilterContext,
  useJournalSyncFilterContext,
  useLedgerAccountFilterContext,
  useLegalEntityFilterContext,
  useOriginatedByFilterContext,
  useStatusFilterContext,
  useTagFilterContext,
  useWalletFilterContext,
  useWalletTypeFilterContext,
} from '../../../context';

function shouldIncrementActiveFilterTypesCount(state: FilterRowState[]) {
  return state.filter((value) => value.selected).length > 0;
}

const FilterList = ({ visibleFilters }: { visibleFilters: FILTER_TYPE[] }) => (
  <>
    {visibleFilters.includes(FILTER_TYPE.LEGAL_ENTITY) && <LegalEntitiesFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.ACCOUNTING_PERIOD) && <AccountingPeriodsFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.ACCOUNTING_TREATMENT) && <AccountingTreatmentFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.CHAIN) && <ChainsFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.CLASSIFICATION) && <ClassificationFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.DIRECTION) && <DirectionsFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.ASSET_TYPE) && <AssetTypesFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.WALLET) && <WalletsFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.WALLET_TYPE) && <WalletTypesFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.TAG) && <TagsFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.ORIGINATED_BY) && <OriginatedByFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.STATUS) && <StatusFilterRowGroup />}
    {visibleFilters.includes(FILTER_TYPE.IMPAIRED) && <ImpairedFilterRowGroup />}
  </>
);

const AllFiltersDropdownContainer = ({ 'data-cy': dataCy, children }: { 'data-cy'?: string; children: ReactNode }) => {
  const accountingPeriodFilter = useAccountingPeriodFilterContext();
  const accountingTreatmentFilter = useAccountingTreatmentFilterContext();
  const assetTypeFilter = useAssetTypeFilterContext();
  const chainFilter = useChainFilterContext();
  const classificationFilter = useClassificationFilterContext();
  const directionFilter = useDirectionFilterContext();
  const impairedFilter = useImpairedFilterContext();
  const legalEntityFilter = useLegalEntityFilterContext();
  const originatedByFilter = useOriginatedByFilterContext();
  const journalSyncFilter = useJournalSyncFilterContext();
  const statusFilter = useStatusFilterContext();
  const tagFilter = useTagFilterContext();
  const walletFilter = useWalletFilterContext();
  const walletTypeFilter = useWalletTypeFilterContext();
  const ledgerAccountFilter = useLedgerAccountFilterContext();

  const deselectAll = () => {
    accountingPeriodFilter.setters.deselectAll();
    accountingTreatmentFilter.setters.deselectAll();
    assetTypeFilter.setters.deselectAll();
    chainFilter.setters.deselectAll();
    classificationFilter.setters.deselectAll();
    directionFilter.setters.deselectAll();
    impairedFilter.setters.deselectAll();
    legalEntityFilter.setters.deselectAll();
    originatedByFilter.setters.deselectAll();
    journalSyncFilter.setters.deselectAll();
    statusFilter.setters.deselectAll();
    tagFilter.setters.deselectAll();
    walletFilter.setters.deselectAll();
    walletTypeFilter.setters.deselectAll();
    ledgerAccountFilter.setters.deselectAll();
  };
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState(0);

  useEffect(() => {
    let count = 0;
    if (shouldIncrementActiveFilterTypesCount(accountingPeriodFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(accountingTreatmentFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(assetTypeFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(chainFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(classificationFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(directionFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(impairedFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(legalEntityFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(originatedByFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(journalSyncFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(statusFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(tagFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(walletFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(walletTypeFilter.state)) count += 1;
    if (shouldIncrementActiveFilterTypesCount(ledgerAccountFilter.state)) count += 1;

    setSelectedFilterTypes(count);
  }, [
    accountingPeriodFilter.state,
    accountingTreatmentFilter.state,
    assetTypeFilter.state,
    chainFilter.state,
    classificationFilter.state,
    directionFilter.state,
    impairedFilter.state,
    legalEntityFilter.state,
    originatedByFilter.state,
    journalSyncFilter.state,
    statusFilter.state,
    tagFilter.state,
    walletFilter.state,
    walletTypeFilter.state,
    ledgerAccountFilter.state,
  ]);

  const allFiltersDataCy = useMemo(() => `${dataCy}_allFilters`, [dataCy]);

  return (
    <Dropdown data-cy={allFiltersDataCy}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).trigger}
          label='All filters'
          labelContainerClassname='font-medium'
          emphasis='medium'
          //className={classNames(selectedFilterTypes > 0 && 'border-indigo-600')}
          leadingIcon={<MdFilterList className='w-5 h-5' />}
          trailingIcon={
            selectedFilterTypes > 0 && (
              <span className='rounded-full bg-indigo-600 text-white p-3 flex items-center justify-center relative'>
                <span className='absolute'>{selectedFilterTypes}</span>
              </span>
            )
          }
        />
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='All filters' data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).body}>{children}</DropdownBody>

        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(allFiltersDataCy).clearAllButton}
            onClick={deselectAll}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};

export const AllFiltersDropdown = ({
  'data-cy': dataCy,
  visibleFilters,
}: {
  'data-cy'?: string;
  visibleFilters: FILTER_TYPE[];
}) => (
  <AllFiltersDropdownContainer data-cy={dataCy}>
    <FilterList visibleFilters={visibleFilters} />
  </AllFiltersDropdownContainer>
);
