import React, { createContext, useContext, useState, useEffect } from 'react';

export type DensityType = 'compact' | 'default' | 'comfortable';

interface DensityContextType {
  density: DensityType;
  setDensity: (density: DensityType) => void;
}

const DensityContext = createContext<DensityContextType>({ density: 'comfortable', setDensity: () => {} });

export const DensityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [density, setDensity] = useState<DensityType>('comfortable');

  useEffect(() => {
    const storedDensity = localStorage.getItem('densitySetting') as DensityType;
    if (storedDensity) {
      setDensity(storedDensity);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('densitySetting', density);
  }, [density]);

  return <DensityContext.Provider value={{ density, setDensity }}>{children}</DensityContext.Provider>;
};

export const useDensity = () => useContext(DensityContext);
