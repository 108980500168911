import { UserName } from 'schemas';
import { WALLET_TYPE } from './response.types';

export enum GLType {
  QUICKBOOKS = 'QUICKBOOKS',
  XERO = 'XERO',
  NETSUITE = 'NETSUITE',
}

export enum ExchangeSourceType {
  KRAKEN = 'KRAKEN',
  CIRCLE = 'CIRCLE',
  COINBASE_PRIME = 'COINBASE_PRIME',
  COINBASE = 'COINBASE',
  HEDGEY = 'HEDGEY',
  MAPLE = 'MAPLE',
  BINANCE = 'BINANCE',
  GATE = 'GATE',
  MEXC = 'MEXC',
  KUCOIN = 'KUCOIN',
  BYBIT = 'BYBIT',
  GEMINI = 'GEMINI',
  WOO = 'WOO',
}

export type ExternalAccountPayload = {
  organizationId: string;
  glType: GLType;
  realmId: string;
};

export type ExternalLegalEntitiesPayload = {
  organizationId: string;
  glType: GLType;
  realmId: string;
};

export type GlAuthRedirectPayload = {
  organizationId: string;
  subdomain: string;
};

export interface UseAccountingPeriodsPayloadType {
  startDate?: Date;
  endDate?: Date;
  status?: 'Open' | 'Closed';
  pageSize?: number;
  sort?: number;
  page?: number;
}

export interface GetAccountingPeriodsPayloadType extends UseAccountingPeriodsPayloadType {
  organizationId: string;
}

export type GetJournalEntryLinesParamsType = Partial<{
  transactionIds: string[];
  legalEntityIds: string[];
  ledgerAccountIds: string[];
  accountingPeriodStartDateUTC: string;
  // startDate: string;
  // endDate: string;
  pageSize?: number;
  page?: number;
}>;

export type GetJournalEntriesQueryFilterType = Partial<{
  transactionIds: string[];
  legalEntityIds: string[];
  status: string[];
  originatedBy: string[];
  accountingPeriodIds: string[];
  accountingDate: string;
  journalEntryIds: string[];
  startDate: string;
  endDate: string;
  sort: {
    id: string;
    desc: boolean;
  };
  searchTerm: string;
  isForCsvExport: string;
  pageSize: number;
}>;

export interface ColumnSort {
  id: string;
  desc: boolean;
}

export interface UseLedgerAccountPayload {
  pageSize?: number;
  ledgerAccountTypes?: string[]; //'Liability', 'Asset', 'Equity', 'Income', 'Expense'
  isLeaf?: boolean;
  sort?: ColumnSort;
  page?: number;
  ledgerAccountIds?: string[];
  searchTerm?: string;
}

export interface GetLedgerAccountPayloadType extends UseLedgerAccountPayload {
  organizationId?: string;
}

type commonProps = {
  userId?: string;
  organizationId?: string;
  body?: any;
};

export interface useAddExternalSourcePropsType extends commonProps {
  transactionIds: string[];
}

export type GetSourcesQueryFilterType = Partial<{
  chains: string[];
  legalEntityIds: string[];
  userIds: string[];
  pageSize: number;
  walletTypes: WALLET_TYPE[];
  walletIds: string[];
  tags: string[];
  nameOrAddress: string;
  page: number;
  sort: {
    id: string;
    desc: boolean;
  };
}>;

export type UserInLocalStorage = {
  name?: UserName;
  email?: string;
  _id: string;
  jwt: string;
  emailVerified?: boolean;
  createdAt: string;
};

export type BulkDeleteJournalEntryTemplatesParams = {
  templateIds: string[];
};

export type TagWalletsPayload = {
  organizationId: string;
  tagId: string;
  walletIds: string[];
};
