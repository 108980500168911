import { useLocalStorage } from 'usehooks-ts';
import { useWorkspaceTabs } from './useWorkspaceTabs';
import { DEFAULT_WORKSPACE_TABS, DEFAULT_WORKSPACE_TAB_STATE } from '../constants';

export const useTabState = () => {
  const [workspaceTabs, setWorkspaceTabs] = useLocalStorage('workspace', DEFAULT_WORKSPACE_TABS);
  const { getActiveTab, updateTabFiltersState, updateTabSidebarState } = useWorkspaceTabs(
    workspaceTabs,
    setWorkspaceTabs,
  );

  const activeTab = getActiveTab();

  return {
    filtersState: activeTab?.state?.filtersState ?? DEFAULT_WORKSPACE_TAB_STATE.filtersState,
    activeTab,
    state: activeTab?.state ?? DEFAULT_WORKSPACE_TAB_STATE,
    sidebarState: activeTab?.state?.sidebarState ?? DEFAULT_WORKSPACE_TAB_STATE.sidebarState,
    primaryPanel: activeTab?.state?.sidebarState?.primaryPanel ?? {},
    secondRouteStack: activeTab?.state?.sidebarState?.secondRouteStack ?? [],
    secondRouteUnStack: activeTab?.state?.sidebarState?.secondRouteUnStack ?? {},
    updateTabFiltersState,
    updateTabSidebarState,
    getActiveTab,
  };
};

export default useTabState;
