import DatePicker from 'react-datepicker';
import { InputWithExtras } from '../input-with-extras';

import { MdCalendarToday, MdCancel } from 'react-icons/md';
import { DateInputProps } from './types';
import './styles.css';
import { isEqual, isValid } from 'date-fns';
import { InputTrailingAction } from '../input-trailing-action';
import { LoaderIcon } from '../loader-icon';
import { getCalendarStyles } from '../calendar/style';

export const DateInput = ({ date, setDate, isLoading, ...rest }: DateInputProps) => {
  return (
    <div className='[&_.react-datepicker\_\_header]:border-b-0 [&_.react-datepicker\_\_month-text--keyboard-selected]:bg-indigo-600 [&_.react-datepicker\_\_day--keyboard-selected]:bg-indigo-600 [&_.react-datepicker\_\_month-text--disabled]:pointer-events-none [&_.react-datepicker\_\_navigation-icon]:before:border-zinc-300 [&_.react-datepicker\_\_navigation-icon]:before:text-black'>
      <DatePicker
        selected={date}
        onChange={(d) => setDate(d)}
        customInput={
          <InputWithExtras
            leading={isLoading ? <LoaderIcon /> : <MdCalendarToday />}
            trailing={
              date && (
                <InputTrailingAction
                  icon={<MdCancel className='w-5 h-5' />}
                  onClick={() => {
                    setDate(null);
                  }}
                />
              )
            }
          />
        }
        showPopperArrow={false}
        dayClassName={(d) =>
          (isValid(date) && isEqual(d, date as Date) ? getCalendarStyles('default').day_selected : '') +
          ' ' +
          getCalendarStyles('default').day +
          ' w-12 h-12 leading-[3rem] text-base '
        }
        popperClassName='border-none'
        calendarClassName='border-none shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.15)] rounded-lg'
        weekDayClassName={() => 'w-12 text-zinc-500 font-medium'}
        monthClassName={() => 'w-12 h-12 text-base leading-[3rem]'}
        calendarIconClassname='h-6 w-6 border border-zinc-300 rounded-md'
        {...rest}
      />
    </div>
  );
};
