'use client';

import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { StyledCheckbox, StyledIndicator } from '../checkbox';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { classNames } from '../utils';
import { IoMdClose } from 'react-icons/io';
import { Button } from '../button';
import { Avatar } from '../avatar';
import { Toggle } from '../toggle';
import { MdSearch } from 'react-icons/md';
import getStyles from '../status-indicator/getStyles';
import { StatusIndicatorProps } from '../status-indicator';
import { Tooltip } from '../tooltip';
import {
  CheckboxListItemProps,
  DropdownBodyProps,
  DropdownFooterProps,
  DropdownGroupBodyProps,
  DropdownGroupProps,
  DropdownGroupTitleProps,
  DropdownHeaderProps,
  DropdownSearchInputProps,
  DropdownTitleWithToggleProps,
  RadioListItemProps,
} from './types';

const MARGIN_BOTTOM = 24;

const Dropdown = PopoverPrimitive.Root;

const DropdownTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> & {
    enableTooltip?: boolean;
    tooltipContent?: string;
    hideTooltip?: boolean;
  }
>(({ enableTooltip = false, tooltipContent = '', hideTooltip = false, children, ...props }, ref) => (
  // @note: removing asChild will cause a hydration error
  <PopoverPrimitive.Trigger asChild ref={ref} {...props}>
    {enableTooltip ? (
      <div>
        <Tooltip content={tooltipContent} hideTooltip={hideTooltip}>
          {children}
        </Tooltip>
      </div>
    ) : (
      children
    )}
  </PopoverPrimitive.Trigger>
));

const DropdownSubHeader: React.FC<{ className?: string; children: React.ReactNode }> = ({ children, className }) => {
  return <div className={classNames('p-2 sticky top-0 bg-white z-50', className)}>{children}</div>;
};

const DropdownContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    triggerRef?: React.RefObject<HTMLButtonElement>;
    sameWidthAsTrigger?: boolean;
    enableHeightCalculation?: boolean;
    withoutPortal?: boolean;
    contentWidth?: number;
  }
>(
  (
    {
      className,
      contentWidth,
      side = 'bottom',
      align = 'start',
      triggerRef,
      sameWidthAsTrigger = false,
      sideOffset = 4,
      children,
      enableHeightCalculation = true,
      withoutPortal = false,
      ...props
    },
    ref,
  ) => {
    const [maxHeight, setMaxHeight] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    React.useEffect(() => {
      const triggerButton = triggerRef?.current;
      const calculateCollisionBoundary = () => {
        if (triggerButton) {
          const viewportHeight = window.innerHeight;
          const dropdownBottom = triggerButton.getBoundingClientRect().bottom;
          const availableSpace = viewportHeight - dropdownBottom;

          setMaxHeight(availableSpace - MARGIN_BOTTOM);
        }
      };

      triggerButton?.addEventListener('click', calculateCollisionBoundary);

      // Initial calculation
      calculateCollisionBoundary();

      const calculateWidth = () => {
        const triggerButton = triggerRef?.current;
        if (sameWidthAsTrigger && triggerButton) {
          const triggerWidth = triggerButton.getBoundingClientRect().width;
          setWidth(triggerWidth);
        }
      };

      calculateWidth();

      // Create a ResizeObserver instance and observe the triggerButton
      const resizeObserver = new ResizeObserver(calculateWidth);
      if (triggerButton) {
        resizeObserver.observe(triggerButton);
      }

      triggerButton?.addEventListener('resize', calculateWidth);

      return () => {
        triggerButton?.removeEventListener('resize', calculateWidth);
        if (triggerButton) {
          resizeObserver.unobserve(triggerButton);
        }
        triggerButton?.removeEventListener('click', calculateCollisionBoundary);
      };
    }, []);

    if (withoutPortal) {
      return (
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={{ right: 24, left: 24 }}
          style={
            {
              '--radix-popover-content-max-height': triggerRef && enableHeightCalculation ? `${maxHeight}px` : '350px',
              '--radix-popover-content-width':
                sameWidthAsTrigger && triggerRef ? `${width}px` : contentWidth ? `${contentWidth}px` : '256px',
            } as React.CSSProperties
          }
          className={classNames(
            'flex flex-col overflow-hidden max-h-[--radix-popover-content-max-height] w-[--radix-popover-content-width]',
            'z-50 truncate text-elipsis rounded border-none bg-white shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)] outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
          )}
          {...props}
        >
          {children}
        </PopoverPrimitive.Content>
      );
    }

    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={{ right: 24, left: 24 }}
          style={
            {
              '--radix-popover-content-max-height': triggerRef && enableHeightCalculation ? `${maxHeight}px` : '350px',
              '--radix-popover-content-width':
                sameWidthAsTrigger && triggerRef ? `${width}px` : contentWidth ? `${contentWidth}px` : '256px',
            } as React.CSSProperties
          }
          className={classNames(
            'flex flex-col overflow-hidden  max-h-[--radix-popover-content-max-height] w-[--radix-popover-content-width]',
            'z-50 truncate text-elipsis rounded border-none bg-white shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)] outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            side === 'top' && 'max-h-[300px]',
            className,
          )}
          {...props}
        >
          {children}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  },
);
DropdownContent.displayName = 'DropdownContent';

const DropdownHeader: React.FC<DropdownHeaderProps> = ({ title, titleClassName, className }) => (
  <div className={classNames('flex justify-between items-center px-4 py-2 border-b border-b-zinc-100', className)}>
    <p className={classNames('text-zinc-900 font-medium leading-[120%]', titleClassName)}>{title}</p>

    <PopoverPrimitive.Close>
      <IoMdClose className='w-9 h-9 p-2 text-zinc-900' />
    </PopoverPrimitive.Close>
  </div>
);

const DropdownBody: React.FC<DropdownBodyProps> = ({ children, className }) => (
  <div className={classNames('flex-grow overflow-auto', className)}>{children}</div>
);

const DropdownFooter: React.FC<DropdownFooterProps> = ({ children, className }) => (
  <div className={classNames('flex justify-end p-2 border-t border-t-zinc-100', className)}>{children}</div>
);

const DropdownGroup: React.FC<DropdownGroupProps> = ({ children, className }) => (
  <div className={classNames('pb-2 border-b border-b-zinc-200 last:border-b-0', className)}>{children}</div>
);
DropdownGroup.displayName = 'DropdownGroup';

const DropdownGroupTitle: React.FC<DropdownGroupTitleProps> = ({ title, className }) => (
  <h3 className={classNames('text-sm font-medium text-zinc-600 leading-[120%] px-4 py-2', className)}>{title}</h3>
);

const DropdownGroupBody: React.FC<DropdownGroupBodyProps> = ({ children, className }) => (
  <div className={classNames('mb-2 px-2 flex flex-col gap-1', className)}>{children}</div>
);
DropdownGroupBody.displayName = 'DropdownGroupBody';

const CheckboxListItem: React.FC<CheckboxListItemProps> = ({
  label,
  bottomText = '',
  checked,
  onCheckedChange,
  checkBoxClassName = '',
  labelClassName = '',
  enableSelectOnly = true,
  selectOnlyFn = () => {},
  enableAvatar = false,
  enableIcon = false,
  src = '',
  alt = '',
  icon = null,
  size = 'medium',
  isStatusLabel = false,
  containerClassNames = '',
}) => {
  return (
    <div
      className={classNames(
        'overflow-hidden whitespace-nowrap text-ellipsis group relative w-full flex gap-2 items-center rounded px-2 py-2 hover:bg-indigo-50 hover:cursor-pointer',
        checked && 'bg-indigo-50',
        enableAvatar && 'py-1',
        containerClassNames,
      )}
    >
      <div className='flex-shrink-0'>
        <StyledCheckbox
          checked={checked}
          onClick={(event) => event.stopPropagation()}
          onCheckedChange={onCheckedChange}
          className={classNames(
            ' hover:!border-zinc-700 data-[state=checked]:!bg-indigo-600 data-[state=checked]:!border-indigo-600',
            checkBoxClassName,
          )}
        >
          <StyledIndicator>
            <CheckIcon />
          </StyledIndicator>
        </StyledCheckbox>
      </div>
      <div className='flex items-center gap-2 overflow-hidden whitespace-nowrap text-ellipsis'>
        {enableAvatar && <Avatar type='chain' size={size} src={src} alt={alt} />}
        {enableIcon && <span className='flex justify-center items-center [&>svg]:w-6 [&>svg]:h-6'>{icon}</span>}
        <div className='overflow-hidden whitespace-nowrap text-ellipsis w-full'>
          <p
            className={classNames(
              'text-zinc-900 text-base leading-[120%] capitalize overflow-hidden whitespace-nowrap text-ellipsis',
              bottomText && 'font-medium',
              labelClassName,
              isStatusLabel && 'px-1.5 py-1 rounded-md !border-none text-xs font-semibold',
              isStatusLabel && getStyles(label.toLowerCase() as StatusIndicatorProps['type'], 'high').classes.label,
              isStatusLabel && getStyles(label.toLowerCase() as StatusIndicatorProps['type'], 'high').classes.container,
            )}
          >
            {label}
          </p>
          {bottomText && (
            <p className='capitalize overflow-hidden whitespace-nowrap text-ellipsis text-zinc-500'>{bottomText}</p>
          )}
        </div>
      </div>

      {enableSelectOnly && (
        <div className='hidden absolute top-1/2 -translate-y-1/2 right-4 group-hover:block'>
          <Button
            label={<span className='text-blue-500'>Only</span>}
            variant='sm'
            emphasis='low'
            className='!rounded-full px-1.5 group-hover:bg-indigo-50 h-auto'
            disableRingOnFocus
            onClick={selectOnlyFn}
          />
        </div>
      )}
    </div>
  );
};

const RadioListItem: React.FC<RadioListItemProps> = ({
  label,
  checked,
  onCheckedChange,
  radioInputClassName = '',
  labelClassName = '',
}) => {
  return (
    <div
      className='flex items-center gap-2 rounded px-2 py-1.5 hover:bg-indigo-50 hover:cursor-pointer'
      onClick={() => {
        if (onCheckedChange) onCheckedChange(!checked);
      }}
    >
      <span>
        <input
          type='radio'
          checked={checked}
          className={classNames(
            'form-radio w-5 h-5 border',
            checked ? 'border-indigo-600' : 'border-zinc-200',
            radioInputClassName,
          )}
          onChange={(e) => {
            if (onCheckedChange) onCheckedChange(e.target.checked);
          }}
        />
      </span>

      <p className={classNames('text-zinc-900 text-base leading-[120%]', labelClassName)}>{label}</p>
    </div>
  );
};

const DropdownTitleWithToggle: React.FC<DropdownTitleWithToggleProps> = ({
  className = '',
  title,
  titleClassName,
  checked,
  onChange,
}) => {
  return (
    <div className={classNames('flex items-center justify-between px-4 my-4', className)}>
      <p className={classNames('text-zinc-900 font-medium leading-[120%]', titleClassName)}>{title}</p>
      <Toggle checked={checked} onChange={onChange} />
    </div>
  );
};

const DropdownSearchInput: React.FC<DropdownSearchInputProps> = ({ onChange, ...rest }) => {
  return (
    <div className='relative'>
      <span className='absolute top-1/2 left-3 -translate-y-1/2'>
        <MdSearch className='w-5 h-5 text-zinc-500' />
      </span>
      <input
        value={rest.value}
        className={classNames(
          'w-full h-10 rounded-lg border border-zinc-300 py-2 px-3 pl-[46px] pr-[26px] shadow-none block',
          'placeholder:text-zinc-500 hover:outline-zinc-500',
          'focus:outline-indigo-600 focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent focus:ring-indigo-600 focus:ring-opacity-50',
        )}
        onChange={onChange}
      />
    </div>
  );
};

export {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
  DropdownHeader,
  DropdownFooter,
  DropdownBody,
  DropdownGroup,
  DropdownGroupTitle,
  DropdownGroupBody,
  CheckboxListItem,
  RadioListItem,
  DropdownTitleWithToggle,
  DropdownSubHeader,
  DropdownSearchInput,
};
