import React, { memo, useContext, useState } from 'react';
import { TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { SidebarGlobalContext, TABLE_TYPE } from '../../../context';
import { TransactionsCSV } from './TransactionsCSV';
import { mapTableKeyToOriginalKey } from 'src/components/utils';
import { EditColumns } from 'src/components/tables/table-components';
import { transactionsColumns } from 'src/components/tables';
import Table from 'src/components/tables/tanstack-table/Table';
import { DensityDropdown } from 'src/components/tables/table-components';

export const TransactionsTable = ({
  setSearchTerm,
  searchTerm,
  isSearching,
  displayedTransactions,
  fetchNextPage,
  onSortingChange,
  isLoading,
  isFetchingNextPage,
  tableClassName = '',
  showSearch = true,
  showEditTableColumns = true,
  showExportCSV = true,
}) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const [columns, setColumns] = useState(transactionsColumns);

  const handleColumnsUpdate = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  return (
    <TableWrapper className={tableClassName}>
      <Table
        onRowClick={(row) => {
          openSidebar('transactions', { id: row.original._id });
        }}
        columns={columns}
        data={displayedTransactions}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        tableType={TABLE_TYPE.ALL_TRANSACTIONS}
        onSortingChange={(sortingState) => {
          if (sortingState.length === 0) return onSortingChange([]);
          // the sorting state from our table always return a single object at max
          // so we can just access the accessorKey as sortingState[0].id
          const accessorKey = sortingState[0].id;
          const desc = sortingState[0].desc;

          // need to ensure correct transaction doc properties are passed as id here, table accessorKeys might be different than transaction doc properties
          const next = [{ id: mapTableKeyToOriginalKey(accessorKey, 'Transactions'), desc }];

          onSortingChange(next);
        }}
      >
        <div className='flex items-center justify-between gap-3 px-6 py-5 md:gap-0 w-full'>
          <div className='flex items-center gap-3'>
            {showSearch && (
              <SearchInput
                data-cy='transactions_searchTermInput'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                loading={isSearching || isLoading}
                placeholder='Search transactions...'
                className='placeholder:text-zinc-500 '
                containerClassName=' min-w-0 '
                resultCount={displayedTransactions?.length}
              />
            )}
          </div>
          <div className='flex gap-3'>
            {showEditTableColumns && <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} />}
            {showExportCSV && <TransactionsCSV />}
            <DensityDropdown />
          </div>
        </div>
      </Table>
    </TableWrapper>
  );
};

export default memo(TransactionsTable);
