import { SortingState } from '@tanstack/react-table';
import {
  useAccountingPeriodFilterContext,
  useDateFilterContext,
  useFilteredAccountingPeriodStartDateUTCs,
  useFilteredAssetTypes,
  useFilteredImpairmentTreatment,
  useFilteredLedgerAccountIds,
  useFilteredLegalEntityIds,
  useSingleDateFilterContext,
  useTargetDateFilterContext,
} from '../../../context';
import { useDebounceValue } from 'usehooks-ts';
import { useEffect } from 'react';

type TResult = {
  assetTypes: string[];
  impaired: string[];
  legalEntityIds: string[];
  startDate?: string;
  endDate?: string;
  sort?: any;
  selectedDate?: string;
  targetDate?: string;
  accountingPeriodStartDateUTCs: Date[];
  ledgerAccountIds: string[];
};
const defaultParams: TResult = {
  assetTypes: [],
  ledgerAccountIds: [],
  impaired: [],
  legalEntityIds: [],
  accountingPeriodStartDateUTCs: [],
};

type TInput = { searchTerm?: string; sortState?: SortingState };
export const useAssetQueryParams = ({ searchTerm = '', sortState }: TInput = {}) => {
  const [params, setParams] = useDebounceValue(defaultParams, 500);

  const assetTypes = useFilteredAssetTypes();
  const impaired = useFilteredImpairmentTreatment();
  const legalEntityIds = useFilteredLegalEntityIds();
  const dateFilter = useDateFilterContext();
  const targetDateFilter = useTargetDateFilterContext();
  const singleDateFilter = useSingleDateFilterContext();
  const accountingPeriodStartDateUTCs = useFilteredAccountingPeriodStartDateUTCs();
  const ledgerAccountIds = useFilteredLedgerAccountIds();

  useEffect(
    () =>
      setParams({
        assetTypes: searchTerm !== '' ? [...assetTypes, searchTerm!] : assetTypes,
        impaired,
        legalEntityIds,
        ledgerAccountIds,
        targetDate: targetDateFilter.datesWithTzOffset.start,
        startDate: dateFilter.datesWithTzOffset.start,
        endDate: dateFilter.datesWithTzOffset.end,
        sort: sortState ? sortState[0] : undefined,
        selectedDate: singleDateFilter.datesWithTzOffset?.start,
        accountingPeriodStartDateUTCs,
      }),
    [
      assetTypes,
      impaired,
      legalEntityIds,
      dateFilter.datesWithTzOffset,
      sortState,
      searchTerm,
      singleDateFilter.datesWithTzOffset,
      targetDateFilter.datesWithTzOffset,
      ledgerAccountIds,
    ],
  );

  return params;
};
