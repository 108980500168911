import React from 'react';
import { RULE_CONFIGURATOR_TYPE } from '../types';

// deprecate label and value please, it should be fact, path, value
export type NormalizedConditionSelectorRowData = {
  label?: string;
  value: string;
  fact?: string;
  path?: string;
  symbol?: any;
};
export type ConditionSelectorRowData<T = NormalizedConditionSelectorRowData> = {
  rowData: T;
  isSelected: boolean;
};
export type CSRowProps<T = NormalizedConditionSelectorRowData> = {
  conditionData: ConditionSelectorRowData<T>;
};
export type FilterFn<T> = (searchTerm: string) => (rowData: T) => boolean;
export type ConvertToRowFn<T> = (ds: T) => ConditionSelectorRowData;
export type ConditionGroupFilterConfig<T = any> = {
  heading: string;
  data: T[];
  rows: ConditionSelectorRowData[];
  filterFn: FilterFn<T>;
  convertToRowFn?: ConvertToRowFn<T>;
  skipFn?: (values: T[]) => T[];
  Row: React.FC<{ conditionData: any }>;
};
export interface ConditionSelectorRowProps<T = NormalizedConditionSelectorRowData> {
  isSelected?: boolean;
  onClick?: () => void;
  rowData?: T;
  isMenuTrigger?: boolean;
  asChild?: boolean;
  children?: React.ReactNode;
  showOnSelectorTypes?: RULE_CONFIGURATOR_TYPE[];
  isTransactionClassification?: boolean;
}
