import { useRuleConfigurator } from '../rule-configurator-context';
import { RULE_CONFIGURATOR_TYPE } from '../types';
import { AccountPostingRulesetPrimaryMenu, ImpairmentRulesetPrimaryMenu } from './primary-menus';
import { useConditionSelectorContext } from './condition-selector-context';

export const PrimaryMenu = ({ showNestConditionRow = false }) => {
  const { state: selectorState } = useConditionSelectorContext();
  const { state: configuratorState } = useRuleConfigurator();

  if (selectorState.currentMenu) return null;
  if (!configuratorState.configuratorType) return null;
  if (configuratorState.configuratorType === RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING)
    return <AccountPostingRulesetPrimaryMenu showNestConditionRow={showNestConditionRow} />;
  if (configuratorState.configuratorType === RULE_CONFIGURATOR_TYPE.IMPAIRMENT)
    return <ImpairmentRulesetPrimaryMenu showNestConditionRow={showNestConditionRow} />;

  return null;
};
