import { useRuleConfigurator } from '../../rule-configurator-context';
import { Operators } from '../../data';
import { getMappedValue } from '../../utils';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu
        defaultValue={{ label: 'Gross Amount', value: condition?.path ?? '' }}
        options={getMappedValue(condition.fact, 'values')}
        value={getMappedValue(condition.fact, 'values', condition.path)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            path: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        options={Operators}
        defaultValue={{
          label: 'Less than',
          value: 'lessThanBigDecimal',
        }}
        value={getMappedValue(condition.fact, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <input
        type='text'
        onBlur={({ target }) => {
          const value = target.value.replace(',', '').replace('$', '');
          if (!isNaN(value as unknown as number)) target.value = `$${value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          updateCondition(condition.id, {
            value,
          });
        }}
        defaultValue={(condition.value !== '0' && `$${condition.value}`) || undefined}
        placeholder='$10,000'
        className='rounded-lg bg-white disabled:opacity-50 border-2 h-10 max-w-[120px] px-4 py-2 text--square text-[#000000] font-semibold'
        disabled={isDisabled}
      />
    </>
  );
};

export const TransactionRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION]}>
    <Row />
  </ConditionRow>
);
