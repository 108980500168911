import { createGlobalStyle } from 'styled-components';

const Buttons = createGlobalStyle`
    .btn--rounded {
        display: grid;
        place-content: center;
        height: auto;
        padding: 16px 32px;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: 0.04em;
        border-radius: 100px;
        border: 1px solid;
        width: fit-content;
        &.btn-home-page {
            padding: 12px 24px;
            height: auto;
        }
        &.btn--rounded--slim {
            padding: 8px 20px;
        }
        &.w-full {
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        &.btn--inverted {
            background-color: var(--white);
            color: var(--primary-dark);
        }
        &.btn--dark {
            background-color: var(--primary-dark);
            color: var(--white);
        }
        &.btn--big {
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 100px;
            padding: 24px 32px;
        }
        &.btn--transparent-light-text {
            color: var(--white);
        }
        &.btn--icon-left {
            display: grid;
            grid-template-columns: min-content max-content;
            grid-column-gap: 8px;
        }

        &.btn--icon-right {
            display: grid;
            grid-template-columns: max-content min-content;
            grid-column-gap: 8px;
        }
        &.light-icon {
            path {
                fill: #fff;
            }


        }

        &.btn--icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 16px;
            height: auto;
            background: #2D3239;
            box-shadow: 0px 36px 28px rgba(0, 0, 0, 0.1);
            border-radius: 80px;
            border: none;
        }

        &.btn--shortcut {
            padding: 8px 12px;
            gap: 8px;
            width: fit-content;
            height: 40px;
            border-radius: 8px;
            font-size: 20px;
            border: none;
        }

        &.btn--shortcut-light {
            background: #EBEBEB;
            color: black;
        }

        &.btn--shortcut-dark {
            background: rgba(235, 235, 235, 0.2);
            color: white;
        }
    }

    
    .btn--bg {
        border: 0;
        &.btn--bg--white {
            --bg: var(--white);
            --bg-rgb: var(--white-rgb);
            --o: 0.95;
        }
        &.btn--bg--primary-dark {
            --bg: var(--primary-dark);
            --bg-rgb: var(--primary-dark-rgb);
            --o: 0.95;
        }
     

        background-color: var(--bg);
        &:hover, &:focus {
            background-color: rgba(var(--bg-rgb),var(--o));
        }
    }
            
    .btn--border {
        &.btn--border--white {
            --color: var(--white);
        }
        &.btn--border--primary-dark {
            --color: var(--primary-dark);
        }
      
        background-color: transparent;
        border-color: var(--color);
        &:hover {
            border-color: var(--white);
       
        }
    }
    

    .btn--square {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 12px;
        gap: 16px;
        height: 40px;
        width: fit-content;
        background:  #111111;
        border-radius: 8px;
        color: black;
        font-weight: 500;
        font-size: 16px;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
        path {
            fill: black;
            fill-opacity: 1;
          }
        
        i {
            display: none;
        }
    }

    .btn--square:hover:not([disabled]) {
        opacity: 0.8;
    }
    .btn--square:hover:not([disabled]) svg{
        opacity: 0.8 !important;
    }
    .btn--square.primary {
        background-color: black;
        border: 1px solid #444444;
        color: white;
    }

    .btn--square.primary:disabled {
        opacity: 0.5;
    }

    .btn--square.secondary {
        background: #F2F2F2;
        border: 1px solid #E8E8E8;
    }
    
    .btn--square.tertiary {
        background: white;
        border: 1px solid #E8E8E8;
    }

    .btn--square.warning {
        background: #CD0A0A;
        border: 2px solid #910000;
        color: #fff;
    }

    .btn--square.warning:disabled {
        opacity: 0.5;
        cursor: disabled;
    }

    .btn--square.destructive {
        background: white;
        border: 1px solid #FF0000;
        --tw-text-opacity: 1 !important;
        color: rgb(255 0 0 / var(--tw-text-opacity)) !important;
    }

    .btn--square.unactive {
        background: #444444;
        border: 2px solid #444444;
        opacity: 0.3;
    }
    .btn--square.icon {
        background: white;
        border: 2px solid #E8E8E8;
        width: 40px;
        height: 40px;
        padding: 8px;
    }
    .btn--square.loading {
        border: 2px solid #E8E8E8;
        i {
            display: block !important;
        }
    }
    .btn--square.disabled,
    .btn--square:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
    .btn--square.icon-left{
        justify-content: flex-start;
        display: grid;
        grid-template-columns: min-content 100%;
        text-align: left;
    }
    .btn--square.v2 {
        height: 40px;
        font-size: 16px;
        padding: 10px 12px;
        margin-left: 16px; 
    }
    .btn--badge {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 16px;
        width: 68px;
        height: 32px;
        border: 2px solid #008000;
        border-radius: 8px;
        color: #008000;
    }

    .btn--tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        gap: 8px;

        width: fit-content;
        height: 28px;

        background: rgba(17, 17, 17, 0.04);
        border: 1px solid rgba(17, 17, 17, 0.1);
        border-radius: 4px;

    }

    .btn--modal-badge {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 8px;
        padding: 2.5px 8px;
        border-radius: 4px;
        background: #F2F2F2;
        border: 1px solid #E8E8E8;
        color: #444444;
        cursor: not-allowed;
        pointer-events: all !important;
    }



    @keyframes line-in{
        0%{transform:translate3d(-101%,0,0)}
        to{transform:translateZ(0)}
    }
    
    @keyframes line-out{
        0%{transform:translateZ(0)}
        to{transform:translate3d(101%,0,0)}
    }
    

  


`;

export default Buttons;
