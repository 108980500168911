import { useEffect, useState } from 'react';
import { Dialog, DialogContent, IndeterminateCheckbox, InputLabel, InputWithExtras } from 'ui';
import TableGroupSourceItems from './TableGroupSourceItems';
import { useActions } from './utils';
import { SourceTableGroupDialogProps } from './types';
import { MdSearch } from 'react-icons/md';

export const SourceTableGroupDialog = ({
  open,
  setOpen,
  data,
  isEdit = false,
  onCreateNewGroup,
  onUpdate,
  onDissolveGroup,
  tableGroupName = '',
  selectedSubRowIds = [],
  isLoading,
}: SourceTableGroupDialogProps) => {
  const [groupName, setGroupName] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>(selectedSubRowIds);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const { actions, handleSubmit } = useActions({
    selectedItems,
    setOpen,
    groupName,
    onCreateNewGroup,
    onUpdate,
    onDissolveGroup,
    isEdit,
    isLoading,
    isCreateGroup: !isEdit ? true : false,
    isUpdateGroup: isEdit,
  });

  useEffect(() => setSelectedItems(selectedSubRowIds), [open]);
  useEffect(() => setGroupName(tableGroupName), [tableGroupName]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredData.map((item) => item._id).filter(Boolean));
    }
    setSelectAll(!selectAll);
  };

  const filteredData =
    data?.filter(
      (item) => !searchTerm || (item?.sourceInfo?.title && item.sourceInfo.title.toLowerCase().includes(searchTerm)),
    ) ?? [];

  return (
    <Dialog open={open}>
      <DialogContent className='text-[16px]' title={isEdit ? 'Edit group' : 'Create group'} actions={actions}>
        <div className='flex flex-col mt-3 w-full min-h-[482px]'>
          <div className={'w-[calc(100%-20px)] mx-auto'}>
            <InputLabel heading='Group name' />
            <InputWithExtras
              className='h-[36px]'
              onEnter={handleSubmit}
              autoFocus={open}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              onEsc={() => setOpen(false)}
              containerClassName='mb-3'
            />
            <InputLabel heading='Sources' />
            <InputWithExtras
              type='text'
              className='h-[36px]'
              placeholder='Search...'
              value={searchTerm}
              onChange={handleSearchChange}
              leading={<MdSearch className='w-5 h-5' />}
              containerClassName='mb-5'
              leadingClassName='text-[#808080]'
            />
          </div>

          <div className='flex items-center mb-4 ml-4 justify-between'>
            <div className='flex items-center'>
              <IndeterminateCheckbox checked={selectAll} onChange={handleSelectAllChange} />
              <label className='ml-3'>{selectAll ? 'Deselect all' : 'Select all'}</label>
            </div>
            <div className='text-indigo-600 pr-4'>{selectAll ? `${selectedItems.length} selected` : ''}</div>
          </div>
          <TableGroupSourceItems
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            data={filteredData}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
