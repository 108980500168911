import { arrayMove } from '@dnd-kit/sortable';
import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const reorderConditionGroupChildren = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    draft.conditionGroups[action.payload.conditionGroupId].childIds = arrayMove(
      draft.conditionGroups[action.payload.conditionGroupId].childIds,
      draft.conditionGroups[action.payload.conditionGroupId].childIds.findIndex(action.payload.id),
      action.payload.to,
    );
  });
