import React, { useState } from 'react';
import { CheckboxGroupProps } from './types';
import { classNames } from '../utils';

export const CheckboxGroup = ({
  checkboxItems = [{ label: 'Label', checked: false }],
  getItems = (_checkboxItems) => _checkboxItems,
  disabled = false,
}: CheckboxGroupProps) => {
  const [saveCheckboxItems, setSaveCheckboxItems] = useState(checkboxItems);
  return (
    <div className='flex flex-col gap-2'>
      {saveCheckboxItems.map((item, index) => {
        return (
          <div
            onClick={
              !disabled
                ? () => {
                    item.checked = !item.checked;
                    setSaveCheckboxItems([...saveCheckboxItems]);
                    getItems(saveCheckboxItems);
                  }
                : undefined
            }
            key={index}
            className={`flex gap-2 items-center p-2 w-full duration-300 ${
              disabled ? 'bg-gray-100' : 'cursor-pointer hover:bg-[#E8E8E8]'
            } rounded-md`}
          >
            <span>
              <input
                disabled={disabled}
                defaultChecked={false}
                type='checkbox'
                checked={item.checked}
                className={classNames(
                  'form-checkbox mb-[3px] text-{#6565F1] w-[20px] h-[20px] border rounded',
                  item.checked ? 'border-[#8A8AF4]' : 'border-zinc-200',
                )}
                style={{
                  boxShadow: '0px 0px 0px',
                  outline: '0px',
                }}
              />
            </span>
            <span className='flex-grow'>{item.label}</span>
          </div>
        );
      })}
    </div>
  );
};
