import { useMutation } from '@tanstack/react-query';
import { getLoopCheckoutUrlV2 } from 'services';

export const useGetLoopCheckoutUrlV2Mutation = () =>
  useMutation(
    ({
      subscriptionPriceId,
      aiCreditsRechargePriceId,
      unlimitedAiCredits,
      subscriptionCadence,
      withTrial,
      trialPeriodDays,
    }: {
      subscriptionPriceId;
      aiCreditsRechargePriceId;
      unlimitedAiCredits;
      subscriptionCadence;
      withTrial;
      trialPeriodDays;
    }) =>
      getLoopCheckoutUrlV2({
        subscriptionPriceId,
        aiCreditsRechargePriceId,
        unlimitedAiCredits,
        subscriptionCadence,
        withTrial,
        trialPeriodDays,
      }),
  );
