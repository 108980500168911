import { SelectableCardWithIconProps } from './types';
import { classNames } from '../utils';

export const SelectableCardWithIcon = ({
  label,
  icon,
  description,
  selected,
  disabled,
  onClick,
  className,
  'data-cy': dataCy,
}: SelectableCardWithIconProps) => {
  return (
    <button
      className={classNames(
        'w-full p-5',
        'inline-flex flex-col',
        'text-base font-medium rounded-lg',
        'border',
        'gap-x-4',
        selected ? 'bg-[#eef2ff] !border-[#8c88ef]' : 'bg-white',
        disabled ? 'opacity-50' : 'hover:border-[#121212] hover:bg-[#e2e2e2]',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      data-cy={dataCy}
    >
      <span className='inline-flex items-center gap-x-5'>
        <span>{icon}</span>
        <span className='inline-flex flex-col item-start text-start'>
          <span className='mb-1'>{label}</span>
          {description && <span className='font-normal text-gray-600 text-sm'>{description}</span>}
        </span>
      </span>
    </button>
  );
};
