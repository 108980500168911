import React, { useState } from 'react';
import { InputLabel, InputWithExtras, TextareaInput } from 'ui';
import { PickJournalEntryTemplateFE } from './PickJournalEntryTemplateFE';
export type AccountPostingRuleFormState = {
  name: string;
  description: string;
  journalEntryTemplateId?: string;
};

export const useAccountPostingRuleFormState = (defaultState = { name: '', description: '' }) =>
  useState<AccountPostingRuleFormState>(defaultState);

export const AccountPostingRuleFE = ({
  value,
  setValue,
}: {
  value: AccountPostingRuleFormState;
  setValue: React.Dispatch<any>;
}) => {
  return (
    <>
      <div>
        <InputLabel heading='Account posting rule name' />
        <InputWithExtras
          data-testid='accountPostingRuleName__input'
          value={value.name}
          onChange={(e) => setValue((prev) => ({ ...prev, name: e.target.value }))}
        />
      </div>
      <div>
        <InputLabel heading='Description' />
        <TextareaInput
          data-testid='accountPostingRuleName__description'
          value={value.description}
          onChange={(e) => setValue((prev) => ({ ...prev, description: e.target.value }))}
        />
      </div>
      <PickJournalEntryTemplateFE
        value={value.journalEntryTemplateId}
        onChange={(v) => setValue((prev) => ({ ...prev, journalEntryTemplateId: v }))}
      />
    </>
  );
};
