import React from 'react';
import { Button } from 'ui';
import { useJournalQueryParams } from './use-journal-query-params';
import { downloadCsv } from '../../../lib/utils/download-csv';
import { useMutation } from '@tanstack/react-query';
import { getAllJEsQueryFn, prepareCsvRowsFromAllJEs } from '../../../lib/utils';
import { useGetAllFn, useSession } from '../../../hooks';
import { JournalEntry } from 'services/http/response.types';

export const JournalsCSV = ({ searchTerm, sortState }) => {
  const journalParams = useJournalQueryParams({ searchTerm, sortState });
  const { organizationId } = useSession();

  const getAllJournalEntriesFn = useGetAllFn<JournalEntry>(
    getAllJEsQueryFn,
    { ...journalParams, organizationIds: [organizationId] },
    250,
  );

  const { isLoading, mutateAsync: exportCsvData } = useMutation({
    mutationFn: async () => {
      const allJournalEntries = await getAllJournalEntriesFn();
      const data = prepareCsvRowsFromAllJEs(allJournalEntries);
      downloadCsv(data, `journals-export-${new Date().toLocaleDateString()}.csv`);
    },
  });
  return (
    <Button
      label='Download'
      emphasis='medium'
      onClick={() => exportCsvData()}
      isLoading={isLoading}
      disabled={isLoading}
    />
  );
};

export default JournalsCSV;
