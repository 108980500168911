import { produce } from 'immer';
import { GATE_TYPE, RuleConfiguratorState } from '../../types';
import short from 'short-uuid';

const translator = short();

export const copyCondition = (state: RuleConfiguratorState, childId: string, parentId: string, shouldReplace = false) =>
  produce(state, (draft) => {
    const conditionToCopy = draft.conditions[childId];
    const newId = translator.new().toString();

    draft.conditions[newId] = {
      ...conditionToCopy,
      id: newId,
      parentId,
    };
    draft.conditionIds.push(newId);
    if (shouldReplace) {
      const indexOfChildInParent = draft.conditionGroups[parentId].childIds.findIndex((id) => id === childId);
      draft.conditionGroups[parentId].childIds.splice(indexOfChildInParent, 1, newId);
    } else {
      const indexOfChildInParent = draft.conditionGroups[parentId].childIds.findIndex((id) => id === childId);
      draft.conditionGroups[parentId].childIds.splice(indexOfChildInParent, 0, newId);
      draft.conditionGroups[parentId].gates.splice(indexOfChildInParent, 0, GATE_TYPE.AND);
    }
  });
