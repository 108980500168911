import { useMutation } from '@tanstack/react-query';
import { createStripeCheckoutSession } from 'services';

export const useCreateStripeCheckoutSessionMutation = () =>
  useMutation(
    ({
      subscriptionPriceId,
      aiCreditsRechargePriceId,
      unlimitedAiCredits,
      subscriptionCadence,
      withTrial,
      trialPeriodDays,
      promotionCode,
    }: {
      subscriptionPriceId;
      aiCreditsRechargePriceId;
      unlimitedAiCredits;
      subscriptionCadence;
      withTrial;
      trialPeriodDays;
      promotionCode;
    }) =>
      createStripeCheckoutSession({
        subscriptionPriceId,
        aiCreditsRechargePriceId,
        unlimitedAiCredits,
        subscriptionCadence,
        withTrial,
        trialPeriodDays,
        promotionCode,
      }),
  );
