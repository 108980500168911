import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getUrlWithoutId } from 'src/components/utils';
import { useReadLocalStorage } from 'usehooks-ts';

function columnVisibilityHandler(columns: any) {
  let newObj: Record<string, boolean> = {};
  columns.forEach((column) => {
    if (column?.accessorKey?.length) {
      if (column.visibility === false) {
        newObj[column.accessorKey] = false;
      } else {
        newObj[column.accessorKey] = true;
      }
    }
  });
  return newObj;
}

export const useGetColumnVisibility = (panelTableName = '') => {
  const { asPath } = useRouter();

  const pageName = `${getUrlWithoutId(asPath).split('/').slice(-1)[0]}${panelTableName ? '-panel-' + panelTableName : ''}`;

  const editTableState = useReadLocalStorage<Record<string, Record<string, boolean>>>('tableVisibility') ?? {};

  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (editTableState[pageName]) {
      setColumnVisibility(columnVisibilityHandler(editTableState[pageName]));
    }
  }, [JSON.stringify(editTableState)]);

  return columnVisibility;
};
