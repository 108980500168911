import { getDisplayedAssets } from './utils';
import { CryptoSelectMenuProps } from './types';
import { useEffect, useState } from 'react';
import { DropdownOption, SingleSelectMenuWithOptionCreate, useMenuSearch } from '../select-menu';
import { getAssetsInfo } from 'global-utils';

const CryptoSelectMenu = ({ data, value, onChange, showCreateMissingOption, ...props }: CryptoSelectMenuProps) => {
  const [options, setOptions] = useState<DropdownOption[]>(
    getDisplayedAssets().map((asset) => ({
      label: getAssetsInfo(asset.label).name,
      bottomText: getAssetsInfo(asset.label).abbreviation,
      value: asset.value,
      icon: asset.value,
    })),
  );
  const { enableSearch, filteredOptions, searchTerm, setSearchTerm, open, setOpen } = useMenuSearch({
    options,
    setOptions,
    createOptionFromSearchTermFn: (searchTerm: string) => ({
      label: getAssetsInfo(searchTerm).name,
      bottomText: getAssetsInfo(searchTerm).abbreviation,
      value: searchTerm,
      icon: searchTerm,
    }),
    filterOptionsFn: (searchTerm: string) => (option: DropdownOption) =>
      (option.label as string)?.toLowerCase().includes(searchTerm.toLowerCase()),
    exactMatchFn: (searchTerm: string) => (option: DropdownOption) =>
      (option.label as string)?.toLowerCase() === searchTerm.toLowerCase(),
    onMenuValueChange: onChange,
    showCreateMissingOption,
  });

  useEffect(() => {
    if (data?.length) {
      setOptions(
        data.map((asset) => ({
          label: getAssetsInfo(asset.label).name,
          bottomText: getAssetsInfo(asset.label).abbreviation,
          value: asset.value,
          icon: asset.value,
        })),
      );
    }
  }, [data]);

  return (
    <SingleSelectMenuWithOptionCreate
      {...props}
      isModal
      enableAvatar
      enableBottomText
      type='chain'
      value={value}
      onChange={onChange}
      options={filteredOptions}
      enableSearch={enableSearch}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      open={open}
      setOpen={setOpen}
    />
  );
};

export { CryptoSelectMenu };
