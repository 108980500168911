import { SectionHeader } from '../section-header';
import { SectionProps } from './types';

export const Section = ({ heading, description, headerActions, children, loading }: SectionProps) => {
  return (
    <div>
      <div className='flex items-center justify-between'>
        <SectionHeader heading={heading} description={description} loading={loading} />
        <div>{headerActions}</div>
      </div>
      <div className='mt-12'>{children}</div>
    </div>
  );
};
