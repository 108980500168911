import { useQuery } from '@tanstack/react-query';
import { getAICreditsRechargeOptions } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';
import { useSession } from '../../../useSession';

export const useGetAICreditsRechargeOptionsQuery = (options: { enabled?: boolean } = {}) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.STRIPE_AI_CREDITS_RECHARGE_OPTIONS),
    async () => {
      const response = await getAICreditsRechargeOptions();

      return response.data.products;
    },
    { ...options, enabled: !!organizationId && !!options.enabled },
  );
};
