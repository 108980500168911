import { BarChartProps } from '@tremor/react';
import { JournalEntriesVolumeByLedgerAccountType } from 'services/http/analytics/journals';
import { LedgerAccount } from 'schemas';

export interface AppBarChartProps extends BarChartProps {
  isLoading: boolean;
  tabs: string[];
  containerId: string;
  title?: string;
  selectedTab: number;
  setSelectedTab: (tabIndex: number) => void;
  category?: string;
  setCategory?: (category: string) => void;
}

export type JournalsBarChartProps = {
  data?: JournalEntriesVolumeByLedgerAccountType['data'];
  accountingPeriodStartDateUTCs?: Date[];
  isLoading: boolean;
};

export enum JournalsBarChartMode {
  INCOME_STATEMENT = 'INCOME_STATEMENT',
  BALANCE_SHEET = 'BALANCE_SHEET',
}

export type AvailableLedgerAccountsType = {
  balanceSheetLedgerAccounts: LedgerAccount[];
  incomeStatementLedgerAccounts: LedgerAccount[];
};
