import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { DESCRIPTIVE_LOADER_STATES, DescriptiveLoader, ModalContent, capitalizeFirstLetter } from 'ui';

export const ShowStripePaymentStatus = ({ onClose, clientSecret }) => {
  const stripe = useStripe();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState<DESCRIPTIVE_LOADER_STATES>();

  useEffect(() => {
    if (!stripe) return;
    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // Inspect the PaymentIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      setTitle(paymentIntent?.status);
      switch (paymentIntent?.status) {
        case 'succeeded':
          setDescription('Success! Payment received.');
          setStatus(DESCRIPTIVE_LOADER_STATES.SUCCESS);
          break;

        case 'processing':
          setDescription("Payment processing. We'll update you when payment is received.");
          setStatus(DESCRIPTIVE_LOADER_STATES.SUCCESS);
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setDescription('Payment failed. Please try another payment method.');
          setStatus(DESCRIPTIVE_LOADER_STATES.ERROR);
          break;

        default:
          setDescription('Something went wrong.');
          break;
      }
    });
  }, []);

  return (
    <ModalContent title='Payment status' onClose={onClose}>
      <DescriptiveLoader title={capitalizeFirstLetter(`${title}`)} description={description} status={status} />
    </ModalContent>
  );
};
