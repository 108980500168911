//import { useKBar } from 'farish-kbar';
import CreateJournalEntry from './SecondaryViews/CreateJournalEntry';
import CreateLedgerAccount from './SecondaryViews/CreateLedgerAccount';
import CreateLegalEntity from './SecondaryViews/CreateLegalEntity';
import CreateWallet from './SecondaryViews/CreateWallet';
import GPTChat from './SecondaryViews/GPTChat';
import { useCommandPallete } from './useCommandPallete';
import { useRef } from 'react';

const SecondView: React.FC = () => {
  const { secondViewPath } = useCommandPallete();
  const ref = useRef(null);

  switch (secondViewPath) {
    case 'create-legal-entity':
      return <CreateLegalEntity />;
    case 'create-journal-entry':
      return <CreateJournalEntry />;
    case 'create-ledger-account':
      return <CreateLedgerAccount />;
    case 'create-wallet':
      return <CreateWallet />;
    case 'gpt-chat':
      return <GPTChat fullscreen={false} inputRef={ref} />;
    default:
      return null;
  }
};

export default SecondView;
