import { LegalEntity } from 'services/http/response.types';
import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import { useLegalEntities } from '../../../hooks';
import { mergeLegalEntities } from '../../../components/templates/utils';
import { useQueryDataWithFilterState } from './use-query-with-filter-state';
import { FilterContextParams } from '../types';
import { useMemo } from 'react';

export type LegalEntityFilterContextType = FilterContextParams<LegalEntity>;

export const useLegalEntityFilterState = (page: string) => {
  const { state, setState } = useFilterState<LegalEntity>(FILTER_TYPE.LEGAL_ENTITY, page);

  const { data, isLoading } = useLegalEntities();
  useQueryDataWithFilterState(data, setState, mergeLegalEntities);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
