import { AreaChart, BarChart, Legend, Text, Title } from '@tremor/react';
import { ChartType, GptAnalyticsCrudResponse } from 'services/http/gpt-service';
import { formatDollars } from '../templates/utils';
import { exportChart } from '../../lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { InputWithExtras, TremorCard } from 'ui';
import { ChartExportDropdown } from 'ui';
import { AssistantsConversationInteractionHistory } from 'schemas';

export const getNewestFunctionCallResponse = (interactionHistory: AssistantsConversationInteractionHistory) => {
  //console.log(`getNewestFunctionCallResponse`, gptChatResponse);
  if (interactionHistory.functionCallResponses) {
    const length = interactionHistory.functionCallResponses.length;
    const chartData = interactionHistory?.functionCallResponses[length - 1];
    if (chartData?.output) return chartData.output;
    return chartData;
  }
};

export const GPTAnalyticsContainer = ({
  interactionHistory,
}: {
  interactionHistory: AssistantsConversationInteractionHistory;
}) => {
  const [title, setTitle] = useState('');
  const [editingTitle, setEditingTitle] = useState(false);

  const { instruction } = interactionHistory;
  const chartData = getNewestFunctionCallResponse(interactionHistory);
  if (!interactionHistory) {
    return null;
  }

  const chartId = useMemo(() => `gpt-analytics-${instruction.split(' ').join('-')}`, [instruction]);

  const { data, categories, index, yAxisWidth, chartType } = processData(chartData);

  if (!data || data.length === 0) {
    return (
      <>
        <TremorCard id={chartId} className='flex justify-center'>
          <Text>No data found</Text>
        </TremorCard>
      </>
    );
  }
  useEffect(() => {
    const chartTitle = getChartTitle(categories, index);
    setTitle(chartTitle);
  }, []);

  const valueFormatter = (value: any) => {
    const isUnit =
      JSON.stringify(chartData.table?.yAxisColumnNames) === JSON.stringify(['count']) ||
      JSON.stringify(chartData.table?.yAxisColumnNames) === JSON.stringify(['totalTokens']) ||
      JSON.stringify(chartData.table?.yAxisColumnNames) === JSON.stringify(['transactionsCount']) ||
      chartData.table?.yAxisColumnNames.some(
        (unit: string) =>
          unit.toLowerCase().includes('percent') ||
          unit.toLowerCase().includes('tokens') ||
          unit.toLowerCase().includes('count') ||
          unit.toLowerCase().includes('quantity'),
      );

    if (isUnit) {
      return value;
    }
    return formatDollars(value, true);
  };

  const SelectedChart = ({ chartType }: { chartType: ChartType }) => {
    switch (chartType) {
      case ChartType.LINE:
        return (
          <AreaChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      case ChartType.BAR: {
        return (
          <BarChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      }
      case ChartType.TABLE:
        // Assuming `TableComponent` is your component for rendering tables
        return (
          <AreaChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      case ChartType.SCATTER:
        // Assuming `TableComponent` is your component for rendering tables
        return (
          <AreaChart
            data={data}
            categories={categories}
            index={index}
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
            className='h-[200px]'
            showLegend={false}
          />
        );
      default:
        return null;
    }
  };

  return (
    <TremorCard id={chartId} className='p-4'>
      <div className='flex gap-x-2 ignore-export'>
        {editingTitle ? (
          <InputWithExtras
            className='border rounded-lg border-gray-300 px-2 py-1 w-[300px]'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => setEditingTitle(false)}
            onEnter={() => setEditingTitle(false)}
            autoFocus
          />
        ) : (
          <Title>{title}</Title>
        )}
        <button
          onClick={() => {
            setEditingTitle(true);
          }}
          className='text-gray-400 hover:text-gray-600'
        >
          <MdEdit />
        </button>
      </div>
      <div className='w-full flex justify-end mb-6 '>
        <Legend categories={categories} />
        <ChartExportDropdown
          onPngExport={() => exportChart({ chartId, format: 'png', title })}
          onSvgExport={() => exportChart({ chartId, format: 'svg', title })}
        />
      </div>
      <SelectedChart chartType={chartType} />
    </TremorCard>
  );
};

const convertCamelCaseToTitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const getChartTitle = (xAxesNames: string[], yAxisName: string) => {
  let convertedYAxisName = yAxisName;
  let convertedXAxesNames = xAxesNames.map((xAxisName) => {
    if (xAxisName === 'transactionsCount') {
      return 'Transactions';
    }
    return convertCamelCaseToTitleCase(xAxisName);
  });

  if (yAxisName === 'accountingPeriodName' || yAxisName === 'transactionMonth') {
    convertedYAxisName = 'Time';
  } else {
    convertedYAxisName = convertCamelCaseToTitleCase(yAxisName);
  }
  return `${convertedXAxesNames.join(' vs ')} over ${convertedYAxisName}`;
};

const processData = (analyticsCrudResponse: GptAnalyticsCrudResponse) => {
  if (!analyticsCrudResponse) {
    return { data: [], categories: [], index: 'Unknown', yAxisWidth: 0, chartType: ChartType.LINE };
  }

  const { table, supportedChartTypes } = analyticsCrudResponse;
  const { headers, data, xAxisColumnName, yAxisColumnNames } = table || {
    headers: [],
    data: [],
    xAxisColumnName: '',
    yAxisColumnNames: [],
  };

  if (!headers || !data || !yAxisColumnNames?.length) {
    return { data: [], categories: [], index: 'Unknown', yAxisWidth: 0, chartType: ChartType.LINE };
  }

  const chartType: any = supportedChartTypes[0] || 'LINE';

  const processedData = data.map((row) => {
    const rowObject: { [key: string]: any } = {};
    row.forEach((value, index) => {
      const header = headers[index];
      if (value && value.$numberDecimal) {
        rowObject[header] = parseFloat(value.$numberDecimal);
      } else {
        rowObject[header] = value;
      }
    });
    return rowObject;
  });

  const maxValue = Math.max(
    ...processedData.map((row) => yAxisColumnNames.map((name) => row[name]).filter(Boolean)).flat(),
  );
  const isUnit = ['count', 'totalTokens', 'transactionsCount'].some((unit) => yAxisColumnNames.includes(unit));
  const maxValueFormatted = isUnit ? maxValue.toString() : formatDollars(maxValue, true);
  const yAxisWidth = Math.max(50, maxValueFormatted.length * 8); // Adjust the base width as needed
  return {
    data: processedData,
    categories: yAxisColumnNames,
    index: xAxisColumnName || 'Unknown',
    yAxisWidth,
    chartType,
  };
};

// const TableComponent = ({ data, headers }) => {
//   console.log(data);
//   return (
//     <table className='min-w-full divide-y divide-gray-200'>
//       <thead className='bg-gray-50'>
//         <tr>
//           {headers.map((header) => (
//             <th key={header} className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
//               {header} {/* Assuming headers are already in a display-friendly format */}
//             </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody className='bg-white divide-y divide-gray-200'>
//         {data.map((row, rowIndex) => (
//           <tr key={rowIndex}>
//             {headers.map((header) => (
//               <td key={`${rowIndex}-${header}`} className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
//                 {row[header] !== undefined ? row[header] : 'N/A'}
//               </td>
//             ))}
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };
