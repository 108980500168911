import React from 'react';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { TextareaInputProps } from './types';

export const TextareaInput = ({ characterLimit, ...props }: TextareaInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({ value: props.value, onChange: props.onChange });
  return (
    <div>
      <InputWithExtras
        {...props}
        {...inputProps}
        trailing={
          <ClearInputTrailingAction value={inputProps.value} setValue={inputProps.onChange} disabled={props.disabled} />
        }
        isTextarea
      />
      {characterLimit && (
        <div className='text-[13px] mt-2'>
          {inputProps.value.length}/{characterLimit} characters
        </div>
      )}
    </div>
  );
};
