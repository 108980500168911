import { MdEmail } from 'react-icons/md';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { EmailInputProps } from './types';

export const EmailInput = (props: EmailInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({
    value: props.value,
    onChange: props.onChange,
  });
  return (
    <InputWithExtras
      {...props}
      {...inputProps}
      leading={<MdEmail className='w-5 h-5' />}
      trailing={
        <ClearInputTrailingAction value={inputProps.value} setValue={inputProps.onChange} disabled={props.disabled} />
      }
    />
  );
};
