import React from 'react';
import { AddInputTrailingAction } from '../add-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { SubtractInputTrailingAction } from '../subtracting-input-trailing-action';
import { PercentageInputProps } from './types';

export const PercentageInput = (props: PercentageInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({
    value: props.value,
    onChange: props.onChange,
  });

  return (
    <InputWithExtras
      {...props}
      {...inputProps}
      type='number'
      suffix='%'
      trailing={
        <>
          <SubtractInputTrailingAction
            value={inputProps.value}
            setValue={inputProps.onChange}
            className='mr-3'
            disabled={props.disabled}
            min={0}
          />
          <AddInputTrailingAction
            value={inputProps.value}
            setValue={inputProps.onChange}
            disabled={props.disabled}
            max={100}
          />
        </>
      }
    />
  );
};
