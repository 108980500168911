import { UseProbablyControlledStateForInputProps } from './types';

const { useState, useEffect } = require('react');

export const useProbablyControlledStateForInput = ({ value, onChange }: UseProbablyControlledStateForInputProps) => {
  const [state, setState] = useState('');
  useEffect(() => {
    if (value !== undefined) setState(value);
  }, [value]);

  const onChangeCallback = (e: any) => {
    setState(e.target.value);
    if (onChange) onChange(e);
  };

  return { value: state, setValue: setState, inputProps: { value: state, onChange: onChangeCallback } };
};
