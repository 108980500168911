import { useMemo, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownHeader,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
  AmountInput,
  InputLabel,
} from 'ui';
import { FILTER_TYPE } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import {
  useAmountFilterContext,
  useAmountFilterMax,
  useAmountFilterMin,
  useAmountFilterMode,
} from '../../../../context';

const DEFAULT_TRIGGER_LABEL = 'Amount';
const useTriggerLabelForAmountFilterDropdown = () => {
  const mode = useAmountFilterMode();
  const min = useAmountFilterMin();
  const max = useAmountFilterMax();

  if (min?.length > 0) {
    if (mode === 'is') return `${DEFAULT_TRIGGER_LABEL}: ${min}`;
    if (mode === 'is-above') return `${DEFAULT_TRIGGER_LABEL}: +${min}`;
    if (mode === 'is-below') return `${DEFAULT_TRIGGER_LABEL}: -${min}`;
    if (mode === 'is-between') return `${DEFAULT_TRIGGER_LABEL}: ${min}-${max}`;
  }
  return DEFAULT_TRIGGER_LABEL;
};

export const AmountFilterDropdown = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const { state, setState } = useAmountFilterContext();
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);
  const triggerLabel = useTriggerLabelForAmountFilterDropdown();

  const typeFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.TYPE}`, [dataCy]);

  const clear = () => setState({ mode: 'is', min: '', max: '' });

  return (
    <Dropdown data-cy={typeFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(typeFilterDataCy).trigger}
            isFocused={open}
            isLoading={false}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      clear();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef} className='w-[324px]'>
        <DropdownHeader title='Amount' data-cy={getFilterDropdownSectionDataCy(typeFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(typeFilterDataCy).body}>
          <div className='px-4'>
            <div className='flex mb-4 mt-3 gap-2 flex-wrap'>
              <Button
                label='is'
                emphasis='medium'
                onClick={() => setState((prev) => ({ ...prev, mode: 'is' }))}
                className={classNames(
                  'duration-100 py-0 px-1',
                  state.mode !== 'is' && isHoveringTrigger && !isHoveringClearAllTrailingIcon && 'shadow',
                  state.mode === 'is' && 'bg-indigo-50 border-indigo-600',
                )}
              />
              <Button
                label='is below'
                emphasis='medium'
                onClick={() => setState((prev) => ({ ...prev, mode: 'is-below' }))}
                className={classNames(
                  'duration-100 py-0',
                  state.mode !== 'is-below' && isHoveringTrigger && !isHoveringClearAllTrailingIcon && 'shadow',
                  state.mode === 'is-below' && 'bg-indigo-50 border-indigo-600',
                )}
              />
              <Button
                label='is above'
                emphasis='medium'
                onClick={() => setState((prev) => ({ ...prev, mode: 'is-above' }))}
                className={classNames(
                  'duration-100 py-0',
                  state.mode !== 'is-above' && isHoveringTrigger && !isHoveringClearAllTrailingIcon && 'shadow',
                  state.mode === 'is-above' && 'bg-indigo-50 border-indigo-600',
                )}
              />
              <Button
                label='is between'
                emphasis='medium'
                onClick={() => setState((prev) => ({ ...prev, mode: 'is-between' }))}
                className={classNames(
                  'duration-100 py-0',
                  state.mode !== 'is-between' && isHoveringTrigger && !isHoveringClearAllTrailingIcon && 'shadow',
                  state.mode === 'is-between' && 'bg-indigo-50 border-indigo-600',
                )}
              />
            </div>
            <div className='flex items-end gap-2 mb-3'>
              <div>
                <InputLabel heading='Min' />
                <AmountInput
                  containerClassName='w-[126px]'
                  currencyDescriptor='$'
                  value={state.min}
                  onChange={(e) => setState((prev) => ({ ...prev, min: e.target.rawValue }))}
                />
              </div>
              <span className={classNames(state.mode !== 'is-between' && 'text-[#8c8c8c]', 'mb-2')}>&mdash;</span>{' '}
              <div>
                <InputLabel heading='Max' className={classNames(state.mode !== 'is-between' && 'text-[#8c8c8c]')} />
                <AmountInput
                  disabled={state.mode !== 'is-between'}
                  containerClassName={classNames('w-[126px]', state.mode !== 'is-between' && 'text-[#8c8c8c]')}
                  currencyDescriptor='$'
                  value={state.max}
                  onChange={(e) => setState((prev) => ({ ...prev, max: e.target.rawValue }))}
                />
              </div>
            </div>
          </div>
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(typeFilterDataCy).clearAllButton}
            onClick={clear}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
