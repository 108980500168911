import { useState } from 'react';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { Avatar, Button, classNames, currencyImg } from 'ui';
import { getAssetsInfo } from '../../../../../../constants';
export interface ConditionItemsWrapperProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

export interface ConditionItemProps extends Omit<React.HTMLProps<HTMLDivElement>, 'label'> {
  label: string | React.ReactNode;
  children?: React.ReactNode;
  img?: string;
  isInLineCondition?: boolean;
  checked?: boolean;
  isLoading?: boolean;
}

export interface ConditionChildrenProps {
  label: string | React.ReactNode;
  children: React.ReactNode;
  isInLineCondition?: boolean;
  setOpen: (data: boolean) => void;
}

const ConditionChildren = ({ children, label, isInLineCondition = false, setOpen }: ConditionChildrenProps) => {
  return (
    <div
      className={`min-w-[320px] w-full h-[390px] pb-3 pt-1 px-2 rounded-md bg-white flex flex-col gap-2 absolute top-0 inset-x-0 cursor-default z-10 ${
        isInLineCondition && 'shadow-[0px_4px_8px_rgba(0,0,0,0.17)]'
      }`}
    >
      <div className='flex items-center mt-2'>
        <Button
          emphasis='low'
          className='border-0'
          onClick={() => setOpen(false)}
          label={
            <>
              <MdChevronLeft />
              {label}
            </>
          }
        />
      </div>
      <div className='flex flex-col gap-2 w-full overflow-y-auto'>{children}</div>
    </div>
  );
};

export const ConditionItem = ({
  label,
  children,
  onClick,
  isInLineCondition,
  img,
  ref,
  checked,
  isLoading = false,
  ...props
}: ConditionItemProps) => {
  const [open, setOpen] = useState(false);
  if (isLoading) {
    return <div>loading...</div>;
  }
  return (
    <div
      ref={ref}
      className={`flex flex-col gap-2 bg-white duration-300 cursor-pointer hover:bg-indigo-50 py-2 px-3 rounded-lg ${
        checked && 'bg-[#2e31381a]'
      }`}
    >
      <div
        onClick={(e) => {
          onClick && onClick(e);
          if (children) setOpen(true);
        }}
        className='flex items-center justify-between'
        {...props}
      >
        {typeof label === 'string' ? (
          <div className='flex justify-center items-center gap-2'>
            {img?.length && <Avatar size='medium' src={currencyImg(img)} alt={img} />}
            <div>
              <p className={classNames(img?.length && 'font-medium text-zinc-900')}>
                {img?.length ? getAssetsInfo(label).name : label}
              </p>
              {!!img?.length && <p className='text-zinc-500'>{getAssetsInfo(label).abbreviation}</p>}
            </div>
          </div>
        ) : (
          <>{label}</>
        )}
        {!!children && <MdChevronRight />}
      </div>
      {open && (
        <ConditionChildren isInLineCondition={isInLineCondition} setOpen={setOpen} label={label}>
          {children}
        </ConditionChildren>
      )}
    </div>
  );
};
export default ConditionItem;
