import { useDebounceValue } from 'usehooks-ts';
import {
  useAmountFilterMax,
  useAmountFilterMin,
  useAmountFilterMode,
  useDateFilterContext,
  useFilteredAccountingPeriodStartDateUTCs,
  useFilteredAccountingTreatment,
  useFilteredAddresses,
  useFilteredAssetTypes,
  useFilteredChains,
  useFilteredClassifications,
  useFilteredDirections,
  useFilteredLegalEntityIds,
  useFilteredSourceIds,
  useFilteredSpamTokenTreatment,
  useFilteredWalletIds,
} from '../../../context';
import { useEffect } from 'react';
import { SortingState } from '@tanstack/react-table';
import { MultiSortState } from './TransactionTableWithQuery';

type TResult = {
  directions: string[];
  chains: string[];
  accountingPeriodIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  addresses: string[];
  walletIds: string[];
  sourceIds: string[];
  sort?: any;
  showTransactionsWithoutJournalEntries?: boolean;
  searchTerm?: string;
  transactionFrom?: string;
  transactionTo?: string;
  includeSpam?: boolean;
  classifications: string[];
  pageSize: number;
  grossAmount?: string;
  minGrossAmount?: string;
  maxGrossAmount?: string;
};
const defaultParams: TResult = {
  directions: [],
  chains: [],
  accountingPeriodIds: [],
  legalEntityIds: [],
  assetTypes: [],
  addresses: [],
  walletIds: [],
  sourceIds: [],
  pageSize: 25,
  classifications: [],
};
type TInput = { searchTerm?: string; sortState?: SortingState; multiSortState?: MultiSortState };

export const useTransactionQueryParams = ({ searchTerm, sortState, multiSortState }: TInput = {}) => {
  const [params, setParams] = useDebounceValue(defaultParams, 500);

  const accountingPeriodStartDateUTCs = useFilteredAccountingPeriodStartDateUTCs();
  const showTransactionsWithoutJournalEntries = useFilteredAccountingTreatment();
  const chains = useFilteredChains();
  const dateFilter = useDateFilterContext();
  const classifications = useFilteredClassifications();
  const legalEntityIds = useFilteredLegalEntityIds();
  const assetTypes = useFilteredAssetTypes();
  const addresses = useFilteredAddresses();
  const walletIds = useFilteredWalletIds();
  const sourceIds = useFilteredSourceIds();
  const directions = useFilteredDirections();
  const includeSpam = useFilteredSpamTokenTreatment();
  const mode = useAmountFilterMode();
  const min = useAmountFilterMin();
  const max = useAmountFilterMax();

  useEffect(() => {
    const amountParams: any = {};
    if (!isNaN(+min) && min.length > 0) {
      if (mode === 'is') amountParams.grossPrice = +min;
      if (mode === 'is-above') amountParams.minGrossPrice = +min;
      if (mode === 'is-below') amountParams.maxGrossPrice = +min;
      if (mode === 'is-between') amountParams.minGrossPrice = +min;
    }
    if (!isNaN(+max) && max.length > 0 && mode === 'is-between') amountParams.maxGrossPrice = +max;
    setParams({
      directions,
      chains,
      transactionFrom: dateFilter.datesWithTzOffset.start,
      transactionTo: dateFilter.datesWithTzOffset.end,
      accountingPeriodStartDateUTCs: accountingPeriodStartDateUTCs,
      legalEntityIds,
      assetTypes,
      addresses,
      walletIds,
      sourceIds,
      sort: sortState ? sortState[0] : undefined,
      asc: multiSortState?.asc,
      desc: multiSortState?.desc,
      showTransactionsWithoutJournalEntries,
      searchTerm: searchTerm || undefined,
      pageSize: 25,
      includeSpam,
      classifications,
      ...amountParams,
    });
  }, [
    searchTerm,
    sortState,
    multiSortState,
    directions,
    chains,
    dateFilter.datesWithTzOffset,
    accountingPeriodStartDateUTCs,
    legalEntityIds,
    assetTypes,
    addresses,
    walletIds,
    sourceIds,
    showTransactionsWithoutJournalEntries,
    includeSpam,
    classifications,
    min,
    mode,
    max,
  ]);

  return params;
};
