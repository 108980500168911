import axios from 'axios';
import { Organization, StripeDetails } from 'schemas';
import Stripe from 'stripe';
import { SERVER_URL_BILLING_SERVICE } from '../../config';
import { prepareSearchParams } from '../core/utils';

export const createSubscription = ({ organizationId, priceId }: { organizationId: string; priceId: string }) =>
  axios.post<{ clientSecret: string }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/subscription`,
    {
      organizationId,
      priceId,
    },
    { skipOrganizationId: true },
  );
export const updateSubscription = ({ organizationId, priceId }: { organizationId: string; priceId: string }) =>
  axios.put<{ clientSecret: string }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/subscription`,
    {
      organizationId,
      priceId,
    },
    { skipOrganizationId: true },
  );
export const updateSubscriptionCadence = ({ organizationId, cadence }: { organizationId: string; cadence: string }) =>
  axios.put<{ clientSecret: string }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/subscription/cadence`,
    {
      organizationId,
      cadence,
    },
    { skipOrganizationId: true },
  );
export type Price = Stripe.Price;
export type ProductWithPrices = {
  product: Stripe.Product;
  prices: Stripe.Price[];
};

export type ProductWithPrice = {
  product: Stripe.Product;
  price: Stripe.Price;
};
export type ProductsWithPrices = ProductWithPrices[];
export type CardPaymentMethod = Stripe.PaymentMethod;

export type PlanDetails = { monthly: Price; yearly: Price };

export type SubscriptionCadence = 'month' | 'year';
export type StripeProductType = 'subscription' | 'ai-credits-recharge';
export type StripeProductsQueryParams = {
  includeCustomPackages?: boolean;
  cadence?: SubscriptionCadence;
  productType?: StripeProductType;
};
export const getAllStripeProducts = ({ includeCustomPackages, cadence, productType }: StripeProductsQueryParams) =>
  axios.get<{
    products: ProductsWithPrices;
  }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/subscription/product?${prepareSearchParams({
      includeCustomPackages,
      cadence,
      productType,
    })}`,
  );

export const getCardsFromStripe = () =>
  axios.get<{ cards: Stripe.PaymentMethod[] }>(`${SERVER_URL_BILLING_SERVICE}/stripe/payment-method/card`);

export type PopulatedStripeDetails = StripeDetails & {
  organizationId: Organization;
  subscription?: Stripe.Subscription;
  paymentMethod?: Stripe.PaymentMethod;
  customer?: Stripe.Customer;
  source?: Stripe.Source;
  updatedAt?: string;
  lastBillingInfoUpdateAt?: string;
};
export const getStripeDetails = () =>
  axios.get<{ stripeDetails: PopulatedStripeDetails }>(`${SERVER_URL_BILLING_SERVICE}/stripe`);
export const getStripeInvoices = ({ startingAfter, limit }) =>
  axios.get<{ invoices: Stripe.Invoice[] }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/invoice?${prepareSearchParams({ startingAfter, limit })}`,
  );

export type AICreditRechargeOption = {
  id: string;
  label: string;
  description: string;
};
export const getAICreditsRechargeOptions = () =>
  axios.get<{
    products: ProductsWithPrices;
  }>(`${SERVER_URL_BILLING_SERVICE}/stripe/ai-credits-recharge/product`);

export const addAICredits = (rechargeId: string) =>
  axios.post<{ clientSecret: string }>(`${SERVER_URL_BILLING_SERVICE}/stripe/ai-credits-recharge`, { rechargeId });

export const startFreeTrialNoPaymentMethod = (args: { organizationId: string; priceId: string }) =>
  axios.post<{ clientSecret: string }>(`${SERVER_URL_BILLING_SERVICE}/stripe/subscription/trial`, args);


export const createSetupIntentForNewCard = () =>
  axios.post<{ clientSecret: string }>(`${SERVER_URL_BILLING_SERVICE}/stripe/payment-method/card`);

export type RemoveStripePaymentMethodPayload = { paymentMethodId: string };

export const removeStripePaymentMethod = ({ paymentMethodId }: { paymentMethodId: string }) =>
  axios.delete<{ clientSecret: string }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/payment-method?paymentMethodId=${paymentMethodId}`,
  );

export const updatePaymentMethodForStripeSubscription = ({ paymentMethodId }: { paymentMethodId: string }) =>
  axios.post(`${SERVER_URL_BILLING_SERVICE}/stripe/subscription/payment-method`, { paymentMethodId });

export const applyDiscountCodeToStripeSubscription = ({ promotionCode }: { promotionCode: string }) =>
  axios.post(`${SERVER_URL_BILLING_SERVICE}/stripe/subscription/discount-code`, { promotionCode });

export const updateStripeCustomer = ({ customerId, payload }) =>
  axios.put(`${SERVER_URL_BILLING_SERVICE}/stripe/customer?customerId=${customerId}`, payload);

export const payStripeInvoice = ({ invoiceId, paymentMethodId }) =>
  axios.post<{ paymentIntent }>(`${SERVER_URL_BILLING_SERVICE}/stripe/invoice/pay`, { invoiceId, paymentMethodId });

export const getStripeSubscriptionPlanSuggestion = () =>
  axios.get<{ productWithPrices: ProductWithPrices }>(`${SERVER_URL_BILLING_SERVICE}/stripe/subscription/suggestion`);

export const getStripePaymentIntentByCheckoutSessionId = (checkoutSessionId: string) =>
  axios.get(
    `${SERVER_URL_BILLING_SERVICE}/stripe/checkout-session/payment-intent?checkoutSessionId=${checkoutSessionId}`,
  );

export const createStripeCheckoutSession = ({
  subscriptionPriceId,
  aiCreditsRechargePriceId,
  unlimitedAiCredits,
  subscriptionCadence,
  withTrial,
  trialPeriodDays,
  promotionCode,
}) =>
  axios.post<{ url: string }>(`${SERVER_URL_BILLING_SERVICE}/stripe/checkout-session`, {
    subscriptionPriceId,
    aiCreditsRechargePriceId,
    unlimitedAiCredits,
    subscriptionCadence,
    withTrial,
    trialPeriodDays,
    promotionCode,
  });

export const getStripeProductByPriceId = (priceId: string) =>
  axios.get<{ product: ProductWithPrices }>(
    `${SERVER_URL_BILLING_SERVICE}/stripe/subscription/product-by-price?priceId=${priceId}`,
  );
