import React from 'react';
import { DatePickerComp } from '../../DatePickerComp';
import { useAccountingPeriodFilterContext, useDateFilterContext, useTargetDateFilterContext } from '../../../context';

export const DateFilter = ({
  'data-cy': dataCy,
  singleDateFilter,
}: {
  'data-cy'?: string;
  singleDateFilter?: boolean;
}) => {
  const { state, setState } = useDateFilterContext();
  const targetDateFilter = useTargetDateFilterContext();
  const accountingPeriodFilter = useAccountingPeriodFilterContext();

  return (
    <DatePickerComp
      data-cy={dataCy}
      dateSelections={state}
      setDateSelections={setState}
      singleDateFilter={singleDateFilter}
      onUserInteractionBasedStateChange={() => {
        accountingPeriodFilter.setters.deselectAll();
        targetDateFilter.setState([]);
      }}
    />
  );
};
