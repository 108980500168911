import React from 'react';
import { JournalsBarChart } from './JournalsBarChart';
import { useGetJournalVolume } from '../../../hooks/http/useGetJournalVolume';
import {
  useFilteredAccountingPeriodStartDateUTCs,
  useFilteredLedgerAccountIds,
  useFilteredLegalEntityIds,
  useFilteredStatus,
} from '../../../context';

export const JournalsChart = () => {
  const legalEntityIds = useFilteredLegalEntityIds();
  const accountingPeriodStartDateUTCs = useFilteredAccountingPeriodStartDateUTCs();
  const status = useFilteredStatus();
  const ledgerAccountIds = useFilteredLedgerAccountIds();
  const { data: journalVolumeData, isLoading: isLoadingJournalEntryVolumeData } = useGetJournalVolume({
    legalEntityIds,
    accountingPeriodStartDateUTCs: accountingPeriodStartDateUTCs.map((date) => new Date(date).toISOString()),
    status,
    ledgerAccountIds,
  });
  return (
    <JournalsBarChart
      data={journalVolumeData?.data}
      isLoading={isLoadingJournalEntryVolumeData}
      accountingPeriodStartDateUTCs={accountingPeriodStartDateUTCs}
    />
  );
};

export default JournalsChart;
