import { useStripe, useElements } from '@stripe/react-stripe-js';

export const useConfirmSetupIntentFromAddCard = ({ clientSecret }: { clientSecret?: string }) => {
  const stripe = useStripe();
  const elements = useElements();

  const confirmSetupIntent = async () => {
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) throw submitError;

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.origin + '/profile/billing/redirect?result=success&operation=NEW_CARD_SETUP',
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      throw error;
    }
  };

  return confirmSetupIntent;
};
