import React, { ReactNode } from 'react';
import Select, { components, SingleValueProps, OptionProps, DropdownIndicatorProps } from 'react-select';

import { TelephoneExtension, TelephoneExtensionMenuProps, TelephoneExtensionRowProps } from './types';
import { MdArrowDropDown, MdArrowDropUp, MdDone } from 'react-icons/md';
import { classNames } from '../utils';

const TelephoneExtensionRow = ({
  icon,
  label,
  isSelected = false,
  showOnlyIcon = false,
}: TelephoneExtensionRowProps) => (
  <div className='flex items-center'>
    <span className='w-[24px] h-[16px] mr-2'>{icon}</span>
    {!showOnlyIcon && <span className='flex-grow'>{label}</span>}

    {!showOnlyIcon && isSelected && (
      <span>
        <MdDone className='w-[24px] h-[24px]' />
      </span>
    )}
  </div>
);

const SelectedTelephone = ({ icon }: { icon: ReactNode }) => {
  return (
    <div className='flex items-center'>
      <span className='w-[24px] h-[16px] mr-2'>{icon}</span>
    </div>
  );
};
const Option = (props: OptionProps<TelephoneExtension>) => (
  <components.Option {...props}>
    <TelephoneExtensionRow
      icon={props.data.icon}
      label={props.data.label}
      isSelected={props.isSelected}
      value={props.data.value}
    />
  </components.Option>
);

const SingleValue = (props: SingleValueProps<TelephoneExtension>) => (
  <components.SingleValue {...props}>
    {props.data && <SelectedTelephone icon={props.data.icon} />}
  </components.SingleValue>
);

const MenuDropdownIndicator = (props: DropdownIndicatorProps<TelephoneExtension>) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? <MdArrowDropUp className='w-5 h-5' /> : <MdArrowDropDown className='w-5 h-5' />}
  </components.DropdownIndicator>
);

export const TelephoneExtensionMenu = ({
  options,
  onChange,
  value,
  isError,
  disabled,
}: TelephoneExtensionMenuProps) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      isClearable={false}
      placeholder=''
      isMulti={false}
      components={{
        Option,
        SingleValue,
        DropdownIndicator: MenuDropdownIndicator,
      }}
      unstyled
      isDisabled={disabled}
      classNames={{
        control: (state) =>
          classNames(
            'w-[38px] h-[40px]',
            isError && 'border-y !border-[#ff0000] ring-y !ring-[#ff0000] hover:ring-[#ff0000]',
            !isError && state.menuIsOpen && 'ring-y-2 ring-[#0000ff] hover:ring-[#0000ff]',
            'hover:ring-y-2 hover:ring-[#808080]',
            state.isDisabled && 'opacity-50 pointer-none',
          ),
        menu: () => 'rounded-lg mt-2 bg-white !w-[140px]',
        menuList: () => 'border rounded-lg p-1 grid grid-cols-1 gap-1',
        option: ({ isFocused, isSelected }) =>
          classNames('rounded bg-white py-[6px] px-[12px]', isFocused && 'bg-zinc-100', isSelected && 'bg-zinc-100'),
      }}
    />
  );
};
