import { useCommandPallete } from '../useCommandPallete';
import { MdChevronLeft } from 'react-icons/md';
import { Button, DescriptiveLoader } from 'ui';
import { useCreateSourceWithTransactionImportJob } from '../../Sources';
import { CreateSourceFormElements, CreateSourceFormState } from '../../form-elements';
import { useState } from 'react';
import { WALLET_CHAIN, WALLET_TYPE } from 'services/http/response.types';
import { deriveError } from '../../templates/utils';
import { toast } from 'react-hot-toast';
import { CREATE_SOURCE_PROCESS_STATE, getAddWalletProcessStateDescriptorProps } from '../../process-states';
import { SidebarBody, SidebarFooter } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';

const defaultFormState = {
  walletType: WALLET_TYPE.INTERNAL,
  name: '',
  address: '',
  chain: WALLET_CHAIN.ETHEREUM,
  addresses: [],
  tags: [],
};

const CreateWallet = () => {
  const { setSecondViewPath } = useCommandPallete();
  const [formState, setFormState] = useState<CreateSourceFormState>(defaultFormState);

  const [error, setError] = useState<string>();

  const { createSourceWithTransactionImportJob, processState, setProcessState, isCreatingSource } =
    useCreateSourceWithTransactionImportJob();

  const onFormSubmit = async () => {
    try {
      await createSourceWithTransactionImportJob(formState);
      setFormState(defaultFormState);
    } catch (error) {
      console.log('CreateWalletForm error:', error);
      setError(deriveError(error));
      toast.error(deriveError(error));
    }
  };

  const handleCancel = () => setSecondViewPath('');

  return (
    <div className='w-full z-commandpallet bg-white h-full rounded-[14px] overflow-auto min-h-[600px] flex flex-col max-h-[70vh]'>
      <div className='flex items-center px-6 py-4 border-b gap-x-4'>
        <Button
          label='Add wallet'
          leadingIcon={<MdChevronLeft />}
          onClick={() => setSecondViewPath('')}
          className='hover:bg-indigo-50'
          emphasis='low'
          disableRingOnFocus
        />
      </div>
      <SidebarBody>
        {processState === CREATE_SOURCE_PROCESS_STATE.FORM ? (
          <>
            <SidebarSectionHeader title='Details' />
            <SidebarSection numberOfColumns={1}>
              <CreateSourceFormElements formState={formState} setFormState={setFormState} />
            </SidebarSection>
          </>
        ) : (
          <div className='h-[300px] w-full flex items-center justify-center'>
            <DescriptiveLoader {...getAddWalletProcessStateDescriptorProps(processState, error)} />
          </div>
        )}
      </SidebarBody>
      {processState === CREATE_SOURCE_PROCESS_STATE.FORM ? (
        <SidebarFooter
          primaryBtn={<Button onClick={onFormSubmit} isLoading={isCreatingSource} label={'Save'} />}
          secondaryBtn={<Button onClick={handleCancel} emphasis='medium' label='Cancel' />}
        />
      ) : (
        <SidebarFooter
          primaryBtn={<Button label='Done' emphasis='high' onClick={handleCancel} />}
          secondaryBtn={
            <Button
              label={
                processState === CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE ? 'Back' : 'Add another source'
              }
              emphasis='medium'
              onClick={() => {
                if (processState !== CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE)
                  setFormState(defaultFormState);
                setProcessState(CREATE_SOURCE_PROCESS_STATE.FORM);
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default CreateWallet;
