import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERY_KEY, useOrgBasedQueryKey } from "../useInvalidateQuery";
import { useSession } from "../useSession";
import { getAuditRecords, } from "services";

export const useGetAuditRecords = (params = {}, useInfiniteQueryOptions = {}) => {
    const pageSize = 10;
    const getKey = useOrgBasedQueryKey();
    const { organizationId } = useSession();
    const infiniteQueryOptions = {
        enabled: !!organizationId,
        getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
        getNextPageParam: (lastPage) => {
            if (lastPage?.length === pageSize) return lastPage.nextPage;
            return undefined;
        },
        refetchOnWindowFocus: false,
        ...useInfiniteQueryOptions,
    };
    return useInfiniteQuery(
        getKey(QUERY_KEY.AUDIT_LOGS, params),
        async ({ pageParam = 0 }) => {
            const response = await getAuditRecords({
                page: pageParam,
                ...params,
            });
            return response.auditRecords;
        },
        infiniteQueryOptions,
    );
};