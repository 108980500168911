import { InvoiceLineItemsTableHeader } from '../../../../constants';
import { Table, TableWrapper } from '../../../dashboard';
import { prepareInvoiceLineItemsTableData } from './utils';

export const InvoiceLineItemsTable = ({ invoice }) => {
  return (
    <TableWrapper isInSidepanel>
      <Table
        data-cy='invoiceLineItems'
        tableHeader={InvoiceLineItemsTableHeader}
        tableData={prepareInvoiceLineItemsTableData(invoice)}
        emptyBtn={{
          emptyMsg: 'No lines found in invoice',
        }}
        isSidePanel
        hideCheckboxes
      />
    </TableWrapper>
  );
};
