import axios from 'axios';
import { SERVER_URL_ANALYTICS, SERVER_URL_CORE, SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';
import { GetJournalEntriesQueryFilterType, GetJournalEntryLinesParamsType } from '../types';
import { prepareSearchParams } from './utils';
import { JournalEntryDB } from 'schemas';

// TODO: ensure that there is a difference of 2 days between today's date and end date

export const getJournalEntries = async (params: Partial<GetJournalEntriesQueryFilterType>) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'accountingDate',
  });
  return axios.get<{ journalEntryModels: JournalEntryDB[]; nextPage: number; prevPage: number }>(
    `${SERVER_URL_CORE}/journal-entries?${stringifiedParams}`,
  );
};

export const getJournalEntryLines = async (params: Partial<GetJournalEntryLinesParamsType>) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
    defaultSortFilterPropertyName: 'accountingDate',
  });
  return axios.get(`${SERVER_URL_ANALYTICS}/get-je-lines?${stringifiedParams}`);
};

export const deleteJournalEntry = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/journal-entries?organizationId=${data.organizationId}&journalEntryId=${data.journalEntryId}`,
  );

export const reverseJournalEntry = async (data) =>
  axios.post(
    `${SERVER_URL_CORE}/journal-entries/reverse?organizationId=${data.organizationId}&journalEntryId=${data.journalEntryId}&userId=${data.userId}`,
    data,
  );

export const addJournalEntries = async (data) => {
  return axios.post(`${SERVER_URL_CORE}/journal-entries?organizationId=${data.organizationId}`, data);
};

export async function patchJournalEntry(data): Promise<any> {
  return axios.patch(
    `${SERVER_URL_CORE}/journal-entries?journalEntryId=${data.journalEntryModel.journalEntry._id}`,
    data,
  );
}

export const bulkPostJournalEntry = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/bulk-post-journal-entries?organizationId=${data.organizationId}${convertQuery(
      data.journalEntryIds,
      'journalEntryIds',
    )}`,
  );

export const bulkDeleteJournalEntry = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/bulk-delete-journal-entries?organizationId=${data.organizationId}${convertQuery(
      data.journalEntryIds,
      'journalEntryIds',
    )}`,
  );

export const bulkUnpostJournalEntry = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/bulk-unpost-journal-entries?organizationId=${data.organizationId}${convertQuery(
      data.journalEntryIds,
      'journalEntryIds',
    )}`,
  );

export const bulkReverseJournalEntry = async (data) =>
  axios.post(
    `${SERVER_URL_CORE}/bulk-reverse-journal-entries?organizationId=${data.organizationId}&userId=${data.userId
    }${convertQuery(data.journalEntryIds, 'journalEntryIds')}`,
  );


export const triggerJournalEntryRecalculationJob = async (params: { organizationId: string, journalEntryId: string }) => {
  const query = prepareSearchParams(params)
  return axios.post(`${SERVER_URL_JOB_QUEUE}/produce-event-driven-asset-reconstruction-job?${query}`)
}