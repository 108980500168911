import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getUnaccountedTransactions } from 'services';

export const useUnaccountedTransactions = () => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.UNACCOUNTED_TRANSACTIONS, organizationId),
    () => getUnaccountedTransactions({ organizationId }).then((response) => response.data.unaccountedTransactions),
    {
      enabled: !!organizationId,
    },
  );
};
