import { useMutation, useQuery } from '@tanstack/react-query';
import {
  MaplePoolPositionQueryParams,
  createEntriesForMaple,
  createMapleJob,
  createMapleSource,
  deleteMapleSource,
  getMaplePoolPosition,
  getMapleSource,
  getMapleVestedBalances,
  updateMapleSource,
  GetMapleSourceParams,
} from 'services/http/core';
import toast from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useGetMapleSource = ({ legalEntityIds }: GetMapleSourceParams = {}, { enabled = true } = {}) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.MAPLE_SOURCES),
    async () => {
      const response = await getMapleSource({ legalEntityIds });
      return response.data;
    },
    {
      enabled: !!organizationId && enabled,
      onError: (error: any) => {
        toast.error((error as any).message);
      },
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetmappleJobHistories = ({ legalEntityIds }: GetMapleSourceParams = {}) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.MAPLE_SOURCES_JOB_HISTORY_RECORDS),
    async () => {
      const response = await getMapleSource({ legalEntityIds });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 30000,
      cacheTime: 30000,
    },
  );
};

export const useCreateMaple = () => {
  return useMutation(async (body: any) => await createMapleSource(body));
};

export const useGetMaplePoolPositions = () => {
  return useMutation(async (body: any) => await getMaplePoolPosition(body));
};

export const useGetMaplePoolPositionsQuery = (params: MaplePoolPositionQueryParams = {}, { enabled = true } = {}) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.MAPLE_POOL_POSITIONS),
    async () => {
      const response = await getMaplePoolPosition(params);
      return response?.data?.poolPositions || null;
    },
    { enabled: !!organizationId && enabled },
  );
};

export const useUpdateMaple = () => {
  return useMutation(async (body: any) => await updateMapleSource(body));
};
export const useDeleteMaple = () => {
  return useMutation(async (body: any) => await deleteMapleSource(body));
};

export const useCreateMapleJob = () => {
  return useMutation(async (body: any) => await createMapleJob(body));
};

export type useGetMapleVestingBalancesPayload = {
  accountingPeriodId: string;
};

export const useGetMapleVestingBalances = ({ accountingPeriodId }: useGetMapleVestingBalancesPayload) => {
  const { organizationId } = useSession();

  return useQuery(
    ['GetMapleVestedBalances', organizationId, accountingPeriodId],
    async () => {
      const response = await getMapleVestedBalances({ organizationId, accountingPeriodId });
      return response.data.vestedBalancesByAssetType;
    },
    {
      enabled: !!organizationId && !!accountingPeriodId,
      staleTime: 30000,
      cacheTime: 30000,
    },
  );
};

export type createEntriesForMaplePayload = {
  accountingPeriodId: string;
  positionAccounting: any;
  organizationId?: string;
};
export const useCreateEntriesForMaple = () => {
  return useMutation(async (body: createEntriesForMaplePayload) => await createEntriesForMaple(body));
};
