import React, { useState, useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../@/components/ui/select';
import { useTheme } from 'next-themes';

interface TokenValue {
  token: string;
  value: number;
}

interface MonthlyTokenValue {
  month: string;
  tokens: TokenValue[];
}

interface CumulativeTokenValueChartProps {
  data: MonthlyTokenValue[];
}

type Period = 'all' | 'lastMonth' | 'lastQuarter' | 'lastYear';

const COLORS = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#a4de6c',
  '#d0ed57',
];

export const CumulativeTokenValueChart: React.FC<CumulativeTokenValueChartProps> = ({ data = [] }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('all');
  const [hiddenTokens, setHiddenTokens] = useState<string[]>([]);
  const { theme } = useTheme();

  const filteredData = useMemo(() => {
    const now = new Date();
    return data.filter((item) => {
      const itemDate = new Date(item.month);
      if (selectedPeriod === 'lastMonth') {
        return itemDate >= new Date(now.getFullYear(), now.getMonth() - 1, 1);
      } else if (selectedPeriod === 'lastQuarter') {
        return itemDate >= new Date(now.getFullYear(), now.getMonth() - 3, 1);
      } else if (selectedPeriod === 'lastYear') {
        return itemDate >= new Date(now.getFullYear() - 1, now.getMonth(), 1);
      }
      return true;
    });
  }, [data, selectedPeriod]);

  const { chartData, tokenTypes } = useMemo(() => {
    const tokenTypesSet = new Set<string>();
    const chartData = filteredData.map((monthData) => {
      const monthItem: { [key: string]: number | string } = { month: monthData.month };
      monthData.tokens.forEach((token) => {
        tokenTypesSet.add(token.token);
        monthItem[token.token] = token.value;
      });
      return monthItem;
    });

    return {
      chartData,
      tokenTypes: Array.from(tokenTypesSet),
    };
  }, [filteredData]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    return (
      <div className='rounded-lg bg-background p-4 shadow-md border border-border'>
        <p className='font-bold mb-2'>{`Month: ${label}`}</p>
        {payload.map((entry: any, index: number) => (
          <div key={`item-${index}`} className='flex justify-between items-center mb-1'>
            <span style={{ color: entry.color }}>{entry.name}:</span>
            <span className='ml-2 font-semibold'>{formatCurrency(entry.value)}</span>
          </div>
        ))}
        <div className='mt-2 pt-2 border-t border-border'>
          <div className='flex justify-between items-center font-bold'>
            <span>Total:</span>
            <span>{formatCurrency(payload.reduce((sum: number, entry: any) => sum + entry.value, 0))}</span>
          </div>
        </div>
      </div>
    );
  };

  const handleLegendClick = (e: any) => {
    const tokenName = e.dataKey;
    setHiddenTokens((prev) => (prev.includes(tokenName) ? prev.filter((t) => t !== tokenName) : [...prev, tokenName]));
  };

  return (
    <Card className='w-full'>
      <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
        <div className='space-y-1'>
          <CardTitle className='text-2xl font-bold'>Cumulative Token Value Over Time</CardTitle>
          <CardDescription>Track the total value of tokens in USD</CardDescription>
        </div>
        <Select value={selectedPeriod} onValueChange={(value: Period) => setSelectedPeriod(value)}>
          <SelectTrigger className='w-[180px]'>
            <SelectValue placeholder='Select period' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value='all'>All Time</SelectItem>
            <SelectItem value='lastMonth'>Last Month</SelectItem>
            <SelectItem value='lastQuarter'>Last Quarter</SelectItem>
            <SelectItem value='lastYear'>Last Year</SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width='100%' height={400}>
          <AreaChart data={chartData}>
            <CartesianGrid
              strokeDasharray='3 3'
              stroke={theme === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}
            />
            <XAxis dataKey='month' />
            <YAxis tickFormatter={(value) => `$${(value / 1e6).toFixed(1)}M`} />
            <Tooltip content={<CustomTooltip />} />
            <Legend onClick={handleLegendClick} />
            {tokenTypes.map((token, index) => (
              <Area
                key={token}
                type='monotone'
                dataKey={token}
                stackId='1'
                stroke={COLORS[index % COLORS.length]}
                fill={COLORS[index % COLORS.length]}
                hide={hiddenTokens.includes(token)}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
