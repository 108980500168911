import { formatTableNumbers } from 'global-utils';
import { capitalizeFirstLetter, currencyImg, DefaultCell, SingleLineNumber, TwoLineNumber } from 'ui';
import { ColumnsProps } from './types';
import { dateConverter, handleGainLoss } from 'src/components/utils';
import { DisplayedAsset } from 'src/components/PageComponents/Assets/type';
import { useDensity } from 'src/context';
import { ColumnLeadingCell } from './columnLeadingCell';
import { LedgerAccount } from 'services/http/response.types';

export const assetsColumns: ColumnsProps<DisplayedAsset, any>[] = [
  {
    accessorKey: 'assetType',
    header: 'Asset',
    cell: ({ row, getValue }) => {
      const { density } = useDensity();

      const { partnerImage, chain } = getValue() || {};
      return (
        <ColumnLeadingCell
          isAvatarVisible={currencyImg(getValue().chain?.toLowerCase()) !== ''}
          src={currencyImg(getValue().chain?.toLowerCase())}
          size={density === 'compact' ? 'default' : density === 'default' ? 'large' : 'extra-large'}
          alt={chain}
          type={partnerImage ? 'two-avatars' : 'chain'}
          secondAvatarPosition='bottom'
          secondAvatar={partnerImage}
          initials={getValue().chain?.toLowerCase() ?? 'AA'}
          label={capitalizeFirstLetter(getValue()?.title ?? '')}
          isCheckboxVisible={false}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
    minSize: 200,
  },
  {
    accessorKey: 'dateReceived',
    header: 'Date received',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center justify-between'>
          <DefaultCell textAlign={'text-left'} className='px-0 w-fit' label={dateConverter(new Date(getValue()))} />
        </div>
      );
    },
  },
  {
    accessorKey: 'costBasis',
    header: 'Cost basis',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineNumber
          textAlign={'text-right'}
          containerClassNames='px-0'
          label={capitalizeFirstLetter(getValue()?.title ?? '')}
        />
      );
    },
  },
  {
    accessorKey: 'pricePaid',
    header: 'Total cost',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      const { partnerImage, partnerName } = getValue() || {};
      const partnerText = (partnerImage && '· ' + partnerName) ?? '';
      return (
        <TwoLineNumber
          textAlign={'text-right'}
          containerClassNames='px-0'
          label={formatTableNumbers({ value: getValue() }) + partnerText}
        />
      );
    },
  },
  {
    accessorKey: 'impairedCostBasis',
    header: 'Impaired cost basis',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <div className='w-full h-full flex items-center'>
          {getValue() === 'N/A' ? (
            <SingleLineNumber className='px-0' textAlign={'text-right'} label='N/A' />
          ) : (
            <SingleLineNumber
              className='px-0'
              textAlign={'text-right'}
              label={formatTableNumbers({ value: getValue() })}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'quantity',
    header: 'Initial qty',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineNumber
          containerClassNames='px-0'
          textAlign={'text-right'}
          label={formatTableNumbers({ value: getValue(), isAmount: true, significantDecimalPlaces: 2 })}
        />
      );
    },
  },
  {
    accessorKey: 'remainingQuantity',
    header: 'Remaining qty',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineNumber
          containerClassNames='px-0'
          textAlign={'text-right'}
          label={formatTableNumbers({
            value: getValue(),
            isAmount: true,
            significantDecimalPlaces: 2,
          })}
        />
      );
    },
  },
  {
    accessorKey: 'currentValue_gainLossPercentage',
    header: 'Current value',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineNumber
          containerClassNames='px-0'
          textAlign={'text-right'}
          label={formatTableNumbers({ value: getValue().currentValue })}
        />
      );
    },
  },
  {
    accessorKey: 'ledgerAccountId',
    header: 'Ledger Account',
    cell: ({ row }) => {
      const ledgerAccount = row.original.ledgerAccountId as LedgerAccount;
      if (!ledgerAccount) return null;
      return (
        <div className='truncate w-full'>
          {ledgerAccount.ledgerAccountSequence}: {ledgerAccount.ledgerAccountName}
        </div>
      );
    },
  },
  {
    accessorKey: 'gainLossPercentage',
    header: 'Gain / Loss',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      const gainLossPercentage = parseFloat(getValue());
      const { value } = handleGainLoss(gainLossPercentage);

      return (
        <TwoLineNumber
          containerClassNames='px-0'
          textAlign={'text-right'}
          label={value}
          labelClassNames={parseFloat(value) > 0 ? 'text-green-600' : parseFloat(value) === 0 ? '' : 'text-red-500'}
        />
      );
    },
  },
];
