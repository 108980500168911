import { useWalletFilterContext } from '../context';
import { useMemo } from 'react';

export const useFilteredSourceIds = () => {
  const { state } = useWalletFilterContext();

  return useMemo(
    () =>
      state
        .filter((row) => row.chain === 'Exchange' || row.chain === 'HEDGEY' || row.chain === 'Raincard')
        .filter((option) => option.selected)
        .map((option) => option._id),
    [state],
  );
};
