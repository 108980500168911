import { useMutation } from '@tanstack/react-query';
import { updateStripeCustomer } from 'services';
import Stripe from 'stripe';

export const useUpdateStripeCustomerMutation = () =>
  useMutation(async ({ customerId, payload }: { customerId: string; payload: Stripe.CustomerUpdateParams }) => {
    const response = await updateStripeCustomer({
      customerId,
      payload,
    });
    return response.data;
  });
