import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';

export const CostBasisInlineConditionMenu = ({ rule, callbackFn }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '>
        <div className='mr-2 flex h-full justify-center items-center content-center'>{rule.value}</div>
        <ChevronDownIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent onClick={() => callbackFn({ ...rule, value: 'Loss Tolerance' })}>tbd</StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
