import { useQuery } from '@tanstack/react-query';
import { getRulesetJobConfigs } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useGetRulesetJobConfigsQuery = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.GET_RULESET_JOB_CONFIGS),
    async () => {
      const response = await getRulesetJobConfigs();
      return response.data;
    },
    { enabled: !!organizationId },
  );
};
