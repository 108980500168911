import axios from 'axios';
import { SERVER_URL_CORE } from '../../config';

export const getJournalEntryFileAttachments = async (data) =>
  axios.get(
    `${SERVER_URL_CORE}/journal-entry-attachments?journalEntryId=${data.journalEntryId}&organizationId=${data.organizationId}`,
  );

export const deleteJournalEntryFileAttachment = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/journal-entry-attachments?journalEntryId=${data.journalEntryId}&fileId=${data.fileId}&organizationId=${data.organizationId}`,
  );
