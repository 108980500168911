import { GetAccountingPeriodsPayloadType } from '../types';
import { SERVER_URL_CORE } from '../../config';
import axios from 'axios';
import { prepareSearchParams } from './utils';
import { AccountingPeriod } from '../response.types';
import { AccountingPeriodCloseSettings } from 'schemas';

export const getAccountingPeriods = async (data: Partial<GetAccountingPeriodsPayloadType>) => {
  return axios.get<{ accountingPeriods: AccountingPeriod[] }>(
    `${SERVER_URL_CORE}/accounting-period?${prepareSearchParams(data, { noExtras: true })}`,
  );
};

export const getAccountingPeriodStartDates = async (data: Partial<GetAccountingPeriodsPayloadType>) => {
  return axios.get<{ accountingPeriodStartDates: string[] }>(
    `${SERVER_URL_CORE}/accounting-period-start-dates?${prepareSearchParams(data, { noExtras: true })}`,
  );
};

export const closeAccountingPeriod = async (data) =>
  axios.patch(`${SERVER_URL_CORE}/close-accounting-period?${prepareSearchParams(data, { noExtras: true })}`);

export const reopenAccountingPeriod = async (data) =>
  axios.post(`${SERVER_URL_CORE}/reopen-accounting-period?${prepareSearchParams(data, { noExtras: true })}`);

export type UpsertAccountingPeriodClosingSettingsParams = {
  legalEntityId: string;
  retryFrequency: number;
  closeDate: string;
  timezone?: string;
  advancedSettings: {
    syncGL?: boolean;
    runRevaluation?: boolean;
  };
};
export const upsertAccountingPeriodClosingSettings = async (data: UpsertAccountingPeriodClosingSettingsParams) =>
  axios.post(`${SERVER_URL_CORE}/schedule-accounting-period-close-settings`, data);

export const getAccountingPeriodClosingSettingsForLegalEntity = async (legalEntityId: string) =>
  axios.get<{ settings: AccountingPeriodCloseSettings }>(
    `${SERVER_URL_CORE}/close-accounting-period-schedule-settings?legalEntityId=${legalEntityId}`,
  );

export const getAccountingPercentage = async (accountingPeriodId: string) =>
  axios.get<{ counts: { accountedTransactions: number; totalTransactions: number }; percentage: number }>(
    `${SERVER_URL_CORE}/accounting-percentage?accountingPeriodId=${accountingPeriodId}`,
  );
