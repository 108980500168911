import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { LEDGER_ACCOUNT_TYPE } from 'services/http/response.types';
import { useAllLedgerAccounts } from '../../../../../hooks';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { formatLedgerAccountName } from '../../../../templates/utils';
import { useAddCondition } from '../useAddCondition';
import { ConditionSelectorMenuTrigger } from '../ConditionSelectorMenuTrigger';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';
import { getElementIndex, useFilteredConditionGroups, useFilteredConditions } from '../utils';

import { CSRowProps, ConditionGroupFilterConfig, FilterFn } from '../types';
import { LedgerAccount } from 'schemas';
import { useConditionSelectorContext } from '../condition-selector-context';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';

const filterLedgerAccountRowsFn: FilterFn<LedgerAccount> = (searchTerm) => (rowData) =>
  `${rowData.ledgerAccountSequence}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
  rowData.ledgerAccountName?.toLowerCase().includes(searchTerm.toLowerCase());

const convertLedgerAccountToRowData = (account: LedgerAccount) => ({
  rowData: { value: account._id, label: formatLedgerAccountName(account) },
  isSelected: false,
});

export const useLedgerAccountsConditionGroups = (): {
  conditionGroups: Omit<ConditionGroupFilterConfig, 'rows'>[];
  isLoading: boolean;
} => {
  const { ledgerAccounts, isLoading } = useAllLedgerAccounts();
  const [conditionGroups, setConditionGroups] = useState<Omit<ConditionGroupFilterConfig<LedgerAccount>, 'rows'>[]>([]);
  useEffect(() => {
    const groups = Object.values(LEDGER_ACCOUNT_TYPE).map((ledgerAccountType) => ({
      heading: `${ledgerAccountType} ledger accounts`,
      data: [] as any[],
      filterFn: filterLedgerAccountRowsFn,
      convertToRowFn: convertLedgerAccountToRowData,
      Row: LedgerAccountConditionSelectorRow,
    }));
    ledgerAccounts.forEach((ledgerAccount) => {
      const index = Object.values(LEDGER_ACCOUNT_TYPE).findIndex((type) => type === ledgerAccount.ledgerAccountType);
      // console.log({ ledgerAccount, index });
      groups[index].data.push(ledgerAccount);
    });
    setConditionGroups(groups);
  }, [ledgerAccounts]);

  return { conditionGroups, isLoading };
};

export const LedgerAccountConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.LEDGER_ACCOUNT,
          operator: 'equal',
          value: conditionData.rowData.value,
        })
      }
    />
  );
};

const useFilteredLedgerAccountConditionGroups = () => {
  const { conditionGroups, isLoading } = useLedgerAccountsConditionGroups();
  const filtered = useFilteredConditionGroups(conditionGroups);

  return { filtered, isLoading };
};

export const LedgerAccountsByTypeConditionsMenu = ({ ledgerAccountType }) => {
  const { ledgerAccounts, isLoading } = useAllLedgerAccounts();

  const [typeWise, setTypeWise] = useState<any[]>([]);

  useEffect(() => {
    setTypeWise(ledgerAccounts.filter((la) => la.ledgerAccountType === ledgerAccountType));
  }, [ledgerAccounts]);

  const filtered = useFilteredConditions<any>(typeWise, filterLedgerAccountRowsFn, convertLedgerAccountToRowData);

  return (
    <ConditionsGroup label={ledgerAccountType}>
      {filtered.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={filtered}
          itemContent={(index, data) => <LedgerAccountConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
      {filtered.length === 0 && (
        <div className='text-center p-2'>
          {isLoading ? <LoaderIcon /> : <span>No {ledgerAccountType} ledger accounts to show</span>}
        </div>
      )}
    </ConditionsGroup>
  );
};

export const LedgerAccountConditionsMenu = () => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const { filtered, isLoading } = useFilteredLedgerAccountConditionGroups();

  return (
    <ConditionsGroup label='Ledger Accounts'>
      {debouncedSearchTerm.length > 0 && filtered.headings.length === 0 && isLoading && (
        <div className='flex items-center justify-center w-full my-2'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
      {debouncedSearchTerm.length > 0 && filtered.headings.length === 0 && !isLoading && (
        <div className='text-center my-2'>No ledger accounts found</div>
      )}

      {debouncedSearchTerm.length > 0 && filtered.headings.length > 0 && (
        <GroupedVirtuoso
          style={{ height: 250 }}
          groupCounts={filtered.counts}
          itemContent={(index, groupIndex) => {
            const internalIndex = getElementIndex(filtered.values, index); // index is flattened index, we need index of item in its parent array
            const ledgerAccountRowData = filtered.values[groupIndex][internalIndex];

            return <LedgerAccountConditionSelectorRow key={index} conditionData={ledgerAccountRowData} />;
          }}
          groupContent={(groupIndex) => (
            <div className='font-medium text-zinc-500 p-1 bg-white'>{filtered.headings[groupIndex]}</div>
          )}
        />
      )}
      {debouncedSearchTerm.length === 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={Object.values(LEDGER_ACCOUNT_TYPE)}
          itemContent={(index, ledgerAccountType) => (
            <ConditionSelectorMenuTrigger label={ledgerAccountType} key={index} visibleOnMenu='Ledger Accounts' />
          )}
        />
      )}
    </ConditionsGroup>
  );
};
