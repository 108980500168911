import { JobConfiguration } from 'schemas';

export const getTimeFromDateOrString = (dateOrString: Date | string) =>
  typeof dateOrString === 'string' ? new Date(dateOrString).getTime() : dateOrString.getTime();

export const shouldUpdateJobConfiguration = (current?: JobConfiguration, next?: JobConfiguration) => {
  if (!current) return true;
  if (!next) return false;
  // triggeredAt based
  // console.log('current', current.triggeredAt);
  // console.log('next', next.triggeredAt);

  if (current.triggeredAt && next.triggeredAt) {
    const currentTime = getTimeFromDateOrString(current.triggeredAt);
    const nextTime = getTimeFromDateOrString(next.triggeredAt);
    if (currentTime < nextTime) return true;
    // ignore next if its older even if it has greater count
    if (currentTime > nextTime) return false;
    console.log('trigger dates equal');
  }

  // completion state based
  if (isJobComplete(current)) return false;
  if (isJobComplete(next)) return true;

  console.log('both configs are IN_PROGRESS');

  // toProcessBased count based
  if (
    current.toProcessCount !== undefined &&
    next.toProcessCount !== undefined &&
    current.toProcessCount < next.toProcessCount
  )
    return true;

  // completed count based
  if (
    current.completedCount !== undefined &&
    next.completedCount !== undefined &&
    current.completedCount < next.completedCount
  )
    return true;

  console.log('current counts are higher');

  return false;
};

export const isJobComplete = (jobConfiguration?: JobConfiguration): boolean =>
  Boolean(jobConfiguration?.jobStatus && ['COMPLETED', 'JOB_FAILED', 'CANCELED'].includes(jobConfiguration.jobStatus));

export const updateJobConfigurationIfNecessary =
  (newJobConfiguration: JobConfiguration) => (previousJobConfiguration?: JobConfiguration) =>
    shouldUpdateJobConfiguration(previousJobConfiguration, newJobConfiguration)
      ? newJobConfiguration
      : previousJobConfiguration;

export const shouldUpdateCount = (fromServer?: number, inMemory?: number) => {
  if (typeof fromServer !== 'number') return false;
  if (typeof inMemory !== 'number') return true;
  return fromServer > inMemory;
};
