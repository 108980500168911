import { useState, useEffect } from 'react';
import { AllQueryFiltersType } from '../components/filters';

export const useMemoizedQuery = (initialQuery) => {
  const [memoizedQuery, setMemoizedQuery] = useState<Partial<AllQueryFiltersType>>(initialQuery);

  useEffect(() => {
    if (JSON.stringify(memoizedQuery) !== JSON.stringify(initialQuery)) {
      setMemoizedQuery(initialQuery);
    }
  }, [initialQuery]);

  return memoizedQuery;
};

export default useMemoizedQuery;
