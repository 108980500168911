import React, { useContext, useState } from 'react';
import { SidebarTableSection } from '../../../atoms';
import { useJournalEntries, useTabState } from '../../../../hooks';
import { SidebarGlobalContext } from '../../../../context';
import { mergeJournalEntries } from '../../../templates/utils';
import Table from 'src/components/tables/tanstack-table/Table';
import { journalEntryTableColumns } from './journalEntryTableColumns';
import { DensityDropdown, EditColumns } from 'src/components/tables/table-components';
import { ColumnsProps } from 'src/components/tables/columns/types';

const JournalEntriesTable = ({ transactionId }) => {
  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(journalEntryTableColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  const { updateTabSidebarState } = useTabState();
  const { openSidebar } = useContext(SidebarGlobalContext);
  const { data: jeData, isLoading: jeLoading } = useJournalEntries({
    transactionIds: [transactionId],
    status: ['POSTED', 'SUBMITTED', 'REVERSED', 'DRAFT'],
  });
  const mergedJournalEntries = mergeJournalEntries(jeData);

  return (
    <SidebarTableSection loading={jeLoading}>
      <div className='w-full'>
        <Table
          enableRowSelection={false}
          columns={columns}
          panelTableName={'JournalEntriesTable'}
          onRowClick={(row) => {
            updateTabSidebarState({ secondRouteUnStack: {} });
            openSidebar('journals', { id: row.original._id, primaryOrSecondary: 'secondary' });
          }}
          isLoading={jeLoading}
          data={
            mergedJournalEntries?.map((je) => {
              let totalCredits = 0;
              let totalDebits = 0;
              je.journalEntryLineIds?.forEach((jel) => {
                if (jel.creditOrDebit === 'CREDIT') {
                  totalCredits += parseFloat(jel.amount.$numberDecimal);
                } else {
                  totalDebits += parseFloat(jel.amount.$numberDecimal);
                }
              });
              return {
                journalSequenceNumber: je.journalSequenceNumber,
                status: je.status,
                credits: totalCredits,
                debits: totalDebits,
                createdAt: je.createdAt,
                createdBy: je?.originatedBy,
                _id: je._id,
                isSync: je?.isSync,
              };
            }) || []
          }
          disableColumnPinning={true}
        >
          <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
            <div className='flex items-center gap-3 flex-1'></div>
            <div className='flex flex-wrap gap-3'>
              <EditColumns
                onColumnsUpdate={handleColumnsUpdate}
                columns={columns}
                panelTableName={'JournalEntriesTable'}
              />
              <DensityDropdown />
            </div>
          </div>
        </Table>
      </div>
    </SidebarTableSection>
  );
};

export default JournalEntriesTable;
