import { useLegalEntities } from 'src/hooks';
import { getDisplayedLegalEntities, mergeLegalEntities } from '../templates/utils';
import { CheckboxListItem, MultiSelectMenuV2 } from 'ui';

type Option = {
  value: string;
  label: string;
};
const getLabelFromSelectionsFn = (selectedOptions: Option[]) => {
  if (selectedOptions.length === 0) return 'Select entities';
  if (selectedOptions.length === 1) return selectedOptions[0].label;
  return `${selectedOptions[0].label} + ${selectedOptions.length - 1}`;
};
export const LegalEntityMultiSelectMenu = ({
  selectedLegalEntities,
  setSelectedLegalEntities,
}: {
  selectedLegalEntities: Option[];
  setSelectedLegalEntities: React.Dispatch<React.SetStateAction<Option[]>>;
}) => {
  const { data: legalEntities, isLoading: isLoadingLegalEntities } = useLegalEntities();
  let mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const onSelectAll = () => setSelectedLegalEntities(displayedLegalEntities);
  const onDeselectAll = () => setSelectedLegalEntities([]);
  const onRowChange = (option, checked: boolean) =>
    setSelectedLegalEntities((prev) => (checked ? [...prev, option] : prev.filter((o) => o.value !== option.value)));

  return (
    <MultiSelectMenuV2
      onSelectAll={onSelectAll}
      onDeselectAll={onDeselectAll}
      isLoading={isLoadingLegalEntities}
      label={getLabelFromSelectionsFn(selectedLegalEntities)}
      allOptionsSelected={displayedLegalEntities.length === selectedLegalEntities.length}
      hasSelections={selectedLegalEntities.length > 0}
    >
      {displayedLegalEntities.map((option, i) => (
        <CheckboxListItem
          key={i}
          enableSelectOnly={false}
          label={option.label}
          checked={!!selectedLegalEntities.find((o) => o.value === option.value)}
          onCheckedChange={(checked) => onRowChange(option, checked)}
        />
      ))}
    </MultiSelectMenuV2>
  );
};
