import { useQuery } from '@tanstack/react-query';
import { getStripeDetails } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';
import { useSession } from '../../../useSession';

export const useGetStripeDetailsForOrgQuery = (disabled?: boolean) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId, token } = useSession();
  return useQuery(
    getKey(QUERY_KEY.STRIPE_DETAILS_FOR_ORG),
    async () => {
      const response = await getStripeDetails();
      return response.data.stripeDetails;
    },
    {
      enabled: !disabled && !!organizationId && !!token,
      retry: false,
      cacheTime: 30 * 1000,
    });
};
