import { useRuleConfigurator } from '../../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../../data';
import { ChainInlineConditionMenu } from './ChainInlineConditionMenu';
import { ConditionRow } from '../ConditionRow';
import { FACT } from '../../../types';
import { useCondition } from '../../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  return (
    <>
      <SingleSelectMenu value={{ label: 'Chain', value: 'Chain' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <ChainInlineConditionMenu
        rule={condition}
        callbackFn={(value) => {
          updateCondition(condition.id, {
            value: value,
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const ChainRuleCondition = () => (
  <ConditionRow facts={[FACT.CHAIN]}>
    <Row />
  </ConditionRow>
);
