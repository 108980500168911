import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAllLegalEntities, useGetSourceById, useTransactions } from '../../hooks/http';
import { getDisplayedLegalEntities, getDisplayedTags } from '../templates/utils';
import { useAllTags } from '../../hooks/http/useAllTags';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { SidebarBody, SidebarSection, SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { DetailsItem, SidebarTableSection } from '../atoms';
import { CryptoIcon } from '../utils';
import { getHost } from '../../lib/utils';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getDisplayedTransactions } from './utils';
import { shortText } from '../rules/utils';
import { useGetRaincardSources } from '../../hooks/http/useRaincards';
import Table from '../tables/tanstack-table/Table';
import { transactionsColumns } from '../tables';
import { DensityDropdown, EditColumns } from '../tables/table-components';
import { ColumnsProps } from '../tables/columns/types';
import { SortingState } from '@tanstack/react-table';

type rainCardData = {
  id: string;
  cardType: string;
  nickname: string;
  status: string;
};
interface RainCard {
  _id: string;
  legalEntityId: string;
  cardData: rainCardData;
}
export const ViewSourceSidebarContent = ({ sourceId, onPatch }) => {
  const { data: wallet, isLoading: isLoadingWallet } = useGetSourceById(sourceId);
  const { data: raincard, isLoading: isLoadingRaincards } = useGetRaincardSources();
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);
  const [rainCardData, setRainCardData] = useState<RainCard>();
  const [sortState, setSortState] = useState<SortingState>([]);
  const { data: recentTransactions, isLoading: recentTransactionLoading } = useTransactions({
    pageSize: 25,
    sourceIds: [sourceId],
    walletIds: [sourceId],
    addresses: [wallet?.address ?? ''],
    sort: sortState.length > 0 ? sortState[0] : { id: 'transactionDate', desc: true },
  });
  const { tags, isLoading: isLoadingTags } = useAllTags();
  const SELECTED_TAGS = useMemo(
    () =>
      getDisplayedTags(tags)
        .filter((tag) => wallet?.tags?.includes(tag.value))
        .map((tag) => tag.label),
    [tags, wallet],
  );

  const { openSidebar } = useContext(SidebarGlobalContext);

  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(transactionsColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  let transactionsToDisplay: any[] = []; // TODO: add return types to getDisplayedTransactions fn
  if (recentTransactions) {
    transactionsToDisplay = getDisplayedTransactions(recentTransactions);
  }

  const dataCy = 'viewSource';

  useEffect(() => {
    if (raincard) {
      const rainCardData = raincard.filter((item) => item._id === sourceId);
      if (rainCardData.length > 0) {
        setRainCardData(rainCardData[0]);
      }
    }
  }, [raincard, sourceId]);

  const Raincard = ({ cardData }) => {
    return (
      <div className='w-[428px] items-center'>
        <div className='card rounded-2xl flex-col flex w-[428px] h-[256px] p-8 my-8 border border-[rgba(17, 34, 17, 0.18)] bg-white'>
          <div className='grid justify-end mb-8'>
            <div>
              <img src='/raincards-card-logo.png' />
            </div>
          </div>

          <div className='text-xl font-medium'>
            {cardData.cardholderFirstName} {cardData.cardholderLastName}
          </div>
          <div className='pt-2 font-medium text-xl'>**** **** **** {cardData.last4}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SidebarHeader
        title={wallet?.name ?? shortText(wallet?.address) ?? rainCardData?.cardData?.nickname ?? ''}
        status={{
          label: wallet?.status?.toLowerCase() ?? rainCardData?.cardData?.status?.toLowerCase() ?? '',
          type: wallet?.status == 'ACTIVE' ?? rainCardData?.cardData?.status == 'ACTIVE' ? 'positive' : 'neutral',
        }}
        actions={[
          {
            label: 'Edit',
            onClick: onPatch,
            variant: 'primary',
            'data-cy': `editButton`,
          },
        ]}
        data-cy={dataCy}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection
          numberOfColumns={2}
          loading={isLoadingWallet || isLoadingEntities || isLoadingTags || isLoadingRaincards}
        >
          <DetailsItem
            tag='Type'
            value={(wallet?.walletType as string) || (rainCardData?.cardData?.cardType as string)}
          />
          <DetailsItem tag='Name' value={(wallet?.name as string) || (rainCardData?.cardData?.nickname as string)} />
          <DetailsItem
            tag='Chain'
            value={wallet?.chain.toUpperCase() as string}
            leadingComponent={<CryptoIcon symbol={wallet?.chain} size='sm' />}
          />
          {typeof wallet?.addresses?.length === 'number' && wallet.addresses.length > 1 ? (
            <DetailsItem maxCharacters={10} tag='Addresses' value={wallet?.addresses} />
          ) : (
            <DetailsItem
              tag='Address'
              maxCharacters={10}
              textToCopy={wallet?.address}
              value={wallet?.address as string}
              truncate={true}
            />
          )}
          <DetailsItem
            tag='Legal entity'
            value={
              LEGAL_ENTITY_SELECT_OPTIONS.filter(
                (option) => option.value === wallet?.legalEntityId || option.value === rainCardData?.legalEntityId,
              )[0]?.label
            }
            variant={'secondary'}
            textToCopy={`${getHost()}/configure/entities/${wallet?.legalEntityId || rainCardData?.legalEntityId}`}
            itemId={wallet?.legalEntityId as string}
            onClick={() =>
              openSidebar('entities', { id: wallet?.legalEntityId as string, primaryOrSecondary: 'secondary' })
            }
          />
          <DetailsItem tag='Tags' value={SELECTED_TAGS} />
          {rainCardData && <Raincard cardData={rainCardData?.cardData} />}
        </SidebarSection>
        <SidebarSectionHeader title='Recent Transactions' />
        <SidebarTableSection>
          <div className='w-full'>
            <Table
              enableRowSelection={false}
              onRowClick={(row) => {
                openSidebar('transactions', { id: row.original._id, primaryOrSecondary: 'secondary' });
              }}
              isLoading={recentTransactionLoading}
              columns={columns}
              panelTableName={'ViewSourceSidebarContent'}
              data={transactionsToDisplay || []}
              disableColumnPinning={true}
              onSortingChange={setSortState}
            >
              <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                <div className='flex items-center gap-3 flex-1'></div>
                <div className='flex flex-wrap gap-3'>
                  <EditColumns
                    onColumnsUpdate={handleColumnsUpdate}
                    columns={columns}
                    panelTableName={'ViewSourceSidebarContent'}
                  />
                  <DensityDropdown />
                </div>
              </div>
            </Table>
          </div>
        </SidebarTableSection>
      </SidebarBody>
    </>
  );
};
