import { Cog8ToothIcon } from '@heroicons/react/24/solid';
import { HiDocumentReport, HiLibrary, HiPuzzle, HiSparkles, HiTemplate } from 'react-icons/hi';

export const DROP_DOWN_MENU_NAVIGATION = [
  { value: 'Overview', path: '/overview' },
  {
    value: 'Ledger',
    path: '/ledger/transactions',
    children: [
      { value: 'Transactions', path: '/ledger/transactions' },
      { value: 'Sources', path: '/ledger/sources' },
      { value: 'Journals', path: '/ledger/journals' },
      { value: 'Assets', path: '/ledger/assets' },
    ],
  },
  { value: 'Copilot', path: '/copilot' },
  {
    value: 'Configure',
    path: '/configure/templates',
    children: [
      { value: 'Templates', path: '/configure/templates' },
      { value: 'Rulesets', path: '/configure/rulesets' },
      { value: 'Tags', path: '/configure/tags' },
      { value: 'Impairment', path: '/configure/impairment-rules' },
      { value: 'Ledger Accounts', path: '/configure/ledger-accounts' },
      { value: 'Entities', path: '/configure/entities' },
    ],
  },
  {
    value: 'Reports',
    path: '/reports/incomestatement',
    children: [
      { value: 'Income statement', path: '/reports/incomestatement' },
      { value: 'Balance sheet', path: '/reports/balancesheet' },
      { value: 'Trial balance', path: '/reports/trialbalance' },
      { value: 'Schedule of disposition', path: '/reports/schedule-of-disposition' },
      { value: 'Asset tax lots', path: '/reports/asset-tax-lots' },
      { value: 'Realized gain/loss', path: '/reports/realized-gain-or-loss' },
      { value: 'Closing positions', path: '/reports/closing-positions' },
      { value: 'Account transactions', path: '/reports/accounting-transactions' },
    ],
  },
  { value: 'Integrations', path: '/integrations' },
];

export const LANDING_LEDGER_SECONDARY_NAVIGATION = [
  {
    value: 'Transactions',
    selected: true,
  },
  {
    value: 'Sources',
  },
  {
    value: 'Journals',
  },
  {
    value: 'Assets',
  },
];

export const LANDING_REPORT_SECONDARY_NAVIGATION = [
  {
    value: 'Income statement',
  },
  {
    value: 'Balance sheet',
  },
];

export const LANDING_PAGE_PRIMARY_NAVIGATION = [
  {
    value: 'Overview',
    path: '/overview',
    unRedirect: true,
  },
  {
    value: 'Ledger',
    path: '/ledger/transactions',
    children: [
      {
        value: 'Transactions',
        path: '/ledger/transactions',
        unRedirect: true,
      },
      {
        value: 'Sources',
        path: '/ledger/sources',
        unRedirect: true,
      },
      {
        value: 'Journals',
        path: '/ledger/journals',
        unRedirect: true,
      },
      {
        value: 'Assets',
        path: '/ledger/assets',
        unRedirect: true,
      },
    ],
  },
  {
    value: 'Configure',
    path: '/configure/templates',
    children: [
      {
        value: 'Templates',
        path: '/configure/templates',
        unRedirect: true,
      },
      {
        value: 'Rulesets',
        path: '/configure/rulesets',
        unRedirect: true,
      },
      {
        value: 'Tags',
        path: '/configure/tags',
        unRedirect: true,
      },
      {
        value: 'Impairment',
        name: 'impairment-rules',
        path: '/configure/impairment-rules',
        unRedirect: true,
      },
      {
        value: 'Ledger Accounts',
        name: 'ledger-accounts',
        path: '/configure/ledger-accounts',
        unRedirect: true,
      },
      {
        value: 'Entities',
        path: '/configure/entities',
        unRedirect: true,
      },
    ],
    checked: false,
  },
  {
    value: 'Reports',
    path: '/reports/incomestatement',
    children: [
      {
        value: 'Income statement',
        name: 'incomestatement',
        path: '/reports/incomestatement',
        unRedirect: true,
      },
      {
        value: 'Balance sheet',
        name: 'balancesheet',
        path: '/reports/balancesheet',
        unRedirect: true,
      },
      {
        value: 'Trial Balance',
        name: 'trialbalance',
        path: '/reports/trialbalance',
        unRedirect: true,
      },
    ],
    checked: false,
  },
  {
    value: 'Integrations',
    path: '/integrations',
    unRedirect: true,
  },
];

export const modifyLandingNavSelected = (nav, selected) => {
  const updateChecked = (items) => {
    return items.map((item) => {
      if (item.children) {
        return {
          ...item,
          checked: item.path === selected ? true : false,
          children: updateChecked(item.children),
          overrideCheck: selected.includes(item.path),
        };
      }
      return {
        ...item,
        checked: item.path === selected ? true : false,
        overrideCheck: item.path === selected ? true : false,
      };
    });
  };

  return updateChecked(nav);
};

export const NAV_ITEMS = [
  {
    name: 'overview',
    url: '/new-ai-layout/overview',
    icon: <HiTemplate />,
  },
  {
    name: 'Copilot',
    value: 'copilot-chat',
    url: '/new-ai-layout/copilot-chat',
    icon: <HiSparkles />,
  },
  {
    name: 'ledger',
    url: '/new-ai-layout/ledger/transactions',
    icon: <HiLibrary />,
  },
  {
    name: 'configure',
    url: '/new-ai-layout/configure/templates',
    icon: <Cog8ToothIcon />,
  },
  {
    name: 'reports',
    url: '/new-ai-layout/reports/income-statement',
    icon: <HiDocumentReport />,
  },
  {
    name: 'integrations',
    url: '/new-ai-layout/integrations/all-integrations',
    icon: <HiPuzzle />,
  },
];
