import { useEffect, useState } from 'react';
import { FilterRowState } from './types';
import { FilterFn } from './types';

export function useSearch<T>(state: FilterRowState<T>[], filterFn: FilterFn<T>) {
  const [searched, setSearched] = useState('');
  const [results, setResults] = useState<FilterRowState<T>[]>(state);

  useEffect(() => {
    setResults(filterFn(state, searched));
  }, [state, filterFn, searched]);

  return { searched, setSearched, results };
}
