import React from 'react';
import { useBulkDeleteTag } from '../../hooks/http';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';

export function TagMultiSelectActionsComponent({ selectedRows, table }) {
  const tagIds = selectedRows?.map((item) => item?.original?._id);
  const { mutateAsync: bulkDeleteTag, isLoading: isDeleting } = useBulkDeleteTag();
  const { invalidateTags } = useInvalidateQuery();

  const { organizationId } = useSession();

  const handleBulkDeleteTag = async () => {
    try {
      await bulkDeleteTag(
        {
          organizationId,
          tagIds,
        },
        {
          onSuccess: async () => {
            invalidateTags();

            toast.success('Tag(s) deleted successfully');
          },
          onError: (error) => {
            console.error(error);

            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);

      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex ml-auto gap-4'>
      <Button status='danger' label='Delete' emphasis='medium' onClick={handleBulkDeleteTag} isLoading={isDeleting} />
    </div>
  );
}
