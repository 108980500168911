import React, { useMemo, useState } from 'react';
import {
  AppLineChart,
  AppLineChartProps,
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownContent,
  DropdownGroupBody,
  DropdownTitleWithToggle,
  DropdownTrigger,
  classNames,
} from 'ui';
import { useTransactionsVolume } from '../../../hooks/http/useTransactionsVolume';
import { formatDollars } from '../../templates/utils';
import { Legend, TabGroup, TabList, Tab } from '@tremor/react';
// import { addMonths, addWeeks, addYears } from 'date-fns';
import { exportChart } from '../../../lib/utils';
import { ChartExportDropdown } from 'ui';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';
import { useTransactionQueryParams } from './use-transaction-query-params';

enum TABS {
  WEEK = 0,
  MONTH = 1,
  YEAR = 2,
}

const getTabName = (tab: TABS) => {
  switch (tab) {
    case TABS.WEEK:
      return 'Week';
    case TABS.MONTH:
      return 'Month';
    case TABS.YEAR:
      return 'Year';
  }
};

// const getISOStringFromPeriod = (period: TABS, now) => {
//   switch (period) {
//     case TABS.WEEK:
//       return addWeeks(now, -1).toISOString();
//     case TABS.MONTH:
//       return addMonths(now, -1).toISOString();
//     case TABS.YEAR:
//       return addYears(now, -1).toISOString();
//   }
// };

const categories = ['Gross Amount', 'Net Amount', 'Fee'];
const colors = ['green', 'yellow', 'red'];

export const TransactionsChart = () => {
  const [selectedTab, setSelectedTab] = useState(TABS.MONTH);
  const [lines, setLines] = useState(categories);
  const [open, setOpen] = useState(false);

  const onClearAllLines = () => {
    setLines([]);
  };

  const onSelectAllLines = () => {
    setLines(categories);
  };

  const transactionParams = useTransactionQueryParams();

  const { data: fetchedTransactionsChartData, isLoading: volumeLoading } = useTransactionsVolume(transactionParams);

  const lineChartProps = useMemo<AppLineChartProps>(
    () => ({
      data: fetchedTransactionsChartData?.data?.slice().reverse() || [],
      index: 'Date',
      categories: lines,
      autoMinValue: true,
      valueFormatter: formatDollars,
      colors: colors as any[],
      isLoading: volumeLoading,
      containerId: 'transaction-chart',
    }),
    [fetchedTransactionsChartData, volumeLoading, lines, selectedTab],
  );

  return (
    <AppLineChart {...lineChartProps}>
      <div className='flex justify-between mb-6 flex-wrap gap-y-2'>
        <TabGroup
          onIndexChange={(index) => {
            setSelectedTab(index);
          }}
          index={selectedTab}
          className='flex-1'
        >
          <TabList variant='solid' className='border-b-0 !h-10 flex w-fit items-center px-1'>
            <Tab className='border-0 data-[headlessui-state=selected]:!text-indigo-600'>{getTabName(TABS.WEEK)}</Tab>
            <Tab className='border-0 data-[headlessui-state=selected]:!text-indigo-600'>{getTabName(TABS.MONTH)}</Tab>
            <Tab className='border-0 data-[headlessui-state=selected]:!text-indigo-600'>{getTabName(TABS.YEAR)}</Tab>
          </TabList>
        </TabGroup>
        <div className='max-w-fit flex gap-x-4 ignore-export'>
          <Dropdown open={open} onOpenChange={setOpen}>
            <DropdownTrigger>
              <span>
                <Button
                  label={
                    lines.length === 0 ? (
                      'Select chart data'
                    ) : (
                      <p className='text-indigo-600'>
                        {lines[0] + ' '}
                        <span>{lines.length > 1 ? lines.length + '+' : ''}</span>
                      </p>
                    )
                  }
                  className={classNames(
                    'duration-100 py-0',
                    lines.length > 0 && 'shadow',
                    lines.length > 0 && 'bg-indigo-50 border-indigo-600',
                  )}
                  labelContainerClassname={classNames('font-medium ', lines.length > 0 && 'text-indigo-600')}
                  emphasis='medium'
                  trailingIconContainerClassname={classNames(
                    lines.length > 0 && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
                    lines.length > 0 && 'bg-indigo-50',
                  )}
                  trailingIcon={
                    <div className='flex items-center'>
                      {lines.length > 0 && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            onClearAllLines();
                          }}
                        >
                          <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                        </span>
                      )}

                      <MdArrowDropDown
                        className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')}
                      />
                    </div>
                  }
                />
              </span>
            </DropdownTrigger>
            <DropdownContent>
              <DropdownTitleWithToggle
                title='Select all'
                checked={
                  categories.length === lines.length && categories.every((value, index) => value === lines[index])
                }
                onChange={(checked: boolean) => (checked ? onSelectAllLines() : onClearAllLines())}
              />
              <DropdownGroupBody>
                {categories.map((category) => (
                  <CheckboxListItem
                    key={category}
                    label={category}
                    checked={lines.includes(category)}
                    onCheckedChange={(selected: boolean) => {
                      if (selected) {
                        setLines([...lines, category]);
                      } else {
                        setLines(lines.filter((line) => line !== category));
                      }
                    }}
                  />
                ))}
              </DropdownGroupBody>
            </DropdownContent>
          </Dropdown>
          <ChartExportDropdown
            onPngExport={() =>
              exportChart({
                chartId: 'transaction-chart',
                ignore: ['tremor-TabGroup-root'],
                format: 'png',
                title: `Transactions Volume Chart - ${getTabName(selectedTab)}`,
              })
            }
            onSvgExport={() =>
              exportChart({
                chartId: 'transaction-chart',
                ignore: ['tremor-TabGroup-root'],
                format: 'svg',
                title: `Transactions Volume Chart - ${getTabName(selectedTab)}`,
              })
            }
          />
        </div>
      </div>
      <div className='flex justify-end w-full mb-4'>
        <Legend categories={lines} colors={colors as any} />
      </div>
    </AppLineChart>
  );
};

export default TransactionsChart;
