import { CardTitle } from '@/shadcn/ui/card';
import { GrTransaction } from 'react-icons/gr';
import { TbCurrencyBitcoin } from 'react-icons/tb';
import { FaChartBar } from 'react-icons/fa';
export const CARD_CONTENT = [
  {
    header: (
      <>
        <CardTitle>TOTAL TRANSACTIONS</CardTitle>
        <GrTransaction />
      </>
    ),
    content: (
      <>
        <div>411</div>
        <p className='text-xs text-muted-foreground'>+20.1% from last month</p>
      </>
    ),
  },
  {
    header: (
      <>
        <CardTitle>TOTAL ASSET TYPES</CardTitle>
        <TbCurrencyBitcoin />
      </>
    ),
    content: (
      <>
        <div>4</div>
        <p className='text-xs text-muted-foreground'>+0.4% from last month</p>
      </>
    ),
  },
  {
    header: (
      <>
        <CardTitle>GROSS VALUE TRANSACTED</CardTitle>
        <FaChartBar />
      </>
    ),
    content: (
      <>
        <div>$188,319.74</div>
        <p className='text-xs text-muted-foreground'>+32% from last month</p>
      </>
    ),
  },
];
