import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getTransactionsVolumeByClassification } from 'services/http/analytics/transactions';

type useTransactionsVolumeParams = {
  directions?: string[];
  chains?: (string | undefined)[];
  transactionFrom?: string;
  transactionTo?: string;
  accountingPeriodIds?: string[];
  legalEntityIds?: string[];
  assetTypes?: string[];
  walletTypesFilter?: string[];
  showTransactionsWithoutJournalEntries?: boolean;
  searchTerm?: string;
  addresses?: string[];
  sourceIds?: string[];
  walletIds?: string[];
};

export const useTransactionsVolumeByClassification = (params: useTransactionsVolumeParams) => {
  const {
    directions = ['Debit', 'Credit'],
    chains,
    transactionFrom,
    transactionTo,
    accountingPeriodIds,
    legalEntityIds,
    assetTypes,
    walletTypesFilter,
    showTransactionsWithoutJournalEntries,
    searchTerm,
    addresses,
    walletIds,
    sourceIds,
  } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.TRANSACTIONS_VOLUME, params),
    async () => {
      const response = await getTransactionsVolumeByClassification({
        transactionFrom,
        transactionTo,
        chains,
        directions,
        accountingPeriodIds,
        legalEntityIds,
        assetTypes,
        walletTypesFilter,
        showTransactionsWithoutJournalEntries,
        searchTerm,
        addresses,
        walletIds,
        sourceIds,
        organizationId,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
