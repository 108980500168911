import React from 'react';
import { FilterProps } from './types';

export const Filter = ({ children, className, style }: FilterProps) => {
  return (
    <div style={style} className={`flex justify-between gap-x-2 px-6 mt-8 ${className} z-10 w-full`}>
      {children}
    </div>
  );
};

export default Filter;
