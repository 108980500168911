import { toast } from 'react-hot-toast';
import { CreateSourceFormState } from './CreateSourceFormElements';

export const isCreateSourceFormStateValid = (formState: CreateSourceFormState) => {
  if (!formState.address.length && !formState.addresses.length) {
    toast.error('We need an address for wallet');
    return false;
  }
  if (!formState.legalEntityId && formState.walletType === 'internal') {
    toast.error('Please select a legal entity for wallet');
    return false;
  }
  if (!formState.chain) {
    toast.error('Please select a chain for wallet');
    return false;
  }
  return true;
};
