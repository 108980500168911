import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { MdInfo } from 'react-icons/md';
import { usePopper } from 'react-popper';
import { InputTooltipProps } from './types';

export const InputTooltip = ({ message, placement = 'top-start' }: InputTooltipProps) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });
  return (
    <Popover className='relative'>
      <Popover.Button className='hover:text-black outline-none' ref={setReferenceElement}>
        <MdInfo className='w-[17px] h-[17px] text-[#808080] ml-[7px] mt-[7px]' />
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className='absolute z-10 text-xs flex py-1 px-2 shadow rounded-lg w-[133px] bg-black opactity-70 text-white mt-2'
      >
        {message}
      </Popover.Panel>
    </Popover>
  );
};
