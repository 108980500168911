import { useRuleConfigurator } from '../../rule-configurator-context';

import { EQUAL_NOTEQUAL_OPERATORS, loopPaths } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { useMemo } from 'react';
import { useLoopRulesMeta } from '../../../../../hooks/http/useLoopService';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { data: loopMeta, isLoading: rainRuleMetaIsLoading } = useLoopRulesMeta();
  const subscriptionNames = useMemo(
    () =>
      loopMeta?.uniqueSubscriptionNames.map((item) => {
        return {
          label: item,
          value: item,
        };
      }) || [],
    [loopMeta],
  );
  const transferTypes = useMemo(
    () =>
      loopMeta?.uniqueTransferTypes.map((item) => {
        return {
          label: item,
          value: item,
        };
      }) || [],
    [loopMeta],
  );
  const frequencies = useMemo(
    () =>
      loopMeta?.uniqueSubscriptionFrequencies.map((item) => {
        return {
          label: item,
          value: item,
        };
      }) || [],
    [loopMeta],
  );

  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  const dropdownOptions = useMemo(() => {
    if (condition.path === '$.loopSubscriptionName') return subscriptionNames;
    else if (condition.path === '$.loopTransferType') return transferTypes;
    else if (condition.path === '$.loopSubscriptionFrequency') return frequencies;
    return [];
  }, [subscriptionNames, transferTypes, frequencies, condition]);

  return (
    <>
      <SingleSelectMenu
        value={{
          label: loopPaths.find((path) => path.value === condition.path)?.label,
          value: condition.operator ?? '',
        }}
        options={loopPaths.map((path) => ({
          label: path.label,
          value: path.value,
        }))}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            path: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        options={dropdownOptions}
        onChange={(option) => {
          updateCondition(condition.id, {
            value: option.value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        placeholder='Select a value'
        isLoading={rainRuleMetaIsLoading}
        value={dropdownOptions.find((option) => option.value === condition.value)}
      />
    </>
  );
};

export const LoopRuleCondition = () => (
  <ConditionRow facts={[FACT.LOOP]}>
    <Row />
  </ConditionRow>
);
