import { useContext, useEffect } from 'react';
import { useAssetsV2 } from '../../../hooks/http/useAssets';
import { useAssetQueryParams } from './use-asset-query-params';
import { MainDirectoryContext } from '../../../context';
import { mergeAssets } from '../../templates/utils';
import { useDisplayedAssets } from './utils';
import AssetsTable from './AssetsTable';

export const AssetTableWithQuery = ({
  sortState,
  setSortState,
  searchTerm,
  setSearchTerm,
  isSearching,
  setIsSearching,
  setShowCreateAsset,
}) => {
  const params = useAssetQueryParams({ searchTerm, sortState });

  const {
    data: assetData,
    isLoading: assetsLoading,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useAssetsV2({
    ...params,
    accountingPeriodStartDateUTCs: params.accountingPeriodStartDateUTCs?.map((date) => new Date(date).toISOString()),
  });

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);

  const { setMainDirectoryIds } = useContext(MainDirectoryContext);
  useEffect(() => {
    const assetIds = mergeAssets(assetData).map((asset) => asset._id);
    if (assetIds) {
      setMainDirectoryIds(assetIds);
    }
  }, [assetData]);

  const displayedAssets = useDisplayedAssets(assetData);

  return (
    <AssetsTable
      isLoading={assetsLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      isSearching={isSearching}
      displayedAssets={displayedAssets}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setShowCreateAsset={setShowCreateAsset}
      setSortState={setSortState}
    />
  );
};
