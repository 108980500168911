'use client';

import React, { useState, useMemo } from 'react';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../../@/components/ui/card';
import { ChartConfig, ChartContainer } from '../../@/components/ui/chart';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../@/components/ui/select';
import { Skeleton } from '../../@/components/ui/skeleton';
interface MonthlyFlowValue {
  month: string;
  cumulativeInflow: number;
  cumulativeOutflow: number;
}

interface CumulativeFlowChartProps {
  data: MonthlyFlowValue[];
  isLoading: boolean;
}

type Period = 'all' | 'last3Months' | 'last6Months' | 'lastYear';

const chartConfig = {
  cumulativeInflow: {
    label: 'Cumulative Inflow',
    color: 'hsl(var(--chart-2))',
  },
  cumulativeOutflow: {
    label: 'Cumulative Outflow',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;
const ChartSkeleton: React.FC = () => {
  const generateSmoothUpwardTrend = () => {
    // Using a quadratic bezier curve for a smooth, consistent upward trend
    return `M0 80 C 25 80, 50 30, 100 20 L100 100 L0 100 Z`;
  };
  return (
    <div className='relative w-full h-full'>
      {/* Background shimmer */}
      <svg width='100%' height='100%' className='absolute inset-0'>
        <defs>
          <linearGradient id='backgroundShimmer' x1='0' x2='100%' y1='0' y2='0'>
            <stop offset='0%' stopColor='rgba(224, 224, 224, 0.4)'>
              <animate attributeName='offset' values='-2; 1' dur='2s' repeatCount='indefinite' />
            </stop>
            <stop offset='50%' stopColor='rgba(224, 224, 224, 0.7)'>
              <animate attributeName='offset' values='-1; 2' dur='2s' repeatCount='indefinite' />
            </stop>
            <stop offset='100%' stopColor='rgba(224, 224, 224, 0.4)'>
              <animate attributeName='offset' values='0; 3' dur='2s' repeatCount='indefinite' />
            </stop>
          </linearGradient>
        </defs>
        <rect width='100%' height='100%' fill='url(#backgroundShimmer)' />
      </svg>

      {/* Upward trend area chart */}
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none' className='absolute inset-0'>
        <defs>
          <linearGradient id='areaShimmer' x1='0' x2='0' y1='0' y2='1'>
            <stop offset='0%' stopColor='rgba(200, 200, 200, 0.7)' />
            <stop offset='100%' stopColor='rgba(200, 200, 200, 0.1)' />
          </linearGradient>
        </defs>
        <path d={generateSmoothUpwardTrend()} fill='url(#areaShimmer)' stroke='none' />
      </svg>

      {/* X-axis */}
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none' className='absolute inset-0'>
        <line x1='0' y1='100' x2='100' y2='100' stroke='#e0e0e0' strokeWidth='1' />
      </svg>
    </div>
  );
};
export function CumulativeFlowChart({ data, isLoading }: CumulativeFlowChartProps) {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('all');
  const [error, setError] = useState<string | null>(null);
  const filteredData = useMemo(() => {
    const now = new Date();
    return data.filter((item) => {
      const itemDate = new Date(item.month);
      switch (selectedPeriod) {
        case 'last3Months':
          return itemDate >= new Date(now.getFullYear(), now.getMonth() - 3, 1);
        case 'last6Months':
          return itemDate >= new Date(now.getFullYear(), now.getMonth() - 6, 1);
        case 'lastYear':
          return itemDate >= new Date(now.getFullYear() - 1, now.getMonth(), 1);
        case 'all':
        default:
          return true;
      }
    });
  }, [data, selectedPeriod]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatXAxis = (tickItem: string) => {
    const date = new Date(tickItem);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      year: 'numeric',
    })
      .format(date)
      .toUpperCase();
    //return date.toLocaleDateString('en-US', { month: 'numeric', year: '2-digit' });
  };
  const formatYAxis = (value: number) => {
    if (value === 0) return '0';
    const absValue = Math.abs(value);
    if (absValue >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
    if (absValue >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
    if (absValue >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
    return value.toFixed(1);
  };

  // Calculate trend percentage (simplified example)
  // const trendPercentage = useMemo(() => {
  //   if (filteredData.length < 2) return 0;
  //   const lastMonth = filteredData[filteredData.length - 1];
  //   const previousMonth = filteredData[filteredData.length - 2];
  //   const lastMonthNet = lastMonth.cumulativeInflow - lastMonth.cumulativeOutflow;
  //   const previousMonthNet = previousMonth.cumulativeInflow - previousMonth.cumulativeOutflow;
  //   return ((lastMonthNet - previousMonthNet) / previousMonthNet) * 100;
  // }, [filteredData]);

  const totals = useMemo(
    () => ({
      inflow: filteredData[filteredData.length - 1]?.cumulativeInflow || 0,
      outflow: filteredData[filteredData.length - 1]?.cumulativeOutflow || 0,
    }),
    [filteredData],
  );

  return (
    <div className='mx-8 my-8'>
      <Card className='w-full'>
        <CardHeader className='flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row'>
          <div className='flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6'>
            <CardTitle>Cumulative Flow</CardTitle>
            <CardDescription>Showing total inflows and outflows for the selected period</CardDescription>
          </div>
          <div className='flex'>
            {['cumulativeInflow', 'cumulativeOutflow'].map((key) => {
              const chart = key as keyof typeof chartConfig;
              const total = totals[key === 'cumulativeInflow' ? 'inflow' : 'outflow'];
              return (
                <div
                  key={chart}
                  className='relative flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6'
                >
                  <span className='text-xs text-muted-foreground whitespace-nowrap'>{chartConfig[chart].label}</span>
                  {isLoading ? (
                    <Skeleton className='h-6 w-24' />
                  ) : (
                    <span className='text-lg font-bold leading-none sm:text-2xl'>{formatCurrency(total)}</span>
                  )}
                </div>
              );
            })}
          </div>
        </CardHeader>
        <CardContent className='p-0'>
          {isLoading ? (
            <div className='h-[200px]'>
              <ChartSkeleton />
            </div>
          ) : (
            <>
              <div className='flex justify-end p-4'>
                <Select value={selectedPeriod} onValueChange={(value: Period) => setSelectedPeriod(value)}>
                  <SelectTrigger className='h-8 w-[180px]'>
                    <SelectValue placeholder='Select period' />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value='all'>All Time</SelectItem>
                    <SelectItem value='last3Months'>Last 3 Months</SelectItem>
                    <SelectItem value='last6Months'>Last 6 Months</SelectItem>
                    <SelectItem value='lastYear'>Last Year</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <ChartContainer config={chartConfig} className='h-[200px] w-full'>
                <ResponsiveContainer width='100%' height='100%'>
                  <AreaChart data={filteredData} margin={{ top: 10, right: 12, left: 12, bottom: 0 }}>
                    <CartesianGrid strokeDasharray='3 3' horizontal={true} vertical={false} className='pl-8' />

                    <XAxis
                      dataKey='month'
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: 10 }}
                      tickFormatter={formatXAxis}
                      interval={'preserveStartEnd'}
                      minTickGap={30}
                      padding={{ left: 40, right: 40 }}
                    />
                    {/* Left Y-Axis for cumulativeInflow */}
                    <YAxis
                      yAxisId='left'
                      tickFormatter={formatYAxis}
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: 10 }}
                      width={60}
                      allowDecimals={false}
                      tickCount={10}
                      domain={['dataMin * 0.9', 'dataMax * 1.2']}
                    />

                    {/* Right Y-Axis for cumulativeOutflow */}
                    <YAxis
                      yAxisId='right'
                      orientation='right'
                      tickFormatter={formatYAxis}
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: 10 }}
                      width={60}
                      allowDecimals={false}
                      tickCount={10}
                      domain={['dataMin * 0.9', 'dataMax * 1.2']}
                    />
                    <Tooltip
                      formatter={(value: number, name: string) => [formatCurrency(value), name]}
                      labelFormatter={formatXAxis}
                    />
                    {/* Area for cumulativeOutflow using the right Y-Axis */}
                    <Area
                      yAxisId='right'
                      dataKey='cumulativeOutflow'
                      type='monotone'
                      fill='var(--color-cumulativeOutflow)'
                      fillOpacity={0.4}
                      stroke='var(--color-cumulativeOutflow)'
                      strokeWidth={1}
                      stackId='a'
                    />

                    {/* Area for cumulativeInflow using the left Y-Axis */}
                    <Area
                      yAxisId='left'
                      dataKey='cumulativeInflow'
                      type='monotone'
                      fill='var(--color-cumulativeInflow)'
                      fillOpacity={0.4}
                      stroke='var(--color-cumulativeInflow)'
                      strokeWidth={1}
                      stackId='a'
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </ChartContainer>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
