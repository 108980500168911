import React, { useState } from 'react';
import { Fieldset } from '../fieldset';
import { InputLabel } from '../input-label';
import { EmailInput } from '../email-input';
import { PasswordInput } from '../password-input';
import { Button } from '../button';
import { QuickbookIntegrationLoginFormProps } from './types';

export const QuickbookIntegrationLoginForm = ({ onConnect, onCancel, icon }: QuickbookIntegrationLoginFormProps) => {
  const [state, setState] = useState({
    email: '',
    password: '',
  });
  return (
    <div className='border rounded-lg bg-white w-[400px]'>
      <div className='flex flex-col items-center justify-center text-center mt-6 mx-8 mb-8'>
        {icon}
        <div className='mb-2 font-medium text-xl'>Connect Quickbooks to Entendre</div>
        <div>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</div>
      </div>

      <Fieldset className='mx-4 mb-8'>
        <div>
          <InputLabel heading='Email' description='Helper text' tooltip='Some hint' />
          <EmailInput value={state.email} onChange={(e) => setState((prev) => ({ ...prev, email: e.target.value }))} />
        </div>
        <div>
          <InputLabel heading='Password' tooltip='Some hint' />
          <PasswordInput
            value={state.password}
            onChange={(e) => setState((prev) => ({ ...prev, password: e.target.value }))}
          />
        </div>
      </Fieldset>
      <div className='mx-10 mb-8 flex flex-row-reverse'>
        <Button label='Connect' onClick={() => onConnect(state)} />
        <Button label='Cancel' onClick={onCancel} className='mr-4' emphasis='medium' />
      </div>
    </div>
  );
};
