import React from 'react';
import { InputLabel, InputWithExtras, SingleSelectMenu, TextareaInput } from 'ui';
import { useJournalEntryTemplates } from '../../hooks/http';
import { getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../templates/utils';

export interface CreateImpairmentRuleFormState {
  name: string;
  description: string;
  journalEntryTemplateId?: string;
}

export interface CreateImpairmentRuleFormElementsProps {
  formState: CreateImpairmentRuleFormState;
  setFormState: React.Dispatch<React.SetStateAction<CreateImpairmentRuleFormState>>;
  'data-cy'?: string;
}

export const CreateImpairmentRuleFormElements = ({
  formState,
  setFormState,
  'data-cy': dataCy = 'createImpairmentRule',
}: CreateImpairmentRuleFormElementsProps) => {
  const { data: journalEntryTemplates, isLoading: isLoadingTemplates } = useJournalEntryTemplates({ pageSize: 1000 });
  const mergedTemplates = mergeJournalEntryTemplates(journalEntryTemplates);
  const displayedTemplates = getDisplayedJournalEntryTemplates(mergedTemplates);

  return (
    <>
      <div>
        <InputLabel heading='Impairment rule name' />
        <InputWithExtras
          data-cy={`${dataCy}__impairmentRuleName`}
          placeholder='Enter impairment rule name'
          value={formState.name}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, name: e.target.value }));
          }}
        />
      </div>
      <div>
        <InputLabel heading='Description' />
        <TextareaInput
          data-cy={`${dataCy}__description`}
          placeholder='Enter impairment rule description'
          value={formState.description}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, description: e.target.value }));
          }}
        />
      </div>
      <div>
        <InputLabel heading='Journal entry template' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          data-cy={`${dataCy}__journalEntryTemplate`}
          options={displayedTemplates}
          onChange={(option: any) => setFormState((prev) => ({ ...prev, journalEntryTemplateId: option.value }))}
          value={displayedTemplates.find((item) => item.value === formState.journalEntryTemplateId)}
          isLoading={isLoadingTemplates}
          placeholder='Select journal entry template'
          onClearValue={() => {
            setFormState((prev) => ({
              ...prev,
              journalEntryTemplateId: '',
            }));
          }}
        />
      </div>
    </>
  );
};
