import { WALLET_TYPE } from 'services/http/response.types';
import { useWalletFilterContext } from '../context';
import { getAddressesFromFilteredWallet } from '../../../components/PageComponents';
import { useMemo } from 'react';

export const useFilteredAddresses = () => {
  const { state } = useWalletFilterContext();

  return useMemo(
    () =>
      state
        .filter((option) => option.selected)
        .filter((option) => option.walletType === WALLET_TYPE.EXTERNAL)
        .map(getAddressesFromFilteredWallet)
        .flat(),
    [state],
  );
};
