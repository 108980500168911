import { ColumnDefTemplate, HeaderContext } from '@tanstack/react-table';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { getUrlWithoutId } from 'src/components/utils';
import { useLocalStorage } from 'usehooks-ts';

type ColumnVisibility = {
  header: ColumnDefTemplate<HeaderContext<any, any>> | undefined;
  accessorKey?: string | number | symbol | (string & {});
  visibility: boolean;
};

function columnVisibilityHandler(columns: ColumnsProps<any, any>[]): ColumnVisibility[] {
  return columns.map((column) => {
    return {
      header: column.header,
      accessorKey: column.accessorKey,
      visibility: column.visibility !== false,
    };
  });
}

export const useColumnVisibility = (columns: ColumnsProps<any, any>[], panelTableName = '') => {
  const { asPath } = useRouter();

  const pageName = `${getUrlWithoutId(asPath).split('/').slice(-1)[0]}${panelTableName ? '-panel-' + panelTableName : ''}`;

  const [editTableState, setEditTableState] = useLocalStorage<{
    [key: string]: ColumnVisibility[];
  }>('tableVisibility', {});

  function saveColumnVisibility(columnVisibility: ColumnVisibility[] | ColumnVisibility) {
    let newColumnVisibilityState;

    if (_.isArray(columnVisibility)) {
      newColumnVisibilityState = editTableState[pageName] = columnVisibility;
    } else {
      newColumnVisibilityState = editTableState[pageName].map((column) => {
        if (column.accessorKey === columnVisibility.accessorKey) {
          return columnVisibility;
        }
        return column;
      });
    }

    setEditTableState((prevState) => {
      return {
        ...prevState,
        [pageName]: newColumnVisibilityState,
      };
    });
  }

  function selectAllColumns() {
    const getInVisibleColumn = editTableState[pageName].find((column) => !column.visibility);
    if (getInVisibleColumn) {
      const newColumnVisibilityState = editTableState[pageName].map((column) => ({ ...column, visibility: true }));
      setEditTableState((prevState) => {
        return {
          ...prevState,
          [pageName]: newColumnVisibilityState,
        };
      });
    } else {
      const newColumnVisibilityState = editTableState[pageName].map((column) => ({ ...column, visibility: false }));
      setEditTableState((prevState) => {
        return {
          ...prevState,
          [pageName]: newColumnVisibilityState,
        };
      });
    }
  }

  useEffect(() => {
    if (!editTableState[pageName]) {
      setEditTableState((prevState) => ({
        ...prevState,
        [pageName]: columnVisibilityHandler(columns),
      }));
    }
  }, []);

  return {
    columnVisibility: editTableState[pageName] ?? [],
    saveColumnVisibility,
    selectAllColumns,
  };
};
