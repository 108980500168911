import { useDebounceValue } from 'usehooks-ts';
import {
  useDateFilterContext,
  useFilteredAccountingPeriodStartDateUTCs,
  useFilteredJournalEntryTemplateIds,
  useFilteredJournalSyncTreatment,
  useFilteredLedgerAccountIds,
  useFilteredLegalEntityIds,
  useFilteredOriginTreatment,
  useFilteredStatus,
} from '../../../context';
import { useEffect } from 'react';
import { SortingState } from '@tanstack/react-table';

type TResult = {
  transactionIds: string[];
  status: string[];
  originatedBy: string[];
  isSync?: boolean;
  accountingPeriodStartDateUTCs: string[];
  legalEntityIds: string[];
  startDate?: string;
  endDate?: string;
  sort?: any;
  searchTerm?: string;
  ledgerAccountIds: string[];
  templateIds: string[];
};
const defaultParams: TResult = {
  transactionIds: [],
  status: [],
  originatedBy: [],
  accountingPeriodStartDateUTCs: [],
  legalEntityIds: [],
  ledgerAccountIds: [],
  templateIds: [],
};
type TInput = { searchTerm?: string; sortState?: SortingState };
export const useJournalQueryParams = ({ searchTerm, sortState }: TInput = {}) => {
  const [params, setParams] = useDebounceValue(defaultParams, 500);
  const accountingPeriodStartDateUTCs = useFilteredAccountingPeriodStartDateUTCs();
  const dateFilter = useDateFilterContext();
  const legalEntityIds = useFilteredLegalEntityIds();
  const ledgerAccountIds = useFilteredLedgerAccountIds();
  const status = useFilteredStatus();
  const originatedBy = useFilteredOriginTreatment();
  const isSync = useFilteredJournalSyncTreatment();
  const templateIds = useFilteredJournalEntryTemplateIds();

  useEffect(
    () =>
      setParams({
        transactionIds: [],
        templateIds,
        status,
        originatedBy,
        isSync,
        accountingPeriodStartDateUTCs: accountingPeriodStartDateUTCs.map((date) => new Date(date).toISOString()),
        legalEntityIds,
        startDate: dateFilter.datesWithTzOffset.start,
        endDate: dateFilter.datesWithTzOffset.end,
        sort: sortState ? sortState[0] : undefined,
        searchTerm: searchTerm || undefined,
        ledgerAccountIds,
      }),
    [
      status,
      originatedBy,
      isSync,
      accountingPeriodStartDateUTCs,
      legalEntityIds,
      dateFilter.datesWithTzOffset,
      sortState,
      searchTerm,
      ledgerAccountIds,
      templateIds,
    ],
  );

  return params;
};
