export const customInputTagStyle = {
  control: (provided, state) => ({
    ...provided,
    height: 42,
    borderRadius: 8,
    boxShadow: state.isFocused ? '0px 0px 0px 1px black' : 'none',
    borderColor: state.isFocused ? 'black' : '#C4C4C4',
    paddingLeft: 5,
    overflow: 'hidden',
    overflowX: 'auto',
    '&:hover': {
      borderColor: '',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    minWidth: 'fit-content',
    height: 42,
    alignItems: 'center',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: '16px !important',
    height: '26px',
    '&:hover': {
      backgroundColor: 'rgb(75, 85, 99, 0.2)',
    },
  }),
};

export const customTags = {
  option: (provided, { isSelected }) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    fontWeight: 500,
    fontSize: 16,
    background: isSelected ? '#E2E2E2' : 'white',
    margin: 'auto',
    borderRadius: 6,
    color: 'black',
    marginBottom: 8,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'rgb(17 17 17 / .08)',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 48,
    width: '100%',
    borderRadius: 8,
    borderWidth: 2,
    boxShadow: state.isFocused ? '0px 0px 0px 1px black' : 'none',
    borderColor: state.isFocused ? 'black' : '#E8E8E8',
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? 'black' : provided.borderColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    height: 'fit-content',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    padding: 4,
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.backgroundColor ? data.backgroundColor : '#1111110A',
    border: '1px solid rgba(17, 17, 17, 0.1)',
    borderRadius: 4,
    color: '#111111cc',
    justifyContent: 'space-between',
    width: 'fit-content',
    alignItems: 'center',
    marginRight: 10,
    fontWeight: 600,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
  }),
  menuList: () => ({
    display: 'block',
  }),
};

export const checkboxStyle = {
  option: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    padding: '8px 12px',
    background: 'white',
    margin: 'auto',
    borderRadius: 4,
    color: 'black',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'rgb(17 17 17 / .08)',
    },
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.backgroundColor ? data.backgroundColor : '#1111110A',
    border: '1px solid rgba(17, 17, 17, 0.1)',
    borderRadius: 4,
    color: '#111111cc',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    fontWeight: 600,
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    minWidth: 'fit-content',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    height: 22,
    '& svg': {
      transition: '300ms !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg': {
        opacity: '0.3 !important',
      },
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    height: 'fit-content',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.17)',
    borderRadius: 8,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
    fontWeight: 500,
  }),
};

export const customDatePickerRange = {
  control: (provided, state) => ({
    ...provided,
    height: 38,
    width: '100%',
    borderRadius: 8,
    borderWidth: 2,
    boxShadow: state.isFocused ? '0px 0px 0px 1px black' : 'none',
    borderColor: state.isFocused ? 'black' : '#E8E8E8',
    background: '#F2F2F2',
    cursor: 'pointer',
    '&:hover': {
      borderColor: state.isFocused ? 'black' : provided.borderColor,
    },
  }),
};

export const styleEdit = {
  container: (provided) => ({
    ...provided,
    width: 'fit-content',
    minWidth: 'fit-content',
  }),
  control: (provided) => ({
    ...provided,
    height: 38,
    borderRadius: 8,
    borderWidth: 1,
    boxShadow: 'none',
    borderColor: 'none',
    cursor: 'pointer',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    '&:hover': {
      borderColor: '#b3b3b3',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontWeight: 500,
    fontSize: 16,
    background: state.isSelected ? '#E2E2E2' : 'white',
    margin: 'auto',
    borderRadius: 6,
    color: 'black',
    marginBottom: 8,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'rgb(17 17 17 / .08)',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    transition: '0.3s',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    transform: 'none',
    position: 'static',
    maxWidth: '100%',
    fontWeight: 500,
    fontSize: 16,
  }),
  menu: (provided) => ({
    ...provided,
    width: 'auto',
    minWidth: '100%',
    height: 'fit-content',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.17)',
    borderRadius: 8,
    padding: 4,
  }),
  dummyInput: (provided) => ({
    ...provided,
    position: 'absolute',
    zIndex: '10',
  }),
};

const afterStyle = 'after:bg-[#E8E8E8] after:absolute after:w-[52px] after:h-[52px] after:-z-10';
const nextElmStyle =
  'next-elm:w-[52px] next-elm:h-[52px] next-elm:bg-[#343434] next-elm:rounded-[50%] next-elm:!top-0 next-elm:!left-[3px]';

export const DateRangeStyle = {
  monthAndYearWrapper: '!absolute w-[108%] top-1/2 first:!left-[-35px]',
  monthAndYearPickers: '!hidden',
  nextPrevButton:
    '!p-0 !w-[48px] !h-[48px] !border-[#E8E8E8] !border-solid !border-2 !rounded-[50%] !z-20 !border-2 !bg-white children:!m-auto',
  monthName: '!text-black !text-xl !font-medium',
  month: '!w-6/12',
  day: '!h-[52px]',
  startEdge: `!text-[#343434] !w-[52px] !h-[52px] !rounded-[64px] !top-0 !left-[4px] !z-10 child:!text-white ${nextElmStyle} ${afterStyle}`,
  inRange: '!w-full !h-[52px] !top-0 !left-[5px] !text-[#E8E8E8] child:!text-black',
  dayPassive: 'children-day-passive-span:!text-[#d5dce0] children-day-passive:!bg-transparent',
  endEdge: `!text-[#343434] !w-[52px] !h-[52px] !rounded-[64px] !top-0 !left-[4px] !z-10 child:!text-white after:!left-[-24px] ${nextElmStyle} ${afterStyle}`,
  dayNumber: 'z-10 !font-medium !text-base !text-black children:after:hidden',
  weekDay: '!font-medium !text-black !text-base',
  dayStartOfWeek: 'last-range:after:hidden children-in-range:!rounded-none',
  dayStartOfMonth: 'last-range:after:hidden children-in-range:!rounded-none',
  dayEndOfWeek: 'first-range:after:hidden children-in-range:!rounded-none',
  dayEndOfMonth: 'first-range:after:hidden children-in-range:!rounded-none',
};
