import { Button } from '../button';
import { BannerProps } from './types';

export const Banner: React.FC<BannerProps> = ({ title, actions = [] }) => {
  return (
    <div className='border py-6 px-6 rounded-[16px] bg-[#f5f5ff] border-[#c4c4ff] items-center flex text-2xl font-semibold'>
      {title}
      <div className='ml-auto'>
        {actions.map((action, i) => (
          <Button key={i} onClick={action.onClick} className='ml-4' label={action.label} {...action} />
        ))}
      </div>
    </div>
  );
};
