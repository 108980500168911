export const DetailsCard = ({
  title,
  subtitle,
  number = 1,
  onClick = () => {
    return;
  },
}) => {
  return (
    <div
      className='bg-white py-2 px-4 my-4 rounded-lg border-gray-300 border flex items-center gap-x-4 hover:cursor-pointer hover:bg-gray-50'
      onClick={onClick}
    >
      <div className='bg-gray-200 rounded-full w-8 h-8 flex justify-center items-center'>
        <div>{number}</div>
      </div>
      <div>
        <p className='font-medium'>{title}</p>
        <p className='font-normal'>{subtitle}</p>
      </div>
    </div>
  );
};
