import { SelectableCardProps } from './types';
import { classNames } from '../utils';

export const SelectableCard = ({
  label,
  description,
  selected,
  disabled,
  onClick,
  className,
  'data-cy': dataCy,
}: SelectableCardProps) => {
  return (
    <button
      className={classNames(
        'w-full px-10 py-5',
        'inline-flex items-center justify-center',
        'text-base font-medium rounded-lg',
        'border',
        selected ? 'bg-indigo-50 !border-indigo-600' : 'bg-white',
        disabled ? 'opacity-50' : 'hover:bg-indigo-50',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      data-cy={`${dataCy}__selectableCard`}
    >
      <span className='inline-flex flex-col'>
        <span>{label}</span>
        <span className='text-sm text-gray-500'>{description}</span>
      </span>
    </button>
  );
};
