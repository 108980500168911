import React, { useEffect, useState } from 'react';
import { Button, LOADER_ICON_SIZE, LoaderIcon, Modal, ModalContent, ModalTrigger } from 'ui';
import { StripeProvider } from '../../../context';
import { EditPaymentMethodModalContent } from './edit-payment-method-modal-content';
import toast from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { createSetupIntentForNewCard } from 'services';

export const EditPaymentMethodModal = () => {
  const [open, setOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>();

  useEffect(() => {
    if (open) {
      const createStripeSetupIntentForAddingNewCard = async () => {
        try {
          const response = await createSetupIntentForNewCard();
          setClientSecret(response.data.clientSecret);
        } catch (error) {
          console.log(error);
          toast.error(deriveError(error));
          setOpen(false);
        }
      };

      createStripeSetupIntentForAddingNewCard();
    }

    return () => setClientSecret(undefined);
  }, [open]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Edit' onClick={() => setOpen(true)} emphasis='medium' />
      </ModalTrigger>

      {clientSecret ? (
        <StripeProvider
          options={{
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false,
            },
            clientSecret,
          }}
        >
          <EditPaymentMethodModalContent stripeSetupIntentClientSecret={clientSecret} onCancel={() => setOpen(false)} />
        </StripeProvider>
      ) : (
        <ModalContent title='Edit payment method'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </ModalContent>
      )}
    </Modal>
  );
};
