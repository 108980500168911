import { ReactNode } from 'react';
import { useConditionSelectorContext } from './condition-selector-context';
import { ConditionSelectorSearchBar } from './ConditionSelectorSearchBar';

export const ConditionsGroup = ({
  label,
  children,
  hideSearchBar = false,
}: {
  label: string;
  children: ReactNode;
  hideSearchBar?: boolean;
}) => {
  const { state } = useConditionSelectorContext();
  if (state.currentMenu !== label) {
    // console.log(state.currentMenu, label);
    return null;
  }
  return (
    <div>
      {!hideSearchBar && <ConditionSelectorSearchBar />}

      <div className='overflow-hidden'>{children}</div>
    </div>
  );
};
