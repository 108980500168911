import React from 'react';
import { Button, DialogTrigger, Tooltip, ButtonProps, classNames } from 'ui';

export const BulkUpdateTransactionMemoModalTrigger = ({
  onClick,
  emphasis = 'high',
  containerClassName,
  buttonClassName,
  buttonLabelContainerClassName,
}: ButtonProps & { containerClassName?: string; buttonClassName?: string; buttonLabelContainerClassName?: string }) => {
  return (
    <DialogTrigger className={classNames('mx-4 my-0 py-0 px-0', containerClassName)} asChild>
      <Tooltip content='Update memo'>
        <Button
          className={classNames('h-full mr-4', buttonClassName)}
          onClick={onClick}
          label={`Update memo`}
          emphasis={emphasis}
          variant='sm'
          labelContainerClassname={buttonLabelContainerClassName}
        />
      </Tooltip>
    </DialogTrigger>
  );
};
