import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion, PanInfo, useMotionValue } from 'framer-motion';
import { SidebarContext } from '../../../context/SidebarProvider';
import SidebarAnim from './SidebarAnim';
import { SliderContext } from '../../../context/SliderProvider';

const SidebarStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  border-width: 1px;
  border-top-width: 0px;
  border-right-width: 0px;
  z-index: 10;
`;
interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
  rootId?: string;
  initialWidth?: number;
  'data-cy'?: string;
}

const MINIMUM_WIDTH = 400;
const DEFAULT_WIDTH = 500;

export function Sidebar({
  style,
  className,
  children,
  rootId,
  initialWidth,
  'data-cy': dataCy,
  ...props
}: SidebarProps) {
  const width = useMotionValue(initialWidth || DEFAULT_WIDTH);

  const { numberOfActiveSidebars, setNumberOfActiveSidebars } = useContext(SidebarContext);
  const { sidebarWidth: sidebarRootWidth } = useContext(SliderContext);

  const onDrag = (_, info: PanInfo) => {
    const newWidth = width.get() - info.delta.x;
    if (sidebarRootWidth - info.delta.x < 0.8 * window.outerWidth || info.delta.x > 0) {
      width.set(newWidth < MINIMUM_WIDTH ? MINIMUM_WIDTH : newWidth);
    }
  };

  useEffect(() => {
    if (numberOfActiveSidebars > 0 && !initialWidth) {
      width.set(DEFAULT_WIDTH);
    }
    setNumberOfActiveSidebars((prev) => prev + 1);
    return () => {
      setNumberOfActiveSidebars((prev) => prev - 1);
    };
  }, []);

  return (
    <div className='flex max-w-full overflow-x-clip'>
      <SidebarAnim data-cy={dataCy} variant='body' style={{ width }} className='h-full max-w-full'>
        <SidebarStyle
          id={rootId || ''}
          style={{ ...style }}
          className={`${className} !h-[calc(100vh-65px)]`}
          {...props}
        >
          <div className={`w-fit flex justify-center z-10 absolute`}>
            <motion.div
              className='h-full fixed !w-1 duration-150 !transform-none hover:bg-[#4D4DFF] active:bg-[#4D4DFF] cursor-col-resize'
              drag='x'
              onDrag={onDrag}
              dragSnapToOrigin
              dragElastic={false}
              style={{ width }}
            />
          </div>
          <div className='h-full w-full flex flex-col overflow-hidden'>{children}</div>
        </SidebarStyle>
      </SidebarAnim>
    </div>
  );
}

export default Sidebar;
