import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { DialogContent, InputLabel, InputWithExtras } from 'ui';
import { useBulkEditTransactionsCostBasis, useInvalidateQuery } from '../../../../hooks';
import { deriveError } from '../../../templates/utils';

export const UpdateTransactionCostBasisModalContent = ({ transactionIds, onSuccess = () => {}, onOpenChange }) => {
  const [value, setValue] = useState('1');
  const { invalidateTransactions } = useInvalidateQuery();
  const { mutateAsync: editTransactionCostBasis, isLoading } = useBulkEditTransactionsCostBasis();

  return (
    <DialogContent
      actions={[
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              await editTransactionCostBasis(
                transactionIds.map((_id) => ({ _id, costBasis: parseFloat(value) })),
                {},
              );
              onOpenChange(false);
              toast.success(`${transactionIds.length === 1 ? 'Transaction' : 'Transactions'} cost-basis updated`);
              onSuccess();
              await invalidateTransactions();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
          disabled: isLoading,
          isLoading: isLoading,
        },
        {
          label: 'Cancel',
          emphasis: 'medium',
          onClick: () => {
            onOpenChange(false);
            setValue('1');
          },
        },
      ]}
    >
      <div>
        <InputLabel heading='Cost basis' />
        <InputWithExtras type='number' value={value} onChange={(e) => setValue(e.target.value)} />
      </div>
    </DialogContent>
  );
};
