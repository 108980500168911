import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, capitalizeFirstLetter } from 'ui';
import { useOriginatedByFilterContext } from '../../../../context';

export const OriginatedByFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useOriginatedByFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Created by' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((origin, i) => (
            <CheckboxListItem
              key={i}
              label={capitalizeFirstLetter(origin.value)}
              checked={origin.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No created by to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
