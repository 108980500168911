import Select, {
  components,
  OptionProps,
  SingleValueProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  ClearIndicatorProps,
} from 'react-select';

import { CheckboxMenuProps, CheckboxMenuRowProps } from './types';
import { MdArrowDropDown, MdArrowDropUp, MdCancel } from 'react-icons/md';
import { classNames } from '../utils';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';

const CheckboxMenuRow = ({ label, isSelected = false, showCheckbox = true }: CheckboxMenuRowProps) => (
  <div className='flex items-center w-full'>
    {showCheckbox && (
      <span>
        <input
          type='checkbox'
          checked={isSelected}
          className={classNames(
            'form-checkbox mr-2 text-{#6565F1] w-[20px] h-[20px] border rounded',
            isSelected ? 'border-[#8A8AF4]' : 'border-zinc-200',
          )}
          readOnly
        />
      </span>
    )}
    <span className='flex-grow'>{label}</span>
  </div>
);

const Option = (props: OptionProps<CheckboxMenuRowProps>) => {
  const {
    data: { label, value },
    isSelected,
  } = props;

  return (
    <components.Option {...props}>
      <CheckboxMenuRow label={label} isSelected={isSelected} value={value} />
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<CheckboxMenuRowProps>) => {
  const {
    data: { label, value },
  } = props;

  return (
    <components.SingleValue {...props}>
      {props.data ? <CheckboxMenuRow label={label} showCheckbox={false} value={value} /> : 'Select'}
    </components.SingleValue>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<CheckboxMenuRowProps>) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? <MdArrowDropUp className='w-5 h-5' /> : <MdArrowDropDown className='w-5 h-5' />}
    </components.DropdownIndicator>
  );
};
const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <span className={`inline-flex items-center text-[#111111] hover:opacity-70`}>
      <MdCancel className='w-4 h-4' />
    </span>
  </components.MultiValueRemove>
);
const ClearIndicator = (props: ClearIndicatorProps<CheckboxMenuRowProps>) => (
  <components.ClearIndicator {...props}>
    <span className='text-[#808080] hover:text-black mr-1'>
      <MdCancel className='w-4 h-4' />
    </span>
  </components.ClearIndicator>
);
const LoadingIndicator = () => {
  return <LoaderIcon size={LOADER_ICON_SIZE.SMALL} />;
};

export const CheckboxMenu = ({
  isError,
  options = [],
  disabled,
  isLoading,
  'data-cy': dataCy,
  ...rest
}: CheckboxMenuProps) => (
  <div className='w-full border border-1.5 border-zinc-300 rounded-lg'>
    <Select
      {...rest}
      options={options}
      isDisabled={disabled}
      components={{
        Option,
        SingleValue,
        DropdownIndicator,
        MultiValueRemove,
        ClearIndicator,
        LoadingIndicator,
      }}
      unstyled
      isLoading={isLoading}
      isMulti
      hideSelectedOptions={false}
      id={`${dataCy}__checkboxMenu-container`}
      classNamePrefix={dataCy}
      classNames={{
        control: (state) =>
          classNames(
            'h-fit !min-h-[40px] border border-1.5 border-zinc-300 bg-white rounded-lg pl-3 pr-[14px] py-[7px] shadow-[0_1px_2px_rgba(0,0,0,0.05)]',
            isError && 'border-[#ff0000] ring-[#ff0000] hover:ring-[#ff0000]',
            !isError && state.menuIsOpen && 'ring-2 ring-[#0000ff] hover:ring-[#0000ff]',
            'hover:ring-2 hover:ring-[#808080]',
            state.isDisabled && 'opacity-50 pointer-none',
          ),
        menu: () => 'rounded-lg mt-2 bg-white',
        menuList: () => 'border rounded-lg p-1 grid grid-cols-1 gap-1',
        option: ({ isFocused }) => classNames('rounded bg-white py-[6px] px-[12px]', isFocused && 'bg-zinc-100'),
        multiValue: () => 'bg-[#dedede] rounded px-1 mr-1',
        multiValueLabel: () => 'text-[#111111] opacity-80 mr-1',
        valueContainer: () => 'grid gap-1',
        placeholder: () => 'text-zinc-400',
      }}
    />
  </div>
);
