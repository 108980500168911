import { useEffect, useState } from 'react';
import { useGetRunningJobsQuery } from './http';
import { JobConfiguration } from 'schemas';
import { useInvalidateQuery } from './useInvalidateQuery';
import { shouldFilterByUniqueId } from 'src/context';
import { JOB_NAME } from 'services';

export const useUpdatePollingForJob = (referenceId, jobName, uniqueId?: string) => {
  const [jobConfig, setJobConfig] = useState<JobConfiguration>();

  const { invalidateGetTransactionCountByWallet } = useInvalidateQuery();
  const { data: runningJobConfigs } = useGetRunningJobsQuery({
    jobNames: [jobName],
  });

  useEffect(() => {
    if (runningJobConfigs?.jobConfigurations) {
      const jc = runningJobConfigs.jobConfigurations.find(
        (config) => config.referenceId === referenceId && (shouldFilterByUniqueId(config.jobName as JOB_NAME) ? config.uniqueId === uniqueId : true)
      );
      setJobConfig(jc);
      if (jc) invalidateGetTransactionCountByWallet({ walletId: jc.referenceId });
    }
  }, [runningJobConfigs]);

  return jobConfig;
};
