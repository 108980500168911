import { BaseButtonProps } from './types';

const getStyles = (
  status: BaseButtonProps['status'],
  emphasis: BaseButtonProps['emphasis'],
  variant: BaseButtonProps['variant'],
  isIconOnly: boolean,
): any => {
  const styles: any = {
    classes: {
      container: 'bg-[#111111] hover:bg-[#222222] border border-black rounded-lg',
      label: 'text-[#FFFFFF]',
      icon: 'text-[#FFFFFF]',
    },
    style: {
      boxShadow: '0px 1px 2px 0px hsla(220, 43%, 11%, 0.05)',
    },
  };
  if (status === 'neutral') {
    if (emphasis === 'medium') {
      styles.classes = {
        container: 'bg-[#ffffff] hover:bg-[#eeeeee] border border-[#C9C9C9] rounded-lg',
        label: 'text-[#111111]',
        icon: 'text-[#111111]',
      };
    }

    if (emphasis === 'low') {
      styles.classes = {
        container: 'hover:bg-[#eeeeee] rounded-lg',
        label: 'text-[#111111]',
        icon: 'text-[#111111]',
      };
      styles.style = {};
    }
  }

  if (status === 'danger') {
    if (emphasis === 'high') {
      styles.classes = {
        container: 'bg-[#ff0000] hover:bg-[#dd0000] border border-[#ff0000] rounded-lg',
        label: 'text-[#FFFFFF]',
        icon: 'text-[#FFFFFF]',
      };
    }

    if (emphasis === 'medium') {
      styles.classes = {
        container: 'bg-[#ffffff] hover:bg-[#eeeeee] border border-[#ff0000] rounded-lg',
        label: 'text-[#ff0000]',
        icon: 'text-[#ff0000]',
      };
    }

    if (emphasis === 'low') {
      styles.classes = {
        container: 'hover:bg-[#eeeeee] rounded-lg',
        label: 'text-[#ff0000]',
        icon: 'text-[#ff0000]',
      };
      styles.style = {};
    }
  }

  if (variant === 'xs') styles.classes.container = `${styles.classes.container} h-8 ${isIconOnly && 'w-8'}`;
  if (variant === 'sm') styles.classes.container = `${styles.classes.container} h-9 ${isIconOnly && 'w-9'}`;
  if (variant === 'md') styles.classes.container = `${styles.classes.container} h-10 ${isIconOnly && 'w-10'}`;
  if (variant === 'lg') styles.classes.container = `${styles.classes.container} h-12 ${isIconOnly && 'w-12'}`;
  if (variant === 'xl') {
    styles.classes.container = `${styles.classes.container} h-14 ${isIconOnly && 'w-14'}`;
    styles.style = {
      ...styles.style,
      width: 56,
      height: 56,
    };
  }

  if (isIconOnly) styles.classes.icon = `${styles.classes.icon} inline-flex w-full h-full items-center justify-center`;

  return styles;
};
export default getStyles;
