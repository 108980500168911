import { useMutation } from '@tanstack/react-query';
import { uploadFile } from 'services/http';
import { FileMetadata } from 'services/http/response.types';

export type FileUploadParams = {
  file: any;
  metadata: Omit<FileMetadata, 'userid' | 'uploaderversion' | 'fileid'>;
};

export const useUploadFile = () => useMutation(({ file, metadata }: FileUploadParams) => uploadFile(file, metadata));
