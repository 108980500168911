import axios from 'axios';
import { prepareSearchParams } from './utils';
import { SERVER_URL_CORE } from '../../config';

export const getClosingPositions = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
  });
  return axios.get(`${SERVER_URL_CORE}/v2/get-closing-positions?${stringifiedParams}`);
};
