import { useRuleConfigurator } from '../../rule-configurator-context';

import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { TRANSACTION_DIRECTION_OPTIONS } from '../../constants';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu
        value={{ label: 'Transaction Direction', value: 'Transaction Direction' }}
        disabled={true}
        options={[]}
      />
      <SingleSelectMenu
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
      />

      <SingleSelectMenu
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        options={TRANSACTION_DIRECTION_OPTIONS}
        value={TRANSACTION_DIRECTION_OPTIONS.find(
          (item) => item.value.toLowerCase() === condition.value?.toLowerCase(),
        )}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value: value.toLowerCase(),
          });
        }}
      />
    </>
  );
};

export const TransactionDirectionRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION_DIRECTION]}>
    <Row />
  </ConditionRow>
);
