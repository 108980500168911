import React from 'react';
import { Section, Card } from 'ui';
import { AddPaymentMethodModal } from '../modals';
import { useGetCardsFromStripeQuery, useGetStripeDetailsForOrgQuery } from '../../../hooks';
import { PaymentCard } from '../common';
import { formatDate } from '../../templates/utils';
import LoadingComponent from 'ui/src/section-header/loading-component';

export const PaymentMethodSection = () => {
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  const { data: stripeCards } = useGetCardsFromStripeQuery();
  const defaultPaymentMethodId = stripeDetails?.paymentMethod?.id;
  const defaultSourceId = stripeDetails?.source?.id;

  const lastUpdatedOn =
    stripeCards && stripeCards[0] ? `Last updated on ${formatDate(new Date(stripeCards[0].created * 1000))}` : ' ';

  return (
    <Card>
      <Section heading='Payment methods' description={lastUpdatedOn} headerActions={<AddPaymentMethodModal />}>
        {!stripeCards && <LoadingComponent />}
        {stripeCards && (
          <>
            {stripeCards.length === 0 && <span>No payment methods found</span>}
            <div className='flex flex-col gap-y-6'>
              {stripeCards.map((data, i) =>
                data.card ? (
                  <PaymentCard
                    brand={data.card.brand}
                    last4={data.card.last4}
                    expiresOn={`${data.card.exp_month}/${`${data.card.exp_year}`.slice(-2)}`}
                    isDefault={defaultPaymentMethodId === data.id || defaultSourceId === data.id}
                    paymentMethodId={data.id}
                    key={i}
                  />
                ) : null,
              )}
            </div>
          </>
        )}
      </Section>
    </Card>
  );
};
