import { SERVER_URL_LOOP_SERVICE } from '../config';
import axios from 'axios';

export const getLoopConfiguration = async () => {
  return axios.get(`${SERVER_URL_LOOP_SERVICE}/configuration`);
};

export const createLoopConfiguration = async (body) => {
  return axios.post(`${SERVER_URL_LOOP_SERVICE}/configuration`, body);
};

export const importLoopTransactions = async (body) => {
  // console.log({ body });
  return axios.post(`${SERVER_URL_LOOP_SERVICE}/transactions?userId=${body.userId}`, body);
};

export type LoopRulesMetaType = {
  uniqueSubscriptionNames: string[];
  uniqueTransferTypes: string[];
  uniqueSubscriptionFrequencies: string[];
};

export const getLoopRulesMeta = async () => {
  return axios.get<{
    values: LoopRulesMetaType;
  }>(`${SERVER_URL_LOOP_SERVICE}/meta`);
};
