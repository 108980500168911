import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';
import { useAssetTypes } from '../../../../../../hooks';

import { ConditionsGroup } from '../../ConditionsGroup';
import { Virtuoso } from 'react-virtuoso';
import { ConditionSelectorRow } from '../../ConditionSelectorRow';
import { useConditionSelectorContext } from '../../condition-selector-context';
import { useAddCondition } from '../../useAddCondition';
import { useEffect, useState } from 'react';
import { FACT } from '../../../types';
import { AssetConditionSelectorRow, convertAssetToRowFn, filterNormalAssetsFn } from './AssetConditionsMenu';
import { ConditionGroupFilterConfig } from '../../types';

export const useMyAssetConditionGroup = () => {
  const { data: assetTypes = [] } = useAssetTypes();
  const [conditionGroup, setConditionGroup] = useState<Omit<ConditionGroupFilterConfig, 'rows'>>({
    heading: 'Assets',
    data: assetTypes,
    filterFn: filterNormalAssetsFn,
    convertToRowFn: convertAssetToRowFn,
    Row: AssetConditionSelectorRow,
  });

  useEffect(() => {
    if (assetTypes)
      setConditionGroup({
        heading: 'Assets',
        data: assetTypes,
        filterFn: filterNormalAssetsFn,
        convertToRowFn: convertAssetToRowFn,
        Row: AssetConditionSelectorRow,
      });
  }, [assetTypes]);

  return conditionGroup;
};

export const MyAssetsConditionMenu = () => {
  const { data: assetTypes, isLoading } = useAssetTypes();
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const addCondition = useAddCondition();

  const [filtered, setFiltered] = useState<string[]>([]);

  useEffect(() => {
    if (assetTypes)
      setFiltered(assetTypes.filter((value) => value.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [debouncedSearchTerm, assetTypes]);

  return (
    <ConditionsGroup label='Assets'>
      {filtered.length === 0 && isLoading && (
        <div style={{ height: 250, width: 250 }} className='flex items-center justify-center'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
      {filtered.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={filtered}
          itemContent={(index, token) => (
            <ConditionSelectorRow
              key={index}
              rowData={{ symbol: token.toLowerCase(), label: token }}
              onClick={() => addCondition({ fact: FACT.ASSET, value: token.toLowerCase(), operator: 'equal' })}
            />
          )}
        />
      )}
    </ConditionsGroup>
  );
};
