import { classNames } from '../utils';
import { ButtonProps } from './types';
const getClassBasedOnButtonIndex = (index: number, buttonCount: number) =>
  index === 0 // first button
    ? 'relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-50 focus:z-10'
    : index === buttonCount - 1
    ? // last button
      'relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-50 focus:z-10'
    : // middle button
      'relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-50 focus:z-10';

export default function ButtonGroup({ buttons }: { buttons: ButtonProps[] }) {
  return (
    <span className='isolate inline-flex rounded-md shadow-sm'>
      {buttons.map((buttonProps, i) => (
        <button
          key={i}
          type='button'
          className={classNames(getClassBasedOnButtonIndex(i, buttons.length), buttonProps.className)}
          disabled={buttonProps.disabled}
          onClick={buttonProps.onClick}
        >
          {buttonProps.label}
        </button>
      ))}
    </span>
  );
}
