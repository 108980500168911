import { forwardRef, useEffect, useRef, useState } from 'react';
import { classNames } from '../utils';
import { InputWithExtrasProps } from './types';
import useDimensions from 'react-cool-dimensions';
import { useProbablyControlledStateForInput } from './useProbablyControlledStateForInput';
import TextareaAutosize from 'react-textarea-autosize';

export const getSelectorsForInputWithExtras = (dataCy?: string) => ({
  textarea: `${dataCy}__inputWithExtras_textarea`,
  input: `${dataCy}__inputWithExtras_input`,
  leading: `${dataCy}__inputWithExtras_leading`,
  trailing: `${dataCy}__inputWithExtras_trailing`,
  suffix: `${dataCy}__inputWithExtras_suffix`,
  container: `${dataCy}__inputWithExtras_container`,
});

export const InputWithExtras = forwardRef(function InputWithExtras(
  {
    leading,
    trailing,
    suffix,
    className = '',
    containerClassName = '',
    leadingClassName = '',
    isError,
    isTextarea,
    onEnter,
    'data-cy': dataCy,
    autoComplete,
    onEsc,
    onKeyDown,
    ...rest
  }: InputWithExtrasProps,
  passedRef: any,
) {
  const { observe: containerRef, width: containerWidth } = useDimensions();
  const { observe: leadingRef, width: leadingWidth } = useDimensions();
  const { observe: trailingRef, width: trailingWidth } = useDimensions();
  const { observe: suffixRef, width: suffixWidth } = useDimensions();
  const { inputProps } = useProbablyControlledStateForInput({
    value: rest.value,
    onChange: rest.onChange,
  });

  const [padding, setPadding] = useState({ left: 12, right: 14 });
  const [suffixLeft, setSuffixLeft] = useState(18);

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setSuffixLeft(Math.min(`${inputProps.value}`.length * 9 + 34, containerWidth - trailingWidth - 28));
  }, [inputProps.value, trailingWidth]);

  useEffect(() => {
    setPadding({
      left: leading ? leadingWidth + 22 : 12,
      right: (trailing ? trailingWidth + 26 : 14) + (suffix ? suffixWidth + 24 : 0),
    });
  }, [leadingWidth, trailingWidth]);

  const inputClassName = classNames(
    'form-input rounded-lg w-full border border-1.5 border-zinc-300 pl-3 pr-[14px] leading-5',
    isTextarea ? 'shadow-[0_1px_2px_rgba(0,0,0,0.05)] py-[9px]' : 'h-10 py-2',
    isError && '!border-[#ff0000]',
    isError && 'hover:ring-[#cc0000]',
    !rest.disabled && 'hover:ring-2 hover:ring-[#808080]',
    isError && 'focus:ring-[#ff0000] focus:border-[#ff0000]',
    !rest.disabled && 'focus:ring-2 focus:ring-[#0000ff]',
    rest.disabled && 'opacity-50 pointer-none border-zinc-200 hover:ring-0',
    className,
  );

  useEffect(() => {
    // disable changing value on scroll while input is focused
    const handleWheel = (event: WheelEvent) => {
      if (!inputRef.current) return;
      if (document.activeElement === inputRef.current) {
        inputRef.current.blur();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    return () => window.removeEventListener('wheel', handleWheel);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        'min-w-[280px] rounded-lg relative',
        !isTextarea ? 'shadow-[0_1px_2px_rgba(0,0,0,0.05)]' : 'h-auto',
        containerClassName,
      )}
      data-cy={getSelectorsForInputWithExtras(dataCy).container}
    >
      {isTextarea ? (
        <TextareaAutosize
          {...rest}
          {...inputProps}
          className={inputClassName}
          style={{
            paddingLeft: padding.left,
            paddingRight: padding.right,
          }}
          ref={(ref) => {
            inputRef.current = ref;
            if (typeof passedRef === 'function') {
              passedRef(ref);
            } else if (passedRef) {
              passedRef.current = ref;
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && onEnter) onEnter();
            else if (event.key === 'Escape' && onEsc) onEsc();
            onKeyDown && onKeyDown(event);
          }}
          minRows={3}
          data-cy={getSelectorsForInputWithExtras(dataCy).textarea}
          autoComplete={autoComplete}
        />
      ) : (
        <input
          {...rest}
          {...inputProps}
          className={inputClassName}
          style={{
            paddingLeft: padding.left,
            paddingRight: padding.right,
          }}
          ref={(ref) => {
            inputRef.current = ref;
            if (typeof passedRef === 'function') {
              passedRef(ref);
            } else if (passedRef) {
              passedRef.current = ref;
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && onEnter) onEnter();
            else if (event.key === 'Escape' && onEsc) onEsc();
            onKeyDown && onKeyDown(event);
          }}
          data-cy={getSelectorsForInputWithExtras(dataCy).input}
          autoComplete={autoComplete}
        />
      )}

      {!isTextarea && (
        <span
          className={classNames(
            'absolute flex items-center h-full top-0',
            rest.disabled && 'opacity-50',
            leadingClassName,
          )}
          ref={leadingRef}
          style={{ left: 12 }}
          data-cy={getSelectorsForInputWithExtras(dataCy).leading}
        >
          {leading}
        </span>
      )}
      <span
        className={classNames(
          'text-[#808080] absolute flex items-center h-full top-0',
          isTextarea && 'top-0 bottom-0 pb-[6px]',
          rest.disabled && 'opacity-50',
        )}
        style={{ right: 14 }}
        ref={trailingRef}
        data-cy={getSelectorsForInputWithExtras(dataCy).trailing}
      >
        {trailing}
      </span>
      {!isTextarea && (
        <span
          className={classNames('absolute flex items-center h-full top-0', rest.disabled && 'opacity-50')}
          style={{ left: suffixLeft }}
          ref={suffixRef}
          data-cy={getSelectorsForInputWithExtras(dataCy).suffix}
        >
          {suffix}
        </span>
      )}
    </div>
  );
});

export default InputWithExtras;
