import { ReactNode, useState } from 'react';
import { OrganizationPickerContext } from './context';

export const OrganizationPickerProvider = ({ children }: { initialState?: any; children: ReactNode }) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');

  return (
    <OrganizationPickerContext.Provider
      value={{
        selectedOrganizationId,
        setSelectedOrganizationId,
      }}
    >
      {children}
    </OrganizationPickerContext.Provider>
  );
};
