import React from 'react';
import {
  useBulkDeleteJournalEntry,
  useBulkPostJournalEntry,
  useBulkUnpostJournalEntry,
  useBulkReverseJournalEntry,
} from '../../hooks/http';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';
import { DropDownItem, DropDownMenu } from '../atoms/DropDownMenu';

export function JournalsMultiSelectActionsComponent({ selectedRows, table }) {
  const { invalidateJournalEntries } = useInvalidateQuery();

  const { organizationId, userId } = useSession();

  const journalEntryIds = selectedRows?.map((item) => item?.original?._id);
  const { mutateAsync: bulkPostJournalEntry, isLoading: isBulkPosting } = useBulkPostJournalEntry();
  const { mutateAsync: bulkDeleteJournalEntry, isLoading: isBulkDeleting } = useBulkDeleteJournalEntry();
  const { mutateAsync: bulkUnpostJournalEntry, isLoading: isBulkUnposting } = useBulkUnpostJournalEntry();
  const { mutateAsync: bulkReverseJournalEntry, isLoading: isBulkReversing } = useBulkReverseJournalEntry();

  const handleBulkPostJournalEntry = async () => {
    try {
      await bulkPostJournalEntry(
        {
          organizationId,
          journalEntryIds,
        },
        {
          onSuccess: async () => {
            invalidateJournalEntries();
            toast.success('Journal(s) posted successfully');
          },
          onError: (error) => {
            console.error(error);

            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);

      toast.error(deriveError(error));
    }
  };

  const handleBulkDeleteJournalEntry = async () => {
    try {
      await bulkDeleteJournalEntry(
        {
          organizationId,
          journalEntryIds,
        },
        {
          onSuccess: async (response) => {
            invalidateJournalEntries();
            // console.log(response);
            toast.success(`${response.data.deletedCount} journals deleted.` + response.data.notDeletedMessage);
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  const handleBulkUnpostJournalEntry = async () => {
    try {
      await bulkUnpostJournalEntry(
        {
          organizationId,
          journalEntryIds,
        },
        {
          onSuccess: async () => {
            invalidateJournalEntries();
            toast.success('Journal(s) unposted successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  const handleBulkReverseJournalEntry = async () => {
    try {
      await bulkReverseJournalEntry(
        {
          organizationId,
          journalEntryIds,
          userId,
        },
        {
          onSuccess: async () => {
            invalidateJournalEntries();
            toast.success('Journal(s) reversed successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex ml-auto gap-4'>
      <Button label='Post' isLoading={isBulkPosting} onClick={handleBulkPostJournalEntry} />
      <Button emphasis='medium' label='Unpost' isLoading={isBulkUnposting} onClick={handleBulkUnpostJournalEntry} />
      <Button emphasis='medium' label='Reverse' isLoading={isBulkReversing} onClick={handleBulkReverseJournalEntry} />
      <DropDownMenu dropdownContainerClassname='w-56'>
        <DropDownItem onClick={handleBulkDeleteJournalEntry} disabled={isBulkDeleting}>
          Delete
        </DropDownItem>
      </DropDownMenu>
    </div>
  );
}
