import { useMemo } from 'react';
import { FILTER_TYPE, StringFilter } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

export type JournalSyncFilterContextType = FilterContextParams<StringFilter>;

const defaultState = [
  { value: 'Yes', selected: false },
  { value: 'No', selected: false },
];
export const useJournalSyncFilterState = (page: string) => {
  const { state, setState } = useFilterState<StringFilter>(FILTER_TYPE.JOURNAL_SYNC, page, defaultState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return { state, setState, hasSelections };
};
