import React, { useEffect, useMemo, useState } from 'react';
import { subMonths, isValid, format, parse } from 'date-fns';
import { Button } from './atoms';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';
import type { DatePickerCompProps } from './DatePickerComp.types';
import { MdArrowDropDown, MdOutlineCalendarMonth, MdOutlineCalendarToday } from 'react-icons/md';
import { Calendar, Dropdown, DropdownContent, DropdownTrigger, SingleSelectMenu, classNames } from 'ui';
import { getDateFromString, getNextDateISOString, getPreviousDateISOString, renderDate } from './date-picker-utils';

const dateOptions = [
  {
    label: 'Last 3 months',
    value: 'Last 3 months',
    numberOfMonths: 3,
  },
  {
    label: 'Last 6 months',
    value: 'Last 6 months',
    numberOfMonths: 6,
  },
  {
    label: 'Last 9 months',
    value: 'Last 9 months',
    numberOfMonths: 9,
  },
  {
    label: 'Last year',
    value: 'Last year',
    numberOfMonths: 12,
  },
];

// https://github.com/hypeserver/react-date-range to get the DatePicker Range docs
// For style plz visit https://github.com/hypeserver/react-date-range/blob/master/src/styles.js

const CustomStyle = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  padding: 16px 12px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  .rdrStartEdge::after {
    //check if the startDate and endDate has on same day
    display: ${({ hiddenItems }: any) => (hiddenItems ? 'none' : '')} !important;
  }
  .rdrEndEdge::after {
    //just to check if the endDate is the next day after startDate (check if endDate is the tomorrow day of startDate)
    background-color: transparent;
  }
`;

export const DatePickerComp = ({
  dateSelections,
  setDateSelections,
  singleDateFilter,
  startDateDefaultLabel = 'Start date',
  endDateDefaultLabel = 'End date',
  onUserInteractionBasedStateChange,
}: DatePickerCompProps) => {
  const [open, setOpen] = useState(false);
  const [selectedXMonths, setSelectedXMonths] = useState<{
    label: string;
    value: string;
    numberOfMonths: number;
  } | null>(dateOptions[0]);

  const [startDateText, setStartDateText] = useState(format(new Date(), 'E, dd LLL yyyy'));
  const [endDateText, setEndDateText] = useState(format(new Date(), 'E, dd LLL yyyy'));

  const [selectedMonth, setSelectedMonth] = useState(
    dateSelections.find((selection) => selection.key === 'selection')?.startDate ?? new Date(),
  );
  const onResetDateSelections = () => setDateSelections([{ key: 'selection' }]);
  const onStartDateDayDcr = () => {
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          startDate: getPreviousDateISOString(selection?.startDate),
        },
      ];
    });
    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };

  const onStartDateDayIncr = () => {
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          startDate: getNextDateISOString(selection?.startDate),
        },
      ];
    });

    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };

  const onEndDateDayDcr = () => {
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        {
          key: 'selection',
          ...selection,
          endDate: getPreviousDateISOString(selection?.endDate),
        },
      ];
    });

    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };

  const onEndDateDayIncr = () => {
    setDateSelections((prev) => {
      const selection = prev.find((selection) => selection.key === 'selection');
      return [
        Object.assign({ key: 'selection' }, selection, {
          endDate: getNextDateISOString(selection?.endDate),
        }),
      ];
    });

    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };
  const onLastXMonths = (numberOfMonths: number) => {
    setDateSelections([
      {
        startDate: subMonths(new Date(), numberOfMonths).toISOString(),
        endDate: new Date().toISOString(),
        key: 'selection',
      },
    ]);

    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };

  const onDateRangeChange = (item) => {
    setDateSelections([item.selection]);

    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
  };

  const range = dateSelections.find((selection) => selection.key === 'selection');

  let timeoutId: NodeJS.Timeout;

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStartDateText(newValue);
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const startDate = parse(newValue, 'EEE, dd MMM yyyy', new Date());
      const isValidDate = isValid(startDate);

      if (isValidDate) {
        setDateSelections((prev) => {
          const selection = prev.find((selection) => selection.key === 'selection');
          let endDate = selection?.endDate;

          // Check if endDate is lesser than startDate or undefined
          if (!endDate || new Date(endDate) < startDate) {
            endDate = startDate.toISOString();
          }

          return [
            {
              key: 'selection',
              startDate: startDate.toISOString(),
              endDate,
            },
          ];
        });
        if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
      }
    }, 500);
  };

  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEndDateText(newValue);

    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const isValidDate = isValid(parse(newValue, 'EEE, dd MMM yyyy', new Date()));

      if (isValidDate) {
        setDateSelections((prev) => {
          const selection = prev.find((selection) => selection.key === 'selection');
          return [
            Object.assign({ key: 'selection' }, selection, {
              startDate: selection?.startDate,
              endDate: parse(newValue, 'EEE, dd MMM yyyy', new Date()),
            }),
          ];
        });
      }
    }, 500);
  };

  useEffect(() => {
    const startDate = range?.startDate;
    const endDate = range?.endDate;
    if (startDate && endDate) {
      setStartDateText(format(new Date(startDate), 'E, dd LLL yyyy'));
      setEndDateText(format(new Date(endDate), 'E, dd LLL yyyy'));
    }
    if (startDate && singleDateFilter) {
      setStartDateText(format(new Date(startDate), 'E, dd LLL yyyy'));
    }
  }, [range?.startDate, range?.endDate]);

  const dateSelectionsForCalendar = useMemo(() => {
    const fromString = dateSelections.find((selection) => selection.key === 'selection')?.startDate;
    const toString = dateSelections.find((selection) => selection.key === 'selection')?.endDate;
    return {
      from: fromString ? new Date(fromString) : undefined,
      to: toString ? new Date(toString) : undefined,
    };
  }, [dateSelections]);

  return (
    <Dropdown onOpenChange={value => setOpen(value)}>
      <DropdownTrigger
        className={classNames(
          'outline-none disabled:opacity-50 flex whitespace-nowrap rounded-lg duration-300 bg-white w-auto',
          open && 'ring-2 ring-btn-focus-stroke',
        )}
        onClick={() => {
          if (!range?.startDate && !range?.endDate) {
            onResetDateSelections();
            if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
          }
          setOpen((prev) => !prev);
        }}
      >
        <div>
          <div
            className={classNames(
              'w-fit h-10 border border-[#C9C9C9] flex items-center justify-between pl-2 duration-300 hover:bg-[#eeeeee] overflow-hidden',
              !singleDateFilter ? 'border-r-0 rounded-l-lg' : 'rounded-lg',
            )}
          >
            <div className='flex items-center gap-x-2'>
              <MdOutlineCalendarToday className='w-5 h-5' />
              <p className=' font-medium !mb-0 mr-3'>
                {renderDate(range?.startDate ? new Date(range.startDate) : undefined, startDateDefaultLabel)}
              </p>
            </div>
            <div className='flex'>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onStartDateDayDcr();
                  if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                }}
                className='px-1 h-10 hover:bg-slate-50 duration-100 border-[#b2b2b2]'
              >
                <MdArrowDropDown className='rotate-90 w-6 h-6' />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onStartDateDayIncr();
                  if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                }}
                className='px-1 h-10 hover:bg-slate-50 duration-100 border-[#b2b2b2]'
              >
                <MdArrowDropDown className='-rotate-90 w-6 h-6' />
              </button>
            </div>
          </div>
          {!singleDateFilter && (
            <div className='w-fit h-10 rounded-r-lg border border-[#C9C9C9] flex items-center justify-between pl-3 duration-300 hover:bg-[#eeeeee] overflow-hidden'>
              <p className=' font-medium !mb-0 mr-3'>
                {renderDate(range?.endDate ? new Date(range.endDate) : undefined, endDateDefaultLabel)}
              </p>
              <div className='flex'>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onEndDateDayDcr();
                    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                  }}
                  className='px-1 h-10 hover:bg-slate-50 duration-100 border-[#b2b2b2]'
                >
                  <MdArrowDropDown className='rotate-90 w-6 h-6' />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onEndDateDayIncr();
                    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                  }}
                  className='px-1 h-10 hover:bg-slate-50 duration-100 border-[#b2b2b2]'
                >
                  <MdArrowDropDown className='-rotate-90 w-6 h-6' />
                </button>
              </div>
            </div>
          )}
        </div>
      </DropdownTrigger>
      <DropdownContent className='w-fit max-h-none'>
        <div>
          <CustomStyle className='!font-bold bg-white !static z-20'>
            <div className='flex justify-between items-center mb-6'>
              {!singleDateFilter && (
                <div className='w-full z-30 pr-2'>
                  <SingleSelectMenu
                    fullWidth
                    isOnSidepanel
                    options={dateOptions}
                    onClearValue={() => {
                      setSelectedXMonths(null);
                      if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                    }}
                    onChange={(item) => {
                      if (item) {
                        setSelectedXMonths(item as any);
                        onLastXMonths(item.numberOfMonths);
                        if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                      }
                    }}
                    clearable
                    value={{
                      value: selectedXMonths?.value ?? '',
                      label: selectedXMonths?.label ?? '',
                    }}
                  />
                </div>
              )}

              <div className={`flex items-center ${singleDateFilter ? 'gap-5' : ''}`}>
                <div className='w-fit relative'>
                  <MdOutlineCalendarMonth className='w-6 h-6 absolute top-1/2 left-2 -translate-y-1/2' />
                  <input
                    type='text'
                    name='start-date'
                    value={startDateText}
                    onChange={(e) => {
                      handleStartDate(e);
                      if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                    }}
                    className='h-10 text-sm pl-10 border border-zinc-200 rounded-l-lg focus:outline-none w-52 border-r-0  font-normal'
                  />
                  <div className='flex absolute top-1/2 -translate-y-1/2 right-0'>
                    <button
                      onClick={() => {
                        onStartDateDayDcr();
                        if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                      }}
                      className='h-10 hover:bg-slate-50 duration-100 border-y border-zinc-200'
                    >
                      <MdArrowDropDown className='rotate-90 w-6 h-6' />
                    </button>
                    <button
                      onClick={() => {
                        onStartDateDayIncr();
                        if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                      }}
                      className='h-10 hover:bg-slate-50 duration-100 border-r border-y border-zinc-200'
                    >
                      <MdArrowDropDown className='-rotate-90 w-6 h-6' />
                    </button>
                  </div>
                </div>
                {!singleDateFilter && (
                  <div className='w-fit relative mr-2 overflow-hidden rounded-r-lg'>
                    <MdOutlineCalendarMonth className='w-6 h-6 absolute top-1/2 left-2 -translate-y-1/2' />
                    <input
                      type='text'
                      name='end-date'
                      value={endDateText}
                      onChange={(e) => {
                        handleEndDate(e);
                        if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                      }}
                      className='h-10 text-sm w-52 pl-10 border border-zinc-200 rounded-r-lg focus:outline-none font-normal '
                    />
                    <div className='flex absolute top-1/2 -translate-y-1/2 right-0'>
                      <button
                        onClick={() => {
                          onEndDateDayDcr();
                          if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                        }}
                        className='h-10 hover:bg-slate-50 duration-100 border-y border-zinc-200'
                      >
                        <MdArrowDropDown className='rotate-90 w-6 h-6' />
                      </button>
                      <button
                        onClick={() => {
                          onEndDateDayIncr();
                          if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                        }}
                        className='h-10 hover:bg-slate-50 duration-100 border-y border-r border-zinc-200'
                      >
                        <MdArrowDropDown className='-rotate-90 w-6 h-6' />
                      </button>
                    </div>
                  </div>
                )}
                <Button variant='tertiary' onClick={onResetDateSelections}>
                  Reset
                </Button>
              </div>
            </div>

            <div className='max-w-full'>
              {singleDateFilter ? (
                <Calendar
                  initialFocus
                  mode='single'
                  month={getDateFromString(selectedMonth?.toString())}
                  selected={dateSelectionsForCalendar.from}
                  onSelect={(range) => {
                    onDateRangeChange({ selection: { key: 'selection', startDate: range } });
                  }}
                  onMonthChange={(month) => setSelectedMonth(month)}
                  numberOfMonths={1}
                />
              ) : (
                <Calendar
                  initialFocus
                  mode='range'
                  month={getDateFromString(selectedMonth?.toString())}
                  selected={{ from: dateSelectionsForCalendar.from, to: dateSelectionsForCalendar.to }}
                  onSelect={(range) => {
                    onDateRangeChange({ selection: { key: 'selection', startDate: range?.from, endDate: range?.to } });
                    if (onUserInteractionBasedStateChange) onUserInteractionBasedStateChange();
                  }}
                  onMonthChange={(month) => setSelectedMonth(month)}
                  numberOfMonths={2}
                />
              )}
            </div>
          </CustomStyle>
        </div>
      </DropdownContent>
    </Dropdown>
  );
};
