import * as React from 'react';
import { forwardRef } from 'react';
const SvgNote = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='m78 14-6-6-6 6-6-6-6 6-6-6-6 6-6-6-6 6-6-6v56H12v12c0 6.64 5.36 12 12 12h48c6.64 0 12-5.36 12-12V8l-6 6ZM60 80H24c-2.2 0-4-1.8-4-4v-4h40v8Zm16-4c0 2.2-1.8 4-4 4s-4-1.8-4-4V64H32V20h44v56Z'
      fill='#000'
    />
    <path d='M60 28H36v8h24v-8ZM72 28h-8v8h8v-8ZM60 40H36v8h24v-8ZM72 40h-8v8h8v-8Z' fill='#000' />
  </svg>
);
const ForwardRef = forwardRef(SvgNote);
export default ForwardRef;
