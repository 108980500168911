import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getHistoricalAssetPrice } from 'services/http/analytics/assets';

export const useGetHistoricalAssetPrices = (params) => {
  const { assetType, startDate, endDate } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.HISTORICAL_ASSET_PRICE, { assetType, startDate }),
    async () => {
      const response = await getHistoricalAssetPrice({
        assetType,
        startDate,
        endDate,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
