import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, currencyImg } from 'ui';
import { getAssetsInfo } from '../../../../constants';
import { useChainFilterContext } from '../../../../context';

export const ChainsFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useChainFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Chains' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((option, i) => (
            <CheckboxListItem
              key={i}
              label={getAssetsInfo(option.value.toLowerCase()).name}
              bottomText={getAssetsInfo(option.value.toLowerCase()).abbreviation}
              checked={option.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
              enableAvatar
              src={currencyImg(option.value.toLowerCase())}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No chains to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
