import { createContext, useContext } from 'react';
import { TableActionBarContextType } from './types';

export const TableActionBarContext = createContext<TableActionBarContextType>({
  setActionBarVisibleForTableType: () => {},
  allActionBarProps: {},
  getPropsForTableType: () => {},
  setPropsForTableType: () => {},
});

export const useTableActionBarContext = () => useContext(TableActionBarContext);
