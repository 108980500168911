import { useMutation } from '@tanstack/react-query';
import { getLoopCheckoutUrl } from 'services';

export const useGetLoopCheckoutUrlMutation = () =>
  useMutation(
    ({
      subscriptionPriceId,
      aiCreditsRechargePriceId,
      unlimitedAiCredits,
      subscriptionCadence,
    }: {
      subscriptionPriceId;
      aiCreditsRechargePriceId;
      unlimitedAiCredits;
      subscriptionCadence;
    }) =>
      getLoopCheckoutUrl({
        subscriptionPriceId,
        aiCreditsRechargePriceId,
        unlimitedAiCredits,
        subscriptionCadence,
      }),
  );
