import React, { useMemo } from 'react';
import { MetricCard, MetricGroup } from '../../atoms';
import { formatDollars } from '../../templates/utils';
import { useTransactionStats } from '../../../hooks';
import { getTop5AssetsByCount } from './utils';
import { currencyImg } from 'ui';
import { useTransactionQueryParams } from './use-transaction-query-params';

export const TransactionsMetricsCard = () => {
  const transactionParams = useTransactionQueryParams();

  const { data: fetchedTransactionsData, isLoading: statsLoading } = useTransactionStats(transactionParams);

  const totalTransactions = useMemo(
    () => (fetchedTransactionsData?.transactionStats?.length ? fetchedTransactionsData?.transactionStats[0].count : 0),
    [fetchedTransactionsData],
  );

  const totalTokens = useMemo(() => fetchedTransactionsData?.totalTokens, [fetchedTransactionsData]);

  const grossValueTransacted = useMemo(
    () =>
      fetchedTransactionsData?.transactionStats?.length
        ? formatDollars(fetchedTransactionsData?.transactionStats[0].grossValueTransacted, true)
        : '0',
    [fetchedTransactionsData],
  );

  const top5Assets = useMemo(
    () => getTop5AssetsByCount(fetchedTransactionsData?.tokensByCount ?? {}),
    [fetchedTransactionsData],
  );

  const top5AssetsIcons = useMemo(
    () => top5Assets.map((asset) => ({ url: currencyImg(asset), alt: asset })),
    [top5Assets],
  );

  return (
    <MetricGroup className='!mb-0 !mt-4'>
      <MetricCard loading={statsLoading} label='total transactions' metric={totalTransactions} />
      <MetricCard loading={statsLoading} label='Total Asset Types' metric={totalTokens} icons={top5AssetsIcons} />
      <MetricCard loading={statsLoading} label='Gross value transacted' metric={grossValueTransacted} />
    </MetricGroup>
  );
};

export default TransactionsMetricsCard;
