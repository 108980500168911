import { Card, Section } from 'ui';
import { useGetInvoicesFromStripeQuery } from '../../../hooks/http/billing-service/stripe/use-get-invoices-from-stripe-query';
import { formatDate } from '../../templates/utils';
import { InvoicesTable } from '../../PageComponents/org-settings/billing/invoicesTable';

export const InvoiceSection = () => {
  const {
    data: invoicePages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useGetInvoicesFromStripeQuery();

  const lastUpdatedOn = invoicePages?.pages[0][0]
    ? `Last updated on ${formatDate(new Date(invoicePages.pages[0][0].created * 1000))}`
    : ' ';

  return (
    <Card>
      <Section heading='Invoices' description={lastUpdatedOn}>
        <InvoicesTable
          invoicePages={invoicePages}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
        />
      </Section>
    </Card>
  );
};
