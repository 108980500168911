import React, { useMemo } from 'react';
import { Card, LoaderIcon, Section, SectionHeader } from 'ui';
import * as Progress from '@radix-ui/react-progress';
import { AddAICreditsModal } from '../modals';
import { useGetAiCreditUsageForOrgQuery } from '../../../hooks';
import { formatDate } from '../../templates/utils';
import LoadingComponent from 'ui/src/section-header/loading-component';

export const AvailableCredits = () => {
  const { data: usage, isLoading } = useGetAiCreditUsageForOrgQuery();
  if (!usage && isLoading) return <LoaderIcon />;
  if (!usage) return null;

  return (
    <div>
      {usage.aiCredits.limit - usage.aiCredits.used} / {usage.aiCredits.limit} credits
    </div>
  );
};

export const AICreditsSection = () => {
  const { data: usage } = useGetAiCreditUsageForOrgQuery();

  const aiCreditUsagePercentage = useMemo(() => {
    const aiCreditsUsed = usage?.aiCredits.used ?? 0;
    const aiCreditLimit = usage?.aiCredits.limit ?? 0;
    const percentage = aiCreditLimit ? ((aiCreditLimit - aiCreditsUsed) * 100) / aiCreditLimit : 0;

    return percentage;
  }, [usage]);
  const lastUpdatedOn = usage?.lastUpdate ? `Last updated on ${formatDate(new Date(usage.lastUpdate))}` : ' ';

  return (
    <Card>
      <Section
        heading='AI credits'
        description={lastUpdatedOn}
        headerActions={usage?.aiCredits?.hasUnlimited ? undefined : <AddAICreditsModal />}
      >
        <Card>
          <div className='flex justify-between items-center mb-4'>
            <SectionHeader heading='Available credits' variant='md' />
          </div>
          {!usage && <LoadingComponent />}
          {usage && (
            <div>
              {usage.aiCredits.hasUnlimited ? (
                <>Unlimited credits</>
              ) : (
                <>
                  <Progress.Root
                    className='w-75 rounded-full overflow-hidden relative h-2 bg-blue-100 mb-2'
                    value={aiCreditUsagePercentage}
                  >
                    <Progress.Indicator
                      className='bg-blue-500 w-full h-full transition-colors'
                      style={{ transform: `translateX(-${100 - aiCreditUsagePercentage}%)` }}
                    />
                  </Progress.Root>
                  <AvailableCredits />
                </>
              )}
            </div>
          )}
        </Card>
      </Section>
    </Card>
  );
};
