import { DefaultCell, StatusIndicator, capitalizeFirstLetter, CopyAction, MultipleActions, Truncate } from 'ui';
import { currencyImg } from 'ui';
import { ColumnsProps } from './types';
import { useCopyToClipboard } from 'usehooks-ts';
import { BiSolidDockRight } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';
import { useContext } from 'react';
import { SidebarGlobalContext } from 'src/context/SidebarGlobalProvider';
import { dateConverter } from 'src/components/utils';
import { useDensity } from 'src/context';
import { ColumnLeadingCell } from './columnLeadingCell';

export const sourcesColumns: ColumnsProps<any, any>[] = [
  {
    header: 'Source',
    accessorKey: 'sourceInfo',
    cell: ({ getValue, row }) => {
      const { density } = useDensity();

      if (row.original.isGroup) {
        return (
          <div className='flex items-center'>
            <DefaultCell label={row.original.name} />
          </div>
        );
      }

      const { title, chain } = getValue() || {};
      const imgSize = density === 'compact' ? 'default' : density === 'default' ? 'large' : 'extra-large';

      if (row.original.isRaincard) {
        return <ColumnLeadingCell size={imgSize} src='/raincards-logo.png' alt={title} label={title} bottomText='' />;
      } else {
        return (
          <ColumnLeadingCell
            size={imgSize}
            isAvatarVisible={true}
            src={currencyImg(chain)}
            alt={chain}
            initials={chain ?? 'AA'}
            label={capitalizeFirstLetter(title ?? '')}
            bottomText={['compact', 'default'].includes(density) ? '' : capitalizeFirstLetter(getValue()?.desc ?? '')}
            isCheckboxVisible={false}
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        );
      }
    },
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: ({ getValue, row }) => {
      if (row.original.isGroup) return <></>;

      return (
        !!getValue()?.length && (
          <StatusIndicator
            label={capitalizeFirstLetter(getValue()?.toLowerCase())}
            type={getValue().toLowerCase()}
            className='my-auto'
          />
        )
      );
    },
  },
  {
    header: 'Address',
    accessorKey: 'address',
    cell: ({ getValue, row }) => {
      if (row.original.isGroup) return <></>;

      const [, copy] = useCopyToClipboard();
      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-7 w-7 mt-1.5',
        default: 'h-9 w-9 mt-0.75',
        comfortable: 'h-10 w-10',
      }[density];

      const iconClassName = {
        compact: '!min-h-4 !min-w-4',
        default: '!h-5 !w-5',
        comfortable: '!w-5 !h-5',
      }[density];

      const value = getValue();
      if (!value) return null;
      return (
        <div className='flex items-center justify-between relative group/address w-full'>
          <DefaultCell
            label={row.original.isRaincard ? '....' + value : <Truncate text={value} />}
            className='text-left flex justify-start group-hover/address:underline group-hover/address:underline-offset-4'
          />
          <span className='h-10 hidden group-hover/address:block'>
            <CopyAction className={btnClassName} iconClassName={iconClassName} copyFn={() => copy(value)} />
          </span>
        </div>
      );
    },
  },
  {
    header: 'Legal entity id',
    accessorKey: 'legalEntityId',
    cell: ({ getValue, row }) => {
      if (row.original.isGroup) return <></>;

      const { entityName } = getValue() || {};
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-7 w-7 mt-1.5',
        default: 'h-9 w-9 mt-0.75',
        comfortable: 'h-10 w-10',
      }[density];

      const iconClassName = {
        compact: '!min-h-4 !min-w-4',
        default: '!h-5 !w-5',
        comfortable: '!w-5 !h-5',
      }[density];

      return (
        <div className='flex items-center justify-between group'>
          <DefaultCell
            label={entityName}
            className='w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('entities', { id: row.original?.legalEntityId._id });
                  },
                  tooltipContent: 'View in panel',
                },
                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/entities/${row.original?.legalEntityId._id}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    header: 'Created',
    accessorKey: 'createdAt',
    cell: ({ getValue, row }: any) => {
      if (row.original.isGroup) return <></>;

      return (
        <div className='flex items-center'>
          <DefaultCell label={dateConverter(new Date(getValue()))} />
        </div>
      );
    },
  },
];
