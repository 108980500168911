import React, { useState, useEffect } from 'react';
import { SidebarRoot, Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import {
  useCreateJournalEntryTemplate,
  useDeleteJournalEntryTemplate,
  useUpdateJournalEntryTemplate,
} from '../../hooks/http';
import AddTemplateLines from './AddTemplateLines';
import { deriveError, getIdFromPopulatedInstance, TEMPLATES_COPY } from './utils';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection, SidebarTableSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import {
  InputWithExtras,
  InputLabel,
  TextareaInput,
  Button,
  SelectableCard,
  currencyOptions,
  SingleSelectMenu,
} from 'ui';
import { toast } from 'react-hot-toast';
import { JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS } from '../../constants';
import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';
import CurrencyFlag from 'react-currency-flags';
import { JournalEntryTemplate } from 'schemas';
import { useRouter } from 'next/router';
import Table from '../tables/tanstack-table/Table';
import { DensityDropdown } from '../tables/table-components';
import { templateLinesColumns } from '../Sidebar/Routes/templateLinesColumns';

type FormState = {
  autoPost?: boolean;
  currency: JournalEntryTemplate['currency']; // default to USD
  name: JournalEntryTemplate['name'];
  externalRef: JournalEntryTemplate['externalReference'];
  memo: JournalEntryTemplate['memo'];
  lineTemplates: any[];
  draftLines: any[];
  status?: JournalEntryTemplate['status'];
  legalEntityId?: string;
};

interface AddTemplateProps {
  onClose?: () => void;
  onCancel?: () => void;
  selectedJournalEntryTemplate?: JournalEntryTemplate;
}

function AddTemplate({ onClose, selectedJournalEntryTemplate = undefined, onCancel }: AddTemplateProps) {
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  const [showAddTemplateLine, setShowAddTemplateLine] = useState(false);
  const [selectedJournalEntryLineTemplate, setSelectedJournalEntryLineTemplate] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<FormState>({
    autoPost: true,
    currency: 'USD', // default to USD
    name: '',
    externalRef: '',
    memo: '',
    lineTemplates: [],
    draftLines: [],
    status: JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS[0].value as JournalEntryTemplate['status'],
  });

  const router = useRouter();
  const [lastTemplateId, setLastTemplateId] = useState<string | void>();

  const resetForm = () =>
    setForm({
      status: undefined,
      currency: 'USD', // default to USD
      name: '',
      externalRef: '',
      memo: '',
      lineTemplates: [],
      draftLines: [],
    });
  const { organizationId, userId } = useSession();

  useEffect(() => {
    if (selectedJournalEntryTemplate) {
      const templateIdFromPathname = router.pathname.split('/configure/templates/')[1];
      if (templateIdFromPathname !== lastTemplateId || router.pathname !== '/configure/templates') {
        setForm({
          ...form,
          name: selectedJournalEntryTemplate.name,
          status: selectedJournalEntryTemplate.status,
          externalRef: selectedJournalEntryTemplate.externalReference,
          memo: selectedJournalEntryTemplate.memo,
          currency: selectedJournalEntryTemplate.currency,
          legalEntityId: getIdFromPopulatedInstance(selectedJournalEntryTemplate.legalEntityId),
          lineTemplates: [...selectedJournalEntryTemplate.journalEntryLineTemplateIds],
        });
        setLastTemplateId(templateIdFromPathname);
      }
    }
  }, [selectedJournalEntryTemplate, router.pathname, lastTemplateId]);

  const { name, memo, draftLines, lineTemplates, currency, externalRef } = form;

  const handleNameChange = (event) => {
    setForm({ ...form, name: event.target.value });
  };
  const handleCurrencyChange = (event) => {
    setForm({ ...form, currency: event.value });
  };
  const handleExternalRefChange = (event) => {
    setForm({ ...form, externalRef: event.target.value });
  };
  const handleMemoChange = (event) => {
    setForm({ ...form, memo: event.target.value });
  };

  const { mutateAsync: createAsync, isLoading: isCreating } = useCreateJournalEntryTemplate();
  const handleSave = async () => {
    // only used for initial creation
    setLoading(true);
    if (draftLines.length < 2) {
      setLoading(false);
      toast.error('You need at least two lines to add a template');
      return;
    }
    try {
      console.log(`Draft lines`, draftLines);
      const data = {
        journalEntryTemplateInput: {
          name,
          userId,
          organizationId: organizationId,
          status: form.status,
          memo,
          externalReference: externalRef,
          currency,
          legalEntityId: draftLines[0].legalEntityId,
        },
        journalEntryLineTemplates: draftLines,
        organizationId,
      };
      await createAsync(data, {
        onSuccess: () => {
          setLoading(false);
          invalidateJournalEntryTemplates();

          toast.success('Your journal entry template has been successfully created');

          onCloseWithFormReset();
        },
      });
    } catch (error) {
      toast.error(deriveError(error));
    }
    setLoading(false);
  };
  const { mutateAsync: updateAsync, isLoading: isUpdating } = useUpdateJournalEntryTemplate();
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const data = {
        journalEntryTemplateId: selectedJournalEntryTemplate?._id as string,
        body: {
          name,
          memo,
          externalReference: externalRef,
          status: form.status ?? selectedJournalEntryLineTemplate?.status,
          currency,
          organizationId,
          legalEntityId: getIdFromPopulatedInstance(lineTemplates[0]?.legalEntityId),
        },
      };
      await updateAsync(data, {
        onSuccess: async () => {
          setLoading(false);
          invalidateJournalEntryTemplates();
          toast.success('Your journal entry template has been successfully updated.');
          onCloseWithFormReset();
        },
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(deriveError(error));
    }
  };
  const { mutateAsync: deleteAsync, isLoading: isDeleting } = useDeleteJournalEntryTemplate();
  const handleDeleteJournalEntryTemplate = async () => {
    setLoading(true);
    const data = {
      journalEntryTemplateIds: [selectedJournalEntryTemplate?._id],
      organizationId,
    };
    try {
      await deleteAsync(data, {
        onSuccess: () => {
          invalidateJournalEntryTemplates();
          toast.success('Your journal entry template has been successfully deleted.');
        },
      });
      setLoading(false);
      onCloseWithFormReset();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(deriveError(error));
    }
  };
  const COPY_PREFIX = selectedJournalEntryTemplate ? 'EDIT_' : 'CREATE_';

  const MODIFIED_DATA_CY = `${selectedJournalEntryTemplate ? 'edit' : 'create'}JournalEntryTemplate`;

  const onCloseWithFormReset = () => {
    resetForm();
    onClose && onClose();
  };
  const onCancelWithFormReset = () => {
    resetForm();
    onCancel && onCancel();
  };
  return (
    <SidebarRoot>
      <Sidebar data-cy={MODIFIED_DATA_CY}>
        <SidebarTopBar onClose={onCloseWithFormReset} />
        <SidebarHeader
          title={`${selectedJournalEntryTemplate ? 'Edit' : 'Create'} journal entry template`}
          loading={loading}
          data-cy={MODIFIED_DATA_CY}
        />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection numberOfColumns={1} loading={loading}>
            <div>
              <InputLabel heading='Template name' />
              <InputWithExtras data-cy={`${MODIFIED_DATA_CY}__templateName`} value={name} onChange={handleNameChange} />
            </div>
            <div>
              <InputLabel heading='Legal entity' />
              <Button className='w-full' label='Operational Transaction Legal Entity' emphasis='medium' disabled />
            </div>
            <div>
              <InputLabel heading='Accounting date' />
              <Button className='w-full' label='Operational Transaction Date' emphasis='medium' disabled />
            </div>
            <div>
              <InputLabel heading='Auto Post' />
              <div className='grid grid-cols-2 gap-4'>
                {JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS.map((radioItem, i) => (
                  <SelectableCard
                    label={radioItem.label}
                    onClick={() =>
                      setForm((prev) => ({ ...prev, status: radioItem.value as JournalEntryTemplate['status'] }))
                    }
                    selected={form.status === radioItem.value}
                    key={i}
                  />
                ))}
              </div>
            </div>
            <div>
              <InputLabel heading='Currency' />

              <SingleSelectMenu
                fullWidth
                isOnSidepanel
                defaultValue={{
                  label: 'U.S Dollar',
                  description: '$USD',
                  icon: <CurrencyFlag currency='USD' width={30} />,
                  value: 'USD',
                }}
                value={{
                  label:
                    currencyOptions.find((item) => item.value === currency)?.description +
                    ' ' +
                    currencyOptions.find((item) => item.value === currency)?.label,
                  description: currencyOptions.find((item) => item.value === currency)?.description,
                  icon: currencyOptions.find((item) => item.value === currency)?.icon,
                  value: currencyOptions.find((item) => item.value === currency)?.value as string,
                }}
                onChange={handleCurrencyChange}
                options={currencyOptions.map((item) => ({
                  ...item,
                  label: item.description + '    ' + item.label,
                }))}
                enableAvatar
                customIconRender={(option) => option.icon}
              />
            </div>
            <div>
              <InputLabel heading='Add external reference' />
              <InputWithExtras
                data-cy={`${MODIFIED_DATA_CY}__addExternalReference`}
                value={externalRef}
                onChange={handleExternalRefChange}
              />
            </div>
            <div>
              <InputLabel heading='Memo' />
              <TextareaInput data-cy={`${MODIFIED_DATA_CY}__memo`} value={memo} onChange={handleMemoChange} />
            </div>
          </SidebarSection>
          <SidebarSectionHeader
            title='Template lines'
            actions={[
              {
                label: 'Add line',
                onClick: () => {
                  setSelectedJournalEntryLineTemplate(null);
                  setShowAddTemplateLine(true);
                },
                variant: 'tertiary',
                'data-cy': `${MODIFIED_DATA_CY}__addLineButton`,
              },
            ]}
          />
          <SidebarTableSection loading={loading}>
            <div className='w-full'>
              <Table
                multiSelectActionBarProps={{
                  selectedJournalEntryTemplate,
                }}
                enableRowSelection={false}
                disableColumnPinning={true}
                columns={templateLinesColumns}
                panelTableName={'editJournalEntryTemplatePanel'}
                data={
                  lineTemplates?.length
                    ? lineTemplates.map((line) => ({
                        ...line,
                        ledgerAccount: line.ledgerAccountId?.ledgerAccountName,
                      }))
                    : draftLines.map((line) => ({
                        ...line,
                      }))
                }
                onRowClick={(row) => {
                  setShowAddTemplateLine(false);
                  setSelectedJournalEntryLineTemplate(row.original);
                }}
              >
                <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                  <div className='flex items-center gap-3 flex-1'></div>
                  <div className='flex flex-wrap gap-3'>
                    <DensityDropdown />
                  </div>
                </div>
              </Table>
            </div>
          </SidebarTableSection>
        </SidebarBody>
        <SidebarFooter
          primaryBtn={
            <Button
              data-cy={`${MODIFIED_DATA_CY}__${TEMPLATES_COPY[`${COPY_PREFIX}TEMPLATE_SIDEBAR_PRIMARY_BUTTON`]
                .split(' ')
                .join('_')
                .toLowerCase()}`}
              label={TEMPLATES_COPY[`${COPY_PREFIX}TEMPLATE_SIDEBAR_PRIMARY_BUTTON`]}
              emphasis='high'
              onClick={async () => {
                if (!selectedJournalEntryTemplate) {
                  await handleSave();
                } else await handleUpdate();
              }}
              isLoading={isCreating || isUpdating}
            />
          }
          secondaryBtn={
            <Button
              label='Cancel'
              emphasis='medium'
              onClick={onCancel ? onCancelWithFormReset : onCloseWithFormReset}
              disabled={loading}
            />
          }
          destructiveBtn={
            selectedJournalEntryTemplate && (
              <Button
                label='Delete'
                status='danger'
                emphasis='medium'
                data-cy={`${MODIFIED_DATA_CY}__deleteButton`}
                onClick={handleDeleteJournalEntryTemplate}
                isLoading={isDeleting}
              />
            )
          }
        />
      </Sidebar>
      {showAddTemplateLine && (
        <AddTemplateLines
          onClose={() => {
            setShowAddTemplateLine(false);
          }}
          journalEntryTemplateId={selectedJournalEntryTemplate?._id}
          createDraftLine={(line) => {
            setForm({
              ...form,
              draftLines: [
                ...draftLines,
                {
                  ...line,
                  draftId: new Date().getTime(),
                },
              ],
            });
          }}
        />
      )}
      {selectedJournalEntryLineTemplate && (
        <AddTemplateLines
          deleteDraftLine={(draftId) => {
            setForm({ ...form, draftLines: draftLines.filter((line) => line.draftId !== draftId) });
          }}
          onClose={() => {
            setSelectedJournalEntryLineTemplate(null);
          }}
          journalEntryTemplateId={selectedJournalEntryTemplate?._id}
          selectedJournalEntryLineTemplate={selectedJournalEntryLineTemplate}
          createDraftLine={(line) => {
            const index = draftLines.findIndex((item) => item.draftId === line.draftId);
            const copy = [...draftLines];
            copy[index] = line;
            setForm({ ...form, draftLines: copy });
          }}
        />
      )}
    </SidebarRoot>
  );
}

export default AddTemplate;
