import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '../../useInvalidateQuery';
import { getOrganizationById } from 'services';

export const useGetOrganizationByIdQuery = (organizationId: string) =>
  useQuery({
    queryFn: async () => {
      // console.log('useGetOrganizationByIdQuery', organizationId);
      const response = await getOrganizationById({ organizationId });
      return response.data.organization;
    },
    queryKey: [QUERY_KEY.GET_ORGANIZATION_BY_ID, organizationId],
    enabled: !!organizationId && organizationId.length > 0,
  });
