import axios from 'axios';
import { SERVER_URL_CORE } from '../../config';
import { LedgerAccount, TrialBalanceResponse } from '../response.types';
import { prepareSearchParams } from './utils';

export interface getTrialBalancePayload {
  legalEntityIds?: string[];
  accountingPeriodStartDateUTCs?: string[];
  organizationId: string;
  tagId?: string;
  startDate?: string;
  endDate?: string;
}

export type CreditDebit = {
  openingBalance: { value: string };
  closingBalance: { value: string };
  currentBalance: { value: string };
  debits: { value: string };
  credits: { value: string };
  accountingPeriodStartDateUTC: Date;
  legalEntityIds: string[];
};
export type ReportResponseV2 = {
  ledgerAccounts: LedgerAccount[];
  balances: {
    [ledgerAccountId: string]: {
      [accountingPeriodName: string]: { creditDebit: CreditDebit; tagBalance: { value: string } };
    };
  }; // ledgerAccountId => accountingPeriodName => credit debit
};

export const getTrialBalance = async (data: getTrialBalancePayload) => {
  return axios.get<{ trialBalance: TrialBalanceResponse }>(
    `${SERVER_URL_CORE}/trial-balance?${prepareSearchParams(data, { noExtras: true })}`,
  );
};

export const getIncomeStatement = async (data) => {
  return axios.get<{ incomeStatement: ReportResponseV2 }>(
    `${SERVER_URL_CORE}/income-statement?${prepareSearchParams(data, { noExtras: true })}`,
  );
};

export const getBalanceSheet = async (data) => {
  return axios.get<{ balanceSheet: ReportResponseV2 }>(
    `${SERVER_URL_CORE}/balance-sheet?${prepareSearchParams(data, { noExtras: true })}`,
  );
};
