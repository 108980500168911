import { PickLedgerAccount } from '../form-elements';
import React, { useEffect, useState } from 'react';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import {
  useLegalEntities,
  useTags,
  useLedgerAccount,
  useUpdateJournalEntryLineTemplate,
  useCreateJournalEntryLineTemplate,
  useBulkDeleteJournalEntryLineTemplate,
  useJournalEntryTemplateById,
} from '../../hooks/http';
import {
  convertSelectedJournalEntryLineTemplateToState,
  getDisplayedLegalEntities,
  getDisplayedTags,
  mergeLedgerAccounts,
  mergeLegalEntities,
  mergeTags,
  TEMPLATES_COPY,
} from './utils';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import {
  InputLabel,
  TextareaInput,
  Button,
  SelectableCard,
  PercentageInputWithSelectableValues,
  SingleSelectMenu,
  MultiSelectMenu,
} from 'ui';
import { addTemplateLineValidation } from '../utils';
import { useSession } from '../../hooks/useSession';
import { LedgerAccount } from 'services/http/response.types';
import { AddTemplateLineProps, DefaultLineRadioGroupData, FormState } from './types';
import { withToasts } from '../billing';
import { useInvalidateQuery } from 'src/hooks';

const DEFAULT_LINE_RADIO_GROUP_DATA: DefaultLineRadioGroupData[] = [
  {
    label: 'Credit',
    value: 'CREDIT',
    labelLeft: false,
    checked: true,
  },
  {
    label: 'Debit',
    value: 'DEBIT',
    labelLeft: false,
    checked: false,
  },
];

const AMOUNT_TYPE_OPTIONS = [
  { label: 'Gross Amount', value: 'GROSS_AMOUNT' },
  { label: 'Net Amount', value: 'NET_AMOUNT' },
  { label: 'Fee', value: 'FEE' },
  { label: 'Realized Gain Loss (+/-)', value: 'GAIN_OR_LOSS' },
  { label: 'Realized Gain / Loss (Absolute Value)', value: 'GAIN_OR_LOSS_ABS' },
  { label: 'Asset Cost Basis', value: 'ASSET_COST_BASIS' },
  { label: 'Impairment Amount', value: 'IMPAIRMENT_AMOUNT' },
  { label: 'Vested Amount', value: 'VESTED_AMOUNT' },
  { label: 'Swap Fee', value: 'SWAP_FEE' },
  { label: 'Maple APY', value: 'MAPLE_APY' },
];

function AddTemplateLines({
  onClose,
  loading,
  journalEntryTemplateId,
  selectedJournalEntryLineTemplate,
  createDraftLine,
  deleteDraftLine,
  'data-cy': dataCy = 'addTemplateLine',
}: AddTemplateLineProps) {
  const [formState, setFormState] = useState<FormState>({
    creditOrDebit: DEFAULT_LINE_RADIO_GROUP_DATA[0].value,
  });

  const { data: journalEntryTemplate } = useJournalEntryTemplateById(journalEntryTemplateId);
  useEffect(() => {
    console.log({ journalEntryTemplate, selectedJournalEntryLineTemplate });
    if (journalEntryTemplate && selectedJournalEntryLineTemplate) {
      console.log('Syncing journal entry template');
      // this means we are editing an existing template
      // we need to sync up state from journalEntryTemplate whenever it changes, to ensure the form always shows latest values
      const lineTemplates = journalEntryTemplate.journalEntryLineTemplateIds;
      const matchingLineTemplate = lineTemplates.find(
        (template) => template._id === selectedJournalEntryLineTemplate._id,
      );
      if (matchingLineTemplate) setFormState(convertSelectedJournalEntryLineTemplateToState(matchingLineTemplate));
    } else if (selectedJournalEntryLineTemplate)
      setFormState(convertSelectedJournalEntryLineTemplateToState(selectedJournalEntryLineTemplate));
  }, [journalEntryTemplate, selectedJournalEntryLineTemplate]);

  const { organizationId } = useSession();

  const { data: tags } = useTags();
  const mergedTags = mergeTags(tags);
  const displayedTags = getDisplayedTags(mergedTags);

  const { data: legalEntities, isLoading: isLoadingLegalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const entitiesWithTransactionLegalEntity = displayedLegalEntities.concat([
    {
      label: 'Transaction Legal Entity',
      value: 'transactionLegalEntity',
    },
  ]);

  const { data: ledgerAccounts } = useLedgerAccount({
    pageSize: 1000,
    isLeaf: true,
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts) as LedgerAccount[];

  const { memo, legalEntityId, ledgerAccountId, creditOrDebit, amountType, allocation, tags: _tags } = formState;

  const { mutateAsync: updateJournalEntryLineTemplate, isLoading: isUpdatingJournalEntryLineTemplate } =
    useUpdateJournalEntryLineTemplate();
  const { mutateAsync: createJournalEntryLineTemplate, isLoading: isCreatingJournalEntryLineTemplate } =
    useCreateJournalEntryLineTemplate();
  const { mutateAsync: bulkDeleteJournalEntryLineTemplate, isLoading: isBulkDeletingJournalEntryLineTemplates } =
    useBulkDeleteJournalEntryLineTemplate();
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  const lineFromState = {
    ledgerAccountId,
    tags: _tags,
    memo,
    creditOrDebit: creditOrDebit?.toLowerCase() === 'debit' ? 'DEBIT' : 'CREDIT',
    amountType,
    allocation: Number(allocation),
    legalEntityId: legalEntityId === 'transactionLegalEntity' ? undefined : legalEntityId,
    ledgerAccount: mergedLedgerAccounts?.find((account) => account._id === ledgerAccountId)?.ledgerAccountName,
    useTransactionLegalEntity: legalEntityId === 'transactionLegalEntity',
  };

  const addJournalEntryLineTemplateToDB = withToasts(
    async () => {
      const payload: any = {
        journalEntryTemplateId,
        journalEntryLineTemplate: { ...lineFromState, organizationId },
      };
      await createJournalEntryLineTemplate(payload);
      onClose();
    },
    {
      loading: 'Creating journal entry line template',
      success: 'Your journal entry line template has been successfully created.',
    },
  );
  const updateLineByIdFromDB = withToasts(
    async () => {
      const payload: any = {
        journalEntryLineTemplateId: selectedJournalEntryLineTemplate._id,
        body: { ...lineFromState, organizationId },
      };
      await updateJournalEntryLineTemplate(payload);
      await invalidateJournalEntryTemplates({ journalEntryTemplateId });

      onClose();
    },
    {
      loading: 'Updating journal entry line template',
      success: 'Your journal entry line template has been successfully updated.',
    },
  );

  const COPY_PREFIX = selectedJournalEntryLineTemplate ? 'EDIT_' : 'CREATE_';

  const deleteJournalEntryLineTemplateFromDB = withToasts(
    async () => {
      const data: any = {
        journalEntryTemplateId,
        journalEntryLineTemplateIds: selectedJournalEntryLineTemplate._id,
        organizationId,
      };
      await bulkDeleteJournalEntryLineTemplate(data);
    },
    {
      loading: 'Deleting journal entry line template',
      success: 'Your journal entry line template has been successfully deleted.',
    },
  );

  const onSaveClick = async () => {
    // start loading
    if (addTemplateLineValidation(lineFromState)) {
      if (selectedJournalEntryLineTemplate) {
        // we are editing
        if (selectedJournalEntryLineTemplate._id) {
          await updateLineByIdFromDB();
        } else {
          createDraftLine({
            ...lineFromState,
            draftId: selectedJournalEntryLineTemplate.draftId,
          });
          onClose();
        }
        // stop loading
      } else {
        // we are creating a new line
        if (journalEntryTemplateId) {
          await addJournalEntryLineTemplateToDB();
        } else {
          // create draft
          createDraftLine(lineFromState);
          onClose();
        }
        setFormState({});
      }
    }
    // stop loading
  };

  const onDeleteClick = async () => {
    // start loading
    if (selectedJournalEntryLineTemplate && !journalEntryTemplateId) {
      deleteDraftLine(selectedJournalEntryLineTemplate.draftId);
    } else if (selectedJournalEntryLineTemplate) {
      await deleteJournalEntryLineTemplateFromDB();
    }
    onClose();
  };

  const onLegalEntityChange = ({ value }) => {
    if (value !== 'transactionLegalEntity') {
      setFormState((prev) => ({ ...prev, legalEntityId: value, useTransactionLegalEntity: false }));
      return;
    }
    setFormState((prev) => ({ ...prev, legalEntityId: value, useTransactionLegalEntity: true }));
  };
  const onLegalEntityClear = () => setFormState((prev) => ({ ...prev, legalEntityId: undefined }));
  const onLedgerAccountIdChange = (selectedLedgerAccountId) =>
    setFormState((prev) => ({ ...prev, ledgerAccountId: selectedLedgerAccountId }));

  const onTypeClick = (creditOrDebitValue) => setFormState((prev) => ({ ...prev, creditOrDebit: creditOrDebitValue }));
  const onAmountTypeChange = ({ value }: any) => setFormState((prev) => ({ ...prev, amountType: value }));
  const onAllocationChange = (e) =>
    setFormState((prev) => ({
      ...prev,
      allocation: e.target.value
        ? +e.target.value < 0
          ? 0
          : +e.target.value > 100
            ? 100
            : +e.target.value
        : undefined,
    }));

  const onTagsChange = (values) => {
    setFormState((prev) => ({ ...prev, tags: values.map(({ value }) => value) }));
  };

  const onMemoChange = (e) => setFormState((prev) => ({ ...prev, memo: e.target.value }));

  return (
    <>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          onClose={() => {
            onClose();
          }}
        />
        <SidebarHeader data-cy={dataCy} title={TEMPLATES_COPY[`${COPY_PREFIX}LINE_SIDEBAR_HEADING`]} />
        <SidebarBody>
          <SidebarSectionHeader />
          <SidebarSection numberOfColumns={1}>
            <div>
              <InputLabel heading='Legal entity' />

              <SingleSelectMenu
                fullWidth
                isOnSidepanel
                data-cy={`${dataCy}__legalEntity`}
                placeholder='Select legal entity'
                options={entitiesWithTransactionLegalEntity ?? []}
                onChange={onLegalEntityChange}
                value={entitiesWithTransactionLegalEntity.find((item) => item.value === formState.legalEntityId)}
                isLoading={isLoadingLegalEntities}
                onClearValue={onLegalEntityClear}
              />
            </div>
            <div>
              <PickLedgerAccount value={ledgerAccountId} onChange={onLedgerAccountIdChange} dataCy={dataCy} />
            </div>
            <div>
              <InputLabel heading='Select type' />
              <div className='grid grid-cols-2 gap-4'>
                {DEFAULT_LINE_RADIO_GROUP_DATA.map((radioItem, i) => (
                  <SelectableCard
                    data-cy={`${dataCy}__${radioItem.label}`}
                    label={radioItem.label}
                    selected={formState.creditOrDebit === radioItem.value}
                    onClick={() => onTypeClick(radioItem.value)}
                    key={i}
                  />
                ))}
              </div>
            </div>
            <div>
              <InputLabel heading='Amount type' />

              <SingleSelectMenu
                fullWidth
                isOnSidepanel
                isModal={false}
                data-cy={`${dataCy}__amountType`}
                placeholder='Select amount type'
                options={AMOUNT_TYPE_OPTIONS}
                onChange={onAmountTypeChange}
                value={AMOUNT_TYPE_OPTIONS?.find((item) => item.value === amountType)}
                onClearValue={() => setFormState((prev) => ({ ...prev, amountType: undefined }))}
              />
            </div>
            <div>
              <InputLabel heading='Allocation' />
              <PercentageInputWithSelectableValues
                data-cy={`${dataCy}__allocation`}
                value={allocation !== undefined ? `${allocation}` : undefined}
                onChange={onAllocationChange}
              />
            </div>
            <div>
              <InputLabel heading='Tags' />
              <MultiSelectMenu
                side='top'
                data-cy={`${dataCy}__tags`}
                isOnSidepanel
                dropdownContentClassName='!max-h-[300px]'
                isModal={false}
                fullWidth
                options={displayedTags ?? []}
                onChange={onTagsChange}
                value={displayedTags?.filter((item) => _tags?.includes(item.value))}
              />
            </div>
            <div>
              <InputLabel heading='Memo' />
              <TextareaInput
                data-cy={`${dataCy}__memo`}
                placeholder='Enter memo'
                onChange={onMemoChange}
                value={memo}
              />
            </div>
          </SidebarSection>
        </SidebarBody>
        <SidebarFooter
          primaryBtn={
            <Button
              data-cy={`${dataCy}__saveButton`}
              label='Save'
              emphasis='high'
              isLoading={isCreatingJournalEntryLineTemplate || isUpdatingJournalEntryLineTemplate}
              onClick={onSaveClick}
              disabled={loading}
            />
          }
          secondaryBtn={
            <Button
              label={TEMPLATES_COPY[`${COPY_PREFIX}LINE_SECONDARY_BUTTON`]}
              emphasis='medium'
              isLoading={isBulkDeletingJournalEntryLineTemplates}
              onClick={onDeleteClick}
              disabled={loading}
            />
          }
        />
      </Sidebar>
    </>
  );
}

export default AddTemplateLines;
