import React, { useCallback } from 'react';
import { Button } from 'ui';
import { Card } from '../../../styles';
import { useRouter } from 'next/router';

export const InviteTeam = () => {
  const router = useRouter();
  const handleInviteClick = useCallback(() => router.push('/organization-settings/team/'), []);
  return (
    <Card className='w-full h-fit rounded-lg border bg-indigo-50'>
      <div>
        <div className='text-2xl font-medium'>Invite team</div>
        <div className='mt-1 text-lg'>Invite teammates to get them started.</div>
      </div>
      <Button label='Invite Team' onClick={handleInviteClick} />
    </Card>
  );
};

export default InviteTeam;
