import { DefaultCell, StatusIndicator, TwoLineAvatar } from 'ui';
import { formatTableNumbers } from 'global-utils';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { ColumnLeadingCell } from 'src/components/tables/columns/columnLeadingCell';
import { dateConverter } from 'src/components/utils';

export const journalEntryTableColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'journalSequenceNumber',
    header: 'Seq ID',
    cell: ({ getValue }) => {
      return <ColumnLeadingCell isAvatarVisible={false} label={getValue() || ''} isCheckboxVisible={false} />;
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'credits',
    header: 'Total Credit',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue(), isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'debits',
    header: 'Total Debit',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue(), isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: '_id',
    header: 'Operational transaction ID',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Created date',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={dateConverter(getValue())} />;
    },
  },
  {
    accessorKey: 'createdBy',
    header: 'Created by',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'isSync',
    header: 'Synced',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          <StatusIndicator
            label={getValue() ? 'yes' : 'no'}
            type={getValue() ? 'positive' : 'neutral'}
            className='h-fit'
          />
        </div>
      );
    },
  },
];
