import { useState } from 'react';
import { BackButtonProps } from './types';
import { usePopper } from 'react-popper';
import { Popover } from '@headlessui/react';
import { Button } from '../button';
import { MdArrowBack } from 'react-icons/md';

export const BackButton = ({ tooltipMessage, onClick }: BackButtonProps) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
  });

  if (tooltipMessage)
    return (
      <Popover className='relative'>
        <Popover.Button
          ref={setReferenceElement}
          as={Button}
          leadingIcon={<MdArrowBack className='w-5 h-5' />}
          onClick={onClick}
          emphasis='low'
        />

        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className='absolute z-10 text-xs flex py-1 px-2 shadow rounded-lg w-[133px] bg-black opactity-70 text-white mt-2'
        >
          {tooltipMessage}
        </Popover.Panel>
      </Popover>
    );
  return <Button leadingIcon={<MdArrowBack className='w-5 h-5' />} onClick={onClick} emphasis='low' />;
};
