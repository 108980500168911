import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';
import { IsImpairedSubMenu } from './IsImpairedSubMenu';

export const IsImpairedInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        disabled={isDisabled}
        className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
      >
        <div className='mr-2 flex h-full justify-center items-center content-center'>
          {parseInt(rule.value) === 1 ? 'True' : 'False'}
        </div>
        <ChevronDownIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent>
          <IsImpairedSubMenu
            callbackFn={(value) => {
              callbackFn(parseInt(value));
            }}
          />
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
