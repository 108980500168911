import { DefaultCell, MultipleActions, StatusIndicator, TwoLineAvatar } from 'ui';
import { useContext } from 'react';
import { SidebarGlobalContext, useDensity } from 'src/context';
import { BiSolidDockRight } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';
import { LegalEntity } from 'schemas';
import { handleTags } from '../templates/utils';
import { ColumnsProps } from '../tables/columns/types';
import { formatTableNumbers } from 'global-utils';

export const journalEntryLinesColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'legalEntity',
    header: 'Legal entity',
    cell: ({ row }) => {
      const { entityName, _id } = row.original?.legalEntity || {};
      const { openSidebar } = useContext(SidebarGlobalContext);
      const { density } = useDensity();

      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={entityName}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('entities', { id: (row.original?.legalEntityId as LegalEntity)._id });
                  },
                  tooltipContent: 'View in panel',
                },
                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/entities/${_id ?? ''}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'ledgerAccount',
    header: 'Ledger account',
    cell: ({ row, getValue }) => {
      const { ledgerAccountId } = row.original;
      const { openSidebar } = useContext(SidebarGlobalContext);
      const { density } = useDensity();

      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('ledger-accounts', {
                      id: ledgerAccountId,
                      primaryOrSecondary: 'secondary',
                    });
                  },
                  tooltipContent: 'View in panel',
                },
                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/ledger-accounts/${ledgerAccountId}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue(), isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'creditOrDebit',
    header: 'Type',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center'>
          <DefaultCell textAlign={'text-left'} label={handleTags(getValue())} />
        </div>
      );
    },
  },
];
