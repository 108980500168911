export enum FILTER_TYPE {
  ACCOUNTING_TREATMENT = 'ACCOUNTING_TREATMENTS_FILTER',
  ACCOUNTING_PERIOD = 'ACCOUNTING_PERIODS_FILTER',
  ASSET = 'ASSETS_FILTER',
  ASSET_TYPE = 'ASSET_TYPES_FILTER',
  CHAIN = 'CHAINS_FILTER',
  CLASSIFICATION = 'CLASSIFICATIONS_FILTER',
  DATE = 'DATES_FILTER',
  TARGET_DATE = 'TARGET_DATE_FILTER',
  SINGLE_DATE = 'SINGLE_DATE',
  DIRECTION = 'DIRECTIONS_FILTER',
  IMPAIRED = 'IMPAIRED_FILTER',
  LEGAL_ENTITY = 'LEGAL_ENTITIES_FILTER',
  LEDGER_ACCOUNT = 'LEDGER_ACCOUNTS_FILTER',
  ORIGINATED_BY = 'ORIGINATED_BY_FILTER',
  JOURNAL_SYNC = 'JOURNAL_SYNC_FILTER',
  STATUS = 'STATUS_FILTER',
  SPAM_TOKEN = 'SPAM_TOKEN',
  TAG = 'TAGS_FILTER',
  WALLET = 'WALLETS_FILTER',
  WALLET_TYPE = 'WALLET_TYPES_FILTER',
  SOURCE = 'SOURCE_FILTER',
  TYPE = 'TYPE_FILTER',
  JOURNAL_ENTRY_TEMPLATE = 'JOURNAL_ENTRY_TEMPLATES_FILTER',
  AMOUNT = 'AMOUNT',
}

export type FilterRowState<T = any> = T & {
  selected: boolean;
};

export interface FilterState<T> {
  entities: {
    [key: string]: FilterRowState<T>;
  };
  ids: string[];
  isLoading?: boolean;
}

export type GenericFilterFn<T> = (state: T, filter: any) => T;
export type FilterFn<T> = (state: FilterRowState<T>[], searched: string) => FilterRowState<T>[];
export type OnFilterStateChangeFn<T> = (value: FilterState<T>) => void;
export type StringFilter = {
  value: string;
  label?: string;
};

export type OnSingleRowChangeFn<T> = (value: FilterRowState<T>) => void;
export type FilterValueTransformFn<T, R = any> = (value: T) => R;

export type FilterTransforms = {
  [key in FILTER_TYPE]?: FilterValueTransformFn<any>;
};

export type FilterSetAllFn<T> = (values: FilterRowState<T>[]) => void;
export type FilterSetByIdFn<T> = (values: FilterRowState<T>) => void;
export type FilterSetIsLoadingFn = (isLoading: boolean) => void;
export type FilterSetOnlyFn = (id: string) => void;
export type FilterSetSelectedFn = (id: string, selected: boolean) => void;
export type FilterSelectAllFn = () => void;
export type FilterDeselectAllFn = () => void;
export type FilterClearFn = () => void;

export interface FilterDropdownRowProps<T> {
  state: FilterRowState<T>[];
  isLoading?: boolean;
  onSelectAll: () => void;
  onClearAll: () => void;
  onSingleRowSelectChange: (rowIndex: number, selected: boolean) => void;
  onOnlySingleRowSelect: (rowIndex: number) => void;
  'data-cy'?: string;
}

export enum IMPAIRED_FILTER_ROW {
  TRUE = 'True',
  FALSE = 'False',
}

export enum STATUS_FILTER_ROW {
  DRAFT = 'Draft',
  POSTED = 'Posted',
  REVERSED = 'Reversed',
  UNPOSTED = 'Unposted',
}

export enum ORIGINATED_BY_FILTER_ROW {
  USER = 'user',
  SYSTEM = 'system',
}

export enum ACCOUNTING_TREATMENT_ROW {
  SHOW_TRANSACTIONS_WITHOUT_JOURNAL_ENTRIES = 'False', // true
  SHOW_TRANSACTIONS_WITH_JOURNAL_ENTRIES = 'True', // false
}

export enum SPAM_TOKEN_ROW {
  SHOW_SPAM = 'Show spam',
}

export enum CLASSIFICATION_ROW {
  SWAP = 'SWAP',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  BRIDGE = 'BRIDGE',
  INTERCOMPANY_TRANSFER = 'INTERCOMPANY TRANSFER',
  INTERNAL_TRANSFER = 'INTERNAL TRANSFER',
  FEE = 'FEE',
  MINTING = 'MINTING',
  SPAM = 'SPAM',
  NFT = 'NFT',
  STAKING_REWARD = 'STAKING_REWARD',
  CLAIM_REWARD = 'CLAIM REWARD',
  UNKNOWN = 'UNKNOWN',
}

export type AllQueryFiltersType = {
  directions: string[];
  chains: string[];
  transactionFrom: string;
  transactionTo: string;
  accountingPeriodIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  addresses: string[];
  walletIds: string[];
  sourceIds: string[];
  showTransactionsWithoutJournalEntries?: ACCOUNTING_TREATMENT_ROW;
  pageSize: number;
  includeSpam: SPAM_TOKEN_ROW;
  nameOrAddress: string;
  walletTypes: string[];
  tags: string[];
  startDate: string;
  endDate: string;
  originatedBy: string[];
  exchangeSourceType: string;
  status: string[];
  transactionIds: string[];
  searchTerm: string;
  impaired: string[];
  tagId: string[];
  periodIds: string[];
  filteredBy: string;
  debouncedSearchTerm: string;
  classifications: string[];
  isSync: string[];
  ledgerAccountIds: string[];
};
