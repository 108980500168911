import { memo, useState } from 'react';
import { Button, Dropdown, DropdownContent, DropdownTrigger } from 'ui';
import { DensityType, useDensity } from 'src/context';
import { HiBars2, HiMiniBars3, HiMiniBars4 } from 'react-icons/hi2';
import { FaCheck } from 'react-icons/fa';

export const DensityDropdown = () => {
  const { density, setDensity } = useDensity();
  const [open, setOpen] = useState(false);
  const handleSetDensity = (newDensity: DensityType) => {
    setDensity(newDensity);
    setOpen(false); // Close dropdown after selection
  };

  const dropdownStyles = 'w-5 h-5 text-black';

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger asChild>
        <Button
          label={`${density}`}
          emphasis='medium'
          className='px-2 capitalize'
          leadingIcon={
            density === 'default' ? (
              <HiMiniBars3 className={dropdownStyles} />
            ) : density === 'comfortable' ? (
              <HiBars2 className={dropdownStyles} />
            ) : (
              <HiMiniBars4 className={dropdownStyles} />
            )
          }
        />
      </DropdownTrigger>
      <DropdownContent
        align='end'
        withoutPortal
        className='grid grid-cols-1 gap-1 p-1 min-w-[95px] w-[200px] !z-commandmenu'
      >
        <Button
          emphasis='low'
          className='font-normal text-[16px] justify-start hover:!bg-indigo-50'
          leadingIcon={<HiMiniBars3 />}
          label='Default'
          trailingIcon={density === 'default' && <FaCheck className='text-indigo-500 ml-[65px]' />}
          onClick={() => handleSetDensity('default')}
          disableRingOnFocus
        />
        <Button
          emphasis='low'
          className='font-normal text-[16px] justify-start hover:!bg-indigo-50'
          leadingIcon={<HiBars2 />}
          label='Comfortable'
          trailingIcon={density === 'comfortable' && <FaCheck className='text-indigo-500 ml-[28px]' />}
          onClick={() => handleSetDensity('comfortable')}
          disableRingOnFocus
        />
        <Button
          emphasis='low'
          className='font-normal text-[16px] justify-start hover:!bg-indigo-50'
          leadingIcon={<HiMiniBars4 />}
          label='Compact'
          trailingIcon={density === 'compact' && <FaCheck className='text-indigo-500 ml-[50px]' />}
          onClick={() => handleSetDensity('compact')}
          disableRingOnFocus
        />
      </DropdownContent>
    </Dropdown>
  );
};

export default memo(DensityDropdown);
