import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getTransactionsVolumeByMonth } from 'services/http/analytics/transactions';

type TransactionsvolumeByMonthParams = {
  accountingPeriodIds: string[];
};

export const useTransactionsVolumeByMonth = (data: TransactionsvolumeByMonthParams) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.TRANSACTIONS_VOLUME, data),
    async () => {
      const response = await getTransactionsVolumeByMonth({
        organizationId,
        ...data,
      });
      return response.data;
    },
    {
      enabled: !!organizationId && !!data?.accountingPeriodIds,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
