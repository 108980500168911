import { useMutation } from '@tanstack/react-query';
import { updateSubscription } from 'services';

export const useUpdateSubscriptionMutation = () =>
  useMutation(async ({ organizationId, priceId }: { organizationId: string; priceId: string }) => {
    const response = await updateSubscription({
      organizationId,
      priceId,
    });
    return response.data;
  });
