import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { IsImpairedInlineConditionMenu } from '../../../IsImpairedInlineConditionMenu';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  return (
    <>
      <SingleSelectMenu defaultValue={{ label: 'Is Impaired', value: 'Is Impaired' }} disabled={true} options={[]} />

      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <IsImpairedInlineConditionMenu
        rule={condition}
        callbackFn={(value) => {
          /**
           * NOTE: value is a boolean
           */
          updateCondition(condition.id, {
            value: value + '',
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const IsImpairedRuleCondition = () => (
  <ConditionRow facts={[FACT.IS_IMPAIRED]}>
    <Row />
  </ConditionRow>
);
