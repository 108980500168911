import * as React from 'react';
import { forwardRef } from 'react';
const SvgAssignment = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M76 12H59.28C57.6 7.36 53.2 4 48 4c-5.2 0-9.6 3.36-11.28 8H20c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V20c0-4.4-3.6-8-8-8Zm-28-1c.88 0 1.64.4 2.2 1 .48.52.8 1.24.8 2 0 1.64-1.36 3-3 3s-3-1.36-3-3c0-.76.32-1.48.8-2 .56-.6 1.32-1 2.2-1Zm28 65H20V20h56v56ZM48 24c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12Zm0 16c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4ZM24 65.88V72h48v-6.12c0-10-15.88-14.32-24-14.32s-24 4.28-24 14.32ZM33.24 64C36 61.76 42.76 59.52 48 59.52S60.04 61.76 62.76 64H33.24Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAssignment);
export default ForwardRef;
