import React from 'react';
import { ConditionSelectorRow } from './';
import { useConditionSelectorContext } from './condition-selector-context';

export const ConditionSelectorMenuTrigger = ({ label, visibleOnMenu }: { label: string; visibleOnMenu?: string }) => {
  const { state, push } = useConditionSelectorContext();

  if (!visibleOnMenu) {
    if (state.currentMenu) return null;
    return <ConditionSelectorRow rowData={{ label }} onClick={() => push(label)} isMenuTrigger />;
  }
  if (visibleOnMenu === state.currentMenu)
    return <ConditionSelectorRow rowData={{ label }} onClick={() => push(label)} isMenuTrigger />;
  return null;
};
