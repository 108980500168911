import { useState } from 'react';
import { Button } from '../button';
import { Tooltip } from '../tooltip';
import { MdCheck } from 'react-icons/md';
import { IoMdClipboard } from 'react-icons/io';
import { SingleActionProps } from './types';

export const SingleAction = ({ className = '', title, icon, tooltipContent, onClickHandler }: SingleActionProps) => {
  return (
    <Tooltip content={tooltipContent} className='' removeTooltipContainerClassname>
      <Button
        title={title ?? ''}
        onClick={onClickHandler}
        leadingIcon={icon}
        emphasis='medium'
        leadingIconContainerClassname='[&>svg]:w-full [&>svg]:h-full p-1'
        className={className}
      />
    </Tooltip>
  );
};
interface CopyActionProps {
  copyFn: () => Promise<boolean>;
  className?: string;
  iconClassName?: string;
}

export const CopyAction = ({ className = '', iconClassName = '', copyFn }: CopyActionProps) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <SingleAction
      className={className}
      icon={isCopied ? <MdCheck className={iconClassName} /> : <IoMdClipboard className={iconClassName} />}
      onClickHandler={async (e) => {
        e.stopPropagation();
        await copyFn();
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      }}
      tooltipContent={isCopied ? 'Copied!' : 'Copy'}
    />
  );
};
