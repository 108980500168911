export enum DESCRIPTIVE_LOADER_STATES {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface DescriptiveLoaderProps {
  title?: string;
  description?: string;
  status?: DESCRIPTIVE_LOADER_STATES;
  containerClassname?: string;
  inModal?: boolean;
  'data-cy'?: string;
}
