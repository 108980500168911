import { WALLET_TYPE } from 'services/http/response.types';
import { FILTER_TYPE } from '../../../components/filters';
import { useFilterState } from '../use-filters';
import { FilterContextParams } from '../types';
import { useMemo } from 'react';

export type WalletTypeFilterContextType = FilterContextParams<{ value: WALLET_TYPE }>;
const defaultState = [
  { value: WALLET_TYPE.INTERNAL, selected: false },
  { value: WALLET_TYPE.EXTERNAL, selected: false },
];
export const useWalletTypeFilterState = (page: string) => {
  const { state, setState } = useFilterState<{ value: WALLET_TYPE }>(FILTER_TYPE.WALLET_TYPE, page, defaultState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
