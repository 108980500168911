import { useEffect, useState } from 'react';
import { convertAssets, formatDollars, mergeAssets } from '../../templates/utils';
import { CryptoIcon, dateConverter } from '../../utils';
import { AssetWithExtraData } from 'services/http/response.types';
import { DisplayedAsset } from './type';
import { AssetBreakdownData } from 'services/http/analytics/assets';
import { getAssetsV2 } from 'services/http';

export const useDisplayedAssets = (assetIQData?: { pages: { assets: AssetWithExtraData[] }[] }) => {
  const [displayedAssets, setDisplayedAssets] = useState<DisplayedAsset[]>([]);
  useEffect(() => {
    if (assetIQData)
      setDisplayedAssets(
        mergeAssets(assetIQData).map((asset: AssetWithExtraData) => {
          const assetType = {
            title: asset.assetType,
            desc: dateConverter(asset.dateReceived),
            chain: asset.assetType,
          };
          const costBasis = {
            title: formatDollars(asset.costBasis, true),
            desc: `${formatDollars(asset.pricePaid, true)} total`,
          };
          return {
            ...asset,
            assetType,
            costBasis,
            currentValue_gainLossPercentage: {
              currentValue: asset.currentValue,
              gainLossPercentage: asset.gainLossPercentage,
            },
          };
        }),
      );
  }, [assetIQData]);
  return displayedAssets;
};

export const processAssetBreakdownData = (assetBreakdownData?: AssetBreakdownData, isPieChart = false) => {
  const assetBreakdown = assetBreakdownData?.data;
  let assetBreakdownArray = Object.values(assetBreakdown || {});
  const processedData: Record<string, any[]> = {
    Quantities: [],
    Values: [],
  };
  if (!assetBreakdownArray) return { processedData, availableAssets: [] };

  if (isPieChart) {
    // if Pie Chart, only return top 5 assets by value and the rest goes under 'Other' category
    assetBreakdownArray?.sort((a, b) => {
      return parseFloat(b.value) - parseFloat(a.value);
    });

    if (assetBreakdownArray.length > 5) {
      const top5AssetsByValue = assetBreakdownArray.slice(0, 5);
      const otherAssets = assetBreakdownArray.slice(5);

      const otherAssetsValue = otherAssets.reduce((acc, curr) => {
        return acc + parseFloat(curr.value);
      }, 0);

      const otherAssetsQuantity = otherAssets.reduce((acc, curr) => {
        return acc + parseFloat(curr.quantity);
      }, 0);

      assetBreakdownArray = [
        ...top5AssetsByValue,
        { assetType: 'Others', quantity: otherAssetsQuantity.toString(), value: otherAssetsValue.toString() },
      ];
    }
  }

  const availableAssets = assetBreakdownArray.map((asset) => asset.assetType);
  assetBreakdownArray.forEach((asset) => {
    processedData.Quantities.push({
      name: asset.assetType,
      value: parseFloat(asset.quantity),
      icon: () => <CryptoIcon symbol={asset.assetType} size='sm' className='mr-3 currencyIcon' />,
    });
    processedData.Values.push({
      name: asset.assetType,
      value: parseFloat(asset.value),
      icon: () => <CryptoIcon symbol={asset.assetType} size='sm' className='mr-3 currencyIcon' />,
    });
  });

  return {
    processedData,
    availableAssets,
  };
};

export const handleDownloadAssets = async ({ organizationId, sortState, assetsCSVParams }) => {
  let allAssets: any[] = [];
  const perQuery = 250;
  let pastLength = 0;
  let count = 0;
  do {
    const response = await getAssetsV2({
      organizationId,
      page: count++,
      sort: sortState[0],
      organizationIds: [organizationId],
      pageSize: perQuery,
      isForCSVExport: true,
      ...assetsCSVParams,
    });
    const newAssets = response.data?.assets;
    pastLength = allAssets.length;
    allAssets = [...allAssets, ...newAssets];
  } while (pastLength !== allAssets.length);

  return convertAssets(allAssets);
};
