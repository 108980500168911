import { useLayoutEffect } from 'react';
import { first } from 'rxjs';
import { LogoutTopic } from 'services/http/axios-interceptor/on-request-error';
import { redirectToLogin } from 'services/http/axios-interceptor/redirect-to-login';

export const useHandleLogoutEvent = () => {
  useLayoutEffect(() => {
    const subscription = LogoutTopic.pipe(first()).subscribe(() => {
      redirectToLogin(true);
    });
    return () => subscription.unsubscribe();
  }, []);
};
