import * as React from 'react';
import { forwardRef } from 'react';
const SvgPerson = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M48 48c8.84 0 16-7.16 16-16s-7.16-16-16-16-16 7.16-16 16 7.16 16 16 16Zm0 8c-10.68 0-32 5.36-32 16v8h64v-8c0-10.64-21.32-16-32-16Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPerson);
export default ForwardRef;
