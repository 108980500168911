import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { styled } from '@stitches/react';

export const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: 'white',
  width: 16,
  height: 16,
  border: '2px solid rgba(17, 17, 17, 0.24)',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&[data-state=checked]': { backgroundColor: 'black' },
});

export const StyledIndicator = styled(CheckboxPrimitive.Indicator, {
  color: 'White',
});

export const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content',
  height: 'fit-content',
});

export const Label = styled('label', {
  color: 'black',
  fontSize: 16,
  lineHeight: 1,
  userSelect: 'none',
  textAlign: 'left',
});
