import { useEffect, useMemo, useState } from 'react';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { SidebarAnim, SidebarAnimationParent, SidebarRoot } from '../atoms/Sidebar';
import SidebarManager from './SidebarManager';
import { useTabState } from '../../hooks';
import { useRouter } from 'next/router';
import { extractPageIdFromObjectByUrl, getPath, getUrlWithoutId } from '../utils';
import { unprotectedPaths } from 'services';
import { getShouldNavigateOnOpenSidebar } from './utils';

export type SidebarRoutes =
  | 'balance-overview'
  | 'transactions'
  | 'sources'
  | 'journals'
  | 'assets'
  | 'templates'
  | 'rulesets'
  | 'tags'
  | 'impairment-rules'
  | 'entities'
  | 'ledger-accounts'
  | 'invoices';

export type SidebarParams = {
  id?: string;
  primaryOrSecondary?: 'primary' | 'secondary';
  extras?: any;
};

export type SidebarRouteStackType = {
  route: SidebarRoutes;
  params: SidebarParams;
};

export type SidebarProps = {
  id: string;
  onBack: () => void;
  isPrimary: boolean;
  handlePrimaryPanelClose?: () => void;
  defaultState?: any;
  isDockPanelAvailable?: boolean;
  'data-cy'?: string;
};

export const SidebarGlobal = ({ children }) => {
  const { query, pathname, asPath, push } = useRouter();
  const { activeTab, sidebarState, primaryPanel, secondRouteStack, updateTabSidebarState } = useTabState();
  const memoizedActiveTabId = useMemo(() => activeTab?.id ?? '', [activeTab?.id]);
  const memoizedActiveTabUrl = useMemo(() => activeTab.url, [activeTab.url]);

  const openSidebar = (route: SidebarRoutes | string | undefined, params: SidebarParams, extras = {}) => {
    if (
      (route !== primaryPanel?.route && primaryPanel?.dockPanel && params.primaryOrSecondary !== 'primary') ||
      params.primaryOrSecondary === 'secondary'
    ) {
      const secondPanelTopRoute = secondRouteStack[secondRouteStack.length - 1];

      if (params.id && params.id !== secondPanelTopRoute?.params?.id) {
        updateTabSidebarState({
          secondRouteStack: [...secondRouteStack, { route: route ?? secondPanelTopRoute.route, params, extras }],
          secondRouteUnStack: {},
        });
      }
    } else {
      const url = getUrlWithoutId(asPath);
      if (primaryPanel?.params?.id !== params?.id || !primaryPanel.params?.id?.length) {
        const newUrl = getPath(route, params?.id ?? '');
        const shouldNavigate = getShouldNavigateOnOpenSidebar(url, route);
        if (shouldNavigate) {
          push({ pathname: newUrl }, newUrl, { scroll: false });
        } else {
          push({ pathname: url }, url, { scroll: false });
        }
        updateTabSidebarState({ primaryPanel: { route, params, extras, url } });
      }
    }
  };

  const closeSidebar = (sidebar: 'primary' | 'secondary' | 'both') => {
    if (sidebar === 'primary') {
      updateTabSidebarState({ primaryPanel: {} });
    } else if (sidebar === 'secondary') {
      updateTabSidebarState({ secondRouteStack: [], secondRouteDockPanel: false });
    } else if (sidebar === 'both') {
      updateTabSidebarState({ primaryPanel: {}, secondRouteStack: [], secondRouteDockPanel: false });
    }
  };

  const goBackSecondPanel = () => {
    if (secondRouteStack.length === 1) return;
    const newStack = [...secondRouteStack];
    newStack.pop();
    updateTabSidebarState({ secondRouteStack: newStack });
  };

  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    setShowSidebar(!!primaryPanel?.route?.length || !!secondRouteStack.length);
  }, [primaryPanel.route, secondRouteStack]);

  useEffect(() => {
    const isTabIdMatched = activeTab.id === memoizedActiveTabId;
    const isprimaryPanelValid = !!primaryPanel.route?.length;
    const isRouteMismatched = !memoizedActiveTabUrl.includes(primaryPanel?.url ?? '');
    if (!isTabIdMatched || !isprimaryPanelValid || !isRouteMismatched) {
      return;
    }

    updateTabSidebarState({
      primaryPanel: primaryPanel?.dockPanel ? { ...primaryPanel } : {},
      secondRouteStack: sidebarState?.secondRouteDockPanel ? [...secondRouteStack] : [],
      secondRouteUnStack: {},
    });
  }, [memoizedActiveTabId, memoizedActiveTabUrl]);

  useEffect(() => {
    const url = `${asPath.split('/').filter((item) => item)[1]}`;
    const pageId = extractPageIdFromObjectByUrl(pathname, query);
    if (pageId?.length && primaryPanel?.params?.id !== pageId) {
      openSidebar(url, { id: pageId });
    }
  }, [query]);

  // Sidebar isn't available on unprotected paths (e.g login, signup, etc.)
  if (unprotectedPaths.includes(pathname) || pathname.includes('/onboarding/new')) {
    return children;
  }

  return (
    <SidebarGlobalContext.Provider
      value={{
        route: primaryPanel?.route ?? '',
        openSidebar,
        closeSidebar,
        sidebarIds: [
          primaryPanel?.params?.id ? primaryPanel?.params?.id : '',
          secondRouteStack[secondRouteStack?.length - 1]?.params?.id || '',
        ],
      }}
    >
      {children}
      <SidebarAnimationParent>
        {showSidebar && (
          <SidebarAnim stopAnimation={showSidebar}>
            <SidebarRoot>
              {(!!primaryPanel?.route?.length || primaryPanel?.dockPanel) && (
                <SidebarManager
                  goBack={undefined}
                  params={primaryPanel?.params ?? {}}
                  route={primaryPanel.route ?? ''}
                  extras={primaryPanel.extras ?? {}}
                  routeStack={
                    primaryPanel?.dockPanel
                      ? [{ route: primaryPanel, params: primaryPanel.params, extras: primaryPanel.extras }]
                      : [primaryPanel] ?? []
                  }
                  isPrimary={true}
                  isDockPanelAvailable
                />
              )}
              {(!!secondRouteStack?.length || sidebarState?.secondRouteDockPanel) && (
                <SidebarManager
                  extras={{}}
                  isDockPanelAvailable
                  params={secondRouteStack.length ? secondRouteStack.at(-1)?.params ?? {} : ''}
                  route={secondRouteStack.length ? secondRouteStack.at(-1)?.route ?? '' : ''}
                  routeStack={secondRouteStack ?? []}
                  goBack={goBackSecondPanel}
                  isPrimary={false}
                  defaultState={secondRouteStack?.at(-1)?.defaultState ?? {}}
                />
              )}
            </SidebarRoot>
          </SidebarAnim>
        )}
      </SidebarAnimationParent>
    </SidebarGlobalContext.Provider>
  );
};

export default SidebarGlobal;
