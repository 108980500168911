import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { TRANSACTION_CLASSIFICATION_OPTIONS } from '../../constants';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu, getLeftIcon } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu
        defaultValue={{ label: 'Transaction Category', value: 'Transaction Category' }}
        value={{ label: 'Transaction Category', value: 'Transaction Category' }}
        disabled={true}
        options={[]}
      />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      <SingleSelectMenu
        enableAvatar
        customIconRender={(item) => getLeftIcon(item.label as string)}
        options={TRANSACTION_CLASSIFICATION_OPTIONS}
        value={TRANSACTION_CLASSIFICATION_OPTIONS.find((item) => item.value === condition.value)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
    </>
  );
};

export const TransactionClassificationRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION_CLASSIFICATION]}>
    <Row />
  </ConditionRow>
);
