import { useMemo } from 'react';
import { getShowTransactionsWithoutJournalEntriesBooleanOrUndefined } from '../../../components/PageComponents';
import { useAccountingTreatmentFilterContext } from '../context';

export const useFilteredAccountingTreatment = () => {
  const { state } = useAccountingTreatmentFilterContext();
  return useMemo(
    () => getShowTransactionsWithoutJournalEntriesBooleanOrUndefined(state.filter((option) => option.selected)),
    [state],
  );
};
