import { createContext, useContext } from 'react';
import { BannerContextType } from './types';

const defaultBannerContextState: BannerContextType = {
  showBanner: () => {},
  hideBanner: () => {},
  isBannerVisible: false,
};

export const BannerContext = createContext<BannerContextType>(defaultBannerContextState);

export const useBannerContext = () => useContext(BannerContext);
