import { CheckboxProps } from './types';
import { classNames } from '../utils';
//import Image from '../../../../apps/next/src/components/image-with-fallback'; @todo DO NOT IMPORT THIS REFACTOR IT INTO UI PACKAGE

export const Checkbox = ({
  label,
  isSelected = false,
  showCheckbox = true,
  onChange,
  icon,
  containerClassName,
  disabled,
}: CheckboxProps) => (
  <div
    className={classNames('flex items-center w-full py-1', containerClassName)}
    onClick={() => {
      if (disabled) return;
      if (onChange) onChange(!isSelected);
    }}
  >
    {showCheckbox && (
      <span>
        <input
          type='checkbox'
          checked={isSelected}
          className={classNames(
            'form-checkbox mr-2 mb-[2px] text-{#6565F1] w-[20px] h-[20px] border rounded disabled:bg-zinc-100',
            isSelected ? 'border-[#8A8AF4]' : 'border-zinc-200',
          )}
          onChange={(e) => {
            if (onChange) onChange(e.target.checked);
          }}
          disabled={disabled}
        />
      </span>
    )}
    <div className='inline-block flex-grow select-none items-center flex gap-x-2'>
      {icon && <img alt={icon.alt} src={icon.src} width={24} height={24} className='rounded-full' />}
      {label}
    </div>
  </div>
);
