import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../@/components/ui/table';
import { Checkbox } from '../../@/components/ui/checkbox';
interface Transaction {
  _id: string;
  sequenceNumber: string;
  transactionDate: string;
  chain: string;
  category: string;
  assetType: string;
  transactionDirection: string;
  grossAmount: { $numberDecimal: string };
  netAmount: { $numberDecimal: string };
  classification: string;
}

interface TransactionsTableProps {
  transactions: Transaction[];
}
// Helper function to format numbers
const formatNumber = (value: string | number) => {
  const number = typeof value === 'string' ? parseFloat(value) : value;

  if (number === 0) return '0.00';

  const absNumber = Math.abs(number);

  if (absNumber >= 1) {
    // For numbers 1 and above, show exactly two decimal places
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(number);
  } else {
    // For numbers less than 1, find the first two non-zero digits
    const log10 = Math.floor(Math.log10(absNumber));
    const scale = Math.pow(10, -log10 + 1);
    const scaledNumber = Math.round(number * scale) / scale;

    // Convert to string and remove trailing zeros
    let formattedNumber = scaledNumber.toString();
    formattedNumber = formattedNumber.replace(/\.?0+$/, '');

    // Ensure at least two decimal places
    const parts = formattedNumber.split('.');
    if (parts.length === 1) {
      formattedNumber += '.00';
    } else if (parts[1].length === 1) {
      formattedNumber += '0';
    }

    return formattedNumber;
  }
};
export const AiTransactionsTable: React.FC<TransactionsTableProps> = ({ transactions }) => {
  const [selectedTransactions, setSelectedTransactions] = useState<Set<string>>(new Set());

  const handleCheckboxChange = (transactionId: string, checked: boolean) => {
    setSelectedTransactions((prev) => {
      const newSet = new Set(prev);
      if (checked) {
        newSet.add(transactionId);
      } else {
        newSet.delete(transactionId);
      }
      return newSet;
    });
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className='w-[50px]'>Select</TableHead>
          <TableHead>Sequence</TableHead>
          <TableHead>Date</TableHead>
          <TableHead>Chain</TableHead>
          <TableHead>Category</TableHead>
          <TableHead>Asset</TableHead>
          <TableHead>Direction</TableHead>
          <TableHead className='text-right'>Gross Amount</TableHead>
          <TableHead className='text-right'>Net Amount</TableHead>
          <TableHead>Classification</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((transaction) => (
          <TableRow key={transaction._id}>
            <TableCell>
              <Checkbox
                checked={selectedTransactions.has(transaction._id)}
                onCheckedChange={(checked) => {
                  if (typeof checked === 'boolean') {
                    handleCheckboxChange(transaction._id, checked);
                  }
                }}
              />
            </TableCell>
            <TableCell>{transaction.sequenceNumber}</TableCell>
            <TableCell>{new Date(transaction.transactionDate).toLocaleString()}</TableCell>
            <TableCell>{transaction.chain}</TableCell>
            <TableCell>{transaction.category}</TableCell>
            <TableCell>{transaction.assetType}</TableCell>
            <TableCell>{transaction.transactionDirection}</TableCell>
            <TableCell className='text-right'>{formatNumber(transaction.grossAmount.$numberDecimal)}</TableCell>
            <TableCell className='text-right'>{formatNumber(transaction.netAmount.$numberDecimal)}</TableCell>
            <TableCell>{transaction.classification}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
