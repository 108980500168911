import React from 'react';
import ConditionItem from '../common/ConditionItem';
import { setSaveData } from '../../../../utils';
import { chains } from '../../../constants';
import SearchFilter from '../common/SearchFilter';
import { ConditionMenusProps } from '../../../types';

export const ChainsCondition = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  isInLineCondition,
  callbackFn,
  closeDialog,
}: ConditionMenusProps) => {
  if (isInLineCondition) {
    return (
      <SearchFilter
        data={chains}
        renderItem={(item) => (
          <ConditionItem
            key={item}
            label={item}
            img={item.toLowerCase()}
            onClick={() => {
              callbackFn && callbackFn(item.toLowerCase());
              closeDialog && closeDialog();
            }}
          />
        )}
      />
    );
  }

  return (
    <ConditionItem isInLineCondition={isInLineCondition} label='Chains'>
      <SearchFilter
        data={chains}
        renderItem={(item) => (
          <ConditionItem
            key={item}
            label={item}
            img={item.toLowerCase()}
            onClick={() => {
              setSaveData('Chain', item.toLowerCase(), isFirstLevel, ruleSetData, getRuleSetData);
            }}
          />
        )}
      />
    </ConditionItem>
  );
};

export default ChainsCondition;
