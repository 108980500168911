export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const ACCOUNT_TYPE_OPTIONS = [
  { label: 'Income', value: 'Income' },
  { label: 'Expense', value: 'Expense' },
  { label: 'Liability', value: 'Liability' },
  { label: 'Equity', value: 'Equity' },
  { label: 'Asset', value: 'Asset' },
];
export const BALANCE_SHEET_ACCOUNT_TYPE_OPTIONS = [
  { label: 'Liability', value: 'Liability' },
  { label: 'Equity', value: 'Equity' },
  { label: 'Asset', value: 'Asset' },
];

export const INCOME_STATEMENT_ACCOUNT_TYPE_OPTIONS = [
  { label: 'Income', value: 'Income' },
  { label: 'Expense', value: 'Expense' },
];

export const BALANCE_SHEET_ACCOUNT_TYPES = ['Liability', 'Equity', 'Asset'];
export const INCOME_STATEMENT_ACCOUNT_TYPES = ['Income', 'Expense'];
