import { useMutation } from '@tanstack/react-query';
import { updateSubscriptionCadence } from 'services';

export const useUpdateSubscriptionCadenceMutation = () =>
  useMutation(async ({ organizationId, cadence }: { organizationId: string; cadence: string }) => {
    const response = await updateSubscriptionCadence({
      organizationId,
      cadence,
    });
    return response.data;
  });
