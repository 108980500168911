import axios, { AxiosRequestConfig } from 'axios';
import { SERVER_URL_CORE, SERVER_URL_FILE_UPLOAD } from '../../config';
import { RevaluationConfiguration } from 'schemas';

export * from './account-posting-rule-metrics';
export * from './account-posting-rules';
export * from './account-posting-rulesets';
export * from './accounting-periods';
export * from './assets';
export * from './schedule-of-disposition';
export * from './realized-gain-or-loss';
export * from './closing-positions';
export * from './exchange-sources';
export * from './hedgey-sources';
export * from './impairmentRules';
export * from './journal-entries';
export * from './journal-entry-file-attachments';
export * from './journal-entry-line-templates';
export * from './journal-entry-templates';
export * from './ledger-accounts';
export * from './legal-entities';
export * from './memberships';
export * from './reports';
export * from './tags';
export * from './transactions';
export * from './users';
export * from './wallets';
export * from './job-configurations';
export * from './maple-sources';
export * from './table-group';

export const getOverviewData = async (data) =>
  axios.get(`${SERVER_URL_CORE}/overview?organizationId=${data.organizationId}`);

export const getUserAlgoliaKey = async (data, options: AxiosRequestConfig) =>
  axios.get(`${SERVER_URL_CORE}/algoliaSearchKey?organizationId=${data.organizationId}`, options);

export async function uploadJournalFile(params): Promise<any> {
  const initiateResult: any = await axios.post(
    `${SERVER_URL_FILE_UPLOAD}/initiate-organization-file-upload?organizationId=${params.meta.organizationId}`,
    { body: params.meta },
  );
  return fetch(initiateResult.data.s3PutObjectUrl, {
    method: 'PUT',
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': params.meta.contentType,
    },
    body: params.file, // body data type must match "Content-Type" header
  });
}

export async function getFileSignUrl(params: { filePath: string }) {
  return axios.get<{ url: string; success: boolean }>(
    `${SERVER_URL_FILE_UPLOAD}/get-organization-file-download-url?filePath=${params.filePath}`,
  );
}

export const upsertJobOrganizationConfig = async (body) => {
  return axios.post(`${SERVER_URL_CORE}/job-organization-config?organizationId=${body.organizationId}`, body);
};

export const getJobOrganizationConfig = async (params) => {
  return axios.get(
    `${SERVER_URL_CORE}/job-organization-config?organizationId=${params.organizationId}&jobType=${params.jobType}`,
  );
};

export const getRevaluationConfiguration = async (params) => {
  return axios.get<{ revaluationConfiguration: RevaluationConfiguration }>(
    `${SERVER_URL_CORE}/revaluation-configuration?organizationId=${params.organizationId}`,
  );
};

export const upsertRevaluationConfiguration = async (body) => {
  return axios.post(`${SERVER_URL_CORE}/revaluation-configuration?organizationId=${body.organizationId}`, body);
};

export const revalueAssetsForPeriod = async (body) => {
  return axios.post(`${SERVER_URL_CORE}/revalue-assets-for-period?organizationId=${body.organizationId}`, body);
};
