import { omitBy, isNil } from 'lodash';
import { ALL_SUPPORTED_ASSETS } from '../../constants';
import { shrink } from '../../lib/utils';
import { AssetWithExtraData, Tag, LegalEntity, AccountingPeriod, LedgerAccount } from 'services/http/response.types';
import { InfiniteData } from '@tanstack/react-query';
import { dateConverter } from '../utils';
import { MergeTransactionsType } from './types';
import { formatTableNumbers } from 'global-utils';
import { AccountingPeriodFilterRow } from 'src/context';

export const formatUnderscoreText = (text: string) =>
  text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const getFloatFromDollarFormat = (value: string) => {
  return parseFloat(value.replace(/,/g, ''));
};

export const handleTags = (str = []) => {
  let saveStr = '';
  str.map((item: Tag, index) => {
    saveStr += `${item?.entry?.key}: ${item?.entry?.value}`;
    if (!!index && index + 1 !== str.length) {
      saveStr += ',';
    }
  });
  return saveStr;
};

export const removeEmpty = (originalObject) => omitBy(originalObject, isNil);
export const createQueryStringFromObject = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const convertSelectedJournalEntryLineTemplateToState = (selectedJournalEntryLineTemplate) => ({
  ...selectedJournalEntryLineTemplate,
  ledgerAccountId: getIdFromPopulatedInstance(selectedJournalEntryLineTemplate?.ledgerAccountId),
  tags: selectedJournalEntryLineTemplate?.tags?.map((tag) => getIdFromPopulatedInstance(tag)) || [],
  legalEntityId: selectedJournalEntryLineTemplate?.useTransactionLegalEntity
    ? 'transactionLegalEntity'
    : getIdFromPopulatedInstance(selectedJournalEntryLineTemplate?.legalEntityId),
});

export const TEMPLATES_COPY = {
  CREATE_LINE_SIDEBAR_HEADING: 'Add template line',
  EDIT_LINE_SIDEBAR_HEADING: 'Edit template line',
  CREATE_TEMPLATE_SIDEBAR_PRIMARY_BUTTON: 'Submit template',
  EDIT_TEMPLATE_SIDEBAR_PRIMARY_BUTTON: 'Update',
  CREATE_LINE_SECONDARY_BUTTON: 'Cancel',
  EDIT_LINE_SECONDARY_BUTTON: 'Delete',
};

export const USDollarFormater = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const mergeLegalEntities = (legalEntities) => {
  let mergedLegalEntities: LegalEntity[] = [];
  if (legalEntities && legalEntities.pages?.length) {
    legalEntities.pages.forEach((page) => {
      mergedLegalEntities = [...mergedLegalEntities, ...page];
    });
  }
  return mergedLegalEntities;
};

export const getDisplayedLegalEntities = (mergedLegalEntities): { label: string; value: string }[] =>
  mergedLegalEntities.map((legalEntity) => ({
    label: `${legalEntity.entityName}`,
    value: legalEntity._id,
  }));

export const getObjectIdsFromInstances = (instances) => instances.map((instance) => instance._id);

/**
 *
 * @param {*} selectedInstances Database objects
 * @param {*} optionalValues Dropdown objects [{label: string, value: string}]
 * @returns
 */
export const getInputTagDefaultValue = (selectedInstances, optionalInstances) => {
  if (!selectedInstances || !optionalInstances) return [];
  const ids = selectedInstances.map((instance) => instance._id);
  const options = optionalInstances.filter((instance) => ids.includes(instance.value));
  return options;
};

export const getDefaultDropDownValue = (displayedInstances, instanceId) => {
  if (!instanceId) return {};
  return displayedInstances.find((instance) => instance.value === instanceId);
};

export const mergeJournalEntryTemplates = (templates) => {
  let pages = [];
  let merged: any = [];
  if (templates?.pages) pages = templates.pages;
  if (pages.length) {
    pages.map((page: any) => {
      merged = [...merged, ...page.journalEntryTemplates];
    });
  }
  return merged;
};

export const mergeAccountPostingRules = (accountPostingRules) => {
  let merged: any = [];
  let pages = [];
  if (accountPostingRules?.data?.pages) pages = accountPostingRules.data.pages;
  pages.map((page: any) => {
    merged = [...merged, ...page.accountPostingRules];
  });
  return merged;
};

export const getDisplayedJournalEntryTemplates = (templates) => {
  if (templates?.journalEntryTemplates) templates = templates.journalEntryTemplates;

  const displayedRes = templates?.map((template) => ({
    label: `${template.name}`,
    value: template._id,
  }));
  return displayedRes;
};

export const mergeAccountingPeriods = (
  accountingPeriods: InfiniteData<{ accountingPeriods: AccountingPeriod[] }> | undefined,
) => {
  if (!accountingPeriods) return [];
  let pages: AccountingPeriod[] = [];
  if (accountingPeriods?.pages) pages = accountingPeriods.pages.map((page) => page?.accountingPeriods).flat();
  return pages;
};

export const mergeAccountingPeriodStartDates = (
  accountingPeriodStartDates: InfiniteData<AccountingPeriodFilterRow[]> | undefined,
) => {
  if (!accountingPeriodStartDates) return [];
  return accountingPeriodStartDates.pages.flat().sort();
};

export const getDisplayedAccountingPeriods = (accountingPeriods) => {
  const displayedRes = accountingPeriods?.map((ap) => ({
    label: ap.accountingPeriodName || formatAccountingPeriodDate(ap.startDate),
    value: ap._id,
  }));
  return displayedRes || [];
};

export const getDisplayedAccountingPeriodStartDateUTCs = (rows: AccountingPeriodFilterRow[]) => {
  const displayedRes = rows?.map((row) => ({
    label: row.accountingPeriodName,
    value: row.startDateUTC.toISOString(),
    startDateUTC: row.startDateUTC,
  }));
  return displayedRes || [];
};

export const getDisplayedAccountingPeriodsReports = (accountingPeriods) => {
  const displayedRes = accountingPeriods?.map((ap) => ({
    label: `${ap.accountingPeriodName || formatAccountingPeriodDate(ap?.startDate)}`,
    value: ap._id,
  }));
  return displayedRes || [];
};

export const getDisplayedTransactions = (transactions) => {
  const displayedRes = transactions?.map((tx) => ({
    label: tx.sequenceNumber || `${shrink(tx?.fromAddress)}-${tx?.transactionDirection}-${shrink(tx?.toAddress)}`,
    value: tx?._id,
    icon: tx.chain,
    bottomText: dateConverter(tx?.transactionDate),
  }));
  return displayedRes || [];
};

export const formatAccountingPeriodDate = (date) => {
  return new Date(date)
    .toLocaleString('default', {
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    })
    .toUpperCase();
};

export const getLastDayOfMonth = (today) => new Date(today.getFullYear(), today.getMonth() + 1, 0).toLocaleDateString();

export const getFirstDayOfYear = () => new Date(new Date().getFullYear(), 0, 1);

export const mergeSources = (sources) => {
  let pages: any = [];
  let merged: any = [];
  if (sources?.pages) pages = sources.pages;
  if (pages.length) {
    pages.map((page) => {
      merged = [...merged, ...page.wallets];
    });
  }
  return merged;
};

export const mergeSourceTags = (sources) => {
  let pages: any = [];
  let merged: any = [];
  if (sources?.pages) pages = sources.pages;
  if (pages.length) {
    pages.map((page) => {
      const wallets = page.tags;
      const tags: Tag[] = [];
      wallets.forEach((wallet) => {
        for (const tag of wallet.tags) {
          tags.push(tag);
        }
      });
      merged = [...new Set([...merged, ...tags])];
    });
  }
  return merged;
};

export const getDisplayedSources = (sources) => {
  const displayedRes = sources.map((source) => ({
    label: `${source.name}: ${source.walletType}`,
    value: source._id,
  }));
  return displayedRes;
};

export const getDispalyedSourcesForConditions = (sources) => {
  const displayedRes = sources.map((source) => ({
    label: `${source.walletType} ${source.name}`,
    value: {
      address: source.address,
      id: source._id,
    },
  }));
  return displayedRes;
};

export const mergeJournalEntries = (journalEntries) => {
  let merged: any = [];
  if (journalEntries && journalEntries.pages?.length) {
    journalEntries.pages.forEach((page) => {
      merged = [...merged, ...page.journalEntryModels];
    });
  }
  return merged;
};

export const getCreditsDebitsForJe = (je) => {
  if (!je || !Object.keys(je).length) return { credits: '', debits: '' };
  const getLinesByType = (type) => je.journalEntryLineIds.filter((item) => item.creditOrDebit === type);
  const debitLines = getLinesByType('DEBIT');
  const creditLines = getLinesByType('CREDIT');
  const sumDebits = debitLines.reduce((a, item) => a + parseFloat(item.amount.$numberDecimal), 0);
  const sumCredits = creditLines.reduce((a, item) => a + parseFloat(item.amount.$numberDecimal), 0);
  return { credits: sumCredits, debits: sumDebits };
};

export const formatAccountingPeriod = (date: Date) =>
  date.toLocaleString('default', { month: 'short', year: 'numeric', timeZone: 'UTC' });

function roundToTwoSignificantDigits(number: number) {
  // If the number is 0, return 0
  if (number === 0) {
    return 0;
  }

  // Calculate the order of magnitude (power of 10) for the number
  const orderOfMagnitude = Math.floor(Math.log10(Math.abs(number)));

  // Calculate the factor to scale the number to two significant digits after decimal
  const scaleFactor = Math.pow(10, 2 - orderOfMagnitude);

  // Scale the number to two significant digits after decimal
  const scaledNumber = number * scaleFactor;

  // Round the scaled number to the nearest integer
  const roundedNumber = Math.round(scaledNumber);

  // Scale the rounded number back to its original magnitude
  const result = roundedNumber / scaleFactor;

  return result;
}

export const formatDollars = (amount, twoDecimals = false) => {
  if (amount == 0) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }
  if (twoDecimals) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 10,
  });

  return formatter.format(roundToTwoSignificantDigits(amount));
};

export const formatSignificantDigits = (input: string | number): string => {
  const num = typeof input === 'number' ? input : parseFloat(input);

  if (Number.isInteger(num)) {
    return num.toFixed(2);
  }

  const significantDigits = num.toPrecision().replace(/0+$/, '');
  const decimalPlaces = significantDigits.split('.')[1]?.length || 0;

  return num.toFixed(Math.min(decimalPlaces, 2));
};

export const formatDate = (date?: Date) => {
  if (!date) return 'Invalid date';

  if (date.getFullYear() === new Date().getFullYear()) {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
    });
  } else {
    return date.toLocaleDateString('en', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }
};

export const getFirstAndLastDatesOfYear = () => {
  const currentYear = new Date().getFullYear();
  const firstDateOfYear = new Date(currentYear, 0, 1);
  const lastDateOfYear = new Date(currentYear, 11, 31);
  return { firstDateOfYear, lastDateOfYear };
};

export const convertJournalEntries = (journals, isPanel = false) => {
  const getAmount = (type, lines) => {
    let total = 0;

    if (!lines) {
      return formatDollars(0);
    }

    for (const line of lines) {
      if (line.creditOrDebit === type.toUpperCase()) {
        total += line.amount.$numberDecimal;
      }
    }

    return formatDollars(total);
  };

  const csvData = journals.map((journal) => {
    return {
      'Journal Entry ID': journal.journalSequenceNumber,
      'Legal Entity': journal.legalEntityId?.entityName,
      'Accounting Period': new Date(journal.accountingPeriodId?.accountingPeriodName),
      'Accounting Date': new Date(journal.accountingDate)
        .toLocaleDateString('en', {
          month: 'short',
          year: 'numeric',
        })
        .toUpperCase(),
      Memo: journal.memo,
      'Total Debit Amount': getAmount('CREDIT', journal?.journalEntryLineIds),
      'Total Credit Amount': getAmount('DEBIT', journal?.journalEntryLineIds),
      'Created At': formatDate(new Date(journal.createdAt)),
      'Updated At': formatDate(new Date(journal.updatedAt)),
      'Created By': journal.originatedBy,
      Template: journal?.journalEntryTemplateId?.name,
    };
  });

  if (isPanel) {
    const activeJournal = journals[0];
    if (activeJournal) {
      for (const line of activeJournal.journalEntryLineIds) {
        csvData.push({
          'Line ID': `${journals[0].journalSequenceNumber} Line ${journals[0].journalEntryLineIds.indexOf(line) + 1}`,
          'Legal Entity': line.legalEntityId?.entityName,
          'Ledger Account': line.ledgerAccountId?.ledgerAccountName,
          'Credit/Debit': line.creditOrDebit,
          'Line Amount': formatDollars(line.amount?.$numberDecimal),
          Tags: handleTags(line.tags),
        });
      }
    }
  }

  return csvData;
};

export const convertJobHistories = (histories) => {
  return histories.map((item) => {
    return {
      ...item,
      createdBy: item?.createdBy?.email,
    };
  });
};

export const convertTransactions = (transactions) => {
  return transactions.map((tsx) => {
    return {
      id: tsx.sequenceNumber,
      Period: formatAccountingPeriodDate(new Date(tsx.transactionDate)),
      'Legal Entity': tsx.legalEntityId?.entityName,
      'Wallet Address': tsx.walletId?.address,
      'Block Number': tsx.blockNumber,
      'Transaction Hash': tsx.transactionHash,
      'Transaction Date': tsx.transactionDate,
      Chain: tsx.chain?.toUpperCase(),
      Category: tsx.category,
      'Asset Type': tsx.assetType?.toUpperCase(),
      'To Address': tsx.toAddress,
      'From Address': tsx.fromAddress,
      'Debit/Credit': tsx.transactionDirection,
      'Gross Token Amount': tsx.grossAmount?.$numberDecimal,
      'Net Token Amount': tsx.netAmount?.$numberDecimal,
      'Fee Token Amount': tsx.fee?.$numberDecimal,
      'Gross Value': formatTableNumbers({ value: tsx.grossPrice?.$numberDecimal }),
      'Net Value': formatTableNumbers({ value: tsx.netPrice?.$numberDecimal }),
      'Fee Value': formatTableNumbers({ value: tsx.feePrice?.$numberDecimal }),
      Currency: tsx.currency,
      'Created At': tsx.createdAt,
      'Updated At': tsx.updatedAt,
    };
  });
};

export const convertAssets = (assets) => {
  return assets.map((assetItem) => {
    const impairedCost = Math.round(assetItem.impairedCostBasis);
    return {
      Asset: assetItem.assetType,
      'Cost Basis': assetItem.costBasis,
      'Total Cost': formatTableNumbers({
        value: parseFloat(assetItem.costBasis) * parseFloat(assetItem.quantity),
      }),
      'Remaining Fiat Amount': formatTableNumbers({
        value: parseFloat(assetItem.costBasis) * parseFloat(assetItem.remainingQuantity),
      }),
      'Ledger Account Sequence': assetItem.ledgerAccountId?.ledgerAccountSequence,
      'Ledger Account Name': assetItem.ledgerAccountId?.ledgerAccountName,
      Impaired: `${assetItem.isImpaired}`,
      'Impaired Cost Basis': isNaN(impairedCost) ? 'N/A' : `$${impairedCost.toString()}`,
      'Remaining Qty': `${assetItem.remainingQuantity}`,
      'Initial Qty': `${assetItem.quantity}`,
      'Current Value': formatDollars(assetItem.currentValue),
      'Date Received': dateConverter(assetItem.dateReceived),
      Journal: assetItem?.journalEntryAssetIn?.journalSequenceNumber,
      'Legal Entity': assetItem?.legalEntityId?.entityName || 'N/A',
    };
  });
};

export const convertSources = (sources) => {
  return sources.map((source) => {
    return {
      Chain: source.chain,
      Type: source.walletType,
      Alias: source?.name || 'N/A',
      Address: source.address,
      'Legal entity': source?.legalEntityId?.entityName || 'N/A',
      'Created date': formatDate(new Date(source.createdAt)),
    };
  });
};

export const getTransactionDetail = (transactionDetail) => {
  return transactionDetail
    ? [
        {
          'Transaction ID': transactionDetail.sequenceNumber,
          'Transaction Date': `${new Date(transactionDetail.transactionDate).toLocaleDateString('en', {
            month: 'numeric',
            year: '2-digit',
          })}`,
          Chain: transactionDetail.chain?.toUpperCase(),
          'Debit/Credit': transactionDetail.transactionDirection,
          Currency: transactionDetail.currency,
          'Gross Token Amount': transactionDetail.grossAmount?.$numberDecimal,
          'Net Token Amount': transactionDetail.netAmount?.$numberDecimal,
          'Fee Token Amount': transactionDetail.fee?.$numberDecimal,
          'Gross Value': formatTableNumbers({ value: transactionDetail.grossPrice?.$numberDecimal }),
          'Net Value': formatTableNumbers({ value: transactionDetail.netPrice?.$numberDecimal }),
          'Fee Value': formatTableNumbers({ value: transactionDetail.feePrice?.$numberDecimal }),
          Asset: transactionDetail.assetType?.toUpperCase(),
          'Transaction Hash': transactionDetail.transactionHash,
          'To Address': transactionDetail.toAddress,
          'From Address': transactionDetail.fromAddress,
          Period: transactionDetail.accountingPeriodId?.accountingPeriodName,
          'Internal Source': transactionDetail.walletId?.name,
          'Cost Basis': formatDollars(transactionDetail.assetUnitPrice?.$numberDecimal),
          Memo: transactionDetail.memo,
        },
      ]
    : [];
};

export const mergeLedgerAccounts = (ledgerAccounts?: { pages?: LedgerAccount[][] }) => {
  let mergedLedgerAccounts: LedgerAccount[] = [];
  if (ledgerAccounts && ledgerAccounts.pages?.length) {
    ledgerAccounts.pages.forEach((page) => {
      mergedLedgerAccounts = [...mergedLedgerAccounts, ...page];
    });
  }
  return mergedLedgerAccounts;
};

export const mergeAssets = (assetsIQData?: { pages: { assets: AssetWithExtraData[] }[] }) => {
  let merged: AssetWithExtraData[] = [];
  assetsIQData?.pages.forEach((page) => (merged = [...merged, ...page.assets]));

  return merged;
};

export const getDisplayedLedgerAccounts = (ledgerAccounts) => {
  return (
    ledgerAccounts?.map((ledgerAcc) => ({
      label: `${ledgerAcc.ledgerAccountSequence}: ${ledgerAcc.ledgerAccountName}`,
      value: ledgerAcc._id,
    })) || []
  );
};

export const getIdFromPopulatedInstance = (instance, key?: string) => {
  // check if instance is an object or a string
  if (!instance) return '';
  if (typeof instance === 'string') return instance;
  return key ? instance[key] : instance._id;
};
export enum STATEMENT_NORMAL {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}
export const getCreditOrDebitNormal = (ledgerAccount): STATEMENT_NORMAL => {
  // if ledgerAccountType is liablity equity or income return 'CREDIT'
  // if ledgerAccountType is asset or expense return 'DEBIT'
  return ['Liability', 'Equity', 'Income'].includes(ledgerAccount.ledgerAccountType)
    ? STATEMENT_NORMAL.CREDIT
    : STATEMENT_NORMAL.DEBIT;
};

export const mergeTags = (tags) => {
  let mergedTags: Tag[] = [];
  tags?.pages.forEach((page) => {
    mergedTags = [...mergedTags, ...page];
  });
  return mergedTags;
};

export const getDisplayedTags = (tags) => {
  const displayedTags =
    tags?.map((tag) => ({
      label: `${tag.entry.key} => ${tag.entry.value}`,
      value: tag._id,
    })) || [];
  return displayedTags;
};

export const mergeTransactions = (transactions) => {
  let merged: MergeTransactionsType[] = [];
  if (transactions && transactions.pages?.length) {
    transactions.pages.forEach((page) => {
      merged = [...merged, ...page.transactions];
    });
  }
  return merged;
};

export const mergeImpairmentRules = (transactions) => {
  let merged: any = [];
  if (transactions && transactions.pages?.length) {
    transactions.pages.forEach((page) => {
      merged = [...merged, ...page.impairmentRules];
    });
  }
  return merged;
};

export const getDisplayedSourceStatuses = () => [
  {
    label: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'ARCHIVED',
    value: 'ARCHIVED',
  },
];

export const deriveError = (error: any) => {
  // deriveError called with null, undefined, false, 0
  if (!error) return 'Unknown error occured';

  // if its an axios error
  if (error.response) {
    // handling validation error, should be changed to status code based errors (waiting on validation middleware mappings on BE)
    if (error.response.data) {
      // prioritize errorMessage
      if (error.response.data.errorMessage) {
        const message = error.response.data.errorMessage;
        if (typeof message === 'string') return message;
        return JSON.stringify(message);
      }
      if (error.response.data.message === 'Validation failed') {
        const requiredFields = error.response.data.fields;
        if (requiredFields)
          return `${requiredFields.join(' ,')} ${requiredFields.length === 1 ? 'field' : 'fields'} required`;
      }
    }

    // if all else fails, but its a response, just show the message as is
    if (error?.response?.data?.message) {
      const message = error.response.data.message;
      return typeof message === 'string' ? message : JSON.stringify(message);
    }
  }

  // this is probably unreachable, not sure where we would have something like this
  if (error.data?.message)
    return typeof error.data.message === 'string' ? error.data.message : JSON.stringify(error.data.message);

  // handling 'Document not found', 'Document already exists', and unkown error
  if (error.message) return typeof error.message === 'string' ? error.message : JSON.stringify(error.message);

  // if we are throwing something that's not an error
  return JSON.stringify(error);
};

export const getDisplayedAssets = () => ALL_SUPPORTED_ASSETS.map((token) => ({ label: token, value: token }));

export const formatLedgerAccountName = (ledgerAccount) => {
  if (ledgerAccount?.ledgerAccountSequence)
    return `${ledgerAccount.ledgerAccountSequence}: ${ledgerAccount.ledgerAccountName}`;
  else return ledgerAccount.ledgerAccountName;
};

export const millisecondsToTime = (ms: number): string => {
  if (typeof ms === 'string') ms = parseFloat(ms);
  const milliseconds = Math.floor((ms % 1000) / 100),
    seconds = Math.floor((ms / 1000) % 60);

  let minutes: string | number = Math.floor((ms / (1000 * 60)) % 60),
    hours: string | number = Math.floor((ms / (1000 * 60 * 60)) % 24);

  let res = '';
  if (hours) {
    hours = hours < 10 ? '0' + hours : hours;
    res += `${hours} hours, `;
  }
  if (minutes) {
    minutes = minutes < 10 ? '0' + minutes : minutes;
    res += `${minutes} min, `;
  }
  if (seconds || milliseconds) {
    res += `${seconds}.${milliseconds} secs`;
  }
  if (!milliseconds && !seconds && !minutes && !hours) res = '-';
  return res;
};

export const generateRandomHexString = (length) => {
  const maxLength = 8;
  const min = Math.pow(16, Math.min(length, maxLength) - 1);
  const max = Math.pow(16, Math.min(length, maxLength)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < length) {
    r = r + generateRandomHexString(length - maxLength);
  }
  return r;
};

export const normalizeMixpanelPageNames = (pageName: string): string => {
  switch (pageName) {
    case 'Create Account':
      return pageName;
    case 'Short Code':
      return 'Verify Email';
    case 'Workspace':
      return 'Workspace Details';
    case 'Business Details':
      return 'Legal Entity Details';
    case 'Add Wallets':
      return 'Wallet Entity Details';
    case 'Wallet Chains':
      return 'Select Chains';
    case 'Connected Wallets':
      return 'Review Wallet Details';
    case 'Importing Transactions':
      return 'Transaction Import';
    case 'Exchange':
      return 'Select Integrations';
    case 'Kraken':
      return 'Select Integrations: Kraken';
    case 'Circle':
      return 'Select Integrations: Circle';
    case 'Coinbase':
      return 'Select Integrations: Coinbase';
    case 'Accounting Automation':
      return 'Automation';
    case 'Subscription':
      return pageName;
    case 'Calendly':
      return pageName;
    case 'Invite Team':
      return pageName;
    default:
      return '';
  }
};
