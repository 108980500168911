import React, { useContext } from 'react';
import { DetailsItem, SidebarSection, SidebarSectionHeader } from '../../../atoms';
import { getHost, shrink } from '../../../../lib/utils';
import { getAddressExplorerLink, getTransactionExplorerLink } from 'ui';
import { formatDate } from '../../../templates/utils';
import { CryptoIcon } from '../../../utils';
import { renderRaincardsTransactionDetails } from '../../../utils/raincard';
import CurrencyFlag from 'react-currency-flags';
import { SidebarGlobalContext } from '../../../../context';
import { useTabState } from '../../../../hooks';
import { getNFTImageUrlFromTransaction } from '../../../PageComponents';

const Details = ({ transaction, isLoading }) => {
  const isKraken = transaction?.exchangeRefId;
  const { openSidebar } = useContext(SidebarGlobalContext);
  const { updateTabSidebarState } = useTabState();

  const closeFormPanels = () => updateTabSidebarState({ secondRouteUnStack: {} });

  let assetTypeLeadingComponent = <></>;
  if (transaction?.classification === 'NFT') {
    assetTypeLeadingComponent = (
      <img className='w-6 h-6 rounded-[100%]' src={getNFTImageUrlFromTransaction(transaction)} />
    );
  } else {
    assetTypeLeadingComponent = (
      <CryptoIcon
        symbol={transaction?.assetType?.toLowerCase()}
        rawContractAddress={transaction?.rawContractAddress}
        size='sm'
      />
    );
  }
  return (
    <>
      <SidebarSectionHeader title='Details' />

      <SidebarSection loading={isLoading}>
        <DetailsItem
          tag='From address'
          value={shrink(transaction?.fromAddress)}
          textToCopy={`${transaction?.fromAddress}`}
          wrapperComponent={{
            component: 'a',
            props: {
              href: `${getAddressExplorerLink(transaction?.chain)}/${transaction?.fromAddress}`,
              target: '_blank',
              rel: 'noreferrer',
              className: 'block',
            },
          }}
        />
        <DetailsItem
          tag='To address'
          value={shrink(transaction?.toAddress)}
          textToCopy={`${transaction?.toAddress}`}
          wrapperComponent={{
            component: 'a',
            props: {
              href: `${getAddressExplorerLink(transaction?.chain)}/${transaction?.toAddress}`,
              target: '_blank',
              rel: 'noreferrer',
              className: 'block',
            },
          }}
        />

        <DetailsItem tag='Transaction date' value={formatDate(new Date(transaction?.transactionDate))} />
        <DetailsItem
          tag='Transaction hash'
          value={shrink(transaction?.transactionHash)}
          textToCopy={`${transaction?.transactionHash}`}
          wrapperComponent={{
            component: 'a',
            props: {
              href: `${getTransactionExplorerLink(transaction?.chain)}/${transaction?.transactionHash}`,
              target: '_blank',
              rel: 'noreferrer',
              className: 'block',
            },
          }}
        />
        <DetailsItem tag='Asset' value={transaction?.assetType} leadingComponent={assetTypeLeadingComponent} />
        <DetailsItem
          tag='Collection URL'
          value={transaction?.meta?.simpleHashNFTDetails?.collection?.name}
          textToCopy={`${transaction?.meta?.simpleHashNFTDetails?.collection?.marketplace_pages[0]?.collection_url}`}
          wrapperComponent={{
            component: 'a',
            props: {
              href: `${getTransactionExplorerLink(transaction?.chain)}/${transaction?.transactionHash}`,
              target: '_blank',
              rel: 'noreferrer',
              className: 'block',
            },
          }}
        />
        <DetailsItem tag='Rarity Rank' value={transaction?.meta?.simpleHashNFTDetails?.rarity?.rank} />

        <DetailsItem
          tag='Chain'
          value={transaction?.chain?.toUpperCase()}
          leadingComponent={<CryptoIcon symbol={transaction?.chain} size='sm' />}
        />
        <DetailsItem
          tag='Exchange'
          value={isKraken ? ' ' : ''}
          leadingComponent={<img className='w-6 h-6' src={'/kraken.png'} />}
        />

        {renderRaincardsTransactionDetails(transaction)}
        <DetailsItem
          tag='Currency'
          value={transaction?.legalEntityId?.currency}
          leadingComponent={<CurrencyFlag currency={transaction?.legalEntityId?.currency} />}
        />
        <DetailsItem
          tag='Legal entity'
          value={transaction?.legalEntityId?.entityName}
          variant='secondary'
          textToCopy={`${getHost()}/configure/entities/${transaction?.legalEntityId?._id}`}
          onClick={() => {
            closeFormPanels();
            openSidebar('entities', { id: transaction?.legalEntityId?._id, primaryOrSecondary: 'secondary' });
          }}
          itemId={transaction?.legalEntityId?._id}
        />
        <DetailsItem
          tag='Internal source'
          value={transaction?.walletId?.name || shrink(transaction?.walletId?.address)}
          variant='secondary'
          textToCopy={`${getHost()}/sources/${transaction?.walletId?._id}`}
          onClick={() => {
            closeFormPanels();

            openSidebar('sources', { id: transaction?.walletId?._id, primaryOrSecondary: 'secondary' });
          }}
          itemId={transaction?.walletId?._id}
        />
        <DetailsItem
          tag='External source'
          value={transaction?.externalSource?.name || shrink(transaction?.externalSource?.address)}
          variant='secondary'
          textToCopy={`${getHost()}/ledger/sources/${transaction?.externalSource?._id}`}
          onClick={() => {
            closeFormPanels();

            openSidebar('sources', { id: transaction?.externalSource?._id, primaryOrSecondary: 'secondary' });
          }}
          itemId={transaction?.externalSource?._id}
        />
        {/* <DetailsItem tag='Function Name' value={transaction?.functionData?.name} /> */}
        <DetailsItem tag='Loop Transfer Type' value={transaction?.meta?.loopTransferType} />
        <DetailsItem tag='Loop Subscription Name' value={transaction?.meta?.loopSubscriptionName} />
        <DetailsItem tag='Loop Subscription Frequency' value={transaction?.meta?.loopSubscriptionFrequency} />

        {/** Cosmos */}
        {/** Omar to add this back */}
        {/* <DetailsItem tag='Type' value={transaction?.meta?.cosmoMessageType?.split('.').join(' ')} /> */}
      </SidebarSection>
    </>
  );
};

export default Details;
