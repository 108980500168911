import React from 'react';
import { IntegrationProcessLoaderProps } from './types';
import { RotatingLines } from 'react-loader-spinner';

export const IntegrationProcessLoader = ({ icon, heading, description, actions }: IntegrationProcessLoaderProps) => {
  return (
    <div className='border rounded-lg bg-white px-5 py-8 flex-col items-center justify-center text-center w-[400px]'>
      <span className='inline-block mb-3'>
        {icon ?? (
          <RotatingLines strokeColor='grey' strokeWidth='3' animationDuration='0.75' width='40' visible={true} />
        )}
      </span>
      <div className='font-medium text-xl mb-2'>{heading}</div>
      <div className='opacity-70 mb-4'>{description}</div>
      <div>{actions}</div>
    </div>
  );
};
