import React from 'react';
import { TableWrapperProps } from './types';
import { classNames } from 'ui';

export const TableWrapper = ({ children, isInSidepanel, className = '', scrollX, scrollY }: TableWrapperProps) => {
  const baseClasses = classNames(
    !isInSidepanel && 'mx-6 mb-8',
    'flex-auto flex flex-col',
    className,
    scrollX && 'overflow-x-auto',
    scrollY && 'overflow-y-auto'  // Make sure this is set correctly based on your needs
  );

  return <div className={baseClasses}>{children}</div>;
};
export default TableWrapper;
