import React, { useEffect, useState } from 'react';
import { AccountPostingRulesHeader } from '../../constants/tableHeaders';
import { useCreateAccountPostingRuleSet, useLegalEntities } from '../../hooks/http';
import { useCreateAccountPostingRule } from '../../hooks/http/useAccountPostingRules';
import { Button, DESCRIPTIVE_LOADER_STATES, DescriptiveLoader, InputLabel, InputWithExtras, MultiSelectMenu } from 'ui';
import { Sidebar, SidebarTopBar, SidebarRoot, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Table } from '../dashboard';
import {
  deriveError,
  getDisplayedLegalEntities,
  getObjectIdsFromInstances,
  mergeLegalEntities,
} from '../templates/utils';
import CreateRule from './CreateRule';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';
import { getMultiSelectMenuValuesForLegalEntities } from 'global-utils';

const RulesetCreatedIndicator = ({ onClose, setStep, 'data-cy': dataCy }) => (
  <>
    <SidebarBody>
      <div className='h-[300px] w-full flex items-center justify-center'>
        <DescriptiveLoader
          title='Ruleset created'
          description='You can close this panel'
          status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
        />
      </div>
    </SidebarBody>
    <SidebarFooter
      primaryBtn={<Button data-cy={`${dataCy}__doneButton`} label='Done' emphasis='high' onClick={onClose} />}
      secondaryBtn={<Button label='Add another ruleset' emphasis='medium' onClick={() => setStep(1)} />}
    />
  </>
);
const CreatingRulesetLoader = ({ onClose, setStep, 'data-cy': dataCy }) => (
  <>
    <SidebarBody>
      <div className='h-[300px] w-full flex items-center justify-center'>
        <DescriptiveLoader title='Creating ruleset' />
      </div>
    </SidebarBody>
    <SidebarFooter
      primaryBtn={<Button data-cy={`${dataCy}__doneButton`} label='Done' emphasis='high' onClick={onClose} />}
      secondaryBtn={<Button label='Add another ruleset' emphasis='medium' onClick={() => setStep(1)} />}
    />
  </>
);

type FormData = {
  name: string;
  legalEntities: string[];
};

export function CreateRuleSet({ onClose, selectedAccountPostingRuleSet }: any) {
  const [step, setStep] = useState(1);
  const { organizationId, userId } = useSession();
  const [selectedAccountPostingRule, setSelectedAccountPostingRule] = useState(false);
  const [showCreateAccountPostingRule, setShowCreateAccountPostingRule] = useState(false);
  const [displayedAccountPostingRules, setDisplayedAccountPostingRules] = useState([]);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    legalEntities: [],
  });
  const { data: legalEntities } = useLegalEntities();

  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);

  const { mutateAsync: createRuleSetAsync } = useCreateAccountPostingRuleSet();
  const { invalidateAccountPostingRulesets } = useInvalidateQuery();
  const [draftRules, setDraftRules] = useState<any[]>([]);

  const { mutateAsync: createRuleAsync } = useCreateAccountPostingRule();

  const onCreateRuleset = async () => {
    setStep(2);
    try {
      const payload = {
        ...formData,
        legalEntities: formData.legalEntities,
        userId,
        organizationId,
      };
      const response = await createRuleSetAsync(payload, {
        onSuccess: () => invalidateAccountPostingRulesets(),
      });
      const { accountPostingRuleSet } = response.data;
      // we have the ruleset now send the lines

      const { _id: accountPostingRuleSetId } = accountPostingRuleSet;

      for (let i = 0; i < draftRules.length; i += 1) {
        const rulePayload = {
          ...draftRules[i],
          accountPostingRuleSetId,
        };
        // post each rule
        await createRuleAsync(rulePayload);
      }

      setStep(3);
      // create the template then add the lines
    } catch (error) {
      toast.error(deriveError(error));
      setStep(1);
    }
  };

  useEffect(() => {
    if (selectedAccountPostingRuleSet) {
      const _legalEntities = getObjectIdsFromInstances(selectedAccountPostingRuleSet.legalEntities);
      setFormData({ ...selectedAccountPostingRuleSet, _legalEntities });
      setDisplayedAccountPostingRules(
        selectedAccountPostingRuleSet.accountPostingRuleIds.map((rule) => ({
          ...rule,
          name: {
            title: rule.name,
          },
        })),
      );
    }
  }, [selectedAccountPostingRuleSet]);

  const dataCy = 'createRuleset';
  return (
    <SidebarRoot>
      <Sidebar data-cy={dataCy} data-testid='create__rulesetsModel' className='!justify-start'>
        <SidebarTopBar onClose={onClose} />
        {step === 1 && (
          <>
            <SidebarHeader data-cy={dataCy} title='Create Rule Set' />
            <SidebarBody>
              <SidebarSectionHeader title='Details' />
              <SidebarSection numberOfColumns={1}>
                <div>
                  <InputLabel heading='Rule set name' />
                  <InputWithExtras
                    data-cy={`${dataCy}__rulesetName`}
                    data-testid='rulesetName__input'
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <InputLabel heading='Legal Entities' />

                  <MultiSelectMenu
                    fullWidth
                    isOnSidepanel
                    value={getMultiSelectMenuValuesForLegalEntities(formData.legalEntities, mergedLegalEntities as any)}
                    data-cy={`${dataCy}__legalEntities`}
                    data-testid='rulesetName__checkboxMenu'
                    options={displayedLegalEntities}
                    onChange={(options) => {
                      setFormData({ ...formData, legalEntities: options.map((option) => option.value) });
                    }}
                    onClearValue={() => setFormData({ ...formData, legalEntities: [] })}
                  />
                </div>
              </SidebarSection>
              <SidebarSectionHeader
                title='Rules'
                actions={[
                  {
                    label: 'Add rule',
                    onClick: () => setShowCreateAccountPostingRule(true),
                    variant: 'tertiary',
                  },
                ]}
              />
              <SidebarSection>
                <Table
                  tableData={{
                    Data: displayedAccountPostingRules.length
                      ? displayedAccountPostingRules
                      : draftRules.map((rule) => ({ ...rule, name: { title: rule.name } })),
                  }}
                  useInViewCallback={() => {
                    undefined;
                  }}
                  tableHeader={AccountPostingRulesHeader}
                  onRowClick={(row) => {
                    setSelectedAccountPostingRule({ ...row.original, name: row.original.name.title });
                  }}
                  emptyBtn={{
                    emptyMsg: 'No rules yet',
                  }}
                  isSidePanel
                />
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter
              secondaryBtn={<Button label='Cancel' emphasis='medium' onClick={onClose} />}
              primaryBtn={
                <Button
                  data-cy={`${dataCy}__createRuleset`}
                  label='Create Ruleset'
                  emphasis='high'
                  onClick={onCreateRuleset}
                  disabled={!formData.legalEntities.length || !formData.name.length}
                />
              }
            />
          </>
        )}
        {step === 2 && <CreatingRulesetLoader data-cy={dataCy} setStep={setStep} onClose={onClose} />}
        {step === 3 && <RulesetCreatedIndicator data-cy={dataCy} setStep={setStep} onClose={onClose} />}
      </Sidebar>
      {showCreateAccountPostingRule && (
        <CreateRule
          onSave={(accountPostingRule) => {
            // we now have the rule with conditions
            // lets give it a random name
            const payload = {
              ...accountPostingRule,
              createdBy: userId,
              organizationId,
              createdAt: new Date(),
              updatedAt: new Date(),
              draftId: new Date().getTime(), // @todo replace with uuid
            };

            setShowCreateAccountPostingRule(false);
            setDraftRules([...draftRules, payload]);
          }}
          onClose={() => setShowCreateAccountPostingRule(false)}
        />
      )}
      {selectedAccountPostingRule && (
        <CreateRule
          onClose={() => setSelectedAccountPostingRule(false)}
          selectedAccountPostingRule={selectedAccountPostingRule}
          onSave={(accountPostingRule) => {
            // we now have the rule with conditions
            const payload = {
              ...accountPostingRule,
              createdBy: userId,
              organizationId,
              createdAt: new Date(),
              updatedAt: new Date(),
            };
            const oldDraftRules = draftRules.filter((rule) => rule._id !== payload._id);
            setDraftRules([...oldDraftRules, payload]);
            setSelectedAccountPostingRule(false);
          }}
        />
      )}
    </SidebarRoot>
  );
}
