import { DropdownOption } from 'ui';

export const CADENCES: DropdownOption[] = [
  { label: 'Hourly', value: '0 * * * *' },
  { label: 'Daily', value: '0 0 * * *' },
  { label: 'Weekly', value: '0 0 * * 0' },
  { label: 'Monthly', value: '0 0 1 * *' },
  { label: 'Quarterly', value: '0 0 1 3,6,9,12 *' },
  { label: 'Half yearly', value: '0 0 1 6,12 *' },
  { label: 'Yearly', value: '0 0 1 1 *' },
];
