import { WALLET_CHAIN } from 'services/http/response.types';

export const chains = Object.values(WALLET_CHAIN).map((chain) => chain.toUpperCase());
export const searchableAssets = ['USD', 'BTC', 'ETH', 'POLYGON', 'SOL'];
export const assets = ['USD', 'BTC', 'ETH', 'ERC - 20 Tokens', 'POLYGON', 'SOL', 'SOL Tokens', 'NEAR Tokens'];
export const TRANSACTION_CLASSIFICATIONS = [
  'SWAP',
  'DEPOSIT',
  'WITHDRAWAL',
  'BRIDGE',
  'INTERCOMPANY TRANSFER',
  'INTERNAL TRANSFER',
  'FEE',
  'MINTING',
  'SPAM',
  'NFT',
  'CLAIM REWARD',
  'STAKING_REWARD',
  'UNKNOWN',
];
export const allFilterItems = {
  searchFilter: '',
  typesFilter: [],
  chainsFilter: [{ label: '', value: '' }],
};

export const HEDGEY_EVENTS = [
  { label: 'Tokens bought', value: 'TokensBought' },
  { label: 'Deal closed', value: 'DealClosed' },
  { label: 'Nft redeemed', value: 'NFTRedeemed' },
  { label: 'Future redeemed', value: 'FutureRedeemed' },
  { label: 'New deal', value: 'NewDeal' },
  { label: 'Swap cancelled', value: 'SwapCancelled' },
  { label: 'Swap executed', value: 'SwapExecuted' },
  { label: 'Nft revoked', value: 'NFTRevoked' },
  { label: 'New nft gated deal', value: 'NewNFTGatedDeal' },
  { label: 'Future created', value: 'FutureCreated' },
  { label: 'New swap', value: 'NewSwap' },
  { label: 'Nft created', value: 'NFTCreated' },
  { label: 'Block processed', value: 'BlockProcessed' },
];

export const TRANSACTION_DIRECTION_OPTIONS = [
  {
    label: 'Credit',
    value: 'Credit',
  },
  {
    label: 'Debit',
    value: 'Debit',
  },
];

export const TRANSACTION_SOURCE_OPTIONS = [
  {
    label: 'internal',
    value: 'internal',
  },
  {
    label: 'external',
    value: 'external',
  },
];

export const TYPE_OPTIONS = [
  {
    label: 'Buy',
    value: 'Buy',
  },
  {
    label: 'Sell',
    value: 'Sell',
  },
];

export const TRANSACTION_CLASSIFICATION_OPTIONS = TRANSACTION_CLASSIFICATIONS.map((classification) => ({
  label: classification,
  value: classification.toLowerCase(),
}));
