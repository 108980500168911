import React from 'react';
import { shrink } from '../../../lib/utils';
import { Button } from '../../atoms';
import { ClipboardIcon } from '../../../assets/generated/svg';
import {
  StyledComponentContainer,
  StyledTag,
  StyledTagText,
  StyledTagsInput,
  StyledTagsInputContainer,
  StyledTagsWrapper,
  StyledXIcon,
} from '../../../styles/TagsInput';
import { TagsInputProps } from './types';

export function TagsInput({
  style,
  label,
  tags = [],
  defaultInputText = '',
  onEnter,
  onRemove,
  viewMode,
}: TagsInputProps) {
  const tagsComponent = tags.map((tag) => (
    <StyledTag key={tag}>
      <StyledTagText>{shrink(tag, 5)}</StyledTagText>
      {!viewMode && <StyledXIcon className='!text-xs' onClick={() => onRemove(tag)} />}
    </StyledTag>
  ));
  return (
    <StyledComponentContainer style={style} className='flex px-1 w-full'>
      {label ? <p className='whitespace-nowrap mr-1'>{label}:</p> : null}
      {!viewMode ? (
        <StyledTagsInputContainer>
          <StyledTagsWrapper>
            {tagsComponent}
            <StyledTagsInput
              placeholder={defaultInputText}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.currentTarget.value !== '') {
                  onEnter(e.currentTarget.value);
                  e.currentTarget.value = '';
                }
                if (e.key === 'Backspace' && tags.length > 0) {
                  onRemove(tags[tags.length - 1]);
                }
              }}
            />
          </StyledTagsWrapper>
        </StyledTagsInputContainer>
      ) : (
        <div className='flex items-center gap-x-3'>
          <StyledTagsWrapper>{tagsComponent}</StyledTagsWrapper>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(tags.join(','));
            }}
            variant='icon'
            className='!border-0 !p-0 !w-fit !h-fit my-auto'
          >
            <ClipboardIcon />
          </Button>
        </div>
      )}
    </StyledComponentContainer>
  );
}

export default TagsInput;
