import { Condition, GATE_TYPE, RULE_CONFIGURATOR_TYPE, RuleConfiguratorState } from '../types';
import short from 'short-uuid';
import { useEffect, useState } from 'react';

const translator = short();

// returns { [gate]: [{}] }
interface TopLevelCondition {
  [key: string]: (Condition | TopLevelCondition)[];
}

export const getInitialStateForRuleConfigurator = (
  configuratorType: RULE_CONFIGURATOR_TYPE,
  createEmpty = false,
): RuleConfiguratorState => {
  if (createEmpty) {
    return {
      conditions: {},
      conditionIds: [],
      conditionGroups: {},
      conditionGroupIds: [],
      configuratorType,
    };
  }
  const id = translator.new().toString();

  const state: RuleConfiguratorState = {
    conditions: {},
    conditionIds: [],
    conditionGroups: {
      [id]: {
        id,
        gates: [],
        childIds: [],
      },
    },
    conditionGroupIds: [id],
    configuratorType,
  };

  return state;
};

// Function to parse the rule configuration state into a top level condition
export const parseRuleConfiguratorState = (state: RuleConfiguratorState): TopLevelCondition => {
  // Find the root condition group (top-level condition group without a parent)
  const rootId = state.conditionGroupIds.find((id) => !state.conditionGroups[id].parentId);

  // Ensure that a root condition group exists, otherwise throw an error
  if (!rootId) throw new Error('Ruleset context state not initialized properly');

  // Call the helper function to prepare the TopLevelCondition
  const tlc = prepareTLCForConditionGroup(state, rootId);

  // Return the top level condition
  return tlc;
};

// Helper function to prepare a TopLevelCondition for a given condition group
const prepareTLCForConditionGroup = (state: RuleConfiguratorState, conditionGroupId: string): TopLevelCondition => {
  // Retrieve information about the current condition group
  const conditionGroup = state.conditionGroups[conditionGroupId];

  // Initialize a TopLevelCondition with the first gate
  let tlc: TopLevelCondition = { [conditionGroup.gates[0]]: [] };

  // Iterate through the children of the current group
  for (let i = 0; i < conditionGroup.childIds.length; i += 1) {
    const childId = conditionGroup.childIds[i];
    // Check if the child is a condition group or a single condition
    const childTlc = state.conditionGroupIds.includes(childId)
      ? prepareTLCForConditionGroup(state, childId) // Recursively prepare subcondition group
      : state.conditions[childId]; // Retrieve single condition

    // initialize the gate with first condition
    if (i === 0) {
      tlc[conditionGroup.gates[i]].push(childTlc);
      continue;
    }
    // add the second condition, no need to check gate
    if (i === 1) {
      tlc[conditionGroup.gates[i - 1]].push(childTlc);
      continue;
    }
    // check if the gate is same, 3rd condition onwards
    if (conditionGroup.gates[i - 1] === conditionGroup.gates[i - 2]) {
      tlc[conditionGroup.gates[i - 1]].push(childTlc);
      continue;
    }
    // create a new gate in TopLevelCondition if needed
    tlc = { [conditionGroup.gates[i - 1]]: [tlc, childTlc] };
  }
  // Return the prepared TopLevelCondition for the current group
  return tlc;
};

export const parseTopLevelCondition = (
  configuratorType: RULE_CONFIGURATOR_TYPE,
  topLevelCondition: any,
  configuratorState?: RuleConfiguratorState,
  parentId?: string,
  gate = GATE_TYPE.AND,
): RuleConfiguratorState => {
  try {
    if (!configuratorState)
      return parseTopLevelCondition(
        configuratorType,
        topLevelCondition,
        getInitialStateForRuleConfigurator(configuratorType, true),
      );

    const conditionGroupId = translator.new().toString();
    configuratorState.conditionGroups[conditionGroupId] = {
      id: conditionGroupId,
      parentId,
      childIds: [],
      gates: [],
    };
    configuratorState.conditionGroupIds.push(conditionGroupId);

    if (parentId) {
      configuratorState.conditionGroups[parentId].childIds.push(conditionGroupId);
      configuratorState.conditionGroups[parentId].gates.push(gate);
    }

    const tlcGate = Object.keys(topLevelCondition)[0] as GATE_TYPE;
    const values = topLevelCondition[tlcGate];

    for (const value of values) {
      if (Object.keys(value).length === 1) {
        parseTopLevelCondition(configuratorType, value, configuratorState, conditionGroupId, tlcGate);
      } else {
        const conditionId = translator.new().toString();
        configuratorState.conditions[conditionId] = {
          ...value,
          id: conditionId,
          parentId: conditionGroupId,
        };
        configuratorState.conditionIds.push(conditionId);
        configuratorState.conditionGroups[conditionGroupId].childIds.push(conditionId);
        configuratorState.conditionGroups[conditionGroupId].gates.push(tlcGate);
      }
    }

    return configuratorState;
  } catch (error) {
    console.error('Could not parse configurator state from top level condition (legacy)', { topLevelCondition });
    return getInitialStateForRuleConfigurator(configuratorType, true);
  }
};

export const useDeriveRuleConfiguratorState = (
  configuratorType: RULE_CONFIGURATOR_TYPE,
  topLevelCondition: any,
  defaultConfiguratorState?: RuleConfiguratorState,
) => {
  const [state, setState] = useState(getInitialStateForRuleConfigurator(configuratorType));

  useEffect(() => {
    if (!topLevelCondition) return;
    setState(parseTopLevelCondition(configuratorType, topLevelCondition));
  }, [topLevelCondition, configuratorType]);

  if (defaultConfiguratorState) return defaultConfiguratorState;
  return state;
};
