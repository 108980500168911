import React, { useState, useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../@/components/ui/select';
import { Switch } from '../../@/components/ui/switch';
import { Label } from '../../@/components/ui/label';
import { useTheme } from 'next-themes';

interface ProcessedAssetChange {
  assetType: string;
  netChange: number;
  usdValue: number;
  changeFromPreviousMonth: number;
}

interface ProcessedMonthlyAssetChange {
  month: string;
  assets: ProcessedAssetChange[];
}

interface AssetQuantityChangeChartProps {
  data: ProcessedMonthlyAssetChange[];
}

type Period = 'all' | 'lastMonth' | 'lastQuarter' | 'lastYear';

const COLORS = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#a4de6c',
  '#d0ed57',
];

export const AssetQuantityChangesChart: React.FC<AssetQuantityChangeChartProps> = ({ data = [] }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('all');
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [useLogScale, setUseLogScale] = useState(false);
  const { theme } = useTheme();

  const filteredData = useMemo(() => {
    const now = new Date();
    return data.filter((item) => {
      const itemDate = new Date(item.month);
      if (selectedPeriod === 'lastMonth') {
        return itemDate >= new Date(now.getFullYear(), now.getMonth() - 1, 1);
      } else if (selectedPeriod === 'lastQuarter') {
        return itemDate >= new Date(now.getFullYear(), now.getMonth() - 3, 1);
      } else if (selectedPeriod === 'lastYear') {
        return itemDate >= new Date(now.getFullYear() - 1, now.getMonth(), 1);
      }
      return true;
    });
  }, [data, selectedPeriod]);

  const { chartData, assetTypes, yAxisDomain, canUseLogScale } = useMemo(() => {
    const assetTypesSet = new Set<string>();
    let minValue = Infinity;
    let maxValue = -Infinity;
    let hasNegativeOrZero = false;

    const chartData = filteredData.map((monthData) => {
      const monthItem: { [key: string]: number | string } = { month: monthData.month };
      let monthTotal = 0;
      monthData.assets.forEach((asset) => {
        assetTypesSet.add(asset.assetType);
        if (!selectedAssets.includes(asset.assetType)) {
          const value = asset.netChange;
          monthItem[asset.assetType] = value;
          monthTotal += value;
          if (value <= 0) {
            hasNegativeOrZero = true;
          }
        }
      });
      minValue = Math.min(minValue, monthTotal);
      maxValue = Math.max(maxValue, monthTotal);
      return monthItem;
    });

    // Adjust the maximum value to be slightly higher than the actual max
    const adjustedMax = maxValue * 1.1; // 10% higher than the actual maximum

    // For log scale, ensure the minimum is positive
    const adjustedMin = useLogScale ? Math.max(minValue, 0.1) : minValue;

    return {
      chartData,
      assetTypes: Array.from(assetTypesSet),
      yAxisDomain: [adjustedMin, adjustedMax] as [number, number],
      canUseLogScale: !hasNegativeOrZero && minValue > 0,
    };
  }, [filteredData, selectedAssets, useLogScale]);

  const formatLargeNumber = (value: number) => {
    if (Math.abs(value) >= 1e9) {
      return (value / 1e9).toFixed(2) + 'B';
    } else if (Math.abs(value) >= 1e6) {
      return (value / 1e6).toFixed(2) + 'M';
    } else if (Math.abs(value) >= 1e3) {
      return (value / 1e3).toFixed(2) + 'K';
    }
    return value.toFixed(2);
  };

  const formatYAxis = (value: number) => {
    if (Math.abs(value) >= 1e9) {
      return (value / 1e9).toFixed(1) + 'B';
    } else if (Math.abs(value) >= 1e6) {
      return (value / 1e6).toFixed(1) + 'M';
    } else if (Math.abs(value) >= 1e3) {
      return (value / 1e3).toFixed(1) + 'K';
    }
    return value.toFixed(1);
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    const monthData = filteredData.find((item) => item.month === label);

    return (
      <div className='rounded-lg bg-background p-2 shadow-md border border-border'>
        <p className='font-bold'>{`Month: ${label}`}</p>
        {payload.map((entry: any, index: number) => {
          const asset = monthData?.assets.find((a) => a.assetType === entry.name);
          if (!asset) return null;

          return (
            <div key={`item-${index}`} className='mt-2'>
              <p style={{ color: entry.color }}>{`${entry.name}: ${formatLargeNumber(entry.value)}`}</p>
              <p className='text-sm' style={{ color: asset.changeFromPreviousMonth >= 0 ? 'green' : 'red' }}>
                Change: {formatLargeNumber(asset.changeFromPreviousMonth)}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  const handleLegendClick = (e: any) => {
    const assetType = e.dataKey;
    setSelectedAssets((prev) =>
      prev.includes(assetType) ? prev.filter((a) => a !== assetType) : [...prev, assetType],
    );
  };

  if (!chartData.length) {
    return <Card className='w-full p-6'>No data available for the selected period.</Card>;
  }

  const actualScale = useLogScale && canUseLogScale ? 'log' : 'linear';

  return (
    <Card className='w-full'>
      <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
        <div className='space-y-1'>
          <CardTitle className='text-2xl font-bold'>Asset Quantity Change</CardTitle>
          <CardDescription>Track changes in asset quantities over time</CardDescription>
        </div>
        <div className='flex items-center space-x-4'>
          <Select value={selectedPeriod} onValueChange={(value: Period) => setSelectedPeriod(value)}>
            <SelectTrigger className='w-[180px]'>
              <SelectValue placeholder='Select period' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value='all'>All Time</SelectItem>
              <SelectItem value='lastMonth'>Last Month</SelectItem>
              <SelectItem value='lastQuarter'>Last Quarter</SelectItem>
              <SelectItem value='lastYear'>Last Year</SelectItem>
            </SelectContent>
          </Select>
          <div className='flex items-center space-x-2'>
            <Switch id='log-scale' checked={useLogScale} onCheckedChange={setUseLogScale} />
            <Label htmlFor='log-scale'>Log Scale</Label>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width='100%' height={400}>
          <AreaChart data={chartData}>
            <defs>
              {assetTypes.map((asset, index) => (
                <linearGradient key={asset} id={`color${asset}`} x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor={COLORS[index % COLORS.length]} stopOpacity={0.8} />
                  <stop offset='95%' stopColor={COLORS[index % COLORS.length]} stopOpacity={0.2} />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='month' />
            <YAxis scale={actualScale} domain={yAxisDomain} tickFormatter={formatYAxis} allowDataOverflow />
            <Tooltip content={<CustomTooltip />} />
            <Legend onClick={handleLegendClick} />
            {assetTypes.map((asset, index) => (
              <Area
                key={asset}
                type='monotone'
                dataKey={asset}
                stroke={COLORS[index % COLORS.length]}
                fillOpacity={1}
                fill={`url(#color${asset})`}
                hide={selectedAssets.includes(asset)}
                stackId='1'
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
