import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';
import { useGetDistinctContractNames } from '../../../../../hooks';

const convertToRowFn: ConvertToRowFn<string> = (data) => ({
  rowData: { value: data.toLowerCase(), label: data },
  isSelected: false,
});

const filterRowsFn: FilterFn<string> = (searchTerm) => (data) => data.toLowerCase().includes(searchTerm.toLowerCase());

export const useTransactionMemoConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> => {
  return useMemo(
    () => ({
      heading: 'Transaction Memo',
      data: [],
      filterFn: filterRowsFn,
      convertToRowFn,
      Row: TransactionMemoConditionSelectorRow,
    }),
    [],
  );
};

export const TransactionMemoConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.TRANSACTION_MEMO,
          operator: 'stringContains',
          value: '',
        })
      }
    />
  );
};

export const TransactionMemoConditionsMenu = () => {
  const { data = [] } = useGetDistinctContractNames();
  const conditionsData = useFilteredConditions(data, filterRowsFn, convertToRowFn);
  return (
    <ConditionsGroup label='Transaction Memo'>
      {conditionsData.length === 0 && <div className='text-center'>No contracts to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <TransactionMemoConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
