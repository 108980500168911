import React from 'react';
import { SidebarAnim, SidebarAnimationParent } from '../../atoms/Sidebar';
import { CreateSourceSidebar } from '../../Sources';

export const SourcesSidebar = ({ showCreateSource, setShowCreateSource }) => {
  return (
    <SidebarAnimationParent>
      {showCreateSource && (
        <SidebarAnim>
          <CreateSourceSidebar
            onClose={() => {
              setShowCreateSource(false);
            }}
          />
        </SidebarAnim>
      )}
    </SidebarAnimationParent>
  );
};

export default SourcesSidebar;
