import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const moveConditionInConditionGroup = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    const fromConditionGroupId = draft.conditionGroupIds.find((id) =>
      draft.conditionGroups[id].childIds.includes(action.payload.id),
    );
    if (!fromConditionGroupId) return;

    draft.conditionGroups[fromConditionGroupId].childIds = draft.conditionGroups[fromConditionGroupId].childIds.filter(
      (id) => id !== action.payload.id,
    );
    draft.conditionGroups[action.payload.toConditionGroup].childIds.splice(action.payload.at, 0, action.payload.id);
  });
