import React from 'react';
import { ModalContent } from 'ui';

export const SelectPaymentMode = ({ onClose, onNext }) => {
  return (
    <ModalContent onClose={onClose} actions={[{ label: 'Confirm', onClick: onNext }]}>
      SelectPaymentMode
    </ModalContent>
  );
};
