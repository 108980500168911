import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const StyledTrigger = styled(DropdownMenu.Trigger, {
  all: 'unset',
  width: 'fit-content',
  height: 'fit-content',
});

export const StyledContent = styled(DropdownMenu.Content, {
  width: 280,
  borderRadius: 8,
  boxShadow: '4px 5px 10px 0px #bbbbbb2e',
  backgroundColor: 'White',
  zIndex: 250,
});
export const StyledItem = styled(DropdownMenu.Item, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 12px',
  transition: '300ms',
  '&[data-highlighted]': { backgroundColor: '#EEEEEE', cursor: 'pointer' },
});
export const StyledSubTrigger = styled(DropdownMenu.SubTrigger, {
  all: 'unset',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 12px',
  transition: '300ms',
  '&[data-highlighted]': { backgroundColor: '#EEEEEE', cursor: 'pointer' },
});
export const StyledSubContent = styled(DropdownMenu.SubContent, {
  width: 280,
  backgroundColor: 'White',
  borderRadius: 8,
  boxShadow: '4px 5px 10px 0px #BBBBBB',
  zIndex: 250,
});
