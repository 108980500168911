import { WalletFilterRow } from '../../../../context';
import { FilterRowState } from '../types';
import { WALLET_TYPE } from 'services/http/response.types';

export const extraFiltersForWalletsFn = (
  state: (FilterRowState<WalletFilterRow> | null)[],
  extraFilters: { isInternal: boolean; isExternal: boolean; isTagged: boolean },
) => {
  if (!extraFilters.isInternal && !extraFilters.isExternal && !extraFilters.isTagged) return state;

  const matches = state.map((source) => {
    if (!source) return null;
    if (extraFilters.isInternal && source.walletType === WALLET_TYPE.INTERNAL) return source;
    if (extraFilters.isExternal && source.walletType === WALLET_TYPE.EXTERNAL) return source;
    if (source.tags && extraFilters.isTagged && source.tags.length > 0) return source;
    return null;
  });

  return matches;
};
