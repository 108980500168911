export const TemplateTableHeader = [
  {
    header: 'Order',
    key: 'Order',
  },
  {
    header: 'Legal entities',
    key: 'Legal entities',
  },
  {
    header: 'Ledger account',
    key: 'Ledger account',
  },
  {
    header: 'Debit',
    key: 'Debit',
  },
  {
    header: 'Credit ',
    key: 'Credit',
  },
];

export const RuleTableHeader = [
  {
    header: 'Name',
    key: 'name',
    textAligned: 'text-left',
  },
  {
    header: 'Legal entity(s)',
    key: 'legalEntities',
    textAligned: 'text-left',
  },
  {
    header: 'Updated',
    key: 'updated',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const TransactionsHeaderData = [
  {
    header: 'ID',
    key: 'sequenceNumber',
    textAligned: 'text-left',
  },
  {
    header: 'Legal Entity',
    key: 'legalEntityId',
    textAligned: 'text-left',
    defaultSelected: false,
  },
  {
    header: 'Transaction Hash',
    key: 'transactionHash',
    textAligned: 'text-left',
    defaultSelected: false,
  },
  {
    header: 'Category',
    key: 'classification',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'transactionDirection',
    textAligned: 'text-left',
  },
  {
    header: 'Asset',
    key: 'transactionAssets',
    textAligned: 'text-left',
  },
  {
    header: 'From',
    key: 'fromAddress',
    textAligned: 'text-left',
  },
  {
    header: 'To',
    key: 'toAddress',
    textAligned: 'text-left',
  },
  {
    header: 'Journal',
    key: 'journalEntry',
    textAligned: 'text-left',
  },
  {
    header: 'Journal Entry Template',
    key: 'journalEntryTemplate',
    textAligned: 'text-left',
    defaultSelected: false,
  },
  {
    header: 'Transaction Memo',
    key: 'memo',
    textAligned: 'text-left',
    defaultSelected: false,
  },
  {
    header: 'Gross',
    key: 'grossAmount',
    textAligned: 'text-right',
  },
  {
    header: 'Net',
    key: 'netAmount',
    textAligned: 'text-right',
  },
  {
    header: 'Fee',
    key: 'fee',
    textAligned: 'text-right',
  },
];

export const UnaccountedTransactionsHeaderData = [
  {
    header: 'ID',
    key: 'sequenceNumber',
    textAligned: 'text-left',
  },
  {
    header: 'Category',
    key: 'classification',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'transactionDirection',
    textAligned: 'text-left',
  },
  {
    header: 'Asset',
    key: 'transactionAssets',
    textAligned: 'text-left',
  },
  {
    header: 'Gross',
    key: 'grossAmount',
    textAligned: 'text-right',
  },
];

export const SpamTransactionsHeaderData = [
  {
    header: 'ID',
    key: 'sequenceNumber',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'transactionDirection',
    textAligned: 'text-left',
  },
  {
    header: 'Asset',
    key: 'transactionAssets',
    textAligned: 'text-left',
  },
  {
    header: 'From',
    key: 'fromAddress',
    textAligned: 'text-left',
  },
  {
    header: 'To',
    key: 'toAddress',
    textAligned: 'text-left',
  },
  {
    header: 'Gross',
    key: 'grossAmount',
    textAligned: 'text-right',
  },
  {
    header: 'Net',
    key: 'netAmount',
    textAligned: 'text-right',
  },
  {
    header: 'Fee',
    key: 'fee',
    textAligned: 'text-right',
  },
];

export const AssetHeadersData = [
  {
    header: 'Asset',
    key: 'assetType',
    textAligned: 'text-left',
  },
  {
    header: 'Cost Basis',
    key: 'costBasis',
    textAligned: 'text-right',
  },
  {
    header: 'Impaired Cost Basis',
    key: 'impairedCostBasis',
    textAligned: 'text-right',
  },
  {
    header: 'Remaining Qty',
    key: 'quantity_remainingQuantity',
    textAligned: 'text-right',
  },
  {
    header: 'Current Value',
    key: 'currentValue_gainLossPercentage',
    textAligned: 'text-right',
  },
];

export const SourcesTableHeader = [
  {
    header: 'Source',
    key: 'sourceInfo',
    textAligned: 'text-left',
  },
  {
    header: 'Status',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Address',
    key: 'address',
    textAligned: 'text-left',
  },
  {
    header: 'Legal entity',
    key: 'legalEntityId',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
  {
    header: '',
    key: 'isGroup',
  },
];

export const JournalsTableHeader = [
  {
    header: 'Name',
    key: 'Name',
  },
  {
    header: 'Chain',
    key: 'Chain',
  },
  {
    header: 'Category',
    key: 'Category',
  },
  {
    header: 'Alias',
    key: 'Alias',
  },
  {
    header: 'Address',
    key: 'Address',
  },
  {
    header: 'Legal entity',
    key: 'Legal entity',
  },
  {
    header: 'Balance',
    key: 'Balance',
  },
  {
    header: 'Created date',
    key: 'TransactionDate',
  },
];

export const JournalEntryLinesTableHeader = [
  {
    header: 'Journal',
    key: 'journal',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'type',
    textAligned: 'text-left',
  },
  {
    header: 'Transaction',
    key: 'transaction',
    textAligned: 'text-left',
  },
  {
    header: 'Amount',
    key: 'amount',
    textAligned: 'text-right',
  },
];

export const AccountPostingRulesHeader = [
  {
    header: 'Name',
    key: 'name',
    textAligned: 'text-left',
  },
  {
    header: 'Updated at',
    key: 'updatedAt',
    textAligned: 'text-left',
  },
  {
    header: 'Created by',
    key: 'createdBy',
    textAligned: 'text-left',
  },
];

export const invoicesHeader = [
  {
    header: 'Invoice',
    key: 'invoiceNumberAndIssueDate',
    textAligned: 'text-left',
  },
  {
    header: 'Status',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Due date',
    key: 'dueDate',
    textAligned: 'text-left',
  },
  {
    header: 'Payment method',
    key: 'paymentMethod',
    textAligned: 'text-left',
  },
  {
    header: 'Total',
    key: 'totalAmount',
    textAligned: 'text-left',
  },
  {
    header: 'Links',
    key: 'links',
    textAligned: 'text-left',
  },
];

export const templateLinesHeader = [
  {
    header: 'Ledger Account',
    key: 'ledgerAccount',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'creditOrDebit',
    textAligned: 'text-left',
  },
  {
    header: 'Allocation',
    key: 'allocation',
    textAligned: 'text-right',
  },
  {
    header: 'Amount Type',
    key: 'amountType',
    textAligned: 'text-left',
  },
  {
    header: 'Tags',
    key: 'tags',
    textAligned: 'text-left',
  },
  {
    header: 'Memo',
    key: 'memo',
    textAligned: 'text-left',
  },
];

export const journalEntryTemplatesHeader = [
  {
    header: 'Name',
    key: 'name',
    textAligned: 'text-left',
  },
  {
    header: 'Auto post',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Legal entity',
    key: 'legalEntityId',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const journalEntryLines = [
  {
    header: 'Company',
    key: 'company',
    textAligned: 'text-left',
  },
  {
    header: 'Ledger account',
    key: 'ledgerAccount',
  },
  {
    header: 'Amount',
    key: 'amount',
    textAligned: 'text-right',
  },
  {
    header: 'Type',
    key: 'creditOrDebit',
    textAligned: 'text-left',
  },
  {
    header: 'Legal Entity',
    key: 'legalEntityId',
    textAligned: 'text-left',
  },
  {
    header: 'Tags',
    key: 'tags',
  },
];

export const configureEntities = [
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
  {
    header: 'null',
    key: 'null',
  },
];

export const OrganizationMembersTableHeader = [
  {
    header: 'Team member',
    key: 'teamMember',
    textAligned: 'text-left',
  },
  {
    header: 'Status',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Role',
    key: 'role',
    textAligned: 'text-left',
  },
  {
    header: 'Updated',
    key: 'updated',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const journalEntryTableHeader = [
  {
    header: 'Seq ID',
    key: 'journalSequenceNumber',
    textAligned: 'text-left',
  },
  {
    header: 'Status',
    key: 'status',
  },
  {
    header: 'Total Credit',
    key: 'credits',
  },
  {
    header: 'Total Debit',
    key: 'debits',
  },
  {
    header: 'Created date',
    key: 'createdAt',
  },
  {
    header: 'Operational transaction ID',
    key: '_id',
  },
  {
    header: 'Created by',
    key: 'createdBy',
  },
  {
    header: 'Synced',
    key: 'isSync',
    textAligned: 'text-left',
  },
];

export const legalEntitiesTableHeader = [
  {
    header: 'Name',
    key: 'entityName',
    textAligned: 'text-left',
  },
  {
    header: 'Currency',
    key: 'currency',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
  {
    header: 'Updated',
    key: 'updated',
    textAligned: 'text-left',
  },
];

export const landingLegalEntitiesTableHeader = [
  {
    header: 'Name',
    key: 'entityName',
    textAligned: 'text-left',
  },
  {
    header: 'Currency',
    key: 'currency',
    textAligned: 'text-left',
  },
  {
    header: 'Updated',
    key: 'updated',
    textAligned: 'text-left',
  },
];

export const jouranlEntriesTableHeader = [
  {
    header: 'ID',
    key: 'journalId',
    textAligned: 'text-left',
  },
  {
    header: 'Legal Entity',
    key: 'legalEntityId',
    textAligned: 'text-left',
    defaultSelected: false,
  },
  {
    header: 'Status',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Journal Entry Template',
    key: 'journalEntryTemplateId',
    textAligned: 'text-left',
  },
  {
    header: 'Transaction',
    key: 'transactionId',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
  {
    header: 'Credit',
    key: 'credits',
    textAligned: 'text-right',
  },
  {
    header: 'Debit',
    key: 'debits',
    textAligned: 'text-right',
  },
  {
    header: 'Synced',
    key: 'isSync',
    textAligned: 'text-left',
  },
];

export const TagsHeader = [
  {
    header: 'Tag',
    key: 'tag',
    textAligned: 'text-left',
  },
  {
    header: 'Status',
    key: 'status',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const LedgerAccountHeader = [
  {
    header: 'Account',
    key: 'account',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'ledgerAccountType',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const LedgerAccountResequencingHeader = [
  {
    header: 'Account',
    key: 'account',
    textAligned: 'text-left',
  },
  {
    header: 'Old Seq #',
    key: 'oldSequence',
    textAligned: 'text-left',
  },
  {
    header: 'New Seq #',
    key: 'newSequence',
    textAligned: 'text-left',
  },
];

export const impairmentRuleHeader = [
  {
    header: 'Name',
    key: 'name',
    textAligned: 'text-left',
  },
  {
    header: 'Description',
    key: 'description',
    textAligned: 'text-left',
  },
  {
    header: 'Updated',
    key: 'updated',
    textAligned: 'text-left',
  },
  {
    header: 'Created',
    key: 'created',
    textAligned: 'text-left',
  },
];

export const assetsDetailsHeader = [
  {
    header: 'Journal Entry',
    key: 'journalEntrySequenceNumber',
  },
  {
    header: 'Ledger Account',
    key: 'ledgerAccountName',
  },
  {
    header: 'Direction',
    key: 'creditOrDebit',
  },
  {
    header: 'Amount',
    key: 'amount',
  },
  {
    header: 'Disposed Quantity',
    key: 'amountDisposed',
  },
];

export const assetImpairmentDetailsHeaders = [
  {
    header: 'Impairment Date',
    key: 'createdAt',
    textAligned: 'text-left',
  },
  {
    header: 'Impaired Cost Basis',
    key: 'costBasis',
  },
  {
    header: 'Quantity',
    key: 'impairmentQuantity',
  },
  {
    header: 'Journal Entry',
    key: 'journalEntrySequenceNumber',
  },
  {
    header: 'Impairment Rule',
    key: 'impairmentRuleName',
  },
];
export const InvoiceLineItemsTableHeader = [
  {
    header: 'Item',
    key: 'item',
    textAligned: 'text-left',
  },
  {
    header: 'Quantity',
    key: 'quantity',
    textAligned: 'text-left',
  },
  {
    header: 'Unit price',
    key: 'unitPrice',
    textAligned: 'text-left',
  },
  {
    header: 'Amount',
    key: 'amount',
    textAligned: 'text-right',
  },
];

export const scheduleOfDispositionTableHeader = [
  {
    header: 'Token',
    key: 'token',
    textAligned: 'text-left',
  },
  {
    header: 'Asset record',
    key: 'assetRecord',
    textAligned: 'text-left',
  },
  {
    header: 'Sale date',
    key: 'saleDate',
    textAligned: 'text-left',
  },
  {
    header: 'Qty',
    key: 'qty',
    textAligned: 'text-right',
  },
  {
    header: 'Acquisition Price',
    key: 'acquisitionPrice',
    textAligned: 'text-left',
  },
  {
    header: 'Cost Basis',
    key: 'costBasis',
    textAligned: 'text-left',
  },
];

export const assetTaxLotsTableHeader = [
  {
    header: 'Token',
    key: 'token',
    textAligned: 'text-left',
  },
  {
    header: 'Acquired date',
    key: 'acquiredDate',
    textAligned: 'text-left',
  },
  {
    header: 'Asset record',
    key: 'assetRecord',
    textAligned: 'text-left',
  },
  {
    header: 'Original qty',
    key: 'originalQty',
    textAligned: 'text-right',
  },
  {
    header: 'Total cost basis',
    key: 'totalCostBasis',
    textAligned: 'text-left',
  },
  {
    header: 'Disposed qty',
    key: 'disposedQty',
    textAligned: 'text-right',
  },
  {
    header: 'Remaining qty',
    key: 'remainingQty',
    textAligned: 'text-right',
  },
];

export const realizedGainOrLoseTableHeader = [
  {
    header: 'Token',
    key: 'token',
    textAligned: 'text-left',
  },
  {
    header: 'Asset record',
    key: 'assetRecord',
    textAligned: 'text-left',
  },
  {
    header: 'Acquisition Entry',
    key: 'acquisitionEntry',
    textAligned: 'text-left',
  },
  {
    header: 'Disposal Entries',
    key: 'disposalEntries',
    textAligned: 'text-left',
  },
  {
    header: 'Acquisition Date',
    key: 'acquisitionDate',
    textAligned: 'text-left',
  },
  {
    header: 'Sale date',
    key: 'saleDate',
    textAligned: 'text-left',
  },
  {
    header: 'Qty',
    key: 'qty',
    textAligned: 'text-right',
  },
  {
    header: 'Cost Basis',
    key: 'costBasis',
    textAligned: 'text-left',
  },
  {
    header: 'Proceeds',
    key: 'proceeds',
    textAligned: 'text-right',
  },
  {
    header: 'Total realized return',
    key: 'totalRealizedReturn',
    textAligned: 'text-right',
  },
  {
    header: 'Long term (>1 year)',
    key: 'longTerm',
    textAligned: 'text-right',
  },
  {
    header: 'Short term (<1 year)',
    key: 'shortTerm',
    textAligned: 'text-right',
  },
];

export const closingPositionsTableHeader = [
  {
    header: 'Token',
    key: 'token',
    textAligned: 'text-left',
  },
  {
    header: 'Date',
    key: 'date',
    textAligned: 'text-left',
  },
  {
    header: 'Qty',
    key: 'qty',
    textAligned: 'text-right',
  },
  {
    header: 'Cost Basis',
    key: 'costBasis',
    textAligned: 'text-left',
  },
  {
    header: 'Market value',
    key: 'marketValue',
    textAligned: 'text-right',
  },
  {
    header: 'Unrealized return',
    key: 'unrealizedReturn',
    textAligned: 'text-right',
  },
];

export const accountingTransactionsTableHeader = [
  {
    header: 'Source',
    key: 'sourceInfo',
    textAligned: 'text-left',
  },
  {
    header: 'Date',
    key: 'date',
    textAligned: 'text-left',
  },
  {
    header: 'Category',
    key: 'category',
    textAligned: 'text-left',
  },
  {
    header: 'Type',
    key: 'type',
    textAligned: 'text-left',
  },
  {
    header: 'Asset',
    key: 'transactionAssets',
    textAligned: 'text-left',
  },
  {
    header: 'Currency',
    key: 'soldCurrency',
    textAligned: 'text-left',
  },
  {
    header: 'Qty',
    key: 'soldQty',
    textAligned: 'text-right',
  },
  {
    header: 'Cost basis',
    key: 'soldCostBasis',
    textAligned: 'text-right',
  },
  {
    header: 'Value',
    key: 'soldValue',
    textAligned: 'text-right',
  },
  {
    header: 'Currency',
    key: 'receivedCurrency',
    textAligned: 'text-left',
  },
  {
    header: 'Qty',
    key: 'receivedQty',
    textAligned: 'text-right',
  },
  {
    header: 'Cost basis',
    key: 'receivedCostBasis',
    textAligned: 'text-right',
  },
  {
    header: 'Value',
    key: 'receiveValue',
    textAligned: 'text-right',
  },
  {
    header: 'Currency',
    key: 'freeCurrency',
    textAligned: 'text-left',
  },
  {
    header: 'Amount',
    key: 'feeQty',
    textAligned: 'text-right',
  },
];

