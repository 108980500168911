import { createGlobalStyle } from 'styled-components';
import colors from 'tailwindcss/colors';

export const RadixSliderGlobalStyle = createGlobalStyle`
.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
}
.SliderRoot[data-orientation='horizontal'] {
  height: 20px;
}
.SliderRoot[data-orientation='vertical'] {
  flex-direction: column;
  width: 20px;
  height: 100px;
}

.SliderTrack {
  background-color: #e1e1e1;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
}
.SliderTrack[data-orientation='horizontal'] {
  height: 3px;
}
.SliderTrack[data-orientation='vertical'] {
  width: 3px;
}

.SliderRange {
  position: absolute;
  background-color: #000;
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px ${colors.gray[900]};
  border-radius: 10px;
}
.SliderThumb:hover {
  background-color: ${colors.black[300]};
}
.SliderThumb:focus {
  outline: none;
  box-shadow: 0 0 0 5px ${colors.black[800]}
}

`;
