import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';
import { Exchanges } from '../../../../../constants';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu value={{ label: 'Exchange', value: 'Exchange' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        isOnSidepanel
        value={{ label: condition.value, value: condition.value }}
        align='end'
        disabled={isDisabled}
        options={Exchanges.map((item) => ({ label: item, value: item }))}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value: value,
          });
        }}
      />
    </>
  );
};

export const ExchangeRuleCondition = () => (
  <ConditionRow facts={[FACT.EXCHANGE]}>
    <Row />
  </ConditionRow>
);
