import React, { useState } from 'react';
import { Flex, Label, StyledSwitch, StyledThumb } from '../../../styles/SwitchGroupStyle';
import { SwitchGroupProps } from './types';

export function SwitchGroup({
  switchGroupItems = [{ label: 'Label', checked: false, labelLeft: true }],
  clickFn,
  className,
  switchStyle,
}: SwitchGroupProps) {
  const [values, setValues] = useState(switchGroupItems);
  // console.log(values);
  return (
    <div className={`flex flex-col items-center justify-center gap-4 ${className}`}>
      {values.map(({ label, checked, labelLeft = true }, parentIndex) => (
        <Flex key={label} className={`gap-4 ${switchStyle}`}>
          {!!label.length && labelLeft && <Label>{label}</Label>}

          <StyledSwitch
            checked={checked}
            onCheckedChange={(checkedState) => {
              const updatedValues = values.map((item, childIndex) => {
                if (parentIndex === childIndex) {
                  return {
                    ...item,
                    checked: !!checkedState, // convert to boolean
                  };
                }
                return {
                  ...item,
                };
              });
              setValues(updatedValues);
              clickFn && clickFn(updatedValues);
            }}
          >
            <StyledThumb />
          </StyledSwitch>
          {!!label.length && !labelLeft && <Label>{label}</Label>}
        </Flex>
      ))}
    </div>
  );
}

export default SwitchGroup;
