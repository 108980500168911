import { useEffect, useRef, useState } from 'react';
import autoAnimate from '@formkit/auto-animate';
import { HiChevronDown as ChevronDownIcon, HiXMark as XMarkIcon } from 'react-icons/hi2';
import { NewTaskManagerProps, ToastTask } from './types';
import { motion, AnimatePresence } from 'framer-motion';
import { TaskRow } from './TaskRow';

export const TaskManager = ({
  open,
  data,
  onCloseItem = console.log,
  closeTaskManager,
  'data-cy': dataCy,
}: NewTaskManagerProps) => {
  const [tasks, setTasks] = useState<ToastTask[]>([]);
  const [showTaskRows, setShowTaskRows] = useState(false);

  useEffect(() => {
    if (data) {
      const tasks = data.map((j) => ({
        description: j.description,
        isLoading: j.state === 'STARTED' || j.state === 'IN_PROGRESS',
        isError: j.state === 'JOB_FAILED',
        isSuccess: j.state === 'COMPLETED',
      })) as ToastTask[];
      setTasks(tasks);
    }
  }, [data]);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='fixed z-30 bottom-0 right-0 m-10'
        >
          <div
            className='bg-zinc-800 text-white rounded-lg font-medium overflow-hidden'
            style={{ width: 296 }}
            ref={parent}
            data-cy={`${dataCy}__taskManager_container`}
          >
            <div className='flex items-center p-4 pr-5'>
              <span className='flex-grow mr-2' data-cy={`${dataCy}__taskManager_status`}>
                {tasks.length} {tasks.length === 1 ? 'task is running' : 'tasks are running'}
              </span>
              <button
                className='mr-3.5 hover:text-gray-500'
                onClick={() => setShowTaskRows((prev) => !prev)}
                data-cy={`${dataCy}__taskManager_chevronButton`}
              >
                <ChevronDownIcon
                  className={`transition duration-500 ease-in-out w-5 h-5 ${showTaskRows ? '-rotate-180' : ''}`}
                />
              </button>
              <button
                onClick={closeTaskManager}
                className='hover:text-gray-500'
                data-cy={`${dataCy}__taskManager_closeButton`}
              >
                <XMarkIcon className='w-5 h-5' />
              </button>
            </div>

            {showTaskRows && (
              <div className={`transition-all duration-500 max-h-32 overflow-hidden ${showTaskRows ? '' : 'max-h-0 '}`}>
                <div className='max-h-32 overflow-auto divide-y divide-gray-700 border-t border-gray-700 bg-black'>
                  {tasks.map((task, i) => (
                    <TaskRow
                      description={task.description}
                      isLoading={task.isLoading}
                      onClose={() => onCloseItem(i)}
                      data-cy={task['data-cy'] ?? `${dataCy}_${i}__taskManager_row`}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
