import { AnimatedStats } from '../animated-stats';
import { classNames } from '../utils';
import { AutomationScorecardItemProps } from './types';

export const AutomationScorecardItem = ({
  stats,
  description,
  variant = 'medium',
  suffix,
}: AutomationScorecardItemProps) => (
  <div className='p-3'>
    <div className={classNames(variant === 'medium' ? 'text-4xl' : 'text-5xl', 'font-semibold mb-2')}>
      <AnimatedStats to={stats ?? 0} />
      {suffix && <span>{suffix}</span>}
    </div>
    <div className='text-gray-500 text-sm'>{description}</div>
  </div>
);
