import { Button } from 'ui';
import { MdChevronLeft } from 'react-icons/md';
import { useConditionSelectorContext } from './condition-selector-context';

export const ConditionSelectorMenuBackButton = () => {
  const { state, pop } = useConditionSelectorContext();
  if (!state.currentMenu) return null;
  return (
    <div className='my-1 mt-2 ml-2'>
      <Button
        label={state.currentMenu}
        leadingIcon={<MdChevronLeft />}
        onClick={pop}
        emphasis='low'
        disableRingOnFocus
      />
    </div>
  );
};
