import React, { useEffect, useState } from 'react';
import { DescriptiveLoader, Dialog, DialogContent } from 'ui';
import { ORGANIZATION_RULESET_JOB_STATUS, getRunAllRulesetsProcessStateDescriptorProps } from '../../process-states';
import { JOB_NAME, RunAccountPostingRuleSetsHandlerPayload } from 'services/http';
import { toast } from 'react-hot-toast';
import { TrackJobInDialog } from '../../job-progress';
import { deriveError } from '../../templates/utils';
import { RunAllRulesetsDialogForm } from '../RunAllRulesetsDialogForm';
import {
  useGetOrganizationRulesetConfig,
  useInvalidateQuery,
  useSession,
  useTriggerAllAccountPostingRuleSetsMutation,
  useUpsertOrganizationRulesetConfig,
} from '../../../hooks';
import { SetJobCadence } from './set-job-cadence';
import { CREATE_MODE, CreateModeTabs } from './create-mode-tabs';
import { ToggleJobAutorun } from './toggle-job-autorun';

export const ManageAllRulesetRunsModal = ({ open, onOpenChange }) => {
  const { organizationId } = useSession();
  const [error, setError] = useState<string>();
  const [backgroundJobState, setBackgroundJobState] = useState<ORGANIZATION_RULESET_JOB_STATUS>(
    ORGANIZATION_RULESET_JOB_STATUS.FORM,
  );

  const { mutateAsync } = useTriggerAllAccountPostingRuleSetsMutation();
  const onClose = () => {
    onOpenChange(false);
  };
  useEffect(() => {
    if (!open) {
      setBackgroundJobState(ORGANIZATION_RULESET_JOB_STATUS.FORM);
    }
  }, [open]);

  const onSubmit = async (params: Omit<RunAccountPostingRuleSetsHandlerPayload, 'ruleSetId'>) => {
    try {
      setBackgroundJobState(ORGANIZATION_RULESET_JOB_STATUS.PROCESSING);
      const response = await mutateAsync(params);
      if (response.data.message === 'no transaction found') {
        throw new Error('No transactions found in the selected accounting period');
      }
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };
  const { data: instance, isLoading } = useGetOrganizationRulesetConfig();
  const { mutateAsync: updateConfig, isLoading: isUpdating } = useUpsertOrganizationRulesetConfig();

  const { invalidateAccountPostingRulesetConfigs } = useInvalidateQuery();

  const onCronExpressionUpdate = async (cronExpression) => {
    try {
      await updateConfig({ organizationId, cronExpression });
      onOpenChange(false);
      toast.success('Settings saved');
      await invalidateAccountPostingRulesetConfigs();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const [createMode, setCreateMode] = useState<CREATE_MODE>(CREATE_MODE.MANUAL);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {open && backgroundJobState === ORGANIZATION_RULESET_JOB_STATUS.FORM ? (
        <DialogContent title='Schedule ruleset runs' onClose={onClose}>
          <ToggleJobAutorun
            jobConfigurationId={instance?._id}
            isPaused={instance?.isPaused}
            cronExpression={instance?.cronExpression}
          />
          <CreateModeTabs value={createMode} onChange={setCreateMode} />
          {createMode === CREATE_MODE.AUTOMATIC && (
            <SetJobCadence
              onSubmit={onCronExpressionUpdate}
              onCancel={() => onOpenChange(false)}
              isSubmitting={isUpdating}
              isLoading={isLoading}
              disabled={isLoading}
              defaultCronExpression={instance?.cronExpression}
            />
          )}
          {createMode === CREATE_MODE.MANUAL && (
            <RunAllRulesetsDialogForm
              onClose={() => onOpenChange(false)}
              organizationId={organizationId}
              onSubmit={onSubmit}
            />
          )}
        </DialogContent>
      ) : backgroundJobState === ORGANIZATION_RULESET_JOB_STATUS.PROCESSING ? (
        <TrackJobInDialog
          jobName={JOB_NAME.ORGANIZATION_RULESET_JOB}
          referenceId={organizationId}
          onClose={onClose}
          error={error}
        />
      ) : (
        <DialogContent onClose={onClose}>
          <div className='h-[200px] w-full flex items-center justify-center'>
            <DescriptiveLoader {...getRunAllRulesetsProcessStateDescriptorProps(backgroundJobState, error)} />
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
