import { useQuery } from '@tanstack/react-query';
import { ExtendedExchangeSource, getExchangeSources } from 'services/http/core';
import toast from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

const mapExchangeSources = (data: { sources: ExtendedExchangeSource[] }) => {
  const sources =
    data?.sources?.map((source) => ({
      ...source,
      legalEntityId: typeof source.legalEntityId === 'string' ? source.legalEntityId : source.legalEntityId._id,
      legalEntityEntityName: typeof source.legalEntityId === 'string' ? '' : source.legalEntityId.entityName,
    })) ?? [];

  return {
    ...data,
    sources,
  };
};

export const useGetExchangeSources = (type?: string, legalEntityIds: string[] = []) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.EXCHANGE_SOURCES, { type }),
    async ({ pageParam = 0 }) => {
      const response = await getExchangeSources({ organizationId, page: pageParam, legalEntityIds });
      return mapExchangeSources(response.data);
    },
    {
      enabled: !!organizationId,
      onError: async (error) => {
        await toast.error((error as any).message);
      },
      staleTime: 30000,
      cacheTime: 30000,
      refetchOnWindowFocus: false,
    },
  );
};
