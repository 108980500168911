import React from 'react';
import cronstrue from 'cronstrue';
import { MESSAGE_TYPES, Message } from 'ui';
import { useScheduleJobCallbacks } from '../../../hooks';

export const ToggleJobAutorun = ({
  jobConfigurationId,
  cronExpression,
  isPaused,
}: {
  jobConfigurationId?: string;
  cronExpression?: string;
  isPaused?: boolean;
}) => {
  const { onPause, onResume } = useScheduleJobCallbacks();

  if (!jobConfigurationId || !cronExpression) return null;
  return (
    <div className='my-4 mt-8'>
      <Message
        type={isPaused ? MESSAGE_TYPES.WARNING : MESSAGE_TYPES.INFO}
        text={
          <div>
            Next ruleset runs at {cronstrue.toString(cronExpression as string)}
            {isPaused ? ', but is paused.' : '.'}
          </div>
        }
        action={
          isPaused
            ? { label: 'Resume', onClick: () => onResume(jobConfigurationId) }
            : { label: 'Pause', onClick: () => onPause(jobConfigurationId) }
        }
      />
    </div>
  );
};
