import React, { useState } from 'react';
import { useCreateImpairmentRule as useCreateImpairmentRuleMutation } from '../../hooks/http/useImpairmentRules';
import { SidebarBody, SidebarFooter, SidebarTopBar, Sidebar, SidebarRoot } from '../atoms/Sidebar';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { deriveError } from '../templates/utils';
import { Button, DescriptiveLoader } from 'ui';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import {
  CreateImpairmentRuleFormElements,
  CreateImpairmentRuleFormState,
} from '../form-elements/CreateImpairmentRuleFormElements';
import { useInvalidateQuery } from '../../hooks';
import {
  CREATE_IMPAIRMENT_RULE_PROCESS_STATE,
  getCreateImpairmentRuleProcessStateDescriptorProps,
} from '../process-states';
import { isCreateImpairmentRuleFormStateValid } from '../form-elements/isCreateImpairmentRuleFormStateValid';
import { RULE_CONFIGURATOR_TYPE, RuleConfiguratorState } from '../rules/configurator/types';
import {
  getInitialStateForRuleConfigurator,
  RulesConfigurator,
  parseRuleConfiguratorState,
} from '../rules/configurator';

const defaultFormState: CreateImpairmentRuleFormState = {
  name: '',
  description: '',
};

export const useCreateImpairmentRule = () => {
  const { userId, organizationId } = useSession();
  const { mutateAsync: create, isLoading: isCreatingImpairmentRule } = useCreateImpairmentRuleMutation();
  const [step, setStep] = useState<CREATE_IMPAIRMENT_RULE_PROCESS_STATE>(CREATE_IMPAIRMENT_RULE_PROCESS_STATE.FORM);
  const { invalidateWallets } = useInvalidateQuery();

  const createImpairmentRule = async (formState: CreateImpairmentRuleFormState) => {
    if (!userId || !organizationId) return toast.error('Something wrong with the session.');

    if (isCreateImpairmentRuleFormStateValid(formState))
      try {
        setStep(CREATE_IMPAIRMENT_RULE_PROCESS_STATE.CREATING_IMPAIRMENT_RULE);
        await create({
          impairmentRule: {
            ...formState,
            journalEntryTemplateId: formState.journalEntryTemplateId as string,
            organizationId,
            userId,
          },
        });
        setStep(CREATE_IMPAIRMENT_RULE_PROCESS_STATE.IMPAIRMENT_RULE_CREATED);

        await invalidateWallets();
      } catch (error) {
        setStep(CREATE_IMPAIRMENT_RULE_PROCESS_STATE.COULD_NOT_CREATE_IMPAIRMENT_RULE);
        console.log('CreateWalletForm error:', error);
        throw error;
      }
  };

  return {
    createImpairmentRule,
    processState: step,
    setProcessState: setStep,
    isCreatingImpairmentRule,
  };
};

export const CreateImpairmentRuleSidebar = ({ onClose }) => {
  const { organizationId, userId } = useSession();

  const [error, setError] = useState<string>();

  const [formState, setFormState] = useState<CreateImpairmentRuleFormState>(defaultFormState);

  const { createImpairmentRule, isCreatingImpairmentRule, processState, setProcessState } = useCreateImpairmentRule();

  const { invalidateImpairmentRules } = useInvalidateQuery();
  const [ruleConfiguratorState, setRuleConfiguratorState] = useState<RuleConfiguratorState>(
    getInitialStateForRuleConfigurator(RULE_CONFIGURATOR_TYPE.IMPAIRMENT),
  );

  const onFormSubmit = async () => {
    try {
      const payload: any = {
        ...formState,
        journalEntryTemplateId: formState.journalEntryTemplateId,
        topLevelCondition: parseRuleConfiguratorState(ruleConfiguratorState),
        configuratorState: ruleConfiguratorState,
        organizationId,
        userId,
      };

      await createImpairmentRule(payload);
      await invalidateImpairmentRules();
    } catch (error) {
      console.log('CreateImpairmentForm error:', error);
      setError(deriveError(error));
      toast.error(deriveError(error));
    }
  };
  const dataCy = 'createImpairmentRule';
  return (
    <SidebarRoot>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar onClose={onClose} />
        <SidebarHeader data-cy={dataCy} title='Create rule' />
        <SidebarBody>
          {processState === CREATE_IMPAIRMENT_RULE_PROCESS_STATE.FORM ? (
            <>
              <SidebarSectionHeader title='Details' />
              <SidebarSection numberOfColumns={1}>
                <CreateImpairmentRuleFormElements formState={formState} setFormState={setFormState} />
              </SidebarSection>
              <SidebarSectionHeader title='Rules' />
              <SidebarSection numberOfColumns={1}>
                <RulesConfigurator
                  configuratorType={RULE_CONFIGURATOR_TYPE.IMPAIRMENT}
                  defaultValue={ruleConfiguratorState}
                  onChange={setRuleConfiguratorState}
                />
              </SidebarSection>
            </>
          ) : (
            <div className='h-[300px] w-full flex items-center justify-center'>
              <DescriptiveLoader {...getCreateImpairmentRuleProcessStateDescriptorProps(processState, error)} />
            </div>
          )}
        </SidebarBody>
        {processState === CREATE_IMPAIRMENT_RULE_PROCESS_STATE.FORM ? (
          <SidebarFooter
            primaryBtn={
              <Button
                data-cy={`${dataCy}__saveButton`}
                onClick={onFormSubmit}
                isLoading={isCreatingImpairmentRule}
                label={'Save'}
              />
            }
            secondaryBtn={<Button onClick={onClose} emphasis='medium' label='Cancel' />}
          />
        ) : (
          <SidebarFooter
            primaryBtn={<Button data-cy={`${dataCy}__doneButton`} label='Done' emphasis='high' onClick={onClose} />}
            secondaryBtn={
              <Button
                label={
                  processState === CREATE_IMPAIRMENT_RULE_PROCESS_STATE.COULD_NOT_CREATE_IMPAIRMENT_RULE
                    ? 'Back'
                    : 'Add another rule'
                }
                emphasis='medium'
                onClick={() => {
                  if (processState !== CREATE_IMPAIRMENT_RULE_PROCESS_STATE.COULD_NOT_CREATE_IMPAIRMENT_RULE)
                    setFormState(defaultFormState);
                  setProcessState(CREATE_IMPAIRMENT_RULE_PROCESS_STATE.FORM);
                }}
              />
            }
          />
        )}
      </Sidebar>
    </SidebarRoot>
  );
};
