import { useState } from 'react';
import { Button, Checkbox } from 'ui';
import { CryptoIcon } from '../../../utils';

export const AssetTypesFilterDropdownRow = ({ label, selected, onSelectOnly, onChange }) => {
  const [showOnlyButton, setShowOnlyButton] = useState(false);
  return (
    <div
      className='px-4 py-2 cursor-pointer relative'
      onMouseEnter={() => setShowOnlyButton(true)}
      onMouseLeave={() => setShowOnlyButton(false)}
    >
      <Checkbox
        label={
          <div className='flex justify-start items-center gap-1.5'>
            {typeof label === 'string' && <CryptoIcon size='sm' symbol={label?.toLowerCase()} />}
            <p className='max-w-[calc(100%-40px)]'>{label}</p>
          </div>
        }
        isSelected={selected}
        onChange={onChange}
      />
      {showOnlyButton && (
        <div className='absolute top-2 right-4'>
          <Button
            label={<span className='text-blue-500'>Only</span>}
            variant='sm'
            emphasis='low'
            className='!rounded-full px-2 hover:bg-blue-50'
            disableRingOnFocus
            onClick={onSelectOnly}
          />
        </div>
      )}
    </div>
  );
};
