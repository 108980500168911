import { DESCRIPTIVE_LOADER_STATES } from 'ui';

export enum CREATE_SOURCE_PROCESS_STATE {
  FORM = 'form',
  CREATING_SOURCE = 'adding-wallet',
  COULD_NOT_CREATE_SOURCE = 'add-wallet-error',
  CREATING_PULL_TRANSACTIONS_BG_JOB = 'creating-pull-transactions-bg-job',
  COULD_NOT_CREATE_PULL_TRANSACTIONS_BG_JOB = 'could-not-create-pull-transactions-bg-job',
  PULLING_TRANSACTIONS = 'pulling-transactions',
  COULD_NOT_PULL_TRANSACTIONS = 'could-not-pull-transactions',
  TRANSACTIONS_PULLED = 'transactions-pulled',
  EXTERNAL_SOURCE_CREATED = 'external-source-created',
}

export const getAddWalletProcessStateDescriptorProps = (processState: CREATE_SOURCE_PROCESS_STATE, error?: string) => {
  switch (processState) {
    case CREATE_SOURCE_PROCESS_STATE.CREATING_SOURCE:
      return {
        title: 'Creating source',
        description: 'Please wait...',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_SOURCE:
      return {
        title: 'Failure',
        description: error ?? 'Could not create source',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case CREATE_SOURCE_PROCESS_STATE.CREATING_PULL_TRANSACTIONS_BG_JOB:
      return {
        title: 'Creating pull-transactions background job',
        description: 'Please wait...',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CREATE_SOURCE_PROCESS_STATE.COULD_NOT_CREATE_PULL_TRANSACTIONS_BG_JOB:
      return {
        title: 'Failure',
        description: error ?? 'Could not create pull-transactions background job',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case CREATE_SOURCE_PROCESS_STATE.PULLING_TRANSACTIONS:
      return {
        title: 'Pulling transactions',
        description: 'You can close this, the task will continue in background',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CREATE_SOURCE_PROCESS_STATE.COULD_NOT_PULL_TRANSACTIONS:
      return {
        title: 'Failure',
        description: error ?? 'Could not pull transactions',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case CREATE_SOURCE_PROCESS_STATE.TRANSACTIONS_PULLED:
      return {
        title: 'Success',
        description: 'All transactions pulled successfully',
        status: DESCRIPTIVE_LOADER_STATES.SUCCESS,
      };
    case CREATE_SOURCE_PROCESS_STATE.EXTERNAL_SOURCE_CREATED:
      return {
        title: 'Success',
        description: 'External Source Created',
        status: DESCRIPTIVE_LOADER_STATES.SUCCESS,
      };

    default:
      return {
        title: 'unreachable',
        description: 'unreachable',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
  }
};
