export const inputTagStyle = {
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    fontWeight: 500,
    fontSize: 16,
    background: state.isSelected ? '#E2E2E2' : 'white',
    margin: 'auto',
    borderRadius: 6,
    color: 'black',
    marginBottom: 8,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: 'rgb(17 17 17 / .08)',
    },
  }),
  menu: () => ({
    display: 'none',
  }),
  menuList: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    transition: '0.3s',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.backgroundColor ? data.backgroundColor : '#1111110A',
    border: '1px solid rgba(17, 17, 17, 0.1)',
    borderRadius: 4,
    color: '#111111cc',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    fontWeight: 600,
  }),
  control: (provided, state) => ({
    ...provided,
    height: 48,
    borderRadius: 8,
    borderWidth: 2,
    boxShadow: state.isFocused ? '0px 0px 0px 1px black' : 'none',
    borderColor: state.isFocused ? 'black' : '#E8E8E8',
    paddingLeft: 5,
    overflow: 'hidden',
    overflowX: 'auto',
    '&:hover': {
      borderColor: '',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    flexWrap: 'nowrap',
    minWidth: 'fit-content',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    padding: '3px',
    '&:hover': {
      backgroundColor: 'rgb(75, 85, 99, 0.2)',
    },
  }),
};
