import axios from 'axios';
import { SERVER_URL_JOB_QUEUE } from '../../config';

export const getRuleMetrics = async (data) =>
  axios.get(
    `${SERVER_URL_JOB_QUEUE}/get-rule-metrics?organizationId=${data.organizationId}&ruleId=${data.ruleId}&page=${data.pageParam}`,
  );

export const getBestPerformingRules = async (data) =>
  axios.get(`${SERVER_URL_JOB_QUEUE}/get-best-performing-rules?organizationId=${data.organizationId}`);

export type ComputeRuleMetricsPayload = {
  ruleId: string;
  isOneTime: boolean;
  legalEntityIds?: string[];
  accountingPeriodStartDateUTCs: string[];
  accountingPeriodIds?: string[];
  topLevelCondition?: any;
};
export const computeRuleMetrics = async (data: ComputeRuleMetricsPayload) =>
  axios.post(`${SERVER_URL_JOB_QUEUE}/compute-rule-metrics`, data);

export const deleteOneTimeRuleMetrics = async (data) =>
  axios.delete(
    `${SERVER_URL_JOB_QUEUE}/delete-one-time-rule-metrics?ruleId=${data.ruleId}&organizationId=${data.organizationId}`,
  );
