import { BarList, Flex, Tab, TabGroup, TabList, Text } from '@tremor/react';
import { AppBarListProps } from './types';
import { useState } from 'react';
import { exportChart } from 'src/lib/utils';
import { TremorCard, ChartExportDropdown } from 'ui';
import { formatTableNumbers } from 'global-utils';

export const AppBarList: React.FC<AppBarListProps> = ({
  title,
  categories,
  data,
  subtitle,
  isLoading,
  containerId,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const valueFormatter = (value: number) => {
    if (selectedCategory === 'Values') {
      return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    if (selectedCategory === 'Quantities') {
      return `${formatTableNumbers({ value, isAmount: true })} units`;
    }

    return value.toLocaleString();
  };

  return (
    <div className='p-6'>
      <TremorCard id={containerId}>
        <Flex className='mb-4 justify-between flex flex-wrap gap-y-2'>
          <TabGroup
            onIndexChange={(idx) => setSelectedCategory(categories[idx])}
            index={categories.indexOf(selectedCategory)}
            className='flex-1'
          >
            <TabList variant='solid' className='border-b-0 !h-10 flex w-fit items-center px-1'>
              {categories.map((category, index) => {
                return (
                  <Tab className='border-0' key={index}>
                    {category}
                  </Tab>
                );
              })}
            </TabList>
          </TabGroup>
          <ChartExportDropdown
            onPngExport={() =>
              exportChart({
                chartId: containerId,
                ignore: ['tremor-TabGroup-root', 'currencyIcon'],
                format: 'png',
                title,
              })
            }
            onSvgExport={() =>
              exportChart({
                chartId: containerId,
                ignore: ['tremor-TabGroup-root', 'currencyIcon'],
                format: 'svg',
                title,
              })
            }
          />
        </Flex>
        {isLoading ? (
          <div className='h-[200px] animate-pulse bg-gray-50' />
        ) : (
          <>
            <Flex className='mb-4'>
              <Text>{subtitle}</Text>
              <Text>{selectedCategory}</Text>
            </Flex>
            <BarList
              data={data[selectedCategory].sort((a, b) => (a.value > b.value ? -1 : 1))}
              valueFormatter={valueFormatter}
            />
          </>
        )}
      </TremorCard>
    </div>
  );
};
