import { Table } from '../dashboard';

export const FunctionCallJournalsTable = ({ outputs }) => {
  if (!outputs) return <></>;
  return (
    <Table
      tableData={{ Data: outputs }}
      tableHeader={[
        {
          key: 'journalSequenceNumber',
          header: 'Journal',
        },
        {
          key: 'accountingDate',
          header: 'Accounting date',
        },
        {
          key: 'sequenceNumber',
          header: 'Transaction',
        },
        {
          key: 'grossPriceAsString',
          header: 'Gross',
        },
      ]}
      hideCheckboxes
    />
  );
};

export const tableToFunctionMap = {
  get_synced_entries: FunctionCallJournalsTable,
  mark_transactions_as_spam: null, // -> make tx table
  unmark_transactions_as_spam: null, // -> make tx table
};
