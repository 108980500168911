import React, { useState } from 'react';
import { SidebarRoot } from '../atoms/Sidebar';
import EditJournalEntrySidebar from './EditJournalEntrySidebar';
import JournalEntrySidebar from './JournalEntrySidebar';

function JournalDetails({ journalEntryId, onCancel, hideRoot, isDockPanelAvailable, onBack, isPrimary }) {
  const [editMode, setEditMode] = useState(false);

  const handleBackButtonClick =
    onBack &&
    (() => {
      onBack();
    });

  if (editMode) {
    return (
      <EditJournalEntrySidebar
        onCancel={() => {
          setEditMode(false);
        }}
        onBack={onBack}
        journalEntryId={journalEntryId}
        isPrimary={isPrimary}
      />
    );
  }
  if (hideRoot)
    return (
      <JournalEntrySidebar
        journalEntryId={journalEntryId}
        onBack={handleBackButtonClick}
        onEditClick={() => setEditMode(true)}
        onCancel={onCancel}
        isDockPanelAvailable={isDockPanelAvailable}
        isPrimary={isPrimary}
        shouldNestViewJournalLinePanel={true}
      />
    );
  return (
    <SidebarRoot>
      <JournalEntrySidebar
        journalEntryId={journalEntryId}
        onBack={handleBackButtonClick}
        onEditClick={() => setEditMode(true)}
        onCancel={onCancel}
        isDockPanelAvailable={isDockPanelAvailable}
        isPrimary={isPrimary}
        shouldNestViewJournalLinePanel={true}
      />
    </SidebarRoot>
  );
}

export default JournalDetails;
