import { useRuleConfigurator } from '../../rule-configurator-context';
import { STRING_OPERATORS } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { InputWithExtras, SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  return (
    <>
      <SingleSelectMenu value={{ label: 'Memo', value: 'Memo' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={STRING_OPERATORS}
        value={STRING_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      {condition.operator && !['stringIsEmpty', 'stringIsNotEmpty'].includes(condition.operator) && (
        <InputWithExtras onChange={(e) => updateCondition(condition.id, { value: e.target.value })} />
      )}
    </>
  );
};

export const TransactionMemoRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION_MEMO]}>
    <Row />
  </ConditionRow>
);
