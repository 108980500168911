import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Action, Button, DetailsItem, SidebarTableSection, TableSkeletonLoader } from '../atoms';
import { Sidebar, SidebarTopBar, SidebarBody } from '../atoms/Sidebar';
import {
  useBulkUnpostJournalEntry,
  useDeleteJournalEntry,
  useGetJournalEntryById,
  usePatchJournalEntry,
  useRecalculateJournalEntryGainLoss,
  useReverseJournalEntry,
} from '../../hooks/http';
import {
  convertJournalEntries,
  deriveError,
  formatAccountingPeriod,
  formatDate,
  getCreditsDebitsForJe,
} from '../templates/utils';
import { JournalEntryFileAttachment } from './JournalEntryFileAttachment';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../lib/utils';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';
import { formatTableNumbers } from 'global-utils';
import { StatusIndicator } from 'ui';
import {
  AccountingPeriod,
  JournalEntryLineDB,
  JournalEntryTemplate,
  LedgerAccount,
  LegalEntity,
  Transaction,
} from 'schemas';
import { getJournalActions } from './JournalActions';
import { ViewJournalEntryLinePanel } from './ViewJournalEntryLinePanel';
import { JournalEntryData, JournalEntryLineData } from './types';
import { useTaskManager } from 'src/context';
import { JOB_NAME } from 'services';
import Table from '../tables/tanstack-table/Table';
import { journalEntryLinesColumns } from './journalEntryLinesColumns';
import { DensityDropdown, EditColumns } from '../tables/table-components';
import { ColumnsProps } from '../tables/columns/types';

const dataCy = 'journalDetails';

const JournalEntrySidebar = ({
  journalEntryId,
  onCancel,
  isDockPanelAvailable,
  onBack,
  isPrimary,
  onEditClick,
  shouldNestViewJournalLinePanel = false,
}) => {
  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(journalEntryLinesColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  const { organizationId, userId } = useSession();
  const { openSidebar } = useContext(SidebarGlobalContext);
  const { data, isLoading } = useGetJournalEntryById(journalEntryId);

  const [journalEntryData, setJournalEntryData] = useState<Partial<JournalEntryData>>({});

  useEffect(() => {
    setJournalEntryData(
      data
        ? {
            journalEntry: data?.journalEntryModels[0],
            accountingPeriod: data?.journalEntryModels[0]?.accountingPeriodId as AccountingPeriod,
            timestampAsString: formatDate(new Date(data?.journalEntryModels[0]?.accountingDate as unknown as string)),
            journalEntryTemplate: data?.journalEntryModels[0]?.journalEntryTemplateId as JournalEntryTemplate,
            legalEntity: data?.journalEntryModels[0]?.legalEntityId as LegalEntity,
            transaction: data?.journalEntryModels[0]?.transactionId as Transaction,
          }
        : {},
    );
  }, [data]);

  const { journalEntry, accountingPeriod, timestampAsString, journalEntryTemplate, legalEntity, transaction } =
    journalEntryData;

  const csvData = data?.journalEntryModels ? convertJournalEntries(data?.journalEntryModels, true) : [];

  const { mutateAsync: reverseJournalEntry, isLoading: isReversingJournalEntry } = useReverseJournalEntry();
  const { mutateAsync: unpostJournalEntry, isLoading: isUnposting } = useBulkUnpostJournalEntry();
  const { mutateAsync: deleteJournalEntry } = useDeleteJournalEntry();
  const { invalidateJournalEntries } = useInvalidateQuery();
  const [showViewJournalEntryLinePanel, setShowViewJournalEntryLinePanel] = useState(false);
  const [selectedLine, setSelectedLine] = useState<JournalEntryLineData | null>(null);

  const handleReverseEntry = async () => {
    if (!isReversingJournalEntry)
      try {
        await reverseJournalEntry({
          organizationId,
          journalEntryId: journalEntry?._id,
          userId,
        });

        invalidateJournalEntries();
        toast.success('Your journal entry has been reversed.');
      } catch (error) {
        toast.error(deriveError(error));
      }
  };

  const handleUnpostJournalEntry = async () => {
    const toastId = toast.loading('Journal unposting.');
    try {
      await unpostJournalEntry({
        organizationId,
        journalEntryIds: [journalEntry?._id],
      });
      invalidateJournalEntries();
      toast.success('Journal unposted successfully.', { id: toastId });
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  const onJournalEntryDelete = async () => {
    const toastId = toast.loading('Deleting journal entry');
    try {
      await deleteJournalEntry({ journalEntryId: journalEntry?._id!, organizationId });
      toast.success('Journal entry deleted successfully', { id: toastId });
      if (onCancel) onCancel();
      invalidateJournalEntries();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  const displayedLines = useMemo(
    () =>
      journalEntry?.journalEntryLineIds?.map((jel): JournalEntryLineData => {
        const line = jel as JournalEntryLineDB;
        const ledgerAccount = line?.ledgerAccountId as LedgerAccount;
        const legalEntity = line?.legalEntityId as LegalEntity;
        return {
          legalEntity: legalEntity,
          ledgerAccount: ledgerAccount?.ledgerAccountName,
          ledgerAccountId: ledgerAccount?._id,
          amount: parseFloat((line.amount as any).$numberDecimal),
          company: legalEntity?.entityName,
          creditOrDebit: line?.creditOrDebit,
          tags: line?.tags,
          legalEntityId: {
            _id: legalEntity?._id,
            entityName: legalEntity?.entityName,
          },
        };
      }) || [],

    [journalEntry],
  );
  const { mutateAsync: editJournalEntryByParams, isLoading: isPosting } = usePatchJournalEntry();
  const postJournalEntry = async () => {
    try {
      const editPayload: any = {
        organizationId,
        journalEntryModel: {
          journalEntry: {
            ...journalEntry,
            status: 'POSTED',
          },
          journalEntryLines: journalEntry?.journalEntryLineIds.map((line: any) => ({
            ...line,
            amount: line.amount.$numberDecimal,
          })),
        },
      };
      await editJournalEntryByParams(editPayload);
      await invalidateJournalEntries();
      toast.success('Journal posted');
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const { passJobToTaskManager } = useTaskManager();
  const { mutateAsync: recalculateJournalEntryGainLoss, isLoading: isRecalculatingJournalEntry } =
    useRecalculateJournalEntryGainLoss();
  const recalculateJournalEntry = async () => {
    try {
      const recalculatePayload = {
        organizationId,
        journalEntryId: journalEntry?._id,
      };
      const transaction = journalEntry?.transactionId as Transaction;
      passJobToTaskManager(organizationId, JOB_NAME.EVENT_DRIVEN_ASSET_RECONSTRUCTION_JOB, transaction?.assetType);
      passJobToTaskManager(organizationId, JOB_NAME.EVENT_DRIVEN_JOURNAL_RECONSTRUCTION_JOB, transaction?.assetType);
      await recalculateJournalEntryGainLoss(recalculatePayload);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const actions: Action[] = useMemo(
    () =>
      getJournalActions({
        journalEntry,
        csvData,
        onJournalEntryDelete,
        onEditClick,
        handleUnpostJournalEntry,
        isUnposting,
        handleReverseEntry,
        isReversingJournalEntry,
        postJournalEntry,
        isPosting,
        recalculateJournalEntry,
        isRecalculatingJournalEntry,
        isLoading,
      }),
    [journalEntry, csvData, isLoading, isUnposting, isReversingJournalEntry, isPosting, isRecalculatingJournalEntry],
  );
  return (
    <>
      <div className={showViewJournalEntryLinePanel && !shouldNestViewJournalLinePanel ? 'hidden' : ''}>
        <Sidebar data-cy={dataCy}>
          <SidebarTopBar
            onClose={onCancel}
            isDockPanelAvailable={isDockPanelAvailable}
            onBack={onBack}
            itemId={journalEntryId}
            isPrimary={isPrimary}
            data-cy={dataCy}
          />
          <SidebarHeader
            data-cy={dataCy}
            title={journalEntry?.journalSequenceNumber ?? ''}
            subtitles={journalEntry?.createdAt ? [`Created ${formatDate(new Date(journalEntry.createdAt))}`] : []}
            status={{
              label: journalEntry?.status?.toLowerCase() ?? '',
              type: journalEntry?.status === 'POSTED' ? 'positive' : 'neutral',
            }}
            loading={isLoading}
            link={`${getHost()}/ledger/journals/${journalEntryId}`}
            actions={actions}
          />
          <SidebarBody>
            <SidebarSectionHeader title='Details' />
            <SidebarSection loading={isLoading}>
              <DetailsItem
                tag='Credit amount'
                value={formatTableNumbers({ value: getCreditsDebitsForJe(journalEntry).credits })}
              />
              <DetailsItem
                tag='Debit amount'
                value={formatTableNumbers({ value: getCreditsDebitsForJe(journalEntry).debits })}
              />
              <DetailsItem
                tag='Balanced'
                value={
                  getCreditsDebitsForJe(journalEntry).credits === getCreditsDebitsForJe(journalEntry).debits
                    ? 'True'
                    : 'False'
                }
              />
              <DetailsItem
                tag='Synced'
                value={
                  <StatusIndicator
                    type={journalEntry?.isSync ? 'positive' : 'neutral'}
                    label={journalEntry?.isSync ? 'Yes' : 'No'}
                  />
                }
              />
              <DetailsItem
                tag='Accounting period'
                value={
                  accountingPeriod?.accountingPeriodName ?? accountingPeriod?.startDateUTC
                    ? formatAccountingPeriod(new Date(accountingPeriod?.startDateUTC))
                    : ''
                }
              />
              <DetailsItem tag='Accounting Date' value={timestampAsString ?? ''} />
              {journalEntryTemplate?._id ? (
                <DetailsItem
                  tag='Template link'
                  value={journalEntryTemplate.name}
                  variant='secondary'
                  textToCopy={`${getHost()}/configure/templates/${journalEntryTemplate._id}`}
                  itemId={journalEntryTemplate._id}
                  onClick={() => {
                    openSidebar('templates', {
                      id: journalEntryTemplate._id,
                      primaryOrSecondary: 'secondary',
                    });
                  }}
                />
              ) : (
                <DetailsItem tag='Template link' value={'N/A'} variant='primary' />
              )}
              {legalEntity?._id ? (
                <DetailsItem
                  tag='Legal entity'
                  value={legalEntity.entityName}
                  variant='secondary'
                  textToCopy={`${getHost()}/configure/entities/${legalEntity._id}`}
                  itemId={legalEntity._id}
                  onClick={() => {
                    openSidebar('entities', {
                      id: legalEntity._id,
                      primaryOrSecondary: 'secondary',
                    });
                  }}
                />
              ) : (
                <DetailsItem tag='Legal entity' value={legalEntity?.entityName ?? ''} variant='primary' />
              )}
              <DetailsItem tag='Created by' value={journalEntry?.originatedBy!} />
              <DetailsItem tag='Memo' value={journalEntry?.memo!} />

              <DetailsItem tag='External reference' value={journalEntryTemplate?.externalReference ?? 'N/A'} />
              {transaction?._id ? (
                <DetailsItem
                  tag='Operational transaction'
                  variant='secondary'
                  value={transaction.sequenceNumber!}
                  textToCopy={`${getHost()}/ledger/transactions/${transaction._id}`}
                  itemId={transaction._id}
                  onClick={() => {
                    openSidebar('transactions', {
                      id: transaction._id,
                      primaryOrSecondary: 'secondary',
                    });
                  }}
                />
              ) : (
                <DetailsItem
                  tag='Operational transaction'
                  variant='primary'
                  value={transaction?.sequenceNumber ?? 'N/A'}
                />
              )}
            </SidebarSection>
            <SidebarSectionHeader title='Attachments' />
            <SidebarSection loading={isLoading} numberOfColumns={1}>
              <JournalEntryFileAttachment jeId={journalEntryId} />
            </SidebarSection>
            <SidebarSectionHeader title='Journal entry lines' />
            <SidebarTableSection loading={isLoading}>
              <div className='w-full'>
                <Table
                  columns={columns}
                  panelTableName={'JournalEntrySidebar'}
                  enableRowSelection={false}
                  data={displayedLines || []}
                  onRowClick={(row) => {
                    setSelectedLine(displayedLines[row.index]);
                    setShowViewJournalEntryLinePanel(true);
                  }}
                  disableColumnPinning={true}
                >
                  <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                    <div className='flex items-center gap-3 flex-1'></div>
                    <div className='flex flex-wrap gap-3'>
                      <EditColumns
                        onColumnsUpdate={handleColumnsUpdate}
                        columns={columns}
                        panelTableName={'JournalEntrySidebar'}
                      />
                      <DensityDropdown />
                    </div>
                  </div>
                </Table>
              </div>
              {isLoading && <TableSkeletonLoader />}
              {isLoading && (
                <div className='w-full flex justify-center items-center my-8'>
                  <Button className='loading text-center' />
                </div>
              )}
            </SidebarTableSection>
          </SidebarBody>
        </Sidebar>
      </div>
      {showViewJournalEntryLinePanel && (
        <ViewJournalEntryLinePanel
          selectedLine={selectedLine}
          onCancel={() => {
            setSelectedLine(null);
            setShowViewJournalEntryLinePanel(false);
          }}
        />
      )}
    </>
  );
};

export default JournalEntrySidebar;
