import { useQuery } from '@tanstack/react-query';
import { getClassificationTaskStatus } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useGetClassificationTaskStatusQuery = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.GET_CLASSIFICATION_TASK_STATUS),
    async () => {
      const response = await getClassificationTaskStatus();
      return response.data;
    },
    { refetchInterval: 3000, enabled: !!organizationId },
  );
};
