import { useGetIntegrationByTypeResponse } from '../components/integrations/gl-integration/useGetIntegrationByType';

export const DEFAULT_INTEGRATIONS_CARD_DETAILS: useGetIntegrationByTypeResponse = {
  heading: '',
  description: '',
  icon: <></>,
  isLive: false,
  onConnectUrl: '',
  isLoading: false,
  createdOn: '',
  lastUpdatedOn: '',
  glAuths: [],
};
