import React from 'react';
import Select from 'react-select';
import { InputTagProps } from './type';
import { MultiValue, MultiValueRemove, Option } from './CustomComponents';
import { inputTagStyle } from '../../../styles/InputTagStyle';
export const InputTag = ({ label = 'Tag', customStyle, viewMode, value, selectId, ...props }: InputTagProps) => {
  return (
    <div className='flex flex-col w-full'>
      <label className={`block mb-1 font-medium ${viewMode && 'text-[#00000066]'}`}>{label}</label>
      {viewMode ? (
        <p className='font-medium opacity-80'>
          {Array.isArray(value) ? (value.length ? value[0]?.label : 'N/A') : value?.label.length ? value?.label : 'N/A'}
        </p>
      ) : (
        <Select
          isMulti
          classNamePrefix={'list'}
          onKeyDown={(event) => {
            if (event.keyCode === 32) event.preventDefault();
          }}
          styles={{ ...inputTagStyle, ...customStyle }}
          components={{
            MultiValueRemove,
            MultiValue,
            Option,
          }}
          value={value}
          id={selectId}
          {...props}
        />
      )}
    </div>
  );
};

export default InputTag;
