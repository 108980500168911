import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { useGetRaincardSources } from '../../../../../hooks/http/useRaincards';
import { FACT } from '../../types';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';

type CardData = {
  id: string;
  name: string;
};

export const convertRaincardToRowFn: ConvertToRowFn<CardData> = (card) => ({
  rowData: { value: card.id, label: card.name },
  isSelected: false,
});
export const filterRaincardRowsFn: FilterFn<CardData> = (searchTerm) => (rowData) =>
  rowData.name?.toLowerCase().includes(searchTerm.toLowerCase());

export const RaincardsSourceConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.RAINCARDS,
          operator: 'raincardIsDebited',
          value: conditionData.rowData.value,
        })
      }
    />
  );
};

export const RaincardsSourcesConditionsMenu = () => {
  const { data: raincards, isLoading } = useGetRaincardSources({ legalEntityId: '' });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (raincards) setData(raincards.map((card) => ({ name: card.cardData.nickname, id: card._id })));
  }, [raincards]);

  const conditionData = useFilteredConditions<any>(data, filterRaincardRowsFn, convertRaincardToRowFn);
  // console.log({ raincards, data, conditionData });
  return (
    <ConditionsGroup label='Raincards Sources'>
      {conditionData.length === 0 && isLoading && (
        <div className='flex items-center justify-center my-4'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
      {conditionData.length === 0 && !isLoading && <div className='text-center'>No raincards sources to show</div>}
      {conditionData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionData}
          itemContent={(index, data) => <RaincardsSourceConditionSelectorRow conditionData={data} key={index} />}
        />
      )}
    </ConditionsGroup>
  );
};
