import React, { useEffect, useMemo, useState } from 'react';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { useSession } from '../../../hooks/useSession';
import { useDeleteLegalEntity, useGetLegalEntityById, usePatchLegalEntity } from '../../../hooks/http';
import {
  Button,
  DropdownOption,
  InputLabel,
  InputWithExtras,
  SingleSelectMenu,
  TextareaInput,
  currencyOptions,
} from 'ui';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import { ENTITY_TYPES } from '../../../constants';
import { SidebarBody, SidebarFooter } from '../../atoms/Sidebar';
import { ConfirmationModal } from '../ConfirmationModal';
import { navigate, updateRouteStackedDefaultState } from '../../utils';
import { getHost } from '../../../lib/utils';
import { useInvalidateQuery, useTabState } from '../../../hooks';

interface FormState {
  entityName: string;
  addressString: string;
  entityType: string;
  currency?: DropdownOption;
}

const PatchLegalEntityFormForSidebar = ({ legalEntityId, onCancel, closeSidebar, defaultFormState = {} }: any) => {
  const dataCy = 'patchLegalEntity';
  const { organizationId } = useSession();
  const { data: legalEntity, isLoading: isLoadingLegalEntity } = useGetLegalEntityById(legalEntityId);
  const isFormDataLoading = useMemo(() => !legalEntity && isLoadingLegalEntity, [legalEntity, isLoadingLegalEntity]);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const { mutateAsync: deleteLegalEntity, isLoading: isDeletingLegalEntity } = useDeleteLegalEntity();
  const {
    activeTab,
    state: { sidebarState },
    updateTabSidebarState,
  } = useTabState();
  const memoizedTabUpdate = useMemo(() => activeTab.id, [activeTab?.id ?? '']);
  const [formState, setFormState] = useState<FormState>(
    defaultFormState ?? {
      entityName: '',
      entityType: '',
      addressString: '',
    },
  );
  const { mutateAsync: patchLegalEntity, isLoading: isPatchingLegalEntity } = usePatchLegalEntity();
  const { userId }: any = useSession();
  const { invalidateLegalEntities } = useInvalidateQuery();

  useEffect(() => {
    const currencyValue = defaultFormState?.currency ?? legalEntity?.currency ?? '';
    const updatedObj = {
      entityName: defaultFormState?.entityName ?? legalEntity?.entityName ?? '',
      entityType: defaultFormState?.entityType ?? legalEntity?.entityType ?? '',
      currency: currencyValue.length ? currencyOptions.find((option) => option.value === currencyValue) : undefined,
      addressString: defaultFormState?.addressString ?? legalEntity?.addressString ?? '',
    };
    if (legalEntity) {
      updateRouteStackedDefaultState({
        sidebarState,
        updateTabSidebarState,
        formState: {
          ...updatedObj,
          currency: currencyValue.length
            ? currencyOptions.find((option) => option.value === currencyValue)?.value
            : undefined,
        },
      });
      setFormState({ ...updatedObj });
    }
  }, [legalEntity]);

  useEffect(() => {
    setFormState({
      entityName: defaultFormState?.entityName ?? legalEntity?.entityName ?? '',
      entityType: defaultFormState?.entityType ?? legalEntity?.entityType ?? '',
      addressString: defaultFormState?.addressString ?? legalEntity?.addressString ?? '',
      currency:
        currencyOptions.find((option) => option.value === defaultFormState?.currency ?? legalEntity?.currency) ??
        undefined,
    });
  }, [memoizedTabUpdate]);

  const onPatchClick = async () => {
    try {
      await patchLegalEntity({
        legalEntity: {
          entityName: formState.entityName,
          entityType: formState.entityType,
          currency: formState.currency?.value ?? 'USD',
          addressString: formState.addressString,
          updatedBy: userId,
        },
        _id: legalEntityId,
      });
      toast.success('Legal entity updated successfully');

      invalidateLegalEntities();

      onCancel();
    } catch (error) {
      console.log('EditLegalEntityForm error: ', error);
      toast.error(deriveError(error));
    }
  };

  const onDelete = async () => {
    try {
      await deleteLegalEntity({ organizationId, legalEntityId });
      toast.success('Legal entity deleted successfully');
      invalidateLegalEntities();
      setShowDeleteConfirmationModal(false);
      navigate(`${getHost()}/configure/entities`);
      closeSidebar();
    } catch (error) {
      console.log('EditLegalEntityForm error on delete: ', error);
      toast.error(deriveError(error));
    }
  };

  return (
    <>
      <SidebarHeader data-cy={dataCy} title={'Update legal entity'} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Business name' />
            <InputWithExtras
              data-cy={`${dataCy}__businessName`}
              value={formState.entityName}
              onChange={(e) => {
                const updatedDefaultState = { ...defaultFormState, entityName: e.target.value };
                updateRouteStackedDefaultState({
                  sidebarState,
                  updateTabSidebarState,
                  formState: updatedDefaultState,
                });
                setFormState((prev) => ({ ...prev, entityName: e.target.value }));
              }}
              disabled={isFormDataLoading}
            />
          </div>
          <div>
            <InputLabel heading='Business type' />

            <SingleSelectMenu
              fullWidth
              isOnSidepanel
              data-cy={`${dataCy}__businessType`}
              options={ENTITY_TYPES}
              placeholder='Select business type'
              onChange={({ value }: any) => {
                const updatedDefaultState = { ...defaultFormState, entityType: value };
                updateRouteStackedDefaultState({
                  sidebarState,
                  updateTabSidebarState,
                  formState: updatedDefaultState,
                });
                setFormState((prev) => ({ ...prev, entityType: value }));
              }}
              value={ENTITY_TYPES.find((option) => option.value === formState.entityType)}
              isLoading={isFormDataLoading}
              onClearValue={() => {
                setFormState((prev) => ({
                  ...prev,
                  entityType: '',
                }));
              }}
            />
          </div>
          <div>
            <InputLabel heading='Business address' />
            <TextareaInput
              data-cy={`${dataCy}__businessAddress`}
              value={formState.addressString}
              onChange={(e) => {
                const updatedDefaultState = { ...defaultFormState, addressString: e.target.value };
                updateRouteStackedDefaultState({
                  sidebarState,
                  updateTabSidebarState,
                  formState: updatedDefaultState,
                });
                setFormState((prev) => ({ ...prev, addressString: e.target.value }));
              }}
              disabled={isFormDataLoading}
            />
          </div>
          <div>
            <InputLabel heading='Currency' />

            <SingleSelectMenu
              fullWidth
              isOnSidepanel
              data-cy={`${dataCy}__currency`}
              options={currencyOptions}
              defaultValue={formState.currency ?? undefined}
              value={
                formState?.currency && {
                  label: formState.currency?.description + ' ' + formState.currency?.label,
                  description: formState.currency?.description,
                  icon: formState.currency?.icon,
                  value: formState.currency?.value ?? '',
                }
              }
              onChange={(currency) => {
                const updatedDefaultState = { ...defaultFormState, currency: currency.value };
                updateRouteStackedDefaultState({
                  sidebarState,
                  updateTabSidebarState,
                  formState: updatedDefaultState,
                });
                setFormState((prev) => ({ ...prev, currency }));
              }}
              isLoading={isFormDataLoading}
              enableAvatar
              customIconRender={(option) => option.icon}
            />
          </div>
        </SidebarSection>
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            data-cy={dataCy}
            title='Are you sure you want to delete this wallet address?'
            warningText='Delete legal entity'
            onDelete={onDelete}
            onClose={() => setShowDeleteConfirmationModal(false)}
            isDeleting={isDeletingLegalEntity}
          />
        )}
      </SidebarBody>
      <SidebarFooter
        destructiveBtn={
          <Button
            onClick={() => setShowDeleteConfirmationModal(true)}
            emphasis='medium'
            label='Delete'
            status='danger'
            data-cy={`${dataCy}__deleteButton`}
          />
        }
        primaryBtn={
          <Button
            data-cy={`${dataCy}__updateButton`}
            onClick={onPatchClick}
            isLoading={isPatchingLegalEntity}
            label={'Update'}
          />
        }
        secondaryBtn={<Button onClick={onCancel} emphasis='medium' label='Cancel' />}
      />
    </>
  );
};

export default PatchLegalEntityFormForSidebar;
