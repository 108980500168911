export const DEFAULT_INTEGRATIONS_PANEL_STATES = {
  quickbooksPanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  netsuitePanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  xeroPanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  circlePanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  coinbasePrimePanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  hedgeyPanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  maplePanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  raincardsPanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
  loopPanelState: {
    viewDetailsPanel: false,
    editPanel: false,
  },
};
