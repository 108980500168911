import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const useToast = () => {
  const [toastId, setToastId] = useState<undefined | string>();
  const create = (type: string | number, message: string) => {
    setToastId(toast[type](message));
  };
  const update = (type: string | number, message: string) => {
    setToastId((prev) => toast[type](message, { id: prev }));
  };
  const hide = () => {
    toast.remove(toastId);
    setToastId(undefined);
  };
  return { create, update, hide };
};
