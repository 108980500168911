import { SectionHeaderProps } from './types';
import { classNames } from '../utils';
import LoadingComponent from './loading-component';

const getHeadingStyles = (variant: 'lg' | 'md' | 'sm') => {
  if (variant === 'lg') return 'text-[25px] font-medium mb-1';
  if (variant === 'md') return 'text-[20px] font-medium mb-1';
  if (variant === 'sm') return 'font-medium mb-1';
  return 'text-[25px] font-medium mb-1';
};

export const SectionHeader = ({
  avatar,
  variant = 'lg',
  heading = '',
  description = '',
  tag,
  loading,
}: SectionHeaderProps) => {
  if (loading) return <LoadingComponent />;
  return (
    <div className={classNames('flex items-center gap-x-3')}>
      {avatar}
      <div>
        <div className='flex gap-x-2 items-center'>
          {heading.length > 0 && <div className={getHeadingStyles(variant)}>{heading}</div>}
          {tag}
        </div>
        {description.length > 0 && <div className='text-[16px]'>{description}</div>}
      </div>
    </div>
  );
};
