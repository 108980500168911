import { useEffect, useMemo, useState } from 'react';
import { SUBSCRIPTION_MODAL_STEP } from './types';
import { useGetStripeDetailsForOrgQuery, useSession, useUpdateSubscriptionMutation } from '../../../../hooks';
import Stripe from 'stripe';
import { formatStripeAmount } from '../../utils';
import toast from 'react-hot-toast';
import { Modal, ModalTrigger } from 'ui';
import { SubscriptionModalTrigger } from './modal-trigger';
import { EditSubscriptionModalContent } from './edit-subscription-modal-content';
import { UpgradeSubscriptionModalContent } from './upgrade-subscription-modal-content';

export const SubscriptionModal = ({ initialStep = SUBSCRIPTION_MODAL_STEP.EDIT }) => {
  const [step, setStep] = useState(initialStep);
  const [open, setOpen] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<any>();
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  const currentSubscription = useMemo(() => {
    if (!stripeDetails) return undefined;
    const currentPrice = stripeDetails.subscription?.items.data[0].price;
    if (!currentPrice) return undefined;
    const currentProduct = currentPrice.product as unknown as Stripe.Product;

    return {
      id: currentPrice.id,
      label: currentProduct.name,
      description: `${formatStripeAmount(currentPrice.unit_amount as number)} per ${currentPrice.recurring?.interval}`,
      amount: currentPrice.unit_amount as number,
      currency: currentPrice.currency,
    };
  }, [stripeDetails]);

  useEffect(() => {
    if (currentSubscription) setSelectedSubscriptionPlan(currentSubscription);
  }, [currentSubscription]);

  const { mutateAsync: updateSubscription, isLoading: isUpdatingSubscription } = useUpdateSubscriptionMutation();
  const { organizationId } = useSession();

  const onClose = () => {
    setOpen(false);
    setStep(initialStep);
  };

  const updateSubscriptionPlan = async () => {
    if (!selectedSubscriptionPlan) {
      toast.error('Please select a subscription plan');
      return true;
    }
    await updateSubscription({ organizationId, priceId: selectedSubscriptionPlan.id });
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <SubscriptionModalTrigger step={initialStep} setOpen={setOpen} />
      </ModalTrigger>
      {step === SUBSCRIPTION_MODAL_STEP.EDIT && (
        <EditSubscriptionModalContent
          onCancel={onClose}
          subscriptionPlan={currentSubscription}
          onUpgrade={() => setStep(SUBSCRIPTION_MODAL_STEP.UPGRADE)}
          isLoading={isUpdatingSubscription}
          disabled={isUpdatingSubscription}
        />
      )}
      {step === SUBSCRIPTION_MODAL_STEP.UPGRADE && (
        <UpgradeSubscriptionModalContent
          onCancel={onClose}
          onUpdate={updateSubscriptionPlan}
          subscriptionPlan={selectedSubscriptionPlan}
          onSubscriptionPlanChange={setSelectedSubscriptionPlan}
        />
      )}
    </Modal>
  );
};
