import * as React from 'react';
import { forwardRef } from 'react';
const SvgCalendarIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 80 88' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M72 8h-4V4c0-2.2-1.8-4-4-4s-4 1.8-4 4v4H20V4c0-2.2-1.8-4-4-4s-4 1.8-4 4v4H8c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V16c0-4.4-3.6-8-8-8Zm-4 72H12c-2.2 0-4-1.8-4-4V28h64v48c0 2.2-1.8 4-4 4Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCalendarIcon);
export default ForwardRef;
