export const INTEGRATIONS_CARD = [
  {
    title: 'QuickBooks',
    description: 'Connect your Quickbooks account to automatically sync your transactions and accounts.',
    icon: '/quickbooks.png',
    connected: true,
  },
  {
    title: 'Netsuite',
    description: 'Connect your Netsuite account to automatically sync your transactions and accounts.',
    icon: '/netsuite.png',
    connected: true,
  },
  {
    title: 'Kraken',
    description: 'Connect your Kraken account to automatically sync your transactions and accounts.',
    icon: '/kraken.png',
    connected: true,
  },
  {
    title: 'Binance',
    description: 'Connect your binance account to automatically sync your transactions and accounts.',
    icon: '/binance.png',
    connected: true,
  },
  {
    title: 'Xero',
    description: 'Connect your Xero account to get started.',
    icon: '/xero.svg',
    connected: false,
  },
  {
    title: 'Circle',
    description: 'Connect your Circle account to automatically sync your transactions and accounts.',
    icon: '/circle.png',
    connected: false,
  },
  {
    title: 'CoinbasePrime',
    description: 'Connect your Coinbase Prime account to automatically sync your transactions and accounts.',
    icon: '/coinbasePrime.png',
    connected: false,
  },
  {
    title: 'Hedgey',
    description:
      'Token infrastructure for on-chain teams. Simple vesting, lockups, and distributions for your community, team and investors at every stage.',
    icon: '/hedgey.png',
    connected: false,
  },
  {
    title: 'Maple Finance',
    description:
      'Maple is the only on-chain marketplace focused exclusively on serving Institutional and Individual Accredited Investors with high-quality lending opportunities.',
    icon: '/maple.png',
    connected: false,
  },
  {
    title: 'Rain',
    description: 'Secure spend management from your decentralized wallet – no exchange or bank account required.',
    icon: '/raincards-logo.png',
    connected: false,
  },
  {
    title: 'Loop Crypto',
    description: 'Crypto payments, simplified. Save time, reduce churn and grow your customer base.',
    icon: '/loop-logo.svg',
    connected: false,
  },
];
