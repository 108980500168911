import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  addJournalEntries,
  bulkDeleteJournalEntry,
  bulkPostJournalEntry,
  bulkReverseJournalEntry,
  bulkUnpostJournalEntry,
  deleteJournalEntry,
  deleteJournalEntryFileAttachment,
  getFileSignUrl,
  getJournalEntries,
  getJournalEntryFileAttachments,
  getJournalEntryLines,
  patchJournalEntry,
  reverseJournalEntry,
  triggerJournalEntryRecalculationJob,
  uploadJournalFile,
} from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { Paginated } from './type';
import { useMemo } from 'react';

export type IsSyncType = 'Yes' | 'No';

export type GetJournalEntriesQueryFilter = Partial<{
  transactionIds: string[];
  legalEntityIds: string[];
  status: string[];
  originatedBy: string[];
  accountingPeriodIds: string[];
  accountingDate: string;
  journalEntryIds: string[];
  startDate: string;
  endDate: string;
  sort: {
    id: string;
    desc: boolean;
  };
  searchTerm: string;
  isForCsvExport: string;
  pageSize: number;
  isSync?: boolean;
  ledgerAccountIds: string[];
}>;

export const calculateIsSyncFromFilterState = (isSyncSelections?: IsSyncType[]) => {
  if (!isSyncSelections || isSyncSelections.length === 2) return undefined;
  if (isSyncSelections[0] === 'Yes') return true;
  if (isSyncSelections[0] === 'No') return false;
  return undefined;
};

export const useJournalEntries = (params?: GetJournalEntriesQueryFilter) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId, userId } = useSession();
  return useInfiniteQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRIES, params),
    async ({ pageParam = 0 }) => {
      const queryParams: Paginated<GetJournalEntriesQueryFilter & { organizationIds: string[]; userId: string }> = {
        ...params,
        userId,
        organizationIds: [organizationId],
        page: pageParam,
        pageSize: params?.pageSize ?? 25,
      };
      const response = await getJournalEntries(queryParams);
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      cacheTime: 5000,
    },
  );
};

export type GetJournalEntryLinesParams = Partial<{
  transactionIds: string[];
  legalEntityIds: string[];
  ledgerAccountIds: string[];
  accountingPeriodStartDateUTC: string;
  startDate: string;
  endDate: string;
  pageSize?: number;
  page?: number;
  searchTerm?: string;
  sort?: any;
}>;

export const useGetJournalEntryLinesQuery = (params?: GetJournalEntryLinesParams) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  const pageSize = useMemo(() => params?.pageSize ?? 25, [params]);

  return useInfiniteQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRIES, params),
    async ({ pageParam = 0 }) => {
      const queryParams: Paginated<GetJournalEntryLinesParams> = {
        ...params,
        page: pageParam,
        pageSize,
      };
      const response = await getJournalEntryLines(queryParams);
      return { ...response.data, page: pageParam };
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => {
        // console.log('getPreviousPageParam', firstPage, firstPage.page > 0 ? firstPage.page - 1 : undefined);
        return firstPage.page > 0 ? firstPage.page - 1 : undefined;
      },
      getNextPageParam: (lastPage) => {
        // console.log(
        //   'getNextPageParam',
        //   lastPage,
        //   lastPage.journalEntryLines.length === pageSize ? lastPage.page + 1 : undefined,
        // );
        return lastPage.journalEntryLines.length === pageSize ? lastPage.page + 1 : undefined;
      },
      cacheTime: 5000,
    },
  );
};

export const useGetJournalEntryById = (id) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRIES, { journalEntryId: id }),
    async () => {
      const queryParams: Paginated<GetJournalEntriesQueryFilter> = { journalEntryIds: [id], page: 0, pageSize: 1 };
      const response = await getJournalEntries(queryParams);
      return response.data;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const getKeyOfGetJournalEntryFileAttachmentsById = (id) => ['getJournalEntryFileAttachments', id];

export const useGetJournalEntryFileAttachmentsById = (id) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRY_FILE_ATTACHMENTS, { journalEntryFileAttachmentId: id }),
    async () => {
      const response = await getJournalEntryFileAttachments({
        organizationId,
        journalEntryId: id,
      });
      return response.data;
    },
    {
      enabled: !!id && !!organizationId,
    },
  );
};

export const useAddJournalEntry = () => useMutation((data) => addJournalEntries(data));

export const useFileUploadMutation = () => useMutation((params) => uploadJournalFile(params));

export const useGetFileSignedUrl = () => useMutation((params: { filePath: string }) => getFileSignUrl(params));

export const useDeleteFileAttachment = () => useMutation((params) => deleteJournalEntryFileAttachment(params));

export const usePatchJournalEntry = () => useMutation((data) => patchJournalEntry(data));

interface DeleteJournalEntryPayload {
  journalEntryId: string;
  organizationId: string;
}

export const useDeleteJournalEntry = () => useMutation((data: DeleteJournalEntryPayload) => deleteJournalEntry(data));

export const useReverseJournalEntry = () => useMutation((data: any) => reverseJournalEntry(data));

export const useBulkPostJournalEntry = () => useMutation((data: any) => bulkPostJournalEntry(data));

export const useBulkDeleteJournalEntry = () => useMutation((data: any) => bulkDeleteJournalEntry(data));

export const useBulkUnpostJournalEntry = () => useMutation((data: any) => bulkUnpostJournalEntry(data));

export const useBulkReverseJournalEntry = () => useMutation((data: any) => bulkReverseJournalEntry(data));

export const useRecalculateJournalEntryGainLoss = () => useMutation((data: any) => triggerJournalEntryRecalculationJob(data));