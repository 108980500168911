import { ReactNode, useState } from 'react';
import { classNames } from '../utils';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2';
import { AnimatePresence, motion } from 'framer-motion';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

export const Accordian = ({
  defaultOpen = false,
  children,
  heading,
}: {
  defaultOpen?: boolean;
  children: ReactNode;
  heading: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  return (
    <div>
      <div
        className={classNames('font-bold w-full flex items-center rounded-t-lg gap-x-3')}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <button>{isOpen ? <MdArrowDropDown /> : <MdArrowDropUp />}</button>
        <span>{heading}</span>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
