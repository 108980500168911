import { getMappedValue } from '../../utils';
import { useRuleConfigurator } from '../../rule-configurator-context';

import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { Button, DialogPrimitive, SingleSelectMenu, classNames } from 'ui';
import { SourcePicker } from '../../../../source-picker';
import { useEffect, useRef, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useCondition } from '../useCondition';
import { useSourceTags, useSources } from '../../../../../hooks';
import { ExchangeSource, Tag, Wallet } from 'schemas';
import { useGetExchangeSources } from '../../../../../hooks/http/useExchangeSource';
import { getWalletTags } from '../../../utils';
import { formatExchangeTypeToName } from '../../../../PageComponents';
import { ThreeDots } from 'react-loader-spinner';
import { mergeSources } from '../../../../templates/utils';

const SourcePickerDropdown = ({
  defaultSourceId,
  defaultSourceLabel = 'Selected',
  onSourceChange,
  isDisabled,
  isLoading,
  hideTags = false,
}) => {
  const [isSourcePickerOpen, setIsSourcePickerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const triggerRef = useRef(null);
  return (
    <DialogPrimitive.Dialog open={isSourcePickerOpen} onOpenChange={setIsSourcePickerOpen}>
      <DialogPrimitive.DialogTrigger ref={triggerRef} asChild disabled={isDisabled}>
        <Button
          emphasis='medium'
          disabled={isDisabled}
          label={
            isLoading ? (
              <ThreeDots
                height='24'
                width='24'
                radius='9'
                color='#aaaaaa'
                ariaLabel='three-dots-loading'
                wrapperClass='w-28'
                visible={true}
              />
            ) : defaultSourceId ? (
              defaultSourceLabel
            ) : (
              'Select source'
            )
          }
          trailingIcon={
            <MdArrowDropDown
              className={classNames(
                'w-6 h-6 text-zinc-900 transition ease-in-out duration-300',
                isSourcePickerOpen && 'rotate-1800',
                isDisabled && 'text-zinc-400',
              )}
            />
          }
        />
      </DialogPrimitive.DialogTrigger>
      <DialogPrimitive.DialogPortal>
        <DialogPrimitive.DialogContent className=' h-fit p-2'>
          <SourcePicker
            defaultValue={defaultSourceId}
            onChange={(value) => {
              setIsSourcePickerOpen(false);
              onSourceChange(value);
            }}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            hideTags={hideTags}
          />
        </DialogPrimitive.DialogContent>
      </DialogPrimitive.DialogPortal>
    </DialogPrimitive.Dialog>
  );
};

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  const { data: wallets, isLoading: isLoadingWalletPages } = useSources({ pageSize: 1000 });
  const [allWallets, setAllWallets] = useState<Wallet[]>([]);

  const { data: exchanges, isLoading: isLoadingExchanges } = useGetExchangeSources();
  const [allExchanges, setAllExchanges] = useState<ExchangeSource[]>([]);

  const { data: tags, isLoading: isLoadingTags } = useSourceTags();
  const [allTags, setAllTags] = useState<Tag[]>([]);

  const [internalWalletLabel, setInternalWalletLabel] = useState('');
  const [externalWalletLabel, setExternalWalletLabel] = useState('');

  useEffect(() => {
    if (wallets) setAllWallets(mergeSources(wallets).map((w) => ({ ...w, selected: false })));
  }, [wallets]);

  useEffect(() => {
    if (exchanges) {
      setAllExchanges(
        exchanges?.sources?.map((source) => ({
          ...source,
          chain: 'Exchange',
          address: formatExchangeTypeToName(source.exchangeSourceType),
          walletType: 'internal',
          selected: false,
        })),
      );
    }
  }, [exchanges]);

  useEffect(() => {
    if (tags) setAllTags(getWalletTags(tags));
  }, [tags]);

  useEffect(() => {
    if (!condition.value?.internalWallet) return;
    if (condition.value?.internalWallet.label) return setInternalWalletLabel(condition.value.internalWallet.label);

    const matchingWallet = allWallets.find(
      (wallet) =>
        wallet.address === condition.value.internalWallet.address ||
        wallet.addresses.includes(condition.value.internalWallet.address),
    );

    const matchingExchange = allExchanges.find((exchange) => exchange._id === condition.value.internalWallet.id);

    const matchingTag = allTags.find((tag) => tag._id === condition.value.internalWallet.tagId);

    if (matchingTag) return setInternalWalletLabel(`${matchingTag.entry.key}: ${matchingTag.entry.value}`);
    if (matchingWallet)
      return setInternalWalletLabel(matchingWallet.name || `${matchingWallet.chain} · ${matchingWallet.walletType}`);
    if (matchingExchange) return setInternalWalletLabel(matchingExchange.exchangeSourceType!);
  }, [allWallets, allExchanges, allTags, condition]);

  useEffect(() => {
    if (!condition.value?.externalWallet) return;
    if (condition.value?.externalWallet.label) return setExternalWalletLabel(condition.value.externalWallet.label);

    const matchingWallet = allWallets.find(
      (wallet) =>
        wallet.address === condition.value.externalWallet.address ||
        wallet.addresses.includes(condition.value.externalWallet.address),
    );

    const matchingExchange = allExchanges.find((exchange) => exchange._id === condition.value.externalWallet.id);

    const matchingTag = allTags.find((tag) => tag._id === condition.value.externalWallet.tagId);

    if (matchingTag) return setExternalWalletLabel(`${matchingTag.entry.key}: ${matchingTag.entry.value}`);
    if (matchingWallet)
      return setExternalWalletLabel(matchingWallet.name || `${matchingWallet.chain} · ${matchingWallet.walletType}`);
    if (matchingExchange) return setExternalWalletLabel(matchingExchange.exchangeSourceType!);
  }, [allWallets, allExchanges, allTags, condition]);

  const isLoading = isLoadingExchanges || isLoadingTags || isLoadingWalletPages;

  const onFirstSourceChange = (source) => {
    if (source.subRows)
      // if its a group
      return updateCondition(condition.id, {
        value: {
          internalWallet: {
            groupId: source._id,
            label: source.name,
          },
          externalWallet: condition?.value?.externalWallet,
        },
      });

    if (source?.entry?.key) {
      // if its a tag
      const label = `${source.entry.key}: ${source.entry.value}`;
      return updateCondition(condition.id, {
        value: {
          internalWallets: {
            tagId: source._id,
            label,
          },
          externalWallet: condition?.value?.externalWallet,
        },
      });
    }
    // its an exchange/source
    updateCondition(condition.id, {
      value: {
        internalWallet: { id: source._id, address: source.address, label: source.name || source.exchangeType },
        externalWallet: condition.value?.externalWallet,
      },
    });
  };
  const onSecondSourceChange = (source) => {
    if (source.subRows)
      // if its a group
      return updateCondition(condition.id, {
        value: {
          internalWallet: condition.value.internalWallet,
          externalWallet: {
            groupId: source.id,
            label: source.name,
          },
        },
      });

    if (source?.entry?.key) {
      // if its a tag
      const label = `${source.entry.key}: ${source.entry.value}`;
      return updateCondition(condition.id, {
        value: {
          internalWallet: condition.value.internalWallet,
          externalWallet: {
            tagId: source._id,
            label,
          },
        },
      });
    }
    // its an exchange/source
    updateCondition(condition.id, {
      value: {
        internalWallet: condition.value.internalWallet,
        externalWallet: { id: source._id, address: source.address, label: source.name || source.exchangeType },
      },
    });
  };

  return (
    <>
      <SourcePickerDropdown
        onSourceChange={onFirstSourceChange}
        defaultSourceId={
          condition.value?.internalWallet?.id ||
          condition.value?.internalWallet?.groupId ||
          condition.value?.internalWallet?.tagId
        }
        defaultSourceLabel={condition.value?.internalWallet?.label || internalWalletLabel}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />

      <SingleSelectMenu
        options={getMappedValue(condition.fact, 'operators')}
        defaultValue={{ label: 'Credits', value: 'Credits' }}
        value={getMappedValue(condition.fact, 'operators', condition.operator)}
        onChange={({ value }) => {
          const externalWalletValue = ['isCredited', 'isDebited'].includes(value)
            ? undefined
            : condition.value.externalWallet;
          const newValue = externalWalletValue
            ? {
                internalWallet: condition?.value?.internalWallet,
                externalWallet: externalWalletValue,
              }
            : {
                internalWallet: condition?.value?.internalWallet,
              };
          updateCondition(condition.id, {
            value: newValue,
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      {(condition.operator === 'isDebitedBy' || condition.operator === 'isCreditedBy') && (
        <SourcePickerDropdown
          defaultSourceId={condition.value?.externalWallet?.id || condition.value?.externalWallet?.tagId}
          defaultSourceLabel={condition.value?.externalWallet?.label || externalWalletLabel}
          onSourceChange={onSecondSourceChange}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export const WalletRuleCondition = () => (
  <ConditionRow facts={[FACT.WALLET]}>
    <Row />
  </ConditionRow>
);
