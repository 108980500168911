import { ForwardedRef, forwardRef } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';
import { Button } from '../button';
import { ModalContentProps } from './types';
import { BackButton } from './back-button';
import { ModalActions } from './modal-actions';
import { classNames } from '../utils';

const ModalContentWrapper = forwardRef((props: any, ref: ForwardedRef<HTMLDivElement>) => {
  return <RadixDialog.Content {...props} ref={ref} />;
});

export const ModalContent = forwardRef(function Content(
  {
    children,
    actions,
    actionsClassName,
    title,
    description,
    onClose,
    backAction,
    className,
    variant,
  }: ModalContentProps,
  forwardedRef: ForwardedRef<HTMLDivElement>,
) {
  const isGroupDialog = ((title as any) || []).includes('group');

  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className='transition-all animate-fadeOverlay fixed inset-0 h-screen w-screen bg-black opacity-50 z-[32]' />
      <ModalContentWrapper
        ref={forwardedRef}
        className={classNames(
          'w-full sm:min-w-[320px] sm:max-w-[640px] md:min-w-[560px]',
          'max-h-[750px] h-fit',
          'transition-all animate-fade',
          'fixed top-1/2 left-1/2',
          'transform -translate-x-1/2 -translate-y-1/2',
          'z-modal',
          'rounded-xl',
          'bg-white ',
          className,
          variant === 'large' ? 'lg:max-w-[1200px]' : 'max-w-[560px]',
        )}
      >
        <div className='flex flex-col h-full'>
          <div className={classNames('flex flex-col  px-6 flex-grow', isGroupDialog ? 'py-4' : 'py-6')}>
            <div className={classNames('w-full flex items-start justify-between', isGroupDialog && 'border-b-2 pb-3')}>
              <div className='flex-grow flex'>
                <div>
                  <div className='flex items-center gap-x-2'>
                    {backAction && (
                      <div className='w-10 relative'>
                        <div className='absolute -top-5'>
                          <BackButton tooltipMessage='go back' onClick={backAction.onClick} />
                        </div>
                      </div>
                    )}
                    {title && (
                      <RadixDialog.Title
                        className={classNames('font-semibold', `${isGroupDialog ? 'ml-2.5 text-[16px]' : 'text-2xl'}`)}
                      >
                        {title}
                      </RadixDialog.Title>
                    )}
                  </div>
                  {description && (
                    <RadixDialog.Description className='mt-3 mb-6 text-[#111111]'>
                      {description}
                    </RadixDialog.Description>
                  )}
                </div>
              </div>
              {onClose && (
                <div className='w-10 h-10 relative'>
                  <div className='absolute'>
                    <Button
                      disableRingOnFocus
                      leadingIcon={<MdClose className='w-5 h-5' />}
                      emphasis='low'
                      onClick={onClose}
                    />
                  </div>
                </div>
              )}
            </div>
            {children}
          </div>
          {actions && (
            <div className='mt-auto py-4 px-6 border-t-2 flex-shrink-0'>
              <ModalActions className={actionsClassName} actions={actions} />
            </div>
          )}
        </div>
      </ModalContentWrapper>
    </RadixDialog.Portal>
  );
});

export const ModalTrigger = RadixDialog.Trigger;
export const Modal = RadixDialog.Root;

export const DialogActions = ModalActions;
export const DialogContent = ({ className, ...props }: any) => (
  <ModalContent className={`h-auto ${className}`} {...props} />
);
export const DialogTrigger = ModalTrigger;
export const Dialog = Modal;
