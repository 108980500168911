import React from 'react';
import { MetricGroupProps } from './types';
import { classNames } from 'ui';

export function MetricGroup({ className = '', style, children }: MetricGroupProps) {
  return (
    <div
      style={style}
      className={classNames('grid grid-cols-[repeat(auto-fit,_minmax(250px,_1fr))] gap-4 mb-9 mt-7 px-6', className)}
    >
      {children}
    </div>
  );
}

export default MetricGroup;
