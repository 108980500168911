import { FILTER_TYPE } from '../../components/filters';

export const JOURNAL_ENTRIES_PAGE_FILTERS = [
  FILTER_TYPE.LEGAL_ENTITY,
  FILTER_TYPE.ACCOUNTING_PERIOD,
  FILTER_TYPE.STATUS,
  FILTER_TYPE.ORIGINATED_BY,
  FILTER_TYPE.DATE,
  FILTER_TYPE.JOURNAL_SYNC,
  FILTER_TYPE.LEDGER_ACCOUNT,
];
