import React from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { useTabBasedFilterState } from '../use-filters';

type AmountFilterState = {
  mode: 'is' | 'is-above' | 'is-below' | 'is-between';
  min: string;
  max: string;
};
export type AmountFilterContextType = {
  state: AmountFilterState;
  setState: React.Dispatch<React.SetStateAction<AmountFilterState>>;
};

const defaultFilterState: AmountFilterState = { mode: 'is', min: '', max: '' };
export const useAmountFilterState = (page: string) => {
  const { state, setState } = useTabBasedFilterState<AmountFilterState>(FILTER_TYPE.AMOUNT, page, defaultFilterState);

  return {
    state,
    setState,
  };
};
