/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { classNames } from 'ui';

type DropDownItemProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  'data-cy'?: string;
};

export const DropDownItem = forwardRef<HTMLButtonElement, DropDownItemProps>(
  ({ children, disabled, onClick, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={classNames(
        'w-full flex justify-start items-center px-2 py-1.5 rounded-md duration-300 transition-all hover:bg-indigo-50 hover:cursor-pointer',
        disabled && '!opacity-40 !cursor-not-allowed',
      )}
    >
      {children}
    </button>
  ),
);

export default DropDownItem;
