import { GLType } from './types';
export enum ORGANIZATION_FILE_USE_CASE {
  LOGO = 'LOGO',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  PROFILE_PHOTO = 'PROFILE_PHOTO',
}
export enum CONTENT_TYPE_SUFFIX_MAPPINGS {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  CSV = 'text/csv',
}
export type FileMetadata = {
  fileid: string;
  contenttype: CONTENT_TYPE_SUFFIX_MAPPINGS;
  usecase: ORGANIZATION_FILE_USE_CASE;
  description?: string;
  organizationid: string;
  userid: string;
  journalentryid?: string;
  uploaderversion: string;
};

export interface LegalEntityPayload {
  entityName: string;
  currency: string;
  addressString: string;
  entityType: string;
  organizationId: string;
  userId: string;
  createdBy?: string;
  updatedBy?: string;
  address?: {
    line1: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
  };
}

export interface CommonWalletPayload {
  legalEntityId?: string;
  userId: string;
  organizationId: string;
  chain: WALLET_CHAIN;
  status?: WALLET_STATUS;
  name?: string;
  walletType: WALLET_TYPE;
  tags: string[];
}

export interface LedgerAccountPayload {
  ledgerAccountName: string;
  ledgerAccountType: LEDGER_ACCOUNT_TYPE;
  isClearingAccount?: boolean;
  ledgerAccountSequence: number;
  parentLedgerAccountId: string;
  organizationId: string;
  userId: string;
}

export interface ImpairmentRulePayload {
  name: string;
  description: string;
  journalEntryTemplateId: string;
  organizationId: string;
  userId: string;
}

export type WalletPayload =
  | (CommonWalletPayload & { address: string })
  | (CommonWalletPayload & { addresses: string[] });

export type PostPayload<T> = { [key: string]: T | string };
export type PatchPayload<T> = Partial<PostPayload<T>> & { _id: string };
type PatchLedgerAccountPayLoad<T> = Partial<PostPayload<T> & { ledgerAccountId: string }>;

export type PostLEPayload = PostPayload<LegalEntityPayload>;
export type PatchLEPayload = PatchPayload<Omit<LegalEntityPayload, 'userId' | 'organizationId'>>;
export type PostWalletPayload = PostPayload<WalletPayload>;
export type PatchWalletPayload = PatchPayload<Omit<WalletPayload, 'userId' | 'organizationId'>>;
export type PostLAPayload = PostPayload<LedgerAccountPayload>;
export type PatchLAPayload = PatchLedgerAccountPayLoad<Omit<LedgerAccountPayload, 'userId' | 'organizationId'>>;
export type PostIRPayload = PostPayload<ImpairmentRulePayload>;
export type PatchIRPayload = PatchPayload<Omit<ImpairmentRulePayload, 'userId' | 'organizationId'> & { _id: string }>;

export type UserName = {
  first?: string;
  last?: string;
  _id: string;
};

export type UserAddress = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  _id: string;
};

export type User = {
  email: string;
  password?: string;
  googleAuth?: any;
  jwt?: string;
  phoneNumber?: string;
  billingEmail?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: UserName;
  address?: UserAddress;
};

export type Organization = {
  organizationName: string;
  createdBy: User['_id'] | User;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum LEDGER_ACCOUNT_TYPE {
  LIABILITY = 'Liability',
  EQUITY = 'Equity',
  ASSET = 'Asset',
  EXPENSE = 'Expense',
  INCOME = 'Income',
}

export type LedgerAccount = {
  organizationId: Organization['_id'] | Organization;
  userId: User;
  ledgerAccountName: string;
  ledgerAccountType: LEDGER_ACCOUNT_TYPE;
  ledgerAccountSequence: number;
  parentLedgerAccountId?: LedgerAccount['_id'] | LedgerAccount;
  isDeleted?: boolean;
  isLeaf?: boolean;
  assetType?:
  | 'CASH'
  | 'BTC'
  | 'USDC'
  | 'DAI'
  | 'TKX'
  | 'LDO'
  | 'ETH'
  | 'SAFE'
  | 'AAVE'
  | 'FEI'
  | 'TRIBE'
  | 'POOL'
  | 'FWB'
  | 'SUSD'
  | 'ENS'
  | 'WETH'
  | 'GRT'
  | 'COVAL'
  | 'BUSD'
  | 'USDT'
  | 'RAD'
  | 'GTC'
  | 'GALA'
  | 'AUSDC'
  | 'APE'
  | 'SOL'
  | 'DUST';
  isClearingAccount?: boolean;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type GetLedgerAccountType = Omit<LedgerAccount, 'parentLedgerAccountId' | 'organizationId'> & {
  parentLedgerAccountId: string;
  organizationId: string;
};

export type GetLedgerAccountByIdType = Omit<LedgerAccount, 'userId' | 'organizationId' | 'parentLedgerAccountId'> & {
  userId: {
    _id: string;
    email: string;
  };
  parentLedgerAccountId: {
    _id: string;
    ledgerAccountName: string;
    ledgerAccountSequence: number;
  };
  children: {
    _id: string;
    ledgerAccountName: string;
    ledgerAccountSequence: number;
  }[];
  organizationId: string;
};

// this is a map of accountingPeriodId -> ledgerAccountBalance
export type BalancesMap = Record<string, { debitsAndCreditsPerLedgerAccount: DebitsAndCreditsPerLedgerAccountType[] }>;
export interface DebitsAndCreditsPerLedgerAccountType {
  debits: { value: string };
  credits: { value: string };
  // ledgerAccountId: string;
  openingBalance: { value: string };
  closingBalance: { value: string };
  currentBalance: { value: string };
}

export type ReportResponse = {
  accountingPeriods: AccountingPeriod[];
  ledgerAccounts: LedgerAccount[];
  balances: BalancesMap;
};

export type TrialBalanceResponse = {
  balances: (DebitsAndCreditsPerLedgerAccountType & { ledgerAccountId: string })[];
  ledgerAccounts: LedgerAccount[];
};

export type LegalEntityAddress = {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  country: string;
  _id: string;
};

export enum LEGAL_ENTITY_TYPE {
  C_CORP = 'C-CORP',
  LLC = 'LLC',
}

export enum LEGAL_ENTITY_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export type LegalEntity = {
  userId: User['_id'] | User;
  organizationId: Organization['_id'] | Organization;
  entityName: string;
  entityType?: LEGAL_ENTITY_TYPE;
  status?: LEGAL_ENTITY_STATUS;
  currency: string;
  addressString?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  address?: LegalEntityAddress;
};

export enum ACCOUNTING_PERIOD_STATUS {
  OPEN = 'Open',
  CLOSED = 'Closed',
  SOFT_CLOSED = 'Soft Closed',
}

export type AccountingPeriod = {
  organizationId: Organization['_id'] | Organization;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  closedBy?: User['_id'] | User;
  startDate: Date;
  endDate: Date;
  startDateUTC: Date;
  closedOnDate?: Date;
  status?: ACCOUNTING_PERIOD_STATUS;
  accountingPeriodName?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ExternalAccountPayload = {
  organizationId: string;
};

export type QuickBooksRedirectPayload = {
  organizationId: string;
  subdomain: string;
};

export type LedgerAccountMapping = {
  organizationId: string;
  glType: GLType;
  internalLedgerAccountId: string;
  externalLedgerAccountId: string;
};

export type LegalEntityMapping = {
  organizationId: string;
  glType: GLType;
  internalLegalEntityId: string;
  externalLegalEntityId: string;
};

export interface CreateSyncHistoryOperationRecordApiCallParams {
  organizationId: string;
  accountingPeriodId: string;
  userId: string;
}

export interface SyncHistory {
  _id: string;
  organizationId: string;
  accountingPeriodId: {
    _id: string;
    organizationId: string;
    startDate: string;
    endDate: string;
    closedOnDate: any;
    status: string;
    accountingPeriodName: string;
    createdAt: string;
    updatedAt: string;
  };
  createdBy: {
    _id: string;
    email: string;
    googleAuth: any;
    address: any;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
}

interface FromServer {
  success: boolean;
}

export interface GetSyncHistories extends FromServer {
  syncHistories: SyncHistory[];
}

export type JournalEntrySyncHistory = {
  organizationId?: string;
  accountingPeriodId: AccountingPeriod['_id'] | AccountingPeriod;
  createdBy: User['_id'] | User;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
};

export interface JournalEntrySyncRecord {
  organizationId?: string;
  accountingPeriodId: string;
  journalEntryId: string;
  externalJournalEntryId: string;
  glType: GLType;
  journalEntrySyncHistoryId: JournalEntrySyncHistory['_id'] | JournalEntrySyncHistory;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface GetSyncEntries extends FromServer {
  entries: JournalEntrySyncRecord[];
}

export type TagEntry = {
  organizationId: Organization['_id'] | Organization;
  key: string;
  value: string;
};

export enum TAG_STATUS {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  UNUSED = 'unused',
}

export type Tag = {
  usageCount: number;
  status: TAG_STATUS;
  createdBy: User['_id'] | User;
  lastUpdatedBy: User['_id'] | User;
  isDeleted?: boolean;
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  entry: TagEntry;
};

export enum WALLET_CHAIN {
  ETHEREUM = 'eth',
  POLYGON = 'polygon',
  ARBITRUM = 'arb',
  OPTIMISM = 'op',
  BASE = 'base',
  SOLANA = 'sol',
  BINANCE_SMART_CHAIN = 'bsc',
  AVALANCHE = 'avalanche',
  FANTOM = 'ftm',
  BITCOIN = 'btc',
  NEAR = 'near',
  LINEA = 'linea',
  RONIN = 'ronin',
  ZORA = 'zora',
  ZKSYNC = 'zksync',
  SCROLL = 'scroll',
  CELO = 'celo',
  XLM = 'xlm',
  XRP = 'xrp',
  CARDANO = 'cardano',
  CELESTIA = 'celestia',
  POLYGON_ZKEVM = 'polygon-zkevm',
  CRONOS = 'cro',
}

export enum WALLET_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum WALLET_TYPE {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
}

export type Wallet = {
  legalEntityId?: LegalEntity['_id'] | LegalEntity;
  userId: User['_id'] | User;
  organizationId: Organization['_id'] | Organization;
  chain: WALLET_CHAIN;
  status?: WALLET_STATUS;
  address?: string;
  addresses: string[];
  name?: string;
  balance?: number;
  lastBlockQueried?: number;
  lastPageQueried?: number;
  lastTransactionHashQueried?: string;
  lastDateQueried?: string;
  walletType: WALLET_TYPE;
  sourceType: string;
  tags: (Tag['_id'] | Tag)[];
  _id: string;
  createdAt?: string;
  updatedAt?: string;
};

export type BalanceTag = {
  tagId: Tag['_id'] | Tag;
  balance: number;
  _id: string;
};

export type Balance = {
  currentBalance?: number;
  openingBalance?: number;
  closingBalance?: number;
  currency?: string;
  legalEntityId?: LegalEntity['_id'] | LegalEntity;
  ledgerAccountId?: LedgerAccount['_id'] | LedgerAccount;
  accountingPeriodId?: AccountingPeriod['_id'] | AccountingPeriod;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  tags: BalanceTag[];
};

export enum AMOUNT_TYPE {
  GROSS_AMOUNT = 'GROSS_AMOUNT',
  NET_AMOUNT = 'NET_AMOUNT',
  FEE = 'FEE',
  GAIN_OR_LOSS = 'GAIN_OR_LOSS',
  IMPAIRMENT_AMOUNT = 'IMPAIRMENT_AMOUNT',
  ASSET_COST_BASIS = 'ASSET_COST_BASIS',
  VESTED_AMOUNT = 'VESTED_AMOUNT',
}

export enum CREDIT_OR_DEBIT {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export type JournalEntryLineTemplate = {
  ledgerAccountId: LedgerAccount['_id'] | LedgerAccount;
  tags: (Tag['_id'] | Tag)[];
  memo?: string;
  creditOrDebit: CREDIT_OR_DEBIT;
  amountType: AMOUNT_TYPE;
  allocation?: number;
  legalEntityId?: LegalEntity['_id'] | LegalEntity;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type JournalEntryLine = {
  idempotencyKey?: string;
  ledgerAccountId: LedgerAccount['_id'] | LedgerAccount;
  amount: {
    $numberDecimal: string;
  };
  creditOrDebit: CREDIT_OR_DEBIT;
  balanceId?: Balance['_id'] | Balance;
  memo?: string;
  metaData1?: any;
  metaData2?: any;
  metaData3?: any;
  metaData4?: any;
  deferred?: boolean;
  deferredTo?: Date;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  tags: (Tag['_id'] | Tag)[];
  updatedAsset?: boolean;
  journalEntryLineTemplateId?: JournalEntryLineTemplate['_id'] | JournalEntryLineTemplate;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AssetQuantity = {
  isReversed?: boolean;
  dateSold: Date;
  quantitySold: number;
  journalEntryLineId: JournalEntryLine['_id'] | JournalEntryLine;
  _id: string;
};

export type Asset = {
  quantity: number;
  remainingQuantity: number;
  costBasis: number;
  currency?: string;
  assetType: string;
  organizationId: Organization['_id'] | Organization;
  journalEntryLineId?: JournalEntryLine['_id'] | JournalEntryLine;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  ledgerAccountId?: LedgerAccount['_id'] | LedgerAccount;
  dateReceived: Date;
  isReversed?: boolean;
  isImpaired?: boolean;
  chain?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  quantities: AssetQuantity[];
};

export enum JOURNAL_ENTRY_STATUS {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  POSTED = 'POSTED',
  UNPOSTED = 'UNPOSTED',
  REVERSED = 'REVERSED',
  ERROR = 'ERROR',
}

export type AccountPostingRule = {
  accountPostingRuleSetId: AccountPostingRuleSet['_id'] | AccountPostingRuleSet;
  name?: string;
  createdBy: User['_id'] | User;
  topLevelCondition?: any;
  journalEntryTemplateId: JournalEntryTemplate['_id'] | JournalEntryTemplate;
  organizationId: Organization['_id'] | Organization;
  description?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AccountPostingRuleSet = {
  organizationId?: Organization['_id'] | Organization;
  userId: User['_id'] | User;
  runFrequency?: 'HOURLY' | 'DAILY' | 'WEEKLY' | 'MONTHLY';
  enabled?: boolean;
  legalEntities: (LegalEntity['_id'] | LegalEntity)[];
  hasGeneratedAccounting?: boolean;
  name: string;
  accountPostingRuleIds: (AccountPostingRule['_id'] | AccountPostingRule)[];
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type JournalEntryTemplate = {
  name: string;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  userId: User['_id'] | User;
  organizationId: Organization['_id'] | Organization;
  status: 'DRAFT' | 'POSTED' | 'ARCHIVED';
  memo?: string;
  externalReference?: string;
  journalEntriesGeneratedCount?: number;
  ruleIds: (AccountPostingRule['_id'] | AccountPostingRule)[];
  ruleSetIds: (AccountPostingRuleSet['_id'] | AccountPostingRuleSet)[];
  journalEntryLineTemplateIds: (JournalEntryLineTemplate['_id'] | JournalEntryLineTemplate)[];
  currency: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Transaction = {
  organizationId: Organization['_id'] | Organization;
  poolName: string;
  currentDepositedAssets: {
    $numberDecimal: string;
  };
  currentLendingBalance: {
    $numberDecimal: string;
  };
  currentWithdrownAssets: {
    $numberDecimal: string;
  };
  currentWithdrawnAssets: {
    $numberDecimal: string;
  };
  idempotencyKey?: string;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  blockNumber?: string;
  walletId?: Wallet['_id'] | Wallet;
  sourceId?: Wallet['_id'] | Wallet;
  sourceType?: 'WALLET' | 'EXCHANGE_SOURCE' | 'MAPLE';
  transactionHash?: string;
  transactionDate: Date;
  /**
   * In spice chains are id'd by the actual name like eth, btc, polygon
   * That is due to SQL queries
   */
  chain?: 'eth' | 'btc' | 'polygon' | 'sol' | 'bsc' | 'avalanche' | 'near';
  category: 'transaction' | 'transfer' | 'fee';
  assetType: string;
  toAddress?: string;
  fromAddress?: string;
  transactionDirection: 'Credit' | 'Debit';
  externalSource?: Wallet['_id'] | Wallet;
  grossAmount: {
    $numberDecimal: string;
  };
  fee: {
    $numberDecimal: string;
  };
  netAmount: {
    $numberDecimal: string;
  };
  nonce?: number;
  grossPrice: {
    $numberDecimal: string;
  };
  feePrice: {
    $numberDecimal: string;
  };
  netPrice: {
    $numberDecimal: string;
  };
  assetUnitPrice: {
    $numberDecimal: string;
  };
  currency: string;
  uniqueId?: string;
  sequenceNumber?: string;
  memo?: string;
  meta?: any;
  exchangeRefId?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  rawContractAddress?: string;
  classification:
  | 'SWAP'
  | 'DEPOSIT'
  | 'WITHDRAWAL'
  | 'BRIDGE'
  | 'INTERCOMPANY TRANSFER'
  | 'INTERNAL TRANSFER'
  | 'FEE'
  | 'MINTING'
  | 'SPAM'
  | 'NFT'
  | 'UNKNOWN';
};

export type JournalEntry = {
  idempotencyKey?: string;
  journalSequenceNumber: string;
  transactionId?: Transaction['_id'] | Transaction;
  accountingPeriodId: AccountingPeriod['_id'] | AccountingPeriod;
  accountingDate: Date;
  legalEntityId: LegalEntity['_id'] | LegalEntity;
  originatedBy: 'system' | 'user';
  status: 'DRAFT' | 'IN_PROGRESS' | 'POSTED' | 'UNPOSTED' | 'REVERSED' | 'ERROR';
  organizationId: Organization['_id'] | Organization;
  userId: User['_id'] | User;
  memo?: string;
  isSync?: boolean;
  walletId?: Wallet['_id'] | Wallet;
  accountPostingRuleId?: AccountPostingRule['_id'] | AccountPostingRule;
  journalEntryLineIds: JournalEntryLine['_id'][] | JournalEntryLine[];
  journalEntryTemplateId?: JournalEntryTemplate['_id'] | JournalEntryTemplate;
  tags: (Tag['_id'] | Tag)[];
  nextJournalEntryId?: JournalEntry['_id'] | JournalEntry;
  previousJournalEntryId?: JournalEntry['_id'] | JournalEntry;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
  externalReference?: string;
};

export type ImpairmentRule = {
  organizationId: Organization['_id'] | Organization;
  userId: User['_id'] | User;
  name: string;
  description?: string;
  sequence: number;
  topLevelCondition: any;
  journalEntryTemplateId: JournalEntryTemplate['_id'] | JournalEntryTemplate;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Impairment = {
  quantity: string;
  costBasis: string;
  assetId: Asset['_id'] | Asset;
  impairmentRuleId?: ImpairmentRule['_id'] | ImpairmentRule;
  journalEntryId?: JournalEntry['_id'] | JournalEntry;
  gainOrLoss?: 'GAIN' | 'LOSS';
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AssetWithExtraData = Asset & {
  impairedCostBasis: string;
  totalImpairedValue: string;
  currentValue: string;
  remainingValue: string;
  costBasis: string;
  quantity: string;
  currentValue_remainingValue: {
    currentValue: string;
    remainingValue: string;
  };
  quantity_remainingQuantity: {
    quantity: string;
    remainingQuantity: string;
  };
  gainLoss: string;
  price: string;
  gainLossPercentage: string;
  pricePaid: string;
  lastImpairedDate?: string;
  journalEntryAssetIn?: JournalEntry;
  journalEntriesAssetOut: JournalEntry[];
  impairments: Impairment[];
};

export type ExternalLedgerAccount = {
  externalLedgerAccountId: string;
  organizationId?: string;
  name: string;
  accountNumber?: string;
  parentAccount?: string;
  description?: string;
  accountType?: string;
  glType?: GLType;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ExternalLegalEntity = {
  externalLegalEntityId: string;
  organizationId?: string;
  realmId: string;
  name: string;
  address?: string;
  addressString?: string;
  entityType?: string;
  entityName?: string;
  currency?: string;
  _id: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type CreateLedgerAccountPayload = {
  ledgerAccount: Omit<LedgerAccount, '_id'>;
  organizationId: string;
};
