import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { useStatusFilterContext } from '../../../../context';

export const StatusFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useStatusFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Status' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((status, i) => (
            <CheckboxListItem
              key={i}
              label={status.value}
              isStatusLabel
              checked={status.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No status to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
