import React, { useEffect, useState } from 'react';
import { useConditionSelectorContext } from '../../condition-selector-context';
import { ConditionSelectorRow } from '../../ConditionSelectorRow';
import { FACT } from '../../../types';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import { assets, searchableAssets } from '../../../constants';
import { NEAR_TOKENS, SOL_TOKENS, SUPPORTED_ERC20_TOKEN } from '../../../../../../constants';
import { ConditionsGroup } from '../../ConditionsGroup';
import { useAddCondition } from '../../useAddCondition';
import { getElementIndex, useFilteredConditionGroups } from '../../utils';
import { ConditionGroupFilterConfig, CSRowProps, ConvertToRowFn, FilterFn } from '../../types';
import { useAssetTypes } from '../../../../../../hooks';

type TokenType = 'ERC - 20 Tokens' | 'SOL Tokens' | 'NEAR Tokens';

const checkIfInnerValueIsSelected = (tokenType: TokenType, selectedValue: string) => {
  switch (tokenType) {
    case 'ERC - 20 Tokens':
      return SUPPORTED_ERC20_TOKEN.map((token) => token.toLowerCase()).includes(selectedValue.toLowerCase());
    case 'NEAR Tokens':
      return NEAR_TOKENS.map((token) => token.toLowerCase()).includes(selectedValue.toLowerCase());
    case 'SOL Tokens':
      return SOL_TOKENS.map((token) => token.toLowerCase()).includes(selectedValue.toLowerCase());
  }
};

const getAssetSymbolFromAssetType = (type: string) => {
  if (type === 'ERC - 20 Tokens') return 'eth';
  if (type === 'SOL Tokens') return 'sol';
  if (type === 'NEAR Tokens') return 'near';
  return type.toLowerCase();
};

const defaultAssetConditionRows = assets.map((assetType) => ({
  rowData: {
    label: assetType,
    symbol: getAssetSymbolFromAssetType(assetType),
    value: assetType.toLocaleLowerCase(),
  },
  isMenuTrigger: ['ERC - 20 Tokens', 'SOL Tokens', 'NEAR Tokens'].includes(assetType),
  isSelected: false,
}));

export const filterNormalAssetsFn: FilterFn<string> = (searchTerm) => (v) =>
  v.toLowerCase().includes(searchTerm.toLowerCase());
const filterErcTokensFn: FilterFn<string> = (searchTerm) => (v) => v.toLowerCase().includes(searchTerm.toLowerCase());
const filterSolTokensFn: FilterFn<string> = (searchTerm) => (v) => v.toLowerCase().includes(searchTerm.toLowerCase());
const filterNearTokensFn: FilterFn<string> = (searchTerm) => (v) => v.toLowerCase().includes(searchTerm.toLowerCase());

export const convertAssetToRowFn: ConvertToRowFn<string> = (assetType) => ({
  rowData: {
    label: assetType,
    value: assetType.toLowerCase(),
    symbol: getAssetSymbolFromAssetType(assetType),
    isMenuTrigger: ['ERC - 20 Tokens', 'SOL Tokens', 'NEAR Tokens'].includes(assetType),
  },
  isSelected: false,
});

export const AssetConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={{
        ...conditionData.rowData,
        symbol: conditionData.rowData.label?.toLowerCase(),
      }}
      onClick={() =>
        addCondition({ fact: FACT.ASSET, value: conditionData.rowData.label?.toLowerCase(), operator: 'equal' })
      }
    />
  );
};

export const useAssetConditionGroups = () => {
  const { data: assetTypes = [] } = useAssetTypes();
  const [conditionGroups, setConditionGroups] = useState<Omit<ConditionGroupFilterConfig, 'rows'>[]>([
    {
      heading: 'My Assets',
      data: assetTypes,
      filterFn: filterNormalAssetsFn,
      convertToRowFn: convertAssetToRowFn,
      Row: AssetConditionSelectorRow,
    },
    {
      heading: 'All Assets',
      data: searchableAssets,
      filterFn: filterNormalAssetsFn,
      convertToRowFn: convertAssetToRowFn,
      Row: AssetConditionSelectorRow,
    },
    {
      heading: 'ERC Tokens',
      data: SUPPORTED_ERC20_TOKEN,
      filterFn: filterErcTokensFn,
      convertToRowFn: convertAssetToRowFn,
      Row: AssetConditionSelectorRow,
    },
    {
      heading: 'SOL Tokens',
      data: SOL_TOKENS,
      filterFn: filterSolTokensFn,
      convertToRowFn: convertAssetToRowFn,
      Row: AssetConditionSelectorRow,
    },
    {
      heading: 'NEAR Tokens',
      data: NEAR_TOKENS,
      filterFn: filterNearTokensFn,
      convertToRowFn: convertAssetToRowFn,
      Row: AssetConditionSelectorRow,
    },
  ]);

  useEffect(() => {
    if (assetTypes)
      setConditionGroups((prev) => {
        const array = [...prev];
        array.splice(0, 1, {
          heading: 'My Assets',
          data: assetTypes,
          filterFn: filterNormalAssetsFn,
          convertToRowFn: convertAssetToRowFn,
          Row: AssetConditionSelectorRow,
        });
        return array;
      });
  }, [assetTypes]);

  return conditionGroups;
};

export const AssetConditionsMenu = () => {
  const { state, debouncedSearchTerm, push } = useConditionSelectorContext();
  const [conditionsData, setConditionsData] = useState(defaultAssetConditionRows);
  const conditionGroups = useAssetConditionGroups();
  const groupedConditions = useFilteredConditionGroups(conditionGroups);

  useEffect(() => {
    const selectedValue = state.selected.value;

    const withSelectionData = selectedValue
      ? defaultAssetConditionRows.map((assetConditionData) => ({
          ...assetConditionData,
          isSelected: assetConditionData.isMenuTrigger
            ? checkIfInnerValueIsSelected(assetConditionData.rowData.label as TokenType, selectedValue)
            : assetConditionData.rowData.value === selectedValue.toLowerCase(),
        }))
      : defaultAssetConditionRows;

    setConditionsData(withSelectionData);
  }, [state.selected.value]);

  // console.log('AssetConditionsMenu', conditionsData);

  return (
    <ConditionsGroup label='All Assets'>
      {debouncedSearchTerm === '' && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, row) =>
            row.isMenuTrigger ? (
              <ConditionSelectorRow key={index} {...row} onClick={() => push(row.rowData.label as string)} />
            ) : (
              <AssetConditionSelectorRow key={index} conditionData={row} />
            )
          }
        />
      )}
      {debouncedSearchTerm !== '' && (
        <>
          {groupedConditions.headings.length === 0 && <div className='text-center my-2'>No matching results found</div>}
          {groupedConditions.headings.length > 0 && (
            <GroupedVirtuoso
              style={{ height: 250 }}
              groupCounts={groupedConditions.counts}
              itemContent={(index, groupIndex) => {
                const internalIndex = getElementIndex(groupedConditions.values, index); // index is flattened index, we need index of item in its parent array
                const assetRowData = groupedConditions.values[groupIndex][internalIndex];

                return <AssetConditionSelectorRow key={index} conditionData={assetRowData} />;
              }}
              groupContent={(groupIndex) => (
                <div className='font-medium text-zinc-500 p-1 bg-white'>{groupedConditions.headings[groupIndex]}</div>
              )}
            />
          )}
        </>
      )}
    </ConditionsGroup>
  );
};
