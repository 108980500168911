import { styled } from '@stitches/react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

export const StyledSwitch = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: 48,
  height: 26,
  backgroundColor: '#e9e9e9',
  borderRadius: '20px',
  position: 'relative',
  border: '1px solid #0000001A',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&[data-state="checked"]': {
    backgroundColor: '#1D4ED8',
  },
});

export const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: 22,
  height: 22,
  borderRadius: '9999px',
  transition: 'all 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  backgroundColor: 'white',
  boxShadow: '0px 0px 4px 1px #7f7f7f38',
  '&[data-state="checked"]': {
    transform: 'translateX(24px)',
  },
});

export const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'fit-content',
  height: 'fit-content',
});

export const Label = styled('label', {
  color: 'black',
  fontSize: 16,
  lineHeight: 1,
  userSelect: 'none',
  textAlign: 'left',
});
