import { useEffect, useState } from 'react';
import { useSession } from './useSession';
import { DatePickerSelection } from '../components/DatePickerComp.types';

export type DateInterval = {
  start?: string;
  end?: string;
};

export const useDateSelections = () => {
  const { selectedOrganization } = useSession();

  const [dateSelections, setDateSelections] = useState<DatePickerSelection[]>([]);
  const [datesWithTzOffset, setDatesWithTzOffset] = useState<DateInterval>({});

  useEffect(() => {
    if (!selectedOrganization) return;
    if (!dateSelections[0]) return;
    const next: DateInterval = {};
    if (dateSelections[0]?.startDate) next.start = new Date(dateSelections[0].startDate).toISOString()
    if (dateSelections[0]?.endDate) next.end = new Date((dateSelections[0].endDate)).toISOString()

    setDatesWithTzOffset(next);
  }, [dateSelections, selectedOrganization]);

  return { dateSelections, setDateSelections, datesWithTzOffset };
};
