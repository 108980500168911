import { useQuery } from '@tanstack/react-query';
import { getOverviewData } from 'services/http/core';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useOverviewData = (organizationId) => {
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.OVERVIEW_DATA),
    async () => {
      const response = await getOverviewData({
        organizationId,
      });
      const { overviewData } = response.data;
      return overviewData;
    },
    {
      enabled: !!organizationId,
    },
  );
};
