import React, { useContext, useEffect, useState } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { TableHead } from '@/shadcn/ui/table';
import { cn } from '@/shadcn/lib/utils';
import { getCommonPinningStyles } from './utils';
import { TanStackContext } from 'src/context/TanStackTableProvider';
import { Dropdown, DropdownContent, DropdownTrigger } from 'ui';
import { HiMenuAlt2 } from 'react-icons/hi';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { RiEyeOffFill } from 'react-icons/ri';
import { useColumnVisibility } from 'src/hooks';
import { ColumnsProps, HeaderProps } from '../columns/types';
import { TABLE_RESIZE_MIN_WIDTH } from 'src/constants';
import { SortDirection, SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { useDensity } from 'src/context';

const MotionComponent = motion(TableHead, { forwardMotionProps: true });

const TanStackTableHeader = ({
  header,
  enableRowSelection,
  showBoxShadow = false,
  enableHide = true,
  enableSort = true,
  setSorting,
  sort,
  columns,
  tableContainerHeight = 48,
  children,
  disableColumnPinning,
}: {
  header: HeaderProps<any, unknown>;
  enableRowSelection: boolean;
  showBoxShadow?: boolean;
  enableHide?: boolean;
  enableSort?: boolean;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  sort: false | SortDirection;
  columns: ColumnsProps<any, any>[];
  tableContainerHeight?: number;
  children: React.ReactNode;
  isDraggableTable?: boolean;
  disableColumnPinning?: boolean;
}) => {
  const DEFAULT_MIN_WIDTH = header.id === 'select' ? 48 : 250;
  const [minWidth, setMinWidth] = useState(DEFAULT_MIN_WIDTH);
  const width: any = useMotionValue(minWidth);
  const [isDragging, setIsDragging] = useState(false);
  const { isDragging: isDraggingContext, setIsDragging: setIsDraggingContext } = useContext(TanStackContext);

  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { columnVisibility, saveColumnVisibility } = useColumnVisibility(columns);
  const route = useRouter();
  const wideCell = route.pathname === '/ledger/sources';

  useEffect(() => {
    if (header.id !== 'select') {
      ref.current?.offsetWidth && width.set(ref.current?.offsetWidth);
    }
  }, [columnVisibility]);

  useEffect(() => {
    if (ref.current?.offsetWidth !== width.get() && header.id !== 'select') {
      width.set(ref.current?.offsetWidth);
    }
  }, [isDraggingContext]);

  const { density } = useDensity();

  return (
    <MotionComponent
      ref={ref}
      key={header.id}
      id={header.id}
      colSpan={header.colSpan}
      style={{
        width,
        minWidth,
        ...getCommonPinningStyles(header.column, enableRowSelection, false, wideCell, disableColumnPinning).style,
        zIndex: (getCommonPinningStyles(header.column, enableRowSelection, false, wideCell)?.style?.zIndex as any) + 1,
        backgroundColor: 'white', // Ensure the background is solid
      }}
      className={cn('relative [&_.view-resize-indicator]:hover:bg-[#00000080] whitespace-nowrap')}
    >
      <div
        className={cn(
          header.column.getIndex() === 0 && !enableRowSelection && 'pl-3',
          'w-[inherit] overflow-y-clip',
          header.id === 'select' && 'mx-auto',
          {
            compact: 'h-[36px]',
            default: 'h-[48px]',
            comfortable: 'h-[64px]',
          }[density],
        )}
      >
        <div
          style={{
            ...getCommonPinningStyles(header.column, enableRowSelection, showBoxShadow, wideCell).divStyle,
          }}
          className={cn(
            header.id !== 'select' && 'pr-3',
            'h-full flex items-center',
            header.id === 'select' && 'justify-center',
            header.column.columnDef?.textAligned === 'text-right' &&
              'justify-start flex-row-reverse [&_svg]:scale-x-[-1]',
          )}
        >
          {header.id === 'select' ? children : <div className='truncate sentence-case'>{children}</div>}

          {(enableHide || enableSort) &&
            header.column.columnDef.accessorKey !== 'editBtn' &&
            !!header.column.getIndex() && (
              <Dropdown open={isOpen} onOpenChange={setIsOpen}>
                <DropdownTrigger>
                  <button>
                    {!sort && <HiMenuAlt2 className='w-9 h-9 p-2 text-zinc-400' />}
                    {sort === 'asc' && <GoArrowUp className='w-9 h-9 p-2 text-zinc-400' />}
                    {sort === 'desc' && <GoArrowDown className='w-9 h-9 p-2 text-zinc-400' />}
                  </button>
                </DropdownTrigger>
                <DropdownContent className='z-sidepanel p-1 flex flex-col gap-1 text-left border-none shadow-[0px_16px_32px_-4px_rgba(0,_0,_0,_0.12)] min-w-full w-[14rem] rounded-lg'>
                  {enableSort && (
                    <>
                      <button
                        onClick={() => {
                          if (sort === 'asc') {
                            setSorting([]);
                            setIsOpen(false);
                            return;
                          }
                          setSorting([{ id: header.column.columnDef?.accessorKey?.toString() ?? '', desc: false }]);
                          setIsOpen(false);
                        }}
                        className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-md hover:cursor-pointer hover:bg-indigo-50'
                      >
                        <GoArrowUp className='w-6 h-6 py-0.5' />
                        <span>Sort Ascending</span>
                      </button>
                      <button
                        onClick={() => {
                          if (sort === 'desc') {
                            setSorting([]);
                            setIsOpen(false);
                            return;
                          }
                          setSorting([{ id: header.column.columnDef?.accessorKey?.toString() ?? '', desc: true }]);
                          setIsOpen(false);
                        }}
                        className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-md hover:cursor-pointer hover:bg-indigo-50'
                      >
                        <GoArrowDown className='w-6 h-6 py-0.5' />
                        <span>Sort Descending</span>
                      </button>
                    </>
                  )}

                  {enableHide && (
                    <button
                      onClick={() => {
                        saveColumnVisibility({
                          accessorKey: header.column.columnDef?.accessorKey ?? '',
                          visibility: false,
                          header: header.column.columnDef.header,
                        });
                        setIsOpen(false);
                      }}
                      className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-md hover:cursor-pointer hover:bg-indigo-50'
                    >
                      <RiEyeOffFill className='w-6 h-6 py-0.5' />
                      <span>Hide</span>
                    </button>
                  )}
                </DropdownContent>
              </Dropdown>
            )}
        </div>
      </div>
      {header.column.getCanResize() && (
        <motion.div
          drag='x'
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
          dragElastic={0}
          dragMomentum={false}
          onDrag={(event, info) => {
            const currentWidth = width.get()
            const newWidth = currentWidth + info.delta.x;
            if (TABLE_RESIZE_MIN_WIDTH <= newWidth) {
              width.set(width.get() + info.delta.x);
            }
          }}
          onDragEnd={() => {
            const endWidth = width.get();
            setIsDragging(false);
            setIsDraggingContext(false);
            setMinWidth(endWidth);
          }}
          onDragStart={(event) => {
            if (ref.current?.offsetWidth !== width.get()) {
              width.set(ref.current?.offsetWidth);
            }

            setIsDragging(true);
            setIsDraggingContext(true);
            setMinWidth(0);

            const targetElement = event.target as HTMLElement;
            const wrappingTable = targetElement.closest('table')
            if (wrappingTable && wrappingTable.classList.contains('w-full')) {
              wrappingTable.classList.remove('w-full');
            }

          }}
          style={{
            height: tableContainerHeight,
          }}
          className={cn(
            'view-resize-indicator cursor-col-resize text-center absolute top-0 right-0 h-full w-1 bg-transparent duration-150',
            isDragging && '!bg-blue-500',
          )}
        />
      )}
    </MotionComponent>
  );
};

export default TanStackTableHeader;
