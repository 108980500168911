import bigDecimal from 'js-big-decimal';
import { useReadLocalStorage } from 'usehooks-ts';
import { toast } from 'react-hot-toast';
import { toPng, toSvg } from 'html-to-image';
import { JobConfiguration } from 'schemas';

export * from './download-csv';
export * from './journal-entry';
export * from './qb-sync-history';
export * from './transaction';
export * from './mixpanel';

export const shrink = (str: string | undefined, len = 7) => {
  if (!str) {
    return '';
  }
  if (str.length <= len) {
    return str;
  }
  return `${str.substring(0, len)}...${str.substring(str.length - len, str.length)}`;
};

export const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    const subdomain = hostname.split('.')[0];
    if (subdomain === 'com') {
      return 'entendre';
    }
    return subdomain;
  }
  return;
};

export const useGetSubdomain = () => {
  const session = useReadLocalStorage<any>('session');
  const subdomain = session?.selectedOrganization?.webAddress ?? 'entendre';
  return subdomain;
};

export const getHost = () => {
  if (typeof window !== 'undefined') {
    const { host, protocol } = window.location;
    return `${protocol && `${protocol}//`}${host}`;
  }
  return '';
};

export const getValueFromBigDecimal = (value) => {
  return value.$numberDecimal;
};

export const getBigDecimal = (value: string | number | bigint | undefined) => {
  return new bigDecimal(value);
};

/**
 *
 * @param chartId - the id of the chart to export (assigned id to the Tremor's chart component)
 * @param ignore - classNames to ignore when exporting the chart
 */
export const exportChart = async (params: {
  chartId: string;
  ignore?: string[];
  format?: 'svg' | 'png';
  title?: string;
}) => {
  const { chartId, ignore = [], format = 'png', title } = params;
  const filter = (node: HTMLElement) => {
    const classesToIgnore = ['ignore-export', ...ignore];
    // html-to-image does not support remote images
    if (node.tagName === 'IMG') {
      return false;
    }

    if (node.classList) {
      const classList = Array.from(node.classList.values());
      return !classList.some((className) => classesToIgnore.includes(className));
    } else {
      return true;
    }
  };

  try {
    const elem = document.getElementById(chartId);
    if (!elem) return;
    const url =
      format === 'svg'
        ? await toSvg(elem, { cacheBust: true, pixelRatio: 2, filter })
        : await toPng(elem, { cacheBust: true, pixelRatio: 2, filter });
    // add title to image
    const canvas = document.createElement('canvas');
    canvas.width = elem.clientWidth * 2;
    canvas.height = elem.clientHeight * 2 + 100;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = url;
    img.onload = async () => {
      if (ctx) {
        if (format === 'png') {
          ctx.drawImage(img, 0, 100);
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, elem.clientWidth * 2, 100);
          if (title) {
            ctx.font = '40px sans-serif';
            ctx.fillStyle = '#000';
            ctx.fillText(title, 40, 50);
          }
          const a = document.createElement('a');
          const dataUrl = canvas.toDataURL('image/png');
          a.href = dataUrl;
          a.download = `${chartId}.${format}`;
          a.click();
          a.remove();
        } else if (format === 'svg') {
          // need to add title text to svg
          const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
          svgElem.setAttribute('width', `${elem.clientWidth * 2}`);
          svgElem.setAttribute('height', `${elem.clientHeight * 2 + 100}`);
          svgElem.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
          svgElem.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
          svgElem.setAttribute('version', '1.1');
          const svgTitle = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          svgTitle.setAttribute('x', '40');
          svgTitle.setAttribute('y', '70');
          svgTitle.setAttribute('font-size', '40px');
          svgTitle.setAttribute('font-family', 'sans-serif');
          svgTitle.setAttribute('fill', '#000');
          svgTitle.textContent = title || '';
          svgElem.appendChild(svgTitle);
          const svgImg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
          svgImg.setAttribute('href', url);
          svgImg.setAttribute('width', `${elem.clientWidth * 2}`);
          svgImg.setAttribute('height', `${elem.clientHeight * 2}`);
          svgImg.setAttribute('x', '0');
          svgImg.setAttribute('y', '100');
          svgElem.appendChild(svgImg);
          const svgData = new XMLSerializer().serializeToString(svgElem);
          const a = document.createElement('a');
          const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
          const dataUrl = URL.createObjectURL(blob);
          a.href = dataUrl;
          a.download = `${chartId}.${format}`;
          a.click();
          a.remove();
        }
      }
    };
  } catch (error) {
    console.log(error);
    toast.error('Error exporting chart');
  }
};

export const incrementalUpdateForJob = (nextConfig: JobConfiguration, currentConfig?: JobConfiguration) => {
  if (currentConfig?.jobStatus && ['CANCELED', 'COMPLETED', 'JOB_FAILED'].includes(currentConfig.jobStatus)) {
    // console.log('update skipped as job already completed');
    return currentConfig;
  }
  // console.log('job status updated');
  return nextConfig;
};
export const updateStatusSlice = (status, setStatus, update) => {
  const slice = status[update.payload.jobConfig.referenceId];
  if (slice) {
    const next = { ...slice, jobConfig: incrementalUpdateForJob(update.payload.jobConfig, slice.jobConfig) };
    setStatus((prev) => ({ ...prev, [update.payload.jobConfig.referenceId]: next }));
  }
  // else
  // console.log('update skipped as slice not found');
};
