import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLedgerAccount, useLegalEntities } from '../../hooks/http';
import {
  getDisplayedAssets,
  getDisplayedLedgerAccounts,
  getDisplayedLegalEntities,
  mergeLedgerAccounts,
  mergeLegalEntities,
} from '../templates/utils';
import {
  InputLabel,
  InputWithExtras,
  NumberWithUnitInput,
  DateInput,
  CryptoCurrencyMenu,
  InputError,
  SingleSelectMenu,
  CryptoSelectMenu,
} from 'ui';
import { isValid, isEqual } from 'date-fns';

const DEFAULT_DATE = new Date();

export const AddAssetForm = ({ selectedAsset = false, setParentAsset }) => {
  const { data: legalEntities, isLoading: legalEntitiesLoading } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const { data: ledgerAccounts, isLoading: ledgerAccountLoading } = useLedgerAccount({
    pageSize: 1000,
    ledgerAccountTypes: ['Asset'],
  });
  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLedgerAccounts = getDisplayedLedgerAccounts(mergedLedgerAccounts);
  const [asset, setAsset] = useState<{
    ledgerAccountId?: string;
    assetType?: string;
    quantity?: string;
    costBasis?: string;
    dateReceived: Date | null;
    legalEntityId?: string;
  }>(
    (selectedAsset as any) || {
      dateReceived: DEFAULT_DATE,
    },
  );

  const [showDateError, setShowDateError] = useState(false);

  useEffect(() => {
    if (asset.dateReceived)
      setShowDateError(!isEqual(DEFAULT_DATE, asset.dateReceived) && !isValid(asset.dateReceived));
    else setShowDateError(true);
  }, [asset.dateReceived]);

  useEffect(() => {
    if (setParentAsset) setParentAsset(asset);
  }, [asset, setParentAsset]);

  const onAssetQuantityChange = ({ target }) =>
    setAsset({
      ...asset,
      quantity: target.value,
    });

  const onCostBasisChange = ({ target }) =>
    setAsset({
      ...asset,
      costBasis: target.value,
    });

  return (
    <>
      <div>
        <InputLabel heading='Legal entity' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={displayedLegalEntities}
          onChange={(option) => {
            setAsset({ ...asset, legalEntityId: option.value });
          }}
          isLoading={legalEntitiesLoading}
          value={displayedLegalEntities.find((item) => item.value === asset.legalEntityId)}
          onClearValue={() => setAsset({ ...asset, legalEntityId: undefined })}
        />
      </div>
      <div>
        <InputLabel heading='Ledger account' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={displayedLedgerAccounts}
          onChange={(option) => {
            setAsset({ ...asset, ledgerAccountId: option.value });
          }}
          isLoading={ledgerAccountLoading}
          value={displayedLedgerAccounts.find((item) => item.value === asset.ledgerAccountId)}
          onClearValue={() => setAsset({ ...asset, ledgerAccountId: undefined })}
        />
      </div>
      <div>
        <InputLabel heading='Asset type' />

        <CryptoSelectMenu
          fullWidth
          isOnSidepanel
          onChange={(option) => {
            setAsset({ ...asset, assetType: option.value });
          }}
          value={{
            label:
              getDisplayedAssets().find((item) => item.value === asset.assetType)?.label ||
              asset.assetType?.toUpperCase(),
            value:
              getDisplayedAssets().find((item) => item.value === asset.assetType)?.value ||
              asset.assetType?.toUpperCase() ||
              '',
            icon:
              getDisplayedAssets().find((item) => item.value === asset.assetType)?.value ||
              asset.assetType?.toUpperCase(),
          }}
          showCreateMissingOption
        />
      </div>
      <div>
        <InputLabel heading='Asset quantity' />
        <NumberWithUnitInput onChange={onAssetQuantityChange} value={asset.quantity} />
      </div>
      <div>
        <InputLabel heading='Cost basis (Asset Unit Price)' />
        <InputWithExtras type='number' onChange={onCostBasisChange} value={asset.costBasis} />
      </div>
      <div>
        <InputLabel heading='Date received' />
        <DateInput
          date={isValid(asset.dateReceived) ? asset.dateReceived : null}
          setDate={(date) =>
            setAsset((prev) => ({
              ...prev,
              dateReceived: date,
            }))
          }
        />
        <InputError message='Please enter a valid date' showError={showDateError} />
      </div>
    </>
  );
};
