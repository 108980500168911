import { Sidebar } from '../../atoms/Sidebar';
import { SidebarProps } from '../SidebarGlobal';

const TagSidebar: React.FC<SidebarProps> = () => {
  return (
    <Sidebar>
      <div>Tag sidebar is not configured. TODO: get single tag API</div>
    </Sidebar>
  );
};

export default TagSidebar;
