import axios from 'axios';

import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
import { prepareSearchParams } from '../core/utils';

//

export const createRulesetsForTransactionClassification = async (params: {
  userId: string;
  organizationId: string;
}) => {
  const searchParams = prepareSearchParams(params, { noExtras: true });

  const response = await axios.post<{ status: string }>(
    `${SERVER_URL_CONFIGURATION_SERVICE}/create-rulesets-for-transaction-classification?${searchParams}`,
  );
  return response;
};
