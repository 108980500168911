import { createContext, useContext } from 'react';
import { OrganizationPickerContextType } from './types';

const defaultOrganizationPickerContextState: OrganizationPickerContextType = {
  selectedOrganizationId: '',
  setSelectedOrganizationId: () => {},
};

export const OrganizationPickerContext = createContext<OrganizationPickerContextType>(
  defaultOrganizationPickerContextState,
);

export const useOrganizationPickerContext = () => useContext(OrganizationPickerContext);
