import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { DayPicker } from 'react-day-picker';
import { classNames } from '../utils';
import { CalendarProps } from './types';
import { DatePickerContainer, getCalendarStyles } from './style';

function Calendar({
  className,
  containerClassNames = '',
  calendarStyles,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DatePickerContainer>
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={classNames('relative', className)}
        classNames={getCalendarStyles(props.mode, calendarStyles)}
        components={{
          IconLeft: ({ ...props }) => <MdChevronLeft className='h-6 w-6' />,
          IconRight: ({ ...props }) => <MdChevronRight className='h-6 w-6' />,
        }}
        {...props}
      />
    </DatePickerContainer>
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
