import { useEffect, useState } from 'react';
import { useLegalEntities } from './useLegalEntities';
import { LegalEntity } from 'services/http/response.types';

export const useAllLegalEntities = () => {
  const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);
  const { data, isLoading } = useLegalEntities();

  useEffect(() => {
    if (!isLoading && data) setLegalEntities(data.pages.flat());
  }, [data, isLoading]);

  return { legalEntities, isLoading };
};
