import { forwardRef, useMemo } from 'react';
import { useAllLegalEntities, useAllTags } from '../../hooks';
import { getDisplayedLegalEntities, getDisplayedTags } from '../templates/utils';
import {
  Card,
  InputLabel,
  InputWithExtras,
  MultiSelectMenu,
  SectionHeader,
  SelectableCardWithRadio,
  SingleSelectMenu,
} from 'ui';
import { TagsInput } from '../atoms';
import { WALLET_CHAIN, WALLET_TYPE } from 'services/http/response.types';
import { SUPPORTED_CHAIN, SUPPORTED_CHAIN_STRINGS, WALLET_TYPE_OPTIONS } from '../Sources';
import { getAssetsInfo } from '../../constants';
// import { DatePicker } from '@tremor/react';
import { format, startOfMonth, startOfYear, subMonths } from 'date-fns';
import DatePicker from 'react-datepicker';
import { MdCalendarToday } from 'react-icons/md';
//import { SUPPORTED_CHAINS_WITHOUT_ICON, WALLET_TYPE_OPTIONS } from '../Sources/constants';

export interface CreateSourceFormState {
  walletType: WALLET_TYPE;
  name: string;
  chain: WALLET_CHAIN;
  address: string;
  addresses: string[];
  legalEntityId?: string;
  parentWalletId?: string;
  tags: string[];
  import?: string;
  importStartDate?: Date;
}

export interface CreateSourceFormElementsProps {
  formState: CreateSourceFormState;
  setFormState: React.Dispatch<React.SetStateAction<CreateSourceFormState>>;
  showTagsFormElement?: boolean;
  'data-cy'?: string;
}

const backfillLabels = {
  monthToDate: 'Month to date',
  past3MonthsToDate: 'Last 3 months to date',
  pastYearToDate: 'Year to date',
  custom: 'Custom date range',
};

const DatePickerInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <button ref={ref} onClick={onClick} className='flex items-center w-full border rounded-lg p-2 px-3 justify-between'>
    <span className='flex-grow flex items-center gap-3'>
      <MdCalendarToday />
      <span>{format(new Date(value), 'LLL d, yyyy')} - Today</span>
    </span>
  </button>
));
DatePickerInput.displayName = 'DatePickerInput';

export const CreateSourceFormElements = ({
  formState,
  setFormState,
  showTagsFormElement = true,
  'data-cy': dataCy = 'createSource',
}: CreateSourceFormElementsProps) => {
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);

  const { tags, isLoading: isLoadingTags } = useAllTags();
  const TAG_OPTIONS = useMemo(() => getDisplayedTags(tags) ?? [], [tags]);

  const setStartDate = (importType, importStartDate) =>
    setFormState((prev) => ({ ...prev, import: importType, importStartDate }));

  return (
    <>
      <div>
        <InputLabel heading='Wallet type' />

        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          placeholder='Select wallet type'
          enableSearch={false}
          onClearValue={() => setFormState((prev) => ({ ...prev, walletType: WALLET_TYPE.INTERNAL }))}
          options={WALLET_TYPE_OPTIONS}
          onChange={(option) => {
            setFormState((prev) => {
              const values = {
                walletType: option.value as WALLET_TYPE,
                legalEntityId: option.value === 'external' ? undefined : prev.legalEntityId,
                import: option.value === 'external' ? undefined : prev.import,
              };
              return { ...prev, ...values };
            });
          }}
          value={WALLET_TYPE_OPTIONS.find((item) => item.value === formState.walletType)}
          data-cy={`${dataCy}__walletType`}
        />
      </div>
      <div>
        <InputLabel heading='Wallet name' />
        <InputWithExtras
          onChange={(e) => setFormState((prev) => ({ ...prev, name: e.target.value }))}
          value={formState.name}
          data-cy={`${dataCy}__walletName`}
        />
      </div>
      <div>
        <InputLabel heading='Chain' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          isModal
          enableAvatar
          options={SUPPORTED_CHAIN.map((chain) => ({
            label: getAssetsInfo(chain.value)?.name,
            bottomText: getAssetsInfo(chain.value)?.abbreviation,
            value: chain.value,
            icon: chain.value,
          }))}
          onChange={(option) => setFormState((prev) => ({ ...prev, chain: option.value as any }))}
          value={{
            label: SUPPORTED_CHAIN.find((chain) => chain.value === formState.chain)?.label,
            value: formState.chain,
            icon: formState.chain,
          }}
          enableBottomText
          data-cy={`${dataCy}__walletChain`}
        />
      </div>
      {/**
       * @todo: use a constant for the chain types
       */}
      {formState.chain && SUPPORTED_CHAIN_STRINGS.includes(formState.chain as any) && (
        <div>
          <InputLabel heading='Wallet address' />
          <InputWithExtras
            value={formState.address}
            onChange={(e) => setFormState((prev) => ({ ...prev, address: e.target.value }))}
            data-cy={`${dataCy}__walletAddress`}
          />
        </div>
      )}

      {formState.chain === 'btc' && (
        <div>
          <TagsInput
            label='Wallet address'
            tags={formState.addresses}
            onEnter={(addressesString) => {
              const addressesArray = addressesString.split(',').map((str) => str.trim());
              setFormState((prev) => ({
                ...prev,
                addresses: prev?.addresses ? [...prev.addresses, ...addressesArray] : addressesArray,
              }));
            }}
            onRemove={(removedAddress) =>
              setFormState((prev) => ({
                ...prev,
                addresses: prev.addresses.filter((address) => address !== removedAddress),
              }))
            }
          />
        </div>
      )}

      <div>
        <InputLabel heading='Legal entity' />
        <SingleSelectMenu
          isModal
          fullWidth
          isOnSidepanel
          options={LEGAL_ENTITY_SELECT_OPTIONS}
          disabled={formState.walletType === 'external'}
          placeholder='Assign legal entity'
          isLoading={isLoadingEntities}
          data-cy={`${dataCy}__legalEntity`}
          onChange={(option) => {
            setFormState((prev) => ({ ...prev, legalEntityId: option.value }));
          }}
          value={LEGAL_ENTITY_SELECT_OPTIONS.find((item) => item.value === formState.legalEntityId)}
          onClearValue={() => setFormState((prev) => ({ ...prev, legalEntityId: undefined as any }))}
        />
      </div>
      {showTagsFormElement && (
        <div>
          <InputLabel heading='Tags' />
          <MultiSelectMenu
            side='top'
            dropdownContentClassName='!max-h-[300px]'
            fullWidth
            isOnSidepanel
            options={TAG_OPTIONS}
            onChange={(options) => {
              setFormState((prev) => ({ ...prev, tags: options.map((option) => option.value) }));
            }}
            value={TAG_OPTIONS.filter((tag) => formState.tags.includes(tag.value))}
            isLoading={isLoadingTags}
            data-cy={`${dataCy}__tags`}
            onClearValue={() => setFormState((prev) => ({ ...prev, tags: [] }))}
          />
        </div>
      )}
      {formState.walletType === 'internal' && (
        <>
          <SectionHeader
            heading={'Transaction backfill'}
            description={'How far back would you like to pull transactions?'}
          />
          <div className='grid gap-3'>
            <SelectableCardWithRadio
              onClick={() => setStartDate(backfillLabels.monthToDate, startOfMonth(new Date()))}
              label={backfillLabels.monthToDate}
              selected={formState.import === backfillLabels.monthToDate}
            />
            <SelectableCardWithRadio
              onClick={() => setStartDate(backfillLabels.past3MonthsToDate, startOfMonth(subMonths(new Date(), 3)))}
              label={backfillLabels.past3MonthsToDate}
              selected={formState.import === backfillLabels.past3MonthsToDate}
            />
            <SelectableCardWithRadio
              onClick={() => setStartDate(backfillLabels.pastYearToDate, startOfYear(new Date()))}
              label={backfillLabels.pastYearToDate}
              selected={formState.import === backfillLabels.pastYearToDate}
            />
            <SelectableCardWithRadio
              onClick={() => setStartDate(backfillLabels.custom, startOfYear(new Date()))}
              label={backfillLabels.custom}
              selected={formState.import === backfillLabels.custom}
            />
          </div>
          {formState.import === backfillLabels.custom && (
            <div>
              <Card>
                <InputLabel
                  heading='Select custom start date'
                  description='Import transactions from the start date to today.'
                />
                <DatePicker
                  selected={formState.importStartDate}
                  onChange={(date) => setStartDate(backfillLabels.custom, date)}
                  customInput={<DatePickerInput value={formState.importStartDate} />}
                />
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
};
