import React from 'react';
import { MetricCard, MetricGroup } from '../../atoms';
import { formatDollars } from '../../templates/utils';
import { useGetAssetMetrics } from '../../../hooks/http/useAssets';
import { useAssetQueryParams } from './use-asset-query-params';

export const AssetsMetric = ({ searchTerm }) => {
  const assetParams = useAssetQueryParams({ searchTerm });
  const { data: metrics, isLoading: metricsLoading } = useGetAssetMetrics({
    ...assetParams,
    accountingPeriodStartDateUTCs: assetParams.accountingPeriodStartDateUTCs?.map((date) =>
      new Date(date).toISOString(),
    ),
  });

  return (
    <MetricGroup className='!mb-0 !mt-4'>
      <MetricCard loading={metricsLoading} label='Asset value' metric={formatDollars(metrics?.assetValue, true)} />
      <MetricCard loading={metricsLoading} label='Impairment gain' metric={formatDollars(metrics?.gainAmount, true)} />
      <MetricCard
        loading={metricsLoading}
        label='Impairment loss'
        metric={`${formatDollars(metrics?.lossAmount, true)}`}
      />
    </MetricGroup>
  );
};

export default AssetsMetric;
