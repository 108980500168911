import React, { useState, forwardRef } from 'react';
import { MdLock, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { InputWithExtras } from '../input-with-extras';
import { PasswordInputProps } from './types';

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(function PasswordInput(props, ref) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <InputWithExtras
      {...props}
      ref={ref}
      leading={<MdLock className='w-5 h-5' />}
      type={isVisible ? 'text' : 'password'}
      autoComplete={props.unblockAutoComplete ? '' : 'new-password'}
      trailing={
        <button onClick={() => setIsVisible((prev) => !prev)} disabled={props.disabled}>
          {!isVisible ? <MdVisibility className='w-5 h-5' /> : <MdVisibilityOff className='w-5 h-5' />}
        </button>
      }
    />
  );
});
