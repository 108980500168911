import { motion, MotionProps } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { classNames } from 'ui';

interface SidebarAnimProps extends MotionProps {
  children?: React.ReactNode;
  className?: string;
  variant?: string;
  stopAnimation?: boolean;
  'data-cy'?: string;
}

export const SidebarAnim = ({
  children,
  className,
  variant = 'root',
  stopAnimation = false,
  'data-cy': dataCy,
  ...props
}: SidebarAnimProps) => {
  const [navBarTopOffset, setNavBarTopOffset] = useState(0);
  useEffect(() => {
    const element = document.getElementById('nav-bar');
    function handleScroll() {
      setNavBarTopOffset(element?.getBoundingClientRect()?.top ?? 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <motion.div
      initial={!stopAnimation && { opacity: 0, right: '-70%' }}
      animate={!stopAnimation && { opacity: 1, right: '0%' }}
      exit={{ opacity: 0, right: '-70%' }}
      transition={{ duration: 0.3 }}
      style={{
        top: navBarTopOffset > -65 ? navBarTopOffset + 65 : 0,
        ...props.style,
      }}
      className={classNames(
        'fixed right-0 z-sidepanel w-fit top-[65px]',
        className,
        navBarTopOffset > -65 ? '!h-[calc(100vh-65px)]' : '!h-[100vh]',
        variant === 'root' ? 'fixed' : 'static',
      )}
      data-cy={`${dataCy}__sidebar`}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default SidebarAnim;
