import React, { useEffect } from 'react';
import { DescriptiveLoader, DialogContent } from 'ui';
import { useLatestJobConfiguration } from './use-latest-job-configuration';
import {
  getLoaderBgStateFromJobConfig,
  getLoaderDescriptionFromBgJobState,
  getLoaderStatusFromBgJobState,
  getLoaderTitleFromBgJobState,
} from './utils';
import { isJobComplete } from 'global-utils';
import { useTaskManager } from '../../context/task-manager-context';

export const TrackJobInDialog = ({ jobName, referenceId, onClose, error }) => {
  useEffect(() => {
    console.log('TrackJobInDialog', { jobName, referenceId, error });
  }, [jobName, referenceId, error]);
  const { passJobToTaskManager } = useTaskManager();
  const { jobConfiguration } = useLatestJobConfiguration(referenceId, jobName);

  const backgroundJobStatus = getLoaderBgStateFromJobConfig(jobConfiguration);
  const title = getLoaderTitleFromBgJobState(backgroundJobStatus);
  const description = getLoaderDescriptionFromBgJobState({
    bgJobState: backgroundJobStatus,
    jobName,
    jobConfig: jobConfiguration,
    error,
  });
  const status = getLoaderStatusFromBgJobState(backgroundJobStatus);

  const onTrackerClose = () => {
    if (!isJobComplete(jobConfiguration)) passJobToTaskManager(referenceId, jobName);
    if (onClose) onClose();
  };

  return (
    <DialogContent onClose={onTrackerClose}>
      <div className='pb-3'>
        <DescriptiveLoader title={title} description={description} status={status} />
      </div>
    </DialogContent>
  );
};
