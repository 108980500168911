import { format } from 'date-fns';

export const formatDate = (date?: Date) => {
    if (!date) return 'Invalid date';

    if (date.getFullYear() === new Date().getFullYear()) {
        return date.toLocaleDateString('en', {
            month: 'short',
            day: 'numeric',
        });
    } else {
        return date.toLocaleDateString('en', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    }
};

export const formatTransactionDateAsTimestamp = (date: Date) => {
    const formattedDate = format(date, 'MMM dd yyyy hh:mm:ss a');
    return formattedDate;
}