export const ONBOARDING_PROGRESS = {
  'create-account': 1,
  'short-code': 2,
  workspace: 3,
  'business-details': 4,
  'add-wallets': 5,
  'wallet-chains': 6,
  'connected-wallets': 7,
  integrations: 8,
  'integrations/circle': 9,
  'integrations/coinbase-prime': 9,
  'integrations/kraken': 9,
  'integrations/raincards': 9,
  'transaction-backfill': 10,
  'transaction-backfill/importing-transactions': 11,
  'cost-basis-methodology': 12,
  assets: 13,
  'accounting-automation': 14,
  'accounting-automation/score': 15,
  subscription: 16,
  payment: 17,
  calendly: 18,
  'invite-team': 19,
};
