import { useRuleConfigurator } from '../../../rule-configurator-context';
import { ConditionRow } from '../ConditionRow';
import { FACT } from '../../../types';
import { AssetInlineConditionMenu } from './AssetInlineConditionMenu';
import { getMappedValue } from '../../../utils';
import { useCondition } from '../../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu value={{ label: 'Asset', value: 'Asset' }} disabled={true} options={[]} />

      <SingleSelectMenu
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        options={getMappedValue(FACT.ASSET, 'operators')}
        defaultValue={{ label: 'Equals', value: 'equal' }}
        value={getMappedValue(FACT.ASSET, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
      />

      <AssetInlineConditionMenu
        isDisabled={isDisabled}
        rule={{ ...condition, value: condition.value.toUpperCase() }}
        callbackFn={(value) => {
          updateCondition(condition.id, {
            value: value.toLowerCase(),
          });
        }}
      />
    </>
  );
};

export const AssetRuleCondition = () => (
  <ConditionRow facts={[FACT.ASSET]}>
    <Row />
  </ConditionRow>
);
