import { useState } from 'react';
import { Button, Checkbox } from 'ui';
import { shortText } from '../../../rules/utils';

export const WalletsFilterDropdownRow = ({ name, chain, type, address, selected, onSelectOnly, onChange }) => {
  const [showOnlyButton, setShowOnlyButton] = useState(false);
  return (
    <div
      className='px-4 py-2 cursor-pointer relative'
      onMouseEnter={() => setShowOnlyButton(true)}
      onMouseLeave={() => setShowOnlyButton(false)}
    >
      <Checkbox
        label={
          <div className='inline-block'>
            <div>
              {name} {shortText(address)}
            </div>
            <div>
              <span className='uppercase'>{chain}</span> &#x2022; <span className='capitalize'>{type}</span>
            </div>
          </div>
        }
        isSelected={selected}
        onChange={onChange}
      />
      {showOnlyButton && (
        <div className='absolute top-5 right-4'>
          <Button
            label={<span className='text-blue-500'>Only</span>}
            variant='sm'
            emphasis='low'
            className='!rounded-full px-2 hover:bg-blue-50'
            disableRingOnFocus
            onClick={onSelectOnly}
          />
        </div>
      )}
    </div>
  );
};
