import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { ConfirmationModalProps } from './types';
import { Button } from 'ui';

export function ConfirmationModal({
  title,
  desc,
  warningText,
  onDelete,
  onClose,
  isDeleting,
  disabledDeleteButton,
}: ConfirmationModalProps) {
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      {createPortal(
        <div
          data-cy='confirmationModal'
          className='fixed top-0 left-0 bg-[#00000040] flex justify-center items-center w-screen h-screen z-modal'
        >
          <div
            className='w-[442px] pb-6 pt-8 px-8 bg-white rounded-xl flex flex-col justify-between items-start text-xl'
            data-testid='confirmation-modal'
          >
            <div>
              <p className='text-xl font-medium leading-[24px] mb-2'>{title}</p>
              <div className='text-base font-normal'>
                {typeof desc === 'string' ? (
                  <p>{desc?.length ? desc : 'This action cannot be undone.'}</p>
                ) : (
                  <>{desc}</>
                )}
              </div>
            </div>
            <div className='w-full flex flex-row-reverse justify-start gap-2 mt-12'>
              <Button
                data-testid='confirm-modal-confirm-button'
                status='danger'
                className='!text-white !h-[36px] !w-fit mb-2'
                onClick={() => onDelete(btnRef)}
                label={warningText?.length ? warningText : 'Delete'}
                ref={btnRef}
                isLoading={isDeleting}
                disabled={disabledDeleteButton}
                data-cy='confirmationModal__deleteButton'
              />
              <Button
                data-cy='confirmationModal__notNowButton'
                emphasis='medium'
                className='!h-[36px] !w-fit'
                onClick={onClose}
                label='Not now'
              />
            </div>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
}
