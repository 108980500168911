import algoliasearch from 'algoliasearch';
import { JournalEntryDB, JournalEntryTemplate, Transaction, Wallet } from 'schemas';
import {
  ALGOLIA_TRANSACTION_INDEX,
  ALGOLIA_JOURNAL_ENTRIES_INDEX,
  ALGOLIA_JOURNAL_ENTRY_TEMPLATES_INDEX,
  ALGOLIA_ACCOUNT_POSTING_RULESET_INDEX,
  ALGOLIA_WALLETS_INDEX,
} from 'services/config';

const ALGOLIA_APP_ID_DEV = 'APFRF60A8I';
const ALGOLIA_APP_ID_PROD = 'U4KN9P48MA';
const ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_VERCEL_STAGE === 'production' ? ALGOLIA_APP_ID_PROD : ALGOLIA_APP_ID_DEV;

type JournalHit = {
  journalSequenceNumber: string;
  organizationId: string;
  createdAt: string;
  accountingPeriodId: string;
  accountingPeriod: {
    startDate: string;
    endDate: string;
  };
  accountingDate: string;
  originatedBy: JournalEntryDB['originatedBy'];
  status: JournalEntryDB['status'];
};

type TransactionHit = {
  operationalTxnId: string;
  organizationId: string;
  createdAt: string;
  transactionHash: string;
  transactionDate: string;
  chain: string;
  netPrice: number;
  grossPrice: number;
  toAddress: string;
  fromAddress: string;
  transactionDirection: Transaction['transactionDirection'];
};

type JournalTemplateHit = {
  name: string;
  legalEntityId: string;
  userId: string;
  organizationId: string;
  status: JournalEntryTemplate['status'];
  memo?: string;
  externalReference?: string;
  journalEntriesGeneratedCount: number;
  ruleIds: string[];
  ruleSetIds: string[];
  journalEntryLineTemplateIds: string[];
  currency: string;
  createdAt: string;
};

type RuleSetHit = {
  name: string;
  organizationId: string;
  userId: string;
  runFrequency: string;
  enabled: boolean;
  legalEntities: string[];
  hasGeneratedAccounting: boolean;
  accountPostingRuleIds: string[];
  createdAt: string;
};

export type WalletHit = {
  name?: string;
  userId: string;
  organizationId: string;
  chain: Wallet['chain'];
  status: Wallet['status'];
  address?: string;
  addresses?: string[];
  balance?: number;
  lastBlockQueried?: number;
  lastPageQueried?: number;
  lastDateQueried?: string;
  walletType: Wallet['walletType'];
  tags?: string[];
};

export const searchAlgolia = async (query: string, userAlgoliaKey: string) => {
  if (process.env.NEXT_PUBLIC_VERCEL_STAGE !== 'production') return;
  const client = algoliasearch(ALGOLIA_APP_ID, userAlgoliaKey);

  const journalIndex = client.initIndex(ALGOLIA_JOURNAL_ENTRIES_INDEX);
  const transactionIndex = client.initIndex(ALGOLIA_TRANSACTION_INDEX);
  const journalEntryTemplatesIndex = client.initIndex(ALGOLIA_JOURNAL_ENTRY_TEMPLATES_INDEX);
  const accountPostingRuleSetsIndex = client.initIndex(ALGOLIA_ACCOUNT_POSTING_RULESET_INDEX);
  const walletsIndex = client.initIndex(ALGOLIA_WALLETS_INDEX);

  try {
    const journalResults = await journalIndex.search<JournalHit>(query);
    const transactionResults = await transactionIndex.search<TransactionHit>(query);
    const journalEntryTemplatesResults = await journalEntryTemplatesIndex.search<JournalTemplateHit>(query);
    const accountPostingRuleSetsResults = await accountPostingRuleSetsIndex.search<RuleSetHit>(query);
    const walletsResults = await walletsIndex.search<WalletHit>(query);

    return {
      transactions: transactionResults.hits,
      journalEntries: journalResults.hits,
      journalEntryTemplates: journalEntryTemplatesResults.hits,
      accountPostingRuleSets: accountPostingRuleSetsResults.hits,
      wallets: walletsResults.hits,
    };
  } catch (err) {
    console.log(err);
  }
};
