import axios from 'axios';
import { SERVER_URL_CORE, SERVER_URL_JOB_QUEUE } from '../../config';
import { convertQuery } from '../utils';
import { ExchangeSource } from 'schemas';
import { prepareSearchParams } from './utils';

export interface ExtendedExchangeSource extends ExchangeSource {
  transactionCounts?: number;
}

export const getExchangeSources = (params) => {
  return axios.get<{ sources: ExtendedExchangeSource[] }>(
    `${SERVER_URL_CORE}/exchange-sources?organizationId=${params.organizationId}${convertQuery(
      params.legalEntityIds,
      'legalEntityIds',
    )}`,
  );
};

export const createExchangeSource = (body) => {
  console.log("🚀 ~ createExchangeSource ~ body:", body)
  return axios.post(`${SERVER_URL_CORE}/exchange-sources`, body);
};

export const updateExchangeSource = (params) => {
  const organizationId = params.organizationId;
  delete params.organizationId;
  return axios.patch(`${SERVER_URL_CORE}/exchange-sources?organizationId=${organizationId}`, params?.updatedSources);
};

export const deleteExchangeSource = (params) => {
  return axios.delete(
    `${SERVER_URL_CORE}/delete-exchange-sources?organizationId=${params.organizationId}&sourceId=${params.sourceId}`,
  );
};

export const getExchangeSourceSyncHistory = (params) => {
  return axios.get(
    `${SERVER_URL_CORE}/exchange-sources-sync-history?organizationId=${params.organizationId}&exchangeSourceId=${params.sourceId}`,
  );
};

// triggers exchange import jobs for all connected exchanges if no sourceId is provided
export const createExchangeJobs = ({
  accountingPeriodId,
  startDate,
  exchangeId,
}: {
  accountingPeriodId?: string;
  startDate?: string;
  exchangeId?: string;
}) => {
  const searchParams = prepareSearchParams({ accountingPeriodId, exchangeId, startDate });
  return axios.post<{ message: string; sourceIds: string[] }>(`${SERVER_URL_JOB_QUEUE}/exchange-jobs?${searchParams}`);
};
