const theme = require('tailwindcss/defaultTheme');

const size = theme.screens;

export const media = {
  smUp: `(min-width: ${size.sm})`,
  mdUp: `(min-width: ${size.md})`,
  lgUp: `(min-width: ${size.lg})`,
  xlUp: `(min-width: ${size.xl})`,
  xxlUp: `(min-width: ${size.xxl})`,
};
