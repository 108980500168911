import { useEffect, useState } from 'react';
import { GetSourcesQueryFilter, useSources } from './useSources';
import { Wallet } from 'services/http/response.types';

export const useAllWallets = (params?: GetSourcesQueryFilter) => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const { data, isLoading, refetch } = useSources(params);

  useEffect(() => {
    if (!isLoading && data) setWallets(data.pages.map((page) => page.wallets).flat());
  }, [data, isLoading]);

  return { wallets, isLoading, refetch };
};
