import React from 'react';
import * as TooltipComp from '@radix-ui/react-tooltip';
import { TooltipProps } from './types';
import { TooltipContentStyle } from '../../../styles/TooltipContentStyle';
import { classNames } from 'ui';

export const Tooltip = ({
  children,
  content,
  delayDuration = 100,
  className,
  variant = 'primary',
  sideOffset = 8,
  showTooltipArrow,
  hideTooltip = false,
  open,
  removeTooltipContainerClassname,
  tooltipContainerClassname,
  ...props
}: TooltipProps) => {
  return (
    <>
      <TooltipContentStyle />
      <TooltipComp.Provider delayDuration={delayDuration}>
        <TooltipComp.Root open={open}>
          <TooltipComp.Trigger asChild>
            <span
              className={
                removeTooltipContainerClassname ? undefined : classNames('items-center flex', tooltipContainerClassname)
              }
            >
              {children}
            </span>
          </TooltipComp.Trigger>
          <TooltipComp.Portal>
            <TooltipComp.Content
              className={`tooltip-custom-style ${variant} ${className} ${hideTooltip && '!hidden'}`}
              sideOffset={sideOffset}
              {...props}
            >
              {content}
              {showTooltipArrow && <TooltipComp.Arrow />}
            </TooltipComp.Content>
          </TooltipComp.Portal>
        </TooltipComp.Root>
      </TooltipComp.Provider>
    </>
  );
};

export default Tooltip;
