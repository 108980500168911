import React from 'react';
import { Button, ButtonProps } from 'ui';
import Label from '../../../Label';
import { DropDownItem, DropDownMenu } from '../../DropDownMenu';

type Action = {
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  WrapperComponent?: {
    component: React.FC<any>;
    props: any;
  };
  variant?: 'primary' | 'secondary' | 'tertiary';
  'data-cy'?: string;
  props?: ButtonProps;
};

type SidebarSectionHeaderProps = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  actions?: Action[];
  label?: string;
};

const wrapComponentWithWrapperComponent = (
  WrapperComponent: React.FC<any>,
  children: React.ReactNode,
  props: any,
  index: number,
) => {
  return (
    <WrapperComponent {...props} key={`${index}`}>
      {children}
    </WrapperComponent>
  );
};

const mapVariantToButtonEmphasis = (
  variant: 'primary' | 'secondary' | 'tertiary' | undefined,
): 'high' | 'medium' | 'low' => {
  switch (variant) {
    case 'primary':
      return 'high';
    case 'secondary':
      return 'low';
    case 'tertiary':
      return 'medium';
    default:
      return 'high';
  }
};

export const SidebarSectionHeader: React.FC<SidebarSectionHeaderProps> = ({ title, subtitle, actions = [], label }) => {
  return (
    <div className='flex justify-between items-center px-6'>
      <div className='gap-y-2'>
        <div className='flex gap-x-2'>
          <p className='text-xl font-semibold'>{title}</p>
          {label ? <Label text={label} /> : null}
        </div>
        {subtitle && <p className='text-base'>{subtitle}</p>}
      </div>
      <div className='flex gap-x-2'>
        {actions.length > 0
          ? wrapComponentWithWrapperComponent(
              actions[0].WrapperComponent?.component ?? React.Fragment,
              <Button
                label={actions[0].label}
                emphasis={mapVariantToButtonEmphasis(actions[0].variant)}
                onClick={actions[0].onClick}
                data-cy={actions[0]['data-cy'] ?? ''}
                {...actions[0].props}
              />,
              actions[0].WrapperComponent?.props ?? {},
              0,
            )
          : null}
        {actions.length > 1 ? (
          <DropDownMenu>
            {actions.slice(1).map((action, index) =>
              wrapComponentWithWrapperComponent(
                action.WrapperComponent?.component ?? React.Fragment,
                <DropDownItem onClick={action.onClick} disabled={action.props?.disabled} key={index}>
                  {action.label}
                </DropDownItem>,
                action.WrapperComponent?.props ?? {},
                index,
              ),
            )}
          </DropDownMenu>
        ) : null}
      </div>
    </div>
  );
};
