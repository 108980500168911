import { Avatar, currencyImg, DefaultCell, getTransactionExplorerLink, StatusIndicator } from 'ui';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { ColumnLeadingCell } from 'src/components/tables/columns/columnLeadingCell';
import { dateOnlyConverter } from 'src/components/utils';
import { formatTableNumbers } from 'global-utils';
import { PaymentMethodSymbol } from 'src/components/billing/common/payment-method-symbol';
import { shrink } from 'src/lib/utils';
import { cn } from '@/shadcn/lib/utils';
import { InvoiceLinks } from 'src/components/dashboard/cells';
import { useDensity } from 'src/context';

export const invoicesColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'invoiceNumber',
    header: 'Invoice',
    cell: ({ row }) => {
      const { number } = row.original?.invoiceNumberAndIssueDate || {};
      return <ColumnLeadingCell isAvatarVisible={false} label={number || ''} />;
    },
  },
  {
    accessorKey: 'issueDate',
    header: 'Issue date',
    cell: ({ row }) => {
      const { issueDate } = row.original?.invoiceNumberAndIssueDate || {};
      return <DefaultCell label={dateOnlyConverter(issueDate)} textAlign='text-left' />;
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'dueDate',
    header: 'Due date',
    cell: ({ getValue }) => {
      return <DefaultCell label={dateOnlyConverter(getValue())} textAlign='text-left' />;
    },
  },
  {
    accessorKey: 'paymentMethod',
    header: 'Payment method',
    cell: ({ getValue }) => {
      const { brand, last4, transactionHash } = getValue() || {};

      if (transactionHash) {
        return (
          <div className={cn('group relative px-6  w-[inherit]', 'flex items-center gap-2', ' hover:cursor-pointer')}>
            <Avatar src={currencyImg('usdc')} alt={'usdc'} type='chain' size='default' />
            {transactionHash && (
              <p className='text-base text-zinc-900 leading-6 flex-shrink-0 truncate text-ellipsis w-[stretch]'>
                <a
                  href={`${getTransactionExplorerLink('usdc')}/${transactionHash}`}
                  target='_blank'
                  className='hover:underline'
                >
                  {shrink(transactionHash)}
                </a>
              </p>
            )}
          </div>
        );
      }

      return (
        <DefaultCell
          label={
            <div className='flex items-center'>
              <div className='h-[21px] w-[36px] flex-shrink-0 flex items-center justify-center'>
                <PaymentMethodSymbol paymentMethodType={brand?.toLowerCase()} />
              </div>
              <div className='ml-2'>
                <span className='capitalize font-semibold'>{brand}</span>
                <span>...{last4}</span>
              </div>
            </div>
          }
          textAlign='text-left'
        />
      );
    },
  },
  {
    accessorKey: 'totalAmount',
    header: 'Total',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return <DefaultCell label={formatTableNumbers({ value: getValue() })} textAlign='text-right' />;
    },
  },
  {
    accessorKey: 'links',
    header: 'Links',
    cell: ({ getValue }) => {
      const { density } = useDensity();

      const btnClassName = {
        compact: 'h-[28px]',
        default: 'h-[36px]',
        comfortable: 'h-[40px]',
      }[density];

      return (
        <DefaultCell
          label={<InvoiceLinks classNames={btnClassName} invoice={getValue() || ''} />}
          textAlign='text-left'
        />
      );
    },
  },
];

export default invoicesColumns;
