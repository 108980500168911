import React from 'react';
import { shrink } from '../../../../lib/utils';
import { DetailsItem, SidebarSection, SidebarSectionHeader } from '../../../atoms';
import Image from 'next/image';

const NFTDetails = ({ transaction, isLoading }) => {
  const imageSrc = transaction?.meta?.simpleHashNFTDetails?.image_url;

  const lastSalePrice = transaction?.meta?.simpleHashNFTDetails?.last_sale?.total_price / Math.pow(10, 18);

  const floorPrices = transaction?.meta?.simpleHashNFTDetails?.collection?.floor_prices
    ?.sort((a, b) => a.value - b.value)
    ?.map((item) => item.value) ?? [0];

  const smallestFloorPrice = floorPrices[0] / Math.pow(10, 18);

  let token = transaction?.meta?.simpleHashNFTDetails?.last_sale?.payment_token?.name;
  if (token === 'Blur Pool') {
    token = 'WETH';
  }
  return (
    <React.Fragment>
      {transaction?.meta?.simpleHashNFTDetails && (
        <>
          <SidebarSectionHeader
            title={transaction?.meta?.simpleHashNFTDetails?.contract?.name}
            subtitle={transaction?.meta?.simpleHashNFTDetails?.description}
          />
          <SidebarSection numberOfColumns={1} loading={isLoading}>
            <div className='flex flex-col'>
              {!!imageSrc?.length && (
                <Image className='rounded-2xl w-full object-cover' width={324} height={326} alt='' src={imageSrc} />
              )}
              <p className='text-xl font-medium mt-6 mb-1'>#{transaction?.meta?.simpleHashNFTDetails?.token_id}</p>
              <p>
                Owned by{' '}
                {transaction?.meta?.simpleHashNFTDetails?.contract?.owned_by
                  ? transaction?.meta?.simpleHashNFTDetails?.contract?.owned_by
                  : shrink(transaction?.toAddress)}
              </p>
            </div>
          </SidebarSection>
          <SidebarSection>
            <DetailsItem tag='Last sale' value={`${lastSalePrice} ${token}`} />
            <DetailsItem tag='Floor price' value={`${smallestFloorPrice} ${token}`} />
          </SidebarSection>
        </>
      )}
    </React.Fragment>
  );
};

export default NFTDetails;
