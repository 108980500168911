import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { Virtuoso } from 'react-virtuoso';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { useAllLegalEntities } from '../../../../../hooks';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';
import { LegalEntity } from 'schemas';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConditionGroupFilterConfig, FilterFn } from '../types';

const filterRowsFn: FilterFn<LegalEntity> = (searchTerm) => (rowData) =>
  `${rowData.entityName}`.toLowerCase().includes(searchTerm.toLowerCase());

const convertToRowData = (legalEntity: LegalEntity) => ({
  rowData: { value: legalEntity._id, label: legalEntity.entityName },
  isSelected: false,
});

export const useLegalEntityConditionGroup = (): {
  conditionGroup: Omit<ConditionGroupFilterConfig, 'rows'>;
  isLoading: boolean;
} => {
  const { legalEntities, isLoading } = useAllLegalEntities();
  const [conditionGroup, setConditionGroup] = useState<Omit<ConditionGroupFilterConfig<any>, 'rows'>>({
    heading: 'Legal entities',
    data: [] as any[],
    filterFn: filterRowsFn,
    convertToRowFn: convertToRowData,
    Row: LegalEntityConditionSelector,
  });

  useEffect(() => {
    setConditionGroup((prev) => ({ ...prev, data: legalEntities }));
  }, [legalEntities]);

  return { conditionGroup, isLoading };
};

export const LegalEntityConditionSelector = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.LEGAL_ENTITY,
          operator: 'equal',
          value: conditionData.rowData.value,
        })
      }
    />
  );
};

export const LegalEntityConditionsMenu = () => {
  const { legalEntities, isLoading } = useAllLegalEntities();
  const conditionData = useFilteredConditions<any>(legalEntities, filterRowsFn, convertToRowData);

  return (
    <ConditionsGroup label='Legal Entity'>
      {conditionData.length === 0 && isLoading && (
        <div className='flex items-center justify-center my-4'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
      {conditionData.length === 0 && !isLoading && <div className='text-center'>No legal entities to show</div>}
      {conditionData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionData}
          itemContent={(index, data) => <LegalEntityConditionSelector key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
