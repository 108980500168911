import React from 'react';
import { FormProps } from './types';

export const Form = ({
  children,
  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
  },
}: FormProps) => {
  return <form onSubmit={onSubmit}>{children}</form>;
};
