import { Subject } from 'rxjs';

export const LogoutTopic = new Subject();

export function onRequestError(error) {
  if (error.message === 'Session expired') {
    console.log('Logout triggered from request config generator');
    LogoutTopic.next(true);
  }
  return Promise.reject(error);
}
