import { KBarResults, useMatches } from 'farish-kbar';
import SearchResult from '../Search/SearchResult';

function Results() {
  const { results, rootActionId } = useMatches();

  return (
    <div>
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === 'string' ? (
            <div className='text-gray-400 text-sm py-1 px-4'>{item}</div>
          ) : (
            <div className='px-2 last:pb-2 first:pt-2' key={item.id}>
              <SearchResult currentRootActionId={rootActionId} action={item} active={active} />
            </div>
          )
        }
      />
    </div>
  );
}

export default Results;
