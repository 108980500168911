import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_FOR_CSV_EXPORT, getDefaultSortFilterByPropertyName } from '../constants';

/**
 * @deprecated This util function is deprecated in favor of prepareSearchParams
 * @returns string
 */

export const buildMultiParams = (paramList, key): string => {
  if (!paramList || paramList.length === 0) {
    return '';
  }
  return paramList?.reduce((a, b) => `${a}&${key}=${b}`, '');
};

type PrepareSearchParamsOptions = {
  isPaginated?: boolean;
  defaultSortFilterPropertyName?: string;
  noSortFilter?: boolean;
  noExtras?: boolean;
};

export const prepareSearchParams = (params, options: PrepareSearchParamsOptions = {}) => {
  if (options.noExtras) return queryString.stringify(params);
  const { noSortFilter, defaultSortFilterPropertyName = 'createdAt' } = options;
  const { sort = getDefaultSortFilterByPropertyName(defaultSortFilterPropertyName), asc, desc, ...rest } = params;
  const sortFilter = noSortFilter ? {} : { sortBy: sort.id, sortDirection: sort.desc ? 'desc' : 'asc' };

  let pageSize = DEFAULT_PAGE_SIZE;
  if (params.pageSize) pageSize = params.pageSize;
  else if (params.isForCsvExport) pageSize = DEFAULT_PAGE_SIZE_FOR_CSV_EXPORT;

  if (options.isPaginated)
    if (asc || desc)
      return queryString.stringify({
        ...rest,
        page: params.page ?? 0,
        pageSize,
        asc,
        desc,
      });
    // override default sort behaviour if asc desc is being used (sort over multiple fields)
    else
      return queryString.stringify({
        ...rest,
        ...sortFilter,
        page: params.page ?? 0,
        pageSize,
      });

  if (asc || desc) return queryString.stringify({ ...rest, asc, desc }); // override default sort behaviour if asc desc is being used (sort over multiple fields)
  return queryString.stringify({ ...rest, ...sortFilter });
};

export const createQueryStringFromObject = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
