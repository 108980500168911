import { useEffect, useState } from 'react';
import { LedgerAccount } from 'services/http/response.types';
import { useLedgerAccount } from './useLedgerAccounts';

export type ParentLedgerAccount = LedgerAccount & {
  children: ParentLedgerAccount[];
};

const linkLedgers = (allLedgers: LedgerAccount[]): ParentLedgerAccount[] => {
  const ledgers: ParentLedgerAccount[] = allLedgers.map((account) => ({
    ...account,
    children: [],
  }));

  const parents = ledgers.reduce((arr, account) => {
    const index = arr.findIndex((a) => a._id === account.parentLedgerAccountId);
    if (index > -1) {
      arr[index].children.push(account);
    }
    return arr;
  }, ledgers);

  return parents;
};

export const useLinkedLegderAccounts = () => {
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[]>([]);
  const { data, isLoading } = useLedgerAccount({});

  useEffect(() => {
    if (!isLoading && data) {
      const linkedLedgers = linkLedgers(data.pages.flat());
      setLedgerAccounts(linkedLedgers);
    }
  }, [data, isLoading]);

  return { ledgerAccounts, isLoading };
};
