import React from 'react';
import LoadingSpinnerStyle from '../../styles/LoadingSpinnerStyle';
export const LoadingSpinner = () => {
  return (
    <LoadingSpinnerStyle>
      <div className='lds-spinner'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingSpinnerStyle>
  );
};

export default LoadingSpinner;
