import { useMutation } from '@tanstack/react-query';
import {
  AddTokenToSpamListPayload,
  RemoveTokenFromSpamListPayload,
  addTokenToSpamList,
  markTransactionsAsSpam,
  removeTokenFromSpamList,
  MarkTransactionAsSpamPayload,
  markContractsAsNotSpam,
  markContractsAsSpam,
  MarkContractsAsSpamPayload,
} from 'services/http/configuration-service/token-spam-list';
import { useInvalidateQuery } from '../useInvalidateQuery';

const useSpamTokenRelatedInvalidations = () => {
  const { invalidateAssetTypes, invalidateTransactions, invalidateSpamTokens, invalidateSpamTransactions } =
    useInvalidateQuery();
  return () => {
    invalidateAssetTypes();
    invalidateTransactions();
    invalidateSpamTokens();
    invalidateSpamTransactions();
  };
};

export const useAddTokenToSpamList = () => {
  const onSuccess = useSpamTokenRelatedInvalidations();
  return useMutation({
    mutationFn: async (body: Omit<AddTokenToSpamListPayload, 'organizationId'>) => {
      return await addTokenToSpamList(body);
    },
    onSuccess,
  });
};

export const useRemoveTokenFromSpamList = () => {
  const onSuccess = useSpamTokenRelatedInvalidations();
  return useMutation({
    mutationFn: async (body: RemoveTokenFromSpamListPayload) => {
      return await removeTokenFromSpamList({ ...body });
    },
    onSuccess,
  });
};

export const useMarkTransactionsAsSpam = () => {
  const onSuccess = useSpamTokenRelatedInvalidations();
  return useMutation({
    mutationFn: async (body: MarkTransactionAsSpamPayload) => {
      return await markTransactionsAsSpam({ ...body });
    },
    onSuccess,
  });
};

export const useMarkContractsAsSpam = () => {
  const onSuccess = useSpamTokenRelatedInvalidations();
  return useMutation({
    mutationFn: async (payload: MarkContractsAsSpamPayload) => {
      return await markContractsAsSpam(payload);
    },
    onSuccess,
  });
};

export const useMarkContractsAsNotSpam = () => {
  const onSuccess = useSpamTokenRelatedInvalidations();
  return useMutation({
    mutationFn: async (rawContractAddresses: string[]) => {
      return await markContractsAsNotSpam(rawContractAddresses);
    },
    onSuccess,
  });
};
