import * as React from 'react';
import { forwardRef } from 'react';
const SvgVisaIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M18 0H2C.89 0 .01.89.01 2L0 14c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V2c0-1.11-.89-2-2-2Zm-1 14H3c-.55 0-1-.45-1-1V8h16v5c0 .55-.45 1-1 1Zm1-10H2V2h16v2Z'
      fill='#000'
      fillOpacity={0.8}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVisaIcon);
export default ForwardRef;
