export const truncateText = (
  text: string,
  containerWidth: number,
  charWidth: number = 10,
  ellipsis: string = '...',
): string => {
  // Calculate the maximum number of characters that can fit within the container
  const maxChars = Math.floor(containerWidth / charWidth);

  // If the text fits, return it as is
  if (text.length <= maxChars) {
    return text;
  }

  // Calculate the width of the ellipsis
  const ellipsisLength = ellipsis.length;

  // Calculate the number of characters to show at the start and end
  const charsToShow = Math.floor((maxChars - ellipsisLength) / 2);

  // Return the truncated string with ellipsis
  return text.substring(0, charsToShow) + ellipsis + text.substring(text.length - charsToShow);
};
