export * from './mongoose-types-fe';

export const ONBOARDING_REDIRECT_MAP = {
  NEW: `/onboarding/new/business-details`,
  ENTITIES: '/onboarding/new/add-wallets',
  WALLET_CREATE: '/onboarding/new/connected-wallets',
  WALLET_IMPORT: '/onboarding/new/accounting-automation/score', // 'onboarding/new/exchange?backfillMode=0',
  ACCOUNTING_AUTOMATION_SCORECARD: '/onboarding/new/accounting-automation/score',
  CALENDLY: '/onboarding/new/calendly',
  SUBSCRIPTION_ACTIVE: '/onboarding/new/calendly',
  //INTEGRATIONS_SETUP: 'overview', //'onboarding/new/accounting-automation',
  //INTEGRATIONS_IMPORT: 'overview', //'onboarding/new/accounting-automation',
};
