import React, { useEffect, useState } from 'react';
import {
  useDeleteImpairmentRule,
  useEditImpairmentRule,
  useGetImpairmentRuleById,
} from '../../hooks/http/useImpairmentRules';
import { SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';
import {
  PatchImpairmentRuleFormElements,
  PatchImpairmentRuleFormState,
} from '../form-elements/PatchImpairmentRuleFormElements';
import { ConfirmationModal } from '../onboarding/ConfirmationModal';
import { RULE_CONFIGURATOR_TYPE, RuleConfiguratorState } from '../rules/configurator/types';
import { useDeriveRuleConfiguratorState, parseRuleConfiguratorState, RulesConfigurator } from '../rules/configurator';

const defaultFormState: PatchImpairmentRuleFormState = {
  name: '',
  description: '',
};

export const PatchImpairmentRuleSidebarContent = ({ onDelete, onCancel, impairmentRuleId, 'data-cy': dataCy }) => {
  const { organizationId, userId } = useSession();

  const [formState, setFormState] = useState<PatchImpairmentRuleFormState>(defaultFormState);

  const { data: impairmentRule, isLoading: isLoadingImpairmentRule } = useGetImpairmentRuleById(impairmentRuleId);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const derivedRuleConfiguratorState = useDeriveRuleConfiguratorState(
    RULE_CONFIGURATOR_TYPE.IMPAIRMENT,
    impairmentRule?.topLevelCondition,
    impairmentRule?.configuratorState,
  );
  const [ruleConfiguratorState, setRuleConfiguratorState] =
    useState<RuleConfiguratorState>(derivedRuleConfiguratorState);

  const { invalidateImpairmentRules } = useInvalidateQuery();

  const { mutateAsync: editImpairmentRule, isLoading: isPatchingImpairmentRule } = useEditImpairmentRule();
  const { mutateAsync: deleteImpairmentRule, isLoading: isDeletingImpairmentRule } = useDeleteImpairmentRule();

  useEffect(() => {
    setRuleConfiguratorState(derivedRuleConfiguratorState);
  }, [derivedRuleConfiguratorState]);

  const onSave = async () => {
    try {
      const payload: any = {
        ...formState,
        journalEntryTemplateId: formState.journalEntryTemplateId,
        topLevelCondition: parseRuleConfiguratorState(ruleConfiguratorState),
        configuratorState: ruleConfiguratorState,
        organizationId,
        userId,
        _id: impairmentRuleId,
      };
      await editImpairmentRule(payload);
      toast.success('Impairment rule updated');

      onCancel();
      await invalidateImpairmentRules();
    } catch (error) {
      console.log('PatchImpairmentRuleFormError', error);
      toast.error(deriveError(error));
    }
  };

  const onDeleteClick = async () => {
    try {
      await deleteImpairmentRule({
        impairmentRuleIds: [impairmentRuleId],
        organizationId,
      });
      toast.success('Impairment rule deleted successfully');
      setShowDeleteConfirmationModal(false);
      onDelete();
      await invalidateImpairmentRules();
    } catch (error) {
      console.log('PatchImpairmentRuleFormError', error);
      setShowDeleteConfirmationModal(false);
      toast.error(deriveError(error));
    }
    return;
  };
  return (
    <>
      <SidebarHeader data-cy={dataCy} title={'Edit impairment rule'} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection loading={isLoadingImpairmentRule} numberOfColumns={1}>
          <PatchImpairmentRuleFormElements
            formState={formState}
            setFormState={setFormState}
            impairmentRuleId={impairmentRuleId}
          />
        </SidebarSection>
        <SidebarSectionHeader title='Rules' />
        <SidebarSection numberOfColumns={1}>
          <RulesConfigurator
            configuratorType={RULE_CONFIGURATOR_TYPE.IMPAIRMENT}
            defaultValue={derivedRuleConfiguratorState}
            onChange={setRuleConfiguratorState}
            isDisabled={isLoadingImpairmentRule}
          />
        </SidebarSection>
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            title='Are you sure you want to delete this impairment rule?'
            warningText='Delete impairment rule'
            onDelete={onDeleteClick}
            onClose={() => setShowDeleteConfirmationModal(false)}
            isDeleting={isDeletingImpairmentRule}
          />
        )}
      </SidebarBody>
      <SidebarFooter
        primaryBtn={
          <Button
            onClick={onSave}
            emphasis='high'
            disabled={isLoadingImpairmentRule || isPatchingImpairmentRule}
            isLoading={isPatchingImpairmentRule}
            label={'Save'}
          />
        }
        secondaryBtn={
          <Button
            emphasis='medium'
            disabled={isLoadingImpairmentRule || isPatchingImpairmentRule}
            onClick={onCancel}
            label='Cancel'
          />
        }
        destructiveBtn={
          <Button
            onClick={() => setShowDeleteConfirmationModal(true)}
            emphasis='medium'
            status='danger'
            disabled={isLoadingImpairmentRule || isPatchingImpairmentRule}
            isLoading={isDeletingImpairmentRule}
            label='Delete'
            data-cy={`${dataCy}__deleteButton`}
          />
        }
      />
    </>
  );
};
