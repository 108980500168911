import { prepareInvoiceTableData } from 'src/components/billing';
import invoicesColumns from './InvoicesColumns';
import { TableWrapper } from 'src/components/dashboard';
import Table from 'src/components/tables/tanstack-table/Table';
import { DensityDropdown, EditColumns } from 'src/components/tables/table-components';
import { useState } from 'react';

export const InvoicesTable = ({ invoicePages, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading }) => {
  const tableData = prepareInvoiceTableData(invoicePages?.pages.flat());
  const [columns, setColumns] = useState(invoicesColumns);

  const handleColumnsUpdate = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  return (
    <TableWrapper>
      <Table
        enableRowSelection={false}
        data={tableData.Data}
        columns={columns}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        <div className='flex items-center justify-between gap-3 px-6 py-5 md:gap-0 w-full'>
          <div className='flex items-center gap-3'></div>
          <div className='flex gap-3'>
            <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} />
            <DensityDropdown />
          </div>
        </div>
      </Table>
    </TableWrapper>
  );
};
