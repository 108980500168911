import React, { useContext, useEffect, useState } from 'react';
import {
  useBulkDuplicateAccountPostingRules,
  useBulkRemoveAccountPostingRules,
  useDeleteAccountPostingRuleSet,
  useLegalEntities,
  usePatchAccountPostingRuleSet,
} from '../../hooks/http';
import { Button, InputLabel, InputWithExtras, MultiSelectMenu } from 'ui';
import { SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { SidebarSection, SidebarTableSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import Table from '../tables/tanstack-table/Table';
import {
  deriveError,
  getDisplayedLegalEntities,
  getInputTagDefaultValue,
  getObjectIdsFromInstances,
  mergeLegalEntities,
} from '../templates/utils';
import { useSession } from '../../hooks/useSession';
import { toast } from 'react-hot-toast';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { useInvalidateQuery } from '../../hooks';
import { Tooltip } from '../atoms';
import { MoveRulesModal } from './MoveRulesModal';
import { TABLE_TYPE } from '../../context';
import { accountPostingRulesColumns } from './accountPostingRulesColumns';
import { DensityDropdown, EditColumns } from '../tables/table-components';

/**
 * used for editing or creating a ruleset
 */
export const EditRulesetMultiSelectActionsComponent = ({
  selectedRows,
  selectedAccountPostingRuleSet,
  showCopyRulesModal,
  setShowCopyRulesModal,
}) => {
  const { mutateAsync: duplicate, isLoading: isCreatingDuplicates } = useBulkDuplicateAccountPostingRules();

  const { mutateAsync: remove, isLoading: isRemovingRules } = useBulkRemoveAccountPostingRules();

  const { invalidateAccountPostingRulesets } = useInvalidateQuery();

  const onDuplicate = async () => {
    if (isCreatingDuplicates) return;
    const toastId = toast.loading(`Creating duplicate ${selectedRows.length === 1 ? 'rule' : 'rules'}`);
    try {
      const ruleset = selectedAccountPostingRuleSet._id;
      const rules = selectedRows.map((row) => row.original._id);

      await duplicate({ ruleset, rules });
      toast.success(`Duplicate ${rules.length === 1 ? 'rule' : 'rules'} created`, { id: toastId });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  const onRemove = async () => {
    if (isRemovingRules) return;
    const toastId = toast.loading('Removing rules');
    try {
      const ruleset = selectedAccountPostingRuleSet._id;
      const rules = selectedRows.map((row) => row.original._id);

      await remove({ ruleset, rules });
      toast.success('Rules removed', { id: toastId });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  return (
    <div className='flex items-center gap-x-2'>
      <MoveRulesModal
        open={showCopyRulesModal}
        onOpenChange={setShowCopyRulesModal}
        sourceRulesetId={selectedAccountPostingRuleSet._id}
        selectedRuleIds={selectedRows.map((row) => row.original._id)}
      />
      <Tooltip content={`Duplicate ${selectedRows.length} ${selectedRows.length === 1 ? 'rule' : 'rules'} in ruleset`}>
        <Button label={`Duplicate`} onClick={onDuplicate} variant='sm' emphasis='medium' />
      </Tooltip>

      <Tooltip content={`Remove ${selectedRows.length} ${selectedRows.length === 1 ? 'rule' : 'rules'} in ruleset`}>
        <Button label={`Remove`} onClick={onRemove} variant='sm' emphasis='medium' />
      </Tooltip>
    </div>
  );
};

export function EditRuleSet({
  onClose,
  selectedAccountPostingRuleSet,
  onCancel,
  setSelectedAccountPostingRule,
  setShowCreateAccountPostingRule,
}) {
  const [columns, setColumns] = useState(accountPostingRulesColumns);

  const handleColumnsUpdate = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  const { organizationId, userId } = useSession();
  const [accountPostingRules, setAccountPostingRules] = useState([]);
  const [displayedAccountPostingRules, setDisplayedAccountPostingRules] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading] = useState(false);
  const { data: legalEntities } = useLegalEntities();
  const [sortedAccountPostingRuleIds, setSortedAccountPostingRuleIds] = useState([]);

  useEffect(() => {
    if (selectedAccountPostingRuleSet)
      setSortedAccountPostingRuleIds(selectedAccountPostingRuleSet.accountPostingRuleIds.map((rule) => rule._id));
  }, [selectedAccountPostingRuleSet]);

  const { closeSidebar } = useContext(SidebarGlobalContext);

  const [displayedLegalEntities, setDisplayedLegalEntities] = useState(
    getDisplayedLegalEntities(mergeLegalEntities(legalEntities)),
  );

  useEffect(() => {
    setDisplayedLegalEntities(getDisplayedLegalEntities(mergeLegalEntities(legalEntities)));
  }, [legalEntities]);

  const { mutateAsync: editAsync, isLoading: isUpdating } = usePatchAccountPostingRuleSet();
  const { invalidateAccountPostingRulesets } = useInvalidateQuery();
  const [draftRules] = useState([]);
  const { mutateAsync: deleteRuleSetAsync, isLoading: isDeleting } = useDeleteAccountPostingRuleSet();
  useEffect(() => {
    if (selectedAccountPostingRuleSet) {
      const _legalEntities = getObjectIdsFromInstances(selectedAccountPostingRuleSet.legalEntities);

      const defaultValues = getInputTagDefaultValue(
        selectedAccountPostingRuleSet?.legalEntities,
        displayedLegalEntities,
      );
      setFormData({ ...selectedAccountPostingRuleSet, _legalEntities, legalEntities: defaultValues });
      setAccountPostingRules(selectedAccountPostingRuleSet.accountPostingRuleIds);
      setDisplayedAccountPostingRules(
        selectedAccountPostingRuleSet.accountPostingRuleIds.map((rule) => ({ ...rule, name: { title: rule.name } })),
      );
    }
  }, [selectedAccountPostingRuleSet, displayedLegalEntities]);

  const [showCopyRulesModal, setShowCopyRulesModal] = useState(false);

  const dataCy = 'editRuleset';

  return (
    <>
      <SidebarHeader data-cy={dataCy} title='Edit rule set' />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <div>
            <InputLabel heading='Rule set name' />
            <InputWithExtras
              data-testid='rulesetName__input'
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
            />
          </div>
          <div>
            <InputLabel heading='Legal entities' />
            <MultiSelectMenu
              isOnSidepanel
              fullWidth
              label='Select legal entities'
              options={displayedLegalEntities}
              value={displayedLegalEntities.filter((le) => formData.legalEntities?.includes(le))}
              onChange={(selected) => {
                setFormData({ ...formData, legalEntities: selected });
              }}
              clearable
              onClearValue={() => {
                setFormData({ ...formData, legalEntities: [] });
              }}
            />
          </div>
        </SidebarSection>
        <SidebarSectionHeader
          title='Posting rules'
          actions={[
            {
              label: 'Add rule',
              onClick: () => {
                closeSidebar('secondary');
                setSelectedAccountPostingRule(null);
                setShowCreateAccountPostingRule(true);
              },
              variant: 'tertiary',
            },
          ]}
        />
        <SidebarTableSection>
          <div className='w-full'>
            <Table
              enableRowSelection={false}
              disableColumnPinning={true}
              columns={columns}
              panelTableName={'JournalEntrySidebar'}
              data={accountPostingRules.length ? displayedAccountPostingRules : draftRules}
              onRowClick={(row) => {
                closeSidebar('secondary');
                setShowCreateAccountPostingRule(false);
                setSelectedAccountPostingRule({ ...row.original, name: row.original.name.title });
              }}
              onSortOrderChange={(rules) => setSortedAccountPostingRuleIds(rules.map((rule) => rule._id))}
              tableType={TABLE_TYPE.EDIT_ACCOUNT_POSTING_RULESET}
              multiSelectActionBarProps={{
                showCopyRulesModal,
                setShowCopyRulesModal,
                selectedAccountPostingRuleSet,
              }}
            >
              <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                <div className='flex items-center gap-3 flex-1'></div>
                <div className='flex flex-wrap gap-3'>
                  <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} panelTableName={'EditRuleSet'} />
                  <DensityDropdown />
                </div>
              </div>
            </Table>
          </div>
        </SidebarTableSection>
      </SidebarBody>
      <SidebarFooter
        primaryBtn={
          <Button
            label='Update'
            emphasis='high'
            onClick={async () => {
              try {
                const payload = {
                  ...formData,
                  legalEntities: formData.legalEntities?.map((le) => le.value),
                  userId,
                  organizationId,
                  accountPostingRuleIds: sortedAccountPostingRuleIds,
                };
                await editAsync(payload, {
                  onSuccess: () => invalidateAccountPostingRulesets(),
                });
                toast.success('Your ruleset has been updated');
              } catch (error) {
                console.error(error);
                toast.error(deriveError(error));
              }
            }}
            disabled={loading || !formData?.legalEntities?.length || !formData?.name?.length}
            isLoading={isUpdating}
          />
        }
        secondaryBtn={
          <Button label='Cancel' emphasis='medium' onClick={onCancel ? onCancel : onClose} disabled={loading} />
        }
        destructiveBtn={
          <Button
            data-cy={`${dataCy}__deleteButton`}
            label='Delete'
            onClick={async () => {
              try {
                const payload = {
                  _id: selectedAccountPostingRuleSet?._id,
                  organizationId,
                };
                await deleteRuleSetAsync(payload, {
                  onSuccess: () => {
                    invalidateAccountPostingRulesets();
                    onClose();
                    toast.success('Your ruleset has been deleted.');
                  },
                  onError: (error) => {
                    console.error(error);
                    toast.error(deriveError(error));
                  },
                });
              } catch (error) {
                console.error(error);
                toast.error(deriveError(error));
              }
            }}
            status='danger'
            emphasis='medium'
            disabled={loading}
            isLoading={isDeleting}
          />
        }
      />
    </>
  );
}
