import { createContext, useContext } from 'react';
import { OnboardingTransactionBackfillContextType } from './types';

export const OnboardingTransactionBackfillContext = createContext<OnboardingTransactionBackfillContextType>({
  jobsToTrack: [],
  setJobsToTrack: () => {},
  onExchangeImportJobComplete: () => {},
  onTransactionImportJobComplete: () => {},
  allJobsCompleted: false,
});

export const useOnboardingTransactionBackfillContext = () => useContext(OnboardingTransactionBackfillContext);
