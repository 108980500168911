import { JournalFileUploader } from '../JournalFileUploader';
import { FileAttachment, RemoveIcon } from '../../assets/generated/icons';
import React from 'react';
import styled from 'styled-components';
import { useDeleteFileAttachment, useGetFileSignedUrl, useGetJournalEntryFileAttachmentsById } from '../../hooks/http';

import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';

const StyledAttachmentContainer = styled.div`
  margin-top: 16px;
`;

const StyledAttachment = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 4px;
  margin-top: 4px;
  width: 167px;
  height: 40px;
  background: #ffffff;
  border: 2px solid #e8e8e8;
  border-radius: 8px;
`;

export const JournalEntryFileAttachment = ({ jeId }) => {
  const { data: attachments, refetch } = useGetJournalEntryFileAttachmentsById(jeId);
  const { mutateAsync } = useGetFileSignedUrl();
  const { mutateAsync: mutateAsyncDeleteAttachment } = useDeleteFileAttachment();

  const { organizationId, userId } = useSession();
  const { invalidateJournalEntryFileAttachments } = useInvalidateQuery();

  const invalidateGetJournalEntryAttachmentsQuery = async () => {
    invalidateJournalEntryFileAttachments();
    await refetch();
    setTimeout(async () => {
      invalidateJournalEntryFileAttachments();
      await refetch();
    }, 3000);
  };

  const openFile = async (filePath) => {
    await mutateAsync(
      { filePath },
      {
        onSuccess: (response) => {
          window.open(response.data.url);
        },
      },
    );
  };

  const deleteAttachment = async (item) => {
    if (!item?.file?._id || !jeId || !organizationId) {
      alert('Something when wrong! please try again later');
      return;
    }

    await mutateAsyncDeleteAttachment(
      { fileId: item?.file?._id, journalEntryId: jeId, organizationId: organizationId },
      {
        onSuccess: async () => {
          await invalidateGetJournalEntryAttachmentsQuery();
        },
      },
    );
  };

  const getFileName = (path) => path?.slice(-10);

  return (
    <div className='my-6'>
      <JournalFileUploader
        journalId={jeId}
        organizationId={organizationId}
        creator={userId}
        onSuccess={() => invalidateGetJournalEntryAttachmentsQuery(jeId)}
      />
      <StyledAttachmentContainer className='w-full'>
        {attachments?.journalEntryFiles?.map((item) => (
          <StyledAttachment key={item?.file?._id}>
            <FileAttachment />
            <span className='text-center' onClick={() => openFile(item?.file?.filePath)}>
              {getFileName(item?.file?.filePath)}
            </span>
            <RemoveIcon variant='secondary' onClick={async () => await deleteAttachment(item)} />
          </StyledAttachment>
        ))}
      </StyledAttachmentContainer>
    </div>
  );
};
