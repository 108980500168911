import Select, { components, DropdownIndicatorProps, OptionProps, SingleValueProps } from 'react-select';
import { CryptoCurrencyOptionProps, MenuProps, MenuRowProps } from './types';
import { MdArrowDropDown } from 'react-icons/md';
import { classNames, currencyImg } from '../utils';
import { Virtuoso } from 'react-virtuoso';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';
import { getDisplayedAssets } from './utils';
import { useEffect, useState } from 'react';
import { Avatar } from '../avatar';

const CurrencyMenuRow = ({ icon, symbol }: MenuRowProps) => (
  <div className='flex items-center justify-between w-full'>
    <div className='flex items-center'>
      {icon && <span className='mr-2'>{icon}</span>}
      {symbol && <span className='flex-grow'>{symbol}</span>}
    </div>
  </div>
);

const Option = (props: OptionProps<CryptoCurrencyOptionProps>) => (
  <components.Option {...props}>
    <CurrencyMenuRow
      icon={
        <Avatar
          size='default'
          alt={props?.data?.label?.toLowerCase()}
          src={currencyImg(props?.data?.label?.toLowerCase())}
        />
      }
      symbol={props.data.label}
      isSelected={props.isSelected}
    />
  </components.Option>
);

const SingleValue = (props: SingleValueProps<CryptoCurrencyOptionProps>) => (
  <components.SingleValue {...props}>
    {props.data ? (
      <CurrencyMenuRow
        icon={
          <Avatar
            size='default'
            alt={props?.data?.label?.toLowerCase()}
            src={currencyImg(props?.data?.label?.toLowerCase())}
          />
        }
        symbol={props.data.label}
      />
    ) : (
      'Select'
    )}
  </components.SingleValue>
);

const DropdownIndicator = (props: DropdownIndicatorProps<CryptoCurrencyOptionProps>) => (
  <components.DropdownIndicator {...props}>
    <MdArrowDropDown
      className={classNames('duration-300 w-6 h-6 text-black', props.selectProps.menuIsOpen && 'rotate-180')}
    />
  </components.DropdownIndicator>
);

const MenuList = (props: any) => {
  const rows = (props.children || []) as React.ReactElement[];
  return (
    <div className='border rounded-lg p-1 grid grid-cols-1 gap-1'>
      <Virtuoso totalCount={rows.length} itemContent={(index) => rows[index]} style={{ height: 150, width: '100%' }} />
    </div>
  );
};
const LoadingIndicator = () => {
  return <LoaderIcon size={LOADER_ICON_SIZE.SMALL} />;
};

export const CryptoCurrencyMenu = ({
  isError,
  disabled,
  onChange,
  isLoading,
  selectedValue,
  data,
  ...props
}: MenuProps) => {
  const [options, setOptions] = useState(getDisplayedAssets());
  useEffect(() => {
    if (data?.length) {
      setOptions(data);
    }
  }, [data]);
  return (
    <div className='min-w-[280px] border border-1.5 border-zinc-300 rounded-lg'>
      <Select
        options={options}
        isDisabled={disabled}
        isClearable={false}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          MenuList,
          LoadingIndicator,
        }}
        isMulti={false}
        isLoading={isLoading}
        unstyled
        value={selectedValue?.length && options.find((option) => option.value === selectedValue)}
        onChange={onChange}
        classNames={{
          control: (state) =>
            classNames(
              'bg-white rounded-lg pl-3 pr-[14px] py-1 leading-[22px] border border-1.5 !border-yellow-600',
              isError && 'border-[#ff0000] ring-[#ff0000] hover:ring-[#ff0000]',
              !isError && state.menuIsOpen && 'ring-2 ring-[#0000ff] hover:ring-[#0000ff]',
              'hover:ring-2 hover:ring-[#808080]',
              state.isDisabled && 'border opacity-50 pointer-none',
            ),
          menu: () =>
            'rounded-lg bg-white mt-2 !border-0 shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)] ',
          menuList: () => '!border-0 rounded-lg p-1 grid grid-cols-1 gap-1',
          menuPortal: () => 'border-0  shadow-none',
          option: ({ isFocused, isSelected }) =>
            classNames(
              'rounded-md bg-white py-[6px] px-[12px] mb-1 last:mb-0',
              isFocused && 'bg-indigo-50',
              isSelected && 'bg-indigo-50',
            ),
          container: () => '!border-0 shadow-none',
        }}
        {...props}
      />
    </div>
  );
};
