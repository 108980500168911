import { DefaultCell, TwoLineAvatar, StatusIndicator } from 'ui';
import { formatTableNumbers } from 'global-utils';
import { ColumnsProps } from 'src/components/tables/columns/types';

export const assetsDetailsColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'journalEntrySequenceNumber',
    header: 'Journal Entry',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'ledgerAccountName',
    header: 'Ledger Account',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'creditOrDebit',
    header: 'Direction',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue(), isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'amountDisposed',
    header: 'Disposed Quantity',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return <TwoLineAvatar label={getValue()} isAvatarVisible={false} textAlign={'text-right'} />;
    },
  },
];
