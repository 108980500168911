import { v4 as uuidv4 } from 'uuid';
import { ModifiedTabType, TabRetainStateType } from '../components/Nav/WorkspaceTabs/types';

export const DEFAULT_WORKSPACE_TAB_STATE: TabRetainStateType = {
  filtersState: {},
  sidebarState: {
    primaryPanel: {},
    secondRouteStack: [],
    secondRouteUnStack: {},
    secondRouteDockPanel: false,
  },
};

export const DEFAULT_WORKSPACE_TABS: ModifiedTabType[] = [
  {
    url: '/overview',
    title: 'overview page',
    id: uuidv4(),
    isActive: true,
    state: DEFAULT_WORKSPACE_TAB_STATE,
  },
];
