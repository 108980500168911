import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { Avatar } from '../avatar';

import clsx from 'clsx';
import { classNames, classNames as cn } from '../utils';
import { GoTriangleRight } from 'react-icons/go';
import { Tooltip } from '../tooltip';
import { LeadingCellExpandableProps } from './types';

export function LeadingCellExpandable({
  state,
  label,
  bottomText,
  src = '',
  alt = '',
  textAlign = 'text-left',
  isAvatarVisible = true,
  isCheckboxVisible = false,
  withoutPaddingLeft = false,
  isHeader = false,
  checked,
  onChange,
  indeterminate,
  canExpand = false,
  isExpanded = false,
  expandFn,
  depth = 0,
  size = 'extra-large',
  type = 'chain',
  icon,
  emoji = '',
  containerClassNames = '',
  initials,
  secondAvatar,
  secondAvatarPosition,
  labelClassNames,
  className = '',
}: LeadingCellExpandableProps) {
  return (
    <div className={clsx('relative w-full flex-grow', className)}>
      <div
        style={{
          left: depth * 2.25 * 16,
        }}
        className={cn(
          clsx(
            'group relative  px-6 w-full h-full',
            'flex items-center gap-3',
            'before:absolute before:w-1 before:inset-y-0 before:left-0 before:hidden before:bg-indigo-600',
            { 'bg-indigo-50 ': state === 'selected' },
            { 'border-b-2 border-b-zinc-300 bg-indigo-100 before:block': state === 'active' },
            { 'text-center justify-center': textAlign === 'text-center' },
            { 'text-left justify-start': textAlign === 'text-left' },
            { 'text-right justify-end': textAlign === 'text-right' },
            { '!pl-0': withoutPaddingLeft },
          ),
        )}
      >
        {canExpand && (
          <Tooltip content='Expand'>
            <button className='w-6 h-6 p-1 rounded-md hover:bg-zinc-200'>
              <GoTriangleRight
                onClick={expandFn}
                className={clsx('w-full h-full text-zinc-800 transition-transform ', {
                  'rotate-90': isExpanded,
                })}
              />
            </button>
          </Tooltip>
        )}

        {isCheckboxVisible && (
          <IndeterminateCheckbox
            isHeader={isHeader}
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChange}
          />
        )}

        {isAvatarVisible && (
          <Avatar
            src={src}
            alt={alt}
            type={type}
            size={size}
            containerClassNames={containerClassNames}
            emoji={emoji}
            icon={icon}
            initials={initials}
            secondAvatar={secondAvatar}
            secondAvatarPosition={secondAvatarPosition}
          />
        )}

        <div className='flex-shrink-0 flex-grow w-full'>
          <p className={classNames('text-lg text-zinc-900 leading-6 font-medium', labelClassNames)}>{label}</p>
          <p className='text-zinc-600 leading-6'>{bottomText}</p>
        </div>
      </div>
    </div>
  );
}
