import React, { useEffect, useMemo, useState } from 'react';
import { Button, InputLabel, InputWithExtras, MultiSelectMenu, SingleSelectMenu } from 'ui';

import { useAllLegalEntities, useAllTags, useGetSourceById, useTabState } from '../../hooks';
import { WALLET_CHAIN, WALLET_STATUS, WALLET_TYPE } from 'services/http/response.types';
import { SUPPORTED_CHAIN, WALLET_STATUS_OPTIONS, WALLET_TYPE_OPTIONS } from '../Sources';
import { TagsInput } from '../atoms';
import { getDisplayedLegalEntities, getDisplayedTags } from '../templates/utils';
import { toast } from 'react-hot-toast';
import { MdContentCopy } from 'react-icons/md';
import { updateRouteStackedDefaultState } from '../utils';
import { useGetRaincardSources } from '../../hooks/http/useRaincards';

export interface PatchSourceFormState {
  walletType: WALLET_TYPE;
  name: string;
  chain: WALLET_CHAIN;
  address: string;
  addresses: string[];
  legalEntityId?: string;
  parentWalletId?: string;
  tags: string[];
  import?: string;
  importStartDate?: string;
  status: WALLET_STATUS;
}

type rainCardData = {
  id: string;
  cardType: string;
  nickname: string;
  status: string;
};
interface RainCard {
  _id: string;
  legalEntityId: string;
  cardData: rainCardData;
}
export interface RainCardFormState {
  _id: string;
  name: string;
  legalEntityId?: string;
}

export interface PatchSourceFormElementsProps {
  formState: PatchSourceFormState;
  setFormState: React.Dispatch<React.SetStateAction<PatchSourceFormState>>;
  sourceId: string;
  defaultFormState: any;
  raindCardFormState: RainCardFormState;
  setRaindCardFormState: React.Dispatch<React.SetStateAction<RainCardFormState>>;
  defaultRainCardFormState: RainCardFormState;
}

export const PatchSourceFormElements = ({
  formState,
  setFormState,
  sourceId,
  defaultFormState,
  raindCardFormState,
  setRaindCardFormState,
  defaultRainCardFormState,
}: PatchSourceFormElementsProps) => {
  const { data: wallet, isLoading: isLoadingWallet } = useGetSourceById(sourceId);
  const { data: raincard, isLoading: isLoadingRaincards } = useGetRaincardSources();
  const [rainCardData, setRainCardData] = useState<RainCard>();
  const {
    activeTab,
    state: { sidebarState },
    updateTabSidebarState,
  } = useTabState();
  const { legalEntities, isLoading: isLoadingEntities } = useAllLegalEntities();
  const LEGAL_ENTITY_SELECT_OPTIONS = useMemo(() => getDisplayedLegalEntities(legalEntities), [legalEntities]);

  const { tags, isLoading: isLoadingTags } = useAllTags();
  const TAG_OPTIONS = useMemo(() => getDisplayedTags(tags) ?? [], [tags]);
  const memoizedTabUpdate = useMemo(() => activeTab.id, [activeTab?.id ?? '']);
  useEffect(() => {
    if (raincard) {
      const rainCards = raincard.filter((item: RainCard) => item._id === sourceId);
      if (rainCards.length > 0) {
        setRainCardData(rainCards[0]);
      }
    }
  }, [raincard, sourceId]);

  useEffect(() => {
    if (wallet)
      setFormState(() => ({
        ...wallet,
        walletType: defaultFormState?.walletType ?? wallet?.walletType ?? '',
        status: defaultFormState?.status ?? wallet?.status ?? '',
        address: defaultFormState?.address ?? wallet?.address ?? '',
        addresses: defaultFormState?.addresses ?? wallet?.addresses ?? [],
        chain: defaultFormState?.chain ?? wallet?.chain ?? '',
        name: defaultFormState?.name ?? wallet?.name ?? '',
        tags: defaultFormState?.tags ?? wallet?.tags ?? [],
        import: defaultFormState?.import ?? '',
        importStartDate: defaultFormState?.importStartDate ?? '',
        legalEntityId: defaultFormState?.legalEntityId ?? (wallet.legalEntityId as string),
      }));
    else if (rainCardData) {
      setRaindCardFormState(() => ({
        _id: sourceId,
        name: rainCardData?.cardData?.nickname ?? '',
        legalEntityId: defaultRainCardFormState?.legalEntityId ?? (rainCardData?.legalEntityId as string),
      }));
    }
  }, [wallet, memoizedTabUpdate, rainCardData, sourceId, isLoadingRaincards]);
  return raindCardFormState.name ? (
    <>
      <div>
        <InputLabel heading='Card name' />
        <InputWithExtras
          onChange={({ target }) => {
            const updatedObject = { formState: { ...defaultRainCardFormState, name: target.value } };
            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setRaindCardFormState((prev) => ({ ...prev, name: target.value }));
          }}
          value={raindCardFormState.name}
          disabled={true}
          placeholder='Enter Card name'
        />
      </div>

      <div>
        <InputLabel heading='Legal entity' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={LEGAL_ENTITY_SELECT_OPTIONS}
          onChange={(option) => {
            const updatedObject = {
              formState: {
                ...defaultRainCardFormState,
                legalEntityId: option.value,
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setRaindCardFormState((prev) => ({ ...prev, legalEntityId: option.value }));
          }}
          value={LEGAL_ENTITY_SELECT_OPTIONS.find((item) => item.value === raindCardFormState.legalEntityId)}
          placeholder='Assign legal entity'
          isLoading={isLoadingRaincards || isLoadingEntities}
        />
      </div>
    </>
  ) : (
    <>
      <div>
        <InputLabel heading='Wallet type' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={WALLET_TYPE_OPTIONS}
          onChange={(option: any) => {
            const values = {
              walletType: option.value ?? formState.walletType,
              legalEntityId: option.value === 'external' ? undefined : formState.legalEntityId,
              import: option.value === 'external' ? undefined : formState.import,
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              formState: { ...formState, ...values },
            });
            setFormState({ ...formState, ...values });
          }}
          value={WALLET_TYPE_OPTIONS.find((item) => item.value === formState.walletType)}
          placeholder='Select wallet type'
          onClearValue={() => {
            const values = {
              walletType: undefined,
              legalEntityId: undefined,
              import: undefined,
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              formState: { ...formState, ...values },
            });
            // @ts-ignore
            setFormState({ ...formState, ...values });
          }}
        />
      </div>
      <div>
        <InputLabel heading='Wallet name' />
        <InputWithExtras
          onChange={({ target }) => {
            const updatedObject = { formState: { ...defaultFormState, name: target.value } };
            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, name: target.value }));
          }}
          value={formState.name}
          placeholder='Enter wallet name'
        />
      </div>
      <div>
        <InputLabel heading='Chain' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          enableAvatar
          options={SUPPORTED_CHAIN.map((chain) => ({ label: chain.label, value: chain.value, icon: chain.value }))}
          onChange={(option) => {
            const updatedObject = { formState: { ...defaultFormState, chain: option.value } };
            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, chain: option.value as WALLET_CHAIN }));
          }}
          value={{
            label: SUPPORTED_CHAIN.find((chain) => chain.value === formState.chain)?.label,
            value: formState.chain,
            icon: SUPPORTED_CHAIN.find((chain) => chain.value === formState.chain)?.value,
          }}
          disabled
        />
      </div>
      {/**
       * @todo: use a constant for the chain types
       */}

      {formState.chain === 'btc' ? (
        <div>
          <TagsInput
            label='Wallet address'
            tags={formState.addresses}
            onEnter={(addressesString) => {
              const addressesArray = addressesString.split(',').map((str) => str.trim());
              const updatedObject = {
                formState: {
                  ...defaultFormState,
                  addresses: formState.addresses ? [...formState.addresses, ...addressesArray] : addressesArray,
                },
              };
              updateRouteStackedDefaultState({
                sidebarState,
                updateTabSidebarState,
                ...updatedObject,
              });
              setFormState((prev) => ({
                ...prev,
                addresses: prev?.addresses ? [...prev.addresses, ...addressesArray] : addressesArray,
              }));
            }}
            onRemove={(removedAddress) => {
              const updatedObject = {
                formState: {
                  ...defaultFormState,
                  addresses: formState.addresses.filter((address) => address !== removedAddress),
                },
              };

              updateRouteStackedDefaultState({
                sidebarState,
                updateTabSidebarState,
                ...updatedObject,
              });

              setFormState((prev) => ({
                ...prev,
                addresses: prev.addresses.filter((address) => address !== removedAddress),
              }));
            }}
          />
        </div>
      ) : (
        <div>
          <InputLabel heading='Wallet address' />
          <InputWithExtras
            value={formState.address}
            placeholder='Enter wallet address'
            disabled
            trailing={
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(formState.address);
                  toast.success('Source address copied to clipboard');
                }}
                className='border-0 p-0 w-fit h-fit my-auto'
                trailingIcon={<MdContentCopy />}
                emphasis='low'
              />
            }
          />
        </div>
      )}

      <div>
        <InputLabel heading='Legal entity' />
        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={LEGAL_ENTITY_SELECT_OPTIONS}
          onChange={(option) => {
            const updatedObject = {
              formState: {
                ...defaultFormState,
                legalEntityId: option.value,
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, legalEntityId: option.value }));
          }}
          value={LEGAL_ENTITY_SELECT_OPTIONS.find((item) => item.value === formState.legalEntityId)}
          disabled={formState.walletType === 'external'}
          placeholder='Assign legal entity'
          isLoading={isLoadingWallet || isLoadingEntities}
          onClearValue={() => {
            formState.walletType === 'external'
              ? undefined
              : () => {
                  const updatedObject = {
                    formState: {
                      ...defaultFormState,
                      legalEntityId: undefined,
                    },
                  };

                  updateRouteStackedDefaultState({
                    sidebarState,
                    updateTabSidebarState,
                    ...updatedObject,
                  });
                  setFormState((prev) => ({ ...prev, legalEntityId: undefined }));
                };
          }}
        />
      </div>
      <div>
        <InputLabel heading='Tags' />

        <MultiSelectMenu
          fullWidth
          isOnSidepanel
          clearable
          side='top'
          options={TAG_OPTIONS}
          onChange={(options) => {
            const updatedObject = {
              formState: {
                ...defaultFormState,
                tags: options.map((option) => option.value),
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, tags: options.map((option: any) => option.value) }));
          }}
          value={TAG_OPTIONS.filter((tag) => formState?.tags?.includes(tag?.value))}
          isLoading={isLoadingWallet || isLoadingTags}
          placeholder='Select tags'
          onClearValue={() => {
            const updatedObject = {
              formState: {
                ...defaultFormState,
                tags: [],
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, tags: [] }));
          }}
        />
      </div>

      <div>
        <InputLabel heading='Status' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={WALLET_STATUS_OPTIONS}
          onChange={(option) => {
            const updatedObject = {
              formState: {
                ...defaultFormState,
                status: option.value,
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            setFormState((prev) => ({ ...prev, status: option.value as WALLET_STATUS }));
          }}
          value={WALLET_STATUS_OPTIONS.find((item) => item.value === formState.status)}
          placeholder='Select status'
          isLoading={isLoadingWallet}
          onClearValue={() => {
            const updatedObject = {
              formState: {
                ...defaultFormState,
                status: undefined,
              },
            };

            updateRouteStackedDefaultState({
              sidebarState,
              updateTabSidebarState,
              ...updatedObject,
            });
            // @ts-ignore
            setFormState((prev) => ({ ...prev, status: undefined }));
          }}
        />
      </div>
    </>
  );
};
