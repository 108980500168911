import React, { useState, useRef, useLayoutEffect } from 'react';

type EditableInputProps = {
  initialValue: string;
  onApiCall: (value: string) => void;
};

export const EditableInput: React.FC<EditableInputProps> = ({ initialValue, onApiCall }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null); // Reference for the container

  useLayoutEffect(() => {
    if (containerRef.current && !isEditing) {
      // Set the container's width to its initial width to prevent shrinking
      const containerWidth = containerRef.current.offsetWidth;
      containerRef.current.style.minWidth = `${containerWidth}px`;
    }
  }, []); // Empty dependency array to run only once on mount

  useLayoutEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.style.height = '0px'; // Reset height to shrink to content
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
      textareaRef.current.focus(); // Automatically focus the textarea when it becomes editable
    }
  }, [isEditing, value]);

  const handleEditIconClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default action to avoid a newline
      setIsEditing(false);
      onApiCall(value);
    }
  };

  const inputStyles: React.CSSProperties = {
    backgroundColor: 'transparent',
    color: 'white',
    border: 'none',
    outline: 'none',
    resize: 'none',
    width: '100%', // Ensure the textarea takes up all available width
    minHeight: '20px', // Minimum height for single line
    lineHeight: '20px', // Line height to match the minimum height
  };

  return (
    <div style={{ width: '100%' }} ref={containerRef}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        {isEditing ? (
          <textarea
            ref={textareaRef}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={() => setIsEditing(false)}
            style={inputStyles}
            rows={1} // Start with a single line
          />
        ) : (
          <div style={{ display: 'flex', width: '100%' }} onClick={handleEditIconClick}>
            <span style={{ flex: 1, marginRight: '10px' }}>{value}</span>
            <span style={{ marginLeft: '10px', cursor: 'pointer' }}>&#9998; {/* Pencil Icon */}</span>
          </div>
        )}
      </div>
    </div>
  );
};
