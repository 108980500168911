import { useConditionId } from './ConditionIdContext';
import { useRuleConfigurator } from '../rule-configurator-context';

export const useCondition = () => {
  const { getConditionById } = useRuleConfigurator();
  const conditionId = useConditionId();
  const condition = getConditionById(conditionId);
  // console.log('condition', condition);
  return condition;
};
