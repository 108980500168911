import axios, { AxiosRequestConfig } from 'axios';
import { SERVER_URL_ORG_SERVICE } from '../../config';
import { CostBasisMethodology, Organization, OrganizationMembers } from 'schemas';

export const getAccountOrganizations = async (userId, options: AxiosRequestConfig = {}) =>
  axios.get(`${SERVER_URL_ORG_SERVICE}/get-user-organizations?userId=${userId}`, options);

export const getOrganizationMembers = async (data) =>
  axios.get(
    `${SERVER_URL_ORG_SERVICE}/get-organization-members?page=${data.page}&sort=${data.sort}&organizationId=${data.organizationId}`,
  );

export interface AddOrgPayload {
  organizationName: string;
  webAddress: string;
  userId?: string;
  billingCountry?: string;
  quoteId?: string;
}

export const addOrganization = async (data: AddOrgPayload, options: AxiosRequestConfig = {}) =>
  axios.post<{ organization: Organization; membership: OrganizationMembers }>(
    `${SERVER_URL_ORG_SERVICE}/add-organization`,
    data,
    options,
  );

export const patchOrganizationById = async (organizationId: string, update: Partial<Omit<Organization, '_id'>>) =>
  axios.patch<{ organization: Organization }>(
    `${SERVER_URL_ORG_SERVICE}/patch-organization?organizationId=${organizationId}`,
    {
      organization: update,
    },
  );

export interface MembershipDetails extends OrganizationMembers {
  organization: Organization;
}

export type DeleteOrganizationMemberType = {
  organizationId: string;
  memberId: string;
};

export type PatchOrganizationMemberRoleType = DeleteOrganizationMemberType & {
  body: {
    role: string;
  };
};

export const getAllMembershipsByMemberId = async (memberId?: string) =>
  axios.get<{ memberships: OrganizationMembers[] }>(
    `${SERVER_URL_ORG_SERVICE}/get-all-memberships?memberId=${memberId}`,
  );

export const getMembershipById = async (membershipId: string) =>
  axios.get<{ membership: OrganizationMembers }>(
    `${SERVER_URL_ORG_SERVICE}/get-membership?membershipId=${membershipId}`,
  );

export const patchOrganizationMemberRole = async (data: PatchOrganizationMemberRoleType) =>
  axios.patch(
    `${SERVER_URL_ORG_SERVICE}/organization-update-member?organizationId=${data.organizationId}&memberId=${data.memberId}`,
    data.body,
  );

export const inviteOrganizationMember = async (data) =>
  axios.post(`${SERVER_URL_ORG_SERVICE}/organization-send-invite`, data.body);

export const deleteOrganizationMember = async (data: DeleteOrganizationMemberType) =>
  axios.delete(
    `${SERVER_URL_ORG_SERVICE}/organization-delete-member?organizationId=${data.organizationId}&memberId=${data.memberId}`,
  );

export const getOrganizationBySubdomain = async ({ subdomain }) =>
  axios.get(`${SERVER_URL_ORG_SERVICE}/organization/by-subdomain?subdomain=${subdomain}`);

export const upsertCostBasisMethodology = async ({ organizationId, userId, methodology }) =>
  axios.patch(
    `${SERVER_URL_ORG_SERVICE}/upsert-organization-cost-basis-methodology?organizationId=${organizationId}&userId=${userId}&methodology=${methodology}`,
  );

export const getOrganizationCostBasisMethodology = async (organizationId) =>
  axios.get<{ costBasisSettings: CostBasisMethodology }>(
    `${SERVER_URL_ORG_SERVICE}/get-organization-cost-basis-methodology?organizationId=${organizationId}`,
  );

export const updateOnboardingStatus = async (pathname: string) =>
  axios.patch(`${SERVER_URL_ORG_SERVICE}/update-onboarding-status`, {
    pathname,
  });
