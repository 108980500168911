import { DetailsItem, SidebarTableSection } from '../../atoms';
import { Sidebar, SidebarBody, SidebarTopBar } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { formatDate, formatDollars, getIdFromPopulatedInstance } from '../../templates/utils';
import { CryptoIcon, handleGainLoss, shortDateConverter } from '../../utils';
import { cryptoSymbol } from 'crypto-symbol';
import { getHost, getValueFromBigDecimal } from '../../../lib/utils';
import { useContext, useState } from 'react';
import { getAssetById } from '../../../hooks/http/useAssets';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { SidebarProps } from '../SidebarGlobal';
import { AssetGainLossChart } from 'ui';
import { useGetHistoricalAssetPrices } from '../../../hooks/http/useGetHistoricalAssetPrices';
import { formatTableNumbers } from 'global-utils';
import { assetsDetailsColumns } from './assetsDetailsColumns';
import Table from 'src/components/tables/tanstack-table/Table';
import { assetImpairmentDetailsColumns } from './assetImpairmentDetailsColumns';
import { DensityDropdown, EditColumns } from 'src/components/tables/table-components';
import { ColumnsProps } from 'src/components/tables/columns/types';

const AssetSidebar: React.FC<SidebarProps> = ({
  id,
  handlePrimaryPanelClose,
  onBack,
  isPrimary,
  isDockPanelAvailable,
}) => {
  const [ImpairmentEventsColumns, setImpairmentEventsColumns] =
    useState<ColumnsProps<any, any>[]>(assetImpairmentDetailsColumns);
  const handleImpairmentEventsColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setImpairmentEventsColumns(updatedColumns);
  };

  const [GainLossColumns, setGainLossColumns] = useState<ColumnsProps<any, any>[]>(assetsDetailsColumns);
  const handleGainLossColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setGainLossColumns(updatedColumns);
  };

  const { nameLookup } = cryptoSymbol({});
  const { data, isLoading } = getAssetById(id);
  const selectedAsset = data?.assets[0];
  const { openSidebar } = useContext(SidebarGlobalContext);

  const { data: assetHistoricalPrice, isLoading: isLoadingAssetHistoricalPrice } = useGetHistoricalAssetPrices({
    assetType: selectedAsset?.assetType,
    startDate: new Date(selectedAsset?.dateReceived),
    endDate: new Date(),
  });

  const displayedImpairment = selectedAsset?.impairments.map((impairment) => {
    return {
      journalEntryId: impairment?.journalEntryId?._id,
      costBasis: getValueFromBigDecimal(impairment?.costBasis),
      impairmentQuantity: getValueFromBigDecimal(impairment?.quantity),
      impairmentRuleName: impairment?.impairmentRuleId?.name,
      createdAt: impairment?.createdAt,
      journalEntrySequenceNumber: impairment?.journalEntryId?.journalSequenceNumber,
    };
  });

  const entries = selectedAsset?.journalEntriesAssetOut || [];
  const linesFlattened = entries
    .map((entry) => {
      return entry.journalEntryLineIds.map((line) => {
        const disposal = selectedAsset?.quantities?.find((q) => q?.journalEntryLineId?._id === line?._id);
        return {
          ...line,
          journalEntryId: entry._id,
          journalEntrySequenceNumber: entry.journalSequenceNumber,
          amountDisposed:
            formatTableNumbers({
              value: disposal?.quantitySold?.$numberDecimal || '0',
              isAmount: true,
            }) +
            ' ' +
            selectedAsset?.assetType,
          dateSold: disposal?.dateSold,
        };
      });
    })
    .flat();
  const displayedGainOrLoss = linesFlattened
    ?.map((line) => {
      if (line.creditOrDebit === 'DEBIT') return undefined;
      return {
        createdAt: line.createdAt,
        amount: getValueFromBigDecimal(line.amount),
        journalEntrySequenceNumber: line.journalEntrySequenceNumber,
        creditOrDebit: line.creditOrDebit,
        ledgerAccountName: line.ledgerAccountId?.ledgerAccountName,
        journalEntryId: line.journalEntryId,
        amountDisposed: line.amountDisposed,
        dateSold: line?.dateSold ? new Date(line?.dateSold || '').toISOString() : '',
      };
    })
    .filter((item) => !!item);

  const dataCy = 'viewAsset';

  return (
    <>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          isDockPanelAvailable={isDockPanelAvailable}
          onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
          onBack={onBack}
          itemId={id}
          isPrimary={isPrimary}
          data-cy={dataCy}
        />
        <SidebarHeader
          loading={isLoading}
          title={`${nameLookup(selectedAsset?.assetType, { exact: true }) ?? ''} (${selectedAsset?.assetType})`}
          link={`${getHost()}/ledger/assets/${id}`}
          subtitles={[`Created ${formatDate(new Date(selectedAsset?.dateReceived))}`]}
          data-cy={dataCy}
        />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection loading={isLoading}>
            <DetailsItem tag='Last Impaired value' value={selectedAsset?.impairedCostBasis} />
            <DetailsItem tag='Current value' value={formatDollars(selectedAsset?.currentValue, true)} />
            <DetailsItem tag='Cost basis' value={formatDollars(selectedAsset?.costBasis)} />
            <DetailsItem
              tag='Initial quantity'
              value={formatTableNumbers({ value: selectedAsset?.quantity, isAmount: true })}
            />
            <DetailsItem
              tag='Remaining quantity'
              value={formatTableNumbers({ value: selectedAsset?.remainingQuantity, isAmount: true })}
            />
            <DetailsItem
              valueStyle={handleGainLoss(selectedAsset?.gainLossPercentage).color}
              tag='Gain/loss'
              value={handleGainLoss(selectedAsset?.gainLossPercentage).value}
            />
            <DetailsItem
              tag='Asset type'
              value={`${nameLookup(selectedAsset?.assetType, { exact: true }) ?? ''} ${selectedAsset?.assetType}`}
              leadingComponent={<CryptoIcon symbol={selectedAsset?.assetType.toLowerCase()} size='sm' />}
            />

            <DetailsItem tag='Operational transaction ID' value={selectedAsset?.transactionId} />
            <DetailsItem
              onClick={() => {
                openSidebar('journals', {
                  id: selectedAsset?.journalEntryAssetIn?._id,
                  primaryOrSecondary: 'secondary',
                });
              }}
              className='text-blue-800'
              tag='Journal entry'
              value={selectedAsset?.journalEntryAssetIn?.journalSequenceNumber}
              itemId={selectedAsset?.journalEntryAssetIn?._id}
            />

            <DetailsItem
              tag='Ledger account'
              variant='secondary'
              onClick={() =>
                openSidebar('ledger-accounts', {
                  id: selectedAsset?.ledgerAccountId?._id,
                  primaryOrSecondary: 'secondary',
                })
              }
              value={selectedAsset?.ledgerAccountId?.ledgerAccountName}
            />
            <DetailsItem
              tag='Legal entity'
              value={getIdFromPopulatedInstance(selectedAsset?.legalEntityId, 'entityName')}
              variant='secondary'
              textToCopy={`${getHost()}/configure/entities/${getIdFromPopulatedInstance(selectedAsset?.legalEntityId)}`}
              onClick={() => {
                openSidebar('entities', {
                  id: getIdFromPopulatedInstance(selectedAsset?.legalEntityId),
                  primaryOrSecondary: 'secondary',
                });
              }}
              itemId={getIdFromPopulatedInstance(selectedAsset?.legalEntityId)}
            />
            <DetailsItem tag='Source' value={selectedAsset?.source} />
            <DetailsItem tag='Received at' value={shortDateConverter(selectedAsset?.dateReceived)} />
            <DetailsItem tag='Last impaired at' value={shortDateConverter(selectedAsset?.lastImpairedAt)} />
          </SidebarSection>
          <SidebarSectionHeader title='Impairment events' />
          <SidebarTableSection>
            <div className='w-full'>
              <Table
                enableRowSelection={false}
                disableColumnPinning={true}
                onRowClick={(row) => {
                  openSidebar('journals', { id: row.original.journalEntryId, primaryOrSecondary: 'secondary' });
                }}
                columns={ImpairmentEventsColumns}
                panelTableName={'AssetSidebar-Impairment-events'}
                data={displayedImpairment || []}
              >
                <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                  <div className='flex items-center gap-3 flex-1'></div>
                  <div className='flex flex-wrap gap-3'>
                    <EditColumns
                      onColumnsUpdate={handleImpairmentEventsColumnsUpdate}
                      columns={ImpairmentEventsColumns}
                      panelTableName={'AssetSidebar-Impairment-events'}
                    />

                    <DensityDropdown />
                  </div>
                </div>
              </Table>
            </div>
          </SidebarTableSection>
          <SidebarSectionHeader title='Gain & Loss' />
          <AssetGainLossChart
            data={assetHistoricalPrice?.data.timeSeriesPrices || []}
            isLoading={isLoadingAssetHistoricalPrice}
          />
          <SidebarTableSection>
            {displayedGainOrLoss && displayedGainOrLoss.length !== 0 && (
              <div className='w-full'>
                <Table
                  enableRowSelection={false}
                  disableColumnPinning={true}
                  onRowClick={(row) => {
                    openSidebar('journals', { id: row.original.journalEntryId, primaryOrSecondary: 'secondary' });
                  }}
                  columns={GainLossColumns}
                  panelTableName={'AssetSidebar-GainLoss'}
                  data={displayedGainOrLoss}
                >
                  <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                    <div className='flex items-center gap-3 flex-1'></div>
                    <div className='flex flex-wrap gap-3'>
                      <EditColumns
                        onColumnsUpdate={handleGainLossColumnsUpdate}
                        columns={GainLossColumns}
                        panelTableName={'AssetSidebar-GainLoss'}
                      />
                      <DensityDropdown />
                    </div>
                  </div>
                </Table>
              </div>
            )}
          </SidebarTableSection>
        </SidebarBody>
      </Sidebar>
    </>
  );
};

export default AssetSidebar;
