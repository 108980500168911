import { MdClose } from 'react-icons/md';
import { BaseMessageProps } from './types';

export const BaseMessage = ({ icon, text, action, onClose, className = '' }: BaseMessageProps) => (
  <div className={className}>
    <div className='flex justify-between items-center w-full p-3'>
      <div className='flex items-center'>
        <div className='mr-3'>{icon}</div>
        <div className='mr-3'>{text}</div>
      </div>
      <div className='flex flex-row-reverse'>
        {onClose && (
          <div className='ml-3'>
            <button onClick={onClose}>
              <MdClose className='w-5 h-5' />
            </button>
          </div>
        )}

        <div className='ml-3'>{action}</div>
      </div>
    </div>
  </div>
);
