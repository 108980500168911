import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callGPTService, getConversations } from 'services';
import { Intent } from '../../context';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { AssistantsConversation } from 'schemas';
export type useGPTPayloadType = {
  instruction: string;
  organizationId: string;
  userId: string;
  selectedIntent?: Intent | null;
  threadId?: string;
  options?: {
    skipTransactionImport?: boolean;
  };
  assistantConversationId?: string;
  formData?: FormData;
};

export const useGPT = () => {
  const queryClient = useQueryClient();
  const getKey = useOrgBasedQueryKey();

  return useMutation(
    (data: useGPTPayloadType) => callGPTService(data),
    {
      // onMutate: async (data) => {
      //   const key = getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId: data.organizationId });

      //   await queryClient.cancelQueries(key);

      //   const previousConversations = queryClient.getQueryData<AssistantsConversation[]>(key);
      //   // queryClient.setQueryData(key, (old: AssistantsConversation[] | undefined) => {
      //   //   const oldData = old || [];
      //   //   if (data.assistantConversationId) {
      //   //     const currentConversation = oldData.find(convo => convo._id === data.assistantConversationId);
      //   //     if (!currentConversation) return oldData;
      //   //     // Assuming you can create a new AssistantsConversation object with just an ID
      //   //     // You might need to adjust this to match the actual structure of AssistantsConversation
      //   //     const newConversation: AssistantsConversation = {
      //   //       ...currentConversation,
      //   //       interactionHistory: [
      //   //         ...currentConversation.interactionHistory,
      //   //         {
      //   //           messageAsText: data.instruction,
      //   //           intent: GPTServiceIntentEnum.AIO_ASSISTANT,
      //   //           functionCallResponses: [],
      //   //         } as any
      //   //       ]

      //   //       // Add other required properties with default or placeholder values
      //   //     };
      //   //     const optimisticUpdate = [newConversation, ...oldData.filter(convo => convo._id !== data.assistantConversationId)];
      //   //     return optimisticUpdate;
      //   //   }
      //   //   return oldData;
      //   // });

      //   return { previousConversations };
      // },
      // onError: (err, data, context) => {
      //   const key = getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId: data.organizationId });
      //   if (context?.previousConversations) {
      //     queryClient.setQueryData(key, context.previousConversations);
      //   }
      // },
      onSettled: (data, error, variables) => {
        const key = getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId: variables.organizationId });
        queryClient.invalidateQueries(key);
      },
      onSuccess: (data, variables) => {
        const key = getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId: variables.organizationId });

        queryClient.setQueryData(key, (old: AssistantsConversation[] | undefined) => {
          const oldData = old || [];
          if (data?.data?.fullGptResponse?.assistantsResponse) {
            const newConversation = data.data.fullGptResponse.assistantsResponse?.assistantConversation;
            //const functionNames = newConversation?.interactionHistory
            if (newConversation) {
              return [newConversation, ...oldData.filter(convo => convo._id !== newConversation._id)];
            }
          }

          return oldData;
        });
      },
    }
  );
};

export const useGetConversations = () => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId, userId } = useSession();
  const params = { organizationId, userId };
  return useQuery(
    getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId }),
    async () => {
      const response = await getConversations(params);
      return response?.data?.conversations as AssistantsConversation[] || []
    },
    {
      enabled: !!organizationId,
    },
  );
};
