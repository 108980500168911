import React from 'react';
import { SkeletonRow } from '../skeleton-row';

export const IntegrationCardSkeleton = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  return (
    <div data-cy={`${dataCy}__integrationCardSkeleton`} className='border rounded-lg bg-white w-[326px] leading-5 '>
      <div className='animate-pulse'>
        <div className='flex items-center mx-5 mt-4 mb-6'>
          <div className='mr-2 w-[40px] h-[40px] bg-gradient-to-r from-zinc-200 to-white'></div>
          <SkeletonRow size='sm' />
        </div>
        <div className='mx-5 mb-6'>
          <SkeletonRow size='sm' />
          <SkeletonRow />
        </div>
      </div>
    </div>
  );
};
