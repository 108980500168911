import { useContext, useEffect, useState } from 'react';
import { JournalEntryLineTemplate, LedgerAccount } from 'schemas';
import { JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS } from '../../../constants';
import { useDuplicateJournalEntryTemplate, useJournalEntryTemplateById } from '../../../hooks/http';
import { DetailsItem, SidebarTableSection } from '../../atoms';
import { Sidebar, SidebarBody, SidebarTopBar } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { deriveError, formatDate } from '../../templates/utils';
import AddTemplate from '../../../components/templates/AddTemplate';
import { SidebarProps } from '../SidebarGlobal';
import { getHost } from '../../../lib/utils';
import CurrencyFlag from 'react-currency-flags';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import AddTemplateMemoSidebar from '../../templates/AddTemplateMemoSidebar';
import { toast } from 'react-hot-toast';
import { useSession } from '../../../hooks/useSession';
import { useInvalidateQuery } from '../../../hooks';
import Table from 'src/components/tables/tanstack-table/Table';
import { templateLinesColumns } from './templateLinesColumns';
import { DensityDropdown } from 'src/components/tables/table-components';

const TemplateSidebar: React.FC<SidebarProps> = ({
  id: journalEntryTemplateId,
  isDockPanelAvailable,
  onBack,
  isPrimary,
  handlePrimaryPanelClose,
  'data-cy': dataCy = 'journalEntryTemplateSidepanel',
}) => {
  const [lineTemplates, setLineTemplates] = useState([]);
  const [showMemoPanel, setShowMemoPanel] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { data: journalEntryTemplate, isLoading } = useJournalEntryTemplateById(journalEntryTemplateId);
  useEffect(() => {
    setLineTemplates(journalEntryTemplate?.journalEntryLineTemplateIds);
  }, [journalEntryTemplate]);
  const displayedLines = lineTemplates?.map((line: JournalEntryLineTemplate) => {
    const _ledgerAccount = line?.ledgerAccountId as LedgerAccount;
    return {
      ...line,
      ledgerAccount: _ledgerAccount?.ledgerAccountSequence + ':' + _ledgerAccount?.ledgerAccountName,
    };
  });
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  const { organizationId } = useSession();

  useEffect(() => {
    setIsEdit(false);
  }, [journalEntryTemplateId]);

  const { openSidebar, closeSidebar } = useContext(SidebarGlobalContext);
  const { mutateAsync: duplicateJournalEntryTemplate, isLoading: isDuplicating } = useDuplicateJournalEntryTemplate();
  const handleDuplicate = async () => {
    try {
      await duplicateJournalEntryTemplate(
        {
          journalEntryTemplateId: journalEntryTemplate?._id,
          organizationId,
        } as any,
        {
          onSuccess: async () => {
            invalidateJournalEntryTemplates();
            toast.success('Journal Entry Template duplicated successfully');

            onClose();
          },
        },
      );
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const onClose = () => {
    handlePrimaryPanelClose && handlePrimaryPanelClose();
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  if (isEdit)
    return (
      <AddTemplate
        selectedJournalEntryTemplate={journalEntryTemplate}
        onClose={onClose}
        onCancel={() => {
          setIsEdit(false);
        }}
      />
    );

  return (
    <>
      <Sidebar data-cy={dataCy}>
        <SidebarTopBar
          isDockPanelAvailable={isDockPanelAvailable}
          onBack={onBack}
          onClose={onClose}
          itemId={journalEntryTemplateId}
          isPrimary={isPrimary}
          data-cy={dataCy}
        />
        <SidebarHeader
          data-cy={dataCy}
          title={journalEntryTemplate?.name}
          subtitles={[
            `${journalEntryTemplate?.updatedAt ? 'Last updated' : 'Created'} ${formatDate(
              new Date(journalEntryTemplate?.updatedAt ?? journalEntryTemplate?.createdAt),
            )}`,
          ]}
          link={`${getHost()}/configure/templates/${journalEntryTemplateId}`}
          actions={[
            {
              label: 'Copy template',
              onClick: handleDuplicate,
              variant: 'primary',
              isLoading: isDuplicating,
            },
            {
              label: 'Edit',
              onClick: onEdit,
              variant: 'tertiary',
              'data-cy': 'editButton',
            },
          ]}
          status={{
            label: journalEntryTemplate?.status?.toLowerCase(),
            type: journalEntryTemplate?.status === 'POSTED' ? 'positive' : 'neutral',
          }}
          loading={isLoading}
        />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection loading={isLoading}>
            <DetailsItem
              tag={'Legal entity'}
              value={journalEntryTemplate?.legalEntityId?.entityName}
              variant='secondary'
              itemId={journalEntryTemplate?.legalEntityId?._id}
              onClick={() => {
                setShowMemoPanel(false);
                openSidebar('entities', {
                  id: journalEntryTemplate?.legalEntityId?._id,
                  primaryOrSecondary: 'secondary',
                });
              }}
            />
            <DetailsItem tag={'Accounting date'} value={'Operational Transaction Date'} />
            <DetailsItem
              tag={'Post settings'}
              value={
                JOURNAL_ENTRY_TEMPLATE_POST_SETTINGS_OPTIONS.find((item) => item.value === journalEntryTemplate?.status)
                  ?.label ?? 'Auto submit'
              }
            />
            <DetailsItem
              tag='Currency'
              value={journalEntryTemplate?.currency}
              leadingComponent={<CurrencyFlag currency={journalEntryTemplate?.currency} />}
            />
            <DetailsItem tag={'Add external reference'} value={journalEntryTemplate?.externalReference} />
          </SidebarSection>
          <SidebarSectionHeader
            title='Memo'
            actions={[
              {
                label: journalEntryTemplate?.memo ? 'Update memo' : 'Add memo',
                variant: 'tertiary',
                onClick: () => {
                  closeSidebar('secondary');
                  setShowMemoPanel(true);
                },
              },
            ]}
          />
          <SidebarSection loading={isLoading}>
            <DetailsItem tag={'Memo'} value={journalEntryTemplate?.memo} />
          </SidebarSection>
          <SidebarSectionHeader title='Template Lines' />
          <SidebarTableSection loading={isLoading}>
            <div className='w-full'>
              <Table
                enableRowSelection={false}
                disableColumnPinning={true}
                columns={templateLinesColumns}
                panelTableName={'TemplateSidebar'}
                data={displayedLines ?? []}
              >
                <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                  <div className='flex items-center gap-3 flex-1'></div>
                  <div className='flex flex-wrap gap-3'>
                    <DensityDropdown />
                  </div>
                </div>
              </Table>
            </div>
          </SidebarTableSection>
        </SidebarBody>
      </Sidebar>
      {showMemoPanel && journalEntryTemplate ? (
        <AddTemplateMemoSidebar
          journalEntryTemplateId={journalEntryTemplateId}
          organizationId={organizationId}
          templateInstance={journalEntryTemplate}
          onClose={() => setShowMemoPanel(false)}
        />
      ) : null}
    </>
  );
};

export default TemplateSidebar;
