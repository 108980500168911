import * as React from 'react';
import { forwardRef } from 'react';
const SvgError = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M17 .333C7.8.333.333 7.8.333 17 .333 26.2 7.8 33.667 17 33.667c9.2 0 16.667-7.467 16.667-16.667C33.667 7.8 26.2.333 17 .333Zm0 18.334c-.917 0-1.667-.75-1.667-1.667v-6.667c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666V17c0 .917-.75 1.667-1.667 1.667Zm1.667 6.666h-3.334V22h3.334v3.333Z'
      fill='red'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgError);
export default ForwardRef;
