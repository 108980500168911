import React, { useEffect, useMemo, useState } from 'react';
import { Action, DetailsItem } from '../atoms';
import { Sidebar, SidebarBody, SidebarFooter, SidebarTopBar } from '../atoms/Sidebar';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import RuleMetrics from './Metrics/RuleMetrics';
import { generateRandomHexString } from '../templates/utils';
import { navigate } from '../utils';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Button } from 'ui';
import { useSession } from '../../hooks/useSession';
import {
  useDeriveRuleConfiguratorState,
  parseRuleConfiguratorState,
  RULE_CONFIGURATOR_TYPE,
  RulesConfigurator,
} from './configurator';

import { AccountPostingRuleFE, useAccountPostingRuleFormState, JournalEntryTemplateDetailItem } from '../form-elements';

import { MoveRulesModal } from './MoveRulesModal';
import { toast } from 'react-hot-toast';

type CreateRuleProps = {
  accountPostingRuleSet?;
  onClose?: () => void;
  onSave;
  onDelete?;
  selectedAccountPostingRule?;
  onDuplicate?: (value?: any) => void;
  hideMoveAction?: boolean;
};

function CreateRule({
  accountPostingRuleSet,
  onClose = () => {
    navigate(`/ledger/configure/rulesets/${accountPostingRuleSet?._id}/account-posting-rules/`);
  },
  onSave,
  onDelete,
  selectedAccountPostingRule,
  onDuplicate = () => {},
  hideMoveAction = false,
}: CreateRuleProps) {
  const { organizationId } = useSession();

  const topLevelCondition = useMemo(() => selectedAccountPostingRule?.topLevelCondition, [selectedAccountPostingRule]);

  const derivedRuleConfiguratorState = useDeriveRuleConfiguratorState(
    RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING,
    topLevelCondition,
    selectedAccountPostingRule?.configuratorState,
  );

  const [ruleConfiguratorState, setRuleConfiguratorState] = useState(derivedRuleConfiguratorState);
  const [editMode, setEditMode] = useState(selectedAccountPostingRule ? false : true);
  const [showMoveRulesModal, setShowMoveRulesModal] = useState(false);
  const [accountPostingRuleFS, setAccountPostingRuleFS] = useAccountPostingRuleFormState();

  useEffect(() => {
    setRuleConfiguratorState(derivedRuleConfiguratorState);
  }, [derivedRuleConfiguratorState]);

  useEffect(() => {
    if (selectedAccountPostingRule) {
      setAccountPostingRuleFS({
        name: selectedAccountPostingRule.name,
        description: selectedAccountPostingRule.description,
        journalEntryTemplateId: selectedAccountPostingRule.journalEntryTemplateId,
      });
    }
  }, [selectedAccountPostingRule]);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const title = `${selectedAccountPostingRule ? (editMode ? 'Edit' : '') : 'Create'} ${
    selectedAccountPostingRule ? selectedAccountPostingRule.name : 'Account Posting Rule'
  }`;

  const onCreateRuleSidebarClose = () => {
    onClose();
  };

  const isPatching = editMode && !!selectedAccountPostingRule;

  const onSaveClick = async () => {
    if (!accountPostingRuleFS.name.length) return toast.error('Please enter a name for the rule');
    if (!accountPostingRuleFS.journalEntryTemplateId)
      return toast.error('Please select a journal entry template for the rule');
    const payload = {
      topLevelCondition: parseRuleConfiguratorState(ruleConfiguratorState),
      configuratorState: ruleConfiguratorState,
      ...accountPostingRuleFS,
    };
    await onSave(payload);
    if (isPatching) setEditMode(false);
    else onClose();
  };

  const sidebarActions = useMemo(() => {
    if (editMode) return [];
    const actions: Action[] = [
      {
        label: 'Duplicate',
        onClick: () => onDuplicate(selectedAccountPostingRule),
        variant: 'primary',
      },
    ];
    if (!hideMoveAction)
      actions.push({
        label: 'Move',
        onClick: () => setShowMoveRulesModal(true),
        variant: 'secondary',
      });
    actions.push({
      label: 'Edit',
      onClick: () => setEditMode(true),
      variant: 'secondary',
    });
    return actions;
  }, [editMode, hideMoveAction, selectedAccountPostingRule, onDuplicate]);

  const dataCy = selectedAccountPostingRule ? 'viewRuleset' : editMode ? 'editRuleset' : 'createRuleset';
  return (
    <Sidebar>
      <SidebarTopBar data-cy={dataCy} onClose={onCreateRuleSidebarClose} />
      <SidebarHeader data-cy={dataCy} title={title} actions={sidebarActions} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          {editMode ? (
            <AccountPostingRuleFE value={accountPostingRuleFS} setValue={setAccountPostingRuleFS} />
          ) : (
            <>
              <DetailsItem tag='Account posting rule name' value={selectedAccountPostingRule?.name} />
              <DetailsItem tag='Description' value={selectedAccountPostingRule?.description} />
              <JournalEntryTemplateDetailItem
                journalEntryTemplateId={selectedAccountPostingRule?.journalEntryTemplateId}
              />
            </>
          )}
        </SidebarSection>
        <SidebarSectionHeader title='Add conditions' subtitle='Any conditions added will show up here' />
        <SidebarSection numberOfColumns={1}>
          <RulesConfigurator
            configuratorType={RULE_CONFIGURATOR_TYPE.ACCOUNT_POSTING}
            defaultValue={derivedRuleConfiguratorState}
            onChange={setRuleConfiguratorState}
            isDisabled={!editMode}
          />
        </SidebarSection>
        <SidebarSectionHeader title='Rule Metrics' />
        <SidebarSection numberOfColumns={1}>
          <RuleMetrics
            ruleId={selectedAccountPostingRule?._id || generateRandomHexString(24)}
            organizationId={organizationId}
            topLevelCondition={parseRuleConfiguratorState(ruleConfiguratorState)}
            accountPostingRuleSet={accountPostingRuleSet}
          />
        </SidebarSection>
        <MoveRulesModal
          sourceRulesetId={accountPostingRuleSet?._id}
          selectedRuleIds={[selectedAccountPostingRule?._id]}
          open={showMoveRulesModal}
          onOpenChange={setShowMoveRulesModal}
          showTrigger={false}
        />
      </SidebarBody>
      <SidebarFooter
        destructiveBtn={
          isPatching && (
            <Button
              label='Delete'
              status='danger'
              emphasis='medium'
              onClick={async () => {
                setIsDeleting(true);
                await onDelete(selectedAccountPostingRule);
                setIsDeleting(false);
              }}
              isLoading={isDeleting}
            />
          )
        }
        secondaryBtn={
          editMode && (
            <Button
              label='Cancel'
              emphasis='medium'
              onClick={
                selectedAccountPostingRule
                  ? () => {
                      setEditMode(false);
                    }
                  : onClose
              }
            />
          )
        }
        primaryBtn={
          editMode && (
            <Button
              label='Save'
              emphasis='high'
              onClick={async () => {
                const getTopLevelConditionKey = Object.keys(parseRuleConfiguratorState(ruleConfiguratorState))[0];
                if (getTopLevelConditionKey === 'undefined') return toast.error('Please add a condition to the rule');
                setIsSaving(true);
                await onSaveClick();
                setIsSaving(false);
              }}
              isLoading={isSaving}
            />
          )
        }
      />
    </Sidebar>
  );
}

export { CreateRule };

export default CreateRule;
