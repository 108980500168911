import React, { useEffect, useMemo, useState } from 'react';
import { Sidebar, SidebarTopBar } from '../atoms/Sidebar';
import PatchLegalEntityFormForSidebar from '../onboarding/2.0/PatchLegalEntityFormForSidebar';
import ViewLegalEntitySidebarContent from '../onboarding/2.0/ViewLegalEntityFormForSidebar';
import { useTabState } from '../../hooks';
import { classNames } from 'ui';
import { updateRouteStackedDefaultState } from '../utils';

enum LE_SIDEBAR_MODE {
  VIEW = 'view',
  PATCH = 'patch',
}

export function EditEntity({ onClose, onBack, legalEntityId, isPrimary, defaultState, isDockPanelAvailable }) {
  const {
    activeTab,
    state: { sidebarState },
    updateTabSidebarState,
  } = useTabState();

  const memoizedTabUpdate = useMemo(() => activeTab?.id, [activeTab?.id]);

  const [mode, setMode] = useState<LE_SIDEBAR_MODE>(defaultState?.mode ?? LE_SIDEBAR_MODE.VIEW);

  useEffect(() => {
    setMode(defaultState?.mode ?? LE_SIDEBAR_MODE.VIEW);
  }, [memoizedTabUpdate]);

  return (
    <div className={classNames(!sidebarState?.secondRouteStack?.length && !isPrimary ? 'hidden' : '')}>
      <Sidebar data-cy={mode === LE_SIDEBAR_MODE.VIEW ? 'viewLegalEntity' : 'patchLegalEntity'}>
        <SidebarTopBar
          onClose={onClose}
          onBack={onBack}
          itemId={legalEntityId}
          isPrimary={isPrimary}
          isDockPanelAvailable={isDockPanelAvailable}
          data-cy={mode === LE_SIDEBAR_MODE.VIEW ? 'viewLegalEntity' : 'patchLegalEntity'}
        />
        {mode === LE_SIDEBAR_MODE.VIEW && (
          <ViewLegalEntitySidebarContent
            legalEntityId={legalEntityId}
            onPatch={() => {
              updateRouteStackedDefaultState({
                isPrimary,
                sidebarState,
                updateTabSidebarState,
                mode: LE_SIDEBAR_MODE.PATCH,
              });

              setMode(LE_SIDEBAR_MODE.PATCH);
            }}
          />
        )}
        {mode === LE_SIDEBAR_MODE.PATCH && (
          <PatchLegalEntityFormForSidebar
            onCancel={() => {
              updateRouteStackedDefaultState({
                isPrimary,
                sidebarState,
                updateTabSidebarState,
                mode: LE_SIDEBAR_MODE.PATCH,
              });
              setMode(LE_SIDEBAR_MODE.VIEW);
            }}
            legalEntityId={legalEntityId}
            closeSidebar={onClose}
            defaultFormState={defaultState.formState ?? {}}
          />
        )}
      </Sidebar>
    </div>
  );
}
