import { useDebounceValue } from 'usehooks-ts';
import {
  useFilteredChains,
  useFilteredLegalEntityIds,
  useFilteredTagIds,
  useFilteredWalletTypes,
} from '../../../context';
import { useEffect } from 'react';
import { SortingState } from '@tanstack/react-table';
import { GetSourcesQueryFilter } from '../../../hooks';

const defaultParams: GetSourcesQueryFilter = {
  legalEntityIds: [],
  tags: [],
  chains: [],
  walletTypes: [],
};

type TInput = { searchTerm?: string; sortState?: SortingState };
export const useSourceQueryParams = ({ searchTerm, sortState }: TInput = {}) => {
  const [params, setParams] = useDebounceValue(defaultParams, 500);

  const chains = useFilteredChains();
  const legalEntityIds = useFilteredLegalEntityIds();
  const walletTypes = useFilteredWalletTypes();
  const tagIds = useFilteredTagIds();

  useEffect(() => {
    setParams({
      legalEntityIds,
      tags: tagIds,
      chains,
      walletTypes,
      nameOrAddress: searchTerm || undefined,
      sort: sortState ? sortState[0] : undefined,
    });
  }, [chains, legalEntityIds, walletTypes, tagIds, sortState, searchTerm]);
  return params;
};
