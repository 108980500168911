import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTrigger } from 'ui/src/dialog/Dialog';
import { ConditionSelectorContextProvider, useConditionSelectorState } from './condition-selector-context';
import { ConditionSelectorMenuBackButton } from './ConditionSelectorMenuBackButton';
import { NestedMenus } from './NestedMenus';
import { PrimaryMenu } from './PrimaryMenu';

interface ConditionSelectorProps {
  parentId: string;
  trigger?: ReactNode;
  triggerLabel?: ReactNode;
  onClose?: () => void;
  showNestConditionRow?: boolean;
  addNewAsNested?: boolean;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const ConditionSelector = ({
  parentId,
  trigger,
  triggerLabel = 'Add condition',
  showNestConditionRow = false,
  addNewAsNested = false,
  onClose = () => {},
}: ConditionSelectorProps) => {
  const [open, setOpen] = useState(false);
  const { push, pop, reset, state, searchTerm, debouncedSearchTerm, setSearchTerm } =
    useConditionSelectorState(parentId);

  const previousOpen = usePrevious(open);

  useEffect(() => {
    if (!open && previousOpen) onClose();
  }, [open]);

  return (
    <Dialog
      open={open}
      onOpenChange={(changed) => {
        if (!changed) reset();
        setOpen(changed);
      }}
      //modal={false}
    >
      {trigger ? <DialogTrigger asChild>{trigger}</DialogTrigger> : <DialogTrigger>{triggerLabel}</DialogTrigger>}

      <ConditionSelectorContextProvider
        value={{
          push,
          pop,
          reset,
          state,
          searchTerm,
          debouncedSearchTerm,
          addNewAsNested,
          setSearchTerm,
          closeSelector: () => {
            reset();
            setOpen(false);
          },
        }}
      >
        <DialogContent className='z-50 border'>
          <ConditionSelectorMenuBackButton />
          <div className='p-2' id='ss'>
            <PrimaryMenu showNestConditionRow={showNestConditionRow} />
            <NestedMenus />
          </div>
        </DialogContent>
      </ConditionSelectorContextProvider>
    </Dialog>
  );
};
