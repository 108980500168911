/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

interface TextareaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
  label: string;
  inputStyle?: string;
  disabled?: boolean;
  viewMode?: boolean;
  value?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label = 'label', inputStyle, value, viewMode = false, className, disabled, ...props }, ref) => (
    <div className={`w-full flex flex-col ${className}`}>
      <label className={`mb-1 font-medium ${viewMode && 'text-[#00000066]'}`}>{label}</label>
      {viewMode ? (
        <p className='font-medium opacity-80'>{value}</p>
      ) : (
        <textarea
          rows={4}
          cols={50}
          disabled={disabled}
          {...props}
          className={`px-4 py-3 border-2 hover:border-[#C4C4C4] border-[#E8E8E8] rounded-lg duration-300 focus-visible:outline-[0px] focus:border-black ${
            disabled && 'text-[#999999] !bg-[#f2f2f2]'
          } ${inputStyle}`}
          value={value}
          ref={ref}
        />
      )}
    </div>
  ),
);

export default Textarea;
