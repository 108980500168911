import React, { useState } from 'react';
import TabsComponent, { Tab } from './Tabs';

import { Button, ButtonProps, StatusIndicator } from 'ui';

import { MdContentCopy } from 'react-icons/md';
import { Tooltip } from '../../Tooltip';
import ResponsiveButtonTruncator from '../../ResponsiveButtonTruncator';

export type Action = {
  label: string | React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent) => void;
  WrapperComponent?: {
    component: React.FC<any>;
    props: any;
  };
  variant?: 'primary' | 'secondary' | 'tertiary';
  props?: ButtonProps;
  isLoading?: boolean;
  'data-cy'?: string;
};

interface SidebarHeaderProps {
  title: string;
  description?: string;
  status?: {
    type: 'positive' | 'neutral' | 'danger' | 'info' | 'warning';
    label: string;
    leadingIcon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
    emphasis?: 'high' | 'medium' | 'low';
  };
  actions?: Action[];
  subtitles?: string[];
  tabs?: Tab[];
  activeTabId?: string;
  loading?: boolean;
  link?: string;
  'data-cy'?: string;
}

const getSidebarHeaderSelector = (dataCy) => ({
  title: `sidebarHeader__title_${dataCy}`,
  description: `sidebarHeader__description_${dataCy}`,
});

const LoadingComponent = () => {
  return (
    <div role='status' className='w-full p-4 space-y-4 rounded border-y animate-pulse md:p-6'>
      <div className='flex-row justify-between items-center'>
        <div className='h-8 bg-gray-200 w-[357px] mb-2 bg-gradient-to-r from-gray-300 to-gray-100' />
        <div className='h-[24px] bg-gray-200 w-full mb-5 bg-gradient-to-r from-gray-300 to-gray-100' />
        <div className='h-10 bg-gray-200 w-full bg-gradient-to-r from-gray-300 to-gray-100' />
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  );
};

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  title,
  description,
  status,
  actions = [],
  subtitles,
  tabs = [],
  activeTabId,
  loading,
  link,
  'data-cy': dataCy,
}) => {
  const [showCopy, setShowCopy] = useState(false);
  const [hideCopyTooltip, setHideCopyTooltip] = useState(true);

  if (loading) return <LoadingComponent />;

  return (
    <div className='border-b'>
      <div className='mx-6 my-5 gap-y-5 flex flex-col'>
        <div className='gap-y-2'>
          <div
            className='flex items-center mb-2.5 break-words'
            onMouseOver={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
          >
            <div>
              <h1
                data-cy={getSidebarHeaderSelector(dataCy).title}
                className='font-semibold text-[31px] leading-[37px] line-clamp-2'
              >
                {title}
              </h1>
              {description && (
                <p data-cy={getSidebarHeaderSelector(dataCy).description} className='mt-1'>
                  {description}
                </p>
              )}
            </div>
            {showCopy && link ? (
              <div className='ml-2'>
                <Tooltip delayDuration={500} content={`Copy panel link`} showTooltipArrow>
                  <Tooltip delayDuration={0} content={`Copied to clipboard`} open={!hideCopyTooltip} showTooltipArrow>
                    <button
                      className='hover:bg-gray-100 rounded'
                      onClick={() => {
                        navigator.clipboard.writeText(link);
                        setHideCopyTooltip(false);
                        setTimeout(() => {
                          setHideCopyTooltip(true);
                        }, 3000);
                      }}
                    >
                      <MdContentCopy className='m-1' size={16} />
                    </button>
                  </Tooltip>
                </Tooltip>
              </div>
            ) : null}
          </div>
          <div className='flex items-center gap-x-2'>
            {status ? (
              <StatusIndicator
                label={status.label}
                type={status.type}
                leadingIcon={status.leadingIcon}
                trailingIcon={status.trailingIcon}
                emphasis={status.emphasis}
              />
            ) : null}
            <div className='flex gap-x-2 flex-wrap'>
              {subtitles?.map((subtitle: string, index: number) => (
                <div key={`subtitle-${index}`}>
                  <p key={`subtitle-${index}`}>{subtitle}</p>
                  {index != subtitles.length - 1 ? <p key={`subtitle-${index}-separator`}>·</p> : null}
                </div>
              ))}
            </div>
          </div>
        </div>
        {!!actions.length && (
          <ResponsiveButtonTruncator className='gap-2'>
            {actions
              ?.map((action: Action, index: number) => {
                return (
                  <Button
                    label={action.label}
                    {...action.props}
                    emphasis={index === 0 ? 'high' : 'medium'}
                    onClick={action.onClick}
                    isLoading={action.isLoading}
                    key={index}
                    data-cy={`${dataCy}__${action?.['data-cy']}`}
                  />
                );
              })
              .filter(Boolean)}
          </ResponsiveButtonTruncator>
        )}
      </div>
      <TabsComponent tabs={tabs} activeTabId={activeTabId} />
    </div>
  );
};

export default SidebarHeader;
