import { useMutation, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { formatAccountingPeriodDate, removeEmpty } from '../../components/templates/utils';
import {
  getAccountingPeriods,
  closeAccountingPeriod,
  reopenAccountingPeriod,
  upsertAccountingPeriodClosingSettings,
  UpsertAccountingPeriodClosingSettingsParams,
  getAccountingPeriodClosingSettingsForLegalEntity,
  getAccountingPercentage,
  getAccountingPeriodStartDates,
} from 'services';
import { useSession } from '../useSession';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { compareDesc } from 'date-fns';

export interface UseAccountingPeriodsPayload {
  legalEntityIds?: string[];
  startDate?: Date;
  endDate?: Date;
  status?: 'Open' | 'Closed';
  pageSize?: number;
  sort?: number;
  page?: number;
}

export interface GetAccountingPeriodsPayload extends UseAccountingPeriodsPayload {
  organizationId: string;
}

const MINIMUM_START_DATE = new Date('12/01/2020');

const prepareAccountingPeriodsQueryParams = (pageParam: number, payload: UseAccountingPeriodsPayload) =>
  removeEmpty({
    ...payload,
    startDate: new Date(payload.startDate ?? MINIMUM_START_DATE),
    endDate: new Date(payload.startDate ?? new Date()),
    sort: payload.sort ?? -1,
    pageSize: payload.pageSize || 100,
    page: pageParam,
  });

export const useAccountingPeriods = (payload: UseAccountingPeriodsPayload = {}, enabled = true) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNTING_PERIODS, payload),
    async ({ pageParam = 0 }) => {
      const response = await getAccountingPeriods(prepareAccountingPeriodsQueryParams(pageParam, payload));
      return response.data;
    },
    {
      enabled: !!organizationId && enabled,
      cacheTime: 1000 * 60 * 5,
    },
  );
};

export const useGetAccountingPeriodStartDates = (payload: UseAccountingPeriodsPayload = {}, enabled = true) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ACCOUNTING_PERIOD_START_DATES, payload),
    async ({ pageParam = 0 }) => {
      const response = await getAccountingPeriodStartDates(prepareAccountingPeriodsQueryParams(pageParam, payload));
      return (
        response.data?.accountingPeriodStartDates
          ?.map((date) => new Date(date))
          .sort(compareDesc)
          .map((date) => ({
            startDateUTC: date,
            accountingPeriodName: formatAccountingPeriodDate(date),
          })) ?? []
      );
    },
    {
      enabled: !!organizationId && enabled,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    },
  );
};

export type closeAccountingPeriodPayload = {
  accountingPeriodId: string;
  organizationId?: string;
  legalEntityId: string;
};

export const useCloseAccountingPeriod = () =>
  useMutation((data: closeAccountingPeriodPayload) => closeAccountingPeriod(data));

export const useReopenAccountingPeriod = () =>
  useMutation((data: closeAccountingPeriodPayload) => reopenAccountingPeriod(data));

export const useUpsertAccountingPeriodClosingSettingsMutation = () => {
  const { invalidateAccountingPeriodClosingSettings } = useInvalidateQuery();
  return useMutation(
    (data: UpsertAccountingPeriodClosingSettingsParams) => upsertAccountingPeriodClosingSettings(data),
    { onSuccess: () => invalidateAccountingPeriodClosingSettings() },
  );
};

export const useGetAccountingPeriodClosingSettingsForLegalEntity = (legalEntityId?: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryKey: getKey(QUERY_KEY.ACCOUNTING_PERIOD_CLOSING_SETTINGS, { legalEntityId }),
    queryFn: async () => {
      const response = await getAccountingPeriodClosingSettingsForLegalEntity(legalEntityId!);
      return response.data.settings ?? null;
    },
    enabled: !!organizationId && !!legalEntityId && legalEntityId.length > 0,
  });
};

export const useGetAccountingPercentage = (accountingPeriodId?: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery({
    queryKey: getKey(QUERY_KEY.ACCOUNTING_PERCENTAGE, { accountingPeriodId }),
    queryFn: async () => {
      const response = await getAccountingPercentage(accountingPeriodId!);
      return response.data.percentage;
    },
    enabled: !!organizationId && !!accountingPeriodId && accountingPeriodId.length > 0,
  });
};
