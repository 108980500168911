import React from 'react';
import { MdCancel } from 'react-icons/md';
import { InputTrailingAction } from '../input-trailing-action';
import { InputTrailingActionWrapperProps } from '../input-trailing-action/types';

export const ClearInputTrailingAction = ({
  value,
  setValue,
  className,
  callback,
  disabled,
}: InputTrailingActionWrapperProps) => {
  return (
    <>
      {value && value !== '' && (
        <InputTrailingAction
          icon={<MdCancel className='w-5 h-5' />}
          onClick={() => {
            setValue({
              target: {
                value: '',
                rawValue: '',
              },
            });
            if (callback) callback();
          }}
          className={className}
          disabled={disabled}
        />
      )}
    </>
  );
};
