import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { ConditionSelectorMenuTrigger } from '../ConditionSelectorMenuTrigger';
import { FACT } from '../../types';
import { RaincardsPaths } from '../../data';
import { useAddCondition } from '../useAddCondition';
import { getElementIndex, useFilteredConditionGroups } from '../utils';

import { CSRowProps, ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { useConditionSelectorContext } from '../condition-selector-context';
import { useGetRaincardSources } from '../../../../../hooks/http/useRaincards';
import {
  RaincardsSourceConditionSelectorRow,
  convertRaincardToRowFn,
  filterRaincardRowsFn,
} from './RaincardsSourcesConditionsMenu';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';
import { GroupedVirtuoso } from 'react-virtuoso';

const filterRaincardsPathRowsFn: FilterFn<{ label: string; value: string }> = (searchTerm) => (rowData) =>
  rowData.label.toLowerCase().includes(searchTerm.toLowerCase());
const convertRaincardsPathToRowFn: ConvertToRowFn<{ label: string; value: string }> = (condition) => ({
  rowData: condition,
  isSelected: false,
});

const useFilteredRaincardsConditionGroups = () => {
  const { data: raincards, isLoading } = useGetRaincardSources({ legalEntityId: '' });
  const [data, setData] = useState([]);

  useEffect(() => {
    if (raincards) setData(raincards.map((card) => ({ name: card.cardData.nickname, id: card._id })));
  }, [raincards]);

  const [conditionGroups, setConditionGroups] = useState<Omit<ConditionGroupFilterConfig, 'rows'>[]>([]);
  useEffect(() => {
    setConditionGroups([
      {
        heading: 'Raincards conditions',
        data: RaincardsPaths,
        filterFn: filterRaincardsPathRowsFn,
        convertToRowFn: convertRaincardsPathToRowFn,
        Row: RaincardsConditionSelectorRow,
      },
      {
        heading: 'Raincards sources',
        data: data,
        filterFn: filterRaincardRowsFn,
        convertToRowFn: convertRaincardToRowFn,
        Row: RaincardsSourceConditionSelectorRow,
      },
    ]);
  }, [data]);

  const groupedConditions = useFilteredConditionGroups(conditionGroups);

  return { groupedConditions, isLoading };
};

export const RaincardsConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.RAINCARDS,
          path: conditionData.rowData.value,
          operator: 'equal',
          value: conditionData.rowData.label,
        })
      }
    />
  );
};

export const RaincardsConditionsMenu = () => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const { groupedConditions, isLoading } = useFilteredRaincardsConditionGroups();

  return (
    <ConditionsGroup label='Raincards'>
      {debouncedSearchTerm === '' && (
        <>
          {RaincardsPaths.map((path) => (
            <RaincardsConditionSelectorRow conditionData={{ rowData: path, isSelected: false }} key={path.label} />
          ))}
          <ConditionSelectorMenuTrigger label='Raincards Sources' visibleOnMenu='Raincards' />
        </>
      )}

      {debouncedSearchTerm !== '' && (
        <>
          {groupedConditions.headings.length === 0 && !isLoading && (
            <div className='text-center my-2'>No raincards sources or conditions to show</div>
          )}
          {groupedConditions.headings.length === 0 && isLoading && (
            <div className='flex items-center justify-center w-full my-2'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
          {groupedConditions.headings.length > 0 && (
            <GroupedVirtuoso
              style={{ height: 250 }}
              groupCounts={groupedConditions.counts}
              itemContent={(index, groupIndex) => {
                const internalIndex = getElementIndex(groupedConditions.values, index); // index is flattened index, we need index of item in its parent array
                const value = groupedConditions.values[groupIndex][internalIndex];
                if (groupIndex === 0) return <RaincardsConditionSelectorRow conditionData={value} key={index} />;
                return <RaincardsSourceConditionSelectorRow conditionData={value} key={index} />;
              }}
              groupContent={(groupIndex) => (
                <div className='font-medium text-zinc-500 p-1 bg-white'>{groupedConditions.headings[groupIndex]}</div>
              )}
            />
          )}
        </>
      )}
    </ConditionsGroup>
  );
};
