import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { GoTriangleRight } from 'react-icons/go';
import { MdModeEdit } from 'react-icons/md';
import { Button, IndeterminateCheckbox, Tooltip, classNames } from 'ui';
import EmojiPicker from 'emoji-picker-react';
import { useOnClickOutside } from 'usehooks-ts';
import { SourceTableGroupDialog } from './source-table-group-dialog';
import { EditSourceTableGroupProps } from './types';

export const EditSourceTableGroup = ({
  row,
  dragActive = false,
  onDissolveGroup,
  isTableLoading,
  isScrollable,
  onUpdate,
  onEmojiChange,
  data,
}: EditSourceTableGroupProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [open, setOpen] = useState(false);
  const { setNodeRef, isOver } = useDroppable({ id: row.original._id, data: row.original._id });

  const emojiPickerContainerRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(emojiPickerContainerRef, () => setShowEmojiPicker(false));
  return (
    <>
      <tr
        id={dragActive ? `${row.original._id}_draggable` : row.original._id}
        ref={(ref) => {
          setNodeRef(ref);
        }}
        className={classNames(isOver && '[&_td]:!bg-zinc-200', '[&_td]:z-[1] [&_td]:!sticky')}
        style={{ transition: 'transform 0ms linear 0s' }}
      >
        <td className={classNames('w-12 !p-0 !z-[2] left-0 !sticky min-w-[24px] items-center')}></td>
        <td className={classNames('w-12 !p-0 !z-[2] left-[24px] !sticky min-w-[24px] items-center')}>
          {row.getCanExpand() && (
            <div className='flex items-center gap-3 w-fit'>
              <Tooltip content='Expand' removeTooltipContainerClassname>
                <GoTriangleRight
                  onClick={(e) => {
                    e.stopPropagation();
                    row.getToggleExpandedHandler()();
                  }}
                  className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                    'rotate-90': row.getIsExpanded(),
                  })}
                />
              </Tooltip>
              <IndeterminateCheckbox
                isHeader
                checked={row.getIsAllSubRowsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
              />
            </div>
          )}
        </td>
        <td className={classNames('w-12 !p-0 !z-[2] left-[92px] !sticky min-w-[24px] items-center')}>
          <div
            style={{
              boxShadow:
                isScrollable && !isTableLoading
                  ? 'rgba(0, 0, 0, 0.1) 11px 4px 19px -15px, rgba(0, 0, 0, 0.1) 0px 3px 8px -10px'
                  : '',
            }}
            className='flex items-center gap-x-4 h-full'
          >
            {/* <button onClick={() => setShowEmojiPicker((prev) => !prev)}>
              <span>{row.original.emoji ?? '🆕'}</span>
            </button> */}
            <p>{row.original.name}</p>
          </div>
        </td>
        <td />
        <td />
        <td />
        <td />
        <td className={classNames('!p-0 z-[1] !sticky')}>
          <Button
            onClick={() => setOpen(true)}
            emphasis='medium'
            leadingIcon={<MdModeEdit className='text-zinc-800' />}
          />
        </td>
      </tr>
      <div className='z-[1000] absolute left-20' ref={emojiPickerContainerRef}>
        {showEmojiPicker && (
          <EmojiPicker onEmojiClick={({ emoji }) => onEmojiChange(row.original._id, emoji)} skinTonesDisabled />
        )}
      </div>
      <SourceTableGroupDialog
        onUpdate={(groupName: string, selectedRowIds: string[]) => {
          onUpdate && onUpdate(row.original._id, { name: groupName, subRows: selectedRowIds });
          setOpen(false);
        }}
        data={data}
        tableGroupName={row.original.name}
        onDissolveGroup={onDissolveGroup}
        setOpen={setOpen}
        open={open}
        selectedSubRowIds={row.original.subRows.map(({ _id }) => _id)}
        isEdit
      />
    </>
  );
};
