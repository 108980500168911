import { InputLabel } from '../input-label';
import { InputWithExtras } from '../input-with-extras';
import { SingleSelectMenu } from '../select-menu';
import { TagTypesOptions, TagInputProps } from './types';

export const TagInput: React.FC<TagInputProps> = ({ tag, setTag }) => {
  return (
    <div className='grid gap-y-2'>
      <div>
        <InputLabel heading='Tag type' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          isModal={false}
          data-cy='tagType'
          onChange={({ value }: any) => {
            setTag({ ...tag, key: value });
          }}
          value={TagTypesOptions.find((option) => option.value === tag.key)}
          placeholder='Select tag type'
          options={TagTypesOptions}
          onClearValue={() => {
            setTag({ ...tag, key: '' });
          }}
        />
      </div>
      <div>
        <InputLabel heading='Tag value' />
        <InputWithExtras
          value={tag.value}
          disabled={!tag.key}
          data-cy='tagValue'
          onChange={(e) => {
            const value = e.target.value;
            setTag({ ...tag, value });
          }}
        />
      </div>
    </div>
  );
};
