import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';

export const getAutomationScore = async () => {
  return await axios.get<{
    totalTransactions: number;
    transactionsWithJEs: number;
    gasFeeTransactions: number;
    swapTransactions: number;
    bridgeTransactions: number;
    internalTransactions: number;
    success: boolean;
  }>(`${SERVER_URL_CONFIGURATION_SERVICE}/automation-score`);
};
