import { deleteCookie } from 'cookies-next';

export const redirectToLogin = (forceLogout = false) => {
  if (forceLogout) {
    localStorage.clear();
    deleteCookie('organizationId');
    deleteCookie('jwt');
    console.warn('Request encountered a session error, redirecting to /login');
    console.log(`${window.location.origin}/login`);
    window.location.replace(`${window.location.origin}/login`);
    return;
  }
  const shouldSkipLoginRedirect = localStorage.getItem('shouldSkipLoginRedirect');
  if (!shouldSkipLoginRedirect) {
    // set the flag and redirect to login
    localStorage.setItem('shouldSkipLoginRedirect', 'true');
    console.warn('Request encountered a session error, redirecting to /login');
    window.location.replace(`${window.location.origin}/login`);
  }
  console.warn('Request encountered a session error, but /login redirect was skipped');
};
