import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
import { buildMultiParams, prepareSearchParams } from './utils';
import queryString from 'query-string';

export const updateJournalEntryLineTemplate = async (data) =>
  axios.patch(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-line-templates?journalEntryLineTemplateId=${data.journalEntryLineTemplateId}`,
    data.body,
  );

export const createJournalEntryLineTemplate = async (data) =>
  axios.post(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-line-templates?journalEntryTemplateId=${data.journalEntryTemplateId}`,
    data.journalEntryLineTemplate,
  );

export const bulkDeleteJournalEntryLineTemplate = async (data) => {
  return axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-line-templates-bulk?${queryString.stringify(data)}`,
  );
};

export const deleteJournalEntryLineTemplate = async (data) =>
  axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-line-templates?journalEntryTemplateId=${data.journalEntryTemplateId}&journalEntryLineTemplateIds=${data.journalEntryLineTemplateId}&organizationId=${data.organizationId}`,
  );
