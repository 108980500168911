import { useCommandPallete } from '../useCommandPallete';
import { MdChevronLeft } from 'react-icons/md';
import { Button } from 'ui';
import AddLedgerAccountForm from '../../ledger-account/AddLedgerAccountForm';
import { useState } from 'react';
import { SidebarBody, SidebarFooter } from '../../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import { deriveError } from '../../templates/utils';
import { useCreateLedgerAccount } from '../../../hooks/http';
import { toast } from 'react-hot-toast';
import { useKBar } from 'farish-kbar';
import { navigate } from '../../utils';
import { getHost } from '../../../lib/utils';
import { useSession } from '../../../hooks/useSession';
import { LEDGER_ACCOUNT_TYPE } from 'services/http/response.types';
import { useInvalidateQuery } from '../../../hooks';

const CreateLedgerAccount = () => {
  const [form, setForm] = useState({
    name: '',
    number: '',
    parentAccount: '',
    childrenAccount: '',
    type: LEDGER_ACCOUNT_TYPE.INCOME,
  });
  const [ledgerAccountId, setLedgerAccountId] = useState<string | null>(null);

  const { setSecondViewPath } = useCommandPallete();

  const { organizationId, userId } = useSession();
  const { mutateAsync: createAsync, isLoading } = useCreateLedgerAccount();

  const { query } = useKBar();
  const { invalidateLedgerAccounts } = useInvalidateQuery();

  const addLedgerAccount = async () => {
    try {
      const postedLedgerAccount = {
        ledgerAccountName: form.name,
        ledgerAccountType: form.type,
        ledgerAccountSequence: Number(form.number),
        parentLedgerAccountId: form.parentAccount,
        userId,
        organizationId,
      };
      await createAsync(
        {
          ledgerAccount: {
            ...postedLedgerAccount,
          },
          organizationId: organizationId,
        },
        {
          onSuccess: (res) => {
            toast.success('Your ledger account has been successfully added.');
            invalidateLedgerAccounts();
            setLedgerAccountId(res.data.ledgerAccount._id);
          },
        },
      );
    } catch (err) {
      console.log(err);
      toast.error(deriveError(err));
    }
    invalidateLedgerAccounts();
  };

  const SuccessView = () => {
    return (
      <div className='w-full z-commandpallet bg-white h-full rounded-[14px] overflow-auto flex flex-col min-h-[300px]'>
        <div className='flex flex-col items-center justify-center flex-grow'>
          <p className='text-2xl font-medium mt-4'>Ledger account added</p>
          <div className='gap-2 flex'>
            <Button onClick={() => setSecondViewPath('')} emphasis='medium' label='Command Bar' className='mt-4' />
            <Button
              onClick={() => {
                query.toggle();
                navigate(`${getHost()}/configure/ledger-accounts/${ledgerAccountId}`);
              }}
              emphasis='high'
              label='View'
              className='mt-4'
            />
          </div>
        </div>
      </div>
    );
  };

  if (ledgerAccountId) {
    return <SuccessView />;
  }

  return (
    <div className='w-full z-commandpallet bg-white h-full rounded-[14px] overflow-auto min-h-[600px] flex flex-col max-h-[90vh]'>
      <div className='flex items-center px-6 py-4 border-b gap-x-4'>
        <Button
          label='Add ledger account'
          leadingIcon={<MdChevronLeft />}
          onClick={() => setSecondViewPath('')}
          className='hover:bg-indigo-50'
          emphasis='low'
          disableRingOnFocus
        />
      </div>
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={1}>
          <AddLedgerAccountForm loading={isLoading} form={form} setForm={setForm} />
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        primaryBtn={<Button label='Create Account' emphasis='high' onClick={addLedgerAccount} disabled={isLoading} />}
        secondaryBtn={
          <Button label='Cancel' emphasis='medium' onClick={() => setSecondViewPath('')} disabled={isLoading} />
        }
      />
    </div>
  );
};

export default CreateLedgerAccount;
