import { useContext, useEffect } from 'react';
import { useSources } from '../../../hooks';
import { useSourceQueryParams } from './use-source-query-params';
import { useDisplayedTableData } from './utils';
import { MainDirectoryContext } from '../../../context';
import { useGetRaincardSources } from '../../../hooks/http/useRaincards';
import { mergeSources } from '../../templates/utils';
import SourcesTable from './SourcesTable';

export const SourcesTableWithQuery = ({
  searchTerm,
  setSearchTerm,
  setShowCreateSource,
  isSearching,
  setIsSearching,
  sortState,
  setSortState,
}) => {
  const sourcesParams = useSourceQueryParams({ searchTerm, sortState });
  const {
    data: sourcesIQData,
    isLoading: isLoadingWallets,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useSources(sourcesParams);

  const { data: raincards, isLoading: isLoadingRaincards } = useGetRaincardSources(
    { legalEntityId: '', populateFields: true },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 30000,
    },
  );

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);
  const displayedTableData: any = useDisplayedTableData({ sourcesIQData, raincards });

  const { setMainDirectoryIds } = useContext(MainDirectoryContext);
  useEffect(() => {
    const sourceIds = mergeSources(sourcesIQData).map((source) => source._id);
    if (sourceIds) {
      setMainDirectoryIds(sourceIds);
    }
  }, [sourcesIQData]);

  const isLoading = isLoadingWallets && isLoadingRaincards;
  return (
    <SourcesTable
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      setShowCreateSource={setShowCreateSource}
      setSortState={setSortState}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading}
      isSearching={isSearching}
      fetchNextPage={fetchNextPage}
      displayedTableData={displayedTableData}
    />
  );
};
