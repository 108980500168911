import React from 'react';
import { SidebarAnim, SidebarAnimationParent } from '../../atoms/Sidebar';
import { AddAssetPanel } from '../../assets';

export const AssetsSidebar = ({ showCreateAsset, setShowCreateAsset }) => {
  return (
    <SidebarAnimationParent>
      {showCreateAsset && (
        <SidebarAnim>
          <AddAssetPanel
            selectedAsset={false}
            onClose={() => {
              setShowCreateAsset(false);
            }}
          />
        </SidebarAnim>
      )}
    </SidebarAnimationParent>
  );
};

export default AssetsSidebar;
