import { InputComp } from '../../../../atoms';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { GREATER_THAN_AND_EQUAL_OPERATORS } from '../../data';
import RadixSlider from '../../../../atoms/RadixSlider/RadixSlider';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { useEffect, useMemo, useState } from 'react';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  const labelLoss = useMemo(() => ({ label: 'Loss Tolerance', value: 'Loss Tolerance' }), []);
  const labelGain = useMemo(() => ({ label: 'Gain Tolerance', value: 'Gain Tolerance' }), []);

  const isGain = useMemo(() => condition.fact === 'Gain Tolerance', [condition.fact]);

  const [sliderValue, setSliderValue] = useState(condition.value);

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        updateCondition(condition.id, {
          value: sliderValue,
        }),
      5,
    );
    return () => clearTimeout(timeout);
  }, [sliderValue]);

  return (
    <>
      <SingleSelectMenu value={isGain ? labelGain : labelLoss} disabled={true} options={[]} />
      <SingleSelectMenu
        options={GREATER_THAN_AND_EQUAL_OPERATORS}
        value={GREATER_THAN_AND_EQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <InputComp className='!max-w-12' disabled value={condition.value + '%'} label='' />
      <RadixSlider
        disabled={isDisabled}
        min={0}
        max={100}
        step={1}
        value={[sliderValue]}
        onValueChange={(value) => {
          // returns an array of numbers
          setSliderValue(value[0]);
        }}
      />
    </>
  );
};

export const CostBasisRuleCondition = () => (
  <ConditionRow facts={[FACT.GAIN_TOLERANCE, FACT.LOSS_TOLERANCE]}>
    <Row />
  </ConditionRow>
);
