import axios from 'axios';
import { SERVER_URL_JOB_QUEUE } from '../config';
import { JobConfiguration } from 'schemas';
import { prepareSearchParams } from './core/utils';

export enum JOB_NAME {
  IMPORT_TRANSACTION_JOB = 'IMPORT_TRANSACTION_JOB',
  RULESET_JOB = 'RULESET_JOB',
  IMPAIRMENT_JOB = 'IMPAIRMENT_JOB',
  RULE_METRICS_JOB = 'RULE_METRICS_JOB',
  SYNC_JOURNAL_ENTRY_JOB = 'SYNC_JOURNAL_ENTRY_JOB',
  IMPORT_EXCHANGE_SOURCE_JOB = 'IMPORT_EXCHANGE_SOURCE_JOB',
  ORGANIZATION_RULESET_JOB = 'ORGANIZATION_RULESET_JOB',
  IMPORT_HEDGEY_SOURCE_JOB = 'IMPORT_HEDGEY_SOURCE_JOB',
  ORGANIZATION_HEDGEY_SOURCE_JOB = 'ORGANIZATION_HEDGEY_SOURCE_JOB',
  RAINCARDS_CARD_JOB = 'RAINCARDS_CARD_JOB',
  MAPLE_TRANSACTION_IMPORT_JOB = 'MAPLE_TRANSACTION_IMPORT_JOB',
  MAPLE_LENDER_POOL_POSITION_JOB = 'MAPLE_LENDER_POOL_POSITION_JOB',
  IMPORT_MAPLE_SOURCE_JOB = 'IMPORT_MAPLE_SOURCE_JOB',
  RAINCARDS_TRANSACTION_JOB = 'RAINCARDS_TRANSACTION_JOB',
  LOOP_TRANSACTION_JOB = 'LOOP_TRANSACTION_JOB',
  TRANSACTION_CLASSIFICATION = 'TRANSACTION_CLASSIFICATION',
  JOURNAL_ENTRY_RULE_MATCH_JOB = 'JOURNAL_ENTRY_RULE_MATCH_JOB',
  ASSET_RECONSTRUCTION_JOB = 'ASSET_RECONSTRUCTION_JOB',
  EVENT_DRIVEN_ASSET_RECONSTRUCTION_JOB = 'EVENT_DRIVEN_ASSET_RECONSTRUCTION_JOB', // unwinds all the assets in the queue to be back to its original state
  EVENT_DRIVEN_JOURNAL_RECONSTRUCTION_JOB = 'EVENT_DRIVEN_JOURNAL_RECONSTRUCTION_JOB', // recalculates all the journals + gain loss in the queue starting from a specific journal entry
}

export type UseJobProgressPayload = {
  jobName: JOB_NAME;
  referenceId: string;
  organizationId: string;
  uniqueId?: string
};

export const enum JOB_STATUS {
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  JOB_FAILED = 'JOB_FAILED',
  CANCELED = 'CANCELED',
}

export const getJobProgress = async (data: UseJobProgressPayload) => {
  const params = prepareSearchParams(data);
  return axios.get<{ jobConfiguration: JobConfiguration }>(
    `${SERVER_URL_JOB_QUEUE}/job-progress?${params}`,
  );
}

export const getJobConfigurationById = async (data: { jobConfigurationId: string }) =>
  axios.get<{ jobConfiguration: JobConfiguration }>(
    `${SERVER_URL_JOB_QUEUE}/job-configuration-by-id?jobConfigurationId=${data.jobConfigurationId}`,
  );
export const getJobConfigurationByJobNameAndReferenceId = async (data: { jobName: JOB_NAME; referenceId: string }) =>
  axios.get<{ jobConfiguration: JobConfiguration }>(
    `${SERVER_URL_JOB_QUEUE}/job-configuration?jobName=${data.jobName}&referenceId=${data.referenceId}`,
  );
