import { useEffect, useMemo, useState } from 'react';
import { PercentageInput } from '../percentage-input';
import { Button } from '../button';
import { SelectableCard } from '../selectable-card';
import { MdEdit } from 'react-icons/md';
import { PercentageInputWithSelectableValuesProps } from './types';

const PERCENTAGE_VALUES = [
  { label: '25%', value: '25' },
  { label: '50%', value: '50' },
  { label: '75%', value: '75' },
  { label: '100%', value: '100' },
];

export function PercentageInputWithSelectableValues({
  value,
  onChange,
  'data-cy': dataCy,
}: PercentageInputWithSelectableValuesProps) {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const customButtonLabel = useMemo(() => {
    if (value === undefined) return 'Custom';
    if (PERCENTAGE_VALUES.find((val) => val.value === value)) return 'Custom';
    return (
      <span className='inline-flex items-center gap-x-1'>
        <span>{value}%</span> <MdEdit className='w-4 h-4' />
      </span>
    );
  }, [value]);

  const highlightCustomButton = useMemo(
    () => !PERCENTAGE_VALUES.find((val) => val.value === value) && value !== undefined,
    [value],
  );
  const [lastInputValue, setLastInputValue] = useState(value);
  useEffect(() => {
    if (!showCustomInput) setLastInputValue(value);
  }, [value]);

  return (
    <div className='w-full flex items-center gap-x-1'>
      {showCustomInput && (
        <>
          <div className='grow'>
            <PercentageInput onChange={onChange} value={value} data-cy={dataCy} />
          </div>
          <Button label='Save' onClick={() => setShowCustomInput(false)} />
          <Button
            label='Cancel'
            emphasis='medium'
            onClick={() => {
              onChange({ target: { value: lastInputValue } });
              setShowCustomInput(false);
            }}
          />
        </>
      )}
      {!showCustomInput && (
        <>
          {PERCENTAGE_VALUES.map((v, i) => (
            <SelectableCard
              data-cy={`${dataCy}-${v.value}`}
              key={i}
              label={v.label}
              onClick={() => onChange({ target: { value: v.value } })}
              selected={v.value === value}
              className='!p-1'
            />
          ))}

          <SelectableCard
            label={customButtonLabel}
            onClick={() => setShowCustomInput(true)}
            selected={highlightCustomButton}
            className='!p-1'
          />
        </>
      )}
    </div>
  );
}
