import { cn } from '@/shadcn/lib/utils';
import { ResizeIndicatorProps } from '../tanstack-table/types';

export const ResizeIndicator = ({
  isResizing,
  canResize,
  hoveredIndex,
  getResizeHandler,
  index,
  className,
}: ResizeIndicatorProps) => {
  return (
    <>
      {canResize && (
        <div
          onMouseDown={index === 1 ? getResizeHandler && getResizeHandler() : () => {}}
          onTouchStart={index === 1 ? getResizeHandler && getResizeHandler() : () => {}}
          className={cn(
            'resizer absolute top-0 right-0 h-full cursor-col-resize select-none touch-none w-1 bg-[#00000080] opacity-0 duration-150 ',
            isResizing ? 'opacity-100 bg-blue-600' : 'opacity-0',
            hoveredIndex === index && 'opacity-100',
            className,
          )}
        />
      )}
    </>
  );
};
