import { formatTableNumbers } from 'global-utils';
import { ExchangeSourceType } from 'services';
import { DisplayedTransaction } from 'src/components/PageComponents';
import {
  capitalizeFirstLetter,
  currencyImg,
  DefaultCell,
  SingleLineAvatar,
  StatusIndicator,
  TwoLineAvatar,
  TwoLineIcons,
} from 'ui';
import { ColumnsProps } from './types';
import { ColumnLeadingCell } from './columnLeadingCell';
import { useDensity } from 'src/context';
import { dateConverter } from 'src/components/utils';

export const overviewTransactionsColumns: ColumnsProps<DisplayedTransaction, any>[] = [
  {
    accessorKey: 'sequenceNumber',
    header: () => <DefaultCell className='pl-3' label='ID' />,
    cell: ({ row, getValue }) => {
      const { partnerImage, partnerName, chain, title } = getValue() || {
        partnerImage: null,
        partnerName: null,
        chain: null,
        title: null,
        desc: null,
      };
      const showBrandedImage = chain || partnerName;

      const { density } = useDensity();

      const imgSize = density === 'compact' ? 'small' : density === 'default' ? 'large' : 'extra-large';

      return (
        <ColumnLeadingCell
          size={imgSize}
          isAvatarVisible={!!showBrandedImage}
          src={currencyImg(chain?.toLowerCase() ?? '')}
          alt={title}
          type={partnerImage && chain ? 'two-avatars' : 'chain'}
          secondAvatarPosition='bottom'
          secondAvatar={partnerImage}
          initials={getValue().chain?.toLowerCase() ?? 'AA'}
          label={capitalizeFirstLetter(title ?? '')}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          isFlipped={partnerImage ? true : false}
        />
      );
    },
    minSize: 200,
  },
  {
    accessorKey: 'transactionDate',
    header: 'Transaction date',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center justify-between'>
          <DefaultCell textAlign={'text-left'} className='px-0 w-fit' label={dateConverter(new Date(getValue()))} />
        </div>
      );
    },
  },
  {
    accessorKey: 'transactionDirection',
    header: 'Type',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'classification',
    header: 'Category',
    cell: ({ getValue }) => {
      return <TwoLineIcons label={capitalizeFirstLetter(getValue()?.toLowerCase())} withIconLeft />;
    },
  },
  {
    accessorKey: 'transactionDirection',
    header: 'Type',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'transactionAssets',
    header: 'Asset',
    cell: ({ getValue, row }) => {
      const { sourceType } = row.original;
      const { title, assetType, img, isNFT, rawContractAddress } = getValue();
      let imageToRender = <></>;
      if (isNFT) imageToRender = img;
      else {
        const renderAssetTypeImage = assetType?.split(' ')?.length ? true : false;
        if (renderAssetTypeImage)
          return (
            <SingleLineAvatar
              label={title}
              src={currencyImg(
                assetType?.toLowerCase(),
                sourceType === ExchangeSourceType.MAPLE ? '' : rawContractAddress,
              )}
              alt={img}
              size='default'
            />
          );
      }

      return (
        <div className={`flex gap-2 whitespace-nowrap`}>
          <div className='w-6 h-6 flex items-center'>{imageToRender}</div>
          <p>{title}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'grossPrice',
    header: 'Gross',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue()?.$numberDecimal, isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'grossAmount',
    header: 'Token gross',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return <TwoLineAvatar label={getValue()?.amount ?? ''} isAvatarVisible={false} textAlign={'text-right'} />;
    },
  },
];
