import React, { useEffect, useState } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorMenuTrigger } from '../ConditionSelectorMenuTrigger';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConvertToRowFn, FilterFn } from '../types';
import { useConditionSelectorContext } from '../condition-selector-context';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';
import { Virtuoso } from 'react-virtuoso';
import { useGetMaplePoolPositions, useGetMapleSource, useSession } from '../../../../../hooks';
import { useAddCondition } from '../useAddCondition';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { MAPLE_PATH } from '../../data';

type filterDataType = {
  id: string;
  name: string;
};

type maplePoolType = {
  label: string;
  value: string;
};
export const convertMapleToRowFn: ConvertToRowFn<filterDataType> = (pool) => ({
  rowData: { value: pool.name, label: pool.name },
  isSelected: false,
});
export const filterMapleRowsFn: FilterFn<filterDataType> = (searchTerm) => (rowData) =>
  rowData.name?.toLowerCase().includes(searchTerm.toLowerCase());

export const convertMapleClientToRowFn: ConvertToRowFn<filterDataType> = (pool) => ({
  rowData: { value: pool.id, label: pool.name },
  isSelected: false,
});
export const filterMapleClientRowsFn: FilterFn<filterDataType> = (searchTerm) => (rowData) =>
  rowData.name?.toLowerCase().includes(searchTerm.toLowerCase());

export const MapleConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  const conditionLabel = conditionData?.rowData?.label;
  if (!conditionData || !conditionData.rowData) {
    return null;
  }
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.MAPLE,
          path: MAPLE_PATH.POOL,
          operator: 'equal',
          value: conditionLabel,
        })
      }
    />
  );
};

export const MapleConditionClientRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();

  if (!conditionData || !conditionData.rowData) {
    return null;
  }
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.MAPLE,
          path: MAPLE_PATH.SOURCE,
          operator: 'equal',
          value: conditionData?.rowData?.value,
        })
      }
    />
  );
};

export const useMaplePool = () => {
  const { mutateAsync: getMaplePoolPositions } = useGetMaplePoolPositions();
  const { organizationId } = useSession();
  const [maplePool, setMaplePool] = useState<{ label: string; value: string }[]>([]);
  useEffect(() => {
    const fetchMaplePoolPositions = async () => {
      try {
        if (organizationId) {
          const data = {
            organizationId: organizationId,
            isActive: true,
          };
          const poolPositions = await getMaplePoolPositions(data);
          const uniquePoolNames = new Set<string>(poolPositions?.data?.poolPositions?.map((item) => item.poolName));
          const uniquePoolNamesArray = [...uniquePoolNames];

          const uniquePoolLabelsAndValues = uniquePoolNamesArray.flatMap((poolName) => ({
            label: poolName,
            value: poolName,
          }));
          setMaplePool(uniquePoolLabelsAndValues);
        }
      } catch (error) {
        console.error('Error fetching pool positions', error);
      }
    };
    fetchMaplePoolPositions();
  }, [organizationId, getMaplePoolPositions]);

  return maplePool;
};

export const MaplesConditionsMenu = () => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const { data: mapleClient, isLoading: isLoadingMapleSources } = useGetMapleSource();
  const maplePool: maplePoolType[] = useMaplePool();
  const [maplePath, setMaplePath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<{ name: string; id: string }[]>([]);

  useEffect(() => {
    if (maplePath === 'Pools') setData(maplePool.map((pool) => ({ name: pool?.label, id: pool?.value })));
    if (maplePath === 'Maple Source' && mapleClient)
      setData(mapleClient.mapleClients.map((client) => ({ name: client?.name, id: client?._id })));
  }, [maplePath, maplePool, mapleClient]);

  const conditionData = useFilteredConditions<any>(data, filterMapleRowsFn, convertMapleToRowFn);
  const mapleClientData = useFilteredConditions<any>(data, filterMapleClientRowsFn, convertMapleClientToRowFn);

  useEffect(() => {
    setIsLoading(true);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <>
      <ConditionsGroup label='Maple'>
        {debouncedSearchTerm === '' && (
          <>
            <div onClick={() => setMaplePath('Pools')}>
              <ConditionSelectorMenuTrigger label='Pools' visibleOnMenu='Maple' />
            </div>
            <div onClick={() => setMaplePath('Maple Source')}>
              <ConditionSelectorMenuTrigger label='Maple Source' visibleOnMenu='Maple' />
            </div>
          </>
        )}
      </ConditionsGroup>
      {maplePath == 'Pools' && (
        <ConditionsGroup label='Pools'>
          {conditionData.length === 0 && isLoading && (
            <div className='flex items-center justify-center my-4'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
          {conditionData.length === 0 && !isLoading && <div className='text-center'>No maple Pool to show</div>}
          {conditionData && (
            <Virtuoso
              style={{ height: 250 }}
              data={conditionData}
              itemContent={(index, data) => <MapleConditionSelectorRow conditionData={data} key={index} />}
            />
          )}
        </ConditionsGroup>
      )}
      {maplePath == 'Maple Source' && (
        <ConditionsGroup label='Maple Source'>
          {mapleClientData.length === 0 && isLoadingMapleSources && (
            <div className='flex items-center justify-center my-4'>
              <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
            </div>
          )}
          {mapleClientData.length === 0 && !isLoadingMapleSources && (
            <div className='text-center'>No maple Pool to show</div>
          )}
          {mapleClientData.length > 0 && (
            <Virtuoso
              style={{ height: 250 }}
              data={mapleClientData}
              itemContent={(index, data) => <MapleConditionClientRow conditionData={data} key={index} />}
            />
          )}
        </ConditionsGroup>
      )}
    </>
  );
};
