import * as React from 'react';
import { forwardRef } from 'react';
const SvgBasisSet = (props, ref) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 314 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <g clipPath='url(#BasisSet_svg__a)' fill='#121212'>
      <path d='M64.872 1.012H76.83c9.105 0 14.333 3.89 14.333 11.677 0 3.949-1.907 7.215-5.477 9.43 4.674 3.013 6.522 5.524 6.522 11.2-.023 8.906-5.927 13.032-14.898 13.032H64.872V1.013Zm8.492 17.948h2.952c3.819 0 6.355-2.263 6.355-5.305 0-3.324-1.907-5.458-5.904-5.458h-3.403V18.96Zm0 20.218h3.57c4.431 0 6.765-2.64 6.765-6.596 0-4.332-2.952-6.484-7.383-6.484h-2.952v13.08ZM95.443 46.35l11.01-45.339h10.884l10.699 45.34h-8.088l-2.149-9.543h-11.773l-2.403 9.543h-8.18Zm12.131-17.199h8.428l-4.136-18.326h-.121l-4.171 18.326ZM150.807 13.626c-1.415-4.268-3.258-6.09-6.215-6.09-2.958 0-4.674 1.881-4.674 4.958 0 6.843 18.948 7.333 18.948 21.526 0 7.787-5.199 12.92-14.147 12.92-6.886 0-12.241-3.83-14.517-12.743l8.364-1.815c.982 5.464 4.044 7.409 6.58 7.409 2.952 0 5.228-2.01 5.228-5.4 0-8.534-18.942-8.658-18.942-21.337 0-7.775 4.546-12.679 13.344-12.679 7.568 0 12.184 4.58 13.657 10.982l-7.626 2.269ZM166.134 46.35V1.012h8.486v45.34h-8.486ZM200.884 13.626c-1.421-4.268-3.264-6.089-6.222-6.089-2.958 0-4.673 1.88-4.673 4.957 0 6.843 18.947 7.345 18.947 21.526 0 7.787-5.228 12.932-14.147 12.932-6.886-.011-12.229-3.83-14.54-12.743l8.365-1.816c.988 5.465 4.043 7.41 6.585 7.41 2.952 0 5.228-2.01 5.228-5.4 0-8.535-18.948-8.658-18.948-21.337.023-7.787 4.576-12.69 13.374-12.69 7.561 0 12.177 4.58 13.656 10.98l-7.625 2.27ZM242.901 13.626c-1.416-4.267-3.258-6.088-6.216-6.088s-4.673 1.88-4.673 4.957c0 6.843 18.947 7.344 18.947 21.526 0 7.786-5.228 12.932-14.153 12.932-6.88 0-12.241-3.831-14.517-12.743l8.371-1.816c.982 5.464 4.044 7.41 6.58 7.41 2.951 0 5.199-2.01 5.199-5.4 0-8.535-18.948-8.659-18.948-21.338.029-7.786 4.581-12.69 13.367-12.69 7.568 0 12.184 4.58 13.657 10.981l-7.614 2.27ZM257.714 46.35V1.012h23.251v7.663H266.2V19.09h11.259v7.663H266.2v11.936h15.395v7.663h-23.881ZM313.573 1.011v7.663h-9.907v37.677h-8.469V8.668h-9.925V1.005l28.301.006ZM45.439 19.968V.647H26.503v7.727h6.007L7.572 33.82V8.374h11.363V.647H-.001v46.365h45.44V27.696h-7.574v11.582H12.927l24.938-25.44v6.13h7.574Z' />
    </g>
    <defs>
      <clipPath id='BasisSet_svg__a'>
        <path fill='#fff' transform='translate(0 .375)' d='M0 0h313.68v47.155H0z' />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgBasisSet);
export default ForwardRef;
