import React, { useMemo } from 'react';
import { Card } from '../../../styles';
import { ProgressItem } from '../../atoms';
import Link from 'next/link';

export const SetupGuide = ({ completed, data }) => {
  const completedPercentage = useMemo(() => `${(completed / 5) * 100}%`, [completed]);

  return (
    <div>
      <Card className='w-full mr-10'>
        <div>
          <div className='text-2xl font-medium'>Setup guide</div>
          <div className='mt-1'>Follow the task list to complete your set up!</div>
        </div>
        <div className='w-full h-full bg-gray-200 duration-300 border rounded-full'>
          <div
            className={`bg-blue-600 h-2.5 duration-300 rounded-full`}
            style={{
              width: completedPercentage,
            }}
          />
        </div>
        <div className='text-blue-600'>{completedPercentage} completed</div>
      </Card>
      <Card className='!gap-0 my-6'>
        <div>
          <p className='font-semibold text-2xl'>Task list</p>
          <p className='text-lg mt-2'>{completed}/5 setup tasks complete</p>
        </div>
        <ProgressItem
          title={<Link href='/configure/entities'>Enter legal entities to account for</Link>}
          completed={!!(data?.legalEntitiesCount > 0)}
        />
        <ProgressItem
          title={<Link href='/integrations'>Connect wallets to pull transactions</Link>}
          completed={!!(data?.walletsCount > 0)}
        />
        <ProgressItem
          title={<Link href='/configure/ledger-accounts'>Structure your chart of accounts</Link>}
          completed={!!(data?.ledgerAccountsCount > 0)}
        />
        <ProgressItem
          title={<Link href='/configure/rulesets'>Create rules to automate entries</Link>}
          completed={!!(data?.journalEntriesCount > 0)}
        />
        <ProgressItem
          title={<Link href='/reports/balancesheet'>View financial statements in realtime</Link>}
          completed={!!(data?.journalEntriesCount > 0)}
        />
      </Card>
    </div>
  );
};

export default SetupGuide;
