import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownSubHeader,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  DropdownSearchInput,
  DropdownTitleWithToggle,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
  currencyImg,
} from 'ui';
import { FILTER_TYPE } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { useAssetTypeFilterContext } from '../../../../context';

const DEFAULT_TRIGGER_LABEL = 'Assets';
export const AssetTypesFilterDropdown = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const {
    state,
    isLoading,
    setters: { onlySelect, selectAll, deselectAll, setSelectionByRowIndex },
  } = useAssetTypeFilterContext();
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedValues = state.filter((value) => value.selected);
      if (selectedValues.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedValues.length === 1) return `${selectedValues[0].name}`;
      return `${selectedValues[0].name} +${selectedValues.length - 1}`;
    });
  }, [state]);

  const assetsFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.ASSET}`, [dataCy]);
  const [searched, setSearched] = useState('');

  const results = state.map((assetData) => {
    if (assetData.selected) return assetData;
    if (searched.length === 0) return assetData;
    if (assetData.abbreviation.toLowerCase().includes(searched.toLowerCase())) return assetData;
    if (assetData.name.toLowerCase().includes(searched.toLowerCase())) return assetData;
    return null;
  });

  return (
    <Dropdown data-cy={assetsFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).trigger}
            isFocused={open}
            isLoading={isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      deselectAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Assets' data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).body}>
          <DropdownSubHeader>
            <DropdownSearchInput
              value={searched}
              onChange={(e) => setSearched(e.target.value)}
              data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).searchInput}
            />
            <DropdownTitleWithToggle
              className='px-2'
              title='Select all'
              checked={state.length === state.filter((value) => value.selected).length}
              onChange={(checked: boolean) => (checked ? selectAll() : deselectAll())}
            />
          </DropdownSubHeader>
          {results.length === 0 && <div className='p-4 text-center'>No assets to show</div>}
          {results.length !== 0 && (
            <>
              <DropdownGroupBody>
                {results.map((option, i) =>
                  option ? (
                    <CheckboxListItem
                      key={i}
                      enableAvatar
                      label={option.name}
                      bottomText={option.abbreviation}
                      src={currencyImg(option.abbreviation.toLowerCase())}
                      checked={option.selected}
                      onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
                      selectOnlyFn={() => onlySelect(i)}
                    />
                  ) : null,
                )}
              </DropdownGroupBody>
            </>
          )}
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(assetsFilterDataCy).clearAllButton}
            onClick={state.length !== 0 ? deselectAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
