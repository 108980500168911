import axios from 'axios';
import { SERVER_URL_GPT_ECS } from '../config';
import { AssistantsConversation, LedgerAccount, LegalEntity, Transaction, Wallet } from 'schemas';
import { transformGptInstruction } from './utils';
import { useGPTPayloadType } from '../../../apps/next/src/hooks/http/useGPT';

export enum GPTServiceIntentEnum {
  // Legal Entities
  LEGAL_ENTITY_CREATE = 'LEGAL_ENTITY_CREATE',
  LEGAL_ENTITY_RENAME = 'LEGAL_ENTITY_RENAME',
  LEGAL_ENTITY_EDIT_TYPE = 'LEGAL_ENTITY_EDIT_TYPE',
  LEGAL_ENTITY_EDIT_CURRENCY = 'LEGAL_ENTITY_EDIT_CURRENCY',
  LEGAL_ENTITY_EDIT_ADDRESS = 'LEGAL_ENTITY_EDIT_ADDRESS',
  LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY = 'LEGAL_ENTITY_DELETE_SPECIFIC_ENTITY',
  // Ledger Accounts
  LEDGER_ACCOUNT_EDIT_TYPE = 'LEDGER_ACCOUNT_EDIT_TYPE',
  LEDGER_ACCOUNT_EDIT_PARENT = 'LEDGER_ACCOUNT_EDIT_PARENT',
  LEDGER_ACCOUNT_EDIT_CLEARING = 'LEDGER_ACCOUNT_EDIT_CLEARING',
  LEDGER_ACCOUNT_DELETE = 'LEDGER_ACCOUNT_DELETE',
  LEDGER_ACCOUNT_CREATE = 'LEDGER_ACCOUNT_CREATE',
  LEDGER_ACCOUNT_RENAME = 'LEDGER_ACCOUNT_RENAME',
  LEDGER_ACCOUNT_EDIT_SEQUENCE = 'LEDGER_ACCOUNT_EDIT_SEQUENCE',
  LEDGER_ACCOUNT_DELETE_PARENT = 'LEDGER_ACCOUNT_DELETE_PARENT',
  LEDGER_ACCOUNT_AGENT = 'LEDGER_ACCOUNT_AGENT',
  // Wallets
  WALLET_EDIT_ALIAS = 'WALLET_EDIT_ALIAS',
  WALLET_EDIT_ADDRESS = 'WALLET_EDIT_ADDRESS',
  WALLET_EDIT_STATUS = 'WALLET_EDIT_STATUS',
  WALLET_DELETE = 'WALLET_DELETE',
  INVALID_INTENT = 'INVALID_INTENT',
  WALLET_EDIT_LEGAL_ENTITY = 'WALLET_EDIT_LEGAL_ENTITY',
  WALLET_ADD_TAG = 'WALLET_ADD_TAG',
  WALLET_CREATE = 'WALLET_CREATE',
  // create journal entry from template for transaction
  JOURNAL_ENTRY_CREATE_BY_TEMPLATE = 'JOURNAL_ENTRY_CREATE_BY_TEMPLATE',
  // Assets
  ASSET_CREATE = 'ASSET_CREATE',
  // Tags
  TAG_CREATE = 'TAG_CREATE',
  TAG_DELETE = 'TAG_DELETE',
  TAG_EDIT_VALUE = 'TAG_EDIT_VALUE',
  // Analytics
  READ_ANALYTICS = 'READ_ANALYTICS',
  // Spam Assistant
  SPAM_ASSISTANT = 'SPAM_ASSISTANT',
  // GL Assistant
  GL_ASSISTANT = 'GL_ASSISTANT',
  // Operational Transactions Assistant
  OPERATIONAL_TRANSACTIONS_ASSISTANT = 'OPERATIONAL_TRANSACTIONS_ASSISTANT',
  // Journal Entry Assistant
  JOURNALS_ASSISTANT = 'JOURNALS_ASSISTANT',
  // AIO Assistant
  AIO_ASSISTANT = 'AIO_ASSISTANT',
  // Reconciliation Agent
  RECONCILE_BALANCE = 'RECONCILE_BALANCE',
}

export enum FAILURE_MODE {
  NONE = 'NONE',
  GPT_SERVICE_ERROR = 'GPT SERVICE ERROR',
  INTENT_CLASSIFICATION = 'INTENT CLASSIFICATION',
  ENTITY_RECOGNITION = 'ENTITY RECOGNITION',
  GPT_API_ERROR = 'GPT API ERROR',
  CRUD_CALLING = 'CRUD CALLING',
  ASK_FOR_CONFIRMATION = 'ASK FOR CONFIRMATION',
  PASS = 'PASSED ALL',
}

export type ReturnedDocument = LegalEntity | LedgerAccount | Wallet | undefined | null;

export interface CRUDCallingAgentResponse {
  successCC: boolean;
  inputJson: Record<string, any>;
  devError?: string;
  crudResponse?: ReturnedDocument;
  missingFields?: string[];
  existingReferenceType?: string;
  extraFields?: string[];
  coreCrudResponse?: any;
  accountResequencingInfo?: {
    oldSequence: number;
    newSequence: number;
  };
}

export interface EntityRecognitionResponse {
  successERA: boolean;
  entitiesJson: Record<string, any>;
  entitiesJsons: Record<string, any>[];
  humanReadableError?: string;
  devError?: string;
  errorObj?: Error;
}

export type ChainValues = Record<string, any>;

export interface IntentClassificationResponse {
  successIC: boolean;
  intentEnumValue?: GPTServiceIntentEnum;
  devError?: string;
  intentResponse?: ChainValues;
}

export interface TableResponse {
  headers: string[];
  data: any[][];
  xAxisColumnName?: string;
  yAxisColumnNames?: string[];
}

export enum ChartType {
  TABLE = 'TABLE',
  BAR = 'BAR',
  LINE = 'LINE',
  SCATTER = 'SCATTER',
  PIE = 'PIE',
}

export interface GptAnalyticsCrudResponse {
  table?: TableResponse;
  supportedChartTypes: ChartType[];
  dbQueries: Array<{
    collectionName: string;
    queryJson: Record<string, any>;
    responseObject?: any;
    responseTable?: TableResponse;
  }>;
}

// TODO: Clean up response type in the server
export type GPTServiceResponse = {
  returnedDocument?: ReturnedDocument;
  success: boolean;
  successIC?: boolean;
  successERA?: boolean;
  successCC?: boolean;
  failureMode: FAILURE_MODE;
  humanReadableError: string;
  devError: string;
  intent?: GPTServiceIntentEnum;
  entityRecognitionResponse?: EntityRecognitionResponse;
  intentClassifierResponse?: IntentClassificationResponse;
  crudCallingResponse?: CRUDCallingAgentResponse;
  bulkCrudCallingResponses: Array<CRUDCallingAgentResponse>;
  userObjectsString?: string;
  analyticsCrudResponse?: GptAnalyticsCrudResponse;
  assistantsResponse?: {
    assistantConversation: AssistantsConversation;
  };
  formData: FormData;
};

export const callGPTService = async ({
  instruction,
  organizationId,
  userId,
  threadId,
  selectedIntent,
  assistantConversationId,
  formData = new FormData(),
}: useGPTPayloadType) => {
  const { instructionWithMarkup, rawInstruction, mentionedObject } = transformGptInstruction(
    instruction,
    selectedIntent,
  );
  formData.append('instruction', rawInstruction);
  formData.append('instructionWithMarkup', instructionWithMarkup);
  formData.append('organizationId', organizationId);
  formData.append('userId', userId);
  if (threadId) formData.append('threadId', threadId);
  if (selectedIntent) formData.append('selectedIntent', selectedIntent);
  if (assistantConversationId) formData.append('assistantConversationId', assistantConversationId);
  if (mentionedObject) formData.append('mentionedObject', mentionedObject);

  return axios.post<{
    returnedDocument: ReturnedDocument;
    fullGptResponse: GPTServiceResponse;
    transactions: Transaction[];
  }>(`${SERVER_URL_GPT_ECS}/gpt`, formData);
};

export const getConversations = async ({ }) => {
  return axios.get(`${SERVER_URL_GPT_ECS}/conversations`)
}

//    `https://api-gpt.entendre.finance/gpt-service/gpt`,
