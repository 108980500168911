import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { mapTableKeyToOriginalKey } from '../../utils';
import { TABLE_TYPE } from '../../../context';
import Table from 'src/components/tables/tanstack-table/Table';
import { sourcesColumns } from 'src/components/tables';
import { EditColumns } from 'src/components/tables/table-components';
import { useGetTableGroups, useUpdateTableGroups } from 'src/hooks';
import { CreateSourceTableGroup } from 'src/components/dashboard/TableGroup';
import { MdModeEditOutline } from 'react-icons/md';
import { DensityDropdown } from 'src/components/tables/table-components';

const tableName = 'Source';

function prepareTableData(cardsData: any[], groupsData) {
  // Create a map for efficient lookup of card data by ID
  const cardMap = cardsData.reduce((acc, card) => {
    acc[card._id] = card;
    return acc;
  }, {});

  // Initialize an array to store table rows
  const tableRows: any[] = [];

  // Process groups
  groupsData.groups.forEach((group) => {
    const groupRow: any = {
      isGroup: true,
      isOpen: false, // Flag to track whether the group is open or closed
      subRows: [], // Initialize an empty array to store sub-rows
      name: group.name,
      icon: group.icon,
      emoji: group.emoji,
      groupId: group._id,
    };

    // Add sub-rows to the group if they exist
    group.subRows?.forEach((cardId) => {
      const card = cardMap[cardId];
      if (card) {
        card.groupId = groupRow.groupId;
        card.groupName = groupRow.name;
        card.isSubRow = true;
        groupRow.subRows.push(card);
      }
    });

    tableRows.push(groupRow);
  });

  // Add filtered cards directly to the tableRows
  cardsData.forEach((card) => {
    if (!groupsData.groups.some((group) => group.subRows.includes(card._id))) {
      tableRows.push(card);
    }
  });

  return tableRows;
}

const editColumn = {
  header: '',
  accessorKey: 'editBtn',
  enableSort: false,
  cell: ({ row, table }: any) => {
    if (row.original.isGroup)
      return (
        <div className='w-full text-center'>
          {/* group edit button */}
          <button onClick={() => table?.options?.meta?.onOpenGroupDialog(row.original)}>
            <MdModeEditOutline />
          </button>
        </div>
      );
  },
};

export const SourcesTable = ({
  displayedTableData = [],
  searchTerm,
  setSearchTerm,
  isSearching,
  setSortState,
  setShowCreateSource,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const [tableData, setTableData] = useState<any[]>([]);
  const lastDataLength = useRef(displayedTableData.length);
  const [tableGroups, setTableGroups] = useState({ groups: [] });
  const { data: groupData } = useGetTableGroups(tableName);
  const [columns, setColumns] = useState([...sourcesColumns, editColumn]);

  const handleColumnsUpdate = (updatedColumns) => {
    setColumns(updatedColumns);
  };

  useEffect(() => {
    if (groupData) {
      setTableGroups(groupData as any);
    }
  }, [groupData]);

  useEffect(() => {
    if (tableGroups && displayedTableData.length) {
      lastDataLength.current = displayedTableData.length;
      const data: any = prepareTableData(displayedTableData, tableGroups);
      setTableData(data);
    }
  }, [tableGroups, displayedTableData]);

  const { mutateAsync: updateTableGroups, isLoading: isUpdatingTableGroups } = useUpdateTableGroups(tableName);

  const handleGroupCreationDialogOpen = useCallback(
    async (groupName: string, rows: string[]) => {
      const newGroupId = tableData.length.toString().padEnd(12, new Date().getTime().toString());
      const newGroup = {
        _id: newGroupId,
        name: groupName,
        subRows: rows,
      };

      // Ensure that subRows are removed from other groups
      const updatedGroups: any = tableGroups ? [...tableGroups.groups] : [];
      if (newGroup.subRows.length > 0) {
        updatedGroups.forEach((group: any) => {
          if (group.subRows) {
            group.subRows = group.subRows.filter((subRowId) => !newGroup.subRows.includes(subRowId));
          }
        });
      }

      updatedGroups.push(newGroup);

      const payload = {
        table: tableName,
        groups: updatedGroups,
      };

      try {
        await updateTableGroups(payload);
        setTableGroups({ groups: updatedGroups }); // Update local state with the new group
      } catch (error) {
        console.error('Failed to create group:', error);
      }
    },
    [tableGroups, updateTableGroups, tableName],
  );

  return (
    <TableWrapper>
      <Table
        setData={setTableData}
        tableName={tableName}
        setTableGroups={setTableGroups}
        updateTableGroups={updateTableGroups}
        tableGroups={tableGroups}
        onRowClick={(row) => {
          setShowCreateSource(false);
          openSidebar('sources', { id: row.original._id });
        }}
        columns={columns}
        data={tableData}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        tableType={TABLE_TYPE.ALL_SOURCES}
        onSortingChange={(state) => {
          if (state.length === 0) return setSortState([]);
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Sources'), desc: state[0].desc }]);
        }}
      >
        <div className='flex items-center justify-between gap-3 px-6 py-5 md:gap-0 w-full'>
          <div className='flex items-center gap-3'>
            <SearchInput
              data-captive='sources_searchTermInput'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              loading={isSearching}
              placeholder='Search sources...'
              className='placeholder:text-zinc-500 '
              containerClassName=' min-w-0 '
              resultCount={lastDataLength.current}
            />
          </div>
          <div className='flex gap-3'>
            <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} />
            <CreateSourceTableGroup
              data={tableData}
              onCreateNewGroup={handleGroupCreationDialogOpen}
              isLoading={isUpdatingTableGroups}
            />
            <DensityDropdown />
          </div>
        </div>
      </Table>
    </TableWrapper>
  );
};

export default SourcesTable;
