/* eslint-disable no-unused-vars */
import React from 'react';
import { Badge } from 'ui';

export type Tab = {
  label?: string;
  onClick?: (clickedTab: Tab) => void;
  active?: boolean;
  title: string;
  className?: string;
  titleClassName?: string;
};

type Props = {
  tabs: Tab[];
  activeTabId?: string;
};

export const TabsComponent: React.FC<Props> = ({ tabs, activeTabId }) => {
  return (
    <div className='flex mx-6 gap-x-6 overflow-x-auto scrollbar-thin'>
      {tabs.map((tab: Tab, index: number) => (
        <div
          key={index}
          className={`min-w-fit gap-x-1 pb-1 items-center ${
            tab.title === activeTabId
              ? 'border-black font-semibold'
              : 'border-transparent  hover:border-gray-300 hover:font-medium'
          } hover:cursor-pointer border-b-2 active:opacity-60 ${tab.className}`}
          onClick={() => tab.onClick?.(tab)}
        >
          <div className={`inline-block ${tab.titleClassName}`}>{tab.title}</div>
          {tab.label ? (
            <Badge key={`icon-${index}`} className='mx-2 !inline-flex' variant='secondary'>
              {tab.label}
            </Badge>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default TabsComponent;
