import React, { useState } from 'react';
import { Dialog } from 'ui';

import { BulkUpdateTransactionMemoModalTrigger } from './trigger';
import { BulkUpdateTransactionMemoModalContent } from './content';

export const BulkUpdateTransactionMemoModal = ({
  transactionIds,
  showTrigger = true,
  onSuccess,
  triggerProps = {},
}) => {
  const [open, onOpenChange] = useState(false);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && <BulkUpdateTransactionMemoModalTrigger {...triggerProps} onClick={() => onOpenChange(true)} />}
      <BulkUpdateTransactionMemoModalContent
        transactionIds={transactionIds}
        onSuccess={onSuccess}
        onOpenChange={onOpenChange}
      />
    </Dialog>
  );
};
