import axios from 'axios';
import { SERVER_URL_BILLING_SERVICE } from '../../config';

export const getLoopCheckoutUrl = ({
  subscriptionPriceId,
  aiCreditsRechargePriceId,
  unlimitedAiCredits,
  subscriptionCadence,
}) =>
  axios.post<{ url: string }>(`${SERVER_URL_BILLING_SERVICE}/loop/checkout-url`, {
    subscriptionPriceId,
    aiCreditsRechargePriceId,
    unlimitedAiCredits,
    subscriptionCadence,
  });

export const getLoopCheckoutUrlV2 = ({
  subscriptionPriceId,
  aiCreditsRechargePriceId,
  unlimitedAiCredits,
  subscriptionCadence,
  withTrial,
  trialPeriodDays,
}) =>
  axios.post<{ url: string }>(`${SERVER_URL_BILLING_SERVICE}/loop/checkout-url/v2`, {
    subscriptionPriceId,
    aiCreditsRechargePriceId,
    unlimitedAiCredits,
    subscriptionCadence,
    withTrial,
    trialPeriodDays,
  });
