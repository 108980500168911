import { formatTableNumbers, formatTransactionDateAsTimestamp, handleWalletName } from 'global-utils';
import Link from 'next/link';
import { BiSolidDockRight } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';
import { ExchangeSourceType } from 'services';
import { DisplayedTransaction } from 'src/components/PageComponents';
import {
  capitalizeFirstLetter,
  currencyImg,
  DefaultCell,
  MultipleActions,
  SingleLineAvatar,
  StatusIndicator,
  Truncate,
  TwoLineAvatar,
  TwoLineIcons,
} from 'ui';
import { ColumnsProps } from './types';
import { useContext } from 'react';
import { SidebarGlobalContext, useDensity } from 'src/context';
import { LegalEntity } from 'services/http/response.types';
import { ColumnLeadingCell } from './columnLeadingCell';
export const transactionsColumns: ColumnsProps<DisplayedTransaction, any>[] = [
  {
    accessorKey: 'sequenceNumber',
    header: 'ID',
    cell: ({ row, getValue }) => {
      const { density } = useDensity();
      const imgSize = density === 'compact' ? 'small' : density === 'default' ? 'large' : 'extra-large';

      const value = getValue();
      const { partnerImage, partnerName, chain, title, assetType } =
        value.chain || value.partnerName
          ? value
          : {
              partnerImage: row.original?.sequenceNumber?.partnerImage,
              partnerName: row.original?.sequenceNumber.partnerName,
              chain: row.original?.sequenceNumber?.chain,
              title: row.original?.sequenceNumber?.title,
              assetType: row.original?.assetType,
            };

      const recordChain = chain || partnerName || assetType;

      return (
        <ColumnLeadingCell
          size={imgSize}
          isAvatarVisible={!!recordChain}
          src={currencyImg(recordChain)}
          alt={title}
          type={partnerImage && chain ? 'two-avatars' : 'chain'}
          secondAvatarPosition='bottom'
          secondAvatar={partnerImage}
          initials={recordChain || 'AA'}
          label={capitalizeFirstLetter(title ?? '')}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          isFlipped={partnerImage ? true : false}
        />
      );
    },
    minSize: 200,
  },
  {
    accessorKey: 'transactionDate',
    header: 'Transaction date',
    cell: ({ getValue }) => {
      const timestamp = formatTransactionDateAsTimestamp(new Date(getValue()));
      return (
        <div className='flex items-center justify-between'>
          <DefaultCell textAlign={'text-left'} className='px-0 w-fit' label={timestamp} />
        </div>
      );
    },
  },
  {
    visibility: false,
    accessorKey: 'legalEntityId',
    header: 'Legal entity',
    cell: ({ getValue, row }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()?.entityName}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              className='px-0'
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('entities', { id: (row.original?.legalEntityId as LegalEntity)._id });
                  },
                  tooltipContent: 'View in panel',
                },
                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/entities/${getValue()?._id ?? ''}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    visibility: false,
    accessorKey: 'transactionHash',
    header: 'Transaction hash',
    cell: ({ getValue }) => {
      const title = getValue()?.title;
      return (
        <div
          className='flex-shrink-0 w-full' // Prevent the link from shrinking
        >
          <Link target='_blank' href={getValue()?.desc ?? ''} onClick={(event) => event.stopPropagation()}>
            {title && <Truncate className='hover:underline' text={title} />}
          </Link>
        </div>
      );
    },
  },
  {
    accessorKey: 'classification',
    header: 'Category',
    cell: ({ getValue }) => {
      return <TwoLineIcons label={capitalizeFirstLetter(getValue()?.toLowerCase())} withIconLeft />;
    },
  },
  {
    accessorKey: 'transactionDirection',
    header: 'Type',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-start items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'transactionAssets',
    header: 'Asset',
    cell: ({ getValue, row }) => {
      const { sourceType } = row.original;
      const { title, assetType, img, isNFT, rawContractAddress } = getValue();
      let imageToRender = <></>;
      if (isNFT) imageToRender = img;
      else {
        const renderAssetTypeImage = assetType?.split(' ')?.length ? true : false;
        if (renderAssetTypeImage)
          return (
            <SingleLineAvatar
              label={title}
              src={currencyImg(
                assetType?.toLowerCase(),
                sourceType === ExchangeSourceType.MAPLE ? '' : rawContractAddress,
              )}
              alt={img}
              size='default'
            />
          );
      }

      return (
        <div className={`flex gap-2 whitespace-nowrap`}>
          <div className='w-6 h-6 flex items-center'>{imageToRender}</div>
          <p>{title}</p>
        </div>
      );
    },
  },
  {
    accessorKey: 'fromAddress',
    header: 'From',
    cell: ({ getValue }) => {
      const walletName = getValue()?.walletName;
      const address = getValue()?.address;
      const exchange = getValue()?.exchange;
      const rain = getValue()?.rain;

      const textValue = handleWalletName({
        walletName: getValue()?.walletName,
        address: getValue()?.address,
        exchange: getValue()?.exchange,
        rain: getValue()?.rain,
      });

      const content = textValue !== '[object Object]' ? textValue : '';
      const { density } = useDensity();

      if (!walletName && !rain && !exchange && address) return <Truncate text={address} />;

      return (
        <TwoLineAvatar
          label={content}
          isAvatarVisible={false}
          textAlign='text-left'
          bottomText={
            ['compact', 'default'].includes(density) ? '' : capitalizeFirstLetter(getValue()?.walletType ?? '')
          }
        />
      );
    },
  },
  {
    accessorKey: 'toAddress',
    header: 'To',
    cell: ({ getValue }) => {
      const walletName = getValue()?.walletName;
      const address = getValue()?.address;
      const exchange = getValue()?.exchange;
      const rain = getValue()?.rain;

      const textValue = handleWalletName({
        walletName: getValue()?.walletName,
        address: getValue()?.address,
        exchange: getValue()?.exchange,
        rain: getValue()?.rain,
      });
      const content = textValue !== '[object Object]' ? textValue : '';
      const { density } = useDensity();

      if (!walletName && !rain && !exchange && address) return <Truncate text={address} />;

      return (
        <TwoLineAvatar
          label={content}
          isAvatarVisible={false}
          textAlign='text-left'
          bottomText={
            ['compact', 'default'].includes(density) ? '' : capitalizeFirstLetter(getValue()?.walletType ?? '')
          }
        />
      );
    },
  },
  {
    accessorKey: 'journalEntry',
    header: 'Journal',
    cell: ({ getValue }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: '!min-h-[15px] !min-w-[15px]',
        default: '!h-[20px] !w-[20px]',
        comfortable: '!w-[20px] !h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()?.journalSequenceNumber}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              className='px-0'
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('journals', { id: getValue()?._id });
                  },
                  tooltipContent: 'View in panel',
                },

                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/ledger/journals/${getValue()?._id}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    visibility: false,
    accessorKey: 'journalEntryTemplate',
    header: 'Journal entry template',
    cell: ({ getValue }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: '!min-h-[15px] !min-w-[15px]',
        default: '!h-[20px] !w-[20px]',
        comfortable: '!w-[20px] !h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()?.name}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              className='px-0'
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('templates', { id: getValue()?._id });
                  },
                  tooltipContent: 'View in panel',
                },

                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/templates/${getValue()?._id}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    visibility: false,
    accessorKey: 'memo',
    header: 'Transaction Memo',
    cell: ({ getValue, cell }) => {
      const index = cell.column.getIndex();
      return <DefaultCell label={getValue()} textAlign={'text-left'} className={index === 0 ? 'font-semibold' : ''} />;
    },
  },
  {
    accessorKey: 'grossPrice',
    header: 'Gross',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue()?.$numberDecimal, isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'grossAmount',
    header: 'Token gross',
    textAligned: 'text-right',
    cell: ({ getValue, row }) => {
      return (
        <TwoLineAvatar
          label={(getValue()?.amount || row.original?.grossAmount['value']) ?? ''}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'netPrice',
    header: 'Net',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue()?.$numberDecimal, isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'netAmount',
    header: 'Token net',
    textAligned: 'text-right',
    cell: ({ getValue, row }) => {
      return (
        <TwoLineAvatar
          label={(getValue()?.amount || row.original?.netAmount['value']) ?? ''}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'feePrice',
    header: 'Fee',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue()?.$numberDecimal, isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'fee',
    header: 'Token fee',
    textAligned: 'text-right',
    cell: ({ getValue, row }) => {
      return (
        <TwoLineAvatar
          label={(getValue()?.amount || row.original?.fee['value']) ?? ''}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
];
