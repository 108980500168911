import { getMappedValue } from '../../utils';
import { useRuleConfigurator } from '../../rule-configurator-context';
import { CoinbasePrimeConditionMenu } from '../../../CoinbasePrimeConditionMenu';
import { FACT } from '../../types';
import { ConditionRow } from './ConditionRow';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <CoinbasePrimeConditionMenu isDisabled={isDisabled} />

      <SingleSelectMenu
        options={getMappedValue(condition.fact, 'operators')}
        defaultValue={{ label: 'Credits', value: 'Credits' }}
        value={getMappedValue(condition.fact, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
    </>
  );
};

export const CoinbasePrimeRuleCondition = () => (
  <ConditionRow facts={[FACT.COINBASE_PRIME]}>
    <Row />
  </ConditionRow>
);
