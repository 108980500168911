import { useEffect, useState } from 'react';
import { GenericFilterFn } from './types';

export function useFiltered<T>(state: T, filterValue: any, filterFn: GenericFilterFn<T>) {
  const [results, setResults] = useState<T>(state);

  useEffect(() => {
    const filtered = filterFn(state, filterValue);
    setResults(filtered);
  }, [state, filterValue, filterFn]);

  return results;
}
