import { useCallback, useEffect, useState } from 'react';
import { useSession } from '../../hooks';
import { JOB_NAME, getJobProgress } from 'services';
import { isJobComplete, shouldUpdateJobConfiguration } from 'global-utils';
import { JobProgressUpdates } from '../../pubsub-topics';
import { filter, map } from 'rxjs';
import { JobConfiguration } from 'schemas';
import { useConditionalInterval } from './use-conditional-interval';

// returns the latest job configuration for a job using websocket updates and periodic api calls
export const useLatestJobConfiguration = (referenceId: string, jobName: JOB_NAME, uniqueId?: string) => {
  const { organizationId } = useSession();
  const [jobConfiguration, setJobConfiguration] = useState<JobConfiguration | undefined>(undefined);
  // only runs if the job is not complete, fetch updates every 5 secs

  const syncFromApi = useCallback(async () => {
    const response = await getJobProgress({ jobName, referenceId, organizationId, uniqueId });
    const jobConfigurationFromApi = response.data?.jobConfiguration;

    // console.log(
    //   'update from api',
    //   jobConfigurationFromApi,
    // );
    setJobConfiguration((prev) =>
      shouldUpdateJobConfiguration(prev, jobConfigurationFromApi) ? jobConfigurationFromApi : prev,
    );
  }, []);

  useConditionalInterval(
    syncFromApi,
    1000, // wait for 1 secs before first fetch
    5000, // run fetch on 5 sec interval
    () => !isJobComplete(jobConfiguration), // till this function returns true
  );

  const clearJobConfiguration = () => {
    setJobConfiguration(undefined);
  };

  useEffect(() => {
    const subscription = JobProgressUpdates.pipe(
      filter((jobUpdate) => {
        const jobConfiguration: JobConfiguration = jobUpdate.payload.jobConfig;
        if (!jobConfiguration) return false;
        if (
          jobConfiguration.referenceId !== referenceId ||
          jobConfiguration.jobName !== jobName
        )
          return false;

        return true;
      }),
      map((jobUpdate) => jobUpdate.payload.jobConfig),
    ).subscribe((jobConfigurationFromWebsocket) => {
      // console.log('update from websocket: ', jobConfigurationFromWebsocket);
      setJobConfiguration((prev) =>
        shouldUpdateJobConfiguration(prev, jobConfigurationFromWebsocket) ? jobConfigurationFromWebsocket : prev,
      );
    });
    return () => subscription.unsubscribe();
  }, [referenceId, jobName]);

  return { jobConfiguration, clearJobConfiguration }; // Return job configuration and loading state
};
