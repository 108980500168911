import React, { useEffect, useState } from 'react';

import { InputLabel, Button, SingleSelectMenu, DropdownOption } from 'ui';
import { CADENCES } from '../../../constants';

export const SetJobCadence = ({ onSubmit, onCancel, disabled, isLoading, isSubmitting, defaultCronExpression }) => {
  const [selected, setSelected] = useState<DropdownOption>(
    CADENCES.find((item) => item.value === defaultCronExpression) ?? CADENCES[0],
  );

  useEffect(() => {
    if (defaultCronExpression)
      setSelected(CADENCES.find((item) => item.value === defaultCronExpression) ?? CADENCES[0]);
  }, [defaultCronExpression]);

  return (
    <div className='flex flex-col items-start w-full'>
      <InputLabel heading='Recurrence' />

      <SingleSelectMenu
        isModal
        fullWidth
        isOnSidepanel
        enableSearch={false}
        options={CADENCES}
        value={selected}
        onChange={(value) => setSelected(value)}
        isLoading={isLoading}
        disabled={disabled}
      />

      <div className='flex place-self-end mt-12 gap-2'>
        <Button onClick={onCancel} emphasis='medium' label='Cancel' />
        <Button onClick={() => onSubmit(selected.value)} isLoading={isSubmitting} disabled={disabled} label='Done' />
      </div>
    </div>
  );
};
