import { AnimatePresence } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { SliderContext } from '../../../../context/SliderProvider';
import { useTabState } from '../../../../hooks';

export const SidebarAnimationParent = ({ children }: { children: React.ReactNode }) => {
  const { setSidebarWidth } = useContext(SliderContext);
  const { primaryPanel, secondRouteStack } = useTabState();
  useEffect(() => {
    if (!Object.keys(primaryPanel)?.length && !secondRouteStack?.length) {
      setSidebarWidth(0);
    }
  }, [children]);
  return <AnimatePresence onExitComplete={() => setSidebarWidth(0)}>{children}</AnimatePresence>;
};

export default SidebarAnimationParent;
