import { Tag } from 'services/http/response.types';
import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import { useTags } from '../../../hooks';
import { mergeTags } from '../../../components/templates/utils';
import { useQueryDataWithFilterState } from './use-query-with-filter-state';
import { FilterContextParams } from '../types';
import { useMemo } from 'react';

export type TagFilterContextType = FilterContextParams<Tag>;
export const useTagFilterState = (page: string) => {
  const { state, setState } = useFilterState<Tag>(FILTER_TYPE.TAG, page);

  const { data, isLoading } = useTags();
  useQueryDataWithFilterState(data, setState, mergeTags);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
