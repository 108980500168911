import PapaParse, { UnparseConfig } from 'papaparse';
import toast from 'react-hot-toast';
import { deriveError } from '../../components/templates/utils';

// derived from https://github.com/Bunlong/react-papaparse/blob/master/src/useCSVDownloader.tsx
type AsyncFn = () => Promise<void>;
export const withCsvToasts = (cb: AsyncFn) => async () => {
  const toastId = toast.loading('Preparing CSV');
  try {
    await cb();
    toast.success('Success', { id: toastId });
  } catch (error) {
    toast.error(deriveError(error), { id: toastId });
  }
};

export const downloadCsv = async (data: any, filename: string, config: UnparseConfig = {}) => {
  const csvContent = PapaParse.unparse(data, config);

  const csvData = new Blob([`${csvContent}`], {
    type: 'text/csv;charset=utf-8;',
  });

  const navObj: any = window.navigator;
  let csvURL: any = null;
  if (navObj.msSaveBlob) {
    csvURL = navObj.msSaveBlob(csvData, `${filename}.csv`);
  } else {
    csvURL = window.URL.createObjectURL(csvData);
  }

  const link = document.createElement('a');
  link.href = csvURL as string;
  link.setAttribute('download', `${filename}.csv`);
  link.click();
  link.remove();
};
