import React, { useRef, useState } from 'react';
import { Button } from '../button';
import { DropdownTrigger, Dropdown, DropdownContent, DropdownTitleWithToggle, DropdownBody } from './Dropdown';
import { classNames } from '../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';

export const MultiSelectMenuV2 = function ({
  onSelectAll,
  onDeselectAll,
  isLoading,
  label,
  children,
  hasSelections,
  allOptionsSelected,
  enableHeightCalculation = true,
}: {
  onSelectAll: () => void;
  onDeselectAll: () => void;
  hasSelections: boolean;
  isLoading?: boolean;
  label?: string;
  children: React.ReactNode;
  allOptionsSelected: boolean;
  enableHeightCalculation?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  return (
    <Dropdown open={open} onOpenChange={setOpen} modal>
      <DropdownTrigger ref={triggerRef}>
        <Button
          isFocused={open}
          emphasis='medium'
          isLoading={isLoading}
          onClick={() => setOpen((prev) => !prev)}
          label={label}
          className='duration-100 py-0 w-full flex justify-between'
          labelContainerClassname='font-medium'
          trailingIconContainerClassname={classNames(hasSelections && 'mr-0 p-2 duration-100 rounded-r-lg bg-white')}
          trailingIcon={
            <div className='flex items-center'>
              {hasSelections && (
                <span onClick={onDeselectAll}>
                  <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                </span>
              )}
              <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
            </div>
          }
        />
      </DropdownTrigger>
      <DropdownContent
        avoidCollisions
        triggerRef={triggerRef}
        sameWidthAsTrigger
        enableHeightCalculation={enableHeightCalculation}
        className='p-1 !z-50'
      >
        <DropdownTitleWithToggle
          title='Select all'
          checked={allOptionsSelected}
          onChange={(checked) => {
            if (checked) onSelectAll();
            else onDeselectAll();
          }}
        />
        <DropdownBody>
          <div className='overflow-hidden flex flex-col gap-1 flex-shrink-0'>{children}</div>
        </DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};
