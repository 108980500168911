import React, { forwardRef } from 'react';
import { ButtonProps } from './types';
import { LoaderIcon } from '../loader-icon';
import { BaseButton } from '../base-button';

export const ButtonComponent = (
  { isLoading, trailingIcon, status, ...rest }: ButtonProps,
  ref?: React.Ref<HTMLButtonElement>,
) => {
  return <BaseButton {...rest} ref={ref} status={status} trailingIcon={isLoading ? <LoaderIcon /> : trailingIcon} />;
};

export const Button = forwardRef(ButtonComponent);
