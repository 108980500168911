import { useRuleConfigurator } from '../../rule-configurator-context';
import { getMappedValue } from '../../utils';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';
import { VALUE_DROPDOWN_OPTIONS } from '../../../../../constants';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu
        value={{ label: condition.fact, value: condition.fact }}
        options={[
          { label: 'Gain', value: 'Gain' },
          { label: 'Loss', value: 'Loss' },
        ]}
        onChange={(value) => {
          updateCondition(condition.id, {
            fact: value.value as FACT,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        options={getMappedValue(condition.fact, 'operators')}
        value={getMappedValue(condition.fact, 'operators', condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <SingleSelectMenu
        options={VALUE_DROPDOWN_OPTIONS}
        onChange={({ value }) => updateCondition(condition.id, { value })}
        value={VALUE_DROPDOWN_OPTIONS.find((option) => option.value === `${condition.value}`)}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
    </>
  );
};

export const GainLossRuleCondition = () => (
  <ConditionRow facts={[FACT.GAIN, FACT.LOSS]}>
    <Row />
  </ConditionRow>
);
