import React, { useEffect, useState } from 'react';
import { MdCreditCard } from 'react-icons/md';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtrasProps } from '../input-with-extras/types';

import { useProbablyControlledStateForInput } from '../input-with-extras';
import Cleave from 'cleave.js/react';
import { classNames } from '../utils';
import { SiVisa, SiMastercard, SiJcb, SiDiscover, SiAmericanexpress } from 'react-icons/si';
import useDimensions from 'react-cool-dimensions';

const cardStarts = {
  jcb: ['35', '2131', '1800'],
  mastercard: ['51', '52', '53', '54', '55', '22', '23', '24', '25', '26', '27'],
  discover: ['6011', '65', '644', '645', '646', '647', '648', '649'],
  americanexpress: ['34', '37'],
  visa: ['4'],
};

const getCardTypeFromValue = (value: string) => {
  if (cardStarts.jcb.find((firstDigits) => value.startsWith(firstDigits))) return 'jcb';
  if (cardStarts.mastercard.find((firstDigits) => value.startsWith(firstDigits))) return 'mastercard';
  if (cardStarts.discover.find((firstDigits) => value.startsWith(firstDigits))) return 'discover';
  if (cardStarts.americanexpress.find((firstDigits) => value.startsWith(firstDigits))) return 'americanexpress';
  if (cardStarts.visa.find((firstDigits) => value.startsWith(firstDigits))) return 'visa';
  return 'unkown';
};

export const PaymentMethodInput = (props: InputWithExtrasProps) => {
  const { observe: leadingRef, width: leadingWidth } = useDimensions();
  const { observe: trailingRef, width: trailingWidth } = useDimensions();
  const { inputProps } = useProbablyControlledStateForInput({
    value: props.value,
    onChange: props.onChange,
  });
  const [cardType, setCardType] = useState('unkown');
  const [padding, setPadding] = useState({ left: 0, right: 0 });
  const inputClassName = classNames(
    'form-input w-full h-full absolute rounded-lg border border-1.5 border-zinc-200 pl-3 pr-[14px] py-2',
    props.isError && '!border-[#ff0000]',
    props.isError && 'hover:ring-[#cc0000]',
    'hover:ring-2 hover:ring-[#808080]',
    props.isError && 'focus:ring-[#ff0000] focus:border-[#ff0000]',
    'focus:ring-2 focus:ring-[#0000ff]',
    props.disabled && 'opacity-50 pointer-none border-zinc-200 hover:ring-0',
    props.className,
  );

  const onChange = (e: any) => {
    setCardType(getCardTypeFromValue(e.target.rawValue));
    inputProps.onChange(e);
  };

  useEffect(() => {
    setPadding({
      left: leadingWidth + 20,
      right: trailingWidth + 26,
    });
  }, [leadingWidth, trailingWidth]);
  return (
    <div className={classNames('w-[280px] h-[40px] rounded-lg relative shadow-[0_1px_2px_rgba(0,0,0,0.05)]')}>
      <Cleave
        {...props}
        {...inputProps}
        placeholder='Credit/Debit Card'
        options={{ creditCard: true }}
        style={{
          paddingLeft: padding.left,
          paddingRight: padding.right,
        }}
        className={inputClassName}
        onChange={onChange}
      />

      <span
        className={classNames('absolute flex items-center h-full left-[12px]', props.disabled && 'opacity-50')}
        ref={leadingRef}
      >
        {cardType === 'unkown' && <MdCreditCard className='w-5 h-5' />}
        {cardType === 'visa' && <SiVisa className='w-5 h-5' />}
        {cardType === 'mastercard' && <SiMastercard className='w-5 h-5' />}
        {cardType === 'jcb' && <SiJcb className='w-5 h-5' />}
        {cardType === 'discover' && <SiDiscover className='w-5 h-5' />}
        {cardType === 'americanexpress' && <SiAmericanexpress className='w-5 h-5' />}
      </span>
      <span className='absolute flex items-center h-full right-[14px]' ref={trailingRef}>
        <ClearInputTrailingAction value={inputProps.value} setValue={inputProps.onChange} disabled={props.disabled} />
      </span>
    </div>
  );
};
