import React, { useEffect } from 'react';
import * as ToastDemo from '@radix-ui/react-toast';
import { useLocalStorage } from 'usehooks-ts';
import { XIcon } from '../../assets/generated/svg';
import ToastConatinerStyle from '../../styles/Toast';
import { ToastProps } from './types';

export function Toast({
  open = false,
  title = 'title',
  desc = 'description',
  duration = 2000,
  toastButton,
  variant,
}: ToastProps) {
  // for toast we have a localStorage call toast
  // we have 3 variant (Success --> Green background, Error --> Red background, Warning --> Yellow background)
  // if you want to add another variant go to (../../styles/Toast) and add a new class --> ToastRoot.newVariant
  const [toast, setToast] = useLocalStorage('toast', {
    open,
    title,
    desc,
    duration,
    variant,
  });

  useEffect(() => {
    setToast({
      open,
      title,
      desc,
      duration,
      variant,
    });
  }, [open, title, desc, duration, variant]);

  return (
    <ToastConatinerStyle>
      <ToastDemo.Provider duration={toast.duration} swipeDirection='right'>
        {toastButton}
        <ToastDemo.Root
          data-testid='toast-container'
          className={`ToastRoot ${toast.variant}`}
          open={toast.open}
          onOpenChange={() => {
            setToast({ ...toast, open: false });
          }}
        >
          <ToastDemo.Title data-testid='toast-title' className='ToastTitle !text-xl capitalize'>
            {toast.title}
          </ToastDemo.Title>
          <ToastDemo.Description asChild>
            <time data-testid='toast-message' className='ToastDescription'>
              {toast.desc}
            </time>
          </ToastDemo.Description>
          <ToastDemo.Action className='ToastAction' asChild altText='Close'>
            <button className='Button small green cursor-pointer hover:opacity-80'>
              <XIcon />
            </button>
          </ToastDemo.Action>
        </ToastDemo.Root>
        <ToastDemo.Viewport className='ToastViewport' />
      </ToastDemo.Provider>
    </ToastConatinerStyle>
  );
}

export default Toast;
