import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';
import { TRANSACTION_CLASSIFICATIONS } from '../../constants';

// this maps to transaction.classification, but our users read it as category

const convertToRowFn: ConvertToRowFn<string> = (directionString) => ({
  rowData: { value: directionString.toLowerCase(), label: directionString },
  isSelected: false,
});

const filterRowsFn: FilterFn<string> = (searchTerm) => (directionString) =>
  directionString.toLowerCase().includes(searchTerm.toLowerCase());

export const useTransactionClassificationConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Transaction conditions',
      data: TRANSACTION_CLASSIFICATIONS,
      filterFn: filterRowsFn,
      convertToRowFn,
      Row: TransactionClassificationConditionSelectorRow,
    }),
    [],
  );

export const TransactionClassificationConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      isTransactionClassification
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.TRANSACTION_CLASSIFICATION,
          operator: 'equal',
          value: conditionData.rowData.value,
        })
      }
    />
  );
};

export const TransactionClassificationConditionsMenu = () => {
  const conditionsData = useFilteredConditions(TRANSACTION_CLASSIFICATIONS, filterRowsFn, convertToRowFn);
  return (
    <ConditionsGroup label='Transaction Categories'>
      {conditionsData.length === 0 && <div className='text-center'>No transaction categories to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => (
            <TransactionClassificationConditionSelectorRow key={index} conditionData={data} />
          )}
        />
      )}
    </ConditionsGroup>
  );
};
