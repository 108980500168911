
import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';

export interface AssetQuantity {
  assetType: string;
  quantity: number;
}

export interface WalletAssets {
  walletId: string;
  walletName: string;
  assets: AssetQuantity[];
}

export interface AssetGroupedData {
  assetType: string;
  wallets: { walletName: string; quantity: number }[];
}

// Update the getWalletAssetQuantities function
export const getWalletAssetQuantities = async (data: {
  sourceIds?: string[];
  legalEntityIds?: string[];
  chains?: string[];
  assetTypes?: string[];
  startDate?: string;
  endDate?: Date | string;
  groupByAsset?: boolean;
}) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });
  return axios.get<{ data: WalletAssets[] | AssetGroupedData[] }>(
    `${SERVER_URL_ANALYTICS}/get-wallet-asset-quantities?${queryParams}`
  );
};