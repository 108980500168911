import { useCallback, useEffect, useState } from 'react';
import { JOB_NAME } from 'services';
import { TaskManagerContext } from './context';
import { AnimatePresence, motion } from 'framer-motion';
import { HiChevronDown } from 'react-icons/hi2';
import { MdClose } from 'react-icons/md';
import { TrackJobInTaskManager } from '../../components/job-progress';
import { Subject } from 'rxjs';
import { useRouter } from 'next/router';
export type TaskType = { referenceId: string; jobName: JOB_NAME; uniqueId?: string };
export const TaskTrackingRequest = new Subject<TaskType>();
export const shouldFilterByUniqueId = (jobName: JOB_NAME) => {
  return (
    jobName === JOB_NAME.EVENT_DRIVEN_ASSET_RECONSTRUCTION_JOB ||
    jobName === JOB_NAME.EVENT_DRIVEN_JOURNAL_RECONSTRUCTION_JOB
  );
};

export const TaskManagerProvider = ({ children }) => {
  const [visibleTasks, setVisibleTasks] = useState<TaskType[]>([]);
  const [showTaskRows, setShowTaskRows] = useState(false);
  const [closedTasks, setClosedTasks] = useState<TaskType[]>([]);

  const passJobToTaskManager = useCallback((referenceId: string, jobName: JOB_NAME, uniqueId?: string) => {
    setVisibleTasks((prev) =>
      prev.find(
        (task) =>
          task.jobName === jobName &&
          task.referenceId === referenceId &&
          (shouldFilterByUniqueId(jobName) ? task.uniqueId === uniqueId : true),
      )
        ? prev
        : [...prev, { referenceId, jobName, uniqueId }],
    );
  }, []);
  const handleWebsocketJobTrackingRequest = useCallback(
    ({ referenceId, jobName, uniqueId }: TaskType) => {
      if (
        closedTasks.find(
          (task) =>
            task.referenceId === referenceId &&
            task.jobName === jobName &&
            (shouldFilterByUniqueId(jobName) ? task.uniqueId === uniqueId : true),
        )
      )
        return;
      if (
        visibleTasks.find(
          (task) =>
            task.referenceId === referenceId &&
            task.jobName === jobName &&
            (shouldFilterByUniqueId(jobName) ? task.uniqueId === uniqueId : true),
        )
      )
        return;
      passJobToTaskManager(referenceId, jobName, uniqueId);
    },
    [closedTasks, visibleTasks, passJobToTaskManager],
  );

  const hideAllJobsFromTaskManager = useCallback(() => {
    setClosedTasks((prev) => [...prev, ...visibleTasks]);
    setVisibleTasks([]);
  }, [visibleTasks]);

  const hideJobFromTaskManager = useCallback((referenceId: string, jobName: JOB_NAME, uniqueId?: string) => {
    setVisibleTasks((prev) =>
      prev.filter(
        (task) =>
          task.jobName !== jobName &&
          task.referenceId !== referenceId &&
          (shouldFilterByUniqueId(jobName) ? task.uniqueId === uniqueId : true),
      ),
    );
    setClosedTasks((prev) => [...prev, { referenceId, jobName, uniqueId }]);
  }, []);

  useEffect(() => {
    const subscription = TaskTrackingRequest.subscribe(handleWebsocketJobTrackingRequest);
    return () => subscription.unsubscribe();
  }, [handleWebsocketJobTrackingRequest]);

  const router = useRouter();

  return (
    <TaskManagerContext.Provider
      value={{
        passJobToTaskManager,
      }}
    >
      {children}
      <AnimatePresence>
        {!router.pathname.startsWith('/onboarding/new') && visibleTasks.length > 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='fixed z-30 bottom-0 right-0 mx-5 my-20'
          >
            <div
              className='bg-zinc-800 text-white rounded-lg font-medium overflow-hidden'
              style={{ width: 296 }}
              data-cy={`taskManager_container`}
            >
              <div className='flex items-center p-4 pr-5'>
                <span className='flex-grow mr-2' data-cy={`taskManager_status`}>
                  {visibleTasks.length} {visibleTasks.length === 1 ? 'task is running' : 'tasks are running'}
                </span>
                <button
                  className='mr-3.5 hover:text-gray-500'
                  onClick={() => setShowTaskRows((prev) => !prev)}
                  data-cy={`taskManager_chevronButton`}
                >
                  <HiChevronDown
                    className={`transition duration-500 ease-in-out w-5 h-5 ${showTaskRows ? '-rotate-180' : ''}`}
                  />
                </button>
                <button
                  onClick={hideAllJobsFromTaskManager}
                  className='hover:text-gray-500'
                  data-cy={`taskManager_closeButton`}
                >
                  <MdClose className='w-5 h-5' />
                </button>
              </div>

              <div className={`transition-all duration-500 max-h-32 overflow-hidden ${showTaskRows ? '' : 'max-h-0 '}`}>
                <div
                  className={`max-h-32 overflow-auto divide-y divide-gray-700 border-t border-gray-700 bg-black ${showTaskRows ? '' : 'hidden'}`}
                >
                  {visibleTasks.map((jobConfig, i) => (
                    <TrackJobInTaskManager
                      referenceId={jobConfig.referenceId}
                      jobName={jobConfig.jobName}
                      key={i}
                      onClose={() => hideJobFromTaskManager(jobConfig.referenceId, jobConfig.jobName)}
                      uniqueId={jobConfig.uniqueId}
                    />
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </TaskManagerContext.Provider>
  );
};
