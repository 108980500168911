import React from 'react';
import { components } from 'react-select';
import { Button } from 'ui';
import { XIcon } from '../../../../assets/generated/svg';
import { CustomMenuList } from './types';
export const MenuList = ({ isLoading, ...props }: CustomMenuList) => {
  if (isLoading) return <>{props.children}</>;
  if (!props.options?.length) return <div className='flex justify-center items-center py-2 px-3'>{props.children}</div>;
  return (
    <components.MenuList {...props}>
      {!!props?.menuLabel?.length && (
        <div className='flex min-w-[248px] items-center justify-between mb-4 p-4 border-b'>
          <div className='text-xl font-medium'>{props.menuLabel}</div>
          <Button onClick={() => props.selectProps.onMenuClose()} leadingIcon={<XIcon />} className='!border-none' />
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};

export default MenuList;
