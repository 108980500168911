import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_JOB_QUEUE } from '../../config';
import { prepareSearchParams } from './utils';
import { HedgeySource } from 'schemas';

export type GetHedgeySourceParams = { legalEntityIds?: string[]; pageParam?: any };
export type VestedBalanceType = {
  tokensVested: string;
  price: string;
  transactionSequences: string[];
  journalEntryData: any[];
};

export type VestedBalancesByAssetType = {
  [key: string]: VestedBalanceType;
};
export const getHedgeySource = (params: GetHedgeySourceParams = {}) => {
  return axios.get<{ hedgeySources: HedgeySource[] }>(
    `${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source?${prepareSearchParams(params, { noExtras: true })}`,
  );
};

export const createHedgeySource = (data) => {
  return axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, data);
};

export const updateHedgeySource = (data) => {
  return axios.patch(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, data);
};

export const deleteHedgeySource = (data) => {
  return axios.delete(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-source`, {
    data,
  });
};

export const createHedgeyJob = (body) => {
  return axios.post(`${SERVER_URL_JOB_QUEUE}/hedgey-producer`, body);
};

export const getHedgeyJobHistories = (body) => {
  return axios.get(`${SERVER_URL_CONFIGURATION_SERVICE}/hedgey-job-histories`, body);
};

export const getHedgeyVestedBalances = (params: { accountingPeriodId?: string }) => {
  return axios.get<{ vestedBalancesByAssetType: VestedBalancesByAssetType }>(
    `${SERVER_URL_JOB_QUEUE}/hedgey-vested-balances?${prepareSearchParams(params, { noExtras: true })}`,
  );
};

export const createEntriesForHedgey = (body) => {
  return axios.post(`${SERVER_URL_JOB_QUEUE}/hedgey-vested-balances`, body);
};
