import * as React from 'react';
import { forwardRef } from 'react';
const SvgCheck = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      opacity={0.8}
      d='m.413 8.031 1.94-1.978 5.03 4.961L18.034.404 20 2.385 7.384 14.952l-6.97-6.92Z'
      fill='#fff'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
