import React from 'react';
import { Button } from '../button';
import { MdAdd, MdCheckCircleOutline } from 'react-icons/md';
import { IntegrationRowProps } from './types';
import { LoaderIcon } from '../loader-icon';
import { StatusIndicator } from '../status-indicator';

export const IntegrationRow = ({
  icon,
  heading,
  description,
  isLive,
  isConnectDisabled,
  isConnectLoading,
  onConnect = console.log,
  onViewDetails = console.log,
}: IntegrationRowProps) => {
  return (
    <div className='border rounded-lg bg-white leading-5 flex items-center justify-between px-5'>
      <div className='flex-grow mr-[120px]'>
        <div className='flex items-center my-4'>
          <span className='mr-2'>{icon}</span>
          <span className='text-[20px] font-medium mr-2'>{heading}</span>
          {isLive && (
            <span>
              <StatusIndicator
                label='Connected'
                leadingIcon={<MdCheckCircleOutline className='w-5 h-5' />}
                type='positive'
              />
            </span>
          )}
        </div>
        <p className='mb-8'>{description}</p>
      </div>
      <span className='shrink-0'>
        {isConnectLoading && (
          <Button
            disabled
            onClick={console.log}
            emphasis='medium'
            leadingIcon={<LoaderIcon />}
            variant='md'
            label='Syncing'
          />
        )}
        {!isConnectLoading && !isLive && (
          <Button
            disabled={isConnectDisabled}
            onClick={onConnect}
            leadingIcon={<MdAdd className='w-5 h-5' />}
            variant='md'
            emphasis='medium'
            label='Connect'
          />
        )}

        {!isConnectLoading && isLive && (
          <Button
            disabled={isConnectDisabled}
            onClick={onViewDetails}
            variant='md'
            emphasis='medium'
            label='View details'
          />
        )}
      </span>
    </div>
  );
};
