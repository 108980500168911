import React from 'react';
import SidebarHeader from '../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { useGetLegalEntityById } from '../../../hooks/http';
import { SidebarSection, SidebarSectionHeader } from '../../atoms/Sidebar/SidebarBody';
import { SidebarBody } from '../../atoms/Sidebar';
import { DetailsItem } from '../../atoms';
import { getHost } from '../../../lib/utils';
import { currencyOptions } from 'ui';
import { dateConverter } from '../../utils';

const ViewLegalEntitySidebarContent = ({ legalEntityId, onPatch }) => {
  const { data: legalEntity, isLoading: isLoadingLegalEntity } = useGetLegalEntityById(legalEntityId);
  const dataCy = 'viewLegalEntity';
  return (
    <>
      <SidebarHeader
        data-cy={dataCy}
        title={legalEntity?.entityName ?? 'Legal entity'}
        description={
          'Updated at: ' + dateConverter(legalEntity?.updatedAt ?? '') ??
          'Created at: ' + dateConverter(legalEntity?.createdAt ?? '')
        }
        link={`${getHost()}/configure/entities/${legalEntity?._id}`}
        actions={[
          {
            label: 'Edit',
            onClick: onPatch,
            variant: 'primary',
            'data-cy': `editButton`,
          },
        ]}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={2} loading={isLoadingLegalEntity}>
          {legalEntity && (
            <>
              <DetailsItem tag='Business name' value={legalEntity.entityName} />
              <DetailsItem tag='Business type' value={legalEntity.entityType as string} />
              <DetailsItem
                tag='Currency'
                value={legalEntity?.currency}
                leadingComponent={currencyOptions.find((option) => option.value === legalEntity.currency)?.icon}
              />
              <DetailsItem tag='Business address' value={legalEntity.addressString as string} />
            </>
          )}
        </SidebarSection>
      </SidebarBody>
    </>
  );
};

export default ViewLegalEntitySidebarContent;
