import { GPTServiceIntentEnum, JOB_NAME, transformGptInstruction } from 'services';
import { invalidateQueriesBasedOnIntent } from '../../components/CommandPallete/utils';
import { useCommandPallete } from '../../components/CommandPallete/useCommandPallete';
import { useGPT } from '../http/useGPT';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { useState } from 'react';
import { useTaskManager } from '../../context';
import { Wallet } from 'schemas';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast'
export type useAskAIType = {
  callback?: () => void;
};

export const useAskAI = ({ callback = undefined }: useAskAIType) => {
  const {
    setGptChatResponses,
    selectedIntent,
    setSelectedIntent,
    setIsLoading,
    threadId,
    setThreadId,
    conversationId,
    setConversationId,
    instruction,
    setInstruction
  } = useCommandPallete();
  const { mutateAsync: askGPT, isLoading } = useGPT();
  const invalidateQueries = useInvalidateQuery();
  const { organizationId, userId } = useSession();
  const [error, setError] = useState<string | undefined>(undefined);
  const { passJobToTaskManager } = useTaskManager();
  const queryClient = useQueryClient()
  const getKey = useOrgBasedQueryKey();


  const handleAskAI = async (forcedInstruction?: string) => {
    //console.log(`Called handleAskAI with instruction: ${instruction} or forcedInstruction: ${forcedInstruction}`)
    if (callback) {
      callback();
    }

    // Optimistically update to the new value
    try {
      setIsLoading(true);
      //console.log(`Instruction before transform: ${instruction}`)
      const { rawInstruction } = transformGptInstruction(instruction, selectedIntent);
      const selectedInstruction = forcedInstruction ?? rawInstruction;
      //console.log(`Instruction after transform: ${selectedInstruction}`)
      const payload = {
        organizationId,
        userId,
        instruction: selectedInstruction,
        selectedIntent,
        threadId,
        assistantConversationId: conversationId,
      };
      // console.log('payload', payload);
      const res = await askGPT(payload);
      if (res.status === 200) {
        invalidateQueriesBasedOnIntent(
          res.data.fullGptResponse.intent ?? GPTServiceIntentEnum.INVALID_INTENT,
          invalidateQueries,
        );
        setGptChatResponses((prev) => [...prev, { ...res.data.fullGptResponse, query: selectedInstruction }]);
        const { returnedDocument } = res.data;
        if (returnedDocument && (returnedDocument as Wallet).chain) {
          passJobToTaskManager(returnedDocument._id, JOB_NAME.IMPORT_TRANSACTION_JOB);
        }
      }

      setThreadId(res.data.fullGptResponse.assistantsResponse?.assistantConversation.activeThreadId);
      //const { assistantConversation } = res.data.fullGptResponse?.assistantsResponse || {}
      setConversationId(res.data.fullGptResponse.assistantsResponse?.assistantConversation._id);
      invalidateQueries.invalidateGetConversations();
      setInstruction('');


      const key = getKey(QUERY_KEY.GET_CONVERSATIONS, { organizationId });

      // await queryClient.setQueryData(key, (old: AssistantsConversation[] | undefined) => {
      //   const oldData = old || [];
      //   console.log(`Old data:`, oldData);
      //   if (assistantConversation) {
      //     const newConversations = [assistantConversation, ...oldData.filter(convo => convo._id !== assistantConversation._id)];
      //     console.log(`Optimistic update:`, newConversations);
      //     return newConversations;
      //   }
      //   return oldData;
      // });

      // Optionally invalidate to ensure fresh data
      await queryClient.invalidateQueries(key);
    } catch (err: any) {
      toast.error(err.message)
      console.log(err);
      if (err?.response?.status === 504) {
        setError('Request timed out. Please try again.');
      } else {
        setError(String(err));
      }
    } finally {
      setIsLoading(false);
    }
    setSelectedIntent(null);
  };

  return { handleAskAI, isLoading, instruction, setInstruction, error, setError };
};
