import styled from 'styled-components';
export const BadgeStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding-right: 1px;
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: 500;
  &.primary {
    background-color: black;
    color: white;
  }
  &.secondary {
    background-color: #e5e7eb;
    color: black;
  }
  &.error {
    background-color: #f30000;
    color: black;
  }
`;
