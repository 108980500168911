import { getCookie, setCookie } from 'cookies-next';
import { useLayoutEffect } from 'react';
import { attachInterceptor, SESSION_COOKIE } from 'services';
import { LogoutTopic } from 'services/http/axios-interceptor/on-request-error';

export const AxiosInterceptorAttachment = () => {
  useLayoutEffect(() => {
    const token = getCookie(SESSION_COOKIE.TOKEN);
    if (token) attachInterceptor();
    else {
      const sessionString = localStorage.getItem('session');
      if (!sessionString) {
        LogoutTopic.next(true);
        return;
      }
      try {
        const session = JSON.parse(sessionString);
        if (!session.token || !session.organizationId) {
          LogoutTopic.next(true);
          return;
        }
        setCookie(SESSION_COOKIE.TOKEN, session.token);
        setCookie(SESSION_COOKIE.ORGANIZATION_ID, session.organizationId);

        attachInterceptor();
      } catch (error) {
        LogoutTopic.next(true);
      }
    }
  }, []);

  return null;
};
