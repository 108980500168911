import { useMemo } from 'react';
import { useTabState } from '../../../hooks';
import { DatePickerSelectionInLocalStorage } from '../../../components/DatePickerComp.types';
import { FILTER_TYPE } from '../../../components/filters';
import { useLocalStorage } from 'usehooks-ts';
import { getLocalStorageKeyForTabState } from '../utils';
import { useDatesWithTzOffset } from './use-dates-with-tz-offset';

export const useSingleDateFilterState = (page: string) => {
  const { activeTab } = useTabState();
  const activeTabId = useMemo(() => activeTab.id, [activeTab]);

  const [state, setState] = useLocalStorage<DatePickerSelectionInLocalStorage[]>(
    getLocalStorageKeyForTabState(activeTabId, FILTER_TYPE.SINGLE_DATE, page),
    [],
  );

  const datesWithTzOffset = useDatesWithTzOffset(state);

  return { state, setState, datesWithTzOffset };
};
