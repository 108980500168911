import { DESCRIPTIVE_LOADER_STATES, DescriptiveLoader, ModalContent, classNames } from 'ui';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useConfirmSetupIntentFromAddCard, useInvalidateQuery } from '../../../../hooks';
import { useState } from 'react';
import { deriveError } from '../../../templates/utils';

enum MODAL_STEP {
  FORM,
  SUCCESS,
  ERROR,
  LOADING,
}

export const AddPaymentMethodModalContent = ({ stripeSetupIntentClientSecret, onCancel }) => {
  const [step, setStep] = useState(MODAL_STEP.FORM);
  const [errorMessage, setErrorMessage] = useState('');
  const confirmSetupIntent = useConfirmSetupIntentFromAddCard({
    clientSecret: stripeSetupIntentClientSecret,
  });

  const { invalidateStripeCards } = useInvalidateQuery();

  const onError = (error) => {
    setStep(MODAL_STEP.ERROR);
    setErrorMessage(deriveError(error));
  };
  const title = step === MODAL_STEP.FORM ? 'Add payment method' : undefined;

  // try to confirm the stripe setup intent for adding the payment method
  // invalidate the existing payment methods
  const onSubmit = async () => {
    setStep(MODAL_STEP.LOADING);
    try {
      await confirmSetupIntent();
      setStep(MODAL_STEP.SUCCESS);
      invalidateStripeCards();
    } catch (error) {
      onError(error);
    }
  };

  const onClose = () => {
    setStep(MODAL_STEP.FORM);
    onCancel();
  };

  return (
    <ModalContent
      title={title}
      backAction={step === MODAL_STEP.ERROR ? { onClick: () => setStep(MODAL_STEP.FORM) } : undefined}
      onClose={step !== MODAL_STEP.LOADING ? onClose : undefined}
      actions={
        step === MODAL_STEP.FORM
          ? [
              {
                label: 'Add payment method',
                onClick: () => onSubmit(),
              },
              {
                label: 'Cancel',
                emphasis: 'medium',
                onClick: onClose,
              },
            ]
          : []
      }
    >
      <div className='pt-8 pb-4'>
        <span className={classNames('inline-block w-[490px]', step !== MODAL_STEP.FORM && 'hidden')}>
          <PaymentElement />
        </span>
        {step === MODAL_STEP.LOADING && (
          <DescriptiveLoader inModal title='Processing' description='Adding payment method' />
        )}
        {step === MODAL_STEP.SUCCESS && (
          <DescriptiveLoader
            inModal
            status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
            title='Success'
            description='Payment method added'
          />
        )}
        {step === MODAL_STEP.ERROR && (
          <DescriptiveLoader
            inModal
            status={DESCRIPTIVE_LOADER_STATES.ERROR}
            title='Could not add payment method'
            description={errorMessage}
          />
        )}
      </div>
    </ModalContent>
  );
};
