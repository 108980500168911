import React, { useState, useMemo } from 'react';
import { Button } from '../../../@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../@/components/ui/popover';
import { ScrollArea } from '../../../@/components/ui/scroll-area';
import { Switch } from '../../../@/components/ui/switch';
import { Checkbox } from '../../../@/components/ui/checkbox';
import { Input } from '../../../@/components/ui/input';
import { Label } from '../../../@/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../@/components/ui/tooltip';
import { cn } from '../../../@/lib/utils';
import { X } from 'lucide-react'; // Import the X icon

interface FilterPopoverProps {
  label: string;
  title: string;
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
  className?: string; // Add this line
  renderSelectedItems: (options: string[], count: number) => JSX.Element;
}

export function FilterPopover({
  label,
  title,
  options,
  selectedOptions,
  setSelectedOptions,
  renderSelectedItems,
}: FilterPopoverProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleOnly = (option: string) => {
    setSelectedOptions([option]);
  };

  const filteredOptions = useMemo(() => {
    return options.filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={false}
          className={cn('justify-between', 'px-3', 'min-w-[150px]')}
        >
          <div
            className='grid items-center w-full gap-2'
            style={{
              gridTemplateColumns: selectedOptions.length > 0 ? '1fr auto' : '1fr',
            }}
          >
            <span className='truncate text-left'>
              {selectedOptions.length > 0 ? renderSelectedItems(selectedOptions, 1) : label}
            </span>
            {selectedOptions.length > 0 && (
              <div
                className='flex items-center justify-center'
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOptions([]);
                }}
              >
                <div className='p-1 rounded-full hover:bg-gray-200 transition-colors duration-200'>
                  <X className='h-4 w-4 shrink-0 opacity-50 hover:opacity-100' />
                </div>
              </div>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[280px] px-4' align='start'>
        <div className='space-y-4'>
          <h3 className='text-sm font-medium'>{title}</h3>
          <Input
            type='text'
            placeholder='Search...'
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
            className='h-8'
          />
          <div className='flex items-center justify-between'>
            <Label htmlFor='selectAll' className='text-sm font-medium'>
              Select All
            </Label>
            <Switch
              id='selectAll'
              checked={selectedOptions.length === options.length}
              onCheckedChange={(checked) => setSelectedOptions(checked ? options : [])}
            />
          </div>
        </div>
        <ScrollArea className='h-[200px] mt-4'>
          <div className='space-y-2'>
            {filteredOptions.map((option) => (
              <div
                key={option}
                className='grid gap-x-2 items-center'
                style={{
                  gridTemplateColumns: 'auto 1fr auto',
                }}
              >
                <Checkbox
                  id={option}
                  checked={selectedOptions.includes(option)}
                  onCheckedChange={(checked) =>
                    setSelectedOptions(
                      checked ? [...selectedOptions, option] : selectedOptions.filter((o) => o !== option),
                    )
                  }
                />
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Label htmlFor={option} className='text-sm leading-none cursor-pointer truncate flex-1 min-w-0'>
                        {option}
                      </Label>
                    </TooltipTrigger>
                    <TooltipContent side='top' align='start'>
                      <p className='font-normal'>{option}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <Button
                  variant='ghost'
                  size='sm'
                  onClick={() => handleOnly(option)}
                  className='h-6 px-2 text-xs whitespace-nowrap shrink-0'
                >
                  Only
                </Button>
              </div>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}
