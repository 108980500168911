import { Dispatch, SetStateAction, useEffect } from 'react';
import { FilterRowState } from '../../../components/filters';
import { useRouter } from 'next/router';

export const useQueryDataWithFilterState = (
  data,
  setState: Dispatch<SetStateAction<FilterRowState[]>>,
  mergeFn = (v) => v,
  prepareInitialOptions: (v) => FilterRowState[] = (data) => data.map((d) => ({ ...d, selected: false })),
  matcherFn: (v1, v2) => boolean = (v1, v2) => v1._id === v2._id,
) => {
  const router = useRouter();
  useEffect(() => {
    setState((prev) => {
      const mergedValues = mergeFn(data);
      if (mergedValues.length === 0) return prev;
      if (prev.length === 0) {
        const next = prepareInitialOptions(mergedValues);

        return next;
      } else {
        const previouslySelectedOptions = prev.filter((value) => value.selected);
        const next = mergedValues.map((value) => ({
          ...value,
          selected: !!previouslySelectedOptions.find((option) => matcherFn(option, value)),
        }));

        return next;
      }
    });
  }, [data, setState, router.pathname]);
};
