import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalContent, ModalTrigger } from 'ui';
import { createSetupIntentForNewCard } from 'services';
import { deriveError } from '../../../templates/utils';
import toast from 'react-hot-toast';
import { StripeProvider } from '../../../../context';
import { AddPaymentMethodModalContent } from './modal-content';

export const AddPaymentMethodModal = () => {
  const [open, setOpen] = useState(false);

  const [clientSecret, setClientSecret] = useState<string | undefined>();

  // Create a new setup intent whenever this modal opens, this is needed by the stripe's PaymentElement component wrapper (StripeProvider)
  useEffect(() => {
    if (open) {
      const createStripeSetupIntentForAddingNewCard = async () => {
        try {
          const response = await createSetupIntentForNewCard();
          setClientSecret(response.data.clientSecret);
        } catch (error) {
          console.log(error);
          toast.error(deriveError(error));
          setOpen(false);
        }
      };

      createStripeSetupIntentForAddingNewCard();
    }

    return () => setClientSecret(undefined);
  }, [open]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Add payment method' onClick={() => setOpen(true)} />
      </ModalTrigger>

      {clientSecret ? (
        <StripeProvider
          options={{
            layout: {
              type: 'accordion',
              defaultCollapsed: false,
              radios: true,
              spacedAccordionItems: false,
            },
            clientSecret,
          }}
        >
          <AddPaymentMethodModalContent stripeSetupIntentClientSecret={clientSecret} onCancel={() => setOpen(false)} />
        </StripeProvider>
      ) : (
        <ModalContent>
          <div className='flex items-center justify-center h-64' />
        </ModalContent>
      )}
    </Modal>
  );
};
