import React from 'react';
import { Card, Section, SectionHeader, StatusIndicator, capitalizeFirstLetter } from 'ui';
import { useGetStripeDetailsForOrgQuery, useSession } from '../../../hooks';
import { MetricGroup } from '../../atoms';
import Image from '../../image-with-fallback';
import { EditBusinessInfoModal } from '../modals';
import { formatStripeAddress, getStatusIndicatorTypeForSubscription } from '../utils';
import { format } from 'date-fns';
import { BillingMetricCard } from '../common';
import { formatInTimeZone } from 'date-fns-tz';
import { formatDate } from '../../templates/utils';
import Avatar from 'react-avatar';

export const BusinessInfoSection = () => {
  // TODO: Add a query for last updated timestamp
  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();
  const { selectedOrganization } = useSession();

  const timezone = selectedOrganization?.timezone ?? 'UTC';
  const address = formatStripeAddress(stripeDetails?.customer?.address);
  const contact = stripeDetails?.customer?.phone;
  const email = stripeDetails?.customer?.email;
  const orgCreatedAt =
    stripeDetails?.subscription?.created && format(new Date(stripeDetails?.subscription?.created * 1000), 'MMM yyyy');

  const lastUpdatedOn =
    selectedOrganization && stripeDetails
      ? `Last updated on ${formatDate(
          new Date(
            (stripeDetails.lastBillingInfoUpdateAt as unknown as string) ||
              (selectedOrganization.updatedAt as unknown as string),
          ),
        )}`
      : ' ';
  const memberName = stripeDetails?.customer?.name ?? '';
  const memberSince = orgCreatedAt ? `Member since ${orgCreatedAt}` : '';

  return (
    <Card>
      <Section heading='Business info' description={lastUpdatedOn}>
        <Card>
          <div className='flex items-center justify-between'>
            <SectionHeader
              loading={!stripeDetails}
              avatar={
                <>
                  {selectedOrganization?.logo ? (
                    <span className='mr-2'>
                      <Image
                        src={selectedOrganization.logo as string}
                        className='rounded-full'
                        width={72}
                        height={72}
                      />
                    </span>
                  ) : (
                    <Avatar
                      name={memberName.split(' ')[0]}
                      className='rounded-full bg-gray-600'
                      size='72px'
                      color='#71717A'
                    />
                  )}
                </>
              }
              heading={memberName}
              description={memberSince}
              variant='md'
              tag={
                stripeDetails?.subscription?.status && (
                  <StatusIndicator
                    label={capitalizeFirstLetter(stripeDetails.subscription.status)}
                    type={getStatusIndicatorTypeForSubscription(stripeDetails.subscription.status)}
                  />
                )
              }
            />
            {stripeDetails && <EditBusinessInfoModal />}
          </div>
        </Card>
        <MetricGroup className='gap-3 p-0 m-0 mt-3'>
          {contact && <BillingMetricCard label='Billing contact' metric={contact} loading={!stripeDetails} />}
          {email && <BillingMetricCard label='Billing email' metric={email} loading={!stripeDetails} />}
          <BillingMetricCard label='Billing address' metric={address} loading={!stripeDetails} />
          <BillingMetricCard
            label='Billing timezone'
            metric={formatInTimeZone(new Date(), timezone, 'OOO')}
            loading={!stripeDetails}
          />
        </MetricGroup>
      </Section>
    </Card>
  );
};
