import React, { useContext, useEffect } from 'react';
import { SidebarContext } from '../../../context/SidebarProvider';
import { SliderContext } from '../../../context/SliderProvider';
import useMeasure from 'react-use-measure';
import { useRouter } from 'next/router';
interface SidebarRootProps {
  children: React.ReactNode;
}

export function SidebarRoot({ children }: SidebarRootProps): JSX.Element {
  const [numberOfActiveSidebars, setNumberOfActiveSidebars] = React.useState(0);

  const { setSidebarWidth } = useContext(SliderContext);
  const [ref, { width: sidebarWidth }] = useMeasure();
  const { asPath } = useRouter();
  useEffect(() => {
    setSidebarWidth(sidebarWidth ? sidebarWidth : 0);
  }, [sidebarWidth, asPath]);
  return (
    <SidebarContext.Provider value={{ numberOfActiveSidebars, setNumberOfActiveSidebars }}>
      <div ref={ref} className='max-h-screen overflow-hidden right-0 top-0 flex justify-end h-screen w-full z-20'>
        <div className='flex overflow-clip'>{children}</div>
      </div>
    </SidebarContext.Provider>
  );
}

export default SidebarRoot;
