import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import { AssetDataType, FilterContextParams } from '../types';
import { useAssetTypes } from '../../../hooks';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

export type AssetTypeFilterContextType = FilterContextParams<AssetDataType>;

export const ASSETS = [
  { abbreviation: 'BTC', name: 'Bitcoin' },
  { abbreviation: 'USDC', name: 'USD Coin' },
  { abbreviation: 'DAI', name: 'Dai' },
  { abbreviation: 'TKX', name: 'Tokenize Xchange' },
  { abbreviation: 'LDO', name: 'Lido' },
  { abbreviation: 'ETH', name: 'Ethereum' },
  { abbreviation: 'SAFE', name: 'SafeCoin' },
  { abbreviation: 'AAVE', name: 'Aave' },
  { abbreviation: 'FEI', name: 'Fei' },
  { abbreviation: 'TRIBE', name: 'Tribe' },
  { abbreviation: 'POOL', name: 'PoolTogether' },
  { abbreviation: 'FWB', name: 'Friends With Benefits' },
  { abbreviation: 'SUSD', name: 'sUSD' },
  { abbreviation: 'ENS', name: 'Ethereum Name Service' },
  { abbreviation: 'WETH', name: 'Wrapped Ethereum' },
  { abbreviation: 'GRT', name: 'The Graph' },
  { abbreviation: 'COVAL', name: 'Circuits of Value' },
  { abbreviation: 'BUSD', name: 'Binance USD' },
  { abbreviation: 'USDT', name: 'Tether' },
  { abbreviation: 'RAD', name: 'Radicle' },
  { abbreviation: 'GTC', name: 'Gitcoin' },
  { abbreviation: 'GALA', name: 'Gala' },
  { abbreviation: 'AUSDC', name: 'Algorand USD Coin' },
  { abbreviation: 'APE', name: 'ApeCoin' },
  { abbreviation: 'SOL', name: 'Solana' },
  { abbreviation: 'DUST', name: 'DUST' },
  { abbreviation: 'ETH', name: 'Ethereum' },
  { abbreviation: 'MATIC', name: 'Polygon' },
  { abbreviation: 'ARB', name: 'Arbitrum' },
  { abbreviation: 'OPT', name: 'Optimism' },
  { abbreviation: 'BASE', name: 'Base Protocol' },
  { abbreviation: 'SOL', name: 'Solana' },
  { abbreviation: 'BSC', name: 'Binance Smart Chain' },
  { abbreviation: 'AVAX', name: 'Avalanche' },
  { abbreviation: 'FTM', name: 'Fantom' },
  { abbreviation: 'BTC', name: 'Bitcoin' },
  { abbreviation: 'NEAR', name: 'NEAR Protocol' },
  { abbreviation: 'LINEA', name: 'Linea' },
  { abbreviation: 'RONIN', name: 'Ronin' },
  { abbreviation: 'ZKSYNC', name: 'ZKSync' },
  { abbreviation: 'SCROLL', name: 'Scroll' },
  { abbreviation: 'ZORA', name: 'Zora' },
  { abbreviation: 'CELO', name: 'Celo' },
  { abbreviation: 'XLM', name: 'Stellar Lumens' },
  { abbreviation: 'XRP', name: 'Xrp' },
  { abbreviation: 'ADA', name: 'Cardano' },
  { abbreviation: "CRO", name: "Cronos" },
];
const defaultState = ASSETS.map((assetData) => ({
  abbreviation: assetData.abbreviation,
  name: assetData.name,
  selected: false,
}));

export const useAssetTypeFilterState = (page: string) => {
  const { state, setState } = useFilterState<AssetDataType>(FILTER_TYPE.ASSET_TYPE, page, defaultState);

  const { data: assetTypes, isLoading } = useAssetTypes({ spamOnly: page === 'markedAsSpam' });
  const router = useRouter();

  useEffect(() => {
    if (assetTypes)
      setState((prev) => {
        const selected = prev.filter((row) => row.selected);
        const next = assetTypes.map((type) => ({
          abbreviation: type,
          name: type,
          selected: !!selected.find((row) => row.abbreviation === type),
        }));
        return next;
      });
  }, [assetTypes, router.pathname]);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
