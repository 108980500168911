import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { useWalletTypeFilterContext } from '../../../../context';

export const WalletTypesFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useWalletTypeFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Types' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((wallet, i) => (
            <CheckboxListItem
              key={i}
              label={wallet.value}
              checked={wallet.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No types to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
