import * as React from 'react';
import { forwardRef } from 'react';
const SvgSvgCloseinline = (props, ref) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path fill='#323023' d='M1.774 11.265.612 10.102l9.499-9.5 1.162 1.163z' />
    <path fill='#323023' d='M.61 1.765 1.771.603l9.5 9.499-1.163 1.162z' />
  </svg>
);
const ForwardRef = forwardRef(SvgSvgCloseinline);
export default ForwardRef;
