import { ReactNode, useMemo } from 'react';
import {
  AccountingPeriodFilterContext,
  AccountingTreatmentFilterContext,
  AmountFilterContext,
  AssetTypeFilterContext,
  ChainFilterContext,
  ClassificationFilterContext,
  DateFilterContext,
  DirectionFilterContext,
  ImpairedFilterContext,
  JournalEntryTemplateFilterContext,
  JournalSyncFilterContext,
  LedgerAccountFilterContext,
  LegalEntityFilterContext,
  OriginatedByFilterContext,
  SingleDateFilterContext,
  SourceFilterContext,
  SpamTokenFilterContext,
  StatusFilterContext,
  TagFilterContext,
  TargetDateFilterContext,
  TypeFilterContext,
  WalletFilterContext,
  WalletTypeFilterContext,
} from './context';
import {
  PrepareInitialAccountingPeriodOptionsFn,
  useAccountingPeriodFilterState,
  useAccountingTreatmentFilterState,
  useAmountFilterState,
  useAssetTypeFilterState,
  useChainFilterState,
  useClassificationFilterState,
  useDateFilterState,
  useDirectionFilterState,
  useImpairedFilterState,
  useJournalSyncFilterState,
  useLedgerAccountFilterState,
  useLegalEntityFilterState,
  useOriginatedByFilterState,
  useSourceFilterState,
  useSpamTokenFilterState,
  useStatusFilterState,
  useTagFilterState,
  useTypeFilterState,
  useWalletFilterState,
  useWalletTypeFilterState,
} from './use-filter-states';
import { createSettersForFilter } from './utils';
import { useJournalEntryTemplateFilterState } from './use-filter-states/use-journal-entry-template-filter-state';
import { useTargetDateFilterState } from './use-filter-states/use-target-date-filter-state';

export const AccountingPeriodFilterProvider = ({
  children,
  prepareInitialOptions,
  page,
}: {
  children: ReactNode;
  prepareInitialOptions?: PrepareInitialAccountingPeriodOptionsFn;
  page: string;
}) => {
  const { state, setState, isLoading } = useAccountingPeriodFilterState(page, prepareInitialOptions);
  const setters = createSettersForFilter(setState);
  return (
    <AccountingPeriodFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </AccountingPeriodFilterContext.Provider>
  );
};
export const AccountingTreatmentFilterProvider = ({ children, page }) => {
  const { state, setState } = useAccountingTreatmentFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <AccountingTreatmentFilterContext.Provider value={{ state, setState, setters }}>
      {children}
    </AccountingTreatmentFilterContext.Provider>
  );
};

export const AssetTypeFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useAssetTypeFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <AssetTypeFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </AssetTypeFilterContext.Provider>
  );
};
export const ChainFilterProvider = ({ children, page }) => {
  const { state, setState } = useChainFilterState(page);
  const setters = createSettersForFilter(setState);
  return <ChainFilterContext.Provider value={{ state, setState, setters }}>{children}</ChainFilterContext.Provider>;
};

export const ClassificationFilterProvider = ({ children, page }) => {
  const { state, setState } = useClassificationFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <ClassificationFilterContext.Provider value={{ state, setState, setters }}>
      {children}
    </ClassificationFilterContext.Provider>
  );
};

export const DateFilterProvider = ({ children, page }) => {
  const { state, setState, datesWithTzOffset } = useDateFilterState(page);
  return (
    <DateFilterContext.Provider value={{ state, setState, datesWithTzOffset }}>{children}</DateFilterContext.Provider>
  );
};

export const TargetDateFilterProvider = ({ children, page }) => {
  const { state, setState, datesWithTzOffset } = useTargetDateFilterState(page);
  return (
    <TargetDateFilterContext.Provider value={{ state, setState, datesWithTzOffset }}>
      {children}
    </TargetDateFilterContext.Provider>
  );
};
export const SingleDateFilterProvider = ({ children, page }) => {
  const { state, setState, datesWithTzOffset } = useDateFilterState(page);
  return (
    <SingleDateFilterContext.Provider value={{ state, setState, datesWithTzOffset }}>
      {children}
    </SingleDateFilterContext.Provider>
  );
};
export const DirectionFilterProvider = ({ children, page }) => {
  const { state, setState } = useDirectionFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <DirectionFilterContext.Provider value={{ state, setState, setters }}>{children}</DirectionFilterContext.Provider>
  );
};
export const SourceFilterProvider = ({ children, page }) => {
  const { state, setState } = useSourceFilterState(page);
  const setters = createSettersForFilter(setState);
  return <SourceFilterContext.Provider value={{ state, setState, setters }}>{children}</SourceFilterContext.Provider>;
};
export const TypeFilterProvider = ({ children, page }) => {
  const { state, setState } = useTypeFilterState(page);
  const setters = createSettersForFilter(setState);
  return <TypeFilterContext.Provider value={{ state, setState, setters }}>{children}</TypeFilterContext.Provider>;
};

export const ImpairedFilterProvider = ({ children, page }) => {
  const { state, setState } = useImpairedFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <ImpairedFilterContext.Provider value={{ state, setState, setters }}>{children}</ImpairedFilterContext.Provider>
  );
};
export const JournalSyncFilterProvider = ({ children, page }) => {
  const { state, setState } = useJournalSyncFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <JournalSyncFilterContext.Provider value={{ state, setState, setters }}>
      {children}
    </JournalSyncFilterContext.Provider>
  );
};
export const LedgerAccountFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useLedgerAccountFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <LedgerAccountFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </LedgerAccountFilterContext.Provider>
  );
};

export const LegalEntityFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useLegalEntityFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <LegalEntityFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </LegalEntityFilterContext.Provider>
  );
};

export const JournalEntryTemplateFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useJournalEntryTemplateFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <JournalEntryTemplateFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </JournalEntryTemplateFilterContext.Provider>
  );
};

export const OriginatedByFilterProvider = ({ children, page }) => {
  const { state, setState } = useOriginatedByFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <OriginatedByFilterContext.Provider value={{ state, setState, setters }}>
      {children}
    </OriginatedByFilterContext.Provider>
  );
};
export const StatusFilterProvider = ({ children, page }) => {
  const { state, setState } = useStatusFilterState(page);
  const setters = createSettersForFilter(setState);
  return <StatusFilterContext.Provider value={{ state, setState, setters }}>{children}</StatusFilterContext.Provider>;
};
export const SpamTokenFilterProvider = ({ children, page }) => {
  const { state, setState } = useSpamTokenFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <SpamTokenFilterContext.Provider value={{ state, setState, setters }}>{children}</SpamTokenFilterContext.Provider>
  );
};
export const TagFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useTagFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <TagFilterContext.Provider value={{ state, setState, isLoading, setters }}>{children}</TagFilterContext.Provider>
  );
};
export const WalletFilterProvider = ({ children, page }) => {
  const { state, setState, isLoading } = useWalletFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <WalletFilterContext.Provider value={{ state, setState, isLoading, setters }}>
      {children}
    </WalletFilterContext.Provider>
  );
};

export const WalletTypeFilterProvider = ({ children, page }) => {
  const { state, setState } = useWalletTypeFilterState(page);
  const setters = createSettersForFilter(setState);
  return (
    <WalletTypeFilterContext.Provider value={{ state, setState, setters }}>{children}</WalletTypeFilterContext.Provider>
  );
};
export const AmountFilterProvider = ({ children, page }) => {
  const { state, setState } = useAmountFilterState(page);

  return <AmountFilterContext.Provider value={{ state, setState }}>{children}</AmountFilterContext.Provider>;
};

const getInitialStateFnForAccountingPeriodFilter = ({
  selectLast3AccountingPeriods,
  selectAllAccountingPeriods,
}: {
  selectLast3AccountingPeriods?: boolean;
  selectAllAccountingPeriods?: boolean;
}) => {
  if (selectLast3AccountingPeriods) {
    console.log('selecting latest 3 accounting periods');
    return ((accountingPeriodFilterRows) =>
      accountingPeriodFilterRows.map((row, i) => ({
        ...row,
        selected: i < 3,
      }))) as PrepareInitialAccountingPeriodOptionsFn;
  }
  if (selectAllAccountingPeriods) {
    console.log('selecting all accounting periods');
    return ((accountingPeriodFilterRows) =>
      accountingPeriodFilterRows.map((row) => ({ ...row, selected: true }))) as PrepareInitialAccountingPeriodOptionsFn;
  }
  console.log('Default function used for accounting periods');
  return ((accountingPeriodFilterRows) =>
    accountingPeriodFilterRows.map((row) => ({ ...row, selected: false }))) as PrepareInitialAccountingPeriodOptionsFn;
};

export const FilterProvider = ({
  children,
  selectLast3AccountingPeriods,
  selectAllAccountingPeriods,
  page,
}: {
  children?: ReactNode;
  selectLast3AccountingPeriods?: boolean;
  selectAllAccountingPeriods?: boolean;
  page: string;
}) => {
  const prepareInitialAccountingPeriodOptions = useMemo(
    () => getInitialStateFnForAccountingPeriodFilter({ selectLast3AccountingPeriods, selectAllAccountingPeriods }),
    [selectLast3AccountingPeriods, selectAllAccountingPeriods],
  );

  return (
    <LegalEntityFilterProvider page={page}>
      <SourceFilterProvider page={page}>
        <LedgerAccountFilterProvider page={page}>
          <AccountingPeriodFilterProvider page={page} prepareInitialOptions={prepareInitialAccountingPeriodOptions}>
            <JournalEntryTemplateFilterProvider page={page}>
              <TagFilterProvider page={page}>
                <OriginatedByFilterProvider page={page}>
                  <AccountingTreatmentFilterProvider page={page}>
                    <AssetTypeFilterProvider page={page}>
                      <ChainFilterProvider page={page}>
                        <ClassificationFilterProvider page={page}>
                          <DateFilterProvider page={page}>
                            <DirectionFilterProvider page={page}>
                              <TypeFilterProvider page={page}>
                                <ImpairedFilterProvider page={page}>
                                  <JournalSyncFilterProvider page={page}>
                                    <SingleDateFilterProvider page={page}>
                                      <SpamTokenFilterProvider page={page}>
                                        <StatusFilterProvider page={page}>
                                          <TargetDateFilterProvider page={page}>
                                            <AmountFilterProvider page={page}>
                                              <WalletTypeFilterProvider page={page}>
                                                <WalletFilterProvider page={page}>{children}</WalletFilterProvider>
                                              </WalletTypeFilterProvider>
                                            </AmountFilterProvider>
                                          </TargetDateFilterProvider>
                                        </StatusFilterProvider>
                                      </SpamTokenFilterProvider>
                                    </SingleDateFilterProvider>
                                  </JournalSyncFilterProvider>
                                </ImpairedFilterProvider>
                              </TypeFilterProvider>
                            </DirectionFilterProvider>
                          </DateFilterProvider>
                        </ClassificationFilterProvider>
                      </ChainFilterProvider>
                    </AssetTypeFilterProvider>
                  </AccountingTreatmentFilterProvider>
                </OriginatedByFilterProvider>
              </TagFilterProvider>
            </JournalEntryTemplateFilterProvider>
          </AccountingPeriodFilterProvider>
        </LedgerAccountFilterProvider>
      </SourceFilterProvider>
    </LegalEntityFilterProvider>
  );
};
