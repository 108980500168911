import React, { useEffect, useState } from 'react';
import { useAllLedgerAccounts } from '../../hooks/http';
import { getDisplayedLedgerAccounts } from '../templates/utils';
import { Checkbox, InputLabel, InputWithExtras, SimpleMenu } from 'ui';
import { LEDGER_ACCOUNT_TYPE, LedgerAccount } from 'services/http/response.types';

export interface CreateLedgerAccountFormState {
  ledgerAccountName: string;
  ledgerAccountType?: LEDGER_ACCOUNT_TYPE;
  isClearingAccount: boolean;
  ledgerAccountSequence: string;
  parentLedgerAccountId?: string;
}

export interface CreateLedgerAccountFormElementsProps {
  formState: CreateLedgerAccountFormState;
  setFormState: React.Dispatch<React.SetStateAction<CreateLedgerAccountFormState>>;
}

export const CreateLedgerAccountFormElements = ({ formState, setFormState }: CreateLedgerAccountFormElementsProps) => {
  const { ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useAllLedgerAccounts();
  const [parentsWithSameType, setParentsWithSameType] = useState<LedgerAccount[]>([]);

  useEffect(() => {
    if (ledgerAccounts && formState.ledgerAccountType)
      setParentsWithSameType(
        ledgerAccounts.filter((account) => account.ledgerAccountType === formState.ledgerAccountType),
      );
  }, [ledgerAccounts, formState]);

  return (
    <>
      <div>
        <InputLabel heading='Name' />
        <InputWithExtras
          placeholder='Enter name'
          onChange={({ target }) =>
            setFormState((prev) => ({
              ...prev,
              ledgerAccountName: target.value,
            }))
          }
          value={formState.ledgerAccountName}
          data-cy='createLedgerAccountFE_ledgerAccountNameInput'
        />
      </div>
      <div>
        <InputLabel heading='Account type' />
        <SimpleMenu
          options={Object.values(LEDGER_ACCOUNT_TYPE).map((type) => ({ label: type, value: type as string }))}
          selectedValue={formState.ledgerAccountType as string | undefined}
          onChange={({ value }) =>
            setFormState((prev) => ({
              ...prev,
              ledgerAccountType: value,
            }))
          }
          placeholder='Account type'
          data-cy='createLedgerAccountFE_ledgerAccountTypeMenu'
        />
      </div>
      <div>
        <InputLabel heading='Number' />
        <InputWithExtras
          placeholder='Enter number'
          onChange={({ target }) =>
            setFormState((prev) => ({
              ...prev,
              ledgerAccountSequence: target.value,
            }))
          }
          value={formState.ledgerAccountSequence}
          data-cy='createLedgerAccountFE_ledgerAccountSequenceInput'
        />
      </div>
      <div>
        <InputLabel heading='Parent account' />
        <SimpleMenu
          options={getDisplayedLedgerAccounts(parentsWithSameType)}
          onChange={({ value }) =>
            setFormState((prev) => ({
              ...prev,
              parentLedgerAccountId: value,
            }))
          }
          selectedValue={formState.parentLedgerAccountId}
          isLoading={isLoadingLedgerAccounts}
          placeholder={parentsWithSameType.length === 0 ? 'No accounts found' : 'Select...'}
          disabled={parentsWithSameType.length === 0}
          data-cy='createLedgerAccountFE_ledgerAccountParentMenu'
        />
      </div>
      <div>
        <Checkbox
          label='Clearing account'
          onChange={(isChecked) => setFormState((prev) => ({ ...prev, isClearingAccount: isChecked }))}
          isSelected={formState.isClearingAccount}
          data-cy='createLedgerAccountFE_isLedgerAccountAClearingAccount'
        />
      </div>
    </>
  );
};
