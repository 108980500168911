import { MESSAGE_TYPES, MessageProps } from './types';
import { MdCheckCircleOutline, MdInfoOutline, MdOutlineWarningAmber } from 'react-icons/md';
import { BaseMessage } from './base-message';
import { MessageAction } from './message-action';

export const Message = ({ type = MESSAGE_TYPES.INFO, text, action, onClose }: MessageProps) => {
  switch (type) {
    case MESSAGE_TYPES.INFO:
      return (
        <BaseMessage
          icon={<MdInfoOutline className='w-5 h-5' />}
          text={text}
          action={action ? <MessageAction {...action} /> : null}
          onClose={onClose}
          className='text-blue-700 border border-blue-700 bg-blue-50 rounded-lg font-medium'
        />
      );
    case MESSAGE_TYPES.SUCCESS:
      return (
        <BaseMessage
          icon={<MdCheckCircleOutline className='w-5 h-5' />}
          text={text}
          action={action ? <MessageAction {...action} /> : null}
          onClose={onClose}
          className='text-green-700 border border-green-700 bg-green-50 rounded-lg font-medium'
        />
      );
    case MESSAGE_TYPES.DANGER:
      return (
        <BaseMessage
          icon={<MdInfoOutline className='w-5 h-5' />}
          text={text}
          action={action ? <MessageAction {...action} /> : null}
          onClose={onClose}
          className='text-red-700 border border-red-700 bg-red-50 rounded-lg font-medium'
        />
      );
    case MESSAGE_TYPES.WARNING:
      return (
        <BaseMessage
          icon={<MdOutlineWarningAmber className='w-5 h-5' />}
          text={text}
          action={action ? <MessageAction {...action} /> : null}
          onClose={onClose}
          className='text-yellow-700 border border-yellow-700 bg-yellow-50 rounded-lg font-medium'
        />
      );
    default:
      return null;
  }
};
