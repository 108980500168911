import React from 'react';
import { DescriptiveLoader } from 'ui';
import { useLatestJobConfiguration } from './use-latest-job-configuration';
import {
  getLoaderBgStateFromJobConfig,
  getLoaderDescriptionFromBgJobState,
  getLoaderStatusFromBgJobState,
  getLoaderTitleFromBgJobState,
} from './utils';

export const TrackJobInSidebar = ({ jobName, referenceId, error }) => {
  const { jobConfiguration } = useLatestJobConfiguration(referenceId, jobName);

  const backgroundJobStatus = getLoaderBgStateFromJobConfig(jobConfiguration);
  const title = getLoaderTitleFromBgJobState(backgroundJobStatus);
  const description = getLoaderDescriptionFromBgJobState({
    bgJobState: backgroundJobStatus,
    jobName,
    jobConfig: jobConfiguration,
    error,
  });
  const status = getLoaderStatusFromBgJobState(backgroundJobStatus);

  return (
    <div className='h-[300px] w-full flex items-center justify-center'>
      <DescriptiveLoader title={title} description={description} status={status} />
    </div>
  );
};
