import axios from 'axios';
import { SERVER_URL_GL_INTEGRATION } from '../config';
import { ExternalLedgerAccount, ExternalLegalEntity, LedgerAccountMapping, LegalEntityMapping } from './response.types';
import { ExternalAccountPayload, GLType, GlAuthRedirectPayload, ExternalLegalEntitiesPayload } from './types';
import { prepareSearchParams } from './core/utils';
import { GLIntegrationConfig, JournalEntrySyncRecord } from 'schemas';

export const getQuickBooksRedirectUrl = async (data: GlAuthRedirectPayload) => {
  const searchParams = prepareSearchParams({
    subdomain: data.subdomain,
  });
  return axios.get(
    `${SERVER_URL_GL_INTEGRATION}/qb-connect?${searchParams}`,
  );
};


export const getExternalAccounts = async (data: ExternalAccountPayload) => {
  const searchParams = prepareSearchParams({
    integrationType: data.glType,
    realmId: data.realmId,
    organizationId: data.organizationId,
  }, { noExtras: true });
  return axios.get<{ externalLedgerAccounts: ExternalLedgerAccount[] }>(
    `${SERVER_URL_GL_INTEGRATION}/external-ledger-accounts?${searchParams}`,
  );
};

export const getExternalLegalEntities = async (data: ExternalLegalEntitiesPayload) => {
  const searchParams = prepareSearchParams({
    integrationType: data.glType,
    realmId: data.realmId,
    organizationId: data.organizationId,
  }, { noExtras: true });
  return axios.get<{ externalLegalEntities: ExternalLegalEntity[] }>(
    `${SERVER_URL_GL_INTEGRATION}/external-legal-entities?${searchParams}`,
  );
};


export const postLedgerAccountMappings = async (
  ledgerAccountMappings: LedgerAccountMapping[],
) => {
  const searchParams = prepareSearchParams({ }, { noExtras: true });
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/ledger-account-mappings?${searchParams}`,
    ledgerAccountMappings,
  );
};

export const postLegalEntityMappings = async (
  legalEntityMappings: LegalEntityMapping[],
) => {
  const searchParams = prepareSearchParams({ }, { noExtras: true });
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/legal-entity-mappings?${searchParams}`,
    legalEntityMappings,
  );
};

export const getAllIntegrationStatus = async () => {
  return axios.get<{
    glAuths: {
      updatedAt: string;
      createdAt: string;
      glType: GLType;
      organizationId: string;
      _id: string;
      realmId: string;
      legalEntityId?: string;
    }[];
  }>(`${SERVER_URL_GL_INTEGRATION}/get-gl-auths`);
};

export const getLedgerAccountMappings = async (glType: GLType, realmId: string) => {

  const searchParams = prepareSearchParams({
    integrationType: glType,
    realmId,
  });

  return axios.get<{ ledgerAccountMappings: LedgerAccountMapping[] }>(
    `${SERVER_URL_GL_INTEGRATION}/ledger-account-mappings?${searchParams}`,
  );
};

export const getLegalEntitiesMappings = async (glType: GLType, realmId: string) => {
  const searchParams = prepareSearchParams({
    integrationType: glType,
    realmId,
  });

  return axios.get<{ legalEntityMappings: LegalEntityMapping[] }>(
    `${SERVER_URL_GL_INTEGRATION}/legal-entity-mappings?${searchParams}`,
  );
}

export const syncJournalEntries = async (accountingPeriodId, glType: GLType) => {
  const searchParams = prepareSearchParams({
    accountingPeriodId,
    integrationType: glType,
  });

  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/sync-journal-entry?${searchParams}`,
  );
};
export const createSyncJournalEntryJob = async (
  accountingPeriodId: string,
  userId: string,
  integrationType: GLType,
  realmId: string,
) => {
  const searchParams = prepareSearchParams({
    accountingPeriodId,
    userId,
    integrationType,
    realmId,
  });
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/sync-journal-entry-job?${searchParams}`,
  );
};

export const disconnectOAuth2Integration = async (organizationId: string, glType: GLType, realmId: string) => {
  const searchParams = prepareSearchParams({
    integrationType: glType,
    realmId,
  });
  return axios.post(
    `${SERVER_URL_GL_INTEGRATION}/oauth-revoke-token?${searchParams}`,
  );

};

export const deleteSyncedEntries = async (organizationId: string, journalEntryIds: string[]) =>
  axios.post(`${SERVER_URL_GL_INTEGRATION}/delete-synced-entries`, {
    organizationId,
    journalEntryIds,
  });

export const deleteSyncedEntriesForAccountingPeriod = async (organizationId: string, accountingPeriodId: string) =>
  axios.post(`${SERVER_URL_GL_INTEGRATION}/delete-synced-entries-for-accounting-period`, {
    organizationId,
    accountingPeriodId,
  });

// QB Sync History related API calls (these might be analogous to Sync Journal Entry related calls)
// entity: SyncHistoryOperationRecord

export interface CreateSyncHistoryOperationRecordApiCallParams {
  organizationId: string;
  accountingPeriodId: string;
  userId: string;
}

export interface SyncHistoryFromServer {
  _id: string;
  organizationId: string;
  accountingPeriodId: {
    _id: string;
    organizationId: string;
    startDate: string;
    endDate: string;
    closedOnDate: any;
    status: string;
    accountingPeriodName: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  createdBy: {
    _id: string;
    email: string;
    googleAuth: any;
    address: any;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GetAllSyncHistoriesResponse {
  syncHistories: SyncHistoryFromServer[];
  success: true;
}

export const getAllSyncHistoryOperationRecords = (filters) =>
  axios.get<GetAllSyncHistoriesResponse>(`${SERVER_URL_GL_INTEGRATION}/sync-history?${prepareSearchParams(filters)}`);

export interface GetAllEntriesForSyncHistoryResponse {
  entries: JournalEntrySyncRecord[];
  success: true;
}

export const getAllEntriesForSyncHistoryOperationRecord = (filters) =>
  axios.get<GetAllEntriesForSyncHistoryResponse>(
    `${SERVER_URL_GL_INTEGRATION}/sync-entries?${prepareSearchParams(filters, { isPaginated: true })}`,
  );

export const getGlIntegrationConfig = (organizationId: string, glType: GLType, realmId: string) => {
 const searchParams = prepareSearchParams({
    organizationId,
    integrationType: glType,
    realmId,
  });

  return axios.get<{ glIntegrationConfig: GLIntegrationConfig | null }>(
    `${SERVER_URL_GL_INTEGRATION}/gl-integration-config?${searchParams}`,
  );
};


export const getAllGlIntegrationConfigs = async (glType: GLType) => {
  const searchParams = prepareSearchParams({
    glType,
  });
  return axios.get<{ glIntegrations: (GLIntegrationConfig & { legalEntityId: { entityName: string } })[] }>(
    `${SERVER_URL_GL_INTEGRATION}/gl-integrations?${searchParams}`,
  );
}

export const updateGlIntegrationConfig = (
  organizationId: string,
  glType: GLType,
  shouldRolledUp: boolean,
  legalEntityId: string,
  realmId: string,
) =>
  axios.post<GLIntegrationConfig>(`${SERVER_URL_GL_INTEGRATION}/gl-integration-config`, {
    organizationId,
    glType,
    shouldRolledUp,
    legalEntityId,
    realmId,
  });

export const getXeroRedirectUrl = async (data: GlAuthRedirectPayload) => {
  const searchParams = prepareSearchParams({
    subdomain: data.subdomain,
    integrationType: GLType.XERO,
  });
  return axios.get(
    `${SERVER_URL_GL_INTEGRATION}/xero-client-connect?${searchParams}`,
  );
}

export const refreshExternalLedgerAccounts = (data: {
  integrationType: GLType;
  realmId: string;
  organizationId: string;
}) => axios.post(`${SERVER_URL_GL_INTEGRATION}/refresh-external-ledger-accounts`, data);

export const getNetsuiteRedirectUrl = async (data: GlAuthRedirectPayload) => {
  const searchParams = prepareSearchParams({
    subdomain: data.subdomain,
    integrationType: GLType.NETSUITE,
  });
  return axios.get(
    `${SERVER_URL_GL_INTEGRATION}/netsuite-connect?${searchParams}`,
  );
}