import { BarList, Metric, Text } from '@tremor/react';
import { TremorCard } from 'ui';
import { useGetBestPerformingRules } from '../../hooks';

export const BestPerformingRulesWidget = () => {
  const { data: ruleMetrics, isLoading } = useGetBestPerformingRules();

  const barListData =
    ruleMetrics
      ?.sort((a, b) => (a.matchCount > b.matchCount ? 1 : 0))
      .slice(0, 5)
      .map((rule) => ({
        name: rule.accountPostingRuleId.name,
        value: rule.matchCount,
      })) || [];

  const totalMatchesTop5Combined: number = ruleMetrics?.reduce((acc, curr) => acc + curr.matchCount, 0);

  return (
    <TremorCard>
      {isLoading ? (
        <div className='h-[300px] animate-pulse bg-gray-50' />
      ) : (
        <>
          <div className='mb-4'>
            <Text>Best performing rules</Text>
            {barListData.length > 0 && <Metric>{totalMatchesTop5Combined}</Metric>}
          </div>
          {barListData.length > 0 && (
            <p className='mt-4 mb-4 text-tremor-default flex items-center justify-between text-tremor-content dark:text-dark-tremor-content'>
              <span>Rule</span>
              <span>Matches</span>
            </p>
          )}
          <BarList data={barListData} onValueChange={() => null} />
        </>
      )}
    </TremorCard>
  );
};
