import React from 'react';
import { SkeletonRow } from '../skeleton-row';
import { classNames } from '../utils';
import { HedgeyIntegrationSkeletonsProps } from './types';

export const HedgeyWalletsSkeleton = ({ numOfColumns = 1 }: HedgeyIntegrationSkeletonsProps) => {
  return (
    <div className='border rounded-lg bg-white leading-5'>
      {[...Array(numOfColumns)].map((_, index) => {
        return (
          <div key={index} className={classNames('animate-pulse p-4 max-w-[300px]', index && 'border-t')}>
            <SkeletonRow />
            <SkeletonRow size='sm' />
          </div>
        );
      })}
    </div>
  );
};

export default HedgeyWalletsSkeleton;
