import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  DropdownTitleWithToggle,
  DropdownSearchInput,
  SelectableCard,
  classNames,
  getFilterDropdownSectionDataCy,
  capitalizeFirstLetter,
  DropdownSubHeader,
  currencyImg,
} from 'ui';
import { FILTER_TYPE } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { shortText } from '../../../rules/utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { useWalletFilterContext } from '../../../../context';
import { extraFiltersForWalletsFn } from './extraFiltersForWalletsFn';

const DEFAULT_TRIGGER_LABEL = 'Wallets';

const getCurrencyImg = (wallet) => {
  if (wallet?.chain.toLowerCase() === 'exchange') {
    //@ts-ignore
    return currencyImg(wallet?.exchangeSourceType.toLowerCase())?.toLowerCase();
  }
  if (wallet?.chain.toLowerCase() === 'raincard') {
    return '/raincards-logo.png';
  }
  return currencyImg(wallet.chain)?.toLowerCase();
};

export const WalletsFilterDropdown = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const {
    state,
    isLoading,
    setters: { onlySelect, selectAll, deselectAll, setSelectionByRowIndex },
  } = useWalletFilterContext();

  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);
  const [searched, setSearched] = useState('');
  const [extraFilters, setExtraFilters] = useState({
    isInternal: false,
    isExternal: false,
    isTagged: false,
  });
  useEffect(() => {
    setTriggerLabel(() => {
      const selectedValues = state.filter((value) => value.selected);
      if (selectedValues.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedValues.length === 1) return `${shortText(selectedValues[0].address)}`;
      return `${shortText(selectedValues[0].address)} +${selectedValues.length - 1}`;
    });
  }, [state]);
  const walletsFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.WALLET}`, [dataCy]);

  const filteredBySearchBar = state.map((option) => {
    if (searched.length === 0) return option;
    if (option.selected) return option;

    if (option.address?.toLowerCase().includes(searched.toLowerCase())) return option;
    if (option.walletType?.toLowerCase().includes(searched.toLowerCase())) return option;
    if (option.chain?.toLowerCase().includes(searched.toLowerCase())) return option;
    if (option.name?.toLowerCase().includes(searched.toLowerCase())) return option;

    return null;
  });

  const results = extraFiltersForWalletsFn(filteredBySearchBar, extraFilters);

  return (
    <Dropdown data-cy={walletsFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).trigger}
            isFocused={open}
            isLoading={isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      deselectAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Wallets' data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).header} />
        <DropdownBody data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).body}>
          <DropdownSubHeader>
            <div className='' data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).subHeader}>
              <div className=' mb-2'>
                <DropdownSearchInput
                  value={searched}
                  onChange={(e) => setSearched(e.target.value)}
                  data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).searchInput}
                />
              </div>
              <div className='grid grid-cols-3 gap-2'>
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='Internal'
                  selected={extraFilters.isInternal}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isInternal: !prev.isInternal }))}
                />
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='External'
                  selected={extraFilters.isExternal}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isExternal: !prev.isExternal }))}
                />
                <SelectableCard
                  className='px-2 py-0 h-10'
                  label='Tagged'
                  selected={extraFilters.isTagged}
                  onClick={() => setExtraFilters((prev) => ({ ...prev, isTagged: !prev.isTagged }))}
                />
              </div>
              {results.length !== 0 && (
                <DropdownTitleWithToggle
                  className='px-0 pl-2'
                  title='Select all'
                  checked={state.length === state.filter((value) => value.selected).length}
                  onChange={(checked: boolean) => (checked ? selectAll() : deselectAll())}
                />
              )}
            </div>
          </DropdownSubHeader>

          <div>
            {results.length === 0 && (
              <div className='flex items-center justify-center'>
                {results.length === 0 && <div className='p-4 text-center'>No wallets to show</div>}
              </div>
            )}
            {results.length !== 0 && (
              <DropdownGroupBody>
                {results.map((option, i) =>
                  option ? (
                    <CheckboxListItem
                      key={i}
                      label={option.name ?? '' + ' ' + shortText(option.address)}
                      bottomText={option.walletType}
                      enableAvatar
                      src={getCurrencyImg(option)?.toLowerCase()}
                      checked={option.selected}
                      onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
                      selectOnlyFn={() => onlySelect(i)}
                    />
                  ) : null,
                )}
              </DropdownGroupBody>
            )}
          </div>
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(walletsFilterDataCy).clearAllButton}
            onClick={results.length !== 0 ? deselectAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
