import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS, RAINCARD_DEBIT_CREDIT_OPERATORS, RaincardsPaths } from '../../data';
import { useGetRaincardSources, useRainRuleMeta } from '../../../../../hooks/http/useRaincards';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { useMemo } from 'react';
import { SingleSelectMenu } from 'ui';

const PathedDropdown = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  return (
    <SingleSelectMenu
      options={RaincardsPaths}
      value={RaincardsPaths.find((path) => path.value === condition.path)}
      onChange={({ value }) => {
        // console.log({ value });
        updateCondition(condition.id, {
          path: value,
        });
      }}
      isOnSidepanel
      align='end'
      disabled={isDisabled}
    />
  );
};

const PathedOperators = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <SingleSelectMenu
      options={EQUAL_NOTEQUAL_OPERATORS}
      value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
      onChange={({ value }) => {
        updateCondition(condition.id, {
          operator: value,
        });
      }}
      isOnSidepanel
      align='end'
      disabled={isDisabled}
    />
  );
};

const SourceOperators = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <SingleSelectMenu
      options={RAINCARD_DEBIT_CREDIT_OPERATORS}
      value={RAINCARD_DEBIT_CREDIT_OPERATORS.find((item) => item.value === condition.operator)}
      onChange={({ value }) => {
        updateCondition(condition.id, {
          operator: value,
        });
      }}
      isOnSidepanel
      align='end'
      disabled={isDisabled}
    />
  );
};

const SourceDropdown = () => {
  const { data: raincards = [], isLoading } = useGetRaincardSources();
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  const cardOptions = raincards.map((card) => ({ label: card.cardData.nickname, value: card._id }));
  const selectedOption = cardOptions.find((option) => option.value === condition.value);
  return (
    <SingleSelectMenu
      options={cardOptions}
      value={selectedOption}
      onChange={({ value }) => {
        // console.log({ value });
        updateCondition(condition.id, {
          value: value,
        });
      }}
      isLoading={isLoading}
      isOnSidepanel
      align='end'
      disabled={isDisabled}
    />
  );
};

const Source = () => (
  <>
    <SourceDropdown />
    <SourceOperators />
  </>
);

const Pathed = () => (
  <>
    <PathedDropdown />
    <PathedOperators />
  </>
);

const Row = () => {
  const { data: rainMeta, isLoading: rainRuleMetaIsLoading }: any = useRainRuleMeta();
  const merchantOptions = useMemo(
    () =>
      rainMeta?.uniqueMerchantNames?.map((item) => {
        const merchant = item.replace(/_/g, ' ').toUpperCase();
        return { label: merchant, value: merchant };
      }) || [],
    [rainMeta],
  );
  const categoryOptions = useMemo(
    () =>
      rainMeta?.uniqueCategoryNames?.map((item) => {
        const category = item.replace(/_/g, ' ').toUpperCase();
        return { label: category, value: category };
      }) || [],
    [rainMeta],
  );
  const typeOptions = useMemo(
    () =>
      rainMeta?.uniqueTypes?.map((item) => {
        const type = item.replace(/_/g, ' ').toUpperCase();
        return { label: type, value: type };
      }) || [],
    [rainMeta],
  );

  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();

  const dropdownOptions = useMemo(() => {
    if (condition.path === '$.merchantName') return merchantOptions;
    else if (condition.path === '$.category') return categoryOptions;
    else if (condition.path === '$.type') return typeOptions;
  }, [merchantOptions, categoryOptions, typeOptions, condition]);

  const isSourceCondition = condition.operator === 'raincardIsCredited' || condition.operator === 'raincardIsDebited';
  if (isSourceCondition) return <Source />;
  return (
    <>
      <Pathed />
      <SingleSelectMenu
        options={dropdownOptions}
        onChange={(option) => {
          updateCondition(condition.id, {
            value: option.value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        placeholder='Select a value'
        isLoading={rainRuleMetaIsLoading}
        value={dropdownOptions.find((option) => option.value === condition.value)}
      />
    </>
  );
};

export const RaincardsRuleCondition = () => (
  <ConditionRow facts={[FACT.RAINCARDS]}>
    <Row />
  </ConditionRow>
);
