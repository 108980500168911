import React, { useState } from 'react';
import ConditionItem from '../common/ConditionItem';
import { SUPPORTED_ERC20_TOKEN } from '../../../../../../constants';
import { ConditionMenusProps } from '../../../types';
import { useAssetTypes } from '../../../../../../hooks';
import { AssetConditionBody } from './AssetConditionBody';

export const AssetCondition = ({
  ruleSetData,
  getRuleSetData,
  isFirstLevel,
  callbackFn,
  isInLineCondition,
  closeDialog,
}: ConditionMenusProps) => {
  const [filterERC, setFilterERC] = useState(SUPPORTED_ERC20_TOKEN);
  const filterAssets = (arr, searchValue) => {
    return arr.filter((el) => el.toLowerCase().includes(searchValue.toLowerCase()));
  };
  const { data: assetTypes = [], isLoading: assetsTypeLoading } = useAssetTypes();

  if (isInLineCondition) {
    return (
      <AssetConditionBody
        {...{
          filterAssets,
          filterERC,
          setFilterERC,
          ruleSetData,
          getRuleSetData,
          isFirstLevel,
          callbackFn,
          isInLineCondition,
          closeDialog,
          assetTypes,
          assetsTypeLoading,
        }}
      />
    );
  }

  return (
    <ConditionItem isInLineCondition={isInLineCondition} label='Asset'>
      <AssetConditionBody
        {...{
          filterAssets,
          filterERC,
          setFilterERC,
          ruleSetData,
          getRuleSetData,
          isFirstLevel,
          callbackFn,
          isInLineCondition,
          closeDialog,
          assetTypes,
          assetsTypeLoading,
        }}
      />
    </ConditionItem>
  );
};

export default AssetCondition;
