import {
  AccountingPeriod,
  CREDIT_OR_DEBIT,
  JournalEntry,
  JournalEntryLine,
  JournalEntryTemplate,
  LedgerAccount,
  LegalEntity,
  Tag,
  Transaction,
} from 'services/http/response.types';
import { getJournalEntries } from 'services';
import { formatDollars } from '../../components/templates/utils';
import { capitalizeFirstLetter } from 'ui';
import { BalanceDrilldownRowData } from '../../components/Sidebar/Routes/balance-overview-sidebar';

enum JE_CSV_HEADER {
  LINE_ID = 'Line id',
  JOURNAL_ENTRY_LINE_LEGAL_ENTITY = 'Legal Entity',
  LEDGER_ACCOUNT = 'Ledger Account',
  DEBIT_CREDIT = 'Debit/Credit',
  JOURNAL_ENTRY_LINE_TAGS = 'Tags',
  JOURNAL_ENTRY_MEMO = 'Journal entry memo',
  JOURNAL_ENTRY_SEQUENCE_ID = 'Journal Entry Sequence Id',
  JOURNAL_ENTRY_LEGAL_ENTITY = 'Legal Entity',
  OPERATIONAL_TRANSACTION = 'Operational Transaction',
  OPERATIONAL_TRANSACTION_HASH = 'Operational Transaction Hash',
  TEMPLATE_NAME = 'Template Name',
  ACCOUNTING_PERIOD = 'Accounting Period',
  ACCOUNTING_DATE = 'Accounting Date',
  TOTAL_DEBIT_AMOUNT = 'Total Debit Amount',
  TOTAL_CREDIT_AMOUNT = 'Total Credit Amount',
  JOURNAL_ENTRY_LINE_MEMO = 'Journal entry line memo',
  JOURNAL_ENTRY_TAGS = 'Tags',
  JOURNAL_ENTRY_CREATED_BY = 'Created By',
  JOURNAL_ENTRY_SYNCED = 'Synced',
}
enum BALANCE_DRILLDOWN_EXPORT_HEADER {
  LINE_ID = 'Line id',
  JOURNAL = 'Journal',
  TRANSACTION = 'Transaction',
  TYPE = 'Type',
  AMOUNT = 'Amount',
}

const getCsvColumnValueForBalanceDrilldownExport = (
  data: BalanceDrilldownRowData,
  column: BALANCE_DRILLDOWN_EXPORT_HEADER,
) => {
  if (!data) return;
  switch (column) {
    case BALANCE_DRILLDOWN_EXPORT_HEADER.LINE_ID:
      return data._id;
    case BALANCE_DRILLDOWN_EXPORT_HEADER.JOURNAL:
      return data.journal.sequenceNumber;
    case BALANCE_DRILLDOWN_EXPORT_HEADER.TYPE:
      return data.type;
    case BALANCE_DRILLDOWN_EXPORT_HEADER.TRANSACTION:
      return data.transaction.sequenceNumber;
    case BALANCE_DRILLDOWN_EXPORT_HEADER.AMOUNT:
      return data.amount;
    default:
      return '';
  }
};

export const prepareCsvRowsForBalanceDrilldown = (data: BalanceDrilldownRowData[]) => [
  Object.values(BALANCE_DRILLDOWN_EXPORT_HEADER) as string[],
  ...data.map((line) =>
    Object.values(BALANCE_DRILLDOWN_EXPORT_HEADER).map((column) =>
      getCsvColumnValueForBalanceDrilldownExport(line, column),
    ),
  ),
];

const getCsvColumnValueFromJE = (lineId: string, je: JournalEntry, column: JE_CSV_HEADER) => {
  const journalEntryLine = (je.journalEntryLineIds as JournalEntryLine[]).find((line) => line._id === lineId);
  if (!journalEntryLine) return;
  switch (column) {
    case JE_CSV_HEADER.LINE_ID:
      return journalEntryLine._id;
    case JE_CSV_HEADER.JOURNAL_ENTRY_LINE_LEGAL_ENTITY:
      return (journalEntryLine.legalEntityId as LegalEntity).entityName;
    case JE_CSV_HEADER.LEDGER_ACCOUNT:
      return `${(journalEntryLine.ledgerAccountId as LedgerAccount).ledgerAccountSequence}:${
        (journalEntryLine.ledgerAccountId as LedgerAccount).ledgerAccountName
      }`;
    case JE_CSV_HEADER.DEBIT_CREDIT:
      return journalEntryLine.creditOrDebit;
    case JE_CSV_HEADER.JOURNAL_ENTRY_LINE_TAGS:
      return (journalEntryLine.tags as unknown as Tag[]).map((t) => t.entry.key).join(':');
    case JE_CSV_HEADER.JOURNAL_ENTRY_MEMO:
      return je.memo;
    case JE_CSV_HEADER.JOURNAL_ENTRY_SEQUENCE_ID:
      return je.journalSequenceNumber;
    case JE_CSV_HEADER.JOURNAL_ENTRY_LEGAL_ENTITY:
      return (je.legalEntityId as LegalEntity).entityName;
    case JE_CSV_HEADER.OPERATIONAL_TRANSACTION:
      return (je?.transactionId as Transaction)?.sequenceNumber;
    case JE_CSV_HEADER.OPERATIONAL_TRANSACTION_HASH:
      return (je?.transactionId as Transaction)?.transactionHash;
    case JE_CSV_HEADER.TEMPLATE_NAME:
      return (je.journalEntryTemplateId as JournalEntryTemplate)?.name ?? '';
    case JE_CSV_HEADER.ACCOUNTING_PERIOD:
      return (je.accountingPeriodId as AccountingPeriod)?.accountingPeriodName;
    case JE_CSV_HEADER.ACCOUNTING_DATE: {
      const transaction = je.transactionId as Transaction;
      let date = new Date(je.accountingDate)?.toLocaleDateString();
      if (transaction?.transactionDate) date = new Date(transaction.transactionDate)?.toLocaleDateString();
      return date;
    }
    case JE_CSV_HEADER.TOTAL_DEBIT_AMOUNT:
      return journalEntryLine.creditOrDebit === CREDIT_OR_DEBIT.DEBIT
        ? formatDollars(journalEntryLine.amount.$numberDecimal, true)
        : '';
    case JE_CSV_HEADER.TOTAL_CREDIT_AMOUNT:
      return journalEntryLine.creditOrDebit === CREDIT_OR_DEBIT.CREDIT
        ? formatDollars(journalEntryLine.amount.$numberDecimal, true)
        : '';
    case JE_CSV_HEADER.JOURNAL_ENTRY_LINE_MEMO:
      return journalEntryLine.memo;
    case JE_CSV_HEADER.JOURNAL_ENTRY_TAGS:
      return (je.tags as unknown as Tag[]).map((t) => `${t.entry.key}:${t.entry.value}`).join('_');
    case JE_CSV_HEADER.JOURNAL_ENTRY_SYNCED:
      return je.isSync ? 'Yes' : 'No';
    case JE_CSV_HEADER.JOURNAL_ENTRY_CREATED_BY:
      return capitalizeFirstLetter(je?.originatedBy ?? '');
    default:
      return '';
  }
};

const prepareCsvRowsFromJE = (je: JournalEntry) =>
  je.journalEntryLineIds.map((line) =>
    Object.values(JE_CSV_HEADER).map((column) => getCsvColumnValueFromJE(line._id, je, column)),
  );

export const prepareCsvRowsFromAllJEs = (allJournalEntries: JournalEntry[]) =>
  allJournalEntries.reduce(
    (data, je) => {
      const rows = prepareCsvRowsFromJE(je).filter(Boolean) as string[][];
      // console.log({ rows, je });
      const result = [...data, ...rows];
      return result;
    },
    [Object.values(JE_CSV_HEADER) as string[]],
  );

export const getAllJEsQueryFn = async (filters) => {
  const response = await getJournalEntries(filters);
  return response.data.journalEntryModels as JournalEntry[];
};
