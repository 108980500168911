import React, { useEffect, useMemo } from 'react';
import {
  Currency,
  CurrencyMenu,
  InputLabel,
  InputWithExtras,
  SingleSelectMenu,
  TextareaInput,
  currencyOptions,
} from 'ui';
import { ENTITY_TYPES } from '../../constants';
import { useGetLegalEntityById } from '../../hooks';

export interface PatchLegalEntityFormState {
  entityName: string;
  addressString: string;
  entityType: string;
  currency?: Currency;
}

export interface PatchLegalEntityFormElementsProps {
  formState: PatchLegalEntityFormState;
  setFormState: React.Dispatch<React.SetStateAction<PatchLegalEntityFormState>>;
  legalEntityId: string;
}

export const PatchLegalEntityFormElements = ({
  formState,
  setFormState,
  legalEntityId,
}: PatchLegalEntityFormElementsProps) => {
  const { data: legalEntity, isLoading: isLoadingLegalEntity } = useGetLegalEntityById(legalEntityId);
  const isFormDataLoading = useMemo(() => !legalEntity && isLoadingLegalEntity, [legalEntity, isLoadingLegalEntity]);

  useEffect(() => {
    if (legalEntity)
      setFormState((prev) => ({
        entityName: legalEntity.entityName,
        entityType: legalEntity.entityType ?? prev.entityType,
        currency: currencyOptions.find((option) => option.value === legalEntity.currency) ?? prev.currency,
        addressString: legalEntity.addressString ?? prev.addressString,
      }));
  }, [legalEntity]);

  return (
    <>
      <div>
        <InputLabel heading='Business name' />
        <InputWithExtras
          value={formState.entityName}
          onChange={(e) => setFormState((prev) => ({ ...prev, entityName: e.target.value }))}
          disabled={isFormDataLoading}
        />
      </div>
      <div>
        <InputLabel heading='Business type' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={ENTITY_TYPES}
          value={ENTITY_TYPES.find((option) => option.value === formState.entityType)}
          onChange={({ value }) => setFormState((prev) => ({ ...prev, entityType: value }))}
          isLoading={isFormDataLoading}
          onClearValue={() => {
            setFormState((prev) => ({
              ...prev,
              entityType: '',
            }));
          }}
        />
      </div>
      <div>
        <InputLabel heading='Business address' />
        <TextareaInput
          value={formState.addressString}
          onChange={(e) => setFormState((prev) => ({ ...prev, addressString: e.target.value }))}
          disabled={isFormDataLoading}
        />
      </div>
      <div>
        <InputLabel heading='Currency' />
        <CurrencyMenu
          options={currencyOptions}
          value={formState.currency}
          onChange={(currency) => setFormState((prev) => ({ ...prev, currency }))}
          isLoading={isFormDataLoading}
        />
      </div>
    </>
  );
};
