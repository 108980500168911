import { arrayMove } from '@dnd-kit/sortable';
import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const reorderConditionGroups = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    draft.conditionGroupIds = arrayMove(
      draft.conditionGroupIds,
      draft.conditionGroupIds.findIndex((id) => id === action.payload.id),
      action.payload.to,
    );
  });
