import toast from 'react-hot-toast';
import { Button } from 'ui';
import { useBulkArchiveSource, useBulkDeleteSource, useInvalidateQuery, useSession } from '../../hooks';
import { deriveError } from '../templates/utils';
import { TagSourcesModal } from '../PageComponents';

export const SourcesMultiSelectActionsComponent = ({ selectedRows, table }) => {
  const { organizationId } = useSession();
  const walletIds = selectedRows?.map((item) => item?.original?._id);
  const { mutateAsync: bulkDeleteSource, isLoading: isBulkDeleting } = useBulkDeleteSource();
  const { mutateAsync: BulkArchiveSource, isLoading: isBulkArchiving } = useBulkArchiveSource();
  const { invalidateWallets } = useInvalidateQuery();

  const handleBulkDeleteSource = async () => {
    if (!organizationId) return;
    try {
      await bulkDeleteSource(
        {
          organizationId,
          walletIds,
        },
        {
          onSuccess: async () => {
            invalidateWallets();
            toast.success('Source(s) deleted successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  const handleBulkArchiveSource = async () => {
    if (!organizationId) return;
    try {
      await BulkArchiveSource(
        {
          organizationId,
          walletIds,
        },
        {
          onSuccess: async () => {
            invalidateWallets();
            toast.success('Source(s) archived successfully.');
          },
          onError: (error) => {
            console.error(error);
            toast.error(deriveError(error));
          },
        },
      );
      table.resetRowSelection();
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex ml-auto gap-4'>
      <Button
        status='danger'
        label='Delete'
        isLoading={isBulkDeleting}
        emphasis='medium'
        onClick={handleBulkDeleteSource}
      />
      <Button emphasis='high' label='Archive' isLoading={isBulkArchiving} onClick={handleBulkArchiveSource} />
      <TagSourcesModal
        sourceIds={walletIds}
        onSuccess={() => {
          table.resetRowSelection();
        }}
      />
    </div>
  );
};
export default SourcesMultiSelectActionsComponent;
