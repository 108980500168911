import * as htmlToImage from 'html-to-image';
import { useRouter } from 'next/router';
import { ModifiedTabType } from '../components/Nav/WorkspaceTabs/types';
import { DEFAULT_WORKSPACE_TABS, DEFAULT_WORKSPACE_TAB_STATE } from '../constants';
import { useSession } from './useSession';

export const convertUrlToPageName = (url: string) => {
  const pageName = url.split('/').filter((item) => item);
  if (pageName.length === 1) {
    return `${pageName[0]} page`;
  }
  return `${pageName[1]} page`;
};

export const handleTabPreview = async () => {
  const componentRef = document.getElementById('main-page');
  if (componentRef) {
    componentRef.style.maxHeight = '100vh';
    const dataURI = await htmlToImage.toJpeg(componentRef);
    return dataURI;
  }
};

function getActiveTab(workspaceTabs: ModifiedTabType[]) {
  return workspaceTabs.find((item) => item.isActive);
}

export const useWorkspaceTabs = (
  workspaceTabs: ModifiedTabType[],
  setWorkspaceTabs: (data: ModifiedTabType[]) => void,
) => {
  const router = useRouter();
  const session = useSession();

  const clearTabState = (tabId: string) => {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(`tabState_${tabId}`))
      .forEach((key) => localStorage.removeItem(key));
  };
  return {
    handleRemoveRoute: (id: string) => {
      if (!session?.token?.length) return;
      if (workspaceTabs.length <= 1 && workspaceTabs[0]?.url !== '/overview') {
        setWorkspaceTabs(DEFAULT_WORKSPACE_TABS);
        router.push('/overview');
        clearTabState(id);
        return;
      }

      const updatedWorkspaceTab = workspaceTabs.filter((tab) => tab.id !== id);

      const activeTabId = workspaceTabs.findIndex((tab) => tab.id === id);

      if (workspaceTabs.at(-1)?.id === id) {
        updatedWorkspaceTab[updatedWorkspaceTab.length - 1].isActive = true;
        router.push(
          {
            pathname: updatedWorkspaceTab[updatedWorkspaceTab.length - 1].url,
            query: { ...updatedWorkspaceTab[updatedWorkspaceTab.length - 1]?.query },
          },
          updatedWorkspaceTab[updatedWorkspaceTab.length - 1].url,
        );
      } else if (activeTabId !== -1) {
        updatedWorkspaceTab[activeTabId].isActive = true;
        router.push(
          {
            pathname: updatedWorkspaceTab[activeTabId].url,
            query: { ...updatedWorkspaceTab[activeTabId]?.query },
          },
          updatedWorkspaceTab[activeTabId].url,
        );
      }

      clearTabState(id);
      setWorkspaceTabs(updatedWorkspaceTab);
    },
    updateActiveRoute: async () => {
      const updatedActiveWorkspaceTab = workspaceTabs.map((item) => ({
        ...item,
        url: item.isActive ? window?.location?.pathname ?? item.url : item.url,
        title: item.isActive
          ? convertUrlToPageName(window?.location?.pathname ?? item.url)
          : convertUrlToPageName(item.url),
      }));
      setWorkspaceTabs(updatedActiveWorkspaceTab);
    },
    updateTabState: async (tabState = DEFAULT_WORKSPACE_TAB_STATE) => {
      const activeTabState = getActiveTab(workspaceTabs)?.state ?? DEFAULT_WORKSPACE_TAB_STATE;
      if (JSON.stringify(tabState) !== JSON.stringify(activeTabState)) {
        const updatedActiveWorkspaceTab = workspaceTabs.map((item) => {
          let updatedState = item.isActive ? { ...item.state, tabState } : item.state;

          return {
            ...item,
            state: updatedState,
          };
        });
        setWorkspaceTabs(updatedActiveWorkspaceTab);
      }
    },
    updateTabFiltersState: async (newFiltersState, replaceEntireState = false) => {
      const activeTabFilterState = getActiveTab(workspaceTabs)?.state?.filtersState ?? {};
      if (JSON.stringify(newFiltersState) !== JSON.stringify(activeTabFilterState)) {
        const updatedActiveWorkspaceTab = workspaceTabs.map((item) => {
          let updatedState = item.isActive
            ? { ...item.state, filtersState: { ...item.state?.filtersState, ...newFiltersState } }
            : item.state;

          if (replaceEntireState && item.isActive) {
            updatedState = { ...item.state, filtersState: { ...newFiltersState } };
          }

          return {
            ...item,
            state: updatedState,
          };
        });
        setWorkspaceTabs(updatedActiveWorkspaceTab);
      }
    },
    updateTabSidebarState: async (newSidebarState, replaceEntireState = false) => {
      const activeTabSidebarState = getActiveTab(workspaceTabs)?.state?.sidebarState ?? {};
      if (JSON.stringify(newSidebarState) !== JSON.stringify(activeTabSidebarState)) {
        const updatedActiveWorkspaceTab = workspaceTabs.map((item) => {
          let updatedState = item.isActive
            ? { ...item.state, sidebarState: { ...item.state?.sidebarState, ...newSidebarState } }
            : item.state;

          if (replaceEntireState && item.isActive) {
            updatedState = { ...item.state, sidebarState: { ...newSidebarState } };
          }

          return {
            ...item,
            state: updatedState,
          };
        });
        setWorkspaceTabs(updatedActiveWorkspaceTab);
      }
    },
    getActiveTab: () => {
      const activeTab = workspaceTabs?.find((item) => item.isActive) ?? DEFAULT_WORKSPACE_TABS[0];
      return activeTab;
    },
    handleSetActiveRoute: async (id: string) => {
      const updatedWorkspaceTab = workspaceTabs.map((tab) => {
        if (tab.isActive) return { ...tab, isActive: false };
        else if (tab.id === id) return { ...tab, isActive: true };
        else return { ...tab, isActive: false };
      });
      setWorkspaceTabs(updatedWorkspaceTab);
    },
    handleCapturePreviewImage: async () => {
      const updatedItems = await Promise.all(
        workspaceTabs.map(async (item) => {
          if (item.isActive && item.url !== item.previewImageUrl) {
            const previewImage = await handleTabPreview();
            return {
              ...item,
              previewImage: previewImage || '',
              previewImageUrl: item.url,
            };
          }
          return item;
        }),
      );
      setWorkspaceTabs(updatedItems);
    },
  };
};
