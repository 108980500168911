import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { TRANSACTION_DIRECTION_OPTIONS } from '../../../components/rules/configurator/constants';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type DirectionFilterRowType = { value: string; label: string };
export type DirectionFilterContextType = FilterContextParams<DirectionFilterRowType>;

const defaultFilterState = TRANSACTION_DIRECTION_OPTIONS.map((option) => ({
  ...option,
  selected: false,
}));
export const useDirectionFilterState = (page: string) => {
  const { state, setState } = useFilterState<DirectionFilterRowType>(FILTER_TYPE.DIRECTION, page, defaultFilterState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
