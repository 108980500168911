import axios from 'axios';
import { SERVER_URL_BILLING_SERVICE } from '../../config';

export const getAiCreditUsage = () =>
  axios.get<{
    aiCredits: {
      used: number;
      limit: number;
      since: string;
      hasUnlimited?: boolean;
    };
    entities: {
      used: number;
      limit: number;
      hasUnlimited?: boolean;
    };
    wallets: {
      used: number;
      limit: number;
      hasUnlimited?: boolean;
    };
    lastUpdate?: number;
  }>(`${SERVER_URL_BILLING_SERVICE}/usage`);

export const createAiCreditUsageRecord = ({
  aiCreditCount,
  operationType,
}: {
  aiCreditCount: number;
  operationType: string;
}) =>
  axios.post<{ clientSecret: string }>(`${SERVER_URL_BILLING_SERVICE}/usage`, {
    aiCreditCount,
    operationType,
  });
