import axios from 'axios';
import { SERVER_URL_MAPLE_SERVICE } from '../../config';
import { prepareSearchParams } from './utils';
import { MapleClient } from 'schemas';

export type GetMapleSourceParams = {
  legalEntityIds?: string[];
};
export const getMapleSource = (params: GetMapleSourceParams = {}) => {
  return axios.get<{ mapleClients: MapleClient[] }>(
    `${SERVER_URL_MAPLE_SERVICE}/maple-clients?${prepareSearchParams(params)}`,
  );
};

export const createMapleSource = (data) => {
  return axios.post(`${SERVER_URL_MAPLE_SERVICE}/maple-client`, data);
};

export const createMapleJob = (data) => {
  return axios.post(`${SERVER_URL_MAPLE_SERVICE}/produce-transactions-job`, data);
};

export type PoolPosition = {
  totalApyCalculated: {
    assetsInterestAccounted: string;
    assetType: string;
  };
  assetPrice: string;
  _id: unknown;
};

export const getPoolPositions = (params) => {
  return axios.get<{
    poolPositions: PoolPosition[];
  }>(`${SERVER_URL_MAPLE_SERVICE}/pool-positions`, params);
};

export const getmappleJobHistories = (body) => {
  return axios.get(`${SERVER_URL_MAPLE_SERVICE}/produce-transactions-job`, body);
};

export type MaplePoolPositionQueryParams = { isActive?: boolean; accountingPeriodId?: string; mapleClientId?: string };
export const getMaplePoolPosition = ({ isActive, accountingPeriodId, mapleClientId }: MaplePoolPositionQueryParams) => {
  const searchParams = prepareSearchParams({ isActive, accountingPeriodId, mapleClientId }, { noExtras: true });

  return axios.get<{ poolPositions: any }>(`${SERVER_URL_MAPLE_SERVICE}/pool-positions?${searchParams}`);
};

export const updateMapleSource = (data) => {
  return axios.patch(`${SERVER_URL_MAPLE_SERVICE}/maple-client`, data);
};

export const deleteMapleSource = (data) => {
  return axios.delete(
    `${SERVER_URL_MAPLE_SERVICE}/maple-client?organizationId=${data?.organizationId}&mapleClientId=${data?.mapleClientId}`,
  );
};

export const getMapleJobHistories = (body) => {
  return axios.get(`${SERVER_URL_MAPLE_SERVICE}/produce-transactions-job`, body);
};

export const getMapleVestedBalances = (data) => {
  return axios.get(`${SERVER_URL_MAPLE_SERVICE}/maple-vested-balances?accountingPeriodId=${data.accountingPeriodId}`);
};

export const createEntriesForMaple = (body: {
  positionAccounting: {
    poolPositionId: string;
    journalEntryTemplateId: string;
  }[];
  accountingPeriodId: string;
}) => {
  return axios.post(`${SERVER_URL_MAPLE_SERVICE}/create-entries-for-apy`, body);
};
