import { useCommandPallete } from '../useCommandPallete';
import { MdChevronLeft } from 'react-icons/md';
import { Button } from 'ui';

const CreateJournalEntry = () => {
  const { setSecondViewPath } = useCommandPallete();

  return (
    <div className='w-full z-commandpallet bg-white h-full rounded-[14px] overflow-scroll min-h-[600px] max-h-[90vh] flex flex-col'>
      <div className='flex items-center px-6 py-4 border-b gap-x-4'>
        <Button leadingIcon={<MdChevronLeft size={20} />} onClick={() => setSecondViewPath('')} emphasis='low' />
        <p className='text-xl'>Add journal entry</p>
      </div>
    </div>
  );
};

export default CreateJournalEntry;
