import { LedgerAccount } from 'services/http/response.types';
import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import { useLedgerAccount } from '../../../hooks';
import { useQueryDataWithFilterState } from './use-query-with-filter-state';
import { mergeLedgerAccounts } from '../../../components/templates/utils';
import { FilterContextParams } from '../types';
import { useMemo } from 'react';

export type LedgerAccountFilterContextType = FilterContextParams<LedgerAccount>;

export const useLedgerAccountFilterState = (page: string) => {
  const { state, setState } = useFilterState<LedgerAccount>(FILTER_TYPE.LEDGER_ACCOUNT, page);

  const { data, isLoading } = useLedgerAccount();
  useQueryDataWithFilterState(data, setState, mergeLedgerAccounts);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
