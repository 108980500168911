import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { HEDGEY_EVENTS } from '../../constants';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();

  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu defaultValue={{ label: 'Hedgey Event', value: 'Hedgey Event' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      <SingleSelectMenu
        options={HEDGEY_EVENTS}
        value={HEDGEY_EVENTS.find((item) => item.value === condition.value)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
    </>
  );
};

export const HedgeyRuleCondition = () => (
  <ConditionRow facts={[FACT.HEDGEY]}>
    <Row />
  </ConditionRow>
);
