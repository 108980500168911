import { useEffect, useState } from 'react';
import { JOB_STATUS } from 'services';
import { useGetRunningJobsQuery } from './http';

export const useAreAllTasksComplete = (taskType) => {
  const [areAllTasksComplete, setAreAllTasksComplete] = useState(false);

  const { data: runningJobConfigs } = useGetRunningJobsQuery({
    jobNames: [taskType],
  });

  useEffect(() => {
    if (areAllTasksComplete) return;
    if (
      runningJobConfigs?.jobConfigurations.filter((config) =>
        [JOB_STATUS.IN_PROGRESS, JOB_STATUS.STARTED].includes(config.jobStatus as JOB_STATUS),
      ).length === 0
    )
      setAreAllTasksComplete(true);
  }, [runningJobConfigs]);

  return areAllTasksComplete;
};
