import React, { useContext, useState } from 'react';
import { DetailsItem } from '../atoms';
import { Sidebar, SidebarBody, SidebarTopBar } from '../atoms/Sidebar';
import EditLedgerAccount from './EditLedgerAccount';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../lib/utils';
import { formatDate } from '../templates/utils';
import { SidebarGlobalContext } from '../../context/SidebarGlobalProvider';
import { LedgerAccountDetailsProps } from './types';

function LedgerAccountDetails({
  onClose,
  selectedLedgerAccount,
  isLoading,
  isDockPanelAvailable,
  onBack,
  id,
  isPrimary,
}: LedgerAccountDetailsProps) {
  const [showEditLedgerAccount, setShowEditLedgerAccount] = useState(false);

  const childrenAccounts = selectedLedgerAccount?.children
    ?.map((item) => `${item.ledgerAccountSequence}: ${item.ledgerAccountName}`)
    ?.join(', ');

  const { openSidebar } = useContext(SidebarGlobalContext);

  return (
    <>
      <Sidebar data-cy={showEditLedgerAccount ? 'editLedgerAccount' : 'viewLedgerAccount'}>
        <SidebarTopBar
          onClose={onClose}
          isDockPanelAvailable={isDockPanelAvailable}
          onBack={onBack}
          itemId={id}
          isPrimary={isPrimary}
          data-cy={showEditLedgerAccount ? 'editLedgerAccount' : 'viewLedgerAccount'}
        />
        <SidebarHeader
          data-cy={showEditLedgerAccount ? 'editLedgerAccount' : 'viewLedgerAccount'}
          title={!showEditLedgerAccount ? selectedLedgerAccount?.ledgerAccountName : 'Edit Ledger Account'}
          loading={isLoading}
          link={`${getHost()}/configure/ledger-accounts/${selectedLedgerAccount?._id}`}
          actions={
            !showEditLedgerAccount
              ? [
                  {
                    label: 'Edit',
                    onClick: () => {
                      setShowEditLedgerAccount(true);
                    },
                    variant: 'primary',
                    'data-cy': 'editButton',
                  },
                ]
              : undefined
          }
        />
        {showEditLedgerAccount ? (
          <EditLedgerAccount
            onCancel={() => {
              setShowEditLedgerAccount(false);
            }}
            onClose={onClose}
            selectedLedgerAccount={selectedLedgerAccount}
            data-cy={showEditLedgerAccount ? 'editLedgerAccount' : 'viewLedgerAccount'}
          />
        ) : (
          <>
            <SidebarBody>
              <SidebarSectionHeader title='Details' />
              <SidebarSection loading={isLoading}>
                <DetailsItem tag='Type' value={selectedLedgerAccount?.ledgerAccountType} />
                <DetailsItem tag='Sequence' value={`${selectedLedgerAccount?.ledgerAccountSequence}`} />
                <DetailsItem tag='Ending balance' value={''} />
                <DetailsItem
                  tag='Parent account'
                  variant='secondary'
                  value={
                    selectedLedgerAccount?.parentLedgerAccountId?._id
                      ? `${selectedLedgerAccount?.parentLedgerAccountId?.ledgerAccountSequence}: ${selectedLedgerAccount?.parentLedgerAccountId?.ledgerAccountName}`
                      : ''
                  }
                  itemId={selectedLedgerAccount?.parentLedgerAccountId?._id}
                  onClick={() => {
                    openSidebar('ledger-accounts', {
                      id: selectedLedgerAccount?.parentLedgerAccountId?._id,
                      primaryOrSecondary: 'secondary',
                    });
                  }}
                  textToCopy={`${getHost()}/configure/ledger-accounts/${selectedLedgerAccount?.parentLedgerAccountId
                    ?._id}`}
                />
                <DetailsItem tag='Children account' variant='secondary' value={childrenAccounts} />
                <DetailsItem
                  tag='Created'
                  value={[
                    selectedLedgerAccount?.userId?.email,
                    formatDate(new Date(selectedLedgerAccount?.createdAt ?? '')),
                  ]}
                />
                <DetailsItem tag='Last update' value={formatDate(new Date(selectedLedgerAccount?.updatedAt ?? ''))} />
              </SidebarSection>
            </SidebarBody>
          </>
        )}
      </Sidebar>
    </>
  );
}

export default LedgerAccountDetails;
