import React, { useContext } from 'react';
import { MdArrowBack, MdClose } from 'react-icons/md';
import { BiDockRight } from 'react-icons/bi';
import { ArrowIcon } from '../../../../assets/generated/svg';
import { Button, classNames } from 'ui';
import { Tooltip } from '../../Tooltip';
import { SidebarGlobalContext, MainDirectoryContext } from '../../../../context';
import { useTabState } from '../../../../hooks';

interface SidebarTopBarProps {
  onClose?: () => void;
  children?: React.ReactNode;
  baseUrl?: string;
  testId?: string;
  onBack?: () => void;
  itemId?: string;
  isPrimary?: boolean;
  isDockPanelAvailable?: boolean;
  'data-cy'?: string;
}

export function SidebarTopBar({
  children,
  onClose,
  testId = '',
  onBack,
  itemId,
  isPrimary = false,
  isDockPanelAvailable = false,
  'data-cy': dataCy,
}: SidebarTopBarProps) {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const { mainDirectoryIds } = useContext(MainDirectoryContext);
  const currentIndex = mainDirectoryIds.findIndex((t) => t === itemId);
  const {
    state: { sidebarState },
    primaryPanel,
    updateTabSidebarState,
  } = useTabState();
  const showArrowBtns = currentIndex !== -1 && isPrimary && mainDirectoryIds.length > 1;

  const itemNumber = showArrowBtns
    ? {
        current: currentIndex + 1,
        total: mainDirectoryIds.length,
      }
    : undefined;

  const hasPrev = currentIndex !== 0;

  const goPrev = hasPrev
    ? () => {
        openSidebar(primaryPanel.route, { id: mainDirectoryIds[currentIndex - 1], primaryOrSecondary: 'primary' });
      }
    : undefined;

  const hasNext = currentIndex !== mainDirectoryIds.length - 1;

  const goNext = hasNext
    ? () => {
        openSidebar(primaryPanel.route, { id: mainDirectoryIds[currentIndex + 1], primaryOrSecondary: 'primary' });
      }
    : undefined;

  return (
    <div
      className='w-full min-h-[70px] bg-white top-0 py-3.5 pr-6 pl-3 flex items-center border-b text-[#111111] font-medium text-xl'
      data-testid={testId}
    >
      <div className='w-full flex items-center'>
        {React.Children.count(children) ? (
          <>{children}</>
        ) : (
          <>
            <div className='flex gap-x-2 mr-2'>
              <Button
                emphasis='low'
                leadingIcon={onBack ? <MdArrowBack fontSize={24} /> : <MdClose fontSize={24} />}
                onClick={
                  onBack
                    ? onBack
                    : () => {
                        onClose && onClose();
                      }
                }
                data-cy={dataCy?.length ? `sidebarHeader__closeButton_${dataCy}` : 'sidebarHeader__closeButton'}
              />
            </div>
            {showArrowBtns && (
              <div className={`${onBack && 'justify-start flex-row-reverse'} flex items-center w-full`}>
                <div className='flex'>
                  <Tooltip content='Move up'>
                    <Button
                      className={`p-2 icon rotate-180 mr-2 ${!goPrev && 'cursor-not-allowed !opacity-60'}`}
                      leadingIcon={<ArrowIcon className='text-xs' />}
                      emphasis='medium'
                      onClick={goPrev}
                      disabled={!goPrev}
                    />
                  </Tooltip>
                  <Tooltip content='Move down'>
                    <Button
                      className={`p-2 icon ${!goNext && 'cursor-not-allowed !opacity-60'}`}
                      emphasis={'medium'}
                      leadingIcon={<ArrowIcon className='text-xs' />}
                      onClick={goNext}
                      disabled={!goNext}
                    />
                  </Tooltip>
                </div>
                {itemNumber ? (
                  <div className='flex mx-2'>
                    <p>{itemNumber.current}</p>
                    <p className='opacity-50 ml-1'>/</p>
                    <p className='opacity-50'>{itemNumber.total}</p>
                  </div>
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
      {isDockPanelAvailable ? (
        <Tooltip content='Dock panel'>
          <Button
            leadingIcon={<BiDockRight size={26} />}
            className={classNames(
              isPrimary
                ? primaryPanel.dockPanel && 'bg-[#eeeeee]'
                : sidebarState?.secondRouteDockPanel && 'bg-[#eeeeee]',
            )}
            onClick={async () => {
              if (isPrimary) {
                await updateTabSidebarState({ primaryPanel: { ...primaryPanel, dockPanel: !primaryPanel?.dockPanel } });
              } else {
                updateTabSidebarState({ secondRouteDockPanel: !sidebarState?.secondRouteDockPanel });
              }
            }}
            emphasis='medium'
          />
        </Tooltip>
      ) : null}
    </div>
  );
}

export default SidebarTopBar;
