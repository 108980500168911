import { useMutation } from '@tanstack/react-query';
import { resentShortCode } from 'services';
import { useSession } from './useSession';

export const useResentShortCode = () => {
  const { updateSessionUser, userId } = useSession();

  return useMutation(async () => {
    const res = await resentShortCode(userId);
    const { user } = res.data;
    updateSessionUser(user);
    return res;
  });
};
