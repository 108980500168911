import { JOB_NAME } from 'services/http';
import { useInvalidateQuery } from './useInvalidateQuery';

export const useJobNameBasedQueryInvalidator = () => {
  const { invalidateJournalEntries, invalidateTransactions, invalidateAssets } = useInvalidateQuery();

  const invalidate = async (jobName: JOB_NAME) => {
    switch (jobName) {
      case JOB_NAME.IMPORT_TRANSACTION_JOB:
        await invalidateTransactions();
        return;
      case JOB_NAME.RULESET_JOB:
        await invalidateJournalEntries();
        return;
      case JOB_NAME.IMPAIRMENT_JOB:
        await invalidateAssets();
        await invalidateJournalEntries();
        return;
      case JOB_NAME.RULE_METRICS_JOB:
        return;
      case JOB_NAME.SYNC_JOURNAL_ENTRY_JOB:
        await invalidateJournalEntries();
        return;
      case JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB:
        await invalidateJournalEntries();
        return;
      case JOB_NAME.ORGANIZATION_RULESET_JOB:
        await invalidateJournalEntries();
        return;
      case JOB_NAME.IMPORT_HEDGEY_SOURCE_JOB:
        await invalidateTransactions();
        return;
      case JOB_NAME.IMPORT_MAPLE_SOURCE_JOB:
        await invalidateTransactions();
        return;
      case JOB_NAME.ORGANIZATION_HEDGEY_SOURCE_JOB:
        await invalidateJournalEntries();
        return;
    }
  };

  return invalidate;
};
