import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createExchangeSource,
  deleteExchangeSource,
  getExchangeSourceSyncHistory,
  updateExchangeSource,
  createExchangeJobs,
  ExchangeSourceType,
} from 'services/http';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../../../hooks';
import { ExchangeSourceResponse, ExchangeSourcesType } from '../common/ExchangeSourceUtils';

export interface CreateExchangeInterface {
  userId: string;
  organizationId: string;
  exchangeSourceType: ExchangeSourceType;
  apiKey: string;
  apiSecret: string;
  apiPassphrase?: string;
  legalEntityId: string;
}

interface DeleteExchangeInterface {
  sourceId: string;
  organizationId: string;
}

interface CreateExchangeJobsInterface {
  accountingPeriodId?: string;
  startDate?: string;
  organizationId?: string;
  userId?: string;
  exchangeId?: string;
}

export const useCreateExchange = () => {
  return useMutation<ExchangeSourceResponse, unknown, ExchangeSourcesType[], unknown>(
    async (body: ExchangeSourcesType[]) => {
      return await createExchangeSource(body);
    },
  );
};

export const useUpdateExchange = () => {
  const { invalidateExchangeSources } = useInvalidateQuery();
  return useMutation(
    async (body: ExchangeSourcesType) => {
      return await updateExchangeSource(body);
    },
    {
      onSuccess: () => {
        invalidateExchangeSources();
      },
    },
  );
};
export const useDeleteExchange = () => {
  return useMutation(async (params: DeleteExchangeInterface) => {
    return await deleteExchangeSource(params);
  });
};

// this triggers multiple exchange import jobs if ran without exchangeId
export const useCreateExchangeJobs = () => {
  const { invalidateExchangeSources } = useInvalidateQuery();
  return useMutation(async (params: CreateExchangeJobsInterface) => {
    const response = await createExchangeJobs(params);
    invalidateExchangeSources();
    return { sourceIds: response.data.sourceIds };
  });
};

export const useGetExchangeSourceSyncHistory = (id: string, organizationId: string) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.EXCHANGE_SOURCE_SYNC_HISTORY_RECORDS, { exchangeSourceId: id }),
    async () => {
      const response = await getExchangeSourceSyncHistory({
        sourceId: id,
        organizationId,
      });
      return response.data;
    },
    {
      enabled: !!id && !!organizationId,
    },
  );
};
