import { CurrencyNameHandlerProps } from './types';

export const currencyNameHandler = ({ code, name_plural }: CurrencyNameHandlerProps) => {
  return `${code} (${currencyNameFormatter(name_plural)})`;
};

export const currencyNameFormatter = (currencyName: string) => {
  const format = currencyName
    .split(' ')
    .map((item, index) => {
      if (!index) {
        return item.split('').join('.');
      } else {
        return capitalizeFirstLetter(item);
      }
    })
    .join('.');
  return format;
};

export const capitalizeFirstLetter = (str: string) => str?.replace(/^./, str[0]?.toUpperCase()) || '';
