import { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { WebsocketContext } from './context';
import { useSession } from '../../hooks';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { JOB_NAME, WS_URL } from 'services';
import { IncomingWebsocketMessages, JobProgressUpdates, OutgoingWebsocketMessages } from '../../pubsub-topics';
import { useInterval } from 'usehooks-ts';
import { WS_INCOMING_MESSAGE_TYPE, WS_OUTGOING_MESSAGE_TYPE } from '../../hooks/types';
import { TaskTrackingRequest } from '../task-manager-context';

export const WebsocketProvider = ({ children }: { children: ReactNode }) => {
  const { organizationId, userId } = useSession();
  const [handshakeDone, setHandshakeDone] = useState(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(WS_URL, { shouldReconnect: () => !!organizationId });

  useEffect(() => {
    const outgoingWebsocketMessagesSubscription = OutgoingWebsocketMessages.subscribe((message) =>
      sendMessage(JSON.stringify(message)),
    );

    return () => outgoingWebsocketMessagesSubscription.unsubscribe();
  }, []);

  useLayoutEffect(() => {
    setHandshakeDone(false);
  }, [organizationId]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) setHandshakeDone(false);
  }, [readyState]);

  useInterval(() => {
    if (organizationId && !handshakeDone && readyState === ReadyState.OPEN) {
      // console.log('Attempting websocket handshake');
      sendMessage(JSON.stringify({ payload: { organizationId, userId }, type: WS_OUTGOING_MESSAGE_TYPE.HANDSHAKE }));
    }
  }, 3000);

  useEffect(() => {
    if (lastMessage !== null) {
      const message: { type: WS_INCOMING_MESSAGE_TYPE; payload: any } = JSON.parse(lastMessage.data);
      IncomingWebsocketMessages.next(message);
      // console.log('ws:', { data: message, lastMessage });
      if (!message.type) return;
      switch (message.type) {
        case WS_INCOMING_MESSAGE_TYPE.HANDSHAKE_SUCCESS:
          setHandshakeDone(true);
          return;
        case WS_INCOMING_MESSAGE_TYPE.JOB_PROGRESS_UPDATE:
          JobProgressUpdates.next(message);
          if (message.payload.jobConfig?.jobName === JOB_NAME.JOURNAL_ENTRY_RULE_MATCH_JOB)
            TaskTrackingRequest.next({
              referenceId: message.payload.jobConfig.referenceId,
              jobName: JOB_NAME.JOURNAL_ENTRY_RULE_MATCH_JOB,
            });
          return;
        default:
        // console.log('ws: bad message', { messageString: lastMessage, data: message });
      }
    }
  }, [lastMessage]);
  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
