import { useQuery } from '@tanstack/react-query';
import { getUserAlgoliaKey } from 'services/http/core';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useAlgoliaKey = () => {
  const { organizationId, token } = useSession();
  const getKey = useOrgBasedQueryKey();

  const { data } = useQuery(
    getKey(QUERY_KEY.ALGOLIA_KEY),
    () =>
      getUserAlgoliaKey(
        { organizationId },
        {
          headers: {
            Authorization: token,
          },
        },
      ),
    {
      enabled: !!organizationId,
    },
  );
  const { securedKey } = data?.data || '';
  return securedKey as string;
};
