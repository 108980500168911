import { useMutation } from '@tanstack/react-query';
import { UpdateJobConfigurationParams, deleteJobConfiguration, updateJobConfiguration } from 'services/http';

export const useUpdateJobConfigurationMutation = () => {
  return useMutation({
    mutationFn: (params: UpdateJobConfigurationParams) => updateJobConfiguration(params),
  });
};
export const useDeleteJobConfigurationMutation = () => {
  return useMutation({
    mutationFn: (params: { jobConfigurationId: string }) => deleteJobConfiguration(params.jobConfigurationId),
  });
};
