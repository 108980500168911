import { createContext, useContext } from 'react';
import { DateFilterContextParams } from './types';
import {
  AccountingPeriodFilterContextType,
  AccountingTreatmentFilterContextType,
  AssetTypeFilterContextType,
  ChainFilterContextType,
  ClassificationFilterContextType,
  DirectionFilterContextType,
  ImpairedFilterContextType,
  JournalSyncFilterContextType,
  LedgerAccountFilterContextType,
  LegalEntityFilterContextType,
  OriginatedByFilterContextType,
  SpamTokenFilterContextType,
  StatusFilterContextType,
  TagFilterContextType,
  WalletFilterContextType,
  WalletTypeFilterContextType,
  SourceFilterContextType,
  TypeFilterContextType,
  AmountFilterContextType,
} from './use-filter-states';
import { JournalEntryTemplateFilterContextType } from './use-filter-states/use-journal-entry-template-filter-state';

const setters = {
  setAll: () => {},
  onlySelect: () => {},
  selectAll: () => {},
  deselectAll: () => {},
  clear: () => {},
  setSelectionByRowIndex: () => {},
};

const defaultContextParams = {
  state: [],
  setState: () => {},
  setters,
  isLoading: false,
  hasSelections: false,
};

const defaultDateFilterContextParams = { state: [], setState: () => {}, datesWithTzOffset: {} };

export const AccountingPeriodFilterContext = createContext<AccountingPeriodFilterContextType>(defaultContextParams);
export const AccountingTreatmentFilterContext =
  createContext<AccountingTreatmentFilterContextType>(defaultContextParams);
export const AssetTypeFilterContext = createContext<AssetTypeFilterContextType>(defaultContextParams);
export const ChainFilterContext = createContext<ChainFilterContextType>(defaultContextParams);
export const ClassificationFilterContext = createContext<ClassificationFilterContextType>(defaultContextParams);
export const DateFilterContext = createContext<DateFilterContextParams>(defaultDateFilterContextParams);
export const TargetDateFilterContext = createContext<DateFilterContextParams>(defaultDateFilterContextParams);
export const SingleDateFilterContext = createContext<DateFilterContextParams>(defaultDateFilterContextParams);
export const DirectionFilterContext = createContext<DirectionFilterContextType>(defaultContextParams);
export const SourceFilterContext = createContext<SourceFilterContextType>(defaultContextParams);
export const TypeFilterContext = createContext<TypeFilterContextType>(defaultContextParams);
export const AmountFilterContext = createContext<AmountFilterContextType>({
  state: { mode: 'is', min: '', max: '' },
  setState: () => {},
});
export const ImpairedFilterContext = createContext<ImpairedFilterContextType>(defaultContextParams);
export const JournalSyncFilterContext = createContext<JournalSyncFilterContextType>(defaultContextParams);
export const LedgerAccountFilterContext = createContext<LedgerAccountFilterContextType>(defaultContextParams);
export const LegalEntityFilterContext = createContext<LegalEntityFilterContextType>(defaultContextParams);
export const JournalEntryTemplateFilterContext =
  createContext<JournalEntryTemplateFilterContextType>(defaultContextParams);
export const OriginatedByFilterContext = createContext<OriginatedByFilterContextType>(defaultContextParams);
export const StatusFilterContext = createContext<StatusFilterContextType>(defaultContextParams);
export const SpamTokenFilterContext = createContext<SpamTokenFilterContextType>(defaultContextParams);
export const TagFilterContext = createContext<TagFilterContextType>(defaultContextParams);
export const WalletFilterContext = createContext<WalletFilterContextType>(defaultContextParams);
export const WalletTypeFilterContext = createContext<WalletTypeFilterContextType>(defaultContextParams);

export const useAccountingPeriodFilterContext = () => useContext(AccountingPeriodFilterContext);
export const useAccountingTreatmentFilterContext = () => useContext(AccountingTreatmentFilterContext);
export const useAssetTypeFilterContext = () => useContext(AssetTypeFilterContext);
export const useChainFilterContext = () => useContext(ChainFilterContext);
export const useClassificationFilterContext = () => useContext(ClassificationFilterContext);
export const useDateFilterContext = () => useContext(DateFilterContext);
export const useTargetDateFilterContext = () => useContext(TargetDateFilterContext);
export const useSingleDateFilterContext = () => useContext(SingleDateFilterContext);
export const useDirectionFilterContext = () => useContext(DirectionFilterContext);
export const useJournalEntryTemplateFilterContext = () => useContext(JournalEntryTemplateFilterContext);
export const useSourceFilterContext = () => useContext(SourceFilterContext);
export const useTypeFilterContext = () => useContext(TypeFilterContext);
export const useAmountFilterContext = () => useContext(AmountFilterContext);
export const useImpairedFilterContext = () => useContext(ImpairedFilterContext);
export const useJournalSyncFilterContext = () => useContext(JournalSyncFilterContext);
export const useLedgerAccountFilterContext = () => useContext(LedgerAccountFilterContext);
export const useLegalEntityFilterContext = () => useContext(LegalEntityFilterContext);
export const useOriginatedByFilterContext = () => useContext(OriginatedByFilterContext);
export const useStatusFilterContext = () => useContext(StatusFilterContext);
export const useSpamTokenFilterContext = () => useContext(SpamTokenFilterContext);
export const useTagFilterContext = () => useContext(TagFilterContext);
export const useWalletFilterContext = () => useContext(WalletFilterContext);
export const useWalletTypeFilterContext = () => useContext(WalletTypeFilterContext);

export const useAllFilterContext = () => ({
  accountingPeriodFilter: useAccountingPeriodFilterContext(),
  accountingTreatmentFilter: useAccountingTreatmentFilterContext(),
  assetTypeFilter: useAssetTypeFilterContext(),
  chainFilter: useChainFilterContext(),
  classificationFilter: useClassificationFilterContext(),
  dateFilter: useDateFilterContext(),
  targetDateFilter: useTargetDateFilterContext(),
  singleDateFilter: useSingleDateFilterContext(),
  directionFilter: useDirectionFilterContext(),
  impairedFilter: useImpairedFilterContext(),
  journalSyncFilter: useJournalSyncFilterContext(),
  ledgerAccountFilter: useLedgerAccountFilterContext(),
  legalEntityFilter: useLegalEntityFilterContext(),
  originatedByFilter: useOriginatedByFilterContext(),
  statusFilter: useStatusFilterContext(),
  spamTokenFilter: useSpamTokenFilterContext(),
  tagFilter: useTagFilterContext(),
  walletFilter: useWalletFilterContext(),
  walletTypeFilter: useWalletTypeFilterContext(),
  amountFilter: useAmountFilterContext(),
});
