import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS, MAPLE_PATH } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

import { useMaplePool } from '../../condition-selector/nested-menus/MaplesConditionsMenu';
import { useGetMapleSource } from '../../../../../hooks';



const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const { data: mapleClient } = useGetMapleSource();
  
  const condition = useCondition();
  type MapleEvent = {
    label: string;
    value: string;
  };

  const poolPositionMap: MapleEvent[] = useMaplePool()
  const mapleClientMap = mapleClient?.mapleClients?.map(item => ({
    label: item?.name,
    value: item?._id
})) || [];
const isLoading = condition.path === MAPLE_PATH.SOURCE
  ? mapleClientMap.find((item) => item.value === condition?.value) === undefined
  : poolPositionMap.find((item) => item.value === condition?.value) === undefined;

  return (
    <>
      <SingleSelectMenu value={condition.path == MAPLE_PATH.SOURCE ? { label: 'Source', value: 'Source' } : { label: 'Pool', value: 'Pool' }} disabled={true} options={[]} />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      <SingleSelectMenu
    
        options={condition.path == MAPLE_PATH.SOURCE ? mapleClientMap : poolPositionMap}
        value={condition.path == MAPLE_PATH.SOURCE ? mapleClientMap.find((item) => {
          return item?.value === condition?.value
        }) : poolPositionMap.find((item) => {
          return item?.value === condition?.value
        })}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value: value,
          });
        }}
        isLoading={isLoading}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
    </>
  );
};

export const MapleRuleCondition = () => (
  <ConditionRow facts={[FACT.MAPLE]}>
    <Row />
  </ConditionRow>
);
