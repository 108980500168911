import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { Avatar } from '../avatar';
import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { LeadingCellProps } from './types';

export function LeadingCell({
  state,
  label,
  bottomText,
  src = '',
  alt = '',
  textAlign = 'text-left',
  type = 'chain',
  isAvatarVisible = true,
  isCheckboxVisible = false,
  isHeader = false,
  checked,
  onChange,
  indeterminate,
  withoutPaddingLeft = true,
  size = 'extra-large',
  icon,
  emoji = '',
  containerClassNames = '',
  initials,
  secondAvatar,
  secondAvatarPosition,
  isFlipped,
  leftMargin = '',
  densityHeight = '',
}: LeadingCellProps) {
  return (
    <div
      className={cn(
        clsx(
          densityHeight || '',
          'w-full flex items-center gap-3 relative',
          'before:w-1 before:inset-y-0 before:left-0 before:hidden before:bg-indigo-600',
          { 'bg-indigo-50 ': state === 'selected' },
          { 'border-b-2 border-b-zinc-300 bg-indigo-100 before:block': state === 'active' },
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { 'pl-0': withoutPaddingLeft },
        ),
        leftMargin,
      )}
    >
      {isCheckboxVisible && (
        <IndeterminateCheckbox
          className='!mr-2'
          isHeader={isHeader}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      )}

      {isAvatarVisible && (
        <Avatar
          src={src}
          alt={alt}
          type={type}
          size={size}
          containerClassNames={containerClassNames}
          emoji={emoji}
          icon={icon}
          initials={initials}
          secondAvatar={secondAvatar}
          secondAvatarPosition={secondAvatarPosition}
          isFlipped={isFlipped}
        />
      )}

      <div className={cn('flex-shrink-0 flex-grow', isAvatarVisible ? 'w-[calc(100%-60px)]' : 'w-[calc(100%-22px)]')}>
        <p className='text-[16px] text-zinc-900 leading-5 w-full truncate whitespace-nowrap overflow-hidden text-ellipsis'>
          {label}
        </p>
        {bottomText && <p className='text-zinc-600 leading-5 truncate'>{bottomText}</p>}
      </div>
    </div>
  );
}
