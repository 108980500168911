import React, { useEffect, useState } from 'react';
import { useGetJournalEntryById } from '../../hooks';
import CreateJournalEntry from './CreateJournalEntry';

const EditJournalEntrySidebar = ({ onCancel, onBack, journalEntryId, isPrimary }) => {
  const [journalEntryData, setJournalEntryData] = useState<any>({});
  const { data, isSuccess } = useGetJournalEntryById(journalEntryId);

  useEffect(() => {
    if (data?.journalEntryModels?.length && data.journalEntryModels.length > 0)
      setJournalEntryData(data?.journalEntryModels[0]);
  }, [data, isSuccess]);

  return (
    <CreateJournalEntry
      onCancel={onCancel}
      onBack={onBack}
      selectedJournalEntry={journalEntryData}
      title={`Edit ${journalEntryData?.journalSequenceNumber}`}
      data-cy='editJournalEntry'
      onClose={onCancel}
      shouldNestCreateJournalLinePanel={isPrimary}
    />
  );
};

export default EditJournalEntrySidebar;
