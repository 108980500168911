import * as React from 'react';
import { forwardRef } from 'react';
const SvgRemoveIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='m29.334 12.547-1.88-1.88L20 18.12l-7.453-7.453-1.88 1.88L18.12 20l-7.453 7.453 1.88 1.88L20 21.88l7.454 7.453 1.88-1.88L21.88 20l7.454-7.453Z'
      fill='#888'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRemoveIcon);
export default ForwardRef;
