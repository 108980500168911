import { useContext, useEffect, useState } from 'react';
import { useTransactionQueryParams } from './use-transaction-query-params';
import { useSources, useTransactions } from '../../../hooks';
import { WALLET_TYPE } from 'services/http/response.types';
import { useDisplayedTransactions } from './utils';
import { MainDirectoryContext } from '../../../context';
import { Transaction } from 'schemas';
import TransactionsTable from './TransactionsTable';

export type MultiSortState = { asc: string[]; desc: string[] };

const DEFAULT_MULTI_SORT_STATE = { asc: [], desc: ['transactionDate'] };
export const TransactionTableWithQuery = ({ searchTerm, setSearchTerm, isSearching, setIsSearching }) => {
  const [multiSortState, setMultiSortState] = useState<MultiSortState>(DEFAULT_MULTI_SORT_STATE);
  const transactionParams = useTransactionQueryParams({ searchTerm, multiSortState });
  const [initialLoading, setInitialLoading] = useState<boolean>(true)

  const {
    data: transactionsData,
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
  } = useTransactions(transactionParams);

  const { data: sourcesData } = useSources({
    asc: multiSortState.asc,
    desc: multiSortState.desc,
    pageSize: 2000,
    walletTypes: Object.values(WALLET_TYPE),
  });
  const displayedTransactions = useDisplayedTransactions(transactionsData, sourcesData);

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);

  const { setMainDirectoryIds } = useContext(MainDirectoryContext);
  useEffect(() => {
    if (displayedTransactions) {
      const transactionIds = displayedTransactions.map((tx) => (tx as Transaction)._id);
      setMainDirectoryIds(transactionIds);

      if (initialLoading && (displayedTransactions.length > 0)) {
        setInitialLoading(false);
      }

    }
  }, [displayedTransactions]);

  return (
    <TransactionsTable
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSortingChange={(sortingState) => {
        if (!sortingState[0]) return setMultiSortState(DEFAULT_MULTI_SORT_STATE);

        const asc: string[] = [],
          desc: string[] = [];

        const accessorKey = sortingState[0].id;
        const direction = sortingState[0].desc ? 'desc' : 'asc';

        const customSortColumns = Object.keys(accessorKeyToSortMap);

        if (customSortColumns.includes(accessorKey))
          if (direction === 'asc') asc.push(...accessorKeyToSortMap[accessorKey]);
          else desc.push(...accessorKeyToSortMap[accessorKey]);
        else if (direction === 'asc') asc.push(accessorKey);
        else desc.push(accessorKey);

        setMultiSortState({ asc, desc });
      }}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading || initialLoading}
      isSearching={isSearching}
      displayedTransactions={displayedTransactions}
      fetchNextPage={fetchNextPage}
    />
  );
};

// accessorKey -> fields for sort
const accessorKeyToSortMap = {
  fromAddress: ['meta.merchantName', 'meta.cardName', 'fromAddress'],
  toAddress: ['meta.merchantName', 'meta.cardName', 'toAddress'],
};
