import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  reach-portal {
  & > :first-child {
    z-index: 20;  
  }

  & #reach-search-container > :nth-child(2) {
    margin-top: 24px;
  }

    input {
      display: flex;
      max-height: 72px;
      height: 100%;
      background: #FFFFFF;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 24px;
      width: 100%;
      outline: none;
    }

    input:focus {
      border: 2px solid rgba(0, 0, 0, 0.5) !important;
    }

    input:active {
      border: 2px solid rgba(0, 0, 0, 0.8) !important;
    }

    #kbar-listbox {
    background: #fff;
    min-height: 85px;
    padding-top: 16px;
    }
  }

  :root {
    --white: #fff;
    --primary-dark: #1E2024;
    --eyebrow:  #EE82EE;
  }
  
  * {
    box-sizing: border-box;
  }
  
  html {
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  }
  body, html {
    min-height: 100vh;
  }

  body {
    position: relative;
  }
  .two-col-min-auto {
    display: grid;
    grid-template-columns: min-content max-content;
  }

  .light-icon {
    path {
      fill: #fff;
    }
  }
  .dark-icon {
    path {
      fill: var(--primary-dark)
    }
  }



  body.dark {
    background: var(--primary-dark);
  }

  html.is-scroll-disabled-nav body {
    height: 100vh;
    overflow: hidden;
    .nav--container {
      &.nav-up {
        transform: translateY(0);
      }
    }
  }
  html.is-scroll-disabled body {
    height: 100vh;
    overflow: hidden;
  }
  
  .nav--container {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    transition: all 0.3s linear;
    opacity: 1;
  }
  


  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }
  
  a {
      transition: all .15s linear;
      cursor: pointer;
      text-decoration: none;
  }
  
  hr {
    border-color: var(----primary-dark);
  }

  .color--white {
    color: var(--white);
  }


  

  .bg--primary-dark {
    background-color: var(--primary-dark);
  }
  .bg--white {
    background-color: var(--white);
  }

  .scrollbar-thin {
    scrollbar-width: thin !important;
  }

  .hedgey-card-parent .hedgey-card-item {
    border-top: 1px solid #e5e7eb;
  }

  .hedgey-card-parent .hedgey-card-item:first-child {
    border-top: 0px !important;
  }
`;

export default GlobalStyles;
