import React from 'react';
import { LeadingCell, capitalizeFirstLetter, currencyImg } from 'ui';
import { onToggleItem } from './utils';
import { TableGroupSourceItemsProps } from '../../types';

const TableGroupSourceItems = ({ data, selectedItems, setSelectedItems }: TableGroupSourceItemsProps) => {
  const getImg = (item: any) => (item.isRaincard ? '/raincards-logo.png' : currencyImg(item?.sourceInfo?.chain));
  const getBottomText = (item: any) => {
    const desc = capitalizeFirstLetter(item?.sourceInfo?.desc || '');
    return `${item.isSubRow ? item.groupName : ''}${item.isSubRow && desc ? ' · ' + desc : desc}`;
  };

  const sourceCell = (item) => (
    <LeadingCell
      className='font-bold'
      size='40px-square'
      src={getImg(item)}
      alt={item?.sourceInfo?.chain}
      label={item?.sourceInfo?.title}
      bottomText={getBottomText(item)}
      isCheckboxVisible={true}
      checked={selectedItems.includes(item._id)}
      onChange={() => onToggleItem({ itemId: item._id, selectedItems, setSelectedItems })}
      withoutPaddingLeft={true}
    />
  );

  return (
    <>
      <div className='max-h-[275px] overflow-y-scroll pl-1 flex flex-col gap-y-0'>
        {data.map((item) => {
          return item.isGroup ? (
            <>
              {item.subRows.map((subItem) => (
                <div
                  key={subItem._id}
                  className='flex justify-between items-center w-full pr-1 hover:bg-[#f8f8f8] rounded-lg px-3 py-2'
                >
                  {sourceCell(subItem)}
                </div>
              ))}
            </>
          ) : (
            <>
              {/* flat rows */}
              <div
                key={item._id}
                className='flex justify-between items-center w-full pr-1 hover:bg-[#f8f8f8] rounded-lg px-3 py-2'
              >
                {sourceCell(item)}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default TableGroupSourceItems;
