import React, { useMemo } from 'react';
import { DetailsItem, Sidebar, SidebarBody, SidebarTopBar } from '../../../atoms';
import SidebarHeader, { Action } from '../../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { SidebarSection, SidebarSectionHeader } from '../../../atoms/Sidebar/SidebarBody';

import { format } from 'date-fns';
import { formatStripeAmount } from '../../../billing/utils';
import toast from 'react-hot-toast';
import { InvoiceLineItemsTable } from './invoice-line-items-table';
import { InvoiceSidebarProps } from './types';

export const InvoiceSidebar = ({
  isDockPanelAvailable,
  onBack,
  handlePrimaryPanelClose,
  isPrimary,
  invoice,
}: InvoiceSidebarProps) => {
  if (!invoice) {
    toast.error('Invoice not defined');
    return null;
  }

  const memoizedActions = useMemo(() => {
    const actions: Action[] = [];
    if (invoice.status === 'open' && invoice.hosted_invoice_url)
      actions.push({
        label: 'Pay now',
        onClick: () => {
          window.open(`${invoice.hosted_invoice_url}`, '_blank');
        },
        variant: 'primary',
      });
    if (invoice.invoice_pdf)
      actions.push({
        label: 'Download',
        onClick: () => {
          window.open(`${invoice.invoice_pdf}`, '_blank');
        },
        variant: 'secondary',
      });

    return actions;
  }, [invoice]);

  return (
    <Sidebar>
      <SidebarTopBar
        onClose={handlePrimaryPanelClose}
        isDockPanelAvailable={isDockPanelAvailable}
        onBack={onBack}
        itemId={invoice.id}
        isPrimary={isPrimary}
      />
      <SidebarHeader title={`${invoice.number}`} actions={memoizedActions} />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={2}>
          {invoice.customer_email && <DetailsItem tag='Billed to' value={invoice.customer_email} />}
          {invoice.due_date && (
            <DetailsItem tag='Due date' value={`${format(new Date(invoice.due_date * 1000), 'MMM d, yyyy')}`} />
          )}
          {invoice.amount_due && <DetailsItem tag='Amount due' value={formatStripeAmount(invoice.amount_due)} />}
          <DetailsItem tag='Frequency' value={'TBD'} />
          <DetailsItem tag='Description' value={'TBD'} />
        </SidebarSection>
        <SidebarSectionHeader title='Summary' />
        <SidebarSection numberOfColumns={1}>
          <InvoiceLineItemsTable invoice={invoice} />

          <div className='flex flex-col items-end'>
            <div className='flex gap-x-4 items-center font-semibold text-lg'>
              <div>Total</div>
              <div>{formatStripeAmount(invoice.amount_due)}</div>
            </div>
          </div>
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};
