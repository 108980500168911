import { classNames } from '../utils';
import { FieldsetProps } from './types';

export const Fieldset = ({ children, className = '' }: FieldsetProps) => {
  return (
    <div className={classNames('px-6 pb-6 grid grid-cols-1 gap-y-6 items-start w-[328px] bg-white', className)}>
      {children}
    </div>
  );
};
