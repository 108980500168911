import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';
import { getAccountingPeriodsHealthByTransactions } from 'services/http/analytics/transactions';

type AccountingPeriodsHealthParams = {
  accountingPeriodIds: string[];
};

export const useAccountingPeriodsHealth = (data: AccountingPeriodsHealthParams) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.GET_ACCOUNTING_PERIODS_HEALTH, data),
    async () => {
      const response = await getAccountingPeriodsHealthByTransactions({
        organizationId,
        ...data,
      });
      return response.data;
    },
    {
      enabled: !!organizationId && !!data?.accountingPeriodIds,
      staleTime: 1000 * 60 * 30,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
