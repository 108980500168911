import queryString from 'query-string';
import { isSessionTokenValid, unprotectedPaths } from '../utils';
import { ExtendedAxiosRequestConfig } from './types';
import Cookies from 'js-cookie';
export enum SESSION_COOKIE {
  TOKEN = 'ENTENDRE_TOKEN',
  ORGANIZATION_ID = 'ENTENDRE_ORGANIZATION_ID',
}

export class RequestConfigFactory {
  private config: ExtendedAxiosRequestConfig;
  private organizationId: string;
  private token: string;

  constructor(config: ExtendedAxiosRequestConfig) {
    this.config = config;
    this.organizationId = Cookies.get(SESSION_COOKIE.ORGANIZATION_ID);
    this.token = Cookies.get(SESSION_COOKIE.TOKEN);

    // if (!this.token) throw new Error('Axios config factory initialized without token');
    // if (!this.organizationId) throw new Error('Axios config factory initialized without organizationId');
    //console.log('RequestConfigFactory: Session:', this.session)
  }

  public transform(): RequestConfigFactory {
    if (this.shouldSkipConfig()) return this;

    this.appendAuthorizationHeader();
    this.appendOrganizationId();
    return this;
  }

  private shouldSkipConfig(): boolean {
    const path = window.location.pathname;
    return (
      this.config.skipEverything ||
      unprotectedPaths.some((unprotected) => path === unprotected || path === unprotected + '/')
    );
  }

  public getConfig(): ExtendedAxiosRequestConfig {
    return this.config;
  }

  private appendAuthorizationHeader() {
    if (!this.config.excludeAuthorizationHeader && isSessionTokenValid(this.token)) {
      this.config.headers = this.config.headers || {};
      this.config.headers.Authorization = `${this.token}`;
    } else {
      console.warn('Authorization header skipped', isSessionTokenValid(this.token), this.token);
    }
  }

  private appendOrganizationId() {
    if (!this.organizationId) console.log('no organizationId available', Cookies.get('organizationId'));
    if (!this.config.skipOrganizationId && this.organizationId) {
      if (this.config.url) {
        const url = new URL(this.config.url);
        const params = queryString.parse(url.search);
        if (!params.organizationId) {
          params.organizationId = this.organizationId;
          this.config.url = `${url.origin}${url.pathname}?${queryString.stringify(params)}`;
        }
      } else {
        console.error('RequestConfigFactory: No URL found in request configuration');
      }
    }
  }
}
