import { useContext } from 'react';
import { TableWrapper } from '../../../dashboard';
import { SidebarGlobalContext, TABLE_TYPE } from '../../../../context';
import { Button, SearchInput } from 'ui';
import { useGetAllFn } from '../../../../hooks';
import { getJournalEntryLines } from 'services';
import { JournalEntryLine } from 'services/http/response.types';
import { useMutation } from '@tanstack/react-query';
import { convertJournalEntryLinesToBalanceDrillDownRows } from './use-balance-drilldown-rows';
import { downloadCsv, prepareCsvRowsForBalanceDrilldown } from '../../../../lib/utils';
import Table from 'src/components/tables/tanstack-table/Table';
import { journalEntryLinesTableColumns } from './journalEntryLinesTableColumns';
import { DensityDropdown } from 'src/components/tables/table-components';

export const JournalLinesTable = ({
  displayedJournalEntryLines,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
  searchTerm,
  setSearchTerm,
  isSearching,
  accountingPeriodStartDateUTC,
  legalEntityIds,
  ledgerAccountIds,
  onSortingChange,
}) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const getAllFn = useGetAllFn<JournalEntryLine>(
    (params) => getJournalEntryLines(params).then((res) => res.data.journalEntryLines),
    {
      accountingPeriodStartDateUTC: accountingPeriodStartDateUTC
        ? new Date(accountingPeriodStartDateUTC).toISOString()
        : undefined,
      legalEntityIds,
      ledgerAccountIds,
    },
    500,
  );

  const { mutateAsync: exportAsCsv, isLoading: isExporting } = useMutation({
    mutationFn: async () => {
      const allRows = await getAllFn();
      const converted = convertJournalEntryLinesToBalanceDrillDownRows(allRows);
      const data = prepareCsvRowsForBalanceDrilldown(converted);
      downloadCsv(data, `balance-journals-export-${new Date().toLocaleDateString()}.csv`);
    },
  });
  return (
    <TableWrapper isInSidepanel>
      <div className='w-full'>
        <Table
          disableColumnPinning={true}
          enableRowSelection={false}
          columns={journalEntryLinesTableColumns}
          panelTableName={'JournalLinesTable'}
          data={displayedJournalEntryLines}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          onRowClick={(row) => openSidebar('journals', { primaryOrSecondary: 'secondary', id: row.original._id })}
          onSortingChange={onSortingChange}
          tableType={TABLE_TYPE.ALL_JOURNALS}
        >
          <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
            <div className='flex items-center gap-3 flex-1 min-w-[320px]'>
              <SearchInput
                data-cy='balanceDrillDownTable_searchTermInput'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                loading={isSearching}
                placeholder='Search journal entries...'
                className='placeholder:text-zinc-500 '
                containerClassName='min-w-0 flex-grow'
                resultCount={displayedJournalEntryLines?.length}
              />
            </div>
            <div className='flex flex-wrap gap-3'>
              <Button
                label='Download'
                emphasis='medium'
                isLoading={isExporting}
                disabled={isExporting}
                onClick={() => exportAsCsv()}
              />
              <DensityDropdown />
            </div>
          </div>
        </Table>
      </div>
    </TableWrapper>
  );
};
