import React, { useState, useRef, ReactElement, useEffect } from 'react';
import { DropDownItem, DropDownMenu } from './DropDownMenu';
import { ResponsiveButtonTruncatorProps, FixedResponsiveButtonTruncatorProps } from './types';
import { classNames } from 'ui';
import useDimensions from 'react-cool-dimensions';

function calculateVisibleItems(
  array: number[],
  maxWidth: number,
  styleSpacing: { gap: number; paddingX: number },
): number {
  let tempArray = [...array];
  const { gap, paddingX } = styleSpacing;
  let elementsTotalWidth = tempArray.reduce((a, b) => a + b, 0) + (tempArray.length - 1) * gap + paddingX;

  for (let i = tempArray.length - 1; elementsTotalWidth >= maxWidth && i >= 0; i--) {
    tempArray.pop();
    elementsTotalWidth = tempArray.reduce((a, b) => a + b, 0) + tempArray.length * gap + paddingX + 40;
  }

  return tempArray.length;
}

export const ResponsiveButtonTruncator = ({
  children,
  className,
  displayElementCount = 3,
  isAutoResponsive = true,
  containerClassName = '',
}: ResponsiveButtonTruncatorProps) => {
  const flattenedChildren: ReactElement[] = Array.isArray(children) ? children : children?.props?.children;

  if (!flattenedChildren?.length && !Array.isArray(flattenedChildren)) return <></>;

  const { observe: containerDiv, width: containerWidth } = useDimensions();
  const childRefArray = useRef<(HTMLElement | null)[]>([]);
  const [childWidthsArray, setChildWidthsArray] = useState<number[]>([]);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const childWidths: number[] = [];
    childRefArray.current.forEach((childRef) => {
      if (childRef) {
        const childWidth = childRef.getBoundingClientRect().width;
        childWidths.push(childWidth);
      }
    });
    setChildWidthsArray(childWidths);
  }, [childRefArray]);

  const getVisibleItemCount = () => {
    if (childWidthsArray.length && itemContainerRef.current) {
      const style = window.getComputedStyle(itemContainerRef.current);
      return calculateVisibleItems(childWidthsArray, containerWidth, {
        paddingX: parseInt(style.paddingRight, 10) + parseInt(style.paddingLeft, 10),
        gap: parseInt(style.gap, 10),
      });
    } else {
      return flattenedChildren?.length;
    }
  };

  const visibleItemCount = getVisibleItemCount();
  const shouldShowDropdown = visibleItemCount !== flattenedChildren?.length;
  return (
    <div className={containerClassName}>
      {isAutoResponsive ? (
        <div className='w-full' ref={containerDiv}>
          <div className={classNames('!w-fit flex gap-3', className)} ref={itemContainerRef}>
            {flattenedChildren?.slice(0, visibleItemCount)?.map((child, index) =>
              React.cloneElement(child, {
                ref: (ref) => {
                  if (ref) {
                    childRefArray.current[index] = ref;
                  }
                },
                key: `${child?.props?.label} ${index}`,
              }),
            )}
            {shouldShowDropdown && (
              <DropDownMenu>
                {flattenedChildren
                  ?.slice(visibleItemCount)
                  .filter(Boolean)
                  .map((child, index) => (
                    <div key={`${child.props?.label} ${index}`}>
                      <DropDownItem onClick={child.props?.onClick}>{child.props?.label}</DropDownItem>
                    </div>
                  ))}
              </DropDownMenu>
            )}
          </div>
        </div>
      ) : (
        <FixedResponsiveButtonTruncator displayElementCount={displayElementCount}>
          {flattenedChildren}
        </FixedResponsiveButtonTruncator>
      )}
    </div>
  );
};

const FixedResponsiveButtonTruncator = ({ children, displayElementCount }: FixedResponsiveButtonTruncatorProps) => (
  <>
    {children?.slice(0, displayElementCount).map((child, index) => React.cloneElement(child, { key: index }))}
    {children.length > displayElementCount ? (
      <DropDownMenu>
        {children
          .slice(displayElementCount)
          .filter(Boolean)
          .map((child, index) => (
            <DropDownItem key={`${child.props.label} ${index}`} onClick={child.props.onClick}>
              {child.props.label}
            </DropDownItem>
          ))}
      </DropDownMenu>
    ) : null}
  </>
);

export default ResponsiveButtonTruncator;
