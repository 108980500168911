import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { CSRowProps, ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { useFilteredConditions } from '../utils';
import { Virtuoso } from 'react-virtuoso';
import { getDataId } from '../../utils';

const ROWS = ['Gross Amount', 'Net Amount', 'Fee'];
const convertToRowFn: ConvertToRowFn<string> = (value) => ({ rowData: { value, label: value }, isSelected: false });
const filterRowsFn: FilterFn<string> = (searchTerm) => (value) =>
  value.toLowerCase().includes(searchTerm.toLowerCase());

export const useTransactionConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Transaction conditions',
      data: ROWS,
      filterFn: filterRowsFn,
      convertToRowFn,
      Row: TransactionConditionSelectorRow,
    }),
    [],
  );

export const TransactionConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() => {
        addCondition({
          fact: FACT.TRANSACTION,
          path: getDataId(FACT.TRANSACTION, conditionData.rowData.value, 'values'),
          operator: 'lessThanBigDecimal',
          value: '0',
        });
      }}
    />
  );
};
export const TransactionConditionsMenu = () => {
  const conditionsData = useFilteredConditions(ROWS, filterRowsFn, convertToRowFn);

  return (
    <ConditionsGroup label='Transaction'>
      {conditionsData.length === 0 && <div className='text-center'>No transaction conditions to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <TransactionConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
