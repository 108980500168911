import { ReactNode, useState } from 'react';
import { OnboardingTransactionBackfillContext } from './context';
import { JobsToTrack } from './types';
import { JOB_NAME } from 'services';
import { useInvalidateQuery } from '../../hooks';

export const OnboardingTransactionBackfillProvider = ({ children }: { initialState?: any; children: ReactNode }) => {
  const [jobsToTrack, setJobsToTrack] = useState<JobsToTrack[]>([]);
  const { invalidateGetTransactionCountByWallet } = useInvalidateQuery();
  const { invalidateGetTransactionCountBySource } = useInvalidateQuery();
  const onTransactionImportJobComplete = (walletId: string) => {
    setJobsToTrack((prev) => {
      const jobIndex = prev.findIndex(
        (j) => j.referenceId === walletId && j.jobName === JOB_NAME.IMPORT_TRANSACTION_JOB,
      );
      if (jobIndex === -1) return prev;
      const next = [...prev];
      next[jobIndex].hasCompleted = true;
      return next;
    });
    invalidateGetTransactionCountByWallet({ walletId });
  };
  const onExchangeImportJobComplete = (exchangeId: string) => {
    setJobsToTrack((prev) => {
      const jobIndex = prev.findIndex(
        (j) => j.referenceId === exchangeId && j.jobName === JOB_NAME.IMPORT_EXCHANGE_SOURCE_JOB,
      );
      if (jobIndex === -1) return prev;
      const next = [...prev];
      next[jobIndex].hasCompleted = true;
      return next;
    });
    invalidateGetTransactionCountBySource({ sourceId: exchangeId });
  };
  return (
    <OnboardingTransactionBackfillContext.Provider
      value={{
        jobsToTrack,
        setJobsToTrack: (params) => {
          console.log('setJobsToTrack called with', params), setJobsToTrack(params);
        },
        onTransactionImportJobComplete,
        onExchangeImportJobComplete,
        allJobsCompleted: jobsToTrack.filter((job) => job.hasCompleted).length === jobsToTrack.length,
      }}
    >
      {children}
    </OnboardingTransactionBackfillContext.Provider>
  );
};
