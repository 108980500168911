import React, { useEffect, useMemo } from 'react';
import { InputLabel, InputWithExtras, SingleSelectMenu, TextareaInput } from 'ui';
import { useJournalEntryTemplates } from '../../hooks/http';
import { getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../templates/utils';
import { useGetImpairmentRuleById } from '../../hooks/http/useImpairmentRules';

export interface PatchImpairmentRuleFormState {
  name: string;
  description: string;
  journalEntryTemplateId?: string;
}

export interface PatchImpairmentRuleFormElementsProps {
  formState: PatchImpairmentRuleFormState;
  setFormState: React.Dispatch<React.SetStateAction<PatchImpairmentRuleFormState>>;
  impairmentRuleId: string;
}

export const PatchImpairmentRuleFormElements = ({
  formState,
  setFormState,
  impairmentRuleId,
}: PatchImpairmentRuleFormElementsProps) => {
  const { data: impairmentRule, isLoading: isLoadingImpairmentRule } = useGetImpairmentRuleById(impairmentRuleId);
  const { data: journalEntryTemplates, isLoading: isLoadingTemplates } = useJournalEntryTemplates({ pageSize: 1000 });
  const mergedTemplates = mergeJournalEntryTemplates(journalEntryTemplates);
  const displayedTemplates = getDisplayedJournalEntryTemplates(mergedTemplates);

  const isFormDataLoading = useMemo(
    () => !impairmentRule && isLoadingImpairmentRule,
    [impairmentRule, isLoadingImpairmentRule],
  );

  useEffect(() => {
    if (impairmentRule)
      setFormState((prev) => ({
        ...prev,
        name: impairmentRule.name,
        description: impairmentRule.description,
        journalEntryTemplateId: impairmentRule.journalEntryTemplateId,
      }));
  }, [impairmentRule]);

  return (
    <>
      <div>
        <InputLabel heading='Impairment rule name' />
        <InputWithExtras
          placeholder='Enter impairment rule name'
          value={formState.name}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, name: e.target.value }));
          }}
          disabled={isFormDataLoading}
        />
      </div>
      <div>
        <InputLabel heading='Description' />
        <TextareaInput
          placeholder='Enter impairment rule description'
          value={formState.description}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, description: e.target.value }));
          }}
          disabled={isFormDataLoading}
        />
      </div>
      <div>
        <InputLabel heading='Journal entry template' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={displayedTemplates}
          onChange={(option: any) => setFormState((prev) => ({ ...prev, journalEntryTemplateId: option.value }))}
          value={displayedTemplates.find((item) => item.value === formState.journalEntryTemplateId)}
          isLoading={isLoadingTemplates || isFormDataLoading}
          onClearValue={() => {
            setFormState((prev) => ({
              ...prev,
              journalEntryTemplateId: '',
            }));
          }}
        />
      </div>
    </>
  );
};
