import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownGroupBody,
  DropdownHeader,
  DropdownTitleWithToggle,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { FILTER_TYPE } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import {useSourceFilterContext } from '../../../../context';

const DEFAULT_TRIGGER_LABEL = 'Source';

export const SourceFilterDropdown = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const {
    state,
    isLoading,
    setters: { onlySelect, selectAll, deselectAll, setSelectionByRowIndex },
  } = useSourceFilterContext();
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedValues = state.filter((value) => value.selected);
      if (selectedValues.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedValues.length === 1) return `${selectedValues[0].value}`;
      return `${selectedValues[0].value} +${selectedValues.length - 1}`;
    });
  }, [state]);

  const sourceFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.SOURCE}`, [dataCy]);

  return (
    <Dropdown data-cy={sourceFilterDataCy} open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(sourceFilterDataCy).trigger}
            isFocused={open}
            isLoading={isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      deselectAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>

      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader title='Source' data-cy={getFilterDropdownSectionDataCy(sourceFilterDataCy).header} />

        <DropdownBody data-cy={getFilterDropdownSectionDataCy(sourceFilterDataCy).body}>
          {state.length === 0 && <div className='p-4 text-center'>No types to show</div>}
          {state.length !== 0 && (
            <>
              <DropdownTitleWithToggle
                title='Select all'
                checked={state.length === state.filter((value) => value.selected).length}
                onChange={(checked: boolean) => (checked ? selectAll() : deselectAll())}
              />
              <DropdownGroupBody>
                {state.map((option, i) => (
                  <CheckboxListItem
                    key={i}
                    label={option.value}
                    checked={option.selected}
                    onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
                    selectOnlyFn={() => onlySelect(i)}
                  />
                ))}
              </DropdownGroupBody>
            </>
          )}
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(sourceFilterDataCy).clearAllButton}
            onClick={state.length !== 0 ? deselectAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
