import { IndeterminateCheckbox } from '../table-cells';
import { Tooltip } from '../tooltip';

import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { GoTriangleRight } from 'react-icons/go';
import { HiMenuAlt2 } from 'react-icons/hi';
import { RiEyeOffFill } from 'react-icons/ri';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

import { Dropdown, DropdownContent, DropdownTrigger } from '../dropdown';
import { useState } from 'react';
import { ExpandableTableHeaderProps } from './types';

export function ExpandableTableHeader({
  state,
  label,
  textAlign = 'text-left',
  isCheckboxVisible = false,
  isHeader = false,
  checked,
  onChange,
  indeterminate,
  isExpanded = false,
  expandFn,
  canExpand,
  sort,
  withoutPaddingLeft = false,
  sortFn,
  hideFn,
}: ExpandableTableHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cn(
        clsx(
          'group relative px-6',
          'flex items-center gap-3',
          'before:absolute before:w-1 before:inset-y-0 before:left-0 before:hidden before:bg-indigo-600',
          { 'bg-indigo-50 ': state === 'selected' },
          { 'border-b-2 border-b-zinc-300 bg-indigo-100 before:block': state === 'active' },
          { 'text-center justify-center': textAlign === 'text-center' },
          { 'text-left justify-start': textAlign === 'text-left' },
          { 'text-right justify-end': textAlign === 'text-right' },
          { '!pl-0': withoutPaddingLeft },
        ),
      )}
    >
      {canExpand ? (
        <Tooltip content='Expand'>
          <button className='w-6 h-6 p-1 rounded-md hover:bg-zinc-200'>
            <GoTriangleRight
              onClick={expandFn}
              className={clsx('w-full h-full text-zinc-800 transition-transform ', {
                'rotate-90': isExpanded,
              })}
            />
          </button>
        </Tooltip>
      ) : (
        <div className='block w-6'></div>
      )}

      {isCheckboxVisible ? (
        <IndeterminateCheckbox
          isHeader={isHeader}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      ) : (
        // 1rem for checkbox width and 2px for border
        <div className='block w-[calc(1rem_+_2px)]'></div>
      )}

      <span className='text-zinc-600 font-medium leading-6'>{label}</span>
      <Dropdown open={isOpen} onOpenChange={setIsOpen}>
        <DropdownTrigger>
          {!sort && <HiMenuAlt2 className='w-9 h-9 p-2 text-zinc-400' />}
          {sort === 'asc' && <GoArrowUp className='w-9 h-9 p-2 text-zinc-400' />}
          {sort === 'desc' && <GoArrowDown className='w-9 h-9 p-2 text-zinc-400' />}
        </DropdownTrigger>
        <DropdownContent className='p-2 flex flex-col gap-1 text-left border-none shadow-[0px_16px_32px_-4px_rgba(0,_0,_0,_0.12)] min-w-full w-[14rem] rounded-lg'>
          <button
            onClick={() => {
              sortFn(false);
              setIsOpen(false);
            }}
            className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 font-medium leading-4'
          >
            <GoArrowUp className='w-6 h-6 py-0.5' />
            <span>Sort Ascending</span>
          </button>
          <button
            onClick={() => {
              sortFn(true);
              setIsOpen(false);
            }}
            className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 font-medium leading-4'
          >
            <GoArrowDown className='w-6 h-6 py-0.5' />
            <span>Sort Descending</span>
          </button>
          <button
            onClick={() => {
              hideFn(false);
              setIsOpen(false);
            }}
            className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 font-medium leading-4'
          >
            <RiEyeOffFill className='w-6 h-6 py-0.5' />
            <span>Hide</span>
          </button>
        </DropdownContent>
      </Dropdown>
    </div>
  );
}
