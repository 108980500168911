import Stripe from 'stripe';

export const prepareInvoiceLineItemsTableData = (invoice: Stripe.Invoice) => ({
  Data: invoice.lines.data.map((line) => ({
    item: line.description,
    quantity: line.quantity,
    unitPrice: line.unit_amount_excluding_tax ? +line.unit_amount_excluding_tax / 100 : line.amount / 100,
    amount: line.amount / 100,
  })),
});
