import Select, { components, DropdownIndicatorProps, OptionProps, SingleValueProps } from 'react-select';

import { Currency, CurrencyMenuProps, CurrencyMenuRowProps, CurrencyNameHandlerProps } from './types';
import { MdArrowDropDown } from 'react-icons/md';
import { classNames } from '../utils';
import { Virtuoso } from 'react-virtuoso';
import CurrencyFlag from 'react-currency-flags';
import CurrencyInfo from './currency-info.json';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';
import { capitalizeFirstLetter } from './utils';

export const getCurrencyNameForCurrencyPicker = ({ code, name_plural }: CurrencyNameHandlerProps) => {
  return `${code} (${capitalizeFirstLetter(name_plural)})`;
};

export const currencyOptions = Object.values(CurrencyInfo).map((item) => {
  return {
    label: getCurrencyNameForCurrencyPicker({ ...item }),
    description: item.symbol,
    icon: <CurrencyFlag currency={item.code} width={30} />,
    value: item.code,
  };
});

export const currencyOptionsWithoutFlag = Object.values(CurrencyInfo).map((item) => {
  return {
    label: getCurrencyNameForCurrencyPicker({ ...item }),
    description: item.symbol,
    icon: item.code,
    value: item.code,
  };
});

const CurrencyMenuRow = ({ currencyIcon, currencyName, currencyDescriptor }: CurrencyMenuRowProps) => (
  <div className='flex items-center justify-between w-full'>
    <div className='flex items-center'>
      {currencyIcon && <span className='w-[24px] h-[16px] mr-2 mb-[2px]'>{currencyIcon}</span>}
      {currencyDescriptor && <span className='font-medium mx-2 inline-block w-10'>{currencyDescriptor}</span>}
      {currencyName && <span className='flex-grow'>{currencyName}</span>}
    </div>
  </div>
);

const Option = (props: OptionProps<Currency>) => (
  <components.Option {...props}>
    <CurrencyMenuRow
      currencyIcon={props.data.icon}
      currencyName={props.data.label}
      currencyDescriptor={props.data.description}
      isSelected={props.isSelected}
    />
  </components.Option>
);

const SingleValue = (props: SingleValueProps<Currency>) => (
  <components.SingleValue {...props}>
    {props.data ? (
      <CurrencyMenuRow
        currencyIcon={props.data.icon}
        currencyName={props.data.label}
        currencyDescriptor={props.data.description}
      />
    ) : (
      'Select'
    )}
  </components.SingleValue>
);

const DropdownIndicator = (props: DropdownIndicatorProps<Currency>) => (
  <components.DropdownIndicator {...props}>
    <MdArrowDropDown
      className={classNames('duration-300 w-6 h-6 text-black', props.selectProps.menuIsOpen && 'rotate-180')}
    />
  </components.DropdownIndicator>
);

const MenuList = (props: any) => {
  const rows = (props.children || []) as React.ReactElement[];
  return (
    <div className='border rounded-lg p-1 grid grid-cols-1 gap-1'>
      <Virtuoso totalCount={rows.length} itemContent={(index) => rows[index]} style={{ height: 150, width: '100%' }} />
    </div>
  );
};
const LoadingIndicator = () => {
  return <LoaderIcon size={LOADER_ICON_SIZE.SMALL} />;
};

export const CurrencyMenu = ({
  isError,
  options = [],
  disabled,
  value,
  onChange,
  isLoading,
  'data-cy': dataCy,
  onFocus,
  onBlur,
}: CurrencyMenuProps) => {
  return (
    <div className='min-w-[280px] border border-1.5 border-zinc-300 rounded-lg'>
      <Select
        options={options}
        isDisabled={disabled}
        isClearable={false}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={{
          label: 'U.S Dollar',
          description: '$USD',
          icon: <CurrencyFlag currency={'USD'} width={30} />,
          value: 'USD',
        }}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          MenuList,
          LoadingIndicator,
        }}
        isMulti={false}
        isLoading={isLoading}
        unstyled
        value={value}
        onChange={onChange}
        classNamePrefix={dataCy}
        classNames={{
          control: (state) =>
            classNames(
              ' bg-white rounded-lg pl-3 pr-[14px] py-2 leading-[22px]  ',
              isError && 'border-[#ff0000] ring-[#ff0000] hover:ring-[#ff0000]',
              !isError && state.menuIsOpen && 'ring-2 ring-[#0000ff] hover:ring-[#0000ff]',
              'hover:ring-2 hover:ring-[#808080]',
              state.isDisabled && 'border opacity-50 pointer-none',
            ),
          menu: () =>
            'rounded-lg mt-2 bg-white border-0 shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)]',
          menuList: () => 'border-0 rounded-lg p-1 grid grid-cols-1 gap-1',
          option: ({ isFocused, isSelected }) =>
            classNames(
              'rounded-md bg-white py-[6px] px-[12px]',
              isFocused && 'bg-indigo-50',
              isSelected && 'bg-indigo-50',
            ),
        }}
      />
    </div>
  );
};
