import { FILTER_TYPE } from '../../../components/filters';
import { useFilterState } from '../use-filters';
import { FilterContextParams } from '../types';
import { useEffect, useMemo } from 'react';
import { useFilteredLegalEntityIds } from '../filtered-hooks';
import { useChains } from 'src/hooks';

export type ChainFilterContextType = FilterContextParams<{ value: string }>;

export const useChainFilterState = (page: string) => {
  const { state, setState } = useFilterState<{ value: string }>(FILTER_TYPE.CHAIN, page);

  const selectedLegalEntityIds = useFilteredLegalEntityIds();

  const { data: chains, refetch, isLoading } = useChains({ legalEntityIds: selectedLegalEntityIds });

  useEffect(() => {
    setState((prev) => {
      if (!chains) return [];
      const previouslySelectedOptions = prev.filter((value) => value.selected);

      const next = chains.map((chain) => ({
        value: chain,
        selected: !!previouslySelectedOptions.find((option) => option.value === chain),
      }));
      return next;
    });
  }, [chains, setState]);

  console.log('chains filter state', state);
  useEffect(() => {
    refetch();
  }, [selectedLegalEntityIds]);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
    isLoading,
  };
};
