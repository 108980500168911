import React from 'react';
import { DatePickerComp } from '../../DatePickerComp';
import { useAccountingPeriodFilterContext, useDateFilterContext, useTargetDateFilterContext } from '../../../context';

export const TargetDateFilter = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const { state, setState } = useTargetDateFilterContext();

  const accountingPeriodFilter = useAccountingPeriodFilterContext();
  const dateFilter = useDateFilterContext();

  return (
    <DatePickerComp
      data-cy={dataCy}
      dateSelections={state}
      setDateSelections={setState}
      onUserInteractionBasedStateChange={() => {
        accountingPeriodFilter.setters.deselectAll();
        dateFilter.setState([]);
      }}
      startDateDefaultLabel='Target date'
      singleDateFilter
    />
  );
};
