import { ReactNode } from 'react';

export enum ALL_FILTERS_TRIGGER_SIZE {
  ICON_ONLY = 'iconOnly',
  FULL = 'full',
}

export interface IFilter {
  id: string;
  name: string;
  category: string;
}

export interface IStatefulFilter extends IFilter {
  selected: boolean;
}

export interface FilterDropdownProps {
  trigger: ReactNode;
  dropdownHeader: ReactNode;
  dropdownSubHeader?: ReactNode;
  dropdownBody: ReactNode;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  open?: boolean;
  onClose?: () => void;
  'data-cy'?: string;
}

export interface AllFiltersDropdownProps {
  triggerSize?: ALL_FILTERS_TRIGGER_SIZE;
  filters: IFilter[];
}
