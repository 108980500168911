import { useFilterState } from '../use-filters';
import { useGetAccountingPeriodStartDates } from '../../../hooks';
import { FILTER_TYPE } from '../../../components/filters';
import { mergeAccountingPeriodStartDates } from '../../../components/templates/utils';
import { FilterContextParams, FilterRowState } from '../types';
import { useMemo } from 'react';
import { useQueryDataWithFilterState } from './use-query-with-filter-state';

export type AccountingPeriodFilterRow = { startDateUTC: Date; accountingPeriodName: string };
export type AccountingPeriodFilterContextType = FilterContextParams<AccountingPeriodFilterRow>;

export type PrepareInitialAccountingPeriodOptionsFn = (
  accountingPeriodStartDates: AccountingPeriodFilterRow[],
) => FilterRowState<AccountingPeriodFilterRow>[];

export const useAccountingPeriodFilterState = (
  page: string,
  prepareInitialOptions?: PrepareInitialAccountingPeriodOptionsFn,
) => {
  const { state, setState } = useFilterState<AccountingPeriodFilterRow>(FILTER_TYPE.ACCOUNTING_PERIOD, page);

  const { data, isLoading } = useGetAccountingPeriodStartDates();
  useQueryDataWithFilterState(
    data,
    setState,
    mergeAccountingPeriodStartDates,
    prepareInitialOptions,
    (option1, option2) => {
      return new Date(option1.startDateUTC).toString() === new Date(option2.startDateUTC).toString();
    },
  );

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
