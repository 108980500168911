import React from 'react';
import { DetailsItem } from '../atoms';
import { useJournalEntryTemplates } from '../../hooks';
import { getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../templates/utils';

export const JournalEntryTemplateDetailItem = ({ journalEntryTemplateId }) => {
  const { data: templatePages } = useJournalEntryTemplates({
    pageSize: 1000,
  });

  const merged = mergeJournalEntryTemplates(templatePages);
  const displayedTemplates = getDisplayedJournalEntryTemplates(merged);
  const value = displayedTemplates.find((template) => template.value === journalEntryTemplateId)?.label;

  return <DetailsItem tag='Journal entry template' value={value} />;
};
