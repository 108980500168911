import { TYPES } from './constants';
import { StatusIndicatorProps } from './types';

const getStyles = (type: StatusIndicatorProps['type'], emphasis: StatusIndicatorProps['emphasis']) => {
  const styles: any = {
    classes: {
      container: 'bg-neutral-fill border border-neutral-stroke',
      label: 'text-neutral-label-light',
      icon: 'text-neutral-label-dark',
    },
  };
  if (TYPES.neutral.includes(type?.toLowerCase() ?? '')) {
    if (emphasis === 'medium')
      styles.classes = {
        container: 'border border-neutral-stroke',
        label: 'text-neutral-label-dark',
        icon: 'text-neutral-label-dark',
      };
    if (emphasis === 'low') {
      styles.classes = {
        container: '',
        label: 'text-neutral-label-dark',
        icon: 'text-neutral-label-dark',
        dotBgColor: 'bg-neutral-label-dark',
      };
    }
  }

  if (type === 'info') {
    if (emphasis === 'high')
      styles.classes = {
        container: 'bg-info-fill border border-info-stroke',
        label: 'text-info-label-light',
        icon: 'text-info-label-dark',
      };
    if (emphasis === 'medium')
      styles.classes = {
        container: 'border border-info-stroke',
        label: 'text-info-label-dark',
        icon: 'text-info-label-dark',
      };
    if (emphasis === 'low') {
      styles.classes = {
        container: '',
        label: 'text-info-label-dark',
        icon: 'text-info-label-dark',
        dotBgColor: 'bg-info-stroke',
      };
    }
  }

  if (TYPES.positive.includes(type?.toLowerCase() ?? '')) {
    if (emphasis === 'high')
      styles.classes = {
        container: 'bg-[#0080001A] border border-[#00800080]',
        label: 'text-[#008000]',
        icon: 'text-[#008000]',
      };
    if (emphasis === 'medium')
      styles.classes = {
        container: 'border border-[#00800080]',
        label: 'text-[#008000]',
        icon: 'text-[#008000]',
      };
    if (emphasis === 'low') {
      styles.classes = {
        container: '',
        label: 'text-[#008000]',
        icon: 'text-[#008000]',

        dotBgColor: 'bg-[#008000]',
      };
    }
  }

  if (type === 'danger') {
    if (emphasis === 'high')
      styles.classes = {
        container: 'bg-danger-fill border border-danger-stroke',
        label: 'text-danger-label-light',
        icon: 'text-danger-label-dark',
      };
    if (emphasis === 'medium')
      styles.classes = {
        container: 'border border-danger-stroke',
        label: 'text-danger-label-dark',
        icon: 'text-danger-label-dark',
      };
    if (emphasis === 'low') {
      styles.classes = {
        container: '',
        label: 'text-danger-label-dark',
        icon: 'text-danger-label-dark',
        dotBgColor: 'bg-danger-stroke',
      };
    }
  }

  if (TYPES.warning.includes(type?.toLowerCase() ?? '')) {
    if (emphasis === 'high')
      styles.classes = {
        container: 'border border-[#FFA50080] text-[#FFA500] bg-[#FFA5001A]',
        label: 'text-[#FFA500]',
        icon: 'text-[#FFA500]',
      };
    if (emphasis === 'medium')
      styles.classes = {
        container: 'border border-[#FFA50080]',
        label: 'text-[#FFA500]',
        icon: 'text-[#FFA500]',
      };
    if (emphasis === 'low') {
      styles.classes = {
        container: '',
        label: 'text-[#FFA500]',
        icon: 'text-[#FFA500]',
        dotBgColor: 'bg-[#FFA500]',
      };
    }
  }

  return styles;
};
export default getStyles;
