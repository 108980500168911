import { FILTER_TYPE, FilterRowState } from './filter-dropdowns';

import { useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useTabState } from '../../hooks';

export const getLocalStorageKeyForFilterState = (activeTabId: string, filterType: FILTER_TYPE, suffix = '') =>
  `filterState_${activeTabId}_${filterType}` + suffix;

export const useFilterState = <T = any>(filterType: FILTER_TYPE, initialState: FilterRowState<T>[] = []) => {
  const { activeTab } = useTabState();
  const activeTabId = useMemo(() => activeTab.id, [activeTab]);

  const [state, setState] = useLocalStorage(getLocalStorageKeyForFilterState(activeTabId, filterType), initialState);

  return { state, setState };
};
