import { useInfiniteQuery } from '@tanstack/react-query';
import { getStripeInvoices } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../../../useInvalidateQuery';

export const useGetInvoicesFromStripeQuery = ({ perPage } = { perPage: 10 }) => {
  const getKey = useOrgBasedQueryKey();
  return useInfiniteQuery({
    queryKey: getKey(QUERY_KEY.STRIPE_INVOICES),
    queryFn: async ({ pageParam }) => {
      const response = await getStripeInvoices({ startingAfter: pageParam, limit: perPage });
      return response.data.invoices;
    },
    getNextPageParam: (lastPage) => (lastPage.length === perPage ? lastPage[lastPage.length - 1].id : undefined),
    getPreviousPageParam: () => undefined,
  });
};
