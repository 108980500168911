import * as React from 'react';
import { forwardRef } from 'react';
const SvgUnlockIcon = (props, ref) => (
  <svg
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 96.11 122.88'
    width='1em'
    height='1em'
    ref={ref}
    {...props}
  >
    <path
      d='M2.89 56h9V37.12a37 37 0 0 1 10.9-26.21 37 37 0 0 1 52.42 0 37 37 0 0 1 10.9 26.21V38H71.66v-1.09a22.68 22.68 0 0 0-6.66-16 22.69 22.69 0 0 0-38.72 16V56h67a2.9 2.9 0 0 1 2.89 2.89V120a2.9 2.9 0 0 1-2.89 2.89H2.89A2.9 2.9 0 0 1 0 120V58.93A2.9 2.9 0 0 1 2.89 56Zm46.26 33.45 4.58 21.14H41.17l3.69-21.42a8.51 8.51 0 1 1 4.29.23Z'
      style={{
        fillRule: 'evenodd',
      }}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnlockIcon);
export default ForwardRef;
