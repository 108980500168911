import { BarChart, Legend } from '@tremor/react';
import { AvailableLedgerAccountsType, JournalsBarChartMode, JournalsBarChartProps } from './bar-chart-types';
import { JournalEntriesVolumeByLedgerAccountType } from 'services/http/analytics/journals';
import { useEffect, useState } from 'react';
import { addMonths } from 'date-fns';
import { ChartExportDropdown } from 'ui';
import { classNames, Button, Dropdown, DropdownContent, DropdownTrigger } from 'ui';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';
import { exportChart } from 'src/lib/utils';
import { TremorCard } from 'ui';

const getModeName = (mode: JournalsBarChartMode) => {
  switch (mode) {
    case JournalsBarChartMode.INCOME_STATEMENT:
      return 'Income Statement';
    case JournalsBarChartMode.BALANCE_SHEET:
      return 'Balance Sheet';
  }
};

export const JournalsBarChart: React.FC<JournalsBarChartProps> = ({
  data,
  isLoading,
  accountingPeriodStartDateUTCs = [],
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [category, setCategory] = useState<string>(JournalsBarChartMode.INCOME_STATEMENT);
  const [selectedLedgerAccounts, setSelectedLedgerAccounts] = useState<string[]>([]);
  const threeMonthsAgo = addMonths(new Date(), -3);

  const chartId = `${category}-journals-chart`;

  useEffect(() => {
    const selectedAccountingPeriodIdsOrDefault =
      accountingPeriodStartDateUTCs.length > 0
        ? accountingPeriodStartDateUTCs
        : data?.accountingPeriods
            .filter((accountingPeriod) => new Date(accountingPeriod.startDate) > threeMonthsAgo)
            .map((accountingPeriod) => accountingPeriod.startDateUTC) ?? [];
    setProcessedData(
      processJournalsBarChartData(
        data,
        category as JournalsBarChartMode,
        selectedLedgerAccounts,
        selectedAccountingPeriodIdsOrDefault,
      ),
    );
  }, [data, category, selectedLedgerAccounts, accountingPeriodStartDateUTCs]);

  useEffect(() => {
    const newAvailableLedgerAccounts: AvailableLedgerAccountsType = {
      balanceSheetLedgerAccounts: [],
      incomeStatementLedgerAccounts: [],
    };

    data?.ledgerAccounts.forEach((ledgerAccount) => {
      ledgerAccount.ledgerAccountType === 'Asset' ||
      ledgerAccount.ledgerAccountType === 'Liability' ||
      ledgerAccount.ledgerAccountType === 'Equity'
        ? newAvailableLedgerAccounts?.balanceSheetLedgerAccounts.push(ledgerAccount)
        : newAvailableLedgerAccounts?.incomeStatementLedgerAccounts.push(ledgerAccount);
    });
    if (category === JournalsBarChartMode.INCOME_STATEMENT)
      setSelectedLedgerAccounts(
        newAvailableLedgerAccounts?.incomeStatementLedgerAccounts?.map((ledgerAccount) => ledgerAccount?._id),
      );
    else
      setSelectedLedgerAccounts(
        newAvailableLedgerAccounts?.balanceSheetLedgerAccounts?.map((ledgerAccount) => ledgerAccount?._id),
      );
  }, [data, category]);

  const maxValue = getMaxValue(processedData);
  const yAxisWidth = calculateYAxisWidth(maxValue);

  const categories =
    category === JournalsBarChartMode.INCOME_STATEMENT ? ['Income', 'Expense'] : ['Asset', 'Liability', 'Equity'];

  return (
    <div className='px-6 py-6'>
      <TremorCard id={chartId}>
        <div className='flex flex-col'>
          <div className='flex justify-between mb-6 flex-wrap gap-y-2'>
            <div className='flex gap-x-2 ignore-export flex-1'>
              <div className='max-w-[300px]'>
                <Dropdown open={open} onOpenChange={setOpen}>
                  <DropdownTrigger>
                    <Button
                      isFocused={open}
                      isLoading={isLoading}
                      onClick={() => setOpen((prev) => !prev)}
                      label={
                        category === '' ? (
                          'Select...'
                        ) : (
                          <span className='text-indigo-600'>{getModeName(category as JournalsBarChartMode)}</span>
                        )
                      }
                      className={classNames(
                        'duration-100 py-0',
                        category !== '' && 'shadow',
                        category !== '' && 'bg-indigo-50 border-indigo-600',
                      )}
                      labelContainerClassname={classNames('font-medium', category !== '' && 'text-indigo-600')}
                      emphasis='medium'
                      trailingIconContainerClassname={classNames(
                        category !== '' && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
                        category !== '' && 'bg-indigo-50',
                      )}
                      trailingIcon={
                        <div className='flex items-center'>
                          {category === '' && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setCategory('');
                              }}
                            >
                              <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                            </span>
                          )}

                          <MdArrowDropDown
                            className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')}
                          />
                        </div>
                      }
                    />
                  </DropdownTrigger>
                  <DropdownContent className='flex flex-col text-left justify-start gap-y-1 p-1'>
                    <button
                      className={classNames(
                        'rounded-md px-2 py-1.5 bg-white hover:bg-indigo-50 text-zinc-900  text-left',
                        category === JournalsBarChartMode.INCOME_STATEMENT && 'bg-indigo-50',
                      )}
                      onClick={() => {
                        setCategory(JournalsBarChartMode.INCOME_STATEMENT);
                        setOpen(false);
                      }}
                    >
                      Income Statement
                    </button>
                    <button
                      className={classNames(
                        'rounded-md px-2 py-1.5 bg-white hover:bg-indigo-50 text-zinc-900 text-left',
                        category === JournalsBarChartMode.BALANCE_SHEET && 'bg-indigo-50',
                      )}
                      onClick={() => {
                        setCategory(JournalsBarChartMode.BALANCE_SHEET);
                        setOpen(false);
                      }}
                    >
                      Balance Sheet
                    </button>
                  </DropdownContent>
                </Dropdown>
              </div>
            </div>
            <div className='flex gap-x-4'>
              <ChartExportDropdown
                onPngExport={() =>
                  exportChart({
                    chartId,
                    ignore: ['tremor-TabGroup-root'],
                    format: 'png',
                    title: `Journals Volume Chart - ${getModeName(category as JournalsBarChartMode)}`,
                  })
                }
                onSvgExport={() =>
                  exportChart({
                    chartId,
                    ignore: ['tremor-TabGroup-root'],
                    format: 'svg',
                    title: `Journals Volume Chart - ${getModeName(category as JournalsBarChartMode)}`,
                  })
                }
              />
            </div>
          </div>
          <Legend className='max-w-fit mb-4 self-end' categories={categories} />
        </div>
        {isLoading ? (
          <div className='h-[300px] animate-pulse bg-gray-50' />
        ) : (
          <BarChart
            {...props}
            categories={categories}
            index='Month'
            data={processedData}
            showLegend={false}
            className='h-[300px]'
            valueFormatter={valueFormatter}
            yAxisWidth={yAxisWidth}
          />
        )}
      </TremorCard>
    </div>
  );
};

const valueFormatter = (value: number) => {
  return '$ ' + Intl.NumberFormat('us').format(value).toString();
};

const getMaxValue = (data: Record<string, any>[]) => {
  let maxValue = 0;
  data.forEach((val) => {
    for (const i of Object.values(val)) {
      if (typeof i === 'number' && Math.abs(i) > maxValue) {
        maxValue = Math.abs(i);
      }
    }
  });
  return maxValue;
};

const processJournalsBarChartData = (
  data: JournalEntriesVolumeByLedgerAccountType['data'] | undefined,
  type: JournalsBarChartMode,
  ledgerAccountIds: string[],
  accountingPeriodStartDateUTCs: Date[] = [],
) => {
  if (!data) return [];
  const selectedPeriods = data.accountingPeriods
    .filter((accountingPeriod) => accountingPeriodStartDateUTCs.includes(accountingPeriod.startDateUTC))
    .map((accountingPeriod) => accountingPeriod.accountingPeriodName);
  if (type === JournalsBarChartMode.BALANCE_SHEET) {
    const balanceSheetData = data.balanceSheet;
    const processedData: {
      Month: string;
      Asset: number;
      Liability: number;
      Equity: number;
    }[] = [];
    if (balanceSheetData) {
      for (const [month, dataByMonth] of Object.entries(balanceSheetData)) {
        let assetTotal = 0;
        let liabilityTotal = 0;
        let equityTotal = 0;
        for (const [ledgerAccountId, value] of Object.entries(dataByMonth.Asset)) {
          if (!ledgerAccountIds.includes(ledgerAccountId)) continue;
          assetTotal += parseInt(value);
        }
        if (dataByMonth?.Liability) {
          for (const [ledgerAccountId, value] of Object.entries(dataByMonth.Liability)) {
            if (!ledgerAccountIds.includes(ledgerAccountId)) continue;
            liabilityTotal += parseInt(value);
          }
        }
        if (dataByMonth?.Equity) {
          for (const [ledgerAccountId, value] of Object.entries(dataByMonth.Equity)) {
            if (!ledgerAccountIds.includes(ledgerAccountId)) continue;
            equityTotal += parseInt(value);
          }
        }
        if (selectedPeriods.includes(month)) continue;

        processedData.push({
          Month: month,
          Asset: assetTotal,
          Liability: liabilityTotal,
          Equity: equityTotal,
        });
      }
    }
    return processedData;
  } else if (type === JournalsBarChartMode.INCOME_STATEMENT) {
    const incomeStatementData = data.incomeStatement;
    const processedData: {
      Month: string;
      Income: number;
      Expense: number;
    }[] = [];

    if (incomeStatementData) {
      for (const [month, dataByMonth] of Object.entries(incomeStatementData)) {
        let incomeTotal = 0;
        let expenseTotal = 0;

        // Check if dataByMonth.Income is defined and not null
        if (dataByMonth?.Income && typeof dataByMonth?.Income === 'object') {
          for (const value of Object.values(dataByMonth?.Income)) {
            incomeTotal += parseInt(value);
          }
        }

        // Check if dataByMonth.Expense is defined and not null
        if (dataByMonth?.Expense && typeof dataByMonth?.Expense === 'object') {
          for (const value of Object.values(dataByMonth.Expense)) {
            expenseTotal += parseInt(value);
          }
        }

        // only add to processedData if there is data for the month
        if (selectedPeriods?.includes(month)) continue;

        processedData.push({
          Month: month,
          Income: incomeTotal,
          Expense: expenseTotal,
        });
      }
    }

    return processedData;
  }
  return [];
};
const calculateYAxisWidth = (maxValue: number) => {
  return maxValue.toFixed(2).toString().length * 11;
};
