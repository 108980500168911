import React, { useContext, useState } from 'react';
import { SidebarHeader, Action } from '../../../atoms';
import { formatDate } from 'services';
import { handlePatchTransactionExternalSource } from './utils';
import toast from 'react-hot-toast';
import {
  useAddExternalSource,
  useInvalidateQuery,
  useMarkTransactionsAsSpam,
  useSession,
  useTabState,
  useMarkContractsAsSpam,
  useMarkContractsAsNotSpam,
} from '../../../../hooks';
import { getCsvRowForSingleTransaction, getHost } from '../../../../lib/utils';
import { deriveError } from '../../../templates/utils';
import { SidebarGlobalContext } from '../../../../context';
import { Dialog } from 'ui';
import {
  ClassifyTransactionModalContent,
  UpdateTransactionCategoryModalContent,
  prepareSpamTokensFromTransactions,
} from '../../../PageComponents';
import { downloadCsv, withCsvToasts } from '../../../../lib/utils/download-csv';
import { useMutation } from '@tanstack/react-query';

const TransactionSidebarHeader = ({ transaction, isLoading, transactionId, isPrimary, dataCy }) => {
  const { secondRouteStack, updateTabSidebarState } = useTabState();
  const { invalidateTransactions, invalidateWallets, invalidateTransactionById } = useInvalidateQuery();
  const { mutateAsync: patchExternalSource, isLoading: isLoadingExternalSource } = useAddExternalSource();

  const { isLoading: isPreparingCsv, mutateAsync: exportAsCsv } = useMutation({
    mutationFn: async () => {
      const data = getCsvRowForSingleTransaction(transaction);
      downloadCsv(data, `transaction-export-${new Date().toLocaleDateString()}.csv`);
    },
  });
  const { mutateAsync: toggleTransactionAsSpam } = useMarkTransactionsAsSpam();
  const { mutateAsync: markContractAsSpam } = useMarkContractsAsSpam();
  const { mutateAsync: unmarkContractAsSpam } = useMarkContractsAsNotSpam();
  const { closeSidebar } = useContext(SidebarGlobalContext);
  const { userId } = useSession();

  const [showClassificationModal, setShowClassificationModal] = useState(false);
  const [showRecategorizationModal, setShowRecatorizationModal] = useState(false);

  const onMarkTransactionAsSpamClick = async () => {
    try {
      await toggleTransactionAsSpam({ transactionIds: [transaction._id] });
      await closeSidebar('primary');
      toast.success(transaction?.sequenceNumber + ' marked as spam');
      await invalidateTransactionById({ transactionId: transaction?._id });
      await invalidateTransactions();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const onUnmarkTransactionAsSpamClick = async () => {
    try {
      await toggleTransactionAsSpam({ transactionIds: [transaction._id] });
      toast.success(transaction?.sequenceNumber + ' removed from spam');
      await invalidateTransactionById({ transactionId: transaction?._id });
      await invalidateTransactions();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const onMarkContractAsSpamClick = async () => {
    if (!transaction) return;
    try {
      await markContractAsSpam({ spamTokens: prepareSpamTokensFromTransactions([transaction]) });
      await closeSidebar('primary');
      toast.success('Contract for ' + transaction.sequenceNumber + ' marked as spam');

      await invalidateTransactionById({ transactionId: transaction._id });
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const onUnmarkContractAsSpamClick = async () => {
    if (!transaction) return;
    try {
      await unmarkContractAsSpam([transaction.spamTokenId]);
      toast.success('Contract for ' + transaction.sequenceNumber + ' removed from spam');

      await invalidateTransactionById({ transactionId: transaction._id });
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const onCreateJournalEntryClick = () => {
    if (isPrimary) {
      closeSidebar('secondary');
    }
    const updatedTabQuery = {
      secondRouteUnStack: {
        showCreateJournal: true,
        journalEntryFormData: {},
      },
      secondRouteStack: isPrimary ? [] : secondRouteStack,
      secondRouteDockPanel: false,
    };
    updateTabSidebarState(updatedTabQuery);
  };
  const onAddExternalSourceClick = () =>
    handlePatchTransactionExternalSource({
      transaction,
      patchExternalSource,
      userId,
      invalidateTransactions,
      invalidateWallets,
    });
  const actions: Action[] = [
    {
      label: 'Create journal entry',
      onClick: onCreateJournalEntryClick,
      variant: 'primary',
    },
    {
      label: 'Add External Source',
      variant: 'tertiary',
      onClick: onAddExternalSourceClick,
      isLoading: isLoadingExternalSource,
    },
    {
      label: 'Download',
      onClick: withCsvToasts(exportAsCsv),
      variant: 'tertiary',
      isLoading: isPreparingCsv,
    },
    {
      label: 'Classify',
      onClick: () => setShowClassificationModal(true),
      variant: 'tertiary',
    },
    {
      label: 'Recategorize',
      onClick: () => setShowRecatorizationModal(true),
      variant: 'tertiary',
    },
  ];
  if (transaction && transaction.rawContractAddress)
    actions.splice(
      3,
      0,
      transaction.isSpam && transaction.spamTokenId
        ? {
            label: 'Remove contract from spam',
            onClick: onUnmarkContractAsSpamClick,
            variant: 'tertiary',
          }
        : {
            label: 'Mark contract as spam',
            onClick: onMarkContractAsSpamClick,
            variant: 'tertiary',
          },
    );

  if (transaction && transaction.isSpam)
    actions.splice(
      4,
      0,
      transaction.isSpam
        ? {
            label: 'Remove from spam',
            onClick: onUnmarkTransactionAsSpamClick,
            variant: 'tertiary',
          }
        : { label: 'Mark as spam', onClick: onMarkTransactionAsSpamClick, variant: 'tertiary' },
    );
  return (
    <>
      <SidebarHeader
        data-cy={dataCy}
        title={transaction?.sequenceNumber}
        loading={isLoading}
        subtitles={[transaction?.createdAt ? `Created ${formatDate(new Date(transaction?.createdAt))}` : '']}
        link={`${getHost()}/ledger/transactions/${transactionId}`}
        status={{
          label: transaction?.transactionDirection,
          type: 'neutral',
          emphasis: 'high',
        }}
        actions={actions}
      />
      <Dialog open={showClassificationModal} onOpenChange={setShowClassificationModal}>
        <ClassifyTransactionModalContent
          transactionIds={[transaction?._id]}
          onOpenChange={setShowClassificationModal}
        />
      </Dialog>
      <Dialog open={showRecategorizationModal} onOpenChange={setShowRecatorizationModal}>
        <UpdateTransactionCategoryModalContent
          transactionIds={[transaction?._id]}
          onOpenChange={setShowRecatorizationModal}
        />
      </Dialog>
    </>
  );
};

export default TransactionSidebarHeader;
