import { useEffect, useState } from 'react';
import { ConditionSelectorMenuTrigger } from '../ConditionSelectorMenuTrigger';
import { ConditionSelectorSearchBar } from '../ConditionSelectorSearchBar';
import { NestConditionRow } from '../NestConditionRow';
import { useConditionSelectorContext } from '../condition-selector-context';
import {
  useChainConditionGroup,
  useIsImpairedConditionGroup,
  useLedgerAccountsConditionGroups,
  useMyAssetConditionGroup,
  IMPAIRMENT_MISC_ROWS,
  MiscConditionSelector,
  useImpairmentMiscConditionsGroup,
} from '../nested-menus';
import { GroupedVirtuoso } from 'react-virtuoso';
import { SearchResultRow } from '../SearchResultRow';
import { ConditionGroupFilterConfig } from '../types';
import { useFilteredConditionGroups } from '../utils';

const useConditionGroupsForImpairmentsMenu = (showNestConditionRow) => {
  const assetConditionGroup = useMyAssetConditionGroup();
  const chainConditionGroup = useChainConditionGroup();
  const isImpairedConditionGroup = useIsImpairedConditionGroup();
  const { conditionGroups: ledgerAccountConditionGroups } = useLedgerAccountsConditionGroups();
  const miscConditionGroup = useImpairmentMiscConditionsGroup(showNestConditionRow);

  const [groups, setGroups] = useState<Omit<ConditionGroupFilterConfig, 'rows'>[]>([]);

  useEffect(() => {
    setGroups([
      assetConditionGroup,
      chainConditionGroup,
      isImpairedConditionGroup,
      ...ledgerAccountConditionGroups,
      miscConditionGroup,
    ]);
  }, [
    assetConditionGroup,
    chainConditionGroup,
    isImpairedConditionGroup,
    miscConditionGroup,
    ledgerAccountConditionGroups,
  ]);

  const groupedConditions = useFilteredConditionGroups(groups);

  return groupedConditions;
};
export const ImpairmentRulesetPrimaryMenu = ({ showNestConditionRow = false }) => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const groupedConditions = useConditionGroupsForImpairmentsMenu(showNestConditionRow);
  // console.log({ groupedConditions });
  return (
    <div>
      <ConditionSelectorSearchBar />
      {debouncedSearchTerm === '' && (
        <div className='h-[294px] overflow-auto'>
          <div className='grid grid-cols-1'>
            {showNestConditionRow && <NestConditionRow />}
            <ConditionSelectorMenuTrigger label={'Assets'} />
            <ConditionSelectorMenuTrigger label={'Chains'} />
            <ConditionSelectorMenuTrigger label={'Is Impaired'} />
            <ConditionSelectorMenuTrigger label={'Ledger Accounts'} />

            {IMPAIRMENT_MISC_ROWS.map((rowData, i) =>
              i === 0 ? null : <MiscConditionSelector conditionData={{ rowData, isSelected: false }} key={rowData} />,
            )}
          </div>
        </div>
      )}
      {debouncedSearchTerm !== '' && (
        <>
          {groupedConditions.headings.length === 0 && <div className='text-center my-2'>No matching results found</div>}
          {groupedConditions.headings.length > 0 && (
            <GroupedVirtuoso
              style={{ height: 250 }}
              groupCounts={groupedConditions.counts}
              itemContent={(index, groupIndex) => (
                <SearchResultRow
                  index={index}
                  groupIndex={groupIndex}
                  groupedConditions={groupedConditions}
                  key={index}
                />
              )}
              groupContent={(groupIndex) => (
                <div className='font-medium text-zinc-500 p-1 bg-white'>{groupedConditions.headings[groupIndex]}</div>
              )}
            />
          )}
        </>
      )}
    </div>
  );
};
