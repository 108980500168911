import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';

type SourcesDataResponse = {
  data: TransactionsBySourcesType;
};

type TotalTransactionsAndValue = { totalTransactions: number; transactionsValue: number };

type TotalTransactionsAndValueByPeriod = {
  day: TotalTransactionsAndValue;
  week: TotalTransactionsAndValue;
  month: TotalTransactionsAndValue;
  year: TotalTransactionsAndValue;
  allTime: TotalTransactionsAndValue;
};

type TransactionsBySourcesType = Record<string, TotalTransactionsAndValueByPeriod>;

// endpoint params
// userIds?: Types.ObjectId[];
// legalEntityIds?: Types.ObjectId[];
// chains?: string[];
// tags?: Types.ObjectId[];

export const getSourcesData = async (data) => {
  const { legalEntityIds, chains, tags } = data;

  const stringQuery = prepareSearchParams({ legalEntityIds, chains, tags });

  return axios.get<SourcesDataResponse>(`${SERVER_URL_ANALYTICS}/get-transactions-by-sources?${stringQuery}`);
};
