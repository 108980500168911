import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { formatDate } from '../templates/utils';
export const LastImpairedOnDateInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        disabled={isDisabled}
        className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
      >
        <div className='mr-2 flex h-full justify-center items-center content-center'>
          {formatDate(new Date(rule.value))}
        </div>
        <ChevronDownIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent className='mr-20 !w-fit' align='start' alignOffset={250} avoidCollisions>
          <Calendar
            className=''
            date={new Date(rule.value)}
            onChange={(date) => {
              callbackFn(new Date(date).getTime());
            }}
          />
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
