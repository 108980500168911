import * as React from 'react';
import { forwardRef } from 'react';
const SvgFilledCheckbox = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <rect x={4} y={4} width={16} height={16} rx={8} fill='#111' />
    <path d='m10.3 15.9-3.733-3.734.716-.716 3.017 3.016 6.4-6.4.717.717L10.3 15.9Z' fill='#fff' stroke='#fff' />
    <rect x={4} y={4} width={16} height={16} rx={8} stroke='#111' strokeWidth={4} />
  </svg>
);
const ForwardRef = forwardRef(SvgFilledCheckbox);
export default ForwardRef;
