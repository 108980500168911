import React from 'react';
import { LoadingComponent } from './LoadingComponent';
export function TableSkeletonLoader({ rows = 10 }) {
  return (
    <div role='status' className='w-full rounded divide-y divide-gray-200 shadow animate-pulse dark:divide-gray-700'>
      {[...Array(rows)].map((_, index) => (
        <div key={index} className='flex justify-between items-center py-4 px-4'>
          <LoadingComponent />
        </div>
      ))}
      <span className='sr-only'>Loading...</span>
    </div>
  );
}
