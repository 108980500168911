import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle } from 'ui';
import { useDirectionFilterContext } from '../../../../context';

export const DirectionsFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useDirectionFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Directions' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((option, i) => (
            <CheckboxListItem
              key={i}
              label={option.value}
              checked={option.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No directions to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
