function isScientificNotation(numberOrString: number | string): boolean {
  const numberString = numberOrString.toString();
  return /^[+-]?\d+(\.\d+)?[eE][+-]?\d+$/.test(numberString);
}

export const formatTableNumbers = ({
  value,
  isAmount = false,
  decimalPlaces = 0,
  hideDecimal = false,
  significantDecimalPlaces = null,
}: {
  value: string | number;
  isAmount?: boolean;
  decimalPlaces?: number;
  hideDecimal?: boolean;
  significantDecimalPlaces?: number | null;
}): string => {
  const currencySymbol = isAmount ? '' : '$';
  let numericValue: number = parseFloat(`${value}`);
  let formattedResult: string = '';

  const formatNumber = (num: number, minFractionDigits: number, maxFractionDigits: number) => {
    return `${currencySymbol}${Math.abs(num).toLocaleString('en-US', {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    })}`;
  };

  if (numericValue < 0) {
    formattedResult = `-${formatNumber(numericValue, hideDecimal ? 0 : 2, hideDecimal ? 0 : 2)}`;
  } else if (numericValue > 0.1) {
    formattedResult = formatNumber(numericValue, hideDecimal ? 0 : 2, hideDecimal ? 0 : 2);
  } else if (numericValue < 0.1 && numericValue !== 0 && numericValue > 0) {
    if (significantDecimalPlaces !== null) {
      const numString = numericValue.toPrecision(significantDecimalPlaces);
      formattedResult = `${currencySymbol}${parseFloat(numString)}`;
    } else {
      const numericValueString = numericValue.toString().slice(1).split('');
      let digit = '';
      let checkDigitGreaterThanZero = false;

      for (let index = 0; index < numericValueString.length; index++) {
        digit += numericValueString[index];

        if (Number(numericValueString[index]) > 0) {
          checkDigitGreaterThanZero = true;
        }

        if ((checkDigitGreaterThanZero && decimalPlaces <= index) || index === numericValueString.length - 1) {
          formattedResult = `${currencySymbol}0${digit}`;
          break;
        }
      }
    }
  } else {
    formattedResult = formatNumber(numericValue, hideDecimal ? 0 : 2, hideDecimal ? 0 : 2);
  }

  return formattedResult;
};



export const getTransactionChain = (assetType: string, chain: string, istransactionFromKraken: boolean) => {
  if (istransactionFromKraken) return 'KRAKEN';
  if (assetType === 'USD') return 'USD';
  return chain;
};
