import React, { useEffect, useMemo, useState } from 'react';
import MetricItem from './MetricItem';
import {
  useAllLegalEntities,
  useComputeRuleMetrics,
  useDeleteOneTimeRuleMetrics,
  useGetRuleMetricsQuery,
} from '../../../hooks/http';
import { deriveError, millisecondsToTime } from '../../templates/utils';
import { Button, CheckboxListItem, InputLabel, LoaderIcon, MenuOptionData, MultiSelectMenuV2, classNames } from 'ui';
import { toast } from 'react-hot-toast';
import { JOB_NAME, JOB_STATUS } from 'services/http';
import * as Progress from '@radix-ui/react-progress';
import { ViewMatches } from './ViewMatches';
import { useLatestJobConfiguration } from '../../job-progress';
import { AccountingPeriodStartDateOption, AccountingPeriodStartDatesMultiSelectMenu } from 'src/components/menu';

const HitRateMetricItem = ({ value }) => (
  <MetricItem value={value} label='Hit rate' formatInPercentage={value !== '-'} />
);
const MatchesMetricItem = ({ value }) => <MetricItem value={value} label='Matches' />;
const RuntimeMetricItem = ({ value }) => <MetricItem value={value} label='Runtime' />;

const getLabelFromSelectionsFn = (selectedPeriods: MenuOptionData[]) => {
  if (selectedPeriods.length === 0) return 'Select entities';
  if (selectedPeriods.length === 1) return selectedPeriods[0].label;
  return `${selectedPeriods[0].label} + ${selectedPeriods.length - 1}`;
};

function RuleMetrics({ organizationId, ruleId, topLevelCondition, accountPostingRuleSet }) {
  const { legalEntities, isLoading: isLoadingLegalEntities } = useAllLegalEntities();
  const [selectedLegalEntityOptions, setSelectedLegalEntityOptions] = useState<MenuOptionData[]>([]);
  const [selectedPeriodOptions, setSelectedPeriodOptions] = useState<AccountingPeriodStartDateOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [progressPercent, setProgressPercent] = useState(0);
  const { jobConfiguration, clearJobConfiguration } = useLatestJobConfiguration(ruleId, JOB_NAME.RULE_METRICS_JOB);

  const [needUpdatesFromApi, setNeedUpdatesFromApi] = useState(false);

  const legalEntityOptions = useMemo(
    () =>
      accountPostingRuleSet?.legalEntities?.map((entity) => ({
        label: `${entity.entityName}${entity.entityType && `, ${entity.entityType}`}`,
        value: entity._id,
      })) ||
      legalEntities?.map((entity) => ({
        label: `${entity.entityName}${entity.entityType && `, ${entity.entityType}`}`,
        value: entity._id,
      })) ||
      [],
    [legalEntities, accountPostingRuleSet],
  );

  const { data: metricsFromServer, refetch } = useGetRuleMetricsQuery({
    ruleId,
    enabled: false,
  });

  const [metrics, setMetrics] = useState<any>({
    hitRate: '-',
    matchCount: '-',
    executionTime: '-',
    totalTransactions: 0,
    matches: [],
  });

  useEffect(() => {
    if (jobConfiguration?.jobStatus === JOB_STATUS.COMPLETED) {
      setNeedUpdatesFromApi(false);
      refetch();
    }
  }, [jobConfiguration]);

  useEffect(() => {
    if (needUpdatesFromApi) {
      const interval = setInterval(() => {
        refetch();
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [needUpdatesFromApi]);

  useEffect(() => {
    if (metricsFromServer) {
      setMetrics(metricsFromServer);
    }
  }, [metricsFromServer]);

  const { mutateAsync: deleteOneTimeRuleMetrics, isLoading: isLoadingDeleteOneTimeRuleMetrics } =
    useDeleteOneTimeRuleMetrics();

  const { mutateAsync: computeRuleMetrics, isLoading: isLoadingStartRuleMetricsTest } = useComputeRuleMetrics();

  const postRequestsLoading = isLoadingDeleteOneTimeRuleMetrics || isLoadingStartRuleMetricsTest;

  const startTest = async () => {
    try {
      setMetrics({
        hitRate: '-',
        matchCount: '-',
        executionTime: '-',
        totalTransactions: 0,
        matches: [],
      });
      setLoading(true);
      setNeedUpdatesFromApi(true);
      clearJobConfiguration();
      await deleteOneTimeRuleMetrics({
        ruleId,
        organizationId,
      });

      await computeRuleMetrics({
        ruleId,
        legalEntityIds: selectedLegalEntityOptions.map((option) => option.value),
        accountingPeriodStartDateUTCs: selectedPeriodOptions.map((period) =>
          new Date(period.startDateUTC).toISOString(),
        ),
        isOneTime: true,
        topLevelCondition,
      });
    } catch (error: any) {
      // console.error(error);
      if (error.response?.status === 500) {
        toast.error('Something went wrong while creating the job');
        setError(deriveError(error));
      } else toast.error(deriveError(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (jobConfiguration?.toProcessCount)
      setProgressPercent(Math.round((100 * (jobConfiguration.completedCount ?? 0)) / jobConfiguration.toProcessCount));
    else setProgressPercent(0);
  }, [jobConfiguration]);

  let jobStatus = <></>;
  if (postRequestsLoading) {
    jobStatus = (
      <div className='grid grid-cols-[max-content_max-content] gap-x-4 justify-center items-center'>
        <div>Job Queued</div>
        <div>
          <LoaderIcon />
        </div>
      </div>
    );
  } else if (jobConfiguration?.jobStatus === 'COMPLETED') {
    jobStatus = <>Job Completed</>;
  } else {
    jobStatus = (
      <div className='grid grid-cols-[max-content_max-content] gap-x-4 justify-center items-center'>
        <div>Job in progress {metrics?.totalTransactions ?? '-'} transactions</div>
      </div>
    );
  }

  return (
    <>
      <div>
        <InputLabel heading='Entities' />
        <MultiSelectMenuV2
          onSelectAll={() => setSelectedLegalEntityOptions(legalEntityOptions)}
          onDeselectAll={() => setSelectedLegalEntityOptions([])}
          allOptionsSelected={selectedLegalEntityOptions.length === legalEntityOptions.length}
          hasSelections={selectedLegalEntityOptions.length > 0}
          isLoading={isLoadingLegalEntities && !accountPostingRuleSet}
          label={getLabelFromSelectionsFn(selectedLegalEntityOptions)}
        >
          {legalEntityOptions.map((option, i) => (
            <CheckboxListItem
              key={i}
              enableSelectOnly={false}
              label={option.label}
              checked={!!selectedLegalEntityOptions.find((o) => o.value === option.value)}
              onCheckedChange={(checked) =>
                setSelectedLegalEntityOptions((prev) =>
                  checked ? [...prev, option] : prev.filter((o) => o.value !== option.value),
                )
              }
            />
          ))}
        </MultiSelectMenuV2>
      </div>
      <div>
        <InputLabel heading='Accounting Periods' />
        <AccountingPeriodStartDatesMultiSelectMenu
          selectedPeriods={selectedPeriodOptions}
          setSelectedPeriods={setSelectedPeriodOptions}
          legalEntityIds={accountPostingRuleSet?.legalEntities.map((entity) => entity._id)}
        />
      </div>
      <div className='flex flex-col gap-4 py-8'>
        <div className='border rounded-lg flex flex-col justify-between'>
          <div className={classNames('py-6 px-10', 'relative')}>
            <div className='w-full  overflow-clip grid content-center '>
              <Progress.Root
                className='w-75 rounded-full overflow-hidden relative h-6 bg-gradient-to-r from-blue-600 to-purple-900'
                value={progressPercent}
              >
                <Progress.Indicator
                  className='bg-gradient-to-r from-blue-900 to-blue-800 w-full h-full transition-colors'
                  style={{ transform: `translateX(-${100 - progressPercent}%)` }}
                />
                {jobConfiguration?.jobStatus !== 'COMPLETED' && (
                  <div className='absolute inset-0 flex items-center justify-center'>
                    <span className='text-white font-semibold'>{progressPercent}%</span>
                  </div>
                )}
              </Progress.Root>
              <div className='mt-2'>
                <MetricItem label={jobStatus} value='' />
              </div>
            </div>
          </div>
          <div className='flex w-full px-8 justify-between items-center border-y py-4'>
            <HitRateMetricItem value={metrics?.hitRate ?? '-'} />
            <MatchesMetricItem value={metrics?.matchCount ?? '-'} />
            <RuntimeMetricItem value={metrics?.executionTime ? millisecondsToTime(metrics.executionTime) : '-'} />
          </div>
          {metrics?.matches?.length > 0 && <ViewMatches matches={metrics?.matches} />}
          {error && <div className='border-red p-2 rounded-lg'>{error}</div>}
        </div>

        <div>
          <Button
            label='Start test'
            emphasis='medium'
            className={`place-self-end`}
            onClick={startTest}
            disabled={loading}
          />
        </div>
      </div>
    </>
  );
}

export default RuleMetrics;
