import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { deriveError } from '../../templates/utils';
import {
  useAddExternalSource,
  useInvalidateQuery,
  useMarkTransactionsAsSpam,
  useSession,
  useMarkContractsAsSpam,
  useMarkContractsAsNotSpam,
} from '../../../hooks';
import { ClassifyTransactionModal } from './classify-transaction-modal';
import { UpdateTransactionCategoryModal } from './update-transaction-category-modal';
import { TABLE_TYPE, useTableActionBarContext } from '../../../context';
import { UpdateTransactionCostBasisModal } from './update-transaction-cost-basis-modal';
import { DropDownItem, DropDownMenu } from '../../atoms/DropDownMenu';
import { BulkUpdateTransactionMemoModalContent } from './update-memo-modal';
import { Dialog } from 'ui';

export const prepareSpamTokensFromTransactions = (transactions) =>
  transactions.map((tx) => ({ assetType: tx.assetType, chain: tx.chain, rawContractAddress: tx.rawContractAddress }));

export const TransactionsMultiSelectActionsComponent = ({ selectedRows, table }) => {
  const { getPropsForTableType } = useTableActionBarContext();
  const { toggleAllRowsSelected } = getPropsForTableType(TABLE_TYPE.ALL_TRANSACTIONS);

  const { mutateAsync: markTransactionsAsSpam, isLoading: isMarkingTransactionsAsSpam } = useMarkTransactionsAsSpam();
  const { mutateAsync: markTransactionsAsNotSpam, isLoading: isMarkingTransactionsAsNotSpam } =
    useMarkTransactionsAsSpam();
  const { mutateAsync: markContractsAsSpam, isLoading: isMarkingContractsAsSpam } = useMarkContractsAsSpam();
  const { mutateAsync: markContractsAsNotSpam, isLoading: isMarkingContractsAsNotSpam } = useMarkContractsAsNotSpam();
  const selectedTxs = selectedRows.map((row) => row?.original).filter(Boolean);
  const txMarkedWithSpamToken = selectedTxs.filter((tx) => tx.spamTokenId);
  const txNotMarkedWithSpamToken = selectedTxs.filter((tx) => !tx.spamTokenId && tx.rawContractAddress);
  const transactionsWithContracts = selectedTxs.filter(
    (tx) => tx.rawContractAddress && tx.rawContractAddress.length > 0,
  );

  const spamTransactions = selectedTxs.filter((tx) => tx.isSpam);
  const nonSpamTransactions = selectedTxs.filter((tx) => !tx.isSpam);
  const { mutateAsync: patchExternalSource, isLoading: isLoadingExternal } = useAddExternalSource();
  const { invalidateWallets } = useInvalidateQuery();
  const { organizationId, userId } = useSession();

  const [showTransactionMemoModal, setShowTransactionMemoModal] = useState(false);

  const handleBulkAddExternalSources = async () => {
    try {
      for (let i = 0; i < selectedTxs.length; i++) {
        await patchExternalSource(
          {
            userId: userId,
            organizationId: organizationId,
            transactionIds: [selectedTxs[i]._id],
            body: {
              chain: selectedTxs[i].chain,
            },
          },
          {
            onSuccess: () => {
              invalidateWallets();
              toggleAllRowsSelected(false);
            },
          },
        );
      }
      table.resetRowSelection();
      toast.success('External source added successfully');
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };

  const handleMarkContractAsSpam = async () => {
    try {
      await markContractsAsSpam({
        spamTokens: prepareSpamTokensFromTransactions(transactionsWithContracts),
      });
      toast.success('Contracts marked as spam successfully');
      toggleAllRowsSelected(false);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const handleMarkContractAsNotSpam = async () => {
    try {
      await markContractsAsNotSpam(txMarkedWithSpamToken.map((tx) => tx.spamTokenId));
      toast.success('Contracts marked as not spam successfully');
      toggleAllRowsSelected(false);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const handleMarkAsSpam = async () => {
    try {
      await markTransactionsAsSpam({
        transactionIds: nonSpamTransactions.map((tx) => tx._id),
      });
      toast.success('Transactions marked as spam successfully');
      toggleAllRowsSelected(false);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  const handleMarkAsNotSpam = async () => {
    try {
      await markTransactionsAsNotSpam({
        transactionIds: spamTransactions.map((tx) => tx._id),
      });
      toast.success('Transactions no longer marked as spam');
      toggleAllRowsSelected(false);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  return (
    <div className='flex ml-auto gap-4'>
      <ClassifyTransactionModal
        onSuccess={() => toggleAllRowsSelected(false)}
        transactionIds={selectedTxs.map((tx) => tx._id)}
        triggerProps={{ buttonClassName: 'm-0', containerClassName: 'm-0' }}
      />
      <UpdateTransactionCategoryModal
        onSuccess={() => toggleAllRowsSelected(false)}
        transactionIds={selectedTxs.map((tx) => tx._id)}
        triggerProps={{
          emphasis: 'medium',
          buttonClassName: 'm-0',
          containerClassName: 'm-0',
        }}
      />
      <UpdateTransactionCostBasisModal
        onSuccess={() => toggleAllRowsSelected(false)}
        transactionIds={selectedTxs.map((tx) => tx._id)}
        triggerProps={{
          emphasis: 'medium',
          buttonClassName: 'm-0',
          containerClassName: 'm-0',
        }}
      />
      <DropDownMenu dropdownContainerClassname='w-56'>
        <DropDownItem onClick={handleBulkAddExternalSources} disabled={isLoadingExternal}>
          Add external sources
        </DropDownItem>
        {txNotMarkedWithSpamToken.length > 0 && (
          <DropDownItem onClick={handleMarkContractAsSpam} disabled={isMarkingContractsAsSpam}>
            Mark contracts as spam
          </DropDownItem>
        )}
        {txMarkedWithSpamToken.length > 0 && (
          <DropDownItem onClick={handleMarkContractAsNotSpam} disabled={isMarkingContractsAsNotSpam}>
            Remove contracts from spam
          </DropDownItem>
        )}
        {nonSpamTransactions.length > 0 && (
          <DropDownItem onClick={handleMarkAsSpam} disabled={isMarkingTransactionsAsSpam}>
            Mark as spam
          </DropDownItem>
        )}
        {spamTransactions.length > 0 && (
          <DropDownItem onClick={handleMarkAsNotSpam} disabled={isMarkingTransactionsAsNotSpam}>
            Remove from spam
          </DropDownItem>
        )}
        {selectedTxs.length > 0 && (
          <DropDownItem onClick={() => setShowTransactionMemoModal(true)}>Update memo</DropDownItem>
        )}

        <Dialog open={showTransactionMemoModal} onOpenChange={setShowTransactionMemoModal}>
          <BulkUpdateTransactionMemoModalContent
            transactionIds={selectedTxs.map((tx) => tx._id)}
            onSuccess={() => toggleAllRowsSelected(false)}
            onOpenChange={setShowTransactionMemoModal}
          />
        </Dialog>
      </DropDownMenu>
    </div>
  );
};

export default TransactionsMultiSelectActionsComponent;
