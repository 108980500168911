import React from 'react';
import { BiSolidCheckCircle } from 'react-icons/bi';
export const ProgressItem = ({ title, completed = false }) => {
  return (
    <div
      className={`${
        completed && 'line-through !text-black'
      } flex items-center py-5 px-0 w-full text-[#0000ff] last:border-none last:pb-0 border-b`}
    >
      {completed && (
        <div className='mr-4'>
          <BiSolidCheckCircle className='w-4.5 h-4.5' />
        </div>
      )}
      <div className={`text-xl font-medium duration-300 cursor-pointer ${!completed && 'hover:opacity-80'}`}>
        {title}
      </div>
    </div>
  );
};

export default ProgressItem;
