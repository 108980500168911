import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

export const useSearch = (debounceDelay = 500) => {
  const [searchTerm, setSearchTerm] = useDebounceValue('', debounceDelay);
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    setIsSearching(true);
  }, [searchTerm]);

  return { searchTerm, debouncedSearchTerm: searchTerm, isSearching, setIsSearching, setSearchTerm };
};
