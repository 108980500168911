import React, { useState } from 'react';
import { Button, Modal, ModalContent, ModalTrigger } from 'ui';

export const RemovePaymentMethodConfirmationModal = ({ onRemove }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Remove' status='danger' emphasis='low' onClick={() => setOpen(true)} />
      </ModalTrigger>
      <ModalContent
        title='Removing payment method'
        description='Are you sure you want to remove this payment method?'
        actions={[
          { label: 'Remove', status: 'danger', onClick: onRemove },
          { label: 'Cancel', emphasis: 'medium', onClick: () => setOpen(false) },
        ]}
      ></ModalContent>
    </Modal>
  );
};
