import React from 'react';
import { classNames } from 'ui';

export const Card = ({ children, className = '' }) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-start py-5 px-6 gap-6 w-full relative border border-[#c9c9c9] bg-white rounded-lg shadow-[rgba(16,24,40,0.05)_0px_1px_2px_0px]',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Card;
