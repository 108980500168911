import * as React from 'react';
import { forwardRef } from 'react';
const SvgFileAttachment = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M2 0C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6.83c0-.53-.21-1.04-.59-1.41L10.58.59C10.21.21 9.7 0 9.17 0H2Zm7 6V1.5L14.5 7H10c-.55 0-1-.45-1-1Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFileAttachment);
export default ForwardRef;
