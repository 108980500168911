import React from 'react';
import { SelectableCard } from 'ui';

export enum CREATE_MODE {
  AUTOMATIC = 'Automatic',
  MANUAL = 'Manual',
}

export const CreateModeTabs = ({ value, onChange }: { value: CREATE_MODE; onChange: (value: CREATE_MODE) => void }) => {
  return (
    <div className='grid grid-cols-2 gap-4 my-4 w-full'>
      <SelectableCard
        label={CREATE_MODE.AUTOMATIC}
        selected={value === CREATE_MODE.AUTOMATIC}
        onClick={() => onChange(CREATE_MODE.AUTOMATIC)}
      />
      <SelectableCard
        label={CREATE_MODE.MANUAL}
        selected={value === CREATE_MODE.MANUAL}
        onClick={() => onChange(CREATE_MODE.MANUAL)}
      />
    </div>
  );
};
