import React, { useEffect, useState } from 'react';
import { useGetJournalEntryLinesQuery, useGetLedgerAccountById } from '../../../../hooks';
import { DetailsItem, Sidebar, SidebarBody, SidebarTopBar } from '../../../atoms';
import SidebarHeader from '../../../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { getHost } from '../../../../lib/utils';
import { SidebarSection, SidebarSectionHeader } from '../../../atoms/Sidebar/SidebarBody';

import { formatDate } from '../../../templates/utils';
import { useBalanceDrilldownRows } from './use-balance-drilldown-rows';
import { JournalLinesTable } from './JournalLinesTable';
// import { ACCOUNTING_PERIOD_STATUS } from 'services/http/response.types';
import { useSearchTerm } from '../../../../context/filter-context/use-search-term';
import { SortingState } from '@tanstack/react-table';
export type BalanceOverviewSidebarProps = {
  isDockPanelAvailable: boolean;
  onBack: () => void;
  handlePrimaryPanelClose: () => void;
  isPrimary: boolean;
  ledgerAccountId: string;
  ledgerAccountIds: string[];
  legalEntityIds?: string[];
  startingBalance: string;
  currentBalance: string;
  endingBalance: string;
  accountingPeriodName: string;
  // accountingPeriodStatus: string;
  accountingPeriodStartDateUTC: string;
};
export const BalanceOverviewSidebar = ({
  isDockPanelAvailable,
  onBack,
  handlePrimaryPanelClose,
  isPrimary,
  ledgerAccountId,
  ledgerAccountIds,
  legalEntityIds = [],
  startingBalance,
  currentBalance,
  endingBalance,
  accountingPeriodName,
  // accountingPeriodStatus,
  accountingPeriodStartDateUTC,
}: BalanceOverviewSidebarProps) => {
  const { data: ledgerAccountResponse, isLoading: isLoadingLedgerAccount } = useGetLedgerAccountById(ledgerAccountId);
  const { searchTerm, isSearching, setIsSearching, setSearchTerm } = useSearchTerm('balanceDrillDown');
  const [sortState, setSortState] = useState<SortingState>([]);
  const filter = {
    accountingPeriodStartDateUTC: accountingPeriodStartDateUTC
      ? new Date(accountingPeriodStartDateUTC).toISOString()
      : undefined,
    legalEntityIds,
    ledgerAccountIds: ledgerAccountId ? [ledgerAccountId] : [],
    searchTerm,
    sort: sortState ? sortState[0] : undefined,
  };
  console.log({ filter });
  const {
    data: journalEntryLinePages,
    isLoading: isLoadingJournalEntries,
    isFetchingNextPage,
    isFetching,
    fetchNextPage,
  } = useGetJournalEntryLinesQuery(filter);
  const rows = useBalanceDrilldownRows(journalEntryLinePages);

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);

  return (
    <Sidebar>
      <SidebarTopBar
        onClose={handlePrimaryPanelClose}
        isDockPanelAvailable={isDockPanelAvailable}
        onBack={onBack}
        itemId={ledgerAccountId}
        isPrimary={isPrimary}
      />
      <SidebarHeader
        title={`${ledgerAccountResponse?.ledgerAccount?.ledgerAccountSequence}:${ledgerAccountResponse?.ledgerAccount?.ledgerAccountName}`}
        link={`${getHost()}/ledger/impairment-rules/${ledgerAccountId}`}
        loading={isLoadingLedgerAccount}
      />
      <SidebarBody>
        <SidebarSectionHeader title='Details' />
        <SidebarSection numberOfColumns={2} loading={isLoadingLedgerAccount}>
          <DetailsItem tag='Accounting period' value={accountingPeriodName} />
          {/* TODO ADD THIS BACK <DetailsItem tag='Accounting status' value={accountingPeriodStatus} /> */}
          <DetailsItem tag='Starting balance' value={startingBalance} />
          <DetailsItem tag='Current balance' value={currentBalance} />
          <DetailsItem tag='Ending balance' value={endingBalance} />

          {/* <DetailsItem
            tag={accountingPeriodStatus === ACCOUNTING_PERIOD_STATUS.OPEN ? 'Current balance' : 'Ending balance'}
            value={accountingPeriodStatus === ACCOUNTING_PERIOD_STATUS.OPEN ? currentBalance : endingBalance}
          /> */}
          <DetailsItem
            tag='Last updated'
            value={`
                ${
                  journalEntryLinePages?.pages[0].journalEntryLines[0]?.user.email
                    ? journalEntryLinePages.pages[0].journalEntryLines[0].user.email
                    : ''
                }\n
                ${
                  journalEntryLinePages?.pages[0].journalEntryLines[0]?.accountingDate
                    ? formatDate(new Date(journalEntryLinePages.pages[0].journalEntryLines[0].accountingDate))
                    : ''
                }`}
          />
        </SidebarSection>
        <SidebarSectionHeader title='Journal lines' />
        <SidebarSection numberOfColumns={1}>
          <JournalLinesTable
            isLoading={isLoadingJournalEntries}
            isFetchingNextPage={isFetchingNextPage}
            displayedJournalEntryLines={rows}
            fetchNextPage={fetchNextPage}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isSearching={isSearching}
            accountingPeriodStartDateUTC={accountingPeriodStartDateUTC}
            legalEntityIds={legalEntityIds}
            ledgerAccountIds={ledgerAccountIds}
            onSortingChange={setSortState}
          />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};
