import * as React from 'react';
import { forwardRef } from 'react';
const SvgEditIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M7.675 18v-5.625h1.5v2.075H18v1.5H9.175V18h-1.5ZM0 15.95v-1.5h6.175v1.5H0Zm4.675-4.15V9.75H0v-1.5h4.675v-2.1h1.5v5.65h-1.5Zm3-2.05v-1.5H18v1.5H7.675Zm4.15-4.125V0h1.5v2.05H18v1.5h-4.675v2.075h-1.5ZM0 3.55v-1.5h10.325v1.5H0Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEditIcon);
export default ForwardRef;
