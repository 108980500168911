import { createContext, useState } from 'react';

export const MainDirectoryContext = createContext<{
  mainDirectoryIds: string[];
  setMainDirectoryIds: (transactionIds: string[]) => void;
}>({
  mainDirectoryIds: [],
  setMainDirectoryIds: () => {
    null;
  },
});

export const MainDirectoryContextProvider = ({ children }) => {
  const [mainDirectoryIds, setMainDirectoryIds] = useState<string[]>([]);

  return (
    <MainDirectoryContext.Provider
      value={{
        mainDirectoryIds,
        setMainDirectoryIds,
      }}
    >
      {children}
    </MainDirectoryContext.Provider>
  );
};
