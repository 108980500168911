import { createContext, useContext } from 'react';

const ConditionIdContext = createContext({ conditionId: '' });
export const ConditionIdProvider = ({ conditionId, children }) => (
  <ConditionIdContext.Provider value={{ conditionId }}>{children}</ConditionIdContext.Provider>
);
export const useConditionId = () => {
  const { conditionId } = useContext(ConditionIdContext);
  return conditionId;
};
