import React, { useEffect, useState } from 'react';
import {
  Button,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  Dialog,
  DialogContent,
  DialogTrigger,
  InputLabel,
  SimpleMenu,
  getMenuOptionsFrom,
} from 'ui';
import { Tooltip } from '../atoms';
import {
  useAccountPostingRuleSets,
  useBulkMoveAccountPostingRules,
  useGetAccountPostingRuleSetById,
  useInvalidateQuery,
} from '../../hooks';
import { deriveError } from '../templates/utils';
import { toast } from 'react-hot-toast';

export const MoveRulesModal = ({ sourceRulesetId, selectedRuleIds, open, onOpenChange, showTrigger = true }) => {
  const { data: sourceRuleset } = useGetAccountPostingRuleSetById({ accountPostingRuleSetId: sourceRulesetId });

  const {
    mutateAsync: move,
    isLoading: isMovingRules,
    isError: couldNotMoveRules,
    isSuccess: rulesMovedSucessfully,
    error: moveRulesError,
  } = useBulkMoveAccountPostingRules();

  const { invalidateAccountPostingRulesets } = useInvalidateQuery();
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();
  const { data, isLoading } = useAccountPostingRuleSets({
    pageSize: 1000,
  });

  useEffect(() => {
    if (data) {
      const accountPostingRuleSets = data.pages
        .map((page) => page.accountPostingRuleSets)
        .flat()
        .filter((r) => r._id !== sourceRulesetId);
      setOptions(getMenuOptionsFrom(accountPostingRuleSets, ['name', '_id']));
      setSelectedOption((prev) => (prev?.value !== sourceRulesetId ? prev : undefined));
    }
  }, [data]);

  const onMove = async () => {
    if (isMovingRules) return;
    try {
      if (!selectedOption?.value) throw new Error('Please select a ruleset');
      const ruleset = selectedOption.value;
      const rules = selectedRuleIds;

      await move({ ruleset, rules });

      await invalidateAccountPostingRulesets();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && (
        <DialogTrigger asChild>
          <Tooltip
            content={`Move ${selectedRuleIds.length} ${selectedRuleIds.length === 1 ? 'rule' : 'rules'} to rulesets`}
          >
            <Button onClick={() => onOpenChange(true)} label={`Move `} variant='sm' />
          </Tooltip>
        </DialogTrigger>
      )}

      {isMovingRules && (
        <DialogContent onClose={() => onOpenChange(false)}>
          <DescriptiveLoader
            status={DESCRIPTIVE_LOADER_STATES.LOADING}
            title='In progress'
            description={`Moving selected ${selectedRuleIds.length === 1 ? 'rule' : 'rules'}`}
          />
        </DialogContent>
      )}
      {couldNotMoveRules && (
        <DialogContent onClose={() => onOpenChange(false)}>
          <DescriptiveLoader
            status={DESCRIPTIVE_LOADER_STATES.ERROR}
            title='Failure'
            description={(moveRulesError as any).message}
          />
        </DialogContent>
      )}
      {rulesMovedSucessfully && (
        <DialogContent onClose={() => onOpenChange(false)}>
          <DescriptiveLoader
            status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
            title='Success'
            description={`${selectedRuleIds.length} ${
              selectedRuleIds.length === 1 ? 'rule' : 'rules'
            } moved successfully`}
          />
        </DialogContent>
      )}
      {!isMovingRules && !couldNotMoveRules && !rulesMovedSucessfully && (
        <DialogContent
          title={'Copying rules'}
          onClose={() => onOpenChange(false)}
          description={
            <span className='mb-4'>
              Copying{' '}
              <span className='font-semibold'>
                {selectedRuleIds.length} {selectedRuleIds.length === 1 ? 'rule' : 'rules'}
              </span>{' '}
              from <span className='font-semibold'>{sourceRuleset?.name}</span>
            </span>
          }
          actions={[
            {
              label: 'Confirm',
              onClick: onMove,
              disabled: isMovingRules || !selectedOption,
              isLoading: isMovingRules,
            },
            {
              label: 'Cancel',
              emphasis: 'medium',
              onClick: () => {
                onOpenChange(false);
                setSelectedOption(undefined);
              },
            },
          ]}
        >
          <div>
            <InputLabel heading='Rulesets' />
            <SimpleMenu
              options={options}
              onChange={(option) => setSelectedOption(option)}
              value={selectedOption}
              isLoading={isLoading}
            />
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
