import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTrigger,
  DropdownFooter,
  DropdownHeader,
  RadioListItem,
  capitalizeFirstLetter,
  classNames,
  getFilterDropdownSectionDataCy,
} from 'ui';
import { FILTER_TYPE } from '../types';
import { MdArrowDropDown } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { useAccountingTreatmentFilterContext } from '../../../../context';

const DEFAULT_TRIGGER_LABEL = 'Has Accounting';
export const AccountingTreatmentFilterDropdown = ({ 'data-cy': dataCy }: { 'data-cy'?: string }) => {
  const {
    state,
    isLoading,
    setters: { onlySelect, deselectAll },
  } = useAccountingTreatmentFilterContext();
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const [triggerLabel, setTriggerLabel] = useState(DEFAULT_TRIGGER_LABEL);
  const [isHoveringClearAllTrailingIcon, setIsHoveringClearAllTrailingIcon] = useState(false);
  const [isHoveringTrigger, setIsHoveringTrigger] = useState(false);

  useEffect(() => {
    setTriggerLabel(() => {
      const selectedValues = state.filter((value) => value.selected);
      if (selectedValues.length === 0) return DEFAULT_TRIGGER_LABEL;
      if (selectedValues.length === 1) return `${DEFAULT_TRIGGER_LABEL}: ${selectedValues[0].value}`;
      return `${selectedValues[0].value} +${selectedValues.length - 1}`;
    });
  }, [state]);

  const accountingTreatmentFilterDataCy = useMemo(() => `${dataCy}_${FILTER_TYPE.ACCOUNTING_TREATMENT}`, [dataCy]);

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <span onMouseEnter={() => setIsHoveringTrigger(true)} onMouseLeave={() => setIsHoveringTrigger(false)}>
          <Button
            data-cy={getFilterDropdownSectionDataCy(accountingTreatmentFilterDataCy).trigger}
            isFocused={open}
            isLoading={isLoading}
            onClick={() => setOpen((prev) => !prev)}
            label={
              triggerLabel === DEFAULT_TRIGGER_LABEL ? (
                triggerLabel
              ) : (
                <span className='text-indigo-600'>{capitalizeFirstLetter(triggerLabel?.toLowerCase())}</span>
              )
            }
            className={classNames(
              'duration-100 py-0',

              triggerLabel !== DEFAULT_TRIGGER_LABEL &&
                isHoveringTrigger &&
                !isHoveringClearAllTrailingIcon &&
                'shadow',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'bg-indigo-50 border-indigo-600',
            )}
            labelContainerClassname={classNames(
              'font-medium ',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'text-indigo-600',
            )}
            emphasis='medium'
            trailingIconContainerClassname={classNames(
              triggerLabel !== DEFAULT_TRIGGER_LABEL && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
              triggerLabel !== DEFAULT_TRIGGER_LABEL && isHoveringTrigger && 'bg-indigo-50',
            )}
            trailingIcon={
              <div className='flex items-center'>
                {triggerLabel !== DEFAULT_TRIGGER_LABEL && (
                  <span
                    onMouseEnter={() => setIsHoveringClearAllTrailingIcon(true)}
                    onMouseLeave={() => setIsHoveringClearAllTrailingIcon(false)}
                    onClick={(e) => {
                      e.stopPropagation();
                      deselectAll();
                    }}
                  >
                    <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                  </span>
                )}

                <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
              </div>
            }
          />
        </span>
      </DropdownTrigger>
      <DropdownContent triggerRef={triggerRef}>
        <DropdownHeader
          title='Accounting'
          data-cy={getFilterDropdownSectionDataCy(accountingTreatmentFilterDataCy).header}
        />
        <DropdownBody className='my-2 px-2'>
          {state.length === 0 && <div className='p-4 text-center'>No modes to show</div>}
          {state.length !== 0 &&
            state.map((value, i) => (
              <RadioListItem
                key={i}
                label={value.value}
                checked={value.selected}
                onCheckedChange={(selected: boolean) => {
                  if (selected) onlySelect(i);
                }}
              />
            ))}
        </DropdownBody>
        <DropdownFooter>
          <Button
            data-cy={getFilterDropdownSectionDataCy(accountingTreatmentFilterDataCy).clearAllButton}
            onClick={state.length !== 0 ? deselectAll : undefined}
            emphasis='low'
            label='Clear'
            labelContainerClassname='text-zinc-500'
          />
        </DropdownFooter>
      </DropdownContent>
    </Dropdown>
  );
};
