import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserById, patchUser } from 'services/http/core';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useGetUserById = (userId, organizationId) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.USER, { userId }),
    async () => {
      const response = await getUserById({ userId, organizationId });
      return response.data?.user ?? null;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const usePatchUser = () => useMutation((data) => patchUser(data));
