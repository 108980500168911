import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';

export const FetchNextPage = ({ fetchNextPage, hasNextPage = true, isFetchingNextPage, data, containerRef }: any) => {
  const { ref, inView } = useInView({
    root: containerRef?.current || null
  });

  useEffect(() => {
    if (inView) {
      hasNextPage && fetchNextPage && fetchNextPage();
    }
  }, [inView]);

  if (data?.length === 0) return null;

  return (
      <div className="w-full" ref={ref} >
      {isFetchingNextPage && (
        <div className='mb-10'>
          <div className='w-full flex justify-center items-center my-8 fixed bottom-6 right-0 left-0 top-[unset]'>
            <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
          </div>
        </div>
      )}
      </div>
  );
};
