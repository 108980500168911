import { useEffect, useState } from 'react';
import { formatDollars, mergeJournalEntries } from '../../templates/utils';
import { dateConverter } from '../../utils';
import { DateProps } from '../../utils/types';
import { DisplayedJournalEntry, PopulatedJournalEntry } from './type';
import { JournalEntryDB } from 'schemas';

export const useDisplayedJEs = (jeIQData?: { pages?: { journalEntryModels: JournalEntryDB[] }[] }) => {
  const [displayedJEs, setDisplayedJEs] = useState<DisplayedJournalEntry[]>([]);

  useEffect(() => {
    if (jeIQData)
      setDisplayedJEs(
        mergeJournalEntries(jeIQData).map((je: PopulatedJournalEntry) => {
          const getLinesByType = (type: string) =>
            je.journalEntryLineIds.filter((item: { creditOrDebit: 'CREDIT' | 'DEBIT' }) => item.creditOrDebit === type);
          const debitLines = getLinesByType('DEBIT');
          const creditLines = getLinesByType('CREDIT');
          const sumDebits = debitLines.reduce(
            (sum: number, line: any) => sum + parseFloat(line.amount.$numberDecimal),
            0,
          );
          const sumCredits = creditLines.reduce(
            (sum: number, line: any) => sum + parseFloat(line.amount.$numberDecimal),
            0,
          );
          const journalId = {
            title: je.journalSequenceNumber || 'Unknown',
            desc: dateConverter(je.accountingDate),
          };
          const created = {
            title: je.originatedBy,
            desc: dateConverter(je.createdAt as DateProps),
          };
          return {
            ...je,
            journalId,
            created,
            debits: formatDollars(sumDebits, true),
            credits: formatDollars(sumCredits, true),
          };
        }),
      );
  }, [jeIQData]);
  return displayedJEs;
};

export const getJournalEntrySidepanelTitle = (isLoading, journalEntriesIQData) => {
  if (isLoading && !journalEntriesIQData?.pages[0]?.length) {
    return 'Create journal entry';
  }

  const sequenceNumber = journalEntriesIQData?.pages[0]?.journalEntryModels[0]?.journalSequenceNumber;
  if (!sequenceNumber) return 'Create journal entry';
  const formattedTitle = `${sequenceNumber.slice(0, 3)} ${Number(sequenceNumber.slice(3)) + 1}`;
  return formattedTitle;
};
