import React, { useEffect, useState } from 'react';
import { DescriptiveLoader, Dialog, DialogContent } from 'ui';
import { RULESET_JOB_STATUS, getRunRulesetProcessStateDescriptorProps } from '../../process-states';
import { JOB_NAME, RunAccountPostingRuleSetsHandlerPayload } from 'services/http';
import { toast } from 'react-hot-toast';
import { TrackJobInDialog } from '../../job-progress';
import { deriveError } from '../../templates/utils';
import { RunRulesetDialogForm } from '../RunRulesetDialogForm';
import { SetJobCadence } from './set-job-cadence';
import {
  useInvalidateQuery,
  useJobProgress,
  useSession,
  useTriggerAccountPostingRuleSetMutation,
  useUpdateJobConfigurationMutation,
} from '../../../hooks';
import { CREATE_MODE, CreateModeTabs } from './create-mode-tabs';
import { ToggleJobAutorun } from './toggle-job-autorun';

export const ManageRulesetRunModal = ({ open, onOpenChange, accountPostingRuleSet }) => {
  const { organizationId } = useSession();
  const [error, setError] = useState<string>();
  const [backgroundJobState, setBackgroundJobState] = useState<RULESET_JOB_STATUS>(RULESET_JOB_STATUS.FORM);
  const { mutateAsync } = useTriggerAccountPostingRuleSetMutation();
  const onClose = () => {
    onOpenChange(false);
  };
  useEffect(() => {
    if (!open) {
      setBackgroundJobState(RULESET_JOB_STATUS.FORM);
    }
  }, [open]);

  const onSubmit = async (params: RunAccountPostingRuleSetsHandlerPayload & { ruleSetId: string }) => {
    try {
      setBackgroundJobState(RULESET_JOB_STATUS.PROCESSING);
      const response = await mutateAsync(params);
      if (response.data.message === 'no transaction found') {
        throw new Error('No transactions found in the selected accounting period');
      }
    } catch (e) {
      console.log('CreateSyncBgJob error:', e);
      setError(deriveError(e));
      toast.error(deriveError(e));
    }
  };

  const { data: jobConfiguration, isLoading } = useJobProgress(
    {
      jobName: JOB_NAME.RULESET_JOB,
      referenceId: accountPostingRuleSet?._id,
      organizationId,
    },
    { enabled: !!accountPostingRuleSet?._id },
  );

  const { mutateAsync: updateConfig, isLoading: isUpdating } = useUpdateJobConfigurationMutation();
  const { invalidateJobProgress } = useInvalidateQuery();

  const onCronExpressionUpdate = async (cronExpression: string) => {
    if (!jobConfiguration?._id) return;
    try {
      await updateConfig({ jobConfigurationId: jobConfiguration?._id, update: { cronExpression } });
      onOpenChange(false);
      toast.success('Settings saved');
      await invalidateJobProgress();
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const [createMode, setCreateMode] = useState<CREATE_MODE>(CREATE_MODE.MANUAL);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {open && backgroundJobState === RULESET_JOB_STATUS.FORM ? (
        <DialogContent title='Schedule ruleset run' onClose={onClose}>
          <ToggleJobAutorun
            jobConfigurationId={jobConfiguration?._id}
            isPaused={jobConfiguration?.isPaused}
            cronExpression={jobConfiguration?.cronExpression}
          />

          <CreateModeTabs value={createMode} onChange={setCreateMode} />
          {createMode === CREATE_MODE.AUTOMATIC && (
            <SetJobCadence
              onSubmit={onCronExpressionUpdate}
              onCancel={() => onOpenChange(false)}
              isSubmitting={isUpdating}
              isLoading={isLoading}
              disabled={isLoading}
              defaultCronExpression={jobConfiguration?.cronExpression}
            />
          )}
          {createMode === CREATE_MODE.MANUAL && (
            <RunRulesetDialogForm
              onClose={() => onOpenChange(false)}
              organizationId={organizationId}
              ruleset={accountPostingRuleSet}
              onSubmit={onSubmit}
            />
          )}
        </DialogContent>
      ) : backgroundJobState === RULESET_JOB_STATUS.PROCESSING ? (
        <TrackJobInDialog
          jobName={JOB_NAME.RULESET_JOB}
          referenceId={accountPostingRuleSet._id}
          onClose={onClose}
          error={error}
        />
      ) : (
        <DialogContent onClose={onClose}>
          <div className='h-[200px] w-full flex items-center justify-center'>
            <DescriptiveLoader {...getRunRulesetProcessStateDescriptorProps(backgroundJobState, error)} />
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};
