import React, { useState } from 'react';
import { Dialog } from 'ui';

import { UpdateTransactionCostBasisModalTrigger } from './trigger';
import { UpdateTransactionCostBasisModalContent } from './content';

export const UpdateTransactionCostBasisModal = ({
  transactionIds,
  showTrigger = true,
  onSuccess,
  triggerProps = {},
}) => {
  const [open, onOpenChange] = useState(false);
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && <UpdateTransactionCostBasisModalTrigger {...triggerProps} onClick={() => onOpenChange(true)} />}
      <UpdateTransactionCostBasisModalContent
        transactionIds={transactionIds}
        onOpenChange={onOpenChange}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
};
