import { BarChart, Metric, Text } from '@tremor/react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownGroupBody,
  DropdownTitleWithToggle,
  DropdownTrigger,
  TremorCard,
  classNames,
} from 'ui';
import { useAccountingPeriods, useTransactionsVolumeByClassification } from '../../hooks';
import { useEffect, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';
import { AccountingPeriod } from 'services/http/response.types';

export const TransactionsByCategoryWidget = () => {
  const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [selectedPeriods, setSelectedPeriods] = useState<AccountingPeriod[]>([]);

  const { data: accountingPeriodData } = useAccountingPeriods();
  const accountingPeriods = accountingPeriodData?.pages[0].accountingPeriods || [];

  useEffect(() => {
    if (accountingPeriods.length > 0) {
      setSelectedPeriods([accountingPeriods[0]]);
    }
  }, [accountingPeriods]);

  const earliestStartDate = Math.min.apply(
    Math,
    selectedPeriods.map((value) => new Date(value.startDate).getTime()),
  );
  const latestEndDate = Math.max.apply(
    Math,
    selectedPeriods.map((value) => new Date(value.endDate).getTime()),
  );

  const { data, isLoading } = useTransactionsVolumeByClassification({
    transactionFrom: new Date(earliestStartDate).toDateString(),
    transactionTo: selectedPeriods.length > 0 ? new Date(latestEndDate).toDateString() : new Date().toDateString(),
  });

  const { transactionsByClassification } = data || {};

  const chartData =
    transactionsByClassification?.map((classification) => ({
      name: classification._id,
      'Number of transactions': classification.volume,
    })) || [];

  const totalTransactions = transactionsByClassification?.reduce((acc, curr) => {
    return acc + curr.volume;
  }, 0);

  const onClearSelectedPeriods = () => {
    setSelectedPeriods([]);
  };

  const onSelectAllPeriods = () => {
    setSelectedPeriods(accountingPeriods);
  };
  return (
    <TremorCard className='overflow-visible'>
      {isLoading ? (
        <div className='h-[300px] animate-pulse bg-gray-50' />
      ) : (
        <>
          <div className='flex justify-between'>
            <div className='mb-4'>
              <Text>Transactions category by month</Text>
              {chartData.length > 0 && <Metric>{totalTransactions}</Metric>}
            </div>
            <Dropdown open={monthDropdownOpen} onOpenChange={setMonthDropdownOpen}>
              <DropdownTrigger>
                <span>
                  <Button
                    label={
                      selectedPeriods.length === 0 ? (
                        'Select accounting periods'
                      ) : (
                        <p className='text-indigo-600'>
                          {selectedPeriods[0].accountingPeriodName + ' '}
                          <span>{selectedPeriods.length > 1 ? selectedPeriods.length + '+' : ''}</span>
                        </p>
                      )
                    }
                    className={classNames(
                      'duration-100 py-0',
                      selectedPeriods.length > 0 && 'shadow',
                      selectedPeriods.length > 0 && 'bg-indigo-50 border-indigo-600',
                    )}
                    labelContainerClassname={classNames(
                      'font-medium ',
                      selectedPeriods.length > 0 && 'text-indigo-600',
                    )}
                    emphasis='medium'
                    trailingIconContainerClassname={classNames(
                      selectedPeriods.length > 0 && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
                      selectedPeriods.length > 0 && 'bg-indigo-50',
                    )}
                    trailingIcon={
                      <div className='flex items-center'>
                        {selectedPeriods.length > 0 && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              onClearSelectedPeriods();
                            }}
                          >
                            <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                          </span>
                        )}

                        <MdArrowDropDown
                          className={classNames('duration-300 w-6 h-6 text-black', monthDropdownOpen && 'rotate-180')}
                        />
                      </div>
                    }
                  />
                </span>
              </DropdownTrigger>
              <DropdownContent>
                <DropdownBody>
                  <DropdownTitleWithToggle
                    title='Select all'
                    checked={
                      selectedPeriods.length === accountingPeriods.length &&
                      accountingPeriods.every((value, index) => value === selectedPeriods[index])
                    }
                    onChange={(checked: boolean) => (checked ? onSelectAllPeriods() : onClearSelectedPeriods())}
                  />
                  <DropdownGroupBody>
                    {accountingPeriods.map((accountingPeriod) => (
                      <CheckboxListItem
                        enableSelectOnly={false}
                        key={accountingPeriod.accountingPeriodName || ''}
                        label={accountingPeriod.accountingPeriodName || ''}
                        checked={selectedPeriods.includes(accountingPeriod)}
                        onCheckedChange={(selected: boolean) => {
                          if (selected) {
                            setSelectedPeriods((prev) => [...prev, accountingPeriod]);
                          } else {
                            setSelectedPeriods((prev) => prev.filter((selected) => selected !== accountingPeriod));
                          }
                        }}
                      />
                    ))}
                  </DropdownGroupBody>
                </DropdownBody>
              </DropdownContent>
            </Dropdown>
          </div>
          <BarChart
            data={chartData || []}
            categories={['Number of transactions']}
            index='name'
            showLegend={false}
            onValueChange={() => null}
          />
        </>
      )}
    </TremorCard>
  );
};
