import { OrganizationMembers, Organization, User } from 'schemas';
import { createContext } from 'react';
import { SessionDetailsFromServer } from 'services';
import { useLocalStorage } from 'usehooks-ts';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { SESSION_COOKIE } from 'services/http/axios-interceptor/axios-request-config-factory';
// import { getAllMembershipsByMemberId } from 'services/http';
export type OrganizationWithBillingInfo = Organization & {
  lastBillingInfoUpdateAt?: string; // @todo akshay explain how this works https://github.com/EntendreFinance/Entendre/pull/1818
};
export interface SessionState {
  organizationId: string;
  userId: string;
  user?: User;
  token: string;
  selectedOrganization?: OrganizationWithBillingInfo;
  selectedMembership?: OrganizationMembers;
  memberships: OrganizationMembers[];
  organizations: OrganizationWithBillingInfo[];
}

const defaultSessionState: SessionState = {
  organizationId: '',
  userId: '',
  token: '' as string,
  selectedOrganization: {} as OrganizationWithBillingInfo,
  selectedMembership: {} as OrganizationMembers,
  memberships: [] as OrganizationMembers[],
  organizations: [] as OrganizationWithBillingInfo[],
};

type SessionContextType = SessionState & {
  updateSessionMemberships: (memberships: OrganizationMembers[]) => void;
  updateSessionUser: (user: User) => void;
  updateSessionToken: (token: string) => void;
  updateSelectedOrganization: (organization: Organization, membership: OrganizationMembers) => void;
  clearSession: () => void;
  handleSessionFromServer: (session: SessionDetailsFromServer) => void;
  setters: {
    setSelectedOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>>;
    setSelectedMembership: React.Dispatch<React.SetStateAction<OrganizationMembers | undefined>>;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    setOrganizations: React.Dispatch<React.SetStateAction<Organization[]>>;
    setMemberships: React.Dispatch<React.SetStateAction<OrganizationMembers[]>>;
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  };
};

const defaultSessionContextState = {
  ...defaultSessionState,
  updateSessionMemberships: () => {},
  updateSessionUser: () => {},
  updateSessionToken: () => {},
  updateSelectedOrganization: () => {},
  clearSession: () => {},
  handleSessionFromServer: () => {},
  setters: {
    setSelectedOrganization: () => {},
    setSelectedMembership: () => {},
    setUser: () => {},
    setOrganizations: () => {},
    setMemberships: () => {},
    setToken: () => {},
    setIsLoggedIn: () => {},
  },
};

export const SessionContext = createContext<SessionContextType>(defaultSessionContextState);

enum SESSION_KEYS {
  USER = 'USER',
  ORGANIZATIONS = 'ORGANIZATIONS',
  MEMBERSHIPS = 'MEMBERSHIPS',
  TOKEN = 'TOKEN',
  SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION',
  SELECTED_MEMBERSHIP = 'SELECTED_MEMBERSHIP',
}

export const SessionProvider = ({ children }) => {
  const [selectedOrganization, setSelectedOrganization] = useLocalStorage<Organization | undefined>(
    SESSION_KEYS.SELECTED_ORGANIZATION,
    undefined,
  );
  const [selectedMembership, setSelectedMembership] = useLocalStorage<OrganizationMembers | undefined>(
    SESSION_KEYS.SELECTED_MEMBERSHIP,
    undefined,
  );
  const [user, setUser] = useLocalStorage<User | undefined>(SESSION_KEYS.USER, undefined);
  const [organizations, setOrganizations] = useLocalStorage<Organization[]>(SESSION_KEYS.ORGANIZATIONS, []);
  const [memberships, setMemberships] = useLocalStorage<OrganizationMembers[]>(SESSION_KEYS.MEMBERSHIPS, []);
  const [token, setToken] = useLocalStorage<string | undefined>(SESSION_KEYS.TOKEN, undefined);

  const updateSessionMemberships = setMemberships;
  const updateSessionUser = setUser;
  const updateSessionToken = setToken;
  const updateSelectedOrganization = (organization: Organization, membership: OrganizationMembers) => {
    setSelectedOrganization(organization);
    setSelectedMembership(membership);
  };

  const clearSession = () => {
    setSelectedOrganization(undefined);
    setSelectedMembership(undefined);
    setUser(undefined);
    setOrganizations([]);
    setMemberships([]);
    setToken(undefined);
    deleteCookie(SESSION_COOKIE.ORGANIZATION_ID);
    deleteCookie(SESSION_COOKIE.TOKEN);
  };

  const handleSessionFromServer = (data: SessionDetailsFromServer) => {
    setSelectedOrganization(data.selectedOrganization);
    setSelectedMembership(data.selectedMembership);
    setUser(data.user);
    setOrganizations(data.organizations);
    setMemberships(data.memberships);
    setToken(data.user.jwt);
    if (data.user.jwt) setCookie(SESSION_COOKIE.TOKEN, data.user.jwt);
    if (data.selectedOrganization?._id) {
      setCookie(SESSION_COOKIE.ORGANIZATION_ID, data.selectedOrganization._id);
      console.log(
        'updated selected organization id cookie',
        data.selectedOrganization._id,
        getCookie(SESSION_COOKIE.ORGANIZATION_ID),
      );
    } else {
      console.log('selected organization id was not updated in handleSessionFromServer');
    }
  };

  return (
    <SessionContext.Provider
      value={{
        organizationId: selectedOrganization?._id!,
        userId: user?._id!,
        user: user!,
        token: token!,
        selectedOrganization,
        selectedMembership,
        memberships,
        organizations,
        updateSessionMemberships,
        updateSessionUser,
        updateSessionToken,
        updateSelectedOrganization,
        clearSession,
        handleSessionFromServer,

        setters: {
          setSelectedOrganization,
          setSelectedMembership,
          setUser,
          setOrganizations,
          setMemberships,
          setToken,
        },
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
