import { JobConfiguration } from 'schemas';
import { JOB_NAME } from 'services';
import { shouldFilterByUniqueId } from 'src/context';

export const filterJobUpdatesByMessageVersion = () => (message: any) => {
  const jobConfig = message.payload.jobConfig as JobConfiguration;
  if (message.version === 2 || !jobConfig) return false;
  return true;
};

export const filterJobUpdatesByJobName = (jobName) => (message: any) => {
  const jobConfig = message.payload.jobConfig as JobConfiguration;
  if (message.version === 2 || !jobConfig) return false;
  return jobConfig.jobName === jobName;
};

export const filterJobUpdatesByJobNameAndReferenceId = (jobName, referenceId, uniqueId?: string) => (message: any) => {
  const jobConfig = message.payload.jobConfig as JobConfiguration;
  if (message.version === 2 || !jobConfig) return false;
  return jobConfig.jobName === jobName && jobConfig?.referenceId === referenceId && shouldFilterByUniqueId(jobConfig?.jobName as JOB_NAME) ? jobConfig.uniqueId === uniqueId : true;
};
