import React, { useState } from 'react';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { CheckboxGroupProps } from './types';
import { Flex, StyledCheckbox, StyledIndicator } from '../../../styles/CheckboxGroupStyle';
import { Label } from '@radix-ui/react-dropdown-menu';

export const CheckboxGroup = ({
  checkboxItems = [{ label: 'Label', labelLeft: false, checked: false }],
  clickFn = (_checkboxItems) => _checkboxItems,
  className,
  ...props
}: CheckboxGroupProps) => {
  const [values, setValues] = useState(checkboxItems);

  return (
    <>
      {checkboxItems.length ? (
        <div className={`flex flex-col items-center justify-center gap-4 ${className}`}>
          {values?.map(({ label, labelLeft = false, checked }, parentIndex) => (
            <Flex
              key={`${label}-${parentIndex}`}
              className='gap-4 px-3 py-2 hover:bg-[#E8E8E8] !rounded-lg duration-300 !cursor-pointer !w-full !justify-start'
            >
              {labelLeft && <Label>{label}</Label>}

              <StyledCheckbox
                onCheckedChange={(checkedState) => {
                  const updatedState = values.map((item, childIndex) => {
                    if (parentIndex === childIndex) {
                      return {
                        ...item,
                        checked: !!checkedState, // convert to boolean
                      };
                    }
                    return {
                      ...item,
                    };
                  });
                  setValues(updatedState);
                  clickFn(updatedState);
                }}
                checked={checked}
                {...props}
              >
                <StyledIndicator>
                  <CheckIcon />
                </StyledIndicator>
              </StyledCheckbox>
              {!labelLeft && <Label>{label}</Label>}
            </Flex>
          ))}
        </div>
      ) : (
        'N/A'
      )}
    </>
  );
};

export default CheckboxGroup;
