import axios from 'axios';
import { JobConfiguration } from 'schemas';
import { SERVER_URL_CONFIGURATION_SERVICE } from '../../config';
import { JOB_NAME } from '../job-queue';
import { prepareSearchParams } from '../core/utils';

export const getRunningJobs = async ({ jobNames }: { jobNames: JOB_NAME[] }) => {
  const searchParams = prepareSearchParams({ jobNames }, { noExtras: true });
  return await axios.get<{
    jobConfigurations: JobConfiguration[];
  }>(`${SERVER_URL_CONFIGURATION_SERVICE}/running-jobs?${searchParams}`);
};
