import React, { useState } from 'react';
import {
  CurrencyMenu,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  InputLabel,
  ModalContent,
  currencyOptions,
} from 'ui';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useConfirmSetupIntentFromAddCard, useInvalidateQuery } from '../../../hooks';
import toast from 'react-hot-toast';
import { deriveError } from '../../templates/utils';

enum MODAL_STEP {
  FORM,
  LOADING,
  SUCCESS,
}
export const EditPaymentMethodModalContent = ({ stripeSetupIntentClientSecret, onCancel }) => {
  const [step, setStep] = useState(MODAL_STEP.FORM);
  const [country, setCountry] = useState('');
  const confirmSetupIntent = useConfirmSetupIntentFromAddCard({
    clientSecret: stripeSetupIntentClientSecret,
  });
  const { invalidateStripeCards } = useInvalidateQuery();

  const onUpdate = async () => {
    setStep(MODAL_STEP.LOADING);
    try {
      await confirmSetupIntent();
      setStep(MODAL_STEP.SUCCESS);
      invalidateStripeCards();
    } catch (error) {
      toast.error(deriveError(error));
      setStep(MODAL_STEP.FORM);
    }
  };

  const onClose = () => {
    setStep(MODAL_STEP.FORM);
    onCancel();
  };
  return (
    <ModalContent
      title='Edit payment method'
      onClose={step === MODAL_STEP.LOADING ? undefined : onClose}
      actions={
        step === MODAL_STEP.FORM
          ? [
              {
                label: 'Update',
                onClick: onUpdate,
              },
              {
                label: 'Cancel',
                onClick: onClose,
                emphasis: 'medium',
              },
            ]
          : []
      }
    >
      {step === MODAL_STEP.FORM && (
        <div>
          <div>
            <InputLabel heading='Card number' />
            <CardNumberElement />
          </div>
          <div>
            <div>
              <InputLabel heading='Expires on' />
              <CardExpiryElement />
            </div>
            <div>
              <InputLabel heading='CVC' />
              <CardCvcElement />
            </div>
          </div>
          <div>
            <div>
              <div>
                <InputLabel heading='Billing country' />
                <CurrencyMenu
                  onChange={({ value }) => {
                    setCountry(value);
                  }}
                  value={currencyOptions.find((item) => item?.value?.toLowerCase() === country?.toLowerCase())}
                  options={currencyOptions}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {step === MODAL_STEP.LOADING && (
        <DescriptiveLoader inModal title='Processing' description='Updating payment method' />
      )}
      {step === MODAL_STEP.SUCCESS && (
        <DescriptiveLoader
          inModal
          title='Success'
          description='Payment method updated'
          status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
        />
      )}
    </ModalContent>
  );
};
