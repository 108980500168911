import short from 'short-uuid';
import { GATE_TYPE, RuleConfiguratorState } from '../../types';
import { produce } from 'immer';
const translator = short();

export const addCondition = (state: RuleConfiguratorState, action) => {
  return produce(state, (draft) => {
    const id = translator.new().toString();
    // add to conditions
    draft.conditions[id] = {
      ...action.payload.condition,
      id,
    };
    // add to conditionIds
    draft.conditionIds.push(id);
    // add to parent
    draft.conditionGroups[action.payload.condition.parentId].childIds.splice(
      action.payload.at ?? draft.conditionGroups[action.payload.condition.parentId].childIds.length,
      0,
      id,
    );
    // add gate to parent
    draft.conditionGroups[action.payload.condition.parentId].gates.push(GATE_TYPE.AND);
  });
};
