export const LEDGER_PAGE_SECONDARY_NAVIGATION = [
  { value: 'Transactions', path: '/ledger/transactions' },
  { value: 'Sources', path: '/ledger/sources' },
  { value: 'Journals', path: '/ledger/journals' },
  { value: 'Assets', path: '/ledger/assets' },
];

export const CONFIGURE_PAGE_SECONDARY_NAVIGATION = [
  { value: 'Templates', path: '/configure/templates' },
  { value: 'Rulesets', path: '/configure/rulesets' },
  { value: 'Tags', path: '/configure/tags' },
  { value: 'Impairment', path: '/configure/impairment-rules' },
  {
    value: 'Ledger Accounts',
    path: '/configure/ledger-accounts',
  },
  { value: 'Entities', path: '/configure/entities' },
];

export const REPORT_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Income statement',
    path: '/reports/incomestatement',
  },
  {
    value: 'Balance sheet',
    path: '/reports/balancesheet',
  },
  {
    value: 'Trial balance',
    path: '/reports/trialbalance',
  },
  {
    value: 'Schedule of disposition',
    path: '/reports/schedule-of-disposition',
  },
  {
    value: 'Asset tax lots',
    path: '/reports/asset-tax-lots',
  },
  {
    value: 'Realized gain/loss',
    path: '/reports/realized-gain-or-loss',
  },
  {
    value: 'Closing positions',
    path: '/reports/closing-positions',
  },
  {
    value: 'Account transactions',
    path: '/reports/accounting-transactions',
  },
];

export const PROFILE_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Account',
    path: '/profile/account-information',
  },
  // {
  //   value: 'Notifications',
  //   path: '/profile/notifications',
  // },
  // {
  //   value: 'Subscription',
  //   path: '/profile/subscription',
  // },
  {
    value: 'Billing',
    path: '/profile/billing',
  },
];

export const ORGANIZATION_SETTINGS_SECONDARY_NAVIGATION = [
  {
    value: 'General',
    path: '/organization-settings/general',
  },
  {
    value: 'Team',
    path: '/organization-settings/team',
  },
  {
    value: 'Billing',
    path: '/organization-settings/billing',
  },
  {
    value: 'Spam',
    path: '/organization-settings/spam',
  },
  {
    value: 'Audit Logs',
    path: '/organization-settings/audit-logs',
  },
];

export const AI_LEDGER_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'transactions',
    path: '/new-ai-layout/ledger/transactions',
  },
  {
    value: 'assets',
    path: '/new-ai-layout/ledger/assets',
  },
  {
    value: 'journals',
    path: '/new-ai-layout/ledger/journals',
  },
  {
    value: 'sources',
    path: '/new-ai-layout/ledger/sources',
  },
];

export const AI_CONFIGURE_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Templates',
    path: '/new-ai-layout/configure/templates',
  },
  {
    value: 'Rulesets',
    path: '/new-ai-layout/configure/rulesets',
  },
  {
    value: 'Tags',
    path: '/new-ai-layout/configure/tags',
  },
  {
    value: 'Impairment',
    path: '/new-ai-layout/configure/impairment-rules',
  },
  {
    value: 'Ledger accounts',
    path: '/new-ai-layout/configure/ledger-accounts',
  },
  {
    value: 'Legal entities',
    path: '/new-ai-layout/configure/legal-entities',
  },
];

export const AI_REPORTS_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Income statement',
    path: '/new-ai-layout/reports/income-statement',
  },
  {
    value: 'Balance sheet',
    path: '/new-ai-layout/reports/balance-sheet',
  },
  {
    value: 'Trial balance',
    path: '/new-ai-layout/reports/trial-balance',
  },
  {
    value: 'Shedule of disposition',
    path: '/new-ai-layout/reports/shedule-of-disposition',
  },
  {
    value: 'Asset tax lots',
    path: '/new-ai-layout/reports/asset-tax-lots',
  },
  {
    value: 'Realized gain/loss',
    label: 'Realized gain or loss',
    path: '/new-ai-layout/reports/realized-gain-or-loss',
  },
  {
    value: 'Closing position',
    path: '/new-ai-layout/reports/closing-position',
  },
];

export const AI_INTEGRATIONS_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'All Integrations',
    path: '/new-ai-layout/integrations/all-integrations',
  },
  {
    value: 'My Integrations',
    path: '/new-ai-layout/integrations/my-integrations',
  },
];

export const AI_PROFILE_PAGE_SECONDARY_NAVIGATION = [
  {
    value: 'Account',
    path: '/new-ai-layout/profile/account-information',
  },
  {
    value: 'Billing',
    path: '/new-ai-layout/profile/billing',
  },
];
