import { useQuery } from '@tanstack/react-query';
import { JOB_NAME, getRunningJobs } from 'services';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useSession } from '../useSession';

export const useGetRunningJobsQuery = ({ jobNames, enabled = true }: { jobNames: JOB_NAME[]; enabled?: boolean }) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  const ms = 1000;
  const seconds = 6;
  return useQuery(
    getKey(QUERY_KEY.GET_RUNNING_JOBS, { jobNames }),
    async () => {
      const response = await getRunningJobs({ jobNames });
      return response.data;
    },
    {
      refetchInterval: ms * seconds,
      enabled: enabled && !!organizationId,
    },
  );
};
