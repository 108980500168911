import { createContext } from 'react';
import { SidebarParams, SidebarRoutes } from '../components/Sidebar/SidebarGlobal';

export const SidebarGlobalContext = createContext<{
  route: string | null;
  openSidebar: (route: SidebarRoutes | undefined, params: SidebarParams, extra?: any) => void;
  closeSidebar: (sidebar: 'primary' | 'secondary' | 'both') => void;
  sidebarIds: string[];
}>({
  route: null,
  openSidebar: () => null,
  sidebarIds: [],
  closeSidebar: () => null,
});
