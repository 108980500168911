import React from 'react';
import { MdRemoveCircle } from 'react-icons/md';
import { InputTrailingAction } from '../input-trailing-action';
import { SubtractInputTrailingActionProps } from './types';

export const SubtractInputTrailingAction = ({
  value,
  setValue,
  className,
  disabled,
  min,
}: SubtractInputTrailingActionProps) => {
  return (
    <InputTrailingAction
      icon={<MdRemoveCircle className='w-5 h-5' />}
      onClick={() =>
        setValue({
          target: {
            value: `${+value - 1}`,
          },
        })
      }
      className={className}
      disabled={min !== undefined ? disabled || +value === +min : disabled}
    />
  );
};
