import { DonutChart, Legend, Metric, Text, ValueFormatter } from '@tremor/react';
import { TremorCard } from 'ui';
import { processAssetBreakdownData } from '../PageComponents';
import { formatDollars } from '../templates/utils';
import { useGetAssetMetrics } from 'src/hooks/http/useAssets';
import { AssetBreakdownData } from 'services/http/analytics/assets';
export const formatMetricsResponseIntoAssetBreakdownData = (data) => {
  if (!data || !data.assetsByCostBasis) return undefined;
  const assetBreakdownData: AssetBreakdownData = {
    data: Object.keys(data.assetsByCostBasis).reduce(
      (acc, key) => {
        acc[key] = {
          assetType: key,
          quantity: data.assetsByQuantity[key],
          value: data.assetsByCostBasis[key],
        };
        return acc;
      },
      {} as Record<string, { quantity: string; value: string; assetType: string }>,
    ),
  };

  return assetBreakdownData;
};
export const AssetBreakdownPieWidget = () => {
  const { data, isLoading } = useGetAssetMetrics({});
  let assetBreakdownData: undefined | AssetBreakdownData = formatMetricsResponseIntoAssetBreakdownData(data);
  const { processedData, availableAssets } = processAssetBreakdownData(assetBreakdownData, true);
  const valueFormatter: ValueFormatter = (value) => {
    return formatDollars(value, true);
  };

  const chartData = processedData['Values'] || [];

  return (
    <TremorCard>
      {isLoading ? (
        <div className='h-[300px] animate-pulse bg-gray-50' />
      ) : (
        <>
          <div className='mb-4'>
            <Text>Asset breakdown</Text>
            {chartData.length > 0 && <Metric>{availableAssets.length}</Metric>}
          </div>
          <div className='justify-center flex items-center flex-col'>
            <DonutChart
              variant='pie'
              data={chartData}
              valueFormatter={valueFormatter}
              showLabel={true}
              category='value'
              index='name'
              onValueChange={() => null}
            />
            <Legend categories={availableAssets} className='mt-4' />
          </div>
        </>
      )}
    </TremorCard>
  );
};
