import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import GlobalStyles from '../styles/GlobalStyles';
import Grid from '../styles/Grid';
import Buttons from '../styles/Buttons';
import Form from '../styles/Form';
import { Toast } from './atoms';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import 'react-date-range/dist/styles.css'; // react-date-range main css file
import 'react-date-range/dist/theme/default.css'; // react-date-range theme css file
import SliderProvider from '../context/SliderProvider';
import { CommandPalleteProvider } from '../context/CommandPalleteProvider';
import { MdClose } from 'react-icons/md';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import SidebarGlobal from './Sidebar/SidebarGlobal';
import {
  MainDirectoryContextProvider,
  TableActionBarProvider,
  WebsocketProvider,
  TaskManagerProvider,
  OnboardingTransactionBackfillProvider,
  BannerProvider,
} from '../context';
import CommandPallete from './CommandPallete/CommandPallete';
import { useRouter } from 'next/router';
import TanStackTableProvider from 'src/context/TanStackTableProvider';
import { DensityProvider } from '../context/DensityContext';
import { useRedirectIfOnboardingNotComplete } from '../hooks';
//import { SubscriptionExpiredBanner } from './SubscriptionExpiredBanner';
import { OrganizationPickerProvider } from '../context/organization-picker-context';
import { AxiosInterceptorAttachment } from './AxiosInterceptorAttachment';
import { useHandleLogoutEvent } from 'src/hooks/use-handle-logout-event';

const queryClient = new QueryClient();
const ContentStyles = styled.main``;

export interface LayoutProps {
  children?: React.ReactNode;
  initialData?: any;
  dehydratedState?: any;
}

function Layout({ children, initialData, dehydratedState }: LayoutProps) {
  const router = useRouter();
  const containerStyles = useMemo(() => {
    if (router.pathname.startsWith('/onboarding/new/')) return { bottom: 21, right: 80 };
    return {};
  }, [router.pathname]);

  useRedirectIfOnboardingNotComplete();
  useHandleLogoutEvent();

  return (
    <>
      <AxiosInterceptorAttachment />
      <GlobalStyles />
      <Grid />
      <Buttons />
      <Form />
      <OrganizationPickerProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <SliderProvider initialData={initialData}>
              <DensityProvider>
                <TanStackTableProvider initialData={initialData}>
                  <WebsocketProvider>
                    <TaskManagerProvider>
                      <CommandPalleteProvider>
                        <CommandPallete>
                          <GoogleOAuthProvider clientId='315799310762-1ij8j316s9u4i585ktcdbtd87tve3an7.apps.googleusercontent.com'>
                            <MainDirectoryContextProvider>
                              <ContentStyles>
                                <TableActionBarProvider>
                                  <SidebarGlobal>
                                    <OnboardingTransactionBackfillProvider>
                                      <BannerProvider>
                                        {children}
                                        {/* <SubscriptionExpiredBanner /> */}
                                      </BannerProvider>
                                    </OnboardingTransactionBackfillProvider>
                                  </SidebarGlobal>
                                </TableActionBarProvider>
                              </ContentStyles>
                            </MainDirectoryContextProvider>
                          </GoogleOAuthProvider>
                        </CommandPallete>
                        <Toast />
                      </CommandPalleteProvider>
                    </TaskManagerProvider>
                  </WebsocketProvider>
                </TanStackTableProvider>
              </DensityProvider>
            </SliderProvider>
            <Toaster
              position='bottom-right'
              reverseOrder={true}
              toastOptions={{
                className: 'bg-black text-white font-normal',
                loading: {
                  iconTheme: {
                    secondary: 'blue',
                    primary: 'white',
                  },
                },
                style: {
                  wordBreak: 'break-word',
                },
              }}
              containerStyle={containerStyles}
            >
              {(t) => (
                <ToastBar toast={t} style={{ background: 'black', color: 'white' }}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <button onClick={() => toast.dismiss(t.id)}>
                          <MdClose className='text-white bg-black w-5 h-5' />
                        </button>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </OrganizationPickerProvider>
    </>
  );
}

export default Layout;
