import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { deriveError } from '../../components/templates/utils';
import {
  getAssets,
  createAsset,
  getAssetMetrics,
  deleteAsset,
  patchAsset,
  produceAssetReconstructionJob,
  getAssetsV2,
} from 'services/http/core';
import { ColumnSort } from '@tanstack/react-table';
import { toast } from 'react-hot-toast';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { Asset } from 'schemas';

export type GetAssetsQueryFilter = Partial<{
  organizationId: string;
  organizationIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  impaired: string[];
  startDate: string;
  endDate: string;
  sort: ColumnSort;
  pageSize: number;
  targetDate: string;
  accountingPeriodStartDateUTCs: string[];
}>;

export const useAssets = (params?: GetAssetsQueryFilter) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ASSETS, params),
    async ({ pageParam = 0 }) => {
      const response = await getAssets({
        organizationId,
        organizationIds: [organizationId],
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'dateReceived', desc: true },
        pageSize: params?.pageSize ?? 25,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      cacheTime: 5000,
      staleTime: 5000,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};


export const useAssetsV2 = (params?: GetAssetsQueryFilter) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.ASSETS, params),
    async ({ pageParam = 0 }) => {
      const response = await getAssetsV2({
        organizationId,
        organizationIds: [organizationId],
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'dateReceived', desc: true },
        pageSize: params?.pageSize ?? 25,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      cacheTime: 5000,
      staleTime: 5000,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};

export const getAssetById = (assetId: string) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ASSET_BY_ID, { assetId }),
    async () => {
      const response = await getAssets({
        organizationId,
        sort: -1,
        page: 0,
        pageSize: 1,
        organizationIds: [organizationId],
        assetIds: assetId,
      });
      return response.data;
    },
    {
      enabled: !!organizationId && !!assetId,
      refetchOnWindowFocus: true,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};

export const useCreateAsset = () => useMutation((data) => createAsset(data));
export const useDeleteAssetMutation = () => useMutation(({ assetId }: { assetId: string }) => deleteAsset(assetId));
export const usePatchAssetMutation = () =>
  useMutation(({ assetId, update }: { assetId: string; update: Partial<Asset> }) => patchAsset(assetId, update));

export const useGetAssetMetrics = (params: Omit<GetAssetsQueryFilter, 'sort' | 'pageSize'>) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.ASSET_METRICS, params),
    async () => {
      const response = await getAssetMetrics(params);
      return response.data;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: true,
      onError: (error) => {
        toast.error(deriveError(error));
      },
    },
  );
};

export const useProduceAssetReconstructionJob = () =>
  useMutation((data: { organizationId: string }) => produceAssetReconstructionJob(data));
