import React, { useState } from 'react';
import { DialogContent, InputLabel, SimpleMenu } from 'ui';
import {
  useCreateJournalEntriesWithoutRule,
  useInvalidateQuery,
  useJournalEntryTemplates,
  useSession,
} from '../../../../hooks';
import { deriveError, getDisplayedJournalEntryTemplates, mergeJournalEntryTemplates } from '../../../templates/utils';
import toast from 'react-hot-toast';

export const ClassifyTransactionModalContent = ({ transactionIds, onSuccess = () => {}, onOpenChange }) => {
  const { invalidateTransactions } = useInvalidateQuery();
  const { mutateAsync: createJournalEntriesWithoutRule, isLoading: createJournalEntriesLoading } =
    useCreateJournalEntriesWithoutRule();
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>();
  const { data, isLoading: isLoadingTemplates } = useJournalEntryTemplates({
    pageSize: 1000,
  });
  const mergedTemplates = mergeJournalEntryTemplates(data);
  const displayedTemplates = getDisplayedJournalEntryTemplates(mergedTemplates);
  const { organizationId } = useSession();
  return (
    <DialogContent
      actions={[
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              await createJournalEntriesWithoutRule({
                organizationId,
                journalEntryTemplateId: selectedOption?.value as string,
                transactionIds,
              });
              onOpenChange(false);
              toast.success(`${transactionIds.length === 1 ? 'Transaction' : 'Transactions'} classified`);
              onSuccess();
              await invalidateTransactions();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
          disabled: createJournalEntriesLoading,
          isLoading: createJournalEntriesLoading,
        },
        {
          label: 'Cancel',
          emphasis: 'medium',
          onClick: () => {
            onOpenChange(false);
            setSelectedOption(undefined);
          },
        },
      ]}
    >
      <div>
        <InputLabel heading='Journal Entry Templates' />
        <SimpleMenu
          options={displayedTemplates}
          onChange={(option) => setSelectedOption(option)}
          value={selectedOption}
          isLoading={isLoadingTemplates}
        />
      </div>
    </DialogContent>
  );
};
