import { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownBody, DropdownContent, DropdownTrigger } from '../dropdown';
import { Button } from '../button';
import { classNames, currencyImg } from '../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { DropdownOption, ListProps, RowProps, SingleSelectProps } from './types';
import { InputWithExtras } from '../input-with-extras';
import { Avatar } from '../avatar';
import { Virtuoso } from 'react-virtuoso';
import { useInView } from 'react-intersection-observer';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';

const MAX_RENDERED_LIST = 100;

export const SingleSelectMenu = ({
  disabled,
  defaultValue,
  value: selectedValue,
  isLoading,
  'data-cy': dataCy,
  placeholder = 'Select',
  onClearValue,
  onChange,
  options: defaultOptions = [],
  fullWidth,
  isOnSidepanel = false,
  enableSearch = true,
  enableAvatar = false,
  type = 'chain',
  isModal = false,
  clearable = false,
  enableBottomText = false,
  side,
  align,
  customIconRender,
  onSearchInputChange,
  isFetching,
  forcePersistSearch,
  hasNextPage,
  fetchNextPage,
  ...props
}: SingleSelectProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState<DropdownOption | undefined>(selectedValue ?? defaultValue);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [options, setOptions] = useState(defaultOptions);

  const handleOnChange = (newValue: DropdownOption) => {
    setOpen(false); // Close the dropdown
    setValue(newValue); // Update the value
    onChange && onChange(newValue); // Call the onChange callback if provided
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    if (onSearchInputChange) onSearchInputChange(e.target.value);
  };

  useEffect(() => {
    // refresh options when they change
    setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    // update value when it changes
    setValue(selectedValue);
  }, [selectedValue]);

  const isValueSelected = (value: DropdownOption | undefined) => (value && value.value !== '') ?? false;

  const canClearValue = (value: DropdownOption | undefined) => clearable && isValueSelected(value) && onClearValue;
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage && fetchNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={dataCy}
          isFocused={open}
          emphasis='medium'
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => setOpen((prev) => !prev)}
          label={
            isValueSelected(value) ? (
              <div className='flex items-center gap-2 overflow-hidden w-full'>
                {enableAvatar && value?.icon && typeof customIconRender !== 'function' && type === 'chain' && (
                  <div className='[&>span]:!w-6 [&>span]:!h-6 flex items-center'>
                    <Avatar
                      size='medium'
                      src={currencyImg((value.icon as string)?.toLowerCase())}
                      alt={value.icon as string}
                    />
                  </div>
                )}
                {enableAvatar && typeof customIconRender === 'function' && customIconRender(value as DropdownOption)}
                <p className='whitespace-nowrap text-ellipsis overflow-hidden truncate w-[250px]'>{value?.label}</p>
              </div>
            ) : (
              placeholder
            )
          }
          className={classNames('duration-100 py-0 text-left', fullWidth && 'w-full flex justify-between', value && '')}
          labelContainerClassname='font-normal w-full overflow-hidden'
          trailingIconContainerClassname={classNames(value && 'mr-0 p-2 duration-100 rounded-r-lg')}
          trailingIcon={
            <div className='flex items-center'>
              {canClearValue(value) && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClearValue && onClearValue();
                  }}
                >
                  <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                </span>
              )}

              <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
            </div>
          }
        />
      </DropdownTrigger>
      <DropdownContent
        enableHeightCalculation={false}
        triggerRef={triggerRef}
        sameWidthAsTrigger={fullWidth}
        className={classNames('p-1 flex flex-col-reverse', isOnSidepanel && '!z-[60]')}
        withoutPortal={isModal}
        side={side}
        align={align}
        autoFocus={false}
      >
        {options.length > MAX_RENDERED_LIST ? (
          <VirtualizedList
            type={type}
            filter={filter}
            options={options}
            selectedValue={value}
            enableAvatar={enableAvatar}
            enableBottomText={enableBottomText}
            handleOnChange={(option) => handleOnChange(option)}
            customIconRender={customIconRender}
          />
        ) : options.length > 0 ? (
          <DropdownBody>
            <List
              type={type}
              filter={filter}
              options={options}
              selectedValue={value}
              enableAvatar={enableAvatar}
              enableBottomText={enableBottomText}
              handleOnChange={(option) => handleOnChange(option)}
              customIconRender={customIconRender}
            />
            {hasNextPage && (
              <div ref={ref} className='w-full flex justify-center items-center my-8'>
                <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
              </div>
            )}
          </DropdownBody>
        ) : (
          <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>
            {isLoading ? 'Loading...' : 'No options available'}
          </p>
        )}
        {((enableSearch && defaultOptions.length > 5) || forcePersistSearch) && (
          <div className='p-1 mb-2'>
            <InputWithExtras
              autoFocus={false}
              placeholder={placeholder.replace('Select', 'Search')}
              onChange={handleInputChange}
              leading={<MdSearch className='w-5 h-5 text-zinc-500 ml-1' />}
              containerClassName='min-w-[none] w-full'
              className='rounded-lg text-zinc-500 items-center leading-5 text-base placeholder:-translate-y-0.5 shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] '
            />
          </div>
        )}
      </DropdownContent>
    </Dropdown>
  );
};

const Row = ({
  option,
  handleOnChange,
  enableAvatar,
  enableBottomText,
  type,
  selectedValue,
  customIconRender,
}: RowProps) => {
  return (
    <button
      onClick={() => handleOnChange(option)}
      className={classNames(
        'text-left mb-1 last:mb-0 w-full flex items-center text-base text-zinc-900  justify-between hover:bg-indigo-50 hover:cursor-pointer mb-1 last:mb-0 px-2 py-1.5 rounded-md',
        enableAvatar && 'flex justify-start items-center gap-2',
        selectedValue?.value === option.value && 'bg-indigo-50',
      )}
    >
      {typeof customIconRender !== 'function' && enableAvatar && option.icon && type === 'chain' && (
        <div className='flex items-center'>
          <Avatar size='medium' src={currencyImg((option.icon as string)?.toLowerCase())} alt={option.icon as string} />
        </div>
      )}

      {enableAvatar && typeof customIconRender === 'function' && customIconRender(option)}
      <div className='w-full'>
        <p
          className={classNames('whitespace-nowrap text-ellipsis overflow-hidden ', enableBottomText && 'font-medium')}
        >
          {option.label}
        </p>
        {enableBottomText && (
          <p className='whitespace-nowrap text-ellipsis overflow-hidden text-zinc-500'>{option.bottomText ?? ''}</p>
        )}
      </div>
    </button>
  );
};

const List = ({ options, filter, enableBottomText, ...props }: ListProps) => {
  if (filter) {
    const optionsLength = options.filter((option) =>
      (option.label as string).toLowerCase().includes(filter.toLowerCase()),
    ).length;
    if (optionsLength === 0 && !props.isFetching) {
      return <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>;
    }

    const filteredOptions = options
      .filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase()))
      .map((option, idx) => <Row key={idx} option={option} enableBottomText={enableBottomText} {...props} />);

    return filteredOptions;
  }
  return options.map((option, idx) => <Row key={idx} option={option} enableBottomText={enableBottomText} {...props} />);
};

const VirtualizedList = ({ options, filter, enableBottomText, ...props }: ListProps) => {
  if (filter) {
    return options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length ===
      0 ? (
      <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>
    ) : (
      <Virtuoso
        style={{ height: 400 }}
        data={options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase()))}
        totalCount={
          options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length
        }
        itemContent={(index, option) => <Row option={option} enableBottomText={enableBottomText} {...props} />}
      />
    );
  }
  return (
    <Virtuoso
      data={options}
      totalCount={options.length}
      style={{ height: 400, maxHeight: 'fit-content' }}
      itemContent={(index, option) => <Row option={option} enableBottomText={enableBottomText} {...props} />}
    />
  );
};
