import { DefaultCell, TwoLineAvatar } from 'ui';
import { formatTableNumbers } from 'global-utils';
import { dateConverter } from 'src/components/utils';
import { ColumnsProps } from 'src/components/tables/columns/types';

export const assetImpairmentDetailsColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'createdAt',
    header: 'Impairment Date',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={dateConverter(new Date(getValue()))} />;
    },
  },
  {
    accessorKey: 'costBasis',
    header: 'Impaired Cost Basis',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <TwoLineAvatar
          label={formatTableNumbers({ value: getValue(), isAmount: false, decimalPlaces: 2 })}
          isAvatarVisible={false}
          textAlign={'text-right'}
        />
      );
    },
  },
  {
    accessorKey: 'impairmentQuantity',
    header: 'Quantity',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return <TwoLineAvatar label={getValue()} isAvatarVisible={false} textAlign={'text-right'} />;
    },
  },
  {
    accessorKey: 'journalEntrySequenceNumber',
    header: 'Journal Entry',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'impairmentRuleName',
    header: 'Impairment Rule',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
];
