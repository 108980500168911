import React from 'react';
import { formatStripeAmount } from '../utils';
import { LoadingComponent } from 'ui';
import { DisplayedAICreditRechargeOption } from '../types';
import Stripe from 'stripe';

const getCadenceDescription = (recurring: Stripe.Price['recurring']) => {
  if (!recurring) return '';
  if (recurring.interval_count > 1) return `every ${recurring.interval_count} ${recurring.interval}s`;
  return `per ${recurring.interval}`;
};

const getPriceDescription = (
  subscriptionPrice: Stripe.Price,
  aiCreditsRechargePrice?: DisplayedAICreditRechargeOption,
) => {
  // console.log(getCadenceDescription(subscriptionPrice.recurring));
  if (aiCreditsRechargePrice) {
    if (aiCreditsRechargePrice.price?.recurring)
      return `${formatStripeAmount(subscriptionPrice.unit_amount!)} + ${formatStripeAmount(aiCreditsRechargePrice.amount)} = ${formatStripeAmount(subscriptionPrice.unit_amount! + aiCreditsRechargePrice.amount)} ${getCadenceDescription(subscriptionPrice.recurring)}`;
    else
      return `${formatStripeAmount(subscriptionPrice.unit_amount!)} ${getCadenceDescription(subscriptionPrice.recurring)} + ${formatStripeAmount(aiCreditsRechargePrice.amount)} (one time)`;
  }
  return `${formatStripeAmount(subscriptionPrice.unit_amount!)} ${getCadenceDescription(subscriptionPrice.recurring)}`;
};

const getStripeProductFeatures = (product: Stripe.Product) => product.features.map((feature) => feature.name) ?? [];

export const SubscriptionPlan = ({
  product,
  subscriptionPrice,
  isLoading,
  aiCreditsRechargePrice,
  hidePriceDescription,
}: {
  product: Stripe.Product;
  subscriptionPrice?: Stripe.Price;
  isLoading?: boolean;
  aiCreditsRechargePrice?: DisplayedAICreditRechargeOption;
  hidePriceDescription?: boolean;
}) => {
  if (!subscriptionPrice) return null;
  const priceDescription = getPriceDescription(subscriptionPrice, aiCreditsRechargePrice);
  const features = getStripeProductFeatures(product);

  return (
    <div className='w-full border rounded-lg grid grid-cols-1 divide-y'>
      <div className='p-4'>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className='font-semibold text-lg'>{product.name}</div>
            <div className='text-gray-500'>
              First 14-days free {hidePriceDescription ? '' : ', then ' + priceDescription}
            </div>
          </>
        )}
      </div>
      <div className='p-4'>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div>Plan includes</div>
            <ul className='list-disc list-inside'>
              {features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
