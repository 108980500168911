import React, { forwardRef } from 'react';
import getStyles from './getStyles';
import { BaseButtonProps } from './types';
import { classNames } from '../utils';

const ButtonComponent = (
  {
    emphasis = 'high',
    status = 'neutral',
    variant = 'md',
    label,
    leadingIcon,
    trailingIcon,
    onClick,
    disabled,
    className = '',
    isFocused,
    disableRingOnFocus,
    trailingIconContainerClassname,
    leadingIconContainerClassname,
    labelContainerClassname,
    'data-cy': dataCy,
  }: BaseButtonProps,
  ref?: React.Ref<HTMLButtonElement>,
) => {
  const isIconOnly = !!(!label && ((!leadingIcon && trailingIcon) || (leadingIcon && !trailingIcon)));
  const styles = getStyles(status, emphasis, variant, isIconOnly);
  if (isIconOnly) styles.style.padding = 4;

  return (
    <button
      ref={ref}
      className={classNames(
        'isolate duration-300 items-center font-medium inline-flex item-center justify-center text-base disabled:opacity-40 disabled:cursor-not-allowed whitespace-nowrap',
        !disableRingOnFocus && typeof isFocused !== 'boolean' && 'focus:!ring-btn-focus-stroke focus:!ring-2',
        !disableRingOnFocus && typeof isFocused === 'boolean' && isFocused && '!ring-btn-focus-stroke !ring-2',
        styles.classes.container,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      style={styles.style}
      data-cy={dataCy}
    >
      {leadingIcon && (
        <span
          className={classNames(
            'h-full inline-flex items-center',
            isIconOnly ? 'm-0' : 'ml-2',
            styles.classes.icon,
            leadingIconContainerClassname,
          )}
        >
          {leadingIcon}
        </span>
      )}
      {label && (
        <span
          className={classNames(
            'h-full mx-2 inline-flex items-center base-button-label',
            styles.classes.label,
            trailingIcon ? 'ml-3' : '',
            leadingIcon ? 'mr-3' : '',
            labelContainerClassname,
          )}
        >
          {label}
        </span>
      )}
      {trailingIcon && (
        <span
          className={classNames(
            isIconOnly ? 'm-0' : 'mr-2',
            'h-full inline-flex items-center',
            styles.classes.icon,
            trailingIconContainerClassname,
          )}
        >
          {trailingIcon}
        </span>
      )}
    </button>
  );
};
export const BaseButton = forwardRef(ButtonComponent);
