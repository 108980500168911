import styled from 'styled-components';

export const Container = styled.div`
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 400px;
  background-color: white;
  border-width: 1px;
  overflow-y: auto;
  border-radius: 8px;
  overflow-y: visible;
`;
