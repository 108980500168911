import React from 'react';
import { SkeletonRow } from '../skeleton-row';
import { classNames } from '../utils';

export const PanelTransactionsSkeleton = ({ numOfColumns = 1 }: { numOfColumns: number }) => {
  return (
    <div className='border rounded-lg bg-white leading-5'>
      {[...Array(numOfColumns)].map((_, index) => {
        return (
          <div key={index} className={classNames('animate-pulse px-4 py-2', index && 'border-t')}>
            <div className='flex items-center w-full max-w-[300px] max-h-[60px]'>
              <div className='rounded-[50%] mr-3 w-[40px] h-[40px] bg-gradient-to-r from-zinc-200 to-white'></div>
              <div className='w-full mt-1'>
                <SkeletonRow size='sm' />
                <SkeletonRow />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PanelTransactionsSkeleton;
