import { ReactNode } from 'react';

export enum MESSAGE_TYPES {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  DANGER = 'DANGER',
  WARNING = 'WARNING',
}

export type MessageProps = {
  type?: MESSAGE_TYPES;
  text: ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
  onClose?: () => void;
  className?: string;
};
export type BaseMessageProps = {
  icon: ReactNode;
  text: ReactNode;
  action: ReactNode;
  onClose?: () => void;
  className?: string;
};
export type MessageActionProps = {
  label: string;
  onClick: () => void;
};
