import { Dispatch, SetStateAction } from 'react';
import { FilterRowState } from './types';

export const setAll =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  (values: T[]) =>
    setState(values.map((value) => ({ ...value, selected: false })));

export const onlySelect =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  (rowIndex: number) =>
    setState((prev) => prev.map((value, i) => ({ ...value, selected: i === rowIndex })));

export const selectAll =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  () =>
    setState((prev) => prev.map((value) => ({ ...value, selected: true })));

export const deselectAll =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  () =>
    setState((prev) => prev.map((value) => ({ ...value, selected: false })));
export const getLocalStorageKeyForTabState = (activeTabId: string, stateKey: string, pathname: string) =>
  `tabState_${activeTabId}_${stateKey}_${pathname}`;

export const clear =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  () =>
    setState([]);

export const setSelectionByRowIndex =
  <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) =>
  (rowIndex: number, selection: boolean = true) =>
    setState((prev) => prev.map((value, i) => ({ ...value, selected: rowIndex === i ? selection : value.selected })));

export const createSettersForFilter = <T = any>(setState: Dispatch<SetStateAction<FilterRowState<T>[]>>) => ({
  setAll: setAll(setState),
  onlySelect: onlySelect(setState),
  selectAll: selectAll(setState),
  deselectAll: deselectAll(setState),
  clear: clear(setState),
  setSelectionByRowIndex: setSelectionByRowIndex(setState),
});

export const getSelectedIds = (state) => state.ids.filter((id) => state.entities[id].selected);
export const getSelected = (state) =>
  state.ids.filter((id) => state.entities[id].selected).map((id) => state.entities[id]);
