import { useRouter } from 'next/router';
import { Sidebar, SidebarBody, SidebarTopBar } from '../atoms/Sidebar';
import { SidebarSection, SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import AssetSidebar from './Routes/AssetSidebar';
import EntitySidebar from './Routes/EntitySidebar';
import ImpairmentSidebar from './Routes/ImpairmentSidebar';
import JournalSidebar from './Routes/JournalSidebar';
import LedgerAccountSidebar from './Routes/LedgerAccountSidebar';
import RulesetSidebar from './Routes/RulesetSidebar';
import SourceSidebar from './Routes/SourceSidebar';
import TagSidebar from './Routes/TagSidebar';
import TemplateSidebar from './Routes/TemplateSidebar';
import { TransactionSidebar } from './Routes/TransactionSidebar';
import { SidebarProps } from './SidebarGlobal';
import { useTabState } from '../../hooks';
import { DEFAULT_WORKSPACE_TAB_STATE } from '../../constants';
import { BalanceOverviewSidebar } from './Routes/balance-overview-sidebar';
import { getUrlWithoutId } from '../utils';
import { InvoiceSidebar } from './Routes/invoice-sidebar';

import Stripe from 'stripe';

const SidebarManager = ({
  goBack,
  routeStack,
  isPrimary,
  defaultState = {},
  isDockPanelAvailable = false,
  route,
  params,
  extras = {},
}) => {
  const { activeTab, sidebarState, primaryPanel, secondRouteStack, updateTabSidebarState } = useTabState();
  const router = useRouter();

  const handlePrimaryPanelClose = async () => {
    if (isPrimary) {
      const url = getUrlWithoutId(router.pathname);

      await updateTabSidebarState(DEFAULT_WORKSPACE_TAB_STATE.sidebarState);
      router.push({ pathname: url, query: activeTab?.state?.filtersState ?? {} }, url, { scroll: false });
    } else {
      updateTabSidebarState({ secondRouteStack: [], secondRouteDockPanel: false });
    }
  };

  const onBack = routeStack.length > 1 ? goBack : undefined;

  if (!primaryPanel?.route?.length && !sidebarState?.secondRouteDockPanel) return null;

  if (!secondRouteStack?.length && !isPrimary) return null;

  const SidebarProps: SidebarProps = {
    isDockPanelAvailable,
    id: params?.id ?? '',
    onBack,
    isPrimary,
    handlePrimaryPanelClose,
    defaultState,
  };

  switch (route) {
    case 'balance-overview':
      return <BalanceOverviewSidebar {...(SidebarProps as any)} {...extras} />;
    case 'transactions':
      return <TransactionSidebar {...SidebarProps} />;
    case 'sources':
      return <SourceSidebar {...SidebarProps} />;
    case 'journals':
      return <JournalSidebar {...SidebarProps} />;
    case 'assets':
      return <AssetSidebar {...SidebarProps} />;
    case 'templates':
      return <TemplateSidebar {...SidebarProps} />;
    case 'rulesets':
      return <RulesetSidebar {...SidebarProps} />;
    case 'tags':
      return <TagSidebar {...SidebarProps} />;
    case 'impairment-rules':
      return <ImpairmentSidebar {...SidebarProps} />;
    case 'entities':
      return <EntitySidebar {...SidebarProps} />;
    case 'ledger-accounts':
      return <LedgerAccountSidebar {...SidebarProps} />;
    case 'invoices':
      return <InvoiceSidebar {...SidebarProps} {...(extras as { invoice: Stripe.Invoice })} />;
    default:
      return (
        <Sidebar>
          <SidebarTopBar onClose={handlePrimaryPanelClose} isDockPanelAvailable={false} />
          <SidebarHeader title={route} />
          <SidebarBody>
            <SidebarSectionHeader />
            <SidebarSection>
              <div>Route does not exist</div>
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      );
  }
};

export default SidebarManager;
