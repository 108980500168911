export enum FACT {
  ASSET = 'Asset',
  COINBASE_PRIME = 'Coinbase Prime',
  CHAIN = 'Chain',
  DATE_RECEIVED_ON = 'Date Received On',
  EXCHANGE = 'Exchange',
  GAIN = 'Gain',
  GAIN_TOLERANCE = 'Gain Tolerance',
  HEDGEY = 'Hedgey Event',
  MAPLE = 'Maple',
  LOOP = 'Loop',
  IS_IMPAIRED = 'Is Impaired',
  LAST_IMPAIRED_ON = 'Last Impaired On Date',
  LEDGER_ACCOUNT = 'Ledger Account',
  LEGAL_ENTITY = 'Legal Entity',
  LOSS = 'Loss',
  LOSS_TOLERANCE = 'Loss Tolerance',
  RAINCARDS = 'Raincards',
  RAINCARDS_SOURCE = 'Raincards Source',
  RAW_CONTRACT_ADDRESS = 'Raw Contract Address',
  SMART_CONTRACT_ADDRESS = 'Smart contract address',
  SOURCE = 'Source',
  TAG = 'Tag',
  TRANSACTION = 'Transaction',
  TRANSACTION_ACTION = 'Transaction Action',
  TRANSACTION_SMART_CONTRACT = 'Transaction Smart Contract',
  TRANSACTION_DIRECTION = 'Transaction Direction',
  TRANSACTION_CLASSIFICATION = 'Transaction Classification', // to our users it category
  WALLET = 'Wallet',
  TRANSACTION_MEMO = 'Transaction Memo',
}
export enum GATE_TYPE {
  AND = 'all',
  OR = 'any',
  NOT = 'not',
}

export type Condition = {
  id: string;
  fact: FACT;
  copyOf?: string;
  parentId: string;
  operator?: string;
  value?: any;
  path?: string;
};

export type ConditionGroup = {
  id: string;
  // type: GATE_TYPE;
  childIds: string[];
  gates: GATE_TYPE[];
  copyOf?: string;
  parentId?: string;
};

export type RuleConfiguratorState = {
  conditions: {
    [key: string]: Condition;
  };
  conditionIds: string[];
  conditionGroups: {
    [key: string]: ConditionGroup;
  };
  conditionGroupIds: string[];
  isDisabled?: boolean;
  configuratorType: RULE_CONFIGURATOR_TYPE;
  isLoading?: boolean;
};

export type Gate = {
  id: string;
  type: GATE_TYPE;
  childIds: string[];
  copyOf?: string;
  parentId?: string;
};

export enum RULE_CONFIGURATOR_TYPE {
  ACCOUNT_POSTING = 'ACCOUNT_POSTING',
  IMPAIRMENT = 'IMPAIRMENT',
}

export type RulesEngineState = {
  conditions: {
    [key: string]: Condition;
  };
  conditionIds: string[];
  gates: {
    [key: string]: Gate;
  };
  gateIds: string[];
  isDisabled?: boolean;
  configuratorType: RULE_CONFIGURATOR_TYPE;
};

export enum GATE_CHILD_TYPE {
  GATE = 'GATE',
  CONDITION = 'CONDITION',
}

export enum CONDITION_GROUP_CHILD_TYPE {
  CONDITION_GROUP = 'CONDITION_GROUP',
  CONDITION = 'CONDITION',
}
export interface ConditionItemsWrapperProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

export interface ConditionItemProps extends Omit<React.HTMLProps<HTMLDivElement>, 'label'> {
  label: string | React.ReactNode;
  children?: React.ReactNode;
  img?: string;
  isInLineCondition?: boolean;
  checked?: boolean;
}

export interface ConditionChildrenProps {
  label: string | React.ReactNode;
  children: React.ReactNode;
  isInLineCondition?: boolean;
  setOpen: (data: boolean) => void;
}
export type RuleDropdownDataRow = {
  name: string;
  data: {
    label: string;
    description: string;
    values?: any[];
    ids?: {
      operators: { label: string; value: string; placeholder?: string; showSelectedIcon?: boolean }[];
      values?: any[];
      valuesTwo?: any[];
      paths?: { label: string; value: string }[];
    };
  }[];
};

export enum RULE_ENGINE_CONDITION_TYPE {
  RECENTS = 'Recents',
  ASSET = 'Asset',
  CHAIN = 'Chain',
  COINBASE_PRIME = 'Coinbase Prime',
  DATE_RECEIVED_ON = 'Date Received On',
  GAIN = 'Gain',
  GAIN_TOLERANCE = 'Gain Tolerance',
  HEDGEY_EVENT = 'Hedgey Event',
  IS_IMPAIRED = 'Is Impaired',
  LAST_IMPAIRED_ON_DATE = 'Last Impaired On Date',
  LEDGER_ACCOUNT = 'Ledger Account',
  LEGAL_ENTITY = 'Legal entity',
  LOSS = 'Loss',
  LOSS_TOLERANCE = 'Loss Tolerance',
  NEST_CONDITION = 'Nest Condition',
  RAINCARDS = 'Raincards',
  RAINCARDS_SOURCE = 'Raincards Source',
  RAW_CONTRACT_ADDRESS = 'Raw Contract Address',
  SMART_CONTRACT_ADDRESS = 'Smart contract address',
  TAG = 'Tag',
  TRANSACTION = 'Transaction',
  TRANSACTION_DIRECTION = 'Transaction Direction',
  WALLET = 'Wallet',
}
import { DropDownCheckboxOptions } from '../../atoms/DropDownCheckbox/types';

export type allFilterProps = {
  searchFilter: string;
  typesFilter: string[];
  chainsFilter: DropDownCheckboxOptions;
};

export interface SearchFilterProps {
  data: Array<any>;
  renderItem: (item: any) => React.ReactNode;
  type?: string;
}

export interface ConditionMenusProps {
  ruleSetData?: any;
  getRuleSetData?: any;
  isFirstLevel?: boolean;
  isInLineCondition?: boolean;
  callbackFn?: (data: any) => void;
  closeDialog?: () => void;
  showTags?: boolean;
  type?: 'checkbox' | 'dropdown';
  getData?: (data: any) => void;
}

export type ConditionMenusBodyProps = ConditionMenusProps & {
  filterERC: string[];
  setFilterERC: React.Dispatch<React.SetStateAction<string[]>>;
  assetTypes: string[];
  assetsTypeLoading: boolean;
  filterAssets: (arr: any, searchValue: any) => any;
};
