import { useEffect, useMemo } from 'react';
import { useInvalidateQuery, usePatchOrganizationById, useSession } from '../../hooks';
import { Button, InputLabel, TimezonePicker } from 'ui';
import { useGetOrganizationByIdQuery } from '../../hooks/http/organization-service';
import toast from 'react-hot-toast';
import { deriveError } from '../templates/utils';

export const TimezoneDropdown = ({ timezone, setTimezone, onlyDropdown = false }) => {
  const { organizationId, setters } = useSession();
  const { data: organization } = useGetOrganizationByIdQuery(organizationId);

  useEffect(() => {
    if (organization?.timezone) setTimezone(organization.timezone);
  }, [organization]);

  const { invalidateGetOrganizationById } = useInvalidateQuery();
  const { mutateAsync: patchOrganization, isLoading } = usePatchOrganizationById();

  const disabled = useMemo(
    () => isLoading || !organization || !timezone || timezone === organization.timezone,
    [isLoading, organization, timezone],
  );

  const onSave = async (timezone: string) => {
    const toastId = toast.loading('Saving timezone setting');

    try {
      if (timezone) {
        await patchOrganization({
          organizationId,
          update: { timezone },
        });
        await invalidateGetOrganizationById(organizationId);
        setters.setSelectedOrganization((prev) => (prev ? { ...prev, timezone } : undefined));
      }
      toast.success(`Timezone updated`, { id: toastId });
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  if (onlyDropdown)
    return (
      <div className='max-w-sm relative'>
        <TimezonePicker value={timezone} onChange={setTimezone} />
      </div>
    );

  return (
    <div className='relative'>
      <InputLabel heading='Select timezone' description='This affects all the data displayed in the application' />
      <div className='max-w-lg'>
        <div className='flex items-center gap-x-2'>
          <div className='max-w-sm'>
            <TimezonePicker value={timezone} onChange={setTimezone} />
          </div>

          <div>
            <Button
              label='Save'
              variant='md'
              onClick={() => onSave(timezone)}
              isLoading={isLoading}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
