import { HiMenuAlt2 } from 'react-icons/hi';
import { RiEyeOffFill } from 'react-icons/ri';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';

import { Dropdown, DropdownContent, DropdownTrigger } from '../dropdown';
import { NumberTableHeaderProps } from './types';
import { useState } from 'react';
import { classNames } from '../utils';

export function NumberHeader({
  label,
  size = 'default',
  sort = false,
  sortFn,
  hideFn,
  resetSorting,
  enableHide = true,
  enableSort = true,
  containerClassNames = '',
}: NumberTableHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classNames(
        'flex items-center gap-3 bg-zinc-100 text-right flex-row-reverse',
        size === 'small' && 'px-4',
        size === 'default' && 'px-6',
        containerClassNames,
      )}
    >
      <span className='text-zinc-600 font-medium leading-6'>{label}</span>

      {(enableHide || enableSort) && (
        <Dropdown open={isOpen} onOpenChange={setIsOpen}>
          <DropdownTrigger>
            <button>
              {!sort && <HiMenuAlt2 className='w-9 h-9 p-2 text-zinc-400 scale-x-[-1]' />}
              {sort === 'asc' && <GoArrowUp className='w-9 h-9 p-2 text-zinc-400' />}
              {sort === 'desc' && <GoArrowDown className='w-9 h-9 p-2 text-zinc-400' />}
            </button>
          </DropdownTrigger>
          <DropdownContent
            align='end'
            className='z-sidepanel p-1 flex flex-col gap-1 text-left border-none shadow-[0px_16px_32px_-4px_rgba(0,_0,_0,_0.12)] min-w-full w-[14rem] rounded-lg'
          >
            {enableSort && (
              <>
                <button
                  onClick={() => {
                    if (sort === 'asc') {
                      resetSorting();
                      setIsOpen(false);
                      return;
                    }
                    sortFn(false);
                    setIsOpen(false);
                  }}
                  className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-lg hover:cursor-pointer hover:bg-indigo-50'
                >
                  <GoArrowUp className='w-6 h-6 py-0.5' />
                  <span>Sort Ascending</span>
                </button>
                <button
                  onClick={() => sortFn(true)}
                  className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-lg hover:cursor-pointer hover:bg-indigo-50'
                >
                  <GoArrowDown className='w-6 h-6 py-0.5' />
                  <span>Sort Descending</span>
                </button>
              </>
            )}
            {enableHide && (
              <button
                onClick={() => hideFn(false)}
                className='flex gap-2 items-center px-2 py-1.5 text-zinc-900 leading-4 rounded-lg hover:cursor-pointer hover:bg-indigo-50'
              >
                <RiEyeOffFill className='w-6 h-6 py-0.5' />
                <span>Hide</span>
              </button>
            )}
          </DropdownContent>
        </Dropdown>
      )}
    </div>
  );
}
