import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { getChains, GetChainsParams } from 'services';

export const useChains = ({ legalEntityIds, sourceIds }: GetChainsParams) => {
  const getKey = useOrgBasedQueryKey();
  return useQuery(getKey(QUERY_KEY.CHAINS), async () => {
    const response = await getChains({ legalEntityIds, sourceIds });
    return response.data.chains;
  });
};
