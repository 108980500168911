import React from 'react';
import { SidebarSection, SidebarSectionHeader } from '../../../atoms';
import { AreaChart, Card, Metric, Subtitle, Text } from '@tremor/react';
import { SkeletonRow } from 'ui';
import { formatDollars } from '../../../templates/utils';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { formatDate } from 'services';
import { useGetTansactionAssetRelativePrices } from '../../../../hooks';
import { AssetRelativePriceData, RelativePriceTimeSeriesPoint } from 'services/http/analytics/assets';
import { format } from 'date-fns';
import { getCaretColor } from './utils';

const PriceBreakdown = ({ transaction, transactionId }) => {
  const { data: transactionAssetPriceData, isLoading: transactionAssetPriceLoading } =
    useGetTansactionAssetRelativePrices({ transactionId });
  const assetUnitPrice = transaction?.assetUnitPrice.$numberDecimal;

  const formattedTransactionAssetPriceData = formatRelativePriceData(transactionAssetPriceData?.data);

  const color = getCaretColor(transactionAssetPriceData?.data.fiveDaysPercentageChange || 0);

  const percentageChange = transactionAssetPriceData?.data.fiveDaysPercentageChange || 0;

  return (
    <>
      <SidebarSectionHeader title='Price breakdown' />
      <SidebarSection numberOfColumns={1}>
        <Card>
          <div className='mb-4 flex justify-between'>
            {!transactionAssetPriceData || !transaction ? (
              <SkeletonRow />
            ) : (
              <div>
                <Metric>{formatDollars(assetUnitPrice)}</Metric>
                <Subtitle className='flex items-center' color={color}>
                  {percentageChange}%{' '}
                  {percentageChange < 0 ? <MdArrowDropDown /> : percentageChange > 0 ? <MdArrowDropUp /> : null} past 5
                  days
                </Subtitle>
              </div>
            )}
          </div>
          {transactionAssetPriceLoading ? (
            <div className='h-[300px] animate-pulse bg-gray-50' />
          ) : (
            <div>
              <AreaChart
                data={formattedTransactionAssetPriceData?.timeSeriesPrices || []}
                index={'timestamp'}
                categories={['price']}
                showLegend={false}
                valueFormatter={(value) => formatDollars(value)}
                yAxisWidth={
                  Math.max(
                    ...(formattedTransactionAssetPriceData?.timeSeriesPrices.map((i) => parseInt(i.price)) || [1000]),
                  ).toFixed(2).length * 9 || 60
                }
                startEndOnly
                autoMinValue
                showXAxis={false}
              />
              <div className='flex justify-between pl-[70px]'>
                <Text>
                  {formatDate(new Date(formattedTransactionAssetPriceData?.timeSeriesPrices[0].timestamp as number))}
                </Text>
                <Text>
                  {formatDate(
                    new Date(
                      formattedTransactionAssetPriceData?.timeSeriesPrices[
                        formattedTransactionAssetPriceData?.timeSeriesPrices.length - 1
                      ].timestamp as number,
                    ),
                  )}
                </Text>
              </div>
            </div>
          )}
        </Card>
      </SidebarSection>
    </>
  );
};

export default PriceBreakdown;

const formatRelativePriceData = (data: AssetRelativePriceData['data'] | undefined) => {
  if (!data) return;
  const timeSeriesData: RelativePriceTimeSeriesPoint[] = [];
  data.timeSeriesPrices.forEach((item) => {
    timeSeriesData.push({ timestamp: format(new Date(item.timestamp), 'MM/dd/yyyy HH:mm'), price: item.price });
  });

  return {
    ...data,
    timeSeriesPrices: timeSeriesData,
  };
};
