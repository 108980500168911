import React, { useState } from 'react';
import { Dialog } from 'ui';

import { ClassifyTransactionModalTrigger } from './trigger';
import { ClassifyTransactionModalContent } from './content';

export const ClassifyTransactionModal = ({ transactionIds, showTrigger = true, onSuccess, triggerProps = {} }) => {
  const [open, onOpenChange] = useState(false);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {showTrigger && <ClassifyTransactionModalTrigger {...triggerProps} onClick={() => onOpenChange(true)} />}
      <ClassifyTransactionModalContent
        transactionIds={transactionIds}
        onSuccess={onSuccess}
        onOpenChange={onOpenChange}
      />
    </Dialog>
  );
};
