import { useMemo } from 'react';
import { classNames } from '../utils';
import { PageHeaderDataCy, PageHeaderProps } from './types';
import useDimensions from 'react-cool-dimensions';

export const PageHeader = ({ title, description, actions, logo }: PageHeaderProps) => {
  const { observe: containerRef, width: containerWidth } = useDimensions();
  const shouldShrink = useMemo(() => containerWidth < 500, [containerWidth]);

  return (
    <div ref={containerRef} className='w-full px-6 mt-6 mb-12'>
      <div
        className={classNames('w-full flex', shouldShrink ? 'flex-col items-start' : 'items-center justify-between')}
      >
        <div className='flex items-center gap-2'>
          {logo}
          <div className='flex flex-col gap-4'>
            <div
              data-cy={`${PageHeaderDataCy.Title}${title.toLowerCase().split(' ').join('_')}`}
              className='text-4xl font-semibold'
            >
              {title}
            </div>
            {description && (
              <div
                data-cy={`${PageHeaderDataCy.Description}${title.toLowerCase().split(' ').join('_')}`}
                className='max-w-450 whitespace-normal'
              >
                {description} &emsp;
              </div>
            )}
          </div>
        </div>

        {actions && (
          <div className={classNames('flex items-center', shouldShrink ? 'mt-3' : 'flex-row-reverse')}>
            {actions} &emsp;
          </div>
        )}
      </div>
    </div>
  );
};
