import JournalDetails from '../../jorunalEntry/JournalDetails';
import { SidebarProps } from '../SidebarGlobal';

const JournalSidebar: React.FC<SidebarProps> = ({
  id,
  isDockPanelAvailable,
  handlePrimaryPanelClose,
  onBack,
  isPrimary,
}) => {
  return (
    <JournalDetails
      journalEntryId={id}
      onCancel={() => {
        handlePrimaryPanelClose && handlePrimaryPanelClose();
      }}
      hideRoot
      isDockPanelAvailable={isDockPanelAvailable}
      onBack={onBack}
      isPrimary={isPrimary}
    />
  );
};

export default JournalSidebar;
