import { SidebarProps } from '../SidebarGlobal';
import { ManageSourceSidebarContent } from '../../Sources';

const SourceSidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  handlePrimaryPanelClose,
  isDockPanelAvailable,
  isPrimary,
  defaultState,
}) => {
  return (
    <ManageSourceSidebarContent
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      sourceId={id}
      isPrimary={isPrimary}
      defaultState={defaultState}
    />
  );
};

export default SourceSidebar;
