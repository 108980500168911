import React, { useState } from 'react';
import { Button, Modal, ModalContent, ModalTrigger } from 'ui';

export const CancelSubscriptionModal = () => {
  const [open, setOpen] = useState(false);
  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Add payment method' onClick={() => setOpen(true)} />
      </ModalTrigger>
      <ModalContent
        title='Cancel subscription'
        description='Your subscription will be available until the end of this billing period. '
        actions={[
          { label: 'Cancel subscription', status: 'danger' },
          { label: 'Cancel', emphasis: 'medium', onClick: () => setOpen(false) },
        ]}
      >
        {/* Add stripe elements here */}
      </ModalContent>
    </Modal>
  );
};
