import React, { useState } from 'react';
import { Sidebar, SidebarTopBar } from '../atoms/Sidebar';
import { ViewImpairmentRuleSidebarContent } from './ViewImpairmentRuleSidebarContent';
import { useRouter } from 'next/router';
import { PatchImpairmentRuleSidebarContent } from './PatchImpairmentRuleSidebarContent';

enum IMPAIRMENT_SIDEBAR_MODE {
  VIEW = 'view',
  PATCH = 'patch',
}

export function ManageImpairmentRuleSidebarContent({
  onClose,
  isDockPanelAvailable,
  onBack,
  impairmentRuleId,
  isPrimary,
}) {
  const [mode, setMode] = useState<IMPAIRMENT_SIDEBAR_MODE>(IMPAIRMENT_SIDEBAR_MODE.VIEW);
  const router = useRouter();
  const dataCy = mode === IMPAIRMENT_SIDEBAR_MODE.VIEW ? 'viewImpairmentRule' : 'patchImpairmentRule';
  return (
    <Sidebar data-cy={dataCy}>
      <SidebarTopBar
        onClose={onClose}
        isDockPanelAvailable={isDockPanelAvailable}
        onBack={onBack}
        itemId={impairmentRuleId}
        isPrimary={isPrimary}
        data-cy={dataCy}
      />
      {mode === IMPAIRMENT_SIDEBAR_MODE.VIEW && (
        <ViewImpairmentRuleSidebarContent
          data-cy={dataCy}
          impairmentRuleId={impairmentRuleId}
          onPatch={() => setMode(IMPAIRMENT_SIDEBAR_MODE.PATCH)}
        />
      )}
      {mode === IMPAIRMENT_SIDEBAR_MODE.PATCH && (
        <PatchImpairmentRuleSidebarContent
          data-cy={dataCy}
          onCancel={() => setMode(IMPAIRMENT_SIDEBAR_MODE.VIEW)}
          impairmentRuleId={impairmentRuleId}
          onDelete={() => {
            router.replace('/configure/impairment-rules');
            onClose();
          }}
        />
      )}
    </Sidebar>
  );
}
