import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  getTransactionById,
  getTransactions,
  patchTransaction,
  getTransactionStats,
  postTransactionExternalSources,
  bulkEditTransactionsCostBasis,
  bulkRecategorizeTransactions,
  BulkUpdateTransactionMemoParams,
  bulkUpdateTransactionMemo,
} from 'services/http/core';
import { useAddExternalSourceProps, useUpdateTransactionProps } from './type';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export type GetTransactionsQueryFilter = Partial<{
  pageSize: number;
  directions: string[];
  chains: string[];
  transactionFrom: string;
  transactionTo: string;
  transactionDate: string;
  accountingPeriodIds: string[];
  legalEntityIds: string[];
  assetTypes: string[];
  walletTypesFilter: string[];
  sort: {
    id: string;
    desc: boolean;
  };
  showTransactionsWithoutJournalEntries: boolean;
  searchTerm: string;
  addresses: string[];
  sourceIds: string[];
  walletIds: string[];
  classifications: string[];
  includeSpam?: boolean;
  grossPrice?: string;
  minGrossPrice?: string;
  maxGrossPrice?: string;
  accountingPeriodStartDateUTCs?: string[];
  asc?: string[];
  desc?: string[];
}>;

export const useSpamTransactions = (params?: GetTransactionsQueryFilter, useInfiniteQueryOptions = {}) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  const infiniteQueryOptions = {
    enabled: !!organizationId,
    getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
    getNextPageParam: (lastPage) => {
      if (lastPage?.transactions?.length === 25) return lastPage.nextPage;
      return undefined;
    },
    refetchOnWindowFocus: false,
    ...useInfiniteQueryOptions,
  };
  return useInfiniteQuery(
    getKey(QUERY_KEY.SPAM_TRANSACTIONS, params),
    async ({ pageParam = 0 }) => {
      const response = await getTransactions({
        page: pageParam,
        organizationId,
        ...params,
        spamOnly: true,
        directions: params?.directions ?? ['Debit', 'Credit'],
        classifications: params?.classifications ?? [],
      });
      return response.data;
    },
    infiniteQueryOptions,
  );
};

export const useTransactions = (params?: GetTransactionsQueryFilter, useInfiniteQueryOptions = {}) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  const infiniteQueryOptions = {
    enabled: !!organizationId,
    getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
    getNextPageParam: (lastPage) => {
      if (lastPage?.transactions?.length === 25) return lastPage.nextPage;
      return undefined;
    },
    refetchOnWindowFocus: false,
    ...useInfiniteQueryOptions,
  };
  return useInfiniteQuery(
    getKey(QUERY_KEY.TRANSACTIONS, params),
    async ({ pageParam = 0 }) => {
      const response = await getTransactions({
        page: pageParam,
        organizationId,
        ...params,
        directions: params?.directions ?? ['Debit', 'Credit'],
        classifications: params?.classifications ?? [],
      });
      return response.data;
    },
    infiniteQueryOptions,
  );
};

export const usePoolPosition = (params?: GetTransactionsQueryFilter, useInfiniteQueryOptions = {}) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  const infiniteQueryOptions = {
    enabled: !!organizationId,
    getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
    getNextPageParam: (lastPage) => {
      if (lastPage?.transactions?.length === 25) return lastPage.nextPage;
      return undefined;
    },
    refetchOnWindowFocus: false,
    ...useInfiniteQueryOptions,
  };
  return useInfiniteQuery(
    getKey(QUERY_KEY.TRANSACTIONS, params),
    async ({ pageParam = 0 }) => {
      const response = await getTransactions({
        page: pageParam,
        organizationId,
        ...params,
        directions: params?.directions ?? ['Debit', 'Credit'],
        classifications: params?.classifications ?? [],
      });
      return response.data;
    },
    infiniteQueryOptions,
  );
};

export const useTransactionById = (transactionId: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.TRANSACTION_BY_ID, { transactionId }),
    async () => {
      const response = await getTransactionById({
        organizationId,
        transactionIds: [transactionId],
        includeSpam: true,
        page: 0,
      });
      return response.data;
    },
    {
      enabled: !!transactionId && !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      cacheTime: 5000,
    },
  );
};

export const useUpdateTransaction = () => useMutation((data: useUpdateTransactionProps) => patchTransaction(data));

export const useAddExternalSource = () =>
  useMutation((data: useAddExternalSourceProps) => postTransactionExternalSources(data));

type useTransactionStatsParams = Omit<GetTransactionsQueryFilter, 'pageSize' | 'sort' | 'searchTerm'>;

export const useTransactionStats = (params: useTransactionStatsParams) => {
  const {
    chains,
    classifications,
    directions = ['Debit', 'Credit'],
    transactionFrom,
    transactionTo,
    accountingPeriodIds,
    legalEntityIds,
    assetTypes,
    walletTypesFilter,
    addresses,
    walletIds,
    sourceIds,
    showTransactionsWithoutJournalEntries,
    includeSpam,
    grossPrice,
    minGrossPrice,
    maxGrossPrice,
    accountingPeriodStartDateUTCs,
  } = params;

  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();

  return useQuery(
    getKey(QUERY_KEY.TRANSACTION_STATS, params),
    async () => {
      const response = await getTransactionStats({
        organizationId,
        chains,
        classifications,
        directions,
        transactionFrom,
        transactionTo,
        accountingPeriodIds,
        legalEntityIds,
        assetTypes,
        walletTypesFilter,
        addresses,
        walletIds,
        sourceIds,
        showTransactionsWithoutJournalEntries,
        includeSpam,
        grossPrice,
        minGrossPrice,
        maxGrossPrice,
        accountingPeriodStartDateUTCs,
      });
      return response?.data ?? [];
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useBulkEditTransactionsCostBasis = () => {
  return useMutation((data: { _id: string; costBasis: number }[]) => bulkEditTransactionsCostBasis(data));
};

export type BulkCategorizeTransactionsParams = {
  transactionIds: string[];
  classification: string; // key of CLASSIFICATION_ROW: INTERNAL_TRANSFER | BRIDGE etc
};
export const useBulkRecategorizeTransactions = () => {
  return useMutation((data: BulkCategorizeTransactionsParams) => bulkRecategorizeTransactions(data));
};

export const useBulkUpdateTransactionMemo = () => {
  return useMutation((data: BulkUpdateTransactionMemoParams) => bulkUpdateTransactionMemo(data));
};
