import { ManageImpairmentRuleSidebarContent } from '../../impairment-rules';
import { SidebarProps } from '../SidebarGlobal';

const ImpairmentSidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  isDockPanelAvailable,
  handlePrimaryPanelClose,
  isPrimary,
}) => {
  return (
    <ManageImpairmentRuleSidebarContent
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      impairmentRuleId={id}
      isPrimary={isPrimary}
    />
  );
};

export default ImpairmentSidebar;
