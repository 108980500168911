import { useSession } from './useSession';
import { useSyncJournalEntriesJobMutation } from './http/useGlIntegrationAccountQueries';
import { GLType } from 'services/http/types';

export type CreateSyncJournalEntriesBgJobProps = {
  onSuccess?: () => void;
  onError?: (error: any) => void;
  realmId: string;
};

export const useCreateSyncJournalEntriesBgJob = ({
  onSuccess,
  onError,
  realmId,
}: CreateSyncJournalEntriesBgJobProps) => {
  const { userId } = useSession();

  const { mutateAsync } = useSyncJournalEntriesJobMutation();

  const createJournalEntryJob = async (accountingPeriodId: string, integrationType: GLType) => {
    try {
      await mutateAsync({
        userId,
        accountingPeriodId,
        integrationType,
        realmId,
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return createJournalEntryJob;
};
