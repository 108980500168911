import React from 'react';
import { components } from 'react-select';

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props} className='whitespace-nowrap'>
      No data found
    </components.NoOptionsMessage>
  );
};

export default NoOptionsMessage;
