import React, { useState } from 'react';
import { DialogContent, InputLabel, InputWithExtras } from 'ui';
import { useBulkUpdateTransactionMemo, useInvalidateQuery } from '../../../../hooks';
import { deriveError } from '../../../templates/utils';
import toast from 'react-hot-toast';

export const BulkUpdateTransactionMemoModalContent = ({
  transactionIds,
  onSuccess = () => {},
  onOpenChange,
}: {
  transactionIds: string[];
  onSuccess;
  onOpenChange;
}) => {
  const { invalidateTransactions } = useInvalidateQuery();
  const { mutateAsync: bulkUpdateTransactionMemo, isLoading: createJournalEntriesLoading } =
    useBulkUpdateTransactionMemo();
  const [memo, setMemo] = useState('');
  return (
    <DialogContent
      actions={[
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              await bulkUpdateTransactionMemo({
                memo,
                transactionIds,
              });
              onOpenChange(false);
              toast.success(`${transactionIds.length === 1 ? 'Transaction' : 'Transactions'} classified`);
              onSuccess();
              await invalidateTransactions();
            } catch (error) {
              toast.error(deriveError(error));
            }
          },
          disabled: createJournalEntriesLoading,
          isLoading: createJournalEntriesLoading,
        },
        {
          label: 'Cancel',
          emphasis: 'medium',
          onClick: () => {
            onOpenChange(false);
            setMemo('');
          },
        },
      ]}
    >
      <div>
        <InputLabel
          heading='Transaction Memo'
          description={`Updating memo for ${transactionIds.length} ${transactionIds.length === 1 ? 'transaction' : 'transactions'}`}
        />
        <InputWithExtras isTextarea rows={3} value={memo} onChange={(e) => setMemo(e.target.value)} />
      </div>
    </DialogContent>
  );
};
