import React, { useMemo, useState } from 'react';

export const TanStackContext = React.createContext<{
  isDragging: boolean;
  setIsDragging: (check: boolean) => void;
}>({
  isDragging: false,
  setIsDragging: (data) => data,
});

function TanStackTableProvider({ children, initialData }) {
  const [isDragging, setIsDragging] = useState(initialData?.isDragging ?? false);
  const isDraggingMemo = useMemo(() => ({ isDragging, setIsDragging }), [isDragging]);

  return (
    <TanStackContext.Provider
      value={{
        ...isDraggingMemo,
      }}
    >
      {children}
    </TanStackContext.Provider>
  );
}

export default React.memo(TanStackTableProvider);
