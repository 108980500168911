import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';
import { AccountingPeriod, LedgerAccount } from 'schemas';

export type JournalEntriesVolumeByLedgerAccountType = {
  data: {
    incomeStatement: Record<
      string,
      {
        Income: Record<string, string>;
        Expense: Record<string, string>;
      }
    >;
    balanceSheet: Record<
      string,
      {
        Asset: Record<string, string>;
        Liability: Record<string, string>;
        Equity: Record<string, string>;
      }
    >;
    ledgerAccounts: LedgerAccount[];
    accountingPeriods: AccountingPeriod[];
  };
};

export const getJournalEntryVolume = async (data) => {
  const { accountingPeriodIds, legalEntityIds, status, ledgerAccountIds } = data;

  const stringQuery = prepareSearchParams({ accountingPeriodIds, legalEntityIds, status, ledgerAccountIds });

  return axios.get<JournalEntriesVolumeByLedgerAccountType>(
    `${SERVER_URL_ANALYTICS}/get-journal-entry-volume?${stringQuery}`,
  );
};
