import * as React from 'react';
import { forwardRef } from 'react';
const SvgNotificationsNone = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M48 88c4.4 0 8-3.6 8-8H40c0 4.4 3.6 8 8 8Zm24-24V44c0-12.28-6.52-22.56-18-25.28V16c0-3.32-2.68-6-6-6s-6 2.68-6 6v2.72C30.56 21.44 24 31.68 24 44v20l-8 8v4h64v-4l-8-8Zm-8 4H32V44c0-9.92 6.04-18 16-18s16 8.08 16 18v24Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNotificationsNone);
export default ForwardRef;
