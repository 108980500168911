import {
  Avatar,
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownContent,
  DropdownTrigger,
  InputLabel,
  LOADER_ICON_SIZE,
  LoaderIcon,
  SearchInput,
  SelectableCard,
  capitalizeFirstLetter,
  classNames,
  currencyImg,
} from 'ui';

import { ExchangeSource, Tag, Wallet } from 'schemas';
import { MdArrowDropDown, MdCheck } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { formatExchangeTypeToName } from 'services/http';
import { useGetExchangeSources } from '../../hooks/http/useExchangeSource';
import { useSourceTags, useSources } from '../../hooks';
import { Filters, SourceGroup, SourcePickerProps, Sources } from './types';
import { getFilteredSources, getNewChainOptionsList } from './utils';
import { FilterFn, getExchangeName } from '../rules/configurator';
import { getWalletTags } from '../rules/utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { useGetTableGroups } from '../../hooks/http/table-groups';

export const filterWalletsFn: FilterFn<Wallet> = (searchTerm) => (wallet) =>
  !!(
    wallet.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    wallet.addresses?.find((address) => address.toLowerCase().includes(searchTerm.toLowerCase())) ||
    wallet.chain.toLowerCase().includes(searchTerm.toLowerCase()) ||
    wallet.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const filterExchangesFn: FilterFn<ExchangeSource> = (searchTerm) => (exchange) =>
  !!exchange.exchangeSourceType?.toLowerCase().includes(searchTerm.toLowerCase());

export const filterTagsFn: FilterFn<Tag> = (searchTerm) => (tag) =>
  !!(
    tag.entry.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tag.entry.value.toLowerCase().includes(searchTerm.toLowerCase())
  );

const useAllSources = ({ searchTerm }) => {
  const { data: walletPages, isLoading: isLoadingWallets } = useSources({ pageSize: 1000 });
  const [allWallets, setAllWallets] = useState<Wallet[]>([]);

  const { data: exchanges, isLoading: isLoadingExchanges } = useGetExchangeSources();
  const [allExchanges, setAllExchanges] = useState<ExchangeSource[]>([]);

  const { data: tagPages, isLoading: isLoadingTags } = useSourceTags();

  const { data: sourceGroupsData, isLoading: isLoadingTableGroups } = useGetTableGroups('Source');

  const sourceGroups = sourceGroupsData?.groups ?? [];

  const [allTags, setAllTags] = useState<Tag[]>([]);

  const [filtered, setFiltered] = useState<Sources>({
    wallets: allWallets,
    exchanges: allExchanges,
    tags: allTags,
    groups: sourceGroups,
  });

  useEffect(() => {
    if (walletPages)
      setAllWallets(walletPages.pages.map((wr) => wr.wallets.map((wallet) => ({ ...wallet, selected: false }))).flat());
  }, [walletPages]);
  useEffect(() => {
    if (exchanges) {
      setAllExchanges(
        exchanges?.sources
          ?.filter((source) => source.exchangeSourceType !== 'CIRCLE')
          .map((source) => ({
            ...source,
            chain: 'Exchange',
            address: formatExchangeTypeToName(source.exchangeSourceType),
            walletType: 'internal',
            selected: false,
          })),
      );
    }
  }, [exchanges]);
  useEffect(() => {
    if (tagPages) setAllTags(getWalletTags(tagPages));
  }, [tagPages]);

  useEffect(() => {
    setFiltered(() => {
      const next: Sources = { wallets: allWallets, exchanges: allExchanges, tags: allTags, groups: sourceGroups };
      if (!searchTerm.length) return next;
      next.wallets = next.wallets.filter(filterWalletsFn(searchTerm));
      next.exchanges = next.exchanges.filter(filterExchangesFn(searchTerm));
      next.tags = next.tags.filter(filterTagsFn);
      next.groups = next.groups.filter((group) => group.name.toLowerCase().includes(searchTerm.toLowerCase()));
      return next;
    });
  }, [searchTerm, allWallets, allExchanges, sourceGroups]);

  return {
    filtered,
    isLoadingWallets,
    isLoadingExchanges,
    isLoadingTags,
    isLoadingTableGroups,
    isLoading: isLoadingWallets || isLoadingExchanges || isLoadingTags || isLoadingTableGroups,
  };
};

export const SourcePicker = ({ searchTerm, setSearchTerm, defaultValue, onChange, hideTags }: SourcePickerProps) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    isInternal: false,
    isExternal: false,
    isTagged: false,
    isExchange: false,
    chains: [],
  });
  const { filtered: filteredBySearchTerm, isLoading } = useAllSources({ searchTerm });

  const [filteredData, setFilteredData] = useState<Sources>({
    groups: [],
    wallets: [],
    exchanges: [],
    tags: [],
  });

  useEffect(() => {
    setFilters((prev) => ({ ...prev, chains: getNewChainOptionsList(prev.chains, filteredBySearchTerm.wallets) }));
  }, [filteredBySearchTerm.wallets]);

  useEffect(() => {
    setFilteredData(getFilteredSources(filteredBySearchTerm, filters));
  }, [filters, filteredBySearchTerm]);

  return (
    <div className='pt-2'>
      <div className='mb-2'>
        <SearchInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} containerClassName='w-full' />
      </div>
      <div className='flex flex-wrap gap-2 mb-2'>
        <SelectableCard
          className='px-2 py-0 h-10 w-fit'
          label='Internal'
          selected={filters.isInternal}
          onClick={() => setFilters((prev) => ({ ...prev, isInternal: !prev.isInternal }))}
        />
        <SelectableCard
          className='px-2 py-0 h-10 w-fit'
          label='External'
          selected={filters.isExternal}
          onClick={() => setFilters((prev) => ({ ...prev, isExternal: !prev.isExternal }))}
        />
        {!hideTags && (
          <SelectableCard
            className='px-2 py-0 h-10 w-fit'
            label='Tagged'
            selected={filters.isTagged}
            onClick={() => setFilters((prev) => ({ ...prev, isTagged: !prev.isTagged }))}
          />
        )}

        <SelectableCard
          className='px-2 py-0 h-10 w-fit'
          label='Exchange'
          selected={filters.isExchange}
          onClick={() => setFilters((prev) => ({ ...prev, isExchange: !prev.isExchange }))}
        />

        <Dropdown open={open} onOpenChange={setOpen}>
          <DropdownTrigger>
            <Button
              onClick={() => setOpen((prev) => !prev)}
              label={
                filters.chains.filter((chain) => chain.checked).length === 0 ? (
                  'Chains'
                ) : (
                  <span className='text-indigo-600'>
                    {capitalizeFirstLetter(filters.chains[0].label)}{' '}
                    {filters.chains.filter((chain) => chain.checked).length > 1
                      ? filters.chains.filter((chain) => chain.checked).length - 1 + '+'
                      : ''}
                  </span>
                )
              }
              className={classNames(
                'duration-100 py-0 shadow hover:bg-indigo-50 justify-between flex',
                filters.chains.filter((chain) => chain.checked).length !== 0 && 'bg-indigo-50 border-indigo-600',
              )}
              labelContainerClassname={classNames(
                'font-medium',
                filters.chains.filter((chain) => chain.checked).length !== 0 && 'text-indigo-600',
              )}
              emphasis='medium'
              trailingIconContainerClassname={classNames(
                filters.chains.filter((chain) => chain.checked).length !== 0 &&
                  'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
                filters.chains.filter((chain) => chain.checked).length !== 0 && 'bg-indigo-50',
              )}
              trailingIcon={
                <div className='flex items-center'>
                  {filters.chains.filter((chain) => chain.checked).length !== 0 && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();

                        setFilters((prev) => ({
                          ...prev,
                          chains: prev.chains.map((chain) => ({ ...chain, checked: false })),
                        }));
                      }}
                    >
                      <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                    </span>
                  )}

                  <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
                </div>
              }
            />
          </DropdownTrigger>
          <DropdownContent withoutPortal align='end' className='!z-commandmenu !w-48 flex flex-col gap-1 p-1 min-w-0'>
            {filters.chains.map((chain) => (
              <CheckboxListItem
                key={chain.value}
                label={chain.label}
                enableSelectOnly={false}
                checked={chain.checked ?? false}
                onCheckedChange={() => {
                  setFilters((prev) => ({
                    ...prev,
                    chains: prev.chains.map((prevChain) =>
                      prevChain.value === chain.value ? { ...prevChain, checked: !prevChain.checked } : prevChain,
                    ),
                  }));
                }}
              />
            ))}
          </DropdownContent>
        </Dropdown>
      </div>

      <div className='max-h-[250px] overflow-auto'>
        {isLoading && (
          <div className='flex items-center justify-center w-full my-4'>
            <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
          </div>
        )}

        {filteredData.wallets.length === 0 &&
          filteredData.exchanges.length === 0 &&
          filteredData.tags.length === 0 &&
          !isLoading && <div className='text-center my-4'>No sources to show</div>}
        <div className='p-2'>
          <InputLabel heading='Source groups' />
          <div className='grid grid-cols-1 gap-2'>
            {filteredData.groups.map((group: SourceGroup, key) => (
              <div key={key}>
                <div
                  className='hover:bg-indigo-50 py-1.5 px-2 rounded-lg flex items-center justify-between cursor-pointer'
                  onClick={() => onChange(group)}
                >
                  <div className='flex items-center gap-y-2 gap-x-4'>
                    {group.icon || '🆕'}
                    <div>
                      <p className='text-zinc-900 font-medium'>{group.name}</p>
                      {group?.subRows?.length} items
                    </div>
                  </div>
                  <div>{defaultValue === group._id && <MdCheck />}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {filteredData.wallets.length > 0 && (
          <div className='p-2'>
            <InputLabel heading='Wallets' />
            <div className='grid grid-cols-1 gap-2'>
              {filteredData.wallets.map((wallet: Wallet, key) => (
                <div key={key}>
                  <div
                    className='hover:bg-indigo-50 py-1.5 px-2 rounded-lg flex items-center justify-between cursor-pointer'
                    onClick={() => onChange(wallet)}
                  >
                    <div className='flex items-center gap-2'>
                      <Avatar
                        type='chain'
                        src={currencyImg(wallet.chain?.toLowerCase() ?? '')}
                        alt={wallet.name ?? ''}
                        size='large'
                      />
                      <div>
                        <p className='text-zinc-900 font-medium'>{wallet.name}</p>
                        <p>{wallet.walletType}</p>
                      </div>
                    </div>
                    <div>{defaultValue === wallet._id && <MdCheck />}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {filteredData.exchanges.length > 0 && (
          <div className='p-2'>
            <InputLabel heading='Exchanges' />
            <div className='grid grid-cols-1 gap-2'>
              {filteredData.exchanges.map((exchange: ExchangeSource, key) => (
                <div
                  key={key}
                  className='hover:bg-indigo-50 py-1.5 px-2 rounded-md flex items-center justify-between cursor-pointer'
                  onClick={() => onChange(exchange)}
                >
                  <div>
                    <div>{getExchangeName(exchange)}</div>
                  </div>
                  <div>{defaultValue === exchange._id && <MdCheck />}</div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!hideTags && filteredData.tags.length > 0 && (
          <div className='p-2'>
            <InputLabel heading='Tags' />
            <div className='grid grid-cols-1 gap-2'>
              {filteredData.tags.map((tag: Tag, key) => (
                <div
                  key={key}
                  className='hover:bg-indigo-50 py-1.5 px-2 rounded-md flex items-center justify-between cursor-pointer'
                  onClick={() => onChange(tag)}
                >
                  <div>
                    {tag.entry.key} · {tag.entry.value}
                  </div>
                  <div>{defaultValue === tag._id && <MdCheck />}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
