import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const removeCondition = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    const parentId = draft.conditions[action.payload.id]?.parentId;
    if (draft.conditionGroups[parentId]) {
      const index = draft.conditionGroups[parentId].childIds.findIndex((id) => id === action.payload.id);
      draft.conditionGroups[parentId].childIds = draft.conditionGroups[parentId].childIds.filter(
        (id) => id !== action.payload.id,
      );
      draft.conditionGroups[parentId].gates.splice(index, 1);
    }
    delete draft.conditions[action.payload.id];
    draft.conditionIds = draft.conditionIds.filter((id) => id !== action.payload.id);
  });
