import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';
import { loopPaths } from '../../data';

type LoopEvent = {
  label: string;
  value: string;
};

const filterLoopRowsFn: FilterFn<LoopEvent> = (searchTerm) => (event) =>
  event.label?.toLowerCase().includes(searchTerm.toLowerCase());
const convertLoopEventToRowFn: ConvertToRowFn<LoopEvent> = (event) => ({
  rowData: {
    value: event.value,
    label: event.label,
  },
  isSelected: false,
});

export const useLoopConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Loop',
      data: loopPaths,
      filterFn: filterLoopRowsFn,
      convertToRowFn: convertLoopEventToRowFn,
      Row: LoopConditionSelectorRow,
    }),
    [],
  );

export const LoopConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();
  console.log({ conditionData });
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.LOOP,
          value: '',
          operator: 'equal',
          path: conditionData.rowData.value,
        })
      }
    />
  );
};

export const LoopConditionsMenu = () => {
  const conditionsData = useFilteredConditions(loopPaths, filterLoopRowsFn, convertLoopEventToRowFn);
  return (
    <ConditionsGroup label='Loop'>
      {conditionsData.length === 0 && <div className='text-center'>No loop conditions to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <LoopConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
