import { MdAddCircle } from 'react-icons/md';
import { InputTrailingAction } from '../input-trailing-action';
import { AddInputTrailingActionProps } from './types';

export const AddInputTrailingAction = ({ value, setValue, className, disabled, max }: AddInputTrailingActionProps) => {
  return (
    <InputTrailingAction
      icon={<MdAddCircle className='w-5 h-5' />}
      onClick={() =>
        setValue({
          target: {
            value: `${+value + 1}`,
          },
        })
      }
      className={className}
      disabled={max !== undefined ? disabled || +value === +max : disabled}
    />
  );
};
