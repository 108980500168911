import axios from 'axios';
import { prepareSearchParams } from './core/utils';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../config';
import { formatDate } from './utils';

const displayedTimestamp = (dateString: string) => `Last updated at ${formatDate(new Date(dateString))}`;

export const getSourcesTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/wallet?${prepareSearchParams({ pageSize: 1, page: 0 }, { isPaginated: true })}`,
  );
  const timestamp = response.data.wallets[0]?.createdAt ? displayedTimestamp(response.data.wallets[0].createdAt) : '';
  return timestamp;
};
export const getTransactionsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/transaction?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true, defaultSortFilterPropertyName: 'transactionDate' },
    )}`,
  );
  const timestamp = response.data.transactions[0]?.createdAt
    ? displayedTimestamp(response.data.transactions[0].createdAt)
    : '';
  return timestamp;
};
export const getJournalsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/journal-entries?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true, defaultSortFilterPropertyName: 'accountingDate' },
    )}`,
    { forwardOrganizationIdAsArray: true },
  );
  const timestamp = response.data.journalEntryModels[0]?.createdAt
    ? displayedTimestamp(response.data.journalEntryModels[0].createdAt)
    : '';
  return timestamp;
};
export const getAssetsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/asset?${prepareSearchParams({ pageSize: 1, page: 0 }, { isPaginated: true })}`,
  );
  const timestamp = response.data.assets[0]?.createdAt ? displayedTimestamp(response.data.assets[0].createdAt) : '';
  return timestamp;
};
export const getTemplatesTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-templates?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
    { forwardOrganizationIdAsArray: true },
  );
  const timestamp = response.data.journalEntryTemplates[0]?.createdAt
    ? displayedTimestamp(response.data.journalEntryTemplates[0].createdAt)
    : '';
  return timestamp;
};
export const getRulesetsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/account-posting-rule-set?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
  );
  const timestamp = response.data.accountPostingRuleSets[0]?.createdAt
    ? displayedTimestamp(response.data.accountPostingRuleSets[0].createdAt)
    : '';
  return timestamp;
};
export const getTagsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/tag?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
  );
  const timestamp = response.data.tags[0]?.createdAt ? displayedTimestamp(response.data.tags[0].createdAt) : '';
  return timestamp;
};
export const getImpairmentsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/impairment-rule?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
  );
  const timestamp = response.data.impairmentRules[0]?.createdAt
    ? displayedTimestamp(response.data.impairmentRules[0].createdAt)
    : '';
  return timestamp;
};
export const getLedgerAccountsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/ledger-account?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
  );
  const timestamp = response.data.ledgerAccounts[0]?.createdAt
    ? displayedTimestamp(response.data.ledgerAccounts[0].createdAt)
    : '';
  return timestamp;
};
export const getLegalEntitiesTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/legal-entity?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true },
    )}`,
  );
  const timestamp = response.data.legalEntities[0]?.createdAt
    ? displayedTimestamp(response.data.legalEntities[0].createdAt)
    : '';
  return timestamp;
};
export const getReportsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/journal-entries?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true, defaultSortFilterPropertyName: 'accountingDate' },
    )}`,
    { forwardOrganizationIdAsArray: true },
  );
  const timestamp = response.data.journalEntryModels[0]?.createdAt
    ? displayedTimestamp(response.data.journalEntryModels[0].createdAt)
    : '';
  return timestamp;
};
export const getIntegrationsTimestamp = async () => {
  const response = await axios.get(
    `${SERVER_URL_CORE}/journal-entries?${prepareSearchParams(
      {
        pageSize: 1,
        page: 0,
      },
      { isPaginated: true, defaultSortFilterPropertyName: 'accountingDate' },
    )}`,
    { forwardOrganizationIdAsArray: true },
  );
  const timestamp = response.data.journalEntryModels[0]?.createdAt
    ? displayedTimestamp(response.data.journalEntryModels[0].createdAt)
    : '';
  return timestamp;
};
