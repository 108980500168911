import * as React from 'react';
import { forwardRef } from 'react';
const SvgDotsIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <rect x={10} y={4} width={4} height={4} rx={2} fill='#000' />
    <rect x={10} y={10} width={4} height={4} rx={2} fill='#000' />
    <rect x={10} y={16} width={4} height={4} rx={2} fill='#000' />
  </svg>
);
const ForwardRef = forwardRef(SvgDotsIcon);
export default ForwardRef;
