import { useRuleConfigurator } from '../../rule-configurator-context';
import { EQUAL_NOTEQUAL_OPERATORS } from '../../data';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { useGetDistinctFunctionNames } from '../../../../../hooks/http';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  const { data, isLoading } = useGetDistinctFunctionNames();
  const options = data?.map((item) => ({
    label: item,
    value: item,
  }));
  return (
    <>
      <SingleSelectMenu
        value={{ label: 'Transaction Action', value: 'Transaction Action' }}
        disabled={true}
        options={[]}
      />
      <SingleSelectMenu
        options={EQUAL_NOTEQUAL_OPERATORS}
        value={EQUAL_NOTEQUAL_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />

      <SingleSelectMenu
        fullWidth
        options={options}
        value={options?.find((item) => item.value.toLowerCase() === condition.value.toLowerCase())}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </>
  );
};

export const TransactionActionRuleCondition = () => (
  <ConditionRow facts={[FACT.TRANSACTION_ACTION]}>
    <Row />
  </ConditionRow>
);
