import { useKBar } from 'farish-kbar';
import React, { useEffect, useRef } from 'react';
import { MdAutoAwesome, MdClose, MdSearch } from 'react-icons/md';
import { useCommandPallete } from './useCommandPallete';
import { Button, classNames } from 'ui';
import { Tooltip } from '../atoms';
import { SearchBarProps } from './types';
import { AppMentionsInput } from './AppMentionsInput';
import { OnChangeHandlerFunc } from 'react-mentions';
import { useAskAI } from '../../hooks/ai-everything';
import { usePathname } from 'next/navigation';

export const SearchBar: React.FC<SearchBarProps> = ({ disabled = false, value, onChange }) => {
  const {
    secondViewPath,
    setIsLoading,
    searchBarDisabled,
    setSecondViewPath,
    selectedIntent,
    setSelectedIntent,
    setInstruction: setCommandPalleteInstruction,
  } = useCommandPallete();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const {
    handleAskAI,
    isLoading: isGPTLoading,
    setInstruction,
  } = useAskAI({
    callback: () => {
      setSecondViewPath('gpt-chat');
      setSelectedIntent(null);
    },
  });

  const { query } = useKBar();

  useEffect(() => {
    // listen to enter key
    const listener = () => {
      // click button if has focus
      (document?.activeElement as HTMLButtonElement | undefined)?.click();
    };
    addEventListener('keydown', listener);
    return () => {
      removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    setIsLoading(isGPTLoading);
  }, [isGPTLoading]);

  const autoHeight = (elem: HTMLTextAreaElement | HTMLDivElement) => {
    /* javascript */
    elem.style.height = '1px';
    elem.style.height = elem.scrollHeight + 'px';
  };

  useEffect(() => {
    try {
      autoHeight(query?.getInput() as unknown as HTMLTextAreaElement);
    } catch (error) {
      console.error(error);
    }
  }, [value]);

  useEffect(() => {
    if (inputRef.current) {
      // set style of the input, because the library doesn't allow us to do it
      inputRef.current.className = classNames(
        inputRef.current.className,
        'text-base w-full placeholder:text-zinc-900 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full',
      );
    }
  }, [inputRef.current]);

  const onChangeHandlerFunc: OnChangeHandlerFunc = (e, newValue) => {
    setInstruction(newValue);
    onChange({ target: { value: newValue } });
  };

  useEffect(() => {
    setInstruction(value);
  }, [selectedIntent, value]);
  const pathname = usePathname();
  const inputDisabled = disabled || (!!secondViewPath && !pathname.includes('copilot')) || searchBarDisabled;
  return (
    <div className={classNames('flex justify-between flex-col relative px-2', secondViewPath && 'hidden')}>
      {selectedIntent ? (
        <div className='pl-2 pr-1 mb-2 bg-gray-200 rounded-md flex h-6.5 w-fit justify-center'>
          {selectedIntent}
          <button className='ml-1 text-gray-400' onClick={() => setSelectedIntent(null)}>
            <MdClose />
          </button>
        </div>
      ) : null}
      <div className={`flex justify-between relative ${secondViewPath ? 'hidden' : ''}`}>
        <div className={`px-3 py-2 mr-2 max-h-60 border rounded-lg scrollbar-hide h-full w-full flex max-w-[81%]`}>
          <MdSearch className='w-6 h-6 text-zinc-500 mr-2' />
          <AppMentionsInput
            autoCorrect='off'
            className='text-[16px] w-full placeholder:!text-zinc-500 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full break-words'
            autoFocus={true}
            disabled={inputDisabled}
            inputRefSetter={(ref) => {
              inputRef.current = ref;
              query?.inputRefSetter(ref);
            }}
            placeholder={!disabled ? 'Search or type a command' : 'Please login to use search'}
            value={value}
            onInputChange={(e) => {
              query.setSearch(e.target.value);
              onChange(e);
              setCommandPalleteInstruction(e.target.value);
            }}
            onChange={onChangeHandlerFunc}
            id='kbar-search'
            rows={1}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !disabled && !isGPTLoading && value) {
                e.preventDefault();
                handleAskAI();
              }
            }}
            customSuggestionsContainer={(children) => {
              return (
                <div className='fixed inset-x-2 top-14 translate-y-0 p-2 z-[9999] rounded-md shadow-[rgba(67,_71,_85,_0.27)_0px_0px_0.25em,_rgba(90,_125,_188,_0.05)_0px_0.25em_1em] bg-white'>
                  <div
                    ref={suggestionsRef}
                    className='max-h-[260px] overflow-auto h-fit  flex flex-col gap-1 [&_li]:mb-1 last:[&_li]:mb-0'
                  >
                    {children}
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div>
          <Tooltip content='Ask AI'>
            <Button
              leadingIcon={<MdAutoAwesome className='text-gray-300' size={20} />}
              emphasis='high'
              disabled={disabled || isGPTLoading || !value}
              onClick={() => handleAskAI()}
              label='Ask AI'
              type='submit'
              ref={buttonRef}
              className='h-11'
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
