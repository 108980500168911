import { Wallet } from 'services/http/response.types';
import { FilterFn } from '../types';

export const walletSearchFilterFn: FilterFn<Wallet> = (state, searched) =>
  state.filter((wallet) => {
    if (searched.length === 0) return true;

    if (wallet.address?.toLowerCase().includes(searched.toLowerCase())) return true;
    if (wallet.walletType?.toLowerCase().includes(searched.toLowerCase())) return true;
    if (wallet.chain?.toLowerCase().includes(searched.toLowerCase())) return true;
    if (wallet.name?.toLowerCase().includes(searched.toLowerCase())) return true;
    // if (wallet.name?.toLowerCase().includes(searched.toLowerCase())) return true;

    return false;
  });
