export const ASSETS = [
  { abbreviation: 'BTC', name: 'Bitcoin' },
  { abbreviation: 'USDC', name: 'USD Coin' },
  { abbreviation: 'DAI', name: 'Dai' },
  { abbreviation: 'TKX', name: 'Tokenize Xchange' },
  { abbreviation: 'LDO', name: 'Lido' },
  { abbreviation: 'ETH', name: 'Ethereum' },
  { abbreviation: 'SAFE', name: 'SafeCoin' },
  { abbreviation: 'AAVE', name: 'Aave' },
  { abbreviation: 'FEI', name: 'Fei' },
  { abbreviation: 'TRIBE', name: 'Tribe' },
  { abbreviation: 'POOL', name: 'PoolTogether' },
  { abbreviation: 'FWB', name: 'Friends With Benefits' },
  { abbreviation: 'SUSD', name: 'sUSD' },
  { abbreviation: 'ENS', name: 'Ethereum Name Service' },
  { abbreviation: 'WETH', name: 'Wrapped Ethereum' },
  { abbreviation: 'GRT', name: 'The Graph' },
  { abbreviation: 'COVAL', name: 'Circuits of Value' },
  { abbreviation: 'BUSD', name: 'Binance USD' },
  { abbreviation: 'USDT', name: 'Tether' },
  { abbreviation: 'RAD', name: 'Radicle' },
  { abbreviation: 'GTC', name: 'Gitcoin' },
  { abbreviation: 'GALA', name: 'Gala' },
  { abbreviation: 'AUSDC', name: 'Algorand USD Coin' },
  { abbreviation: 'APE', name: 'ApeCoin' },
  { abbreviation: 'SOL', name: 'Solana' },
  { abbreviation: 'DUST', name: 'DUST' },
  { abbreviation: 'LTC', name: 'Litecoin' },
];

export const SUPPORTED_ERC20_TOKEN = [
  'USDC',
  'EOSDT',
  '$MECHA',
  '0XBTC',
  '0XMR',
  '1337',
  '1INCH',
  '1MT',
  '1UP',
  '1WO',
  '2BASED',
  '2KEY',
  '300',
  '3CRV',
  '3CS',
  '3XT',
  '69C',
  '7ADD',
  '808TA',
  'A',
  'AAB',
  'ARB',
  'AAC',
  'AAPL',
  'AAVE',
  'ABS',
  'ABUSD',
  'ABYSS',
  'AC',
  'ACED',
  'ACPT',
  'ACR',
  'ADAI',
  'ADEL',
  'ADX',
  'AER',
  'AERGO',
  'AETH',
  'AFCASH',
  'AFDLT',
  'AFI',
  'AFROX',
  'ALCX',
  'AGA',
  'AGI',
  'AGS',
  'AGVC',
  'AHF',
  'AIDOC',
  'AIRX',
  'AKRO',
  'ALBT',
  'ALD',
  'ALEPH',
  'ALEX',
  'ALG',
  'ALINK',
  'ALIS',
  'ALPA',
  'ALPHA',
  'AMB',
  'AMLT',
  'AMN',
  'AMP',
  'AMPL',
  'AMR',
  'ANC',
  'ANJ',
  'ANK',
  'ANKR',
  'ANRX',
  'ANT',
  'ANTS',
  'AOG',
  'APE',
  'API3',
  'APIX',
  'APY',
  'AQUA',
  'ARCH',
  'ARCONA',
  'ARIA20',
  'ARMOR',
  'ARNX',
  'ARNXM',
  'ARPA',
  'ARTE',
  'ARTH',
  'ASAC',
  'ASKO',
  'ASNX',
  'AST',
  'ASTRO',
  'ASUSD',
  'ASWAP',
  'AT',
  'ATIS',
  'ATL',
  'ATRI',
  'AUC',
  'AUDIO',
  'AURA',
  'AUSCM',
  'AUSDC',
  'AVT',
  'AXI',
  'AXIAV3',
  'AXIS',
  'AXN',
  'AXS',
  'AZUKI',
  'BAC',
  'BACON',
  'BADGER',
  'BAEPAY',
  'BAFI',
  'BAL',
  'BANANA',
  'BANCA',
  'BAND',
  'BAO',
  'BART',
  'BAS',
  'BASE',
  'BASED',
  'BAST',
  'BAT',
  'BAZT',
  'BBC',
  'BBL',
  'BBP',
  'BBR',
  'BBRA',
  'BC',
  'BCC',
  'BCDT',
  'BCS',
  'BCTR',
  'BD',
  'BDG',
  'BDP',
  'BDT',
  'BEAR',
  'BEAST',
  'BEE',
  'BEER',
  'BEFX',
  'BEL',
  'BEPRO',
  'BEST',
  'BETTER',
  'BFC',
  'BFR',
  'BFT',
  'BFX',
  'BGTT',
  'BID',
  'BIFI',
  'BIGO',
  'BIKI',
  'BIO',
  'BIRD',
  'BITT',
  'BITTO',
  'BKBT',
  'BKX',
  'BKY',
  'BLC',
  'BLISS',
  'BLOC',
  'BLOODY',
  'BLT',
  'BLUE',
  'BLV',
  'BLZ',
  'BLZN',
  'BMJ',
  'BMX',
  'BNB',
  'BNC',
  'BNF',
  'BNFI',
  'BNS',
  'BNSD',
  'BNT',
  'BNZ',
  'BOA',
  'BOMB',
  'BOND',
  'BONDLY',
  'BONE',
  'BONK',
  'BOOB',
  'BOOST',
  'BOR',
  'BOT',
  'BOX',
  'BOXX',
  'BPLC',
  'BPOP',
  'BPP',
  'BPTN',
  'BRAIN',
  'BRAP',
  'BRD',
  'BREE',
  'BSC',
  'BSE',
  'BSG',
  'BSGS',
  'BSOV',
  'BST',
  'BTB',
  'BTC++',
  'BTCP',
  'BTCWH',
  'BTMX',
  'BTRS',
  'BTSE',
  'BTSG',
  'BTU',
  'BUGS',
  'BUIDL',
  'BUILD',
  'BULL',
  'BUND',
  'BURN',
  'BUSD',
  'BWF',
  'BXIOT',
  'BZKY',
  'BZRX',
  'C20',
  'C8',
  'CAG',
  'CANDY',
  'CAP',
  'CARD',
  'CAT',
  'CAVO',
  'CBAT',
  'CBC',
  'CBET',
  'CBIX7',
  'CBR',
  'CBT',
  'CC10',
  'CCOMP',
  'CDAI',
  'CDT',
  'CEEK',
  'CEL',
  'CELR',
  'CENNZ',
  'CETH',
  'CEZ',
  'CFI',
  'CGT',
  'CHADS',
  'CHAI',
  'CHAIN',
  'CHAL',
  'CHART',
  'CHERRY',
  'CHESS',
  'CHI',
  'CHM',
  'CHONK',
  'CHOP',
  'CHP',
  'CHR',
  'CHSB',
  'CID',
  'CLIQ',
  'CLL',
  'CLOUT',
  'CLT',
  'CLV',
  'CMCT',
  'CMS',
  'CND',
  'CNFI',
  'CNTM',
  'CNTR',
  'COC',
  'COCOS',
  'COFI',
  'COIL',
  'COIN',
  'COKE',
  'COL',
  'COM',
  'COMB',
  'COMBO',
  'COMP',
  'COOM',
  'COR',
  'CORD',
  'CORE',
  'CORN',
  'CORX',
  'COT',
  'COV',
  'COVAL',
  'COVER',
  'COW',
  'COZOM',
  'CP3R',
  'CPAY',
  'CPC',
  'CPR',
  'CRAD',
  'CRAFT',
  'CRBN',
  'CRD',
  'CRDT',
  'CRE',
  'CREAM',
  'CRED',
  'CREDIT',
  'CREED',
  'CRO',
  'CRTS',
  'CRU',
  'CRV',
  'CRYPL',
  'CSAI',
  'CTCN',
  'CTG',
  'CTI',
  'CTSI',
  'CUB',
  'CUBE',
  'CUDOS',
  'CUR',
  'CURA',
  'CURRY',
  'CUSDC',
  'CUSDT',
  'CUTE',
  'CVC',
  'CVD',
  'CVL',
  'CVP',
  'CVR',
  'CWBTC',
  'CXN',
  'CXO',
  'CYFM',
  'CYTR',
  'CZRX',
  'DAI',
  'DAIQ',
  'DAM',
  'DANDY',
  'DAOFI',
  'DAP',
  'DARK',
  'DATA',
  'DAWN',
  'DAY',
  'DBUND',
  'DCN',
  'DCORE',
  'DDIM',
  'DDTG',
  'DDX',
  'DEA',
  'DEBASE',
  'DEC',
  'DEFI+S',
  'DEFI5',
  'DEFL',
  'DEFLCT',
  'DEFO',
  'DEGO',
  'DEGOV',
  'DENT',
  'DEPAY',
  'DESH',
  'DETS',
  'DEUS',
  'DEV',
  'DEVE',
  'DEX',
  'DEXE',
  'DEXG',
  'DEXT',
  'DEXTF',
  'DF',
  'DFD',
  'DFGL',
  'DFIO',
  'DFK',
  'DFLY',
  'DFS',
  'DFSOCIAL',
  'DFT',
  'DFX',
  'DG',
  'DGCL',
  'DGD',
  'DGMT',
  'DGNN',
  'DGTX',
  'DGVC',
  'DGX',
  'DHC',
  'DHT',
  'DIA',
  'DICE',
  'DIE',
  'DIGG',
  'DIP',
  'DIS',
  'DISTX',
  'DKA',
  'DMG',
  'DML',
  'DMME',
  'DMST',
  'DNA',
  'DNS',
  'DNT',
  'DOCK',
  'DODO',
  'DOGDEFI',
  'DOGEFI',
  'DOGES',
  'DOGY',
  'DOKI',
  'DONUT',
  'DOS',
  'DOTX',
  'DOUGH',
  'DOV',
  'DPH',
  'DPI',
  'DRAY',
  'DRC',
  'DREAM',
  'DREP',
  'DRGN',
  'DRIP',
  'DRK',
  'DSD',
  'DSGN',
  'DSLA',
  'DSS',
  'DSWAP',
  'DTA',
  'DTH',
  'DTUBE',
  'DUCATO',
  'DUCK',
  'DUO',
  'DUSD',
  'DUST',
  'DVI',
  'DVP',
  'DX',
  'DXD',
  'DXIOT',
  'DXY',
  'DYNMT',
  'DYP',
  'DYT',
  'DZAR',
  'EAN',
  'EASY',
  'EBASE',
  'EC',
  'ECA',
  'ECELL',
  'ECL',
  'ECO',
  'ECP',
  'ECTO',
  'EDI',
  'EDN',
  'EDO',
  'EDR',
  'EER',
  'EGR',
  'EL',
  'ELEC',
  'ELET',
  'ELF',
  'ELX',
  'ELYX',
  'EMOJI',
  'EMONT',
  'EMTR',
  'EMTRG',
  'ENB',
  'ENCORE',
  'ENG',
  'ENJ',
  'ENOL',
  'ENS',
  'ENTRP',
  'EOST',
  'EPAN',
  'EQMT',
  'EQUAD',
  'ERC20',
  'ERC223',
  'ERSDL',
  'ESD',
  'ESG',
  'ESH',
  'ESS',
  'ESWA',
  'ESZ',
  'ETGF',
  'ETH',
  'ETH3L',
  'ETHBN',
  'ETHMNY',
  'ETHPY',
  'ETHV',
  'ETHY',
  'ETHYS',
  'ETY',
  'EURS',
  'EVED',
  'EVX',
  'EXE',
  'EXM',
  'EXNT',
  'EXRD',
  'EXRN',
  'EXRT',
  'EYE',
  'FACE',
  'FACT',
  'FAM',
  'FAME',
  'FAMILY',
  'FANTA',
  'FARM',
  'FAST',
  'FAT',
  'FC007',
  'FCBTC',
  'FCN',
  'FCX',
  'FEI',
  'FERA',
  'FET',
  'FEVR',
  'FFYI',
  'FGP',
  'FIN',
  'FIRE',
  'FIRST',
  'FL',
  'FLASH',
  'FLC',
  'FLIXX',
  'FLL',
  'FLOW',
  'FLUX',
  'FMA',
  'FMTA',
  'FNK',
  'FNSP',
  'FNT',
  'FNX',
  'FOAM',
  'FOL',
  'FOR',
  'FORCER',
  'FORS',
  'FOUR',
  'FOX',
  'FR',
  'FRAX',
  'FREE',
  'FRENS',
  'FRM',
  'FRMX',
  'FRNK',
  'FRONT',
  'FRY',
  'FSD',
  'FSP',
  'FSW',
  'FSXA',
  'FTM',
  'FTT',
  'FTX',
  'FUD',
  'FUN',
  'FUSE',
  'FUZE',
  'FVT',
  'FWB',
  'FWT',
  'FXS',
  'FYZ',
  'GALA',
  'GAME',
  'GBP',
  'GDAO',
  'GEAR',
  'GEEQ',
  'GEM',
  'GEN',
  'GEO',
  'GET',
  'GEX',
  'GFARM',
  'GFARM2',
  'GGTK',
  'GHD',
  'GHST',
  'GIV',
  'GL',
  'GLCH',
  'GLF',
  'GLM',
  'GLOX',
  'GNO',
  'GNY',
  'GOF',
  'GOLD',
  'GOLDX',
  'GOM2',
  'GR',
  'GRAIN',
  'GRAP',
  'GRAPH',
  'GRG',
  'GRID',
  'GRO',
  'GRPL',
  'GRT',
  'GST2',
  'GSWAP',
  'GTC',
  'GTF',
  'GTH',
  'GUP',
  'GUSD',
  'GVT',
  'GVY',
  'GYSR',
  'GZE',
  'H3X',
  'HAKKA',
  'HALO',
  'HAND',
  'HATCH',
  'HATE',
  'HAUT',
  'HBT',
  'HBTC',
  'HBURN',
  'HDAO',
  'HDI',
  'HEDG',
  'HEGIC',
  'HEX',
  'HEZ',
  'HGET',
  'HGT',
  'HIPPO',
  'HIZ',
  'HLAND',
  'HLC',
  'HOLE',
  'HOLY',
  'HOT',
  'HOUSE',
  'HT',
  'HTB',
  'HTN',
  'HTRE',
  'HTX',
  'HUB',
  'HUE',
  'HUNT',
  'HUSD',
  'HVN',
  'HXRO',
  'HXY',
  'HY',
  'HYDRO',
  'HYPE',
  'HYPEBET',
  'HYVE',
  'IBETH',
  'ICE',
  'ICHI',
  'ICK',
  'ICNQ',
  'IDEX',
  'IDLE',
  'IDRT',
  'IETH',
  'IFUND',
  'IFX24',
  'IGG',
  'IHF',
  'ILK',
  'IMBTC',
  'IMS',
  'IMUSD',
  'IND',
  'INDEX',
  'INFI',
  'INFT',
  'INJ',
  'INTX',
  'INX',
  'INXT',
  'IPM',
  'ISIKC',
  'ISLA',
  'ITS',
  'IUT',
  'IXT',
  'IYF',
  'IZI',
  'JAMM',
  'JEM',
  'JET',
  'JFIN',
  'JGN',
  'JIAOZI',
  'JNB',
  'JNT',
  'JNTRE',
  'JOON',
  'JPYQ',
  'JREX',
  'JRT',
  'JT',
  'JUICE',
  'JUL',
  'JULIEN',
  'JUP',
  'KAI',
  'KARMA',
  'KASH',
  'KASSIAHOME',
  'KASSIAHOTEL',
  'KAT',
  'KATANA',
  'KCAL',
  'KDAG',
  'KEEP',
  'KEK',
  'KEN',
  'KERMAN',
  'KEX',
  'KEY',
  'KFC',
  'KGW',
  'KICK',
  'KIF',
  'KIMCHI',
  'KIRO',
  'KIT',
  'KIWI',
  'KLON',
  'KMPL',
  'KNC',
  'KNV',
  'KOBE',
  'KOIN',
  'KOMET',
  'KOMP',
  'KP3R',
  'KP4R',
  'KPER',
  'KRL',
  'KSC',
  'KSEED',
  'KTLYO',
  'KTON',
  'KUN',
  'L2',
  'LADZ',
  'LAR',
  'LAYER',
  'LBA',
  'LBD',
  'LBURST',
  'LCS',
  'LCX',
  'LDFI',
  'LDO',
  'LEAD',
  'LEND',
  'LEO',
  'LEV',
  'LGCY',
  'LGO',
  'LIB',
  'LIBERTAS',
  'LID',
  'LIEN',
  'LIMEX',
  'LIMIT',
  'LINA',
  'LINK',
  'LINKETHRSI',
  'LION',
  'LIQ',
  'LIQLO',
  'LIQUID',
  'LIT',
  'LMS',
  'LMY',
  'LNC',
  'LND',
  'LNT',
  'LOAD',
  'LOCK',
  'LON',
  'LOOM',
  'LOOT',
  'LPT',
  'LQD',
  'LRC',
  'LST',
  'LTO',
  'LTX',
  'LUA',
  'LUD',
  'LUNA',
  'LYNC',
  'LYXE',
  'MAFI',
  'MAHA',
  'MAKI',
  'MANA',
  'MANY',
  'MARK',
  'MARS',
  'MASQ',
  'MATH',
  'MATIC',
  'MBBASED',
  'MBC',
  'MBN',
  'MCB',
  'MCH',
  'MCI',
  'MCP',
  'MCX',
  'MDS',
  'MDT',
  'MDTL',
  'MDX',
  'MEGA',
  'MEME',
  'MET',
  'METM',
  'METRIC',
  'MEXP',
  'MFG',
  'MFT',
  'MFTU',
  'MGX',
  'MILK2',
  'MINDS',
  'MINI',
  'MIR',
  'MIS',
  'MITX',
  'MIXS',
  'MKCY',
  'MKR',
  'MLN',
  'MLR',
  'MM',
  'MNE',
  'MNT',
  'MNTP',
  'MOBI',
  'MOD',
  'MODEX',
  'MOF',
  'MOL',
  'MONA',
  'MOON',
  'MOONDAY',
  'MOONS',
  'MORC',
  'MORK',
  'MORT',
  'MOST',
  'MPH',
  'MPS',
  'MRPH',
  'MSP',
  'MSV',
  'MSWAP',
  'MT',
  'MTA',
  'MTI',
  'MTL',
  'MTLX',
  'MTN',
  'MTX',
  'MUSD',
  'MUSE',
  'MUST',
  'MVEDA',
  'MVL',
  'MVP',
  'MX',
  'MXC',
  'MXT',
  'MXX',
  'MYB',
  'MYFI',
  'MYST',
  'MYTV',
  'MYX',
  'N0031',
  'N3RDZ',
  'NAMI',
  'NANJ',
  'NCC',
  'NCT',
  'NDR',
  'NDX',
  'NEBO',
  'NEC',
  'NEST',
  'NEWTON',
  'NEXO',
  'NFT',
  'NFTX',
  'NFY',
  'NICE',
  'NIF',
  'NII',
  'NIOX',
  'NKN',
  'NLINK',
  'NMR',
  'NMT',
  'NOAHARK',
  'NOAHP',
  'NOIA',
  'NOOB',
  'NOODLE',
  'NORD',
  'NOTRUMP',
  'NPXS',
  'NSURE',
  'NTK',
  'NTRUMP',
  'NU',
  'NUG',
  'NUTS',
  'NYAN',
  'NYAN-2',
  'NYB',
  'OBEE',
  'OBR',
  'OCB',
  'OCEAN',
  'OCN',
  'OCTO',
  'ODEX',
  'OFT',
  'OGN',
  'OIN',
  'OKB',
  'OLCF',
  'OM',
  'OMC',
  'OMG',
  'OMX',
  'ON',
  'ONC',
  'ONE',
  'ONES',
  'ONG',
  'ONIGIRI',
  'ONS',
  'ONX',
  'OPCT',
  'OPEN',
  'OPIUM',
  'OPM',
  'OPT',
  'ORAI',
  'ORB',
  'ORBI',
  'ORBS',
  'ORME',
  'ORN',
  'ORO',
  'OSINA',
  'OST',
  'OUSD',
  'OVR',
  'OWL',
  'OXT',
  'PAID',
  'PAMP',
  'PAN',
  'PAR',
  'PARETO',
  'PARTY',
  'PASS',
  'PASTA',
  'PAX',
  'PAXG',
  'PAY',
  'PAYOU',
  'PAYT',
  'PBL',
  'PBR',
  'PBTC',
  'PBTC35A',
  'PCT',
  'PDAI',
  'PEAK',
  'PEGS',
  'PERL',
  'PERP',
  'PERX',
  'PFARM',
  'PGS',
  'PGT',
  'PGU',
  'PHA',
  'PHNX',
  'PICKLE',
  'PIE',
  'PIGX',
  'PIPT',
  'PIS',
  'PITCH',
  'PJM',
  'PKG',
  'PKT',
  'PLAAS',
  'PLAY',
  'PLBT',
  'PLOT',
  'PLR',
  'PLTC',
  'PLU',
  'PMA',
  'PMGT',
  'PNK',
  'PNT',
  'POE',
  'POLIS',
  'POLS',
  'POLY',
  'POND',
  'POOL',
  'POOLZ',
  'POSH',
  'POT',
  'POWER',
  'POWR',
  'PPAY',
  'PPBLZ',
  'PPDEX',
  'PPP',
  'PPT',
  'PRDX',
  'PRDZ',
  'PRE',
  'PRIA',
  'PRIME',
  'PRINT',
  'PROB',
  'PROM',
  'PROPHET',
  'PROPS',
  'PROS',
  'PRQ',
  'PRQBOOST',
  'PRT',
  'PREMIA',
  'PRY',
  'PSHP',
  'PSI',
  'PSK',
  'PTE',
  'PTERIA',
  'PTF',
  'PTOY',
  'PUNK',
  'PUSD',
  'PUX',
  'PYLON',
  'PYRO',
  'QBX',
  'QCH',
  'QCORE',
  'QDAO',
  'QHC',
  'QKC',
  'QNT',
  'QRX',
  'QSP',
  'QUICK',
  'QUN',
  'QUO',
  'QUSD',
  'R34P',
  'RAC',
  'RAD',
  'RAIN',
  'RAISE',
  'RAK',
  'RAKU',
  'RAMP',
  'RARE',
  'RARI',
  'RAUX',
  'RBASE',
  'RBC',
  'RCKT',
  'RCN',
  'RDN',
  'REB2',
  'REBASE',
  'REEF',
  'REL',
  'REM',
  'REN',
  'RENBCH',
  'RENBTC',
  'RENFIL',
  'RENZEC',
  'REP',
  'REQ',
  'RES',
  'REV',
  'REVV',
  'RFCTR',
  'RFI',
  'RFOX',
  'ROKO',
  'RFR',
  'RFUEL',
  'RFX',
  'RFY',
  'RGT',
  'RHEGIC',
  'RI',
  'RIGEL',
  'RING',
  'RLC',
  'RLR',
  'RLX',
  'RLY',
  'RMPL',
  'RNDR',
  'ROCKS',
  'ROOBEE',
  'ROOK',
  'ROOT',
  'ROPE',
  'ROT',
  'ROYA',
  'RPEPE',
  'RPL',
  'RSCT',
  'RSPT',
  'RSR',
  'RSV',
  'RTC',
  'RTK',
  'RUG',
  'RUGZ',
  'RVX',
  'RWS',
  'RZN',
  'S4F',
  'SAC',
  'SAFE',
  'SAFE2',
  'SAI',
  'SAKE',
  'SALE',
  'SALT',
  'SAM',
  'SAN',
  'SAND',
  'SAS',
  'SASHIMI',
  'SAV3',
  'SBTC',
  'SBTCCURVE',
  'SBX',
  'SCHA',
  'SCURVE',
  'SDEFI',
  'SDT',
  'SDX',
  'SEAL',
  'SEED',
  'SEEN',
  'SENT',
  'SERGS',
  'SETH',
  'SFG',
  'SFI',
  'SFL',
  'SGR',
  'SGT',
  'SHAKE',
  'SHARE',
  'SHIB',
  'SHIP',
  'SHIT',
  'SHR',
  'SHRIMP',
  'SHROOM',
  'SHUF',
  'SKEY',
  'SKL',
  'SKM',
  'SKULL',
  'SKYM',
  'SLINK',
  'SLP',
  'SLV',
  'SMARTCREDIT',
  'SMDX',
  'SMOL',
  'SMPL',
  'SNC',
  'SNGJ',
  'SNGLS',
  'SNOV',
  'SNOW',
  'SNT',
  'SNTR',
  'SNTVT',
  'SNX',
  'SOAR',
  'SOCKS',
  'SODA',
  'SOFI',
  'SOLARITE',
  'SOLVE',
  'SOUL',
  'SPA',
  'SPANK',
  'SPAZ',
  'SPC',
  'SPDR',
  'SPI',
  'SPICE',
  'SPN',
  'SPORE',
  'SPRKL',
  'SRK',
  'SRM',
  'SRN',
  'SS',
  'SSJ',
  'SSL',
  'SST',
  'STA',
  'STACY',
  'STAKE',
  'STAR',
  'STASH',
  'STBU',
  'STBZ',
  'STEAK',
  'STEEL',
  'STETH',
  'STFIRO',
  'STGZ',
  'STLP',
  'STM',
  'STMX',
  'STONK',
  'STOP',
  'STORJ',
  'STPT',
  'STRN',
  'STRNG',
  'STRONG',
  'STT',
  'STV',
  'STXEM',
  'SUKU',
  'SUP8EME',
  'SURE',
  'SURF',
  'SUSD',
  'SUSHI',
  'SUTER',
  'SVB',
  'SWAG',
  'SWAGG',
  'SWAP',
  'SWFL',
  'SWFTC',
  'SWG',
  'SWISS',
  'SWM',
  'SWRV',
  'SWSH',
  'SX',
  'SXAU',
  'SXMR',
  'SXP',
  'SXRP',
  'SXUT',
  'SYFI',
  'SYLO',
  'SYN',
  'SYNC',
  'T1C',
  'TACO',
  'TAD',
  'TAR',
  'TARM',
  'TAT',
  'TAU',
  'TAUD',
  'TBB',
  'TBTC',
  'TBX',
  'TCAD',
  'TCH',
  'TCORE',
  'TDEX',
  'TEAM',
  'TEAT',
  'TEL',
  'TEN',
  'TEND',
  'TENS',
  'TFB',
  'TGBP',
  'THIRM',
  'THKD',
  'THRN',
  'TIG',
  'TIME',
  'TITAN',
  'TKMN',
  'TKN',
  'TKP',
  'TKT',
  'TKX',
  'TLN',
  'TMC',
  'TME',
  'TMPL',
  'TMT',
  'TMV',
  'TNI',
  'TNT',
  'TOB',
  'TOL',
  'TOMOE',
  'TON',
  'TONE',
  'TRAC',
  'TRADE',
  'TRB',
  'TRBT',
  'TRI',
  'TRIB',
  'TRIBE',
  'TRISM',
  'TRND',
  'TROP',
  'TRST',
  'TRU',
  'TRUMP',
  'TRUSD',
  'TRUST',
  'TRXC',
  'TRYB',
  'TRZ',
  'TSD',
  'TSHP',
  'TST',
  'TTT',
  'TUSD',
  'TVK',
  'TVT',
  'TWA',
  'TWOB',
  'TXL',
  'U8D',
  'UBEX',
  'UBOMB',
  'UBT',
  'UCAP',
  'UCM',
  'UCO',
  'UCT',
  'UDOO',
  'UETL',
  'UFC',
  'UFR',
  'UFT',
  'UGAS-JAN21',
  'UGC',
  'ULLU',
  'ULT',
  'ULU',
  'UMA',
  'UMX',
  'UNC',
  'UNCL',
  'UNCX',
  'UNDB',
  'UNDG',
  'UNI',
  'UNICRAP',
  'UNIDX',
  'UNIFI',
  'UNII',
  'UNISTAKE',
  'UNITS',
  'UNIUSD',
  'UNL',
  'UNN',
  'UNOS',
  'UNT',
  'UOP',
  'UOS',
  'UP',
  'UPI',
  'UPP',
  'UPT',
  'UQC',
  'USD',
  'USD++',
  'USDA',
  'USDB',
  'USDC',
  'USDFL',
  'USDK',
  'USDN',
  'USDP',
  'USDQ',
  'USDS',
  'USDT',
  'USDX',
  'USE',
  'UST',
  'UTK',
  'UTO',
  'UTU',
  'UTY',
  'UWL',
  'VAL',
  'VALOR',
  'VALUE',
  'VAMP',
  'VANA',
  'VAULTZ',
  'VERI',
  'VESTA',
  'VETH',
  'VEY',
  'VFI',
  'VGT',
  'VGTG',
  'VGTN',
  'VGX',
  'VI',
  'VIDT',
  'VIDYA',
  'VIEW',
  'VISION',
  'VLD',
  'VLO',
  'VLT',
  'VNDC',
  'VNLA',
  'VNXLU',
  'VOLTS',
  'VOX',
  'VRA',
  'VRS',
  'VRX',
  'VSF',
  'VSN',
  'VTD',
  'VVT',
  'VXV',
  'VYA',
  'VYBE',
  'WAIF',
  'WANATHA',
  'WAND',
  'WAR',
  'WAV3',
  'WAXE',
  'WBIND',
  'WBTC',
  'WCCX',
  'WCELO',
  'WCHI',
  'WCK',
  'WCOINBASE-IOU',
  'WCRES',
  'WDGLD',
  'WDP',
  'WENB',
  'WETH',
  'WFIL',
  'WG0',
  'WHALE',
  'WHITE',
  'WHOLE',
  'WING',
  'WINGS',
  'WIS',
  'WISE',
  'WIZ',
  'WLEO',
  'WLT',
  'WNRZ',
  'WNXM',
  'WOA',
  'WOM',
  'WOO',
  'WOZX',
  'WPP',
  'WRC',
  'WRLD',
  'WSCRT',
  'WSTA',
  'WTF',
  'WVG0',
  'WWGR',
  'WZEC',
  'X8X',
  'XAMP',
  'XANK',
  'XAUT',
  'XBP',
  'XBTC',
  'XCHF',
  'XCM',
  'XCUR',
  'XDB',
  'XDEF2',
  'XDNA',
  'XDT',
  'XED',
  'XEN',
  'XETH-G',
  'XFI',
  'XFII',
  'XFOC',
  'XFT',
  'XFYI',
  'XIO',
  'XIOT',
  'XMM',
  'XMX',
  'XNK',
  'XNS',
  'XOR',
  'XPAT',
  'XPB',
  'XPND',
  'XPR',
  'XQC',
  'XRPC',
  'XRT',
  'XSGD',
  'XSP',
  'XST',
  'XSTAR',
  'XTK',
  'XTP',
  'XVIX',
  'XYO',
  'YAM',
  'YAMV2',
  'YAX',
  'YBAN',
  'YBREE',
  'YCURVE',
  'YEA',
  'YEFIM',
  'YELD',
  'YESTRUMP',
  'YETI',
  'YF-DAI',
  'YF4',
  'YFA',
  'YFARM',
  'YFARMER',
  'YFB2',
  'YFBETA',
  'YFBT',
  'YFC',
  'YFD',
  'YFDOT',
  'YFDT',
  'YFE',
  'YFED',
  'YFF',
  'YFFC',
  'YFFI',
  'YFFII',
  'YFFS',
  'YFI',
  'YFI3',
  'YFIB',
  'YFID',
  'YFIEC',
  'YFII',
  'YFIIG',
  'YFIII',
  'YFIKING',
  'YFILD',
  'YFIM',
  'YFIP',
  'YFIS',
  'YFIVE',
  'YFIX',
  'YFKA',
  'YFL',
  'YFMB',
  'YFMS',
  'YFO',
  'YFOS',
  'YFOX',
  'YFP',
  'YFPI',
  'YFPRO',
  'YFR',
  'YFRB',
  'YFRM',
  'YFSI',
  'YFT',
  'YFTE',
  'YFUEL',
  'YG',
  'YGY',
  'YI12',
  'YIELDX',
  'YKZ',
  'YLAB',
  'YLAND',
  'YLD',
  'YMAX',
  'YMEN',
  'YMF20',
  'YMPL',
  'YNK',
  'YO',
  'YOK',
  'YOP',
  'YOUC',
  'YPLT',
  'YRISE',
  'YSEC',
  'YSKF',
  'YSR',
  'YTHO',
  'YTRUMP',
  'YTSLA',
  'YUI',
  'YUNO',
  'YUP',
  'YVAULT-LP-YCURVE',
  'YVS',
  'YYFI',
  'Z502',
  'ZAC',
  'ZAI',
  'ZAP',
  'ZCC',
  'ZCN',
  'ZDEX',
  'ZEE',
  'ZEFU',
  'ZEON',
  'ZERO',
  'ZEST',
  'ZHEGIC',
  'ZIN',
  'ZIP',
  'ZIPT',
  'ZKS',
  'ZLOT',
  'ZMN',
  'ZOM',
  'ZOMB',
  'ZOMBIE',
  'ZORA',
  'ZRX',
  'ZUM',
  'ZUT',
  'ZXC',
  'ZZZ',
  'ZZZV2',
  'tUSDC',
  'xDG',
  'WTAO',
];

export const BTC = 'BTC';
export const POLYGON = 'MATIC';
export const SOL = 'SOL';
export const USD = 'USD';
export const AVALANCHE = 'AVAX';

export const SOL_TOKENS = ['DUST', 'RAY'];
export const NEAR_TOKENS = ['NEAR', 'SWEAT'];

export const ALL_SUPPORTED_ASSETS = [BTC, POLYGON, SOL, ...NEAR_TOKENS, AVALANCHE, ...SUPPORTED_ERC20_TOKEN];


export function getAssetsInfo(query: string): { name: string; abbreviation: string } {
  const coin = ASSETS.find(
    (c) => c.abbreviation.toLowerCase() === query.toLowerCase() || c.name.toLowerCase() === query.toLowerCase(),
  );

  if (coin) {
    return coin;
  } else {
    // If no match is found, return an object with the same attributes
    return { name: query, abbreviation: query };
  }
}
