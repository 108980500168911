import React from 'react';

type LabelProps = {
  text: string;
};

export const Label = ({ text }: LabelProps) => {
  return <p className='!px-2 bg-[#1111110A] border border-[#1111111A] rounded font-medium opacity-80'>{text}</p>;
};

export default Label;
