import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownTitleWithToggle,
  DropdownTrigger,
  StyledCheckbox,
  StyledIndicator,
  classNames,
} from 'ui';
import { MdArrowDropDown, MdTune, MdDragIndicator } from 'react-icons/md';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { useColumnVisibility } from 'src/hooks';
import { ColumnsProps } from '../columns/types';
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getUrlWithoutId } from 'src/components/utils';
import { useRouter } from 'next/router';

type EditColumnsProps = {
  columns: ColumnsProps<any, any>[];
  onColumnsUpdate: (updatedColumns: ColumnsProps<any, any>[]) => void;
  panelTableName?: string;
};

const DraggableItem = ({ column, saveColumnVisibility }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: column.accessorKey.toString(),
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const isColumnVisible = column.visibility !== false;
  const handleCheckedChange = () => {
    saveColumnVisibility({
      ...column,
      visibility: !isColumnVisible,
    });
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={classNames(
        'hover:bg-indigo-50 rounded-md flex items-center py-2 px-3 bg-white',
        isColumnVisible && 'bg-indigo-50',
      )}
    >
      <MdDragIndicator className='mr-2 text-zinc-400 cursor-grab' />
      <div className='flex gap-2 items-center w-full'>
        <StyledCheckbox
          id={column.accessorKey.toString()}
          checked={isColumnVisible}
          className='hover:!border-zinc-700 data-[state=checked]:!bg-indigo-600 data-[state=checked]:!border-indigo-600'
          onCheckedChange={handleCheckedChange}
        >
          <StyledIndicator>
            <CheckIcon />
          </StyledIndicator>
        </StyledCheckbox>

        <label htmlFor={column.accessorKey.toString()} className='text-zinc-900 text-base leading-5 select-none'>
          {column.header ?? ''}
        </label>
      </div>
    </div>
  );
};

export const EditColumns = ({ columns: initialColumns, onColumnsUpdate, panelTableName = '' }: EditColumnsProps) => {
  const { asPath } = useRouter();
  const pageName = getUrlWithoutId(asPath).split('/').slice(-1)[0];
  const [isOpen, setIsOpen] = useState(false);
  const resetState: any = useRef(initialColumns); // Store initial state
  const initialColumnsRef: any = useRef(initialColumns); // Store initial state
  const { columnVisibility, saveColumnVisibility, selectAllColumns } = useColumnVisibility(
    initialColumns,
    panelTableName,
  );
  const [internalColumnVisibility, setInternalColumnVisibility] = useState(columnVisibility as any);

  const filteredVisibility = internalColumnVisibility.filter((item) => item.accessorKey !== 'editBtn');

  const reorder = (internalColumnVisibility) => {
    // Create a map from internalColumnVisibility for quick lookup
    const orderMap = new Map();
    internalColumnVisibility.forEach((item, index) => {
      orderMap.set(item.accessorKey, index);
    });

    // Sort the columns array based on the order in internalColumnVisibility
    initialColumnsRef.current = [...initialColumns].sort((a, b) => {
      return orderMap.get(a.accessorKey) - orderMap.get(b.accessorKey);
    });

    onColumnsUpdate(initialColumnsRef.current);
  };

  useEffect(() => {
    reorder(filteredVisibility);
  }, [pageName]);

  // Update internal state whenever columnVisibility changes
  useEffect(() => {
    setInternalColumnVisibility(columnVisibility);
    if (!initialColumnsRef.current || initialColumnsRef.current.length === 0) {
      initialColumnsRef.current = columnVisibility;
    }
  }, [columnVisibility]);

  const handleReset = () => {
    saveColumnVisibility(resetState.current);
    onColumnsUpdate(resetState.current);
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 150,
      tolerance: 15,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 150,
      tolerance: 15,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
    if (active.id === 'sourceInfo') return;

    const oldIndex = filteredVisibility.findIndex((item) => item?.accessorKey?.toString() === active.id);
    if (oldIndex === 0) return;

    const newIndex = filteredVisibility.findIndex((item) => item?.accessorKey?.toString() === over.id);

    if (newIndex > 0) {
      // Reorder filteredVisibility based on drag-and-drop
      const updatedInternalVisibility: any = arrayMove(filteredVisibility, oldIndex, newIndex);
      saveColumnVisibility(updatedInternalVisibility);
      reorder(updatedInternalVisibility);
    }
  };

  return (
    <Dropdown open={isOpen} onOpenChange={setIsOpen} key={panelTableName || pageName}>
      <DropdownTrigger asChild>
        <Button
          emphasis='medium'
          labelContainerClassname='font-medium text-base leading-5 text-zinc-900 mx-0 hidden md:block'
          isFocused={false}
          leadingIcon={<MdTune className='w-5 h-5 text-zinc-900' />}
          leadingIconContainerClassname='ml-0'
          trailingIconContainerClassname='mr-0 hidden md:inline-flex'
          trailingIcon={
            <MdArrowDropDown
              className={classNames('w-6 h-6 md:mr-0 mr-0 text-zinc-900 transition-transform', isOpen && 'rotate-180')}
            />
          }
          label='Edit table'
          className='[&_.base-button-label]:hidden md:[&_.base-button-label]:inline-flex w-10 md:w-auto md:px-4 px-2 gap-2 !shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)]'
        />
      </DropdownTrigger>

      <DropdownContent align='end' className='rounded-lg max-h-none z-50' contentWidth={278}>
        <DropdownTitleWithToggle
          title='Select all'
          checked={filteredVisibility.every((column) => column.visibility !== false)}
          onChange={() => {
            selectAllColumns();
          }}
        />
        <div className='bg-white flex flex-col gap-1 p-1'>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext
              items={filteredVisibility.map((column) => column.accessorKey?.toString())}
              strategy={verticalListSortingStrategy}
            >
              {filteredVisibility.map(
                (column) =>
                  column.accessorKey !== 'select' && (
                    <DraggableItem
                      key={column.accessorKey?.toString()}
                      column={column}
                      saveColumnVisibility={saveColumnVisibility}
                    />
                  ),
              )}
            </SortableContext>
          </DndContext>
        </div>
        <div className='w-full flex justify-end border-t mt-2 p-2'>
          <Button emphasis='low' label='Reset' labelContainerClassname='text-zinc-500' onClick={handleReset} />
        </div>
      </DropdownContent>
    </Dropdown>
  );
};

export default EditColumns;
