import { loginUser } from 'services/http/auth';
import { useSession } from '../useSession';
import { ONBOARDING_REDIRECT_MAP, Organization } from 'schemas';
import { useRouter } from 'next/navigation';
import { useMutation } from '@tanstack/react-query';
import { useOrganizationPickerContext } from '../../context';
import { isProductionHref } from '../useOrgChangeRedirect';

export interface LoginCredentials {
  email: string;
  password: string;
}

const getSelectedOrganizationMembershipBasedOnUrl = (organizations: Organization[]): Organization => {
  const href = window.location.href;
  if (isProductionHref(href)) {
    try {
      const url = new URL(href);
      const subdomain = url.host.replace('.entendre.finance', '');
      const organization = organizations.find((org) => org.webAddress === subdomain) ?? organizations[0];
      return organization;
    } catch (error) {
      console.log('error while parsing subdomain from href', error);
    }
    return organizations[0];
  }
  return organizations[0];
};

export const useLoginMutation = () => {
  const router = useRouter();
  const { handleSessionFromServer } = useSession();
  const { setSelectedOrganizationId } = useOrganizationPickerContext();
  return useMutation({
    mutationFn: async (credentials: LoginCredentials) => {
      try {
        // do everything we do
        // console.log('logging in');
        const { data: session } = await loginUser({ user: credentials });

        // console.log({ jwt: getCookie(SESSION_COOKIE.TOKEN), user: session.user });
        // console.log('got session', JSON.stringify(session, null, 2));
        // console.log('updated session in context, set token in jwt cookie');
        const organization = getSelectedOrganizationMembershipBasedOnUrl(session.organizations);
        console.log({ selectedOrganization: organization });
        if (!organization) return router.replace('/onboarding/new/workspace');
        const organizationId = organization._id;
        // get organization onboarding status
        // console.log('fetching organization details');
        setSelectedOrganizationId(organizationId);
        handleSessionFromServer(session);

        console.log('organization', organization);
        const isOnboardingComplete = organization.completedOnboarding;

        // console.log('redirecting to overview as onboarding is complete for this org', isOnboardingComplete);
        // redirect based on onboarding status
        if (isOnboardingComplete) return router.replace('/overview');

        const pathname = ONBOARDING_REDIRECT_MAP[organization.onboardingStatus] ?? ONBOARDING_REDIRECT_MAP.NEW;
        // console.log(
        //   'redirecting to onboarding as some steps are remaining',
        //   isOnboardingComplete,
        //   organization.onboardingStatus,
        //   pathname,
        // );
        return router.replace(pathname);
      } catch (error) {
        console.error('Error logging in:', error);
        throw error;
      }
    },
  });
};
