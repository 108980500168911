import React from 'react';
import { PathNameProps, CustomPathProps } from './types';
import { navigate } from '../utils';
import { DROP_DOWN_MENU_NAVIGATION, PROFILE_PAGE_SECONDARY_NAVIGATION } from '../../constants';

export const capitalizeFirstLetter = (str: string) => str?.replace(/^./, str[0]?.toUpperCase()) || '';

export const handlePath = (str: string) => str.split('/').join(' ').trim().split(' ');

export const PathNameConverter = (pathName: string, customPathName: PathNameProps[], customPath?: CustomPathProps) => {
  if (customPath?.length) {
    const saveCustomPathItem = customPath.find(({ pathname }) => pathName.toLowerCase() === pathname.toLowerCase());
    if (saveCustomPathItem) {
      return (
        <p
          className={`${
            saveCustomPathItem.redirectTo?.length &&
            'cursor-pointer duration-300 rounded-lg py-1 px-3 hover:!bg-[#2e31381a]'
          } pl-3`}
          onClick={() => saveCustomPathItem.redirectTo?.length && navigate(saveCustomPathItem.redirectTo)}
        >
          {saveCustomPathItem?.customPathName ?? capitalizeFirstLetter(pathName.replace('-', ' '))}
        </p>
      );
    }
  }

  if (customPathName.length) {
    return (
      <p className='pl-3'>
        {customPathName.filter(({ oldName }) => oldName === pathName)[0]?.newName ||
          capitalizeFirstLetter(pathName.replace('-', ' '))}
      </p>
    );
  }
  return <p className='pl-3'>{capitalizeFirstLetter(pathName.replace('-', ' '))}</p>;
};

export const findMenuItemByPath = (path: string) => {
  for (const menu of DROP_DOWN_MENU_NAVIGATION) {
    if (!menu?.children?.length && path.includes(menu.path)) {
      const save = menu.path.split('/').filter((item) => item.length);
      return save;
    }

    if (menu.children) {
      for (const child of menu.children) {
        if (path.includes(child.path)) {
          const save = child.path.split('/').filter((item) => item.length);
          save[1] = child.value;
          return save;
        }
      }
    }
  }

  for (const menu of PROFILE_PAGE_SECONDARY_NAVIGATION) {
    if (menu.path.includes(path)) {
      const save = menu.path.split('/').filter((item) => item.length);
      save[1] = menu.value;
      return save;
    }
  }

  return path.split('/').filter((item) => item.length);
};
