import React from 'react';
import { ChecklistRowProps } from './types';
import { ChecklistRow } from './checklist-row';
import { ChecklistContainer } from './checklist-container';

export const Checklist = ({ list }: { list: ChecklistRowProps[] }) => {
  return (
    <ChecklistContainer>
      {list.map((item, i) => (
        <ChecklistRow key={i} {...item} />
      ))}
    </ChecklistContainer>
  );
};
