import styled from 'styled-components';
import { classNames } from '../utils';
import { CalendarMode, CalendarStyles } from './types';

const getCalendarStyles = (mode: CalendarMode, styles?: Partial<CalendarStyles>): CalendarStyles => {
  const calendarStyles: CalendarStyles = {
    months: classNames('flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0', styles?.months),
    month: classNames('space-y-4', styles?.month),
    caption: classNames('flex justify-center pt-1 relative items-center', styles?.caption),
    caption_label: classNames('text-sm font-medium', styles?.caption_label),
    nav: classNames('space-x-1 flex items-center', styles?.nav),
    nav_button: classNames(
      'inline-flex items-center justify-center whitespace-nowrap rounded-md',
      'ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      'h-10 w-10 border border-zinc-200 p-2 opacity-80 hover:opacity-100 !bg-transparent',
      styles?.nav_button,
    ),
    nav_button_previous: classNames('absolute left-1', styles?.nav_button_previous),
    nav_button_next: classNames('absolute right-1', styles?.nav_button_next),
    table: classNames('w-full border-collapse space-y-1 flex flex-col', styles?.table),
    head_row: classNames('flex w-full', styles?.head_row),
    head_cell: classNames(
      'text-zinc-500 rounded-md w-12 text-sm text-center font-medium !bg-transparent border-none',
      styles?.head_cell,
    ),
    row: classNames('flex w-full mt-2 !border-b-0', styles?.row),
    cell: classNames(
      'h-12 relative p-0 text-center focus-within:relative focus-within:z-20 [&:has([aria-selected])]:!bg-indigo-50  [&:has([aria-selected].day-range-end)]:!rounded-r-md',
      mode === 'range'
        ? '[&:has(>.day-range-end)]:!rounded-r-md [&:has(>.day-range-start)]:!rounded-l-md first:[&:has([aria-selected])]:!rounded-l-md last:[&:has([aria-selected])]:!rounded-r-md'
        : '[&:has([aria-selected])]:!rounded-md',
      styles?.cell,
    ),
    day: classNames(
      'inline-flex items-center justify-center whitespace-nowrap rounded-md ',
      'ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      'h-12 w-12 p-0 font-normal aria-selected:opacity-100 hover:!bg-indigo-600 hover:!text-white',
      styles?.day,
    ),
    day_range_start: classNames('day-range-start', styles?.day_range_start),
    day_range_end: classNames('day-range-end', styles?.day_range_end),
    day_selected: classNames(
      '!bg-indigo-600 text-white  hover:text-white focus:!bg-indigo-600 focus:text-white',
      styles?.day_selected,
    ),
    day_today: classNames('!bg-zinc-900 text-white', styles?.day_today),
    day_outside: classNames(
      'day-outside text-zinc-300 [&:has([aria-selected].day-range-start)]:!text-white [&:has([aria-selected].day-range-end)]:!text-white',
      styles?.day_outside,
    ),
    day_disabled: classNames('text-zinc-300 opacity-50', styles?.day_disabled),
    day_range_middle: classNames(
      'aria-selected:!bg-indigo-50 hover:aria-selected:!bg-indigo-600 hover:aria-selected:!text-white aria-selected:text-zinc-900 aria-selected:!text-zinc-500',
      styles?.day_range_middle,
    ),
    day_hidden: classNames('invisible', styles?.day_hidden),
  };

  return calendarStyles;
};

export { getCalendarStyles };

// @note: this is used to override the styles of tables
// in apps/next/src/styles/Tables.js which creates global styling
// and react-day-picker uses tables for rendering the calendar
export const DatePickerContainer = styled.div`
  table {
    min-width: initial;
    overflow-y: initial;
  }
  tbody {
    overflow-y: initial;
  }
  td {
    background-color: initial;
    color: initial;
    border-bottom: initial;
  }
`;
