import React from 'react';
import { CorrectIcon } from '../../../../assets/generated/svg';
import { components } from 'react-select';
import { CustomOptionProps } from './types';
export const Option = (props: CustomOptionProps) => {
  const data: any = props.data;
  return (
    <components.Option {...props}>
      <div className='flex justify-between items-center gap-2 w-full'>
        <p>{data.label}</p>
        {data.placeholder && <p className='text-[#8C8C8C]'>{data.placeholder}</p>}
      </div>
      {props.isSelected && props.showSelectedIcon && data.showSelectedIcon && <CorrectIcon className='ml-1' />}
    </components.Option>
  );
};

export default Option;
