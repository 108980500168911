import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { bulkDeleteJournalEntryLineTemplate } from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useLineTemplates = () => {
  const { userId, organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.LINE_TEMPLATES, { userId }),
    async () => {
      undefined;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage: any) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage: any) => lastPage.nextPage,
    },
  );
};

export const useBulkDeleteJournalEntryLineTemplate = () =>
  useMutation((data: any) => bulkDeleteJournalEntryLineTemplate(data));
