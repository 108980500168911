import axios from 'axios';
import { SERVER_URL_CORE } from '../../config';
import { convertQuery } from '../utils';
import { prepareSearchParams } from './utils';

export const createTag = async (data) => axios.post(`${SERVER_URL_CORE}/tag`, data);

export const getTags = async (data) => {
  const params = prepareSearchParams(data);

  return axios.get(`${SERVER_URL_CORE}/tag?${params}`);
};

export const patchTag = async (data) => axios.patch(`${SERVER_URL_CORE}/tag?tagId=${data.tagId}`, data.body);

export const deleteTag = async (data) => axios.delete(`${SERVER_URL_CORE}/tag?tagId=${data.tagId}`);

export const bulkDeleteTag = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/bulk-delete-tag?organizationId=${data.organizationId}${convertQuery(data.tagIds, 'tagIds')}`,
  );
