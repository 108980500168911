type WalletNameType = {
  walletName: string;
  address: string;
  exchange: string;
  rain: string;
};

export const handleWalletName = ({ walletName, address, exchange, rain }: Partial<WalletNameType>) => {
  let textValue = '';

  if (walletName) {
    textValue += walletName;
  } else if (address) {
    textValue += address;
  } else if (exchange) {
    textValue += exchange;
  } else if (rain) {
    textValue += rain;
  }

  return textValue;
};
