import { StringFilter } from '../../../components/filters';
import { useJournalSyncFilterContext } from '../context';
import { FilterRowState } from '../types';
import { useMemo } from 'react';

const calculateIsSyncFromFilterState = (selectedRows: FilterRowState<StringFilter>[]) => {
  if (selectedRows.length === 0 || selectedRows.length === 2) return undefined;
  if (selectedRows[0].value === 'Yes') return true;
  if (selectedRows[0].value === 'No') return false;
  return undefined;
};
export const useFilteredJournalSyncTreatment = () => {
  const { state } = useJournalSyncFilterContext();

  return useMemo(() => calculateIsSyncFromFilterState(state.filter((option) => option.selected)), [state]);
};
