// import { setCookie } from 'cookies-next';
import { useSession } from './useSession';

export const isProductionHref = (href: string) =>
  href.startsWith('https://') &&
  href.includes('.entendre.finance') &&
  !href.includes('entendrefinance.vercel.app') &&
  !href.includes('https://staging.entendre.finance') &&
  !href.includes('https://preview.entendre.finance');

export const useOrgChangeRedirect = () => {
  const { organizations, token } = useSession();

  const redirectIfNeeded = (organizationId: string) => {
    const selectedOrganization = organizations.find((o) => o._id === organizationId);

    if (!window?.location.href) {
      // console.log('useOrgChangeRedirect: window?.location.href', window?.location.href);
      return;
    }
    const href = window.location.href;
    const subdomain = selectedOrganization?.webAddress;

    if (!token) {
      // console.log('useOrgChangeRedirect: token', token);
      return;
    }
    if (!subdomain || subdomain.length === 0 || subdomain === 'undefined') {
      // console.log('useOrgChangeRedirect: subdomain', subdomain);
      return;
    }
    const url = isProductionHref(href)
      ? `https://${subdomain}.entendre.finance` // production
      : href.includes('localhost')
        ? `http://${window.location.host}` // localhost
        : `https://${window.location.host}`; // deployment previews

    console.log('useOrgChangeRedirect: redirecting to change-workspace');
    window.location.replace(`${url}/change-workspace?token=${token}&organizationId=${organizationId}`);
  };
  return redirectIfNeeded;
};
