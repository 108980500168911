import React, { ReactNode } from 'react';
import { AvatarProps, AvatarType } from '../avatar/types';

export type DropdownOption = {
  [key: string]: any;
  icon?: ReactNode | string;
  label: string | ReactNode;
  value: string;
};

export interface SingleSelectProps extends Partial<AvatarProps> {
  isError?: boolean;
  disabled?: boolean;
  value?: DropdownOption;
  defaultValue?: DropdownOption;
  options: DropdownOption[];
  onChange?: (value: DropdownOption) => void;
  isLoading?: boolean;
  'data-cy'?: string;
  placeholder?: string;
  clearable?: boolean;
  onClearValue?: () => void;
  fullWidth?: boolean;
  isOnSidepanel?: boolean;
  enableSearch?: boolean;
  onInputChange?: (value: string) => void;
  enableAvatar?: boolean;
  isModal?: boolean;
  enableBottomText?: boolean;
  side?: 'bottom' | 'top' | 'left' | 'right' | undefined;
  align?: 'start' | 'center' | 'end';
  customIconRender?: (option: DropdownOption) => ReactNode;
  blockInlineFilter?: boolean;
  searchInputValue?: string;
  onSearchInputChange?: (value: string) => void;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetching?: boolean;
  forcePersistSearch?: boolean;
}

export interface MultiSelectProps extends Partial<AvatarProps> {
  isError?: boolean;
  disabled?: boolean;
  value?: DropdownOption[];
  options: DropdownOption[];
  onChange?: (value: DropdownOption[]) => void;
  isLoading?: boolean;
  'data-cy'?: string;
  placeholder?: string;
  clearable?: boolean;
  onClearValue?: () => void;
  fullWidth?: boolean;
  isOnSidepanel?: boolean;
  enableSearch?: boolean;
  onInputChange?: (value: string) => void;
  side?: 'bottom' | 'top' | 'left' | 'right' | undefined;
  isModal?: boolean;
  enableAvatar?: boolean;
  enableBottomText?: boolean;
  customIconRender?: (option: DropdownOption) => ReactNode;
  dropdownContentClassName?: string;
}

export interface RowProps {
  option: DropdownOption;
  handleOnChange: (option: DropdownOption) => void;
  selectedValue: DropdownOption | undefined;
  enableAvatar: boolean;
  type: AvatarType;
  enableBottomText?: boolean;
  customIconRender?: (option: DropdownOption) => ReactNode;
}

export interface RowPropsForSelectMenuWithOptionCreate extends RowProps {
  index: number;
  createOptionFromRowFn: (option: DropdownOption) => void;
}

export interface ListProps {
  filter: string;
  options: DropdownOption[];
  selectedValue: DropdownOption | undefined;
  handleOnChange: (option: DropdownOption) => void;
  enableAvatar: boolean;
  type: AvatarType;
  enableBottomText?: boolean;
  customIconRender?: (option: DropdownOption) => ReactNode;
  isFetching?: boolean;
}

export interface ListPropsForSelectMenuWithOptionCreate extends Omit<RowProps, 'option'> {
  // filter: string;
  options: DropdownOption[];
  isFetching?: boolean;
}

export interface SelectMenuWithOptionCreateProps
  extends Omit<SingleSelectProps, 'forcePersistSearch' | 'searchInputValue' | 'onInputChange' | 'onSearchInputChange'> {
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface MultiRowProps {
  option: DropdownOption;
  handleOnChange: (option: DropdownOption, checked: boolean) => void;
  enableAvatar?: boolean;
  enableBottomText?: boolean;
  type?: AvatarType;
  values?: DropdownOption[];
  customIconRender?: (option: DropdownOption) => React.ReactNode;
}

export interface MultiListProps {
  options: DropdownOption[];
  values?: DropdownOption[];
  handleOnChange: (option: DropdownOption, checked: boolean) => void;
  enableAvatar?: boolean;
  enableBottomText?: boolean;
  type?: AvatarType;
  filter: string;
  customIconRender?: (option: DropdownOption) => React.ReactNode;
}
