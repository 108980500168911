import React from 'react';
import { classNames } from 'ui';

interface MetricItemProps {
  label: string | React.ReactNode;
  value: string | number;
  wasTested?: boolean;
  formatInPercentage?: boolean;
  primaryTextClassName?: string;
  secondartTextClassName?: string;
}

const MetricItem = ({
  label,
  value,
  formatInPercentage,
  primaryTextClassName,
  secondartTextClassName,
}: MetricItemProps) => {
  return (
    <div className='flex flex-col'>
      <p className={classNames('font-semibold text-[#111111] text-center', primaryTextClassName)}>
        {value}
        {formatInPercentage ? '%' : ''}
      </p>
      <p className={classNames('text--square secondary !text-[#8C8C8C] text-center', secondartTextClassName)}>
        {label}
      </p>
    </div>
  );
};

export default MetricItem;
