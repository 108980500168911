import React from 'react';
import { OptionProps } from 'react-select';
import { components } from 'react-select';
import Checkbox from '../../Checkbox';
export const Option = (props: OptionProps) => {
  const data: { label: string } = props.data as { label: string };
  return (
    <components.Option {...props}>
      <Checkbox label={data.label} checked={props.isSelected} />
    </components.Option>
  );
};

export default Option;
