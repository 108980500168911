import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import { RadixSliderGlobalStyle } from '../../../styles/RadixSliderStyle';
import { SliderProps } from '@radix-ui/react-slider';
const RadixSlider = ({ ...props }: SliderProps) => (
  <form>
    <RadixSliderGlobalStyle />
    <Slider.Root className='SliderRoot' max={100} step={1} aria-label='Volume' {...props}>
      <Slider.Track className='SliderTrack'>
        <Slider.Range className='SliderRange' />
      </Slider.Track>
      <Slider.Thumb className='SliderThumb' />
    </Slider.Root>
  </form>
);

export default RadixSlider;
