import { useEffect, useState } from 'react';
import { FILTER_TYPE, FilterRowState, StringFilter } from '../../filters';
import { mergeSources } from '../../templates/utils';
import { DisplayedTransaction } from './types';
import { HedgeyIcon } from '../../../assets/generated/icons';
import { dateConverter } from '../../utils';
import { SERVER_URL_ANALYTICS, SERVER_URL_CORE } from 'services/config';
import axios from 'axios';
import { formatTableNumbers } from 'global-utils';
import { getTransactionExplorerLink } from 'ui';
import Image from '../../image-with-fallback';

export function formatExchangeTypeToName(str) {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const getNFTImageUrlFromTransaction = (transaction) => {
  return transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.image_original_url;
};

export const getAddressesFromFilteredWallet = ({ address, addresses }: { address?: string; addresses?: string[] }) => {
  const result: string[] = [];
  if (address) result.push(address);
  if (addresses) result.push(...addresses);
  return Array.from(new Set(result)); // remove duplicates
};

export const hasIntersection = (addresses1, addresses2) => {
  return addresses1.some((address) => addresses2.includes(address));
};

export const isAddressesMatching = (addressesString, addressesArray) => {
  if (addressesString && addressesArray?.length > 0) {
    const splittedAddresses = addressesString.split(',').map((address) => address.trim());
    return hasIntersection(addressesArray, splittedAddresses);
  }
  return false;
};

export const getTransactionChain = (assetType: string, chain: string, istransactionFromKraken: boolean) => {
  if (istransactionFromKraken) return 'KRAKEN';
  if (assetType === 'USD') return 'USD';
  return chain;
};

const feeHandler = (transactionChain) => {
  const chain = transactionChain?.toLowerCase();
  if (['arb', 'op', 'eth', 'base'].includes(chain)) {
    return 'ETH';
  } else if (chain === 'polygon') {
    return 'MATIC';
  } else if (chain === 'avalanche') {
    return 'AVAX';
  } else if (chain === 'binance smart chain') {
    return 'BNB';
  } else if (chain === 'fantom') {
    return 'FTM';
  }
  if (chain === 'celestia') {
    return 'TIA';
  } else {
    return chain?.toUpperCase();
  }
};

export const tableTransactionsFormatter = (transactions, mergedSources) => {
  return transactions.map((transaction) => {
    let partnerImage;
    let partnerName;
    const isDebitFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'debit';
    const isCreditFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'credit';
    if (transaction?.meta?.hedgeyEvent) {
      partnerImage = <HedgeyIcon />;
      partnerName = 'Hedgey';
    } else if (transaction?.meta?.cardholderFirstName || transaction?.sourceType?.includes('RAIN')) {
      transaction.chain = transaction.assetType;
      partnerImage = <img src='/raincards-logo.png' className='rounded-full' />;
      partnerName = 'Rain';
    } else if (transaction?.meta?.loopTransferType) {
      partnerImage = <img src='/loop-logo.svg' className='rounded-[100px] border ' />;
      partnerName = 'Loop';
    } else if (transaction?.meta?.circle) {
      partnerImage = <img src='/circle.png' className='rounded-[100px] border ' alt='circle-integration' />;
      partnerName = 'Circle';
    } else if (transaction?.sourceType == 'MAPLE') {
      partnerImage = <img src='/maple.png' className='rounded-[100px] border' alt='Maple-integration' />;
      partnerName = 'Maple';
    } else if (transaction?.classification === 'NFT') {
      partnerName = 'NFT';
      partnerImage = (
        <Image
          src={transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.image_original_url}
          className='rounded-[100px] h-full w-full border'
          width={16}
          height={16}
        />
      );
      partnerName = 'NFT';
    } else if (transaction?.sourceId?.exchangeSourceType === 'KUCOIN') {
      partnerName = 'Kucoin';
      partnerImage = <img src='/kucoin.png' className='rounded-[100px] border' alt='Kucoin-integration' />;
      transaction.chain = transaction.assetType;
    } else if (transaction?.sourceId?.exchangeSourceType === 'BINANCE') {
      partnerName = 'Binance';
      partnerImage = <img src='/binance.png' className='rounded-[100px] border' alt='Binance-integration' />;
      transaction.chain = transaction.assetType;
    } else if (
      transaction?.meta?.coinbasePrimeType ||
      transaction?.meta?.symbol ||
      transaction?.meta?.destinationSymbol ||
      transaction?.meta?.productId
    ) {
      partnerName = 'Coinbase Prime';
      partnerImage = <img src='/coinbasePrime.png' className='rounded-[100px] border ' />;
    } else if (transaction?.sourceId?.exchangeSourceType === 'COINBASE') {
      partnerName = 'Coinbase';
      partnerImage = <img src='/coinbase.png' className='rounded-[100px] border' alt='coinbase-integration' />;
      transaction.chain = transaction.assetType;
    }
    let secondAvatar;
    if (transaction?.meta?.hedgeyEvent) {
      secondAvatar = <HedgeyIcon />;
    }
    const sequenceNumber = {
      title: transaction?.sequenceNumber,
      desc: dateConverter(transaction?.transactionDate),
      img: transaction?.chain,
      secondAvatar,
      chain: getTransactionChain(transaction?.assetType, transaction?.chain, isCreditFromKraken || isDebitFromKraken),
      partnerImage,
      partnerName,
    };
    if (partnerName === 'Coinbase Prime') {
      sequenceNumber.chain = transaction?.assetType;
    }

    const grossAmount = {
      value: `${formatTableNumbers({ value: transaction?.grossPrice?.$numberDecimal })}`,
      amount: `${formatTableNumbers({ value: transaction?.grossAmount?.$numberDecimal, isAmount: true, significantDecimalPlaces: 2 })} ${transaction?.assetType}`,
    };
    const netAmount = {
      value: `${formatTableNumbers({ value: transaction?.netPrice?.$numberDecimal })}`,
      amount: `${formatTableNumbers({ value: transaction?.netAmount?.$numberDecimal, isAmount: true, significantDecimalPlaces: 2 })} ${transaction?.assetType}`,
    };
    const fee = {
      value: formatTableNumbers({ value: transaction?.feePrice?.$numberDecimal }),
      amount: `${formatTableNumbers({ value: transaction?.fee?.$numberDecimal, isAmount: true, significantDecimalPlaces: 2 })} 
      ${feeHandler(transaction?.chain ?? transaction?.assetType)}`,
    };
    const fromAddressSourceCheck = mergedSources.find(
      (source) =>
        (source?.address?.toLowerCase() === transaction?.fromAddress?.toLowerCase() ||
          isAddressesMatching(transaction?.fromAddress?.toLowerCase(), source?.addresses)) &&
        source?.chain?.toLowerCase() === transaction?.chain?.toLowerCase(),
    );

    const cardholderName =
      transaction?.meta?.cardholderFirstName &&
      transaction?.meta?.cardholderLastName &&
      `${transaction?.meta?.cardholderFirstName} ${transaction?.meta?.cardholderLastName}`;
    const isCredit = transaction?.transactionDirection?.toLowerCase() === 'credit';
    const fromAddress = {
      address: fromAddressSourceCheck?.name || transaction?.fromAddress,
      walletType: fromAddressSourceCheck?.walletType,
      exchange: isDebitFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
      cardholderName,
      rain: isCredit ? transaction?.meta?.merchantName : transaction?.meta?.cardName,
    };

    const toAddressSourceCheck = mergedSources.find(
      (source) =>
        (source?.address?.toLowerCase() === transaction?.toAddress?.toLowerCase() ||
          isAddressesMatching(transaction?.toAddress?.toLowerCase(), source?.addresses)) &&
        source?.chain?.toLowerCase() === transaction?.chain?.toLowerCase(),
    );
    const toAddress = {
      address: transaction?.toAddress,
      walletName: toAddressSourceCheck?.name,
      walletType: toAddressSourceCheck?.walletType,
      exchange: isCreditFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
      rain: isCredit ? transaction?.meta?.cardName : transaction?.meta?.merchantName,
    };

    const transactionAssets = {
      title: transaction?.assetType,
      assetType: transaction?.assetType,
      isNFT: false,
      img: <></>,
      rawContractAddress: transaction?.rawContractAddress,
    };
    if (transaction?.classification === 'NFT') {
      transactionAssets.img = (
        <Image
          src={transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.image_original_url}
          className='rounded-[100px] h-6 w-6 object-cover border'
          width={24}
          height={24}
        />
      );
      transactionAssets.isNFT = true;
    }
    return {
      ...transaction,
      sequenceNumber,
      transactionAssets,
      grossAmount,
      netAmount,
      transactionHash: {
        title: transaction?.transactionHash,
        desc: `${getTransactionExplorerLink(transaction?.chain)}/${transaction?.transactionHash}`,
      },
      fee,
      fromAddress,
      toAddress,
    };
  });
};

export const useDisplayedTransactions = (transactionPages, sourcePages) => {
  const [displayedTransactions, setDisplayedTransactions] = useState<DisplayedTransaction[]>([]);
  useEffect(() => {
    if (transactionPages && sourcePages) {
      const mergedSources = mergeSources(sourcePages);
      let merged = [];
      for (const page of transactionPages.pages) {
        merged = merged.concat(tableTransactionsFormatter(page.transactions, mergedSources));
      }
      setDisplayedTransactions(merged);
    }
  }, [transactionPages, sourcePages]);

  return displayedTransactions;
};
export const useDisplayedSpamTransactions = (transactionPages, sourcePages) => {
  const [displayedTransactions, setDisplayedTransactions] = useState<DisplayedTransaction[]>([]);
  useEffect(() => {
    if (transactionPages && sourcePages) {
      const mergedSources = mergeSources(sourcePages);
      let merged = [];
      for (const page of transactionPages.pages) {
        merged = merged.concat(
          page.transactions.map((transaction) => {
            let partnerImage;
            let partnerName;
            if (transaction?.meta?.hedgeyEvent) {
              partnerImage = <HedgeyIcon />;
              partnerName = 'Hedgey';
            } else if (transaction?.meta?.cardholderFirstName) {
              partnerImage = <img src='/raincards-logo.png' className='rounded-full' />;
              partnerName = 'Rain';
            } else if (transaction?.meta?.loopTransferType) {
              partnerImage = <img src='/loop-logo.svg' className='rounded-[100px] border ' />;
              partnerName = 'Loop';
            } else if (transaction?.meta?.circle) {
              partnerImage = <img src='/circle.png' className='rounded-[100px] border ' />;
              partnerName = 'Circle';
            } else if (transaction?.sourceType == 'MAPLE') {
              partnerImage = <img src='/maple.png' className='rounded-[100px] border' alt='Maple-integration' />;
              partnerName = 'Maple';
            } else if (transaction?.classification === 'NFT') {
              partnerName = 'NFT';
              partnerImage = (
                <img
                  src={transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.image_original_url}
                  className='rounded-[100px] h-4 w-4 border '
                />
              );
              partnerName = 'NFT';
            } else if (transaction?.sourceId?.exchangeSourceType === 'KUCOIN') {
              partnerName = 'Kucoin';
              partnerImage = <img src='/kucoin.png' className='rounded-[100px] border' alt='Kucoin-integration' />;
              transaction.chain = transaction.assetType;
            } else if (transaction?.sourceId?.exchangeSourceType === 'BINANCE') {
              partnerName = 'Binance';
              partnerImage = <img src='/binance.png' className='rounded-[100px] border' alt='Binance-integration' />;
              transaction.chain = transaction.assetType;
            } else if (
              transaction?.meta?.coinbasePrimeType ||
              transaction?.meta?.symbol ||
              transaction?.meta?.destinationSymbol ||
              transaction?.meta?.productId
            ) {
              partnerName = 'Coinbase Prime';
              partnerImage = <img src='/coinbasePrime.png' className='rounded-[100px] border ' />;
            }

            const sequenceNumber = {
              title: transaction.sequenceNumber,
              desc: dateConverter(transaction.transactionDate),
              img: transaction.chain,
              secondAvatar: transaction?.meta?.hedgeyEvent && <HedgeyIcon />,
              chain: transaction.assetType === 'USD' ? 'USD' : transaction.chain,
              partnerImage,
              partnerName,
            };
            if (partnerName === 'Coinbase Prime') {
              sequenceNumber.chain = transaction.assetType;
            }
            const grossAmount = {
              value: parseFloat(transaction?.grossPrice?.$numberDecimal),
              amount: `${parseFloat(transaction?.grossAmount?.$numberDecimal)} ${transaction.assetType}`,
            };
            const netAmount = {
              value: parseFloat(transaction?.netPrice?.$numberDecimal),
              amount: `${parseFloat(transaction?.netAmount?.$numberDecimal)} ${transaction.assetType}`,
            };
            const fee = {
              value: parseFloat(transaction?.feePrice?.$numberDecimal),
              amount: `${parseFloat(transaction?.fee?.$numberDecimal)} ${transaction.assetType}`,
            };
            const fromAddressSourceCheck = mergedSources.find(
              (source) =>
                (source?.address?.toLowerCase() === transaction?.fromAddress?.toLowerCase() ||
                  isAddressesMatching(transaction?.fromAddress?.toLowerCase(), source?.addresses)) &&
                source?.chain?.toLowerCase() === transaction?.chain?.toLowerCase(),
            );
            const cardholderName =
              transaction?.meta?.cardholderFirstName &&
              transaction?.meta?.cardholderLastName &&
              `${transaction?.meta?.cardholderFirstName} ${transaction?.meta?.cardholderLastName}`;
            const isDebitFromKraken =
              transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'debit';
            const isCreditFromKraken =
              transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'credit';
            const isCredit = transaction?.transactionDirection?.toLowerCase() === 'credit';
            const fromAddress = {
              address: fromAddressSourceCheck?.name || transaction.fromAddress,
              walletType: fromAddressSourceCheck?.walletType,
              exchange: isDebitFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
              cardholderName,
              rain: isCredit ? transaction?.meta?.merchantName : transaction?.meta?.cardName,
            };

            const toAddressSourceCheck = mergedSources.find(
              (source) =>
                (source?.address?.toLowerCase() === transaction?.toAddress?.toLowerCase() ||
                  isAddressesMatching(transaction?.toAddress?.toLowerCase(), source?.addresses)) &&
                source?.chain?.toLowerCase() === transaction?.chain?.toLowerCase(),
            );

            const toAddress = {
              address: toAddressSourceCheck?.name || transaction.toAddress,
              walletType: toAddressSourceCheck?.walletType,
              exchange: isCreditFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
              rain: isCredit ? transaction?.meta?.cardName : transaction?.meta?.merchantName,
            };

            const transactionAssets = {
              title: transaction?.assetType,
              assetType: transaction?.assetType,
              isNFT: false,
              img: <></>,
            };
            if (transaction?.classification === 'NFT') {
              transactionAssets.img = (
                <img
                  src={transaction?.meta?.simpleHashNFTDetails?.extra_metadata.image_original_url}
                  className='rounded-[100px] h-6 w-6 object-cover border '
                />
              );
              transactionAssets.isNFT = true;
            }
            return {
              ...transaction,
              sequenceNumber,
              transactionAssets,
              grossAmount,
              netAmount,
              fee,
              fromAddress,
              toAddress,
            };
          }),
        );
      }
      setDisplayedTransactions(merged);
    }
  }, [transactionPages, sourcePages]);

  return displayedTransactions;
};

export function handleTransactionsTable(transactions, sources) {
  if (!transactions || !sources) {
    return null;
  }

  const mergedSources = mergeSources(sources);

  const merged = transactions.transactions.map((transaction) => {
    const {
      meta,
      sequenceNumber,
      transactionDate,
      chain,
      assetType,
      grossPrice,
      netPrice,
      feePrice,
      fromAddress,
      toAddress,
      exchangeRefId,
      transactionDirection,
    } = transaction;

    const partnerImage = meta?.hedgeyEvent ? <HedgeyIcon /> : null;
    const partnerName = meta?.hedgeyEvent ? 'Hedgey' : meta?.cardholderFirstName ? 'Rain' : null;

    const cardholderName =
      (meta?.cardholderFirstName &&
        meta?.cardholderLastName &&
        `${meta?.cardholderFirstName} ${meta?.cardholderLastName}`) ??
      null;

    const isDebitFromKraken = exchangeRefId && transactionDirection?.toLowerCase() === 'debit';
    const isCreditFromKraken = exchangeRefId && transactionDirection?.toLowerCase() === 'credit';
    const isCredit = transactionDirection?.toLowerCase() === 'credit';

    const fromAddressSourceCheck = mergedSources.find(
      (source) =>
        (source?.address?.toLowerCase() === fromAddress?.toLowerCase() ||
          isAddressesMatching(fromAddress?.toLowerCase(), source?.addresses)) &&
        source?.chain?.toLowerCase() === chain?.toLowerCase(),
    );

    const fromAddressResult = fromAddressSourceCheck || {};

    const fromAddr = {
      address: fromAddressResult?.name || fromAddress,
      walletType: fromAddressResult?.walletType ?? null,
      exchange: isDebitFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
      cardholderName,
      rain: isCredit ? meta?.merchantName || meta?.cardName : null,
    };

    const toAddressSourceCheck = mergedSources.find(
      (source) =>
        (source?.address?.toLowerCase() === toAddress?.toLowerCase() ||
          isAddressesMatching(toAddress?.toLowerCase(), source?.addresses)) &&
        source?.chain?.toLowerCase() === chain?.toLowerCase(),
    );

    const toAddressResult = toAddressSourceCheck || {};

    const toAddr = {
      address: toAddressResult?.name || toAddress,
      walletType: toAddressResult?.walletType ?? null,
      exchange: isCreditFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
      rain: isCredit ? meta?.cardName || meta?.merchantName : null,
    };

    return {
      ...transaction,
      sequenceNumber: {
        title: sequenceNumber,
        desc: dateConverter(transactionDate),
        img: chain,
        secondAvatar: meta?.hedgeyEvent ? <HedgeyIcon /> : null,
        rainAvatar: transaction?.sourceType.includes('RAIN') ? (
          <img src='/raincards-logo.png' className='rounded-full' />
        ) : null,
        chain: assetType === 'USD' ? 'USD' : chain,
        partnerImage,
        partnerName,
      },
      transactionAssets: {
        title: assetType,
        img: assetType,
      },
      grossAmount: parseFloat(grossPrice?.$numberDecimal) || 0,
      netAmount: parseFloat(netPrice?.$numberDecimal) || 0,
      fee: parseFloat(feePrice?.$numberDecimal) || 0,
      fromAddress: fromAddr,
      toAddress: toAddr,
    };
  });

  return merged;
}

export async function getTransactionsMetricsData({ organizationId, jwt }) {
  const respone = await axios
    .get(
      `${SERVER_URL_CORE}/transaction-stats?organizationId=${organizationId}&page=0&pageSize=25&sortBy=transactionDate&sortDirection=desc`,
      {
        headers: {
          Authorization: jwt?.toString() ?? '',
        },
      },
    )
    .then((res) => res.data)
    .then((res) => res)
    .catch(() => ({ success: false }));

  return respone;
}

export async function getTransactionsChartData({ organizationId, jwt }) {
  const respone = await axios
    .get(`${SERVER_URL_ANALYTICS}/get-transactions-volume?organizationId=${organizationId}`, {
      headers: {
        Authorization: jwt?.toString() ?? '',
      },
    })
    .then((res) => res.data)
    .then((res) => res)
    .catch(() => ({ success: false }));

  return respone;
}

async function fetchData(url, headers) {
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null; // Handle errors as needed
  }
}

export async function getTransactionsTableData({ organizationId, jwt }) {
  const headers = {
    Authorization: jwt?.toString() ?? '',
  };

  const transactionsUrl = `${SERVER_URL_CORE}/transaction?organizationId=${organizationId}&page=0&pageSize=25&sortBy=transactionDate&sortDirection=desc`;
  const sourcesUrl = `${SERVER_URL_CORE}/wallet?organizationId=${organizationId}&page=0&pageSize=25&sortBy=transactionDate&sortDirection=desc`;

  try {
    const [transactions, sources] = await Promise.all([
      fetchData(transactionsUrl, headers),
      fetchData(sourcesUrl, headers),
    ]);

    if (transactions === null || sources === null) {
      // Handle error here
    }

    const displayedTransactions = handleTransactionsTable(transactions, sources);
    return displayedTransactions;
  } catch (error) {
    console.error('Error:', error);
    return null; // Handle errors as needed
  }
}

export const getShowTransactionsWithoutJournalEntries = (filterHelpers) => {
  const selectedTreatment = filterHelpers[FILTER_TYPE.ACCOUNTING_TREATMENT]?.getAllSelectedWithTransform()[0];
  return selectedTreatment;
};

export const getShowTransactionsWithoutJournalEntriesBooleanOrUndefined = (
  selectedTreatments: FilterRowState<StringFilter>[],
) => {
  const selectedTreatment = selectedTreatments[0];
  if (!selectedTreatment) return undefined;
  if (selectedTreatment.value === 'true') return false;
  else if (selectedTreatment.value === 'false') return true;
  return undefined;
};

export const getTop5AssetsByCount = (assetsByCount: Record<string, number>) => {
  return Object.entries(assetsByCount)
    .sort(([, countA], [, countB]) => countB - countA)
    .slice(0, 5)
    .map(([asset]) => asset);
};
