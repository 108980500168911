import React, { useEffect, useMemo, useState } from 'react';
import { Sidebar, SidebarTopBar } from '../atoms/Sidebar';
import { PatchSourceSidebarContent } from './PatchSourceSidebarContent';
import { ViewSourceSidebarContent } from './ViewSourceSidebarContent';
import { useTabState } from '../../hooks';
import { useRouter } from 'next/router';
import { updateRouteStackedDefaultState } from '../utils';

enum WALLET_SIDEBAR_MODE {
  VIEW = 'view',
  PATCH = 'patch',
}

export function ManageSourceSidebarContent({
  onClose,
  onBack,
  sourceId,
  isPrimary,
  isDockPanelAvailable,
  defaultState,
}) {
  const router = useRouter();
  const {
    activeTab,
    state: { sidebarState },
    updateTabSidebarState,
  } = useTabState();

  const memoizedTabUpdate = useMemo(() => activeTab?.id, [activeTab?.id]);

  const [mode, setMode] = useState<WALLET_SIDEBAR_MODE>(defaultState?.mode ?? WALLET_SIDEBAR_MODE.VIEW);

  useEffect(() => {
    setMode(defaultState?.mode ?? WALLET_SIDEBAR_MODE.VIEW);
  }, [memoizedTabUpdate]);

  return (
    <Sidebar>
      <SidebarTopBar
        onClose={onClose}
        onBack={onBack}
        itemId={sourceId}
        isPrimary={isPrimary}
        isDockPanelAvailable={isDockPanelAvailable}
      />
      {mode === WALLET_SIDEBAR_MODE.VIEW && (
        <ViewSourceSidebarContent
          sourceId={sourceId}
          onPatch={() => {
            if (!isPrimary) {
              updateRouteStackedDefaultState({
                sidebarState,
                updateTabSidebarState,
                mode: WALLET_SIDEBAR_MODE.PATCH,
              });
            }
            setMode(WALLET_SIDEBAR_MODE.PATCH);
          }}
        />
      )}
      {mode === WALLET_SIDEBAR_MODE.PATCH && (
        <PatchSourceSidebarContent
          onCancel={() => {
            if (!isPrimary) {
              updateRouteStackedDefaultState({
                sidebarState,
                updateTabSidebarState,
                mode: WALLET_SIDEBAR_MODE.VIEW,
                formState: {},
              });
            }
            setMode(WALLET_SIDEBAR_MODE.VIEW);
          }}
          walletId={sourceId}
          onDelete={() => {
            router.replace('/ledger/sources');
            onClose();
          }}
          defaultFormState={sidebarState?.secondRouteStack?.at(-1)?.defaultState?.formState ?? {}}
        />
      )}
    </Sidebar>
  );
}
