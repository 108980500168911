import { useEffect, useState } from 'react';
import { Transaction } from 'schemas';
import { LineWithDecimalAmount } from '../../../PageComponents/Journals/type';

export type BalanceDrilldownRowData = {
  _id: any;
  type: 'CREDIT' | 'DEBIT';
  journal: {
    sequenceNumber: any;
  };
  transaction: {
    sequenceNumber: string | undefined;
  };
  amount: string;
};

export const convertJournalEntryLinesToBalanceDrillDownRows = (lines) => {
  return lines.map((line) => ({
    _id: line._id,
    type: (line.journalEntryLine as LineWithDecimalAmount)?.creditOrDebit,
    journal: { sequenceNumber: line.journalSequenceNumber },
    transaction: { sequenceNumber: (line.transaction as Transaction)?.sequenceNumber },
    amount: (line.journalEntryLine as LineWithDecimalAmount)?.amount.$numberDecimal,
  }));
};

export const useBalanceDrilldownRows = (journalEntryLinePages) => {
  const [balanceDrilldownRows, setBalanceDrilldownRows] = useState<BalanceDrilldownRowData[]>([]);

  useEffect(() => {
    if (journalEntryLinePages) {
      const jeLines = journalEntryLinePages.pages
        .map((page) => page.journalEntryLines)
        .flat()
        .map((line) => ({
          _id: line._id,
          type: (line.journalEntryLine as LineWithDecimalAmount).creditOrDebit,
          journal: { sequenceNumber: line.journalSequenceNumber },
          transaction: { sequenceNumber: (line.transaction as Transaction)?.sequenceNumber },
          amount: (line.journalEntryLine as LineWithDecimalAmount).amount.$numberDecimal,
        }));
      setBalanceDrilldownRows(jeLines);
    }
  }, [journalEntryLinePages]);

  return balanceDrilldownRows;
};
