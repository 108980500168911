import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../@/components/ui/table';
import { AuditRecord, User } from 'schemas';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from '../../@/components/ui/pagination';
import { Skeleton } from '../../@/components/ui/skeleton';

export interface AuditLogsTableProps {
  auditLogs: Partial<AuditRecord & { userId: User }>[];
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  isLoading: boolean;
  hasNextPage: boolean;
}

const extractNameFields = (targetId: any): string => {
  if (typeof targetId !== 'object' || targetId === null) return '';

  let extractedFields = Object.entries(targetId)
    .filter(([key]) => key.toLowerCase().includes('name') || key.toLowerCase().includes('sequence'))
    .map(([key, value]) => `${String(value).replace(/_/g, ' ')}`);

  // Check for member.email
  if (targetId.memberId && targetId.memberId.email) {
    extractedFields.push(targetId.memberId.email);
  }

  return extractedFields.join(', ');
};

export const AuditLogsTable: React.FC<AuditLogsTableProps> = ({
  auditLogs,
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
  hasNextPage,
}) => {
  const LoadingSkeleton = () => (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell className='py-2'>
            <Skeleton className='h-4 w-[80%]' />
          </TableCell>
          <TableCell className='py-2'>
            <Skeleton className='h-4 w-[70%]' />
          </TableCell>
          <TableCell className='py-2'>
            <Skeleton className='h-4 w-[90%]' />
          </TableCell>
          <TableCell className='py-2'>
            <Skeleton className='h-4 w-[85%]' />
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  const renderPaginationItems = () => {
    const items: React.ReactNode[] = [];
    const displayedCurrentPage = currentPage + 1; // Adjust for 0-based index
    const displayedTotalPages = hasNextPage ? displayedCurrentPage + 1 : displayedCurrentPage;
    console.log({ displayedCurrentPage, displayedTotalPages, hasNextPage });
    // Always show first page
    items.push(
      <PaginationItem key={0}>
        <PaginationLink onClick={() => onPageChange(0)} isActive={displayedCurrentPage === 1}>
          1
        </PaginationLink>
      </PaginationItem>,
    );

    if (displayedTotalPages <= 7) {
      // If there are 7 or fewer pages, show all of them
      for (let i = 2; i <= displayedTotalPages; i++) {
        items.push(
          <PaginationItem key={i - 1}>
            <PaginationLink onClick={() => onPageChange(i - 1)} isActive={displayedCurrentPage === i}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        );
      }
    } else {
      // More than 7 pages, we need to add ellipses
      if (displayedCurrentPage > 3) {
        items.push(<PaginationEllipsis key='ellipsis-start' />);
      }

      // Show pages around the current page
      const startPage = Math.max(2, displayedCurrentPage - 1);
      const endPage = Math.min(displayedTotalPages - 1, displayedCurrentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <PaginationItem key={i - 1}>
            <PaginationLink onClick={() => onPageChange(i - 1)} isActive={displayedCurrentPage === i}>
              {i}
            </PaginationLink>
          </PaginationItem>,
        );
      }

      if (displayedCurrentPage < displayedTotalPages - 2) {
        items.push(<PaginationEllipsis key='ellipsis-end' />);
      }

      // Always show last page
      items.push(
        <PaginationItem key={displayedTotalPages - 1}>
          <PaginationLink
            onClick={() => onPageChange(displayedTotalPages - 1)}
            isActive={displayedCurrentPage === displayedTotalPages}
          >
            {displayedTotalPages}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    return items;
  };

  return (
    <>
      <Table className='text-sm'>
        <TableHeader>
          <TableRow>
            <TableHead className='py-2'>User</TableHead>
            <TableHead className='py-2'>Action</TableHead>
            <TableHead className='py-2'>Description</TableHead>
            <TableHead className='py-2'>Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <LoadingSkeleton />
          ) : auditLogs.length > 0 ? (
            auditLogs.map((log) => (
              <TableRow key={log._id}>
                <TableCell className='py-2'>{log?.userId?.email}</TableCell>
                <TableCell className='py-2'>{`${log.operationMethod} ${log.operationPath}`}</TableCell>
                <TableCell className='py-2'>
                  {log.description}
                  {extractNameFields(log.targetId) && (
                    <span className='normal-case'> {extractNameFields(log.targetId)}</span>
                  )}
                </TableCell>
                <TableCell className='py-2'>{new Date(log.createdAt as Date).toLocaleString()}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} className='text-center py-4'>
                No audit logs to display.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination className='mt-2'>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              onClick={() => onPageChange(currentPage - 1)}
              aria-disabled={currentPage === 0 || isLoading}
            />
          </PaginationItem>
          {renderPaginationItems()}
          <PaginationItem>
            <PaginationNext onClick={() => onPageChange(currentPage + 1)} aria-disabled={!hasNextPage || isLoading} />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </>
  );
};
