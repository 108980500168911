import { useMutation } from '@tanstack/react-query';
import { createAiCreditUsageRecord } from 'services';

export const useCreateAiCreditUsageRecordMutation = () =>
  useMutation(async ({ aiCreditCount, operationType }: { aiCreditCount: number; operationType: string }) => {
    const response = await createAiCreditUsageRecord({
      aiCreditCount,
      operationType,
    });
    return response.data;
  });
