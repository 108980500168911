import { JournalEntryTemplate, } from 'services/http/response.types';
import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import { useJournalEntryTemplates, } from '../../../hooks';
import { useQueryDataWithFilterState } from './use-query-with-filter-state';
import { FilterContextParams } from '../types';
import { useMemo } from 'react';
import { mergeJournalEntryTemplates } from '../../../components/templates/utils';

export type JournalEntryTemplateFilterContextType = FilterContextParams<JournalEntryTemplate>;

export const useJournalEntryTemplateFilterState = (page: string) => {
  const { state, setState } = useFilterState<JournalEntryTemplate>(FILTER_TYPE.JOURNAL_ENTRY_TEMPLATE, page);

  const { data, isLoading } = useJournalEntryTemplates({ pageSize: 500 });
  useQueryDataWithFilterState(data, setState, mergeJournalEntryTemplates);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading,
    hasSelections,
  };
};
