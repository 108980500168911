import * as React from 'react';
import { forwardRef } from 'react';
const SvgWarning = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 36 30' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M2.542 30c-.64 0-1.111-.278-1.417-.833-.306-.556-.306-1.111 0-1.667L16.542.833C16.847.278 17.333 0 18 0c.667 0 1.153.278 1.458.833L34.875 27.5c.306.556.306 1.111 0 1.667-.306.555-.778.833-1.417.833H2.542ZM18 11.667c-.472 0-.868.16-1.187.478-.32.32-.48.716-.48 1.188v5c0 .473.16.868.48 1.187.32.32.715.48 1.187.48.472 0 .868-.16 1.188-.48.32-.319.479-.714.479-1.187v-5c0-.472-.16-.868-.479-1.188A1.617 1.617 0 0 0 18 11.667ZM18 25c.472 0 .868-.16 1.188-.48.32-.319.479-.714.479-1.187 0-.472-.16-.867-.479-1.186-.32-.32-.716-.48-1.188-.48s-.868.16-1.187.48c-.32.319-.48.714-.48 1.186 0 .473.16.868.48 1.187.32.32.715.48 1.187.48Z'
      fill='orange'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWarning);
export default ForwardRef;
