import React from 'react';
import {
  Currency,
  CurrencyMenu,
  InputLabel,
  InputWithExtras,
  SingleSelectMenu,
  TextareaInput,
  currencyOptions,
} from 'ui';
import { ENTITY_TYPES } from '../../constants';

export interface CreateLegalEntityFormState {
  entityName: string;
  addressString: string;
  entityType: string;
  currency?: Currency;
}

export interface CreateLegalEntityFormElementsProps {
  formState: CreateLegalEntityFormState;
  setFormState: React.Dispatch<React.SetStateAction<CreateLegalEntityFormState>>;
}

export const CreateLegalEntityFormElements = ({ formState, setFormState }: CreateLegalEntityFormElementsProps) => {
  return (
    <>
      <div>
        <InputLabel heading='Business name' />
        <InputWithExtras
          value={formState.entityName}
          onChange={(e) => setFormState((prev) => ({ ...prev, entityName: e.target.value }))}
          data-cy='createLegalEntityFE_legalEntityNameInput'
        />
      </div>
      <div>
        <InputLabel heading='Business type' />

        <SingleSelectMenu
          fullWidth
          isOnSidepanel
          options={ENTITY_TYPES}
          placeholder='Select business type'
          onChange={(option) => {
            setFormState((prev) => ({ ...prev, entityType: option.value }));
          }}
          value={ENTITY_TYPES.find((option) => option.value.toLowerCase() == formState.entityType?.toLowerCase())}
          onClearValue={() => {
            setFormState((prev) => ({
              ...prev,
              entityType: '',
            }));
          }}
          data-cy='createLegalEntityFE_legalEntityTypeMenu'
        />
      </div>
      <div>
        <InputLabel heading='Business address' />
        <TextareaInput
          value={formState.addressString}
          onChange={(e) => setFormState((prev) => ({ ...prev, addressString: e.target.value }))}
          data-cy='createLegalEntityFE_legalEntityAddressInput'
        />
      </div>
      <div>
        <InputLabel heading='Currency' />
        <CurrencyMenu
          options={currencyOptions}
          value={formState.currency}
          onChange={(currency) => setFormState((prev) => ({ ...prev, currency }))}
          data-cy='createLegalEntityFE_legalEntityCurrencyMenu'
        />
      </div>
    </>
  );
};
