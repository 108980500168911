import axios from 'axios';
import { prepareSearchParams } from './utils';
import { SERVER_URL_CORE } from '../../config';

export const getScheduleOfDisposition = async (params) => {
  const stringifiedParams = prepareSearchParams(params, {
    isPaginated: true,
  });
  return axios.get(`${SERVER_URL_CORE}/get-schedule-of-disposition?${stringifiedParams}`);
};
