import React from 'react';
import { MdDragIndicator } from 'react-icons/md';
import { Button, Tooltip, classNames } from 'ui';
import { EditSourceTableGroup } from './TableGroup';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BiSolidDockRight } from 'react-icons/bi';
import { fixedTableColumn } from '../utils';
import { SortTableRowComponentProps } from './types';

export const SortTableRowComponent = ({
  row,
  dissolveGroup,
  dragActive,
  updateTableGroup,
  changeGroupEmoji,
  sidebarIds,
  enableColumnPinning,
  hideCheckboxes,
  onRowClick,
  isTableLoading,
  isSortable,
  tableHeader,
  isGroupable,
  isScrollable,
  flexRender,
  data,
}: SortTableRowComponentProps) => {
  if (row.original.subRows) {
    return (
      <EditSourceTableGroup
        row={row}
        dragActive={dragActive}
        isScrollable={isScrollable}
        isTableLoading={isTableLoading}
        onDissolveGroup={() => dissolveGroup(row.original._id)}
        onUpdate={updateTableGroup}
        onEmojiChange={changeGroupEmoji}
        data={data}
      />
    );
  }
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.original._id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  let selectedRow = false;
  if (row.original._id) {
    selectedRow = sidebarIds.includes(row.original._id);
  }

  return (
    <tr id={row.original._id} ref={setNodeRef} style={style}>
      <td
        className={classNames(
          'w-12 !p-0 z-normal left-0 !sticky min-w-[24px]',
          row.getIsSelected() && 'bg-[#f8f8f8]',
          dragActive && '!z-normal',
          !enableColumnPinning && '!relative',
          selectedRow && '!bg-indigo-100 before:!block border-b-2 border-b-zinc-300',
        )}
      >
        <button
          className={classNames('w-full h-full flex items-center justify-center', row.depth > 0 && 'relative left-16')}
          {...listeners}
          {...attributes}
        >
          <MdDragIndicator className='text-zinc-400 cursor-grab' />
        </button>
      </td>
      {row.getVisibleCells().map((cell, idx) => {
        const index = hideCheckboxes ? idx : idx - 1;
        let childWidth: any;
        if (dragActive) {
          childWidth = document?.getElementById(`${row.original._id}`)?.children[idx + 1].getBoundingClientRect().width;
        }
        return (
          <td
            key={cell.id}
            onClick={(e) => {
              cell.row.getToggleExpandedHandler();
              if (cell.column.columnDef.header !== 'Account' && onRowClick) {
                // setScrollPosition(window.scrollY);
                e.stopPropagation();
                onRowClick(row, cell);
              }
            }}
            data-testid='tbody__td'
            className={classNames(
              // cell.row.depth && 'bg-[#F8F8F8] whitespace-nowrap ml-7.5',
              row.getIsSelected() && 'bg-[#f8f8f8] text-[#111111]',
              tableHeader[index]?.type === 'fixedRight' && 'sticky-table fixedRight',
              tableHeader[index]?.type === 'fixedLeft' && 'sticky-table fixedLeft',
              selectedRow && '!bg-indigo-100 border-top-none border-b-2 border-b-zinc-300',
              selectedRow && !idx && '!bg-indigo-100 before:!block border-b-2 border-b-zinc-300',
              row?.original?.__className,
              cell.column.id === 'checkbox' &&
                enableColumnPinning &&
                !isSortable &&
                '!p-0 !z-[1] left-12 !sticky min-w-[55px]',
              cell.column.id === 'checkbox' &&
                enableColumnPinning &&
                isSortable &&
                '!p-0 !z-[2] left-6 !sticky min-w-[55px]',
              index === 0 && isSortable && enableColumnPinning && !hideCheckboxes && '!p-0 !z-[1] left-[92px] !sticky',
              !dragActive && enableColumnPinning && 'sticky',
              isSortable && !enableColumnPinning && index !== -1 && 'pl-0',
              dragActive && isGroupable && 'opacity-80',
              '!px-0',
            )}
            style={{
              left: fixedTableColumn.left(tableHeader[index]?.type, idx),
              right: fixedTableColumn.right(tableHeader[index]?.type, idx, row.getVisibleCells().length),
              width: dragActive ? childWidth : '',
            }}
          >
            <div
              style={{
                paddingLeft: index === 0 ? (row?.parentId?.length ? row.depth * 3.25 * 10 + 12.5 : 0) : 'auto',
              }}
              className={classNames(
                'h-full flex items-center',
                cell.column.id === 'checkbox' && 'flex justify-start',
                dragActive && 'absolute top-0',
              )}
            >
              {!index ? (
                <span
                  style={{
                    boxShadow:
                      isScrollable && enableColumnPinning && !isTableLoading
                        ? 'rgb(0 0 0 / 10%) 11px 4px 19px -15px, rgb(0 0 0 / 10%) 0px 10px 8px -10px'
                        : '',
                  }}
                  className='flex justify-between items-center w-full h-full'
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  {onRowClick ? (
                    <span className='h-10 w-[90px] mx-2'>
                      <Tooltip content='View in panel' removeTooltipContainerClassname>
                        <Button
                          onClick={(e) => {
                            // setScrollPosition(window.scrollY);
                            e.stopPropagation();
                            onRowClick(row, cell);
                          }}
                          className='viewBtn !hidden'
                          label='View'
                          emphasis='medium'
                          leadingIcon={<BiSolidDockRight className='w-5 h-5' />}
                        />
                      </Tooltip>
                    </span>
                  ) : null}
                </span>
              ) : (
                <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default SortTableRowComponent;
