import axios from 'axios';
import { SERVER_URL_AUDIT_RECORDS } from '../config';
import { AuditRecord } from 'schemas';

interface GetAuditRecordsParams {
    userId?: string;
    targetPath?: string;
    page?: number;
    pageSize?: number;
    operationMethod?: string;
    startDate?: string;
}

interface AuditRecordsResponse {
    auditRecords: AuditRecord[];
    nextPage: number;
    prevPage: number;
}

export const getAuditRecords = async (params: GetAuditRecordsParams): Promise<AuditRecordsResponse> => {
    const response = await axios.get<AuditRecordsResponse>(`${SERVER_URL_AUDIT_RECORDS}/audits`, {
        params: {
            ...params,
            page: params.page || 0,
            pageSize: params.pageSize || 10, // Set a default page size if not provided
            startDate: params.startDate ? new Date(params.startDate).toISOString() : undefined,
        },
    });
    return response.data;
};