import React from 'react';
import { MetricCardProps } from './types';
import { LoadingComponent } from '../SkeletonLoading';
import { Card } from '../../../styles';
import { Tooltip } from '../Tooltip';
import { classNames } from 'ui';

export function MetricCard({
  label = 'Label',
  metric = '0',
  loading,
  icons,
  containerClassName,
  metricClassName,
  labelClassName,
}: MetricCardProps) {
  return (
    <Card className={classNames('w-full gap-0 py-4 px-5', containerClassName)}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className={classNames('text-sm font-semibold text-black-1 opacity-40 uppercase', labelClassName)}>
            {label}
          </div>
          <div className='flex justify-between w-full items-center'>
            <p className={classNames('text-[32px] font-medium', metricClassName)}>{metric}</p>
            <div className='flex'>
              {icons?.map((icon, index) => {
                const translateLeft = (icons.length - index) * 10;
                return (
                  <Tooltip key={index} content={icon.alt} style={{ left: translateLeft }} className='relative'>
                    <div className={`p-1 rounded-full relative bg-white`} style={{ left: translateLeft }}>
                      <div className={`h-6 w-6 rounded-full bg-gray-400`}>
                        <img
                          height={40}
                          width={40}
                          src={icon.url}
                          onError={(e) => {
                            e.currentTarget.style.display = 'none';
                          }}
                          alt={icon.alt}
                          className='h-6 w-6 rounded-full'
                        />
                      </div>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

export default MetricCard;
