import React, { useEffect, useRef } from 'react';
import { animate } from 'framer-motion';
import { AnimatedStatsProps } from './types';

export const AnimatedStats = ({ from = 0, to }: AnimatedStatsProps) => {
  const nodeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (node) node.textContent = `${value.toFixed(0)}`;
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
};
