import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  bulkArchiveLegalEntity,
  bulkDeleteLegalEntity,
  createLegalEntity,
  deleteLegalEntity,
  getLegalEntity,
  getLegalEntityWiseAccountingPeriods,
  patchLegalEntity,
} from 'services/http';

import { ColumnSort } from '@tanstack/react-table';
import { useSession } from '../useSession';
import { LegalEntity } from 'schemas';
import { PatchLEPayload, PostLEPayload } from 'services/http/response.types';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useLegalEntities = (payload?: {
  legalEntityIds?: string;
  sort?: ColumnSort;
  fields?: string[];
  searchTerm?: string;
}) => {
  const { legalEntityIds, sort = { id: 'createdAt', desc: true }, fields = [], searchTerm = '' } = payload || {};

  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.LEGAL_ENTITIES, { sort }),
    async ({ pageParam = 0 }) => {
      const response = await getLegalEntity({
        organizationId,
        page: pageParam,
        legalEntityIds,
        sort,
        fields,
        searchTerm,
      });
      return response?.data?.legalEntities ?? [];
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
    },
  );
};
export const useLegalEntityWiseAccountingPeriods = () => {
  const PAGE_SIZE = 20;
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.LEGAL_ENTITY_WISE_ACCOUNTING_PERIODS),
    async ({ pageParam = 0 }) => {
      const response = await getLegalEntityWiseAccountingPeriods({
        organizationId,
        page: pageParam,
        pageSize: PAGE_SIZE,
      });
      return response?.data?.legalEntities ?? [];
    },
    {
      enabled: !!organizationId,
      getNextPageParam: (lastPage, pages) => (lastPage.length < PAGE_SIZE ? undefined : pages.length),
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetLegalEntityById = (legalEntityId) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.LEGAL_ENTITIES, { legalEntityId }),
    async () => {
      const response = await getLegalEntity({
        organizationId,
        legalEntityIds: [legalEntityId],
        sort: -1,
        page: 0,
      });
      return response?.data?.legalEntities[0] as LegalEntity;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useCreateLegalEntity = () => {
  const { invalidateLegalEntities } = useInvalidateQuery();
  return useMutation({
    mutationFn: (data: PostLEPayload) => createLegalEntity(data),
    onSuccess: () => invalidateLegalEntities(),
  });
};

export const usePatchLegalEntity = () => useMutation((data: PatchLEPayload) => patchLegalEntity(data));

export const useDeleteLegalEntity = () => {
  const { invalidateLegalEntities } = useInvalidateQuery();
  return useMutation({
    mutationFn: (data: { organizationId: string; legalEntityId: string }) => deleteLegalEntity(data),
    onSuccess: () => invalidateLegalEntities(),
  });
};

export const useBulkDeleteLegalEntity = () =>
  useMutation((data: { organizationId: string; legalEntityIds: string[] }) => bulkDeleteLegalEntity(data));

export const useBulkArchiveLegalEntity = () =>
  useMutation((data: { organizationId: string; legalEntityIds: string[] }) => bulkArchiveLegalEntity(data));
