import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Select from 'react-select';
import { styleEdit } from '../../../constants/customStyles';
import { DropDownSelectProps } from './type';
import { Option, MultiValueRemove, MenuList, NoOptionsMessage } from './DropDownSelectComponents';
export function DropDownSelect({
  label,
  menuLabel = '',
  className,
  customStyle,
  value,
  viewMode,
  useInViewCallback,
  dropdownId,
  classNames,
  isLoading,
  options,
  showSelectedIcon = true,
  ...props
}: DropDownSelectProps) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && useInViewCallback) {
      useInViewCallback();
    }
  }, [inView, useInViewCallback]);

  return (
    <div ref={ref} className={className}>
      {!!label && <label className={`block mb-1 font-medium ${viewMode && 'text-[#00000066]'}`}>{label}</label>}
      {viewMode ? (
        <p className='font-medium opacity-80'>
          {Array.isArray(value) ? value[0]?.label : value?.label?.length ? value?.label : 'N/A'}
        </p>
      ) : (
        <Select
          key={JSON.stringify(value) as any}
          value={value}
          isLoading={isLoading}
          options={isLoading ? [] : options}
          styles={{
            ...styleEdit,
            ...customStyle,
          }}
          {...props}
          components={{
            Option: (props) => <Option showSelectedIcon={showSelectedIcon} {...props} />,
            MultiValueRemove,
            MenuList: (props) => <MenuList {...props} menuLabel={menuLabel} />,
            NoOptionsMessage,
          }}
          id={dropdownId}
          classNames={classNames}
        />
      )}
    </div>
  );
}

export default DropDownSelect;
