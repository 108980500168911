export const pastMonth = new Date().setMonth(new Date().getMonth() - 1);
export const pastThreeMonths = new Date().setMonth(new Date().getMonth() - 3);
export const beginningOfYear = new Date().setMonth(0, 1);
export const beginningOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1).getTime();

export const BACKFILL_OPTIONS = [
  { label: 'Month to date', value: pastMonth },
  { label: 'Last 3 months', value: pastThreeMonths },
  { label: 'Year to date', value: beginningOfYear },
  { label: 'Prior year to date', value: beginningOfLastYear },
];
