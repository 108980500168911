import { CryptoIcon } from '../utils';

export const SUPPORTED_CHAIN = [
  { label: 'Ethereum', value: 'eth', icon: <CryptoIcon symbol={'eth'} /> },
  { label: 'Bitcoin', value: 'btc', icon: <CryptoIcon symbol={'btc'} /> },
  { label: 'Solana', value: 'sol', icon: <CryptoIcon symbol={'sol'} /> },
  { label: 'Polygon', value: 'polygon', icon: <CryptoIcon symbol={'polygon'} /> },
  { label: 'Binance Smart Chain', value: 'bsc', icon: <CryptoIcon symbol={'bsc'} /> },
  { label: 'Avalanche', value: 'avalanche', icon: <CryptoIcon symbol={'avax'} /> },
  { label: 'Near', value: 'near', icon: <CryptoIcon symbol={'near'} /> },
  { label: 'Arbitrum', value: 'arb', icon: <CryptoIcon symbol={'arb'} /> },
  { label: 'Optimism', value: 'op', icon: <CryptoIcon symbol={'op'} /> },
  { label: 'Fantom', value: 'ftm', icon: <CryptoIcon symbol={'ftm'} /> },
  { label: 'Base', value: 'base', icon: <CryptoIcon symbol={'base'} /> },
  { label: 'Zora', value: 'zora', icon: <CryptoIcon symbol={'zora'} /> },
  { label: 'Linea', value: 'linea', icon: <CryptoIcon symbol={'linea'} /> },
  { label: 'Ronin', value: 'ronin', icon: <CryptoIcon symbol={'ronin'} /> },
  { label: 'Scroll', value: 'scroll', icon: <CryptoIcon symbol={'scroll'} /> },
  { label: 'ZKSync', value: 'zksync', icon: <CryptoIcon symbol={'zksync'} /> },
  { label: 'Celo', value: 'celo', icon: <CryptoIcon symbol={'celo'} /> },
  { label: 'Stellar Lumens', value: 'xlm', icon: <CryptoIcon symbol={'xlm'} /> },
  { label: 'Xrp', value: 'xrp', icon: <CryptoIcon symbol={'xrp'} /> },
  { label: 'Cardano', value: 'cardano', icon: <CryptoIcon symbol={'ADA'} /> },
  { label: 'Celestia', value: 'celestia', icon: <CryptoIcon symbol={'TIA'} /> },
  { label: 'Polygon zkEVM', value: 'polygon-zkevm', icon: <CryptoIcon symbol={'MATIC'} /> },
  { label: 'Cronos', value: 'cro', icon: <CryptoIcon symbol={'cro'} /> },
];

export const SUPPORTED_CHAIN_STRINGS = SUPPORTED_CHAIN.map((chain) => chain.value);

export const WALLET_TYPE_OPTIONS = [
  {
    label: 'Internal',
    value: 'internal',
  },
  {
    label: 'External',
    value: 'external',
  },
];

export const WALLET_STATUS_OPTIONS = [
  {
    label: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    label: 'ARCHIVED',
    value: 'ARCHIVED',
  },
];

export const SUPPORTED_CHAINS_WITHOUT_ICON = SUPPORTED_CHAIN.map((chain) => ({
  label: chain.label,
  value: chain.value,
}));
