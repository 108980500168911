import { useGetAccountingPeriodStartDates } from 'src/hooks';
import { getDisplayedAccountingPeriodStartDateUTCs, mergeAccountingPeriodStartDates } from '../templates/utils';
import { CheckboxListItem, MultiSelectMenuV2 } from 'ui';
import React from 'react';
export type AccountingPeriodStartDateOption = {
  startDateUTC: Date;
  value: string;
  label: string;
};
const getLabelFromSelectionsFn = (selectedPeriods: AccountingPeriodStartDateOption[]) => {
  if (selectedPeriods.length === 0) return 'Select periods';
  if (selectedPeriods.length === 1) return selectedPeriods[0].label;
  return `${selectedPeriods[0].label} + ${selectedPeriods.length - 1}`;
};

export const AccountingPeriodStartDatesMultiSelectMenu = ({
  selectedPeriods,
  setSelectedPeriods,
  legalEntityIds = [],
}: {
  selectedPeriods: AccountingPeriodStartDateOption[];
  setSelectedPeriods: React.Dispatch<React.SetStateAction<AccountingPeriodStartDateOption[]>>;
  legalEntityIds?: string[];
}) => {
  const { data: startDatesData, isLoading: isLoadingAccountingPeriods } = useGetAccountingPeriodStartDates({
    sort: -1,
    status: 'Open',
    legalEntityIds,
  });
  const mergedStartDateUTCs = mergeAccountingPeriodStartDates(startDatesData);
  const displayedAccountingPeriodStartDates = getDisplayedAccountingPeriodStartDateUTCs(mergedStartDateUTCs) || [];

  const onSelectAll = () => setSelectedPeriods(displayedAccountingPeriodStartDates);
  const onDeselectAll = () => setSelectedPeriods([]);
  const onRowChange = (option, checked: boolean) =>
    setSelectedPeriods((prev) => (checked ? [...prev, option] : prev.filter((o) => o.value !== option.value)));

  return (
    <MultiSelectMenuV2
      onSelectAll={onSelectAll}
      onDeselectAll={onDeselectAll}
      isLoading={isLoadingAccountingPeriods}
      label={getLabelFromSelectionsFn(selectedPeriods)}
      allOptionsSelected={displayedAccountingPeriodStartDates.length === selectedPeriods.length}
      hasSelections={selectedPeriods.length > 0}
    >
      {displayedAccountingPeriodStartDates.map((option, i) => (
        <CheckboxListItem
          key={i}
          enableSelectOnly={false}
          label={option.label}
          checked={!!selectedPeriods.find((o) => o.value === option.value)}
          onCheckedChange={(checked) => onRowChange(option, checked)}
        />
      ))}
    </MultiSelectMenuV2>
  );
};
