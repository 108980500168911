import { currencyImg } from 'ui/src/utils';

export enum EVM_WALLET_CHAIN {
  ETHEREUM = 'eth',
  ARBITRUM = 'arb',
  OPTIMISM = 'op',
  POLYGON = 'polygon',
  BINANCE_SMART_CHAIN = 'bsc',
  AVALANCHE = 'avalanche',
  BASE = 'base',
  FANTOM = 'ftm',
  LINEA = 'linea',
  SCROLL = 'scroll',
  ZKSYNC = 'zksync',
  ZORA = 'zora',
}

export const EVM_CHAINS_OPTIONS = [
  { label: 'Ethereum', value: EVM_WALLET_CHAIN.ETHEREUM, icon: { src: currencyImg('ETH'), alt: 'ETH' } },
  { label: 'Arbitrum', value: EVM_WALLET_CHAIN.ARBITRUM, icon: { src: currencyImg('ARB'), alt: 'ARB' } },
  { label: 'Optimism', value: EVM_WALLET_CHAIN.OPTIMISM, icon: { src: currencyImg('OP'), alt: 'OP' } },
  { label: 'Polygon', value: EVM_WALLET_CHAIN.POLYGON, icon: { src: currencyImg('POLYGON'), alt: 'POLYGON' } },
  {
    label: 'Binance Smart Chain',
    value: EVM_WALLET_CHAIN.BINANCE_SMART_CHAIN,
    icon: { src: currencyImg('BNB'), alt: 'BNB' },
    alt: 'BNB',
  },
  { label: 'Avalanche', value: EVM_WALLET_CHAIN.AVALANCHE, icon: { src: currencyImg('AVAX'), alt: 'AVAX' } },
  { label: 'Base', value: EVM_WALLET_CHAIN.BASE, icon: { src: currencyImg('BASE'), alt: 'BASE' } },
  { label: 'Fantom', value: EVM_WALLET_CHAIN.FANTOM, icon: { src: currencyImg('FTM'), alt: 'FTM' } },
  { label: 'Linea', value: EVM_WALLET_CHAIN.LINEA, icon: { src: currencyImg('LINEA'), alt: 'LINEA' } },
  { label: 'Scroll', value: EVM_WALLET_CHAIN.SCROLL, icon: { src: currencyImg('SCROLL'), alt: 'SCROLL' } },
  { label: 'ZKSync', value: EVM_WALLET_CHAIN.ZKSYNC, icon: { src: currencyImg('ZKSYNC'), alt: 'ZKSYNC' } },
  { label: 'Zora', value: EVM_WALLET_CHAIN.ZORA, icon: { src: currencyImg('ZORA'), alt: 'ZORA' } },
];
