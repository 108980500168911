import { DESCRIPTIVE_LOADER_STATES } from 'ui';

export enum ORGANIZATION_RULESET_JOB_STATUS {
  FORM = 'form',
  CREATING_BG_JOB = 'creating-bg-job',
  COULD_NOT_CREATE_BG_JOB = 'could-not-create-bg-job',
  PROCESSING = 'processing',
  COULD_NOT_PROCESS = 'could-not-process',
  PROCESS_SUCCESSFULL = 'process-complete',
}

export const getRunAllRulesetsProcessStateDescriptorProps = (
  processState: ORGANIZATION_RULESET_JOB_STATUS,
  error?: string,
) => {
  switch (processState) {
    case ORGANIZATION_RULESET_JOB_STATUS.CREATING_BG_JOB:
      return {
        title: 'Creating run ruleset background jobs',
        description: 'Please wait...',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case ORGANIZATION_RULESET_JOB_STATUS.COULD_NOT_CREATE_BG_JOB:
      return {
        title: 'Failure',
        description: error ?? 'Could not create background jobs to run rulesets',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case ORGANIZATION_RULESET_JOB_STATUS.PROCESSING:
      return {
        title: 'Running rulesets',
        description: 'You can close this, the tasks will continue in background',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case ORGANIZATION_RULESET_JOB_STATUS.COULD_NOT_PROCESS:
      return {
        title: 'Failure',
        description: error ?? 'Could not run rulesets',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };
    case ORGANIZATION_RULESET_JOB_STATUS.PROCESS_SUCCESSFULL:
      return {
        title: 'Success',
        description: 'Ruleset runs completed',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };

    default:
      return {
        title: 'unreachable',
        description: 'unreachable',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
  }
};
