import { useRuleConfigurator } from '../rule-configurator-context';
import { useConditionSelectorContext } from './condition-selector-context';

export const useAddConditionGroup = () => {
  const { addConditionGroup } = useRuleConfigurator();
  const { state, closeSelector } = useConditionSelectorContext();

  const add = () => {
    addConditionGroup({ parentId: state.parentId });
    closeSelector();
  };
  return add;
};
