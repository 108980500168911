import React from 'react';
import { MdCancel } from 'react-icons/md';
import { classNames } from '../utils';
import { FileUploadInputProps } from './types';

export const FileUploadInput = (props: FileUploadInputProps) => {
  return (
    <>
      <input
        {...props}
        type='file'
        value={props.file}
        onChange={(e) => {
          props.setFile((e.target as HTMLInputElement).value);
        }}
        className={classNames('disabled:opacity-50', props.className)}
      />
      {props.file && (
        <button onClick={() => props.setFile('')}>
          <MdCancel className='w-5 h-5' />
        </button>
      )}
    </>
  );
};
