import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type StatusFilterRowType = { label: string; value: string };
export type StatusFilterContextType = FilterContextParams<StatusFilterRowType>;

const options = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Posted', value: 'POSTED' },
  { label: 'Reversed', value: 'REVERSED' },
  { label: 'Unposted', value: 'UNPOSTED' },
];

const defaultState = options.map((value) => ({
  ...value,
  selected: false,
}));
export const useStatusFilterState = (page: string) => {
  const { state, setState } = useFilterState<StatusFilterRowType>(FILTER_TYPE.STATUS, page, defaultState);
  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
