import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledItem } from './StyledRadixDropdownElements';
export const LastImpairedOnDateSubMenu = ({ callbackFn }) => {
  return (
    <DropdownMenu.Sub defaultOpen>
      <StyledItem onClick={() => callbackFn()}>
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center py-2'>
            <div>
              <p className='text-[#222222] mb-0'>Last Impaired On Date</p>
            </div>
          </div>
        </div>
      </StyledItem>
    </DropdownMenu.Sub>
  );
};
