import React from 'react';
import { MdLink } from 'react-icons/md';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { UrlInputProps } from './types';

export const UrlInput = (props: UrlInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({ value: props.value, onChange: props.onChange });
  return (
    <InputWithExtras
      {...props}
      {...inputProps}
      leading={<MdLink className='w-5 h-5' />}
      trailing={
        <ClearInputTrailingAction value={inputProps.value} setValue={inputProps.onChange} disabled={props.disabled} />
      }
    />
  );
};
