import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type SpamTokenFilterRow = { value: string; label: string };
export type SpamTokenFilterContextType = FilterContextParams<SpamTokenFilterRow>;

const defaultState = [{ value: 'showSpam', label: 'Show spam', selected: false }];
export const useSpamTokenFilterState = (page: string) => {
  const { state, setState } = useFilterState<SpamTokenFilterRow>(FILTER_TYPE.SPAM_TOKEN, page, defaultState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
