import toast from 'react-hot-toast';
import {
  useInvalidateQuery,
  useMarkTransactionsAsSpam,
  useMarkContractsAsNotSpam,
  useMarkContractsAsSpam,
} from '../../hooks';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import { prepareSpamTokensFromTransactions } from '../PageComponents';
import { TABLE_TYPE, useTableActionBarContext } from 'src/context';

export function SpamTransactionsMultiSelectActionsComponent({ selectedRows, table }) {
  const { invalidateTransactions } = useInvalidateQuery();
  const { getPropsForTableType } = useTableActionBarContext();
  const { toggleAllRowsSelected } = getPropsForTableType(TABLE_TYPE.SPAM_TRANSACTIONS);
  const selectedTxns = selectedRows?.map((item) => item?.original).filter(Boolean);
  const spamContracts = selectedTxns.filter((tx) => tx.rawContractAddress && tx.spamTokenId);
  const txNotMarkedWithSpamToken = selectedTxns.filter((tx) => !tx.spamTokenId && tx.rawContractAddress);
  const transactionsWithContracts = selectedTxns.filter(
    (tx) => tx.rawContractAddress && tx.rawContractAddress.length > 0,
  );

  const { mutateAsync: toggleTransactionsAsSpam, isLoading: isMarkingTransactions } = useMarkTransactionsAsSpam();
  const { mutateAsync: markContractsAsNotSpam, isLoading: isMarkingContracts } = useMarkContractsAsNotSpam();

  const { mutateAsync: markContractsAsSpam, isLoading: isMarkingContractsAsSpam } = useMarkContractsAsSpam();

  const bulkUnspamTransactions = async () => {
    const toastId = toast.loading('Unmarking transactions as spam');
    try {
      await toggleTransactionsAsSpam({
        transactionIds: selectedTxns.map((tx) => tx._id),
      });

      table.resetRowSelection();
      toast.success('Transactions no longer marked as spam', { id: toastId });
      invalidateTransactions();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };

  const bulkUnspamContracts = async () => {
    const toastId = toast.loading('Unmarking contracts as spam');
    try {
      await markContractsAsNotSpam(spamContracts.map((tx) => tx.spamTokenId));

      table.resetRowSelection();
      toast.success('Contracts no longer marked as spam', { id: toastId });
      invalidateTransactions();
    } catch (error) {
      toast.error(deriveError(error), { id: toastId });
    }
  };
  const handleMarkContractAsSpam = async () => {
    try {
      await markContractsAsSpam({
        spamTokens: prepareSpamTokensFromTransactions(transactionsWithContracts),
      });
      toast.success('Contracts marked as spam successfully');
      toggleAllRowsSelected(false);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };
  return (
    <div className='flex ml-auto gap-4'>
      {txNotMarkedWithSpamToken.length > 0 && (
        <Button onClick={handleMarkContractAsSpam} disabled={isMarkingContractsAsSpam} label='Mark contracts as spam' />
      )}
      {selectedTxns.length > 0 && (
        <Button
          label='Unmark as spam'
          emphasis='medium'
          onClick={bulkUnspamTransactions}
          isLoading={isMarkingTransactions}
        />
      )}
      {spamContracts.length > 0 && (
        <Button
          label='Unmark contracts as spam'
          emphasis='medium'
          onClick={bulkUnspamContracts}
          isLoading={isMarkingContracts}
        />
      )}
    </div>
  );
}
