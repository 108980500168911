import React from 'react';
import { MetricCard, MetricGroup } from '../../atoms';
import { useAccountingPeriods, useLegalEntities } from '../../../hooks';
import { ACCOUNTING_PERIOD_STATUS } from 'services/http/response.types';
import { mergeLegalEntities } from '../../templates/utils';

export const OverviewMetrics = ({ data, loading = false }) => {
  const { data: accountingPeriodData, isLoading: isLoadingAccountingPeriods } = useAccountingPeriods();
  const { data: legalEntityData, isLoading: isLoadingLegalEntities } = useLegalEntities();

  const legalEntites = mergeLegalEntities(legalEntityData);

  const accountingPeriods = accountingPeriodData?.pages[0].accountingPeriods || [];
  const totalPeriodsCount = accountingPeriods.length;
  const closedPeriodsCount = accountingPeriods.reduce((acc, curr) => {
    if (curr.status === ACCOUNTING_PERIOD_STATUS.CLOSED) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  const closedPeriodsRate = (closedPeriodsCount / totalPeriodsCount) * 100;
  return (
    <MetricGroup className='lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 !mt-0 !mb-0 !p-0 w-full'>
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={loading}
        label='TRANSACTIONS IMPORTED'
        metric={data?.transactionsCount}
      />
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={loading}
        label='JOURNAL ENTRIES RECORDED'
        metric={data?.journalEntriesCount}
      />
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={isLoadingAccountingPeriods}
        label='ACCOUNTING PERIOD CLOSE RATE'
        metric={`${closedPeriodsRate.toFixed(0)}%`}
      />
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={isLoadingLegalEntities}
        label='LEGAL ENTITES CREATED'
        metric={`${legalEntites.length}`}
      />
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={loading}
        label='LEDGER ACCOUNTS CREATED'
        metric={data?.ledgerAccountsCount}
      />
      <MetricCard
        containerClassName='bg-zinc-100'
        loading={loading}
        label='CONNECTED WALLETS'
        metric={data?.walletsCount}
      />
    </MetricGroup>
  );
};

export default OverviewMetrics;
