import toast from 'react-hot-toast';
import { deriveError } from '../../../templates/utils';

export const handlePatchTransactionExternalSource = async ({
  patchExternalSource,
  transaction,
  userId,
  invalidateTransactions,
  invalidateWallets,
}) => {
  try {
    await patchExternalSource(
      {
        organizationId: transaction?.organizationId,
        transactionIds: [transaction?._id ?? ''],
        userId: userId,
        body: {
          chain: transaction.chain,
        },
      },
      {
        onSuccess: () => {
          invalidateTransactions({ transactionId: transaction?._id });
          invalidateWallets();
        },
      },
    );
    toast.success('External Source added successfully.');
  } catch (error) {
    console.error(error);
    toast.error(deriveError(error));
  }
};

export const getCaretColor = (percentageChange: number) => {
  if (percentageChange > 0) {
    return 'green';
  } else if (percentageChange < 0) {
    return 'red';
  } else {
    return 'gray';
  }
};
