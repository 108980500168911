import { useMemo } from 'react';
import { FILTER_TYPE } from '../../../components/filters';
import { TRANSACTION_SOURCE_OPTIONS } from '../../../components/rules/configurator/constants';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

type SourceFilterRowType = { value: string; label: string };
export type SourceFilterContextType = FilterContextParams<SourceFilterRowType>;
const defaultFilterState = TRANSACTION_SOURCE_OPTIONS.map((option) => ({
  ...option,
  selected: false,
}));
export const useSourceFilterState = (page: string) => {
  const { state, setState } = useFilterState<SourceFilterRowType>(FILTER_TYPE.SOURCE, page, defaultFilterState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
