import { useEffect, useState } from 'react';
import { useLedgerAccount } from './http';
import { useExternalAccountMappings, useExternalAccounts } from './http/useGlIntegrationAccountQueries';
import { GLType } from 'services/http/types';
import { ExternalLedgerAccount, LedgerAccount, LedgerAccountMapping } from 'services/http/response.types';
import { LedgerMapping } from '../components/integrations/common/types';
import { useSession } from './useSession';

export const arrangeLedgers = (
  allLedgers: LedgerAccount[],
  clientMappings: LedgerAccountMapping[],
  externalAccounts: ExternalLedgerAccount[],
): LedgerMapping[] => {
  const mappedLedgers: LedgerMapping[] = allLedgers.map((ledger) => {
    const ledgerMap = clientMappings.find((map) => map.internalLedgerAccountId === ledger._id);
    const externalAccountId = ledgerMap?.externalLedgerAccountId ?? undefined;
    const externalAccount = externalAccounts.find((account) => account._id === externalAccountId);

    return {
      ...ledger,
      external: externalAccount
        ? {
            name: externalAccount.name,
            id: externalAccount._id,
          }
        : undefined,
      children: [],
      organizationId: `${ledger.organizationId}`,
      isDeleted: !!ledger.isDeleted,
      parentLedgerAccountId: ledger.parentLedgerAccountId as string,
    };
  });

  const ledgerMap = new Map<string, LedgerMapping>();
  mappedLedgers.forEach((ledger) => ledgerMap.set(ledger._id, ledger));

  const rootLedgers: LedgerMapping[] = [];

  mappedLedgers.forEach((ledger) => {
    if (ledger.parentLedgerAccountId) {
      const parent = ledgerMap.get(ledger.parentLedgerAccountId);
      if (parent) {
        parent.children.push(ledger);
      }
    } else {
      rootLedgers.push(ledger);
    }
  });

  return rootLedgers;
};

export const useMappedLedgers = (glType: GLType, realmId: string) => {
  const { organizationId } = useSession();
  const { data: ledgerAccountResponse, isLoading: isLoadingLedgerAccount } = useLedgerAccount({
    pageSize: 1000,
  });

  const { data: externalAccountsResponse, isLoading: isExternalAccountLoading } = useExternalAccounts(
    { organizationId, glType, realmId },
    !!organizationId,
  );

  const [mappedLedgers, setMappedLedgers] = useState<LedgerMapping[]>([]);
  const [mappedLedgerTypes, setMappedLedgerTypes] = useState<string[]>([]);
  const { data: mappingResponse } = useExternalAccountMappings(organizationId, glType, realmId);

  useEffect(() => {
    if (ledgerAccountResponse && mappingResponse && externalAccountsResponse) {
      const arrangedLedgers = arrangeLedgers(
        ledgerAccountResponse.pages.flat(),
        mappingResponse.ledgerAccountMappings.filter((map) => map.glType === glType),
        externalAccountsResponse.externalLedgerAccounts,
      );
      setMappedLedgers(arrangedLedgers);
    }
  }, [mappingResponse, ledgerAccountResponse, externalAccountsResponse, realmId]);

  useEffect(() => {
    setMappedLedgerTypes([
      ...mappedLedgers.reduce((set, ledger) => {
        set.add(ledger.ledgerAccountType);
        return set;
      }, new Set()),
    ] as string[]);
  }, [mappedLedgers]);

  return { mappedLedgers, mappedLedgerTypes, isLoading: isLoadingLedgerAccount || isExternalAccountLoading };
};
