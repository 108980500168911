import { useInfiniteQuery } from '@tanstack/react-query';
import { getLastTransactionsDate } from 'services/http/core';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useTransactionsLastDate = (organizationId: string) => {
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.TRANSACTIONS_LAST_DATE),
    async () => {
      const response = await getLastTransactionsDate({
        organizationId,
      });
      return response?.data ?? [];
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};
