import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  bulkArchiveWallet,
  bulkDeleteWallet,
  createWallet,
  deleteWallet,
  getWalletById,
  getWallets,
  getWalletTags,
  patchWallet,
  tagWallets,
  TagWalletsPayload,
} from 'services/http';
import { PatchWalletPayload, PostWalletPayload, WALLET_TYPE } from 'services/http/response.types';
import { useSession } from '../useSession';
import { Wallet } from 'services/http/response.types';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useGetAllFn } from './use-get-all-fn';

export type GetSourcesQueryFilter = Partial<{
  chains: string[];
  legalEntityIds: string[];
  userIds: string[];
  pageSize: number;
  walletTypes: WALLET_TYPE[];
  walletIds: string[];
  tags: string[];
  nameOrAddress: string;
  page: number;
  sort: {
    id: string;
    desc: boolean;
  };
  asc?: string[];
  desc?: string[];
}>;

export const useGetAllSourcesFn = (params?: GetSourcesQueryFilter) => useGetAllFn(getWallets, params, 500);

export const useSources = (params?: GetSourcesQueryFilter) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.WALLETS, params),
    async ({ pageParam = 0 }) => {
      const queryFilter = {
        page: pageParam,
        ...params,
        sort: params?.sort ?? { id: 'createdAt', desc: true },
        pageSize: params?.pageSize ?? 2000,
      };
      const response = await getWallets(queryFilter);
      return response.data;
    },
    {
      enabled: !!organizationId,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 5 * 60,
      getPreviousPageParam: (firstPage) => {
        return firstPage.prevPage ?? undefined;
      },
      getNextPageParam: (lastPage) => {
        return lastPage.wallets.length ? lastPage.nextPage : undefined;
      },
    },
  );
};

export type UseSourceTagsParams = {
  pageSize?: number;
};
export const useSourceTags = (params?: UseSourceTagsParams) => {
  const { pageSize } = params || {};
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.WALLET_TAGS),
    async ({ pageParam = 0 }) => {
      const queryFilter = {
        page: pageParam,
        organizationId,
        pageSize: pageSize || 500,
      };
      const response = await getWalletTags(queryFilter);
      return response.data.tags;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  );
};
export const useGetSourceById = (id) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.WALLETS_BY_ID, { walletId: id }),
    async () => {
      const response = await getWalletById({ walletId: id, organizationId });
      const { wallet } = response.data;
      return wallet as Wallet;
    },
    {
      enabled: !!organizationId,
    },
  );
};

export const useCreateSource = () => useMutation((data: PostWalletPayload) => createWallet(data));

export interface DeleteSourcePayload {
  walletId: string;
  organizationId: string;
}

export const useDeleteSource = () => useMutation((data: DeleteSourcePayload) => deleteWallet(data));

export const usePatchSource = () => useMutation((data: PatchWalletPayload) => patchWallet(data));

export interface BulkDeleteSourcePayload {
  walletIds: string[];
  organizationId: string;
}
export const useBulkDeleteSource = () => useMutation((data: BulkDeleteSourcePayload) => bulkDeleteWallet(data));

export const useBulkArchiveSource = () => useMutation((data: BulkDeleteSourcePayload) => bulkArchiveWallet(data));

export const useTagWallets = () => useMutation((data: TagWalletsPayload) => tagWallets(data));
