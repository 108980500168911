import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import {
  AllFiltersDropdown,
  AccountingPeriodsFilterDropdown,
  AssetTypesFilterDropdown,
  ChainsFilterDropdown,
  ClassificationFilterDropdown,
  DirectionsFilterDropdown,
  ImpairedFilterDropdown,
  LegalEntitiesFilterDropdown,
  OriginatedByFilterDropdown,
  StatusFilterDropdown,
  TagsFilterDropdown,
  WalletsFilterDropdown,
  WalletTypesFilterDropdown,
  AccountingTreatmentFilterDropdown,
  HideSpamTokensFilterDropdown,
  LedgerAccountsFilterDropdown,
  JournalSyncFilterDropdown,
  DateFilter,
  FILTER_TYPE,
  SourceFilterDropdown,
  TypeFilterDropdown,
  AmountFilterDropdown,
} from '../../components/filters';
import { Button, classNames } from 'ui';
import { useWindowSize } from 'usehooks-ts';
import { motion, useSpring } from 'framer-motion';
import useDimensions from 'react-cool-dimensions';
import { SliderContext } from '../../context/SliderProvider';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { JournalEntryTemplatesFilterDropdown } from './filter-dropdowns/journal-entry-templates-filter';
import { TargetDateFilter } from './filter-dropdowns/target-date-filter';

const SCROLL_STEP_SIZE = 200;

const getScrollClamp = (pageWidth: number, filtersWidth: number, sidebarWidth = 0) =>
  pageWidth - sidebarWidth < filtersWidth ? filtersWidth - pageWidth + 100 + sidebarWidth : 0;

const enum SCROLL_DIRECTION {
  LEFT = 'left',
  RIGHT = 'right',
}

const getScrollStep = (
  pageWidth: number,
  filtersWidth: number,
  currentScroll: number,
  direction: SCROLL_DIRECTION,
  sidebarWidth,
) => {
  let step = SCROLL_STEP_SIZE;
  if (direction === SCROLL_DIRECTION.RIGHT) {
    const maxPositiveScroll = 0;
    if (currentScroll === maxPositiveScroll) step = 0;
    else if (currentScroll >= -400) step = 0;
    else step = Math.min(SCROLL_STEP_SIZE, maxPositiveScroll - currentScroll) + currentScroll;
  } else {
    const maxNegativeScroll = getScrollClamp(pageWidth, filtersWidth, sidebarWidth);
    if (currentScroll === -maxNegativeScroll) step = 0;
    else if (-maxNegativeScroll >= currentScroll - 300) step = -maxNegativeScroll;
    else step = currentScroll - Math.min(SCROLL_STEP_SIZE, maxNegativeScroll + currentScroll);
  }
  return step;
};

export const Filters = ({
  visibleFilters = Object.values(FILTER_TYPE),
  'data-cy': dataCy,
  singleDateFilter: singleDateFilter,
}: {
  visibleFilters?: FILTER_TYPE[];
  'data-cy'?: string;
  singleDateFilter?: boolean;
}) => {
  return (
    <Container>
      <FilterList visibleFilters={visibleFilters} data-cy={dataCy} singleDateFilter={singleDateFilter} />
    </Container>
  );
};

const FilterList = ({
  visibleFilters = Object.values(FILTER_TYPE),
  'data-cy': dataCy,
  singleDateFilter: singleDateFilter,
}: {
  visibleFilters?: FILTER_TYPE[];
  'data-cy'?: string;
  singleDateFilter?: boolean;
}) => (
  <>
    <AllFiltersDropdown data-cy={dataCy} visibleFilters={visibleFilters} />
    {visibleFilters.includes(FILTER_TYPE.LEGAL_ENTITY) && <LegalEntitiesFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.LEDGER_ACCOUNT) && <LedgerAccountsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.JOURNAL_ENTRY_TEMPLATE) && (
      <JournalEntryTemplatesFilterDropdown data-cy={dataCy} />
    )}
    {visibleFilters.includes(FILTER_TYPE.ACCOUNTING_PERIOD) && <AccountingPeriodsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.ACCOUNTING_TREATMENT) && (
      <AccountingTreatmentFilterDropdown data-cy={dataCy} />
    )}
    {visibleFilters.includes(FILTER_TYPE.CHAIN) && <ChainsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.CLASSIFICATION) && <ClassificationFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.DIRECTION) && <DirectionsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.ASSET_TYPE) && <AssetTypesFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.WALLET) && <WalletsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.WALLET_TYPE) && <WalletTypesFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.ORIGINATED_BY) && <OriginatedByFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.JOURNAL_SYNC) && <JournalSyncFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.STATUS) && <StatusFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.TAG) && <TagsFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.IMPAIRED) && <ImpairedFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.AMOUNT) && <AmountFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.DATE) && <DateFilter data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.TARGET_DATE) && <TargetDateFilter data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.SINGLE_DATE) && (
      <DateFilter data-cy={dataCy} singleDateFilter={singleDateFilter} />
    )}
    {visibleFilters.includes(FILTER_TYPE.SPAM_TOKEN) && <HideSpamTokensFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.SOURCE) && <SourceFilterDropdown data-cy={dataCy} />}
    {visibleFilters.includes(FILTER_TYPE.TYPE) && <TypeFilterDropdown data-cy={dataCy} />}
  </>
);

const Container = ({ children, 'data-cy': dataCy }: { children: ReactNode; 'data-cy'?: string }) => {
  const [isFixed, setIsFixed] = useState(false);
  const { sidebarWidth } = useContext(SliderContext);

  const filtersRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { observe: observeFilters, width: filtersWidth } = useDimensions();

  const [shouldShowScrollButtons, setShouldShowScrollButtons] = useState(false);
  const [shouldShowRightScrollButton, setShouldShowRightScrollButton] = useState(false);
  const [shouldShowLeftScrollButtons, setShouldShowLeftScrollButton] = useState(false);

  const [xScroll, setXScroll] = useState(0);
  const { width: pageWidth } = useWindowSize();

  const smoothScroll = useSpring(xScroll, { duration: 300 });

  useEffect(() => {
    setShouldShowScrollButtons(!!getScrollClamp(pageWidth, filtersWidth, sidebarWidth));

    window.addEventListener('resize', () => {
      setShouldShowScrollButtons(!!getScrollClamp(pageWidth, filtersWidth, sidebarWidth));
    });

    return () => {
      window.removeEventListener('resize', () => {
        setShouldShowScrollButtons(!!getScrollClamp(pageWidth, filtersWidth, sidebarWidth));
      });
    };
  }, [pageWidth, filtersWidth, sidebarWidth]);

  useEffect(() => {
    setShouldShowLeftScrollButton(shouldShowScrollButtons && xScroll !== 0);
    setShouldShowRightScrollButton(
      shouldShowScrollButtons && xScroll !== -getScrollClamp(pageWidth, filtersWidth, sidebarWidth),
    );
  }, [shouldShowScrollButtons, xScroll, pageWidth, sidebarWidth]);

  useEffect(() => {
    if (!sidebarWidth) smoothScroll.set(!xScroll ? xScroll : xScroll + 35);
    else smoothScroll.set(!xScroll ? xScroll : xScroll + 43);
  }, [xScroll]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (containerRef.current) {
        const componentPosition = containerRef.current.offsetTop;

        if (scrollPosition > componentPosition) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{ width: pageWidth - sidebarWidth }}
        className={classNames(
          shouldShowLeftScrollButtons && 'left',
          shouldShowRightScrollButton && 'right',
          !sidebarWidth && 'mx-6',
          'h-10 relative ',
          isFixed && 'fixed inset-x-0 top-[117px] z-[7]',
        )}
        ref={containerRef}
        data-cy={`${dataCy}_filtersContainer`}
      >
        <motion.div
          className={classNames(
            'flex items-center gap-x-2 absolute z-[8] w-fit',
            isFixed &&
              'sticky inset-x-0 w-full top-[120px] z-normal py-2 bg-white border-b before:w-16 before:border-b before:-translate-x-full before:absolute before:top-0 before:bottom-[-1px] before:left-0 before:z-[0] before:bg-white',
          )}
          ref={(el) => {
            observeFilters(el);
            filtersRef.current = el;
          }}
          style={{
            left: smoothScroll,
          }}
        >
          {children}
        </motion.div>
        {shouldShowLeftScrollButtons && (
          <span
            className={classNames(
              'shadow-xl rounded-full  top-1/2',
              'before:absolute before:-left-6 before:rounded-none before:w-[60px] before:h-14 before:-translate-y-2.5 before:bg-[linear-gradient(to_left,rgba(255,255,255,0.8),rgba(255,255,255,0.2))] before:top-0 before:bottom-0 before:z-[0]',
              isFixed ? '-translate-y-1/4' : '-translate-y-1/2',
            )}
            style={{
              position: 'absolute',

              left: 0,
              zIndex: 11,
            }}
          >
            <Button
              leadingIcon={<MdChevronLeft />}
              emphasis='medium'
              className='rounded-full'
              variant='sm'
              onClick={() =>
                setXScroll((currentScroll) => {
                  const step = getScrollStep(
                    pageWidth,
                    filtersWidth,
                    currentScroll,
                    SCROLL_DIRECTION.RIGHT,
                    sidebarWidth,
                  );
                  return step;
                })
              }
            />
          </span>
        )}
        {shouldShowRightScrollButton && (
          <span
            className={classNames(
              'shadow-xl rounded-full relative top-1/2',
              'before:absolute before:-right-6 before:rounded-none before:w-[60px] before:h-14 before:-translate-y-2.5 before:bg-[linear-gradient(to_left,rgba(255,255,255,1),rgba(255,255,255,0.2))] before:top-0 before:bottom-0 before:z-[0]',
              isFixed ? '-translate-y-1/4' : '-translate-y-1/2',
            )}
            style={{
              position: 'absolute',
              right: 24,
              zIndex: 11,
            }}
          >
            <Button
              leadingIcon={<MdChevronRight />}
              emphasis='medium'
              className='rounded-full'
              variant='sm'
              onClick={() =>
                setXScroll((currentScroll) => {
                  const step = getScrollStep(
                    pageWidth,
                    filtersWidth,
                    currentScroll,
                    SCROLL_DIRECTION.LEFT,
                    sidebarWidth,
                  );
                  return step;
                })
              }
            />
          </span>
        )}
      </div>
    </>
  );
};
