import { DropdownGroupTitle } from '../dropdown';
import { TimezoneRow } from './TimezoneRow';
import { CountryRowProps } from './types';

export const CountryRow = ({ country, timezones, onSelect, style }: CountryRowProps) => {
  return (
    <div style={style} className='mb-4 last:mb-0'>
      <DropdownGroupTitle title={country} className='px-2' />
      <div>
        {timezones.map((timezone, i) => (
          <TimezoneRow timezone={timezone} key={i} onSelect={onSelect} />
        ))}
      </div>
    </div>
  );
};
