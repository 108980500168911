import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { TableCell } from '@/shadcn/ui/table';
import { getCommonPinningStyles } from './utils';
import { SidebarGlobalContext } from 'src/context';
import { cn } from '@/shadcn/lib/utils';
import { Cell, Row } from '@tanstack/react-table';
import { BiSolidDockRight } from 'react-icons/bi';
import { Button } from '@/shadcn/ui/button';
import { TABLE_RESIZE_MIN_WIDTH } from 'src/constants';
import { useDensity } from 'src/context';
import { useRouter } from 'next/router';

const TanStackTableCell = ({
  cell,
  row,
  enableRowSelection,
  showBoxShadow = false,
  onRowClick,
  children,
  disableColumnPinning,
}: {
  cell: Cell<any, any>;
  row: Row<any>;
  enableRowSelection: boolean;
  showBoxShadow?: boolean;
  onRowClick?: (row: Row<any>) => void;
  children: React.ReactNode;
  disableColumnPinning?: boolean;
}) => {
  const { sidebarIds } = useContext(SidebarGlobalContext);
  const selectedRow = sidebarIds.includes(row.original._id);

  const [showViewButton, setShowViewButton] = useState(false);
  const { density } = useDensity();

  const route = useRouter();
  const wideCell = route.pathname === '/ledger/sources';

  useEffect(() => {
    if (enableRowSelection && cell.column.getIndex() === 1) {
      setShowViewButton(true);
    } else if (!enableRowSelection && cell.column.getIndex() === 0) {
      setShowViewButton(true);
    } else {
      setShowViewButton(false);
    }
  }, [cell.column.getIndex()]);

  const allCells = useMemo(() => row.getVisibleCells(), [row]);
  const isCheckboxCol = cell.column.columnDef['accessorKey'] === 'select_checkbox';

  return (
    <TableCell
      className={cn(
        {
          compact: 'h-[36px]',
          default: 'h-[48px]',
          comfortable: 'h-[64px]',
        }[density],
        'relative',
        selectedRow && '!bg-indigo-100',
        ((cell.column.getIndex() === 0 && !isCheckboxCol) ||
          (cell.column.getIndex() === 1 && allCells[0]?.column.columnDef['accessorKey'] === 'select_checkbox')) &&
          'font-medium',
      )}
      key={cell.id}
      style={{
        maxWidth: TABLE_RESIZE_MIN_WIDTH,
        ...getCommonPinningStyles(cell.column, enableRowSelection, false, wideCell, disableColumnPinning).style,
      }}
    >
      <div
        style={{
          ...getCommonPinningStyles(cell.column, enableRowSelection, showBoxShadow, wideCell).divStyle,
        }}
        className={cn(
          'flex items-center',
          // affects horizontal scroll, wide cell is where we have the select col with multiple items
          wideCell ? 'px-2' : 'px-3',
        )}
      >
        {cell.column.getIndex() === 0 && selectedRow && (
          <div className='absolute top-0 left-0 w-1 h-full bg-[#4d4dff] z-10 ' />
        )}
        <div className='flex w-full'>
          {children}
          {showViewButton && onRowClick && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onRowClick && onRowClick(row);
              }}
              className={cn(
                'viewBtn !hidden absolute top-1/2 right-2 -translate-y-1/2',
                {
                  compact: 'h-[28px] w-[90px]',
                  default: 'h-[36px] w-[90px]',
                  comfortable: 'h-[40px] w-[90px]',
                }[density],
              )}
              variant={'outline'}
            >
              <BiSolidDockRight
                className={cn(
                  'mr-2',
                  {
                    compact: 'h-[18px] w-[18px]',
                    default: 'h-[20px] w-[20px]',
                    comfortable: 'h-[20px] w-[20px]',
                  }[density],
                )}
              />
              View
            </Button>
          )}
        </div>
      </div>
    </TableCell>
  );
};

export default memo(TanStackTableCell);
