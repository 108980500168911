import axios from 'axios';
import { SERVER_URL_ORG_SERVICE } from '../../config';

export const updateSelectedOrganization = ({
  selectedOrganizationId,
  token,
}: {
  selectedOrganizationId: string;
  token: string;
}) => {
  return axios.post(
    `${SERVER_URL_ORG_SERVICE}/change-selected-organization?organizationId=${selectedOrganizationId}`,
    {
      selectedOrganizationId,
    },
    { headers: { Authorization: token } },
  );
};
