import { useMemo } from 'react';
import { FILTER_TYPE, StringFilter } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';

export type AccountingTreatmentFilterContextType = FilterContextParams<StringFilter>;
const defaultState = [
  { value: 'true', selected: false },
  { value: 'false', selected: false },
];
export const useAccountingTreatmentFilterState = (page: string) => {
  const { state, setState } = useFilterState<StringFilter>(FILTER_TYPE.ACCOUNTING_TREATMENT, page, defaultState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);
  return {
    state,
    setState,
    hasSelections,
  };
};
