export enum TABLE_TYPE {
  EDIT_ACCOUNT_POSTING_RULESET = 'EDIT_ACCOUNT_POSTING_RULESET',
  ALL_JOURNALS = 'ALL_JOURNALS',
  ALL_LEGAL_ENTITIES = 'ALL_LEGAL_ENTITIES',
  ALL_SOURCES = 'ALL_SOURCES',
  ALL_TAGS = 'ALL_TAGS',
  ALL_TRANSACTIONS = 'ALL_TRANSACTIONS',
  ALL_TEMPLATES = 'ALL_TEMPLATES',
  ALL_JOURNAL_ENTRY_TEMPLATES = 'ALL_JOURNAL_ENTRY_TEMPLATES',
  SPAM_TRANSACTIONS = 'SPAM_TRANSACTIONS',
}

export type AllActionBarProps = {
  [key in TABLE_TYPE]?: any;
};

export type TableActionBarContextType = {
  actionBarVisibleForTableType?: TABLE_TYPE;
  setActionBarVisibleForTableType: (v?: TABLE_TYPE) => void;
  allActionBarProps: AllActionBarProps;
  getPropsForTableType: (t: TABLE_TYPE) => any;
  setPropsForTableType: (t: TABLE_TYPE, p: any) => void;
};
