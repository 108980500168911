import { useMutation } from '@tanstack/react-query';
import { createRulesetsForTransactionClassification } from 'services';
import { useSession } from '../useSession';
import { useInvalidateQuery } from '../useInvalidateQuery';

export const useCreateRulesetForTransactionClassificationMutation = () => {
  const { organizationId, userId } = useSession();
  const { invalidateAccountPostingRulesets } = useInvalidateQuery();
  return useMutation(async () => {
    await createRulesetsForTransactionClassification({ organizationId, userId });
    await invalidateAccountPostingRulesets();
  });
};
