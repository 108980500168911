import { MdDelete } from 'react-icons/md';
import { ActionsProps, ActionsReturnProps, HandleSubmitFnParams, OnToggleItemFunctionParams } from './types';

export const handleSubmitFn = async ({ onCreateNewGroup, groupName, selectedItems, setOpen }: HandleSubmitFnParams) => {
  if (onCreateNewGroup) await onCreateNewGroup(groupName, selectedItems);
  setOpen(false);
};

export const onToggleItem = ({ itemId, selectedItems, setSelectedItems }: OnToggleItemFunctionParams) => {
  if (!selectedItems?.includes(itemId)) {
    setSelectedItems([...selectedItems, itemId]);
    return;
  }
  setSelectedItems(selectedItems.filter((id) => id !== itemId));
};

export const useActions = ({
  setOpen,
  selectedItems,
  groupName,
  onCreateNewGroup,
  isEdit,
  onUpdate,
  onDissolveGroup,
  isLoading,
  isCreateGroup,
  isUpdateGroup,
}: ActionsProps): ActionsReturnProps => {
  let actions: any = [
    {
      label: isCreateGroup ? 'Create group' : 'Create',
      onClick: () => handleSubmitFn({ setOpen, selectedItems, groupName, onCreateNewGroup }),
      disabled: groupName.length === 0 || isLoading,
    },
    {
      label: 'Cancel',
      onClick: () => setOpen(false),
      emphasis: 'medium',
    },
  ];

  if (isEdit) {
    actions = [
      {
        label: isUpdateGroup ? 'Update group' : 'Update',
        disabled: groupName.length === 0,
        onClick: () => onUpdate && onUpdate(groupName, selectedItems),
      },
      {
        label: 'Cancel',
        onClick: () => setOpen(false),
        emphasis: 'medium',
      },
      {
        label: 'Delete',
        status: 'danger',
        emphasis: 'low',
        leadingIcon: <MdDelete />,
        onClick: onDissolveGroup,
        className: 'mr-auto',
      },
    ];
  }
  return {
    actions,
    handleSubmit: () => handleSubmitFn({ setOpen, selectedItems, groupName, onCreateNewGroup }),
  };
};
