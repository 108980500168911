import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../../config';

export const getAccountPostingRules = async (data) =>
  axios.get(
    `${SERVER_URL_CORE}/account-posting-rule?userId=${data.userId}&accountPostingRuleSetId=${data.accountPostingRuleSetId}&page=${data.page}&sort=${data.sort}`,
  );

export const createAccountPostingRule = async (data) =>
  axios.post(`${SERVER_URL_CORE}/account-posting-rule`, {
    ...data,
  });

export const patchAccountPostingRule = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/account-posting-rule?accountPostingRuleId=${data._id}&organizationId=${data.organizationId}`,
    {
      ...data,
    },
  );

export const deleteAccountPostingRule = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/account-posting-rule?accountPostingRuleId=${data._id}&organizationId=${data.organizationId}`,
  );

export const bulkRemoveAccountPostingRules = async (data: { ruleset: string; rules: string[] }) =>
  axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-remove-ap-rules`, data);
export const bulkMoveAccountPostingRules = async (data: { ruleset: string; rules: string[] }) =>
  axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-move-ap-rules`, data);
export const bulkDuplicateAccountPostingRules = async (data: { ruleset: string; rules: string[] }) =>
  axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/bulk-duplicate-ap-rules`, data);
