import { produce } from 'immer';
import short from 'short-uuid';
import { GATE_TYPE, RuleConfiguratorState } from '../../types';

const translator = short();
export const addConditionGroup = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    // console.log('ADD_CONDITION_GROUP payload', action.payload);
    const id = translator.new().toString();
    // add to conditionGroups
    draft.conditionGroups[id] = {
      id,
      childIds: [],
      gates: [],
      ...action.payload.conditionGroup,
    };
    // add to conditionGroupIds
    draft.conditionGroupIds.splice(action.payload.at ?? draft.conditionGroupIds.length, 0, id);

    // add to parent
    if (action.payload.conditionGroup.parentId) {
      draft.conditionGroups[action.payload.conditionGroup.parentId].childIds.splice(
        action.payload.at ?? draft.conditionGroups[action.payload.conditionGroup.parentId].childIds.length,
        0,
        id,
      );

      // add gate to parent
      draft.conditionGroups[action.payload.conditionGroup.parentId].gates.push(GATE_TYPE.AND);
    }
  });
