import React, { useState } from 'react';
import { MdFolderOpen } from 'react-icons/md';
import { Button } from 'ui';
import { SourceTableGroupDialog } from './source-table-group-dialog';
import { CreateSourceTableGroupProps } from './types';

export const CreateSourceTableGroup = ({ data, onCreateNewGroup, isLoading }: CreateSourceTableGroupProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        label='New group'
        leadingIcon={<MdFolderOpen className='text-zinc-800' size={20} />}
        onClick={() => setOpen(true)}
        emphasis='medium'
      />
      <SourceTableGroupDialog
        onCreateNewGroup={onCreateNewGroup}
        open={open}
        setOpen={setOpen}
        data={data}
        isLoading={isLoading}
      />
    </div>
  );
};
