import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { CSRowProps, ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';

const ROWS = ['True', 'False'];

const filterRowsFn: FilterFn<string> = (searchTerm) => (value) =>
  value.toLowerCase().includes(searchTerm.toLowerCase());
const convertToRowData: ConvertToRowFn<string> = (value) => ({
  rowData: {
    label: value,
    value,
  },
  isSelected: false,
});

export const useIsImpairedConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Is Impaired',
      data: ROWS,
      filterFn: filterRowsFn,
      Row: IsImpairedConditionSelectorRow,
    }),
    [],
  );

const IsImpairedConditionSelectorRow = ({ conditionData }: CSRowProps) => {
  const addCondition = useAddCondition();

  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.IS_IMPAIRED,
          operator: 'equal',
          value: conditionData.rowData.value === ROWS[0] ? 1 : 0,
        })
      }
    />
  );
};

export const IsImpairedConditionsMenu = () => {
  const conditionsData = useFilteredConditions(ROWS, filterRowsFn, convertToRowData);
  return (
    <ConditionsGroup label='Is Impaired'>
      {conditionsData.length === 0 && <div className='text-center'>No impairment conditions to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <IsImpairedConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
