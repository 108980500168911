import { CheckboxListItem, DropdownGroup, DropdownGroupBody, DropdownGroupTitle, capitalizeFirstLetter } from 'ui';
import { useAccountingPeriodFilterContext } from '../../../../context';

export const AccountingPeriodsFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useAccountingPeriodFilterContext();
  return (
    <DropdownGroup>
      <DropdownGroupTitle title='Accounting periods' />
      <DropdownGroupBody>
        {state.length > 1 ? (
          state.map((accountingPeriod, i) => (
            <CheckboxListItem
              key={i}
              label={capitalizeFirstLetter(accountingPeriod.accountingPeriodName?.toLowerCase() ?? '')}
              checked={accountingPeriod.selected}
              onCheckedChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              selectOnlyFn={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No accounting periods to show.</p>
        )}
      </DropdownGroupBody>
    </DropdownGroup>
  );
};
