import React, { useContext } from 'react';
import { SliderContext } from '../../../context/SliderProvider';

interface SidebarWidthWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const SidebarWidthWrapper = ({ children, className }: SidebarWidthWrapperProps) => {
  const { sidebarWidth } = useContext(SliderContext);

  return (
    <div
      className={`${sidebarWidth ? '[&>*]:!px-0 [&>*]:!mx-0 pl-6 pr-8' : ''} ${className}`}
      style={{ width: sidebarWidth ? `calc(100vw - ${sidebarWidth}px)` : '100%' }}
    >
      {children}
    </div>
  );
};

export default SidebarWidthWrapper;
