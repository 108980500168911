import React from 'react';
import { XIcon } from '../../assets/generated/svg';

function EntityItem({ name, type, index, onDelete }) {
  return (
    <div className='flex justify-between items-center py-4 px-5 border rounded-lg mb-4'>
      <div>
        <p className='text--square primary'>
          {name},{type}.
        </p>
        <p className='text--square secondary !text-[#11111166]' />
      </div>

      <XIcon onClick={() => onDelete(index)} />
    </div>
  );
}

export default EntityItem;
