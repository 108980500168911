import React, { Children } from 'react';
import { TableLoader } from 'src/components/tables/table-components';

type SidebarSectionProps = {
  children: React.ReactNode;
  numberOfColumns?: number;
  loading?: boolean;
};

const LoadingComponent = ({
  numberOfColumns,
  numberOfComponents,
}: {
  numberOfColumns: number;
  numberOfComponents: number;
}) => {
  return (
    <div className={`grid grid-cols-${numberOfColumns} gap-y-5 gap-x-2 animate-pulse px-6`}>
      {Array(numberOfComponents)
        .fill(1)
        .map((_, index) => {
          return (
            <div key={index}>
              <div className='h-4 w-[50%] bg-gray-200 mb-2 bg-gradient-to-r from-gray-300 to-gray-100' />
              <div className='h-4 w-[80%] bg-gray-200 mb-5 bg-gradient-to-r from-gray-300 to-gray-100' />
            </div>
          );
        })}
    </div>
  );
};

export const SidebarSection: React.FC<SidebarSectionProps> = ({ children, numberOfColumns = 2, loading }) => {
  if (loading)
    return <LoadingComponent numberOfColumns={numberOfColumns} numberOfComponents={Children.count(children)} />;
  return (
    <div
      className={`grid grid-cols-${numberOfColumns} gap-y-5 gap-x-2 border-b pb-5 px-6 last:border-b-0 overflow-x-clip`}
    >
      {children}
    </div>
  );
};

export const SidebarTableSection: React.FC<SidebarSectionProps> = ({ children, loading }) => {
  if (loading) return <TableLoader />;
  return (
    <div className='flex flex-wrap gap-y-5 gap-x-2 border-b pb-5 px-6 last:border-b-0 overflow-x-clip w-full'>
      {children}
    </div>
  );
};
