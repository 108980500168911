import { useRuleConfigurator } from '../../rule-configurator-context';
import { GREATER_LESS_OPERATORS } from '../../data';
import { LastImpairedOnDateInlineConditionMenu } from '../../../LastImpairedOnDateInlineConditionMenu';
import { ConditionRow } from './ConditionRow';
import { FACT } from '../../types';
import { useCondition } from '../useCondition';
import { SingleSelectMenu } from 'ui';

const Row = () => {
  const { updateCondition, isDisabled } = useRuleConfigurator();
  const condition = useCondition();
  return (
    <>
      <SingleSelectMenu
        value={{ label: 'Last Impaired On Date', value: 'Last Impaired On Date' }}
        disabled={true}
        options={[]}
      />
      <SingleSelectMenu
        options={GREATER_LESS_OPERATORS}
        value={GREATER_LESS_OPERATORS.find((item) => item.value === condition.operator)}
        onChange={({ value }) => {
          updateCondition(condition.id, {
            operator: value,
          });
        }}
        isOnSidepanel
        align='end'
        disabled={isDisabled}
      />
      <LastImpairedOnDateInlineConditionMenu
        rule={condition}
        callbackFn={(value) => {
          updateCondition(condition.id, {
            value: value,
          });
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};

export const LastImpairedOnRuleCondition = () => (
  <ConditionRow facts={[FACT.LAST_IMPAIRED_ON]}>
    <Row />
  </ConditionRow>
);
