import React, { useMemo, useState } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from '@stitches/react';

const VIEWPORT_PADDING = 25;

const StyledViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 390,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  outline: 'none',
});

export const SliderContext = React.createContext<{
  sliderActive: boolean;
  sideNotifications: boolean;
  setSideNotifications: (check: boolean) => void;
  setSliderActive: (check: boolean) => void;
  sidebarWidth: number;
  setSidebarWidth: (width: number) => void;
}>({
  sliderActive: false,
  sideNotifications: false,
  setSideNotifications: (data) => data,
  setSliderActive: (data) => data,
  sidebarWidth: 0,
  setSidebarWidth: (width) => width,
});

function SliderProvider({ children, initialData }) {
  const [sidebarWidth, setSidebarWidth] = useState(initialData?.sidebarWidth ?? 0);
  const sidebarWidthMemo = useMemo(() => ({ sidebarWidth, setSidebarWidth }), [sidebarWidth]);
  const [sliderActive, setSliderActive] = useState(initialData?.sliderActive ?? false);
  const sliderMemo = useMemo(() => ({ sliderActive, setSliderActive }), [sliderActive]);
  const [sideNotifications, setSideNotifications] = useState(initialData?.sideNotifications ?? false);
  const sideNotificationsMemo = useMemo(() => ({ sideNotifications, setSideNotifications }), [sideNotifications]);

  return (
    <ToastPrimitive.Provider swipeDirection='right'>
      <SliderContext.Provider
        value={{
          ...sliderMemo,
          ...sideNotificationsMemo,
          ...sidebarWidthMemo,
        }}
      >
        {children}
      </SliderContext.Provider>
      <StyledViewport />
    </ToastPrimitive.Provider>
  );
}

export default React.memo(SliderProvider);
