import React from 'react';
import { handleDownloadAssets } from './utils';
import { Button } from 'ui';
import { useSession } from '../../../hooks';
import { useAssetQueryParams } from './use-asset-query-params';
import { downloadCsv, withCsvToasts } from '../../../lib/utils/download-csv';
import { useMutation } from '@tanstack/react-query';

export const AssetsCSV = ({ searchTerm, sortState }) => {
  const { organizationId } = useSession();
  const assetsCSVParams = useAssetQueryParams({ searchTerm, sortState });

  const { isLoading, mutateAsync } = useMutation({
    mutationFn: async () => {
      const data = await handleDownloadAssets({
        sortState,
        organizationId,
        assetsCSVParams,
      });
      downloadCsv(data, `assets-export-${new Date().toLocaleDateString()}`);
    },
  });

  return (
    <Button
      emphasis='medium'
      onClick={withCsvToasts(mutateAsync)}
      isLoading={isLoading}
      disabled={isLoading}
      label='Download'
    />
  );
};

export default AssetsCSV;
