import React, { useState } from 'react';
import { Button, classNames } from 'ui';
import { ConditionComponent } from './ConditionComponent';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MdAdd, MdDragIndicator } from 'react-icons/md';
import { CONDITION_GROUP_CHILD_TYPE, GATE_TYPE } from '../types';
import { ConditionSelector } from '../condition-selector/ConditionSelector';
import { useRuleConfigurator } from '../rule-configurator-context/RuleConfiguratorContext';
import { dropAnimationConfig } from '../../../atoms/DND-KIT/components/SortableList/components/SortableOverlay/SortableOverlay';

interface SortableConditionGroupChildProps {
  childId: string;
  isOverlay?: boolean;
  showDragHandle?: boolean;
}

const SortableConditionGroupChild = ({ childId, isOverlay, showDragHandle }: SortableConditionGroupChildProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: childId,
    transition: null,
  });
  const { isDisabled, getConditionGroupChildType } = useRuleConfigurator();

  const style = {
    opacity: isDragging ? 0.4 : 1,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <>
      <div ref={setNodeRef} style={style} className={classNames(isOverlay && 'opacity-90 bg-white')}>
        <div className='w-full flex items-center'>
          {!isDisabled && showDragHandle && (
            <div className='w-12'>
              <button className='w-full h-full flex items-center justify-center' {...listeners} {...attributes}>
                <MdDragIndicator className='text-zinc-400' />
              </button>
            </div>
          )}
          <div className='grow'>
            {getConditionGroupChildType(childId) === CONDITION_GROUP_CHILD_TYPE.CONDITION_GROUP ? (
              <ConditionGroupComponent conditionGroupId={childId} />
            ) : (
              <ConditionComponent conditionId={childId} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const GateComponent = ({ childId }) => {
  const { isDisabled, updateGate, getGate } = useRuleConfigurator();
  const gate = getGate(childId);
  if (!gate.shouldShow) return null;
  return (
    <div
      className={classNames(
        'grid grid-cols-2 gap-x-1 mb-2 rounded-lg grow bg-white border p-1',
        !isDisabled && 'ml-12',
      )}
    >
      <Button
        label={'And'}
        className='capitalize'
        emphasis={gate.value === GATE_TYPE.AND ? 'high' : 'low'}
        onClick={() => updateGate(gate.parentId, gate.index, GATE_TYPE.AND)}
        disableRingOnFocus
        disabled={isDisabled}
      />
      <Button
        label={'Or'}
        className='capitalize'
        emphasis={gate.value === GATE_TYPE.OR ? 'high' : 'low'}
        onClick={() => updateGate(gate.parentId, gate.index, GATE_TYPE.OR)}
        disableRingOnFocus
        disabled={isDisabled}
      />
    </div>
  );
};
export const ConditionGroupComponent = ({ conditionGroupId }) => {
  const { isDisabled, getConditionGroupById, removeConditionGroup, duplicate, updateConditionGroup } =
    useRuleConfigurator();
  const conditionGroup = getConditionGroupById(conditionGroupId);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id && conditionGroup?.childIds) {
      const oldIndex = conditionGroup.childIds.findIndex((id) => id === active.id);
      const newIndex = conditionGroup.childIds.findIndex((id) => id === over.id);

      updateConditionGroup(conditionGroupId, { childIds: arrayMove(conditionGroup.childIds, oldIndex, newIndex) });
    }
    setActiveId(null);
  }

  if (!conditionGroup) return null;
  return (
    <div className={classNames('rounded-lg', conditionGroup.parentId ? 'p-2 bg-[#F8F8F8] border' : 'bg-white')}>
      <div className='grid grid-cols-1 gap-2'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          modifiers={[restrictToVerticalAxis]}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
            draggable: {
              measure: (node) => {
                return node.getBoundingClientRect();
              },
            },
          }}
        >
          <SortableContext items={conditionGroup.childIds} strategy={verticalListSortingStrategy}>
            {conditionGroup.childIds.map((childId) => (
              <React.Fragment key={childId}>
                <SortableConditionGroupChild childId={childId} showDragHandle={conditionGroup.childIds.length > 1} />
                {conditionGroup.childIds.length > 0 && <GateComponent childId={childId} />}
              </React.Fragment>
            ))}
            <DragOverlay dropAnimation={dropAnimationConfig}>
              {activeId ? (
                <SortableConditionGroupChild
                  isOverlay
                  childId={conditionGroup.childIds.find((id) => id === activeId) as string}
                />
              ) : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
        {/* Show condition selector if the group component is not disabled and its a nested condition group */}
        {!isDisabled && !conditionGroup.parentId && (
          <div>
            <ConditionSelector
              parentId={conditionGroupId}
              addNewAsNested
              trigger={
                <Button label='Add new condition' leadingIcon={<MdAdd className='w-5 h-5' />} emphasis='medium' />
              }
            />
          </div>
        )}

        {/* Show condition selector if the group component is not disabled */}
        {!isDisabled && conditionGroup.parentId && (
          <div className='flex items-center justify-between'>
            <ConditionSelector
              parentId={conditionGroup.id}
              trigger={<Button label='Add' leadingIcon={<MdAdd className='w-5 h-5' />} emphasis='medium' />}
              showNestConditionRow
            />
            <div>
              <Button
                label='Duplicate'
                emphasis='low'
                onClick={() => duplicate(conditionGroupId, conditionGroup.parentId!)}
              />
              <Button
                label='Remove'
                emphasis='low'
                status='danger'
                onClick={() => removeConditionGroup(conditionGroupId)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
