import { useEffect, useState } from 'react';
import { JOB_STATUS } from 'services';

export const useJobProgressDescriptor = (jobName, jobConfig) => {
  const [description, setDescription] = useState<string>(jobName);

  useEffect(() => {
    let d: string = jobName;
    if (!jobConfig) d = `${jobName} queued`;
    else if (jobConfig.jobStatus === JOB_STATUS.IN_PROGRESS)
      d = `${jobConfig?.completedCount}/${jobConfig?.toProcessCount} ${jobName}`;
    setDescription(d);
  }, [jobConfig, jobName]);
  return description;
};
