import React, { useState } from 'react';
import { ChainsCondition } from './ChainsCondition';
import { DialogPrimitive } from 'ui';
import { MdArrowDropDown } from 'react-icons/md';

export const ChainInlineConditionMenu = ({ rule, callbackFn, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <DialogPrimitive.Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogPrimitive.DialogTrigger
        disabled={isDisabled}
        className='border border-[#c4c4c4] disabled:opacity-50 bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
      >
        <div className='mr-2 flex h-full justify-center items-center content-center'>{rule.value?.toUpperCase()}</div>
        <MdArrowDropDown className='w-6 h-6 text-black' />
      </DialogPrimitive.DialogTrigger>
      <DialogPrimitive.DialogContent className='max-h-[70vh] overflow-auto'>
        <ChainsCondition isInLineCondition callbackFn={callbackFn} closeDialog={closeDialog} />
      </DialogPrimitive.DialogContent>
    </DialogPrimitive.Dialog>
  );
};
