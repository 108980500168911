import { DESCRIPTIVE_LOADER_STATES } from 'ui';

export enum CREATE_IMPAIRMENT_RULE_PROCESS_STATE {
  FORM = 'form',
  CREATING_IMPAIRMENT_RULE = 'adding-impairment-rule',
  COULD_NOT_CREATE_IMPAIRMENT_RULE = 'add-impairment-rule-error',
  IMPAIRMENT_RULE_CREATED = 'impairment-rule-created',
}

export const getCreateImpairmentRuleProcessStateDescriptorProps = (
  processState: CREATE_IMPAIRMENT_RULE_PROCESS_STATE,
  error?: string,
) => {
  switch (processState) {
    case CREATE_IMPAIRMENT_RULE_PROCESS_STATE.CREATING_IMPAIRMENT_RULE:
      return {
        title: 'Creating source',
        description: 'Please wait...',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
    case CREATE_IMPAIRMENT_RULE_PROCESS_STATE.COULD_NOT_CREATE_IMPAIRMENT_RULE:
      return {
        title: 'Failure',
        description: error ?? 'Could not create source',
        status: DESCRIPTIVE_LOADER_STATES.ERROR,
      };

    case CREATE_IMPAIRMENT_RULE_PROCESS_STATE.IMPAIRMENT_RULE_CREATED:
      return {
        title: 'Success',
        description: 'Impairment rule created successfully',
        status: DESCRIPTIVE_LOADER_STATES.SUCCESS,
      };

    default:
      return {
        title: 'unreachable',
        description: 'unreachable',
        status: DESCRIPTIVE_LOADER_STATES.LOADING,
      };
  }
};
