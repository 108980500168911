import { EditEntity } from '../../../components/LegalEntities';
import { SidebarProps } from '../SidebarGlobal';

const EntitySidebar: React.FC<SidebarProps> = ({
  id,
  onBack,
  isDockPanelAvailable,
  handlePrimaryPanelClose,
  isPrimary,
  defaultState,
}) => {
  return (
    <EditEntity
      onBack={onBack}
      isDockPanelAvailable={isDockPanelAvailable}
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      legalEntityId={id}
      isPrimary={isPrimary}
      defaultState={defaultState}
    />
  );
};

export default EntitySidebar;
