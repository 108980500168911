import * as RadixDialog from '@radix-ui/react-dialog';
import { MdClose } from 'react-icons/md';
import { Button, ButtonProps } from '../button';
import { ModalContentProps } from './types';
import { BackButton } from './back-button';
import { ModalActions } from './modal-actions';
import { classNames } from '../utils';
import React, { MouseEventHandler, ReactNode } from 'react';

export const ModalContentBoxWithOverflow = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={classNames('px-4 pb-4 min-w-max flex-grow overflow-auto grid grid-cols-1 gap-4', className)}>
    <div className='h-0' />
    {children}
  </div>
);

export const ModalContentWrapper = ({ className, children }: { className?: string; children: ReactNode }) => {
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className='transition-all animate-fadeOverlay fixed inset-0 h-screen w-screen bg-black opacity-50 z-[32]' />
      <RadixDialog.Content
        className={classNames(
          // 'max-h-[750px] h-fit',
          'max-h-[750px] min-h-max',
          'transition-all animate-fade',
          'fixed top-1/2 left-1/2',
          'transform -translate-x-1/2 -translate-y-1/2',
          'z-modal',
          'rounded-xl',
          'bg-white',
          // variant === 'large' ? 'lg:max-w-[1200px]' : 'max-w-[560px]',
          className,
        )}
      >
        {children}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  );
};

export const ModalHeader = ({
  onBack,
  title,
}: {
  onBack?: MouseEventHandler<HTMLButtonElement>;
  title?: ReactNode;
}) => (
  <div className='flex items-center gap-x-2'>
    {onBack && (
      <div className='w-10 relative'>
        <BackButton tooltipMessage='go back' onClick={onBack} />
      </div>
    )}
    {title && <RadixDialog.Title className='font-semibold'>{title}</RadixDialog.Title>}
  </div>
);

export const ModalTopBar = ({
  onBack,
  title,
  description,
  onClose,
  hasBorder,
  withoutPadding,
}: {
  onBack?: MouseEventHandler<HTMLButtonElement>;
  title?: ReactNode;
  description?: ReactNode;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  hasBorder?: boolean;
  withoutPadding?: boolean;
}) => (
  <div
    className={classNames('w-full flex items-start justify-between', !withoutPadding && 'p-4', hasBorder && 'border-b')}
  >
    <div className='flex-grow flex items-center min-h-10'>
      <div className='flex items-center gap-x-2'>
        {onBack && (
          <div className='w-10 relative'>
            <BackButton tooltipMessage='go back' onClick={onBack} />
          </div>
        )}
        {title && <RadixDialog.Title className='font-semibold'>{title}</RadixDialog.Title>}
      </div>
      {description && (
        <RadixDialog.Description className='mt-3 mb-6 text-[#111111]'>{description}</RadixDialog.Description>
      )}
    </div>
    {onClose && (
      <div className='w-10 h-10 relative'>
        <Button disableRingOnFocus leadingIcon={<MdClose className='w-5 h-5' />} emphasis='low' onClick={onClose} />
      </div>
    )}
  </div>
);

export const ModalBottomBar = ({
  actions,
  className,
  rightActions,
}: {
  actions?: (ReactNode | ButtonProps)[];
  className?: string;
  rightActions?: (ReactNode | ButtonProps)[];
}) => (
  <div className={classNames('mt-auto py-4 px-6 border-t-2 flex-shrink-0', className)}>
    <div className='flex w-full'>
      {rightActions && (
        <div className='flex-grow flex flex-row gap-x-2'>
          {rightActions.map((action, i) =>
            React.isValidElement(action) ? action : <Button key={i} {...(action as ButtonProps)} />,
          )}
        </div>
      )}
      {actions && (
        <div className='flex-grow flex flex-row-reverse gap-x-2'>
          {actions.map((action, i) =>
            React.isValidElement(action) ? action : <Button key={i} {...(action as ButtonProps)} />,
          )}
        </div>
      )}
    </div>
  </div>
);

export const ModalContent = function Content({
  children,
  actions,
  actionsClassName,
  title,
  description,
  onClose,
  backAction,
}: ModalContentProps) {
  return (
    <ModalContentWrapper>
      <ModalTopBar
        onBack={backAction?.onClick}
        title={title}
        description={description}
        onClose={onClose}
        withoutPadding
      />
      {children}
      {actions && <ModalBottomBar actions={actions} className={actionsClassName} />}
    </ModalContentWrapper>
  );
};

export const ModalTrigger = RadixDialog.Trigger;
export const Modal = RadixDialog.Root;

export const DialogActions = ModalActions;
export const DialogContent = ModalContent;
export const DialogContentWrapper = ModalContentWrapper;
export const DialogHeader = ModalHeader;
export const DialogTopBar = ModalTopBar;
export const DialogBottomBar = ModalBottomBar;
export const DialogTrigger = ModalTrigger;
export const Dialog = Modal;
export const DialogContentBoxWithOverflow = ModalContentBoxWithOverflow;
