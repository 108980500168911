import React from 'react';
import { RxCheck as CheckIcon } from 'react-icons/rx';
import { Flex, Label, StyledCheckbox, StyledIndicator } from '../../styles/CheckboxStyle';
import { CheckboxDataProps } from './types';

function Checkbox({ label = 'label', labelLeft = false, checked = false }: CheckboxDataProps) {
  return (
    <div className='flex flex-col !min-h-[36px] items-center justify-center gap-4'>
      <Flex className='gap-4'>
        {labelLeft && <Label>{label}</Label>}
        <StyledCheckbox checked={checked}>
          <StyledIndicator>
            <CheckIcon />
          </StyledIndicator>
        </StyledCheckbox>
        {!labelLeft && <Label>{label}</Label>}
      </Flex>
    </div>
  );
}

export default Checkbox;
