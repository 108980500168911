import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const updateCondition = (state: RuleConfiguratorState, action) => {
  const newState = produce(state, (draft) => {
    draft.conditions[action.payload.id] = {
      ...draft.conditions[action.payload.id],
      ...action.payload.update,
    };
  });
  console.log('new state on updateCondition', newState, action);
  return newState;
};
