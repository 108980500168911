import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createImpairmentRule,
  deleteImpairmentRule,
  duplicateImpairmentRule,
  editImpairmentRule,
  getImpairmentRules,
} from 'services/http/core';
import { ColumnSort } from '@tanstack/react-table';
import { useSession } from '../useSession';
import { PatchIRPayload, PostIRPayload } from 'services/http/response.types';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export interface UseImpairmentRulesPayload {
  impairmentRuleIds?: string[];
  cancelQuery?: boolean;
  pageSize?: number;
  sort?: ColumnSort;
  searchTerm?: string;
}

export const useImpairmentRules = (payload: UseImpairmentRulesPayload) => {
  const { impairmentRuleIds, pageSize, sort = { id: 'createdAt', desc: true }, searchTerm = '' } = payload || {};

  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.IMPAIRMENT_RULES, { sort }),
    async ({ pageParam = 0 }) => {
      const response = await getImpairmentRules({
        page: pageParam,
        sort,
        pageSize: pageSize || 25,
        organizationId,
        impairmentRuleIds,
        organizationIds: [organizationId],
        searchTerm,
      });
      return response.data;
    },
    {
      enabled: !!organizationId,
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  );
};

export const useGetImpairmentRuleById = (id?: string) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.IMPAIRMENT_RULES, { impairmentRuleId: id }),
    async () => {
      const response = await getImpairmentRules({
        impairmentRuleIds: [id],
        pageSize: 1,
        organizationId,
      });
      return response.data?.impairmentRules[0];
    },
    {
      enabled: !!id && !!organizationId,
    },
  );
};

export const useCreateImpairmentRule = () => useMutation((data: PostIRPayload) => createImpairmentRule(data));
export const useEditImpairmentRule = () => useMutation((data: PatchIRPayload) => editImpairmentRule(data));
export const useDuplicateImpairmentRule = () => useMutation((_id: string) => duplicateImpairmentRule({ _id }));
interface DeleteImpairmentRulePayload {
  impairmentRuleIds: string[];
  organizationId: string;
}

export const useDeleteImpairmentRule = () =>
  useMutation((data: DeleteImpairmentRulePayload) => deleteImpairmentRule(data));
