// We get invoice in cellData

import { Button } from 'ui';
import { StripeProvider } from '../../../context';
// import { useStripe, useElements } from '@stripe/react-stripe-js';
// import { useGetStripeDetailsForOrgQuery } from '../../../hooks';
// import toast from 'react-hot-toast';
// import { getHost } from '../../../lib/utils';
// import { RETURN_TO } from '../../../constants/return-to';
import { PayInvoiceModal } from '../../billing/modals/pay-invoice-modal';

// This part will be required if we ever decide to use stripe client SDK for payments (we are going for serverside integration)
// const PayInvoiceButton = ({ clientSecret, paymentIntentId }) => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const onConfirm = async () => {
//     if (!stripe || !elements) return;

//     const { error } = await stripe.confirmPayment({
//       // `clientSecret` from the created PaymentIntent
//       clientSecret,
//       confirmParams: {
//         return_url: getHost() + `/payment-status?returnTo=${RETURN_TO.billing.key}&session_id={CHECKOUT_SESSION_ID}`,
//         payment_method: paymentIntentId,
//       },
//     });

//     if (error) return toast.error(error.message as string);
//     toast.success('Invoice paid');
//   };

//   return <Button label='Pay invoice' variant='xs' onClick={onConfirm} emphasis='medium' />;
// };

export const InvoiceLinks = ({ invoice, classNames = '' }) => {
  if (!invoice) return null;
  if (invoice.status === 'paid')
    return (
      <Button
        className={classNames}
        onClick={() => window.open(invoice.hosted_invoice_url, '_blank')}
        label='View invoice'
        variant='xs'
        emphasis='medium'
      />
    );
  if (invoice.status === 'open')
    return (
      <StripeProvider>
        <PayInvoiceModal invoice={invoice} />
      </StripeProvider>
    );
  return null;
};
