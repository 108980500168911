import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  BulkDeleteJournalEntryTemplatesParams,
  CreateJournalEntriesWithoutRuleParams,
  bulkDeleteJournalEntryTemplates,
  createJournalEntriesWithoutRule,
  createJournalEntryLineTemplate,
  createJournalEntryTemplate,
  deleteJournalEntryLineTemplate,
  deleteJournalEntryTemplate,
  duplicateJournalEntryTemplate,
  getJournalEntryTemplateById,
  getJournalEntryTemplates,
  updateJournalEntryLineTemplate,
  updateJournalEntryTemplate,
} from 'services';
import { JournalEntryTemplate } from 'schemas';
import { useSession } from '../useSession';
import { QUERY_KEY, useInvalidateQuery, useOrgBasedQueryKey } from '../useInvalidateQuery';
import { useGetAllFn } from './use-get-all-fn';

type UseJournalEntryTemplatesPayload = {
  pageSize?: number;
  sort?: { id: string; desc: boolean };
  searchTerm?: string;
};

/**
 * @blocker
 * This is broken do not use it
 *
 * @todo akshay to fix this and write tests for it.
 */
export const useAllJournalEntryTemplates = (queryParams?: UseJournalEntryTemplatesPayload) => {
  const getKey = useOrgBasedQueryKey();
  const { organizationId } = useSession();
  const queryFn = useGetAllFn<JournalEntryTemplate>(
    getJournalEntryTemplates,
    queryParams ?? {
      organizationIds: [organizationId],
    },
    queryParams?.pageSize ?? 500,
  );
  return useQuery(getKey(QUERY_KEY.GET_ALL_JOURNAL_ENTRY_TEMPLATES, queryParams), queryFn, {
    enabled: !!organizationId,
  });
};

export const useJournalEntryTemplates = (payload: UseJournalEntryTemplatesPayload = {}) => {
  const { pageSize = 25, searchTerm, sort = { id: 'createdAt', desc: true } } = payload;
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();

  return useInfiniteQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRY_TEMPLATES, { sort, searchTerm }),
    async ({ pageParam = 0 }) => {
      const response = await getJournalEntryTemplates({
        userIds: [],
        organizationIds: [organizationId],
        page: pageParam,
        pageSize,
        sort,
        searchTerm,
      });
      return response.data;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.prevPage ?? undefined,
      getNextPageParam: (lastPage) => lastPage.nextPage,
      enabled: !!organizationId,
    },
  );
};

export const useJournalEntryTemplateById = (journalEntryTemplateId?: string) => {
  const { organizationId } = useSession();

  const getKey = useOrgBasedQueryKey();

  return useQuery(
    getKey(QUERY_KEY.JOURNAL_ENTRY_TEMPLATES, { journalEntryTemplateId }),
    async () => {
      const response = await getJournalEntryTemplateById({ journalEntryTemplateId });
      const { journalEntryTemplate } = response.data;
      return journalEntryTemplate;
    },
    { enabled: !!organizationId && !!journalEntryTemplateId && journalEntryTemplateId.length > 0 },
  );
};

export const useUpdateJournalEntryTemplate = () =>
  useMutation(
    (data: {
      journalEntryTemplateId: string;
      body: {
        name: JournalEntryTemplate['name'];
        memo: JournalEntryTemplate['memo'];
        externalReference: JournalEntryTemplate['externalReference'];
        status: JournalEntryTemplate['status'];
        currency: JournalEntryTemplate['currency'];
        organizationId: JournalEntryTemplate['organizationId'];
      };
    }) => updateJournalEntryTemplate(data),
  );

export const useCreateJournalEntryTemplate = () => useMutation((data: any) => createJournalEntryTemplate(data));

export const useDeleteJournalEntryTemplate = () => useMutation((data: any) => deleteJournalEntryTemplate(data));

export const useUpdateJournalEntryLineTemplate = () => {
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  return useMutation(async (data) => {
    const response = await updateJournalEntryLineTemplate(data);
    await invalidateJournalEntryTemplates();
    return response;
  });
};

export const useCreateJournalEntryLineTemplate = () => {
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  return useMutation(async (data) => {
    const response = await createJournalEntryLineTemplate(data);
    await invalidateJournalEntryTemplates();
    return response;
  });
};

export const useDeleteJournalEntryLineTemplate = () => {
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  return useMutation(async (data) => {
    const response = await deleteJournalEntryLineTemplate(data);
    await invalidateJournalEntryTemplates();
    return response;
  });
};

export const useDuplicateJournalEntryTemplate = () => useMutation((data) => duplicateJournalEntryTemplate(data));

export const useCreateJournalEntriesWithoutRule = () =>
  useMutation((data: CreateJournalEntriesWithoutRuleParams) => createJournalEntriesWithoutRule(data));

export const useBulkDeleteJournalEntryTemplateMutation = () =>
  useMutation((data: BulkDeleteJournalEntryTemplatesParams) => bulkDeleteJournalEntryTemplates(data));
