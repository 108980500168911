import React, { memo, CSSProperties } from 'react';
import { TableRow } from '@/shadcn/ui/table';
import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useDensity } from 'src/context';
import { cn } from '@/shadcn/lib/utils';
import { NET_INCOME } from 'global-utils';

const TanStackTableRow = ({ onRowClick, row, children, isGroup = false, isDragEnabled = false }) => {
  const { density } = useDensity();
  let { ledgerAccount, ledgerAccountName } = row.original;
  ledgerAccountName = ledgerAccountName || ledgerAccount?.ledgerAccountName;
  const isTotal = ledgerAccountName?.toLowerCase().includes('total') && !row.original?.subRows?.length;
  const isGrandTotal = ledgerAccountName === NET_INCOME;

  const tableRowClasses = `${isGrandTotal ? '[&_td]:bg-[#EEF2FF]' : isTotal ? '[&_td]:bg-[#FFFBEB]' : '[&_td]:bg-white'}  [&_td]:hover:bg-[#f8f8f8] [&_.viewBtn]:hover:!inline-flex cursor-pointer relative`;

  if (!isDragEnabled) {
    return (
      <TableRow
        className={cn(
          tableRowClasses,
          {
            compact: 'h-[36px]',
            default: 'h-[48px]',
            comfortable: 'h-[64px]',
          }[density],
        )}
        onClick={(e) => {
          e.stopPropagation();
          onRowClick && onRowClick(row);
        }}
      >
        {children}
      </TableRow>
    );
  }

  const { setNodeRef, transform, transition, isDragging, attributes, listeners }: any = isGroup
    ? useDroppable({ id: row.original.groupId })
    : useSortable({ id: row.original._id });

  const style: CSSProperties = isGroup
    ? {}
    : {
        transform: CSS.Transform.toString(transform),
        transition: transition || 'none',
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1000 : 0,
        position: 'relative',
        cursor: isDragging ? 'grabbing' : 'grab',
      };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onClick={(e) => {
        e.stopPropagation();
        onRowClick && onRowClick(row);
      }}
      className={cn(
        tableRowClasses,
        {
          compact: 'h-[36px]',
          default: 'h-[48px]',
          comfortable: 'h-[64px]',
        }[density],
      )}
    >
      {children}
    </TableRow>
  );
};

export default memo(TanStackTableRow);
