import React from 'react';
import { SidebarBody, SidebarSection, DetailsItem } from '../atoms';
import { formatDollars } from '../templates/utils';

export const ViewJournalEntryLineBody = ({ line }) => {
  const tags = line?.tags?.map((tag) => tag.tag);
  const tagsValue = tags?.length > 0 ? tags : 'No tags yet';

  return (
    <SidebarBody>
      <SidebarSection>
        <DetailsItem tag={'Legal Entity'} value={line.legalEntityId.entityName} />
        <DetailsItem tag='Ledger Account' value={line.ledgerAccount} />
        <DetailsItem tag='Type' value={line.creditOrDebit} />
        <DetailsItem tag='Amount' value={formatDollars(line?.amount, true)} />
        <DetailsItem tag='Memo' value={line?.memo} />
        <DetailsItem tag='Tags' value={tagsValue} />
      </SidebarSection>
    </SidebarBody>
  );
};
