import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useGetExchangeSources } from '../../hooks/http/useExchangeSource';
import { ExchangeSourceType } from 'services/http/types';
import { ThreeDots } from 'react-loader-spinner';

export const CoinbasePrimeConditionMenu = ({ isDisabled = false }) => {
  const { data: integrations, isLoading } = useGetExchangeSources(ExchangeSourceType.COINBASE_PRIME);

  const getSelectedSource = () => {
    return integrations?.sources?.find((item) => item.exchangeSourceType === ExchangeSourceType.COINBASE_PRIME);
  };

  const renderText = () => {
    if (isLoading) {
      return (
        <ThreeDots
          height='24'
          width='24'
          radius='9'
          color='#aaaaaa'
          ariaLabel='three-dots-loading'
          wrapperClass='w-28'
          visible={true}
        />
      );
    }
    const selectedSource = getSelectedSource();

    if (selectedSource) {
      return 'Coinbase Prime';
    }

    return 'Select a Exchange';
  };

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger
        className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center'
        disabled={isDisabled}
      >
        <div className='mr-2 flex h-full justify-center items-center content-center'>{renderText()}</div>
      </DropdownMenu.Trigger>
    </DropdownMenu.Root>
  );
};
