/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  type?: string;
  inputStyle?: string;
  disabled?: boolean;
  viewMode?: boolean;
  value?: string;
  customValue?: boolean;
  customValueComp?: React.ReactNode;
}
export const InputComp = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label = 'Label',
      type,
      className,
      value,
      customValue = false,
      customValueComp = <></>,
      viewMode = false,
      inputStyle,
      disabled,
      ...props
    },
    ref,
  ) => (
    <div className={`flex flex-col font-medium max-w-[720px] ${className}`} ref={ref}>
      <label className={`mb-1 font-medium ${viewMode && 'text-[#00000066]'}`}>{label}</label>
      {viewMode ? (
        <>{customValue ? customValueComp : <p className='font-medium opacity-80'>{value ? value : 'N/A'}</p>}</>
      ) : (
        <input
          disabled={disabled}
          className={`px-4 py-3 h-[38px] border-2 hover:border-[#C4C4C4] border-[#E8E8E8] rounded-lg duration-300 focus-visible:outline-[0px] focus:border-black ${
            disabled && 'text-[#999999] !bg-[#f2f2f2]'
          } ${inputStyle}`}
          type={type || 'text'}
          value={value}
          {...props}
        />
      )}
    </div>
  ),
);

export default InputComp;
