import { useEffect, useState } from 'react';
import { DatePickerSelectionInLocalStorage } from 'src/components/DatePickerComp.types';
import { useSession } from 'src/hooks';
import { DateInterval } from '../types';

export const useDatesWithTzOffset = (dateSelections: DatePickerSelectionInLocalStorage[]) => {
  const { selectedOrganization } = useSession();
  const [datesWithTzOffset, setDatesWithTzOffset] = useState<DateInterval>({});

  useEffect(() => {
    if (!selectedOrganization) return;

    if (!dateSelections[0]) setDatesWithTzOffset({});
    const next: DateInterval = {};
    if (dateSelections[0]?.startDate) next.start = (new Date(dateSelections[0].startDate).toISOString());
    if (dateSelections[0]?.endDate) next.end = (new Date(dateSelections[0].endDate).toISOString());

    setDatesWithTzOffset(next);
  }, [dateSelections, selectedOrganization]);

  return datesWithTzOffset;
};
