import * as React from 'react';
import { forwardRef } from 'react';
const SvgLogo = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='#933DF5' />
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='url(#logo_svg__a)' fillOpacity={0.2} />
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='url(#logo_svg__b)' fillOpacity={0.2} />
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='url(#logo_svg__c)' fillOpacity={0.2} />
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='url(#logo_svg__d)' fillOpacity={0.2} />
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} fill='url(#logo_svg__e)' fillOpacity={0.08} />
    <g filter='url(#logo_svg__f)'>
      <path
        d='m19.669 14.68.141.142.142-.142 3.168-3.168c.45-.45.45-1.18 0-1.63l-2.495-2.494c-.45-.45-1.18-.45-1.63 0l-2.494 2.494c-.45.45-.45 1.18 0 1.63l3.168 3.168Z'
        fill='#fff'
      />
      <path
        d='m19.669 14.68.141.142.142-.142 3.168-3.168c.45-.45.45-1.18 0-1.63l-2.495-2.494c-.45-.45-1.18-.45-1.63 0l-2.494 2.494c-.45.45-.45 1.18 0 1.63l3.168 3.168Z'
        fill='url(#logo_svg__g)'
        fillOpacity={0.12}
      />
      <path
        d='m19.669 14.68.141.142.142-.142 3.168-3.168c.45-.45.45-1.18 0-1.63l-2.495-2.494c-.45-.45-1.18-.45-1.63 0l-2.494 2.494c-.45.45-.45 1.18 0 1.63l3.168 3.168Z'
        fill='url(#logo_svg__h)'
        fillOpacity={0.8}
      />
      <path
        d='m19.669 14.68.141.142.142-.142 3.168-3.168c.45-.45.45-1.18 0-1.63l-2.495-2.494c-.45-.45-1.18-.45-1.63 0l-2.494 2.494c-.45.45-.45 1.18 0 1.63l3.168 3.168Z'
        fill='url(#logo_svg__i)'
        fillOpacity={0.2}
      />
      <path
        d='m19.669 14.68.141.142.142-.142 3.168-3.168c.45-.45.45-1.18 0-1.63l-2.495-2.494c-.45-.45-1.18-.45-1.63 0l-2.494 2.494c-.45.45-.45 1.18 0 1.63l3.168 3.168Z'
        stroke='url(#logo_svg__j)'
        strokeWidth={0.4}
      />
      <path
        d='m19.816 26.076-.142-.142-.141.142-3.032 3.032c-.45.45-.45 1.18 0 1.63l2.359 2.358c.45.45 1.18.45 1.63 0l2.358-2.359c.45-.45.45-1.18 0-1.63l-3.032-3.031Z'
        fill='#fff'
      />
      <path
        d='m19.816 26.076-.142-.142-.141.142-3.032 3.032c-.45.45-.45 1.18 0 1.63l2.359 2.358c.45.45 1.18.45 1.63 0l2.358-2.359c.45-.45.45-1.18 0-1.63l-3.032-3.031Z'
        fill='url(#logo_svg__k)'
        fillOpacity={0.12}
      />
      <path
        d='m19.816 26.076-.142-.142-.141.142-3.032 3.032c-.45.45-.45 1.18 0 1.63l2.359 2.358c.45.45 1.18.45 1.63 0l2.358-2.359c.45-.45.45-1.18 0-1.63l-3.032-3.031Z'
        fill='url(#logo_svg__l)'
        fillOpacity={0.8}
      />
      <path
        d='m19.816 26.076-.142-.142-.141.142-3.032 3.032c-.45.45-.45 1.18 0 1.63l2.359 2.358c.45.45 1.18.45 1.63 0l2.358-2.359c.45-.45.45-1.18 0-1.63l-3.032-3.031Z'
        fill='url(#logo_svg__m)'
        fillOpacity={0.2}
      />
      <path
        d='m19.816 26.076-.142-.142-.141.142-3.032 3.032c-.45.45-.45 1.18 0 1.63l2.359 2.358c.45.45 1.18.45 1.63 0l2.358-2.359c.45-.45.45-1.18 0-1.63l-3.032-3.031Z'
        stroke='url(#logo_svg__n)'
        strokeWidth={0.4}
      />
      <mask id='logo_svg__r' maskUnits='userSpaceOnUse' x={8.201} y={17.216} width={23} height={9} fill='#000'>
        <path fill='#fff' d='M8.201 17.216h23v9h-23z' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.602 18.216a.4.4 0 0 0-.283.682l5.895 5.895a.62.62 0 0 0 .877 0l3.818-3.818 3.818 3.818a.62.62 0 0 0 .877 0l5.895-5.895a.4.4 0 0 0-.283-.682H9.602Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.602 18.216a.4.4 0 0 0-.283.682l5.895 5.895a.62.62 0 0 0 .877 0l3.818-3.818 3.818 3.818a.62.62 0 0 0 .877 0l5.895-5.895a.4.4 0 0 0-.283-.682H9.602Z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.602 18.216a.4.4 0 0 0-.283.682l5.895 5.895a.62.62 0 0 0 .877 0l3.818-3.818 3.818 3.818a.62.62 0 0 0 .877 0l5.895-5.895a.4.4 0 0 0-.283-.682H9.602Z'
        fill='url(#logo_svg__o)'
        fillOpacity={0.8}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.602 18.216a.4.4 0 0 0-.283.682l5.895 5.895a.62.62 0 0 0 .877 0l3.818-3.818 3.818 3.818a.62.62 0 0 0 .877 0l5.895-5.895a.4.4 0 0 0-.283-.682H9.602Z'
        fill='url(#logo_svg__p)'
        fillOpacity={0.2}
      />
      <path
        d='m9.32 18.898-.284.283.283-.283Zm5.894 5.895.283-.282-.283.282Zm.877 0-.283-.282.283.282Zm3.818-3.818.283-.283-.283-.282-.283.282.283.283Zm4.695 3.818.283.283-.283-.283Zm5.895-5.895-.283-.282.283.282Zm-20.897-.282v-.8a.8.8 0 0 0-.566 1.365l.566-.565Zm5.895 5.895-5.895-5.895-.566.565 5.895 5.895.566-.566Zm.311 0a.22.22 0 0 1-.311 0l-.566.565a1.02 1.02 0 0 0 1.443 0l-.566-.566Zm3.818-3.819-3.818 3.819.566.565 3.818-3.818-.566-.566Zm4.384 3.819-3.818-3.819-.566.566 3.818 3.818.566-.566Zm.311 0a.22.22 0 0 1-.311 0l-.566.565a1.02 1.02 0 0 0 1.443 0l-.566-.566Zm5.895-5.895-5.895 5.895.566.565 5.895-5.895-.566-.565Zm0 0 .566.565a.8.8 0 0 0-.566-1.365v.8Zm-6.05 0h6.05v-.8h-6.05v.8Zm-1.497 0h1.497v-.8h-1.497v.8Zm-5.52 0h5.52v-.8h-5.52v.8Zm-1.497 0h1.497v-.8h-1.497v.8Zm-6.05 0h6.05v-.8h-6.05v.8Z'
        fill='url(#logo_svg__q)'
        mask='url(#logo_svg__r)'
      />
    </g>
    <rect x={0.556} y={0.556} width={38.889} height={38.889} rx={8.333} stroke='#600AC2' strokeWidth={1.111} />
    <rect
      x={0.556}
      y={0.556}
      width={38.889}
      height={38.889}
      rx={8.333}
      stroke='url(#logo_svg__s)'
      strokeOpacity={0.1}
      strokeWidth={1.111}
    />
    <defs>
      <radialGradient
        id='logo_svg__b'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='rotate(61.389 -2.197 17.023) scale(22.5539)'
      >
        <stop offset={0.163} stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__d'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='rotate(51.028 -1.905 13.257) scale(22.8954)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__e'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(12 13.8 -13.8 12 7.2 8.2)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__h'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(3.4 4.2 -4.2 3.4 16 8.2)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__i'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(1.54533 .04593 -.26465 8.90388 17.5 10.74)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__l'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='rotate(51.009 -20.836 30.524) scale(5.21247)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__m'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(1.49064 .0443 -.25529 8.58879 17.445 29.964)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__o'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='rotate(108.444 2.343 13.878) scale(7.04858 20.3194)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id='logo_svg__p'
        cx={0}
        cy={0}
        r={1}
        gradientUnits='userSpaceOnUse'
        gradientTransform='matrix(4.17953 1.4 -4.86744 14.53115 13.346 19.6)'
      >
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </radialGradient>
      <linearGradient id='logo_svg__a' x1={20} y1={0} x2={20} y2={40.5} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient id='logo_svg__c' x1={20} y1={36.4} x2={6.2} y2={11.2} gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient id='logo_svg__g' x1={17.89} y1={15.238} x2={17.89} y2={7.143} gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient id='logo_svg__j' x1={21} y1={11.6} x2={17.2} y2={8.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#600AC2' />
        <stop offset={1} stopColor='#B2FD9F' />
      </linearGradient>
      <linearGradient id='logo_svg__k' x1={17.822} y1={34.302} x2={17.822} y2={26.494} gradientUnits='userSpaceOnUse'>
        <stop />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient id='logo_svg__n' x1={20.822} y1={30.793} x2={17.156} y2={27.513} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#600AC2' />
        <stop offset={1} stopColor='#B2FD9F' />
      </linearGradient>
      <linearGradient id='logo_svg__q' x1={20.709} y1={23.436} x2={20.597} y2={17.084} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#600AC2' />
        <stop offset={1} stopColor='#B2FD9F' />
      </linearGradient>
      <linearGradient id='logo_svg__s' x1={20} y1={-3.8} x2={20} y2={29.2} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#fff' />
        <stop offset={1} stopColor='#fff' stopOpacity={0} />
      </linearGradient>
      <filter
        id='logo_svg__f'
        x={5.067}
        y={4.716}
        width={29.867}
        height={32.967}
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset dy={1.2} />
        <feGaussianBlur stdDeviation={0.8} />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0.188235 0 0 0 0 0.0196078 0 0 0 0 0.380392 0 0 0 0.5 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1437_144653' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_1437_144653' result='shape' />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
