import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import {
  useLegalEntities,
  useAddJournalEntry,
  useAccountingPeriods,
  useLedgerAccount,
  usePatchJournalEntry,
  useDeleteJournalEntry,
  useTransactions,
} from '../../hooks/http';
import {
  deriveError,
  getDisplayedAccountingPeriods,
  getDisplayedLegalEntities,
  getDisplayedTransactions,
  getIdFromPopulatedInstance,
  mergeAccountingPeriods,
  mergeLedgerAccounts,
  mergeLegalEntities,
  mergeTransactions,
} from '../templates/utils';
import { CreateJournalEntryLinePanel } from './CreateJournalEntryLinePanel';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody/SidebarSectionHeader';
import { SidebarSection, SidebarTableSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { InputLabel, TextareaInput, Button, SelectableCard, InputWithExtras, SingleSelectMenu } from 'ui';
import { toast } from 'react-hot-toast';
import { JournalEntryLineDB, Tag } from 'schemas';
import { getUpdatedTabSidebarObject } from '../utils';
import { useSession } from '../../hooks/useSession';
import { JOURNAL_ENTRY_STATUS, JournalEntry } from 'services/http/response.types';
import { useInvalidateQuery, useSearch, useTabState } from '../../hooks';
import { journalEntryLinesColumns } from './journalEntryLinesColumns';
import Table from '../tables/tanstack-table/Table';
import { DensityDropdown, EditColumns } from '../tables/table-components';
import { ColumnsProps } from '../tables/columns/types';
import { SortingState } from '@tanstack/react-table';

/**
 * Small note on this component we cant test it locally unless we provide hardcoded wallet id and transaction id
 *
 * we need to somehow sync our local db with algolia for free to test. I dont know if thats possible
 *
 * One work around is to query transactions directly and show them in the dropdown but in prod default to the algolia search
 */

interface CreateJournalEntryProps {
  onCancel?: () => void;
  onBack?: () => void;
  onClose?: () => void;
  title?: string;
  selectedJournalEntry?: any;
  shouldNestCreateJournalLinePanel?: boolean;
  defaultTransaction?: {
    transactionId: string;
    walletId: string;
    legalEntityId: string;
    sequenceNumber: string;
    chain: string;
    accountingPeriodId: string;
    amount?: string;
  };
  'data-cy'?: string;
}

const CreateJournalEntry = ({
  onCancel = () => undefined,
  title = 'Create journal entry',
  selectedJournalEntry,
  onBack,
  onClose = () => undefined,
  shouldNestCreateJournalLinePanel = false,
  defaultTransaction,
  'data-cy': dataCy = 'createJournalEntry',
}: CreateJournalEntryProps) => {
  const [sortState, setSortState] = useState<SortingState>([]);
  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(journalEntryLinesColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  const { activeTab, secondRouteUnStack, sidebarState, updateTabSidebarState } = useTabState();
  const [formData, setFormData] = useState<JournalEntry | any>(
    selectedJournalEntry || {
      status: secondRouteUnStack?.journalEntryFormData?.status ?? JOURNAL_ENTRY_STATUS.POSTED,
      memo: secondRouteUnStack?.journalEntryFormData?.memo ?? '',
      externalReference: secondRouteUnStack?.journalEntryFormData?.externalReference ?? '',
      tags: secondRouteUnStack?.journalEntryFormData?.tags ?? [],
      accountingDate: secondRouteUnStack?.journalEntryFormData?.accountingDate ?? '',
      accountingPeriodId:
        defaultTransaction?.accountingPeriodId ?? secondRouteUnStack?.journalEntryFormData?.accountingPeriodId ?? '',
      accountPostingRuleId: secondRouteUnStack?.journalEntryFormData?.accountPostingRuleId ?? '',
      legalEntityId:
        secondRouteUnStack?.journalEntryFormData?.legalEntityId ??
        getIdFromPopulatedInstance(defaultTransaction?.legalEntityId) ??
        undefined,
      transactionId: secondRouteUnStack?.journalEntryFormData?.transactionId ?? defaultTransaction?.transactionId ?? '',
      walletId: secondRouteUnStack?.journalEntryFormData?.walletId ?? defaultTransaction?.walletId ?? '',
    },
  );

  const { mutate: createJournalEntryByParams } = useAddJournalEntry();
  const { mutate: editJournalEntryByParams } = usePatchJournalEntry();

  const { organizationId, userId } = useSession();

  const [showCreateJournalEntryLinePanel, setShowCreateJournalEntryLinePanel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: legalEntities } = useLegalEntities();
  const mergedLegalEntities = mergeLegalEntities(legalEntities);
  const displayedLegalEntities = getDisplayedLegalEntities(mergedLegalEntities);
  const { data: accountingPeriods, isLoading: isLoadingAccountingPeriods } = useAccountingPeriods({ status: 'Open' });
  const mergedAccountingPeriods = mergeAccountingPeriods(accountingPeriods);
  const displayedAccountingPeriods = getDisplayedAccountingPeriods(mergedAccountingPeriods);
  const uniqueAccountingPeriods = displayedAccountingPeriods.filter(
    (period, index, self) => index === self.findIndex((t) => t.label === period.label),
  );
  const [displayedTransactions, setDisplayedTransactions] = useState<
    {
      value: string;
      label: string;
      bottomText?: string;
      icon?: string | ReactNode;
    }[]
  >([]);
  const { mutateAsync: deleteJournalEntry } = useDeleteJournalEntry();
  const { searchTerm, isSearching, setIsSearching, setSearchTerm } = useSearch();

  const {
    data: _transactions,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useTransactions({
    searchTerm,
    pageSize: 25,
    sort: sortState.length > 0 ? sortState[0] : { id: 'transactionDate', desc: true },
  });

  useEffect(() => {
    if (!isFetching && isSearching) setIsSearching(false);
  }, [isFetching, isSearching]);

  const { invalidateJournalEntries } = useInvalidateQuery();

  const handleClickCreateJournalEntry = async () => {
    const tags = [...new Set(lines.map((line) => line.tags).flatMap((tag) => (tag as unknown as Tag)?._id))].filter(
      (item) => item,
    );
    const getLineTagids = (line) => {
      if (line.tags && line.tags.length) return line.tags.map((tag) => tag._id);
      else return [];
    };
    const payload = {
      organizationId,
      journalEntryModelInput: {
        journalEntry: {
          idempotencyKey: `${new Date().getTime()} ${userId} Manual JE`,
          accountingDate: new Date(), // this should get overidden in the backend anyway
          legalEntityId: formData.legalEntityId,
          organizationId,
          originatedBy: 'user',
          status: formData.status,
          userId,
          transactionId: formData?.transactionId,
          walletId: formData?.walletId ?? undefined,
          accountingPeriodId: formData.accountingPeriodId,
          tags,
        },
        journalEntryLines: lines.map((line, i) => ({
          ...line,
          tags: getLineTagids(line),
          idempotencyKey: `${new Date().getTime()} Manual JEL ${i}`,
        })),
      },
    };
    const editPayload = {
      organizationId,
      journalEntryModel: {
        journalEntry: {
          _id: null,
          idempotencyKey: `${new Date().getTime()} ${userId} Manual JE`,
          accountingDate: new Date(),
          legalEntityId: formData.legalEntityId,
          organizationId,
          originatedBy: 'user',
          status: formData.status,
          userId,
          transactionId: formData?.transactionId,
          walletId: formData?.walletId ?? undefined,
          accountingPeriodId: formData.accountingPeriodId,
          tags,
        },
        journalEntryLines: lines.map((line, i) => ({
          ...line,
          tags: getLineTagids(line),
          idempotencyKey: `${new Date().getTime()} Manual JEL ${i}`,
        })),
      },
    };

    const onSuccess = async () => {
      invalidateJournalEntries();
      if (selectedJournalEntry) {
        // invalidate query for getJournalEntryById
        invalidateJournalEntries({ journalEntryId: selectedJournalEntry._id });
      }
      toast.success(`Your journal entry has been ${selectedJournalEntry ? 'edited' : 'created'}.`);
      setIsSaving(false);
      onCancel();
    };
    const onError = (error) => {
      toast.error(deriveError(error));
      setIsSaving(false);
    };
    try {
      setIsSaving(true);
      if (selectedJournalEntry) {
        editPayload.journalEntryModel.journalEntry._id = selectedJournalEntry._id;
        await editJournalEntryByParams(editPayload as any, {
          onSuccess,
          onError,
        });
      } else {
        await createJournalEntryByParams(payload as any, {
          onSuccess,
          onError,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickDeleteJorunal = async () => {
    if (
      selectedJournalEntry?.status?.toLowerCase() === 'draft' ||
      selectedJournalEntry?.status?.toLowerCase() === 'unposted'
    ) {
      setIsDeleting(true);
      await deleteJournalEntry(
        { journalEntryId: selectedJournalEntry?._id, organizationId: selectedJournalEntry?.organizationId },
        {
          onSuccess: () => {
            toast.success('Journal entry deleted successfully');

            onClose();
            invalidateJournalEntries();
          },
          onError: (error) => {
            toast.error(deriveError(error));
          },
        },
      );
    }
  };

  const [lines, setLines] = useState<(JournalEntryLineDB & { draftId?: string })[]>([]);
  const [selectedLine, setSelectedLine] = useState<(JournalEntryLineDB & { draftId?: string }) | null>(null);
  const { data: ledgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgeAccounts = mergeLedgerAccounts(ledgerAccounts);
  const displayedLinesForTable =
    lines.map((line) => ({
      ...line,
      ledgerAccount: mergedLedgeAccounts.find((account) => account._id === line.ledgerAccountId)?.ledgerAccountName,
      company: mergedLegalEntities.find((entity) => entity._id === line.legalEntityId)?.entityName,
      legalEntityId: {
        _id: line.legalEntityId,
        entityName: mergedLegalEntities.find((entity) => entity._id === line.legalEntityId)?.entityName,
      },
    })) || [];

  useEffect(() => {
    if (selectedJournalEntry) {
      const updatedFormObject = getUpdatedTabSidebarObject({
        sidebarState,
        keys: ['secondRouteUnStack', 'journalEntryFormData'],
        ...selectedJournalEntry,
      });

      updateTabSidebarState(updatedFormObject, true);

      setFormData({ ...selectedJournalEntry });

      setLines(
        selectedJournalEntry.journalEntryLineIds?.map((line) => ({
          ...line,
          amount: parseFloat(line?.amount?.$numberDecimal),
          company: line?.legalEntityId?.entityName,
          ledgerAccount: line?.ledgerAccountId?.ledgerAccountName,
          draftId: new Date().getTime() + line._id,
          ledgerAccountId: line?.ledgerAccountId?._id,
          legalEntityId: line?.legalEntityId?._id,
        })) || [],
      );

      setDisplayedTransactions([
        {
          value: selectedJournalEntry?.transactionId?._id || '',
          label: selectedJournalEntry?.transactionId?.sequenceNumber || '',
        },
      ]);
    }
  }, [selectedJournalEntry]);
  const mergedTransactions = mergeTransactions(_transactions ?? []);

  useEffect(() => {
    const transactions: any[] = [];
    if (mergedTransactions?.length) {
      transactions.push(...getDisplayedTransactions(mergedTransactions));
    }

    setDisplayedTransactions(transactions);
  }, [_transactions]);

  useEffect(() => {
    if (defaultTransaction && defaultTransaction.transactionId) {
      setDisplayedTransactions((prevTransactions) => {
        const defaultTransactionExists = prevTransactions.some(
          (transaction) => transaction.value === defaultTransaction.transactionId,
        );

        if (!defaultTransactionExists) {
          return [
            {
              value: defaultTransaction.transactionId,
              label: defaultTransaction.sequenceNumber || 'Default Transaction',
              // You can add more properties here if needed
            },
            ...prevTransactions,
          ];
        }

        return prevTransactions;
      });
    }
  }, [defaultTransaction]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      onCancel && onCancel();
    }
  };
  const memoizedTabUpdate = useMemo(() => activeTab?.id, [activeTab?.id]);

  useEffect(() => {
    setFormData({
      status: secondRouteUnStack?.journalEntryFormData?.status ?? JOURNAL_ENTRY_STATUS.POSTED,
      memo: secondRouteUnStack?.journalEntryFormData?.memo ?? '',
      externalReference: secondRouteUnStack?.journalEntryFormData?.externalReference ?? '',
      tags: secondRouteUnStack?.journalEntryFormData?.tags ?? [],
      accountingDate: secondRouteUnStack?.journalEntryFormData?.accountingDate ?? '',
      accountingPeriodId:
        defaultTransaction?.accountingPeriodId ?? secondRouteUnStack?.journalEntryFormData?.accountingPeriodId ?? '',
      accountPostingRuleId: secondRouteUnStack?.journalEntryFormData?.accountPostingRuleId ?? '',
      legalEntityId:
        secondRouteUnStack?.journalEntryFormData?.legalEntityId ??
        getIdFromPopulatedInstance(defaultTransaction?.legalEntityId) ??
        undefined,
      transactionId: secondRouteUnStack?.journalEntryFormData?.transactionId ?? defaultTransaction?.transactionId ?? '',
      walletId: secondRouteUnStack?.journalEntryFormData?.walletId ?? defaultTransaction?.walletId ?? undefined,
    });
  }, [memoizedTabUpdate]);

  return (
    <>
      <div className={showCreateJournalEntryLinePanel && !shouldNestCreateJournalLinePanel ? 'hidden' : ''}>
        <Sidebar data-cy={dataCy}>
          <SidebarTopBar onBack={onBack} onClose={handleClose} />
          <SidebarHeader data-cy={dataCy} title={title} />
          <SidebarBody>
            <SidebarSectionHeader title='Details' />
            <SidebarSection numberOfColumns={1}>
              <div>
                <InputLabel heading='Legal entity' />
                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  data-cy={`${dataCy}__legalEntity`}
                  options={displayedLegalEntities}
                  placeholder='Select legal entity'
                  value={displayedLegalEntities.find(
                    (entity) => entity.value === getIdFromPopulatedInstance(formData.legalEntityId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      legalEntityId: data.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, legalEntityId: data.value });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      legalEntityId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, legalEntityId: undefined });
                  }}
                />
              </div>
              <div>
                <InputLabel heading='Operational transaction' />

                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  enableSearch
                  enableAvatar
                  enableBottomText
                  blockInlineFilter
                  data-cy={`${dataCy}__operationalTransaction`}
                  options={displayedTransactions}
                  placeholder='Select operational transaction'
                  onSearchInputChange={(value) => setSearchTerm(value)}
                  searchInputValue={searchTerm}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  value={displayedTransactions.find(
                    (transaction) => transaction.value === getIdFromPopulatedInstance(formData.transactionId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      transactionId: data.value,
                      walletId: mergedTransactions.find((transaction) => transaction._id === data.value)?.walletId?._id,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({
                      ...formData,
                      transactionId: data.value,
                      walletId: mergedTransactions.find((transaction) => transaction._id === data.value)?.walletId?._id,
                    });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      transactionId: undefined,
                      walletId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, transactionId: undefined, walletId: undefined });
                  }}
                  isLoading={isFetching || isSearching}
                  forcePersistSearch
                />
              </div>
              <div>
                <div className='grid grid-cols-2 gap-4'>
                  <SelectableCard
                    data-cy={`${dataCy}__autoPost`}
                    label='Auto Post'
                    onClick={() => {
                      const updatedFormObject = getUpdatedTabSidebarObject({
                        sidebarState,
                        keys: ['secondRouteUnStack', 'journalEntryFormData'],
                        status: JOURNAL_ENTRY_STATUS.POSTED,
                      });
                      updateTabSidebarState(updatedFormObject, true);

                      setFormData({ ...formData, status: JOURNAL_ENTRY_STATUS.POSTED });
                    }}
                    selected={formData.status === JOURNAL_ENTRY_STATUS.POSTED}
                  />
                  <SelectableCard
                    label='Draft'
                    data-cy={`${dataCy}__draft`}
                    onClick={() => {
                      const updatedFormObject = getUpdatedTabSidebarObject({
                        sidebarState,
                        keys: ['secondRouteUnStack', 'journalEntryFormData'],
                        status: JOURNAL_ENTRY_STATUS.DRAFT,
                      });
                      updateTabSidebarState(updatedFormObject, true);

                      setFormData({ ...formData, status: JOURNAL_ENTRY_STATUS.DRAFT });
                    }}
                    selected={formData.status === JOURNAL_ENTRY_STATUS.DRAFT}
                  />
                </div>
              </div>
              <div>
                <InputLabel heading='External reference' />
                <InputWithExtras
                  data-cy={`${dataCy}__externalReference`}
                  onChange={(e) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      externalReference: e.target.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, externalReference: e.target.value });
                  }}
                  value={formData?.externalReference}
                  placeholder='Enter external reference'
                />
              </div>
              <div>
                <InputLabel heading='Accounting period' />
                <SingleSelectMenu
                  fullWidth
                  isOnSidepanel
                  data-cy={`${dataCy}__accountingPeriod`}
                  options={uniqueAccountingPeriods}
                  placeholder='Select accounting period'
                  isLoading={isLoadingAccountingPeriods}
                  value={uniqueAccountingPeriods.find(
                    (period) => period.value === getIdFromPopulatedInstance(formData.accountingPeriodId),
                  )}
                  onChange={(data) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      accountingPeriodId: data.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, accountingPeriodId: data.value });
                  }}
                  onClearValue={() => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      accountingPeriodId: undefined,
                    });
                    updateTabSidebarState(updatedFormObject, true);

                    setFormData({ ...formData, accountingPeriodId: undefined });
                  }}
                />
              </div>
              <div>
                <InputLabel heading='Memo' />
                <TextareaInput
                  data-cy={`${dataCy}__memo`}
                  onChange={(e) => {
                    const updatedFormObject = getUpdatedTabSidebarObject({
                      sidebarState,
                      keys: ['secondRouteUnStack', 'journalEntryFormData'],
                      memo: e.target.value,
                    });
                    updateTabSidebarState(updatedFormObject, true);
                    setFormData({ ...formData, memo: e.target.value });
                  }}
                  value={formData?.memo}
                />
              </div>
            </SidebarSection>
            <SidebarSectionHeader
              title='Journal lines'
              actions={[
                {
                  label: 'Add journal lines',
                  onClick: () => {
                    setShowCreateJournalEntryLinePanel(true);
                  },
                  variant: 'tertiary',
                  'data-cy': `${dataCy}__addJournalLinesButton`,
                },
              ]}
            />
            <SidebarTableSection>
              <div className='w-full'>
                <Table
                  data={displayedLinesForTable}
                  columns={columns}
                  panelTableName={'CreateJournalEntry'}
                  onSortingChange={setSortState}
                  onRowClick={(row) => {
                    const line = lines.find((line) => line.draftId === row.original.draftId);
                    if (!line) {
                      toast.error('Error opening journal entry line');
                      return;
                    }
                    setSelectedLine(line);
                    setShowCreateJournalEntryLinePanel(true);
                  }}
                  disableColumnPinning={true}
                  enableRowSelection={false}
                >
                  <div className='flex flex-wrap items-center justify-between gap-3 px-3 py-5 w-full'>
                    <div className='flex items-center gap-3 flex-1'></div>
                    <div className='flex flex-wrap gap-3'>
                      <EditColumns
                        onColumnsUpdate={handleColumnsUpdate}
                        columns={columns}
                        panelTableName={'CreateJournalEntry'}
                      />
                      <DensityDropdown />
                    </div>
                  </div>
                </Table>
              </div>
            </SidebarTableSection>
          </SidebarBody>
          <SidebarFooter
            primaryBtn={
              <Button
                emphasis='high'
                data-cy={`${dataCy}__createJournalEntryButton`}
                label='Create journal entry'
                isLoading={isSaving}
                onClick={async () => {
                  try {
                    await handleClickCreateJournalEntry();
                  } catch (error) {
                    toast.error(deriveError(error));
                  }
                }}
              />
            }
            secondaryBtn={
              <Button
                label='Cancel'
                emphasis='medium'
                onClick={() => {
                  onCancel && onCancel();
                }}
              />
            }
            destructiveBtn={
              selectedJournalEntry && (
                <Button
                  data-cy={`${dataCy}__deleteJournalEntryButton`}
                  label='Delete'
                  emphasis='medium'
                  status='danger'
                  isLoading={isDeleting}
                  onClick={async () => {
                    await handleClickDeleteJorunal();
                  }}
                />
              )
            }
          />
        </Sidebar>
      </div>
      {showCreateJournalEntryLinePanel && (
        <CreateJournalEntryLinePanel
          setSelectedLine={setSelectedLine}
          selectedLine={selectedLine}
          setLines={setLines}
          onCancel={() => {
            setShowCreateJournalEntryLinePanel(false);
          }}
          defaultLineParams={{
            amount: defaultTransaction?.amount
              ? (Math.floor(parseFloat(defaultTransaction.amount) * 100) / 100).toString()
              : undefined,
            legalEntityId: defaultTransaction?.legalEntityId,
          }}
        />
      )}
    </>
  );
};

export default CreateJournalEntry;
