import { DefaultCell } from 'ui';
import { dateConverter } from 'src/components/utils';
import { ColumnsProps } from 'src/components/tables/columns/types';

export const accountPostingRulesColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue().title} />;
    },
  },
  {
    accessorKey: 'updatedAt',
    header: 'Updated at',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={dateConverter(getValue())} />;
    },
  },
  {
    accessorKey: 'createdBy',
    header: 'Created by',
    cell: ({ row }) => {
      return <DefaultCell textAlign={'text-left'} label={row.original?.createdBy?.email || ''} />;
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Created date',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={dateConverter(getValue())} />;
    },
  },
];
