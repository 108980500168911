import { WALLET_TYPE } from 'services/http/response.types';
import { useFilterState } from '../use-filters';
import { FILTER_TYPE } from '../../../components/filters';
import {
  useGetExchangeSources,
  useGetHedgeySource,
  useGetMapleSource,
  useSources,
  useGetRaincardSources,
} from '../../../hooks';
import { useEffect, useMemo } from 'react';
import { mergeSources } from '../../../components/templates/utils';
import { formatExchangeTypeToName } from 'services';
import { FilterContextParams } from '../types';
import { useFilteredLegalEntityIds } from '../filtered-hooks';

export type WalletFilterRow = {
  _id: string;
  chain: string;
  walletType: WALLET_TYPE;
  name?: string;
  address?: string;
  tags?: string[];
};
export type WalletFilterContextType = FilterContextParams<WalletFilterRow>;

export const useWalletFilterState = (page: string) => {
  const { state, setState } = useFilterState<WalletFilterRow>(FILTER_TYPE.WALLET, page);
  const selectedLegalEntityIds = useFilteredLegalEntityIds();

  const {
    data: walletData,
    isLoading: isLoadingWallet,
    refetch: refetchSources,
  } = useSources({
    pageSize: 1000,
    legalEntityIds: selectedLegalEntityIds,
  });
  const {
    data: hedgeyData,
    isLoading: isLoadingHedgey,
    refetch: refetchHedgey,
  } = useGetHedgeySource({
    legalEntityIds: selectedLegalEntityIds,
  });
  const {
    data: exchangeData,
    isLoading: isLoadingExchange,
    refetch: refetchExchanges,
  } = useGetExchangeSources(undefined, selectedLegalEntityIds);
  const {
    data: mapleData,
    isLoading: isLoadingMaple,
    refetch: refetchMaple,
  } = useGetMapleSource({ legalEntityIds: selectedLegalEntityIds });

  const {
    data: raincardData,
    isLoading: isLoadingRaincard,
    refetch: refetchRaincards,
  } = useGetRaincardSources({
    legalEntityIds: selectedLegalEntityIds,
  });

  useEffect(() => {
    refetchExchanges();
    refetchHedgey();
    refetchMaple();
    refetchSources();
    refetchRaincards();
  }, [selectedLegalEntityIds]);

  useEffect(() => {
    setState((prev) => {
      const previouslySelectedOptions = prev.filter((value) => value.selected);
      const wallets = mergeSources(walletData);
      const hedgeySources = hedgeyData?.hedgeySources ?? [];
      const exchangeSources = exchangeData?.sources ?? [];
      const mapleSources = mapleData?.mapleClients ?? [];

      const allSources = [
        ...wallets,
        ...hedgeySources.map((source) => ({
          ...source,
          chain: 'HEDGEY',
          walletType: WALLET_TYPE.INTERNAL,
        })),
        ...exchangeSources.map((source) => ({
          ...source,
          chain: 'Exchange',
          address: formatExchangeTypeToName(source.exchangeSourceType) + ': ' + source?.legalEntityEntityName,
          walletType: WALLET_TYPE.INTERNAL,
        })),
        ...mapleSources.map((source) => ({
          ...source,
          name: 'Maple Finance 1',
          walletType: WALLET_TYPE.EXTERNAL,
        })),
        ...(raincardData?.map((source) => ({
          _id: source._id,
          chain: 'Raincard',
          walletType: WALLET_TYPE.INTERNAL,
          name: `${source.cardData.nickname}`,
          address: source.cardData.nickname,
        })) || []),
      ];

      const next = allSources.map((source) => ({
        ...source,
        selected: previouslySelectedOptions.find((option) => option._id === source._id),
      }));

      return next;
    });
  }, [walletData, hedgeyData, exchangeData, mapleData, setState, page]);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    isLoading: isLoadingWallet || isLoadingHedgey || isLoadingExchange || isLoadingMaple || isLoadingRaincard,
    hasSelections,
  };
};
