import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  GetScheduledImpairmentJobsParams,
  getJobOrganizationConfig,
  getScheduledImpairmentJobs,
  manualAssetImpairment,
  triggerImpairmentRuleRun,
  upsertJobOrganizationConfig,
} from 'services/http/core';
import { useSession } from '../useSession';
import { QUERY_KEY, useOrgBasedQueryKey } from '../useInvalidateQuery';

export const useManualAssetImpairment = () => useMutation((data) => manualAssetImpairment(data));

export const useUpsertOrganizationImpairmentConfig = () =>
  useMutation((data: { organizationId: string; cronExpression?: string }) =>
    upsertJobOrganizationConfig({ ...data, jobType: 'IMPAIRMENT' }),
  );

export const useGetOrganizationImpairmentConfig = () => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useQuery(
    getKey(QUERY_KEY.ORGANIZATION_IMPAIRMENT_CONFIG),
    async () => {
      const response = await getJobOrganizationConfig({ organizationId, jobType: 'IMPAIRMENT' });
      const { jobConfig } = response.data;
      return jobConfig;
    },
    {
      enabled: !!organizationId,
    },
  );
};
export const useGetScheduledImpairmentJobsQuery = (params: GetScheduledImpairmentJobsParams) => {
  const { organizationId } = useSession();
  const getKey = useOrgBasedQueryKey();
  return useInfiniteQuery({
    queryKey: getKey(QUERY_KEY.SCHEDULED_IMPAIRMENT_JOBS),
    queryFn: () => getScheduledImpairmentJobs(params),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: !!organizationId,
  });
};

export const useTriggerImpairmentJob = () => useMutation({ mutationFn: triggerImpairmentRuleRun });
