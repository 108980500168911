import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledContent } from './StyledRadixDropdownElements';
import { RxChevronDown as ChevronDownIcon } from 'react-icons/rx';
import { useLedgerAccount } from '../../hooks/http';
import { formatLedgerAccountName, mergeLedgerAccounts } from '../templates/utils';
import { LedgerAccountSubMenu } from './LedgerAccountSubMenu';

export const LedgerAccountInlineConditionMenu = ({
  rule,
  callbackFn,
  onOpenChange = () => {
    return;
  },
  isDisabled,
}) => {
  const {
    data: ledgerAccounts,
    isInitialLoading,
    isLoading,
  } = useLedgerAccount({
    pageSize: 1000,
  });

  const mergedLedgerAccounts = mergeLedgerAccounts(ledgerAccounts);

  const getSelectedLedgerAccount = () => {
    return mergedLedgerAccounts.find((la) => la._id === rule?.value);
  };

  const renderText = () => {
    if (isLoading || isInitialLoading) {
      return <i className='fa fa-spinner fa-spin'></i>;
    }
    const selectedInstance = getSelectedLedgerAccount();
    if (selectedInstance) {
      return formatLedgerAccountName(selectedInstance);
    }
    return 'Select a ledger account';
  };

  return (
    <DropdownMenu.Root onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger
        disabled={isDisabled}
        className='border border-[#c4c4c4] bg-white px-3 py-2 rounded-lg flex items-center max-h-10 !justify-center '
      >
        <div className='mr-2 flex h-full justify-center items-center content-center'>{renderText()}</div>
        <ChevronDownIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent>
          <LedgerAccountSubMenu
            callbackFn={(value) => {
              callbackFn(value);
            }}
          />
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
