import React, { useState, useEffect, useRef, useCallback, LegacyRef } from 'react';
import { DndContext, closestCenter, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { flexRender, getCoreRowModel, SortingState, useReactTable, getExpandedRowModel } from '@tanstack/react-table';
import { defaultColumns } from './default-columns';
import { Table as ShadcnTable, TableBody, TableHeader, TableRow } from '@/shadcn/ui/table';
import { useGetColumnVisibility } from 'src/hooks';
import TanStackTableHeader from './TanStackTableHeader';
import TanStackTableRow from './TanStackTableRow';
import TanStackTableCell from './TanStackTableCell';
import { useTableActionBarContext } from 'src/context';
import { HeaderProps } from '../columns/types';
import { FetchNextPage, TableLoader } from '../table-components';
import { SourceTableGroupDialog } from 'src/components/dashboard/TableGroup';
import { useDensity } from 'src/context/DensityContext';
import { TableProps } from './types';
import { cn } from '@/shadcn/lib/utils';

const DEFAULT_ACTION_BAR_PROPS = {};

const Table = ({
  tableName = '',
  tableGroups,
  setData,
  setTableGroups,
  updateTableGroups,
  tableOptions = {},
  enableRowSelection = true,
  columns = [],
  data = [],
  children,
  onRowClick,
  isLoading,
  isFetchingNextPage = false,
  multiSelectActionBarProps = DEFAULT_ACTION_BAR_PROPS,
  fetchNextPage = () => {},
  hasNextPage = true,
  tableType,
  onSortingChange,
  hideFetchingNextPage = false,
  sectionedHeader = false,
  disableColumnPinning = false,
  panelTableName = '',
}: TableProps) => {
  const { density } = useDensity();
  const isDragEnabled = tableName === 'Source';
  const columnVisibility = useGetColumnVisibility(panelTableName);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState({});
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const openedDialogGroupRowDataRef = useRef({} as any);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 15,
      },
    }),
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  const [, setActiveRow] = useState(null);

  const handleDragStart = (event) => {
    const { active } = event;
    const row: any = table.getRowModel().rows.find((r) => r.id === active.id);
    setActiveRow(row);
  };

  const findItemInData = (data, id) => {
    for (let item of data) {
      if (item._id === id) return { item, parent: null, index: data.indexOf(item) };
      if (item.subRows) {
        for (let subItem of item.subRows) {
          if (subItem._id === id) return { item: subItem, parent: item, index: item.subRows.indexOf(subItem) };
        }
      }
    }
    return { item: null, parent: null, index: -1 };
  };

  const removeItemFromParent = (parent, id) => {
    if (!parent || !parent.subRows) return;
    parent.subRows = parent.subRows.filter((subItem) => subItem._id !== id);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return; // Do nothing if there's no target or no position change
    }

    let newData = [...data]; // Create a shallow copy of data to mutate
    const { item: activeItem, parent: activeParent } = findItemInData(newData, active.id);
    let { item: overItem, parent: overParent } = findItemInData(newData, over.id);

    if (!activeItem) {
      console.error('Invalid active item');
      return; // Safety check to ensure valid items
    }

    // Special case: if the over item is a group but was not found, manually check
    if (!overItem && over.id) {
      const overGroup = newData.find((group) => group.groupId === over.id);
      if (overGroup) {
        overItem = overGroup;
        overParent = null;
      }
    }

    // Remove the active item from its current parent (group or main list)
    if (activeParent) {
      removeItemFromParent(activeParent, activeItem._id);
    } else {
      newData = newData.filter((item) => item._id !== activeItem._id);
    }

    // Handle the case where the over item is a group or empty group
    if (overItem.isGroup) {
      activeItem.groupId = overItem.groupId;
      overItem.subRows = overItem.subRows || [];
      overItem.subRows.push(activeItem);
    } else {
      // Handle moving the item within the main list or from a group to the main list
      if (activeItem.groupId) {
        activeItem.groupId = null;
      }

      if (overParent) {
        // Insert into a group
        const insertIndex = overParent.subRows.findIndex((subItem) => subItem._id === overItem._id);
        overParent.subRows.splice(insertIndex, 0, activeItem);
      } else {
        // Insert into the main list
        const insertIndex = newData.findIndex((item) => item._id === overItem._id);
        newData.splice(insertIndex, 0, activeItem);
      }
    }

    // Ensure the updated groups structure is maintained
    if (setData) setData(newData); // Update state with the new data array

    // Update the backend
    const updatedGroups = newData
      .filter((item) => item.isGroup)
      .map((group) => ({
        _id: group.groupId,
        name: group.name,
        subRows: group.subRows.map((subRow) => subRow._id),
      }));

    if (updateTableGroups) await updateTableGroups({ table: tableName, groups: updatedGroups });
  };

  const table = useReactTable({
    ...tableOptions,
    data: data,
    columns: [...defaultColumns, ...columns],
    state: {
      sorting,
      expanded,
      columnVisibility: {
        select: enableRowSelection,
        ...columnVisibility,
      },
      rowSelection,
    },
    meta: {
      openGroupDialog,
      onOpenGroupDialog: (rowData) => {
        openedDialogGroupRowDataRef.current = rowData;
        setOpenGroupDialog(true);
      },
      onCloseGroupDialog: () => {
        setOpenGroupDialog(false);
      },
    },
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { setActionBarVisibleForTableType, setPropsForTableType, getPropsForTableType } = useTableActionBarContext();

  useEffect(() => {
    setActionBarVisibleForTableType(tableType);
    return;
  }, [tableType]);

  useEffect(() => {
    if (tableType) {
      const prevProps = getPropsForTableType(tableType);
      const selectedRowsLength = table.getSelectedRowModel().flatRows.length;

      if (selectedRowsLength > 0 && prevProps?.selectedRows?.length !== selectedRowsLength)
        setActionBarVisibleForTableType(tableType);

      const selectedFlatRows = table.getSelectedRowModel().flatRows;
      setPropsForTableType(tableType, {
        selectedRows: selectedFlatRows,
        table,
      });
    }
  }, [tableType, rowSelection, table.getSelectedRowModel]);

  useEffect(() => {
    if (tableType) {
      setPropsForTableType(tableType, {
        ...multiSelectActionBarProps,
        disabled: false,
        hidden: false,
        toggleAllRowsSelected: table.toggleAllRowsSelected,
      });
    }
  }, [tableType, multiSelectActionBarProps]);

  useEffect(
    () => () => {
      table.toggleAllRowsSelected(false);
      if (tableType) setPropsForTableType(tableType, { hidden: true, activeFn: table.toggleAllRowsSelected });
    },
    [table.toggleAllPageRowsSelected, tableType],
  );

  const handleScroll = () => {
    const scrollLeft = tableContainerRef.current?.scrollLeft;
    setShowBoxShadow(scrollLeft === 0 ? false : true);
  };

  useEffect(() => {
    onSortingChange && onSortingChange(sorting);
  }, [sorting]);

  const handleGroupUpdate = useCallback(
    async (groupUpdatedData) => {
      if (!setTableGroups || !updateTableGroups) return;

      const groupId = openedDialogGroupRowDataRef.current.groupId;
      if (!groupId || !tableGroups) return;

      const groups = [...tableGroups.groups];
      const groupIndex = groups.findIndex((group) => group._id === groupId);
      if (groupIndex < 0) return;

      const previousState = { ...tableGroups }; // Capture the previous state before any modifications

      if (groupUpdatedData.subRows.length > 0) {
        groups.forEach((group) => {
          if (group._id !== groupId && group.subRows) {
            group.subRows = group.subRows.filter((subRowId) => !groupUpdatedData.subRows.includes(subRowId));
          }
        });
      }

      groups[groupIndex] = { ...groups[groupIndex], ...groupUpdatedData };

      setTableGroups({ groups });

      try {
        await updateTableGroups({
          table: tableName,
          groups,
        });
        setOpenGroupDialog(false);
      } catch (error) {
        console.error('Failed to update groups:', error);
        setTableGroups(previousState);
        setOpenGroupDialog(false);
      }
    },
    [tableGroups, updateTableGroups],
  );

  const handleDissolveGroup = useCallback(
    async (groupId) => {
      if (!setTableGroups || !updateTableGroups) return;
      if (!tableGroups) return;

      const newGroups = tableGroups.groups.filter((group) => group._id !== groupId);

      await updateTableGroups({ table: tableName, groups: newGroups });
      setOpenGroupDialog(false);
    },
    [tableGroups, updateTableGroups],
  );

  const getAllRowIds = (rows) => {
    let ids: any[] = [];
    rows.forEach((row: any) => {
      ids.push(row.original._id);
      if (row.subRows && row.subRows.length > 0) {
        ids = [...ids, ...getAllRowIds(row.subRows)];
      }
    });
    return ids;
  };

  const tableRef: React.RefObject<HTMLTableElement> = useRef<HTMLTableElement>(null); // ref for table
  const infScrollTriggerRef: LegacyRef<HTMLDivElement> = useRef<HTMLDivElement>(null); // ref for scroll trigger

  useEffect(() => {
    const updateScrollTriggerWidth = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.offsetWidth;

        if (infScrollTriggerRef.current) {
          infScrollTriggerRef.current.style.width = `${tableWidth}px`;
        }
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      updateScrollTriggerWidth()
    })

    if (tableRef.current) {
      resizeObserver.observe(tableRef.current)
    }

    window.addEventListener('resize', updateScrollTriggerWidth)

    return () => {
      if (tableRef.current) {
        resizeObserver.unobserve(tableRef.current)
      }
      window.removeEventListener('resize', updateScrollTriggerWidth)
    }

  }, [tableRef?.current?.offsetWidth])

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={getAllRowIds(table.getRowModel().rows)} strategy={verticalListSortingStrategy}>
        <div className="relative">
          <div className='sticky top-0 w-full overflow-x-auto border border-[#C8C8C8] rounded-xl'>
            {children}
            {!isLoading && data.length === 0 ? (
              <div className='min-h-[600px] flex w-full gap-5 py-4 justify-center items-center flex-grow'>
                <p data-cy='table_emptyMessage' className='text-2xl font-medium'>
                  No data found
                </p>
              </div>
            ) : (
              <div
                ref={tableContainerRef}
                onScroll={handleScroll}
                className='relative w-full max-h-[calc(100vh-298px)] overflow-auto pb-20'
              >
                <ShadcnTable ref={tableRef} className='border-separate border-spacing-0'>
                  <TableHeader className="sticky top-0 z-10">
                    {sectionedHeader && (
                      <tr>
                        <th colSpan={5} className='text-center border text-sm font-light'></th>
                        <th colSpan={4} className='text-center border text-sm font-light'>
                          Sold
                        </th>
                        <th colSpan={4} className='text-center border text-sm font-light'>
                          Received
                        </th>
                        <th colSpan={2} className='text-center border text-sm font-light'>
                          Fee
                        </th>
                      </tr>
                    )}

                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id + headerGroup.headers.length}>
                        {headerGroup.headers.map((header: HeaderProps<any, any>) => {
                          const headerContent = header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext());
                          return (
                            <TanStackTableHeader
                              disableColumnPinning={disableColumnPinning}
                              isDraggableTable={isDragEnabled}
                              enableSort={header.column.columnDef.enableSort}
                              setSorting={setSorting}
                              columns={columns}
                              showBoxShadow={showBoxShadow}
                              enableRowSelection={enableRowSelection}
                              header={header}
                              key={header.id}
                              sort={header.column.getIsSorted()}
                              tableContainerHeight={tableContainerRef.current?.offsetHeight}
                            >
                              {headerContent}
                            </TanStackTableHeader>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>

                  <TableBody className={`${density}`} colSpan={columns.length + 2}>
                    {isLoading && (
                      <TableRow>
                        {table.getHeaderGroups()[0].headers.map((header) => (
                          <td key={header.id}>
                            <TableLoader />
                          </td>
                        ))}
                      </TableRow>
                    )}

                    {table.getRowModel().rows.map((row) =>
                      row.original.isGroup || row.original?.subRows?.length > 0 ? (
                        <>
                          <TanStackTableRow
                            isDragEnabled={isDragEnabled}
                            row={row}
                            onRowClick={row.getToggleExpandedHandler()}
                            key={row.original.groupId}
                            isGroup={row.original.isGroup}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TanStackTableCell
                                disableColumnPinning={disableColumnPinning}
                                onRowClick={onRowClick}
                                enableRowSelection={enableRowSelection}
                                showBoxShadow={false}
                                key={cell.column.id}
                                row={row}
                                cell={cell}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TanStackTableCell>
                            ))}
                          </TanStackTableRow>

                          <SourceTableGroupDialog
                            onUpdate={(name: string, selectedRowIds: string[]) =>
                              handleGroupUpdate({ name, subRows: selectedRowIds })
                            }
                            data={data}
                            tableGroupName={openedDialogGroupRowDataRef.current.name}
                            onDissolveGroup={() => handleDissolveGroup(row.original.groupId)}
                            setOpen={setOpenGroupDialog}
                            open={openGroupDialog}
                            selectedSubRowIds={openedDialogGroupRowDataRef.current.subRows?.map(({ _id }) => _id)}
                            isEdit
                          />
                        </>
                      ) : (
                        <TanStackTableRow
                          isDragEnabled={isDragEnabled}
                          row={row}
                          onRowClick={onRowClick}
                          key={row.original._id}
                        >
                          {row.getVisibleCells().map((cell, index) => (
                            <TanStackTableCell
                              disableColumnPinning={disableColumnPinning}
                              onRowClick={onRowClick}
                              enableRowSelection={enableRowSelection}
                              showBoxShadow={showBoxShadow}
                              key={cell.column.id}
                              row={row}
                              cell={cell}
                            >
                              {isDragEnabled && ['checkbox', 'select'].includes(cell.column.id) && (
                                <div className={cn(`${index === 0 ? (row.depth === 1 ? 'pl-5' : 'pl-5') : ''}`)}></div>
                              )}
                              <div className={cn(`${index === 1 && row.depth === 1 ? 'pl-4' : ''}`)}></div>

                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TanStackTableCell>
                          ))}
                        </TanStackTableRow>
                      ),
                    )}
                  </TableBody>
                </ShadcnTable>
                {!hideFetchingNextPage && tableContainerRef?.current && (
                  <div ref={infScrollTriggerRef}>
                  <FetchNextPage
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    data={data}
                    containerRef={tableContainerRef}
                  />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default Table;
