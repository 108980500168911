import React, { forwardRef } from 'react';
import { FilterDropdownProps } from './types';
import { Button } from '../button';
import { MdClose } from 'react-icons/md';
import { motion } from 'framer-motion';

export const getFilterDropdownSectionDataCy = (dataCy?: string) => ({
  searchInput: `${dataCy}__filterDropdown_searchInput`,
  trigger: `${dataCy}__filterDropdown_trigger`,
  container: `${dataCy}__filterDropdown_container`,
  header: `${dataCy}__filterDropdown_header`,
  subHeader: `${dataCy}__filterDropdown_subHeader`,
  bodyContainer: `${dataCy}__filterDropdown_bodyContainer`,
  body: `${dataCy}__filterDropdown_body`,
  closeButton: `${dataCy}__filterDropdown_closeButton`,
  clearAllButton: `${dataCy}__filterDropdown_clearAllButton`,
  selectAllButton: `${dataCy}__filterDropdown_selectAllButton`,
});

export const FilterDropdown = forwardRef(function Component(
  {
    trigger,
    dropdownHeader,
    dropdownSubHeader,
    dropdownBody,
    onSelectAll,
    onClearAll,
    open,
    onClose,
    'data-cy': dataCy,
  }: FilterDropdownProps,
  ref: React.LegacyRef<HTMLDivElement> | null,
) {
  return (
    <div className='relative' ref={ref} data-cy={getFilterDropdownSectionDataCy(dataCy).container}>
      {trigger}
      {open && (
        <motion.div>
          <div className='absolute w-[350px] z-[8] left-0'>
            <div className='flex flex-col divide-y bg-white shadow rounded-lg mt-2 shadow-[0px_12px_24px_0px_rgba(0,0,0,0.15)] bg-white'>
              <div className='px-4 py-2'>
                <div className='flex items-center justify-between'>
                  <span>{dropdownHeader}</span>
                  <Button
                    data-cy={getFilterDropdownSectionDataCy(dataCy).closeButton}
                    leadingIcon={<MdClose className='w-5 h-5' />}
                    onClick={onClose}
                    emphasis='low'
                  />
                </div>
              </div>
              {dropdownSubHeader}
              <div className='grow' data-cy={getFilterDropdownSectionDataCy(dataCy).bodyContainer}>
                {dropdownBody}
              </div>
              {(!!onSelectAll || !!onClearAll) && (
                <div className='px-4 py-2 flex flex-row-reverse gap-x-2'>
                  {!!onClearAll && (
                    <Button
                      onClick={onClearAll}
                      emphasis='low'
                      label='Clear'
                      data-cy={getFilterDropdownSectionDataCy(dataCy).clearAllButton}
                    />
                  )}
                  {!!onSelectAll && (
                    <Button
                      onClick={onSelectAll}
                      emphasis='low'
                      label='Select all'
                      data-cy={getFilterDropdownSectionDataCy(dataCy).selectAllButton}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
});
