import React, { useMemo, useState } from 'react';
import {
  Button,
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  InputLabel,
  LOADER_ICON_SIZE,
  LoaderIcon,
  Modal,
  ModalContent,
  ModalTrigger,
  SelectableCardWithRadio,
} from 'ui';
import {
  useGetAICreditsRechargeOptionsQuery,
  useGetStripeDetailsForOrgQuery,
  useInvalidateQuery,
} from '../../../hooks';
import { useAddAICreditsMutation } from '../../../hooks/http/billing-service/stripe/use-add-ai-credits-mutation';
import toast from 'react-hot-toast';
import { prepareDisplayedOptionsForAddAICreditModal } from '../utils';
import { DisplayedAICreditRechargeOption } from '../types';
import { deriveError } from '../../templates/utils';

const SelectRechargeOption = ({
  onRecharge,
  onCancel,
  disabled = false,
  selectedRecharge,
  onSelectedRechargeChange,
}) => {
  const { data: stripeDetails, isLoading: isLoadingStripeDetails } = useGetStripeDetailsForOrgQuery();
  const { data: aiCreditRechargeProducts, isLoading: isLoadingAICreditRechargeProducts } =
    useGetAICreditsRechargeOptionsQuery();

  const isLoading = isLoadingAICreditRechargeProducts || isLoadingStripeDetails;

  const cadence = stripeDetails?.subscription?.items.data[0]?.price?.recurring?.interval ?? 'month';

  const aiCreditRechargeOptions = useMemo(
    () => prepareDisplayedOptionsForAddAICreditModal(aiCreditRechargeProducts, cadence),
    [aiCreditRechargeProducts, cadence],
  );

  return (
    <ModalContent
      title='Add AI credits'
      description='For every successful Copilot instruction one credit will be subtracted from your remaining credits.'
      actions={[
        {
          label: 'Add credits',
          onClick: onRecharge,
          isLoading,
          disabled,
        },
        { label: 'Cancel', onClick: onCancel, emphasis: 'medium', disabled },
      ]}
    >
      <InputLabel heading='Available plans' />
      {!aiCreditRechargeProducts && isLoading && <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />}
      {aiCreditRechargeOptions && (
        <div className='grid grid-cols-1 gap-4'>
          {aiCreditRechargeOptions.map((option, i) => (
            <SelectableCardWithRadio
              label={
                <span className='flex flex-col items-start'>
                  <span className='text-lg font-semibold'>{option.label} </span>
                  <span>{option.description} </span>
                </span>
              }
              key={i}
              selected={option.id === selectedRecharge?.id}
              onClick={() => onSelectedRechargeChange(option)}
            />
          ))}
        </div>
      )}
    </ModalContent>
  );
};

enum AI_CREDIT_MODAL_STEP {
  SELECT_RECHARGE,
  PROCESSING,
  SUCCESS,
  ERROR,
}

export const AddAICreditsModal = () => {
  const [step, setStep] = useState(AI_CREDIT_MODAL_STEP.SELECT_RECHARGE);
  const [open, setOpen] = useState(false);
  const [selectedRecharge, setSelectedRecharge] = useState<DisplayedAICreditRechargeOption | undefined>();
  const { mutateAsync: initializePaymentFlowForAICreditRecharge } = useAddAICreditsMutation();
  const { invalidateUsage, invalidateStripeInvoices } = useInvalidateQuery();

  const onClose = () => {
    setStep(AI_CREDIT_MODAL_STEP.SELECT_RECHARGE);
    setSelectedRecharge(undefined);
    setOpen(false);
  };

  const payForAICredits = async () => {
    try {
      if (!open) return;
      if (!selectedRecharge) return toast.error('Please select a recharge option');

      setStep(AI_CREDIT_MODAL_STEP.PROCESSING);
      await initializePaymentFlowForAICreditRecharge(selectedRecharge.id);
      setStep(AI_CREDIT_MODAL_STEP.SUCCESS);
      setTimeout(async () => {
        await invalidateUsage();
        await invalidateStripeInvoices();
      }, 3000);
    } catch (error) {
      setStep(AI_CREDIT_MODAL_STEP.SELECT_RECHARGE);
      toast.error(deriveError(error));
    }
  };

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button
          label='Add credits'
          onClick={() => {
            setStep(AI_CREDIT_MODAL_STEP.SELECT_RECHARGE);
            setOpen(true);
          }}
        />
      </ModalTrigger>
      {step === AI_CREDIT_MODAL_STEP.SELECT_RECHARGE && (
        <SelectRechargeOption
          onCancel={onClose}
          onRecharge={payForAICredits}
          selectedRecharge={selectedRecharge}
          onSelectedRechargeChange={setSelectedRecharge}
        />
      )}

      {step === AI_CREDIT_MODAL_STEP.PROCESSING && (
        <ModalContent>
          <DescriptiveLoader
            title='Processing'
            description='Attempting charge on your default payment method'
            inModal
          />
        </ModalContent>
      )}
      {step === AI_CREDIT_MODAL_STEP.SUCCESS && (
        <ModalContent onClose={onClose}>
          <DescriptiveLoader
            inModal
            title='Success'
            description='AI credit recharge successful'
            status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
          />
        </ModalContent>
      )}
    </Modal>
  );
};
