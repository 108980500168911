import React, { useEffect, useState } from 'react';
import { useConditionSelectorContext } from '../../condition-selector-context/ConditionConfiguratorContext';
import { Virtuoso } from 'react-virtuoso';
import { ConditionSelectorRow } from '../../ConditionSelectorRow';
import { FACT } from '../../../types';
import { ConditionsGroup } from '../../ConditionsGroup';
import { useAddCondition } from '../../useAddCondition';

export const AssetTokenConditionsGroup = ({ label, tokens }: { label: string; tokens: string[] }) => {
  const { debouncedSearchTerm } = useConditionSelectorContext();
  const addCondition = useAddCondition();

  const [filtered, setFiltered] = useState(tokens);

  useEffect(() => {
    setFiltered(tokens.filter((value) => value.toLowerCase().includes(debouncedSearchTerm.toLowerCase())));
  }, [debouncedSearchTerm]);

  return (
    <ConditionsGroup label={label}>
      <Virtuoso
        style={{ height: 250 }}
        data={filtered}
        itemContent={(index, token) => (
          <ConditionSelectorRow
            key={index}
            rowData={{ symbol: token.toLowerCase(), label: token }}
            onClick={() => addCondition({ fact: FACT.ASSET, value: token.toLowerCase(), operator: 'equal' })}
          />
        )}
      />
    </ConditionsGroup>
  );
};
