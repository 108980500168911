import { ReactNode, useMemo, useState } from 'react';
import { AllActionBarProps, TABLE_TYPE } from './types';
import { TableActionBarWrapper } from '../../components/dashboard';
import { EditRulesetMultiSelectActionsComponent } from '../../components/rules/EditRuleSet';
import {
  JournalsMultiSelectActionsComponent,
  SourcesMultiSelectActionsComponent,
  TagMultiSelectActionsComponent,
  JETemplatesMultiSelectActionsComponent,
  JELineTemplatesMultiSelectActionsComponent,
} from '../../components/multi-selects';
import { LegalEntityMultiSelectActionsComponent } from '../../components/multi-selects/legalEntity';
import { TransactionsMultiSelectActionsComponent } from '../../components/PageComponents';
import { TableActionBarContext } from './context';
import { SpamTransactionsMultiSelectActionsComponent } from '../../components/multi-selects/spam-transactions';

const renderTableActions = (component, props) => <TableActionBarWrapper component={component} props={props} />;
const renderTableActionBar = (tableType: TABLE_TYPE, props) => {
  try {
    switch (tableType) {
      case TABLE_TYPE.EDIT_ACCOUNT_POSTING_RULESET:
        return renderTableActions(EditRulesetMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_JOURNALS:
        return renderTableActions(JournalsMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_LEGAL_ENTITIES:
        return renderTableActions(LegalEntityMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_SOURCES:
        return renderTableActions(SourcesMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_TAGS:
        return renderTableActions(TagMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_TRANSACTIONS:
        return renderTableActions(TransactionsMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_TEMPLATES:
        return renderTableActions(JELineTemplatesMultiSelectActionsComponent, props);
      case TABLE_TYPE.ALL_JOURNAL_ENTRY_TEMPLATES:
        return renderTableActions(JETemplatesMultiSelectActionsComponent, props);
      case TABLE_TYPE.SPAM_TRANSACTIONS:
        return renderTableActions(SpamTransactionsMultiSelectActionsComponent, props);
      default:
        return null;
    }
  } catch (error) {
    console.error('Could not draw action bar for ', tableType);
    return null;
  }
};

export const TableActionBarProvider = ({ children }: { initialState?: any; children: ReactNode }) => {
  const [allActionBarProps, setAllActionBarProps] = useState<AllActionBarProps>({});

  const setPropsForTableType = (tableType: TABLE_TYPE, tableProps: any, overwrite = false) => {
    setAllActionBarProps((prev) => ({
      ...prev,
      [tableType]: overwrite ? tableProps : prev[tableType] ? { ...prev[tableType], ...tableProps } : tableProps,
    }));
  };

  const getPropsForTableType = (tableType: TABLE_TYPE) => allActionBarProps[tableType];
  const [tableType, setTableType] = useState<TABLE_TYPE | undefined>();

  const actionBar = useMemo(
    () => (tableType ? renderTableActionBar(tableType, allActionBarProps[tableType]) : null),
    [tableType, allActionBarProps],
  );

  return (
    <TableActionBarContext.Provider
      value={{
        actionBarVisibleForTableType: tableType,
        setActionBarVisibleForTableType: setTableType,
        allActionBarProps,
        getPropsForTableType,
        setPropsForTableType,
      }}
    >
      {children}
      {actionBar}
    </TableActionBarContext.Provider>
  );
};
