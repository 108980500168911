import { MdCheck } from 'react-icons/md';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';
import { ChecklistRowProps } from './types';

export const ChecklistRow = ({ isLoading, label }: ChecklistRowProps) => (
  <div className='flex items-center gap-x-4'>
    <span>
      {isLoading ? (
        <LoaderIcon size={LOADER_ICON_SIZE.MEDIUM} />
      ) : (
        <MdCheck className='text-green-500 w-6 w-6 text-xl' />
      )}
    </span>
    <span className='text-xl'>{label}</span>
  </div>
);
