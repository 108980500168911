import React, { useEffect, useMemo, useState } from 'react';
import { Textarea } from '../atoms';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { useUpdateTransaction } from '../../hooks/http';
import { deriveError } from '../templates/utils';
import { Button } from 'ui';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody';
import { toast } from 'react-hot-toast';
import { useInvalidateQuery, useTabState } from '../../hooks';
import { getUpdatedTabSidebarObject } from '../utils';

function AddTransactionMemo({
  onClose = () => {
    return;
  },
  transactionId,
  organizationId,
  savedMemo,
}) {
  const { sidebarState, secondRouteUnStack, updateTabSidebarState, activeTab } = useTabState();
  const [memo, setMemo] = useState(savedMemo ?? '');

  const memoizedTabUpdate = useMemo(() => activeTab?.id, [activeTab?.id]);

  const { invalidateTransactions } = useInvalidateQuery();
  const { mutateAsync, isLoading: isSaving } = useUpdateTransaction();

  useEffect(() => {
    setMemo(secondRouteUnStack?.memo ?? '');
  }, [memoizedTabUpdate]);

  const handleMemoChange = (event) => {
    const updatedTabObject = getUpdatedTabSidebarObject({
      sidebarState,
      keys: ['secondRouteUnStack'],
      memo: event.target.value,
    });
    updateTabSidebarState(updatedTabObject);
    setMemo(event.target.value);
  };

  const handleSave = async () => {
    try {
      await mutateAsync(
        {
          transactionId,
          organizationId,
          body: {
            transaction: {
              memo,
            },
          },
        },
        {
          onSuccess: () => {
            invalidateTransactions();
            onClose();
          },
        },
      );
      toast.success('Transaction updated successfully');
    } catch (error) {
      console.error(error);
      toast.error(deriveError(error));
    }
  };
  return (
    <Sidebar>
      <SidebarTopBar
        onClose={() => {
          onClose();
        }}
      />
      <SidebarHeader title='Add transaction memo' />
      <SidebarBody>
        <SidebarSection numberOfColumns={1}>
          <Textarea value={memo} label='Memo' onChange={handleMemoChange} />
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        secondaryBtn={
          <Button
            onClick={() => {
              onClose && onClose();
            }}
            emphasis='medium'
            label='Cancel'
            disabled={isSaving}
          />
        }
        primaryBtn={
          <Button
            onClick={() => {
              handleSave && handleSave();
            }}
            isLoading={isSaving}
            label='Save'
          />
        }
      />
    </Sidebar>
  );
}

export default AddTransactionMemo;
