import { BiSolidDockRight } from 'react-icons/bi';
import { GoArrowUpRight } from 'react-icons/go';
import { capitalizeFirstLetter, currencyImg, DefaultCell, MultipleActions, StatusIndicator } from 'ui';
import { ColumnsProps } from './types';
import { useContext } from 'react';
import { SidebarGlobalContext, useDensity } from 'src/context';
import { DisplayedJournalEntry } from 'src/components/PageComponents/Journals/type';
import { dateConverter } from 'src/components/utils';
import { LegalEntity, Transaction } from 'schemas';
import { ColumnLeadingCell } from './columnLeadingCell';

export const journalsColumns: ColumnsProps<DisplayedJournalEntry, any>[] = [
  {
    accessorKey: 'journalId',
    header: 'ID',
    cell: ({ getValue, row }) => {
      const { density } = useDensity();
      const { partnerImage, partnerName, chain } = getValue() || {};
      const showBrandedImage = chain || partnerName;

      return (
        <ColumnLeadingCell
          isAvatarVisible={!!showBrandedImage}
          size={density === 'compact' ? 'default' : density === 'default' ? 'large' : 'extra-large'}
          src={currencyImg(getValue()?.chain?.toLowerCase())}
          alt={getValue()?.title}
          type={partnerImage && chain ? 'two-avatars' : 'chain'}
          secondAvatarPosition='bottom'
          secondAvatar={partnerImage}
          initials={getValue()?.chain?.toLowerCase() ?? 'AA'}
          label={capitalizeFirstLetter(getValue()?.title ?? '')}
          isCheckboxVisible={false}
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          isFlipped={partnerImage ? true : false}
          withoutPaddingLeft={false}
        />
      );
    },
    minSize: 200,
  },
  {
    accessorKey: 'accountingDate',
    header: 'Accounting date',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center justify-between'>
          <DefaultCell textAlign={'text-left'} className='px-0 w-fit' label={dateConverter(new Date(getValue()))} />
        </div>
      );
    },
  },
  {
    visibility: false,
    accessorKey: 'legalEntityId',
    header: 'Legal entity',
    cell: ({ getValue, row }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()?.entityName}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('entities', { id: (row.original?.legalEntityId as LegalEntity)._id });
                  },
                  tooltipContent: 'View in panel',
                },

                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/entities/${getValue()?._id ?? ''}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'journalEntryTemplateId',
    header: 'Journal entry template',
    cell: ({ getValue }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);
      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/legal-entity'>
          <DefaultCell
            label={getValue()?.name}
            className='px-0 w-fit group-hover/legal-entity:underline group-hover/legal-entity:underline-offset-4'
          />
          <span className='w-fit h-10 mx-2 absolute right-0 top-1/2 -translate-y-1/2 hidden group-hover/legal-entity:block'>
            <MultipleActions
              btnClassName={btnClassName}
              className='px-0'
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('templates', { id: getValue()?._id });
                  },
                  tooltipContent: 'View in panel',
                },
                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/configure/templates/${getValue()?._id}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'transactionId',
    header: 'Transaction',
    cell: ({ getValue, row }) => {
      const { openSidebar } = useContext(SidebarGlobalContext);

      const { density } = useDensity();
      const btnClassName = {
        compact: 'h-[28px] w-[28px] mt-[6px]',
        default: 'h-[36px] w-[36px] mt-[3px]',
        comfortable: 'h-[40px] w-[40px]',
      }[density];

      const iconClassName = {
        compact: 'min-h-[15px] min-w-[15px]',
        default: 'h-[20px] w-[20px]',
        comfortable: 'w-[20px] h-[20px]',
      }[density];

      return (
        <div className='w-full flex items-center justify-between group/transaction'>
          <DefaultCell
            textAlign={'text-left'}
            label={getValue()?.sequenceNumber}
            className='px-0 w-fit group-hover/transaction:underline group-hover/transaction:underline-offset-4'
          />
          <span className='w-fit h-10 mx-0 absolute right-[-15px] top-1/2 -translate-y-1/2 hidden group-hover/transaction:block'>
            <MultipleActions
              btnClassName={btnClassName}
              actions={[
                {
                  icon: <BiSolidDockRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    openSidebar('transactions', { id: (row.original?.transactionId as Transaction)?._id });
                  },
                  tooltipContent: 'View in panel',
                },

                {
                  icon: <GoArrowUpRight className={iconClassName} />,
                  onClickHandler: (e) => {
                    e.stopPropagation();
                    window.open(`/ledger/transactions/${(row.original?.transactionId as Transaction)?._id}`, '_blank');
                  },
                  tooltipContent: 'Open new window',
                },
              ]}
            />
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Created date',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center justify-between'>
          <DefaultCell textAlign={'text-left'} className='px-0 w-fit' label={dateConverter(new Date(getValue()))} />
        </div>
      );
    },
  },
  {
    accessorKey: 'credits',
    header: 'Credit',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <div className='w-full flex items-center'>
          <DefaultCell textAlign={'text-right'} label={getValue()} />
        </div>
      );
    },
  },
  {
    accessorKey: 'debits',
    header: 'Debit',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return (
        <div className='w-full flex items-center'>
          <DefaultCell textAlign={'text-right'} label={getValue()} />
        </div>
      );
    },
  },
  {
    accessorKey: 'isSync',
    header: 'Synced',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          <StatusIndicator
            label={getValue() ? 'yes' : 'no'}
            type={getValue() ? 'positive' : 'neutral'}
            className='h-fit'
          />
        </div>
      );
    },
  },
];
