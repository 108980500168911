import { createWalletJobs } from 'services/http/core';
import { removeEmpty } from '../templates/utils';
import { dateConverter } from '../utils';
import { Wallet } from 'schemas';
import { Transaction } from 'services/http/response.types';
import Image from 'next/image';

type RecentTransactions = PaginatedTransactions | Transaction[];

type PaginatedTransactions = {
  pages: { transactions: Transaction[] }[];
};

export const importTransactions = async (wallet: Wallet, startDate: string, skipClassification = false) => {
  await createWalletJobs(
    removeEmpty({
      chain: wallet.chain,
      startDate,
      organizationIds: [wallet.organizationId],
      legalEntityIds: wallet.legalEntityId
        ? [typeof wallet.legalEntityId === 'string' ? wallet.legalEntityId : wallet.legalEntityId._id]
        : [],
      walletIds: [wallet._id],
      skipClassification,
    }),
  );
};

export const pullNewTransactions = async (organizationId: string) => {
  return await createWalletJobs(
    removeEmpty({
      organizationIds: [organizationId],
    }),
  );
};

export const getDisplayedTransactions = (recentTransactions: RecentTransactions) => {
  const isPaginated = !!(recentTransactions as PaginatedTransactions).pages as boolean;
  const transactionsToProcess = isPaginated
    ? (recentTransactions as PaginatedTransactions).pages[0].transactions
    : (recentTransactions as Transaction[]) || [];
  const txs = transactionsToProcess.map((transaction) => {
    const sequenceNumber = {
      title: transaction.sequenceNumber,
      desc: dateConverter(transaction.transactionDate),
      chain: transaction.chain,
    };
    const grossAmount = parseFloat(
      transaction.grossPrice.$numberDecimal || (transaction.grossPrice as unknown as string),
    );
    const netAmount = parseFloat(transaction.netPrice.$numberDecimal || (transaction.netPrice as unknown as string));
    const fee = parseFloat(transaction.feePrice.$numberDecimal || (transaction.feePrice as unknown as string));

    const isDebitFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'debit';
    const isCreditFromKraken =
      transaction?.exchangeRefId && transaction?.transactionDirection?.toLowerCase() === 'credit';
    const fromAddress = {
      address: transaction.fromAddress,
      exchange: isDebitFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
    };

    const toAddress = {
      address: transaction.toAddress,
      exchange: isCreditFromKraken ? <img src='/kraken.png' className='w-6 h-6' /> : null,
    };

    const transactionAssets = {
      title: transaction?.assetType,
      assetType: transaction?.assetType,
      isNFT: false,
      img: <></>,
      rawContractAddress: transaction?.rawContractAddress,
    };
    if (transaction?.classification === 'NFT') {
      transactionAssets.img = (
        <Image
          src={transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.image_original_url}
          className='rounded-[100px] h-6 w-6 object-cover border'
          width={24}
          height={24}
          alt={transaction.assetType}
        />
      );
      transactionAssets.isNFT = true;
    }

    return {
      ...transaction,
      sequenceNumber,
      grossAmount: {
        value: grossAmount,
      },
      netAmount: {
        value: netAmount,
      },
      fee: {
        value: fee,
      },
      fromAddress,
      toAddress,
      transactionAssets,
    };
  });

  return txs;
};
