import { useEffect, useState } from 'react';
import { JobConfiguration } from 'schemas';
import { useWebsocketUpdatesForJob } from './use-websocket-update-for-job';
import { useUpdatePollingForJob } from './use-update-polling-for-job';
import { JOB_STATUS } from 'services';

export const useIsTaskComplete = (taskType, referenceId, uniqueId?: string) => {
  const [isComplete, setIsComplete] = useState(false);
  const [jobConfig, setJobConfig] = useState<JobConfiguration>();

  const jobConfigFromWebsocket = useWebsocketUpdatesForJob(referenceId, taskType, uniqueId);
  const jobConfigFromPolling = useUpdatePollingForJob(referenceId, taskType, uniqueId);

  useEffect(() => {
    setJobConfig(jobConfigFromPolling || jobConfigFromWebsocket);
  }, [jobConfigFromWebsocket, jobConfigFromPolling]);

  useEffect(() => {
    if (isComplete) return;
    if (jobConfig && ![JOB_STATUS.STARTED, JOB_STATUS.IN_PROGRESS].includes(jobConfig.jobStatus as JOB_STATUS))
      setIsComplete(true);
  }, [isComplete, jobConfig]);

  return isComplete;
};
