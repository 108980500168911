import { useKBar, useMatches } from 'farish-kbar';
import { MdArrowDownward, MdArrowUpward, MdKeyboardCommandKey, MdKeyboardReturn } from 'react-icons/md';
import { Button } from 'ui';

export const Footer: React.FC = () => {
  const { query } = useKBar();
  const { results } = useMatches();

  const { setActiveIndex } = query;

  const moveUp = () => {
    setActiveIndex((currentIndex) => {
      if (currentIndex === 0) {
        return currentIndex;
      } else {
        return currentIndex - 1;
      }
    });
  };

  const moveDown = () => {
    setActiveIndex((currentIndex) => {
      if (currentIndex === results.length - 1) {
        return currentIndex;
      } else {
        return currentIndex + 1;
      }
    });
  };

  if (results.length === 0) return null;

  return (
    <div className='h-11 mt-2 w-full border-t border-zinc-300 flex px-6 gap-x-4 items-center justify-end rounded-b-[14px]'>
      <div className='gap-x-2 flex items-center'>
        <Button variant='xs' leadingIcon={<MdArrowUpward color='black' />} onClick={moveUp} emphasis='medium' />
        <Button variant='xs' leadingIcon={<MdArrowDownward color='black' />} onClick={moveDown} emphasis='medium' />
        <p className='font-medium'>Navigate</p>
      </div>
      <div className='gap-x-2 flex items-center'>
        <Button variant='xs' leadingIcon={<MdKeyboardReturn />} className='hover:cursor-default' emphasis='medium' />
        <p className='font-medium'>Select</p>
      </div>
      <div className='gap-x-2 flex items-center'>
        <Button variant='xs' leadingIcon={<MdKeyboardCommandKey />} emphasis='medium' onClick={() => query.toggle()} />
        <p className='font-medium'>Quit</p>
      </div>
    </div>
  );
};
