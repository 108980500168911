import React, { useRef, useState } from 'react';
import { MultiValueProps, components } from 'react-select';
import { RoundedCheckbox } from '../../../../assets/generated/svg';
import { multiValueDataProps } from './types';
export const MultiValue = (props: MultiValueProps) => {
  const data: multiValueDataProps = props.data as multiValueDataProps;
  const selectOption: any = props.selectOption;
  const [focusItem, setFocusItem] = useState('');
  const [optionData, setOptionData] = useState<any>(selectOption?.options);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleValueChange = () => {
    optionData.map(({ label }, index) => {
      if (label === data?.label) {
        optionData[index].value = inputRef?.current && inputRef?.current.value;
        setOptionData([...optionData]);
      }
    });
  };

  return (
    <components.MultiValue
      className={focusItem === props?.children ? ' !bg-transparent !border-0 [&_svg]:!hidden' : ''}
      {...props}
    >
      <>
        {data?.edit ? (
          <div className='flex px-1 w-full'>
            <p className='whitespace-nowrap mr-1'>{data.label}:</p>
            <input
              className='bg-transparent border-0 outline-none font-medium text-[#414141] max-w-full'
              value={data.value}
              onChange={handleValueChange}
              ref={inputRef}
              autoFocus
              onFocus={() => setFocusItem(data.label)}
              onBlur={() => setFocusItem('')}
              style={{ width: `${data.value.length ? data.value.length * 7 : 8}px` }}
              onKeyDown={(event) => {
                if (event.keyCode === 13 || event.keyCode === 32) {
                  inputRef.current && inputRef.current.blur();
                }
              }}
            />
          </div>
        ) : (
          <div className='flex px-1 w-fit items-center gap-1.5'>
            <RoundedCheckbox className='w-4 h-4' />
            <p className='whitespace-nowrap mr-1'>{data.label}</p>
          </div>
        )}
      </>
    </components.MultiValue>
  );
};

export default MultiValue;
