import { useEffect } from 'react';
import { useSession } from './useSession';
import { getOrganizationById, unprotectedPaths } from 'services';
import { ONBOARDING_REDIRECT_MAP } from 'schemas';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { SESSION_COOKIE } from 'services/http/axios-interceptor/axios-request-config-factory';
import { LogoutTopic } from 'services/http/axios-interceptor/on-request-error';

export const useRedirectIfOnboardingNotComplete = () => {
  const session = useSession();
  const router = useRouter();

  // redirects the user to onboarding if needed
  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (router.pathname.includes('onboarding')) return;
      if (!session?.user) return;
      const { userId, organizationId, selectedOrganization } = session;
      const { user } = session;
      if (!user || !selectedOrganization || selectedOrganization?.completedOnboarding) return;
      try {
        if (userId && userId.length) {
          console.log('useRedirectIfOnboardingNotComplete', organizationId);
          const response = await getOrganizationById({ organizationId, token: session.token });
          const organization = response.data.organization;
          if (organization?.completedOnboarding) return;

          if (!organizationId?.length && userId && user?.emailVerified) {
            router.replace('/onboarding/new/workspace');
            return;
          }
          // console.log(
          //   'onboarding-status',
          //   organization.completedOnboarding,
          //   organization.onboardingStatus,
          //   ONBOARDING_REDIRECT_MAP[organization.onboardingStatus],
          // );
          if (!organization) throw new Error('No organization found for organizationId: ' + organizationId);
          if (!organization.completedOnboarding) {
            if (!user?.emailVerified) {
              //   console.log(`redirecting to /onboarding/new/short-code`);
              router.push('/onboarding/new/short-code');
              return;
            }
            //   console.log(
            //     'redirecting to onboarding',
            //     ONBOARDING_REDIRECT_MAP[organization.onboardingStatus] ?? ONBOARDING_REDIRECT_MAP.NEW,
            //   );
            router.replace(ONBOARDING_REDIRECT_MAP[organization.onboardingStatus] ?? ONBOARDING_REDIRECT_MAP.NEW);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [router.pathname, session]);

  // logs the user out if session is invalid
  useEffect(() => {
    if (unprotectedPaths.indexOf(router.pathname) >= 0) return;
    const token = getCookie(SESSION_COOKIE.TOKEN)?.toString();
    console.log(`Getting token from useRedirectIfOnboardingNotComplete: ${token}`);
    if (!token?.length) {
      console.log('No token found, logging out');
      LogoutTopic.next(true);
    }
  }, []);
};
