import React from 'react';
import { Sidebar, SidebarBody, SidebarFooter, SidebarTopBar, SidebarRoot } from '../atoms/Sidebar';
import { AddAssetForm } from './AddAssetForm';
import { useState } from 'react';
import { useCreateAsset } from '../../hooks/http/useAssets';
import { deriveError } from '../templates/utils';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody/SidebarSection';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { Button } from 'ui';
import { SidebarSectionHeader } from '../atoms/Sidebar/SidebarBody';
import { toast } from 'react-hot-toast';
import { useSession } from '../../hooks/useSession';
import { useInvalidateQuery } from '../../hooks';

export const AddAssetPanel = ({ onClose, selectedAsset }) => {
  const [parentAsset, setParentAsset] = useState<any>({});
  const { organizationId } = useSession();
  const { mutateAsync: createAsset, isLoading: isCreatingAsset } = useCreateAsset();

  const { invalidateAssets } = useInvalidateQuery();

  const handleSave = async () => {
    try {
      await createAsset(
        {
          ...parentAsset,
          organizationId,
        },
        {
          onSuccess: async () => {
            invalidateAssets();
            toast.success('Asset created');

            onClose();
          },
          onError: (error) => {
            toast.error(deriveError(error));
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SidebarRoot>
      <Sidebar>
        <SidebarTopBar onClose={onClose} />
        <SidebarHeader title='Add asset' />
        <SidebarBody>
          <SidebarSectionHeader title='Details' />
          <SidebarSection numberOfColumns={1}>
            <AddAssetForm setParentAsset={setParentAsset} selectedAsset={selectedAsset} />
          </SidebarSection>
        </SidebarBody>
        <SidebarFooter
          primaryBtn={<Button label='Add asset' emphasis='high' onClick={handleSave} isLoading={isCreatingAsset} />}
          secondaryBtn={<Button label='Close' emphasis='medium' onClick={onClose} />}
        />
      </Sidebar>
    </SidebarRoot>
  );
};
