import { createGlobalStyle } from 'styled-components';
import { media } from './media';

const Grid = createGlobalStyle`
  .container {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    @media ${media.smUp} { 
      padding-left: 32px;
      padding-right: 32px;
    }
    @media ${media.mdUp} { 
      padding-left: 96px;
      padding-right: 96px;
    }
    @media ${media.xlUp} { 
      padding-left: 0;
      padding-right: 0;
    }
  }  
`;

export default Grid;
