import React, { useState } from 'react';
import { Textarea } from '../atoms';
import { Sidebar, SidebarTopBar, SidebarBody, SidebarFooter } from '../atoms/Sidebar';
import { useUpdateJournalEntryTemplate } from '../../hooks/http';
import { Button } from 'ui';
import SidebarHeader from '../atoms/Sidebar/SidebarHeader/SidebarHeader';
import { SidebarSection } from '../atoms/Sidebar/SidebarBody';
import { JournalEntryTemplate } from 'schemas';
import Toast from 'react-hot-toast';
import { useInvalidateQuery } from '../../hooks';

type AddTemplateMemoSidebarProps = {
  onClose?: () => void;
  journalEntryTemplateId: string;
  organizationId: string;
  templateInstance: JournalEntryTemplate;
};

function AddTemplateMemoSidebar({
  onClose = () => {
    return;
  },
  journalEntryTemplateId,
  organizationId,
  templateInstance,
}: AddTemplateMemoSidebarProps) {
  const [memo, setMemo] = useState(templateInstance.memo || '');
  const { invalidateJournalEntryTemplates } = useInvalidateQuery();
  const { mutateAsync: updateAsync, isLoading: isSaving } = useUpdateJournalEntryTemplate();
  const handleMemoChange = (event) => {
    setMemo(event.target.value);
  };
  const handleSave = async () => {
    try {
      await updateAsync(
        {
          journalEntryTemplateId: journalEntryTemplateId,
          body: {
            memo,
            currency: templateInstance.currency,
            name: templateInstance.name,
            externalReference: templateInstance.externalReference,
            status: templateInstance.status,
            organizationId,
          },
        },
        {
          onSuccess: () => {
            invalidateJournalEntryTemplates();
            onClose();
          },
        },
      );
      Toast.success('Memo updated.');
    } catch (error) {
      console.error(error);
      Toast.error('Error updating memo.');
    }
  };
  return (
    <Sidebar>
      <SidebarTopBar
        onClose={() => {
          onClose();
        }}
      />
      <SidebarHeader title='Add template memo' />
      <SidebarBody>
        <SidebarSection numberOfColumns={1}>
          <Textarea value={memo} label='Memo' onChange={handleMemoChange} />
        </SidebarSection>
      </SidebarBody>
      <SidebarFooter
        secondaryBtn={
          <Button
            onClick={() => {
              onClose && onClose();
            }}
            emphasis='medium'
            label='Cancel'
            disabled={isSaving}
          />
        }
        primaryBtn={
          <Button
            onClick={() => {
              handleSave && handleSave();
            }}
            isLoading={isSaving}
            label='Save'
          />
        }
      />
    </Sidebar>
  );
}

export default AddTemplateMemoSidebar;
