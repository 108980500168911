import { Button } from '../button';
import { MdAdd } from 'react-icons/md';
import { IntegrationCardProps } from './types';
import { LoaderIcon } from '../loader-icon';
import { StatusIndicator } from '../status-indicator';
import Link from 'next/link';
import { IntegrationCardDescriptionStyle } from './style';

export const getSelectorsForIntegrationCard = (dataCy?: string) => ({
  heading: `${dataCy}__integrationCard_heading`,
  connectButton: `${dataCy}__integrationCard_connect_button`,
});

export const IntegrationCard = ({
  icon,
  heading,
  description,
  isLive,
  isConnectDisabled,
  isConnectLoading,
  onConnectUrl,
  onConnect,
  onViewDetails,
  'data-cy': dataCy,
}: IntegrationCardProps) => {
  return (
    <div className='border rounded-lg bg-white w-full leading-5'>
      <div className='h-full flex flex-col justify-between gap-6 pt-4 pb-6'>
        <div>
          <div className='flex items-center mx-5 mb-6'>
            <span className='mr-2'>{icon}</span>
            <span className='text-[20px] font-medium' data-cy={getSelectorsForIntegrationCard(dataCy).heading}>
              {heading}
            </span>
          </div>
          <IntegrationCardDescriptionStyle className='mx-5'>{description}</IntegrationCardDescriptionStyle>
        </div>
        <div className='flex items-center mx-5'>
          {isConnectLoading ? (
            <span className='flex-grow'>
              <Button disabled emphasis='medium' leadingIcon={<LoaderIcon />} variant='md' label='Loading' />
            </span>
          ) : (
            <>
              {!isLive && onConnectUrl && (
                <Link href={onConnectUrl ?? ''} passHref>
                  <Button
                    data-cy={getSelectorsForIntegrationCard(dataCy).connectButton}
                    disabled={isConnectDisabled}
                    leadingIcon={<MdAdd className='w-5 h-5' />}
                    variant='md'
                    emphasis='medium'
                    label='Connect'
                  />
                </Link>
              )}
              {!isLive && !onConnectUrl && (
                <Button
                  disabled={isConnectDisabled}
                  onClick={onConnect}
                  leadingIcon={<MdAdd className='w-5 h-5' />}
                  variant='md'
                  emphasis='medium'
                  label='Connect'
                />
              )}

              {isLive && (
                <>
                  <span className='flex-grow'>
                    <Button
                      disabled={isConnectDisabled}
                      onClick={onViewDetails}
                      variant='md'
                      emphasis='medium'
                      label='View details'
                    />
                  </span>
                  <span>
                    <StatusIndicator className='h-fit' label='Connected' type='positive' />
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
