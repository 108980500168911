import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const updateConditionGroup = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    draft.conditionGroups[action.payload.id] = {
      ...draft.conditionGroups[action.payload.id],
      ...action.payload.update,
    };
  });
