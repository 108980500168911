import axios from 'axios';
import { PatchLEPayload, PostLEPayload } from '../response.types';
import { SERVER_URL_CORE } from '../../config';
import { prepareSearchParams } from './utils';
import { convertQuery } from '../utils';
import { AccountingPeriod, LegalEntity } from 'schemas';

export const createLegalEntity = async (data: PostLEPayload) => axios.post(`${SERVER_URL_CORE}/legal-entity`, data);

export const patchLegalEntity = async (data: PatchLEPayload) =>
  axios.patch(`${SERVER_URL_CORE}/legal-entity?legalEntityId=${data._id}`, data);

export const deleteLegalEntity = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/legal-entity?legalEntityId=${data.legalEntityId}&organizationId=${data.organizationId}`,
  );

export const getLegalEntity = async (data) => {
  return axios.get(
    `${SERVER_URL_CORE}/legal-entity?${prepareSearchParams(data, {
      defaultSortFilterPropertyName: data.sort.id,
    })}`,
  );
};
export interface LegalEntityInfo {
  legalEntity: LegalEntity;
  openPeriods: AccountingPeriod[];
  status: string;
  nextScheduledClose?: string;
}

export const getLegalEntityWiseAccountingPeriods = async (data) => {
  return axios.get<{ legalEntities: LegalEntityInfo[] }>(
    `${SERVER_URL_CORE}/legal-entity-wise-accounting-periods?${prepareSearchParams(data, {
      isPaginated: true,
      noSortFilter: true,
    })}`,
  );
};

export const bulkArchiveLegalEntity = async (data) =>
  axios.patch(
    `${SERVER_URL_CORE}/bulk-archive-legal-entity?organizationId=${data.organizationId}${convertQuery(
      data.legalEntityIds,
      'legalEntityIds',
    )}`,
  );

export const bulkDeleteLegalEntity = async (data) =>
  axios.delete(
    `${SERVER_URL_CORE}/bulk-delete-legal-entity?organizationId=${data.organizationId}${convertQuery(
      data.legalEntityIds,
      'legalEntityIds',
    )}`,
  );
