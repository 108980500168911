import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

export const useMixpanelPageViewTracker = (pageName: string) => {
  useEffect(() => {
    if (window) mixpanelTrack(pageName);
  }, [pageName]);
};

export const mixpanelTrack = (pageName: string) => {
  try {
    if (window) mixpanel.track(pageName, { url: window.location.href });
  } catch (error) {
    console.error('Mixpanel track event failure: ', error);
  }
};

export const mixpanelTimeEvent = (eventName: string) => {
  try {
    if (window) mixpanel.time_event(eventName);
  } catch (error) {
    console.error('Mixpanel time_event failure: ', error);
  }
};

export const mixpanelPeopleSet = (params) => {
  try {
    if (window) mixpanel.people.set(params);
  } catch (error) {
    console.error('Mixpanel people.set failure: ', error);
  }
};

export const mixpanelPeopleIncrement = (toIncrement: string, incrementBy: number) => {
  try {
    if (window) mixpanel.people.increment(toIncrement, incrementBy);
  } catch (error) {
    console.error('Mixpanel people.increment failure: ', error);
  }
};

export const mixpanelInit = (token) => {
  try {
    if (window) mixpanel.init(token, { ip: true });
  } catch (error) {
    console.error('Mixpanel init failure: ', error);
  }
};

export const mixpanelIdentify = async (userId: string) => {
  try {
    if (window) await mixpanel.identify(userId);
  } catch (error) {
    console.error('Mixpanel identify failure: ', error);
  }
};

export const mixpanelReset = () => {
  try {
    if (window) mixpanel.reset();
  } catch (error) {
    console.error('Mixpanel reset failure: ', error);
  }
};

export const mixpanelAddGroup = (groupKey: string, groupId: string) => {
  try {
    if (window) mixpanel.add_group(groupKey, groupId);
  } catch (error) {
    console.error('Mixpanel reset failure: ', error);
  }
};
