import { useMemo } from 'react';
import { FILTER_TYPE, StringFilter } from '../../../components/filters';
import { FilterContextParams } from '../types';
import { useFilterState } from '../use-filters';
import { TRANSACTION_CLASSIFICATIONS } from 'src/components/rules/configurator/constants';

export type ClassificationFilterContextType = FilterContextParams<StringFilter>;

const classificationTypes = TRANSACTION_CLASSIFICATIONS

const defaultFilterState = classificationTypes.map((value) => ({
  value,
  selected: false,
  label: value.replace("_", " ")
}));
export const useClassificationFilterState = (page: string) => {
  const { state, setState } = useFilterState<StringFilter>(FILTER_TYPE.CLASSIFICATION, page, defaultFilterState);

  const hasSelections = useMemo(() => state.filter((option) => option.selected).length > 0, [state]);

  return {
    state,
    setState,
    hasSelections,
  };
};
