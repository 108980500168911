import { useRuleConfigurator } from '../rule-configurator-context/RuleConfiguratorContext';
import { useConditionSelectorContext } from './condition-selector-context';

export const useAddCondition = () => {
  const { addCondition, addNestedCondition } = useRuleConfigurator();
  const { state, closeSelector, addNewAsNested } = useConditionSelectorContext();

  const add = (conditionData) => {
    if (addNewAsNested) addNestedCondition({ ...conditionData, parentId: state.parentId });
    else addCondition({ ...conditionData, parentId: state.parentId });
    closeSelector();
  };
  return add;
};
