import React from 'react';
import { DetailsItem, SidebarSection, SidebarSectionHeader } from '../../../atoms';
import { useTabState } from '../../../../hooks';

const Memo = ({ transaction, isLoading, isPrimary }) => {
  const { secondRouteStack, updateTabSidebarState } = useTabState();

  return (
    <>
      <SidebarSectionHeader
        title='Memo'
        actions={[
          {
            label: transaction?.memo ? 'Update Memo' : 'Add Memo',
            onClick: () => {
              const updatedTabQuery = {
                secondRouteStack: isPrimary ? [] : secondRouteStack,
                secondRouteDockPanel: false,
                secondRouteUnStack: {
                  showAddTransactionMemo: true,
                  memo: '',
                },
              };
              updateTabSidebarState(updatedTabQuery);
            },
            variant: 'tertiary',
          },
        ]}
      />
      <SidebarSection loading={isLoading} numberOfColumns={1}>
        <DetailsItem tag='Memo' value={transaction?.memo} />
      </SidebarSection>
    </>
  );
};

export default Memo;
