import React, { useContext, useState } from 'react';
import { TableWrapper } from '../../dashboard';
import { SearchInput } from 'ui';
import { SidebarGlobalContext } from '../../../context/SidebarGlobalProvider';
import { mapTableKeyToOriginalKey } from 'src/components/utils';
import Table from 'src/components/tables/tanstack-table/Table';
import { assetsColumns } from 'src/components/tables';
import { EditColumns } from 'src/components/tables/table-components';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { DensityDropdown } from 'src/components/tables/table-components';

export const AssetsTable = ({
  displayedAssets,
  searchTerm,
  setSearchTerm,
  isSearching,
  setSortState,
  setShowCreateAsset,
  fetchNextPage,
  isLoading,
  isFetchingNextPage,
}) => {
  const { openSidebar } = useContext(SidebarGlobalContext);
  const [columns, setColumns] = useState<ColumnsProps<any, any>[]>(assetsColumns);

  const handleColumnsUpdate = (updatedColumns: ColumnsProps<any, any>[]) => {
    setColumns(updatedColumns);
  };

  return (
    <TableWrapper>
      <Table
        onRowClick={(row) => {
          setShowCreateAsset(false);
          openSidebar('assets', { id: row.original._id });
        }}
        columns={columns}
        data={displayedAssets}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        onSortingChange={(state) => {
          if (state.length === 0) return setSortState([]);
          setSortState([{ id: mapTableKeyToOriginalKey(state[0].id, 'Assets'), desc: state[0].desc }]);
        }}
      >
        <div className='flex items-center justify-between gap-3 px-6 py-5 md:gap-0 w-full'>
          <div className='flex items-center gap-3'>
            <SearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              loading={isSearching}
              placeholder='Search assets...'
              className='placeholder:text-zinc-500 '
              containerClassName=' min-w-0 '
              resultCount={displayedAssets?.length}
            />
          </div>
          <div className='flex gap-3'>
            <EditColumns onColumnsUpdate={handleColumnsUpdate} columns={columns} />
            <DensityDropdown />
          </div>
        </div>
      </Table>
    </TableWrapper>
  );
};

export default AssetsTable;
