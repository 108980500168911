import { Button, EditableInput, classNames } from 'ui';
import { ResponseContainerProps } from './types';
import { useAskAI } from '../../hooks/ai-everything';
import { useCommandPallete } from './useCommandPallete';
import { MdRefresh } from 'react-icons/md';
const ResponseContainer = ({
  children,
  query,
  isForm = false,
  className,
  error,
  newQuery = false, // is this response container for a new query? If so do not show edit or retry
}: ResponseContainerProps) => {
  const { setIsLoading, setInstruction } = useCommandPallete();
  const { handleAskAI } = useAskAI({
    callback: () => {
      setIsLoading(true);
    },
  });
  if (error) return <div>{error}</div>;

  if (query) {
    return (
      <div className='flex justify-end'>
        <div
          className={classNames(
            'py-2 px-3 border rounded-md bg-white border-[#e4e4e7] font-medium shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] w-fit',
            query && 'bg-indigo-500 border-indigo-600 text-white',
            isForm && 'bg-gray-100 border-gray-300',
            className,
          )}
        >
          {newQuery ? (
            children
          ) : (
            <EditableInput
              initialValue={query}
              onApiCall={async (value) => {
                setInstruction(value);
                await handleAskAI(value);
              }}
            />
          )}
        </div>
        <Button
          onClick={async () => {
            setInstruction(query);
            await handleAskAI(query);
          }}
          className='ml-4'
          leadingIcon={<MdRefresh />}
          emphasis='low'
          status='neutral'
        />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        'py-2 px-3 border rounded-md bg-white border-[#e4e4e7] font-medium shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] break-keep',
        query && 'bg-indigo-500 border-indigo-600 text-white',
        className,
      )}
    >
      <div className='text-wrap whitespace-pre'>{children}</div>
    </div>
  );
};

const ChatLoader = () => {
  return (
    <ResponseContainer className='mt-4 h-10 flex flex-col justify-center w-fit'>
      <div className='flex items-center gap-1 '>
        <span className='w-2.5 h-2.5 bg-indigo-600 rounded-full animate-[dot1_3s_infinite_ease-in-out]'></span>
        <span className='w-2.5 h-2.5 bg-[#A5B4FC] rounded-full animate-[dot2_3s_infinite_ease-in-out]'></span>
        <span className='w-2.5 h-2.5 bg-[#C7D2FE] rounded-full animate-[dot3_3s_infinite_ease-in-out]'></span>
      </div>
    </ResponseContainer>
  );
};

export { ResponseContainer, ChatLoader };
