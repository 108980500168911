import { ACCOUNTING_TREATMENT_ROW, CLASSIFICATION_ROW, FILTER_TYPE, SPAM_TOKEN_ROW } from '../../components/filters';

export const TRANSACTION_PAGE_FILTERS = [
  FILTER_TYPE.LEGAL_ENTITY,
  FILTER_TYPE.ACCOUNTING_PERIOD,
  FILTER_TYPE.CHAIN,
  FILTER_TYPE.CLASSIFICATION,
  FILTER_TYPE.DIRECTION,
  FILTER_TYPE.ASSET,
  FILTER_TYPE.WALLET,
  FILTER_TYPE.DATE,
  FILTER_TYPE.ACCOUNTING_TREATMENT,
  FILTER_TYPE.SPAM_TOKEN,
];

export const MARKED_AS_SPAM_PAGE_FILTERS = [
  FILTER_TYPE.LEGAL_ENTITY,
  FILTER_TYPE.ACCOUNTING_PERIOD,
  FILTER_TYPE.CHAIN,
  FILTER_TYPE.ASSET,
  FILTER_TYPE.DATE,
];

export const ACCOUNTING_TREATMENT_ROWS = Object.values(ACCOUNTING_TREATMENT_ROW);
export const SPAM_TOKEN_ROWS = Object.values(SPAM_TOKEN_ROW);
export const CLASSIFICATION_ROWS = Object.values(CLASSIFICATION_ROW);
