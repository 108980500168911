import { BarChart, Metric, Text } from '@tremor/react';
import {
  Button,
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownGroupBody,
  DropdownTitleWithToggle,
  DropdownTrigger,
  TremorCard,
  classNames,
} from 'ui';
import { useTransactionsVolumeByMonth } from '../../hooks/http/useTransactionsVolumeByMonth';
import { useEffect, useState } from 'react';
import { AccountingPeriod } from 'services/http/response.types';
import { useAccountingPeriods } from '../../hooks';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown } from 'react-icons/md';

export const TransactionsByMonthWidget = () => {
  const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [selectedPeriods, setSelectedPeriods] = useState<AccountingPeriod[]>([]);
  const [categoryClicked, setCategoryClicked] = useState<'Txns-in' | 'Txns-out' | null>(null);

  const { data, isLoading } = useTransactionsVolumeByMonth({
    accountingPeriodIds: selectedPeriods.map((ap) => ap._id),
  });

  const { data: accountingPeriodData } = useAccountingPeriods();
  const accountingPeriods = accountingPeriodData?.pages[0].accountingPeriods || [];

  useEffect(() => {
    if (accountingPeriods.length > 0) {
      setSelectedPeriods(accountingPeriods.slice(0, 3));
    }
  }, [accountingPeriods]);

  const chartData =
    data?.transactionVolumeByMonth.map((volumeByMonth) => {
      return {
        month: volumeByMonth.accountingPeriod.accountingPeriodName || '',
        'Txns-in':
          volumeByMonth.transactionsVolume.find((volumeByDirection) => volumeByDirection._id === 'Debit')?.volume || 0,
        'Txns-out':
          volumeByMonth.transactionsVolume.find((volumeByDirection) => volumeByDirection._id === 'Credit')?.volume || 0,
      };
    }) || [];

  const totalVolume = chartData.reduce((acc, curr) => acc + curr['Txns-in'] + curr['Txns-out'], 0);
  const totalTransactionsIn = chartData.reduce((acc, curr) => acc + curr['Txns-in'], 0);
  const totalTransactionsOut = chartData.reduce((acc, curr) => acc + curr['Txns-out'], 0);

  const totalTransactionsToShow = !categoryClicked
    ? totalVolume
    : categoryClicked === 'Txns-in'
      ? totalTransactionsIn
      : totalTransactionsOut;

  const onClearSelectedPeriods = () => {
    setSelectedPeriods([]);
  };

  const onSelectAllPeriods = () => {
    setSelectedPeriods(accountingPeriods);
  };

  return (
    <TremorCard>
      {isLoading ? (
        <div className='h-[300px] animate-pulse bg-gray-50' />
      ) : (
        <>
          <div className='mb-4 flex justify-between'>
            <div>
              <Text>Transactions by month</Text>
              {chartData.length > 0 && <Metric>{totalTransactionsToShow}</Metric>}
            </div>
            <Dropdown open={monthDropdownOpen} onOpenChange={setMonthDropdownOpen}>
              <DropdownTrigger>
                <span>
                  <Button
                    label={
                      selectedPeriods.length === 0 ? (
                        'Select accounting periods'
                      ) : (
                        <p className='text-indigo-600'>
                          {selectedPeriods[0].accountingPeriodName + ' '}
                          <span>{selectedPeriods.length > 1 ? selectedPeriods.length + '+' : ''}</span>
                        </p>
                      )
                    }
                    className={classNames(
                      'duration-100 py-0',
                      selectedPeriods.length > 0 && 'shadow',
                      selectedPeriods.length > 0 && 'bg-indigo-50 border-indigo-600',
                    )}
                    labelContainerClassname={classNames(
                      'font-medium ',
                      selectedPeriods.length > 0 && 'text-indigo-600',
                    )}
                    emphasis='medium'
                    trailingIconContainerClassname={classNames(
                      selectedPeriods.length > 0 && 'mr-0 p-2 duration-100 bg-indigo-50 text-blue-500 rounded-r-lg',
                      selectedPeriods.length > 0 && 'bg-indigo-50',
                    )}
                    trailingIcon={
                      <div className='flex items-center'>
                        {selectedPeriods.length > 0 && (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              onClearSelectedPeriods();
                            }}
                          >
                            <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                          </span>
                        )}

                        <MdArrowDropDown
                          className={classNames('duration-300 w-6 h-6 text-black', monthDropdownOpen && 'rotate-180')}
                        />
                      </div>
                    }
                  />
                </span>
              </DropdownTrigger>
              <DropdownContent>
                <DropdownBody>
                  <DropdownTitleWithToggle
                    title='Select all'
                    checked={
                      selectedPeriods.length === accountingPeriods.length &&
                      accountingPeriods.every((value, index) => value === selectedPeriods[index])
                    }
                    onChange={(checked: boolean) => (checked ? onSelectAllPeriods() : onClearSelectedPeriods())}
                  />
                  <DropdownGroupBody>
                    {accountingPeriods.map((accountingPeriod) => (
                      <CheckboxListItem
                        enableSelectOnly={false}
                        key={accountingPeriod.accountingPeriodName || ''}
                        label={accountingPeriod.accountingPeriodName || ''}
                        checked={selectedPeriods.includes(accountingPeriod)}
                        onCheckedChange={(selected: boolean) => {
                          if (selected) {
                            setSelectedPeriods((prev) => [...prev, accountingPeriod]);
                          } else {
                            setSelectedPeriods((prev) => prev.filter((selected) => selected !== accountingPeriod));
                          }
                        }}
                      />
                    ))}
                  </DropdownGroupBody>
                </DropdownBody>
              </DropdownContent>
            </Dropdown>
          </div>
          <BarChart
            data={chartData}
            categories={['Txns-in', 'Txns-out']}
            index='month'
            showLegend={true}
            showXAxis={false}
            onValueChange={(v) => setCategoryClicked(v?.categoryClicked as 'Txns-in' | 'Txns-out')}
          />
        </>
      )}
    </TremorCard>
  );
};
