import { DefaultCell, TwoLineAvatar, StatusIndicator } from 'ui';
import { ColumnsProps } from 'src/components/tables/columns/types';
import { formatUnderscoreText, handleTags } from 'src/components/templates/utils';

export const templateLinesColumns: ColumnsProps<any, any>[] = [
  {
    accessorKey: 'ledgerAccount',
    header: 'Ledger account',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
  {
    accessorKey: 'creditOrDebit',
    header: 'Type',
    cell: ({ getValue }) => {
      return (
        <div className='flex justify-center items-center'>
          {!!getValue()?.length && (
            <StatusIndicator label={getValue().toLowerCase()} type={getValue().toLowerCase()} className='h-fit' />
          )}
        </div>
      );
    },
  },
  {
    accessorKey: 'allocation',
    header: 'Allocation',
    textAligned: 'text-right',
    cell: ({ getValue }) => {
      return <TwoLineAvatar label={getValue()} isAvatarVisible={false} textAlign={'text-right'} />;
    },
  },
  {
    accessorKey: 'amountType',
    header: 'Amount type',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={formatUnderscoreText(getValue())} />;
    },
  },
  {
    accessorKey: 'tags',
    header: 'Tags',
    cell: ({ getValue }) => {
      return (
        <div className='flex items-center'>
          <DefaultCell textAlign={'text-left'} label={handleTags(getValue())} />
        </div>
      );
    },
  },
  {
    accessorKey: 'memo',
    header: 'Memo',
    cell: ({ getValue }) => {
      return <DefaultCell textAlign={'text-left'} label={getValue()} />;
    },
  },
];
