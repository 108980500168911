import React from 'react';

interface SidebarFooterProps {
  children?: React.ReactNode;
  primaryBtn?: React.ReactNode;
  secondaryBtn?: React.ReactNode;
  destructiveBtn?: React.ReactNode;
}

export function SidebarFooter({ primaryBtn, secondaryBtn, destructiveBtn, children }: SidebarFooterProps): JSX.Element {
  return (
    <div className='flex bg-white justify-between items-center py-4 px-6 border-t gap-2'>
      <div className='w-full'>{destructiveBtn}</div>
      <div className='flex gap-2'>
        {secondaryBtn}
        {primaryBtn}
      </div>
      {children}
    </div>
  );
}

export default SidebarFooter;
