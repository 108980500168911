import { produce } from 'immer';
import { RuleConfiguratorState } from '../../types';

export const removeConditionGroup = (state: RuleConfiguratorState, action) =>
  produce(state, (draft) => {
    const parentId = draft.conditionGroups[action.payload.id]?.parentId;
    if (parentId && draft.conditionGroups[parentId]) {
      const index = draft.conditionGroups[parentId].childIds.findIndex((id) => id === action.payload.id);
      draft.conditionGroups[parentId].childIds = draft.conditionGroups[parentId].childIds.filter(
        (id) => id !== action.payload.id,
      );
      draft.conditionGroups[parentId].gates.splice(index, 1);
    }
    delete draft.conditionGroups[action.payload.id];
    draft.conditionGroupIds = draft.conditionGroupIds.filter((id) => id !== action.payload.id);
  });
