import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import '../css/index.css'; // Tailwind
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import '../css/globals.css'; // Global styles
import { SessionProvider } from '../context';
import { IntercomProvider } from 'react-use-intercom';
import { useReadLocalStorage } from 'usehooks-ts';
import ThemeProvider from '../components/theme-provider';
import { mixpanelIdentify, mixpanelInit } from '../lib/utils/mixpanel';

if (!process.browser) React.useLayoutEffect = React.useEffect;
export default function app({ Component, pageProps }) {
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false);
  const mixpanelToken =
    process.env.NEXT_PUBLIC_VERCEL_STAGE === 'production'
      ? '571f5390e8258ac8b401fc20d68ad4e3'
      : '5dc8de751ae568ee7119b792d58f86af';

  useEffect(() => {
    if (!mixpanelInitialized) {
      mixpanelInit(mixpanelToken);
      setMixpanelInitialized(true);
    }
  }, [mixpanelInitialized]);

  const session = useReadLocalStorage('session');
  useEffect(() => {
    if (session?.userId?.length) {
      mixpanelIdentify(session.userId);
    }
  }, [session]);

  const getLayout = Component.getLayout || ((page) => <Layout initialData={pageProps?.initialData}>{page}</Layout>);

  return (
    <ThemeProvider attribute='class' defaultTheme='light' enableSystem disableTransitionOnChange>
      <SessionProvider>
        <IntercomProvider appId='rthtzuyc'>
          {getLayout(
            <>
              <Component {...pageProps} />
            </>,
          )}
        </IntercomProvider>
      </SessionProvider>
    </ThemeProvider>
  );
}
