import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import { Button, InputLabel, InputTrailingAction, InputWithExtras, Modal, ModalContent, ModalTrigger } from 'ui';
import { useApplyDiscountCodeToStripeSubscriptionMutation } from '../../../hooks';
import { withToasts } from '../utils';
import toast from 'react-hot-toast';

export const AddDiscountModal = () => {
  const [open, setOpen] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const onClear = () => setDiscountCode('');

  const { mutateAsync: applyDiscountCode } = useApplyDiscountCodeToStripeSubscriptionMutation();

  const onAddDiscountClick = withToasts(
    async () => {
      if (!discountCode || discountCode.length === 0) {
        toast.error('Please enter a discount code');
        return true;
      }
      await applyDiscountCode({ promotionCode: discountCode });
      setOpen(false);
    },
    {
      loading: 'Processing',
      success: 'Discount code applied',
    },
  );

  return (
    <Modal open={open} onOpenChange={setOpen}>
      <ModalTrigger asChild>
        <Button label='Add Discount' onClick={() => setOpen(true)} emphasis='medium' />
      </ModalTrigger>
      <ModalContent
        title='Add discount'
        actions={[
          { label: 'Add discount', onClick: onAddDiscountClick },
          { label: 'Cancel', emphasis: 'medium', onClick: () => setOpen(false) },
        ]}
      >
        <InputLabel heading='Discount code' />
        <InputWithExtras
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          trailing={<InputTrailingAction icon={<MdCancel className='w-5 h-5' />} onClick={onClear} />}
        />
      </ModalContent>
    </Modal>
  );
};
