import React from 'react';
import { classNames } from '../utils';
import { InputTrailingActionProps } from './types';

export const InputTrailingAction = ({ icon, onClick, disabled, className = '' }: InputTrailingActionProps) => (
  <button
    className={classNames('text-[#808080] hover:text-black', disabled && '!text-[#808080] opacity-50', className)}
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
  </button>
);
