import React, { useState } from 'react';
import { XIcon } from '../../../assets/generated/svg';
import { Button } from '../Button';
import { Container } from '../../../styles/ModalSwitchStyle';
import { ModalSwitchProps } from './types';

function ModalSwitch({
  title,
  rightText,
  leftText,
  rightComponent,
  leftComponent,
  loading = false,
  primaryBtnText = 'save',
  onClose,
  handleSave,
  className = '',
}: ModalSwitchProps) {
  const [showRightComponent, setShowRightComponent] = useState(false);

  return (
    <Container className={`max-h-[calc(100vh-20px)] overflow-auto ${className}`}>
      <div>
        <div className='w-full border-b py-4 px-6 text-xl font-medium flex items-center justify-between'>
          <p className='text-2xl font-semibold'>{title}</p>
          <Button onClick={onClose} className='!border-0' variant='icon'>
            <XIcon />
          </Button>
        </div>
        <div className='py-4 px-6'>
          <div className='w-full flex gap-x-2 mx-auto mb-2 border-2 p-1 rounded-lg'>
            <Button
              onClick={() => setShowRightComponent(false)}
              classNameContainer='!w-6/12'
              className={`${showRightComponent && '!border-0'} !w-full`}
              variant={`${showRightComponent ? 'tertiary' : 'primary'}`}
            >
              <p>{leftText}</p>
            </Button>
            <Button
              onClick={() => setShowRightComponent(true)}
              classNameContainer='!w-6/12'
              className={`${!showRightComponent && '!border-0'} !w-full`}
              variant={`${showRightComponent ? 'primary' : 'tertiary'}`}
            >
              <p>{rightText}</p>
            </Button>
          </div>
        </div>
        <div className='px-6 overflow-x-auto'>{showRightComponent ? rightComponent : leftComponent}</div>
      </div>
      <div className='flex justify-end gap-x-2 items-center py-4 px-6 border-t'>
        <Button onClick={onClose} variant='tertiary'>
          Cancel
        </Button>
        <Button onClick={handleSave} variant='primary' className={loading ? 'loading' : ''} disabled={loading}>
          {primaryBtnText}
        </Button>
      </div>
    </Container>
  );
}

export default ModalSwitch;
