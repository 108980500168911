import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'styled-components';

export const getCommonPinningStyles = (
  column: Column<any>,
  enableRowSelection: boolean,
  showBoxShadow = false,
  wideCell = false,
  disableColumnPinning = false,
): {
  style: CSSProperties;
  divStyle: CSSProperties;
} => {
  const saveBoxShadow = 'rgba(0, 0, 0, 0.03) 10px 5px 15px -2px';

  let isPinned = false;
  let left = 0;
  let zIndex = 0;
  let boxShadow = '';
  let paddingLeft = '';

  if (column.getIndex() > 0) {
    paddingLeft = '14px';
  }
  if (enableRowSelection && column.getIndex() === 0) {
    isPinned = true;
    left = 0;
    zIndex = 2;
  } else if (enableRowSelection && column.getIndex() === 1) {
    isPinned = true;
    if (wideCell) left = 58;
    else left = 40;
    zIndex = 1;
    boxShadow = saveBoxShadow;
  } else if (!enableRowSelection && column.getIndex() === 0) {
    isPinned = true;
    left = 0;
    zIndex = 1;
    boxShadow = saveBoxShadow;
  }
  if (!showBoxShadow) {
    boxShadow = '';
  }
  return {
    style: {
      left,
      position: !disableColumnPinning && isPinned ? 'sticky' : 'relative',
      top: 0,
      zIndex,
    },
    divStyle: {
      boxShadow,
      paddingLeft,
    },
  };
};
