import * as React from 'react';
import { forwardRef } from 'react';
const SvgBackArrow = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <g clipPath='url(#back_arrow_svg__a)'>
      <path
        d='M76 44H31.32l19.52-19.52a4.032 4.032 0 0 0 0-5.68 3.983 3.983 0 0 0-5.64 0L18.84 45.16a3.983 3.983 0 0 0 0 5.64L45.2 77.16a3.983 3.983 0 0 0 5.64 0 3.983 3.983 0 0 0 0-5.64L31.32 52H76c2.2 0 4-1.8 4-4s-1.8-4-4-4Z'
        fill='#000'
      />
    </g>
    <defs>
      <clipPath id='back_arrow_svg__a'>
        <path fill='#fff' d='M0 0h96v96H0z' />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgBackArrow);
export default ForwardRef;
