import Stripe from 'stripe';
import { useGetAllStripeProductsQuery, useGetStripeDetailsForOrgQuery, useInvalidateQuery } from '../../../../hooks';
import { formatStripeAmount, prepareDisplayedOptionsForEditSubscriptionModal } from '../../utils';
import { useMemo, useState } from 'react';
import {
  DESCRIPTIVE_LOADER_STATES,
  DescriptiveLoader,
  InputLabel,
  ModalContent,
  SelectableCardWithRadio,
  LoadingComponent,
} from 'ui';
import { deriveError } from '../../../templates/utils';
import { SubscriptionCadence } from 'services';

export enum UPGRADE_SUBSCRIPTION_MODAL_STEP {
  FORM,
  LOADING,
  SUCCESS,
  ERROR,
}

export const UpgradeSubscriptionModalContent = ({
  onUpdate,
  onCancel,
  disabled = false,
  subscriptionPlan,
  onSubscriptionPlanChange,
}) => {
  const [step, setStep] = useState(UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM);
  const [errorMessage, setErrorMessage] = useState('');

  const { invalidateStripeDetailsForOrg, invalidateStripeInvoices, invalidateUsage } = useInvalidateQuery();

  const { data: stripeDetails } = useGetStripeDetailsForOrgQuery();

  const currentPrice = stripeDetails?.subscription?.items.data[0].price;
  const currentProduct = currentPrice?.product as unknown as Stripe.Product;

  const currentSubscription = {
    id: currentPrice?.id,
    label: currentProduct?.name,
    description: `${formatStripeAmount(currentPrice?.unit_amount as number)} per ${currentPrice?.recurring?.interval}`,
    amount: currentPrice?.unit_amount as number,
    currency: currentPrice?.currency,
  };

  const { data: subscriptionProducts, isLoading } = useGetAllStripeProductsQuery({
    productType: 'subscription',
    cadence: (currentPrice?.recurring?.interval as SubscriptionCadence) ?? 'month',
  });

  const subscriptionPlans = useMemo(() => {
    const availableOptions = prepareDisplayedOptionsForEditSubscriptionModal(
      subscriptionProducts,
      currentPrice?.recurring?.interval ?? 'month',
    );

    // if no subscription active
    if (!currentProduct) return availableOptions;

    // if the user is on a custom price
    if (currentProduct.metadata.isCustomProduct === 'true') return [];

    // filter out lower tier plans
    const currentAmount = currentPrice?.unit_amount ?? 0;
    const higherTiers = availableOptions.filter((price) => price.amount > currentAmount);

    // console.log(higherTiers, availableOptions, currentProduct);
    return higherTiers;
  }, [subscriptionProducts, currentPrice, currentProduct]);

  const performUpdateWithModalStateChanges = async () => {
    try {
      setStep(UPGRADE_SUBSCRIPTION_MODAL_STEP.LOADING);
      await onUpdate();
      setStep(UPGRADE_SUBSCRIPTION_MODAL_STEP.SUCCESS);

      setTimeout(async () => {
        await invalidateStripeDetailsForOrg();
        await invalidateStripeInvoices();
        await invalidateUsage();
      }, 3000);
    } catch (error) {
      onError(error);
    }
  };

  const title = step === UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM ? 'Upgrade subscription' : undefined;

  const onClose = () => {
    setStep(UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM);
    onCancel();
  };

  const onError = (error) => {
    setStep(UPGRADE_SUBSCRIPTION_MODAL_STEP.ERROR);
    setErrorMessage(deriveError(error));
  };

  return (
    <ModalContent
      onClose={step !== UPGRADE_SUBSCRIPTION_MODAL_STEP.LOADING ? onClose : undefined}
      backAction={
        step === UPGRADE_SUBSCRIPTION_MODAL_STEP.ERROR
          ? { onClick: () => setStep(UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM) }
          : undefined
      }
      title={title}
      actions={
        step === UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM
          ? [
              {
                label: 'Upgrade subscription',
                onClick: performUpdateWithModalStateChanges,
                isLoading,
                disabled,
              },
              { label: 'Cancel', onClick: onClose, emphasis: 'medium', disabled },
            ]
          : []
      }
    >
      {step === UPGRADE_SUBSCRIPTION_MODAL_STEP.FORM && (
        <>
          <div className='mt-2'>
            {currentProduct ? (
              <>
                <InputLabel heading='Current plan' />

                <SelectableCardWithRadio
                  label={
                    <span className='flex flex-col items-start'>
                      <span className='text-lg font-semibold'>{currentSubscription.label} </span>
                      <span>{currentSubscription.description} </span>
                    </span>
                  }
                  selected={currentSubscription.id === subscriptionPlan?.id}
                  onClick={() => onSubscriptionPlanChange(currentSubscription)}
                />
              </>
            ) : (
              <div className='py-4'>No subscription plan active</div>
            )}
          </div>

          {currentProduct?.metadata.isCustomProduct === 'true' ? (
            <div className='mt-2'>
              You are on an enterprise plan. Please reach out to our customer care for any changes.
            </div>
          ) : subscriptionPlans.length === 0 ? (
            <div className='mt-2'>
              You are on our top tier plan! For more flexibility please reach out to our customer care.
            </div>
          ) : (
            <div className='mt-2'>
              <InputLabel heading='Available plans' />

              <div className='max-h-96 overflow-auto'>
                {!subscriptionProducts && isLoading && <LoadingComponent />}
                {subscriptionPlans && (
                  <div className='grid grid-cols-1 gap-4'>
                    {subscriptionPlans
                      .filter((plan) => currentSubscription.id !== plan.id)
                      .map((option, i) => (
                        <SelectableCardWithRadio
                          label={
                            <span className='flex flex-col items-start'>
                              <span className='text-lg font-semibold'>{option.label} </span>
                              <span>{option.description} </span>
                            </span>
                          }
                          key={i}
                          selected={option.id === subscriptionPlan?.id}
                          onClick={() => onSubscriptionPlanChange(option)}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {step === UPGRADE_SUBSCRIPTION_MODAL_STEP.LOADING && (
        <DescriptiveLoader inModal title='Processing' description='Updating subscription' />
      )}
      {step === UPGRADE_SUBSCRIPTION_MODAL_STEP.SUCCESS && (
        <DescriptiveLoader
          inModal
          title='Success'
          status={DESCRIPTIVE_LOADER_STATES.SUCCESS}
          description='Subscription updated'
        />
      )}
      {step === UPGRADE_SUBSCRIPTION_MODAL_STEP.ERROR && (
        <DescriptiveLoader
          inModal
          title='Could not change subscription'
          status={DESCRIPTIVE_LOADER_STATES.ERROR}
          description={errorMessage}
        />
      )}
    </ModalContent>
  );
};
