import * as React from 'react';
import { KBarSections } from '../../constants';

function SearchResult({ action, active, currentRootActionId }) {
  const ancestors = React.useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId);
    // +1 removes the currentRootAction; e.g.
    // if we are on the "Set theme" parent action,
    // the UI should not display "Set theme… > Dark"
    // but rather just "Dark"
    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);

  return (
    <div
      className={`${active ? 'bg-gray-200' : 'bg-white'}`}
      style={{
        padding: '4px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        borderRadius: 8,
        minHeight: 38,
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <span className={`${active ? 'text-gray-700' : 'text-gray-400'}`}>{action.icon ? action.icon : null}</span>
        {ancestors.length > 0 &&
          ancestors.map((ancestor) => (
            <React.Fragment key={ancestor.id}>
              <span className='font-medium'>{ancestor.name}</span>
              <span>&rsaquo;</span>
            </React.Fragment>
          ))}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div
            style={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              flex: 1,
              display: 'flex',
            }}
          >
            <span className='font-medium text-sm'>
              {action.name?.length < 30 || action.section === KBarSections.suggestions
                ? action.name
                : `${action.name?.substring(0, 10)}...${action.name?.substring(action.name.length - 10)}`}
            </span>
            <div className='gap-x-1 flex'>
              {action.subtitles?.map((item, index, arr) => (
                <>
                  <span className='text-gray-700' style={{ fontSize: 14 }} key={index}>
                    {item}
                  </span>
                  {index !== arr.length - 1 && <span>&middot;</span>}
                </>
              ))}
            </div>
          </div>
          {action.subtitle && <span style={{ fontSize: 12 }}>{action.subtitle}</span>}
        </div>
      </div>
      {action.shortcut?.length ? (
        <div aria-hidden style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}>
          {action.shortcut.map((sc) => (
            <div
              className='font-semibold text-sm border shadow-sm border-gray-300 h-8 aspect-square rounded-[8px] bg-[#f0f0f0] flex items-center justify-center'
              key={sc}
            >
              {sc.toUpperCase()}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default SearchResult;

/*

    <div className="search-result items-center mx-5 my-4" {...props}>
      {action.icon && action.icon}
      <div
        className={`${
          mode === "dark" ? "text-white" : "text-primary-dark"
        } text-[20px] font-[500]`}
      >
        {action}
      </div>
      <div class="flex gap-4">
        {keys.map((key) => (
          <button
            className={`btn--rounded btn--shortcut btn--shortcut-${mode}`}
          >
            {key}
          </button>
        ))}
      </div>
    </div>
      <Sunny
        width="40px"
        height="40px"
        className={`${mode === "dark" ? "light-icon" : "dark-icon"}`}
      />
      */
