/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { ButtonProps } from './types';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'tertiary', label, children, className, classNameContainer, disabled, ...props }, ref) => (
    <div className={`flex flex-col min-h-[38px] font-medium capitalize ${classNameContainer}`}>
      {!!label?.length && <label className='mb-1 font-medium'>{label}</label>}
      <button {...props} ref={ref} className={`btn--square p-2 ${variant} ${className}`} disabled={disabled}>
        {children}
        <i className='fa fa-spinner fa-spin' />
      </button>
    </div>
  ),
);

export default Button;
