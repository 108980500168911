import { useImpairedFilterContext } from '../../../../context';
import { ImpairedFilterDropdownRow } from '../impaired-filter';

export const ImpairedFilterRowGroup = () => {
  const {
    state,
    setters: { setSelectionByRowIndex, onlySelect },
  } = useImpairedFilterContext();
  return (
    <div className='px-4 py-2'>
      <div className='font-semibold'>Status</div>
      <div>
        {state.length > 1 ? (
          state.map((impairedFilterRow, i) => (
            <ImpairedFilterDropdownRow
              key={i}
              label={impairedFilterRow.value}
              selected={impairedFilterRow.selected}
              onChange={(selected: boolean) => setSelectionByRowIndex(i, selected)}
              onSelectOnly={() => onlySelect(i)}
            />
          ))
        ) : (
          <p className='text-zinc-900 text-sm px-2'>No status to show.</p>
        )}
      </div>
    </div>
  );
};
