// author: ABD

import React from 'react';
import { classNames } from '../utils';

interface LoadingComponentProps {
  mainItemClassName?: string;
  secondaryItemClassName?: string;
  className?: string;
}

export const LoadingLineComponent = ({
  className,
  large,
  ltr,
  pulse,
}: {
  className?: string;
  large?: boolean;
  ltr?: boolean;
  pulse?: boolean;
}) => (
  <div
    className={classNames(
      'h-4 bg-gray-200 bg-gradient-to-r from-gray-300 to-gray-100',
      ltr && 'bg-gradient-to-l',
      large ? 'w-[264px]' : 'w-[160px]',
      className,
      pulse && 'animate-pulse',
    )}
  />
);

export const LoadingComponent = ({
  mainItemClassName = '',
  secondaryItemClassName = '',
  className,
}: LoadingComponentProps) => {
  return (
    <div className={classNames('grid gap-y-4 gap-x-2 animate-pulse', className)}>
      <LoadingLineComponent className={mainItemClassName} />
      <LoadingLineComponent className={secondaryItemClassName} large />
    </div>
  );
};

export default LoadingComponent;
