import React from 'react';
import { ConditionItemsWrapperProps } from '../../..';
import { Button } from 'ui';

export const ConditionItemsWrapper = ({ children, className = '', isLoading }: ConditionItemsWrapperProps) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className={`flex flex-col gap-2 overflow-y-auto ${className}`}>
      {isLoading ? (
        <Button isLoading className='!bg-white mx-auto' />
      ) : childrenArray.length ? (
        childrenArray
      ) : (
        <div className='w-full text-center'>No data found</div>
      )}
    </div>
  );
};

export default ConditionItemsWrapper;
