import { RULE_CONFIGURATOR_TYPE, RuleConfiguratorState } from '../../types';
import { ACTION_TYPE } from '../action-types';
import { addConditionGroup } from './addConditionGroup';
import { updateConditionGroup } from './updateConditionGroup';
import { removeConditionGroup } from './removeConditionGroup';
import { reorderConditionGroups } from './reorderConditionGroups';
import { updateGate } from './updateGate';
import { addCondition } from './addCondition';
import { updateCondition } from './updateCondition';
import { removeCondition } from './removeCondition';
import { reorderConditionGroupChildren } from './reorderConditionGroupChildren';
import { moveConditionInConditionGroup } from './moveConditionInConditionGroup';
import { useReducer } from 'react';
import { addNestedCondition } from './addNestedCondition';
import { copyCondition } from './copyCondition';
import { copyConditionGroup } from './copyConditionGroup';
import { getInitialStateForRuleConfigurator } from '../utils';

const reducer = (state: RuleConfiguratorState, action): RuleConfiguratorState => {
  // console.log(action);
  switch (action.type) {
    // payload: conditionGroup, at
    case ACTION_TYPE.ADD_CONDITION_GROUP:
      return addConditionGroup(state, action);
    case ACTION_TYPE.UPDATE_CONDITION_GROUP:
      return updateConditionGroup(state, action);
    case ACTION_TYPE.REMOVE_CONDITION_GROUP:
      return removeConditionGroup(state, action);
    case ACTION_TYPE.REORDER_CONDITION_GROUPS:
      return reorderConditionGroups(state, action);

    case ACTION_TYPE.UPDATE_GATE:
      return updateGate(state, action);

    case ACTION_TYPE.ADD_CONDITION:
      // console.log(ACTION_TYPE.ADD_CONDITION_IN_CONDITION_GROUP, action.payload);
      return addCondition(state, action);
    case ACTION_TYPE.ADD_NESTED_CONDITION:
      // console.log(ACTION_TYPE.ADD_CONDITION_IN_CONDITION_GROUP, action.payload);
      return addNestedCondition(state, action);

    case ACTION_TYPE.CREATE_COPY:
      if (state.conditionIds.includes(action.payload.childId))
        return copyCondition(state, action.payload.childId, action.payload.parentId);
      else return copyConditionGroup(state, action.payload.childId, action.payload.parentId);

    case ACTION_TYPE.COPY_CONDITION:
      return copyCondition(state, action.payload.childId, action.payload.parentId);
    case ACTION_TYPE.COPY_CONDITION_GROUP:
      return copyConditionGroup(state, action.payload.childId, action.payload.parentId);

    case ACTION_TYPE.UPDATE_CONDITION:
      return updateCondition(state, action);
    case ACTION_TYPE.REMOVE_CONDITION:
      return removeCondition(state, action);
    case ACTION_TYPE.REORDER_CONDITION_GROUP_CONDITIONS:
      return reorderConditionGroupChildren(state, action);
    case ACTION_TYPE.MOVE_CONDITION_TO_CONDITION_GROUP:
      return moveConditionInConditionGroup(state, action);

    case ACTION_TYPE.CLEAR_ALL:
      return getInitialStateForRuleConfigurator(state.configuratorType);

    case ACTION_TYPE.SYNC:
      return action.payload;

    default:
      return state;
  }
};

export const useRuleConfiguratorReducer = (
  configuratorType: RULE_CONFIGURATOR_TYPE,
  defaultValue?: RuleConfiguratorState,
) => useReducer(reducer, defaultValue ?? getInitialStateForRuleConfigurator(configuratorType));
