import axios from 'axios';
import { SERVER_URL_ANALYTICS } from '../../config';
import { prepareSearchParams } from '../core/utils';
import { AccountingPeriod } from 'schemas';

type TransactionVolumeItem = {
  Date: string;
  grossPrice: number;
  netPrice: number;
  fee: number;
};

type TransactionVolumeResponse = {
  data: TransactionVolumeItem[];
};

type TransactionVolumeByClassificationResponse = {
  transactionsByClassification: {
    _id: string;
    volume: number;
    totalGrossPrice: {
      $numberDecimal: string;
    };
    totalFeePrice: {
      $numberDecimal: string;
    };
    totalNetPrice: {
      $numberDecimal: string;
    };
  }[];
};

type TransactionVolumeByMonthResponse = {
  transactionVolumeByMonth: {
    accountingPeriod: AccountingPeriod;
    transactionsVolume: {
      _id: string;
      volume: number;
    }[];
  }[];
};

type AccountingPeriodsHealthResponse = {
  data: {
    accountingPeriod: AccountingPeriod;
    accountedCount: number;
    unaccountedCount: number;
  }[];
};



export const getTransactionsVolume = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<TransactionVolumeResponse>(`${SERVER_URL_ANALYTICS}/get-transactions-volume?${queryParams}`);
};

export const getTransactionsNetFiatAmountCumulative = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });
  return axios.get<{ result: any }>(`${SERVER_URL_ANALYTICS}/transaction-net-fiat-amount-cumulative?${queryParams}`);
}



export interface AssetChange {
  assetType: string;
  netChange: number;
  usdValue: number;
}

export interface MonthlyAssetChange {
  month: string;
  assets: AssetChange[];
}
export const getTransactionAssetQuantityChanges = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<{ changes: MonthlyAssetChange[] }>(`${SERVER_URL_ANALYTICS}/transaction-asset-quantity-changes?${queryParams}`);
};

export const getTransactionsVolumeByClassification = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<TransactionVolumeByClassificationResponse>(
    `${SERVER_URL_ANALYTICS}/get-transactions-by-classification?${queryParams}`,
  );
};

export const getTransactionsVolumeByMonth = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<TransactionVolumeByMonthResponse>(
    `${SERVER_URL_ANALYTICS}/get-transactions-volume-by-month?${queryParams}`,
  );
};

export const getAccountingPeriodsHealthByTransactions = async (data) => {
  const queryParams = prepareSearchParams(data, { isPaginated: false });

  return axios.get<AccountingPeriodsHealthResponse>(
    `${SERVER_URL_ANALYTICS}/get-accounting-periods-health?${queryParams}`,
  );
};
