import { googleLogout } from '@react-oauth/google';
import { useSession } from './useSession';
// import { useLogoutMutation } from './http';
import toast from 'react-hot-toast';
import { useRouter } from 'next/navigation';
import { useIntercom } from 'react-use-intercom';
import { mixpanelReset, mixpanelTrack } from '../lib/utils/mixpanel';
export const useLogout = (withToast = true) => {
  const { clearSession } = useSession();
  const { hardShutdown } = useIntercom();
  // const { mutateAsync: logout } = useLogoutMutation();
  const router = useRouter();

  const cleanup = async () => {
    // try {
    //   await logout();
    // } catch (error) {
    //   console.error(error);
    // }
    mixpanelTrack('Logout');
    hardShutdown();
    googleLogout();
    clearSession();
    localStorage.clear();
    if (withToast) toast.success('Logged out');
    mixpanelReset();
    router.replace('/login');
  };

  return cleanup;
};
