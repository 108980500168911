import { FC } from 'react';
import { capitalizeFirstLetter, classNames } from 'ui';
import { useCommandPallete } from './useCommandPallete';
import { Intent } from '../../context';

type SuggestionsProps = {
  value: string;
  onSearch: (text: string) => void;
};

const intents: Intent[] = ['Create', 'Edit', 'Delete', 'Analyze'];

const Suggestions: FC<SuggestionsProps> = ({ value }) => {
  const { setSelectedIntent, selectedIntent } = useCommandPallete();

  const filteredIntents = intents.filter((intent) => {
    return intent.toLocaleLowerCase().startsWith(value.toLocaleLowerCase());
  });

  if (filteredIntents.length === 0) return;

  return (
    <>
      {filteredIntents.map((intent, index) => {
        return (
          <button
            key={index}
            className={classNames(
              'rounded-lg px-3 py-2 border border-zinc-300 mr-2 hover:bg-indigo-50 hover:cursor-pointer mb-2',
              selectedIntent === intent && 'bg-indigo-50 border-indigo-600',
            )}
            onClick={() => {
              if (selectedIntent === intent) {
                setSelectedIntent(null);
                return;
              }
              setSelectedIntent(intent);
            }}
          >
            {capitalizeFirstLetter(intent.toLowerCase())}
          </button>
        );
      })}
    </>
  );
};

export default Suggestions;
