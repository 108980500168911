import * as React from 'react';
import { forwardRef } from 'react';
const SvgLockIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2Zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2ZM5 7V5c0-1.66 1.34-3 3-3s3 1.34 3 3v2H5Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLockIcon);
export default ForwardRef;
