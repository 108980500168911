import { SearchInput } from 'ui';
import { useConditionSelectorContext } from './condition-selector-context';

export const ConditionSelectorSearchBar = () => {
  const { searchTerm, setSearchTerm } = useConditionSelectorContext();

  return (
    <SearchInput
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className='w-full text-zinc-900'
      containerClassName='min-w-full mb-4 text-zinc-900'
      leadingClassName='text-zinc-900'
    />
  );
};
