import axios from 'axios';
import { SERVER_URL_CONFIGURATION_SERVICE, SERVER_URL_CORE } from '../../config';
import { prepareSearchParams } from './utils';
import { BulkDeleteJournalEntryTemplatesParams } from '../types';

export const getJournalEntryTemplates = async (data) => {
  return axios.get(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-templates?page=${data.page}&sortBy=${
      data.sort?.id || 'createdAt'
    }&sortDirection=${data.sort?.desc ? 'desc' : 'asc'}&pageSize=${data.pageSize}&searchTerm=${data.searchTerm || ''}`,
  );
};
export const duplicateJournalEntryTemplate = async (data) =>
  axios.post(
    `${SERVER_URL_CONFIGURATION_SERVICE}/duplicate-journal-entry-templates?organizationId=${data.organizationId}&journalEntryTemplateId=${data.journalEntryTemplateId}`,
  );

export const createJournalEntryTemplate = async (data) =>
  axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-templates`, data);

export const updateJournalEntryTemplate = async (data) =>
  axios.patch(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-templates?journalEntryTemplateId=${data.journalEntryTemplateId}`,
    data.body,
  );

export const getJournalEntryTemplateById = async (data) =>
  axios.get(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-template-by-id?journalEntryTemplateId=${data.journalEntryTemplateId}`,
  );

export const deleteJournalEntryTemplate = async (data) =>
  axios.delete(
    `${SERVER_URL_CONFIGURATION_SERVICE}/journal-entry-templates?journalEntryTemplateIds=${data.journalEntryTemplateIds}&organizationId=${data.organizationId}`,
  );

export type CreateJournalEntriesWithoutRuleParams = {
  organizationId: string;
  journalEntryTemplateId: string;
  transactionIds: string[];
};
export const createJournalEntriesWithoutRule = async (data: CreateJournalEntriesWithoutRuleParams) =>
  axios.post(`${SERVER_URL_CONFIGURATION_SERVICE}/je-create-no-rule`, {
    organizationId: data.organizationId,
    journalEntryTemplateId: data.journalEntryTemplateId,
    transactionIds: data.transactionIds,
  });

export const bulkDeleteJournalEntryTemplates = async (data: BulkDeleteJournalEntryTemplatesParams) => {
  const searchParams = prepareSearchParams(data);

  return axios.delete(`${SERVER_URL_CORE}/bulk-delete-journal-entry-templates?${searchParams}`);
};
