import React from 'react';
import { AddInputTrailingAction } from '../add-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';
import { SubtractInputTrailingAction } from '../subtracting-input-trailing-action';
import { NumberWithUnitsInputProps } from './types';

export const NumberWithUnitInput = ({ unit, min, max, ...rest }: NumberWithUnitsInputProps) => {
  const { inputProps } = useProbablyControlledStateForInput({
    value: rest.value,
    onChange: rest.onChange,
  });
  return (
    <InputWithExtras
      {...rest}
      {...inputProps}
      type='number'
      trailing={
        <>
          <SubtractInputTrailingAction
            value={inputProps.value}
            setValue={inputProps.onChange}
            className='mr-3'
            disabled={rest.disabled}
            min={min}
          />
          <AddInputTrailingAction
            value={inputProps.value}
            setValue={inputProps.onChange}
            disabled={rest.disabled}
            max={max}
          />
        </>
      }
      suffix={unit && <span>{unit}</span>}
    />
  );
};
