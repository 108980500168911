import { TagDismissButtonProps, DissmissibleTagProps } from './types';
import { Tag } from '../tag';
import { MdClose } from 'react-icons/md';

const CloseButton = ({ icon = <MdClose className='w-4 h-4' />, onClick }: TagDismissButtonProps) => (
  <button className='rounded hover:bg-[#eeeeee]' onClick={onClick}>
    {icon}
  </button>
);

export const DismissibleTag = ({
  emphasis = 'high',
  label,
  leadingIcon,
  trailingIcon,
  onClose,
}: DissmissibleTagProps) => {
  return (
    <Tag
      emphasis={emphasis}
      label={label}
      leadingIcon={leadingIcon}
      trailingIcon={<CloseButton onClick={onClose} icon={trailingIcon} />}
    />
  );
};
