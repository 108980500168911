import { useEffect, useMemo, useState } from 'react';
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts';
import { useTabState } from '../../hooks';
import { getLocalStorageKeyForTabState } from './utils';

export const useSearchTerm = (page: string, debounceDelay = 500) => {
  const { activeTab } = useTabState();
  const activeTabId = useMemo(() => activeTab.id, [activeTab]);

  const [searchTerm, setSearchTerm] = useLocalStorage(
    getLocalStorageKeyForTabState(activeTabId, 'searchTerm', page),
    '',
  );

  const debouncedSettter = useDebounceCallback(setSearchTerm, debounceDelay);

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
  }, [searchTerm]);

  return { searchTerm: searchTerm.trimEnd(), isSearching, setIsSearching, setSearchTerm: debouncedSettter };
};
