import React from 'react';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';

interface SidebarBodyProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  testId?: string;
  id?: string;
  activeTabId?: string;
}

/**
 * prop id of the SidebarBody is to be used with active tab
 * @returns
 */

export const SidebarBody: React.FC<SidebarBodyProps> = ({
  children,
  className = '',
  isLoading = false,
  testId = '',
  id,
  activeTabId,
}) => {
  if (id && activeTabId && id !== activeTabId) return null;
  return (
    <div className={`flex-grow flex flex-col gap-y-[20px] overflow-auto ${className} py-6 w-full`} data-testid={testId}>
      {isLoading ? (
        <div className='mx-auto mt-20'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default SidebarBody;
