import React from 'react';
import { classNames } from 'ui';
import ReactImageFallback from 'react-image-fallback';
import { ImageWithFallbackProps } from './type';

export const Image = ({ ...props }: ImageWithFallbackProps) => {
  return (
    <span
      className={classNames(
        'inline-block bg-white',
        props.width && `w-[${props.width}]`,
        props.height && `h-[${props.height}]`,
        props.className,
      )}
    >
      <ReactImageFallback
        {...props}
        alt={props?.alt ?? ''}
        fallbackImage={props.fallbackImage ?? 'https://craftypixels.com/placeholder-image/400x400/e8e8e8/e8e8e8'}
        className={classNames(props.className, '!top-0 !left-0')}
      />
    </span>
  );
};

export default Image;
