import * as React from 'react';
import { forwardRef } from 'react';
const SvgRoundedCheckbox = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M9.001.668A8.336 8.336 0 0 0 .668 9.001c0 4.6 3.733 8.334 8.333 8.334S17.335 13.6 17.335 9 13.6.668 9 .668Zm0 15a6.676 6.676 0 0 1-6.666-6.667A6.676 6.676 0 0 1 9 2.335 6.676 6.676 0 0 1 15.668 9a6.676 6.676 0 0 1-6.667 6.667Zm3.234-9.758-4.9 4.9-1.567-1.567a.83.83 0 1 0-1.175 1.175l2.158 2.158a.83.83 0 0 0 1.175 0l5.492-5.491a.83.83 0 0 0 0-1.175.84.84 0 0 0-1.183 0Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRoundedCheckbox);
export default ForwardRef;
