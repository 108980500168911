import { useEffect, useState } from 'react';
import { shrink } from '../../../lib/utils';
import { convertSources, mergeSources } from '../../templates/utils';
import { dateConverter } from '../../utils';
import { HedgeyIcon } from '../../../assets/generated/icons';
import { DateProps } from '../../utils/types';
import { DisplayedWallet } from './type';
import { Wallet } from '../../../types';
import { getWallets } from 'services/http';

export const useDisplayedSources = (sourcesData) => {
  const [displayedSources, setDisplayedSources] = useState<DisplayedWallet[]>();
  useEffect(() => {
    if (sourcesData)
      setDisplayedSources(
        mergeSources(sourcesData).map((source: Wallet) => {
          const address =
            source.addresses?.length > 1
              ? source.addresses?.map((adr) => shrink(adr)).reduce((acc, currentValue) => `${acc}, ${currentValue}`)
              : source.address;

          const sourceInfo = {
            title: source?.name ?? shrink(source?.address),
            desc: source?.walletType,
            chain: source.chain,
            partnerImage: source?.meta?.hedgeyEvent && <HedgeyIcon />,
          };
          const created = {
            title: source?.createdBy,
            desc: dateConverter(source.createdAt as DateProps),
          };
          return {
            ...source,
            sourceInfo,
            address,
            created,
          };
        }),
      );
  }, [sourcesData]);
  return displayedSources;
};

export const useDisplayedTableData = ({ sourcesIQData, raincards }) => {
  const displayedWallets = useDisplayedSources(sourcesIQData);

  const displayedRaincards =
    raincards?.map((raincard) => {
      return {
        ...raincard,
        sourceInfo: {
          title: raincard.cardData.nickname,
          img: <img src='/raincards-logo.png' />,
        },
        address: raincard.cardData.last4,
        legalEntityId: raincard.legalEntityId,
        status: raincard.cardData.status,
        isRaincard: true,
        created: raincard.cardData.cardholderFirstName + ' ' + raincard.cardData.cardholderLastName,
      };
    }) || [];
  const displayedTableData = displayedWallets?.concat(displayedRaincards);
  return displayedTableData;
};

export const handleDownloadSources = async ({ chains, sourcesIQData, walletTypes, searchTerm }) => {
  let allTxs: any = [];
  const perQuery = 3000;
  const totalTx = sourcesIQData?.pages
    .map(({ wallets }) => wallets.length)
    ?.reduce((acc, currentValue) => acc + currentValue);

  const totalQuery = Math.max(1, totalTx / perQuery);

  for (let i = 0; i < totalQuery; i += 1) {
    const response = await getWallets({
      page: i,
      chains,
      walletTypes,
      nameOrAddress: searchTerm || undefined,
      pageSize: perQuery,
    });
    const newTexs = response.data?.wallets;
    allTxs = [...allTxs, ...newTexs];
  }

  return convertSources(allTxs);
};
