import React from 'react';
import { DESCRIPTIVE_LOADER_STATES, DescriptiveLoaderProps } from './types';
import { LOADER_ICON_SIZE, LoaderIcon } from '../loader-icon';
import { classNames } from '../utils';
import { MdCheckCircle, MdError } from 'react-icons/md';

export const DescriptiveLoader = ({
  title,
  description,
  status = DESCRIPTIVE_LOADER_STATES.LOADING,
  containerClassname,
  inModal,
  'data-cy': dataCy,
}: DescriptiveLoaderProps) => {
  return (
    <div
      className={classNames(
        'bg-white p-4 text-center flex flex-col items-center',
        inModal && 'mb-3',
        containerClassname,
      )}
      data-cy={`${dataCy}__descriptiveLoader_container`}
    >
      <div className='m-4'>
        {status === DESCRIPTIVE_LOADER_STATES.LOADING && <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />}
        {status === DESCRIPTIVE_LOADER_STATES.SUCCESS && (
          <MdCheckCircle className='w-10 h-10 text-green-100 bg-green-800 rounded-full' />
        )}
        {status === DESCRIPTIVE_LOADER_STATES.ERROR && (
          <MdError className='w-10 h-10 text-red-100 bg-red-800 rounded-full' />
        )}
      </div>
      {title && (
        <div className='font-semibold text-xl leading-6 mb-1' data-cy={`${dataCy}__descriptiveLoader_title`}>
          {title}
        </div>
      )}
      {description && (
        <div className='leading-5 max-h-40 overflow-auto' data-cy={`${dataCy}__descriptiveLoader_description`}>
          {description}
        </div>
      )}
    </div>
  );
};
