import { useEffect, useRef, useState } from 'react';
import {
  CheckboxListItem,
  Dropdown,
  DropdownBody,
  DropdownContent,
  DropdownTitleWithToggle,
  DropdownTrigger,
} from '../dropdown';
import { Button } from '../button';
import { classNames } from '../utils';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { DropdownOption, MultiListProps, MultiRowProps, MultiSelectProps } from './types';
import { InputWithExtras } from '../input-with-extras';
import { Virtuoso } from 'react-virtuoso';

const MAX_RENDERED_LIST = 100;

export const MultiSelectMenu = ({
  disabled,
  value,
  isLoading,
  'data-cy': dataCy,
  placeholder = 'Select',
  clearable,
  onClearValue,
  onChange,
  options: defaultOptions = [],
  fullWidth,
  isOnSidepanel = false,
  isModal = true,
  side,
  dropdownContentClassName,
  enableAvatar = false,
  customIconRender,
  enableBottomText = false,
  type = 'chain',
}: MultiSelectProps) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [options, setOptions] = useState<DropdownOption[]>(defaultOptions);
  const [values, setValues] = useState<DropdownOption[]>(value ?? []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleOnChange = (option: DropdownOption, checked: boolean) => {
    setValues((prev) => {
      if (checked) {
        return [...prev, option];
      }
      return prev.filter((item) => item.value !== option.value);
    });
    onChange &&
      onChange(
        (() => {
          if (checked) {
            return [...values, option];
          }
          return values.filter((item) => item.value !== option.value);
        })(),
      );
  };

  const selectAll = () => {
    setValues(options);
    onChange && onChange(options);
  };

  const clearAll = () => {
    setValues([]);
    onChange && onChange([]);
  };

  const clearValue = () => {
    setValues([]);
    onClearValue && onClearValue();
  };

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    setValues(value ?? []);
  }, [value]);

  const canClearValue = (values: DropdownOption[] | undefined) => values && values.length > 0 && onClearValue;

  return (
    <Dropdown open={open} onOpenChange={setOpen}>
      <DropdownTrigger ref={triggerRef}>
        <Button
          data-cy={dataCy}
          isFocused={open}
          emphasis='medium'
          disabled={disabled}
          isLoading={isLoading}
          onClick={() => setOpen((prev) => !prev)}
          label={
            values && values.length > 0 ? (
              <span>
                {values[0].label} {values && values.length > 1 && <span>+{values.length - 1}</span>}
              </span>
            ) : (
              placeholder
            )
          }
          className={classNames('duration-100 py-0', fullWidth && 'w-full flex justify-between')}
          labelContainerClassname='font-normal'
          trailingIconContainerClassname={classNames(
            values && values.length > 0 && 'mr-0 p-2 duration-100 rounded-r-lg bg-white',
          )}
          trailingIcon={
            <div className='flex items-center'>
              {canClearValue(value) && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    clearValue();
                  }}
                >
                  <IoMdCloseCircle className='w-6 h-6 text-zinc-500' />
                </span>
              )}

              <MdArrowDropDown className={classNames('duration-300 w-6 h-6 text-black', open && 'rotate-180')} />
            </div>
          }
        />
      </DropdownTrigger>
      <DropdownContent
        avoidCollisions
        side={side}
        triggerRef={triggerRef}
        enableHeightCalculation={false}
        sameWidthAsTrigger={fullWidth}
        className={classNames('p-1', isOnSidepanel && '!z-50', dropdownContentClassName)}
        withoutPortal={isModal}
      >
        <div className='overflow-hidden flex flex-col gap-1 flex-shrink-0'>
          {options.length > 5 && (
            <div className='p-1'>
              <InputWithExtras
                placeholder={placeholder.replace('Select', 'Search')}
                onChange={handleInputChange}
                leading={<MdSearch className='w-5 h-5 text-zinc-500 ml-1' />}
                containerClassName='min-w-[none] w-full'
                className='rounded-lg text-zinc-500 items-center leading-5 text-base placeholder:-translate-y-0.5 shadow-[0px_2px_4px_0px_rgba(0,_0,_0,_0.05)] '
              />
            </div>
          )}
        </div>
        <DropdownTitleWithToggle
          title='Select all'
          checked={(value && value.length === options.length) ?? false}
          onChange={(checked: boolean) => (checked ? selectAll() : clearAll())}
        />

        <DropdownBody className='flex flex-col gap-1'>
          {options.length > MAX_RENDERED_LIST ? (
            <VirtualizedList
              type={type}
              filter={filter}
              options={options}
              values={values}
              enableAvatar={enableAvatar}
              enableBottomText={enableBottomText}
              handleOnChange={handleOnChange}
              customIconRender={customIconRender}
            />
          ) : options.length > 0 ? (
            <List
              type={type}
              filter={filter}
              options={options}
              values={values}
              enableAvatar={enableAvatar}
              enableBottomText={enableBottomText}
              handleOnChange={handleOnChange}
              customIconRender={customIconRender}
            />
          ) : (
            <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>
              {isLoading ? 'Loading...' : 'No options available'}
            </p>
          )}
        </DropdownBody>
      </DropdownContent>
    </Dropdown>
  );
};

const List = ({
  options,
  values,
  handleOnChange,
  enableAvatar,
  enableBottomText,
  type,
  filter,
  customIconRender,
}: MultiListProps) => {
  if (filter) {
    return options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length ===
      0 ? (
      <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>
    ) : (
      options
        .filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase()))
        .map((option, idx) => (
          <Row
            key={idx}
            option={option}
            type={type}
            enableAvatar={enableAvatar}
            enableBottomText={enableBottomText}
            values={values}
            handleOnChange={handleOnChange}
            customIconRender={customIconRender}
          />
        ))
    );
  }

  return options.map((option, idx) => (
    <Row key={idx} option={option} values={values} handleOnChange={handleOnChange} />
  ));
};

const VirtualizedList = ({
  options,
  values,
  handleOnChange,
  enableAvatar,
  enableBottomText,
  type,
  filter,
  customIconRender,
}: MultiListProps) => {
  if (filter) {
    return options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length ===
      0 ? (
      <p className='text-zinc-900 text-center font-medium py-1.5 px-2'>No options available</p>
    ) : (
      <Virtuoso
        style={{ height: 400 }}
        data={options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase()))}
        totalCount={
          options.filter((option) => (option.label as string).toLowerCase().includes(filter.toLowerCase())).length
        }
        itemContent={(index, option) => (
          <Row
            key={index}
            option={option}
            type={type}
            values={values}
            enableAvatar={enableAvatar}
            enableBottomText={enableBottomText}
            handleOnChange={handleOnChange}
            customIconRender={customIconRender}
          />
        )}
      />
    );
  }
  return (
    <Virtuoso
      data={options}
      totalCount={options.length}
      style={{ height: 400, maxHeight: 'fit-content' }}
      itemContent={(index, option) => (
        <Row
          key={index}
          option={option}
          type={type}
          enableAvatar={enableAvatar}
          enableBottomText={enableBottomText}
          values={values}
          handleOnChange={handleOnChange}
          customIconRender={customIconRender}
        />
      )}
    />
  );
};

const Row = ({ option, values, handleOnChange, enableAvatar }: MultiRowProps) => {
  return (
    <CheckboxListItem
      enableSelectOnly={false}
      enableAvatar={enableAvatar}
      label={option.label as string}
      checked={values !== undefined && !!values.find((item) => item.value === option.value)}
      onCheckedChange={(checked) => handleOnChange(option, checked)}
      containerClassNames='flex-shrink-0'
    />
  );
};
