import { Button, StatusIndicator, classNames } from 'ui';
import { PaymentCardProps } from './types';
import { useRemoveStripePaymentMethodMutation } from '../../../hooks/http/billing-service/stripe/use-remove-stripe-payment-method-mutation';
import toast from 'react-hot-toast';
import { useInvalidateQuery } from '../../../hooks';
import { withToasts } from '../utils';
import { PaymentMethodSymbol } from './payment-method-symbol';
import { RemovePaymentMethodConfirmationModal } from '../modals';

export const PaymentCard = ({
  paymentMethodId,
  brand,
  last4,
  isDefault,
  expiresOn,
  onEdit,
  hideRemove,
  className,
}: PaymentCardProps) => {
  const { mutateAsync: removeStripePaymentMethod } = useRemoveStripePaymentMethodMutation();
  const { invalidateStripeCards } = useInvalidateQuery();

  const onRemove = withToasts(
    async () => {
      if (isDefault) {
        toast.error('Please select another payment method as default before removing this one');
        return true;
      }
      await removeStripePaymentMethod({ paymentMethodId });
      await invalidateStripeCards();
    },
    {
      loading: 'Removing payment method',
      success: 'Payment method removed',
    },
  );

  return (
    <div className={classNames('flex items-center justify-between border rounded-lg p-4', className)}>
      <div className='flex items-center gap-x-4 px-4'>
        <PaymentMethodSymbol paymentMethodType={brand} />

        <div>
          <div className='flex items-center gap-x-2'>
            <span className='capitalize font-semibold'>
              {brand}...{last4}
            </span>
            {isDefault && <StatusIndicator label='Active' type='positive' />}
          </div>
          <div>{expiresOn && <span className='text-gray-600 font-base'>Expires {expiresOn}</span>}</div>
        </div>
      </div>

      <div className='flex gap-x-2'>
        {!hideRemove && !isDefault && <RemovePaymentMethodConfirmationModal onRemove={onRemove} />}
        {onEdit && <Button label='Edit' onClick={onEdit} />}
      </div>
    </div>
  );
};
