import bigDecimal from 'js-big-decimal';
import { NET_INCOME } from '../../utils';

// Helper function to initialize balance values
const initializeBalanceValues = (balance: any = {}) => {
  return {
    debits: { value: parseFloat(balance?.debits?.value || '0').toFixed(2) },
    credits: { value: parseFloat(balance?.credits?.value || '0').toFixed(2) },
    currentBalance: { value: parseFloat(balance?.currentBalance?.value || '0').toFixed(2) },
  };
};

// Income statement
export const addTotalsByAccountTypeIncomeStat = (rows: any[]): any[] => {
  const totalsByAccountType: { [key: string]: { [key: string]: any; rows: any[] } } = {
    Income: { balances: {}, rows: [] },
    Expense: { balances: {}, rows: [] },
  };

  rows.forEach((row: any) => {
    const { ledgerAccountType } = row.ledgerAccount;

    if (!totalsByAccountType[ledgerAccountType]) {
      totalsByAccountType[ledgerAccountType] = {
        balances: {},
        rows: [],
      };
    }

    totalsByAccountType[ledgerAccountType].rows.push(row);

    for (const period in row.balances) {
      const periodData = row.balances[period].creditDebit;
      const balance = initializeBalanceValues(periodData);

      if (!totalsByAccountType[ledgerAccountType].balances[period]) {
        totalsByAccountType[ledgerAccountType].balances[period] = initializeBalanceValues();
      }

      totalsByAccountType[ledgerAccountType].balances[period].debits.value = bigDecimal.add(
        totalsByAccountType[ledgerAccountType].balances[period].debits.value,
        balance.debits.value,
      );

      totalsByAccountType[ledgerAccountType].balances[period].credits.value = bigDecimal.add(
        totalsByAccountType[ledgerAccountType].balances[period].credits.value,
        balance.credits.value,
      );

      totalsByAccountType[ledgerAccountType].balances[period].currentBalance.value = bigDecimal.subtract(
        totalsByAccountType[ledgerAccountType].balances[period].credits.value,
        totalsByAccountType[ledgerAccountType].balances[period].debits.value,
      );
    }
  });

  const rowsWithTotals: any[] = [];

  // Add Income and Expense rows
  ['Income', 'Expense'].forEach((type) => {
    if (totalsByAccountType[type].rows.length > 0) {
      rowsWithTotals.push(...totalsByAccountType[type].rows);
      rowsWithTotals.push({
        ledgerAccount: {
          ledgerAccountName: `Total ${type}`,
          _id: `total-${type.toLowerCase()}`,
          ledgerAccountType: type,
          ledgerAccountSequence: 0,
          isTotal: true,
        },
        balances: totalsByAccountType[type].balances,
        subRows: [],
      });
    }
  });

  return rowsWithTotals;
};

export const calculateGrandTotalIncomeStat = (rows: any[]): any => {
  const grandTotalBalances: { [key: string]: any } = {};

  rows.forEach((row: any) => {
    if (row.ledgerAccount.isTotal) return;

    for (const period in row.balances) {
      const periodData = row.balances[period].creditDebit;
      const balance = initializeBalanceValues(periodData);

      if (!grandTotalBalances[period]) {
        grandTotalBalances[period] = initializeBalanceValues();
      }

      grandTotalBalances[period].debits.value = bigDecimal.add(
        grandTotalBalances[period].debits.value,
        balance.debits.value,
      );
      grandTotalBalances[period].credits.value = bigDecimal.add(
        grandTotalBalances[period].credits.value,
        balance.credits.value,
      );

      grandTotalBalances[period].currentBalance.value = bigDecimal.subtract(
        grandTotalBalances[period].credits.value,
        grandTotalBalances[period].debits.value,
      );
    }
  });

  const ledgerAccount = {
    ledgerAccountName: NET_INCOME,
    _id: 'grand-total',
  };

  return {
    ledgerAccount: ledgerAccount as any,
    subRows: [],
    balances: grandTotalBalances,
  };
};

// Balance sheet
export const addTotalsByAccountTypeBalanceSheet = (rows: any[]): any[] => {
  const totalsByAccountType: any = {};
  const rowsWithTotals: any[] = [];

  const processRow = (row: any) => {
    const { ledgerAccountType } = row.ledgerAccount;

    if (!totalsByAccountType[ledgerAccountType]) {
      totalsByAccountType[ledgerAccountType] = {
        balances: {},
        rows: [],
      };
    }

    totalsByAccountType[ledgerAccountType].rows.push(row);

    for (const period in row.balances) {
      const periodData = row.balances[period].creditDebit;
      const balance = initializeBalanceValues(periodData);

      if (!totalsByAccountType[ledgerAccountType].balances[period]) {
        totalsByAccountType[ledgerAccountType].balances[period] = initializeBalanceValues();
      }

      totalsByAccountType[ledgerAccountType].balances[period].debits.value = bigDecimal.add(
        totalsByAccountType[ledgerAccountType].balances[period].debits.value,
        balance.debits.value,
      );
      totalsByAccountType[ledgerAccountType].balances[period].credits.value = bigDecimal.add(
        totalsByAccountType[ledgerAccountType].balances[period].credits.value,
        balance.credits.value,
      );
      totalsByAccountType[ledgerAccountType].balances[period].currentBalance.value = bigDecimal.subtract(
        totalsByAccountType[ledgerAccountType].balances[period].credits.value,
        totalsByAccountType[ledgerAccountType].balances[period].debits.value,
      );
    }
  };

  rows.forEach(processRow);

  Object.entries(totalsByAccountType).forEach(([accountType, data]: any) => {
    rowsWithTotals.push(...data.rows);
    rowsWithTotals.push({
      ledgerAccount: {
        ledgerAccountName: `Total ${accountType}`,
        _id: `total-${accountType}`,
        ledgerAccountType: accountType,
        ledgerAccountSequence: 0,
        isTotal: true,
      },
      balances: data.balances,
      subRows: [],
    });
  });

  return rowsWithTotals;
};
