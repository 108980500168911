import React, { useEffect, useState } from 'react';
import { MdCircle } from 'react-icons/md';
import { ClearInputTrailingAction } from '../clear-input-trailing-action';
import { InputWithExtras, useProbablyControlledStateForInput } from '../input-with-extras';

import { ChromePicker } from 'react-color';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { ColorPickerTypes } from './types';

export const ColorPickerInput = (props: ColorPickerTypes) => {
  const { inputProps } = useProbablyControlledStateForInput({
    value: props.value,
    onChange: props.onChange,
  });

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-start' });
  useEffect(() => {
    inputProps.onChange({ target: { value: '#000000' } });
  }, []);
  return (
    <InputWithExtras
      {...props}
      {...inputProps}
      leading={
        <Popover className='relative'>
          <Popover.Button
            className='hover:text-black outline-none disabled:opacity-50'
            ref={setReferenceElement}
            disabled={props.disabled}
          >
            <MdCircle
              className='w-5 h-5 mt-[5px]'
              style={{ color: inputProps.value.length === 7 ? inputProps.value : '#222222' }}
            />
          </Popover.Button>

          <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper} className='mt-6 bg-white'>
            <ChromePicker
              onChangeComplete={(color) => {
                inputProps.onChange({ target: { value: color.hex } });
              }}
              color={inputProps.value.length === 7 && inputProps.value.startsWith('#') ? inputProps.value : '#000000'}
            />
          </Popover.Panel>
        </Popover>
      }
      trailing={
        <>
          {inputProps.value !== '#000000' && (
            <ClearInputTrailingAction
              value={inputProps.value}
              setValue={inputProps.onChange}
              callback={() => inputProps.onChange({ target: { value: '#000000' } })}
            />
          )}
        </>
      }
    />
  );
};
