import React from 'react';
import { SidebarSection, SidebarSectionHeader } from '../../../atoms';

const Traits = ({ transaction }) => {
  return (
    <>
      {transaction?.meta?.simpleHashNFTDetails && (
        <>
          <SidebarSectionHeader title='Traits' />
          <SidebarSection>
            {transaction?.meta?.simpleHashNFTDetails?.extra_metadata?.attributes?.map((item) => (
              <div key={item?.trait_type}>
                <p className='text-[#00000066]'>{item.trait_type}</p>
                <p>{item?.value}</p>
              </div>
            ))}
          </SidebarSection>
        </>
      )}
    </>
  );
};

export default Traits;
