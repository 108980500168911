import { Checkbox } from '../checkbox/Checkbox';
import { CheckBoxCardProps } from './types';

export const CheckboxCard = ({ label, subLabel, options, onChange, values, avatar }: CheckBoxCardProps) => {
  const onSelect = (value: string) => {
    if (values.includes(value)) {
      onChange(values.filter((val) => val !== value));
    } else {
      onChange([...values, value]);
    }
  };

  const onSelectAll = () => {
    if (values.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.value));
    }
  };

  return (
    <div className='border rounded-md'>
      <div className='flex items-center gap-x-4 p-4 border-b'>
        <span className='flex items-center justify-center'>{avatar}</span>
        <div className='flex flex-col'>
          {label && <div className='text-lg font-medium'>{label}</div>}
          {subLabel && <div className='text-sm text-gray-500'>{subLabel}</div>}
        </div>
      </div>
      <div className='flex items-center gap-2 flex-col p-4'>
        <div className='flex w-full justify-between items-center'>
          <Checkbox label='Select all' onChange={onSelectAll} isSelected={values.length === options.length} />
          <p className='break-keep w-full text-end text-indigo-600'>{values.length} selected</p>
        </div>
        {options.map((option, index) => {
          return (
            <Checkbox
              key={index}
              label={option.label}
              icon={option.icon}
              isSelected={values.includes(option.value)}
              onChange={() => onSelect(option.value)}
            />
          );
        })}
      </div>
    </div>
  );
};
