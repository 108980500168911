import * as React from 'react';
import { forwardRef } from 'react';
const SvgSuccessCheck = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='m14.667 20-3.625-3.625a1.53 1.53 0 0 0-1.125-.458c-.445 0-.834.166-1.167.5-.306.305-.458.694-.458 1.166 0 .473.152.861.458 1.167l4.75 4.75c.306.305.694.458 1.167.458.472 0 .86-.152 1.166-.458l9.459-9.458a1.53 1.53 0 0 0 .458-1.125c0-.445-.167-.834-.5-1.167-.305-.306-.694-.458-1.167-.458-.472 0-.86.152-1.166.458L14.667 20ZM17 33.667c-2.306 0-4.472-.438-6.5-1.314-2.028-.874-3.792-2.061-5.292-3.561S2.521 25.528 1.647 23.5C.77 21.472.333 19.305.333 17c0-2.306.438-4.472 1.314-6.5.874-2.028 2.061-3.792 3.561-5.292S8.472 2.521 10.5 1.645C12.528.771 14.694.333 17 .333s4.472.438 6.5 1.312c2.028.876 3.792 2.063 5.292 3.563s2.687 3.264 3.561 5.292c.876 2.028 1.314 4.194 1.314 6.5 0 2.305-.438 4.472-1.314 6.5-.874 2.028-2.061 3.792-3.561 5.292s-3.264 2.687-5.292 3.561c-2.028.876-4.194 1.314-6.5 1.314Z'
      fill='green'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSuccessCheck);
export default ForwardRef;
