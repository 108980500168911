import { useEffect } from 'react';
import { useAccountingPeriodFilterContext, useDateFilterContext } from '../context';

export const useResetAccountingFilterOnCustomDateSelection = () => {
  const { state } = useDateFilterContext();
  const { setState } = useAccountingPeriodFilterContext();
  // deselect all accounting periods when user selects a custom date
  useEffect(() => {
    setState((prev) => prev.map((value) => ({ ...value, selected: false })));
  }, [state, setState]);
};
