import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  getExpandedRowModel,
  CellContext,
} from '@tanstack/react-table';
import { useInView } from 'react-intersection-observer';
import StyledTable from '../../styles/Tables';
import { TableSkeletonLoader } from '../atoms';
import {
  DefaultTableHeader,
  LOADER_ICON_SIZE,
  LeadingCellExpandable,
  LoaderIcon,
  NumberHeader,
  SingleLineNumber,
  Tooltip,
} from 'ui';

import { GoTriangleRight } from 'react-icons/go';
import { TrialBalanceTableProps } from './types';
import { formatDollars } from '../templates/utils';
import clsx from 'clsx';

const tableHeader = [
  { header: 'Ledger account', key: 'ledgerAccountName', textAligned: 'text-left' },
  { header: 'Starting balance', key: 'openingBalance', textAligned: 'text-right' },
  { header: 'Ending balance', key: 'closingBalance', textAligned: 'text-right' },
  { header: 'Debit', key: 'debits', textAligned: 'text-right' },
  { header: 'Credit', key: 'credits', textAligned: 'text-right' },
];

function getTextAlignment(headerName: string) {
  const header = tableHeader.find((item) => item.key === headerName);
  if (header) {
    return header.textAligned;
  } else {
    // Default alignment if header not found
    return 'text-left';
  }
}

const EMPTY_TABLE_MESSAGE = 'No data found';

/**
 * Trial balance table component is to be used to display trial balance in report page
 *
 * @returns
 */

export const TrialBalanceTable = ({
  tableData = [],
  isLoading = false,
  className = '',
  tableClasses = '',
  initialExpanded = {},
}: TrialBalanceTableProps) => {
  const { ref } = useInView();

  const columnHelper = createColumnHelper<any>();

  const defaultColumns = [
    ...tableHeader.map(({ header, key, textAligned }) => {
      const handleCells = (payload: CellContext<any, any>) => {
        const { getValue, row } = payload;
        const compare = (passedKey) => header === passedKey;

        if (compare('Ledger account')) {
          return (
            <div
              style={{ transform: row.depth !== 0 ? `translateX(calc(${row.depth * 2.25}rem))` : '' }}
              className='flex gap-3 items-center z-normal'
            >
              <LeadingCellExpandable
                label={getValue()}
                isAvatarVisible={false}
                bottomText=''
                isCheckboxVisible={false}
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                expandFn={row.getToggleExpandedHandler()}
                canExpand={false}
                withoutPaddingLeft={true}
              />
            </div>
          );
        }

        if (compare('Starting balance') || compare('Ending balance')) {
          if (row.original.type === 'total') return null;
          return (
            <SingleLineNumber
              key={key}
              label={getValue() ? formatDollars(getValue().value, true) : formatDollars(0)}
              textAlign={textAligned}
            />
          );
        }
        if (compare('Debit') || compare('Credit')) {
          return (
            <SingleLineNumber
              key={key}
              label={getValue() ? formatDollars(getValue().value, true) : formatDollars(0)}
              textAlign={textAligned}
              className={row.original.type === 'total' ? 'font-medium' : ''}
            />
          );
        }
      };
      return columnHelper.accessor(key, {
        header,
        cell: (payload) => handleCells(payload),
        footer: (info) => info.column.id,
      });
    }),
  ];

  const [columns, setColumns] = useState(defaultColumns);
  const [hoveredHeader, setHoveredHeader] = useState<string | null>(null);

  const expandableHeaderIds = ['ledgerAccountName'];

  useEffect(() => {
    let isExpandable = false;
    setColumns([
      columnHelper.accessor('checkbox', {
        id: 'checkbox',
        header: ({ table }) => {
          const checkIfTableHeaderIsExpandable = () => {
            expandableHeaderIds.forEach((headerId) => {
              if (table.getHeaderGroups()[0].headers.find((header) => header.id === headerId)) {
                isExpandable = true;
              }
            });
            return isExpandable;
          };

          return (
            <div className='flex items-center'>
              {checkIfTableHeaderIsExpandable() && (
                <Tooltip
                  removeTooltipContainerClassname
                  content={table.getIsAllRowsExpanded() ? 'Collapse all' : 'Expand all'}
                >
                  <GoTriangleRight
                    onClick={(e) => {
                      e.stopPropagation();
                      table.getToggleAllRowsExpandedHandler()(e);
                    }}
                    className={clsx(
                      'rounded-lg w-6 h-6 p-1 mr-3 text-zinc-800 transition-transform',
                      'hover:bg-zinc-200',
                      {
                        'rotate-90': table.getIsAllRowsExpanded(),
                      },
                    )}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
        cell: ({ row }) => (
          <div
            style={{
              transform: `translateX(calc(${row.depth * 2.25}rem + 1rem))`,
            }}
            className='flex gap-3 items-center z-normal'
          >
            {row.getCanExpand() && isExpandable ? (
              <Tooltip content='Expand' removeTooltipContainerClassname>
                <GoTriangleRight
                  onClick={(e) => {
                    e.stopPropagation();
                    row.getToggleExpandedHandler()();
                  }}
                  className={clsx('rounded-lg w-6 h-6 p-1 text-zinc-800 transition-transform', 'hover:bg-zinc-200', {
                    'rotate-90': row.getIsExpanded(),
                  })}
                />
              </Tooltip>
            ) : null}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      ...defaultColumns,
    ]);
  }, [tableHeader]);

  const [expanded, setExpanded] = useState(initialExpanded ?? {});
  const [rowSelection, setRowSelection] = React.useState({});

  const [data, setData] = useState(() => [...tableData]);
  useEffect(() => {
    // This part is important we used it to refreash the table data we can't remove it
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      rowSelection,
    },
    defaultColumn: {
      minSize: 0,
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableSorting: false,
  });

  return (
    <>
      <div
        className={`overflow-auto mt-4 col-span-full  ${
          !tableData.length && !isLoading ? 'flex flex-auto' : 'rounded-xl border border-[#CBCBCB]'
        } ${className}`}
      >
        {!tableData.length && !isLoading ? (
          <>
            <div className='w-full flex flex-col justify-center items-center'>
              <p className='text-2xl font-medium mb-6'>{EMPTY_TABLE_MESSAGE}</p>
            </div>
          </>
        ) : (
          <>
            <StyledTable />
            <table
              data-testid={'trial-balance'}
              className={tableClasses}
              style={{
                minWidth: '100%',
                width: table.getCenterTotalSize(),
              }}
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => {
                      if (header.id === 'checkbox') {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            className={`h-12 !p-0   ${
                              tableHeader[index]?.textAligned ? tableHeader[index].textAligned : 'text-center'
                            }
                          ${header.id === 'checkbox' && '!p-0 w-12 left-0 sticky fixedLeft !bg-[#F2F2F2] z-[5]'}`}
                          >
                            <div className={'flex pl-6'}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                          </th>
                        );
                      }

                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={`h-12 !p-0 ${
                            tableHeader[index]?.textAligned ? tableHeader[index].textAligned : 'text-center'
                          }
                          ${header.id === 'checkbox' && '!p-0 w-12 left-0 sticky fixedLeft !bg-[#F2F2F2] z-[5]'}`}
                          style={{ minWidth: header.getSize() }}
                          onMouseEnter={() => setHoveredHeader(header.id)}
                          onMouseLeave={() => setHoveredHeader(null)}
                        >
                          {header.isPlaceholder ? null : getTextAlignment(header.id) === 'text-right' ? (
                            <NumberHeader
                              enableHide={false}
                              sort={header.column.getIsSorted()}
                              label={flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? ''}
                              resetSorting={() => header.column.clearSorting()}
                              sortFn={(value: boolean | undefined) => header.column.toggleSorting(value)}
                              //@TODO: add proper hide & sort logic
                              hideFn={() => {
                                //get the header from tableHeader by key
                                // const headerToUpdate = tableHeader.findIndex((item) => item.key === header.id);
                                // const newTableHeader = [...tableHeader];
                                // newTableHeader.splice(headerToUpdate, 1);
                                // if (typeof getFilteredTableHeader === 'function') {
                                //   getFilteredTableHeader(newTableHeader);
                                // }
                              }}
                            />
                          ) : (
                            <DefaultTableHeader
                              isHeader
                              enableHide={false}
                              isCheckboxVisible={false}
                              sort={header.column.getIsSorted()}
                              label={flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? ''}
                              checked={table.getIsAllRowsSelected()}
                              indeterminate={table.getIsSomeRowsSelected()}
                              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                              withoutPaddingLeft={index === 1}
                              resetSorting={() => header.column.clearSorting()}
                              sortFn={(value: boolean | undefined) => header.column.toggleSorting(value)}
                              hideFn={() => {
                                //get the header from tableHeader by key
                                // const headerToUpdate = tableHeader.findIndex((item) => item.key === header.id);
                                // const newTableHeader = [...tableHeader];
                                // newTableHeader.splice(headerToUpdate, 1);
                                // if (typeof getFilteredTableHeader === 'function') {
                                //   getFilteredTableHeader(newTableHeader);
                                // }
                              }}
                            />
                          )}
                          <div
                            {...{
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer ${header.column.getIsResizing() ? 'isResizing !bg-blue-700' : ''}`,
                            }}
                          />
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td className='pt-0 px-0 pb-0 !bg-white overflow-hidden' colSpan={tableHeader.length + 2}>
                      <TableSkeletonLoader />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {table.getRowModel().rows.map((row) => {
                    if (typeof row.original === 'string') {
                      return (
                        <tr key={row.id}>
                          <td colSpan={tableHeader.length + 1} className='font-semibold  bg-[#F8F8F8] !h-10 px-[60px] '>
                            {row.original}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      <tr id={row.original._id} key={row.id}>
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <td
                              key={cell.id}
                              onClick={() => {
                                cell.row.getToggleExpandedHandler();
                              }}
                              className={`relative  ${index !== 0 ? 'min-w-[10rem] max-w-[16rem]' : ''} px-0 ${
                                row.original.type === 'total' ? '!bg-[#ebebff]' : null
                              }`}
                              data-testid='tbody__td'
                              style={{
                                minWidth: cell.column.id !== 'checkbox' ? cell.column.getSize() : '',
                              }}
                            >
                              {!index ? (
                                <div className='flex justify-between items-center'>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                              ) : (
                                <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                              )}
                              <div
                                className={`w-[5px] top-[-64px] bottom-[-1px] absolute right-0 ${
                                  cell.getContext().column.getIsResizing() ? 'bg-blue-700' : 'bg-transparent'
                                }
                                ${hoveredHeader === cell.column.id && '!bg-gray-500'}
                                
                                `}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </>
        )}
      </div>
      <div className='h-5 w-5 my-4' ref={ref} />
      {isLoading && !!tableData.length && (
        <div className='w-full flex justify-center items-center my-8'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
    </>
  );
};
