import LedgerAccountDetails from '../../ledger-account/LedgerAccountDetails';
import { useGetLedgerAccountById } from '../../../hooks/http';
import { SidebarProps } from '../SidebarGlobal';

const LedgerAccountSidebar: React.FC<SidebarProps> = ({
  isDockPanelAvailable,
  id,
  onBack,
  handlePrimaryPanelClose,
  isPrimary,
}) => {
  const { data, isLoading } = useGetLedgerAccountById(id);

  return (
    <LedgerAccountDetails
      onClose={() => handlePrimaryPanelClose && handlePrimaryPanelClose()}
      selectedLedgerAccount={data?.ledgerAccount}
      isLoading={isLoading}
      isDockPanelAvailable={isDockPanelAvailable}
      onBack={onBack}
      id={id}
      isPrimary={isPrimary}
    />
  );
};

export default LedgerAccountSidebar;
