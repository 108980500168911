import { useMemo } from 'react';
import { FACT } from '../../types';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { useAddCondition } from '../useAddCondition';
import { CSRowProps, FilterFn } from '../types';
import { NestConditionRow } from '../NestConditionRow';

export const ACCOUNT_POSTING_MISC_ROWS = [
  'Nest Condition',
  'Gain',
  'Loss',
  'Smart Contract Address',
  'Transaction Memo',
  'Exchange',
];
export const IMPAIRMENT_MISC_ROWS = [
  'Nest Condition',
  'Gain',
  'Gain Tolerance',
  'Loss',
  'Loss Tolerance',
  'Last Impaired On Date',
  'Date Received On',
];
const filterAccountPostingMiscRowsFn: FilterFn<string> = (searchTerm) => (value) =>
  value.toLowerCase().includes(searchTerm.toLowerCase());

export const useAccountPostingRulesetMiscConditionsGroup = (showNestConditionRow) =>
  useMemo(
    () => ({
      heading: 'Misc conditions',
      data: ACCOUNT_POSTING_MISC_ROWS,
      filterFn: filterAccountPostingMiscRowsFn,
      Row: MiscConditionSelector,
      skipFn: (values) => (showNestConditionRow ? values : values.filter((v) => v !== 'Nest Condition')),
    }),
    [],
  );

export const useImpairmentMiscConditionsGroup = (showNestConditionRow) =>
  useMemo(
    () => ({
      heading: 'Misc conditions',
      data: IMPAIRMENT_MISC_ROWS,
      filterFn: filterAccountPostingMiscRowsFn,
      Row: MiscConditionSelector,
      skipFn: (values) => (showNestConditionRow ? values : values.filter((v) => v !== 'Nest Condition')),
    }),
    [],
  );

export const MiscConditionSelector = ({ conditionData }: CSRowProps<string>) => {
  const addCondition = useAddCondition();
  switch (conditionData.rowData) {
    case 'Nest Condition':
      return <NestConditionRow />;
    case 'Gain':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Gain' }}
          onClick={() =>
            addCondition({
              fact: FACT.GAIN,
              operator: 'equal',
              value: true,
            })
          }
        />
      );
    case 'Gain Tolerance':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Gain Tolerance' }}
          onClick={() =>
            addCondition({
              fact: FACT.GAIN_TOLERANCE,
              operator: 'equal',
              value: 5,
            })
          }
        />
      );
    case 'Loss':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Loss' }}
          onClick={() =>
            addCondition({
              fact: FACT.LOSS,
              operator: 'equal',
              value: true,
            })
          }
        />
      );
    case 'Loss Tolerance':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Loss Tolerance' }}
          onClick={() =>
            addCondition({
              fact: FACT.LOSS_TOLERANCE,
              operator: 'equal',
              value: 5,
            })
          }
        />
      );
    case 'Last Impaired On Date':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Last Impaired On Date' }}
          onClick={() =>
            addCondition({
              fact: FACT.LAST_IMPAIRED_ON,
              value: new Date().getTime(),
              operator: 'greaterThan',
            })
          }
        />
      );
    case 'Date Received On':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Date Received On' }}
          onClick={() =>
            addCondition({
              fact: FACT.DATE_RECEIVED_ON,
              value: new Date().getTime(),
              operator: 'greaterThan',
            })
          }
        />
      );
    case 'Smart Contract Address':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Smart Contract Address' }}
          onClick={() =>
            addCondition({
              fact: FACT.RAW_CONTRACT_ADDRESS,
              operator: 'equal',
              value: '',
            })
          }
        />
      );
    case 'Transaction Memo':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Transaction Memo' }}
          onClick={() =>
            addCondition({
              fact: FACT.TRANSACTION_MEMO,
              operator: 'stringContains',
              value: '',
            })
          }
        />
      );
    case 'Exchange':
      return (
        <ConditionSelectorRow
          rowData={{ label: 'Exchange' }}
          onClick={() =>
            addCondition({
              fact: FACT.EXCHANGE,
              operator: 'equal',
              value: '',
            })
          }
        />
      );
  }
};
