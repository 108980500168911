import { BUTTON_EMPHASIS, BUTTON_STATUS } from 'ui';
import { Action } from '../atoms';
import toast from 'react-hot-toast';
import { downloadCsv } from '../../lib/utils/download-csv';

export const getJournalActions = ({
  journalEntry,
  csvData,
  onJournalEntryDelete,
  onEditClick,
  handleUnpostJournalEntry,
  isUnposting,
  handleReverseEntry,
  isReversingJournalEntry,
  postJournalEntry,
  isPosting,
  recalculateJournalEntry,
  isRecalculatingJournalEntry,
  isLoading,
}): Action[] => {
  if (!journalEntry) return [];
  if (journalEntry.status === 'UNPOSTED')
    return unpostedActions({
      onJournalEntryDelete,
      journalEntry,
      onEditClick,
      csvData,
      postJournalEntry,
      isPosting,
      isLoading,
    });
  if (journalEntry.status === 'DRAFT')
    return draftActions({
      onJournalEntryDelete,
      onEditClick,
      journalEntry,
      csvData,
      postJournalEntry,
      isPosting,
      isLoading,
    });
  if (journalEntry.status === 'POSTED')
    return postedActions({
      handleUnpostJournalEntry,
      isUnposting,
      journalEntry,
      handleReverseEntry,
      isReversingJournalEntry,
      csvData,
      isLoading,
      recalculateJournalEntry,
      isRecalculatingJournalEntry,
    });
  if (journalEntry.status === 'REVERSED') {
    return reveresedActions({
      handleUnpostJournalEntry,
      journalEntry,
      isUnposting,
      csvData,
      isLoading,
    });
  }
  return [];
};

export const commonActions = ({ csvData, isLoading }): Action[] => {
  const exportAsCsv = () => {
    if (!csvData?.length) {
      toast.error('No data to export');
    } else {
      downloadCsv(csvData, `journal-entry-export-${new Date().toLocaleDateString()}.csv`);
      toast.success('Journal entry exported successfully');
    }
  };
  return [
    {
      label: <button onClick={exportAsCsv}>Download</button>,
      props: {
        disabled: !csvData?.length || isLoading,
      },
    },
  ];
};

export const unpostedActions = ({
  onJournalEntryDelete,
  journalEntry,
  onEditClick,
  csvData,
  postJournalEntry,
  isPosting,
  isLoading,
}): Action[] => {
  const actions = [
    {
      label: 'Post',
      onClick: postJournalEntry,
      props: { emphasis: BUTTON_EMPHASIS.MEDIUM, status: BUTTON_STATUS.NEUTRAL, disabled: isPosting },
      isLoading: isPosting,
    },
    {
      label: 'Edit',
      onClick: onEditClick,
      props: {
        disabled: journalEntry.status === 'POSTED',
      },
      'data-cy': 'editButton',
    },
    {
      label: 'Delete',
      onClick: onJournalEntryDelete,
      props: { emphasis: BUTTON_EMPHASIS.MEDIUM, status: BUTTON_STATUS.DANGER },
    },
  ];
  return [...commonActions({ csvData, isLoading }), ...actions];
};

export const reveresedActions = ({
  handleUnpostJournalEntry,
  journalEntry,
  isUnposting,
  csvData,
  isLoading,
}): Action[] => {
  const actions = [
    {
      label: 'Unpost',
      onClick: handleUnpostJournalEntry,
      props: {
        disabled: isUnposting || journalEntry.status?.toLowerCase() !== 'posted',
      },
      isLoading: isUnposting,
    },
  ];
  return [...commonActions({ csvData, isLoading }), ...actions];
};

export const postedActions = ({
  handleUnpostJournalEntry,
  isUnposting,
  journalEntry,
  handleReverseEntry,
  isReversingJournalEntry,
  csvData,
  isLoading,
  recalculateJournalEntry,
  isRecalculatingJournalEntry,
}): Action[] => {
  const actions = [
    {
      label: 'Unpost',
      onClick: handleUnpostJournalEntry,
      props: {
        disabled: isUnposting || journalEntry.status?.toLowerCase() !== 'posted',
      },
      isLoading: isUnposting,
    },

    {
      label: 'Reverse entry',
      onClick: handleReverseEntry,
      props: {
        disabled: isReversingJournalEntry,
      },
      isLoading: isReversingJournalEntry,
    },
    {
      label: 'Recalculate',
      onClick: recalculateJournalEntry,
      props: {
        disabled: isRecalculatingJournalEntry,
      },
      isLoading: isRecalculatingJournalEntry,
    },
  ];

  return [...commonActions({ csvData, isLoading }), ...actions];
};

export const draftActions = ({
  onJournalEntryDelete,
  onEditClick,
  journalEntry,
  csvData,
  postJournalEntry,
  isPosting,
  isLoading,
}): Action[] => {
  const actions = [
    {
      label: 'Post',
      onClick: postJournalEntry,
      props: { emphasis: BUTTON_EMPHASIS.MEDIUM, status: BUTTON_STATUS.NEUTRAL, disabled: isPosting },
      isLoading: isPosting,
    },
    {
      label: 'Edit',
      onClick: onEditClick,
      props: {
        disabled: journalEntry.status === 'POSTED',
      },
      'data-cy': 'editButton',
    },
    {
      label: 'Delete',
      onClick: onJournalEntryDelete,
      props: { emphasis: BUTTON_EMPHASIS.MEDIUM, status: BUTTON_STATUS.DANGER },
    },
  ];
  return [...commonActions({ csvData, isLoading }), ...actions];
};
