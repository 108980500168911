import React, { useMemo } from 'react';
import { ConditionsGroup } from '../ConditionsGroup';
import { ConditionSelectorRow } from '../ConditionSelectorRow';
import { FACT } from '../../types';
import { useAddCondition } from '../useAddCondition';
import { useFilteredConditions } from '../utils';
import { ConditionGroupFilterConfig, ConvertToRowFn, FilterFn } from '../types';
import { Virtuoso } from 'react-virtuoso';

const TRANSACTION_DIRECTIONS = ['Credit', 'Debit'];

const convertToRowFn: ConvertToRowFn<string> = (directionString) => ({
  rowData: { value: directionString.toLowerCase(), label: directionString },
  isSelected: false,
});

const filterRowsFn: FilterFn<string> = (searchTerm) => (directionString) =>
  directionString.toLowerCase().includes(searchTerm.toLowerCase());

export const useTransactionDirectionConditionGroup = (): Omit<ConditionGroupFilterConfig, 'rows'> =>
  useMemo(
    () => ({
      heading: 'Transaction conditions',
      data: TRANSACTION_DIRECTIONS,
      filterFn: filterRowsFn,
      convertToRowFn,
      Row: TransactionDirectionConditionSelectorRow,
    }),
    [],
  );

export const TransactionDirectionConditionSelectorRow = ({ conditionData }) => {
  const addCondition = useAddCondition();
  return (
    <ConditionSelectorRow
      rowData={conditionData.rowData}
      onClick={() =>
        addCondition({
          fact: FACT.TRANSACTION_DIRECTION,
          operator: 'equal',
          value: conditionData.rowData.value,
        })
      }
    />
  );
};

export const TransactionDirectionConditionsMenu = () => {
  const conditionsData = useFilteredConditions(TRANSACTION_DIRECTIONS, filterRowsFn, convertToRowFn);
  return (
    <ConditionsGroup label='Transaction Direction'>
      {conditionsData.length === 0 && <div className='text-center'>No transaction directions to show</div>}
      {conditionsData.length > 0 && (
        <Virtuoso
          style={{ height: 250 }}
          data={conditionsData}
          itemContent={(index, data) => <TransactionDirectionConditionSelectorRow key={index} conditionData={data} />}
        />
      )}
    </ConditionsGroup>
  );
};
