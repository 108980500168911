import { Wallet } from 'schemas';
import { importTransactions } from '../components/Sources/utils';

export const useCreateImportTransactionsBgJob = ({
  onSuccess,
  onError,
  skipClassification = false,
}: {
  onSuccess?: () => void;
  onError?: (error: any) => void;
  skipClassification?: boolean;
} = {}) => {
  const createJob = async (wallet: Wallet, startDate: Date) => {
    try {
      await importTransactions(wallet, new Date(startDate).toISOString(), skipClassification);

      if (onSuccess) onSuccess();
      return;
    } catch (error) {
      if (onError) onError(error);
      else throw error;
    }
  };

  return { createJob };
};
