import { classNames as cn } from '../utils';
import { TwoLineIconsProps } from './types';
import { IoMdHelpCircleOutline, IoMdRepeat } from 'react-icons/io';
import {
  MdAutorenew,
  MdOutlineHistoryToggleOff,
  MdOutlinePaid,
  MdOutlineSettingsBackupRestore,
  MdRotateRight,
  MdOutlineSouth,
  MdOutlineNorth,
  MdOutlineImage,
  MdOutlineWarning,
} from 'react-icons/md';
export enum CLASSIFICATION_ROW {
  SWAP = 'SWAP',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  BRIDGE = 'BRIDGE',
  INTERCOMPANY_TRANSFER = 'INTERCOMPANY TRANSFER',
  INTERNAL_TRANSFER = 'INTERNAL TRANSFER',
  FEE = 'FEE',
  MINTING = 'MINTING',
  SPAM = 'SPAM',
  NFT = 'NFT',
  STAKING_REWARD = 'STAKING_REWARD',
  CLAIM_REWARD = 'CLAIM REWARD',
  UNKNOWN = 'UNKNOWN',
}

export function TwoLineIcons({
  label,
  bottomText,
  iconLeft,
  iconRight,
  textAlign = 'text-left',
  state = 'default',
  withIconLeft = false,
  withIconRight = false,
  labelClassNames = '',
}: TwoLineIconsProps) {
  return (
    <div
      className={cn(
        'group relative overflow-hidden w-[inherit]',
        'py-1 flex items-center gap-3 ',
        state === 'selected' && 'bg-indigo-50',
        state === 'active' && 'border-b-2 bg-indigo-100',
        textAlign === 'text-center' && 'text-center justify-center',
        textAlign === 'text-left' && 'text-left justify-start',
        textAlign === 'text-right' && 'text-right justify-end',
      )}
    >
      {withIconLeft && (
        <div className='[&>svg]:w-6 [&>svg]:h-6 [&>svg]:text-black'>
          {getLeftIcon(label?.toUpperCase()) !== null ? getLeftIcon(label?.toUpperCase()) : iconLeft}
        </div>
      )}
      <div className='flex-shrink-0 flex-grow w-full'>
        <p className={cn('text-base text-zinc-900 leading-6 truncate w-[calc(100%_-_2rem)]', labelClassNames)}>
          {label ? label.replace(/_/g, ' ') : 'Unknown'}
        </p>
        {bottomText && <p className='text-base text-zinc-600 leading-6 truncate w-[calc(100%_-_2rem)]'>{bottomText}</p>}
      </div>
      {withIconRight && <div className='[&>svg]:w-6 [&>svg]:h-6'>{iconRight}</div>}
    </div>
  );
}

export const getLeftIcon = (type: string) => {
  switch (type) {
    case CLASSIFICATION_ROW.MINTING:
      return <MdOutlineHistoryToggleOff />;
    case CLASSIFICATION_ROW.WITHDRAWAL:
      return <MdOutlineNorth />;
    case CLASSIFICATION_ROW.DEPOSIT:
      return <MdOutlineSouth />;
    case CLASSIFICATION_ROW.SWAP:
      return <MdAutorenew />;
    case CLASSIFICATION_ROW.FEE:
      return <MdOutlinePaid />;
    case CLASSIFICATION_ROW.BRIDGE:
      return <MdRotateRight />;
    case CLASSIFICATION_ROW.INTERNAL_TRANSFER:
      return <MdOutlineSettingsBackupRestore />;
    case CLASSIFICATION_ROW.INTERCOMPANY_TRANSFER:
      return <IoMdRepeat />;
    case CLASSIFICATION_ROW.SPAM:
      return <MdOutlineWarning />;
    case CLASSIFICATION_ROW.NFT:
      return <MdOutlineImage />;
    case CLASSIFICATION_ROW.STAKING_REWARD:
      return <MdOutlineSouth />;
    case CLASSIFICATION_ROW.CLAIM_REWARD:
      return <MdOutlineSouth />;
  }

  return <IoMdHelpCircleOutline />;
};
