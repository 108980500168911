import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledItem, StyledSubContent, StyledSubTrigger } from './StyledRadixDropdownElements';
import { ArrowRight } from '../../assets/generated/svg';
import { useLedgerAccount } from '../../hooks/http';
import { formatLedgerAccountName, mergeLedgerAccounts } from '../templates/utils';
export const LedgerAccountSubMenu = ({ callbackFn }) => {
  const { data: ledgerAccounts } = useLedgerAccount({
    pageSize: 1000,
  });
  const mergedLedgeAccounts = mergeLedgerAccounts(ledgerAccounts);
  const liability = mergedLedgeAccounts.filter((la) => la.ledgerAccountType === 'Liability');
  const equity = mergedLedgeAccounts.filter((la) => la.ledgerAccountType === 'Equity');
  const asset = mergedLedgeAccounts.filter((la) => la.ledgerAccountType === 'Asset');
  const income = mergedLedgeAccounts.filter((la) => la.ledgerAccountType === 'Income');
  const expense = mergedLedgeAccounts.filter((la) => la.ledgerAccountType === 'Expense');

  return (
    // <DropdownMenu.Sub asChild defaultOpen>
    <DropdownMenu.Sub defaultOpen>
      <StyledSubTrigger>
        <div className='flex justify-between items-center w-full'>
          <div className='flex items-center py-2'>
            <div>
              <p className='text-[#222222] mb-0'>Ledger accounts</p>
            </div>
          </div>
          <ArrowRight width='7' height='11' />
        </div>
      </StyledSubTrigger>
      <StyledSubContent>
        <DropdownMenu.Sub>
          <StyledSubTrigger>
            <div className='flex justify-between items-center w-full'>
              <div className='flex items-center py-2'>
                <div>
                  <p className='text-[#222222] mb-0'>Equity</p>
                </div>
              </div>
              <ArrowRight width='7' height='11' />
            </div>
          </StyledSubTrigger>
          <StyledSubContent className='max-h-[400px] overflow-auto'>
            {equity.map((la) => (
              <StyledItem onClick={() => callbackFn(la._id)} key={la._id}>
                {formatLedgerAccountName(la)}
              </StyledItem>
            ))}
          </StyledSubContent>
        </DropdownMenu.Sub>

        <DropdownMenu.Sub>
          <StyledSubTrigger>
            <div className='flex justify-between items-center w-full'>
              <div className='flex items-center py-2'>
                <div>
                  <p className='text-[#222222] mb-0'>Asset</p>
                </div>
              </div>
              <ArrowRight width='7' height='11' />
            </div>
          </StyledSubTrigger>
          <StyledSubContent className='max-h-[400px] overflow-auto'>
            {asset.map((la) => (
              <StyledItem onClick={() => callbackFn(la._id)} key={la._id}>
                {formatLedgerAccountName(la)}
              </StyledItem>
            ))}
          </StyledSubContent>
        </DropdownMenu.Sub>

        <DropdownMenu.Sub>
          <StyledSubTrigger>
            <div className='flex justify-between items-center w-full'>
              <div className='flex items-center py-2'>
                <div>
                  <p className='text-[#222222] mb-0'>Liability</p>
                </div>
              </div>
              <ArrowRight width='7' height='11' />
            </div>
          </StyledSubTrigger>
          <StyledSubContent className='max-h-[400px] overflow-auto'>
            {liability.map((la) => (
              <StyledItem onClick={() => callbackFn(la._id)} key={la._id}>
                {formatLedgerAccountName(la)}
              </StyledItem>
            ))}
          </StyledSubContent>
        </DropdownMenu.Sub>
        <DropdownMenu.Sub>
          <StyledSubTrigger>
            <div className='flex justify-between items-center w-full'>
              <div className='flex items-center py-2'>
                <div>
                  <p className='text-[#222222] mb-0'>Income</p>
                </div>
              </div>
              <ArrowRight width='7' height='11' />
            </div>
          </StyledSubTrigger>
          <StyledSubContent className='max-h-[400px] overflow-auto'>
            {income.map((la) => (
              <StyledItem onClick={() => callbackFn(la._id)} key={la._id}>
                {formatLedgerAccountName(la)}
              </StyledItem>
            ))}
          </StyledSubContent>
        </DropdownMenu.Sub>
        <DropdownMenu.Sub>
          <StyledSubTrigger>
            <div className='flex justify-between items-center w-full'>
              <div className='flex items-center py-2'>
                <div>
                  <p className='text-[#222222] mb-0'>Expense</p>
                </div>
              </div>
              <ArrowRight width='7' height='11' />
            </div>
          </StyledSubTrigger>
          <StyledSubContent className='max-h-[400px] overflow-auto'>
            {expense.map((la) => (
              <StyledItem onClick={() => callbackFn(la._id)} key={la._id}>
                {formatLedgerAccountName(la)}
              </StyledItem>
            ))}
          </StyledSubContent>
        </DropdownMenu.Sub>
      </StyledSubContent>
    </DropdownMenu.Sub>
  );
};
