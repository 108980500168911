import { SkeletonRowProps } from './types';

export const SkeletonRow = ({ size, variant }: SkeletonRowProps) => {
  if (variant === 'card')
    return (
      <div className='flex items-center justify-center h-[300px] bg-[#F8F8F8]'>
        <div className='w-96 bg-gray-100 p-6 rounded-lg shadow-lg'>
          <div className='animate-pulse'>
            <div className='h-12 bg-gray-300 rounded w-3/4'></div>
            <div className='mt-2 h-12 bg-gray-300 rounded w-full'></div>
            <div className='mt-2 h-12 bg-gray-300 rounded w-1/2'></div>
          </div>
        </div>
      </div>
    );

  if (size === 'sm') return <div className='h-4 w-[45%] bg-gradient-to-r from-zinc-200 to-white mb-1'></div>;
  return <div className='h-4 bg-gradient-to-r from-zinc-200 to-white mb-1 w-[90%] mb-1'></div>;
};
