import * as React from 'react';
import { forwardRef } from 'react';
const SvgClipboardIcon = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      d='M9 .667H1.667C.933.667.333 1.267.333 2v8.667c0 .366.3.666.667.666.367 0 .667-.3.667-.666v-8c0-.367.3-.667.666-.667H9c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666Zm2.667 2.666H4.334C3.6 3.333 3 3.933 3 4.667V14c0 .733.6 1.333 1.333 1.333h7.334c.733 0 1.333-.6 1.333-1.333V4.667c0-.734-.6-1.334-1.333-1.334ZM11 14H5a.669.669 0 0 1-.667-.667v-8c0-.366.3-.666.667-.666h6c.367 0 .667.3.667.666v8c0 .367-.3.667-.667.667Z'
      fill='#000'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgClipboardIcon);
export default ForwardRef;
