import clsx from 'clsx';
import { classNames as cn } from '../utils';
import { SingleLineNumberProps } from './types';

export function SingleLineNumber({
  label,
  state = 'default',
  textAlign,
  withoutPaddingLeft = false,
  className,
}: SingleLineNumberProps) {
  return (
    <p
      className={cn(
        clsx(
          'group relative overflow-hidden min-w-max w-full',
          'px-6 text-zinc-900',
          ' hover:cursor-pointer',
          { 'bg-indigo-50': state === 'selected' },
          { 'border-b-2 bg-indigo-100': state === 'active' },
          { 'text-center': textAlign === 'text-center' },
          { 'text-left': textAlign === 'text-left' },
          { 'text-right': textAlign === 'text-right' },
          { '!pl-0': withoutPaddingLeft },
          className,
        ),
      )}
    >
      <span>{label}</span>
    </p>
  );
}
