import * as React from 'react';
import { forwardRef } from 'react';
const SvgArrowTop = (props, ref) => (
  <svg width='1em' height='1em' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' ref={ref} {...props}>
    <path
      opacity={0.5}
      d='M4.898 12V3.068L1.494 6.483.46 5.46 5.636.295l5.165 5.165L9.79 6.483 6.375 3.068V12H4.898Z'
      fill='#111'
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowTop);
export default ForwardRef;
