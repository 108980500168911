import { useCommandPallete } from '../useCommandPallete';
import { MdChevronLeft, MdSend } from 'react-icons/md';
import { Button, classNames } from 'ui';
import { GPTFeedback } from '../GPTFeedback';
import { Action, useKBar } from 'farish-kbar';
import { ChangeEvent, memo, useEffect, useRef, useState } from 'react';
import { AppMentionsInput } from '../AppMentionsInput';

import { ChatLoader, ResponseContainer } from '../ResponseContainer';
import Suggestions from '../Suggestions';
import { useOnClickOutside } from 'usehooks-ts';
import { motion, AnimatePresence } from 'framer-motion';
import { AnalyzeIntentActions, CreateIntentActions, DeleteIntentActions, EditIntentActions } from '../../../constants';

const SuggestionsByIntent = {
  Create: CreateIntentActions,
  Edit: EditIntentActions,
  Delete: DeleteIntentActions,
  Analyze: AnalyzeIntentActions,
};
import { useAskAI } from '../../../hooks/ai-everything';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { ScrollBar } from '@/shadcn/ui/scroll-area';
import { usePathname } from 'next/navigation';
import { AssistantsConversationInteractionHistory } from 'schemas';
import { useGetConversations } from 'src/hooks/http/useGPT';

const styles = {
  fullscreen: 'w-full h-full z-0',
  kbar: 'max-h-[600px] h-[60vh] z-commandpallet',
  common: 'w-full  bg-white rounded- flex flex-col px-2 relative',
};

export const getGPTChatStyles = (fullscreen: boolean) => {
  if (fullscreen) return styles.fullscreen + ' ' + styles.common;
  return styles.kbar + ' ' + styles.common;
};

export const GPTChat = ({ inputRef, fullscreen = true }) => {
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);

  const pathname = usePathname();

  const {
    setSecondViewPath,
    //setGptChatResponses,
    gptChatResponses,
    isLoading,
    selectedIntent,
    setSelectedIntent,
    setThreadId,
    isOpen,
    instruction,
    setInstruction,
    conversationId,
    setConversationId,
  } = useCommandPallete();

  useOnClickOutside(suggestionsRef, () => {
    if (pathname.includes('copilot') && fullscreen && isOpen) return; // prevent this hook from conflicting when on copilot page and the cmd bar is open
  });

  const { query } = useKBar();
  const onBack = () => {
    query.setSearch('');
    setSecondViewPath('');
    //setGptChatResponses([]);
    setSelectedIntent(null);
    setThreadId(undefined);
    setConversationId(undefined);
  };
  const { handleAskAI, error } = useAskAI({ callback: undefined });

  const autoHeight = (elem: HTMLTextAreaElement) => {
    /* javascript */
    elem.style.height = '1px';
    elem.style.height = elem.scrollHeight + 'px';
  };

  const scrollToBottom = () => {
    chatBoxRef.current?.scrollTo({
      top: chatBoxRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [isLoading, gptChatResponses, instruction]);

  useEffect(() => {
    if (!inputRef) return;
    inputRef.current?.focus();
  }, [selectedIntent]);

  useEffect(() => {
    if (inputRef.current) {
      // set style of the input, because the library doesn't allow us to do it
      inputRef.current.className = classNames(
        inputRef.current.className,
        'text-[16px] w-full placeholder:text-gray-600 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide h-full',
      );
    }
  }, [inputRef.current]);

  const onSelectSuggestion = (action: Action) => {
    let newValue = '';
    switch (selectedIntent) {
      case 'Create':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Edit':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Delete':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      case 'Analyze':
        newValue = action.name.endsWith('...') ? action.name.slice(0, -3) + ' ' : action.name;
        break;
      default:
        break;
    }
    query.setSearch(newValue);
    setInstruction(newValue);
    if (!inputRef.current) return;
    inputRef.current.value = newValue;
    inputRef.current?.focus();
    autoHeight(inputRef.current);
  };

  const { data } = useGetConversations(); // isLoading: isLoadingConversations
  useEffect(() => {
    if (!conversationId && data && data.length > 0) {
      // setConversationId(data[0]._id);
    }
  }, [data]);

  const interactionHistories =
    data?.find((conversation) => conversation._id === conversationId)?.interactionHistory || [];

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  console.log(`Conversation ID: ${conversationId}`, `Data`, data, `Interaction Histories`, interactionHistories);
  return (
    <div className={getGPTChatStyles(fullscreen)}>
      <div className={`flex items-center gap-x-2 ${fullscreen ? 'hidden' : ''}`}>
        <Button
          label={inputRef?.current?.value ?? 'Copilot'}
          leadingIcon={<MdChevronLeft className='flex-shrink-0' />}
          onClick={onBack}
          className='hover:bg-indigo-50 h-auto items-center max-w-full'
          emphasis='low'
          labelContainerClassname='w-full py-1.5 whitespace-nowrap block h-auto text-left  text-ellipsis overflow-hidden'
          disableRingOnFocus
        />
      </div>
      <div className='h-full overflow-auto break-words' ref={chatBoxRef}>
        <div className='h-full pr-2'>
          <ScrollArea>
            <ScrollBar />
            {/* <ResponseContainer className={`${error ? '' : 'hidden'}`}>{error}</ResponseContainer> */}
            <MemoizedResponsesComponent interactionHistories={interactionHistories} />
            <div className={`${isLoading && instruction ? '' : 'hidden'}`}>
              <ResponseContainer newQuery={true} query={instruction.toString()}>
                {instruction.toString()}
              </ResponseContainer>
              <ChatLoader />
            </div>
          </ScrollArea>
        </div>
      </div>
      <div className={`mt-4 flex flex-col gap-x-4 relative'}`}>
        <div className='flex flex-col gap-x-2 relative'>
          <div className=''>
            <Suggestions value={instruction} onSearch={setInstruction} />
          </div>
        </div>
        <div className={`px-4 mr-2 max-h-60 scrollbar-hide w-full relative flex ${fullscreen ? '' : 'z-50'}`}>
          <AnimatePresence>
            <motion.div
              ref={suggestionsRef}
              initial={{ opacity: 0, y: 0, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              className={`
              ${selectedIntent && !instruction && !isLoading ? ' ' : 'hidden'}
              absolute top-0 -translate-y-[calc(100%_+_4rem)] !transform flex flex-col gap-1 w-full right-0 overflow-clip rounded-md bg-white p-1 shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)]`}
            >
              {SuggestionsByIntent[selectedIntent ? selectedIntent : '']?.map((action) => (
                <button
                  key={action.id}
                  className='px-3 flex items-center gap-2 py-1.5 hover:bg-indigo-50 cursor-pointer rounded-md text-zinc-900 font-medium w-full text-left'
                  onClick={() => onSelectSuggestion(action)}
                >
                  {action.icon}
                  {action.name}
                </button>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
        <div className='w-full flex items-end flex-row mb-1'>
          <div
            className={`px-3 py-2 min-h-[2.5rem]  mr-2 border rounded-lg scrollbar-hide w-full relative flex ${fullscreen ? '' : 'z-commandmenu'}`}
          >
            <AppMentionsInput
              className='text-[16px] w-full h-full placeholder:text-gray-600 focus:!outline-none disabled:text-gray-700 disabled:bg-white resize-none max-h-60 scrollbar-hide break-words'
              placeholder='Enter message'
              onInput={(e: ChangeEvent<HTMLTextAreaElement>) => autoHeight(e.target)}
              value={instruction}
              onChange={(e) => {
                setInstruction(e.target.value);
                query.setSearch(e.target.value);
                inputRef.current.value = e.target.value;
                if (isSuggestionOpen) setIsSuggestionOpen(false);
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && instruction && !isLoading) {
                  e.preventDefault();
                  handleAskAI();
                }
              }}
              onInputChange={(e) => {
                setInstruction(e.target.value);
                query.setSearch(e.target.value);
              }}
              disabled={isLoading}
              inputRefSetter={(ref) => {
                if (ref) {
                  inputRef.current = ref;
                  query?.inputRefSetter(ref);
                }
              }}
              customSuggestionsContainer={(children) => {
                return (
                  <div className='fixed inset-x-2 bottom-0 -translate-y-16 p-2 z-[9999] rounded-md shadow-[0px_10px_10px_-5px_rgba(0,_0,_0,_0.04),_0px_20px_25px_-5px_rgba(0,_0,_0,_0.10)] bg-white'>
                    <div className='max-h-[260px] overflow-auto h-fit  flex flex-col gap-1 [&_li]:mb-1 last:[&_li]:mb-0'>
                      {children}
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <Button
            onClick={() => handleAskAI()}
            type='submit'
            leadingIcon={<MdSend className='w-9 h-9 p-2 mb-0.5 text-zinc-900 ' />}
            aria-label='Send'
            disabled={!instruction || isLoading}
            className='bg-transparent border-0 !p-0 group hover:bg-transparent !shadow-none flex items-center'
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
const ResponsesComponent = ({
  interactionHistories,
}: {
  interactionHistories: AssistantsConversationInteractionHistory[];
}) => {
  return (
    <div className='py-2 flex flex-col gap-4'>
      {interactionHistories.map((interactionHistory) => {
        return <GPTFeedback key={interactionHistory._id} interactionHistory={interactionHistory} />;
      })}
    </div>
  );
};
const MemoizedResponsesComponent = memo(ResponsesComponent);

export default GPTChat;
